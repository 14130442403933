import React from "react";

import { useState } from "react";
import Header from "../../components/header/Header";
import Navbar from "../../components/navbar/Navbar";
import { Routes, Route, useNavigate, useLocation, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import VendorTarrif from "./VendorTarrif";
import "./VendorTarrifMAnagement.css";




const VendorTarrifMAnagement = () => {
  const [sidebar, setSidebar] = useState(false);

  const navigates = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const handleItemClick = (itemName) => {
    console.log(`Clicked on item: ${itemName}`);
  };

  const navigate = (to) => {
    console.log(`Navigating to: ${to}`);
    navigates(to);
  };

  return (
    <div>
      <Header setSidebar={setSidebar} sidebar={sidebar} />
      <div className="navbar-adminInfo">
        <Navbar
          sidebar={sidebar}
          handleItemClick={handleItemClick}
          navigate={navigate}
        />
        <VendorTarrif/>
      </div>
    </div>
  );
};

export default VendorTarrifMAnagement;
