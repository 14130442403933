import axios from "axios";
import {
  GET_OUTSTATION_TARIFF,
  GET_AIRPORT_TARIFF_OBJ,
  MAKE_REQUEST,
  FAIL_REQUEST,
  DELETE_TARIFF,
  GET_AIRPORT_TARIFF,
  ADD_AIRPORT_LOCAL_TARIFF,
  UPDATE_LOCAL_AIRPORT_TARIFF,
  ADD_OUTSTATION_TARIFF,
  GET_SINGLE_OUTSTATION_TARIFF,
  UPDATE_OUTSTATION_TARIFF,
  GET_LOCAL_TARIFF,
  ADD_LOCAL_TARIFF,
  UPDATE_LOCAL_TARIFF,
} from "./Actiontype";

export const makeRequest = () => {
  return {
    type: MAKE_REQUEST,
  };
};
export const failRequest = (err) => {
  return {
    type: FAIL_REQUEST,
    payload: err,
  };
};

//for airport and local
const getAirportTariff = (airporttariffData) => {
  console.log(airporttariffData.data);
  return {
    type: GET_AIRPORT_TARIFF,
    payload: airporttariffData.data,
  };
};

// //for outstation
const getOutstationTariff = (oustationtariffData) => {
  console.log(oustationtariffData.data);
  return {
    type: GET_OUTSTATION_TARIFF,
    payload: oustationtariffData.data,
  };
};

const getLocalTariff = (localtariffData) => {
  console.log(localtariffData.data);
  return {
    type: GET_LOCAL_TARIFF,
    payload: localtariffData.data,
  };
};

export const deleteTariff = () => {
  return {
    type: DELETE_TARIFF,
  };
};

export const updateLocalAirportTariff = () => {
  return {
    type: UPDATE_LOCAL_AIRPORT_TARIFF,
  };
};

export const getAirportObj = (airporttraiffdata) => {
  return {
    type: GET_AIRPORT_TARIFF_OBJ,
    payload: airporttraiffdata,
  };
};

export const addLocalAirportTariff = () => {
  return {
    type: ADD_AIRPORT_LOCAL_TARIFF,
  };
};

export const addOutstationTariff = () => {
  return {
    type: ADD_OUTSTATION_TARIFF,
  };
};

export const addLocalTariff = () => {
  return {
    type: ADD_LOCAL_TARIFF,
  };
};

export const getSingleOutstationTariffId = (outstationtariffObj) => {
  return {
    type: GET_SINGLE_OUTSTATION_TARIFF,
    payload: outstationtariffObj,
  };
};

export const updateOutstationTariffData = () => {
  return {
    type: UPDATE_OUTSTATION_TARIFF,
  };
};

export const updateLocalTariffData = () => {
  return {
    type: UPDATE_LOCAL_TARIFF,
  };
};

export const FetchAirportTariffList = () => {
  return function (dispatch) {
    // dispatch(makeRequest());
    axios
      .get("https://api.yatricabs.com/v1/admin/tariffs/airport")
      .then((response) => {
        console.log("res", response);
        dispatch(getAirportTariff(response.data));
      })
      .catch((error) => {
        dispatch(failRequest(error.errorMsg));
      });
  };
};

export const deleteTariffId = (code) => {
  return (dispatch) => {
    dispatch(makeRequest());
    axios
      .delete("https://api.yatricabs.com/v1/admin/tariffs/" + code)
      .then((response) => {
        dispatch(deleteTariff());
      })
      .catch(function (error) {
        if (error.response) {
          console.log(
            "Server responded with status code:",
            error.response.status
          );
          console.log("Response data:", error.response.data);
        } else if (error.request) {
          console.log("No response received:", error.request);
        } else {
          console.log("Error creating request:", error.message);
        }
      });
  };
};

export const addLocalAirportTariffFun = (data) => {
  return (dispatch) => {
    dispatch(makeRequest());
    axios
      .post("https://api.yatricabs.com/v1/admin/tariffs/local-airport/", data)
      .then((response) => {
        dispatch(addLocalAirportTariff());
        console.log(response);
        alert("Tariff added successfully");
      })
      .catch((error) => {
        dispatch(failRequest(error.errorMsg));
      });
  };
};

export const updateLocalAirportTariffFun = (airportObj, id) => {
  return (dispatch) => {
    dispatch(makeRequest());
    axios
      .put(
        "https://api.yatricabs.com/v1/admin/tariffs/local-airport/" + id,
        airportObj
      )
      .then((response) => {
        dispatch(updateLocalAirportTariff());
        console.log(response);
        alert("Tariff updated successfully");
      })
      .catch(function (error) {
        if (error.response) {
          console.log(
            "Server responded with status code:",
            error.response.status
          );
          console.log("Response data:", error.response.data);
        } else if (error.request) {
          console.log("No response received:", error.request);
        } else {
          console.log("Error creating request:", error.message);
        }
      });
  };
};

export const FetchTariffAirportObj = (code) => {
  return (dispatch) => {
    dispatch(makeRequest());
    axios
      .get("https://api.yatricabs.com/v1/admin/tariffs/airport/" + code)
      .then((res) => {
        const airportlist = res.data;
        console.log(airportlist);
        dispatch(getAirportObj(airportlist));
      })
      .catch((err) => {
        dispatch(failRequest(err.message));
      });
  };
};

export const LoadOutstationTariffList = () => {
  return function (dispatch) {
    axios
      .get("https://api.yatricabs.com/v1/admin/tariffs/outstation")
      .then((response) => {
        console.log("response", response);
        dispatch(getOutstationTariff(response.data));
      })
      .catch((error) => {
        dispatch(failRequest(error.errorMsg));
      });
  };
};

export const addOutstationTariffFun = (outstationtariffObj) => {
  return (dispatch) => {
    dispatch(makeRequest());
    axios
      .post(
        "https://api.yatricabs.com/v1/admin/tariffs/outstation/",
        outstationtariffObj
      )
      .then((response) => {
        console.log("res", response);
        dispatch(addOutstationTariff());
        alert("Outstation Tariff added successfully");
      })
      .catch(function (error) {
        if (error.response) {
          console.log(
            "Server responded with status code:",
            error.response.status
          );
          console.log("Response data:", error.response.data);
        } else if (error.request) {
          console.log("No response received:", error.request);
        } else {
          console.log("Error creating request:", error.message);
        }
      });
  };
};

export const getSingleOutstationTariff = (outstationtariffObj) => {
  return (dispatch) => {
    axios
      .get("https://api.yatricabs.com/v1/admin/tariffs" + outstationtariffObj)
      .then((response) => {
        dispatch(getSingleOutstationTariffId(response.data));
        console.log(response.data);
      })
      .catch(function (error) {
        if (error.response) {
          console.log(
            "Server responded with status code:",
            error.response.status
          );
          console.log("Response data:", error.response.data);
        } else if (error.request) {
          console.log("No response received:", error.request);
        } else {
          console.log("Error creating request:", error.message);
        }
      });
  };
};

export const updateOutstationTariff = (outstationtariffObj, id) => {
  return (dispatch) => {
    axios
      .put(
        "https://api.yatricabs.com/v1/admin/tariffs/outstation/" + id,
        outstationtariffObj
      )
      .then((response) => {
        dispatch(updateOutstationTariffData());
        alert("Outstation Tariff updated successfully");
        console.log(response.data);
      })
      .catch(function (error) {
        if (error.response) {
          console.log(
            "Server responded with status code:",
            error.response.status
          );
          console.log("Response data:", error.response.data);
          alert("Response data:", error.response.data);
        } else if (error.request) {
          console.log("No response received:", error.request);
          alert("No response received:", error.request);
        } else {
          console.log("Error creating request:", error.message);
          alert("Error creating request:", error.message);
        }
      });
  };
};

// for local tariff
export const LoadLocalTariffList = () => {
  return function (dispatch) {
    axios
      .get("https://api.yatricabs.com/v1/admin/tariffs/local")
      .then((response) => {
        console.log("response", response);
        dispatch(getLocalTariff(response.data));
      })
      .catch(function (error) {
        if (error.response) {
          console.log(
            "Server responded with status code:",
            error.response.status
          );
          console.log("Response data:", error.response.data);
        } else if (error.request) {
          console.log("No response received:", error.request);
        } else {
          console.log("Error creating request:", error.message);
        }
      });
  };
};

export const addLocalTariffFun = (localtariffData) => {
  return (dispatch) => {
    axios
      .post(
        "https://api.yatricabs.com/v1/admin/tariffs/local-airport/",
        localtariffData
      )
      .then((response) => {
        console.log("res", response);
        dispatch(addLocalTariff());
        alert("Local Tariff added successfully");
      })
      .catch(function (error) {
        if (error.response) {
          console.log(
            "Server responded with status code:",
            error.response.status
          );
          console.log("Response data:", error.response.data);
        } else if (error.request) {
          console.log("No response received:", error.request);
        } else {
          console.log("Error creating request:", error.message);
        }
      });
  };
};

export const updateLocalTariff = (localtariffObj, id) => {
  return (dispatch) => {
    // dispatch(makeRequest());
    axios
      .put(
        "https://api.yatricabs.com/v1/admin/tariffs/local-airport/" + id,
        localtariffObj
      )
      .then((response) => {
        dispatch(updateLocalTariffData());
        alert("Local Tariff updated successfully");
        console.log(response.data);
      })
      .catch(function (error) {
        if (error.response) {
          console.log(
            "Server responded with status code:",
            error.response.status
          );
          console.log("Response data:", error.response.data);
        } else if (error.request) {
          console.log("No response received:", error.request);
        } else {
          console.log("Error creating request:", error.message);
        }
      });
  };
};

export const setTariffInactive = (id) => {
  return async (dispatch) => {
    try {
      await axios.put(
        `https://api.yatricabs.com/v1/admin/tariffs/${id}/status`,
        { status: "Inactive" }
      );
      console.log("Sending request to set tariff as inactive. Payload:", id);
      dispatch({ type: "SET_TARIFF_INACTIVE", payload: id });
    } catch (error) {
      console.error("Error setting tariff as inactive:", error);
      if (error.response) {
        console.log(
          "Server responded with status code:",
          error.response.status
        );
        console.log("Response data:", error.response.data);
      } else if (error.request) {
        console.log("No response received:", error.request);
      } else {
        console.log("Error creating request:", error.message);
      }
    }
  };
};

export const setTariffActive = (id) => {
  return async (dispatch) => {
    try {
      await axios.put(
        `https://api.yatricabs.com/v1/admin/tariffs/${id}/status`,
        { status: "Active" }
      );
      console.log("Sending request to set tariff as active. Payload:", id);
      dispatch({ type: "SET_TARIFF_ACTIVE", payload: id });
    } catch (error) {
      console.error("Error setting tariff as active:", error);
      if (error.response) {
        console.log(
          "Server responded with status code:",
          error.response.status
        );
        console.log("Response data:", error.response.data);
      } else if (error.request) {
        console.log("No response received:", error.request);
      } else {
        console.log("Error creating request:", error.message);
      }
    }
  };
};
