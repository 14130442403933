export const DELETE_TARIFF = 'DELETE_TARIFF'
export const ADD_AIRPORT_LOCAL_TARIFF = 'ADD_AIRPORT_LOCAL_TARIFF'
export const UPDATE_LOCAL_AIRPORT_TARIFF = 'UPDATE_LOCAL_AIRPORT_TARIFF'
export const MAKE_REQUEST='MAKE_REQUEST'
export const FAIL_REQUEST='FAIL_REQUEST'
export const GET_AIRPORT_TARIFF = 'GET_AIRPORT_TARIFF'
export const GET_AIRPORT_TARIFF_OBJ = 'GET_AIRPORT_TARIFF_OBJ'

//for outstation tariff
export const GET_OUTSTATION_TARIFF = 'GET_OUTSTATION_TARIFF'
export const ADD_OUTSTATION_TARIFF = 'ADD_OUTSTATION_TARIFF'
export const UPDATE_OUTSTATION_TARIFF = 'UPDATE_OUTSTATION_TARIFF'
export const GET_SINGLE_OUTSTATION_TARIFF = 'GET_SINGLE_OUTSTATION_TARIFF'

//for local tariff
export const GET_LOCAL_TARIFF = 'GET_LOCAL_TARIFF'
export const ADD_LOCAL_TARIFF = 'ADD_LOCAL_TARIFF'
export const UPDATE_LOCAL_TARIFF = 'UPDATE_LOCAL_TARIFF'
export const GET_SINGLE_LOCAL_TARIFF = 'GET_SINGLE_LOCAL_TARIFF'

//for inactive tariff
export const SET_TARIFF_INACTIVE ='SET_TARIFF_INACTIVE';
export const SET_TARIFF_ACTIVE = 'SET_TARIFF_ACTIVE';