import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Link,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import DropdownColumn from "../Booking/Dropdown";
import Filter from "../Booking/Filter";
import ActiveCity from "./activecity/ActiveCity";
import AddNewCity from "./AddNewCity";
import "./Geography.css";
import {
  fetchactivecity,
  fetchinactivecity,
  fetchviewallcityAsync,
  resetCompState,
  selectAllActiveCity,
  selectInActiveCity,
  selectViewAllCity,
} from "./geographySlice";
import InactiveCity from "./InactiveCity";
import ViewAllCityPage from "./ViewAllCityPage";
import { IoIosArrowDown } from "react-icons/io";

const Geography = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedCity, setSelectedCity] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [selectedAirport, setSelectedAirport] = useState("");
  const [filteredCities, setFilteredCities] = useState([]);
  const [filteredActiveCities, setFilteredActiveCities] = useState([]);
  const [filteredInactiveCities, setFilteredInactiveCities] = useState([]);

  const allcity = useSelector(selectViewAllCity);
  const activecities = useSelector(selectAllActiveCity);
  const inactivecities = useSelector(selectInActiveCity);

  const { currentComp } = useSelector((state) => state.geography);

  // Function to get unique options
  const extractOptions = (dataArray, message) => {
    if (Array.isArray(dataArray)) {
      return [...new Set(dataArray.map((item) => item[message]))].map(
        (item) => ({
          label: item,
          value: item,
        })
      );
    }
    return [];
  };
  const [filteredCityOptions, setFilteredCityOptions] = useState([]);
  // Extract unique options for states and airports
  const stateOptions = extractOptions(allcity, "state");
  const airportOptions = extractOptions(allcity, "airportName");

  useEffect(() => {
    console.log("Selected State:", selectedState);

    if (selectedState) {
      const filteredOptions = extractOptions(
        allcity.filter((city) => city.state === selectedState),
        "city"
      );
      setFilteredCityOptions(filteredOptions);
    } else {
      setFilteredCityOptions([]);
    }

    console.log("Filtered City Options:", filteredCityOptions);
  }, [selectedState, allcity]);

  useEffect(() => {
    dispatch(fetchviewallcityAsync());
    dispatch(fetchactivecity());
    dispatch(fetchinactivecity());
    if (currentComp !== null) {
      navigate(`/geography/${currentComp.toLowerCase().replace(/\s+/g, "-")}`);
      dispatch(resetCompState(null));
    } else if (
      location.pathname === "/geography" ||
      location.pathname === "/geography/"
    ) {
      navigate("/geography/view-all-city");
    }
  }, [dispatch, currentComp, navigate, location.pathname]);

  const handleGeographyButtonClick = (buttonType) => {
    navigate(`/geography/${buttonType.toLowerCase().replace(/\s+/g, "-")}`);
  };

  useEffect(() => {
    const path = location.pathname.split("/").pop().replace(/-/g, " ");
    if (path) {
      setSelectedCity("");
      setSelectedState("");
      setSelectedAirport("");
      setFilteredCities([]);
      setFilteredActiveCities([]);
      setFilteredInactiveCities([]);
    }
  }, [location.pathname]);

  useEffect(() => {
    if (allcity.length > 0) {
      const cities = allcity.filter((object) => {
        return (
          (!selectedCity || object.city === selectedCity) &&
          (!selectedState || object.state === selectedState) &&
          (!selectedAirport || object.airportName === selectedAirport)
        );
      });
      setFilteredCities(cities);
    }

    if (activecities.length > 0) {
      const cities = activecities.filter((object) => {
        return (
          (!selectedCity || object.city === selectedCity) &&
          (!selectedState || object.state === selectedState) &&
          (!selectedAirport || object.airportName === selectedAirport)
        );
      });
      setFilteredActiveCities(cities);
    }

    if (inactivecities.length > 0) {
      const cities = inactivecities.filter((object) => {
        return (
          (!selectedCity || object.city === selectedCity) &&
          (!selectedState || object.state === selectedState) &&
          (!selectedAirport || object.airportName === selectedAirport)
        );
      });
      setFilteredInactiveCities(cities);
    }
  }, [
    selectedCity,
    selectedState,
    selectedAirport,
    allcity,
    activecities,
    inactivecities,
  ]);

  const capitalizeWords = (str) => {
    return str.replace(/\b\w/g, (char) => char.toUpperCase());
  };

  return (
    <div
      className="carmangement-header"
      style={{ minWidth: "81dvw", marginTop: "10px", paddingRight: "10px" }}
    >
      <div className="booking">
        <div className="header">
          <div className="header-text">
            <Link to="/dashboard" className="h3-booking">
              Geography
            </Link>{" "}
            <IoIosArrowDown
              style={{ transform: "rotate(-90deg)" }}
              color="#f56a00"
            />
            <Link to="/geography" className="h2-booking">
              {capitalizeWords(
                location.pathname.split("/").pop().replace(/-/g, " ")
              )}
            </Link>
          </div>

          <div className="btns">
            <button
              onClick={() => handleGeographyButtonClick("View All City")}
              className={
                location.pathname.endsWith("/view-all-city")
                  ? "createButton-cars"
                  : "createButton"
              }
            >
              View All City
            </button>
            <button
              onClick={() => handleGeographyButtonClick("Active City")}
              className={
                location.pathname.endsWith("/active-city")
                  ? "createButton-cars"
                  : "createButton"
              }
            >
              Active City
            </button>
            <button
              onClick={() => handleGeographyButtonClick("Inactive City")}
              className={
                location.pathname.endsWith("/inactive-city")
                  ? "createButton-cars"
                  : "createButton"
              }
            >
              Inactive City
            </button>
            <button
              onClick={() => handleGeographyButtonClick("Add New City")}
              className={
                location.pathname.endsWith("/add-new-city")
                  ? "createButton-cars"
                  : "createButton"
              }
            >
              Add New City
            </button>
          </div>
        </div>
      </div>
      {location.pathname !== "/geography/add-new-city" && (
        <div className="dropdown-container">
          <div
            className="filter-container"
            style={{ marginTop: "0px", marginRight: "0px" }}
          >
            <div className="text">Filters</div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="my-svg"
              viewBox="0 0 14 14"
              fill="none"
            >
              <path
                d="M5.17176 6.32244V12.792C5.17176 12.8792 5.19334 12.965 5.23444 13.0412C5.27554 13.1174 5.3348 13.1815 5.40659 13.2273C5.47838 13.2732 5.56031 13.2993 5.64459 13.3031C5.72888 13.307 5.81272 13.2885 5.88814 13.2494L8.8566 11.7144C8.93867 11.6719 9.00768 11.6066 9.05591 11.5258C9.10414 11.445 9.12969 11.3519 9.12969 11.257V6.32244L13.4706 0.836331C13.5117 0.784323 13.5426 0.724434 13.5614 0.660083C13.5803 0.595731 13.5866 0.528178 13.5802 0.46128C13.5738 0.394382 13.5547 0.329449 13.524 0.270189C13.4933 0.210929 13.4516 0.158502 13.4013 0.115901C13.351 0.0733005 13.2931 0.0413609 13.2309 0.0219055C13.1687 0.0024502 13.1033 -0.00413972 13.0387 0.00251208C12.974 0.00916387 12.9112 0.0289272 12.8539 0.0606734C12.7966 0.0924196 12.7459 0.135527 12.7047 0.187535L8.25202 5.81589C8.17963 5.90741 8.14012 6.02204 8.14021 6.14029V10.9408L6.16124 11.9641V6.14029C6.16133 6.02204 6.12182 5.90741 6.04943 5.81589L2.25773 1.0236H10.1192C10.2504 1.0236 10.3762 0.969694 10.469 0.873737C10.5618 0.777781 10.6139 0.647636 10.6139 0.511933C10.6139 0.37623 10.5618 0.246085 10.469 0.150128C10.3762 0.0541719 10.2504 0.000264019 10.1192 0.000264019H1.21382C1.1201 0.000199353 1.02828 0.0276694 0.949069 0.0794759C0.869854 0.131282 0.806497 0.205294 0.766375 0.292894C0.726253 0.380494 0.711016 0.47808 0.722438 0.574288C0.73386 0.670497 0.771471 0.761371 0.830892 0.836331L5.17176 6.32244Z"
                fill="#38B000"
              />
            </svg>
          </div>

          <div style={{ position: "relative", display: "inline-block" }}>
            <select
              style={{
                display: "flex",
                width: "121px",
                height: "29px", // Increased height for better alignment
                padding: "8px 16px",
                alignItems: "center",
                gap: "4px",
                flexShrink: "0",
                borderRadius: "2px 0px 0px 2px",
                border: "1px solid #D9D9D9",
                background: "#FFF",
                appearance: "none", // Removes default dropdown arrow
                WebkitAppearance: "none",
                MozAppearance: "none",
                paddingRight: "30px", // Space for the SVG arrow
              }}
              value={selectedState}
              onChange={(e) => setSelectedState(e.target.value)}
            >
              <option value="">Search State</option>
              {stateOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="10"
              height="6"
              viewBox="0 0 10 6"
              fill="none"
              style={{
                position: "absolute",
                right: "10px",
                top: "50%",
                transform: "translateY(-50%)",
                pointerEvents: "none", // Prevent interaction with SVG
              }}
            >
              <path
                d="M9.35355 1.35355C9.54882 1.15829 9.54882 0.841709 9.35355 0.646447C9.15829 0.451184 8.84171 0.451184 8.64645 0.646447L9.35355 1.35355ZM5 5L4.64645 5.35355L5 5.70711L5.35355 5.35355L5 5ZM1.35355 0.646447L1 0.292893L0.292892 1L0.646446 1.35355L1.35355 0.646447ZM8.64645 0.646447L4.64645 4.64645L5.35355 5.35355L9.35355 1.35355L8.64645 0.646447ZM5.35355 4.64645L1.35355 0.646447L0.646446 1.35355L4.64645 5.35355L5.35355 4.64645Z"
                fill="#FA7C07"
              />
            </svg>
          </div>



		  <div style={{ position: "relative", display: "inline-block" }}>
            <select
              style={{
                display: "flex",
                width: "121px",
                height: "29px", // Increased height for better alignment
                padding: "8px 16px",
                alignItems: "center",
                gap: "4px",
                flexShrink: "0",
                borderRadius: "2px 0px 0px 2px",
                border: "1px solid #D9D9D9",
                background: "#FFF",
                appearance: "none", // Removes default dropdown arrow
                WebkitAppearance: "none",
                MozAppearance: "none",
                paddingRight: "30px", // Space for the SVG arrow
              }}
			  value={selectedCity}
            onChange={(e) => setSelectedCity(e.target.value)}
          >
            <option value="">Search City</option>
            {filteredCityOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
            </select>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="10"
              height="6"
              viewBox="0 0 10 6"
              fill="none"
              style={{
                position: "absolute",
                right: "10px",
                top: "50%",
                transform: "translateY(-50%)",
                pointerEvents: "none", // Prevent interaction with SVG
              }}
            >
              <path
                d="M9.35355 1.35355C9.54882 1.15829 9.54882 0.841709 9.35355 0.646447C9.15829 0.451184 8.84171 0.451184 8.64645 0.646447L9.35355 1.35355ZM5 5L4.64645 5.35355L5 5.70711L5.35355 5.35355L5 5ZM1.35355 0.646447L1 0.292893L0.292892 1L0.646446 1.35355L1.35355 0.646447ZM8.64645 0.646447L4.64645 4.64645L5.35355 5.35355L9.35355 1.35355L8.64645 0.646447ZM5.35355 4.64645L1.35355 0.646447L0.646446 1.35355L4.64645 5.35355L5.35355 4.64645Z"
                fill="#FA7C07"
              />
            </svg>
          </div>
        </div>
      )}

      <div>
        <Routes>
          <Route
            path="view-all-city"
            element={<ViewAllCityPage viewallcitydata={filteredCities} />}
          />
          <Route
            path="active-city"
            element={<ActiveCity data={filteredActiveCities} />}
          />
          <Route
            path="inactive-city"
            element={<InactiveCity data={filteredInactiveCities} />}
          />
          <Route path="add-new-city" element={<AddNewCity />} />
        </Routes>
      </div>
    </div>
  );
};

export default Geography;
