import React, { useState } from "react";
import "./AddCarCategory.css";
import axios from "axios";
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const AddCarCategory = () => {
  const [carFront, setcarFront] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [showCloseIconCarfront, setShowCloseIconCarfront] = useState(false);
  const [sittingCap, setSittingCap] = useState("");
  const [categoryname, setCategoryName] = useState("");
  const [luggagecap, setLuggageCap] = useState("");
  const [vehicleincludes, setVehicleIncludes] = useState("");

  const handleFileChange = (event, setFile, setShowCloseIcon) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedImage(event.target.files[0]);
      // Update the corresponding state with the selected file  and doinf some other things
      setFile(file);
      setShowCloseIcon(true);
    }
  };
  const handleRemoveFile = (setFile, setShowCloseIcon) => {
    setFile(null);
    setShowCloseIcon(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const headers = {
      // 'Content-Type': 'application/json',
      "Content-Type": "multipart/form-data",
    };
    const carCategoryObj = new FormData();
    carCategoryObj.append("category", categoryname);
    carCategoryObj.append("includes", vehicleincludes);
    carCategoryObj.append("seatingCapacity", sittingCap);
    carCategoryObj.append("luggageCapacity", luggagecap);
    //carCategoryObj.append('carPhoto', getFile);
    carCategoryObj.append("carPhoto", selectedImage);

    console.log(carCategoryObj);

    try {
      // Add a new car category
      await axios.post(
        `${API_BASE_URL}/v1/admin/car-categories`,
        carCategoryObj,
        { headers }
      );
      alert("New car category added!");
      e.target.reset();
      setcarFront(null);
      setShowCloseIconCarfront(false);
    } catch (error) {
      alert("Failed to add a new car category!", error);
      console.error(error);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div
          className="add-new-car-vehicle-scrolbar"
          style={{ height: "95vh",
            overflow:"scroll"
           }}
        >
          <div className="add-new-car-vehicle">Vehicle Information</div>
          <div className="user-details">
            <div className="user-details-section">
              <div className="user-detail">
                <div className="user-heading3">Category Name</div>
                <div>
                  <input
                    type="text"
                    className="custom-input3"
                    placeholder="Type Category Name"
                    onChange={(e) => setCategoryName(e.target.value)}
                  />
                </div>
              </div>

              <div className="user-detail">
                <div className="user-heading3">Seating Capacity</div>
                <input
                  type="text"
                  className="custom-input3"
                  placeholder="No. of seats"
                  onChange={(e) => setSittingCap(e.target.value)}
                />
              </div>
            </div>
            <div className="user-details-section">
              <div className="user-detail">
                <div className="user-heading3">Vehicle Includes</div>
                <input
                  style={{
                    width: "300px",
                  }}
                  type="text"
                  className="custom-input3"
                  placeholder="Type all vehicles name which includes"
                  onChange={(e) => setVehicleIncludes(e.target.value)}
                />
              </div>
            </div>
            <div className="user-details-section">
              <div className="user-detail">
                <div className="user-heading3">Luggage Capacity</div>
                <input
                  type="text"
                  className="custom-input3"
                  placeholder="No. of bags"
                  onChange={(e) => setLuggageCap(e.target.value)}
                />
              </div>
            </div>
          </div>


{/* car photo upload  */}
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(auto-fit, minmax(350px, 1fr))",
              gap: "20px",
              padding: "0 27px",
            }}
          >
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "100px 1fr",
                // alignItems: "center",
                gap: "px",
              }}
            >
              {/* Label Column */}
              <div
                style={{
                  fontSize: "11px",
                  fontWeight: 500,
                  display: "flex",
                  flexWrap: true,
                }}
              >
                Car Photo
              </div>

              {/* File & Button Column */}
              <div
                style={{
                  display: "grid",
                  gridTemplateRows: "auto auto",
                  gap: "5px",
                }}
              >
                {/* File Preview + Expiry Date */}
                <div
                  style={{
                    display: "flex",
                    alignItems: "start",
                    gap: "10px",
                  }}
                >
                  {/* File Preview Box */}
                  <div
                    style={{
                      width: "83px",
                      height: "89px",
                      backgroundColor: "#ddd",
                      borderRadius: "5px",
                      position: "relative",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {carFront ? (
                      <img
                        src={URL.createObjectURL(carFront)}
                        alt="Selected"
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                          borderRadius: "5px",
                        }}
                      />
                    ) : null}

                    {carFront && (
                      <span
                        onClick={() =>
                          handleRemoveFile(
                            setcarFront,
                            setShowCloseIconCarfront
                          )
                        }
                        style={{
                          position: "absolute",
                          top: "5px",
                          right: "5px",
                          color: "red",
                          fontSize: "18px",
                          fontWeight: "bold",
                          cursor: "pointer",
                        }}
                      >
                        ×
                      </span>
                    )}
                  </div>
                </div>

                {/* File Upload Button & File Name */}
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                  }}
                >
                  <label
                    style={{
                      backgroundColor: "#0A85DE",
                      color: "#fff",
                      padding: "6px 10px",
                      borderRadius: "4px",
                      cursor: "pointer",
                      fontSize: "8px",
                      marginLeft: 0,
                    }}
                  >
                    <input
                      type="file"
                      style={{ display: "none" }}
                      onChange={(e) =>
                        handleFileChange(
                          e,
                          setcarFront,
                          setShowCloseIconCarfront
                        )
                      }
                    />
                    Choose File
                  </label>

                  {/* Selected File Info */}
                  <p style={{ fontSize: "11px", color: "#666" }}>
                    {carFront ? `${carFront.name}` : "No file chosen"}
                  </p>
                </div>
              </div>
            </div>
          </div>





          {/* <div className="user-details">
            <div className="user-detail-section">
              <div className="user-detail">
                <div className="user-headin">Car Photo</div>
                <div className="dropdown">
                  <div className="pic">
                    {carFront && (
                      <img
                        className="pic-selected"
                        src={URL.createObjectURL(carFront)}
                        alt="Selected"
                      />
                    )}
                  </div>
                  <div className="dropdown-file">
                    <div className="file-container">
                      <label className="upload">
                        <input
                          type="file"
                          onChange={(e) =>
                            handleFileChange(
                              e,
                              setcarFront,
                              setShowCloseIconCarfront
                            )
                          }
                        />
                        <span>Choose File</span>
                      </label>
                      {showCloseIconCarfront && (
                        <span
                          className="close-sign"
                          onClick={() =>
                            handleRemoveFile(
                              setcarFront,
                              setShowCloseIconCarfront
                            )
                          }
                        >
                          &times;
                        </span>
                      )}
                    </div>
                    {carFront && (
                      <p className="selected">Selected File: {carFront.name}</p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div> */}

          <div style={{ display: "flex", justifyContent: "center" }}>
            <button
              style={{
                borderRadius: "5px",
                margin: "10px",
                border: "0.5px solid #38B000",
                background: "#38B000",
                width: "150px",
                padding: "6px",
                color: "#FFF",
                fontSize: "15px",
              }}
              type="submit"
            >
              Submit
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default AddCarCategory;
