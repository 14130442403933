import React, { useEffect, useState } from "react";
import Table1 from './table/table1';
import Table2 from './table/table2';
import Table3 from './table/table3';
import Table4 from './table/table4';
import Table5 from './table/table5';
import './EarningReport.css';
import { useParams } from "react-router-dom";
import axios from "axios";
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const EarningReport = () => {
  const { bookingId } = useParams();
  const [earningDetails, setEarningDetails] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchEarningDetails = async () => {
      try {
        const response = await axios.get(
          `${API_BASE_URL}/v1/admin/drivers/get-earning/${bookingId}`
        );
        setEarningDetails(response.data);
        console.log("Booking id Earning Report>>>>>>>", response.data);
        setLoading(false);
      } catch (err) {
        console.error("Error fetching earning details:", err);
        setLoading(false);
      }
    };

    if (bookingId) {
      fetchEarningDetails();
    }
  }, [bookingId]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="earning-report-container">
      <div className="scrollable-tables">
        <div className="section1">
          <Table1 data={earningDetails} />
        </div>
        <div className="section2">
          <Table2 data={earningDetails} tripType={earningDetails?.data?.tariff?.tripType} />
          <Table3 data={earningDetails} />
        </div>
        <div className="section3">
          <Table4 data={earningDetails} tripType={earningDetails?.data?.tariff?.tripType} />
          <Table5 data={earningDetails} tripType={earningDetails?.data?.tariff?.tripType} />
        </div>
      </div>
    </div>
  );
};

export default EarningReport;
