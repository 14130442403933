import { useEffect, useState } from "react";
import Header from "../components/header/Header";
import Navbar from "../components/navbar/Navbar";
import AssignSection from "../components/AssignCab&Driver/AssignSection";
import { useNavigate, useParams } from "react-router-dom";
import Clip from "../components/Buttons/Clip-button";
import NavbarContent from "../components/header2/NavbarContent";
import Scanbar from "../components/Details/Scanbar";
import "../components/AssignCab&Driver/AssignSection.css";
import scanerImg from "../assets/scanerImg.jpg";

import { useLocation } from "react-router-dom";
import golFirst from "../assets/golFirst.svg";
import line from "../assets/line.svg";
import golSecond from "../assets/golSecond.svg";



function Assign() {
  const [sidebar, setSidebar] = useState(false);
  const navigates = useNavigate();
  const location = useLocation();
  const { id } = useParams();

  // Function to handle item click
  const handleItemClick = (itemName) => {
    // Perform actions based on the clicked item
    console.log(`Clicked on item: ${itemName}`);
    // Add further logic here, such as updating state or performing other operations
  };

  // Function to handle navigation
  const navigate = (to) => {
    // Perform navigation logic, e.g., using react-router-dom
    console.log(`Navigating to: ${to}`);
    navigates(to);
    // Implement navigation logic here, e.g., using history.push() or Link components
  };
  return (
    <div className="App">
      <Header setSidebar={setSidebar} sidebar={sidebar} />
      <div className="navbar-adminInfo">
        <Navbar
          sidebar={sidebar}
          handleItemClick={handleItemClick}
          navigate={navigate}
        />

        <header>
          <div className="nevbar-booking">
            <NavbarContent />
            <Clip />
            <div className="color">
              <AssignSection />
              <div className="space-container">

                <div className="golFirst-container">
                  <img src={golFirst} alt="golFirst" />
                </div>

                <div className="line-container">
                  <img src={line} alt="line" />
                </div>

                {/* <div className="golSecond-container">
  <img src={golSecond} alt="golSecond" />
</div> */}

              </div>
              <div className="right-action-buttons">
                <div className="right-box">
                  <img
                    src={scanerImg}
                    alt="Scanner"
                  ></img>
                  <h7>Booking ID {id}</h7>
                </div>
                <div className="right-box-action">

                  <h4>Recent Action</h4>
                  <div className="Right-btn">
                    <button
                      id="cancel-btn"
                      // onClick={() => {
                      //   handleDelete(id);
                      // }}
                      className={
                        location.pathname.endsWith("/assign/cab")
                          ? "greenButton"
                          : "whiteButton"
                      }
                    >
                      Cancel
                    </button>
                    <button id="broadcast-btn" className={
                      location.pathname.endsWith("/assign/cab")
                        ? "greenButton"
                        : "whiteButton"
                    }>
                      Modify Booking
                    </button>
                    <button id="assign-btn" className={
                      location.pathname.endsWith("/assign/cab")
                        ? "whiteButton"
                        : "greenButton"
                    }>
                      Broadcast Ride
                    </button>
                    <button id="assign-btn"
                      // onClick={handleAssignAndCabDriver}  
                      className={
                        location.pathname.endsWith("/assign/cab")
                          ? "whiteButton"
                          : "greenButton"
                      }>
                      Assign Driver & Cab
                    </button>


                  </div>
                </div>
                <Scanbar />
              </div>
            </div>
          </div>
        </header>
      </div>
    </div>
  );
}

export default Assign;
