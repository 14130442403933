import React, { useState } from "react";

const CheckboxColumn = ({ checked, handleChange, label, className }) => {
  return (
    <>
      <input
        type="checkbox"
        className={className}
        checked={checked}
        onChange={handleChange}
      />
      <label htmlFor="check-box" className="bottom-label1">
        {label}
      </label>
    </>
  );
};

const InputColumn = ({
  title,
  type,
  name,
  id,
  selectedValue,
  handleChange,
  placeholder,
  style,
  className,
  isChecked,
}) => {
  const handleInputChange = (e) => {
    // If the checkbox is checked, enable the input field
    if (isChecked) {
      handleChange(e.target.value);
    }
  };
  return (
    <div>
      <div className="title">{title}</div>

      <input
        type={type}
        value={selectedValue}
        onChange={handleInputChange}
        className={className}
        style={style}
        name={name}
        id={id}
        placeholder={placeholder}
        disabled={!isChecked}
      />
    </div>
  );
};

const Localgst = (props) => {
  const { gstValue, setGstValue, flightValue, setFlightValue, setComments } =
    props;
  const [gstChecked, setGstChecked] = useState(false);
  const [flightChecked, setFlightChecked] = useState(false);

  const handleGstChange = (value) => {
    setGstValue(value);
  };

  const handleFlightChange = (value) => {
    setFlightValue(value);
  };

  return (
    <>
      <div>
        <div className="create-new-booling-check-box">
          <div className="row bottom-inp-check">
            <div className="col inp-check">
              <div className="col-gst-1">
                <CheckboxColumn
                  label="GST"
                  className="check-box-gst-1"
                  checked={gstChecked}
                  handleChange={(e) => setGstChecked(e.target.checked)}
                />
              </div>

              <InputColumn
                type="text"
                className="book-input-round"
                isChecked={gstChecked}
                selectedValue={gstValue}
                handleChange={handleGstChange}
              />
            </div>
          </div>

          <div className="col inp-check" style={{ marginLeft: "60px" }}>
            <div className="col-flight-1">
              <CheckboxColumn
                label="Flight"
                className="check-box-flight-1"
                checked={flightChecked}
                handleChange={(e) => setFlightChecked(e.target.checked)}
              />

              <InputColumn
                type="text"
                className="book-input-round-2"
                isChecked={flightChecked}
                selectedValue={flightValue}
                handleChange={handleFlightChange}
              />
            </div>
          </div>
        </div>

        <div className="row">
          <textarea
            type="text"
            placeholder="Comment here for any specific request for ride :"
            className="book-input-comment"
            onChange={(e) => setComments(e.target.value)}
          />
        </div>
      </div>
     
    </>
  );
};

export default Localgst;
