import axios from "axios";
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import toast, { Toaster } from "react-hot-toast";
import useSuggestionsAirport from "../../hooks/useSuggestionsAirport";
import useSuggestionsCity from "../../hooks/useSuggestionsIndianCities";
import { generateTimeOptions } from "../../utils/time/time";
import DropdownColumn from "./Dropdown";
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const Totheairport = (props) => {
  const {
    selectAirport,
    setSelectAirport,
    selectedLocation,
    setSelectedLocation,
    pickUpDate,
    setPickUpDate,
    pickUpTime,
    setPickUpTime,
    cabType,
    setCabType,
    selectedCabType,
    setSelectedCabType,
    setSelectTemp,
    setSelectedTariffId,
    setCarCategoriesDetails,
  } = props;

  const [citySuggestions] = useSuggestionsCity();
  const [suggestions] = useSuggestionsAirport();
  const [showAirpotSuggestion, setShowAirpotSuggestion] = useState(false);

  const extractCarCategoriesAsOptions = (dataArray) => {
    if (Array.isArray(dataArray.data)) {
      return dataArray.data.map((item) => ({
        label: item.carCategory.category,
        value: item.carCategory.category,
      }));
    }
    return [];
  };
  const handleselectedlocation = async (e) => {
    setSelectedLocation(e.target.value);
    // if (location.trim() === "") {
    //   setCabType([]);
    //   return;
    // }
  };

  const subTripType = "To The Airport";

  const handleChange = (e) => {
    setPickUpTime(e.target.value);
  };

  useEffect(() => {
    if (selectedLocation && subTripType && pickUpTime) {
      const fetchCars = async () => {
        try {
          const response = await axios.get(
           `${API_BASE_URL}/v1/users/select-cars/airport?source=${selectedLocation}&subTripType=${subTripType}&time=${pickUpTime}`
          );

          console.log(response.data.data);

          const carCategoryOptions = extractCarCategoriesAsOptions(
            response.data
          );
          setCabType(carCategoryOptions);

          const temp = response.data.tempDataId;
          setSelectTemp(temp);
          console.log(temp);
        } catch (error) {
          toast.error(error.response.data.message);
          console.error("Error fetching cars:", error);
          setCabType([]);
        }
      };

      fetchCars();
    }
  }, [pickUpTime, selectedLocation, subTripType]);

  const handleChangeCabType = async (e) => {
    const cabType = e.target.value;
    setSelectedCabType(cabType);
    const subTripType = "To The Airport";

    console.log(selectedLocation, cabType, subTripType);

    try {
      const response = await axios.get(
       `${API_BASE_URL}/v1/users/select-cars/airport?source=${selectedLocation}&subTripType=${subTripType}&time=${pickUpTime}`
      );
      console.log(response.data.data);
      const carCategories = response.data.data.filter(
        (item) => item.carCategory.category === cabType
      );

      setCarCategoriesDetails(carCategories);

      console.log(carCategories);
      carCategories.forEach((item) => {
        setSelectedTariffId(item._id);
      });
    } catch (error) {
      console.error("Error fetching cars:", error);
    }
  };

  return (
    <>
      <Toaster />
      <div className="row">
        <div className="user-booking select">
          <label className="title-name">Select City</label>
          <input
            type="text"
            id="from"
            className="custom-input"
            autoComplete="off"
            placeholder="City Name"
            value={selectedLocation}
            onChange={(e) => handleselectedlocation(e)}
          />
          <div className="to-the-airport-city">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="15"
              height="17"
              viewBox="0 0 15 17"
              fill="none"
            >
              <g filter="url(#filter0_d_945_1137)">
                <path
                  d="M7.93203 8.79777C8.86719 7.66632 11 4.92407 11 3.38376C11 1.51564 9.43229 0 7.5 0C5.56771 0 4 1.51564 4 3.38376C4 4.92407 6.13281 7.66632 7.06797 8.79777C7.29219 9.06741 7.70781 9.06741 7.93203 8.79777ZM7.5 2.25584C7.80942 2.25584 8.10617 2.37467 8.32496 2.5862C8.54375 2.79772 8.66667 3.08461 8.66667 3.38376C8.66667 3.6829 8.54375 3.96979 8.32496 4.18132C8.10617 4.39284 7.80942 4.51168 7.5 4.51168C7.19058 4.51168 6.89383 4.39284 6.67504 4.18132C6.45625 3.96979 6.33333 3.6829 6.33333 3.38376C6.33333 3.08461 6.45625 2.79772 6.67504 2.5862C6.89383 2.37467 7.19058 2.25584 7.5 2.25584Z"
                  fill="#7C7A7A"
                />
              </g>
              <defs>
                <filter
                  id="filter0_d_945_1137"
                  x="0"
                  y="0"
                  width="15"
                  height="17"
                  filterUnits="userSpaceOnUse"
                  color-interpolation-filters="sRGB"
                >
                  <feFlood flood-opacity="0" result="BackgroundImageFix" />
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  />
                  <feOffset dy="4" />
                  <feGaussianBlur stdDeviation="2" />
                  <feComposite in2="hardAlpha" operator="out" />
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                  />
                  <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_945_1137"
                  />
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_945_1137"
                    result="shape"
                  />
                </filter>
              </defs>
            </svg>
          </div>
          {
            <div className="dropdown-option  to-the-airport-drop-option">
              {citySuggestions
                .filter((suggestion) =>
                  suggestion
                    .toLowerCase()
                    .includes(selectedLocation.toLowerCase())
                )
                .slice(0, 6)
                .map((suggestion, index) => {
                  const isMatch =
                    suggestion
                      .toLowerCase()
                      .indexOf(selectedLocation.toLowerCase()) > -1;
                  return (
                    <div key={index}>
                      {isMatch &&
                        selectedLocation &&
                        !citySuggestions.includes(selectedLocation) && (
                          <div
                            className="suggestion"
                            onClick={() => {
                              setSelectedLocation(suggestion);
                              handleselectedlocation({
                                target: { value: suggestion },
                              });
                            }}
                          >
                            {suggestion}
                          </div>
                        )}
                    </div>
                  );
                })}
            </div>
          }
        </div>

        <div className="user-booking select">
          <label className="title-name">Select Airport</label>
          <input
            type="text"
            id="from"
            className="custom-input"
            autoComplete="off"
            placeholder="Airport Name"
            value={selectAirport}
            onChange={(e) => {
              setSelectAirport(e.target.value);
              setShowAirpotSuggestion(true);
            }}
          />
          <div className="from-the-airport-location">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="15"
              height="17"
              viewBox="0 0 15 17"
              fill="none"
            >
              <g filter="url(#filter0_d_945_1131)">
                <path
                  d="M7.93203 8.79777C8.86719 7.66632 11 4.92407 11 3.38376C11 1.51564 9.43229 0 7.5 0C5.56771 0 4 1.51564 4 3.38376C4 4.92407 6.13281 7.66632 7.06797 8.79777C7.29219 9.06741 7.70781 9.06741 7.93203 8.79777ZM7.5 2.25584C7.80942 2.25584 8.10617 2.37467 8.32496 2.5862C8.54375 2.79772 8.66667 3.08461 8.66667 3.38376C8.66667 3.6829 8.54375 3.96979 8.32496 4.18132C8.10617 4.39284 7.80942 4.51168 7.5 4.51168C7.19058 4.51168 6.89383 4.39284 6.67504 4.18132C6.45625 3.96979 6.33333 3.6829 6.33333 3.38376C6.33333 3.08461 6.45625 2.79772 6.67504 2.5862C6.89383 2.37467 7.19058 2.25584 7.5 2.25584Z"
                  fill="#7C7A7A"
                />
              </g>
              <defs>
                <filter
                  id="filter0_d_945_1131"
                  x="0"
                  y="0"
                  width="15"
                  height="17"
                  filterUnits="userSpaceOnUse"
                  color-interpolation-filters="sRGB"
                >
                  <feFlood flood-opacity="0" result="BackgroundImageFix" />
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  />
                  <feOffset dy="4" />
                  <feGaussianBlur stdDeviation="2" />
                  <feComposite in2="hardAlpha" operator="out" />
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                  />
                  <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_945_1131"
                  />
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_945_1131"
                    result="shape"
                  />
                </filter>
              </defs>
            </svg>
          </div>
          {showAirpotSuggestion && (
            <div className="dropdown-option  from-the-drop-option-city">
              {citySuggestions
                .filter((suggestion) =>
                  suggestion.toLowerCase().includes(selectAirport.toLowerCase())
                )
                .slice(0, 6)
                .map((suggestion, index) => {
                  const isMatch =
                    suggestion
                      .toLowerCase()
                      .indexOf(selectAirport.toLowerCase()) > -1;
                  return (
                    <div key={index}>
                      {isMatch &&
                        selectAirport &&
                        !suggestions.includes(selectAirport) && (
                          <div
                            className="suggestion"
                            onClick={() => {
                              setSelectAirport(suggestion);
                              setShowAirpotSuggestion(false);
                            }}
                          >
                            {suggestion}
                          </div>
                        )}
                    </div>
                  );
                })}
            </div>
          )}
        </div>
        <div className="user-booking">
          <label className="title-name">Pick Up Date</label>
          <DatePicker
            selected={pickUpDate}
            dateFormat="dd-MM-yyyy"
            autoComplete="off"
            onChange={(date) => {
              setPickUpDate(date);
            }}
            className="custom-input"
            // isClearable
            minDate={new Date()}
            closeOnScroll={true}
            placeholderText="Select Pick Up Date"
            
          />
          <div className="to-the-airport-date">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="17"
              height="17"
              viewBox="0 0 17 17"
              fill="none"
            >
              <g filter="url(#filter0_d_945_1098)">
                <path
                  d="M6.57143 0C6.92701 0 7.21429 0.251367 7.21429 0.5625V1.125H9.78571V0.5625C9.78571 0.251367 10.073 0 10.4286 0C10.7842 0 11.0714 0.251367 11.0714 0.5625V1.125H12.0357C12.5681 1.125 13 1.50293 13 1.96875V2.8125H4V1.96875C4 1.50293 4.43192 1.125 4.96429 1.125H5.92857V0.5625C5.92857 0.251367 6.21585 0 6.57143 0ZM4 3.375H13V8.15625C13 8.62207 12.5681 9 12.0357 9H4.96429C4.43192 9 4 8.62207 4 8.15625V3.375ZM5.28571 4.78125V5.34375C5.28571 5.49844 5.43036 5.625 5.60714 5.625H6.25C6.42679 5.625 6.57143 5.49844 6.57143 5.34375V4.78125C6.57143 4.62656 6.42679 4.5 6.25 4.5H5.60714C5.43036 4.5 5.28571 4.62656 5.28571 4.78125ZM7.85714 4.78125V5.34375C7.85714 5.49844 8.00179 5.625 8.17857 5.625H8.82143C8.99821 5.625 9.14286 5.49844 9.14286 5.34375V4.78125C9.14286 4.62656 8.99821 4.5 8.82143 4.5H8.17857C8.00179 4.5 7.85714 4.62656 7.85714 4.78125ZM10.75 4.5C10.5732 4.5 10.4286 4.62656 10.4286 4.78125V5.34375C10.4286 5.49844 10.5732 5.625 10.75 5.625H11.3929C11.5696 5.625 11.7143 5.49844 11.7143 5.34375V4.78125C11.7143 4.62656 11.5696 4.5 11.3929 4.5H10.75ZM5.28571 7.03125V7.59375C5.28571 7.74844 5.43036 7.875 5.60714 7.875H6.25C6.42679 7.875 6.57143 7.74844 6.57143 7.59375V7.03125C6.57143 6.87656 6.42679 6.75 6.25 6.75H5.60714C5.43036 6.75 5.28571 6.87656 5.28571 7.03125ZM8.17857 6.75C8.00179 6.75 7.85714 6.87656 7.85714 7.03125V7.59375C7.85714 7.74844 8.00179 7.875 8.17857 7.875H8.82143C8.99821 7.875 9.14286 7.74844 9.14286 7.59375V7.03125C9.14286 6.87656 8.99821 6.75 8.82143 6.75H8.17857ZM10.4286 7.03125V7.59375C10.4286 7.74844 10.5732 7.875 10.75 7.875H11.3929C11.5696 7.875 11.7143 7.74844 11.7143 7.59375V7.03125C11.7143 6.87656 11.5696 6.75 11.3929 6.75H10.75C10.5732 6.75 10.4286 6.87656 10.4286 7.03125Z"
                  fill="#7C7A7A"
                />
              </g>
              <defs>
                <filter
                  id="filter0_d_945_1098"
                  x="0"
                  y="0"
                  width="17"
                  height="17"
                  filterUnits="userSpaceOnUse"
                  color-interpolation-filters="sRGB"
                >
                  <feFlood flood-opacity="0" result="BackgroundImageFix" />
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  />
                  <feOffset dy="4" />
                  <feGaussianBlur stdDeviation="2" />
                  <feComposite in2="hardAlpha" operator="out" />
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                  />
                  <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_945_1098"
                  />
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_945_1098"
                    result="shape"
                  />
                </filter>
              </defs>
            </svg>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="user-booking">
          <label className="title-name">Pick Up Time</label>
          <div className="to-airport-time-dropdown">
            <DropdownColumn
              placeholder="Select Time"
              options={generateTimeOptions(pickUpDate).map((option) => ({
                label: option,
                value: option,
              }))}
              selectedValue={pickUpTime}
              handleChange={(e) => handleChange(e)}
              className="dropdown"
              useInlineStyle={true}
              autoComplete="off"
              required
            />
          </div>
          <div className="to-the-airport-time">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="17"
              height="17"
              viewBox="0 0 17 17"
              fill="none"
            >
              <g filter="url(#filter0_d_945_1098)">
                <path
                  d="M6.57143 0C6.92701 0 7.21429 0.251367 7.21429 0.5625V1.125H9.78571V0.5625C9.78571 0.251367 10.073 0 10.4286 0C10.7842 0 11.0714 0.251367 11.0714 0.5625V1.125H12.0357C12.5681 1.125 13 1.50293 13 1.96875V2.8125H4V1.96875C4 1.50293 4.43192 1.125 4.96429 1.125H5.92857V0.5625C5.92857 0.251367 6.21585 0 6.57143 0ZM4 3.375H13V8.15625C13 8.62207 12.5681 9 12.0357 9H4.96429C4.43192 9 4 8.62207 4 8.15625V3.375ZM5.28571 4.78125V5.34375C5.28571 5.49844 5.43036 5.625 5.60714 5.625H6.25C6.42679 5.625 6.57143 5.49844 6.57143 5.34375V4.78125C6.57143 4.62656 6.42679 4.5 6.25 4.5H5.60714C5.43036 4.5 5.28571 4.62656 5.28571 4.78125ZM7.85714 4.78125V5.34375C7.85714 5.49844 8.00179 5.625 8.17857 5.625H8.82143C8.99821 5.625 9.14286 5.49844 9.14286 5.34375V4.78125C9.14286 4.62656 8.99821 4.5 8.82143 4.5H8.17857C8.00179 4.5 7.85714 4.62656 7.85714 4.78125ZM10.75 4.5C10.5732 4.5 10.4286 4.62656 10.4286 4.78125V5.34375C10.4286 5.49844 10.5732 5.625 10.75 5.625H11.3929C11.5696 5.625 11.7143 5.49844 11.7143 5.34375V4.78125C11.7143 4.62656 11.5696 4.5 11.3929 4.5H10.75ZM5.28571 7.03125V7.59375C5.28571 7.74844 5.43036 7.875 5.60714 7.875H6.25C6.42679 7.875 6.57143 7.74844 6.57143 7.59375V7.03125C6.57143 6.87656 6.42679 6.75 6.25 6.75H5.60714C5.43036 6.75 5.28571 6.87656 5.28571 7.03125ZM8.17857 6.75C8.00179 6.75 7.85714 6.87656 7.85714 7.03125V7.59375C7.85714 7.74844 8.00179 7.875 8.17857 7.875H8.82143C8.99821 7.875 9.14286 7.74844 9.14286 7.59375V7.03125C9.14286 6.87656 8.99821 6.75 8.82143 6.75H8.17857ZM10.4286 7.03125V7.59375C10.4286 7.74844 10.5732 7.875 10.75 7.875H11.3929C11.5696 7.875 11.7143 7.74844 11.7143 7.59375V7.03125C11.7143 6.87656 11.5696 6.75 11.3929 6.75H10.75C10.5732 6.75 10.4286 6.87656 10.4286 7.03125Z"
                  fill="#7C7A7A"
                />
              </g>
              <defs>
                <filter
                  id="filter0_d_945_1098"
                  x="0"
                  y="0"
                  width="17"
                  height="17"
                  filterUnits="userSpaceOnUse"
                  color-interpolation-filters="sRGB"
                >
                  <feFlood flood-opacity="0" result="BackgroundImageFix" />
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  />
                  <feOffset dy="4" />
                  <feGaussianBlur stdDeviation="2" />
                  <feComposite in2="hardAlpha" operator="out" />
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                  />
                  <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_945_1098"
                  />
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_945_1098"
                    result="shape"
                  />
                </filter>
              </defs>
            </svg>
          </div>
        </div>

        <div className="user-booking  to-the-airport-cab-title">
          <label className="title-name">Cab Type</label>
          <div className="to-airport-cab-dropdown">
            <DropdownColumn
              placeholder="Vehicle Type"
              options={cabType}
              selectedValue={selectedCabType}
              handleChange={handleChangeCabType}
              className="dropdown"
              useInlineStyle={true}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Totheairport;
