import React from "react";
import "./FareDetails.css";

const FareDetails_Airport = ({ fareDetails }) => {
	if (!fareDetails) {
		return (
			<>
				<div className="fd-details-container">
					<div className="head-title">
						<p>Fare Details</p>
					</div>

					<div className="subheader-strong-container">
						<p>Ride Estimate</p>
						<div>
							<b>Rs. 0.00</b>
							<p>(incl. of all taxes)</p>
						</div>
					</div>

					<div className="row-flexer">
						<p>Included Hrs</p>
						<p>0 Hrs</p>
					</div>

					<div className="row-flexer">
						<p>Included KM</p>
						<p>0 KM</p>
					</div>

					<div className="row-flexer">
						<p>Base Fare</p>
						<p>Rs. 0.00</p>
					</div>

					<div className="row-flexer">
						<p>GST @ 5%</p>
						<p>Rs. 0.00</p>
					</div>
				</div>
			</>
		);
	}
	return (
		<>
			<div className="fd-details-container">
				<div className="head-title">
					<p>Fare Details</p>
				</div>

				<div className="subheader-strong-container">
					<p>Ride Estimate</p>
					<div>
						<b>Rs. {fareDetails?.fareDetails?.estimatedFare.toFixed(2)}</b>
						<p>(incl. of all taxes)</p>
					</div>
				</div>

				<div className="row-flexer">
					<p>Included Hrs</p>
					<p>{fareDetails?.fareDetails?.minHrs} Hrs</p>
				</div>

				<div className="row-flexer">
					<p>Included Km </p>
					<p>{fareDetails?.rideDistance} Km</p>
				</div>

				<div className="row-flexer">
					<p>Base Fare</p>
					<p>Rs. {fareDetails?.fareDetails?.baseFare.toFixed(2)}</p>
				</div>

				<br />

				{fareDetails?.dayDriverAllowance > 0 && (
					<div className="row-flexer">
						<p>Driver Allowance </p>
						<p>Rs. {fareDetails?.dayDriverAllowance?.toFixed(2)}</p>
					</div>
				)}

				{fareDetails?.nightAllowance > 0 && (
					<div className="row-flexer">
						<p>Night Charge </p>
						<p>Rs. {fareDetails?.nightAllowance?.toFixed(2)}</p>
					</div>
				)}

				{fareDetails?.morningAllowance > 0 && (
					<div className="row-flexer">
						<p>Morning Charge </p>
						<p>Rs. {fareDetails?.morningAllowance?.toFixed(2)}</p>
					</div>
				)}
				<div className="row-flexer">
					<p>GST @ {fareDetails?.fareDetails?.gstRate}%</p>
					<p>Rs. {fareDetails?.fareDetails?.gstAmount.toFixed(2)}</p>
				</div>
			</div>

			<div className="info-container">
				<div className="infohead">Inclusions</div>
				<div className="info-wrapper">
					<p>
						Base Fare(Include {fareDetails?.fareDetails?.minKm} Km{" "}
						{fareDetails?.fareDetails?.minHrs} Hrs)
					</p>
					{fareDetails?.nightAllowance > 0 && (
						<p>
							Night Charge Rs.{fareDetails?.fareDetails?.nightDriverAllowance}
						</p>
					)}
					{fareDetails?.morningAllowance > 0 && (
						<p>
							Morning Charge Rs.
							{fareDetails?.fareDetails?.morningDriverAllowance}
						</p>
					)}

					<p>GST ({fareDetails?.fareDetails?.gstRate}%)</p>
				</div>
			</div>

			<div className="info-container">
				<div className="infohead">Exclusion</div>
				<div className="info-wrapper">
					<p>
						Pay ₹{fareDetails?.fareDetails?.perKmCharge}/km after{" "}
						{fareDetails?.fareDetails?.minKm} km{" "}
					</p>
					{fareDetails?.nightAllowance == 0 && (
						<p>
							Night Charge Rs.{fareDetails?.fareDetails?.nightDriverAllowance}
						</p>
					)}
					{fareDetails?.morningAllowance == 0 && (
						<p>
							Morning Charge Rs.
							{fareDetails?.fareDetails?.morningDriverAllowance}
						</p>
					)}

					<p>
						Pay ₹{fareDetails?.fareDetails?.extraHrsCharge}/hrs after{" "}
						{fareDetails?.fareDetails?.minHrs}hrs
					</p>
					<p>Toll / State tax / Parking</p>
				</div>
			</div>
		</>
	);
};

export default FareDetails_Airport;
