import React from "react";
import { AiOutlineRight } from "react-icons/ai";
import { Link } from "react-router-dom";
import "./Navbar.css";
import { sideBar } from "../../utils/SideBar";
import { useState, useEffect } from "react";

const Navbar = ({ sidebar, handleItemClick, navigate }) => {
  //  {/*if (!sidebar) {
  //     return (
  //       <div className="relative-part">
  //         <div className="nav-part">
  //           {sideBar.map((s, index) => {
  //             return (
  //               <Link
  //                 to={s.to}
  //                 key={index}
  //                 onClick={() => handleItemClick(s.name)}
  //                 className="link-style"
  //               >
  //                 <div className="part" key={index}>
  //                   <div className="sub-part">
  //                     <p className="image-size">{s.src}</p>
  //                     <p className="para-font">{s.name}</p>
  //                   </div>
  //                   <AiOutlineRight className="outline-right" />
  //                 </div>
  //               </Link>
  //             );
  //           })}
  //         </div>
  //       </div>
  //     );
  //    } else {
  //     return (
  //      <div className="false-part">
  //         {sideBar.map((s, index) => {
  //           return (
  //             <Link to={s.to} onClick={() => handleItemClick(s.name)}>
  //               <div className="without-name" key={index}>
  //                 <div className="false-subpart">{s.src}</div>
  //               </div>
  //             </Link>
  //           );
  //         })}

  //       </div>

  //     )
  //     }*/}
  const [selectedItem, setSelectedItem] = useState("");
  useEffect(() => {
    const savedSelection = localStorage.getItem("selectedNavItem");
    const currentPath = window.location.pathname;
  
    const matchedItem = sideBar.find((item) => item.to === currentPath);
    if (matchedItem) {
      setSelectedItem(matchedItem.name);
      localStorage.setItem("selectedNavItem", matchedItem.name);
    } else if (savedSelection) {
      setSelectedItem(savedSelection);
    }
  }, []);
  
  const handleNavItemClick = (itemName) => {
    setSelectedItem(itemName);
    localStorage.setItem("selectedNavItem", itemName);
    handleItemClick(itemName);
  };

  return (
    <div className="relative-part">
      <div className="nav-part">
        {sideBar.map((s, index) => (
          <Link
            to={s.to}
            key={index}
            onClick={() => handleNavItemClick(s.name)}
            className="link-style"
          >
            <div
              className={`${selectedItem === s.name ? "selected" : "part"}`}
              key={index}
            >
              <div className="sub-part">
                <p className="image-size">{s.src}</p>
                <p className="para-font">{s.name}</p>
              </div>
              <AiOutlineRight className="outline-right" />
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default Navbar;
