import React from 'react'
import "./ViewCustomerDetails.css";
import logo from "./../../assets/logo.png";

function CustomerInvoice(props) {
 return (
    <> 
       (
                  <div className="invoice-overlay">
                  <div className="invoice-popup-main">
                    <div className="invice-popup-header">
                    {/* Your invoice content goes here */}
                    {/* You can use selectedInvoiceItem to display relevant details */}
                    <button
                      className="close-invoice-2"
                      onClick={() => props.setShowInvoicePopup(false)}
                    >
                      X
                    </button>

                    <div className="invoice-popup-content1">
                      <div className="invoice-popup-left">
                        <img src={logo} alt="" className="logo" />
                        <div className="invoice-popup-left-two">
                          Name: {props.userDetails.firstName} {props.userDetails.lastName} <br />
                          Phone: {props.userDetails.phone} <br />
                          Email: {props.userDetails.mail} <br /> GST: N/A{" "}
                        </div>
                      </div>

                      <div className="invoice-popup-center">
                        <div className="invoice-popup-center-one">
                          Tax Invoice
                        </div>
                        <div className="invoice-popup-center-two">
                          For <br /> Taxi Service <br /> HSN Code: 996411{" "}
                        </div>
                      </div>

                      <div className="invoice-popup-right">
                        <div className="invoice-popup-right-title">
                          AARSAAR Technologies Pvt Ltd,
                          <br />
                          D-2063, D Block,
                          <br />
                          Indira Nagar, Lucknow,
                          <br />
                          Uttar Pradesh, India - 226016 <br />{" "}
                          <b>GST No.: 06AAJCC2343N1ZI</b>
                          <br />
                          <br />
                          Invoice: <b> N/A</b> <br /> Date:{" "}
                          <b>{props.selectedInvoiceItem["Created At"]}</b>
                        </div>
                      </div>
                    </div>
                    </div>
                    <div className="invoice-popup-content2">
                      <div className="invoice-popup-left">
                        <div className="head-price">Date</div>
                      </div>
                      <div className="invoice-popup-center">
                        <div className="head-price">Description</div>
                      </div>

                      <div className="invoice-popup-right">
                        <div className="head-price">Amount (Rs.)</div>
                      </div>
                    </div>
                    <hr className="line-break" />

                    {props.selectedInvoiceItem["Trip Type"] === "Local" && (
                      <div className="invoice-popup-content3">
                        <div className="invoice-popup-left">
                          <div className="invoice-popup-left">{props.selectedInvoiceItem["Pickup Date"]}</div>
                        </div>
                        <div className="invoice-popup-center" style={{marginLeft:"9rem"}}>
                          <h1>Taxi for<br></br>{props.selectedInvoiceItem["Pickup City"]} - {props.selectedInvoiceItem["Destination"]}<br></br> for {props.selectedInvoiceItem["Trip Type"]} - Est (Time & Km) - {props.selectedInvoiceItem["Sub Trip Type"]}</h1>

                          <h1>Extra Km charge</h1>
                  
                          <h1>Extra Hrs charge</h1>
                    
                          <h1>Sub Total</h1>
                  
                          <h1>GST (@ 5%)</h1>
                         
                          <h1>Toll tax and State tax extra</h1>
                        </div>
                        <div className="invoice-popup-right-out">
                        <br />
                        
                          {props.selectedInvoiceItem["Est  Fare"]}
                          <br />
                          <br />
                          {props.selectedInvoiceItem["Extra Km Charge"]}
                          <br /> <br />
                          {props.selectedInvoiceItem["Extra hrs Charge"]}
                          <br /> <br />
                            N/A
                          <br /> <br />
                          {props.selectedInvoiceItem["Gst on Extra"]}
                          <br />
                          <br />
                          N/A
                          <br />
                          <br />
                          <br />
                          <div className="row-customer-invoice">
                          <div className="row-customer-inv-row">
                            <div className="row1">
                              <div className="item1"> Total: </div>
                              <div className="item2">
                                {" "}
                                {props.selectedInvoiceItem["Billed Amount"]}
                                <div>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="70"
                                    height="7"
                                    viewBox="0 0 70 7"
                                    fill="none"
                                  >
                                    <line
                                      y1="1"
                                      x2="70"
                                      y2="1"
                                      stroke="black"
                                    />
                                    <line
                                      y1="6"
                                      x2="70"
                                      y2="6"
                                      stroke="black"
                                    />
                                  </svg>
                                </div>
                              </div>
                            </div>
                            </div>
                            <br />

                            <div className="row-customer-invoice">
                              <div className="row1">
                                <div className="item4">
                                  {" "}
                                  Advance Amount Paid:{" "}
                                </div>
                                <div className="item4"> 257.00</div>
                              </div>
                            </div>

                            <div className="row-customer-invoice">
                              <div className="row1">
                                <div className="item4"> Balance Paid: </div>
                                <div className="item4"> N/A</div>
                              </div>
                            </div>

                            <div className="row-customer-invoice">
                              <div className="row1">
                                <div className="item1"> Total Paid: </div>
                                <div className="item2">
                                  {" "}
                                  {props.selectedInvoiceItem["Billed Amount"]}
                                  <div>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="70"
                                      height="7"
                                      viewBox="0 0 70 7"
                                      fill="none"
                                    >
                                      <line
                                        y1="1"
                                        x2="70"
                                        y2="1"
                                        stroke="black"
                                      />
                                      <line
                                        y1="6"
                                        x2="70"
                                        y2="6"
                                        stroke="black"
                                      />
                                    </svg>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        
                    </div>
                    )}

                    {props.selectedInvoiceItem["Trip Type"] === "Airport" && (
                      <div className="invoice-popup-content3">
                        <div className="invoice-popup-left">
                          <div className="invoice-popup-left">{props.selectedInvoiceItem["Pickup Date"]}</div>
                        </div>
                        
                        <div className="invoice-popup-center" style={{marginLeft:"9rem"}}>
                          <h1>Taxi for<br></br>{props.selectedInvoiceItem["Pickup City"]} - {props.selectedInvoiceItem["Destination"]}<br></br> for {props.selectedInvoiceItem["Trip Type"]} - Est (Time & Km) - {props.selectedInvoiceItem["Sub Trip Type"]}</h1>

                          <h1>Extra Km charge</h1>
                  
                          <h1>Extra Hrs charge</h1>
                    
                          <h1>Sub Total</h1>
                  
                          <h1>GST (@ 5%)</h1>
                         
                          <h1>Toll tax and State tax extra</h1>
                        </div>

                        <div className="invoice-popup-right-out">
                        <br />
                        
                          {props.selectedInvoiceItem["Est  Fare"]}
                          <br />
                          <br />
                          {props.selectedInvoiceItem["Extra Km Charge"]}
                          <br /> <br />
                          {props.selectedInvoiceItem["Extra hrs Charge"]}
                          <br /> <br />
                            N/A
                          <br /> <br />
                          {props.selectedInvoiceItem["Gst on Extra"]}
                          <br />
                          <br />
                          N/A
                          <br />
                          <br />
                          <br />
                          <div className="row-customer-invoice">
                          <div className="row-customer-inv-row">
                            <div className="row1">
                              <div className="item1"> Total: </div>
                              <div className="item2">
                                {" "}
                                {props.selectedInvoiceItem["Billed Amount"]}
                                <div>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="70"
                                    height="7"
                                    viewBox="0 0 70 7"
                                    fill="none"
                                  >
                                    <line
                                      y1="1"
                                      x2="70"
                                      y2="1"
                                      stroke="black"
                                    />
                                    <line
                                      y1="6"
                                      x2="70"
                                      y2="6"
                                      stroke="black"
                                    />
                                  </svg>
                                </div>
                              </div>
                            </div>
                            </div>
                            <br />

                            <div className="row-customer-invoice">
                              <div className="row1">
                                <div className="item4">
                                  {" "}
                                  Advance Amount Paid:{" "}
                                </div>
                                <div className="item4"> 257.00</div>
                              </div>
                            </div>

                            <div className="row-customer-invoice">
                              <div className="row1">
                                <div className="item4"> Balance Paid: </div>
                                <div className="item4"> N/A</div>
                              </div>
                            </div>

                            <div className="row-customer-invoice">
                              <div className="row1">
                                <div className="item1"> Total Paid: </div>
                                <div className="item2">
                                  {" "}
                                  {props.selectedInvoiceItem["Billed Amount"]}
                                  <div>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="70"
                                      height="7"
                                      viewBox="0 0 70 7"
                                      fill="none"
                                    >
                                      <line
                                        y1="1"
                                        x2="70"
                                        y2="1"
                                        stroke="black"
                                      />
                                      <line
                                        y1="6"
                                        x2="70"
                                        y2="6"
                                        stroke="black"
                                      />
                                    </svg>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                    </div>
                    )}

                    {props.selectedInvoiceItem["Trip Type"] === "Outstation" && (
                      <div className="invoice-popup-content3">
                        <div className="invoice-popup-left">
                          <div className="invoice-popup-left">
                            <div className="row-customer-invoice">
                              <div className="row1-out">
                                <div className="item1">From Date: </div>
                                <div className="item">{props.selectedInvoiceItem["Pickup Date"]}</div>
                              </div>
                            </div>

                            <div className="row-customer-invoice">
                              <div className="row1-out">
                                <div className="item1"> To Date: </div>
                                <div className="item">{props.selectedInvoiceItem["Pickup Date"]}</div>
                              </div>
                            </div>

                            <div className="row-customer-invoice">
                              <div className="row1-out">
                                <div className="item1"> No. of Days: </div>
                                <div className="item">{props.selectedInvoiceItem["No of Days"]} Days</div>
                              </div>
                            </div>
                            <div className="row-customer-invoice">
                              <div className="row1-out">
                                <div className="item1"> Total Kms: </div>
                                <div className="item"> N/A</div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="invoice-popup-center" style={{marginLeft:"9rem"}}>
                          <h1>Taxi for<br></br>{props.selectedInvoiceItem["Pickup City"]} - {props.selectedInvoiceItem["Destination"]}<br></br> for {props.selectedInvoiceItem["Trip Type"]} - Est (Time & Km) - {props.selectedInvoiceItem["Sub Trip Type"]}</h1>

                          <h1>Extra Km charge</h1>
                  
                          <h1>Extra Hrs charge</h1>
                    
                          <h1>Sub Total</h1>
                  
                          <h1>GST (@ 5%)</h1>
                         
                          <h1>Toll tax and State tax extra</h1>
                        </div>

                        <div className="invoice-popup-right-out">
                        <br />
                        
                          {props.selectedInvoiceItem["Est  Fare"]}
                          <br />
                          <br />
                          {props.selectedInvoiceItem["Extra Km Charge"]}
                          <br /> <br />
                          {props.selectedInvoiceItem["Extra hrs Charge"]}
                          <br /> <br />
                            N/A
                          <br /> <br />
                          {props.selectedInvoiceItem["Gst on Extra"]}
                          <br />
                          <br />
                          N/A
                          <br />
                          <br />
                          <br />
                          <div className="row-customer-invoice">
                          <div className="row-customer-inv-row">
                            <div className="row1">
                              <div className="item1"> Total: </div>
                              <div className="item2">
                                {" "}
                                {props.selectedInvoiceItem["Billed Amount"]}
                                <div>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="70"
                                    height="7"
                                    viewBox="0 0 70 7"
                                    fill="none"
                                  >
                                    <line
                                      y1="1"
                                      x2="70"
                                      y2="1"
                                      stroke="black"
                                    />
                                    <line
                                      y1="6"
                                      x2="70"
                                      y2="6"
                                      stroke="black"
                                    />
                                  </svg>
                                </div>
                              </div>
                            </div>
                            </div>
                            <br />

                            <div className="row-customer-invoice">
                              <div className="row1">
                                <div className="item4">
                                  {" "}
                                  Advance Amount Paid:{" "}
                                </div>
                                <div className="item4"> 257.00</div>
                              </div>
                            </div>

                            <div className="row-customer-invoice">
                              <div className="row1">
                                <div className="item4"> Balance Paid: </div>
                                <div className="item4"> N/A</div>
                              </div>
                            </div>

                            <div className="row-customer-invoice">
                              <div className="row1">
                                <div className="item1"> Total Paid: </div>
                                <div className="item2">
                                  {" "}
                                  {props.selectedInvoiceItem["Billed Amount"]}
                                  <div>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="70"
                                      height="7"
                                      viewBox="0 0 70 7"
                                      fill="none"
                                    >
                                      <line
                                        y1="1"
                                        x2="70"
                                        y2="1"
                                        stroke="black"
                                      />
                                      <line
                                        y1="6"
                                        x2="70"
                                        y2="6"
                                        stroke="black"
                                      />
                                    </svg>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    <div className="footer">
                      <hr className="line-break" />
                      <div className="footer-text">
                        <b> Thank you for using Yatri Car Rental. </b> <br />{" "}
                        This is a computer generated invoice and requires no
                        signature.
                      </div>
                      <div className="footer-buttons">
                        <button className="footer-btn">Download as PDF</button>
                        <button className="footer-btn">Send via email</button>
                        <button className="footer-btn">Send via SMS</button>
                      </div>
                    </div>
                  </div>
                  </div>
                )
    </>
  )
}

export default CustomerInvoice