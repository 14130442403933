import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";
import { fetchAllBooking, fetchPendingBooking } from "./BookingAPI";

const AllinitialState = {
  bookings: [],
  status: null,
  isLoading: false,
  isError: false,
  createnewbookingpathvalue:null
};

const PendinginitialState = {
  pendingBookings: [],
  status: null,
  isLoading: false,
  isError: false,
};

export const fetchBooking = createAsyncThunk(
  "fetchBooking",
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await fetchAllBooking(dispatch);
      // const data =
      // console.log(response);
      return response;
    } catch (e) {
      console.log("Error in fetching Data", e);
      return rejectWithValue(e.message);
    }
  }
);

export const fetchPendingBookings = createAsyncThunk(
  "fetchPendingBooking",
  async () => {
    try {
      const result = await fetchPendingBooking();
      // console.log(result);
      return result;
    } catch (e) {
      console.log("Error in fetching Data", e);
      throw e;
    }
  }
);

export const createnewbookingpath=createAction("booking/newcreate",(value)=>{
  return{
    payload:value,
  }
})


const BookingSlice = createSlice({
  name: "booking",
  initialState: AllinitialState,
  extraReducers: (builder) => {
    builder.addCase(fetchBooking.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchBooking.fulfilled, (state, action) => {
      state.isLoading = false;
      state.status = "idle";
      state.bookings = action.payload;
    });
    builder.addCase(fetchBooking.rejected, (state, action) => {
      state.isLoading = false;
      console.log("Error", action.payload);
      state.isError = true;
    });
    
    // Reducer for setting a new value
    builder.addCase(createnewbookingpath, (state, action) => {
      state.createnewbookingpathvalue = action.payload;
    });
  },
});

const PendingBookingSlice = createSlice({
  name: "pendingBooking",
  initialState: PendinginitialState,
  extraReducers: (builder) => {
    builder.addCase(fetchPendingBookings.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchPendingBookings.fulfilled, (state, action) => {
      state.isLoading = false;
      state.status = "idle";
      state.pendingBookings = action.payload;
    });
    builder.addCase(fetchPendingBookings.rejected, (state, action) => {
      state.isLoading = false;
      console.log("Error", action.payload);
      state.isError = true;
    });
  },
});

export const selectAllBooking = (state) => state.booking.bookings;

export const selectPendingBooking = (state) =>
  state.pendingBooking.pendingBookings;

export const selectLoading = (state) => state.booking.isLoading;
export const selectNewCreateBookingPathValue = (state) => state.booking.createnewbookingpathvalue; // Selector for the new value

export const bookingSclice = BookingSlice.reducer;
export const pendingBookingSclice = PendingBookingSlice.reducer;
