import React from "react";
import { IoSearch } from "react-icons/io5";

const InputColumn = ({ title, selectedValue, handleChange }) => {
  return (
<>      <input
        type="text"
        value={selectedValue}
        onChange={handleChange}
        className="inputText dropdown-select-1" style={{borderRadius:"5px", marginLeft:"25px", width: "126px",height: "28px", padding: "1px 0px", boxShadow: "0px 1px 8px 0px #0000002B",
          border: "1px solid white"
      }}
        placeholder={title}
        // style={{
        //   display: "flex",
        //   width: "100%",
        //   height: "19px",
        //   padding: "0px 16px",
        //   alignItems: "center",
        //   gap: "4px",
        //   flexShrink: 0,
        //   fontSize: "12px",
        //   fontFamily: "sans-serif",
        //   background: "#FFF", // Set background color to #FFF
        //   boxShadow:
        //     "0px -1px 0px 0px #D9D9D9 inset, 0px 1px 0px 0px #D9D9D9 inset, -1px 0px 0px 0px #D9D9D9 inset", // Add box shadow
        //   color: "#585858",
        //   leadingTrim: "both",
        //   textEdge: "cap",
        //   fontFamily: "Inter",
        //   fontSize: "8px",
        //   fontStyle: "normal",
        //   fontWeight: 600,
        //   lineHeight: "22px" /* 275% */,
        //   borderRadius: "0",
        //   borderLeft: "1px solid var(--gray-border-gray-5, #D9D9D9)",
        //   "::placeholder": {
        //     color: "#3a3a3a",
        //     leadingTrim: "both",
        //     textEdge: "cap",
        //     fontFamily: "Poppins",
        //     fontSize: "10px",
        //     fontStyle: "normal",
        //     fontWeight: 600,
        //     lineHeight: "22px" /* 275% */,
        //   },
        // }}
      />
      <IoSearch className="dropdown-icon search007" style={{paddingTop: "5px"}}/>
    </>
  );
};

export default InputColumn;
