import React, { useEffect, useState } from "react";
import "./ViewCouponCode.css";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllCouponsAsync, selectAllcoupons } from "./couponSlice";
const TableItem = ({ item }) => {
  const handleDelete = () => {
    item.onDelete(item?.id);
  };

  return (
    <tr>
      <td>{item["Sr No"]}</td>
      <td>{item["Promo Code"]}</td>
      <td>{item["Discount"]}</td>
      <td>{item["Validity Upto"]}</td>
      <td>{item["Minimum Amount"]}</td>
      <td>{item["Trip Type"]}</td>
      <td>{item["Sub Trip Type"]}</td>
      <td>{item["Status"]}</td>
      <td>{item["Created At"]}</td>
      <td>{item["Action"]}</td>

      <td className="view-coupon-buttons">
        <button className="view-coupon-table-button" onClick={handleDelete}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="8"
            height="9"
            viewBox="0 0 8 9"
            fill="none"
          >
            <path
              d="M2.41429 0.311133L2.28571 0.5625H0.571429C0.255357 0.5625 0 0.813867 0 1.125C0 1.43613 0.255357 1.6875 0.571429 1.6875H7.42857C7.74464 1.6875 8 1.43613 8 1.125C8 0.813867 7.74464 0.5625 7.42857 0.5625H5.71429L5.58571 0.311133C5.48929 0.119531 5.29107 0 5.075 0H2.925C2.70893 0 2.51071 0.119531 2.41429 0.311133ZM7.42857 2.25H0.571429L0.95 8.20898C0.978571 8.65371 1.35357 9 1.80536 9H6.19464C6.64643 9 7.02143 8.65371 7.05 8.20898L7.42857 2.25Z"
              fill="#FC0F0F"
            />
          </svg>
          Delete
        </button>
      </td>
    </tr>
  );
};

const Table = ({ headers, data }) => {
  return (
    <table className="view-coupon-table">
      <thead>
        <tr>
          {headers.map((header, index) => (
            <th key={index}>{header}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((item, index) => (
          <TableItem key={index} item={item} index={index} />
        ))}
      </tbody>
    </table>
  );
};

const App = ({ filteredCoupons }) => {
  const coupons = useSelector(selectAllcoupons);

  const dispatch = useDispatch();

  const deleteCoupon = async (id) => {
    try {
      await axios.delete(
        `https://api.yatricabs.com/v1/admin/coupon-codes/${id}`
      );
      dispatch(fetchAllCouponsAsync());
    } catch (error) {
      console.error("Error deleting coupon:", error);
    }
  };

  const headers = [
    "Sr No",
    "Promo Code",
    "Discount",
    "Validity Upto",
    "Minimum Amount",
    "Trip Type",
    "Sub Trip Type",
    "Status",
    "Created At",
    "Action",
    "",
  ];

  useEffect(() => {
    dispatch(fetchAllCouponsAsync());
  }, []);


  let newarray = [];


  for (let i = 0; i < filteredCoupons.length; i++) {
    const data = filteredCoupons[i];

    // Parse the createdAt and expiryDate dates
    const createdAtDate = new Date(data.createdAt);
    const formattedDate = `${createdAtDate.getDate().toString().padStart(2, '0')}-${(createdAtDate.getMonth() + 1).toString().padStart(2, '0')}-${createdAtDate.getFullYear()}`; // Format as 'DD-MM-YYYY'

    const expiryDate = new Date(data.expiryDate);
    const currentDate = new Date();

    // Determine if the promo code is still valid
    const actionStatus = currentDate > expiryDate ? "Validity Expired" : "Valid";

    const obj = {
      "Sr No": i + 1,
      "Promo Code": data?.promoCode,
      Discount: data?.discountValue,
      "Validity Upto": data?.expiryDate,
      "Minimum Amount": data?.minBookingAmount,
      "Trip Type": data?.tripType,
      "Sub Trip Type": data?.subTripType,
      Status: data?.status,
      "Created At": formattedDate, // Use the formatted createdAt date
      Action: actionStatus, // Set action based on the current date and expiry date
      id: data?._id,
    };
    newarray.push(obj);
  }


  const ViewAllCityData = newarray;
  return (
    <div>
      <Table
        headers={headers}
        data={ViewAllCityData.map((item) => ({
          ...item,
          onDelete: deleteCoupon, // Pass the deleteCoupon function to each item
        }))}
      />
    </div>
  );
};

export default App;
