import React, { useState, useEffect, useRef } from "react";
import "./AddNewCar.css";
import axios from "axios";
import DropdownColumn from "../Booking/Dropdown";
import { useDispatch } from "react-redux";
import { setBooleanValue } from "./DriverSlice";
import toast, { Toaster } from "react-hot-toast";
import CountryDropdown from "../countrydropdown/CountryDropdown";
import dropArrow from "./../../assets/dropArrow.svg";
// import dateIcon from "./../../assets/dateIcon.svg";
import { TiTick } from "react-icons/ti";
import { Link, useNavigate, useParams } from "react-router-dom";
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const tableHeaderStyle = {
  backgroundColor: "#38B000",
  color: "white",
  padding: "10px",
  fontFamily: "Inter",
  fontSize: "14px",
  fontWeight: 600,
};

const AddNewCar = () => {
  const navigate = useNavigate();
  const [selectedLocation, setSelectedLocation] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("+91");
  const [showOtherInput, setShowOtherInput] = useState(false);
  const [otherLanguage, setOtherLanguage] = useState("");
  const [locationOptions, setLocationOptions] = useState([]);
  const [profilePhoto, setProfilePhoto] = useState(null);
  const [panCard, setPanCard] = useState(null);
  const [aadharCardFront, setAadharCardFront] = useState(null);
  const [aadharCardBack, setAadharCardBack] = useState(null);
  const [drivingLicenseFront, setDrivingLicenseFront] = useState(null);
  const [drivingLicenseBack, setDrivingLicenseBack] = useState(null);
  const [policeClearanceCertificate, setPoliceClearanceCertificate] =
    useState(null);
  const [step, setStep] = useState(1);

  const [isOtpModalOpen, setIsOtpModalOpen] = useState(false);
  const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalImageUrl, setModalImageUrl] = useState(null);

  const [inputValue, setInputValue] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    alternatePhoneNumber: "",
    password: "",
    confirmPassword: "",
    dob: "",
    source: "",
    panNo: "",
    adharNo: "",
    permanentAddress: "",
    currentAddress: "",
    location: "",
    language: "",
    dlNo: "",
    expiryDate: "",
    beneficiaryName: "",
    accountNo: "",
    confirmAccountNo: "",
    ifscCode: "",
    bankName: "",
    branchName: "",
    ratePer20km: "",
    ratePer40km: "",
    ratePer60km: "",
    ratePer80km: "",
    ratePer100km: "",
    ratePer120km: "",
    extraKm: "",
    extraHours: "",
    ratePerKm: "",
    minKmPerDay: "",
    nightCharge: "",
    ratePerTrip: "",
    profilePhoto: "",
    panCard: "",
    aadharCardFront: "",
    aadharCardBack: "",
    drivingLicenseFront: "",
    drivingLicenseBack: "",
    policeClearanceCertificate: "",
    otp: ["", "", "", "", "", ""],
    isPhoneNumberVerified: false,
    isOTPVerified: false,
    otpId: "",
    phoneNumberToken: "",
  });
  const [errors, setErrors] = useState({});

  const dispatch = useDispatch();

  const handleEarningReport = (e) => {
    e.preventDefault();
    dispatch(setBooleanValue(false));
  };
  const validateStep1 = () => {
    let newErrors = {};

    if (!selectedLocation) newErrors.selectedLocation = "Location is required.";
    if (!inputValue.firstName) newErrors.firstName = "First Name is required.";
    if (!inputValue.lastName) newErrors.lastName = "Last Name is required.";
    if (!inputValue.dob) newErrors.dob = "Date of Birth is required.";
    if (!inputValue.password) newErrors.password = "Password is required.";
    if (!inputValue.confirmPassword)
      newErrors.confirmPassword = "Confirm Password is required.";
    if (
      inputValue.password &&
      inputValue.confirmPassword &&
      inputValue.password !== inputValue.confirmPassword
    )
      newErrors.confirmPassword = "Passwords do not match.";
    if (!inputValue.dlNo) newErrors.dlNo = "DL Number is required.";
    if (!inputValue.expiryDate)
      newErrors.expiryDate = "Expiry Date is required.";
    if (!inputValue.panNo) newErrors.panNo = "PAN Number is required.";
    if (!inputValue.currentAddress)
      newErrors.currentAddress = "Current Address is required.";
    if (!inputValue.permanentAddress)
      newErrors.permanentAddress = "Permanent Address is required.";
    if (!inputValue.adharNo) newErrors.adharNo = "Aadhar Number is required.";
    if (!inputValue.alternatePhoneNumber)
      newErrors.alternatePhoneNumber = "Alternate Phone Number is required.";
    if (!inputValue.phoneNumber)
      newErrors.phoneNumber = "Phone Number is required.";
    if (!inputValue.isPhoneNumberVerified)
      newErrors.phoneNumber = "Phone Number must be verified.";
    // Validate Sourcing: Ensure a sourcing option is selected.
    if (!inputValue.source) newErrors.source = "Sourcing is required.";

    // Validate Language Speak: At least one language should be selected.
    if (!inputValue.language || inputValue.language.length === 0)
      newErrors.language = "Please select at least one language.";

    // If "Other" is selected, ensure the custom input is filled.
    if (
      inputValue.language &&
      inputValue.language.includes("Other") &&
      !otherLanguage
    ) {
      newErrors.otherLanguage = "Please specify the other language.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Return true if no errors, else false
  };

  const validateStep2 = () => {
    let newErrors = {};

    if (!inputValue.beneficiaryName)
      newErrors.beneficiaryName = "Beneficiary Name is required.";
    if (!inputValue.accountNo)
      newErrors.accountNo = "Bank Account Number is required.";
    if (!inputValue.confirmAccountNo)
      newErrors.confirmAccountNo = "Please confirm your Bank Account Number.";
    if (
      inputValue.accountNo &&
      inputValue.confirmAccountNo &&
      inputValue.accountNo !== inputValue.confirmAccountNo
    )
      newErrors.confirmAccountNo = "Bank Account Numbers do not match.";
    if (!inputValue.ifscCode) newErrors.ifscCode = "IFSC Code is required.";
    if (!inputValue.bankName) newErrors.bankName = "Bank Name is required.";
    if (!inputValue.branchName)
      newErrors.branchName = "Branch Name is required.";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const validateStep3 = () => {
    let newErrors = {};

    if (!profilePhoto) newErrors.profilePhoto = "Profile Photo is required.";
    if (!panCard) newErrors.panCard = "PAN Card is required.";
    if (!aadharCardFront)
      newErrors.aadharCardFront = "Aadhar Card (Front) is required.";
    if (!aadharCardBack)
      newErrors.aadharCardBack = "Aadhar Card (Back) is required.";
    if (!drivingLicenseFront)
      newErrors.drivingLicenseFront = "Driving License (Front) is required.";
    if (!drivingLicenseBack)
      newErrors.drivingLicenseBack = "Driving License (Back) is required.";
    if (!policeClearanceCertificate)
      newErrors.policeClearanceCertificate =
        "Police Clearance Certificate is required.";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleNext = () => {
    let isValid = false;

    if (step === 1) {
      isValid = validateStep1();
    } else if (step === 2) {
      isValid = validateStep2();
    } else if (step === 3) {
      isValid = validateStep3();
    }

    if (isValid) {
      setStep((prevStep) => prevStep + 1);
    } else {
      toast.error("Please fill all required fields before proceeding.");
    }
  };

  const handlePrevious = () => setStep((prevStep) => prevStep - 1);

  const handleChange = (e) => {
    if (e.target.name === "dlNo") {
      if (e.target.value.length <= 16) {
        e.target.value = e.target.value.toUpperCase();
        if (e.target.value.length === 2 && inputValue.dlNo.length === 3) {
          e.target.value = e.target.value.slice(0, -1); // Remove the hyphen if extra
        }
        // Add hyphen after the second character if not present
        if (e.target.value.length === 2 && !e.target.value.includes("-")) {
          e.target.value += "-";
        }
        console.log(e.target.value);
        setInputValue({
          ...inputValue,
          [e.target.name]: e.target.value,
        });
      }
    } else if (e.target.name === "panNo") {
      e.target.value = e.target.value.toUpperCase();
      const regex =
        /^[A-Z]{0,5}$|^[A-Z]{5}[0-9]{0,4}$|^[A-Z]{5}[0-9]{4}[A-Z]{0,1}$/;
      if (regex.test(e.target.value)) {
        setInputValue({
          ...inputValue,
          [e.target.name]: e.target.value,
        });
      }
    } else if (e.target.name === "ifscCode") {
      e.target.value = e.target.value.toUpperCase();
      let newValue = e.target.value;
      // Enforce maximum length of 11 characters
      if (newValue.length > 11) {
        newValue = newValue.slice(0, 11);
      }

      // Process based on the current length of input
      if (newValue.length <= 4) {
        // First 4 characters: only letters allowed
        newValue = newValue.replace(/[^A-Z]/g, "");
      } else if (newValue.length === 5) {
        // Ensure the fifth character is '0'
        newValue = newValue.slice(0, 4) + "0";
      } else {
        // For length greater than 5:
        //   - Enforce the first 4 letters and 5th as '0'
        //   - Allow only alphanumeric characters for the remaining part
        let prefix = newValue.slice(0, 4).replace(/[^A-Z]/g, "");
        newValue = prefix + "0" + newValue.slice(5).replace(/[^A-Z0-9]/g, "");
      }

      setInputValue({
        ...inputValue,
        ifscCode: newValue,
      });
    } else {
      setInputValue({
        ...inputValue,
        [e.target.name]: e.target.value,
      });
    }
  };

  const SourcehandleCheckbox = (e) => {
    const { name, value, checked } = e.target;
    console.log(value);
    setInputValue({
      ...inputValue,
      [name]: checked ? value : "",
    });
  };

  const handleImageClick = (file) => {
    const imageUrl = URL.createObjectURL(file);
    setModalImageUrl(imageUrl);
    setIsModalOpen(true);
  };

  const handleCheckboxChange = (e) => {
    const { name, value, checked } = e.target;
    setInputValue((prevInputValue) => {
      if (checked) {
        // Add the language to the array if checked
        const updatedLanguages = [...prevInputValue[name], value];
        console.log(updatedLanguages); // Log the updated array
        return { ...prevInputValue, [name]: updatedLanguages };
      } else {
        // Remove the language from the array if unchecked
        const updatedLanguages = prevInputValue[name].filter(
          (lang) => lang !== value
        );
        console.log(updatedLanguages); // Log the updated array
        return { ...prevInputValue, [name]: updatedLanguages };
      }
    });
    if (value === "Other") {
      setShowOtherInput(checked);
      if (!checked) {
        setOtherLanguage("");
      }
    }
  };

  const otpInputs = useRef([]);

  const handleOTPChange = (index, value) => {
    const updatedOTP = [...otp];
    updatedOTP[index] = value;
    setOtp(updatedOTP);

    if (value !== "" && index < otpInputs.current.length - 1) {
      otpInputs.current[index + 1].focus();
    }
  };
  const handleFileChange = (event, setFile) => {
    const file = event.target.files[0];

    if (file) {
      setFile(file);
    }
  };

  if (selectedCountry && inputValue.phoneNumber) {
    var completePhoneNumber = `${selectedCountry}-${inputValue.phoneNumber}`;
  }

  if (selectedCountry && inputValue.alternatePhoneNumber) {
    var alternateNumber = `${selectedCountry}-${inputValue.alternatePhoneNumber}`;
  }

  if (inputValue.language) {
    var formattedlanguage = inputValue.language.join(",");
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const headers = {
      // 'Content-Type': 'application/json',
      "Content-Type": "multipart/form-data",
    };

    const data = {
      firstName: inputValue.firstName,
      lastName: inputValue.lastName,
      phone: completePhoneNumber,
      alternatePhone: alternateNumber,
      password: inputValue.password,
      otpToken: inputValue.phoneNumberToken,
      dateOfBirth: inputValue.dob,
      languages: formattedlanguage,
      sourcing: inputValue.source,
      location: selectedLocation,
      dlNumber: inputValue.dlNo,
      dlExpiryDate: inputValue.expiryDate,
      panNumber: inputValue.panNo,
      permanentAddress: inputValue.permanentAddress,
      currentAddress: inputValue.currentAddress,
      aadhaarNumber: inputValue.adharNo,
      bankAccountNumber: inputValue.accountNo,
      confirmAccountNo: inputValue.confirmAccountNo,
      bankAccountBeneficiaryName: inputValue.beneficiaryName,
      bankIFSCCode: inputValue.ifscCode,
      bankName: inputValue.bankName,
      branchName: inputValue.branchName,

      airportBaseFare: inputValue.ratePerTrip,
      nightCharge: inputValue.nightCharge,
      profilePhoto: profilePhoto,
      aadhaarFrontPhoto: aadharCardFront,
      aadhaarBackPhoto: aadharCardBack,
      panCardPhoto: panCard,
      drivingLicenseFrontPhoto: drivingLicenseFront,
      drivingLicenseBackPhoto: drivingLicenseBack,
      policeClearanceCertificatePhoto: policeClearanceCertificate,
    };
    try {
      // Add a new car category
      await axios.post(`${API_BASE_URL}/v1/admin/drivers`, data, {
        headers,
      });
      toast.success("New Driver added!");

      setTimeout(() => {
        navigate("/drivermanagement");
      }, 3000);

      e.target.reset();
      setSelectedLocation("");
      setProfilePhoto(null);
      setPanCard(null);
      setAadharCardFront(null);
      setAadharCardBack(null);
      setDrivingLicenseFront(null);
      setDrivingLicenseBack(null);
      setPoliceClearanceCertificate(null);
    } catch (error) {
      toast.error(error.response.data.message);
      console.error("error ", error.response.data.message);
    }

    console.log("Form Values:", data);
  };

  const handleVerifyPhoneNumber = () => {
    console.log("Phone Number:", completePhoneNumber);
    fetch(`${API_BASE_URL}/v1/send-otp`, {
      method: "POST",
      body: JSON.stringify({ phone: completePhoneNumber }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.ok) {
          console.log("OTP sent successfully");
          console.log(response);
          return response.json();
        } else {
          throw new Error("Failed to send OTP");
        }
      })
      .then((data) => {
        if (data && data.data && data.data.otpId) {
          const otpId = data.data.otpId;
          console.log("OTP ID:", otpId);
          setInputValue({
            ...inputValue,
            otpId: otpId,
            isPhoneNumberVerified: true,
          });
          setIsOtpModalOpen(true); // Open OTP modal
        } else {
          throw new Error("Invalid response format from OTP sending API");
        }
      })
      .catch((error) => {
        console.error("Error sending OTP:", error.message);
      });
  };

  const handleVerifyOTP = () => {
    const otpInteger = parseInt(otp.join(""), 10);

    fetch(`${API_BASE_URL}/v1/verify-otp`, {
      method: "POST",
      body: JSON.stringify({
        phone: completePhoneNumber,
        otp: otpInteger,
        otpId: inputValue.otpId,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data && data.otpToken) {
          setInputValue({
            ...inputValue,
            isOTPVerified: true,
            phoneNumberToken: data.otpToken,
          });
          setIsOtpModalOpen(false); // Close OTP modal on success
          toast.success("OTP Verified!");
        } else {
          throw new Error("OTP verification failed");
        }
      })
      .catch((error) => {
        console.error("Error verifying OTP:", error.message);
        toast.error("Invalid OTP. Please try again.");
      });
  };

  useEffect(() => {
    const extractLocationsAsOptions = (dataArray) => {
      if (Array.isArray(dataArray.data)) {
        return dataArray.data.map((item) => ({
          label: item.location,
          value: item._id,
        }));
      }
      return [];
    };

    const fetchLocation = async () => {
      try {
        const locationResponse = await fetch(
          `${API_BASE_URL}/v1/locations`
        );
        const locationData = await locationResponse.json();
        const locationOptions = extractLocationsAsOptions(locationData);
        // console.log(locationOptions);
        setLocationOptions(locationOptions);
      } catch (error) {
        console.error("Error fetching location options:", error);
      }
    };
    fetchLocation();
  }, []);

  const handlePhoneNumberChange = () => {
    setInputValue({
      ...inputValue,
      isPhoneNumberVerified: false,
      isOTPVerified: false,
      phoneNumber: "",
      otp: ["", "", "", "", "", ""],
    });
  };

  const handleOtherLanguageChange = (e) => {
    setOtherLanguage(e.target.value);
  };

  const maxDate = new Date();
  maxDate.setFullYear(maxDate.getFullYear() - 19);
  const maxDateString = maxDate.toISOString().split("T")[0];

  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <div className="add-new-car-driver-info-user-details">
            <div className="add-new-car-driver-info-user-details-section">
              {/* Location */}
              <div className="add-new-car-driver-info-user-detail">
                <div className="user-heading left-space-location">Location</div>
                <div className="input-left">
                  <select
                    value={selectedLocation}
                    onChange={(e) => setSelectedLocation(e.target.value)}
                    className="location-dropdown"
                  >
                    <option value="">Select City</option>
                    {locationOptions.map((option, index) => (
                      <option key={index} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                  {errors.selectedLocation && (
                    <p className="error-text">{errors.selectedLocation}</p>
                  )}
                </div>
              </div>
              {/* First Name */}
              <div className="add-new-car-driver-info-user-detail">
                <div className="user-heading">First Name</div>
                <div>
                  <input
                    placeholder="Enter Name"
                    className="custom-input"
                    name="firstName"
                    autoComplete="off"
                    value={inputValue.firstName}
                    onChange={(e) => {
                      const value = e.target.value
                        .toUpperCase()
                        .replace(/[^A-Z ]/g, "");
                      setInputValue((prevState) => ({
                        ...prevState,
                        firstName: value,
                      }));
                    }}
                  />
                  {errors.firstName && (
                    <p className="error-text">{errors.firstName}</p>
                  )}
                </div>
              </div>
              {/* Last Name */}
              <div className="add-new-car-driver-info-user-detail">
                <div className="user-heading">Last Name</div>
                <div>
                  <input
                    placeholder="Enter surname"
                    className="custom-input"
                    name="lastName"
                    autoComplete="off"
                    value={inputValue.lastName}
                    onChange={(e) => {
                      const value = e.target.value
                        .toUpperCase()
                        .replace(/[^A-Z ]/g, "");
                      setInputValue((prevState) => ({
                        ...prevState,
                        lastName: value,
                      }));
                    }}
                  />
                  {errors.lastName && (
                    <p className="error-text">{errors.lastName}</p>
                  )}
                </div>
              </div>

              {/* Phone Number Section */}
              <div className="add-new-car-driver-info-user-detail">
                <div className="user-heading" style={{ marginLeft: "40px" }}>
                  Phone Number
                </div>
                <div
                  className="phone-input-container"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginLeft: "-35px",
                  }}
                >
                  <div style={{ display: "flex" }}>
                    <input
                      placeholder="Enter Phone No."
                      className="custom-input-phone"
                      name="phoneNumber"
                      autoComplete="off"
                      value={inputValue.phoneNumber}
                      onChange={handleChange}
                      disabled={inputValue.isPhoneNumberVerified} // Disable input if verified
                      style={{ marginLeft: "40px", width: "215px" }}
                    />
                    {!inputValue.isPhoneNumberVerified && (
                      <button
                        type="button"
                        className="verify"
                        onClick={handleVerifyPhoneNumber}
                      >
                        Verify Now
                      </button>
                    )}
                    {inputValue.isPhoneNumberVerified &&
                      inputValue.isOTPVerified && (
                        <div className="verified">Verified</div>
                      )}
                  </div>

                  {/* Change phone number link */}
                  {inputValue.isPhoneNumberVerified && (
                    <div
                      style={{
                        marginTop: "8px",
                        fontSize: "12px",
                        color: "#38B000",
                      }}
                    >
                      <button
                        type="button"
                        onClick={handlePhoneNumberChange}
                        style={{
                          background: "none",
                          border: "none",
                          color: "#000",
                          fontSize: "8px",
                          fontStyle: "normal",
                          fontWeight: 500,
                          cursor: "pointer",
                          fontFamily: "Inter, sans-serif",
                        }}
                      >
                        Change phone no.?
                      </button>
                    </div>
                  )}
                  {errors.phoneNumber && (
                    <p className="error-text" style={{ marginLeft: "40px" }}>
                      {errors.phoneNumber}
                    </p>
                  )}
                </div>
              </div>

              {/* Alternate Phone no */}
              <div className="add-new-car-driver-info-user-detail">
                <div
                  className="user-heading for-nowrap"
                  style={{ marginLeft: "-20px" }}
                >
                  Alternate Phone No.
                </div>
                <div>
                  <input
                    placeholder="Enter alternate number"
                    className="custom-input"
                    autoComplete="off"
                    name="alternatePhoneNumber"
                    style={{ marginLeft: "20px" }}
                    value={inputValue.alternatePhoneNumber}
                    onChange={handleChange}
                  />
                  {errors.alternatePhoneNumber && (
                    <p className="error-text" style={{ marginLeft: "20px" }}>
                      {errors.alternatePhoneNumber}
                    </p>
                  )}
                </div>
              </div>

              {/* DOB */}
              <div className="add-new-car-driver-info-user-detail">
                <div
                  className="user-heading left-dob"
                  style={{ marginLeft: "-20px" }}
                >
                  Date Of Brith
                </div>
                <div>
                  <input
                    type="date"
                    placeholder="Enter DOB"
                    className="custom-input"
                    name="dob"
                    autoComplete="off"
                    value={inputValue.dob}
                    onChange={handleChange}
                    style={{ marginLeft: "20px" }}
                    max={maxDateString}
                  />
                  {errors.dob && (
                    <p className="error-text" style={{ marginLeft: "20px" }}>
                      {errors.dob}
                    </p>
                  )}
                </div>
                {/* <img src="dateIcon" alt="dateIcon" /> */}
              </div>

              {/* Language Speak */}
              <div className="left-seprate-css-add-new-car-driver">
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginLeft: "15px",
                    }}
                  >
                    <div className="user-heading">
                      Language{" "}
                      <div
                        style={{ marginLeft: "15px", marginBottom: "-10px" }}
                      >
                        {" "}
                        Speak
                      </div>
                    </div>

                    <input
                      type="checkbox"
                      className="custom-checkbox"
                      id="hindiCheckbox"
                      name="language"
                      value="Hindi"
                      checked={inputValue.language.includes("Hindi")}
                      onChange={handleCheckboxChange}
                    />
                    <label htmlFor="hindiCheckbox">Hindi</label>

                    <input
                      type="checkbox"
                      className="custom-checkbox"
                      id="englishCheckbox"
                      name="language"
                      value="English"
                      checked={inputValue.language.includes("English")}
                      onChange={handleCheckboxChange}
                    />
                    <label htmlFor="englishCheckbox">English</label>

                    <input
                      type="checkbox"
                      className="custom-checkbox"
                      id="otherCheckbox"
                      name="language"
                      value="Other"
                      checked={inputValue.language.includes("Other")}
                      onChange={handleCheckboxChange}
                    />
                    <label htmlFor="otherCheckbox">Other</label>
                  </div>

                  {/* Show "Other" input below the checkboxes */}
                  {showOtherInput && (
                    <input
                      type="text"
                      className="custom-width-and-height"
                      value={otherLanguage}
                      onChange={handleOtherLanguageChange}
                      placeholder="Type Here"
                      style={{ marginLeft: "200px" }}
                    />
                  )}
                </div>

                {errors.language && (
                  <p style={{ marginLeft: "30%" }} className="error-text">
                    {errors.language}
                  </p>
                )}
              </div>

              {/* Create Password */}
              <div className="add-new-car-driver-info-user-detail">
                <div
                  className="user-heading for-two-line-create-pass"
                  style={{ marginLeft: "10px" }}
                >
                  <div style={{ marginLeft: "20px" }}>Create</div> Password
                </div>
                <div>
                  <input
                    type="password"
                    placeholder="Enter Password"
                    className="custom-input"
                    autoComplete="off"
                    name="password"
                    value={inputValue.password}
                    onChange={(e) => {
                      const value = e.target.value;
                      setInputValue((prevState) => ({
                        ...prevState,
                        password: value,
                      }));

                      // Validation for password complexity
                      const passwordPattern =
                        /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

                      setErrors((prevErrors) => ({
                        ...prevErrors,
                        password: passwordPattern.test(value)
                          ? ""
                          : "Password must be at least 8 characters long, contain uppercase, lowercase, a number, and a special character.",
                      }));
                    }}
                  />
                  {errors.password && (
                    <p className="error-text">{errors.password}</p>
                  )}
                </div>
              </div>
              {/* Confirm Password */}
              <div className="add-new-car-driver-info-user-detail">
                <div
                  className="user-heading for-two-line"
                  style={{ marginLeft: "10px" }}
                >
                  Confirm Password
                </div>
                <div>
                  <input
                    type="text"
                    placeholder="Enter Password"
                    className="custom-input"
                    name="confirmPassword"
                    autoComplete="off"
                    style={{ marginLeft: "-10px" }}
                    value={inputValue.confirmPassword}
                    onChange={handleChange}
                  />
                  {errors.confirmPassword && (
                    <p className="error-text">{errors.confirmPassword}</p>
                  )}
                </div>
              </div>
              {/* DL No. */}
              <div className="add-new-car-driver-info-user-detail">
                <div className="user-heading left-space-DL">DL No.</div>
                <div style={{ marginTop: "6px" }}>
                  <input
                    placeholder="Enter Driving License number"
                    className="custom-input input-left-DN-no"
                    name="dlNo"
                    autoComplete="off"
                    value={inputValue.dlNo}
                    onChange={handleChange}
                  />
                  <p
                    style={{
                      color: "grey",
                      fontSize: "10px",
                      textAlign: "left",
                      marginLeft: "-25px",
                      marginTop: "3px",
                    }}
                  >
                    Format: DL-1420110012345
                  </p>
                  {errors.dlNo && (
                    <p className="error-text" style={{ marginLeft: "-50px" }}>
                      {errors.dlNo}
                    </p>
                  )}
                </div>
              </div>

              {/* Expiry Date */}
              <div className="add-new-car-driver-info-user-detail">
                <div className="user-heading for-two-line">Expiry Date</div>
                <div className="user-info">
                  <input
                    type="date"
                    placeholder="Enter Password"
                    className="custom-input"
                    name="expiryDate"
                    autoComplete="off"
                    value={inputValue.expiryDate}
                    onChange={handleChange}
                    min={new Date().toISOString().split("T")[0]}
                  />
                  {errors.expiryDate && (
                    <p className="error-text">{errors.expiryDate}</p>
                  )}
                </div>
              </div>

              {/* PAN No. */}
              <div className="add-new-car-driver-info-user-detail">
                <div className="user-heading" style={{ marginLeft: "20px" }}>
                  PAN No.
                </div>
                <div style={{ marginTop: "6px" }}>
                  <input
                    type="text"
                    placeholder="Enter PAN Number"
                    className="custom-input"
                    name="panNo"
                    autoComplete="off"
                    style={{ marginLeft: "-20px" }}
                    value={inputValue.panNo}
                    onChange={handleChange}
                  />
                  <p
                    style={{
                      color: "grey",
                      fontSize: "10px",
                      textAlign: "left",
                      marginLeft: "22px",
                      marginTop: "3px",
                    }}
                  >
                    Format: ABCDE1234C
                  </p>
                  {errors.panNo && <p className="error-text">{errors.panNo}</p>}
                </div>
              </div>

              {/* Sourcing */}
              <div className="left-sourcing-add-new-car-driver">
                <div style={{ display: "flex" }}>
                  <div
                    className="user-heading left-sourcing"
                    style={{ marginLeft: "45px" }}
                  >
                    Sourcing
                  </div>
                  <input
                    type="checkbox"
                    id="CompanyOwnedCheckbox"
                    name="source"
                    value="Company Owned"
                    checked={inputValue.source === "Company Owned"}
                    onChange={SourcehandleCheckbox}
                  />
                  <label
                    htmlFor="CompanyOwnedCheckbox"
                    className="for-two-line-create-pass"
                    style={{
                      margintop: " 6px",
                    }}
                  >
                    Company Owned
                  </label>
                  <input
                    type="checkbox"
                    id="OutsourcedCheckbox"
                    name="source"
                    value="Outsourced"
                    checked={inputValue.source === "Outsourced"}
                    onChange={SourcehandleCheckbox}
                  />
                  <label
                    htmlFor="OutsourcedCheckbox"
                    style={{
                      margintop: " 6px",
                    }}
                  >
                    Outsourced
                  </label>
                </div>
                {errors.source && (
                  <p style={{ marginLeft: "140px" }} className="error-text">
                    {errors.source}
                  </p>
                )}
              </div>

              {/* Aadhar No. */}
              <div className="add-new-car-driver-info-user-detail">
                <div className="user-heading" style={{ marginLeft: "-10px" }}>
                  Aadhar No.
                </div>
                <div>
                  <input
                    type="text"
                    placeholder="Enter Aadhar Number"
                    className="custom-input"
                    name="adharNo"
                    autoComplete="off"
                    value={inputValue.adharNo}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (/^\d{0,12}$/.test(value)) {
                        handleChange(e);
                      }
                    }}
                    maxLength="16"
                  />
                  {errors.adharNo && (
                    <p className="error-text">{errors.adharNo}</p>
                  )}
                </div>
              </div>

              {/* Empty div to push Current Address to the next line */}
              <div className="add-new-car-driver-info-user-detail"></div>

              {/* Permanent address*/}
              <div className="add-new-car-driver-info-user-detail">
                <div className="user-heading" style={{ marginLeft: "20px" }}>
                  Permanent Address
                </div>
                <div>
                  <input
                    // placeholder="Permanent Address"
                    className="current-add-input"
                    name="permanentAddress"
                    autoComplete="off"
                    style={{ marginLeft: "-20px" }}
                    value={inputValue.permanentAddress}
                    onChange={handleChange}
                  />
                  {errors.permanentAddress && (
                    <p className="error-text" style={{ marginLeft: "-10px" }}>
                      {errors.permanentAddress}
                    </p>
                  )}
                </div>
              </div>

              {/* Current Address */}
              <div className="add-new-car-driver-info-user-detail">
                <div
                  className="user-heading left-current-add"
                  style={{ marginLeft: "-110px" }}
                >
                  Current Address
                </div>
                <div>
                  <input
                    type="text"
                    className="current-add-input"
                    name="currentAddress"
                    autoComplete="off"
                    style={{ marginLeft: "10px" }}
                    value={inputValue.currentAddress}
                    onChange={handleChange}
                  />
                  {errors.currentAddress && (
                    <p className="error-text">{errors.currentAddress}</p>
                  )}
                </div>
              </div>

              {/* OTP Modal */}
              {isOtpModalOpen && (
                <div className="otp-modal">
                  <div className="otp-modal-content">
                    <h3>Enter OTP</h3>
                    <div className="otp-inputs">
                      {otp.map((value, index) => (
                        <input
                          key={index}
                          ref={(input) => (otpInputs.current[index] = input)}
                          type="text"
                          maxLength="1"
                          className="otp-input"
                          value={value}
                          onChange={(e) =>
                            handleOTPChange(index, e.target.value)
                          }
                        />
                      ))}
                    </div>
                    <button
                      type="button"
                      className="submit-otp"
                      onClick={handleVerifyOTP}
                    >
                      Submit OTP
                    </button>
                    <button
                      type="button"
                      className="close-otp"
                      onClick={() => setIsOtpModalOpen(false)}
                    >
                      Close
                    </button>
                  </div>
                </div>
              )}

              {/* <div>
          <button className="earning-button" onClick={handleEarningReport}>
            Earning Report
          </button>
        </div> */}

              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="128"
                height="36"
                viewBox="0 0 128 36"
                fill="none"
                onClick={handleNext}
                style={{ cursor: "pointer" }}
              >
                <path
                  d="M0 9C0 4.02944 4.02944 0 9 0H106.435C108.412 0 110.335 0.651323 111.905 1.85331L123.663 10.8533C128.369 14.4556 128.369 21.5444 123.663 25.1467L111.905 34.1467C110.335 35.3487 108.412 36 106.435 36H8.99999C4.02943 36 0 31.9706 0 27V9Z"
                  fill="#38B000"
                />
                <text
                  x="40%"
                  y="50%"
                  dominant-baseline="middle"
                  text-anchor="middle"
                  fill="#FFF"
                  font-family="Inter, Arial, sans-serif"
                  font-size="23"
                  font-weight="700"
                  font-style="normal"
                  line-height="normal"
                >
                  Next
                </text>
              </svg>
            </div>
          </div>
        );

      case 2:
        return (
          <div
            className="user-details"
            style={{
              display: "flex",
              flexDirection: "column",
              marginBottom: "0",
            }}
          >
            <div className="user-details-section">
              <div className="user-detail" style={{ marginBottom: "0" }}>
                <div className="user-heading" style={{ marginBottom: "15px" }}>
                  Beneficiary name
                </div>
                <div>
                  <input
                    placeholder="Enter Beneficiary name"
                    className="custom-input"
                    name="beneficiaryName"
                    autoComplete="off"
                    value={inputValue.beneficiaryName}
                    onChange={(e) => {
                      const value = e.target.value
                        .toUpperCase()
                        .replace(/[^A-Z0-9 ]/g, "");
                      setInputValue((prevState) => ({
                        ...prevState,
                        beneficiaryName: value,
                      }));
                    }}
                  />
                  {errors.beneficiaryName && (
                    <p className="error-text">{errors.beneficiaryName}</p>
                  )}
                </div>
              </div>
            </div>

            <div className="user-details-section">
              <div className="user-detail" style={{ marginBottom: "0" }}>
                <div className="user-heading" style={{ marginBottom: "15px" }}>
                  Account No.
                </div>
                <div>
                  <input
                    type="password"
                    placeholder="Enter Bank Account number"
                    className="custom-input"
                    name="accountNo"
                    autoComplete="off"
                    value={inputValue.accountNo}
                    onChange={handleChange}
                    maxLength="16"
                    onPaste={(e) => e.preventDefault()}
                    onCopy={(e) => e.preventDefault()}
                  />
                  {errors.accountNo && (
                    <p className="error-text">{errors.accountNo}</p>
                  )}
                </div>
              </div>
            </div>

            <div className="user-details-section">
              <div className="user-detail" style={{ marginBottom: "0" }}>
                <div className="user-heading" style={{ marginBottom: "15px" }}>
                  Confirm Account No.
                </div>
                <div>
                  <input
                    type="text"
                    placeholder="Confirm Bank Ac. No."
                    className="custom-input"
                    name="confirmAccountNo"
                    autoComplete="off"
                    style={{ width: "210px" }}
                    value={inputValue.confirmAccountNo}
                    onChange={handleChange}
                    maxLength="16"
                    onPaste={(e) => e.preventDefault()}
                    onCopy={(e) => e.preventDefault()}
                  />
                  {errors.confirmAccountNo && (
                    <p className="error-text">{errors.confirmAccountNo}</p>
                  )}
                  {inputValue.accountNo &&
                    inputValue.confirmAccountNo &&
                    inputValue.accountNo !== inputValue.confirmAccountNo && (
                      <p className="error-text">Account numbers do not match</p>
                    )}
                </div>
              </div>
            </div>

            <div className="user-details-section">
              <div className="user-detail" style={{ marginBottom: "0" }}>
                <div className="user-heading" style={{ marginBottom: "15px" }}>
                  IFSCCODE
                </div>
                <div>
                  <input
                    type="text"
                    placeholder="Enter IFSC Code"
                    className="custom-input"
                    name="ifscCode"
                    autoComplete="off"
                    value={inputValue.ifscCode}
                    onChange={handleChange}
                    pattern="^[A-Z]{4}0[A-Z0-9]{6}$"
                    maxLength="11"
                    title="Enter a valid IFSC code: 4 letters, followed by 0, then 6 alphanumeric characters."
                  />
                  <p
                    style={{
                      color: "grey",
                      fontSize: "10px",
                      textAlign: "left",
                      marginLeft: "0px",
                      marginTop: "3px",
                    }}
                  >
                    Format: HDFC0A12B34
                  </p>

                  {errors.ifscCode && (
                    <p className="error-text">{errors.ifscCode}</p>
                  )}
                </div>
              </div>
            </div>

            <div className="user-details-section">
              <div className="user-detail" style={{ marginBottom: "0" }}>
                <div className="user-heading" style={{ marginBottom: "15px" }}>
                  Bank Name
                </div>
                <div>
                  <input
                    placeholder="Enter Bank Name"
                    className="custom-input"
                    name="bankName"
                    autoComplete="off"
                    value={inputValue.bankName}
                    onChange={(e) => {
                      const value = e.target.value
                        .toUpperCase()
                        .replace(/[^A-Z ]/g, "");
                      setInputValue((prevState) => ({
                        ...prevState,
                        bankName: value,
                      }));
                    }}
                  />
                  {errors.bankName && (
                    <p className="error-text">{errors.bankName}</p>
                  )}
                </div>
              </div>
            </div>

            <div className="user-details-section">
              <div className="user-detail" style={{ marginBottom: "0" }}>
                <div className="user-heading" style={{ marginBottom: "15px" }}>
                  Branch Name
                </div>
                <div>
                  <input
                    placeholder="Enter Branch Name"
                    className="custom-input"
                    name="branchName"
                    autoComplete="off"
                    value={inputValue.branchName}
                    onChange={(e) => {
                      const value = e.target.value
                        .toUpperCase()
                        .replace(/[^A-Z ]/g, "");
                      setInputValue((prevState) => ({
                        ...prevState,
                        branchName: value,
                      }));
                    }}
                  />
                  {errors.branchName && (
                    <p className="error-text">{errors.branchName}</p>
                  )}
                </div>
              </div>
            </div>

            <div
              className="button-container"
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="128"
                height="36"
                viewBox="0 0 128 36"
                fill="none"
                onClick={handlePrevious}
                style={{ cursor: "pointer" }}
              >
                <path
                  d="M0 9C0 4.02944 4.02944 0 9 0H106.435C108.412 0 110.335 0.651323 111.905 1.85331L123.663 10.8533C128.369 14.4556 128.369 21.5444 123.663 25.1467L111.905 34.1467C110.335 35.3487 108.412 36 106.435 36H8.99999C4.02943 36 0 31.9706 0 27V9Z"
                  fill="#38B000"
                  transform="rotate(180, 64, 18)"
                />
                <text
                  x="55%"
                  y="50%"
                  dominant-baseline="middle"
                  text-anchor="middle"
                  fill="#FFF"
                  font-family="Inter, Arial, sans-serif"
                  font-size="23"
                  font-weight="700"
                  font-style="normal"
                  line-height="normal"
                >
                  Previous
                </text>
              </svg>

              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="128"
                height="36"
                viewBox="0 0 128 36"
                fill="none"
                onClick={handleNext}
                style={{ cursor: "pointer" }}
              >
                <path
                  d="M0 9C0 4.02944 4.02944 0 9 0H106.435C108.412 0 110.335 0.651323 111.905 1.85331L123.663 10.8533C128.369 14.4556 128.369 21.5444 123.663 25.1467L111.905 34.1467C110.335 35.3487 108.412 36 106.435 36H8.99999C4.02943 36 0 31.9706 0 27V9Z"
                  fill="#38B000"
                />
                <text
                  x="40%"
                  y="50%"
                  dominant-baseline="middle"
                  text-anchor="middle"
                  fill="#FFF"
                  font-family="Inter, Arial, sans-serif"
                  font-size="23"
                  font-weight="700"
                  font-style="normal"
                  line-height="normal"
                >
                  Next
                </text>
              </svg>
            </div>
          </div>
        );
      case 3:
        return (
          <>
            <div className="file-upload-grid" style={{ padding: "20px" }}>
              <div
                className="grid-container"
                style={{
                  display: "grid",
                  gridTemplateColumns: "repeat(4, 1fr)",
                  gap: "20px",
                }}
              >
                {[
                  {
                    label: "Profile Photo",
                    state: profilePhoto,
                    setter: setProfilePhoto,
                  },
                  { label: "Pan Card", state: panCard, setter: setPanCard },
                  {
                    label: "Aadhar Card Front",
                    state: aadharCardFront,
                    setter: setAadharCardFront,
                  },
                  {
                    label: "Aadhar Card Back",
                    state: aadharCardBack,
                    setter: setAadharCardBack,
                  },
                ].map(({ label, state, setter }, index) => (
                  <div
                    className="file-upload-item"
                    key={index}
                    style={{ width: "50%", textAlign: "center" }}
                  >
                    <div
                      style={{
                        color: "#000",
                        fontFamily: "Lato",
                        fontSize: "15px",
                        fontWeight: 600,
                        marginBottom: "10px",
                      }}
                    >
                      {label}
                    </div>
                    <div
                      className="file-upload-container"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "150px",
                        height: "150px",
                        border: "6px dashed #CBD0DC",
                        borderRadius: "40px",
                        background: "#FFF",
                        position: "relative", // Ensures absolute positioning works
                      }}
                    >
                      {state && (
                        <div
                          style={{
                            position: "absolute",
                            top: "5px",
                            right: "5px",
                            background: "red",
                            color: "white",
                            borderRadius: "50%",
                            width: "20px",
                            height: "20px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            cursor: "pointer",
                            zIndex: 1,
                          }}
                          onClick={() => setter(null)}
                        >
                          &#x2715;
                        </div>
                      )}
                      {state ? (
                        <img
                          className="uploaded-preview"
                          src={URL.createObjectURL(state)}
                          alt="Uploaded Preview"
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                            borderRadius: "40px",
                            position: "absolute", // Cover the entire div
                            top: 0,
                            left: 0,
                          }}
                          onClick={() => handleImageClick(state)} // Open modal on click
                        />
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="51"
                          height="50"
                          viewBox="0 0 51 50"
                          fill="none"
                          style={{ marginBottom: "10px" }}
                        >
                          <path
                            d="M16.043 40.2909H12.0429C5.91786 39.8534 3.16797 35.1451 3.16797 30.9576C3.16797 26.7701 5.91789 22.0409 11.9387 21.6242C12.7929 21.5409 13.5429 22.2076 13.6054 23.0826C13.6679 23.9367 13.0221 24.6867 12.1471 24.7492C8.10546 25.0409 6.29297 28.0825 6.29297 30.9784C6.29297 33.8742 8.10546 36.9159 12.1471 37.2076H16.043C16.8971 37.2076 17.6055 37.9159 17.6055 38.7701C17.6055 39.6242 16.8971 40.2909 16.043 40.2909Z"
                            fill="#292D32"
                          />
                          <path
                            d="M35.2274 40.2932C35.1857 40.2932 35.1649 40.2932 35.1233 40.2932C34.2691 40.2932 33.4775 39.5849 33.4775 38.7307C33.4775 37.8349 34.1441 37.1682 35.0191 37.1682C37.5816 37.1682 39.8733 36.2724 41.665 34.6682C44.915 31.8349 45.1233 27.7516 44.2483 24.8766C43.3733 22.0224 40.9358 18.7516 36.6858 18.2307C35.9983 18.1474 35.4565 17.6266 35.3315 16.9391C34.4982 11.9391 31.8108 8.48072 27.7275 7.23072C23.5191 5.91822 18.6024 7.20988 15.5399 10.4182C12.5607 13.5224 11.8732 17.8766 13.6024 22.6682C13.8941 23.4807 13.4775 24.3765 12.665 24.6682C11.8525 24.9599 10.9566 24.5432 10.665 23.7307C8.56079 17.8557 9.51914 12.2307 13.29 8.27239C17.1441 4.23072 23.3316 2.62654 28.6441 4.25154C33.5191 5.75154 36.9566 9.77237 38.2066 15.3557C42.4566 16.314 45.8733 19.5432 47.2274 24.0016C48.7066 28.8557 47.3733 33.8557 43.7274 37.0224C41.4149 39.1057 38.3941 40.2932 35.2274 40.2932Z"
                            fill="#292D32"
                          />
                          <path
                            d="M25.4987 46.4193C21.3112 46.4193 17.3946 44.1901 15.2487 40.5859C15.0196 40.2318 14.7904 39.8151 14.6029 39.3568C13.8946 37.8776 13.5195 36.1901 13.5195 34.4401C13.5195 27.8359 18.8945 22.4609 25.4987 22.4609C32.1029 22.4609 37.4779 27.8359 37.4779 34.4401C37.4779 36.2109 37.1029 37.8776 36.3529 39.4193C36.1862 39.8151 35.9571 40.2318 35.7071 40.6276C33.6029 44.1901 29.6862 46.4193 25.4987 46.4193ZM25.4987 25.5859C20.6237 25.5859 16.6445 29.5651 16.6445 34.4401C16.6445 35.7318 16.9154 36.9401 17.4362 38.0443C17.6029 38.3984 17.7487 38.6901 17.9153 38.9609C19.4987 41.6484 22.3945 43.2943 25.4778 43.2943C28.5612 43.2943 31.457 41.6484 33.0195 39.0026C33.207 38.6901 33.3738 38.3984 33.4988 38.1068C34.0613 36.9609 34.332 35.7526 34.332 34.4609C34.3528 29.5651 30.3737 25.5859 25.4987 25.5859Z"
                            fill="#292D32"
                          />
                          <path
                            d="M24.3112 38.0611C23.9154 38.0611 23.5196 37.9153 23.2071 37.6028L21.1445 35.5403C20.5404 34.9361 20.5404 33.9361 21.1445 33.3319C21.7487 32.7278 22.7487 32.7278 23.3529 33.3319L24.3529 34.3319L27.6862 31.2486C28.3321 30.6653 29.3112 30.7069 29.8946 31.3319C30.4779 31.9569 30.4363 32.957 29.8113 33.5403L25.3738 37.6445C25.0613 37.9153 24.6862 38.0611 24.3112 38.0611Z"
                            fill="#292D32"
                          />
                        </svg>
                      )}
                    </div>
                    <div style={{ marginTop: state ? "10px" : "0px" }}>
                      {" "}
                      {/* Moves down when image is present */}
                      <label
                        className="upload-label"
                        style={{ cursor: "pointer" }}
                      >
                        <input
                          type="file"
                          onChange={(e) => handleFileChange(e, setter)}
                          style={{ display: "none" }}
                        />
                        <span style={{ fontSize: "14px", color: "#292D32" }}>
                          {state ? "Add new Image" : "Upload"}
                        </span>
                      </label>
                    </div>
                    {state && (
                      <p
                        style={{
                          fontSize: "12px",
                          marginTop: "5px",
                          color: "#6B7280",
                        }}
                        title={state.name} // Full name will appear as a tooltip on hover
                      >
                        {state.name.length > 20
                          ? state.name.substring(0, 20) + "..."
                          : state.name}
                      </p>
                    )}
                  </div>
                ))}
              </div>

              <div
                className="second-row"
                style={{
                  display: "flex",
                  justifyContent: "space-evenly",
                  marginTop: "20px",
                }}
              >
                {[
                  {
                    label: "Driving License Front",
                    state: drivingLicenseFront,
                    setter: setDrivingLicenseFront,
                  },
                  {
                    label: "Driving License Back",
                    state: drivingLicenseBack,
                    setter: setDrivingLicenseBack,
                  },
                  {
                    label: "Police Clearance Certificate",
                    state: policeClearanceCertificate,
                    setter: setPoliceClearanceCertificate,
                  },
                ].map(({ label, state, setter }, index) => (
                  <div
                    className="file-upload-item"
                    key={index}
                    style={{
                      textAlign: "center",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        color: "#000",
                        fontFamily: "Lato",
                        fontSize: "15px",
                        fontWeight: 600,
                        marginBottom: "10px",
                      }}
                    >
                      {label}
                    </div>
                    <div
                      className="file-upload-container"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "150px",
                        height: "150px",
                        border: "6px dashed #CBD0DC",
                        borderRadius: "40px",
                        background: "#FFF",
                        position: "relative", // Needed for absolute positioning
                      }}
                    >
                      {state && (
                        <div
                          style={{
                            position: "absolute",
                            top: "5px",
                            right: "5px",
                            background: "red",
                            color: "white",
                            borderRadius: "50%",
                            width: "20px",
                            height: "20px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            cursor: "pointer",
                            zIndex: 1,
                          }}
                          onClick={() => setter(null)}
                        >
                          &#x2715;
                        </div>
                      )}
                      {state ? (
                        <img
                          className="uploaded-preview"
                          src={URL.createObjectURL(state)}
                          alt="Uploaded Preview"
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                            borderRadius: "40px",
                            position: "absolute",
                            top: 0,
                            left: 0,
                          }}
                          onClick={() => handleImageClick(state)} // Open modal on click
                        />
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="51"
                          height="50"
                          viewBox="0 0 51 50"
                          fill="none"
                          style={{ marginBottom: "10px" }}
                        >
                          <path
                            d="M16.043 40.2909H12.0429C5.91786 39.8534 3.16797 35.1451 3.16797 30.9576C3.16797 26.7701 5.91789 22.0409 11.9387 21.6242C12.7929 21.5409 13.5429 22.2076 13.6054 23.0826C13.6679 23.9367 13.0221 24.6867 12.1471 24.7492C8.10546 25.0409 6.29297 28.0825 6.29297 30.9784C6.29297 33.8742 8.10546 36.9159 12.1471 37.2076H16.043C16.8971 37.2076 17.6055 37.9159 17.6055 38.7701C17.6055 39.6242 16.8971 40.2909 16.043 40.2909Z"
                            fill="#292D32"
                          />
                          <path
                            d="M35.2274 40.2932C35.1857 40.2932 35.1649 40.2932 35.1233 40.2932C34.2691 40.2932 33.4775 39.5849 33.4775 38.7307C33.4775 37.8349 34.1441 37.1682 35.0191 37.1682C37.5816 37.1682 39.8733 36.2724 41.665 34.6682C44.915 31.8349 45.1233 27.7516 44.2483 24.8766C43.3733 22.0224 40.9358 18.7516 36.6858 18.2307C35.9983 18.1474 35.4565 17.6266 35.3315 16.9391C34.4982 11.9391 31.8108 8.48072 27.7275 7.23072C23.5191 5.91822 18.6024 7.20988 15.5399 10.4182C12.5607 13.5224 11.8732 17.8766 13.6024 22.6682C13.8941 23.4807 13.4775 24.3765 12.665 24.6682C11.8525 24.9599 10.9566 24.5432 10.665 23.7307C8.56079 17.8557 9.51914 12.2307 13.29 8.27239C17.1441 4.23072 23.3316 2.62654 28.6441 4.25154C33.5191 5.75154 36.9566 9.77237 38.2066 15.3557C42.4566 16.314 45.8733 19.5432 47.2274 24.0016C48.7066 28.8557 47.3733 33.8557 43.7274 37.0224C41.4149 39.1057 38.3941 40.2932 35.2274 40.2932Z"
                            fill="#292D32"
                          />
                          <path
                            d="M25.4987 46.4193C21.3112 46.4193 17.3946 44.1901 15.2487 40.5859C15.0196 40.2318 14.7904 39.8151 14.6029 39.3568C13.8946 37.8776 13.5195 36.1901 13.5195 34.4401C13.5195 27.8359 18.8945 22.4609 25.4987 22.4609C32.1029 22.4609 37.4779 27.8359 37.4779 34.4401C37.4779 36.2109 37.1029 37.8776 36.3529 39.4193C36.1862 39.8151 35.9571 40.2318 35.7071 40.6276C33.6029 44.1901 29.6862 46.4193 25.4987 46.4193ZM25.4987 25.5859C20.6237 25.5859 16.6445 29.5651 16.6445 34.4401C16.6445 35.7318 16.9154 36.9401 17.4362 38.0443C17.6029 38.3984 17.7487 38.6901 17.9153 38.9609C19.4987 41.6484 22.3945 43.2943 25.4778 43.2943C28.5612 43.2943 31.457 41.6484 33.0195 39.0026C33.207 38.6901 33.3738 38.3984 33.4988 38.1068C34.0613 36.9609 34.332 35.7526 34.332 34.4609C34.3528 29.5651 30.3737 25.5859 25.4987 25.5859Z"
                            fill="#292D32"
                          />
                          <path
                            d="M24.3112 38.0611C23.9154 38.0611 23.5196 37.9153 23.2071 37.6028L21.1445 35.5403C20.5404 34.9361 20.5404 33.9361 21.1445 33.3319C21.7487 32.7278 22.7487 32.7278 23.3529 33.3319L24.3529 34.3319L27.6862 31.2486C28.3321 30.6653 29.3112 30.7069 29.8946 31.3319C30.4779 31.9569 30.4363 32.957 29.8113 33.5403L25.3738 37.6445C25.0613 37.9153 24.6862 38.0611 24.3112 38.0611Z"
                            fill="#292D32"
                          />
                        </svg>
                      )}
                    </div>
                    <div style={{ marginTop: state ? "10px" : "0px" }}>
                      <label
                        className="upload-label"
                        style={{ cursor: "pointer" }}
                      >
                        <input
                          type="file"
                          onChange={(e) => handleFileChange(e, setter)}
                          style={{ display: "none" }}
                        />
                        <span style={{ fontSize: "14px", color: "#292D32" }}>
                          {state ? "Add new Image" : "Upload"}
                        </span>
                      </label>
                    </div>
                    {state && (
                      <p
                        style={{
                          fontSize: "12px",
                          marginTop: "5px",
                          color: "#6B7280",
                        }}
                        title={state.name} // Full name will appear as a tooltip on hover
                      >
                        {state.name.length > 20
                          ? state.name.substring(0, 20) + "..."
                          : state.name}
                      </p>
                    )}
                  </div>
                ))}
              </div>

              <div
                className="button-container"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="128"
                  height="36"
                  viewBox="0 0 128 36"
                  fill="none"
                  onClick={handlePrevious}
                  style={{ cursor: "pointer" }}
                >
                  <path
                    d="M0 9C0 4.02944 4.02944 0 9 0H106.435C108.412 0 110.335 0.651323 111.905 1.85331L123.663 10.8533C128.369 14.4556 128.369 21.5444 123.663 25.1467L111.905 34.1467C110.335 35.3487 108.412 36 106.435 36H8.99999C4.02943 36 0 31.9706 0 27V9Z"
                    fill="#38B000"
                    transform="rotate(180, 64, 18)"
                  />
                  <text
                    x="55%"
                    y="50%"
                    dominant-baseline="middle"
                    text-anchor="middle"
                    fill="#FFF"
                    font-family="Inter, Arial, sans-serif"
                    font-size="23"
                    font-weight="700"
                    font-style="normal"
                    line-height="normal"
                  >
                    Previous
                  </text>
                </svg>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <button
                    style={{
                      display: "flex",
                      width: "133px",
                      height: "36px",
                      padding: "9px 17px",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "10px",
                      flexShrink: 0,
                      borderRadius: "5px",
                      margin: "10px",
                      border: "0.5px solid #38B000",
                      background: "#38B000",
                      cursor: "pointer",
                      color: "#FFF",
                      fontFamily: "Inter, sans-serif",
                      fontSize: "24px",
                      fontStyle: "normal",
                      fontWeight: "700",
                      lineHeight: "normal",
                    }}
                    onClick={handleSubmit}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
            {isModalOpen && (
              <div
                className="modal-overlay"
                style={{
                  position: "fixed",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  zIndex: 1000,
                }}
                onClick={() => setIsModalOpen(false)} // Optionally close on clicking outside
              >
                <div
                  className="modal-content"
                  style={{
                    position: "relative",
                    background: "#FFF",
                    padding: "20px",
                    borderRadius: "8px",
                  }}
                  onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside modal
                >
                  {/* Close Icon */}
                  <span
                    onClick={() => setIsModalOpen(false)}
                    style={{
                      position: "absolute",
                      top: "0px",
                      right: "5px",
                      cursor: "pointer",
                      fontSize: "24px",
                    }}
                  >
                    &times;
                  </span>
                  {/* Display the large image */}
                  <img
                    src={modalImageUrl}
                    alt="Enlarged Preview"
                    style={{
                      width: "500px",
                      height: "auto",
                      borderRadius: "8px",
                    }}
                  />
                </div>
              </div>
            )}
          </>
        );
      default:
        return null;
    }
  };

  return (
    <div className="add-new-car">
      <Toaster />
      <div className="step-navigation">
        <div
          className={`step-item ${
            step === 1 ? "active-step" : step > 1 ? "completed-step" : ""
          }`}
        >
          Driver Information{" "}
          {step > 1 && (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={15}
              height={10.757}
              viewBox="0 0 15 11"
              style={{ flexShrink: 0, marginLeft: "5px" }}
            >
              <path
                d="M4.66573 8.74738L5.37284 9.45448L6.07995 8.74738L13.7938 1.03352C13.8128 1.01452 13.8423 1 13.8821 1C13.9219 1 13.9514 1.01452 13.9704 1.03352C13.9894 1.05252 14.0039 1.08205 14.0039 1.12181C14.0039 1.16157 13.9894 1.1911 13.9704 1.2101L5.46113 9.71935C5.43642 9.74406 5.40476 9.75663 5.37284 9.75663C5.34092 9.75663 5.30926 9.74407 5.28455 9.71935L1.03743 5.47223C1.01843 5.45324 1.00391 5.4237 1.00391 5.38394C1.00391 5.34418 1.01843 5.31465 1.03743 5.29565C1.05642 5.27665 1.08595 5.26213 1.12572 5.26213C1.16548 5.26213 1.19501 5.27665 1.21401 5.29565L4.66573 8.74738Z"
                style={{ fill: "#38B000", stroke: "#38B000", strokeWidth: 2 }}
              />
            </svg>
          )}
        </div>
        <div
          className={`step-item ${
            step === 2 ? "active-step" : step > 2 ? "completed-step" : ""
          }`}
        >
          Bank Account{" "}
          {step > 2 && (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={15}
              height={10.757}
              viewBox="0 0 15 11"
              style={{ flexShrink: 0, marginLeft: "5px" }}
            >
              <path
                d="M4.66573 8.74738L5.37284 9.45448L6.07995 8.74738L13.7938 1.03352C13.8128 1.01452 13.8423 1 13.8821 1C13.9219 1 13.9514 1.01452 13.9704 1.03352C13.9894 1.05252 14.0039 1.08205 14.0039 1.12181C14.0039 1.16157 13.9894 1.1911 13.9704 1.2101L5.46113 9.71935C5.43642 9.74406 5.40476 9.75663 5.37284 9.75663C5.34092 9.75663 5.30926 9.74407 5.28455 9.71935L1.03743 5.47223C1.01843 5.45324 1.00391 5.4237 1.00391 5.38394C1.00391 5.34418 1.01843 5.31465 1.03743 5.29565C1.05642 5.27665 1.08595 5.26213 1.12572 5.26213C1.16548 5.26213 1.19501 5.27665 1.21401 5.29565L4.66573 8.74738Z"
                style={{ fill: "#38B000", stroke: "#38B000", strokeWidth: 2 }}
              />
            </svg>
          )}
        </div>
        <div className={`step-item ${step === 3 ? "active-step" : ""}`}>
          Documents
        </div>
      </div>

      <form>{renderStep()}</form>
    </div>
  );
};

export default AddNewCar;
