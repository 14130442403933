import { useEffect, useState } from "react";
import {
  Routes,
  Route,
  useNavigate,
  useLocation,
  Link,
} from "react-router-dom";
import "./Corporate.css";
import { useSelector, useDispatch } from "react-redux";
import { setBooleanValue, selectBooleanValue } from "./CorporateBookingSlice";
import CorporateBookings from "./CorporateBookings";
import CorporateUser from "./CorporateUser";
import InactiveAccount from "./InactiveAccount";
import AddNewCorporate from "./AddNewCorporate";
import UpdateCorporate from "./UpdateCorporate";
import ApprovalPending from "./ApprovalPending";
import { IoIosArrowDown } from "react-icons/io";

const Corporate = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [activeComponent, setActiveComponent] = useState("corporatebookings");

  const create_mode = useSelector(selectBooleanValue) || false;

  const formatTitle = (str) => {
    return str
      .replace(/-/g, " ")
      .replace(/\b\w/g, (char) => char.toUpperCase());
  };

  useEffect(() => {
    const currentPath = location.pathname.split("/").pop();
    if (location.pathname === "/corporatebooking") {
      navigate("/corporatebooking/corporatebookings");
      setActiveComponent("corporatebookings");
    } else {
      setActiveComponent(currentPath);
    }
  }, [location.pathname, navigate]);
  

  const handleActiveComponent = (type) => {
    setActiveComponent(type);
    if (type === "addnewcorporate") {
      dispatch(setBooleanValue(true));
    } else {
      dispatch(setBooleanValue(false));
    }
    navigate(`/corporatebooking/${type}`);
  };

  return (
    <div style={{ minWidth: "81dvw", paddingRight: "10px", marginTop: "20px" }}>
      <div className="corporate-management">
 
          <div className="btn-container">
            <button
            style={{ width: "130px" }}
              className={
                activeComponent === "corporatebookings"
                  ? "createButton-corporate-active"
                  : "createButton-corporate"
              }
              onClick={() => handleActiveComponent("corporatebookings")}
            >
              Corporate Booking
            </button>

            <button
            style={{ width: "120px" }}
              className={
                activeComponent === "corporateuser"
                  ? "createButton-corporate-active"
                  : "createButton-corporate"
              }
              onClick={() => handleActiveComponent("corporateuser")}
            >
              Corporate Users
            </button>


            <button
            style={{ width: "120px" }}
              className={
                activeComponent === "approvalpending"
                  ? "createButton-corporate-active"
                  : "createButton-corporate"
              }
              onClick={() => handleActiveComponent("approvalpending")}
            >
              Approval Pending
            </button>


            <button
            style={{ width: "120px" }}
              className={
                activeComponent === "inactiveaccount"
                  ? "createButton-corporate-active"
                  : "createButton-corporate"
              }
              onClick={() => handleActiveComponent("inactiveaccount")}
            >
              Inactive Account
            </button>
            <button
              style={{ width: "140px" }}
              className={
                activeComponent === "addnewcorporate"
                  ? "createButton-corporate-active"
                  : "createButton-corporate"
              }
              onClick={() => handleActiveComponent("addnewcorporate")}
            >
              Add New Corporate
            </button>
          </div>
      </div>

      <div>
        <Routes>
          <Route path="corporatebookings" element={<CorporateBookings />} />
          <Route path="corporateuser" element={<CorporateUser />} />
          <Route path="approvalpending" element={<ApprovalPending />} />
          <Route path="inactiveaccount" element={<InactiveAccount />} />
          <Route path="addnewcorporate" element={<AddNewCorporate />} />
          <Route path="updatecorporate" element={<UpdateCorporate />} />
        </Routes>
      </div>
    </div>
  );
};

export default Corporate;
