import React, { useEffect } from "react";
import { Routes, Route, useNavigate, useLocation, Link } from "react-router-dom";
import ViewAllCustomer from "./ViewAllCustomer";
import AddCustomer from "./AddCustomer";
import { FaLongArrowAltRight } from "react-icons/fa";
import "./Customer.css";
import { useSelector, useDispatch } from "react-redux";
import { resetCompState } from "./CustomerDetailsSclice";

const Customer = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { currentComp } = useSelector((state) => state.customerInfo);

  useEffect(() => {
    if (location.pathname === "/customer") {
      navigate("/customer/view-all-customer");
    }
  }, [navigate, location.pathname]);

  useEffect(() => {
    if (currentComp !== null) {
      navigate(`/customer/${currentComp.toLowerCase().replace(/ /g, "-")}`);
      dispatch(resetCompState(null));
    }
  }, [currentComp, navigate, dispatch]);

  const handleActiveComponent = (buttonType) => {
    navigate(`/customer/${buttonType.toLowerCase().replace(/ /g, "-")}`);
  };

  const getActiveComponentName = () => {
    return location.pathname.split("/").pop().replace(/-/g, " ").replace(/\b\w/g, (c) => c.toUpperCase());
  };

  return (
    <div className="carmangement-header" style={{ width: "100%", overflowX: "auto", height: "100%" }}>
      <div className="booking">
        <div className="header">
          <div className="header-text">
            <Link to="/dashboard" className="h3-booking">
              Customer
            </Link>{" "}
            <FaLongArrowAltRight />{" "}
            <Link to="/customer" className="h2-booking">
              {getActiveComponentName()}
            </Link>
          </div>
          <div className="btns">
            <button
              className={location.pathname.includes("view-all-customer") ? "createButton-cars" : "createButton"}
              onClick={() => handleActiveComponent("View All Customer")}
            >
              View All Customer
            </button>
            <button
              className={location.pathname.includes("add-customer") ? "createButton-cars" : "createButton"}
              onClick={() => handleActiveComponent("Add Customer")}
            >
              Add Customer
            </button>
          </div>
        </div>
      </div>

      <div>
        <Routes>
          <Route path="view-all-customer" element={<ViewAllCustomer />} />
          <Route path="add-customer" element={<AddCustomer />} />
        </Routes>
      </div>
    </div>
  );
};

export default Customer;
