import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// 1. Define a thunk to fetch corporate users
export const fetchCorporateUsers = createAsyncThunk(
  "corporateUsers/fetchCorporateUsers",
  async () => {
    // Replace with your real API endpoint for corporate users
    const response = await fetch("/api/corporateUsers");
    const data = await response.json();
    return data;
  }
);

// For backward compatibility with existing component imports
export const fetchCorporateBookings = fetchCorporateUsers;

// 2. Create a slice with an extra boolean state
const corporateUserSlice = createSlice({
  name: "corporateUsers", // internal slice name; doesn't affect the store key
  initialState: {
    data: [],
    status: "idle",
    error: null,
    booleanValue: false, // New boolean state
  },
  reducers: {
    // Reducer to update the boolean value
    setBooleanValue(state, action) {
      state.booleanValue = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCorporateUsers.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchCorporateUsers.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload; // your fetched corporate user array
      })
      .addCase(fetchCorporateUsers.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

// 3. Export the default reducer
export default corporateUserSlice.reducer;

// 4. Export selectors referencing the correct store key: corporateBookings
export const selectAllCorporateUsers = (state) => state.corporateBookings.data;
export const selectBooleanValue = (state) => state.corporateBookings.booleanValue;

// Re-export with legacy names for backward compatibility
export const selectAllCorporateBookings = selectAllCorporateUsers;

// 5. Export the reducer action for the boolean value
export const { setBooleanValue } = corporateUserSlice.actions;
