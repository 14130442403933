import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import "./CreateNewTariffPage.css";
import DropdownColumn from "../Booking/Dropdown";
import {
  addLocalTariffFun,
  addLocalAirportTariffFun,
  addOutstationTariffFun,
} from "./Action";

const InputColumn = ({ title, selectedValue, handleChange }) => {
  return (
    <div className="dropdown-column">
      <div className="title">{title}</div>

      <input
        type="text"
        value={selectedValue}
        onChange={handleChange}
        className="input-field"
      />
    </div>
  );
};

const CreateNewTariffPage = () => {
  const [tripType, setTripType] = useState("Local");
  const [subTripType, setSubTripType] = useState("");
  const [selectedLocation, setSelectedLocation] = useState("");
  const [selectedCarCategory, setSelectedCarCategory] = useState("");
  const [selectedDistance, setSelectedDistance] = useState("");
  const [selectedDays, setSelectedDays] = useState("");
  const [selectedFuel, setSelectedFuel] = useState("");
  const [selectedBaseFare, setSelectedBaseFare] = useState("");
  const [selectedMinKm, setSelectedMinKm] = useState("");
  const [selectedMinHours, setSelectedMinHours] = useState("");
  const [selectedExtraKm, setSelectedExtraKm] = useState("");
  const [selectedExtraHours, setSelectedExtraHours] = useState("");
  const [selectedGst, setSelectedGst] = useState("");
  const [selectedExtraMorBata, setSelectedExtraMorBata] = useState("");
  const [selectedNightBata, setSelectedNightBata] = useState("");
  const [selectedDayBata, setSelectedDayBata] = useState("");
  const [selectedPerKmCharge, setSelectedPerKmCharge] = useState("");
  const [selectedExtraKmCharge, setSelectedExtraKmCharge] = useState("");
  const [selectedNightCharge, setSelectedNightCharge] = useState("");
  const [selectedDayDriverAllowance, setSelectedDayDriverAllowance] =
    useState("");

  const [tripTypes, setTripTypes] = useState([]);
  const [subTripTypeOptions, setSubTripTypeOptions] = useState([]);

  console.log(tripType);

  const [locationOptions, setLocationOptions] = useState([]);
  const [carOptions, setCarOptions] = useState([]);
  const [daysOptions, setDayOptions] = useState([]);
  const [distanceOptions, setDistanceOptions] = useState([]);
  const [fuelOptions, setFuelOptions] = useState([]);

  const dispatch = useDispatch();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (tripType === "Airport") {
      console.log("airport");
      const airportObj = {
        locationId: selectedLocation,
        carCategoryId: selectedCarCategory,
        tripType: tripType,
        subTripType: subTripType,
        distance: selectedDistance,
        fuelType: selectedFuel,
        days: selectedDays,
        morningDriverAllowance: selectedExtraMorBata,
        nightDriverAllowance: selectedNightBata,
        dayDriverAllowance: selectedDayBata,
        extraKmCharge: selectedExtraKm,
        gstRate: selectedGst,
        baseFare: selectedBaseFare,
        minKm: selectedMinKm,
        minHrs: selectedMinHours,
        extraHrsCharge: selectedExtraHours,
      };
      dispatch(addLocalAirportTariffFun(airportObj));
      console.log(airportObj);
    } else if (tripType === "Local") {
      console.log("local tariff value");
      const localtariffObj = {
        locationId: selectedLocation,
        carCategoryId: selectedCarCategory,
        tripType: tripType,
        subTripType: subTripType,
        distance: selectedDistance,
        fuelType: selectedFuel,
        days: selectedDays,
        morningDriverAllowance: selectedExtraMorBata,
        nightDriverAllowance: selectedNightBata,
        dayDriverAllowance: selectedDayBata,
        extraKmCharge: selectedExtraKm,
        gstRate: selectedGst,
        baseFare: selectedBaseFare,
        minKm: selectedMinKm,
        minHrs: selectedMinHours,
        extraHrsCharge: selectedExtraHours,
      };
      dispatch(addLocalTariffFun(localtariffObj));
      console.log(localtariffObj);
    } else {
      console.log("outstation tarif value");
      const outstationtariffObj = {
        locationId: selectedLocation,
        carCategoryId: selectedCarCategory,
        tripType: tripType,
        subTripType: subTripType,
        distance: selectedDistance,
        fuelType: selectedFuel,
        days: selectedDays,
        morningDriverAllowance: selectedExtraMorBata,
        nightDriverAllowance: selectedNightCharge,
        dayDriverAllowance: selectedDayDriverAllowance,
        extraKmCharge: selectedExtraKmCharge,
        gstRate: selectedGst,
        minKmPerDay: selectedMinKm,
        perKmCharge: selectedPerKmCharge,
      };
      dispatch(addOutstationTariffFun(outstationtariffObj));
    }
  };

  // Fetch data for dropdown options
  useEffect(() => {
    // Function to convert data array to options array
    const extractCategoriesAsOptions = (dataArray) => {
      if (Array.isArray(dataArray.data)) {
        return dataArray.data.map((item) => ({
          label: item.category,
          value: item._id,
        }));
      }
      return [];
    };

    // Function to convert data array to options array
    const extractLocationsAsOptions = (dataArray) => {
      if (Array.isArray(dataArray.data)) {
        console.log(dataArray.data);
        return dataArray.data.map((item) => ({
          //label: item.city,
          label: item.location,
          value: item._id,
          // Add more properties as needed
        }));
      }
      return [];
    };

    // Function to convert data array to options array
    const extractFuelAsOptions = (dataArray) => {
      if (Array.isArray(dataArray.data)) {
        return dataArray.data.map((item) => ({
          label: item.fuel,
          value: item.fuel,
          // Add more properties as needed
        }));
      }
      return [];
    };

    // Function to convert data array to options array
    const extractDistanceAsOptions = (dataArray) => {
      if (Array.isArray(dataArray.data)) {
        return dataArray.data.map((item) => ({
          label: item.distance,
          value: item.distance,
          // Add more properties as needed
        }));
      }
      return [];
    };

    // Function to convert data array to options array
    const extractDayAsOptions = (dataArray) => {
      if (Array.isArray(dataArray.data)) {
        return dataArray.data.map((item) => ({
          label: item.day,
          value: item.day,
          // Add more properties as needed
        }));
      }
      return [];
    };

    const fetchOptions = async () => {
      try {
        // Fetching location options
        const locationResponse = await fetch(
          "https://api.yatricabs.com/v1/locations"
        );
        const locationData = await locationResponse.json();

        console.log(locationData.data);
        // locationData = locationData.data
        const locationOptions = extractLocationsAsOptions(locationData);
        console.log(locationOptions);

        setLocationOptions(locationOptions);

        // Fetching car category options
        const carResponse = await fetch(
          "https://api.yatricabs.com/v1/car-categories"
        );
        const carData = await carResponse.json();

        console.log(carData);
        // Convert car category object data into array format for dropdown options
        const carCategoriesOptions = extractCategoriesAsOptions(carData);

        console.log(carCategoriesOptions);

        // Set the car options state to use in your component
        setCarOptions(carCategoriesOptions);

        // Fetching Fuel options
        const fuelResponse = await fetch("https://api.yatricabs.com/v1/fuels");
        const fuelData = await fuelResponse.json();
        console.log(fuelData);
        const fuelOptions = extractFuelAsOptions(fuelData);
        console.log(fuelOptions);
        setFuelOptions(fuelOptions);

        // Fetching distance options
        const distanceResponse = await fetch(
          "https://api.yatricabs.com/v1/distances"
        );
        const distanceData = await distanceResponse.json();
        console.log(distanceData);
        const distanceOptions = extractDistanceAsOptions(distanceData);
        console.log(distanceOptions);
        setDistanceOptions(distanceOptions);

        // Fetching day options
        const dayResponse = await fetch("https://api.yatricabs.com/v1/days");
        const dayData = await dayResponse.json();
        console.log(dayData);
        const dayOptions = extractDayAsOptions(dayData);
        console.log(dayOptions);
        setDayOptions(dayOptions);
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };

    fetchOptions();
  }, []);

  useEffect(() => {
    // Function to convert data array to options array
    const extractTripTypesAsOptions = (dataArray) => {
      if (Array.isArray(dataArray.data)) {
        return dataArray.data.map((item) => ({
          label: item.tripType,
          value: item.tripType,
          // Add more properties as needed
        }));
      }
      return [];
    };
    const fetchTripTypes = async () => {
      try {
        const response = await fetch(`https://api.yatricabs.com/v1/trip-types`);
        if (!response.ok) {
          throw new Error("Network response was not ok.");
        }
        const data = await response.json();
        console.log(data);
        const triptTypeOptions = extractTripTypesAsOptions(data);
        setTripTypes(triptTypeOptions); // Update trip types state with fetched data
      } catch (error) {
        console.error("Error fetching trip types:", error);
      }
    };

    fetchTripTypes();
  }, []);

  useEffect(() => {
    const extractSubTripTypesAsOptions = (dataArray) => {
      if (Array.isArray(dataArray.data)) {
        // console.log(typeof dataArray.data);
        // console.log(dataArray.data);
        // console.log(dataArray.data[0]);
        const opt = dataArray.data[0];
        console.log(opt);
        return opt.subTripTypes.map((subTripTypes) => ({
          label: subTripTypes,
          value: subTripTypes,
          // Add more properties as needed
        }));
      }
      return [];
    };
    const fetchData = async () => {
      try {
        if (tripType) {
          const response = await fetch(
            `https://api.yatricabs.com/v1/sub-trip-types/${tripType}`
          );
          console.log(response);
          if (!response.ok) {
            throw new Error("Network response was not ok.");
          }
          const data = await response.json();
          console.log(data);
          const subTripTypeOptions = extractSubTripTypesAsOptions(data);
          setSubTripTypeOptions(subTripTypeOptions); // Update sub trip types based on the selected trip type
        }
      } catch (error) {
        console.error("Error fetching sub trip types:", error);
      }
    };

    fetchData();
  }, [tripType]);

  return (
    <div className="create-new-tariff-container">
      <form onSubmit={handleSubmit}>
        <div className="price-details">
          <div className="crete-new-sub-head">Add Price Details</div>
          <div className="row">
            <DropdownColumn
              placeholder={"Select Location"}
              title="Location"
              options={locationOptions}
              selectedValue={selectedLocation}
              useInlineStyle={true}
              handleChange={(e) => setSelectedLocation(e.target.value)}
            />

            <DropdownColumn
              placeholder={"Select Car Category"}
              title="Car Category"
              options={carOptions}
              selectedValue={selectedCarCategory}
              useInlineStyle={true}
              handleChange={(e) => setSelectedCarCategory(e.target.value)}
            />

            <DropdownColumn
              placeholder={"Select Trip Type"}
              title="Trip Type"
              options={tripTypes}
              selectedValue={tripType}
              useInlineStyle={true}
              handleChange={(e) => setTripType(e.target.value)}
            />

            <DropdownColumn
              placeholder={"Select Sub Trip"}
              title="Sub Trip Type"
              options={subTripTypeOptions}
              selectedValue={subTripType}
              useInlineStyle={true}
              handleChange={(e) => {
                setSubTripType(e.target.value);
              }}
            />
          </div>

          <div className="row">
            <DropdownColumn
              placeholder={"Select Distance"}
              title="Distance"
              options={distanceOptions}
              selectedValue={selectedDistance}
              useInlineStyle={true}
              handleChange={(e) => setSelectedDistance(e.target.value)}
            />

            <DropdownColumn
              placeholder={"Select Fuel"}
              title="Fuel"
              options={fuelOptions}
              selectedValue={selectedFuel}
              useInlineStyle={true}
              handleChange={(e) => setSelectedFuel(e.target.value)}
            />

            <DropdownColumn
              placeholder={"Select Days"}
              title="Days"
              options={daysOptions}
              selectedValue={selectedDays}
              useInlineStyle={true}
              handleChange={(e) => setSelectedDays(e.target.value)}
            />
          </div>
        </div>

        {(tripType === "Local" || tripType === "Airport") && (
          <div className="price-slab">
            <div className="crete-new-sub-head">Pricing Slab</div>
            <div className="row">
              <InputColumn
                title="Base Fare"
                selectedValue={selectedBaseFare}
                handleChange={(e) => setSelectedBaseFare(e.target.value)}
              />

              <InputColumn
                title="Min Km"
                selectedValue={selectedMinKm}
                handleChange={(e) => setSelectedMinKm(e.target.value)}
              />

              <InputColumn
                title="Min Hours"
                selectedValue={selectedMinHours}
                handleChange={(e) => setSelectedMinHours(e.target.value)}
              />
            </div>

            <div className="row">
              <InputColumn
                title="Extra KM"
                selectedValue={selectedExtraKm}
                handleChange={(e) => setSelectedExtraKm(e.target.value)}
              />

              <InputColumn
                title="Extra Hours"
                selectedValue={selectedExtraHours}
                handleChange={(e) => setSelectedExtraHours(e.target.value)}
              />

              <InputColumn
                title="GST"
                selectedValue={selectedGst}
                handleChange={(e) => setSelectedGst(e.target.value)}
              />
            </div>
          </div>
        )}

        {(tripType === "Local" || tripType === "Airport") && (
          <div className="driver-allowance">
            <div className="crete-new-sub-head">Driver Allowance</div>

            <div className="row">
              <InputColumn
                title="Extra Morning Bata"
                selectedValue={selectedExtraMorBata}
                handleChange={(e) => setSelectedExtraMorBata(e.target.value)}
              />

              <InputColumn
                title="Night Bata"
                selectedValue={selectedNightBata}
                handleChange={(e) => setSelectedNightBata(e.target.value)}
              />

              <InputColumn
                title="Day Bata"
                selectedValue={selectedDayBata}
                handleChange={(e) => setSelectedDayBata(e.target.value)}
              />
            </div>
          </div>
        )}

        {tripType === "Outstation" && (
          <div>
            <div className="price-slab">
              <div className="crete-new-sub-head">Pricing Slab</div>
              <div className="row">
                <InputColumn
                  title="Min Km/day"
                  selectedValue={selectedMinKm}
                  handleChange={(e) => setSelectedMinKm(e.target.value)}
                />

                <InputColumn
                  title="Per Km Charge"
                  selectedValue={selectedPerKmCharge}
                  handleChange={(e) => setSelectedPerKmCharge(e.target.value)}
                />

                <InputColumn
                  title="Extra KM Charge"
                  selectedValue={selectedExtraKmCharge}
                  handleChange={(e) => setSelectedExtraKmCharge(e.target.value)}
                />

                <InputColumn
                  title="GST"
                  selectedValue={selectedGst}
                  handleChange={(e) => setSelectedGst(e.target.value)}
                />
              </div>
            </div>
            <div className="driver-allowance">
              <div className="crete-new-sub-head">Driver Allowance</div>
              <div className="row">
                <InputColumn
                  title="Extra Morning Bata"
                  selectedValue={selectedExtraMorBata}
                  handleChange={(e) => setSelectedExtraMorBata(e.target.value)}
                />

                <InputColumn
                  title="Night Charge"
                  selectedValue={selectedNightCharge}
                  handleChange={(e) => setSelectedNightCharge(e.target.value)}
                />

                <InputColumn
                  title="Day Driver Allowance"
                  selectedValue={selectedDayDriverAllowance}
                  handleChange={(e) =>
                    setSelectedDayDriverAllowance(e.target.value)
                  }
                />
              </div>
            </div>
          </div>
        )}

        <div className="add">
          <button className="add-btn">Add</button>
        </div>
      </form>
    </div>
  );
};

export default CreateNewTariffPage;
