// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.filter-container {
    display: flex;
    width: 82px;
    height: 22px;
    padding: 8px 16px;
    align-items: center;
    gap: 4px;
    flex-shrink: 0;
    border-radius: 2px 0px 0px 2px;
    border: 1px solid var(--gray-border-gray-5, #D9D9D9);
    background: var(--gray-light-background-gray-1, #FFF);
    margin-top: 2px;
    border-radius: 5px;
    height: 28px;
    /* width: 126px; */
    border: 1px solid white;
    box-shadow: rgba(0, 0, 0, 0.17) 0px 1px 8px 0px;
    margin-left: 6px;
}

.filter-container .text {
    color: #38B000;
    font-family: Inter;
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.my-svg {
    width: 50px;
    height: 50px;
  }`, "",{"version":3,"sources":["webpack://./src/components/Booking/Filter.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,WAAW;IACX,YAAY;IACZ,iBAAiB;IACjB,mBAAmB;IACnB,QAAQ;IACR,cAAc;IACd,8BAA8B;IAC9B,oDAAoD;IACpD,qDAAqD;IACrD,eAAe;IACf,kBAAkB;IAClB,YAAY;IACZ,kBAAkB;IAClB,uBAAuB;IACvB,+CAA+C;IAC/C,gBAAgB;AACpB;;AAEA;IACI,cAAc;IACd,kBAAkB;IAClB,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,mBAAmB;AACvB;AACA;IACI,WAAW;IACX,YAAY;EACd","sourcesContent":[".filter-container {\n    display: flex;\n    width: 82px;\n    height: 22px;\n    padding: 8px 16px;\n    align-items: center;\n    gap: 4px;\n    flex-shrink: 0;\n    border-radius: 2px 0px 0px 2px;\n    border: 1px solid var(--gray-border-gray-5, #D9D9D9);\n    background: var(--gray-light-background-gray-1, #FFF);\n    margin-top: 2px;\n    border-radius: 5px;\n    height: 28px;\n    /* width: 126px; */\n    border: 1px solid white;\n    box-shadow: rgba(0, 0, 0, 0.17) 0px 1px 8px 0px;\n    margin-left: 6px;\n}\n\n.filter-container .text {\n    color: #38B000;\n    font-family: Inter;\n    font-size: 10px;\n    font-style: normal;\n    font-weight: 700;\n    line-height: normal;\n}\n.my-svg {\n    width: 50px;\n    height: 50px;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
