import React from "react";

const LocalDetails = (props) => {
  const { carCategoriesDetails, selectedCabType, onEstimatedFareChange } =
    props;

  // console.log("Selected cabType", selectedCabType);
  const cabType = carCategoriesDetails?.find(
    (item) => item?.carCategory?.category === selectedCabType
  );

  console.log("Selected cabType", cabType);

  const fareDetails = cabType?.fareDetails;

  const hourlyPackage = cabType?.subTripType;
  console.log("HourlyPackage", hourlyPackage);
  const splitValues = hourlyPackage?.split(" ");

  let hours = 0;
  let Kilometer = 0;
  if (splitValues) {
    hours = parseInt(splitValues[0]);
    Kilometer = parseInt(splitValues[1]);
  }

  console.log("Hours:", hours); // Output: 4
  console.log("Kilometer:", Kilometer); // Output: 40
  // console.log("details", fareDetails);
  onEstimatedFareChange(fareDetails?.estimatedFare);
  return (
    <>
      <div className="booking-section-2">
        <div className="fare-details">
          <div className="fare-head">
            <div className="fare-head-content">Fare Details</div>
          </div>
          <div className="rows">
            <div className="row-head">
              <div className="col col-head">Ride Estimate</div>
              <div className="col col-head">
                <div className="col-item">
                  {" "}
                  Rs.{" "}
                  {fareDetails && fareDetails?.estimatedFare
                    ? fareDetails?.estimatedFare.toFixed(2)
                    : "00.00"}
                </div>
                <div className="col-sub-item">(incl. of all taxes)</div>
              </div>
            </div>

            <hr />
            <div className="row">
              <div className="col1">Included Hrs</div>
              <div className="col"> {hours} Hrs</div>
            </div>

            <div className="row">
              <div className="col1">Included KM</div>
              <div className="col">{Kilometer} KM</div>
            </div>

            <div className="row">
              <div className="col1">Base Fare</div>
              <div className="col">
                Rs.{" "}
                {fareDetails && fareDetails?.baseFare
                  ? fareDetails?.baseFare.toFixed(2)
                  : "00.00"}
              </div>
            </div>

            <div className="row">
              <div className="col1">GST @ 5%</div>
              <div className="col">
                Rs.{" "}
                {fareDetails && fareDetails?.gstAmount
                  ? fareDetails?.gstAmount.toFixed(2)
                  : "00.00"}
              </div>
            </div>


            {/* <div className="row">
              <div className="col1">Driver charges</div>
              <div className="col grn">
                {" "}
                Rs.{" "}
                {fareDetails && fareDetails.dayDriverAllowance
                  ? fareDetails.dayDriverAllowance.toFixed(2)
                  : "00.00"}
              </div>
            </div> */}


            {/* <div className="row">
              <div className="col1">Night charges</div>
              <div className="col">
                {" "}
                Rs.{" "}
                {fareDetails && fareDetails.nightDriverAllowance
                  ? fareDetails.nightDriverAllowance.toFixed(2)
                  : "00.00"}
              </div>
            </div> */}


          </div>
          <div className="fare-bottom">
            <button className="grn-btn">Inclusions</button>
            <div className="green-box1">
              <div className="grn-con">Base Fare</div>

              {/* <div className="grn-con">Driver Allowance</div> */}

              <div className="grn-con">
                {" "}
                GST ({" "}
                {fareDetails && fareDetails ? fareDetails?.gstRate : "5"}% )
              </div>
            </div>


            <button className="grn-btn-2">Exclusions</button>

            <div className="green-box2">
              <div className="grn-con">
                Pay ₹
                {fareDetails && fareDetails?.extraKmCharge
                  ? fareDetails?.extraKmCharge.toFixed(2)
                  : "11"}{""}
                  {/* "00.00" */}
                /km after {"80"} km
                {/* {Kilometer} */}
              </div>


              <div className="grn-con">
                Pay ₹{"120"}/hrs 
                {fareDetails && fareDetails?.extraHrsCharge
                  ? fareDetails?.extraHrsCharge.toFixed(2)
                  : ""}{" "}after {"8"}hrs
                {/* {hours} */}
              </div>


              <div className="grn-con">Night allowance</div>
              <div className="grn-con">Toll / State tax / Parking</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LocalDetails;
