import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchInteractionDetails } from "./DetailsAPI";

const initialState = {
  interactionList: [],
  status: null,
  isLoading: false,
  isError: false,
};

export const fetchInteraction = createAsyncThunk(
  "booking/interaction",
  async (id) => {
    try {
      const response = await fetchInteractionDetails(id);
      console.log(id);
      // console.log("response", response);
      return response;
    } catch (e) {
      console.log("Error in fetching Data", e);
      throw e;
    }
  }
);

const InteractionSlice = createSlice({
  name: "interaction",
  initialState,

  extraReducers: (builder) => {
    builder.addCase(fetchInteraction.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchInteraction.fulfilled, (state, action) => {
      state.isLoading = false;
      state.status = "idle";
      state.interactionList = action.payload;
    });
    builder.addCase(fetchInteraction.rejected, (state, action) => {
      state.isLoading = false;
      console.log("Error", action.payload);
      state.isError = true;
    });
  },
});

// export const selectInteraction = (state) => state.interaction.interactionList;
export const selectInteraction = (state) => {
  console.log("State:", state);
  return state.interaction.interactionList;
};

export const loading = (state) => state.interaction.isLoading;

export default InteractionSlice.reducer;
