import React from "react";
import "./table4.css";

const Table4 = ({ data, tripType }) => {
  console.log("Table 4", data, tripType);
  const subTripType = data?.data?.tariff?.subTripType || "";
  let numberOfDays = data?.numberOfDays || "1";

  if (
    tripType === "local" ||
    tripType === "airport" ||
    (tripType === "outstation" && subTripType === "one way")
  ) {
    numberOfDays = 1;
  }

  return (
    <div className="table4-container">
      <div className="table-title4">Billed to Customer</div>
      <table className="styled-table4">
        {/* Fare Breakup */}
        <thead>
          <tr>
            <th colSpan="2" className="titletable4">
              Fare Breakup
            </th>
          </tr>
        </thead>
        <tbody>
          {(() => {
            const totalAmount = data?.data?.totalAmount || 0;
            const advancePayment = data?.data?.advancePayment || 0;
            const balanceAmount = data?.data?.amountPayable ;

            return tripType === "Outstation" ? (
              <>
                <tr>
                  <td className="green-cell">Estimated Fare</td>
                  <td className="right-cell">Rs. {totalAmount.toFixed(2)}</td>
                </tr>
                <tr>
                  <td className="right-cell">Advance Payment (50%)</td>
                  <td className="right-cell">
                    Rs. {advancePayment.toFixed(2)}
                  </td>
                </tr>
                <tr>
                  <td className="green-cell">Balance Payment to Pay</td>
                  <td className="highlight-green4">
                    Rs. {balanceAmount.toFixed(2)}
                  </td>
                </tr>
              </>
            ) : (
              <>
                <tr>
                  <td className="green-cell">
                    Estimated Fare <br />
                    80 KM, 8 Hours
                  </td>
                  <td className="right-cell">Rs. {totalAmount.toFixed(2)}</td>
                </tr>
                <tr>
                  <td className="right-cell">Advance Payment (50%)</td>
                  <td className="right-cell">
                    Rs. {advancePayment.toFixed(2)}
                  </td>
                </tr>
                <tr>
                  <td className="green-cell">Balance Payment to Pay</td>
                  <td className="highlight-green4">
                    Rs. {balanceAmount.toFixed(2)}
                  </td>
                </tr>
              </>
            );
          })()}
        </tbody>

        {/* Extra Coverage */}
        <thead>
          <tr>
            <th colSpan="2" className="titletable4">
              Extra Coverage
            </th>
          </tr>
        </thead>
        <tbody>
          {tripType === "Outstation" ? (
            <>
              <tr>
                <td className="green-cell">Total Distance Covered</td>
                <td className="right-cell">{data?.data?.totalDistance} KM</td>
              </tr>
              <tr>
                <td className="green-cell">Extra KM</td>
                <td className="right-cell">{data?.data?.extraKm} KM</td>
              </tr>
              <tr>
                <td className="green-cell">Actual No. of Days</td>
                <td className="right-cell">{data?.data?.noOfDays} Days</td>
              </tr>
              <tr>
                <td className="green-cell">Extra No. of Days</td>
                <td className="right-cell">
                  {data?.data?.extraDays || "0"} Day
                </td>
              </tr>
            </>
          ) : (
            <>
              <tr>
                <td className="green-cell">Total Distance Covered</td>
                <td className="right-cell">
                  {(data?.data?.tariff?.fareDetails?.minKm || 0) +
                    (data?.data?.extraKm || 0)}{" "}
                  KM
                </td>
              </tr>
              <tr>
                <td className="green-cell">Total Hours Covered</td>
                <td className="right-cell">
                  {data?.data?.tariff?.fareDetails?.minHrs || "N/A"} Hrs
                </td>
              </tr>
              <tr>
                <td className="green-cell">Extra KM</td>
                <td className="right-cell">{data?.data?.extraKm} KM</td>
              </tr>
              <tr>
                <td className="green-cell">Extra Hours</td>
                <td className="right-cell">{data?.data?.extraHrs} Hr</td>
              </tr>
              <tr>
                <td className="green-cell">Night Allowance</td>
                <td className="right-cell">
                  Rs. {data?.data?.extraNightAllowance || "0"}
                </td>
              </tr>
            </>
          )}
        </tbody>

        {/* Extra Fare */}
        <thead>
          <tr>
            <th colSpan="2" className="titletable4">
              Extra Fare
            </th>
          </tr>
        </thead>
        <tbody>
          {tripType === "Outstation" ? (
            <>
              <tr>
                <td className="green-cell">
                  Extra KM Charge{" "}
                  <div>
                    @ Rs.{" "}
                    {data?.data?.vendorTariff?.fareDetails?.extraKmCharge ||
                      "N/A"}{" "}
                    / KM
                  </div>
                </td>
                <td className="right-cell">
                  Rs.{" "}
                  {(
                    (data?.data?.extraKm || 0) *
                    (data?.data?.vendorTariff?.fareDetails?.extraKmCharge || 0)
                  ).toFixed(2)}
                </td>
              </tr>

              <tr>
                <td className="green-cell">
                  Extra Night Allowance{" "}
                  <div>
                    @ Rs.{" "}
                    {data?.data?.vendorTariff?.fareDetails
                      ?.nightDriverAllowance || "N/A"}{" "}
                    / Day
                  </div>
                </td>
                <td className="right-cell">
                  Rs.{" "}
                  {(
                    (data?.data?.extraNightAllowance || 0) * numberOfDays
                  ).toFixed(2) || "N/A"}
                </td>
              </tr>
            </>
          ) : (
            <>
              <tr>
                <td className="green-cell">
                  Extra KM Charge{" "}
                  <div>
                    @ Rs.{" "}
                    {data?.data?.vendorTariff?.fareDetails?.extraKmCharge ||
                      "N/A"}{" "}
                    / KM
                  </div>
                </td>
                <td className="right-cell">
                  Rs.{" "}
                  {(
                    (data?.data?.extraKm || 0) *
                    (data?.data?.vendorTariff?.fareDetails?.extraKmCharge || 0)
                  ).toFixed(2)}
                </td>
              </tr>

              <tr>
                <td className="green-cell">
                  Extra Hours Charge{" "}
                  <div>
                    @ Rs.{" "}
                    {data?.data?.vendorTariff?.fareDetails?.extraHrsCharge ||
                      "N/A"}{" "}
                    / Hr
                  </div>
                </td>
                <td className="right-cell">
                  Rs.{" "}
                  {(
                    (data?.data?.extraHrs || 0) *
                    (data?.data?.vendorTariff?.fareDetails?.extraHrsCharge || 0)
                  ).toFixed(2)}
                </td>
              </tr>

              <tr>
                <td className="green-cell">
                  Extra Night Allowance{" "}
                  <div>
                    @ Rs.{" "}
                    {data?.data?.vendorTariff?.fareDetails
                      ?.nightDriverAllowance || "N/A"}{" "}
                    / Day
                  </div>
                </td>
                <td className="right-cell">
                  Rs.{" "}
                  {(
                    (data?.data?.extraNightAllowance || 0) * numberOfDays
                  ).toFixed(2) || "N/A"}
                </td>
              </tr>
            </>
          )}

          {/* Calculate the total for GST */}
          {(() => {
            const extraKmCharge =
              (data?.data?.extraKm || 0) * (data?.data?.extraKmRate || 12);
            const extraHrsCharge =
              (data?.data?.extraHrs || 0) * (data?.data?.extraHrsRate || 120);
            const extraNightAllowance = data?.data?.extraNightAllowance || 0;
            const totalCharge =
              extraKmCharge + extraHrsCharge + extraNightAllowance;
            const gstAmount = (5 / 100) * totalCharge;
            const totalExtraAmount = totalCharge + gstAmount;

            const balancePaymentToPay = data?.data?.totalAmount || 0;
            const advancePayment = data?.data?.advancePayment || 0;
            const plusBalanceAmount =  data?.data?.amountPayable;

            const lessCashReceived = data?.data?.lessCashReceived || 0;
            const totalPaidCash =
              totalExtraAmount + plusBalanceAmount - lessCashReceived;

            return (
              <>
                <tr>
                  <td className="green-cell">
                    5 % GST on Rs. {totalCharge.toFixed(2)}
                  </td>
                  <td className="right-cell">Rs. {gstAmount.toFixed(2)}</td>
                </tr>
                <tr>
                  <td className="highlight-green4">Total Extra Amount</td>
                  <td className="highlight-green4">
                    Rs. {totalExtraAmount.toFixed(2)}
                  </td>
                </tr>
                <tr>
                  <td className="green-cell"  style={{alignItems:"center",alignContent:"center",textAlign:"center"}}>Plus Balance Amount</td>
                  <td className="green-cell" style={{ textAlign: "center" }}>
                    Rs. {plusBalanceAmount.toFixed(2)}
                  </td>
                </tr>
                <tr>
                  <td
                    className={
                      tripType === "Outstation"
                        ? "highlight-red4"
                        : "highlight-green4"
                    }
                  >
                    Less Cash Received
                    <br /> by Driver in Advance
                  </td>
                  <td className="highlight-green4">
                    Rs. {lessCashReceived.toFixed(2)}
                  </td>
                </tr>
                <tr>
                  <td className="green-cell" style={{alignItems:"center",alignContent:"center",textAlign:"center",fontSize:"14px"}}>
                    {tripType === "Outstation"
                      ? "Total Paid - Online"
                      : "Total Paid - Cash"}
                  </td>
                  <td className="highlight-green4">
                    Rs. {totalPaidCash.toFixed(2)}
                  </td>
                </tr>
              </>
            );
          })()}
        </tbody>
      </table>
    </div>
  );
};

export default Table4;
