// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.box-action {
display: flex;
margin-right: 10px;
align-items: center;
justify-content: center;
height:10px;
gap: 10px;
}

.box-action svg{
cursor: pointer;
}
.toggle {
  display: flex;
  align-items: center; 
}
.box-action .icon.icon-large{
font-size: 20px;
margin-left: 5px;
}`, "",{"version":3,"sources":["webpack://./src/components/TariffManagement/Toggle.css"],"names":[],"mappings":"AAAA;AACA,aAAa;AACb,kBAAkB;AAClB,mBAAmB;AACnB,uBAAuB;AACvB,WAAW;AACX,SAAS;AACT;;AAEA;AACA,eAAe;AACf;AACA;EACE,aAAa;EACb,mBAAmB;AACrB;AACA;AACA,eAAe;AACf,gBAAgB;AAChB","sourcesContent":[".box-action {\ndisplay: flex;\nmargin-right: 10px;\nalign-items: center;\njustify-content: center;\nheight:10px;\ngap: 10px;\n}\n\n.box-action svg{\ncursor: pointer;\n}\n.toggle {\n  display: flex;\n  align-items: center; \n}\n.box-action .icon.icon-large{\nfont-size: 20px;\nmargin-left: 5px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
