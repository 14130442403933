import { useState } from "react";
import Customer from "../components/Customer/Customer";
import Header from "../components/header/Header";
import Navbar from "../components/navbar/Navbar";
import { useNavigate } from "react-router-dom";

const Customers = () => {
    const [sidebar, setSidebar] = useState(false);
    const navigates = useNavigate();

    // Function to handle item click
    const handleItemClick = (itemName) => {
        // Perform actions based on the clicked item
        console.log(`Clicked on item: ${itemName}`);
        // Add further logic here, such as updating state or performing other operations
    };

    // Function to handle navigation
    const navigate = (to) => {
        // Perform navigation logic, e.g., using react-router-dom
        console.log(`Navigating to: ${to}`);
        navigates(to);
        // Implement navigation logic here, e.g., using history.push() or Link components
    };
    return (
        <div className="App">
            <Header setSidebar={setSidebar} sidebar={sidebar} />
            <div className="navbar-adminInfo">
                <Navbar
                    sidebar={sidebar}
                    handleItemClick={handleItemClick}
                    navigate={navigate}
                />
                <Customer />
            </div>
        </div>
    );
};

export default Customers;
