import React from "react";
import "./table2.css";

const Table2 = ({ data }) => {
  console.log(data);

  const tripType = data?.data?.tariff?.tripType || "N/A";

  // Extract relevant data
  const totalBillAmount = data?.data?.totalAmount || "N/A";
  const totalKmCovered = data?.data?.totalDistance;
  const totalHrsCovered = data?.data?.tariff?.fareDetails?.minHrs || "N/A";

  return (
    <table className="table2">
      <tbody>
        <tr>
          <td className="green-cell2">Total Bill Amount</td>
          <td className="right-cell2">Rs. {totalBillAmount}</td>
        </tr>
        <tr>
          <td className="green-cell2">Total KM Covered</td>
          <td className="right-cell2">{totalKmCovered} KM</td>
        </tr>
        {tripType !== "Outstation" && (
          <tr>
            <td className="green-cell2">Total Hrs Covered</td>
            <td className="right-cell2">{totalHrsCovered} Hrs</td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

export default Table2;
