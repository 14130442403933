import React, { useEffect, useState } from 'react'
import './CustomBilling.css'
import cross from '../../../assets/cross.svg'
import down_box from '../../../assets/down-arrow-box.svg'
const CustomBilling = ({ bookingDetails, open, close, type }) => {

    const [startDate, setStartDate] = useState("");
    const [startTime, setStartTime] = useState("");
    const [endDate, setEndDate] = useState("");
    const [endTime, setEndTime] = useState("");
    const [totalHours, setTotalHours] = useState(null);
    const [triptype, settriptype] = useState(type);
    const [inputDistance, setInputDistance] = useState('');
    const [fuelCash, setFuelCash] = useState('');
    const [totalDistance, setTotalDistance] = useState(0);
    const [extraKm, setExtraKm] = useState(0);
    const [totalDays, setTotalDays] = useState(0);

    useEffect(() => {
        const distance = parseFloat(inputDistance) || 0;
        setTotalDistance(distance);
        const extraDistance = distance - bookingDetails.kmIncluded;
        setExtraKm(extraDistance > 0 ? extraDistance : 0);
    }, [inputDistance]);

    const handleInputChange = (e) => {
        setInputDistance(e.target.value);
    };

    const handleFuelCashChange = (e) => {
        setFuelCash(e.target.value);
    };
    useEffect(() => {
        setStartDate(bookingDetails.pickupDate);
        setStartTime(bookingDetails.pickupTime);
    }, [bookingDetails]);


    useEffect(() => {
        calculateTotalHours();
        calculateTotalDays();
    }, [startDate, startTime, endDate, endTime]);

    const convertToISOFormat = (dateStr) => {
        const [day, month, year] = dateStr.split("-");
        return `${year}-${month}-${day}`;
    };
    const calculateTotalDays = () => {
        if (startDate && endDate) {
            const formattedStartDate = convertToISOFormat(startDate);
            const startDateTime = new Date(formattedStartDate);
            const endDateTime = new Date(endDate);
            const diffInMs = endDateTime - startDateTime;
            const extraDays = Math.floor(diffInMs / (1000 * 60 * 60 * 24));
            setTotalDays(extraDays >= 0 ? extraDays : 0);
        }
    };

    const calculateTotalHours = () => {
        if (startDate && startTime && endDate && endTime) {
            const formattedStartDate = convertToISOFormat(startDate);
            const startDateTime = new Date(`${formattedStartDate}T${startTime}`);
            const endDateTime = new Date(`${endDate}T${endTime}`);
            const diffInMs = endDateTime - startDateTime;

            if (diffInMs >= 0) {
                const hours = diffInMs / (1000 * 60 * 60);
                setTotalHours(Math.round(hours));
            } else {
                setTotalHours("0.00");
            }
        }
    };

    useEffect(() => {
        console.log('here tripe is ...', type)
        settriptype(type)
    })


    const [openLocal, setopenLocal] = useState(false);
    const [openLocalPayTpye, setopenLocalPayTpye] = useState('online');
    const paytypeLocal = (type) => {
        setopenLocalPayTpye(type)
        setopenLocal(false)
    }

    const [openOutstaion, setopenOutstaion] = useState(false);
    const [openOutstaionPayTpye, setopenOutstaionPayTpye] = useState('online');
    const paytypeOutstation = (type) => {
        setopenOutstaionPayTpye(type)
        setopenOutstaion(false)
    }



    const parseDate = (dateStr) => {
        const [day, month, year] = dateStr.split("-").map(Number);
        return new Date(year, month - 1, day);
    };
    
    const start = parseDate(startDate);
    
    const handleEndDateChange = (e) => {
        const endDateValue = e.target.value; 
        const endDate = new Date(endDateValue);
    
        start.setHours(0, 0, 0, 0);
        endDate.setHours(0, 0, 0, 0);
    
        const oneDayInMs = 24 * 60 * 60 * 1000;
    
        if (
            endDate.getTime() === start.getTime() ||
            endDate.getTime() === start.getTime() + oneDayInMs
        ) {
            console.log("End date is valid");
            setEndDate(endDateValue)
        } else {
            alert("End date should be the same or the day after the start date");
        }
    };




    const handleEndTimeChange = (e) => {
        const endTimeValue = e.target.value;
        const endTimeDate = new Date(`1970-01-01T${endTimeValue}:00Z`);
    
        const start = new Date(startDate.split("-").reverse().join("-"));
        const end = new Date(endDate);

    
        if (start.getDate() === end.getDate() && start.getMonth() === end.getMonth() && start.getFullYear() === end.getFullYear()) {
            console.log("End time is valid");
        } else if (endTimeDate.getUTCHours() < 5 || (endTimeDate.getUTCHours() === 5 && endTimeDate.getUTCMinutes() === 0)) {
            console.log("End time is valid");
        } else {
            alert("End time should be no later than 5:00 AM");
        }
    };
   
    
    


    if (!open) return;
    return (
        <>


            <div id="big-bg-dull">
                <div className="manual-billing-container">

                    {
                        triptype === 'Local' ?

                            <>
                                <div className='cross-holder' onClick={close}><img src={cross} alt="" /></div>
                                <h1>Manual Billing</h1>

                                <div className="trip-detail-container">
                                    <div className="row-flexer2">
                                        <h2>Trip Type</h2>
                                        <p>Local - {bookingDetails.hrsIncluded}  HRS | {bookingDetails.kmIncluded} KM</p>
                                    </div>

                                    <div className="row-flexer2">
                                        <h2>Trip Start Date & Time</h2>
                                        <p>{bookingDetails.pickupDate}   &ensp; &ensp;     {bookingDetails.pickupTime}</p>
                                    </div>

                                    <div className="row-flexer2">
                                        <h2>Trip End Date & Time</h2>
                                        <div className="sub-datetime-row">
                                            <input type="date"
                                                // value={endDate}
                                                // onChange={(e) => setEndDate(e.target.value)}
                                                onChange={handleEndDateChange}
                                            />
                                            <input type="time"
                                                // value={endTime}
                                                // onChange={(e) => setEndTime(e.target.value)}
                                                onChange={handleEndTimeChange} 
                                                />
                                        </div>
                                    </div>
                                </div>


                                <div className="mannual-booking-customize-container">
                                    <h2>Fare Breakup</h2>

                                    <div className="flexer">
                                        <div className="l clr-white green">Estimated Fare <p className='p-small'>{bookingDetails.kmIncluded} Km,  {bookingDetails.hrsIncluded} Hours </p></div>
                                        <div className="r">Rs. {bookingDetails.estimatedFare}</div>
                                    </div>

                                    <div className="flexer">
                                        <div className="l ">Advance Payment {bookingDetails.partialPaymentPercentage}%</div>
                                        <div className="r">Rs. {bookingDetails.advancePaymentReceived}</div>
                                    </div>

                                    <div className="flexer down-line">
                                        <div className="l clr-white green">Balance Payment to Pay</div>
                                        <div className="r clr-green">Rs. {bookingDetails.amountPayable}</div>
                                    </div>

                                    <h2 >Extra Coverage</h2>

                                    <div className="flexer">
                                        <div className="l green clr-white flx-row">Total Distance Covered <span> *
                                        </span></div>
                                        <div className="r light-green last"><input type="text"
                                            value={inputDistance}
                                            onChange={handleInputChange} /> &ensp; km</div>
                                    </div>

                                    <div className="flexer">
                                        <div className="l green clr-white ">Total Hours Covered </div>
                                        <div className="r light-green last">{totalHours} Hrs</div>
                                    </div>

                                    <div className="flexer">
                                        <div className="l green clr-white">Extra km</div>
                                        <div className="r light-green last"> {extraKm} Km</div>
                                    </div>

                                    <div className="flexer">
                                        <div className="l green clr-white">Extra Hours</div>
                                        <div className="r light-green last"> {(totalHours - bookingDetails.hrsIncluded) > 0 ? (totalHours - bookingDetails.hrsIncluded) : 0} Hrs</div>
                                    </div>

                                    <div className="flexer down-line">
                                        <div className="l green clr-white">Night Allowance</div>
                                        <div className="r light-green start">Rs {bookingDetails.tariff.fareDetails.nightDriverAllowance}</div>
                                    </div>

                                    <h2>Extra Fare</h2>

                                    <div className="flexer">
                                        <div className="l green clr-white">Extra km charge @ Rs. {bookingDetails.tariff.fareDetails.extraKmCharge}/Km</div>
                                        <div className="r last">Rs. {bookingDetails.tariff.fareDetails.extraKmCharge * extraKm}</div>
                                    </div>

                                    <div className="flexer">
                                        <div className="l green clr-white">Extra hrs charge @ Rs. {bookingDetails.tariff.fareDetails.extraHrsCharge}/hrs</div>
                                        <div className="r last">Rs. {bookingDetails.tariff.fareDetails.extraHrsCharge * (totalHours - bookingDetails.hrsIncluded)}</div>
                                    </div>

                                    <div className="flexer">
                                        <div className="l green clr-white">Extra Night Allowance @ Rs.{bookingDetails.tariff.fareDetails.nightDriverAllowance}/day X 1 Day</div>
                                        <div className="r last">Rs. {bookingDetails.tariff.fareDetails.nightDriverAllowance}</div>
                                    </div>

                                    <div className="flexer">
                                        <div className="l green clr-white">5 % GST on Rs. {bookingDetails.tariff.fareDetails.nightDriverAllowance + bookingDetails.tariff.fareDetails.extraHrsCharge * (totalHours - bookingDetails.hrsIncluded) + bookingDetails.tariff.fareDetails.extraKmCharge * extraKm}</div>
                                        <div className="r last">Rs. {(bookingDetails.tariff.fareDetails.nightDriverAllowance + bookingDetails.tariff.fareDetails.extraHrsCharge * (totalHours - bookingDetails.hrsIncluded) + bookingDetails.tariff.fareDetails.extraKmCharge * extraKm) * 0.05}</div>
                                    </div>

                                    <div className="flexer">
                                        <div className="l clr-green fnt-14px">Total Extra Amount</div>
                                        <div className="r clr-green last fnt-14px">Rs. {bookingDetails.tariff.fareDetails.nightDriverAllowance + bookingDetails.tariff.fareDetails.extraHrsCharge * (totalHours - bookingDetails.hrsIncluded) + bookingDetails.tariff.fareDetails.extraKmCharge * extraKm + (bookingDetails.tariff.fareDetails.nightDriverAllowance + bookingDetails.tariff.fareDetails.extraHrsCharge * (totalHours - bookingDetails.hrsIncluded) + bookingDetails.tariff.fareDetails.extraKmCharge * extraKm) * 0.05}</div>
                                    </div>

                                    <div className="flexer">
                                        <div className="l green clr-white">Plus Balance Amount</div>
                                        <div className="r green last clr-white">Rs. {bookingDetails.amountPayable + bookingDetails.tariff.fareDetails.nightDriverAllowance + bookingDetails.tariff.fareDetails.extraHrsCharge * (totalHours - bookingDetails.hrsIncluded) + bookingDetails.tariff.fareDetails.extraKmCharge * extraKm + (bookingDetails.tariff.fareDetails.nightDriverAllowance + bookingDetails.tariff.fareDetails.extraHrsCharge * (totalHours - bookingDetails.hrsIncluded) + bookingDetails.tariff.fareDetails.extraKmCharge * extraKm) * 0.05}</div>
                                    </div>

                                    <div className="flexer">
                                        <div className="l clr-green ">Less cash for fuel received <p> by driver in advance <span> *</span></p></div>
                                        <div className="r light-green start">Rs. &ensp;<input type="text"
                                            value={fuelCash}
                                            onChange={handleFuelCashChange} /></div>
                                    </div>

                                    <div className="flexer">
                                        <div className="l green clr-white flx-row gp-4">Total Paid - {openLocalPayTpye} <span onClick={() => setopenLocal(!openLocal)}><img src={down_box} alt="" /></span></div>
                                        <div className="r green  clr-white last">Rs. {bookingDetails.amountPayable + bookingDetails.tariff.fareDetails.nightDriverAllowance + bookingDetails.tariff.fareDetails.extraHrsCharge * (totalHours - bookingDetails.hrsIncluded) + bookingDetails.tariff.fareDetails.extraKmCharge * extraKm + (bookingDetails.tariff.fareDetails.nightDriverAllowance + bookingDetails.tariff.fareDetails.extraHrsCharge * (totalHours - bookingDetails.hrsIncluded) + bookingDetails.tariff.fareDetails.extraKmCharge * extraKm) * 0.05 - fuelCash}</div>

                                        {
                                            openLocal ?
                                                <div className="paytype">
                                                    <div className="sub-paytype" onClick={() => paytypeLocal('online')}>Online</div>
                                                    <div className="sub-paytype" onClick={() => paytypeLocal('cash')}>Cash</div>
                                                </div>
                                                : ''
                                        }



                                    </div>

                                </div>


                                <div className="generate-bill-btn">
                                    <button >Generate Bill</button>
                                </div>

                            </>

                            :

                            <>
                                <div className='cross-holder' onClick={close}><img src={cross} alt="" /></div>
                                <h1>Manual Billing</h1>

                                <div className="trip-detail-container">
                                    <div className="row-flexer2">
                                        <h2>Trip Type</h2>
                                        <p>{bookingDetails.tariff.tripType} - {bookingDetails.tariff.subTripType} </p>
                                    </div>

                                    <div className="row-flexer2">
                                        <h2>Trip Start Date & Time</h2>
                                        <p>{bookingDetails.pickupDate}   &ensp; &ensp;     {bookingDetails.pickupTime}</p>
                                    </div>

                                    <div className="row-flexer2">
                                        <h2>Trip End Date & Time</h2>
                                        <div className="sub-datetime-row">
                                            <input type="date"
                                                value={endDate}
                                                onChange={(e) => setEndDate(e.target.value)}
                                            />
                                            <input type="time"
                                                value={endTime}
                                                onChange={(e) => setEndTime(e.target.value)} />
                                        </div>
                                    </div>
                                </div>


                                <div className="mannual-booking-customize-container">
                                    <h2>Fare Breakup</h2>

                                    <div className="flexer">
                                        <div className="l clr-white green">Estimated Fare <p className='p-small'>{bookingDetails.kmIncluded} Km, {bookingDetails.noOfDays} days </p></div>
                                        <div className="r">Rs. {bookingDetails.estimatedFare}</div>
                                    </div>

                                    <div className="flexer">
                                        <div className="l ">Advance Payment {bookingDetails.partialPaymentPercentage}%</div>
                                        <div className="r">Rs. {bookingDetails.advancePaymentReceived}</div>
                                    </div>

                                    <div className="flexer down-line">
                                        <div className="l clr-white green">Balance Payment to Pay</div>
                                        <div className="r clr-green">Rs. {bookingDetails.amountPayable}</div>
                                    </div>

                                    <h2 >Extra Coverage</h2>


                                    <div className="flexer">
                                        <div className="l green clr-white flx-row">Total Distance Covered<span> *</span></div>
                                        <div className="r light-green last"><input type="text"
                                            value={inputDistance}
                                            onChange={handleInputChange} /> &ensp; km</div>
                                    </div>

                                    <div className="flexer">
                                        <div className="l green clr-white">Extra km</div>
                                        <div className="r light-green last">{extraKm} Km</div>
                                    </div>

                                    <div className="flexer">
                                        <div className="l green clr-white">Actual No of Days</div>
                                        <div className="r light-green last">{totalDays}</div>
                                    </div>

                                    <div className="flexer down-line">
                                        <div className="l green clr-white">Extra Days</div>
                                        <div className="r light-green last">{totalDays - bookingDetails.noOfDays}</div>
                                    </div>

                                    <h2>Extra Fare</h2>

                                    <div className="flexer">
                                        <div className="l green clr-white">Extra km charge @ Rs. {bookingDetails.tariff.fareDetails.extraKmCharge}/Km</div>
                                        <div className="r last">Rs. {bookingDetails.tariff.fareDetails.extraKmCharge * extraKm}</div>
                                    </div>

                                    <div className="flexer">
                                        <div className="l green clr-white">Extra Night Allowance @ Rs.{bookingDetails.tariff.fareDetails.nightDriverAllowance}/day X 1 Day</div>
                                        <div className="r last">Rs. {bookingDetails.tariff.fareDetails.nightDriverAllowance * (totalDays - bookingDetails.noOfDays)}</div>
                                    </div>

                                    <div className="flexer">
                                        <div className="l green clr-white">5 % GST on Rs. {bookingDetails.tariff.fareDetails.extraKmCharge * extraKm + bookingDetails.tariff.fareDetails.nightDriverAllowance * (totalDays - bookingDetails.noOfDays)}</div>
                                        <div className="r last">Rs. {0.05 * (bookingDetails.tariff.fareDetails.extraKmCharge * extraKm + bookingDetails.tariff.fareDetails.nightDriverAllowance * (totalDays - bookingDetails.noOfDays))}</div>
                                    </div>


                                    <div className="flexer">
                                        <div className="l clr-green fnt-14px">Total Extra Amount</div>
                                        <div className="r clr-green  fnt-14px last">Rs. {bookingDetails.tariff.fareDetails.nightDriverAllowance * (totalDays - bookingDetails.noOfDays) + bookingDetails.tariff.fareDetails.extraKmCharge * extraKm + 0.05 * (bookingDetails.tariff.fareDetails.extraKmCharge * extraKm + bookingDetails.tariff.fareDetails.nightDriverAllowance * (totalDays - bookingDetails.noOfDays))}</div>
                                    </div>

                                    <div className="flexer">
                                        <div className="l green clr-white">Plus Balance Amount</div>
                                        <div className="r green  clr-white last">Rs. {bookingDetails.amountPayable + bookingDetails.tariff.fareDetails.nightDriverAllowance * (totalDays - bookingDetails.noOfDays) + bookingDetails.tariff.fareDetails.extraKmCharge * extraKm + 0.05 * (bookingDetails.tariff.fareDetails.extraKmCharge * extraKm + bookingDetails.tariff.fareDetails.nightDriverAllowance * (totalDays - bookingDetails.noOfDays))}</div>
                                    </div>

                                    <div className="flexer">
                                        <div className="l clr-green ">Less cash for fuel received <p> by driver in advance <span> *</span></p></div>
                                        <div className="r light-green start">Rs. &ensp;<input type="text"
                                            value={fuelCash}
                                            onChange={handleFuelCashChange} /></div>
                                    </div>

                                    <div className="flexer">
                                        <div className="l green clr-white flx-row gp-4">Total Paid - {openOutstaionPayTpye} <span onClick={() => setopenOutstaion(!openOutstaion)}><img src={down_box} alt="" /></span></div>
                                        <div className="r green  clr-white last">Rs. {bookingDetails.amountPayable + bookingDetails.tariff.fareDetails.nightDriverAllowance * (totalDays - bookingDetails.noOfDays) + bookingDetails.tariff.fareDetails.extraKmCharge * extraKm + 0.05 * (bookingDetails.tariff.fareDetails.extraKmCharge * extraKm + bookingDetails.tariff.fareDetails.nightDriverAllowance * (totalDays - bookingDetails.noOfDays)) - fuelCash}</div>

                                        {
                                            openOutstaion ?
                                                <div className="paytype">
                                                    <div className="sub-paytype" onClick={() => paytypeOutstation('online')}>Online</div>
                                                    <div className="sub-paytype" onClick={() => paytypeOutstation('cash')}>Cash</div>
                                                </div>
                                                : ''
                                        }



                                    </div>


                                </div>


                                <div className="generate-bill-btn">
                                    <button >Generate Bill</button>
                                </div>

                            </>
                    }


                </div>
            </div>


        </>
    )
}

export default CustomBilling