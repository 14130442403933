import React, { useEffect, useState } from "react";
import "./AssignCarData.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaFilter } from "react-icons/fa";
import DropdownFilter from "../dropdown/DropdownFilter";
import Loader from "../Loader";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { assignCab, fetchCabData } from "./assignCabSlice";
import { setToggleForTaskDetails } from "./assignCab&DriverSlice";
import { useNavigate, useParams } from "react-router-dom";
import { BiRightArrow } from "react-icons/bi";
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;


function AssignCar({ bookingId, driverData, setDriverState }) {
  const [apiData, setApiData] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const [filterValue, setFilterValue] = useState([]);
  const [filterValues, setFilterValues] = useState({});
  const [componentKey, setComponentKey] = useState(0);
  const [searchName, setSearchName] = useState("");
  const [filterOptionsStates, setFilterOptionsStates] = useState([]);
  const [initialDriverData, setInitialDriverData] = useState([]);
  const [filterCabMakes, setfilterMakeOption] = useState([]);
  const [currentFilterValue, setCurrentFilterValue] = useState();
  const [intialCabData, setInitialCabData] = useState([]);

  const [cabNumber, setCabNumber] = useState("");
  const [filterOptionsState, setfilterOptionsState] = useState();
  const { allCabs, cabData } = useSelector((state) => state.assignCabs);

  const headerColumns = [
    "Cab No.",
    "Cab Type",
    "Location",
    "Cab Make & Model",
    "Cab Color",
    "Status",
    "Insurance Validity",
    "Permit Validity",
    "",
  ];

  const [driverLocationState, setDriverLocationState] = useState("");
  const [driverLocationCity, setDriverLocationCity] = useState("");
  const [isApiCalled, setIsApiCalled] = useState(false);



useEffect(() => {
  if (driverData?.location) {
    const [city, state] = driverData.location.split(",").map(item => item.trim());
    setDriverLocationState(state);
    setDriverLocationCity(city);
  }
}, [driverData]);


  useEffect(() => {
    if (driverLocationState) {
      fetchLocations();
    }
  }, [driverLocationState]);

  console.log("cab data is ", cabData);

  console.log("Driver data in car", driverData);
  console.log("Booking id in car assign code", bookingId);
  console.log("Booking id in car assign code", id);

  useEffect(() => {
    if (driverData && driverData.id) {
      const driverId = driverData?.id.toString();
      dispatch(fetchCabData({ bookingId, driverId }));
    }
  }, [dispatch, bookingId, driverData]);

  const currentPath = window.location.pathname;
  useEffect(() => {
    if (currentPath.includes("/assign/cab")) {
      setDriverState(false);
    }
  }, [currentPath]);

  const filterOptions = [
    {
      label: "Pick Up State",
      values: null,
    },
    {
      label: "Pick Up City",
      values: null,
    },
    {
      label: "Cab Type",
      values: null,
    },
    {
      label: "Cab Make",
      values: null,
    },
    {
      label: "Cab Model",
      values: null,
    },
  ];

  let filterAvailability = {
    label: "Availability",
    values: ["Available", "Not Available"],
  };
  let filterLocations = [];
  let filterCabType = [];
  let filterCabMake = [];

  const fetchLocations = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/v1/locations`);
      const locations = response.data.data.map((value) => {
        const [city, state] = value.location
          .split(",")
          .map((item) => item.trim());
        return { city, state };
      });

      // Get unique states where the driver is present
      const driverStates = new Set(
        locations
          .filter((location) => location.state === driverLocationState)
          .map((location) => location.state)
      );

      // Get cities where the driver is present
      const driverCities = locations
        .filter(
          (location) =>
            location.state === driverLocationState &&
            location.city === driverLocationCity
        )
        .map((location) => location.city);

      const updatedFilterOptions = filterOptions.map((temp) => {
        if (temp.label === "Pick Up State") {
          temp.values = Array.from(driverStates); // Convert Set to Array
        } else if (temp.label === "Pick Up City") {
          temp.values = driverCities; // Only cities tied to the driver
        }
        return temp;
      });

      setFilterOptionsStates(updatedFilterOptions);
    } catch (error) {
      console.error("Failed to fetch locations.", error);
    }
  };

  const fetchCabTypes = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/v1/car-categories`);
      if (response.data?.data) {  // Ensure response.data.data exists
        const cabTypes = response.data.data.map((value) => value.category);
        setFilterOptionsStates((prev) =>
          prev.map((option) =>
            option.label === "Cab Type" ? { ...option, values: cabTypes } : option
          )
        );
      } else {
        console.warn("fetchCabTypes: No data received");
      }
    } catch (error) {
      console.error("Error fetching cab types:", error);
    }
  };
  

  const fetchCabMake = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/v1/car-makes`);
      const cabMakes = response.data.data.map((value) => value.make);

      setFilterOptionsStates((prev) =>
        prev.map((option) =>
          option.label === "Cab Make" ? { ...option, values: cabMakes } : option
        )
      );
    } catch (error) {
      console.error("Error fetching cab makes:", error);
    }
  };

  console.log("filter Cab Make make api.....", filterCabMake);

  const applyFilters = () => {
    const filteredData = intialCabData?.filter((driver) => {
      // Matches search by cab number
      const matchesSearchName = driver["Cab No."]
        ?.toLowerCase()
        .startsWith(searchName.toLowerCase());

      // Matches filter values for other fields
      const matchesFilterValues = Object.keys(filterValues).every((key) => {
        if (key === "Pick Up State") {
          const driverLocation = driver["Location"];
          const locationParts = driverLocation ? driverLocation.split(",") : [];
          const driverState = locationParts[1]?.trim();
          return (
            driverState?.toLowerCase() ===
            filterValues["Pick Up State"].toLowerCase()
          );
        } else if (key === "Pick Up City") {
          const driverLocation = driver["Location"];
          const locationParts = driverLocation ? driverLocation.split(",") : [];
          const driverCity = locationParts[0]?.trim();
          return (
            driverCity?.toLowerCase() ===
            filterValues["Pick Up City"].toLowerCase()
          );
        } else if (key === "Cab Make" || key === "Cab Model") {
          const cabMake = filterValues["Cab Make"];
          const cabModel = filterValues["Cab Model"];

          if (cabMake && cabModel) {
            const cabMakeModel = `${cabMake} ${cabModel}`.trim().toLowerCase();
            const driverMakeModel = driver["Cab Make & Model"]
              ?.trim()
              .toLowerCase();
            return driverMakeModel === cabMakeModel;
          } else if (cabMake) {
            const driverCabMakeModel = driver["Cab Make & Model"];
            const [driverMake] = driverCabMakeModel
              ? driverCabMakeModel.split(" ")
              : [];
            return (
              driverMake &&
              driverMake.toLowerCase().startsWith(cabMake.toLowerCase())
            );
          } else if (cabModel) {
            return driver["Cab Make & Model"]
              ?.toLowerCase()
              .includes(cabModel.toLowerCase());
          }
          return true;
        } else if (key === "Availability") {
          // Normalize the comparison for availability
          return (
            driver["Status"]?.toLowerCase() === filterValues[key].toLowerCase()
          );
        } else {
          return driver[key]?.includes(filterValues[key]);
        }
      });

      return matchesSearchName && matchesFilterValues;
    });

    setApiData(filteredData);
  };

  console.log("filter values", filterValues);

  useEffect(() => {
    applyFilters();
  }, [searchName, filterValues, currentFilterValue]);

  useEffect(() => {
    if (cabNumber) {
      globalSelectedOption(cabNumber, "Cab No.");
    }
  }, [cabNumber]);

  const handleSelectedData = (cabNumber) => {
    const currentCab = apiData.find((cab) => cab["id"] === cabNumber);
    if (currentCab) {
        dispatch(assignCab(currentCab));
    } else {
        console.error("Cab not found");
    }
};

  const cabDetails = [];
  let cabModels = [];
  const [filterCabModels, setFilterCabModels] = useState();
  useEffect(() => {
    if (allCabs?.data?.data) {
      const cabDetails = [];
      const cabModels = [{ "Cab Make & Model": "Cab Model" }]; // Initial model

      allCabs.data.data.forEach((cab) => {
        cabDetails.push({
          id: cab._id,
          "Cab No.": cab.registrationCertificate.number,
          "Cab Type": cab.carCategory.category,
          Location: cab.location
            ? `${cab.location.city}, ${cab.location.state}`
            : cab.location,
          "Cab Make & Model": `${cab.carMake} ${cab.carModel}`,
          "Cab Color": cab.carColor,
          Status:
            cab?.available? "Available" : "Not Available",
          "Insurance Validity": cab.insurance.expiryDate,
          "Permit Validity": cab.permit.expiryDate,
        });

        cabModels.push({
          "Cab Make & Model": `${cab.carMake} ${cab.carModel}`,
          "Cab Type": cab.carCategory.category,
        });
      });

      setApiData(cabDetails);
      setInitialCabData(cabDetails);
      setFilterCabModels(cabModels);
    }
  }, [allCabs.data]);


  const [labels, setLabels] = useState([]);
  // const [filterValuess, setFilterValuess] = useState([]);

  console.log("initial cab data", intialCabData);

  const globalSelectedOption = async (value, label) => {
    let temp = { ...filterValues, [label]: value }; // Copy the current filter values

    if (value === "") {
      delete temp[label]; // Remove the value if it's empty
    } else {
      temp[label] = value; // Set the new filter value
    }

    setFilterValues(temp); // Update filter values

    // Handle state and city-specific filters
    if (label === "Pick Up State") {
      setCurrentFilterValue(value);
      const cities = await fetchCitiesByState(value); // Fetch cities for the selected state
      const updatedFilterOptions = filterOptionsStates.map((filter) => {
        if (filter.label === "Pick Up City") {
          filter.values = cities;
        }
        return filter;
      });
      setFilterOptionsStates(updatedFilterOptions);
    } else if (label === "Pick Up City") {
      const state = temp["Pick Up State"];
      const city = value;
      if (state && city) {
        const location = `${city}, ${state}`;
        setCurrentFilterValue(location);
      }
    } else if (label === "Cab Make") {
      await fetchCabModel(value); // Fetch models for selected cab make
    } else {
      applyFilters(); // Apply filters for other fields
    }
  };

  useEffect(() => {
    if (currentFilterValue) {
      const newData = intialCabData?.filter((driver) => {
        const result = labels.every((key) => {
          if (driver[key] !== null && filterValues[key] !== undefined) {
            if (key === "Pick Up State" || key === "Pick Up City") {
              const state = filterValues["Pick Up State"];
              const city = filterValues["Pick Up City"];
              const driverLocation = driver["Location"];
              const [driverCity, driverState] = driverLocation
                ? driverLocation.split(",").map((part) => part.trim())
                : [];
              if (key === "Pick Up State") {
                return (
                  driverState &&
                  driverState.toLowerCase() === state.toLowerCase()
                );
              } else if (key === "Pick Up City") {
                return (
                  driverCity && driverCity.toLowerCase() === city.toLowerCase()
                );
              }
            } else if (key === "Cab Make" || key === "Cab Model") {
              const cabMake = filterValues["Cab Make"];
              const cabModel = filterValues["Cab Model"];
              if (key === "Cab Make") {
                const driverCabMakeModel = driver["Cab Make & Model"];
                const [driverMake] = driverCabMakeModel
                  ? driverCabMakeModel.split(" ")
                  : [];
                return (
                  driverMake &&
                  driverMake.toLowerCase().startsWith(cabMake.toLowerCase())
                );
              } else if (key === "Cab Model" && cabMake) {
                const CabMakeModel = `${cabMake} ${cabModel}`;
                const check = driver["Cab Make & Model"];
                return check?.includes(CabMakeModel);
              }
            } else if (key === "Availability") {
              return (
                driver["Status"]?.toLowerCase() ===
                filterValues[key].toLowerCase()
              );
            } else {
              return driver[key]?.includes(filterValues[key]);
            }
          }
          return true;
        });

        return result;
      });

      setApiData([...newData]);
    }
  }, [currentFilterValue, labels, filterValues, intialCabData]);

  const fetchCitiesByState = async (state) => {
    console.log("state is ", state);

    try {
      const response = await axios.post(
        `${API_BASE_URL}/v1/admin/bookings/get-cities-state`,
        { state: state } // Pass state as a body
      );
      console.log("response by api", response);
      const cities = response.data.data.map((cityData) => cityData.city);
      console.log("cities for selected state is", cities);

      const updatedFilterOptions = filterOptionsStates.map((temp) => {
        if (temp.label === "Pick Up City") {
          temp.values = cities; // Update cities for the selected state
        }
        return temp;
      });

      setFilterOptionsStates(updatedFilterOptions);
    } catch (error) {
      console.error("Failed to fetch cities for the selected state.", error);
    }
  };
  const fetchCabModel = async (selectedMake) => {
    // If no make is selected (e.g. on first render), skip the request
    if (!selectedMake) {
      // Optional: Reset the Cab Model dropdown to empty
      const updatedFilterOptions = filterOptionsStates.map((temp) => {
        if (temp.label === "Cab Model") {
          temp.values = []; // Clear out any previously loaded models
        }
        return temp;
      });
      setFilterOptionsStates(updatedFilterOptions);
  
      return; // Don’t proceed with the API call
    }
  
    try {
      const response = await axios.get(
        `${API_BASE_URL}/v1/car-models/${selectedMake}`
      );
      const data = response?.data?.data;
      
      // Safely extract the models array
      const models = Array.isArray(data) && data.length > 0 && data[0]?.models
        ? data[0].models
        : [];
  
      // Update the filter options with the newly fetched models
      const updatedFilterOptions = filterOptionsStates.map((temp) => {
        if (temp.label === "Cab Model") {
          temp.values = models; // Populate "Cab Model" dropdown
        }
        return temp;
      });
  
      setFilterOptionsStates(updatedFilterOptions);
    } catch (error) {
      console.error("Failed to fetch models for the selected cab make", error);
    }
  };
  
  useEffect(() => {
    console.log("preview Data", driverData);
    console.log("cabData", cabData);
  }, [handleSelectedData]);

  const TableHeaders = ({ headers }) => {
    return (
      <thead>
        <tr className="table-header-row">
          {headers.map((header, index) => (
            <th key={index}>
              <div className="table-header"> {header}</div>
            </th>
          ))}
        </tr>
      </thead>
    );
  };

  const TableBody = ({ enteries, headers }) => {
    return (
      <tbody>
        {enteries.map((driver, index) => {
          return (
            <tr className="table-data-row" key={index}>
              {headers.map((header) => {
                return header !== "" ? (
                  <td className="table-data" key={header}>
                    {" "}
                    {driver[header]}{" "}
                  </td>
                ) : (
                  <td className="table-data" key={header}>
                    <button
                      className="select-button"
                      onClick={() => handleSelectedData(driver["id"])}
                    >
                      Select
                    </button>
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    );
  };
  useEffect(() => {
    const initializeFilters = async () => {
      await fetchLocations();
      await fetchCabTypes();
      await fetchCabMake();
    };
    initializeFilters();
  }, [driverLocationState, driverLocationCity]); // Runs when driver's location changes
  
  useEffect(() => {
    if (filterValues["Cab Make"]) {
      fetchCabModel(filterValues["Cab Make"]); // Fetch models when make is selected
    }
  }, [filterValues["Cab Make"]]);
  

  const ResetFilters = () => {
    // Perform the necessary cleanup actions before reload (if needed)
    setFilterValues({});
    setSearchName("");
    // setSearchContact("");

    // Full page reload
    window.location.reload();
  };

  useEffect(() => {
    if (cabNumber.length !== 0) {
      console.log("name", apiData);
      const filterData = intialCabData?.filter((driver) => {
        return cabNumber.includes(driver["Cab No."]);
      });

      if (filterData.length !== 0) {
        setApiData([...filterData]);
      }
    }
  }, [cabNumber]);

  useEffect(() => {
    if (filterValue.length !== 0) {
      const temp = filterValue.filter((values) => {
        return values !== undefined || values;
      });

      const check = temp.map((value, index) => {
        return value[1];
      });

      const filterData = intialCabData?.filter((driver) =>
        check.every((key, index) => temp[index].includes(driver[key]))
      );

      setApiData([...filterData]);
    }
  }, [filterValue]);

  const handleAssignCabAndDriver = async () => {
    try {
      const response = await axios.post(
        `${API_BASE_URL}/v1/admin/bookings/assign-driver-car`,
        {
          driverId: driverData?.id,
          carId: cabData?.id,
          bookingId: bookingId,
        }
      );

      if (response.status === 200) {
        toast.success("Cab and driver successfully assigned", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setTimeout(() => {
          navigate(`/booking/${bookingId}`);
        }, 2000);
      } else {
        toast.error("Failed to assign cab and driver", {
          position: "top-center",
        });
      }
    } catch (error) {
      console.error("Error assigning cab and driver:", error);
      toast.error("Driver is already assigned", {
        position: "top-center",
      });
    }
  };

  return (
    <div className="data-container">
      <div className="filter-sectionCab">
        <p className="filter-tag">
          {" "}
          Filter
          <FaFilter />
        </p>

        <div
  style={{
    display: "flex",
    justifyContent: "space-between",
    width: "750px",
    gap: "15px",
    marginBottom: "6px",
  }}
>
  <div>
    <DropdownFilter
      key={1}
      filterOptions={{
        label: "Pick Up State",
        values: filterOptionsStates.find((f) => f.label === "Pick Up State")?.values || [],
      }}
      onSelect={globalSelectedOption}
      defaultValue={driverLocationState}
      disabled={!filterOptionsStates.find((f) => f.label === "Pick Up State")?.values?.length}
    />
  </div>

  <div>
    <DropdownFilter
      key={2}
      filterOptions={{
        label: "Pick Up City",
        values: filterOptionsStates.find((f) => f.label === "Pick Up City")?.values || [],
      }}
      onSelect={globalSelectedOption}
      defaultValue={driverLocationCity}
      disabled={!filterOptionsStates.find((f) => f.label === "Pick Up City")?.values?.length}
    />
  </div>

  <div>
    <DropdownFilter
      key={3}
      filterOptions={{
        label: "Cab Type",
        values: filterOptionsStates.find((f) => f.label === "Cab Type")?.values || [],
      }}
      onSelect={globalSelectedOption}
      defaultValue={undefined}
    />
  </div>

  <div>
    <DropdownFilter
      key={4}
      filterOptions={{
        label: "Cab Make",
        values: filterOptionsStates.find((f) => f.label === "Cab Make")?.values || [],
      }}
      onSelect={globalSelectedOption}
      defaultValue={undefined}
    />
  </div>

  <div>
    <DropdownFilter
      key={5}
      filterOptions={{
        label: "Cab Model",
        values: filterOptionsStates.find((f) => f.label === "Cab Model")?.values || [],
      }}
      onSelect={globalSelectedOption}
      defaultValue={undefined}
    />
  </div>
</div>

        <div
          style={{
            marginBottom: "6px",
            marginLeft: "20px",
          }}
        >
          <DropdownFilter
            key={0}
            filterOptions={filterAvailability}
            onSelect={globalSelectedOption}
            defaultValue={undefined}
          />
        </div>
        <div
          className="second-Container"
          style={{
            marginLeft: "15px",
            marginBottom: "12px",
            height: "28px",
          }}
        >
          <div>
            <input
              className="input-search "
              style={{ marginRight: "4px", marginTop: "6px", width: "100px" }}
              value={searchName}
              type="text"
              placeholder="Search By Cab no."
              onChange={(e) => setSearchName(e.target.value)}
            />
          </div>
          <div>
            {/* {filterValue.length !== 0 && ( */}
            <button
              className="filter-button"
              style={{
                marginTop: "6px",
              }}
              onClick={() => ResetFilters()}
            >
              Reset
            </button>
            {/* )} */}
          </div>
        </div>
      </div>

      <div className="table-container">
        <table className="driver-table">
          <TableHeaders headers={headerColumns} />
          {intialCabData?.length > 0 ? (
            <TableBody enteries={apiData} headers={headerColumns} />
          ) : (
            <tbody>
              <tr>
                <td colSpan={headerColumns.length} className="no-data">
                  <span>{"Driver has no available cars"}</span>
                </td>
              </tr>
            </tbody>
          )}
        </table>
      </div>

      {/* <div>{intialCabData.length==0 && "Driver has no available cars"}</div> */}

      <div className="preview-data">
        <div className="driver-data-preview">
          <p style={{ fontWeight: "700" }}>Selected Driver</p>
          <p>Driver Name: {driverData["Driver Name"]}</p>
          <p>Driver Mob. No.: {driverData["Contact No."]} </p>
        </div>
        <div className="driver-data-preview">
          <p style={{ fontWeight: "700" }}>Selected Cab</p>
          {cabData ? (
            <>
              <p>Cab Type: {cabData["Cab Type"]} </p>
              <p>Cab Model: {cabData["Cab Make & Model"]}</p>
              <p>Cab Color: {cabData["Cab Color"]}</p>
              <p>Cab No.: {cabData["Cab No."]}</p>
            </>
          ) : (
            <p>Not Assigned yet</p>
          )}
        </div>
      </div>
      <div>
        <button
          className="assign-cabdriver-button"
          onClick={handleAssignCabAndDriver}
        >
          Assign Cab & Driver
        </button>
      </div>
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default AssignCar;
