import React, { useState, useEffect } from "react";

import { useDispatch } from "react-redux";
import { logout } from "../components/login/authSlice";

const LogoutTimer = () => {
  const [logoutTimer, setLogoutTimer] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    const resetTimeout = () => {
      clearTimeout(logoutTimer);
    };

    const logoutUser = () => {
      console.log("User logged out due to inactivity");
      dispatch(logout());
      window.location.href = "/";
    };
    const timer = setTimeout(logoutUser, 60 * 60 * 1000);
    setLogoutTimer(timer);

    return () => {
      clearTimeout(logoutTimer);
    };
  }, []);

  return;
};

export default LogoutTimer;
