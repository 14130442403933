import axios from "axios";

export  function fetchviewallcar() {
  return new Promise(async (resolve,rejection) => {
    try {
      const response = await axios.get(
        "https://api.yatricabs.com/v1/admin/cars"
      );
      resolve(response.data.data);
    } catch (e) {
      console.log("Error in fetching Data", e);
      rejection(e);
    }
  });
}
export  function fetchviewcarscategory() {
  return new Promise(async (resolve, rejection) => {
    try {
      const response = await axios.get(
        "https://api.yatricabs.com/v1/admin/car-categories"
      );
      resolve(response.data.data);
    } catch (e) {
      console.log("Error in fetching Data", e);
      rejection(e);
    }
  });
}

export  function fetchpendingcardata() {
    return new Promise(async (resolve,rejection) => {
      try {
        const response = await axios.get(
          "https://api.yatricabs.com/v1/admin/cars/approval-pending"
        );
        resolve(response.data.data);
      } catch (e) {
        console.log("Error in fetching Data", e);
        rejection(e);
      }
    });
  }


export  function fetchinactivecardata() {
    return new Promise(async (resolve,rejection) => {
      try {
        const response = await axios.get(
          "https://api.yatricabs.com/v1/admin/cars/inactive"
        );
        resolve(response.data.data);
      } catch(error) {
        if (error.response) {
          console.log('Server responded with status code:', error.response.status);
          console.log('Response data:', error.response.data);
        } else if (error.request) {
          console.log('No response received:', error.request);
        } else {
          console.log('Error creating request:', error.message);
        }
      }
    });
  }



  export function fetchsinglecardata(id) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios.get(`https://api.yatricabs.com/v1/admin/cars/${id}`);
        resolve(response.data.data);
      } catch (error) {
        if (error.response) {
          console.log('Server responded with status code:', error.response.status);
          console.log('Response data:', error.response.data);
        } else if (error.request) {
          console.log('No response received:', error.request);
        } else {
          console.log('Error creating request:', error.message);
        }
        reject(error); // Reject the promise with the error
      }
    });
  }

  

//



  // import axios from "axios";

  // export const fetchviewallcar = async () => {
  //   try {
  //     const response = await axios.get(
  //       "https://api.yatricabs.com/v1/admin/cars"
  //     );
  //     return response.data.data;
  //   } catch (error) {
  //     throw new Error(`Error fetching all cars: ${error.message}`);
  //   }
  // };
  
  // export const fetchPendingCars = async () => {
  //   try {
  //     const response = await axios.get(
  //       "https://api.yatricabs.com/v1/admin/cars/approval-pending"
  //     );
  //     return response.data.data;
  //   } catch (error) {
  //     throw new Error(`Error fetching pending cars: ${error.message}`);
  //   }
  // };
  
  // export const fetchInactiveCars = async () => {
  //   try {
  //     const response = await axios.get(
  //       "https://api.yatricabs.com/v1/admin/cars/inactive"
  //     );
  //     return response.data.data;
  //   } catch (error) {
  //     throw new Error(`Error fetching inactive cars: ${error.message}`);
  //   }
  // };
  
  // export const fetchSingleCarData = async (carId) => {
  //   try {
  //     const response = await axios.get(
  //       `https://api.yatricabs.com/v1/admin/cars/${carId}`
  //     );
  //     return response.data.data;
  //   } catch (error) {
  //     throw new Error(`Error fetching car details for ID ${carId}: ${error.message}`);
  //   }
  // };
  