// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* NotFound.css */
.not-found-box {
  font-family: "Lato";
}

.not-found-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 50%;
  padding-right: 22px;
  padding-left: 22px;
  justify-content: center;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.268);
}

.not-found-box {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f8f9fa;
  height: 100vh;
}

.not-found-box .not-found-containe h1 {
  font-size: 3em;
  margin-bottom: 20px;
  color: #495057;
}

.not-found-box .not-found-containe p {
  text-align: center;
  margin: 0;
  color: #E6F5E0;

  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
}

.route-text {
  font-size: 1.1rem;
  padding-left: 0.2rem;
  padding-right: 0.2rem;
  color: #38b000;
  font-size: "Lato";
}`, "",{"version":3,"sources":["webpack://./src/components/Page404.css"],"names":[],"mappings":"AAAA,iBAAiB;AACjB;EACE,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,WAAW;EACX,mBAAmB;EACnB,kBAAkB;EAClB,uBAAuB;EACvB,kBAAkB;EAClB,sBAAsB;EACtB,yCAAyC;AAC3C;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,yBAAyB;EACzB,aAAa;AACf;;AAEA;EACE,cAAc;EACd,mBAAmB;EACnB,cAAc;AAChB;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,cAAc;;EAEd,kBAAkB;EAClB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;EACjB,oBAAoB;EACpB,qBAAqB;EACrB,cAAc;EACd,iBAAiB;AACnB","sourcesContent":["/* NotFound.css */\n.not-found-box {\n  font-family: \"Lato\";\n}\n\n.not-found-container {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  height: 50%;\n  padding-right: 22px;\n  padding-left: 22px;\n  justify-content: center;\n  border-radius: 5px;\n  background-color: #fff;\n  box-shadow: 0 0 10px rgba(0, 0, 0, 0.268);\n}\n\n.not-found-box {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  background-color: #f8f9fa;\n  height: 100vh;\n}\n\n.not-found-box .not-found-containe h1 {\n  font-size: 3em;\n  margin-bottom: 20px;\n  color: #495057;\n}\n\n.not-found-box .not-found-containe p {\n  text-align: center;\n  margin: 0;\n  color: #E6F5E0;\n\n  font-family: Inter;\n  font-size: 15px;\n  font-style: normal;\n  font-weight: 700;\n}\n\n.route-text {\n  font-size: 1.1rem;\n  padding-left: 0.2rem;\n  padding-right: 0.2rem;\n  color: #38b000;\n  font-size: \"Lato\";\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
