import React from "react";
import { useSelector } from "react-redux";
import { selectbookingDetails } from "../DetailsSlice";
import "./Fourthtable.css";

const FourthFare = ({ data }) => {
  const bookingDetails = useSelector(selectbookingDetails);
  if (!data || typeof data !== "object") {
    return null;
  }

  console.log(data);

  const extrakmchargeRate = data?.data?.extraKmChargeRate || 0;
  const extraHrsChargeRate = data?.data?.extraHrsChargeRate ?? 0;
  const finalExtrakmcharge =
    extrakmchargeRate *
    (data?.data?.actualDistance - data?.data?.approxDistance);
  const finalExtraHrsCharge = data?.data?.extraHrsCharge ?? 0;
  const nightDriverAllowanceRate = data?.data?.nightDriverAllowanceRate || 0;
  const finalNightDriverAllowance = data?.data?.extraNightAllowance || 0;
  const gstRate = data?.data?.gstAmount || 0;
  const finalGstRate = data?.data?.gstAmount || 0;
  // const finalGstAmount =
  //   data?.data?.paymentDetails && data?.data?.paymentDetails.length > 0
  //     ? data?.data?.paymentDetails[data?.data?.paymentDetails.length - 1]?.totalAmount
  //     : 0;

  const finalGstAmount = data?.data?.gstOnExtraCharges ?? 0;
  const totalextraamount = data?.data?.totalExtraAmount || 0;
  const balanceAmount = data?.data?.balancePayment || 0;
  const totalAmtPaidToDriver = data?.data?.totalPaidToDriver || 0;
  const cashPaid = data?.data?.cashForFuel ?? 0;

  const extraCharges = data?.data?.extraCharges ?? 0;


  if(bookingDetails?.tariff?.tripType==="Outstation"){

    
  var farebreakupdata = [
    {
      lable: `Extra km charge @Rs.${extrakmchargeRate}/Km `,
      value: finalExtrakmcharge.toFixed(2),
    },
    {
      lable: `Extra Night Allowance  @Rs.${nightDriverAllowanceRate}/day X ${data?.data?.actualDays - data?.data?.noOfDays}Day`,
      value: finalNightDriverAllowance.toFixed(2),
    },
    {
      lable: `5 % GST on Rs. ${extraCharges.toFixed(2)}`,
      value: finalGstAmount.toFixed(2),
    },
    { lable: "Total Extra Amount", value: totalextraamount.toFixed(2) },
    { lable: "Plus Balance Amount", value: balanceAmount.toFixed(2) },
    { lable: "Less cash for fuel received by driver in advance", value: cashPaid.toFixed(2) },
    { lable: "Total Paid to Online", value: totalAmtPaidToDriver.toFixed(2) },
  ];


  }else if(bookingDetails?.tariff?.tripType==="Local"){

    
  var farebreakupdata = [
    {
      lable: `Extra Km charge  @Rs.${extrakmchargeRate}/Km `,
      value: finalExtrakmcharge.toFixed(2),
    },
    {
      lable: `Extra Hrs charge @Rs.${extraHrsChargeRate}/Hr `,
      value: finalExtraHrsCharge.toFixed(2),
    },
    {
      lable: `Extra Night Allowance @Rs.${nightDriverAllowanceRate}/day`,
      value: finalNightDriverAllowance.toFixed(2),
    },
    {
      lable: `5 % GST on Rs. ${extraCharges.toFixed(2)}`,
      value: finalGstAmount.toFixed(2),
    },
    { lable: "Total Extra Amount", value: totalextraamount.toFixed(2) },
    { lable: "Plus Balance Amount", value: balanceAmount.toFixed(2) },
    { lable: "Less cash for fuel received by driver in advance", value: cashPaid },
    { lable: "Total Paid to Online", value: totalAmtPaidToDriver.toFixed(2) },
  ];


  }else{
    
    
  var farebreakupdata = [
    {
      lable: `Extra Km charge @Rs.${extrakmchargeRate}/Km `,
      value: finalExtrakmcharge.toFixed(2),
    },
    {
      lable: `Extra Hrs charge @Rs.${extraHrsChargeRate}/Hr `,
      value: finalExtraHrsCharge.toFixed(2),
    },
    {
      lable: `Extra Night Allowance @Rs.${nightDriverAllowanceRate}/day`,
      value: finalNightDriverAllowance.toFixed(2),
    },
    {
      lable: `5 % GST on Rs. ${extraCharges.toFixed(2)}`,
      value: finalGstAmount.toFixed(2),
    },
    { lable: "Total Extra Amount", value: totalextraamount.toFixed(2) },
    { lable: "Plus Balance Amount", value: balanceAmount.toFixed(2) },
    { lable: "Less cash for fuel received by driver in advance", value: cashPaid.toFixed(2) },
    { lable: "Total Paid to Online", value: totalAmtPaidToDriver.toFixed(2) },
  ];
  }

  return (
    <div className="billing_fourth_containers">
      <div className="billing_fourth_scroll">
        <table className="billing-table-fourth">
          <thead>
            <tr>
              <th colSpan="2">Extra Fare</th>
            </tr>
          </thead>
          <tbody className="firstFare_billing_table_data">
            {farebreakupdata.map((row, index) => (
              <React.Fragment key={index}>
                <tr>
                  <td
                    className="fourth-td"
                    style={
                      row.lable === "Total Extra Amount" ||
                      row.lable ===
                        "Less cash for fuel received by driver in advance"
                        ? {
                            backgroundColor: "#fff",
                            color: "#38B000",
                            fontWeight: "700",
                          }
                        : {}
                    }
                  >
                    {row.lable}
                  </td>
                  <td
                    style={
                      row.lable === "Total Extra Amount" ||
                      row.lable ===
                        "Less cash for fuel received by driver in advance" ||
                      row.lable === "Total Paid to Online"
                        ? {
                            backgroundColor: "#fff",
                            color: "#38B000",
                            fontWeight: "500",
                            fontWeight: "700",
                          }
                        : {}
                    }
                  >
                    {row.value < 1 ? "0" : `Rs. ${row.value}`}
                  </td>
                </tr>
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default FourthFare;
