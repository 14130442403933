import React from "react";

const OutstationDetails = (props) => {
  const { carCategoriesDetails, selectedCabType, onEstimatedFareChange } =
    props;
  // console.log("Selected cabType", selectedCabType);
  const cabType = carCategoriesDetails?.find(
    (item) => item?.carCategory?.category === selectedCabType
  );

  console.log("Selected cabType", cabType);

  const fareDetails = cabType?.fareDetails;
  console.log("details", fareDetails);
  onEstimatedFareChange(fareDetails?.estimatedFare);
  return (
    <>
      <div className="booking-section">
        <div className="fare-details  out-roundt-fare-details">
          <div className="fare-head  out-roundt-fare-head">
            <div className="fare-head-content">Fare Details</div>
          </div>
          <div className="rows"> 
            <div className="row-head">
              <div className="col col-head  out-roundt-col-head">Ride Estimate</div>
              <div className="col col-head">
                <div className="col-item">
                  {" "}
                  Rs.{" "}
                  {fareDetails && fareDetails?.estimatedFare
                    ? fareDetails?.estimatedFare.toFixed(2)
                    : "00.00"}
                </div>
                <div className="col-sub-item">(incl. of all taxes)</div>
              </div>
            </div>

            <hr />

            <div className="row">
              <div className="col1">Included KM @ Min {cabType?.fareDetails?.minKmPerDay}km/day</div>
              <div className="col">
                {" "}
                {cabType && cabType?.rideDistance
                  ? cabType?.rideDistance
                  : "0"}{" "}
                KM
              </div>
            </div>
            <div className="row">
              <div className="col1">Base Fare/Km @ Rs.{cabType?.fareDetails?.perKmCharge}/km</div>
              <div className="col">
                {" "}
                Rs.{" "}
                {fareDetails && fareDetails?.baseFare
                  ? fareDetails?.baseFare?.toFixed(2)
                  : "00.00"}
              </div>
            </div>

            <div className="row">
              <div className="col1">Night charges @ Rs.{cabType?.fareDetails?.nightDriverAllowance} X 1 Days</div>
              <div className="col">
                {" "}
                Rs.{" "}
                {fareDetails && fareDetails?.nightDriverAllowance
                  ? fareDetails?.nightDriverAllowance.toFixed(2)
                  : "00.00"}
              </div>
            </div>
            <div className="row">
              <div className="col1">GST @ 5%</div>
              <div className="col">
                {" "}
                Rs.{" "}
                {fareDetails && fareDetails?.gstAmount
                  ? fareDetails?.gstAmount.toFixed(2)
                  : "00.00"}
              </div>
            </div>
          </div>
          <div className="fare-bottom">
            <button className="grn-btn">Inclusions</button>
            <div className="green-box1">

              <div className="grn-con"> 
                {cabType && cabType?.rideDistance ? cabType?.rideDistance : "300"}{" "}
                km @Rs.10{" "}
                {fareDetails && fareDetails?.extraKmCharge
                  ? fareDetails?.extraKmCharge.toFixed(2)
                  : ""}{" "}
                 /km{" "}
              </div>

              <div className="grn-con">
                Night Charge Rs.{"300"}
                {fareDetails && fareDetails?.dayDriverAllowance
                  ? fareDetails?.dayDriverAllowance.toFixed(2)
                  : ""}
                /day X {cabType && cabType?.noOfDays ? cabType?.noOfDays : ""}{" "}
               1 Day
              </div>


              <div className="grn-con">
                {" "}
                GST ({" "}
                {fareDetails && fareDetails ? fareDetails?.gstRate : "5"}% )
              </div>
            </div>

            <button className="grn-btn">Exclusions</button>

            <div className="green-box2">
              <div className="grn-con">
                Pay ₹
                {fareDetails && fareDetails?.extraKmCharge
                  ? fareDetails?.extraKmCharge.toFixed(2)
                  : "11"}{" "}
                /km after{""}
                {cabType && cabType?.rideDistance ? cabType?.rideDistance : ""} {"300"} km
              </div>


              <div className="grn-con">
                Night Charge Rs.{"300"}
                {fareDetails && fareDetails?.nightDriverAllowance
                  ? fareDetails?.nightDriverAllowance.toFixed(2)
                  : ""}
                /day X {cabType && cabType?.noOfDays ? cabType?.noOfDays : ""}{"1"}  day
               
              </div>
              <div className="grn-con">Toll / State tax / Parking</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OutstationDetails;
