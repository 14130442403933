import React from "react";
import "react-datepicker/dist/react-datepicker.css";
import "./Dropdown.css";
import { IoIosArrowDown } from "react-icons/io";

const DropdownColumn = ({
  options,
  selectedValue,
  handleChange,
  placeholder,
  useInlineStyle,
}) => {
  const placeholderOption = {
    label: placeholder || "Select an option",
    value: "",
  };

  // const inlineStyle = useInlineStyle
  //   ? {}
  //   : {
  //       display: "flex",
  //       width: "100%",
  //       height: "22px",
  //       padding: "0px 33px",
  //       alignItems: "center",
  //       gap: "4px",
  //       flexShrink: 0,
  //       cursor: "pointer",
  //       background: "#FFF",
  //       fontWeight: 600,
  //       boxShadow:
  //         "0px -1px 0px 0px #D9D9D9 inset, 0px 1px 0px 0px #D9D9D9 inset, -1px 0px 0px 0px #D9D9D9 inset", // Add box shadow
  //       color: "#585858",
  //       leadingTrim: "both",
  //       textEdge: "cap",
  //       fontFamily: "Inter",
  //       fontSize: "10px",
  //       fontStyle: "normal",
  //       lineHeight: "22px" /* 275% */,
  //       borderRadius: "0",
  //       borderLeft: "1px solid var(--gray-border-gray-5, #D9D9D9)",
  //       marginTop: "2px",
  //       "::placeholder": {
  //         color: "#585858",
  //         leadingTrim: "both",
  //         textEdge: "cap",
  //         fontFamily: "Poppins",
  //         fontSize: "8px",
  //         fontStyle: "normal",
  //         fontWeight: 400,
  //         lineHeight: "22px" /* 275% */,
  //         padding: "60px",
  //       },
  //       ":focus": {
  //         borderColor: "#FF6A28",
  //         boxShadow: "0px 0px 0px 1px #FF6A28",
  //       },
  //     };

  return (
    <div className="div1">
      <select
        value={selectedValue}
        onChange={handleChange}
        className="dropdown-select-1 " style={{borderRadius:"5px", height:"28px", width:"126px", border:"1px solid white", boxShadow: "0px 1px 8px 0px #0000002B"
        }}
        // style={inlineStyle}
      >
        {[placeholderOption, ...options].map((option, index) => (
          <React.Fragment key={index}>
            {Array.isArray(option.label) ? (
              option.label.map((subOption, subIndex) => (
                <option key={subIndex} value={subOption}>
                  {subOption}
                </option>
              ))
            ) : (
              <option key={index} value={option.value}>
                {option.label}
              </option>
            )}
          </React.Fragment>
        ))}
      </select>

      <IoIosArrowDown className="dropdown-icon" style={{marginTop:"0px", paddingTop:"5px"}}  />

      </div>
  );
};

export default DropdownColumn;
