import React, { useState, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { ImEye } from "react-icons/im";
import { FaStar } from "react-icons/fa6";
import "./ViewAllCar.css";
import { FaPen } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { fetchDriver, selectAllDriver, selectLoading } from "./DriverSlice";
import {
  currentDriverId,
  resetDriverDetails,
  fetchDriversDetails,
} from "./DriverDetailsSlice";
import { useNavigate } from "react-router-dom";
import BookingHistoryPopUp from "./bookinghistory/BookingHistoryPopUp";
import InputColumn from "../Booking/Input";
import DropdownColumn from "../Booking/Dropdown";
import Filter from "../Booking/Filter"; 
import { Toggle } from "../TariffManagement/Toggle";
import { toggleDriverStatus } from "./DriverSlice";
import axios from "axios";

const TableItem = ({ item, index }) => {
  const rowClass = index % 2 === 0 ? "table-row-even" : "table-row-odd";

  return (
    <tr className={rowClass}>
      <td>{item["Sr no."]}</td>
      <td>{item["Location"]}</td>
      <td>{item["Driver Name"]}</td>
      <td>{item["Driver Mobile No."]}</td>
      <td>{item["No. of Ride"]}</td>
      <td>{item["Rating"]}</td>
      <td>{item["Registered On"]}</td>
      <td>{item["Verified On"]}</td>
      <td>{item["Status"]}</td>
      <td>{item["Booking History"]}</td>
      <td>{item["Action"]}</td>
    </tr>
  );
};

const Table = ({ headers, data }) => {
  return (
    <div className="table-containers">
      <div className="table-scroll">
        <table className="table">
          <thead>
            <tr>
              {headers.map((header, index) => (
                <th key={index}>{header}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <TableItem key={index} item={item} index={index} />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const ViewAllCar = () => {
  const [locationOptions, setLocationOptions] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState("");
  const [searchPhoneNumber, setsearchPhoneNumber] = useState("");
  const [searchName, setsearchName] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedApprovel, setselectedApprovel] = useState("");
  const [placeholder, setPlaceholder] = useState("Select a location");

  const dispatch = useDispatch();
  const drivers = useSelector(selectAllDriver);
  const loader = useSelector(selectLoading);
  const { driversId } = useSelector((state) => state.driverInfo);
  const navigate = useNavigate();

  const currentSection = "ViewAllDriver";
  const [showDetails, setShowDetails] = useState(false);
  const [editDetails, setEditDetails] = useState(false);
  const [popUpBookingHistory, setPopUpBookingHistory] = useState(false);
  const [driverIdHistory,setDriverIdHistory] = useState('');

  console.log(loader);
  useEffect(() => {
    dispatch(fetchDriver());
  }, [dispatch]);

  const handleImEyeClick = (id) => { 
    console.log("checking driver id", id); 
    dispatch(currentDriverId(id));
    setShowDetails(true);
  };

  const handleEditIcon = (id) => {
    dispatch(currentDriverId(id));
    setEditDetails(true);
  };

  const handleShowBookingHistory = async(driverId) => {
    await setDriverIdHistory(driverId);
    setPopUpBookingHistory(true);
    
  };

  const handleDriverStatus = async (driverId, newStatus, dispatch) => {
    try {
      const response = await axios.put(
        `https://api.yatricabs.com/v1/admin/drivers/${driverId}/status`,
        {
          status: newStatus,
        }
      );
      console.log("Response:", response.data);
      dispatch(toggleDriverStatus({ driverId, newStatus }));
      dispatch(fetchDriver());
    } catch (error) {
      console.error("Error toggling driver status:", error);
    }
  };

  const logState = (state, driverId, dispatch) => {
    const newStatus = state === true ? "Inactive" : "Active";
    handleDriverStatus(driverId, newStatus, dispatch);
  };

  console.log("Drivers:",drivers);

  const headers = [
    "Sr no.",
    "Location",
    "Driver Name",
    "Driver Mobile No.",
    "No. of Ride",
    "Rating",
    "Registered On",
    "Verified On",
    "Status",
    "Booking History",
    "Action",
  ];
  // let filteredDrivers = drivers;

  // // Filter based on selectedLocation
  // if (selectedStatus) {
  //   filteredDrivers = drivers.filter(
  //     (driver) => driver.status === selectedStatus
  //   );
  // }

  // // Filter based on selectedLocation
  // if (selectedLocation) {
  //   filteredDrivers = drivers.filter(
  //     (driver) => driver.location === selectedLocation
  //   );
  // }

  // // Filter based on searchName
  // if (searchName) {
  //   filteredDrivers = filteredDrivers.filter((driver) =>
  //     driver.name.toLowerCase().includes(searchName.toLowerCase())
  //   );
  // }

  // // Filter based on searchPhoneNumber
  // if (searchPhoneNumber) {
  //   filteredDrivers = filteredDrivers.filter((driver) =>
  //     driver.phone.includes(searchPhoneNumber)
  //   );
  // }

  const filteredDrivers = drivers.filter((driver) => {
    const selectedRating = parseInt(selectedApprovel, 10);
    return (
      (!selectedStatus || driver.status === selectedStatus) &&
      (!selectedLocation || driver.location === selectedLocation) &&
      (!searchName ||
        driver.name.toLowerCase().includes(searchName.toLowerCase())) &&
      (!searchPhoneNumber || driver.phone.includes(searchPhoneNumber)) &&
      (!selectedRating || driver.rating === selectedRating)
    );
  });

  const data = [];

  for (let i = 0; i < filteredDrivers.length; i++) {
    const driver = filteredDrivers[i];
    const ratingColor = driver.rating < 3 ? "rgba(56, 176, 0, 1)" : "rgba(109, 109, 109, 1)";

    data.push({
      "Sr no.": i + 1,
      Location: driver.location,
      "Driver Name": driver.name,
      // "Driver Mobile No.": driver.phone.startsWith("+91-")
      //   ? driver.phone
      //   : `+91- ${driver.phone}`,
      "Driver Mobile No.": driver.phone,
      "No. of Ride": driver.noOfRides,
      Rating: (
        <>
          {Array.from({ length: driver?.rating || 2 }).map((_, index) => (
            <FaStar
              key={index}
              className="star"
              style={{ color: ratingColor }}
            />
          ))}
        </>
      ),
      "Registered On": driver?.createdAt,
      "Verified On": driver?.approval?.verifiedOn || "Not Verified",
      // "Verified On": "22-10-2023", // "verifiedOn" is not present in the data
      Status: driver.status,
      "Booking History": (
        <button className="button" onClick={()=>handleShowBookingHistory(driver._id)}>
          Show
        </button>
      ),
      Action: (
        <div className="box-action">
          <Toggle
            toggled={driver.status === "Active" ? false : true}
            onClick={(state) => logState(state, driver._id, dispatch)}
          />
          <ImEye
            className="icon icon-large"
            onClick={() => handleImEyeClick(driver._id)}
          />
          <FaPen
            className="icon-edit "
            onClick={() => handleEditIcon(driver._id)}
          />
        </div>
      ),
    });
  }

  const DriverList = data;

  useEffect(() => {
    const extractlocationAsOptions = (dataArray) => {
      if (Array.isArray(dataArray.data)) {
        const allLocationsOption = { label: "Select a location", value: "" };
        const locationOptions = dataArray.data.map((item) => ({
          label: item.location,
          value: item._id,
        }));
        return [allLocationsOption, ...locationOptions];
      }
      return [];
    };

    const fetchOptions = async () => {
      try {
        const locationRespons = await fetch(
          "https://api.yatricabs.com/v1/locations"
        );
        if (!locationRespons.ok) {
          throw new Error(`HTTP error! Status: ${locationRespons.status}`);
        }
        const locationData = await locationRespons.json();
        const locationOptions = extractlocationAsOptions(locationData);
        console.log(locationOptions);
        setLocationOptions(locationOptions);
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };
    fetchOptions();
  }, []);

  useEffect(() => {
    if (driversId !== null && showDetails) {
      dispatch(resetDriverDetails());
      dispatch(fetchDriversDetails(driversId));
      navigate(
        `/drivermanagement/${currentSection}/DriverDetails/${driversId}`
      );
    }
    setShowDetails(false);
    console.log("checking drivres id in effect", driversId);
  }, [showDetails]);

  useEffect(() => {
    if (driversId !== null && editDetails) {
      dispatch(resetDriverDetails());
      navigate(
        `/drivermanagement/${currentSection}/modify-details/${driversId}`
      );
    }
    setShowDetails(false);
    console.log("checking drivres id in effect", driversId);
  }, [editDetails]);

  return (
    <div >
      <BookingHistoryPopUp
        popUpBookingHistory={popUpBookingHistory}
        setPopUpBookingHistory={setPopUpBookingHistory}
        driverId={driverIdHistory}
      />
      <>
          <div className="dropdown-container">
            <Filter />
            <DropdownColumn
              placeholder={placeholder}
              options={locationOptions}
              selectedValue={selectedLocation}
              // handleChange={(e) => setSelectedLocation(e.target.value)}
              handleChange={(e) => {
                const selectedOption = locationOptions.find(
                  (option) => option.value === e.target.value
                );

                if (selectedOption) {
                  const selectedCity = selectedOption.label;
                  console.log("Selected City:", selectedCity);
                  // setSelectedLocation(selectedCity);
                  // setPlaceholder(selectedCity);
                  if (selectedCity === "Select a location") {
                    // If "Location" is selected, set an empty string to show all locations
                    setSelectedLocation("");
                    setPlaceholder("Select a location");
                  } else {
                    setSelectedLocation(selectedCity);
                    setPlaceholder(selectedCity);
                  }
                } else {
                  setPlaceholder("Select a location");
                }
              }}
              className="dropdown"
            />
            <InputColumn
              title="Search by Name"
              selectedValue={searchName}
              handleChange={(e) => setsearchName(e.target.value)}
            />
            <InputColumn
              title="Search by Mobile No."
              selectedValue={searchPhoneNumber}
              handleChange={(e) => setsearchPhoneNumber(e.target.value)}
            />
            <DropdownColumn
              placeholder="Status"
              options={[
                { label: "Active", value: "Active" },
                { label: "Inactive", value: "Inactive" },
              ]}
              selectedValue={selectedStatus}
              handleChange={(e) => setSelectedStatus(e.target.value)}
              className="dropdown"
            />
            <DropdownColumn
              placeholder="Rating"
              options={[
                { label: "1 Star", value: "1" },
                { label: "2 Stars", value: "2" },
                { label: "3 Stars", value: "3" },
                { label: "4 Stars", value: "4" },
                { label: "5 Stars", value: "5" },
              ]}
              selectedValue={selectedApprovel}
              handleChange={(e) => setselectedApprovel(e.target.value)}
              className="dropdown"
            />
          </div>
        
        <Table
          headers={headers}
          data={DriverList.map((item) => ({
            ...item,
          }))}
        />
      </>
      {/*})*/}
    </div>
  );
};

export default ViewAllCar;
