// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.noshowbookinghistory {
  display: none;
}
.pop_up_main_container {
  z-index: 1;
  display: flex;
  justify-content: center;
  position: absolute;
  width: 100%;
}
.pop_up_main_container svg {
  font-size: 25px;
  color: #38b000;
  background: white;
  border-radius: 13px;
  box-shadow: 3px 3px 5px 8px #d9d9d97a;
}
.pop_up_main_container svg:hover {
  cursor: pointer;
}
.pop_up_sub_container {
  display: flex;
  gap: 10px;
}

.pop_up_sub_container img {
  width: 130px;
  height: 45px;
  margin-top: 10px;
  margin-left: 20px;
  margin-right: 28px;
  cursor: pointer;
}

.background_white {
  height: 80vh;
  overflow-y: auto;
  background: white;
  border: 1px solid #f3eaeab8;
  border-radius: 10px;
  padding: 0.3rem 0.3rem 0.3rem;
  box-shadow: 3px 3px 5px 8px #d9d9d97a;
}
.pop_up_content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;
}
.pop_up_content p {
  font-size: 1.3rem;
  color: #38b000;
  font-family: "Lato";
}
`, "",{"version":3,"sources":["webpack://./src/components/DriverManagement/bookinghistory/bookinghistorypopup.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;AACA;EACE,UAAU;EACV,aAAa;EACb,uBAAuB;EACvB,kBAAkB;EAClB,WAAW;AACb;AACA;EACE,eAAe;EACf,cAAc;EACd,iBAAiB;EACjB,mBAAmB;EACnB,qCAAqC;AACvC;AACA;EACE,eAAe;AACjB;AACA;EACE,aAAa;EACb,SAAS;AACX;;AAEA;EACE,YAAY;EACZ,YAAY;EACZ,gBAAgB;EAChB,iBAAiB;EACjB,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,YAAY;EACZ,gBAAgB;EAChB,iBAAiB;EACjB,2BAA2B;EAC3B,mBAAmB;EACnB,6BAA6B;EAC7B,qCAAqC;AACvC;AACA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,SAAS;AACX;AACA;EACE,iBAAiB;EACjB,cAAc;EACd,mBAAmB;AACrB","sourcesContent":[".noshowbookinghistory {\n  display: none;\n}\n.pop_up_main_container {\n  z-index: 1;\n  display: flex;\n  justify-content: center;\n  position: absolute;\n  width: 100%;\n}\n.pop_up_main_container svg {\n  font-size: 25px;\n  color: #38b000;\n  background: white;\n  border-radius: 13px;\n  box-shadow: 3px 3px 5px 8px #d9d9d97a;\n}\n.pop_up_main_container svg:hover {\n  cursor: pointer;\n}\n.pop_up_sub_container {\n  display: flex;\n  gap: 10px;\n}\n\n.pop_up_sub_container img {\n  width: 130px;\n  height: 45px;\n  margin-top: 10px;\n  margin-left: 20px;\n  margin-right: 28px;\n  cursor: pointer;\n}\n\n.background_white {\n  height: 80vh;\n  overflow-y: auto;\n  background: white;\n  border: 1px solid #f3eaeab8;\n  border-radius: 10px;\n  padding: 0.3rem 0.3rem 0.3rem;\n  box-shadow: 3px 3px 5px 8px #d9d9d97a;\n}\n.pop_up_content {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  gap: 15px;\n}\n.pop_up_content p {\n  font-size: 1.3rem;\n  color: #38b000;\n  font-family: \"Lato\";\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
