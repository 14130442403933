// import React from 'react'
// import './FareDetails.css'
// const FareDetails = ({ fareDetails }) => {
//   return (
//     <>
//     <div className="fd-details-container">
//         <div className="head-title"><p>Fare  Details</p></div>

//         <div className="subheader-strong-container">
//             <p>Ride Estimate</p>
//             <div>
//                 <b>Rs. 00.00</b>
//                 <p>(incl. of all taxes)</p>
//             </div>
//         </div>

//         <div className="row-flexer">
//             <p>Included Km @ Min 300km/day</p>
//             <p>0 Km</p>
//         </div>

//         <div className="row-flexer">
//             <p>Base Fare @ Rs.10/km</p>
//             <p>Rs. 00.00</p>
//         </div>

//         <div className="row-flexer">
//             <p>Night charges @ Rs.300 X 1 Days</p>
//             <p>Rs. 00.00</p>
//         </div>

//         <div className="row-flexer">
//             <p>GST @ 5%</p>
//             <p>Rs. 00.00</p>
//         </div>

//     </div>

// <div className="info-container">
//     <div className="infohead">Inclusions</div>
//     <div className="info-wrapper">
//         <p>300km @Rs.10/km </p>
//         <p>Night Charge  Rs.300/day X 1 Day</p>
//         <p>GST (5%)</p>
//     </div>
// </div>

// <div className="info-container">
//     <div className="infohead">Exclusion</div>
//     <div className="info-wrapper">
//         <p>Pay ₹11/km after 300 km </p>
//         <p>Night Charge Rs.300/day X 1 Day</p>
//         <p>Toll / State tax / Parking</p>
//     </div>
// </div>

//     </>
//   )
// }

// export default FareDetails

////////////////////////////////////////////////////

import React from "react";
import "./FareDetails.css";
const FareDetails = ({ fareDetails }) => {
	if (!fareDetails) {
		return (
			<>
				<div className="fd-details-container">
					<div className="head-title">
						<p>Fare Details</p>
					</div>

					<div className="subheader-strong-container">
						<p>Ride Estimate</p>
						<div>
							<b>Rs. 00.00</b>
							<p>(incl. of all taxes)</p>
						</div>
					</div>

					<div className="row-flexer">
						<p>Included Km </p>
						<p>0 Km</p>
					</div>

					<div className="row-flexer">
						<p>Base Fare </p>
						<p>Rs. 00.00</p>
					</div>

					<div className="row-flexer">
						<p>Night charges </p>
						<p>Rs. 00.00</p>
					</div>

					<div className="row-flexer">
						<p>GST @ 5%</p>
						<p>Rs. 00.00</p>
					</div>
				</div>
			</>
		);
	}

	return (
		<div className="fd-details-container">
			<div className="head-title">
				<p>Fare Details</p>
			</div>
			<div className="subheader-strong-container">
				<p>Ride Estimate</p>
				<div>
					<b>Rs. {fareDetails.fareDetails.estimatedFare.toFixed(2)}</b>
					<p>(incl. of all taxes)</p>
				</div>
			</div>

			<div className="row-flexer">
				<p>Included Km @ Min {fareDetails.fareDetails.minKmPerDay}km/day</p>
				<p>{fareDetails.rideDistance} Km</p>
			</div>

			<br />

			<div className="row-flexer">
				<p>Base Fare </p>
				<p>Rs. {fareDetails?.fareDetails?.baseFare.toFixed(2)}</p>
			</div>

			{fareDetails?.dayDriverAllowance > 0 && (
				<div className="row-flexer">
					<p>Driver Allowance </p>
					<p>Rs. {fareDetails?.dayDriverAllowance?.toFixed(2)}</p>
				</div>
			)}

			{fareDetails?.nightAllowance > 0 && (
				<div className="row-flexer">
					<p>Night Charge </p>
					<p>Rs. {fareDetails?.nightAllowance?.toFixed(2)}</p>
				</div>
			)}

			{fareDetails?.morningAllowance > 0 && (
				<div className="row-flexer">
					<p>Morning Charge </p>
					<p>Rs. {fareDetails?.morningAllowance?.toFixed(2)}</p>
				</div>
			)}

			<div className="row-flexer">
				<p>GST @ {fareDetails?.fareDetails?.gstRate}%</p>
				<p>Rs. {fareDetails?.fareDetails?.gstAmount.toFixed(2)}</p>
			</div>

			{/* Additional fare details... */}

			<div className="info-container">
				<div className="infohead">Inclusions</div>
				<div className="info-wrapper">
					<p>
						{fareDetails?.rideDistance}km @Rs.
						{fareDetails?.fareDetails?.perKmCharge}/km{" "}
					</p>
					{fareDetails?.nightAllowance > 0 && (
						<p>
							Night Charge Rs.{fareDetails?.fareDetails?.nightDriverAllowance}
						</p>
					)}
					{fareDetails?.morningAllowance > 0 && (
						<p>
							Morning Charge Rs.
							{fareDetails?.fareDetails?.morningDriverAllowance}
						</p>
					)}
					{fareDetails?.driverAllowance > 0 && (
						<p>
							Driver Allowance Rs.
							{fareDetails?.fareDetails?.dayDriverAllowance}
						</p>
					)}
					<p>GST ({fareDetails?.fareDetails?.gstRate}%)</p>
				</div>
			</div>

			<div className="info-container">
				<div className="infohead">Exclusion</div>
				<div className="info-wrapper">
					<p>
						Pay ₹{fareDetails?.fareDetails?.extraKmCharge}/km after{" "}
						{fareDetails?.rideDistance} km{" "}
					</p>
					{fareDetails?.nightAllowance == 0 && (
						<p>
							Night Charge Rs.{fareDetails?.fareDetails?.nightDriverAllowance}
							/day X {fareDetails?.noOfDays || 1} Day
						</p>
					)}
					{fareDetails?.morningAllowance == 0 && (
						<p>
							Morning Charge Rs.
							{fareDetails?.fareDetails?.morningDriverAllowance}
							/day X {fareDetails?.noOfDays || 1} Day
						</p>
					)}
					{fareDetails?.dayDriverAllowance == 0 && (
						<p>
							Driver Allowance Rs.
							{fareDetails?.fareDetails?.dayDriverAllowance}
							/day X {fareDetails?.noOfDays || 1} Day
						</p>
					)}
					<p>Toll / State tax / Parking</p>
				</div>
			</div>
		</div>
	);
};

export default FareDetails;
