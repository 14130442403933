import React, { useState, useRef, useEffect } from "react";
import CountryDropdown from "../countrydropdown/CountryDropdown";
import "./AddCustomer.css";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import { useParams, Link, useNavigate } from "react-router-dom";
import { fetchCustomerDetails, resetCompState } from "./CustomerDetailsSclice";
import { FaLongArrowAltRight } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../Loader";

const EditCustomerDetails = () => {
  const [firstName, setFirstName] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [alternateMobile, setAlternateMobile] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumberVerified, setPhoneNumberVerified] = useState(false);
  const [otpInput, setOtpInput] = useState(["", "", "", "", "", ""]);
  const [verified, setVerified] = useState(false);
  const [OtpId, setOtpId] = useState("");
  const [verificationToken, setVerificationToken] = useState("");
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const otpInputs = useRef([]);

  const singleCustomerDetail = useSelector(
    (state) => state.customerInfo?.customerDetails
  );

  const handleOTPChange = (index, value) => {
    const updatedOTP = [...otpInput];
    updatedOTP[index] = value;
    setOtpInput(updatedOTP);

    if (value !== "" && index < otpInputs.current.length - 1) {
      otpInputs.current[index + 1].focus();
    }
  };

  if (selectedCountry && phoneNumber) {
    var completePhoneNumber = `${selectedCountry}-${phoneNumber}`;
  }

  if (selectedCountry && alternateMobile) {
    var completeAlterNumber = `${selectedCountry}-${alternateMobile}`;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const headers = {
      "Content-Type": "application/json",
    };
    const custumerObj = new FormData();
    custumerObj.append("firstName", firstName);
    custumerObj.append("lastName", lastName);
    // custumerObj.append("email", email);
    custumerObj.append("phone", completePhoneNumber);
    // custumerObj.append("otpToken", verificationToken);
    // custumerObj.append("alternatePhone", completeAlterNumber);

    console.log("Data Entered");
    for (const pair of custumerObj.entries()) {
      console.log(pair[0] + ": " + pair[1]);
    }

    try {
      await axios.put(
        `https://api.yatricabs.com/v1/admin/users/${id}`,
        custumerObj,
        { headers }
      );
      toast.success("Details Updated successfully!");

      setTimeout(() => {
        window.location.reload();
      }, 3000);

      e.target.reset();
    } catch (error) {
      toast.error(error.response.data.message);
      console.error(error.response.data.message);
    }
  };

  const handleVerifyPhoneNumber = () => {
    console.log("Phone Number:", phoneNumber);
    fetch("https://api.yatricabs.com/v1/send-otp", {
      method: "POST",
      body: JSON.stringify({ phone: completePhoneNumber }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.ok) {
          console.log("OTP sent successfully");
          console.log(response);
          return response.json();
        } else {
          throw new Error("Failed to send OTP");
        }
      })
      .then((data) => {
        if (data && data.data && data.data.otpId) {
          setOtpId(data.data.otpId);
          setPhoneNumberVerified(true);
          console.log("OTP ID:", data.data.otpId);
        } else {
          throw new Error("Invalid response format from OTP sending API");
        }
      })
      .catch((error) => {
        console.error("Error sending OTP:", error.message);
      });
  };

  const handleVerifyOTP = () => {
    const otpNumber = parseInt(otpInput.join(""), 10);
    console.log("OTP:", otpNumber);
    console.log("OTP ID:", OtpId);
    fetch("https://api.yatricabs.com/v1/verify-otp", {
      method: "POST",
      body: JSON.stringify({
        phone: completePhoneNumber,
        otp: otpNumber,
        otpId: OtpId,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.ok) {
          console.log("OTP verified successfully");
          console.log(response);
          setVerified(true);
          return response.json();
        } else {
          throw new Error("Failed to verify OTP");
        }
      })
      .then((data) => {
        setVerificationToken(data.otpToken);
        console.log("Message:", data.message);
        console.log("Token:", data.otpToken);
      })
      .catch((error) => {
        console.error("Error verifying OTP:", error.message);
      });

    console.log("Phone Number:", phoneNumber);
  };

  const handlePhoneCode = (phoneNumber, value) => {
    let ind = 0;
    let code = "+";
    while (phoneNumber[ind] !== "-") {
      if (phoneNumber[ind] !== "+") {
        code = code + phoneNumber[ind];
      }
      ind = ind + 1;
    }
    const checkNumber = phoneNumber.slice(ind + 1);
    if (value === "phone") {
      setPhoneNumber(checkNumber);
    }

    if (value === "alternatePhone") {
      setAlternateMobile(checkNumber);
    }
    setSelectedCountry(code);
    console.log("checking code", code);
    console.log("checking Number", checkNumber);
  };

  useEffect(() => {
    if (singleCustomerDetail) {
      console.log("checking customer details", singleCustomerDetail);
      setFirstName(singleCustomerDetail.firstName);
      setLastName(singleCustomerDetail.lastName);
      // setPhoneNumber(singleCustomerDetail.phone);
      // setAlternateMobile(singleCustomerDetail.alternatePhone);
      setEmail(singleCustomerDetail.email);
      handlePhoneCode(singleCustomerDetail.phone, "phone");
      handlePhoneCode(singleCustomerDetail.alternatePhone, "alternatePhone");
    }
  }, [singleCustomerDetail]);

  useEffect(() => {
    if (id) {
      dispatch(fetchCustomerDetails(id));
    }
  }, []);
  return (
    <>
      <Toaster />
      <form onSubmit={handleSubmit}>
        <div className="nav-content" style={{ marginTop: "25px" }}>
          <div className="header-text">
            <Link className="h3-booking">Customers</Link>
            <FaLongArrowAltRight />
            {""}
            <Link className="h3-booking">View All Customers</Link>
            <FaLongArrowAltRight />
            <Link className="h2-booking">Edit Customer Details</Link>
          </div>

          <button
            className="createButton"
            onClick={() => navigate("/customer")}
          >
            View All Customers
          </button>
        </div>
        {!singleCustomerDetail ? (
          <Loader />
        ) : (
          <>
            <div className="add-new-customer-container">
              <div className="customer">
                <div className="crete-new-sub-head">Edit Customer Details</div>
                <div className="customer-details">
                  <div className="row1">
                    <div className="col">
                      <div className="col-item">
                        <div className="dropdown-column">
                          <div className="title">First Name</div>
                          <input
                            type="text"
                            onChange={(e) => setFirstName(e.target.value)}
                            placeholder="Type First Name"
                            className="input"
                            value={firstName}
                          />
                        </div>
                      </div>

                      <div className="col-item-phone">
                        <div
                          className={`dropdown-phone ${
                            phoneNumberVerified && !verified
                              ? "column-layout"
                              : "row-layout"
                          }`}
                        >
                          <div className="title">Primary Mobile No.</div>
                          {!verified ? (
                            <React.Fragment>
                              <CountryDropdown
                                selectedCountry={selectedCountry}
                                setSelectedCountry={setSelectedCountry}
                              />
                              <input
                                type="text"
                                onChange={(e) => setPhoneNumber(e.target.value)}
                                maxLength="10"
                                placeholder="Type Mobile Number"
                                className="input-mobile"
                                value={phoneNumber}
                                disabled={phoneNumberVerified}
                              />
                              {!phoneNumberVerified && (
                                <div
                                  className="verify"
                                  onClick={handleVerifyPhoneNumber}
                                >
                                  Sent OTP
                                </div>
                              )}
                              <div className="otp-inputs">
                                {phoneNumberVerified &&
                                  !verified &&
                                  otpInput.map((value, index) => (
                                    <input
                                      key={index}
                                      ref={(input) =>
                                        (otpInputs.current[index] = input)
                                      }
                                      type="text"
                                      maxLength="1"
                                      className="otp-input"
                                      value={value}
                                      onChange={(e) =>
                                        handleOTPChange(index, e.target.value)
                                      }
                                    />
                                  ))}
                              </div>
                              {phoneNumberVerified && !verified && (
                                <div
                                  className="verify"
                                  onClick={handleVerifyOTP}
                                >
                                  Verify OTP
                                </div>
                              )}
                            </React.Fragment>
                          ) : (
                            <React.Fragment>
                              <div className="phone-number">
                                <CountryDropdown
                                  selectedCountry={selectedCountry}
                                  setSelectedCountry={setSelectedCountry}
                                />
                                <input
                                  style={{ marginLeft: "28px" }}
                                  type="tel"
                                  onChange={(e) =>
                                    setPhoneNumber(e.target.value)
                                  }
                                  maxLength="10"
                                  value={phoneNumber}
                                  placeholder="Type Mobile Number"
                                  className="input-mobile"
                                />
                                <div className="verified">Verified</div>
                              </div>
                            </React.Fragment>
                          )}
                        </div>
                      </div>

                      <div className="col-item">
                        <div className="dropdown-column">
                          <div className="title">Email Address</div>
                          <input
                            type="text"
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="Type Email Address"
                            className="input"
                            value={email}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col">
                      <div className="col-item">
                        <div className="dropdown-column">
                          <div className="title">Last Name</div>
                          <input
                            title="Last Name"
                            onChange={(e) => setLastName(e.target.value)}
                            placeholder="Type Last Name"
                            className="input"
                            value={lastName}
                          />
                        </div>
                      </div>
                      <div className="col-item">
                        <div className="dropdown-column">
                          <div className="title">Alternate Mobile No.</div>
                          <CountryDropdown
                            selectedCountry={selectedCountry}
                            setSelectedCountry={setSelectedCountry}
                          />
                          <input
                            style={{ marginTop: "-8px" }}
                            type="mobile"
                            title="Alternate Mobile No."
                            onChange={(e) => setAlternateMobile(e.target.value)}
                            placeholder="Alternate Mobile Number"
                            className="input-mobile"
                            value={alternateMobile}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="add">
                <button type="submit" className="add-btn">
                  Update
                </button>
              </div>
            </div>
          </>
        )}
      </form>
    </>
  );
};

export default EditCustomerDetails;
