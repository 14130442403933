import axios from "axios";
import { format } from "date-fns";
import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import useSuggestionsCity from "../../../../hooks/useSuggestionsIndianCities";
import { generateTimeOptions } from "../../../../utils/time/time";
import useSuggestionsCustomer from "../../../../hooks/useSuggestionCustomer";
import useSuggestionsCitiesAvailable from "../../../../hooks/useSuggestionCities";
import "./shared.css";
import searchicon from "../../../../assets/search-icon.svg";
import add from "../../../../assets/add.svg";
import minus from "../../../../assets/minus.svg";
import location from "../../../../assets/location.svg";
import clock from "../../../../assets/clock.svg";
import "../../../Booking/Dropdown.css";
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const apiUrl = "AIzaSyB1LsPrgq_8-ygapY5zgmzGhXuPcn4VtQQ";
let autoComplete;

const loadScript = (url, callback) => {
	let script = document.createElement("script");
	script.type = "text/javascript";

	if (script.readyState) {
		script.onreadystatechange = function () {
			if (script.readyState === "loaded" || script.readyState === "complete") {
				script.onreadystatechange = null;
				callback();
			}
		};
	} else {
		script.onload = () => callback();
	}

	script.src = url;
	document.getElementsByTagName("head")[0].appendChild(script);
};

//======================== 👇 Roundtrip 👇 ====================//
//======================== 👇 Roundtrip 👇 ====================//

const Roundtrip = ({ setFareDetails, fareDetails, states, setters }) => {
	const navigate = useNavigate();

	const {
		tripType,
		subTripType,
		pickUpLocation,
		selectedLocation,
		selectDropAddress,
		pickUpDate,
		dropDate,
		pickUpTime,
		timeOptions,
		isDropdownVisible,
		cabType,
		allCabsData,
		selectedCabType,
		selectTemp,
		selectedTariffId,
		carCategoriesDetails,
		additionalCities,
		search,
		listVisible,
		showDropdown,
		showDropdownDestination,
		activeInputIndex,
		errorMessages,
		showDate,
		startDate,
		endDate,
		isNewCustomer,
		selectCustomer,
		selectCustomerId,
		showSuggestions,
		firstName,
		lastName,
		mobileNo,
		email,
		AlternateContactNumber,
		pickupAddress,
		longitude,
		gstValue,
		flightValue,
		isGstChecked,
		isFlightChecked,
		comments,
		selectedAdvanceAmount,
		otp,
		otpId,
		isOtpModalOpen,
		errors,
	} = states;

	const {
		setTripType,
		setSubTripType,
		setPickUpLocation,
		setSelectedLocation,
		setSelectDropAddress,
		setPickUpDate,
		setDropDate,
		setPickUpTime,
		setTimeOptions,
		setIsDropdownVisible,
		setCabType,
		setAllCabsData,
		setSelectedCabType,
		setSelectTemp,
		setSelectedTariffId,
		setCarCategoriesDetails,
		setAdditionalCities,
		setSearch,
		setListVisible,
		setShowDropdown,
		setShowDropdownDestination,
		setActiveInputIndex,
		setErrorMessages,
		setShowDate,
		setStartDate,
		setEndDate,
		setIsNewCustomer,
		setSelectCustomer,
		setSelectCustomerId,
		setShowSuggestions,
		setFirstName,
		setLastName,
		setMobileNo,
		setEmail,
		setAlternateContactNumber,
		setPickupAddress,
		setLongitude,
		setGstValue,
		setFlightValue,
		setIsGstChecked,
		setIsFlightChecked,
		setComments,
		setSelectedAdvanceAmount,
		setOtp,
		setOtpId,
		setIsOtpModalOpen,
	} = setters;

	const [citySuggestions] = useSuggestionsCity();

	const { suggestions, loading, error, refetch } = useSuggestionsCustomer();

	const [isDropdownOpen, setIsDropdownOpen] = useState(false);

	const handleGstCheckboxChange = (e) => {
		const checked = e.target.checked;
		setIsGstChecked(checked);
		if (!checked) {
			setGstValue(""); // Clear the value when unchecked
		}
	};

	const handleFlightCheckboxChange = (e) => {
		const checked = e.target.checked;
		setIsFlightChecked(checked);
		if (!checked) {
			setFlightValue(""); // Clear the value when unchecked
		}
	};

	const handleNewCustomerChange = () => {
		setIsNewCustomer(true);
		setSelectCustomer(""); // Clear customer search input when selecting new customer
		// Clear other details when switching to new customer
		setFirstName("");
		setLastName("");
		setMobileNo("");
		setEmail("");
	};

	const handleExistingCustomerChange = () => {
		setIsNewCustomer(false);
	};

	const handleSelectSuggestion = (suggestion) => {
		setSelectCustomer(suggestion.label);
		setSelectCustomerId(suggestion.value);
		setFirstName(suggestion.firstname);
		setLastName(suggestion.lastname);
		setMobileNo(suggestion.phone.split("-")[1]); // Assumes phone format is 'prefix-number'
		setEmail(suggestion.email);
		setShowSuggestions(false);
	};

	// Now here is the search location functions

	const autoCompleteRef = useRef(null);

	const handleScriptLoad = (updateQuery, autoCompleteRef) => {
		autoComplete = new window.google.maps.places.Autocomplete(
			autoCompleteRef.current,
			{
				// types: ["(cities)"],
				componentRestrictions: { country: "IN" },
			},
		);

		autoComplete.addListener("place_changed", () => {
			handlePlaceSelect(updateQuery);
		});
	};

	const handlePlaceSelect = async (updateQuery) => {
		const addressObject = await autoComplete.getPlace();

		const query = addressObject.formatted_address;
		updateQuery(query);
		console.log({ query });

		const latLng = {
			lat: addressObject?.geometry?.location?.lat(),
			lng: addressObject?.geometry?.location?.lng(),
		};

		console.log({ latLng });
		setLongitude(latLng);
	};

	useEffect(() => {
		loadScript(
			`https://maps.googleapis.com/maps/api/js?key=${apiUrl}&libraries=places`,
			() => handleScriptLoad(setPickupAddress, autoCompleteRef),
		);
	}, []);

	useEffect(() => {
		if (startDate) {
			const generatedOptions = generateTimeOptions(startDate); // You can customize pickUpDate as needed
			setTimeOptions(generatedOptions);
		}
	}, [startDate]);

	const handleStartDateChange = (e) => {
		setStartDate(e.target.value);
	};

	const handleEndDateChange = (e) => {
		setEndDate(e.target.value);
	};

	const suggestionhide = () => {
		setShowDropdown(false);
		console.log("mouseleave dropdown", showDropdown);
	};

	const suggestionshows = () => {
		setShowDropdown(true);

		console.log("mouseover dropdown ", showDropdown);
	};

	const suggestionshowsdestination = (index) => {
		setActiveInputIndex(index); // Set the active input index to the clicked input
		setShowDropdownDestination(true); // Show dropdown
	};

	const suggestionhidedestination = () => {
		setActiveInputIndex(null); // Reset active input index
		setShowDropdownDestination(false); // Hide dropdown
	};

	// Function to handle adding a new city input
	const handleAddDestination = () => {
		const lastCityIndex = additionalCities.length - 1;

		// Check if the last city input is filled
		if (additionalCities[lastCityIndex] === "") {
			const updatedErrors = [...errorMessages];
			updatedErrors[lastCityIndex] =
				"Please fill out this field before adding a new one.";
			setErrorMessages(updatedErrors);
		} else if (additionalCities.length < 5) {
			// Add a new input only if the last input is filled and there are fewer than 5 inputs
			setAdditionalCities([...additionalCities, ""]); // Add an empty input field
			setErrorMessages([...errorMessages, ""]); // Add an empty error state for the new input
		}
	};

	// Function to handle changes to the city input
	const handleCityChange = (index, value) => {
		const updatedCities = [...additionalCities];
		updatedCities[index] = value; // Update the specific city at the given index
		setAdditionalCities(updatedCities);

		// Clear the error when the user fills in the input
		const updatedErrors = [...errorMessages];
		updatedErrors[index] = value === "" ? "Please fill out this field." : "";
		setErrorMessages(updatedErrors);
	};

	// Function to handle removing a city input
	const handleRemoveCity = (index) => {
		const updatedCities = [...additionalCities];
		updatedCities.splice(index, 1); // Remove the city at the given index
		setAdditionalCities(updatedCities);

		const updatedErrors = [...errorMessages];
		updatedErrors.splice(index, 1); // Remove the corresponding error message
		setErrorMessages(updatedErrors);
	};

	useEffect(() => {
		const extractLocationsAsOptions = (dataArray) => {
			if (Array.isArray(dataArray.data)) {
				return dataArray.data.map((item) => ({
					label: item.location,
					value: item.location,
				}));
			}
			return [];
		};

		const fetchData = async () => {
			try {
				const locationResponse = await fetch(`${API_BASE_URL}/v1/locations`);
				const locationData = await locationResponse.json();
				const pickUpLocation = extractLocationsAsOptions(locationData);
				setPickUpLocation(pickUpLocation);
			} catch (error) {
				console.error("Error fetching data: ", error);
			}
		};
		fetchData();
	}, [setPickUpLocation]);

	const extractCarCategoriesAsOptions = (dataArray) => {
		if (Array.isArray(dataArray.data)) {
			return dataArray.data.map((item) => ({
				label: item.carCategory.category,
				value: item.carCategory.category,
			}));
		}
		return [];
	};
	// Helper function to format the date
	const formatDate = (date) => {
		if (!(date instanceof Date) || isNaN(date)) {
			return "";
		}
		return format(date, "dd-MM-yyyy");
	};

	// First function: handleChange
	const handleChange = async (e) => {
		const time = e.target.value;
		setPickUpTime(time);

		// Ensure startDate and endDate are valid Date objects
		const formatedPickup = formatDate(new Date(startDate)); // Assuming startDate is a string or valid Date
		const formatedDrop = formatDate(new Date(endDate)); // Assuming endDate is a string or valid Date
		const subTripType = "Round Trip";

		// Construct destination parameters from additionalCities array
		const destinationParams = additionalCities
			.filter((city) => city) // Ensure non-empty city values
			.map((city) => `&destination=${encodeURIComponent(city)}`)
			.join("");

		console.log(
			selectedLocation,
			formatedPickup,
			formatedDrop,
			time,
			subTripType,
			destinationParams,
		);

		try {
			const response = await axios.get(
				`${API_BASE_URL}/v1/users/select-cars/outstation?source=${encodeURIComponent(
					selectedLocation,
				)}&time=${encodeURIComponent(
					time,
				)}&startDate=${formatedPickup}&endDate=${formatedDrop}&subTripType=${subTripType}${destinationParams}`,
			);

			const carCategoryOptions = extractCarCategoriesAsOptions(response.data);
			setCabType(carCategoryOptions);

			setAllCabsData(response.data.data);

			const temp = response.data.tempDataId;
			setSelectTemp(temp);
		} catch (error) {
			toast.error(error.response.data.message);
			console.error("Error fetching cars:", error);
			setCabType([]);
		}
	};

	useEffect(() => {
		setSelectedCabType("");
		setFareDetails(null);
	}, [allCabsData, cabType, pickUpTime]);

	useEffect(() => {
		setSelectedCabType("");
		setPickUpTime("");
		setAdditionalCities([""]);
		setActiveInputIndex(null);
		setStartDate("");
		setEndDate("");
		setFareDetails(null);
	}, [search]);

	useEffect(() => {
		setSelectedCabType("");
		setPickUpTime("");
		setStartDate("");
		setEndDate("");
		setFareDetails(null);
	}, [additionalCities]);

	useEffect(() => {
		setSelectedCabType("");
		setPickUpTime("");
		setEndDate("");
		setFareDetails(null);
	}, [startDate]);

	const handleChangeCabType = (e) => {
		const selectedCab = e.target.value;
		setSelectedCabType(selectedCab);

		// Find the corresponding fare details from the response data
		const selectedFare = allCabsData.find(
			(item) =>
				item.carCategory.category ===
				cabType.find((option) => option.value === selectedCab).label,
		);

		// Update fare details state
		if (selectedFare) {
			setSelectedTariffId(selectedFare._id);
			setFareDetails(selectedFare);
		} else {
			setFareDetails(null);
		}
	};

	const filteredLocations =
		search.length === 0
			? pickUpLocation
			: pickUpLocation.filter((city) =>
					city.label.toLowerCase().includes(search.toLowerCase()),
			  );

	const handleCityClick = (city) => {
		setSelectedLocation(city.label);
		setSearch(city.label);
		setListVisible(false);
	};

	// code for otp verification

	const handleOtpChange = (index, value) => {
		const newOtp = [...otp];
		newOtp[index] = value;

		// If all OTP digits are filled, open the modal
		if (newOtp.every((digit) => digit !== "")) {
			setIsOtpModalOpen(true);
		}

		setOtp(newOtp);

		// Move focus to the next input field
		if (value !== "" && index < otp.length - 1) {
			document.getElementById(`otp-input-${index + 1}`).focus();
		}
	};

	const handleKeyDown = (index, e) => {
		// Move focus to the previous input field when backspace is pressed and the current input is empty
		if (e.keyCode === 8 && otp[index] === "" && index > 0) {
			document.getElementById(`otp-input-${index - 1}`).focus();
		}
	};

	const handleVerifyOtp = async () => {
		try {
			const phoneNumberWithCountryCode = "+91-" + mobileNo;

			const combinedOtp = otp.join("");

			const requestBodyOtp = {
				phone: phoneNumberWithCountryCode,
				otp: Number(combinedOtp),
				otpId: otpId,
			};

			const otpVerifyApiUrl = `${API_BASE_URL}/v1/verify-otp`;
			const otpVerifyResponse = await fetch(otpVerifyApiUrl, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify(requestBodyOtp),
			});

			if (otpVerifyResponse.ok) {
				const result = await otpVerifyResponse.json();
				console.log("OTP Verification Result:", result);
				console.log("Otp Token :", result.otpToken);
				toast.success(result.message);

				// Close OTP verification modal
				setIsOtpModalOpen(false);
				// Update the otpToken state before proceeding
				// await handleOtpToken(result);
				// otpToken = await result.otpToken;
				if (result.otpToken) {
					handleRegisterAndProceed(result.otpToken);
				} else {
					console.error("Error: OTP token is empty.");
					// Handle the case where OTP token is empty
				}

				// Proceed with further logic after successful OTP verification
			} else {
				const result = await otpVerifyResponse.json();
				console.error("Error verifying OTP:", result);
				// toast.error('OTP verification failed. Please try again.');
				// setOtpError("Invalid OTP. Please try again.");
			}
		} catch (error) {
			console.error("Error during OTP verification:", error);
			// setOtpError("Something went wrong. Please try again.");
		}
	};

	const handleCancelOtp = () => {
		// Clear OTP input and close the modal
		setOtp(["", "", "", "", "", ""]);
		setIsOtpModalOpen(false);
	};

	const handleRegisterAndProceed = async (otpToken) => {
		try {
			const phoneNumberWithCountryCode = "+91-" + mobileNo;
			const alternatePhoneNumberWithCountryCode =
				"+91-" + AlternateContactNumber;

			let requestBody;

			console.log("AlternateContactNumber :", AlternateContactNumber);

			if (!AlternateContactNumber) {
				requestBody = {
					// Include other data from the form
					otpToken: otpToken,
					firstName: firstName,
					lastName: lastName,
					email: email,
					phone: phoneNumberWithCountryCode,
					deviceType: "Web",
					// ... Add other form fields as needed
				};
			} else {
				requestBody = {
					// Include other data from the form
					otpToken: otpToken,
					firstName: firstName,
					lastName: lastName,
					email: email,
					phone: phoneNumberWithCountryCode,
					alternatePhone: alternatePhoneNumberWithCountryCode,
					deviceType: "Web",

					// ... Add other form fields as needed
				};
			}

			console.log("Guest Register requestbody :", requestBody);

			const registerApiUrl = `${API_BASE_URL}/v1/users/register-guest`;
			const registerResponse = await fetch(registerApiUrl, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					"x-device-type": "Web",
				},
				body: JSON.stringify(requestBody),
			});
			console.log("Register Response :", registerResponse);

			// setGuestRegistered(true);
			await refetch();
			setIsNewCustomer(false);
			// navigate('/bookings/create-new-booking')

			if (registerResponse.ok) {
				const resultRegister = await registerResponse.json();
				toast.success(
					"Now select it from select custmer:",
					resultRegister.message,
				);
				// handleCreateBooking();
			} else {
				// const resultAgainRegister = await registerResponse.json();
				console.error(
					"Error registering user:",
					registerResponse.status,
					registerResponse.statusText,
				);
				// toast.error(resultAgainRegister.message);
			}
		} catch (error) {
			console.error("Error during registration and order creation :", error);
			// toast.error(error);
		}
	};

	const [fields, setFields] = useState([{ id: 0, isAdd: true }]);

	const handleAddField = (index) => {
		if (fields.length >= 5 && fields[index].isAdd) {
			return;
		}

		const newFields = [...fields];

		if (newFields[index].isAdd) {
			newFields.splice(index + 1, 0, { id: newFields.length, isAdd: true });
			newFields[index].isAdd = false;
		} else {
			newFields.splice(index + 1);
			newFields[index].isAdd = true;
		}

		setFields(newFields);
	};
	const [selectedIndexCustomer, setSelectedIndexCustomer] = useState(-1);
	const inputRef = useRef();
	const [hoveredIndexCustomer, setHoveredIndexCustomer] = useState(-1);
	const [selectedIndex, setSelectedIndex] = useState(-1);
	const [destinationIndex, setDestinationIndex] = useState(-1);

	const handleKeyCustomer = (e) => {
		const filteredSuggestions = suggestions
			.filter((suggestion) => {
				const lowerCaseInput = selectCustomer.toLowerCase();
				const lowerCaseName = suggestion.label.toLowerCase();
				const phone = suggestion.phone;
				return (
					lowerCaseName.includes(lowerCaseInput) ||
					phone.includes(selectCustomer)
				);
			})
			.slice(0, 6);
		if (e.key === "ArrowDown") {
			setSelectedIndexCustomer((prevIndex) =>
				prevIndex < filteredSuggestions.length - 1 ? prevIndex + 1 : 0,
			);
		} else if (e.key === "ArrowUp") {
			setSelectedIndexCustomer((prevIndex) =>
				prevIndex > 0 ? prevIndex - 1 : filteredSuggestions.length - 1,
			);
		} else if (e.key === "Enter") {
			e.preventDefault();
			if (
				selectedIndexCustomer >= 0 &&
				selectedIndexCustomer < filteredSuggestions.length
			) {
				const suggestion = filteredSuggestions[selectedIndexCustomer];
				handleSelectSuggestion(suggestion);
				setShowSuggestions(false);
			}
		}
	};

	const handleKey = (e) => {
		const filteredSuggestions = filteredLocations.slice(0, 6);

		if (e.key === "ArrowDown") {
			setSelectedIndex((prevIndex) =>
				prevIndex < filteredSuggestions.length - 1 ? prevIndex + 1 : 0,
			);
		} else if (e.key === "ArrowUp") {
			setSelectedIndex((prevIndex) =>
				prevIndex > 0 ? prevIndex - 1 : filteredSuggestions.length - 1,
			);
		} else if (e.key === "Enter") {
			e.preventDefault();
			if (selectedIndex >= 0 && selectedIndex < filteredSuggestions.length) {
				const suggestion = filteredSuggestions[selectedIndex];
				setSelectedLocation(suggestion.label);
				setSearch(suggestion.label);
				// inputRef.current.focus();
				setShowDropdown(false);
			}
		}
	};

	const calenderKhol = (e) => {
		if (e?.target?.showPicker) {
			e.target.showPicker();
		}
	};
	const [highlightedIndex, setHighlightedIndex] = useState(-1);
	const handleKeyNavigation = (e, index) => {
		const filteredSuggestions = citySuggestions
			.filter((suggestion) =>
				suggestion
					.toLowerCase()
					.includes(additionalCities[index].toLowerCase()),
			)
			.slice(0, 6);

		if (e.key === "ArrowDown") {
			setHighlightedIndex((prevIndex) => {
				const nextIndex =
					prevIndex === filteredSuggestions.length - 1 ? 0 : prevIndex + 1;
				return nextIndex;
			});
		} else if (e.key === "ArrowUp") {
			setHighlightedIndex((prevIndex) => {
				const prevIndexAdjusted =
					prevIndex === 0 ? filteredSuggestions.length - 1 : prevIndex - 1;
				return prevIndexAdjusted;
			});
		} else if (e.key === "Enter" && highlightedIndex !== -1) {
			e.preventDefault();
			const selectedSuggestion = filteredSuggestions[highlightedIndex];
			if (selectedSuggestion) {
				handleCityChange(index, selectedSuggestion);
				setActiveInputIndex(null);
				setHighlightedIndex(-1);
			}
		}
	};

	const [focusedField, setFocusedField] = useState(null);
	const handleFocus = (field) => setFocusedField(field);
	const handleBlur = () => setFocusedField(null);

	return (
		<>
			<div style={{ position: "relative", width: "100%" }}>
				<div className="cust-type-checkbox-container">
					<p>Customer Type</p>
					<div>
						<input
							type="checkbox"
							checked={isNewCustomer}
							onChange={handleNewCustomerChange}
							onFocus={() => handleFocus("isNewCustomer")}
							onBlur={handleBlur}
						/>
						<label>New Customer</label>
					</div>
					<div>
						<input
							type="checkbox"
							checked={!isNewCustomer}
							onChange={handleExistingCustomerChange}
							onFocus={() => handleFocus("existingCustomer")}
							onBlur={handleBlur}
						/>
						<label>Existing Customer</label>
					</div>
				</div>

				{!isNewCustomer && (
					<div className="single-txtfield-wrapper big">
						<input
							type="text"
							placeholder="Search Customer by Name or Mobile number"
							value={selectCustomer}
							onChange={(e) => {
								setSelectCustomer(e.target.value);
							}}
							onKeyDown={handleKeyCustomer}
							autoComplete="off"
							onFocus={() => {
								handleFocus("Search");
								setShowSuggestions(true);
							}}
							onBlur={() => {
								handleBlur();
								setTimeout(() => {
									setShowSuggestions(false);
									setSelectedIndexCustomer(-1);
								}, [150]);
							}}
							style={{
								border:
									focusedField === "Search"
										? "2px solid #38b000"
										: "1px solid #ccc",
								outline: "none",
							}}
						/>
						<div
							className="BtoC-icon-holder"
							style={{
								top: "10px",
							}}
						>
							<img src={searchicon} alt="search icon" />
						</div>

						{showSuggestions && (
							<div
								className="cstomerSelectionDropdown"
								onMouseLeave={() => setSelectedIndexCustomer(-1)}
							>
								{suggestions
									.filter((suggestion) => {
										const lowerCaseInput = selectCustomer.toLowerCase();
										const lowerCaseName = suggestion.label.toLowerCase();
										const phone = suggestion.phone;
										return (
											lowerCaseName.includes(lowerCaseInput) ||
											phone.includes(selectCustomer)
										);
									})
									.slice(0, 6)
									.map((suggestion, index) => (
										<div
											key={index}
											style={{
												cursor: "pointer",
												backgroundColor:
													selectedIndexCustomer === index
														? "#38b000"
														: hoveredIndexCustomer === index
														? "#38b000"
														: "transparent",
											}}
											className={`suggestion ${
												selectedIndexCustomer === index ? "selected" : ""
											}`}
											onClick={() => handleSelectSuggestion(suggestion)}
											onMouseEnter={() => setHoveredIndexCustomer(index)}
											onMouseLeave={() => setHoveredIndexCustomer(-1)}
										>
											{suggestion.label} - {suggestion.phone}
										</div>
									))}
							</div>
						)}
					</div>
				)}
			</div>

			<div className="oneway-row-flexer addcity007">
				<div className="whole-inputlable-wrapper" onClick={suggestionshows}>
					<label>Pick Up Location</label>
					<div className="single-txtfield-wrapper">
						<input
							placeholder="Enter pick up location"
							type="text"
							name="pickUpLocation"
							autoComplete="off"
							value={search}
							onChange={(e) => {
								setSearch(e.target.value);
								setListVisible(true);
								// clearalldata(e);
							}}
							onKeyDown={handleKey}
							onFocus={() => handleFocus("Pickupcity")}
							onBlur={handleBlur}
							style={{
								border:
									focusedField === "Pickupcity"
										? "2px solid #38b000"
										: "1px solid #ccc",
								outline: "none",
							}}
						/>
						<div className="BtoC-icon-holder">
							{" "}
							<img src={location} alt="img"></img>{" "}
						</div>

						{showDropdown && (
							<div>
								{listVisible && showDropdown && (
									<div
										className="list-container"
										onClick={suggestionshows}
										onMouseLeave={suggestionhide}
									>
										<ul>
											{filteredLocations && filteredLocations.length > 0 ? (
												filteredLocations.map((city, index) => (
													<li
														key={city.value}
														style={{
															cursor: "pointer",
															backgroundColor:
																selectedIndex === index
																	? "#bde4ff"
																	: "transparent",
															...(selectedIndex === index && {
																backgroundColor: "#38b000",
															}),
														}}
														onClick={() => handleCityClick(city)}
														onMouseEnter={() => setSelectedIndex(index)}
														onMouseLeave={() => setSelectedIndex(-1)}
													>
														{city.label}
													</li>
												))
											) : (
												<li>No cities found</li>
											)}
										</ul>
									</div>
								)}
							</div>
						)}
					</div>
				</div>

				{additionalCities.map((city, index) => (
					<div
						className="whole-inputlable-wrapper"
						onClick={() => suggestionshowsdestination(index)}
						key={index}
						onMouseLeave={suggestionhidedestination}
					>
						<label>Destination City {index + 1}</label>
						<div className="single-txtfield-wrapper">
							<input
								type="text"
								placeholder="Enter city name"
								value={city}
								id="addcityinput"
								onChange={(e) => handleCityChange(index, e.target.value)}
								onKeyDown={(e) => handleKeyNavigation(e, index)}
								style={{
									borderColor: errorMessages[index] ? "red" : "initial",
									border:
										focusedField === "Destination" + index
											? "2px solid #38b000"
											: "1px solid #ccc",
									outline: "none",
								}}
								onFocus={() => handleFocus("Destination" + index)}
								onBlur={handleBlur}
							/>
							<div className="BtoC-icon-holder">
								<img src={location} alt="location icon" />
							</div>
							{errorMessages[index] && (
								<p
									style={{ color: "red", marginTop: "0.5rem", fontSize: "8px" }}
								>
									{errorMessages[index]}
								</p>
							)}
							<div className="Addsub-container">
								{index === additionalCities.length - 1 &&
									additionalCities.length < 5 && (
										<div
											className="icon-addsub-wrapper"
											onClick={handleAddDestination}
										>
											<div>
												<img src={add} alt="img" />
											</div>
											Add Cities
										</div>
									)}

								{index > 0 && (
									<div
										className="minus-only"
										onClick={() => handleRemoveCity(index)}
									>
										<div>
											<img src={minus} alt="img" />
										</div>
									</div>
								)}
							</div>

							{showDropdownDestination && activeInputIndex === index && (
								<div
									className="list-container Destination"
									onClick={() => suggestionshowsdestination(index)}
								>
									{citySuggestions
										.filter((suggestion) =>
											suggestion.toLowerCase().includes(city.toLowerCase()),
										)
										.slice(0, 6)
										.map((suggestion, suggestionIndex) => (
											<div
												key={suggestionIndex}
												className={`suggestion ${
													suggestionIndex === highlightedIndex
														? "highlighted"
														: ""
												}`}
												style={{
													cursor: "pointer",
													backgroundColor:
														suggestionIndex === highlightedIndex
															? "#bde4ff"
															: "transparent",
													...(suggestionIndex === highlightedIndex && {
														backgroundColor: "#38b000",
													}),
												}}
												onClick={() => handleCityChange(index, suggestion)}
												onMouseEnter={() =>
													setHighlightedIndex(suggestionIndex)
												}
												onMouseLeave={() => setHighlightedIndex(-1)}
											>
												{suggestion}
											</div>
										))}
								</div>
							)}
						</div>
					</div>
				))}

				<div className="whole-inputlable-wrapper">
					<label>From Date</label>
					<div className="single-txtfield-wrapper">
						<input
							type="date"
							value={startDate}
							onChange={handleStartDateChange}
							onClick={(e) => calenderKhol(e)}
							min={new Date().toISOString().split("T")[0]} // Setting today's date as the minimum
							required
							onFocus={() => handleFocus("FromDate")}
							onBlur={handleBlur}
							style={{
								border:
									focusedField === "FromDate"
										? "2px solid #38b000"
										: "1px solid #ccc",
								outline: "none",
							}}
						/>
					</div>
					{/* {startDate && <p>Selected Start Date: {formatDateNew(startDate)}</p>} */}
				</div>

				<div className="whole-inputlable-wrapper">
					<label>To Date</label>
					<div className="single-txtfield-wrapper">
						<input
							type="date"
							value={endDate}
							onChange={handleEndDateChange}
							onClick={(e) => calenderKhol(e)}
							min={startDate} // Set the minimum to be the selected start date
							required
							onFocus={() => handleFocus("ToDate")}
							onBlur={handleBlur}
							style={{
								border:
									focusedField === "ToDate"
										? "2px solid #38b000"
										: "1px solid #ccc",
								outline: "none",
							}}
						/>
					</div>
				</div>

				<div className="whole-inputlable-wrapper">
					<label>Pick Up Time</label>
					<div className="single-txtfield-wrapper">
						<select
							value={pickUpTime}
							onChange={handleChange}
							className="time-select"
							onFocus={() => handleFocus("PickupTime")}
							onBlur={handleBlur}
							style={{
								border:
									focusedField === "PickupTime"
										? "2px solid #38b000"
										: "1px solid #ccc",
								outline: "none",
							}}
						>
							<option value="" disabled>
								Select pick up time
							</option>
							{generateTimeOptions(startDate).map((option, index) => (
								<option key={index} value={option}>
									{option}
								</option>
							))}
						</select>
						<div className="BtoC-icon-holder">
							<img src={clock} alt="img" />
						</div>
					</div>
				</div>

				<div className="whole-inputlable-wrapper">
					<label>Cab Type</label>
					<div className="single-txtfield-wrapper">
						<select
							value={selectedCabType} // Selected cab type value
							onChange={handleChangeCabType} // Event handler for selection change
							onFocus={() => handleFocus("CabType")}
							onBlur={handleBlur}
							style={{
								border:
									focusedField === "CabType"
										? "2px solid #38b000"
										: "1px solid #ccc",
								outline: "none",
							}}
						>
							<option value="" disabled>
								Select cab type
							</option>
							{cabType.map((option, index) => (
								<option key={index} value={option.value}>
									{option.label}
								</option>
							))}
						</select>
					</div>
				</div>
			</div>

			<div className="oneway-row-flexer">
				<div className="whole-inputlable-wrapper">
					<label>Passenger Details</label>
					<div
						className={
							"single-txtfield-wrapper " + (!isNewCustomer ? "gray" : "")
						}
					>
						<input
							type="text"
							placeholder="First name"
							value={isNewCustomer ? firstName : firstName}
							onChange={(e) => setFirstName(e.target.value)}
							disabled={!isNewCustomer}
							required
							onFocus={() => handleFocus("FName")}
							onBlur={handleBlur}
							style={{
								border:
									focusedField === "FName"
										? "2px solid #555"
										: "1px solid #ccc",
								outline: "none",
							}}
						/>
					</div>
					{errors.firstName && (
						<p className="error-message">{errors.firstName}</p>
					)}
				</div>

				<div className="whole-inputlable-wrapper">
					<label>&ensp;</label>
					<div
						className={
							"single-txtfield-wrapper " + (!isNewCustomer ? "gray" : "")
						}
					>
						<input
							type="text"
							placeholder="Last name"
							value={isNewCustomer ? lastName : lastName}
							onChange={(e) => setLastName(e.target.value)}
							disabled={!isNewCustomer}
							required
							onFocus={() => handleFocus("LName")}
							onBlur={handleBlur}
							style={{
								border:
									focusedField === "LName"
										? "2px solid #555"
										: "1px solid #ccc",
								outline: "none",
							}}
						/>
					</div>
					{errors.lastName && (
						<p className="error-message">{errors.lastName}</p>
					)}
				</div>

				<div className="whole-inputlable-wrapper">
					<label>&ensp;</label>
					<div
						className={
							"single-txtfield-wrapper " + (!isNewCustomer ? "gray" : "")
						}
					>
						<input
							type="text"
							placeholder="Passenger E-mail"
							value={isNewCustomer ? email : email}
							onChange={(e) => setEmail(e.target.value)}
							disabled={!isNewCustomer}
							required
							onFocus={() => handleFocus("PEmail")}
							onBlur={handleBlur}
							style={{
								border:
									focusedField === "PEmail"
										? "2px solid #555"
										: "1px solid #ccc",
								outline: "none",
							}}
						/>
					</div>
				</div>

				<div className="whole-inputlable-wrapper">
					<label>&ensp;</label>
					<div
						className={
							"single-txtfield-wrapper " + (!isNewCustomer ? "gray" : "")
						}
					>
						<input
							type="text"
							placeholder="Passenger contact number"
							value={isNewCustomer ? mobileNo : mobileNo}
							onChange={(e) => setMobileNo(e.target.value)}
							disabled={!isNewCustomer}
							required
							onFocus={() => handleFocus("ContactNo")}
							onBlur={handleBlur}
							style={{
								border:
									focusedField === "ContactNo"
										? "2px solid #555"
										: "1px solid #ccc",
								outline: "none",
							}}
						/>
					</div>
					{errors.mobileNo && (
						<p className="error-message">{errors.mobileNo}</p>
					)}
				</div>

				{/* Search Location Component */}

				<div className="whole-inputlable-wrapper">
					<label>&ensp;</label>
					<div className="single-txtfield-wrapper">
						<input
							type="text"
							ref={autoCompleteRef}
							onChange={(event) => setPickupAddress(event.target.value)}
							value={pickupAddress}
							placeholder="Pickup Address"
							onFocus={() => handleFocus("PickUpAdd")}
							onBlur={handleBlur}
							style={{
								border:
									focusedField === "PickUpAdd"
										? "2px solid #38b000"
										: "1px solid #ccc",
								outline: "none",
							}}
						/>
						<div className="BtoC-icon-holder">
							<img src={searchicon} alt="search icon" />
						</div>
					</div>
					{errors.pickupAddress && (
						<p className="error-message">{errors.pickupAddress}</p>
					)}
				</div>

				<div className="whole-inputlable-wrapper">
					<label>&ensp;</label>
					<div className="single-txtfield-wrapper">
						<input
							type="text"
							placeholder="Alternate contact number"
							value={AlternateContactNumber}
							onChange={(e) => setAlternateContactNumber(e.target.value)}
							onFocus={() => handleFocus("AlternateContact")}
							onBlur={handleBlur}
							style={{
								border:
									focusedField === "AlternateContact"
										? "2px solid #38b000"
										: "1px solid #ccc",
								outline: "none",
							}}
						/>
					</div>
				</div>
			</div>
			<div className="curve-field-container">
				<div className="sub-wrapper">
					<input
						id="c001"
						type="checkbox"
						checked={isGstChecked}
						onChange={handleGstCheckboxChange}
						onFocus={() => handleFocus("GstCheck")}
						onBlur={handleBlur}
					/>
					<label htmlFor="c001">GST</label>
					<input
						type="text"
						value={gstValue}
						onChange={(e) => setGstValue(e.target.value)}
						disabled={!isGstChecked}
						onFocus={() => handleFocus("GstVal")}
						onBlur={handleBlur}
						style={{
							border:
								focusedField === "GstVal"
									? "2px solid #38b000"
									: "1px solid #ccc",
							outline: "none",
						}}
					/>
				</div>

				<div className="sub-wrapper">
					<input
						id="c002"
						type="checkbox"
						checked={isFlightChecked}
						onChange={handleFlightCheckboxChange}
						onFocus={() => handleFocus("FlightCheck")}
						onBlur={handleBlur}
					/>
					<label htmlFor="c002">Flight</label>
					<input
						type="text"
						value={flightValue}
						onChange={(e) => setFlightValue(e.target.value)}
						disabled={!isFlightChecked}
						onFocus={() => handleFocus("FlightVal")}
						onBlur={handleBlur}
						style={{
							border:
								focusedField === "FlightVal"
									? "2px solid #38b000"
									: "1px solid #ccc",
							outline: "none",
						}}
					/>
				</div>
			</div>

			<textarea
				placeholder="Comment here for any specific request for ride :"
				value={comments}
				onChange={(e) => setComments(e.target.value)}
				onFocus={() => handleFocus("comment")}
				onBlur={handleBlur}
				style={{
					border:
						focusedField === "comment" ? "2px solid #38b000" : "1px solid #ccc",
					outline: "none",
				}}
			/>

			{isOtpModalOpen && (
				<div className="modal-container">
					<div className="modal-content">
						<p>
							Please enter the OTP sent on user mobile no. {"+91-" + mobileNo}
						</p>
						<div className="otp-input-container">
							{otp.map((digit, index) => (
								<input
									key={index}
									id={`otp-input-${index}`}
									type="text"
									maxLength="1"
									value={digit}
									onChange={(e) => handleOtpChange(index, e.target.value)}
									onKeyDown={(e) => handleKeyDown(index, e)}
									autoComplete="off"
								/>
							))}
						</div>
						<div className="button-container">
							<button className="verify-button" onClick={handleVerifyOtp}>
								Verify Now
							</button>
							<button className="cancel-button" onClick={handleCancelOtp}>
								Cancel
							</button>
						</div>
					</div>
				</div>
			)}
			<Toaster />
		</>
	);
};

//======================== 👇 Oneway 👇 ====================//
//======================== 👇 Oneway 👇 ====================//
const Oneway = ({ setFareDetails, fareDetails, states, setters }) => {
	const navigate = useNavigate();

	const {
		tripType,
		subTripType,
		pickUpLocation,
		selectedLocation,
		selectDropAddress,
		pickUpDate,
		dropDate,
		pickUpTime,
		timeOptions,
		isDropdownVisible,
		cabType,
		allCabsData,
		selectedCabType,
		selectTemp,
		selectedTariffId,
		carCategoriesDetails,
		additionalCities,
		search,
		listVisible,
		showDropdown,
		showDropdownDestination,
		activeInputIndex,
		errorMessages,
		showDate,
		startDate,
		endDate,
		isNewCustomer,
		selectCustomer,
		selectCustomerId,
		showSuggestions,
		firstName,
		lastName,
		mobileNo,
		email,
		AlternateContactNumber,
		pickupAddress,
		longitude,
		gstValue,
		flightValue,
		isGstChecked,
		isFlightChecked,
		comments,
		selectedAdvanceAmount,
		otp,
		otpId,
		isOtpModalOpen,
		destination,
		errors,
	} = states;

	const {
		setTripType,
		setSubTripType,
		setPickUpLocation,
		setSelectedLocation,
		setSelectDropAddress,
		setPickUpDate,
		setDropDate,
		setPickUpTime,
		setTimeOptions,
		setIsDropdownVisible,
		setCabType,
		setAllCabsData,
		setSelectedCabType,
		setSelectTemp,
		setSelectedTariffId,
		setCarCategoriesDetails,
		setAdditionalCities,
		setSearch,
		setListVisible,
		setShowDropdown,
		setShowDropdownDestination,
		setActiveInputIndex,
		setErrorMessages,
		setShowDate,
		setStartDate,
		setEndDate,
		setIsNewCustomer,
		setSelectCustomer,
		setSelectCustomerId,
		setShowSuggestions,
		setFirstName,
		setLastName,
		setMobileNo,
		setEmail,
		setAlternateContactNumber,
		setPickupAddress,
		setLongitude,
		setGstValue,
		setFlightValue,
		setIsGstChecked,
		setIsFlightChecked,
		setComments,
		setSelectedAdvanceAmount,
		setOtp,
		setOtpId,
		setIsOtpModalOpen,
		setDestination,
	} = setters;

	const [citySuggestions] = useSuggestionsCity();
	const [availableCities] = useSuggestionsCitiesAvailable();

	const { suggestions, loading, error, refetch } = useSuggestionsCustomer();

	const [isDropdownOpen, setIsDropdownOpen] = useState(false);
	const [selectedIndexCustomer, setSelectedIndexCustomer] = useState(-1);
	const inputRef = useRef();
	const [hoveredIndexCustomer, setHoveredIndexCustomer] = useState(-1);
	const [selectedIndex, setSelectedIndex] = useState(-1);
	const [destinationIndex, setDestinationIndex] = useState(-1);

	// fucntion for gst and flight

	const handleGstCheckboxChange = (e) => {
		const checked = e.target.checked;
		setIsGstChecked(checked);
		if (!checked) {
			setGstValue(""); // Clear the value when unchecked
		}
	};

	const handleFlightCheckboxChange = (e) => {
		const checked = e.target.checked;
		setIsFlightChecked(checked);
		if (!checked) {
			setFlightValue(""); // Clear the value when unchecked
		}
	};

	const handleNewCustomerChange = () => {
		setIsNewCustomer(true);
		setSelectCustomer(""); // Clear customer search input when selecting new customer
		// Clear other details when switching to new customer
		setFirstName("");
		setLastName("");
		setMobileNo("");
		setEmail("");
	};

	const handleExistingCustomerChange = () => {
		setIsNewCustomer(false);
	};

	const handleSelectSuggestion = (suggestion) => {
		setSelectCustomer(suggestion.label);
		setSelectCustomerId(suggestion.value);
		setFirstName(suggestion.firstname);
		setLastName(suggestion.lastname);
		setMobileNo(suggestion.phone.split("-")[1]); // Assumes phone format is 'prefix-number'
		setEmail(suggestion.email);
		setShowSuggestions(false);
	};

	// Now here is the search location functions

	const autoCompleteRef = useRef(null);

	const handleScriptLoad = (updateQuery, autoCompleteRef) => {
		autoComplete = new window.google.maps.places.Autocomplete(
			autoCompleteRef.current,
			{
				// types: ["(cities)"],
				componentRestrictions: { country: "IN" },
			},
		);

		autoComplete.addListener("place_changed", () => {
			handlePlaceSelect(updateQuery);
		});
	};

	const handlePlaceSelect = async (updateQuery) => {
		const addressObject = await autoComplete.getPlace();

		const query = addressObject.formatted_address;
		updateQuery(query);
		console.log({ query });

		const latLng = {
			lat: addressObject?.geometry?.location?.lat(),
			lng: addressObject?.geometry?.location?.lng(),
		};

		console.log({ latLng });
		setLongitude(latLng);
	};

	useEffect(() => {
		loadScript(
			`https://maps.googleapis.com/maps/api/js?key=${apiUrl}&libraries=places`,
			() => handleScriptLoad(setPickupAddress, autoCompleteRef),
		);
	}, []);

	useEffect(() => {
		if (startDate) {
			const generatedOptions = generateTimeOptions(startDate); // You can customize pickUpDate as needed
			setTimeOptions(generatedOptions);
		}
	}, [startDate]);

	const handleStartDateChange = (e) => {
		setStartDate(e.target.value);
	};

	useEffect(() => {
		console.log("showDate changed to:", showDate);
	}, [showDate]);

	const suggestionhide = () => {
		setShowDropdown(false);
		console.log("mouseleave dropdown", showDropdown);
	};

	const suggestionshows = () => {
		setShowDropdown(true);

		console.log("mouseover dropdown ", showDropdown);
	};

	const suggestionshowsdestination = (index) => {
		setActiveInputIndex(index);
		setShowDropdownDestination(true);
	};

	// Function to handle changes to the city input
	const handleCityChange = (value) => {
		setDestination(value);
	};

	useEffect(() => {
		const extractLocationsAsOptions = (dataArray) => {
			if (Array.isArray(dataArray.data)) {
				return dataArray.data.map((item) => ({
					label: item.location,
					value: item.location,
				}));
			}
			return [];
		};

		const fetchData = async () => {
			try {
				const locationResponse = await fetch(`${API_BASE_URL}/v1/locations`);
				const locationData = await locationResponse.json();
				const pickUpLocation = extractLocationsAsOptions(locationData);
				setPickUpLocation(pickUpLocation);
			} catch (error) {
				console.error("Error fetching data: ", error);
			}
		};
		fetchData();
	}, [setPickUpLocation]);

	const extractCarCategoriesAsOptions = (dataArray) => {
		if (Array.isArray(dataArray.data)) {
			return dataArray.data.map((item) => ({
				label: item.carCategory.category,
				value: item.carCategory.category,
			}));
		}
		return [];
	};
	// Helper function to format the date
	const formatDate = (date) => {
		if (!(date instanceof Date) || isNaN(date)) {
			return "";
		}
		return format(date, "dd-MM-yyyy");
	};

	// First function: handleChange
	const handleChange = async (e) => {
		const time = e.target.value;
		setPickUpTime(time);

		// Ensure startDate and endDate are valid Date objects
		const formatedPickup = formatDate(new Date(startDate)); // Assuming startDate is a string or valid Date
		const subTripType = "One Way";

		// Construct destination parameters from additionalCities array
		const destinationOneway = encodeURIComponent(destination);

		console.log(
			selectedLocation,
			formatedPickup,

			time,
			subTripType,
			destination,
		);

		try {
			const response = await axios.get(
				`${API_BASE_URL}/v1/users/select-cars/outstation?source=${encodeURIComponent(
					selectedLocation,
				)}&time=${encodeURIComponent(
					time,
				)}&startDate=${formatedPickup}&subTripType=${subTripType}&destination=${destinationOneway}`,
			);

			const carCategoryOptions = extractCarCategoriesAsOptions(response.data);
			setCabType(carCategoryOptions);

			setAllCabsData(response.data.data);

			const temp = response.data.tempDataId;
			setSelectTemp(temp);
		} catch (error) {
			toast.error(error.response.data.message);
			console.error("Error fetching cars:", error);
			setCabType([]);
		}
	};

	useEffect(() => {
		setSelectedCabType("");
		setFareDetails(null);
	}, [allCabsData, cabType, pickUpTime]);

	useEffect(() => {
		setSelectedCabType("");
		setPickUpTime("");
		setDestination("");
		setStartDate("");
		setFareDetails(null);
	}, [search]);

	useEffect(() => {
		setSelectedCabType("");
		setPickUpTime("");
		setStartDate("");
		setFareDetails(null);
	}, [destination]);

	useEffect(() => {
		setSelectedCabType("");
		setPickUpTime("");
		setFareDetails(null);
	}, [startDate]);

	const handleChangeCabType = (e) => {
		const selectedCab = e.target.value;
		setSelectedCabType(selectedCab);

		// Find the corresponding fare details from the response data
		const selectedFare = allCabsData.find(
			(item) =>
				item.carCategory.category ===
				cabType.find((option) => option.value === selectedCab).label,
		);

		// Update fare details state
		if (selectedFare) {
			setSelectedTariffId(selectedFare._id);
			setFareDetails(selectedFare);
		} else {
			setFareDetails(null);
		}
	};

	const filteredLocations =
		search.length === 0
			? pickUpLocation
			: pickUpLocation.filter((city) =>
					city.label.toLowerCase().includes(search.toLowerCase()),
			  );

	const handleCityClick = (city) => {
		setSelectedLocation(city.label);
		setSearch(city.label);
		setListVisible(false);
	};

	// code for otp verification

	const handleOtpChange = (index, value) => {
		const newOtp = [...otp];
		newOtp[index] = value;

		// If all OTP digits are filled, open the modal
		if (newOtp.every((digit) => digit !== "")) {
			setIsOtpModalOpen(true);
		}

		setOtp(newOtp);

		// Move focus to the next input field
		if (value !== "" && index < otp.length - 1) {
			document.getElementById(`otp-input-${index + 1}`).focus();
		}
	};

	const handleKeyDown = (index, e) => {
		// Move focus to the previous input field when backspace is pressed and the current input is empty
		if (e.keyCode === 8 && otp[index] === "" && index > 0) {
			document.getElementById(`otp-input-${index - 1}`).focus();
		}
	};

	const handleVerifyOtp = async () => {
		try {
			const phoneNumberWithCountryCode = "+91-" + mobileNo;

			const combinedOtp = otp.join("");

			const requestBodyOtp = {
				phone: phoneNumberWithCountryCode,
				otp: Number(combinedOtp),
				otpId: otpId,
			};

			const otpVerifyApiUrl = `${API_BASE_URL}/v1/verify-otp`;
			const otpVerifyResponse = await fetch(otpVerifyApiUrl, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify(requestBodyOtp),
			});

			if (otpVerifyResponse.ok) {
				const result = await otpVerifyResponse.json();
				console.log("OTP Verification Result:", result);
				console.log("Otp Token :", result.otpToken);
				toast.success(result.message);

				// Close OTP verification modal
				setIsOtpModalOpen(false);
				// Update the otpToken state before proceeding
				// await handleOtpToken(result);
				// otpToken = await result.otpToken;
				if (result.otpToken) {
					handleRegisterAndProceed(result.otpToken);
				} else {
					console.error("Error: OTP token is empty.");
					// Handle the case where OTP token is empty
				}

				// Proceed with further logic after successful OTP verification
			} else {
				const result = await otpVerifyResponse.json();
				console.error("Error verifying OTP:", result);
				// toast.error('OTP verification failed. Please try again.');
				// setOtpError("Invalid OTP. Please try again.");
			}
		} catch (error) {
			console.error("Error during OTP verification:", error);
			// setOtpError("Something went wrong. Please try again.");
		}
	};

	const handleCancelOtp = () => {
		// Clear OTP input and close the modal
		setOtp(["", "", "", "", "", ""]);
		setIsOtpModalOpen(false);
	};

	const handleRegisterAndProceed = async (otpToken) => {
		try {
			const phoneNumberWithCountryCode = "+91-" + mobileNo;
			const alternatePhoneNumberWithCountryCode =
				"+91-" + AlternateContactNumber;

			let requestBody;

			console.log("AlternateContactNumber :", AlternateContactNumber);

			if (!AlternateContactNumber) {
				requestBody = {
					// Include other data from the form
					otpToken: otpToken,
					firstName: firstName,
					lastName: lastName,
					email: email,
					phone: phoneNumberWithCountryCode,
					deviceType: "Web",
					// ... Add other form fields as needed
				};
			} else {
				requestBody = {
					// Include other data from the form
					otpToken: otpToken,
					firstName: firstName,
					lastName: lastName,
					email: email,
					phone: phoneNumberWithCountryCode,
					alternatePhone: alternatePhoneNumberWithCountryCode,
					deviceType: "Web",

					// ... Add other form fields as needed
				};
			}

			console.log("Guest Register requestbody :", requestBody);

			const registerApiUrl = `${API_BASE_URL}/v1/users/register-guest`;
			const registerResponse = await fetch(registerApiUrl, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					"x-device-type": "Web",
				},
				body: JSON.stringify(requestBody),
			});
			console.log("Register Response :", registerResponse);

			// setGuestRegistered(true);
			await refetch();
			setIsNewCustomer(false);
			// navigate('/bookings/create-new-booking')

			if (registerResponse.ok) {
				const resultRegister = await registerResponse.json();
				toast.success(
					"Now select it from select custmer:",
					resultRegister.message,
				);
				// handleCreateBooking();
			} else {
				// const resultAgainRegister = await registerResponse.json();
				console.error(
					"Error registering user:",
					registerResponse.status,
					registerResponse.statusText,
				);
				// toast.error(resultAgainRegister.message);
			}
		} catch (error) {
			console.error("Error during registration and order creation :", error);
			// toast.error(error);
		}
	};

	const handleKeyCustomer = (e) => {
		const filteredSuggestions = suggestions
			.filter((suggestion) => {
				const lowerCaseInput = selectCustomer.toLowerCase();
				const lowerCaseName = suggestion.label.toLowerCase();
				const phone = suggestion.phone;
				return (
					lowerCaseName.includes(lowerCaseInput) ||
					phone.includes(selectCustomer)
				);
			})
			.slice(0, 6);
		if (e.key === "ArrowDown") {
			setSelectedIndexCustomer((prevIndex) =>
				prevIndex < filteredSuggestions.length - 1 ? prevIndex + 1 : 0,
			);
		} else if (e.key === "ArrowUp") {
			setSelectedIndexCustomer((prevIndex) =>
				prevIndex > 0 ? prevIndex - 1 : filteredSuggestions.length - 1,
			);
		} else if (e.key === "Enter") {
			e.preventDefault();
			if (
				selectedIndexCustomer >= 0 &&
				selectedIndexCustomer < filteredSuggestions.length
			) {
				const suggestion = filteredSuggestions[selectedIndexCustomer];
				handleSelectSuggestion(suggestion);
				setShowSuggestions(false);
			}
		}
	};

	const handleKey = (e) => {
		const filteredSuggestions = filteredLocations.slice(0, 6);
		if (e.key === "ArrowDown") {
			setSelectedIndex((prevIndex) =>
				prevIndex < filteredSuggestions.length - 1 ? prevIndex + 1 : 0,
			);
		} else if (e.key === "ArrowUp") {
			setSelectedIndex((prevIndex) =>
				prevIndex > 0 ? prevIndex - 1 : filteredSuggestions.length - 1,
			);
		} else if (e.key === "Enter") {
			e.preventDefault();
			if (selectedIndex >= 0 && selectedIndex < filteredSuggestions.length) {
				const suggestion = filteredSuggestions[selectedIndex];
				setSelectedLocation(suggestion.label);
				setSearch(suggestion.label);
				// inputRef.current.focus();
				setShowDropdown(false);
			}
		}
	};

	const handleDestinationKey = (e) => {
		const filteredSuggestions = availableCities
			.filter((suggestion) =>
				suggestion.toLowerCase().startsWith(destination.toLowerCase()),
			)
			.slice(0, 6);
		if (e.key === "ArrowDown") {
			setDestinationIndex((prevIndex) =>
				prevIndex < filteredSuggestions.length - 1 ? prevIndex + 1 : 0,
			);
		} else if (e.key === "ArrowUp") {
			setDestinationIndex((prevIndex) =>
				prevIndex > 0 ? prevIndex - 1 : filteredSuggestions.length - 1,
			);
		} else if (e.key === "Enter") {
			e.preventDefault();
			if (
				destinationIndex >= 0 &&
				destinationIndex < filteredSuggestions.length
			) {
				const suggestion = filteredSuggestions[destinationIndex];
				handleCityChange(suggestion);
				setShowDropdownDestination(false);
				inputRef.current.focus(); // Focus back on the input
			}
		}
	};

	const calenderKhol = (e) => {
		if (e?.target?.showPicker) {
			e.target.showPicker();
		}
	};
	const [focusedField, setFocusedField] = useState(null);
	const handleFocus = (field) => setFocusedField(field);
	const handleBlur = () => setFocusedField(null);

	return (
		<>
			<div style={{ position: "relative", width: "100%" }}>
				<div className="cust-type-checkbox-container">
					<p>Customer Type</p>
					<div>
						<input
							type="checkbox"
							checked={isNewCustomer}
							onChange={handleNewCustomerChange}
							onFocus={() => handleFocus("isNewCustomer")}
							onBlur={handleBlur}
						/>
						<label>New Customer</label>
					</div>

					<div>
						<input
							type="checkbox"
							checked={!isNewCustomer}
							onChange={handleExistingCustomerChange}
							onFocus={() => handleFocus("existingCustomer")}
							onBlur={handleBlur}
						/>
						<label>Existing Customer</label>
					</div>
				</div>

				{!isNewCustomer && (
					<div className="single-txtfield-wrapper big">
						<input
							type="text"
							placeholder="Search Customer by Name or Mobile number"
							value={selectCustomer}
							onChange={(e) => {
								setSelectCustomer(e.target.value);
							}}
							onKeyDown={handleKeyCustomer}
							autoComplete="off"
							onFocus={() => {
								handleFocus("Search");
								setShowSuggestions(true);
							}}
							onBlur={() => {
								handleBlur();
								setTimeout(() => {
									setShowSuggestions(false);
									setSelectedIndexCustomer(-1);
								}, [150]);
							}}
							style={{
								border:
									focusedField === "Search"
										? "2px solid #38b000"
										: "1px solid #ccc",
								outline: "none",
							}}
						/>
						<div
							className="BtoC-icon-holder"
							style={{
								top: "10px",
							}}
						>
							<img src={searchicon} alt="search icon" />
						</div>

						{showSuggestions && (
							<div
								className="cstomerSelectionDropdown"
								onMouseLeave={() => setSelectedIndexCustomer(-1)}
							>
								{suggestions
									.filter((suggestion) => {
										const lowerCaseInput = selectCustomer.toLowerCase();
										const lowerCaseName = suggestion.label.toLowerCase();
										const phone = suggestion.phone;
										return (
											lowerCaseName.includes(lowerCaseInput) ||
											phone.includes(selectCustomer)
										);
									})
									.slice(0, 6)
									.map((suggestion, index) => (
										<div
											key={index}
											style={{
												cursor: "pointer",
												backgroundColor:
													selectedIndexCustomer === index
														? "#38b000"
														: hoveredIndexCustomer === index
														? "#38b000"
														: "transparent",
											}}
											className={`suggestion ${
												selectedIndexCustomer === index ? "selected" : ""
											}`}
											onClick={() => handleSelectSuggestion(suggestion)}
											onMouseEnter={() => setHoveredIndexCustomer(index)}
											onMouseLeave={() => setHoveredIndexCustomer(-1)}
										>
											{suggestion.label} - {suggestion.phone}
										</div>
									))}
							</div>
						)}
					</div>
				)}
			</div>

			<div className="oneway-row-flexer">
				<div className="whole-inputlable-wrapper" onClick={suggestionshows}>
					<label>Pick Up Location</label>
					<div className="single-txtfield-wrapper">
						<input
							placeholder="Enter pick up location"
							type="text"
							name="pickUpLocation"
							autoComplete="off"
							value={search}
							onChange={(e) => {
								setSearch(e.target.value);
								setListVisible(true);
								// clearalldata(e);
							}}
							onKeyDown={handleKey}
							onFocus={() => handleFocus("Pickupcity")}
							onBlur={handleBlur}
							style={{
								border:
									focusedField === "Pickupcity"
										? "2px solid #38b000"
										: "1px solid #ccc",
								outline: "none",
							}}
						/>
						<div className="BtoC-icon-holder">
							{" "}
							<img src={location} alt="img"></img>{" "}
						</div>

						{showDropdown && (
							<div>
								{listVisible && showDropdown && (
									<div
										className="list-container"
										onClick={suggestionshows}
										onMouseLeave={suggestionhide}
									>
										<ul>
											{filteredLocations && filteredLocations.length > 0 ? (
												filteredLocations.map((city, index) => (
													<li
														key={city.value}
														style={{
															cursor: "pointer",
															backgroundColor:
																selectedIndex === index
																	? "#bde4ff"
																	: "transparent",
															...(selectedIndex === index && {
																backgroundColor: "#38b000",
															}),
														}}
														onClick={() => handleCityClick(city)}
														onMouseEnter={() => setSelectedIndex(index)}
														onMouseLeave={() => setSelectedIndex(-1)}
													>
														{city.label}
													</li>
												))
											) : (
												<li>No cities found</li>
											)}
										</ul>
									</div>
								)}
							</div>
						)}
					</div>
				</div>

				<div
					className="whole-inputlable-wrapper"
					onClick={suggestionshowsdestination}
				>
					<label>Destination City</label>
					<div className="single-txtfield-wrapper">
						<input
							type="text"
							placeholder="Select Destination"
							value={destination}
							onChange={(e) => handleCityChange(e.target.value)}
							onKeyDown={handleDestinationKey}
							ref={inputRef}
							autoComplete="off"
							onFocus={() => handleFocus("Destination")}
							onBlur={handleBlur}
							style={{
								border:
									focusedField === "Destination"
										? "2px solid #38b000"
										: "1px solid #ccc",
								outline: "none",
							}}
						/>
						<div className="BtoC-icon-holder">
							<img src={location} alt="img" />
						</div>
						<div className="list-container Destination">
							{showDropdownDestination &&
								availableCities
									.filter((suggestion) =>
										suggestion
											.toLowerCase()
											.startsWith(destination.toLowerCase()),
									)
									.slice(0, 6)
									.map((suggestion, suggestionIndex) => {
										const isMatch = suggestion
											.toLowerCase()
											.includes(destination.toLowerCase());
										return (
											<div key={suggestionIndex}>
												{isMatch &&
													destination &&
													!availableCities.includes(destination) && (
														<div
															className="suggestion"
															style={{
																cursor: "pointer",
																backgroundColor:
																	destinationIndex === suggestionIndex
																		? "#bde4ff"
																		: "transparent",
																...(destinationIndex === suggestionIndex && {
																	backgroundColor: "#38b000",
																}),
															}}
															onClick={() => handleCityChange(suggestion)}
															onMouseEnter={() =>
																setDestinationIndex(suggestionIndex)
															}
															onMouseLeave={() => setDestinationIndex(-1)}
														>
															{suggestion}
														</div>
													)}
											</div>
										);
									})}
						</div>
					</div>
				</div>

				<div className="whole-inputlable-wrapper">
					<label>From Date</label>
					<div className="single-txtfield-wrapper">
						<input
							type="date"
							value={startDate}
							onChange={handleStartDateChange}
							onClick={(e) => calenderKhol(e)}
							min={new Date().toISOString().split("T")[0]} // Setting today's date as the minimum
							required
							onFocus={() => handleFocus("FromDate")}
							onBlur={handleBlur}
							style={{
								border:
									focusedField === "FromDate"
										? "2px solid #38b000"
										: "1px solid #ccc",
								outline: "none",
							}}
						/>
					</div>
					{/* {startDate && <p>Selected Start Date: {formatDateNew(startDate)}</p>} */}
				</div>
				<div className="whole-inputlable-wrapper">
					<label>Pick Up Time</label>
					<div className="single-txtfield-wrapper">
						<select
							value={pickUpTime}
							onChange={handleChange}
							className="time-select"
							onFocus={() => handleFocus("PickupTime")}
							onBlur={handleBlur}
							style={{
								border:
									focusedField === "PickupTime"
										? "2px solid #38b000"
										: "1px solid #ccc",
								outline: "none",
							}}
						>
							<option value="" disabled>
								Select pick up time
							</option>
							{generateTimeOptions(startDate).map((option, index) => (
								<option key={index} value={option}>
									{option}
								</option>
							))}
						</select>
						<div className="BtoC-icon-holder">
							<img src={clock} alt="img" />
						</div>
					</div>
				</div>

				<div className="whole-inputlable-wrapper">
					<label>Cab Type</label>
					<div className="single-txtfield-wrapper">
						<select
							value={selectedCabType} // Selected cab type value
							onChange={handleChangeCabType} // Event handler for selection change
							onFocus={() => handleFocus("CabType")}
							onBlur={handleBlur}
							style={{
								border:
									focusedField === "CabType"
										? "2px solid #38b000"
										: "1px solid #ccc",
								outline: "none",
							}}
						>
							<option value="" disabled>
								Select cab type
							</option>
							{cabType.map((option, index) => (
								<option key={index} value={option.value}>
									{option.label}
								</option>
							))}
						</select>
						{/* You can uncomment this for icon */}
						{/* <div className="BtoC-icon-holder"> <img src={searchicon} alt="img"></img> </div> */}
					</div>
				</div>
			</div>

			<div className="oneway-row-flexer">
				<div className="whole-inputlable-wrapper">
					<label>Passenger Details</label>
					<div
						className={
							"single-txtfield-wrapper " + (!isNewCustomer ? "gray" : "")
						}
					>
						<input
							type="text"
							placeholder="First name"
							value={isNewCustomer ? firstName : firstName}
							onChange={(e) => setFirstName(e.target.value)}
							disabled={!isNewCustomer}
							required
							onFocus={() => handleFocus("FName")}
							onBlur={handleBlur}
							style={{
								border:
									focusedField === "FName"
										? "2px solid #555"
										: "1px solid #ccc",
								outline: "none",
							}}
						/>
					</div>
					{errors.firstName && (
						<p className="error-message">{errors.firstName}</p>
					)}
				</div>

				<div className="whole-inputlable-wrapper">
					<label>&ensp;</label>
					<div
						className={
							"single-txtfield-wrapper " + (!isNewCustomer ? "gray" : "")
						}
					>
						<input
							type="text"
							placeholder="Last name"
							value={isNewCustomer ? lastName : lastName}
							onChange={(e) => setLastName(e.target.value)}
							disabled={!isNewCustomer}
							required
							onFocus={() => handleFocus("LName")}
							onBlur={handleBlur}
							style={{
								border:
									focusedField === "LName"
										? "2px solid #555"
										: "1px solid #ccc",
								outline: "none",
							}}
						/>
					</div>
					{errors.lastName && (
						<p className="error-message">{errors.lastName}</p>
					)}
				</div>

				<div className="whole-inputlable-wrapper">
					<label>&ensp;</label>
					<div
						className={
							"single-txtfield-wrapper " + (!isNewCustomer ? "gray" : "")
						}
					>
						<input
							type="text"
							placeholder="Passenger E-mail"
							value={isNewCustomer ? email : email}
							onChange={(e) => setEmail(e.target.value)}
							disabled={!isNewCustomer}
							required
							onFocus={() => handleFocus("PEmail")}
							onBlur={handleBlur}
							style={{
								border:
									focusedField === "PEmail"
										? "2px solid #555"
										: "1px solid #ccc",
								outline: "none",
							}}
						/>
					</div>
				</div>

				<div className="whole-inputlable-wrapper">
					<label>&ensp;</label>
					<div
						className={
							"single-txtfield-wrapper " + (!isNewCustomer ? "gray" : "")
						}
					>
						<input
							type="text"
							placeholder="Passenger contact number"
							value={isNewCustomer ? mobileNo : mobileNo}
							onChange={(e) => setMobileNo(e.target.value)}
							disabled={!isNewCustomer}
							required
							onFocus={() => handleFocus("ContactNo")}
							onBlur={handleBlur}
							style={{
								border:
									focusedField === "ContactNo"
										? "2px solid #555"
										: "1px solid #ccc",
								outline: "none",
							}}
						/>
					</div>
					{errors.mobileNo && (
						<p className="error-message">{errors.mobileNo}</p>
					)}
				</div>

				{/* Search Location Component */}

				<div className="whole-inputlable-wrapper">
					<label>&ensp;</label>
					<div className="single-txtfield-wrapper">
						<input
							type="text"
							ref={autoCompleteRef}
							onChange={(event) => setPickupAddress(event.target.value)}
							value={pickupAddress}
							placeholder="Pickup Address"
							onFocus={() => handleFocus("PickUpAdd")}
							onBlur={handleBlur}
							style={{
								border:
									focusedField === "PickUpAdd"
										? "2px solid #38b000"
										: "1px solid #ccc",
								outline: "none",
							}}
						/>
						<div className="BtoC-icon-holder">
							<img src={searchicon} alt="search icon" />
						</div>
					</div>
					{errors.pickupAddress && (
						<p className="error-message">{errors.pickupAddress}</p>
					)}
				</div>

				<div className="whole-inputlable-wrapper">
					<label>&ensp;</label>
					<div className="single-txtfield-wrapper">
						<input
							type="text"
							placeholder="Alternate contact number"
							value={AlternateContactNumber}
							onChange={(e) => setAlternateContactNumber(e.target.value)}
							onFocus={() => handleFocus("AlternateContact")}
							onBlur={handleBlur}
							style={{
								border:
									focusedField === "AlternateContact"
										? "2px solid #38b000"
										: "1px solid #ccc",
								outline: "none",
							}}
						/>
					</div>
				</div>
			</div>
			<div className="curve-field-container">
				<div className="sub-wrapper">
					<input
						id="c001"
						type="checkbox"
						checked={isGstChecked}
						onChange={handleGstCheckboxChange}
						onFocus={() => handleFocus("GstCheck")}
						onBlur={handleBlur}
					/>
					<label htmlFor="c001">GST</label>
					<input
						type="text"
						value={gstValue}
						onChange={(e) => setGstValue(e.target.value)}
						disabled={!isGstChecked}
						onFocus={() => handleFocus("GstVal")}
						onBlur={handleBlur}
						style={{
							border:
								focusedField === "GstVal"
									? "2px solid #38b000"
									: "1px solid #ccc",
							outline: "none",
						}}
					/>
				</div>

				<div className="sub-wrapper">
					<input
						id="c002"
						type="checkbox"
						checked={isFlightChecked}
						onChange={handleFlightCheckboxChange}
						onFocus={() => handleFocus("FlightCheck")}
						onBlur={handleBlur}
					/>
					<label htmlFor="c002">Flight</label>
					<input
						type="text"
						value={flightValue}
						onChange={(e) => setFlightValue(e.target.value)}
						disabled={!isFlightChecked}
						onFocus={() => handleFocus("FlightVal")}
						onBlur={handleBlur}
						style={{
							border:
								focusedField === "FlightVal"
									? "2px solid #38b000"
									: "1px solid #ccc",
							outline: "none",
						}}
					/>
				</div>
			</div>

			<textarea
				placeholder="Comment here for any specific request for ride :"
				value={comments}
				onChange={(e) => setComments(e.target.value)}
				onFocus={() => handleFocus("comment")}
				onBlur={handleBlur}
				style={{
					border:
						focusedField === "comment" ? "2px solid #38b000" : "1px solid #ccc",
					outline: "none",
				}}
			/>

			{isOtpModalOpen && (
				<div className="modal-container">
					<div className="modal-content">
						<p>
							Please enter the OTP sent on user mobile no. {"+91-" + mobileNo}
						</p>
						<div className="otp-input-container">
							{otp.map((digit, index) => (
								<input
									key={index}
									id={`otp-input-${index}`}
									type="text"
									maxLength="1"
									value={digit}
									onChange={(e) => handleOtpChange(index, e.target.value)}
									onKeyDown={(e) => handleKeyDown(index, e)}
									autoComplete="off"
								/>
							))}
						</div>
						<div className="button-container">
							<button className="verify-button" onClick={handleVerifyOtp}>
								Verify Now
							</button>
							<button className="cancel-button" onClick={handleCancelOtp}>
								Cancel
							</button>
						</div>
					</div>
				</div>
			)}
			<Toaster />
		</>
	);
};

export { Oneway, Roundtrip };
