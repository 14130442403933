import axios from "axios";
import { useState, useEffect, useCallback } from "react";

const useSuggestionsCustomer = () => {
  const [suggestions, setSuggestions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchData = useCallback(async () => { 
    setLoading(true);
    setError(null);
    try {
      const response = await axios.get(
        "https://api.yatricabs.com/v1/admin/users/active"
      );

      const Data = response.data;
      const processedData = Data.data.map((customer) => ({
        label: `${customer.firstName} ${customer.lastName}`,
        firstname: customer.firstName,
        lastname: customer.lastName,
        phone: customer.phone,
        email: customer.email,
        value: customer._id,
      }));


 

      console.log( processedData);

      setSuggestions(processedData);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching suggestions:", error);
      setError(error);
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return { suggestions, loading, error, refetch: fetchData };
};

export default useSuggestionsCustomer;
