import React, { useEffect } from "react";
import { useState } from "react";
import "./Login.css";
import logo from "../../assets/logo.png";
import { useNavigate } from "react-router-dom";
// import { useUser } from "../../utils/UserContext";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { login, logout } from "./authSlice";

const Login = () => {
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState("");
  const [data, setData] = useState({ email: "", password: "" });
  const dispatch = useDispatch();
  const { isLogIn } = useSelector((state) => state.auth);
  const user = useSelector((state) => state.auth.user);
  // const userData = localStorage?.getItem("user");
  // const parsedData = JSON?.parse(userData);
  // console.log(parsedData?._id);

  useEffect(() => {
    // if (parsedData?._id) {
    //   navigate("/dashboard");
    // }
    if (isLogIn) {
      navigate("/dashboard");
    } else {
      navigate("/");
    }
  }, [isLogIn]);

  const changeHandler = (e) => {
    setData((prevData) => {
      return { ...prevData, [e.target.name]: e.target.value };
    });
  };

  const loginHandler = async (e) => {
    e.preventDefault();

    if (!data.email || !data.password) {
      setErrorMessage("Please enter both email and password");
      return;
    }
    try {
      var result = await axios.post(
        `https://api.yatricabs.com/v1/admin/login`,
        {
          email: data.email,
          password: data.password,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
          },
        }
      );

      result = JSON.parse(result.request.response);
      console.log(result);
      console.log(data.email, data.password);

      if (result.message === "Login Successful") {
        if (result.data) {
          console.log(data.email, data.password);
          // loginUser(result.data);
          dispatch(login(result.data));
          navigate("/dashboard");
        } else {
          setErrorMessage("Login credentials are not correct");
        }
      } else if (result.message === "Invalid Credentials") {
        setErrorMessage("User is not registered. Please Contact Admin.");
      } else if (result.message === "Invalid Credentials") {
        setErrorMessage("Incorrect password. Please try again.");
      } else {
        setErrorMessage("Sorry! Not available. Please try again.");
      }
    } catch (error) {
      if (error.message === "Network Error") {
        // Unauthorized, so logout the user
        dispatch(logout());
        window.location.href = "/";
      } else {
        // Other errors
        setErrorMessage("Sorry! Not available. Please try again.");
        console.error("Error:", error);
      }
    }
  };

  return (
    // onSubmit={()=>navigate('/dashboard')}
    <div className="login">
      <div className="login-heading">
        {/* <img className="login-image" src={logo} alt="logo" loading="lazy" /> */}
      </div>
      <div className="login-main">
        <form className="login-main-card" onSubmit={loginHandler}>
          <img src={logo} alt="logo" loading="lazy" className="main-logo " />
          <input
            type="email"
            className="login-input"
            name="email"
            placeholder="Enter email"
            onChange={changeHandler}
            value={data.email}
          />
          <input
            type="password"
            className="login-input"
            name="password"
            placeholder="Enter Password"
            onChange={changeHandler}
            value={data.password}
          />
          {errorMessage && <div className="errro">{errorMessage}</div>}
          <button type="submit" className="login-btn">
            Login
          </button>
        </form>
      </div>
    </div>
  );
};

export default Login;
