import React, { useEffect, useState } from "react";
import axios from "axios";
import "./DriverInfo.css";
import { useSelector, useDispatch } from "react-redux";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { FaLongArrowAltRight } from "react-icons/fa";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  currentDriverId,
  resetDriverDetails,
  fetchDriversDetails,
  resetCompState,
} from "./DriverDetailsSlice";
import Loader from "../Loader";
const tableHeaderStyle = {
  backgroundColor: "#38B000",
  color: "white",
  padding: "10px",
  fontFamily: "Inter",
  fontSize: "15px",
  fontWeight: 600,
  borderRadius: "unset"
};

const DriverInfo = (props) => {
  const { driverDetails, currentComp } = useSelector(
    (state) => state.driverInfo
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showPopup, setShowPopup] = useState(false);
  const [popupText, setPopupText] = useState("");
  const [reason, setReason] = useState("");
  const { currentSection, driverId } = useParams();


  const handleCheckboxChange = (e) => {
    setReason(e.target.value);
  };


  const handleReSubmitClick = () => {
    setShowPopup(true);
  };

  const handlePopupClose = () => {
    setShowPopup(false);
    setPopupText(""); // Clear the text field value when closing the popup
  };
  const handlePopupSubmit = async (driverId) => {
    try {
      // Construct the API URL with the driverId
      const apiUrl = `https://api.yatricabs.com/v1/admin/drivers/${driverId}/approve/resubmit`;

      // Prepare the request body
      const requestBody = {
        reason: reason,
        remark: popupText,
      };

      // Make the API request
      const response = await axios.post(apiUrl, requestBody);

      // Handle the response
      console.log("API response:", response.data);

      // Close the popup after submission
      setShowPopup(false);
      setPopupText("");
      setReason("");
    } catch (error) {
      // Handle any errors
      console.error("Error submitting data:", error);
    }
  };

  const [popUpImage, setPopUpImage] = useState(false);
  const [displayImage, setDisplayImage] = useState();
  const hanldeImagePopup = (imageURL) => {
    setPopUpImage(true);
    setDisplayImage(imageURL);
  };
  const [previousLocation, setPreviousLocation] = useState();
  const checkParentLocation = (path) => {
    if (path.includes("InactiveDriver")) {
      setPreviousLocation("Inactive Drivers");
    } else if (path.includes("ApprovalPendingDriver")) {
      setPreviousLocation("Approval Pending");
    } else {
      setPreviousLocation("View All Drivers");
    }
  };


  const handleModifyButton = () => {
    navigate(`/drivermanagement/${currentSection}/modify-details/${driverId}`);
  };

  const handleApprovalClick = async (driverId) => {
    try {
      const response = await axios.put(
        `https://api.yatricabs.com/v1/admin/drivers/${driverId}/approve`
      );

      const approvedDriver = response.data;

      // After successful approval, navigate to '/drivermanagement' page
      navigate("/drivermanagement");

      console.log("Driver Approved:", approvedDriver);
    } catch (error) {
      console.error("Error approving driver:", error);
    }
  };

  // const handleChange = (e) => {
  //   setInputValue({
  //     ...inputValue,
  //     [e.target.name]: e.target.value,
  //   });
  // };

  const handleEarning = () => {
    navigate(
      `/drivermanagement/${currentSection}/DriverDetails/${driverId}/Reports`
    );
  };

  useEffect(() => {
    const checkingLocation = window.location.pathname;
    console.log("checking driver details in booking", driverDetails);
    console.log(
      "checking locattion",
      checkingLocation.includes("ViewAllDriver")
    );
    checkParentLocation(window.location.pathname);
  }, []);

  const handleToggleComponent = (value) => {
    dispatch(resetCompState(value));
    navigate("/drivermanagement");
  };

  return (
    <>
      {
        <div
          className={popUpImage ? "blurbg" : "driver-info-container"}
          style={popUpImage ? {} : { marginTop: "1px" }}
        >
          <div className="nav-content">
            <div className="header-text">
              <Link className="h3-booking">Driver Management</Link>
              <FaLongArrowAltRight />
              {""}
              <Link className="h3-booking">{previousLocation}</Link>
              <FaLongArrowAltRight />
              <Link className="h2-booking">Driver Details</Link>
            </div>
            {previousLocation === "View All Drivers" && (
              <button
                className="createButton"
                onClick={() => handleToggleComponent("View All Drivers")}
              >
                View All Drivers
              </button>
            )}

            {previousLocation === "Inactive Drivers" && (
              <button
                className="createButton"
                onClick={() => {
                  handleToggleComponent("Inactive Driver");
                }}
              >
                Inactive Drivers
              </button>
            )}

            {previousLocation === "Approval Pending" && (
              <button
                className="createButton"
                onClick={() => handleToggleComponent("Approval Pending")}
              >
                Approval Pending
              </button>
            )}
          </div>
          <div
            className="add-new-car-vehicle-scrolbar"
            style={{ height: "100vh" }}
          >
            <div
              style={{
                backgroundColor: "#38B000",
                borderRadius: "2px",
                boxShadow: "0px 4px 22px 0px rgba(0, 0, 0, 0.17)",
                color: "#FFF",
                padding: "10px",
                fontFamily: "Lato",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: "700",
                lineHeight: "normal",
              }}
            >
              Driver Information
            </div>
            {driverDetails ? (
              <>
                <div className="user-details">
                  <div className="user-details-section">
                    <div className="user-detail">
                      <div className="user-heading">Location</div>
                      <div className="user-info">
                        {driverDetails.location.city},
                        {driverDetails.location.state}
                      </div>
                    </div>
                    <div className="user-detail">
                      <div className="user-heading">Phone Number</div>
                      <div className="user-info">{driverDetails.phone}</div>
                    </div>
                    <div className="user-detail">
                      <div className="user-heading">Language Speak</div>
                      {driverDetails.languages.map((language, index) => {
                        return (
                          <div className="user-languages" key={index}>
                            <input
                              type="checkbox"
                              id="hindiCheckbox"
                              name="language"
                              value="Hindi"
                              checked
                            />
                            <label htmlFor="hindiCheckbox">{language}</label>
                          </div>
                        );
                      })}
                    </div>

                    <div className="user-detail">
                      <div className="user-heading">DL Number</div>
                      <div className="user-info">
                        {driverDetails.license.number}
                      </div>
                    </div>
                    <div className="user-detail">
                      <div className="user-heading">Permanent Address</div>
                      <div className="user-info">
                        {driverDetails.address.permanent}
                      </div>
                    </div>
                  </div>
                  <div className="user-details-section">
                    <div className="user-detail">
                      <div className="user-heading">First Name</div>
                      <div className="user-info">{driverDetails.firstName}</div>
                    </div>
                    <div className="user-detail">
                      <div className="user-heading">Alternate Phone Number</div>
                      <div className="user-info">
                        {driverDetails.alternatePhone}
                      </div>
                    </div>
                    <div className="user-detail">
                      <div className="user-heading">Expiry Date</div>
                      <div className="user-info">
                        {driverDetails.license.expiryDate}
                      </div>
                    </div>
                    <div className="user-detail">
                      <div className="user-heading">Current Address</div>
                      <div className="user-info">
                        {driverDetails.address.current}
                      </div>
                    </div>
                    <div className="user-detail">
                      <div className="user-heading">Sourcing</div>
                      <div className="user-info">{driverDetails.sourcing}</div>
                    </div>
                  </div>
                  <div className="user-details-section">
                    <div className="user-detail">
                      <div className="user-heading">Last Name</div>
                      <div className="user-info">{driverDetails.lastName}</div>
                    </div>
                    <div className="user-detail">
                      <div className="user-heading">Date Of Birth</div>
                      <div className="user-info">
                        {driverDetails.dateOfBirth}
                      </div>
                    </div>
                    <div className="user-detail">
                      <div className="user-heading">PAN Number</div>
                      <div className="user-info">
                        {driverDetails.pan.number}
                      </div>
                    </div>
                    <div className="user-detail">
                      <div className="user-heading">Aadhar Number</div>
                      <div className="user-info">
                        {driverDetails.aadhaar.number}
                      </div>
                    </div>
                  </div>
                  {/* {previousLocation !== "Approval Pending" ? ( */}
                  <div>
                    <button
                      className="createButton"
                      onClick={handleEarning}
                      style={{ marginTop: "10px", marginRight: "10px" }}
                    >
                      Earning Report
                    </button>
                  </div>
                  {/* ) : null} */}
                </div>
              </>
            ) : (
              <div className="loading-circle">
                <Loader />
              </div>
            )}

            <div
              style={{
                backgroundColor: "#38B000",
                borderRadius: "2px",
                boxShadow: "0px 4px 22px 0px rgba(0, 0, 0, 0.17)",
                color: "#FFF",
                padding: "10px",
                fontFamily: "Lato",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: "700",
                lineHeight: "normal",
              }}
            >
              Bank Details
            </div>
            {driverDetails ? (
              <div className="user-details">
                <div className="user-details-section">
                  <div className="user-detail">
                    <div className="user-heading">Beneficiary Name</div>
                    <div className="user-info">
                      {driverDetails.bankDetails.beneficiaryName}
                    </div>
                  </div>
                </div>
                <div className="user-details-section">
                  <div className="user-detail">
                    <div className="user-heading">Account No.</div>
                    <div className="user-info">
                      {driverDetails.bankDetails.accountNumber}
                    </div>
                  </div>
                </div>
                <div className="user-details-section">
                  <div className="user-detail">
                    <div className="user-heading">IFSCCODE</div>
                    <div className="user-info">
                      {driverDetails.bankDetails.IFSC}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="loading-circle">
                <Loader />
              </div>
            )}

            <div className="driver-detail-price-quota">
              Price Quote
            </div>
            <div className="driver-detail-price-quota-details">
              {/* Outstation Table */}
              <table className="price-quota-pricing-table">
                <thead>
                  <tr>
                    <th colSpan="2" style={tableHeaderStyle}>
                      Outstation
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Rate/km</td>
                    <td className="driver-fare-white-box">
                      Rs.{" "}{driverDetails?.fares.outstation.perKmFare}
                    </td>
                  </tr>
                  <tr>
                    <td>Min km/Day</td>
                    <td className="driver-fare-white-box">
                      {driverDetails?.fares.outstation.minKmPerDay}{" KM"}
                    </td>
                  </tr>
                </tbody>
              </table>

              {/* Local Table */}
              <table className="price-quota-pricing-table">
                <thead>
                  <tr>
                    <th colSpan="2" style={tableHeaderStyle}>
                      Local
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>2hrs | 20km</td>
                    <td className="driver-fare-white-box">
                      Rs.{" "}{driverDetails?.fares.local.fare2hr20km}
                    </td>
                  </tr>
                  <tr>
                    <td>4hrs | 40km</td>
                    <td className="driver-fare-white-box">
                      Rs.{" "}{driverDetails?.fares.local.fare4hr40km}
                    </td>
                  </tr>
                  <tr>
                    <td>6hrs | 60km</td>
                    <td className="driver-fare-white-box">
                      Rs.{" "}{driverDetails?.fares.local.fare6hr60km}
                    </td>
                  </tr>
                  <tr>
                    <td>8hrs | 80km</td>
                    <td className="driver-fare-white-box">
                      Rs.{" "}{driverDetails?.fares.local.fare8hr80km}
                    </td>
                  </tr>
                  <tr>
                    <td>10hrs | 100km</td>
                    <td className="driver-fare-white-box">
                      Rs.{" "}{driverDetails?.fares.local.fare10hr100km}
                    </td>
                  </tr>
                  <tr>
                    <td>12hrs | 120km</td>
                    <td className="driver-fare-white-box">
                      Rs.{" "}{driverDetails?.fares.local.fare12hr120km}
                    </td>
                  </tr>
                  <tr>
                    <td>Extra Km Charge</td>
                    <td className="driver-fare-white-box">
                      Rs.{" "}{driverDetails?.fares.local.perKmFareExtra}{" /km"}
                    </td>
                  </tr>

                  <tr>
                    <td>Extra Hrs Charge</td>
                    <td className="driver-fare-white-box">
                      Rs.{" "}{driverDetails?.fares.local.perHourFareExtra}{" /hrs"}
                    </td>
                  </tr>
                </tbody>
              </table>

              {/* Airport Table */}
              <table className="price-quota-pricing-table">
                <thead>
                  <tr>
                    <th colSpan="2" style={tableHeaderStyle}>
                      Airport
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Rate Per Trip</td>
                    <td className="driver-fare-white-box">
                      Rs.{" "}{driverDetails?.fares.airport.baseFare}
                    </td>
                  </tr>
                </tbody>
              </table>

              {/* Night Charge Table */}
              <table className="price-quota-pricing-table">
                <thead>
                  <tr>
                    <th colSpan="2" style={tableHeaderStyle}>
                      Night Charge
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="driver-fare-white-box">
                      Rs.{" "}{driverDetails?.fares.nightCharge}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div
              style={{
                backgroundColor: "#38B000",
                borderRadius: "2px",
                boxShadow: "0px 4px 22px 0px rgba(0, 0, 0, 0.17)",
                color: "#FFF",
                padding: "10px",
                fontFamily: "Lato",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: "700",
                lineHeight: "normal",
              }}
            >
              Upload Documents
            </div>
            {driverDetails ? (
              <>
                <div className="user-details">
                  <div className="user-details-section">
                    <div className="user-detail">
                      <div className="user-headin">Profile Photo</div>
                      <div className="dropdown">
                        <div className="pic">
                          <img
                            className="pic-selected"
                            src={driverDetails.driverPhoto}
                            alt="Selected"
                            onClick={() =>
                              hanldeImagePopup(driverDetails.driverPhoto)
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className="user-detail">
                      <div className="user-headin">Pan Card</div>
                      <div className="dropdown">
                        <div className="pic">
                          <img
                            className="pic-selected"
                            src={driverDetails.pan.photo}
                            alt="Selected"
                            onClick={() =>
                              hanldeImagePopup(driverDetails.pan.photo)
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="user-details-section">
                    <div className="user-detail">
                      <div className="user-headin">Aadhar Card Front</div>
                      <div className="dropdown">
                        <div className="pic">
                          <img
                            className="pic-selected"
                            src={driverDetails.aadhaar.frontPhoto}
                            alt="Selected"
                            onClick={() =>
                              hanldeImagePopup(driverDetails.aadhaar.frontPhoto)
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className="user-detail">
                      <div className="user-headin">Aadhar Card Back</div>
                      <div className="dropdown">
                        <div className="pic">
                          <img
                            className="pic-selected"
                            src={driverDetails.aadhaar.frontPhoto}
                            alt="Selected"
                            onClick={() =>
                              hanldeImagePopup(driverDetails.aadhaar.frontPhoto)
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="user-details-section">
                    <div className="user-detail">
                      <div className="user-headin">Driving License Front</div>
                      <div className="dropdown">
                        <div className="pic">
                          <img
                            className="pic-selected"
                            src={driverDetails.license.frontPhoto}
                            alt="Selected"
                            onClick={() =>
                              hanldeImagePopup(driverDetails.license.frontPhoto)
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className="user-detail">
                      <div className="user-headin">Driving License Back</div>
                      <div className="dropdown">
                        <div className="pic">
                          <img
                            className="pic-selected"
                            src={driverDetails.license.backPhoto}
                            alt="Selected"
                            onClick={() =>
                              hanldeImagePopup(driverDetails.license.backPhoto)
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="user-details-section">
                    <div className="user-detail">
                      <div className="user-headin">
                        Police clearance certificate
                      </div>
                      <div className="dropdown">
                        <div className="pic">
                          <img
                            className="pic-selected"
                            src={driverDetails.policeClearanceCertificatePhoto}
                            alt="Selected"
                            onClick={() =>
                              hanldeImagePopup(
                                driverDetails.policeClearanceCertificatePhoto
                              )
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div className="add-car-driver">
                    <button
                      style={{
                        borderRadius: "5px",
                        margin: "10px",
                        border: "0.5px solid #38B000",
                        background: "#38B000",
                        width: "150px",
                        padding: "6px",
                        color: "#FFF",
                        fontSize: "15px",
                        hover: "cursor",
                      }}
                      onClick={handleModifyButton}
                    >
                      Modify
                    </button>

                    {previousLocation === "Approval Pending" ? (
                      <>
                        <button
                          onClick={() => handleApprovalClick(driverId)}
                          style={{
                            borderRadius: "5px",
                            margin: "10px",
                            border: "0.5px solid #38B000",
                            background: "#38B000",
                            width: "150px",
                            padding: "6px",
                            color: "#FFF",
                            fontSize: "15px",
                          }}
                        >
                          Approve
                        </button>
                        <button
                          onClick={handleReSubmitClick}
                          style={{
                            borderRadius: "5px",
                            margin: "10px",
                            border: "0.5px solid #38B000",
                            background: "#38B000",
                            width: "150px",
                            padding: "6px",
                            color: "#FFF",
                            fontSize: "15px",
                          }}
                        >
                          ReSubmit
                        </button>
                      </>
                    ) : null}
                  </div>
                </div>
              </>
            ) : (
              <div className="loading-circle">
                <Loader />
              </div>
            )}

            {/* Popup */}
            {showPopup && (
              <div className="popup">
                <div className="popup-content">
                  <span className="close" onClick={handlePopupClose}>
                    &times;
                  </span>
                  <div className="popup-content-checkbox">
                    <div className="popup-checkbox">
                      <input
                        type="checkbox"
                        id="checkbox1"
                        name="checkbox1"
                        value="Document Pending"
                        // checked={reason === "Document Pending"}
                        onChange={handleCheckboxChange}
                      />
                      <label htmlFor="checkbox1">Document Pending</label>
                    </div>
                    <div className="popup-checkbox">
                      <input
                        type="checkbox"
                        id="checkbox1"
                        name="checkbox1"
                        value="Missing Profile Information"
                        // checked={reason === "Missing Profile Information"}
                        onChange={handleCheckboxChange}
                      />
                      <label htmlFor="checkbox1">
                        Missing Profile Information
                      </label>
                    </div>
                  </div>
                  <textarea
                    type="text"
                    value={popupText}
                    placeholder="Enter remark here"
                    onChange={(e) => setPopupText(e.target.value)}
                  />
                  <button onClick={()=>handlePopupSubmit(driverId)}>Resubmit</button>
                </div>
              </div>
            )}
          </div>
        </div>
      }
      {popUpImage && (
        <>
          <div className="imagePopUp">
            <div style={{ marginLeft: "100%", cursor: "pointer" }}>
              <IoIosCloseCircleOutline
                style={{ width: "30px", height: "30px" }}
                onClick={() => setPopUpImage(false)}
              />
            </div>
            <img
              src={displayImage}
              style={{ width: "100%", height: "100%" }}
              alt="Document image"
            />
          </div>
        </>
      )}
    </>
  );
};

export default DriverInfo;
