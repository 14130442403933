import React from "react";
import { RxCross2 } from "react-icons/rx";
import "./TripTypePopups.css";

const Outstation_Round_Trip = ({ bookingDetails, closepopup }) => {
  return (
    <div className="airport-popup-main-container">
      <div className="airport-popup-main-booking">
        <div className="type-airport-popup-OR">
          <div className="summary">Fare Summary</div>

          <div className="type-airport-popup-header">
            <div className="local-f2">Fare Breakup:</div>
            <h5
              onClick={closepopup}
              style={{
                fontSize: "2.50rem",
                cursor: "pointer",
                height: "0px",
                width: "32px",
                marginTop: "-3px",
              }}
            >
              <RxCross2 />
            </h5>
          </div>

          <div className="airport-popup-text">
            <div className="airport-fare-amount">
              <div className="airport-each-amount">
                <span className="left-span">Approx Roundtrip Distance :</span>

                <span className="middle-span">{"   "}</span>
                <span className="right-span">
                  {bookingDetails.approxDistance} Kms
                </span>
              </div>

              <div className="airport-each-amount">
                <span className="left-span">Min Km Charged :</span>

                <span className="middle-span">{"   "}</span>
                <span className="right-span">
                  {bookingDetails.tariff.fareDetails.minKmPerDay} Kms/day
                </span>
              </div>

              <div className="airport-each-amount">
                <span className="left-span">
                  Approx Roundtrip Charges : ({bookingDetails.approxDistance} KM
                  X Rs.
                  {bookingDetails.tariff.fareDetails.perKmCharge}/km)
                </span>
                <span className="middle-span">
                  {" "}
                </span>
                <span className="right-span">
                  = Rs.
                  {bookingDetails.approxDistance *
                    bookingDetails.tariff.fareDetails.perKmCharge}
                  /-
                </span>
              </div>

              {/* Conditionally render Morning Charge */}
              {bookingDetails?.morningAllowance> 0 && (
                <div className="airport-each-amount">
                  <span className="left-span">Morning Charge :</span>
                  <span className="right-span">
                    = Rs.{" "}
                    {bookingDetails?.morningAllowance}/-
                  </span>
                </div>
              )}

              <div className="airport-each-amount">
                <span className="left-span">
                  Driver Allowance :{" "}
                  <div>
                    {" "}
                    (Rs.{" "}
                    {
                      bookingDetails?.nightAllowance
                    } X {bookingDetails.noOfDays} Day)
                  </div>
                </span>
                <span className="middle-span">
                  {" "}
                </span>
                <span className="right-span">
                  = Rs.
                  {bookingDetails?.nightAllowance*
                    bookingDetails.noOfDays}
                  /-
                </span>
              </div>

              <div className="airport-each-amount">
                <span className="left-span">GST 5% :</span>
                <span className="middle-span">
                  {" "}
                </span>
                <span className="right-span">
                  = Rs. {bookingDetails.gstOnEstimatedFare}/-
                </span>
              </div>

              <div className="airport-each-amount">
                <span className="left-span green-total">Total :</span>
                <span className="middle-span">
                  {" "}
                </span>
                <span className="right-span">
                  <b>Rs. {bookingDetails.estimatedFare}/-</b>
                </span>
              </div>
            </div>

            <div className="airport-total-text">
              <b>Extra Charges:</b> If you will use the car/cab for more than{" "}
              <b>{bookingDetails.approxDistance} Kms</b> for roundtrip, extra
              charges are as follows:
            </div>

            <div className="airport-extra-charges">
              <div className="airport-each-extra-charge">
                <span className="left-span">
                  Extra Km after {bookingDetails.approxDistance} Kms:
                </span>
                <span className="right-span">
                  <b>
                    = Rs. {bookingDetails.tariff.fareDetails.extraKmCharge}/km
                  </b>
                </span>
              </div>
            </div>

            <div className="airport-instruction">
              <div className="airport-Tc">
                <b>**T&C apply:-</b>
                <div className="airport-all-instructions">
                  • One day means one calendar day (12 midnight to 12 midnight).
                  <br />
                  • Kilometres and Hours will be calculated from garage to
                  garage.
                  <br />
                  • Toll, Parking, State Border Charges etc. are not included in
                  the above cost; you have to pay extra as applicable.
                  <br />
                  • AC will be switched off in hilly areas.
                  <br />
                  • Driver would take care of his food and stay.
                  <br />• For driving between <b>09:45 PM to 06:00 AM</b> on any
                  of the nights, a Driver Night Allowance will be applicable and
                  is to be paid to the driver.
                  <br />
                  • Your trip includes one pick-up in Pick-up city and one drop
                  to the destination city. It does not include within-city
                  travel.
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Outstation_Round_Trip;
