// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.list-container {
  /*
  height: auto;
  overflow-y: scroll; /* Enable vertical scroll if content exceeds the max height */
  /* z-index: 9;
  */ 

  margin-top: 4px;
  position: absolute;
  top: 100%;
  right: 0;
  left: -1px;
  background-color: #fff;
  border-radius: 5px;
  max-height: 155px;
  box-shadow: 2px 2px 7px 0 rgb(0, 0, 0, 0.5);
  cursor: pointer;
  overflow-y: scroll;
  scrollbar-width: thin;
  scroll-behavior: smooth;

  /* max-height: 267px;

  position: absolute;
  background-color: white;
  cursor: pointer;
  margin-top: 5px;
  border-radius: 5px;
  box-shadow: 2px 2px 7px 0 rgb(0, 0, 0, 0.5);
   */
  
}

.list-container ul li {
  padding: 8px 10px;
  cursor: pointer;
  font-family: Inter;
  font-size: 11px;
  font-weight: 500;
}

.list-container ul li:hover {
  background-color: #38b000;
  color: white;
}
`, "",{"version":3,"sources":["webpack://./src/components/Booking/List.css"],"names":[],"mappings":"AAAA;EACE;;mFAEiF;EACjF;GACC;;EAED,eAAe;EACf,kBAAkB;EAClB,SAAS;EACT,QAAQ;EACR,UAAU;EACV,sBAAsB;EACtB,kBAAkB;EAClB,iBAAiB;EACjB,2CAA2C;EAC3C,eAAe;EACf,kBAAkB;EAClB,qBAAqB;EACrB,uBAAuB;;EAEvB;;;;;;;;IAQE;;AAEJ;;AAEA;EACE,iBAAiB;EACjB,eAAe;EACf,kBAAkB;EAClB,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,yBAAyB;EACzB,YAAY;AACd","sourcesContent":[".list-container {\n  /*\n  height: auto;\n  overflow-y: scroll; /* Enable vertical scroll if content exceeds the max height */\n  /* z-index: 9;\n  */ \n\n  margin-top: 4px;\n  position: absolute;\n  top: 100%;\n  right: 0;\n  left: -1px;\n  background-color: #fff;\n  border-radius: 5px;\n  max-height: 155px;\n  box-shadow: 2px 2px 7px 0 rgb(0, 0, 0, 0.5);\n  cursor: pointer;\n  overflow-y: scroll;\n  scrollbar-width: thin;\n  scroll-behavior: smooth;\n\n  /* max-height: 267px;\n\n  position: absolute;\n  background-color: white;\n  cursor: pointer;\n  margin-top: 5px;\n  border-radius: 5px;\n  box-shadow: 2px 2px 7px 0 rgb(0, 0, 0, 0.5);\n   */\n  \n}\n\n.list-container ul li {\n  padding: 8px 10px;\n  cursor: pointer;\n  font-family: Inter;\n  font-size: 11px;\n  font-weight: 500;\n}\n\n.list-container ul li:hover {\n  background-color: #38b000;\n  color: white;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
