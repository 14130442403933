import React, { useEffect, useState } from "react";
import "../AddCarCategory.css";
import "./PreviewCarCategory.css"
import axios from "axios";
import { useParams, Link, useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { FaLongArrowAltRight } from "react-icons/fa";


const ModifyCarCategory = () => {
  const { id, previewBool } = useParams();

  const [carFront, setcarFront] = useState(null);
  const navigate = useNavigate();
  const [selectedImage, setSelectedImage] = useState(null);
  const [showCloseIconCarfront, setShowCloseIconCarfront] = useState(false);
  const [sittingCap, setSittingCap] = useState("");
  const [categoryname, setCategoryName] = useState("");
  const [luggagecap, setLuggageCap] = useState("");
  const [vehicleincludes, setVehicleIncludes] = useState("");
  const [previousLocation, setPreviousLocation] = useState();
  const [imageUpdated, setImageUpdated] = useState(false);
  const [preview, setPreview] = useState(false);
  const [carCategory, setCarCategory] = useState({
    category: "",
    includes: "",
    seatingCapacity: "",
    luggageCapacity: "",
    carPhoto: null,
  });


  const handleFileChange = (event, setFile, setShowCloseIcon) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedImage(event.target.files[0]);
      setCarCategory((prevState) => ({
        ...prevState,
        carPhoto: event.target.files[0],
      }));
      setImageUpdated(true);
      setFile(file);
      setShowCloseIcon(true);
    }
  };

  const handleRemoveFile = (setFile, setShowCloseIcon) => {
    setFile(null);
    setCarCategory((prevState) => ({
      ...prevState,
      carPhoto: null,
    }));
    setImageUpdated(false);
    setcarFront(null);
    setShowCloseIcon(false);
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    console.log("submit triggered...")
    const headers = {
      "Content-Type": "multipart/form-data",
    };
    const carCategoryObj = new FormData();
    carCategoryObj.append("category", carCategory.category);
    carCategoryObj.append("includes", carCategory.includes);
    carCategoryObj.append("seatingCapacity", carCategory.seatingCapacity);
    carCategoryObj.append("luggageCapacity", carCategory.luggageCapacity);
    if (imageUpdated) {
      carCategoryObj.append("carPhoto", carCategory.carPhoto);
    }
    try {
      const response = await axios.put(
        `https://api.yatricabs.com/v1/admin/car-categories/${id}`,
        carCategoryObj,
        { headers }
      );
      //   alert("Car category updccccated!");
      // e.target.reset();
      setcarFront(null);
      setShowCloseIconCarfront(false);
      toast.success("Car category updated!");
      setTimeout(() => {
        navigate("/carmanagement/view-cars-category");
      }, 1000);
    } catch (error) {
      toast.error("Failed to update car category!");
      console.error(error);
    }
  };

  const fetchCarCategory = async () => {
    try {
      const response = await axios.get(
        `https://api.yatricabs.com/v1/admin/car-categories/${id}`
      );
      const categoryData = response.data.data;
      console.log("categoryData is...",carCategory)
      setCarCategory({
        category: categoryData.category,
        includes: categoryData.includes,
        seatingCapacity: categoryData.seatingCapacity,
        luggageCapacity: categoryData.luggageCapacity,
        carPhoto: categoryData.carPhoto,
      });
      setcarFront(true);
      setShowCloseIconCarfront(true);
    } catch (error) {
      console.error("Failed to fetch car category:", error);
    }
  };

  useEffect(() => {
    fetchCarCategory();
  }, [id]);

  const handleToggleComponent = (value) => {
    // dispatch(resetCompState(value));
    navigate(`/carmanagement/${value}`);
  };


  const checkParentLocation = (path) => {
    if (path.includes("modify-car-category")) {
      setPreviousLocation("View Cars Category");
    } else {
      setPreviousLocation("View All Cars");
    }
  };

  useEffect(() => {
    // Assuming carid is the ID of the car you want to fetch
    // dispatch(fetchSingleCarDetailsAsync(carid));
    if (previewBool === "true") {
      setPreview(true)
    }

    checkParentLocation(window.location.pathname);
  }, []);

  const changetopreview=(e)=>{
    e.preventDefault()
    // () => setPreview(!preview) 
    setPreview(!preview)
  }

  return (
    <>
      <Toaster />

      <form>
        <div
          className="add-new-car-vehicle-scrolbar"
          style={{ height: "95vh" }}
        >
          <div className="nav-content" style={{ marginTop: "25px" }}>
            <div className="header-text">
              <Link className="h3-booking">Fleet Management</Link>
              <FaLongArrowAltRight />
              {""}
              <Link className="h3-booking">{previousLocation}</Link>
              <FaLongArrowAltRight />
              <Link className="h2-booking">Edit Car Category Details</Link>
            </div>
            {previousLocation === "View All Cars" && (
              <button
                className="createButton"
                onClick={() => handleToggleComponent("view-all-cars")}
              >
                View All Cars
              </button>
            )}

            {previousLocation === "View Cars Category" && (
              <button
                className="createButton"
                onClick={() => {
                  handleToggleComponent("view-cars-category");
                }}
              >
                View Cars Category
              </button>
            )}


          </div>
          <div className="add-new-car-vehicle">Car Category Information</div>
          {!preview ? (
            <div>
              <div className="user-details">
                <div className="user-details-section">
                  <div className="user-detail">
                    <div className="user-heading3">Category Name</div>
                    <input
                      type="text"
                      className="custom-input3"
                      value={carCategory.category}
                      onChange={(e) =>
                        setCarCategory({
                          ...carCategory,
                          category: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="user-detail">
                    <div className="user-heading3">Seating Capacity</div>
                    <input
                      type="text"
                      className="custom-input3"
                      value={carCategory.seatingCapacity}
                      onChange={(e) =>
                        setCarCategory({
                          ...carCategory,
                          seatingCapacity: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
                <div className="user-details-section">
                  <div className="user-detail">
                    <div className="user-heading3">Vehicle Includes</div>
                    <textarea
                      type="text"
                      className="custom-input3"
                      value={carCategory.includes}
                      onChange={(e) =>
                        setCarCategory({
                          ...carCategory,
                          includes: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
                <div className="user-details-section">
                  <div className="user-detail">
                    <div className="user-heading3">Luggage Capacity</div>
                    <input
                      type="text"
                      className="custom-input3"
                      placeholder="No. of bags"
                      value={carCategory.luggageCapacity}
                      onChange={(e) =>
                        setCarCategory({
                          ...carCategory,
                          luggageCapacity: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="user-details">
                <div className="user-detail-section">
                  <div className="user-detail">
                    <div className="user-heading3">Car Photo</div>
                    <div className="dropdown">
                      <div className="pic">
                        {carFront && (
                          <img
                            className="pic-selected"
                            src={
                              carCategory.carPhoto instanceof File ||
                                carCategory.carPhoto instanceof Blob
                                ? URL.createObjectURL(carCategory.carPhoto)
                                : carCategory.carPhoto
                            }
                            alt="Selected"
                          />
                        )}

                        {showCloseIconCarfront && (
                          <span
                            className="close-sign"
                            onClick={() =>
                              handleRemoveFile(
                                setcarFront,
                                setShowCloseIconCarfront,
                                setImageUpdated
                              )
                            }
                          >
                            X
                          </span>
                        )}

                      </div>
                      <div className="dropdown-file">
                        <div className="file-container">
                          <label className="upload">
                            <input
                              type="file"
                              onChange={(e) =>
                                handleFileChange(
                                  e,
                                  setcarFront,
                                  setShowCloseIconCarfront,
                                  setImageUpdated
                                )
                              }
                            />
                            <span>Choose File</span>
                          </label>

                          {carFront ? (
                          <p className="selected">Selected File: {carFront.name}</p>
                        ):(<p className="selected">No file choosen</p>)}

                        </div>
                      
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="preview-container">
              <div className="left-preview-container">
                <div className="detail">
                  <div>Category Name</div>
                  <p>{carCategory.category}</p>
                </div>
                <div className="detail">
                  <div>Seating Capacity</div>
                  <p>{carCategory.seatingCapacity} Passengers</p>
                </div>
                <div className="detail">
                  <div>Vehicle Includes</div>
                  <p>{carCategory.includes}</p>
                </div>
                <div className="detail">
                  <div>Luggage Capacity</div>
                  <p>{carCategory.luggageCapacity} bags</p>
                </div>
              </div>
              <div className="right-preview-container">
                <div className="car-photo">
                  <div>Car Photo</div>
                  <img
                    src={
                      carCategory.carPhoto instanceof File ||
                        carCategory.carPhoto instanceof Blob
                        ? URL.createObjectURL(carCategory.carPhoto)
                        : carCategory.carPhoto
                    }
                    alt="Car Photo"
                  />
                </div>
              </div>
            </div>
          )}

          <div style={{ display: "flex", justifyContent: "center" }}>
            {preview === false ?
              (<button
                style={{
                  borderRadius: "5px",
                  margin: "10px",
                  border: "0.5px solid #38B000",
                  background: "#38B000",
                  width: "150px",
                  padding: "6px",
                  color: "#FFF",
                  fontSize: "15px",
                  cursor: 'pointer'
                }}
                // type="submit"
                onClick={handleUpdate}
              >
                Update
              </button>
              ) : (
                <button
                  style={{
                    borderRadius: "5px",
                    margin: "10px",
                    border: "0.5px solid #38B000",
                    background: "#38B000",
                    width: "150px",
                    padding: "6px",
                    color: "#FFF",
                    fontSize: "15px",
                    cursor: 'pointer',
                    zIndex: '10',
                  }}
                  onClick={changetopreview}
                  type="button"
                >
                  {preview ? "Edit Category" : "Preview"}
                </button>
              )}
          </div>
        </div>
      </form>
    </>
  );
};

export default ModifyCarCategory;
