import { useState } from "react";
import "./Toggle.css";

export const Toggle = ({ label, toggled, onClick }) => {
  const [isToggled, toggle] = useState(toggled);

  const callback = () => {
    toggle(!isToggled);
    onClick(!isToggled);
  };

  console.log("lable is   ===", label)
  return (
    <>
      <div className="toggle">
        <label>
          <input type="checkbox" defaultChecked={isToggled} onClick={callback} />
          <span />
          <strong>{label}</strong>
        </label>
      </div>

      {/* <div className="toggler-wrapper">
        <input type="checkbox" id="label001" />
        <label htmlFor="label001"></label>
      </div> */}

    </>
  );
};
