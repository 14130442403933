import { useState, useEffect } from "react";
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const useSuggestionsCities = () => {
  const [suggestions, setSuggestions] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/v1/locations`);
        const data = await response.json();
        const locationCities = data.data.map((item) => item.location);

        // console.log(locationCities);

        setSuggestions(locationCities);
      } catch (error) {
        console.error("Error fetching suggestions:", error);
      }
    };

    fetchData();
  }, []);

  return [suggestions];
};

export default useSuggestionsCities;
