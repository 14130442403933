import React from "react";
import { useState, useEffect } from "react";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./ViewAllCar.css";
import AddNewDriver from "./BookingHistory";
//import BookingHistory from "./components/DriverManagement/BookingHistory";
//import BookingHistory from "./BookingHistory";
import AddNewCar from "./AddNewCar";
import { ImEye } from "react-icons/im";
import { FaPen } from "react-icons/fa6";
import { IoSearch } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";

import {
  fetchInactive,
  selectInactiveDriver,
  selectLoading,
} from "./DriverSlice";
import {
  currentDriverId,
  resetDriverDetails,
  fetchDriversDetails,
  resetCompState,
} from "./DriverDetailsSlice";
import { useNavigate } from "react-router-dom";
import InputColumn from "../Booking/Input";
import DropdownColumn from "../Booking/Dropdown";
import Filter from "../Booking/Filter";
import { Toggle } from "../TariffManagement/Toggle";
import { toggleDriverStatus } from "./DriverSlice";
import axios from "axios";
import { IoIosArrowDown } from "react-icons/io";
import { CiFilter } from "react-icons/ci";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const CustomDropdown = ({
  options,
  placeholder,
  selectedValue,
  handleChange,
}) => {
  const placeholderOption = {
    label: placeholder || "Select an option",
    value: "",
  };

  return (
    <div>
      <select
        value={selectedValue}
        onChange={handleChange}
        className="dropdown-select-1 "
      >
        {[placeholderOption, ...options].map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      <IoIosArrowDown
        className="dropdown-icon"
        style={{ marginTop: "0px", paddingTop: "5px" }}
      />
    </div>
  );
};



const TableItem = ({ item, index }) => {
  const rowClass = index % 2 === 0 ? "table-row-even" : "table-row-odd";
  return (
    <tr className={rowClass}>
      <td>{item["Sr no."]}</td>
      <td>{item["Location"]}</td>
      <td>{item["Driver Name"]}</td>
      <td>{item["Driver Mobile No."]}</td>
      <td>{item["No. of Ride"]}</td>
      <td>{item["Rating"]}</td>
      <td>{item["Registered On"]}</td>
      <td>{item["Verified On"]}</td>
      <td>{item["Status"]}</td>
      <td>{item["Reason"]}</td>
      <td>{item["Action"]}</td>
    </tr>
  );
};

const Table = ({ headers, data }) => {
  return (
    <div className="table-containers">
      <div className="table-scroll">
        <table className="table">
          <thead>
            <tr>
              {headers.map((header, index) => (
                <th key={index}>{header}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <TableItem key={index} item={item} index={index} />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const ViewAllCar = () => {
  const [locationOptions, setLocationOptions] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState("");
  const [searchPhoneNumber, setsearchPhoneNumber] = useState("");
  const [searchName, setsearchName] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedApprovel, setselectedApprovel] = useState("");
  const [placeholder, setPlaceholder] = useState("Select a location");
  const [showDetails, setShowDetails] = useState(false);
  const [editDetails, setEditDetails] = useState(false);

  const Inactives = useSelector(selectInactiveDriver);
  const loader = useSelector(selectLoading);
  const dispatch = useDispatch();
  const { driversId, currentComp } = useSelector((state) => state.driverInfo);
  const navigate = useNavigate();
  const currentSection = "InactiveDriver";

  const handleImEyeClick = (id) => {
    console.log("checking inactive driver id", id);
    dispatch(currentDriverId(id));
    setShowDetails(true);
  };

  const handleEditIcon = (id) => {
    dispatch(currentDriverId(id));
    setEditDetails(true);
  };

  console.log(loader);
  useEffect(() => {
    dispatch(fetchInactive());
  }, [dispatch]);

  console.log(Inactives);

  const handleActiveDriver = (driverId) => {
    console.log("Driver id ", driverId);
    
    try {
      const updatedStatus = "Active";
      const response = axios.put(
       `${API_BASE_URL}/v1/admin/drivers/${driverId}/status`,
        { status: "Active" }
      );
      console.log(response);
      alert("Driver status set to active");
      dispatch(toggleDriverStatus(driverId, updatedStatus));
      dispatch(fetchInactive());
    } catch (error) {
      console.error("Error toggling driver status: ", error);
    }
  };
  const NoRating = () => <span>No rating available</span>;

  const StarRating = ({ rating }) => {
    const starSvgs = {
      1: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="100"
          height="16"
          viewBox="0 0 100 16"
          fill="none"
        >
          <path
            d="M10 0.21875L12.2451 5.59306H19.5106L13.6327 8.91457L15.8779 14.2889L10 10.9674L4.12215 14.2889L6.36729 8.91457L0.489435 5.59306H7.75486L10 0.21875Z"
            fill="#FF0000"
          />
          <path
            d="M30 0.21875L32.2451 5.59306H39.5106L33.6327 8.91457L35.8779 14.2889L30 10.9674L24.1221 14.2889L26.3673 8.91457L20.4894 5.59306H27.7549L30 0.21875Z"
            fill="#8B8B8B"
          />
          <path
            d="M50 0.21875L52.2451 5.59306H59.5106L53.6327 8.91457L55.8779 14.2889L50 10.9674L44.1221 14.2889L46.3673 8.91457L40.4894 5.59306H47.7549L50 0.21875Z"
            fill="#8B8B8B"
          />
          <path
            d="M70 0.21875L72.2451 5.59306H79.5106L73.6327 8.91457L75.8779 14.2889L70 10.9674L64.1221 14.2889L66.3673 8.91457L60.4894 5.59306H67.7549L70 0.21875Z"
            fill="#8B8B8B"
          />
          <path
            d="M90 0.21875L92.2451 5.59306H99.5106L93.6327 8.91457L95.8779 14.2889L90 10.9674L84.1221 14.2889L86.3673 8.91457L80.4894 5.59306H87.7549L90 0.21875Z"
            fill="#8B8B8B"
          />
        </svg>
      ),
      2: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="100"
          height="17"
          viewBox="0 0 100 17"
          fill="none"
        >
          <path
            d="M10 0.664062L12.2451 6.03837H19.5106L13.6327 9.35988L15.8779 14.7342L10 11.4127L4.12215 14.7342L6.36729 9.35988L0.489435 6.03837H7.75486L10 0.664062Z"
            fill="#FF8C00"
          />
          <path
            d="M30 0.664062L32.2451 6.03837H39.5106L33.6327 9.35988L35.8779 14.7342L30 11.4127L24.1221 14.7342L26.3673 9.35988L20.4894 6.03837H27.7549L30 0.664062Z"
            fill="#FF8C00"
          />
          <path
            d="M50 0.664062L52.2451 6.03837H59.5106L53.6327 9.35988L55.8779 14.7342L50 11.4127L44.1221 14.7342L46.3673 9.35988L40.4894 6.03837H47.7549L50 0.664062Z"
            fill="#8B8B8B"
          />
          <path
            d="M70 0.664062L72.2451 6.03837H79.5106L73.6327 9.35988L75.8779 14.7342L70 11.4127L64.1221 14.7342L66.3673 9.35988L60.4894 6.03837H67.7549L70 0.664062Z"
            fill="#8B8B8B"
          />
          <path
            d="M90 0.664062L92.2451 6.03837H99.5106L93.6327 9.35988L95.8779 14.7342L90 11.4127L84.1221 14.7342L86.3673 9.35988L80.4894 6.03837H87.7549L90 0.664062Z"
            fill="#8B8B8B"
          />
        </svg>
      ),
      3: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="100"
          height="16"
          viewBox="0 0 100 16"
          fill="none"
        >
          <path
            d="M10 0.109375L12.2451 5.48369H19.5106L13.6327 8.80519L15.8779 14.1795L10 10.858L4.12215 14.1795L6.36729 8.80519L0.489435 5.48369H7.75486L10 0.109375Z"
            fill="#E3D96F"
          />
          <path
            d="M30 0.109375L32.2451 5.48369H39.5106L33.6327 8.80519L35.8779 14.1795L30 10.858L24.1221 14.1795L26.3673 8.80519L20.4894 5.48369H27.7549L30 0.109375Z"
            fill="#E3D96F"
          />
          <path
            d="M50 0.109375L52.2451 5.48369H59.5106L53.6327 8.80519L55.8779 14.1795L50 10.858L44.1221 14.1795L46.3673 8.80519L40.4894 5.48369H47.7549L50 0.109375Z"
            fill="#E3D96F"
          />
          <path
            d="M70 0.109375L72.2451 5.48369H79.5106L73.6327 8.80519L75.8779 14.1795L70 10.858L64.1221 14.1795L66.3673 8.80519L60.4894 5.48369H67.7549L70 0.109375Z"
            fill="#8B8B8B"
          />
          <path
            d="M90 0.109375L92.2451 5.48369H99.5106L93.6327 8.80519L95.8779 14.1795L90 10.858L84.1221 14.1795L86.3673 8.80519L80.4894 5.48369H87.7549L90 0.109375Z"
            fill="#8B8B8B"
          />
        </svg>
      ),
      4: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="100"
          height="17"
          viewBox="0 0 100 17"
          fill="none"
        >
          <path
            d="M10 0.554688L12.2451 5.929H19.5106L13.6327 9.25051L15.8779 14.6248L10 11.3033L4.12215 14.6248L6.36729 9.25051L0.489435 5.929H7.75486L10 0.554688Z"
            fill="#CFD72A"
          />
          <path
            d="M30 0.554688L32.2451 5.929H39.5106L33.6327 9.25051L35.8779 14.6248L30 11.3033L24.1221 14.6248L26.3673 9.25051L20.4894 5.929H27.7549L30 0.554688Z"
            fill="#CFD72A"
          />
          <path
            d="M50 0.554688L52.2451 5.929H59.5106L53.6327 9.25051L55.8779 14.6248L50 11.3033L44.1221 14.6248L46.3673 9.25051L40.4894 5.929H47.7549L50 0.554688Z"
            fill="#CFD72A"
          />
          <path
            d="M70 0.554688L72.2451 5.929H79.5106L73.6327 9.25051L75.8779 14.6248L70 11.3033L64.1221 14.6248L66.3673 9.25051L60.4894 5.929H67.7549L70 0.554688Z"
            fill="#CFD72A"
          />
          <path
            d="M90 0.554688L92.2451 5.929H99.5106L93.6327 9.25051L95.8779 14.6248L90 11.3033L84.1221 14.6248L86.3673 9.25051L80.4894 5.929H87.7549L90 0.554688Z"
            fill="#8B8B8B"
          />
        </svg>
      ),
      5: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="100"
          height="16"
          viewBox="0 0 100 16"
          fill="none"
        >
          <path
            d="M10 0L12.2451 5.37431H19.5106L13.6327 8.69582L15.8779 14.0701L10 10.7486L4.12215 14.0701L6.36729 8.69582L0.489435 5.37431H7.75486L10 0Z"
            fill="#38B000"
          />
          <path
            d="M30 0L32.2451 5.37431H39.5106L33.6327 8.69582L35.8779 14.0701L30 10.7486L24.1221 14.0701L26.3673 8.69582L20.4894 5.37431H27.7549L30 0Z"
            fill="#38B000"
          />
          <path
            d="M50 0L52.2451 5.37431H59.5106L53.6327 8.69582L55.8779 14.0701L50 10.7486L44.1221 14.0701L46.3673 8.69582L40.4894 5.37431H47.7549L50 0Z"
            fill="#38B000"
          />
          <path
            d="M70 0L72.2451 5.37431H79.5106L73.6327 8.69582L75.8779 14.0701L70 10.7486L64.1221 14.0701L66.3673 8.69582L60.4894 5.37431H67.7549L70 0Z"
            fill="#38B000"
          />
          <path
            d="M90 0L92.2451 5.37431H99.5106L93.6327 8.69582L95.8779 14.0701L90 10.7486L84.1221 14.0701L86.3673 8.69582L80.4894 5.37431H87.7549L90 0Z"
            fill="#38B000"
          />
        </svg>
      ),
    };

    return rating ? starSvgs[rating] || NoRating() : NoRating();
  };

  const logState = async (state, driverId) => {
    console.log("Toggled:", state);
    // setToggleState(state);
    console.log(driverId);
    state === false && handleActiveDriver(driverId);
    // ? handleActiveDriver(driverId)
    // : handleInactiveDriver(driverId);
  };

  const headers = [
    "Sr no.",
    "Location",
    "Driver Name",
    "Driver Mobile No.",
    "No. of Ride",
    "Rating",
    "Registered On",
    "Verified On",
    "Status",
    "Reason",
    "Action",
  ];
  const filteredDrivers = (Inactives || []).filter((Inactive) => {
    const selectedRating = parseInt(selectedApprovel, 10);
    return (
      (!selectedStatus || Inactive.status?.state === selectedStatus) &&
      (!selectedLocation || Inactive.location === selectedLocation) &&
      (!searchName ||
        Inactive.name?.toLowerCase().includes(searchName.toLowerCase())) &&
      (!searchPhoneNumber || Inactive.phone?.includes(searchPhoneNumber)) &&
      (!selectedRating || Inactive.rating === selectedRating)
    );
  });

  const data = [];

  for (let i = 0; i < filteredDrivers.length; i++) {
    const Inactive = filteredDrivers[i];

    data.push({
      "Sr no.": i + 1,
      Location: `${Inactive.location?.state || "Unknown"} ${
        Inactive.location?.city || "Unknown"
      }`,
      "Driver Name": `${Inactive.firstName || "N/A"} ${
        Inactive.lastName || ""
      }`,
      "Driver Mobile No.": Inactive.phone?.startsWith("+91-")
        ? Inactive.phone
        : `+91- ${Inactive.phone || "N/A"}`,
      "No. of Ride": Inactive.noOfRides || 0,
      Rating: <StarRating rating={Inactive.rating} />,
      "Registered On": Inactive.createdAt || "Unknown",
      "Verified On": "22-10-2023",
      "Approval Status": Inactive.approval?.status || "Pending",
      Status: Inactive.status?.state || "Unknown",
      Reason: Inactive.status?.reason || "No reason provided",
      Action: (
        <div className="box-action">
          <Toggle
            toggled={true}
            onClick={(state) => {
              console.log("Driver id passed : ", Inactives._id);
              logState(state, Inactive._id);
            }}
          />
          <ImEye
            style={{marginLeft:"20px"}}
            className="icon icon-large"
            onClick={() => handleImEyeClick(Inactive._id)}
          />
          <FaPen
            className="icon-edit "
            onClick={() => handleEditIcon(Inactive._id)}
          />
        </div>
      ),
    });
  }
  const DriverList = data;

  useEffect(() => {
    const extractlocationAsOptions = (dataArray) => {
      if (Array.isArray(dataArray.data)) {
        const allLocationsOption = { label: "Select a location", value: "" };
        const locationOptions = dataArray.data.map((item) => ({
          label: item.location,
          value: item._id,
        }));
        return [allLocationsOption, ...locationOptions];
      }
      return [];
    };

    const fetchOptions = async () => {
      try {
        const locationRespons = await fetch(
          `${API_BASE_URL}/v1/locations`
        );
        if (!locationRespons.ok) {
          throw new Error(`HTTP error! Status: ${locationRespons.status}`);
        }
        const locationData = await locationRespons.json();
        const locationOptions = extractlocationAsOptions(locationData);
        console.log(locationOptions);
        setLocationOptions(locationOptions);
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };
    fetchOptions();
  }, []);

  useEffect(() => {
    if (driversId !== null && showDetails) {
      dispatch(resetDriverDetails());
      dispatch(fetchDriversDetails(driversId));
      navigate(
        `/drivermanagement/${currentSection}/driverDetails/${driversId}`
      );
    }

    setShowDetails(false);

    console.log("checking Inactive drivers id in effect", driversId);
  }, [showDetails]);

  useEffect(() => {
    if (driversId !== null && editDetails) {
      dispatch(resetDriverDetails());
      navigate(
        `/drivermanagement/${currentSection}/modify-details/${driversId}`
      );
    }
    setShowDetails(false);
    console.log("checking drivres id in effect", driversId);
  }, [editDetails]);

  return (
    <div>
      <>
        <div className="row">
          <div className="dropdown-container">
            <div className="main-1">
              <div>Filters</div>
              <div className="icon-1">
                <CiFilter color="#38b000" size={18} />
              </div>
            </div>
            <CustomDropdown
              placeholder={placeholder}
              options={locationOptions}
              selectedValue={selectedLocation}
              // handleChange={(e) => setSelectedLocation(e.target.value)}
              handleChange={(e) => {
                const selectedOption = locationOptions.find(
                  (option) => option.value === e.target.value
                );

                if (selectedOption) {
                  const selectedCity = selectedOption.label;
                  console.log("Selected City:", selectedCity);
                  // setSelectedLocation(selectedCity);
                  // setPlaceholder(selectedCity);
                  if (selectedCity === "Select a location") {
                    // If "Select a location" is selected, set an empty string to show all locations
                    setSelectedLocation("");
                    setPlaceholder("Select a location");
                  } else {
                    setSelectedLocation(selectedCity);
                    setPlaceholder(selectedCity);
                  }
                } else {
                  setPlaceholder("Select a location");
                }
              }}
              className="dropdown"
            />
            <InputColumn
              title="Search by Name"
              selectedValue={searchName}
              handleChange={(e) => setsearchName(e.target.value)}
            />
            <InputColumn
              title="Search by Mobile No."
              selectedValue={searchPhoneNumber}
              handleChange={(e) => setsearchPhoneNumber(e.target.value)}
            />
            <CustomDropdown
              placeholder="Status"
              options={[
                { label: "Active", value: "Active" },
                { label: "Inactive", value: "Inactive" },
              ]}
              selectedValue={selectedStatus}
              handleChange={(e) => setSelectedStatus(e.target.value)}
              className="dropdown"
            />
            <CustomDropdown
              placeholder="Rating"
              options={[
                { label: "1 Star", value: "1" },
                { label: "2 Stars", value: "2" },
                { label: "3 Stars", value: "3" },
                { label: "4 Stars", value: "4" },
                { label: "5 Stars", value: "5" },
              ]}
              selectedValue={selectedApprovel}
              handleChange={(e) => setselectedApprovel(e.target.value)}
              className="dropdown"
            />
          </div>
        </div>
        <Table
          headers={headers}
          data={DriverList.length > 0 ? DriverList : []}
        />
      </>
    </div>
  );
};

export default ViewAllCar;
