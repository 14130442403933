import React, { useState } from 'react'
import Header from '../header2/Header-nav'
import NavContent from './NavContent';


const CustomerBookingDetails = () => {
  const [bookingDetailsCondition, setbookingDetailsCondition] = useState(true);
  const styleFromCustomerBookingDetails = {
    width: "1265px"
  }
  return (
    <div>
      <NavContent />
      <Header bookingDetailsCondition={bookingDetailsCondition} styleFromCustomerBookingDetails={styleFromCustomerBookingDetails} />
    </div>
  )
}

export default CustomerBookingDetails
  