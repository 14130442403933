// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.route-log-table {
    width: 100%;
    border-collapse: collapse;
}

.route-log-table td {
    border: 1px solid #000;
    /* padding: 10px; */
   font-size: 13px;
   font-weight: 400;
    text-align: left;
    border-radius: none;
    text-align: center;
    white-space: nowrap;
    /* padding-bottom: 10px; */
}

.route-log-table th {
    background: #38b000;
    color: #ffffff;
    font-size: 12px;
    border: 1px solid #000;
    /* padding: 10px; */
    text-align: left;
    border-radius: 0;
    text-align: center;

}

.route-log-table tbody tr {
    height: 60px;
   
    
  
}


.map-box {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.map {
    width: 430px;
    height: 514px;
    flex-shrink: 0;
}

.main-box-route {
    height: 780px;
    flex-shrink: 0;
    border-radius: 10px;
    background: #fff;
    margin-top: 8px;
    padding:18px  0;
}

.top-extra-space {
    /* height: 4vh; */
    background: #fff;
}


.map-container {
    width: 100%;
    height: 400px;
    margin: 0px 20px;
    
    border: 2px solid #ddd;
    border-radius: 10px;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/Details/RouteLog.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,yBAAyB;AAC7B;;AAEA;IACI,sBAAsB;IACtB,mBAAmB;GACpB,eAAe;GACf,gBAAgB;IACf,gBAAgB;IAChB,mBAAmB;IACnB,kBAAkB;IAClB,mBAAmB;IACnB,0BAA0B;AAC9B;;AAEA;IACI,mBAAmB;IACnB,cAAc;IACd,eAAe;IACf,sBAAsB;IACtB,mBAAmB;IACnB,gBAAgB;IAChB,gBAAgB;IAChB,kBAAkB;;AAEtB;;AAEA;IACI,YAAY;;;;AAIhB;;;AAGA;IACI,WAAW;IACX,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,cAAc;IACd,mBAAmB;IACnB,gBAAgB;IAChB,eAAe;IACf,eAAe;AACnB;;AAEA;IACI,iBAAiB;IACjB,gBAAgB;AACpB;;;AAGA;IACI,WAAW;IACX,aAAa;IACb,gBAAgB;;IAEhB,sBAAsB;IACtB,mBAAmB;EACrB","sourcesContent":[".route-log-table {\n    width: 100%;\n    border-collapse: collapse;\n}\n\n.route-log-table td {\n    border: 1px solid #000;\n    /* padding: 10px; */\n   font-size: 13px;\n   font-weight: 400;\n    text-align: left;\n    border-radius: none;\n    text-align: center;\n    white-space: nowrap;\n    /* padding-bottom: 10px; */\n}\n\n.route-log-table th {\n    background: #38b000;\n    color: #ffffff;\n    font-size: 12px;\n    border: 1px solid #000;\n    /* padding: 10px; */\n    text-align: left;\n    border-radius: 0;\n    text-align: center;\n\n}\n\n.route-log-table tbody tr {\n    height: 60px;\n   \n    \n  \n}\n\n\n.map-box {\n    width: 100%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    margin-top: 20px;\n}\n\n.map {\n    width: 430px;\n    height: 514px;\n    flex-shrink: 0;\n}\n\n.main-box-route {\n    height: 780px;\n    flex-shrink: 0;\n    border-radius: 10px;\n    background: #fff;\n    margin-top: 8px;\n    padding:18px  0;\n}\n\n.top-extra-space {\n    /* height: 4vh; */\n    background: #fff;\n}\n\n\n.map-container {\n    width: 100%;\n    height: 400px;\n    margin: 0px 20px;\n    \n    border: 2px solid #ddd;\n    border-radius: 10px;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
