import React, { useState, useEffect } from "react";
import "./Earning.css";
import FirstTable from "./table/FirstTable";
import SecondTable from "./table/SecondTable";
import ThirdTable from "./table/ThirdTable";

import { FaLongArrowAltRight } from "react-icons/fa";
import { Link } from "react-router-dom"; 

const EarningReport = () => {
  const [previousLocation, setPreviousLocation] = useState();
  const checkParentLocation = (path) => {
    if (path.includes("InactiveDriver")) {
      setPreviousLocation("Inactive Drivers");
    } else if (path.includes("ApprovalPendingDriver")) {
      setPreviousLocation("Approval Pending");
    } else {
      setPreviousLocation("View All Drivers");
    }
  };

  useEffect(() => {
    const checkingLocation = window.location.pathname;
    console.log(
      "checking locattion",
      checkingLocation.includes("ViewAllDriver")
    );
    checkParentLocation(window.location.pathname);
  }, []);

  return (
    <div className="earning_container">
      <div className="header-text">
        <Link className="h3-booking">Driver Management</Link>
        <FaLongArrowAltRight />
        {""}
        <Link className="h3-booking">{previousLocation}</Link>
        <FaLongArrowAltRight />
        <Link className="h3-booking">Driver Details</Link>
        <FaLongArrowAltRight />
        <Link className="h2-booking">Earning Reports</Link>
      </div>

      <div className="earning_and_report">Earning & Payment Report</div>
      <div className="first_table">
        <FirstTable />
      </div>
      <div className="second_table">
        <SecondTable />
      </div>

      <div className="third_table">
        <ThirdTable />
      </div>
    </div>
  );
};

export default EarningReport;
