import React from 'react'
import './secondtable.css'

const TableItem = ({ item, index }) => {
    
    return (
      <tr className="second_Earning_table_data">
        <td>{item[" "]}</td>
        <td>{item["Amount in Rs ."]}</td>
       
        
      
      </tr>
    );
  };

const Table = ({ headers, data }) => {
    return (
      <div className="second_table_containers">
        <div className="second_table_scroll">
          <table className="second_table">
            <thead>
              <tr>
                {headers.map((header, index) => (
                  <th style={{borderRadius:"0"}}key={index}>{header}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => (
                <TableItem key={index} item={item} index={index} />
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  };

const SecondTable = () => {

    const headers =[
        " ",
        "Amount in Rs .",
      

    ];
    const data=[
        {
            " ":"Opening Balance as on 01-01-2024",
            "Amount in Rs .":"10",
           
        },
        {
            " ":"Total Business",
            "Amount in Rs .":"10",
       
        },
        {
            " ":"Total Driver Earning",
            "Amount in Rs .":"10",
        
        },
        {
            " ":"Total Amount Received in Cash",
            "Amount in Rs .":"10",
           
        },
        {
            " ":"Payment Pending",
            "Amount in Rs .":"10",
          
        },
        
    ];

  return (
    <Table headers={headers} data={data}/>
  )
}

export default SecondTable
