import { useState } from "react";
import Header from "../components/header/Header";
import Navbar from "../components/navbar/Navbar";
import { Link, useNavigate } from "react-router-dom";
import CreateNewBooking from "../components/Booking/createNewBooking";
import { FaLongArrowAltRight } from "react-icons/fa";
import { selectNewCreateBookingPathValue } from "../components/Booking/BookingSlice";
import { useSelector } from "react-redux";

const CreateNewBooking1 = () => {
  const [sidebar, setSidebar] = useState(false);
  const pathtogopreviousbookingpage=useSelector(selectNewCreateBookingPathValue)

  const navigates = useNavigate();

  // Function to handle item click
  const handleItemClick = (itemName) => {
    // Perform actions based on the clicked item
    console.log(`Clicked on item: ${itemName}`);
    // Add further logic here, such as updating state or performing other operations
  };

  // Function to handle navigation
  const navigate = (to) => {
    // Perform navigation logic, e.g., using react-router-dom
    console.log(`Navigating to: ${to}`);
    navigates(to);
    // Implement navigation logic here, e.g., using history.push() or Link components
  };
  const handleBookingPreviousPage=()=>{
    navigate(pathtogopreviousbookingpage);
  }
  return (
    <div className="App">
      <Header setSidebar={setSidebar} sidebar={sidebar} />
      <div className="navbar-adminInfo">
        <Navbar
          sidebar={sidebar}
          handleItemClick={handleItemClick}
          navigate={navigate}
        />
        <div style={{display:"flex",flexDirection:"column",padding:"15px 0px"}}>
        <div className="booking">
        <div className="header">
          <div className="header-text">
            <Link to="/dashboard" className="h3-booking">
              B2C Booking
            </Link>{" "}
            <FaLongArrowAltRight />{" "}
            <Link to="/bookings" className="h2-booking">
             Create New Booking
            </Link>
          </div>
          <div className="btns">
            <button
             className="createButton"
              onClick={() => handleBookingPreviousPage()}
            >
             Booking Details
            </button>
          
          
          </div>
        </div>
      </div>

        <CreateNewBooking/>
        </div>
        
      </div>
    </div>
  );
};
export default CreateNewBooking1;
