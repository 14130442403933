// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.btn-3 {
  display: flex;
  justify-content: flex-start;
  padding-top: 5px;
  padding-bottom: 6px;
}
.main-btn {
  width: 11.8%;
  cursor: pointer;
  font-size: 0.8vw;
  font-family: inter;
  font-weight: 700;
}
.main-btn svg {
  width: 100%;
}

.main-btn.active svg path  {
  background-color: #38B000; 
  stroke: #000;
}

.main-btn svg path {
  transition: fill 0.3s ease;
}
.main-btn.active text {
  fill: #fff;
}`, "",{"version":3,"sources":["webpack://./src/components/Buttons/Clip.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,2BAA2B;EAC3B,gBAAgB;EAChB,mBAAmB;AACrB;AACA;EACE,YAAY;EACZ,eAAe;EACf,gBAAgB;EAChB,kBAAkB;EAClB,gBAAgB;AAClB;AACA;EACE,WAAW;AACb;;AAEA;EACE,yBAAyB;EACzB,YAAY;AACd;;AAEA;EACE,0BAA0B;AAC5B;AACA;EACE,UAAU;AACZ","sourcesContent":[".btn-3 {\n  display: flex;\n  justify-content: flex-start;\n  padding-top: 5px;\n  padding-bottom: 6px;\n}\n.main-btn {\n  width: 11.8%;\n  cursor: pointer;\n  font-size: 0.8vw;\n  font-family: inter;\n  font-weight: 700;\n}\n.main-btn svg {\n  width: 100%;\n}\n\n.main-btn.active svg path  {\n  background-color: #38B000; \n  stroke: #000;\n}\n\n.main-btn svg path {\n  transition: fill 0.3s ease;\n}\n.main-btn.active text {\n  fill: #fff;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
