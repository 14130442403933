import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import axios from 'axios'

const initialState = {
    tariffId:null,
    tariffDetails:null,
    loading:"idle",
    errorMessage:null
}

export const fetchTariffDetails = createAsyncThunk('/fetchTariffDetails',async(tariffId)=>{

        const response = await axios.get(`https://api.yatricabs.com/v1/admin/tariffs/${tariffId}`);
        console.log("checking tariff details",response.data.data);

        return response.data.data
})

const singletariffDetailSlice = createSlice({
    name:'tariffDetails',
    initialState,
    reducers:{
        currentTariffId:(state,action)=>{
            state.tariffId = action.payload;
        },
        resetTariffDetails:(state)=>{
            state.tariffDetails = null;
        }
    },
    extraReducers: (builder)=>{
        builder.addCase(fetchTariffDetails.pending,(state)=>{
            state.loading = 'Loading';
        })
        .addCase(fetchTariffDetails.fulfilled,(state,action)=>{
            state.loading = 'Idle'
            state.tariffDetails = action.payload;
            console.log(action.payload)
        })
        .addCase(fetchTariffDetails.rejected,(state,action)=>{
            state.loading = 'Rejected'
            state.errorMessage = action.error.message;
        })
    }
})
export const {currentTariffId,resetTariffDetails} = singletariffDetailSlice.actions;
export default singletariffDetailSlice.reducer;