import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import "./CreateNewTariffPage.css";
// import DropdownColumn from "../Booking/Dropdown";

import { DropdownColumnForTarrif } from "../Booking/Dropdown";
import {
  addLocalTariffFun,
  addLocalAirportTariffFun,
  addOutstationTariffFun,
} from "./Action";
import axios from "axios";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const InputColumn = ({
  title,
  selectedValue,
  handleChange,
  position,
  text,
}) => {
  return (
    <div className="dropdown-column">
      <span className="title">{title}</span>

      <div
        style={{
          display: "flex",
          flexDirection: position === "left" ? "row-reverse" : "row",
          justifyContent: "space-between",
          alignItems: "center",
          width: "200px",
          borderRadius: "5px",
          border: "none",
          boxShadow: " 0px 4px 22px 0px rgba(0, 0, 0, 0.17)",
        }}
      >
        <input
          style={{
            border: "2px",
            width: "150px",
            boxShadow: "none",
          }}
          type="text"
          value={selectedValue}
          onChange={handleChange}
          className="input-field"
        />
        <div
          style={{
            fontSize: "11px",
            fontWeight: "500",
            padding: "0 6px",
          }}
        >
          {text}
        </div>
      </div>
    </div>
  );
};

const CreateNewTariffPage = () => {
  const [tripType, setTripType] = useState("Local");
  const [subTripType, setSubTripType] = useState("");
  const [selectedLocation, setSelectedLocation] = useState("");
  const [selectedCarCategory, setSelectedCarCategory] = useState("");
  const [selectedDistance, setSelectedDistance] = useState("");
  const [selectedDays, setSelectedDays] = useState("");
  const [selectedFuel, setSelectedFuel] = useState("");
  const [selectedBaseFare, setSelectedBaseFare] = useState("");
  const [selectedMinKm, setSelectedMinKm] = useState("");
  const [selectedMinHours, setSelectedMinHours] = useState("");
  const [selectedExtraKm, setSelectedExtraKm] = useState("");
  const [selectedExtraHours, setSelectedExtraHours] = useState("");
  const [selectedGst, setSelectedGst] = useState("");
  const [selectedExtraMorBata, setSelectedExtraMorBata] = useState(0);
  const [selectedNightBata, setSelectedNightBata] = useState(0);
  const [selectedDayBata, setSelectedDayBata] = useState(0);
  const [selectedPerKmCharge, setSelectedPerKmCharge] = useState("");
  const [selectedExtraKmCharge, setSelectedExtraKmCharge] = useState("");
  const [selectedNightCharge, setSelectedNightCharge] = useState(0);
  const [selectedDayDriverAllowance, setSelectedDayDriverAllowance] =
    useState(0);

  const [tripTypes, setTripTypes] = useState([]);
  const [subTripTypeOptions, setSubTripTypeOptions] = useState([]);
  const [isTarrifExist,setIsTarrifExist]=useState(false);

  console.log(tripType);

  const [locationOptions, setLocationOptions] = useState([]);
  const [carOptions, setCarOptions] = useState([]);
  const [daysOptions, setDayOptions] = useState([]);
  const [distanceOptions, setDistanceOptions] = useState([]);
  const [fuelOptions, setFuelOptions] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    const checkTariff = async () => {
      // console.log("Location Id", selectedLocation);
      // console.log("Car Category Id", selectedCarCategory);
      // console.log("Trip Type", tripType);
      // console.log("SubTripType", subTripType);
      // console.log("Fuel Type", selectedFuel);
  
      if (
        selectedLocation &&
        selectedCarCategory &&
        tripType &&
        subTripType &&
        selectedFuel
      ) {
        try {
          const response = await axios.post(
            `${API_BASE_URL}/v1/admin/tariffs/check-tariff`,
            {
              locationId: selectedLocation,
              carCategoryId: selectedCarCategory,
              tripType,
              subTripType,
              fuelType: selectedFuel,
            }
          );
          setIsTarrifExist(false)
          console.log("Tariff check response", response.data);
          // toast.success("Tarrif do not exist", {
          //   position: "top-center",
          // });
        } catch (e) {
          console.error("Error", e);
          setIsTarrifExist(true);
          // toast.error(e?.response?.data?.message || "Something went wrong", {
          //   position: "top-center",
          // });
        }
      }
    };

    checkTariff();
  }, [selectedFuel]);


  const handleSubmit = (e) => {
    e.preventDefault();
    if (tripType === "Airport") {
      console.log("airport");
      const airportObj = {
        locationId: selectedLocation,
        carCategoryId: selectedCarCategory,
        tripType: tripType,
        subTripType: subTripType,
        distance: selectedDistance,
        fuelType: selectedFuel,
        days: selectedDays,
        morningDriverAllowance: selectedExtraMorBata,
        nightDriverAllowance: selectedNightBata,
        dayDriverAllowance: selectedDayBata,
        extraKmCharge: selectedExtraKm,
        gstRate: selectedGst,
        baseFare: selectedBaseFare,
        minKm: selectedMinKm,
        minHrs: selectedMinHours,
        extraHrsCharge: selectedExtraHours,
      };

      dispatch(addLocalAirportTariffFun(airportObj));
      console.log(airportObj);
    } else if (tripType === "Local") {
      console.log("local tariff value");
      const localtariffObj = {
        locationId: selectedLocation,
        carCategoryId: selectedCarCategory,
        tripType: tripType,
        subTripType: subTripType,
        distance: selectedDistance,
        fuelType: selectedFuel,
        days: selectedDays,
        morningDriverAllowance: selectedExtraMorBata,
        nightDriverAllowance: selectedNightBata,
        dayDriverAllowance: selectedDayBata,
        extraKmCharge: selectedExtraKm,
        gstRate: selectedGst,
        baseFare: selectedBaseFare,
        minKm: selectedMinKm,
        minHrs: selectedMinHours,
        extraHrsCharge: selectedExtraHours,
      };

      console.log("Local Object", localtariffObj);

      dispatch(addLocalTariffFun(localtariffObj));
      console.log(localtariffObj);
    } else {
      console.log("outstation tarif value");
      const outstationtariffObj = {
        locationId: selectedLocation,
        carCategoryId: selectedCarCategory,
        tripType: tripType,
        subTripType: subTripType,
        distance: selectedDistance,
        fuelType: selectedFuel,
        days: selectedDays,
        morningDriverAllowance: selectedExtraMorBata,
        nightDriverAllowance: selectedNightCharge,
        dayDriverAllowance: selectedDayDriverAllowance,
        extraKmCharge: selectedExtraKmCharge,
        gstRate: selectedGst,
        minKmPerDay: selectedMinKm,
        perKmCharge: selectedPerKmCharge,
      };
      dispatch(addOutstationTariffFun(outstationtariffObj));
    }
  };

  // Fetch data for dropdown options
  useEffect(() => {
    // Function to convert data array to options array
    const extractCategoriesAsOptions = (dataArray) => {
      if (Array.isArray(dataArray.data)) {
        return dataArray.data.map((item) => ({
          label: item.category,
          value: item._id,
        }));
      }
      return [];
    };

    // Function to convert data array to options array
    const extractLocationsAsOptions = (dataArray) => {
      if (Array.isArray(dataArray.data)) {
        console.log(dataArray.data);
        return dataArray.data.map((item) => ({
          //label: item.city,
          label: item.location,
          value: item._id,
          // Add more properties as needed
        }));
      }
      return [];
    };

    // Function to convert data array to options array
    const extractFuelAsOptions = (dataArray) => {
      if (Array.isArray(dataArray.data)) {
        return dataArray.data.map((item) => ({
          label: item.fuel,
          value: item.fuel,
          // Add more properties as needed
        }));
      }
      return [];
    };

    // Function to convert data array to options array
    const extractDistanceAsOptions = (dataArray) => {
      if (Array.isArray(dataArray.data)) {
        return dataArray.data.map((item) => ({
          label: item.distance,
          value: item.distance,
          // Add more properties as needed
        }));
      }
      return [];
    };

    // Function to convert data array to options array
    const extractDayAsOptions = (dataArray) => {
      if (Array.isArray(dataArray.data)) {
        return dataArray.data.map((item) => ({
          label: item.day,
          value: item.day,
          // Add more properties as needed
        }));
      }
      return [];
    };

    const fetchOptions = async () => {
      try {
        // Fetching location options
        const locationResponse = await fetch(`${API_BASE_URL}/v1/locations`);
        const locationData = await locationResponse.json();

        console.log(locationData.data);
        // locationData = locationData.data
        const locationOptions = extractLocationsAsOptions(locationData);
        console.log(locationOptions);

        setLocationOptions(locationOptions);

        // Fetching car category options
        const carResponse = await fetch(`${API_BASE_URL}/v1/car-categories`);
        const carData = await carResponse.json();

        console.log(carData);
        // Convert car category object data into array format for dropdown options
        const carCategoriesOptions = extractCategoriesAsOptions(carData);

        console.log(carCategoriesOptions);

        // Set the car options state to use in your component
        setCarOptions(carCategoriesOptions);

        // Fetching Fuel options
        const fuelResponse = await fetch(`${API_BASE_URL}/v1/fuels`);
        const fuelData = await fuelResponse.json();
        console.log(fuelData);
        const fuelOptions = extractFuelAsOptions(fuelData);
        console.log("Fuel Options", fuelOptions);
        setFuelOptions(fuelOptions);

        // Fetching distance options
        const distanceResponse = await fetch(`${API_BASE_URL}/v1/distances`);
        const distanceData = await distanceResponse.json();
        console.log(distanceData);
        const distanceOptions = extractDistanceAsOptions(distanceData);
        console.log(distanceOptions);
        setDistanceOptions(distanceOptions);

        // Fetching day options
        const dayResponse = await fetch(`${API_BASE_URL}/v1/days`);
        const dayData = await dayResponse.json();
        console.log(dayData);
        const dayOptions = extractDayAsOptions(dayData);
        console.log(dayOptions);
        setDayOptions(dayOptions);
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };

    fetchOptions();
  }, []);

  useEffect(() => {
    // Function to convert data array to options array
    const extractTripTypesAsOptions = (dataArray) => {
      if (Array.isArray(dataArray.data)) {
        return dataArray.data.map((item) => ({
          label: item.tripType,
          value: item.tripType,
          // Add more properties as needed
        }));
      }
      return [];
    };
    const fetchTripTypes = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/v1/trip-types`);
        if (!response.ok) {
          throw new Error("Network response was not ok.");
        }
        const data = await response.json();
        console.log(data);
        const triptTypeOptions = extractTripTypesAsOptions(data);
        setTripTypes(triptTypeOptions); // Update trip types state with fetched data
      } catch (error) {
        console.error("Error fetching trip types:", error);
      }
    };

    fetchTripTypes();
  }, []);

  useEffect(() => {
    const extractSubTripTypesAsOptions = (dataArray) => {
      if (Array.isArray(dataArray.data)) {
        // console.log(typeof dataArray.data);
        // console.log(dataArray.data);
        // console.log(dataArray.data[0]);
        const opt = dataArray.data[0];
        console.log(opt);
        return opt.subTripTypes.map((subTripTypes) => ({
          label: subTripTypes,
          value: subTripTypes,
          // Add more properties as needed
        }));
      }
      return [];
    };
    const fetchData = async () => {
      try {
        if (tripType) {
          const response = await fetch(
            `${API_BASE_URL}/v1/sub-trip-types/${tripType}`
          );
          console.log(response);
          if (!response.ok) {
            throw new Error("Network response was not ok.");
          }
          const data = await response.json();
          console.log(data);
          const subTripTypeOptions = extractSubTripTypesAsOptions(data);
          setSubTripTypeOptions(subTripTypeOptions); // Update sub trip types based on the selected trip type
        }
      } catch (error) {
        console.error("Error fetching sub trip types:", error);
      }
    };

    fetchData();
  }, [tripType]);

  return (
    <div className="create-new-tariff-container">
      <form onSubmit={handleSubmit}>
        <div className="price-details">
          <div className="crete-new-sub-head">Add Price Details</div>
          <div className="row">
            <div>
              <div className="title">Location</div>
              <DropdownColumnForTarrif
                placeholder={"Select Location"}
                title="Location"
                options={locationOptions}
                selectedValue={selectedLocation}
                useInlineStyle={true}
                handleChange={(e) => setSelectedLocation(e.target.value)}
              />
            </div>

            <div>
              <div className="title">Trip Type</div>
              <DropdownColumnForTarrif
                placeholder={"Select Trip Type"}
                title="Trip Type"
                options={tripTypes}
                selectedValue={tripType}
                useInlineStyle={true}
                handleChange={(e) => setTripType(e.target.value)}
              />
            </div>
            <div>
              <div className="title">Sub Trip Type</div>
              <DropdownColumnForTarrif
                placeholder={"Select Sub Trip"}
                title="Sub Trip Type"
                options={subTripTypeOptions}
                selectedValue={subTripType}
                useInlineStyle={true}
                handleChange={(e) => {
                  setSubTripType(e.target.value);
                }}
              />
            </div>
            <div>
              <div className="title">Car Category</div>
              <DropdownColumnForTarrif
                placeholder={"Select Car Category"}
                title="Car Category"
                options={carOptions}
                selectedValue={selectedCarCategory}
                useInlineStyle={true}
                handleChange={(e) => setSelectedCarCategory(e.target.value)}
              />
            </div>
          </div>

          <div className="row">
            <div>
              <div className="title">Garage Distance Option</div>
              <DropdownColumnForTarrif
                placeholder={"Select Distance"}
                title="Distance"
                options={distanceOptions}
                selectedValue={selectedDistance}
                useInlineStyle={true}
                handleChange={(e) => setSelectedDistance(e.target.value)}
              />
            </div>

            <div>
              <div className="title">Fuel Type</div>
              <DropdownColumnForTarrif
                placeholder={"Select Fuel"}
                title="Fuel"
                options={fuelOptions}
                selectedValue={selectedFuel}
                useInlineStyle={true}
                handleChange={(e) => setSelectedFuel(e.target.value)}
              />
            </div>

            <div>
              <div className="title">Days</div>
              <DropdownColumnForTarrif
                placeholder={"Select Days"}
                title="Days"
                options={daysOptions}
                selectedValue={selectedDays}
                useInlineStyle={true}
                handleChange={(e) => setSelectedDays(e.target.value)}
              />
            </div>
          </div>

          <div style={{
            textAlign:"center",
            color:"red",
            fontSize:"13px"
          }}>
            {
              isTarrifExist&&<div>
                Tarrif already exist
              </div>
            }
          </div>
        </div>

        {(tripType === "Local" || tripType === "Airport") && (
          <div className="price-slab" style={{ padding: "20px 0px" }}>
            <div className="crete-new-sub-head">Pricing Slab</div>
            <div className="row">
              <div>
                <InputColumn
                  position="left"
                  text="Rs."
                  title="Base Fare"
                  selectedValue={selectedBaseFare}
                  handleChange={(e) => setSelectedBaseFare(e.target.value)}
                />
              </div>

              <InputColumn
                title="Min Km"
                position="right"
                text="Km"
                selectedValue={selectedMinKm}
                handleChange={(e) => setSelectedMinKm(e.target.value)}
              />

              <InputColumn
                title="Min Hours"
                position="right"
                text="Hrs"
                selectedValue={selectedMinHours}
                handleChange={(e) => setSelectedMinHours(e.target.value)}
              />
            </div>

            <div
              style={{
                margin: "50px 0",
              }}
              className="row"
            >
              <InputColumn
                title="Extra KM"
                position="right"
                text="Rs./Km"
                selectedValue={selectedExtraKm}
                handleChange={(e) => setSelectedExtraKm(e.target.value)}
              />

              <InputColumn
                title="Extra Hours"
                position="right"
                text="Rs./Hrs"
                selectedValue={selectedExtraHours}
                handleChange={(e) => setSelectedExtraHours(e.target.value)}
              />

              <InputColumn
                title="GST"
                position="right"
                text="%"
                selectedValue={selectedGst}
                handleChange={(e) => setSelectedGst(e.target.value)}
              />
            </div>
          </div>
        )}

        {(tripType === "Local" || tripType === "Airport") && (
          <div className="driver-allowance" style={{}}>
            <div className="crete-new-sub-head">Driver Allowance</div>

            <div className="row">
              <InputColumn
                position="right"
                text="Rs./Day"
                title="Morning Charge"
                selectedValue={selectedExtraMorBata}
                handleChange={(e) => setSelectedExtraMorBata(e.target.value)}
              />

              <InputColumn
                position="right"
                text="Rs./Day"
                title="Night Charge"
                selectedValue={selectedNightBata}
                handleChange={(e) => setSelectedNightBata(e.target.value)}
              />

              {/* <InputColumn
			   position="right"
			  text="Rs./Day"
                title="Day Bata"
                selectedValue={selectedDayBata}
                handleChange={(e) => setSelectedDayBata(e.target.value)}
              /> */}
            </div>
          </div>
        )}

        {tripType === "Outstation" && (
          <div>
            <div className="price-slab" style={{ height: "150px" }}>
              <div className="crete-new-sub-head">Pricing Slab</div>
              <div className="row">
                <InputColumn
                  position="right"
                  text="Km"
                  title="Min Km/day"
                  selectedValue={selectedMinKm}
                  handleChange={(e) => setSelectedMinKm(e.target.value)}
                />

                <InputColumn
                  position="right"
                  text="Rs./Km"
                  title="Per Km Charge"
                  selectedValue={selectedPerKmCharge}
                  handleChange={(e) => setSelectedPerKmCharge(e.target.value)}
                />

                <InputColumn
                  position="right"
                  text="Rs./Km"
                  title="Extra KM Charge"
                  selectedValue={selectedExtraKmCharge}
                  handleChange={(e) => setSelectedExtraKmCharge(e.target.value)}
                />

                <InputColumn
                  title="GST"
                  position="right"
                  text="%"
                  selectedValue={selectedGst}
                  handleChange={(e) => setSelectedGst(e.target.value)}
                />
              </div>
            </div>

            <div className="driver-allowance" style={{ height: "150px" }}>
              <div className="crete-new-sub-head">Driver Allowance</div>
              <div className="row">
                <InputColumn
                  position="right"
                  text="Rs./Day"
                  title={
                    subTripType === "Round Trip"
                      ? "Morning Allowance"
                      : "Morning Charge"
                  }
                  selectedValue={selectedExtraMorBata}
                  handleChange={(e) => setSelectedExtraMorBata(e.target.value)}
                />

                {subTripType === "One Way" && (
                  <InputColumn
                    position="right"
                    text="Rs./Day"
                    title="Night Charge"
                    selectedValue={selectedNightCharge}
                    handleChange={(e) => setSelectedNightCharge(e.target.value)}
                  />
                )}

                {subTripType === "Round Trip" && (
                  <InputColumn
                    position="right"
                    text="Rs./Day"
                    title="Driver Allowance"
                    selectedValue={selectedDayDriverAllowance}
                    handleChange={(e) =>
                      setSelectedDayDriverAllowance(e.target.value)
                    }
                  />
                )}
              </div>
            </div>
          </div>
        )}

        <div className="add" style={{ marginTop: "50px" }}>
          <button className="add-btn">Add</button>
        </div>
      </form>

      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
};

export default CreateNewTariffPage;
