import React from 'react'
import './Header.css'
import { selectbookingDetails } from '../Details/DetailsSlice';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
const NavbarContent = () => {
    const navigate=useNavigate();
    const bookingDetails = useSelector(selectbookingDetails);
    const { id } = useParams();
    const updatedAt = new Date(bookingDetails?.updatedAt);
    const formattedDate = `${updatedAt.getDate().toString().padStart(2, "0")}-${(
        updatedAt?.getMonth() + 1
      )
        .toString()
        .padStart(2, "0")}-${updatedAt.getFullYear()} ${updatedAt
        .getHours()
        .toString()
        .padStart(2, "0")}:${updatedAt.getMinutes().toString().padStart(2, "0")}`;

        const handleNewCreatingBooking=()=>{
            navigate(`/bookings/create-new-booking`)
        
          }
          const handleAllBooking=()=>{
            navigate(`/bookings/all-bookings`)
          }

  return (
    <div className="nevbar-content" style={{ marginLeft: "1.50rem" }}>
    <div className="nevbar-text-booking">
      <h5>Booking ID</h5>
      <h6>{id}</h6>
    </div>
    <div className="nevbar-content">
      <div className="nevbar-text-booking">
        <h5> Category Name</h5>
        <h6>{bookingDetails?.tariff?.tripType}</h6>
      </div>
    </div>
    <div className="nevbar-content">
      <div className="nevbar-text-booking">
        <h5>Created At</h5>
        <h6>{bookingDetails?.createdAt}</h6>
      </div>
    </div>
    <div className="nevbar-content">
      <div className="nevbar-text-booking">
        <h5>Created By</h5>
        <h6>
          {bookingDetails?.user?.firstName} {bookingDetails?.user?.lastName}
        </h6>
      </div>
    </div>
    <div className="nevbar-content">
      <div className="nevbar-text-booking">
        <h5>Updated At</h5>
        <h6>{formattedDate}</h6>
      </div>
    </div>
<div className="nevbar-content">
<div className="nevbar-text-booking">
<div className="button_All_booking">
  <button 
   type="submit"
   name="allBooking"
   id="search"
   onClick={handleAllBooking}
  >All Bookings</button>
</div>
</div>
</div>


    <div className="nevbar-content">
      <div
        className="nevbar-text-booking align"
        style={{ marginRight: "-1.90rem" }}
      >
        <div className="button_new_booking">
          <form action="">
            <button
              type="submit"
              name="search"
              id="search"
              onClick={handleNewCreatingBooking}
            
            >Create New Booking</button>
          </form>
        </div>
      </div>
    </div>
    <div className="nevbar-content">
      <div className="nevbar-text-booking">
        <div className="icon">
          <a href="#">
            <i className="">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="17"
                viewBox="0 0 24 17"
                fill="none"
              >
                <path
                  d="M2.25 0C1.00781 0 0 0.951823 0 2.125C0 2.79349 0.332813 3.42214 0.9 3.825L11.1 11.05C11.6344 11.4263 12.3656 11.4263 12.9 11.05L23.1 3.825C23.6672 3.42214 24 2.79349 24 2.125C24 0.951823 22.9922 0 21.75 0H2.25ZM0 4.95833V14.1667C0 15.7294 1.34531 17 3 17H21C22.6547 17 24 15.7294 24 14.1667V4.95833L13.8 12.1833C12.7312 12.9404 11.2688 12.9404 10.2 12.1833L0 4.95833Z"
                  fill="white"
                />
              </svg>
            </i>
          </a>
        </div>
      </div>
    </div>
  </div>
  )
}

export default NavbarContent
