// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.third_table{
    padding-top: 5rem;
    width: 79rem;
    overflow-x: scroll;
}
.earning_container .third_table_containers table{
    border-collapse: collapse;
    width: 60%;
}

.earning_container .third_table_containers  th{
    padding: 10px 8px;
    color: white;
    background-color: #38b000;
    box-shadow: 0px 4px 18px 0px #38b00040;
    font-size: 14px;
    width: 8rem;
    border:1px solid black;
    white-space: nowrap;
}
.earning_container .third_table tr :first-child{
    width: 25rem;
}
.third_Earning_table_data td{
    border: 1px solid black;
    text-align: left;
    font-size: 0.8rem;
    white-space: nowrap;
}


`, "",{"version":3,"sources":["webpack://./src/components/DriverManagement/earning/table/thirdtable.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,YAAY;IACZ,kBAAkB;AACtB;AACA;IACI,yBAAyB;IACzB,UAAU;AACd;;AAEA;IACI,iBAAiB;IACjB,YAAY;IACZ,yBAAyB;IACzB,sCAAsC;IACtC,eAAe;IACf,WAAW;IACX,sBAAsB;IACtB,mBAAmB;AACvB;AACA;IACI,YAAY;AAChB;AACA;IACI,uBAAuB;IACvB,gBAAgB;IAChB,iBAAiB;IACjB,mBAAmB;AACvB","sourcesContent":[".third_table{\n    padding-top: 5rem;\n    width: 79rem;\n    overflow-x: scroll;\n}\n.earning_container .third_table_containers table{\n    border-collapse: collapse;\n    width: 60%;\n}\n\n.earning_container .third_table_containers  th{\n    padding: 10px 8px;\n    color: white;\n    background-color: #38b000;\n    box-shadow: 0px 4px 18px 0px #38b00040;\n    font-size: 14px;\n    width: 8rem;\n    border:1px solid black;\n    white-space: nowrap;\n}\n.earning_container .third_table tr :first-child{\n    width: 25rem;\n}\n.third_Earning_table_data td{\n    border: 1px solid black;\n    text-align: left;\n    font-size: 0.8rem;\n    white-space: nowrap;\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
