import React from "react";
import "./Fourthtable.css";
import { useDispatch, useSelector } from "react-redux";
import { selectbookingDetails } from "../DetailsSlice";

const Fourthtable = ({ bookingDetailsTableFourth }) => {

  console.log("boooking details table 44444444444444444444444444444444",bookingDetailsTableFourth)
  const bookingDetails = useSelector(selectbookingDetails);

  const pickupDateString = bookingDetailsTableFourth?.pickupDate || "0-0-0";
  const returnDateString = bookingDetailsTableFourth?.returnDate || "0-0-0";

  const pickupDateParts = pickupDateString.split("-").reverse();
  const returnDateParts = returnDateString.split("-").reverse();

  const pickupDate = new Date(
    pickupDateParts[0],
    pickupDateParts[1] - 1,
    pickupDateParts[2] || 0
  );
  const returnDate = new Date(
    returnDateParts[0],
    returnDateParts[1] - 1,
    returnDateParts[2] || 0
  );

  const differenceInMs = returnDate - pickupDate;
  const differenceInDays = isNaN(differenceInMs)
    ? "N/A"
    : differenceInMs / (1000 * 60 * 60 * 24);

  const estimatedfare = bookingDetailsTableFourth?.estimatedFare ?? 0;
  const advancePayment = bookingDetailsTableFourth?.advancePaymentReceived ?? 0;
  const balancePayment = bookingDetailsTableFourth?.estimatedFare - bookingDetailsTableFourth?.advancePaymentReceived ?? 0;
  const distanceTravelledOutstation = bookingDetailsTableFourth?.approxDistance ?? 0;
  const distanceTravelledOneDay = bookingDetailsTableFourth?.kmIncluded ?? 0;
  const noofdays = bookingDetailsTableFourth?.noOfDays ?? "0";
  const hrsIncluded = bookingDetailsTableFourth?.hrsIncluded ?? 0;

  if(bookingDetailsTableFourth?.tariff?.tripType==="Outstation"){

    var faredetaildata = [
      {
        lable: `Estimated Fare <br/> ${distanceTravelledOutstation} Km, ${noofdays} day`,
        value: estimatedfare.toFixed(2),
      },
      { lable: `Advance Payment (${bookingDetailsTableFourth?.partialPaymentPercentage}%)`, value: advancePayment.toFixed(2) },
      { lable: "Balance Payment to Pay", value: balancePayment.toFixed(2) },
    ];

  }else if(bookingDetailsTableFourth?.tariff?.tripType==="Local"){

    var faredetaildata = [
      {
        lable: `Estimated Fare <br/> ${distanceTravelledOneDay} Km, ${hrsIncluded} Hours`,
        value: estimatedfare.toFixed(2),
      },
      { lable: `Advance Payment (${bookingDetailsTableFourth?.partialPaymentPercentage}%)`, value: advancePayment.toFixed(2) },
      { lable: "Balance Payment to Pay", value: balancePayment.toFixed(2) },
    ];

  }else{

    
    var faredetaildata = [
      {
        lable: `Estimated Fare <br/> ${distanceTravelledOneDay} Km, ${hrsIncluded} Hours`,
        value: estimatedfare.toFixed(2),
      },
      { lable: `Advance Payment (${bookingDetailsTableFourth?.partialPaymentPercentage}%)`, value: advancePayment.toFixed(2) },
      { lable: "Balance Payment to Pay", value: balancePayment.toFixed(2) },
    ];
    
  }

  return (
    <div className="billing_fourth_containers">
      <div className="billing_fourth_scroll">
        <table className="billing-table-fourth">
          <thead>
            <tr>
              <th colSpan="2">Fare Breakup</th>
            </tr>
          </thead>
          <tbody className="first_billing_table_data">
            {faredetaildata.map((row, index) => (
              <React.Fragment key={index}>
                <tr>
                  <td
                    style={
                      row.lable === `Advance Payment (${bookingDetailsTableFourth?.partialPaymentPercentage}%)`
                        ? { backgroundColor: "white", color: "black",  fontWeight: "700",  }
                        : { backgroundColor: "#38b000", color: "white" }
                    }
                    dangerouslySetInnerHTML={{ __html: row.lable }}
                  />
                  <td
                    style={
                      row.lable === "Balance Payment to Pay"
                      ? { color: "#38b000", fontWeight: "800",backgroundColor: "#fff", }

                      : row.lable === `Advance Payment (${bookingDetailsTableFourth?.partialPaymentPercentage}%)`
                        ? { backgroundColor: "white", color: "black",  fontWeight: "700",  }
                        : { backgroundColor: "#38b000", color: "white" }
                    }
                  >
                    {row.value === "" ? "" : `Rs. ${row.value}`}
                  </td>
                </tr>
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Fourthtable;
