import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom"; // Correctly importing useParams
import axios from "axios";
import "./Earning.css";
import FirstTable from "./earningtable/FirstTable";
import SecondTable from "./earningtable/SecondTable";
import ThirdTable from "./earningtable/ThirdTable";

import { FaLongArrowAltRight } from "react-icons/fa";
import redArrow from "./../../../assets/redArrow.svg";
import { Link } from "react-router-dom";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const EarningReport = () => {
  const { driverId } = useParams(); // Correct usage of useParams
  const [previousLocation, setPreviousLocation] = useState();
  const [earningData, setEarningData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const checkParentLocation = (path) => {
    if (path.includes("InactiveDriver")) {
      setPreviousLocation("Inactive Drivers");
    } else if (path.includes("ApprovalPendingDriver")) {
      setPreviousLocation("Approval Pending");
    } else {
      setPreviousLocation("View All Drivers");
    }
  };

  useEffect(() => {
    checkParentLocation(window.location.pathname);
  }, []);

  useEffect(() => {
    const fetchEarningData = async () => {
      if (!driverId) return; // Ensure driverId is available before making API call

      try {
        const response = await axios.get(
          `${API_BASE_URL}/v1/admin/drivers/${driverId}/earning-data`
        );

        setEarningData(response.data); // Ensure response data is set properly
      } catch (error) {
        console.error("Error fetching earning data:", error);
        setError("Failed to fetch earning data");
      } finally {
        setLoading(false);
      }
    };

    fetchEarningData();
  }, [driverId]);

  if (loading) return <p>Loading earnings data...</p>;
  if (error) return <p>{error}</p>;

  return (
    <div className="earning_container">
      <div className="header-text">
        <Link className="h3-booking">Driver Management</Link>
        <img src={redArrow} alt="redArrow" />
        <Link className="h3-booking">{previousLocation}</Link>
        <img src={redArrow} alt="redArrow" />
        <Link className="h3-booking">Driver Details</Link>
        <img src={redArrow} alt="redArrow" />
        <Link className="h2-booking">Earning Reports</Link>
      </div>

      <div className="earning_and_report">Earning & Payment Report</div>

      <div className="first_table">
      <FirstTable earningData={earningData} />

      </div>

      <div className="second_table">
        <SecondTable earningData={earningData} />
      </div>

      <div className="third_table">
        <div className="Bookingtitle">Booking History</div>
        <ThirdTable data={earningData} />
      </div>
    </div>
  );
};

export default EarningReport;
