import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const initialState = {
    driverData: null,
    cabData: null,
    allDrivers: {
        data: null,
        loading: 'idle',
        error: null,
    },
    allCabs: {
        data: null,
        loading: 'idle',
        error: null
    },
    togglefortaskdetails: false
}

export const fetchDriverData = createAsyncThunk('assignCab&Driver/fetchDriverData', async () => {
    const response = await axios.get(`${API_BASE_URL}/v1/admin/drivers`);
    console.log('api driver response', response.data);
    return response.data;
});

export const fetchCabData = createAsyncThunk('assignCab&Driver/fetchCabData', async () => {
    const response = await axios.get(`${API_BASE_URL}/v1/admin/cars`);
    console.log('api cab response', response.data);
    return response.data;
});

export const fetchDriversByLocation = createAsyncThunk('assignCab&Driver/fetchDriversByLocation', async ({ bookingId, location }) => {
    const response = await axios.get(`${API_BASE_URL}/v1/admin/bookings/drivers/location?bookingId=${bookingId}&location=${location}`);
    console.log('api driver by location response', response.data);
    return response.data;
});

export const fetchCarByDriverId = createAsyncThunk('assignCab&Driver/fetchCarByDriverId', async ({ bookingId, driverId }) => {
    const response = await axios.get(`${API_BASE_URL}/v1/admin/bookings/cars?bookingId=${bookingId}&driverId=${driverId}`);
    console.log('api car by driver response', response.data);
    return response.data;
});


const assignDataSlice = createSlice({
    name: 'assignCab&Driver',
    initialState,

    reducers: {
        assignDriver: (state, action) => {
            state.driverData = action.payload
        },
        assignCab: (state, action) => {
            state.cabData = action.payload
        },
        setToggleForTaskDetails: (state, action) => {
            state.togglefortaskdetails = action.payload;
        },
    },

    extraReducers: (builder) => {
        builder.addCase(fetchDriverData.pending, (state) => {
            state.allDrivers.loading = 'loading';
        })
            .addCase(fetchDriverData.fulfilled, (state, action) => {
                state.allDrivers.loading = 'succeeded';
                state.allDrivers.data = action.payload;
            })
            .addCase(fetchDriverData.rejected, (state, action) => {
                state.allDrivers.loading = 'failed';
                state.allDrivers.error = action.error.message;
            })
            .addCase(fetchCabData.pending, (state) => {
                state.allCabs.loading = 'loading'
            })
            .addCase(fetchCabData.fulfilled, (state, action) => {
                state.allCabs.loading = 'succeeded';
                state.allCabs.data = action.payload
            })
            .addCase(fetchCabData.rejected, (state, action) => {
                state.allCabs.loading = 'failed';
                state.allCabs.error = action.error.message;
            })
            .addCase(fetchDriversByLocation.pending, (state) => {
                state.allDrivers.loading = 'loading';
            })
            .addCase(fetchDriversByLocation.fulfilled, (state, action) => {
                state.allDrivers.loading = 'succeeded';
                state.allDrivers.data = action.payload;
            })
            .addCase(fetchDriversByLocation.rejected, (state, action) => {
                state.allDrivers.loading = 'failed';
                state.allDrivers.error = action.error.message;
            })
            .addCase(fetchCarByDriverId.pending, (state) => {
                state.allCabs.loading = 'loading';
            })
            .addCase(fetchCarByDriverId.fulfilled, (state, action) => {
                state.allCabs.loading = 'succeeded';
                state.allCabs.data = action.payload;
            })
            .addCase(fetchCarByDriverId.rejected, (state, action) => {
                state.allCabs.loading = 'failed';
                state.allCabs.error = action.error.message;
            });
    }
})

export const { assignDriver, assignCab, setToggleForTaskDetails } = assignDataSlice.actions;
export default assignDataSlice.reducer;
