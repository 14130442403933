import './ButtonSwitcher.css'

const ButtonSwitcher = () => {
    return (
        <>
            {/* <div id="main-buttons-container">

                <p>Trip Type:</p>


                <div className="button-parent-wrapper">

                    <div className="big-buttons-wrapper">
                        <button>Local</button>
                        <button>Outstaion</button>
                        <button>Airport</button>
                    </div>

                    <div className="small-buttons-wrapper">
                        <button>From the airport</button>
                        <button>To the airport</button>
                    </div>

                </div>


            </div> */}
        </>
    )
}

export default ButtonSwitcher;