import React, { useEffect } from "react";
import { Routes, Route, useNavigate, useLocation, Link } from "react-router-dom";
import "./Booking.css";
import { FaLongArrowAltRight } from "react-icons/fa";
import ViewAllBooking from "./viewAllBooking";
import BookingPending from "./BookingPending";
// import CreateNewBooking from "./createNewBooking";
import Layout from '../../components/BookingB2C/CreateNewBooking/Layout'

 
const Booking = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleActiveComponent = (type) => {
    navigate(`/bookings/${type.toLowerCase().replace(/\s+/g, '-')}`);
  };

  useEffect(() => {
    if (location.pathname === "/bookings" || location.pathname === "/bookings/") {
      navigate("/bookings/all-bookings");
    }
  }, [location.pathname, navigate]);

  const capitalizeWords = (str) => {
    return str.replace(/\b\w/g, (char) => char.toUpperCase());
  };

  return (
    <div className="carmangement-header" style={{ width: "100%" }}>
      <div className="booking">
        <div className="header">

          {/* <div className="header-text">
            <Link to="/dashboard" className="h3-booking">
              B2C Booking
            </Link>{" "}
            <FaLongArrowAltRight />{" "}
            <Link to="/bookings" className="h2-booking">
              {capitalizeWords(location.pathname.split("/").pop().replace(/-/g, " "))}
            </Link>
          </div> */}
          
          <div className="btns only-B2C">
            <button
              className={
                location.pathname.endsWith("/all-bookings")
                  ? "createButton-cars"
                  : "createButton"
              }
              onClick={() => handleActiveComponent("All Bookings")}
            >
              All Bookings
            </button>
            
            <button
              className={
                location.pathname.endsWith("/pending-bookings")
                  ? "createButton-cars"
                  : "createButton"
              }
              onClick={() => handleActiveComponent("Pending Bookings")}
            >
              Pending Bookings
            </button>

            <button
              className={
                location.pathname.endsWith("/create-new-booking")
                  ? "createButton-cars"
                  : "createButton"
              }
              onClick={() => handleActiveComponent("Create New Booking")}
            >
              Create New Booking
            </button>
            
          </div>
        </div>
      </div>
      <div className="All-btc-pages-wrapper">
        <Routes>
          <Route path="all-bookings" element={<ViewAllBooking />} />
          {/* <Route path="create-new-booking" element={<CreateNewBooking />} /> */}
          <Route path="create-new-booking" element={<Layout/>} />
          <Route path="pending-bookings" element={<BookingPending />} />
        </Routes>
      </div>
    </div>
  );
};

export default Booking;
