import React, { useState, useEffect } from "react";
import "./ViewAllCityPage.css";
import { useSelector, useDispatch } from "react-redux";
import {
  selectInActiveCity,
  selectViewAllCity,
  editCurrentCity,
  toggleCityStatus,
} from "./geographySlice";
import { FaPen } from "react-icons/fa";
import Notification from "../Notification";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Toggle } from "../TariffManagement/Toggle";
import toast, { Toaster } from "react-hot-toast";
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

// Component for each row in the table
const TableItem = ({ item, index, sentToInActiveItem }) => {
  const rowClass = index % 2 === 0 ? "table-row-even" : "table-row-odd";

  const logState = (state, item, dispatch) => {
    const newStatus = state ? "Inactive" : "Active";
    sentToInActiveItem(item, newStatus, dispatch);
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentComponent = "view-all-city";

  const handleEditbutton = () => {
    dispatch(
      editCurrentCity([
        item.city,
        item.state,
        item.zeroPaymentBooking === true ? "Enabled" : "Disabled",
      ])
    );
    console.log("checking current location");
    navigate(`/geography/${currentComponent}/edit-location/${item._id}`);
  };

  return (
    <tr className={rowClass}>
      <td>{index + 1}</td> 
      <td>{item?.city}</td>
      <td>{item?.state}</td>
      <td>{item?.zeroPaymentBooking === true ? "Enabled" : "Disabled"}</td>
      <td>{item?.status}</td>
      <td>
        <div className="icons"  style={{height:"10px"}}>
          <FaPen className="icon-edit " onClick={handleEditbutton} style={{marginTop:"-5px"}} />
      
          {/* <BiSolidToggleLeft className="icon" onClick={handleInactivebutton} /> */}
          <Toggle
            toggled={item.status === "Active" ? false : true}
            onClick={(state) => logState(state, item._id, dispatch)}
          />
        </div>
      </td>
    </tr>
  );
};

// Table component
const Table = ({ headers, data }) => {
  const [rawdata, setRawData] = useState(data);
  const [showNotification, setShowNotification] = useState(null);
  const [showMessage, setShowMessage] = useState(null);
  const updatedInactiveData = useSelector(selectInActiveCity);
  const remainingViewAllCity = useSelector(selectViewAllCity);

  // Delete item function with try-catch for error handling
  const deleteItem = async (item, type, message) => {
    try {
      await axios.delete(
        `${API_BASE_URL}/v1/admin/locations/${item?._id}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      remainingViewAllCity = remainingViewAllCity.filter(
        (data) => data._id !== item._id
      );
      setRawData(remainingViewAllCity);
      setShowNotification(type);
      setShowMessage(message);
    } catch (error) {
      setShowNotification("error");
      setShowMessage(error);
    }
  };

  // Inactivate item function with try-catch for error handling
  const sentToInActiveItem = async (
    item,
    newStatus,
    dispatch,
    type,
    message
  ) => {
    try {
      const response = await axios.put(
        `${API_BASE_URL}/v1/admin/locations/${item}/status`,
        { status: "Inactive" },
        item,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log(item.id);
      if (response.status === 200) {
        //setRawData(updatedInactiveData);
        dispatch(toggleCityStatus(item, newStatus));
        toast.success(response.data.message);
        setTimeout(() => {
          window.location.reload();
        }, 1500);

      }

    } catch (error) {
      // setShowNotification("error");
      toast.error("Failed to update data");
    }
  };

  // Edit item function
  const editItem = (type, message) => {
    setShowNotification(type);
    setShowMessage(message);
  };

  // Handle notification close
  const handleNotificationClose = () => {
    setShowNotification(false);
  };

  return (
    <>
    <Toaster />
    <div className="table-containers">
      <div className="table-scroll">
        <table className="table">
          <thead> 
          <tr>
              {headers?.map((header, index) => (
                <th key={index}>
                  {index !== headers.length - 1 && <div className='th-line'></div>}
                {header}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {rawdata?.map((item, index) => (
              <TableItem
                key={index}
                item={item}
                index={index}
                deleteItem={deleteItem}
                sentToInActiveItem={sentToInActiveItem}
                editItem={editItem}
              />
            ))}
          </tbody>
        </table>
        {showNotification && (
          <Notification
            type={`${showNotification}`}
            message={`${showMessage}`}
            onClose={handleNotificationClose}
          />
        )}
      </div>
    </div>
    </>
  );
};

// Main App component
const App = ({ viewallcitydata }) => {
  let tabledata = viewallcitydata;
  const [tableKey, setTableKey] = useState(0);

  const headers = [
    "Sr No",
    "City Name",
    "State Name",
    "Pay To Driver",
    "Status",
    "Action",
  ];

  useEffect(() => {
    setTableKey((prevKey) => prevKey + 1);
  }, [viewallcitydata]);

  return (
    <div>
      <Table key={tableKey} headers={headers} data={tabledata} />
    </div>
  );
};

export default App;
