// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.earning-report-container{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    height: 95vh; /* Full viewport height */
    overflow: hidden; /* Prevent entire page from scrolling */
    overflow-x: scroll;
    overflow-y: scroll;

}
.earning_and_report{
    background-color: #38b000;
    border-radius: 2px;
    box-shadow: 0px 4px 22px 0px rgba(0, 0, 0, 0.17);
    color: #fff;
    padding: 10px;
    font-family: lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

}

.section1{
    display: flex;
    gap:20px;
}
.section2{
    display: flex;
    align-items: center;
    justify-content: center;
    gap:300px;
}
.section3{
    margin-top: 30px;
    display: flex;
    gap:20px;
}`, "",{"version":3,"sources":["webpack://./src/components/DriverManagement/earning/EarningReport.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,SAAS;IACT,YAAY,EAAE,yBAAyB;IACvC,gBAAgB,EAAE,uCAAuC;IACzD,kBAAkB;IAClB,kBAAkB;;AAEtB;AACA;IACI,yBAAyB;IACzB,kBAAkB;IAClB,gDAAgD;IAChD,WAAW;IACX,aAAa;IACb,iBAAiB;IACjB,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,mBAAmB;;AAEvB;;AAEA;IACI,aAAa;IACb,QAAQ;AACZ;AACA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,SAAS;AACb;AACA;IACI,gBAAgB;IAChB,aAAa;IACb,QAAQ;AACZ","sourcesContent":[".earning-report-container{\n    display: flex;\n    flex-direction: column;\n    gap: 1rem;\n    height: 95vh; /* Full viewport height */\n    overflow: hidden; /* Prevent entire page from scrolling */\n    overflow-x: scroll;\n    overflow-y: scroll;\n\n}\n.earning_and_report{\n    background-color: #38b000;\n    border-radius: 2px;\n    box-shadow: 0px 4px 22px 0px rgba(0, 0, 0, 0.17);\n    color: #fff;\n    padding: 10px;\n    font-family: lato;\n    font-size: 16px;\n    font-style: normal;\n    font-weight: 700;\n    line-height: normal;\n\n}\n\n.section1{\n    display: flex;\n    gap:20px;\n}\n.section2{\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    gap:300px;\n}\n.section3{\n    margin-top: 30px;\n    display: flex;\n    gap:20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
