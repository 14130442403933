import React from 'react'
import './Bill2Customer.css'
const Bill2Customer = () => {
    return (

        <>

            <div className="bill2c-main-container">
                <h1>Billed to Customer </h1>


                <div className="bill2c-layout-container">

                    <div className="bill2c-inner-flexer">
                        <h2>Fare Breakup</h2>
                    </div>

                    <div className="bill2c-inner-flexer">
                        <div>
                            <p>Estimated Fare</p>
                            <p>Min Km Charged : 250 Kms/day</p>
                        </div>
                        <div>
                            <p>Rs. 12345.00</p>
                        </div>
                    </div>

                    <div className="bill2c-inner-flexer">
                        <div>
                            <p>Advance Payment (50%)</p>
                        </div>
                        <div>
                            <p>Rs. 6172.50</p>
                        </div>
                    </div>

                    <div className="bill2c-inner-flexer">
                        <div>
                            <p>Balance Payment to Pay</p>
                        </div>
                        <div>
                            <p>Rs. 6172.50</p>
                        </div>

                    </div>

                    <div className="bill2c-inner-flexer">
                        <h2>Extra Coverage</h2>
                    </div>

                    <div className="bill2c-inner-flexer">
                        <div>
                            <p>Balance Payment to Pay</p>
                        </div>
                        <div>
                            <p>Rs. 6172.50</p>
                        </div>
                    </div>

                    <div className="bill2c-inner-flexer">
                        <div>
                            <p>Balance Payment to Pay</p>
                        </div>
                        <div>
                            <p>Rs. 6172.50</p>
                        </div>
                    </div>

                    <div className="bill2c-inner-flexer">
                        <div>
                            <p>Balance Payment to Pay</p>
                        </div>
                        <div>
                            <p>Rs. 6172.50</p>
                        </div>
                    </div>

                    <div className="bill2c-inner-flexer">
                        <div>
                            <p>Balance Payment to Pay</p>
                        </div>
                        <div>
                            <p>Rs. 6172.50</p>
                        </div>
                    </div>

                    <div className="bill2c-inner-flexer">
                        <h2>Extra Fare</h2>
                    </div>
                    
                    <div className="bill2c-inner-flexer">
                        <div>
                            <p>Balance Payment to Pay</p>
                        </div>
                        <div>
                            <p>Rs. 6172.50</p>
                        </div>
                    </div>
                    
                    <div className="bill2c-inner-flexer">
                        <div>
                            <p>Balance Payment to Pay</p>
                        </div>
                        <div>
                            <p>Rs. 6172.50</p>
                        </div>
                    </div>
                    
                    <div className="bill2c-inner-flexer">
                        <div>
                            <p>Balance Payment to Pay</p>
                        </div>
                        <div>
                            <p>Rs. 6172.50</p>
                        </div>
                    </div>
                    
                    <div className="bill2c-inner-flexer">
                        <div className='bill2c-bg-white'>
                            <p className='bill2c-clr-green'>Total Extra Amount</p>
                        </div>
                        <div>
                            <p className='bill2c-clr-green'>Rs. 1,148.60</p>
                        </div>
                    </div>
                    
                    <div className="bill2c-inner-flexer">
                        <div>
                            <p>Balance Paym Pay</p>
                        </div>
                        <div className='bill2c-bg-green'>
                            <p className='bill2c-clr-white'>Rs. 6172.50</p>
                        </div>
                    </div>
                    
                    <div className="bill2c-inner-flexer">
                        <div className='bill2c-bg-white'>
                            <p className='bill2c-clr-red'>Less cash for fuel received by driver in advance</p>
                        </div>
                        <div>
                            <p className='bill2c-clr-green'>Rs. 6172.50</p>
                        </div>
                    </div>
                    
                    <div className="bill2c-inner-flexer stop-lstBoder">
                        <div>
                            <p className='bill2c-font-15px'>Total Paid - Online</p>
                        </div>
                        <div>
                            <p className='bill2c-clr-green'>Rs. 2321.10</p>
                        </div>
                    </div>

                </div>

            </div>

        </>

    )
}

export default Bill2Customer