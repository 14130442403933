import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { selectbookingDetails } from "../Details/DetailsSlice";
import { IoIosPhonePortrait } from "react-icons/io";
const Scanbar = ({ activeButton }) => {
  const { id } = useParams();
  const bookingDetails = useSelector(selectbookingDetails);
  console.log("Active Button:", activeButton);
  console.log("Booking details in scanner", bookingDetails);

  return (
    <div>
      <div className="main-header-box">
        <div className="right-side-group">
          {/* <div className="right-box">
            <img
              src="https://cdn.britannica.com/17/155017-050-9AC96FC8/Example-QR-code.jpg"
              alt=""
            ></img>
            <h7>Booking ID YC2324-01</h7>
          </div> */}

          <div className="right-box-2">
            <h3>{bookingDetails?.createdAt}</h3>
            <div className="Line"></div>
            <div className="header-text-main">
              <div className="right-text">
                <IoIosPhonePortrait
                  style={{
                    marginLeft: "10px",
                    marginRight: "-10px",
                    fontSize: "18px",
                  }}
                />
                <div className="right-text-content">
                  <h7>Booking Created by</h7> <br></br>
                  <span>
                    {bookingDetails?.user?.firstName +
                      " " +
                      bookingDetails?.user?.lastName}
                    <br></br>
                  </span>
                  <span>{bookingDetails?.user?.phone}</span>
                </div>
              </div>
            </div>
          </div>

          <div>
            {bookingDetails?.driver && (
              <>
                <div className="right-box-2">
                  <h3>{bookingDetails.createdAt || "Date not available"}</h3>
                  <div className="Line"></div>
                  <div className="header-text-main">
                    <div className="right-text">
                      <IoIosPhonePortrait
                        style={{
                          marginLeft: "10px",
                          marginRight: "-10px",
                          fontSize: "18px",
                        }}
                      />
                      <div className="right-text-content">
                        <h7>Driver & Cab Assigned</h7>
                        <br />
                        <span>
                          {bookingDetails.driver.firstName}{" "}
                          {bookingDetails.driver.lastName}{" "}
                          {bookingDetails.driver.phone}
                          <br />
                        </span>
                        {bookingDetails.car && (
                          <span>
                            Cab No.{" "}
                            {bookingDetails.car.registrationCertificate
                              ?.number || "N/A"}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
          <div>
            {bookingDetails?.pickupInitiated === true && (
              <>
                <div className="right-box-1">
                  <h3>16-08-2023 17:30</h3>
                  <div className="Line"></div>
                  <div className="header-text-main">
                    <div className="right-text">
                      <IoIosPhonePortrait
                        style={{
                          marginLeft: "10px",
                          marginRight: "-10px",
                          fontSize: "18px",
                        }}
                      />
                      <div className="right-text-content">
                        <h7>Pickup Initiated</h7>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
          <div>
            {bookingDetails?.reachedPickupPoint === true && (
              <>
                <div className="right-box-1">
                  <h3>16-08-2023 18:00</h3>
                  <div className="Line"></div>
                  <div className="header-text-main">
                    <div className="right-text">
                      <IoIosPhonePortrait
                        style={{
                          marginLeft: "10px",
                          marginRight: "-10px",
                          fontSize: "18px",
                        }}
                      />
                      <div className="right-text-content">
                        <h7>Reached Pickup Point</h7>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>

          <div>
            {bookingDetails?.rideStarted === true && (
              <>
                <div className="right-box-1">
                  <h3>16-08-2023 16:58</h3>
                  <div className="Line"></div>
                  <div className="header-text-main">
                    <div className="right-text">
                      <IoIosPhonePortrait
                        style={{
                          marginLeft: "10px",
                          marginRight: "-10px",
                          fontSize: "18px",
                        }}
                      />
                      <div className="right-text-content">
                        <h7>Ride Started</h7>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>

          <div>
            {bookingDetails?.rideEnded === true && (
              <>
                <div className="right-box-1">
                  <h3>16-08-2023 16:58</h3>
                  <div className="Line"></div>
                  <div className="header-text-main">
                    <div className="right-text">
                      <IoIosPhonePortrait
                        style={{
                          marginLeft: "10px",
                          marginRight: "-10px",
                          fontSize: "18px",
                        }}
                      />
                      <div className="right-text-content">
                        <h7>Ride Ended</h7>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        {/* )} */}
      </div>
    </div>
  );
};

export default Scanbar;
