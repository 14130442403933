import React from 'react'
import { FaLongArrowAltRight } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import "../shared.css";
import Bill2Customer from './Bill2Customer';
import VenderPayout from './VenderPayout';

const Outstation = () => {
    return (
        <>

            <div className="er-container">

                <div className="header">

                    <div className="header-text">
                        <Link className="h3-booking">Driver Management</Link>
                        <FaLongArrowAltRight />
                        {""}
                        <Link className="h3-booking">View All Driver</Link>
                        <FaLongArrowAltRight />
                        <Link className="h3-booking">Driver Details</Link>
                        <FaLongArrowAltRight />
                        <Link className="h3-booking">Earning Reports</Link>
                        <FaLongArrowAltRight />
                        <Link className="h2-booking">Outstation</Link>
                    </div>

                    <div><button class="createButton-cars">View All Drivers</button><button class="createButton">Inactive Driver</button><button class="createButton">Approval Pending</button><button class="createButton">Add New Driver</button></div>
                </div>



                <div className="er-detail-container1">

                    <div className="er-sub-detail a1">
                        <div className="head">Booking ID</div>
                        <div className="body">YCR0523886724-01</div>
                    </div>

                    <div className="er-sub-detail a2">
                        <div className="head">Car Category</div>
                        <div className="body">YCR052324-01</div>
                    </div>

                    <div className="er-sub-detail a3">
                        <div className="head">Trip Type</div>
                        <div className="body">YCR052324-01</div>
                    </div>

                    <div className="er-sub-detail a4">
                        <div className="head">Route</div>
                        <div className="body">YCR052324-01</div>
                    </div>

                    <div className="er-sub-detail a5">
                        <div className="head">Start Date & Time</div>
                        <div className="body">YCR052324-01</div>
                    </div>

                    <div className="er-sub-detail a6">
                        <div className="head">End Date & Time</div>
                        <div className="body">YCR052324-01</div>
                    </div>

                    <div className="er-sub-detail a7">
                        <div className="head">No of Day</div>
                        <div className="body">YCR052324-01</div>
                    </div>

                    <div className="er-sub-detail a8">
                        <div className="head">Distance Travelled</div>
                        <div className="body">YCR052324-01</div>
                    </div>

                </div>


                <div className="er-detail-container2">
                    <div className="er-sub-box">
                        <div className="inner-flexer">
                            <div>Total Bill Amount</div>
                            <div>Rs. 13,493.60</div>
                        </div>

                        <div className="inner-flexer">
                            <div>Total KM Covered</div>
                            <div>700 KM</div>
                        </div>
                    </div>

                    <div className="er-sub-box">
                        <div className="inner-flexer">
                            <div>Total Bill Amount</div>
                            <div>Rs. 13,493.60</div>
                        </div>

                        <div className="inner-flexer">
                            <div>Total KM Covered</div>
                            <div>700 KM</div>
                        </div>
                    </div>
                </div>


                <div className="customer-venter-comp-flexer">
                    <Bill2Customer />
                    <VenderPayout />

                </div>

            </div>

        </>
    )
}

export default Outstation