import React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Notification = ({ type, message, onClose }) => {
  const notify = () => {
    switch (type) {
      case 'success':
        toast.success(message, {
          position: toast.POSITION.TOP_RIGHT,
          onClose: onClose,
        });
        break;
      case 'error':
        toast.error(message, {
          position: toast.POSITION.TOP_RIGHT,
          onClose: onClose,
        });
        break;
      case 'info':
        toast.info(message, {
          position: toast.POSITION.TOP_RIGHT,
          onClose: onClose,
        });
        break;
      default:
        toast(message, {
          position: toast.POSITION.TOP_RIGHT,
          onClose: onClose,
        });
    }
  };

  // Trigger notification on mount
  React.useEffect(() => {
    notify();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return <ToastContainer />;
};

export default Notification;
