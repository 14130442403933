import React, { useEffect, useState } from "react";
import "./thirdtable.css";
import { useParams } from "react-router-dom";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import { CiFilter } from "react-icons/ci";
import { useNavigate } from "react-router-dom";
import "./thirdtable.css";
import { FaEye } from "react-icons/fa";
import { FaSortUp, FaSortDown } from "react-icons/fa";

import { FaFilePdf } from "react-icons/fa6";
import { FaFileExcel } from "react-icons/fa";
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const TableItem = ({ item, index, driverId, selectedRow, setSelectedRow }) => {
  const navigate = useNavigate();

  const handleRowClick = () => {
    setSelectedRow(index); // Set this row as selected
  };

  return (
    <tr
      className="third_Earning_table_data"
      onClick={handleRowClick}
      style={{
        cursor: "pointer",
        backgroundColor: selectedRow === index ? "lightgreen" : "white",
      }}
    >
      {Object.entries(item).map(([key, value], i) => (
        <td key={i} style={{ textAlign: "center", verticalAlign: "middle" }}>
          {value}
        </td>
      ))}

      {/* Eye button for viewing details */}
      <td style={{ textAlign: "center", verticalAlign: "middle" }}>
        <button
          onClick={() => {
            if (item["Status"]?.props?.children === "Completed") {
              navigate(
                `/drivermanagement/ViewAllDriver/DriverDetails/${driverId}/Reports/earnings/${item["Booking ID"]}`
              );
            } else {
              toast.error("You can only view details for completed bookings!");
            }
          }}
          style={{
            display: "flex",
            justifyContent: "center",
            marginLeft: "30px",
            width: "80px",
            border: "none",
            background: "none",
            cursor: "pointer",
          }}
        >
          <FaEye size={15} color="#38b000" />
        </button>
      </td>
    </tr>
  );
};

const NoRating = () => <span>No rating available</span>;

const StarRating = ({ rating }) => {
  const starStyles = {
    width: "70px",
  };
  const starSvgs = {
    1: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100"
        height="16"
        viewBox="0 0 100 16"
        fill="none"
        style={starStyles}
      >
        <path
          d="M10 0.21875L12.2451 5.59306H19.5106L13.6327 8.91457L15.8779 14.2889L10 10.9674L4.12215 14.2889L6.36729 8.91457L0.489435 5.59306H7.75486L10 0.21875Z"
          fill="#FF0000"
        />
        <path
          d="M30 0.21875L32.2451 5.59306H39.5106L33.6327 8.91457L35.8779 14.2889L30 10.9674L24.1221 14.2889L26.3673 8.91457L20.4894 5.59306H27.7549L30 0.21875Z"
          fill="#8B8B8B"
        />
        <path
          d="M50 0.21875L52.2451 5.59306H59.5106L53.6327 8.91457L55.8779 14.2889L50 10.9674L44.1221 14.2889L46.3673 8.91457L40.4894 5.59306H47.7549L50 0.21875Z"
          fill="#8B8B8B"
        />
        <path
          d="M70 0.21875L72.2451 5.59306H79.5106L73.6327 8.91457L75.8779 14.2889L70 10.9674L64.1221 14.2889L66.3673 8.91457L60.4894 5.59306H67.7549L70 0.21875Z"
          fill="#8B8B8B"
        />
        <path
          d="M90 0.21875L92.2451 5.59306H99.5106L93.6327 8.91457L95.8779 14.2889L90 10.9674L84.1221 14.2889L86.3673 8.91457L80.4894 5.59306H87.7549L90 0.21875Z"
          fill="#8B8B8B"
        />
      </svg>
    ),
    2: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100"
        height="17"
        viewBox="0 0 100 17"
        fill="none"
        style={starStyles}
      >
        <path
          d="M10 0.664062L12.2451 6.03837H19.5106L13.6327 9.35988L15.8779 14.7342L10 11.4127L4.12215 14.7342L6.36729 9.35988L0.489435 6.03837H7.75486L10 0.664062Z"
          fill="#FF8C00"
        />
        <path
          d="M30 0.664062L32.2451 6.03837H39.5106L33.6327 9.35988L35.8779 14.7342L30 11.4127L24.1221 14.7342L26.3673 9.35988L20.4894 6.03837H27.7549L30 0.664062Z"
          fill="#FF8C00"
        />
        <path
          d="M50 0.664062L52.2451 6.03837H59.5106L53.6327 9.35988L55.8779 14.7342L50 11.4127L44.1221 14.7342L46.3673 9.35988L40.4894 6.03837H47.7549L50 0.664062Z"
          fill="#8B8B8B"
        />
        <path
          d="M70 0.664062L72.2451 6.03837H79.5106L73.6327 9.35988L75.8779 14.7342L70 11.4127L64.1221 14.7342L66.3673 9.35988L60.4894 6.03837H67.7549L70 0.664062Z"
          fill="#8B8B8B"
        />
        <path
          d="M90 0.664062L92.2451 6.03837H99.5106L93.6327 9.35988L95.8779 14.7342L90 11.4127L84.1221 14.7342L86.3673 9.35988L80.4894 6.03837H87.7549L90 0.664062Z"
          fill="#8B8B8B"
        />
      </svg>
    ),
    3: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100"
        height="16"
        viewBox="0 0 100 16"
        fill="none"
        style={starStyles}
      >
        <path
          d="M10 0.109375L12.2451 5.48369H19.5106L13.6327 8.80519L15.8779 14.1795L10 10.858L4.12215 14.1795L6.36729 8.80519L0.489435 5.48369H7.75486L10 0.109375Z"
          fill="#E3D96F"
        />
        <path
          d="M30 0.109375L32.2451 5.48369H39.5106L33.6327 8.80519L35.8779 14.1795L30 10.858L24.1221 14.1795L26.3673 8.80519L20.4894 5.48369H27.7549L30 0.109375Z"
          fill="#E3D96F"
        />
        <path
          d="M50 0.109375L52.2451 5.48369H59.5106L53.6327 8.80519L55.8779 14.1795L50 10.858L44.1221 14.1795L46.3673 8.80519L40.4894 5.48369H47.7549L50 0.109375Z"
          fill="#E3D96F"
        />
        <path
          d="M70 0.109375L72.2451 5.48369H79.5106L73.6327 8.80519L75.8779 14.1795L70 10.858L64.1221 14.1795L66.3673 8.80519L60.4894 5.48369H67.7549L70 0.109375Z"
          fill="#8B8B8B"
        />
        <path
          d="M90 0.109375L92.2451 5.48369H99.5106L93.6327 8.80519L95.8779 14.1795L90 10.858L84.1221 14.1795L86.3673 8.80519L80.4894 5.48369H87.7549L90 0.109375Z"
          fill="#8B8B8B"
        />
      </svg>
    ),
    4: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100"
        height="17"
        viewBox="0 0 100 17"
        fill="none"
        style={starStyles}
      >
        <path
          d="M10 0.554688L12.2451 5.929H19.5106L13.6327 9.25051L15.8779 14.6248L10 11.3033L4.12215 14.6248L6.36729 9.25051L0.489435 5.929H7.75486L10 0.554688Z"
          fill="#CFD72A"
        />
        <path
          d="M30 0.554688L32.2451 5.929H39.5106L33.6327 9.25051L35.8779 14.6248L30 11.3033L24.1221 14.6248L26.3673 9.25051L20.4894 5.929H27.7549L30 0.554688Z"
          fill="#CFD72A"
        />
        <path
          d="M50 0.554688L52.2451 5.929H59.5106L53.6327 9.25051L55.8779 14.6248L50 11.3033L44.1221 14.6248L46.3673 9.25051L40.4894 5.929H47.7549L50 0.554688Z"
          fill="#CFD72A"
        />
        <path
          d="M70 0.554688L72.2451 5.929H79.5106L73.6327 9.25051L75.8779 14.6248L70 11.3033L64.1221 14.6248L66.3673 9.25051L60.4894 5.929H67.7549L70 0.554688Z"
          fill="#CFD72A"
        />
        <path
          d="M90 0.554688L92.2451 5.929H99.5106L93.6327 9.25051L95.8779 14.6248L90 11.3033L84.1221 14.6248L86.3673 9.25051L80.4894 5.929H87.7549L90 0.554688Z"
          fill="#8B8B8B"
        />
      </svg>
    ),
    5: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100"
        height="16"
        viewBox="0 0 100 16"
        fill="none"
        style={starStyles}
      >
        <path
          d="M10 0L12.2451 5.37431H19.5106L13.6327 8.69582L15.8779 14.0701L10 10.7486L4.12215 14.0701L6.36729 8.69582L0.489435 5.37431H7.75486L10 0Z"
          fill="#38B000"
        />
        <path
          d="M30 0L32.2451 5.37431H39.5106L33.6327 8.69582L35.8779 14.0701L30 10.7486L24.1221 14.0701L26.3673 8.69582L20.4894 5.37431H27.7549L30 0Z"
          fill="#38B000"
        />
        <path
          d="M50 0L52.2451 5.37431H59.5106L53.6327 8.69582L55.8779 14.0701L50 10.7486L44.1221 14.0701L46.3673 8.69582L40.4894 5.37431H47.7549L50 0Z"
          fill="#38B000"
        />
        <path
          d="M70 0L72.2451 5.37431H79.5106L73.6327 8.69582L75.8779 14.0701L70 10.7486L64.1221 14.0701L66.3673 8.69582L60.4894 5.37431H67.7549L70 0Z"
          fill="#38B000"
        />
        <path
          d="M90 0L92.2451 5.37431H99.5106L93.6327 8.69582L95.8779 14.0701L90 10.7486L84.1221 14.0701L86.3673 8.69582L80.4894 5.37431H87.7549L90 0Z"
          fill="#38B000"
        />
      </svg>
    ),
  };

  return rating ? starSvgs[rating] || NoRating() : NoRating();
};
const SortableHeader = ({ label, sortColumn, sortOrder, handleSort }) => {
  return (
    <div
      style={{
        display: "inline-flex",
        alignItems: "center",
        gap: "3px",
        whiteSpace: "nowrap",
        width: "100px",
      }}
    >
      <span>{label}</span>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <FaSortUp
          onClick={() => handleSort(label, "asc")}
          style={{
            cursor: "pointer",
            width: "10px",
            color:
              sortColumn === label && sortOrder === "asc" ? "yellow" : "white",
          }}
        />
        <FaSortDown
          onClick={() => handleSort(label, "desc")}
          style={{
            cursor: "pointer",
            width: "10px",
            marginTop: "-3px",
            color:
              sortColumn === label && sortOrder === "desc" ? "yellow" : "white",
          }}
        />
      </div>
    </div>
  );
};

const Table = ({ headers, data, driverId }) => {
  const [selectedRow, setSelectedRow] = useState(null); // Track selected row

  return (
    <div className="third_table_containers">
      <div className="third_table_scroll">
        <table className="third_table">
          <thead>
            <tr className="table3head">
              {headers.map((header, index) => (
                <th
                  key={index}
                  className="table3head"
                  style={{ textAlign: "center", verticalAlign: "middle" }}
                >
                  {header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <TableItem
                key={index}
                item={item}
                index={index} // Pass row index
                driverId={driverId} // Pass driverId here
                selectedRow={selectedRow}
                setSelectedRow={setSelectedRow}
              />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const ThirdTable = () => {
  const { driverId } = useParams();

  const [sortColumn, setSortColumn] = useState(null);
  const [sortOrder, setSortOrder] = useState("asc");
  const handleSort = (column, order) => {
    setSortColumn(column);
    setSortOrder(order);

    const sortedData = [...filteredData].sort((a, b) => {
      let valueA = a[column];
      let valueB = b[column];

      // Convert date fields to Date objects for proper sorting
      if (column.includes("Date")) {
        valueA = new Date(valueA);
        valueB = new Date(valueB);
      }

      if (valueA < valueB) return order === "asc" ? -1 : 1;
      if (valueA > valueB) return order === "asc" ? 1 : -1;
      return 0;
    });

    setFilteredData(sortedData);
  };

  const headers = [
    "Sr No.",
    <SortableHeader
      label="Booking ID"
      sortColumn={sortColumn}
      sortOrder={sortOrder}
      handleSort={handleSort}
    />,
    <SortableHeader
      label="From Date"
      sortColumn={sortColumn}
      sortOrder={sortOrder}
      handleSort={handleSort}
    />,
    <SortableHeader
      label="To Date"
      sortColumn={sortColumn}
      sortOrder={sortOrder}
      handleSort={handleSort}
    />,
    <SortableHeader
      label="Booking Type"
      sortColumn={sortColumn}
      sortOrder={sortOrder}
      handleSort={handleSort}
    />,
    <SortableHeader
      label="Pickup City"
      sortColumn={sortColumn}
      sortOrder={sortOrder}
      handleSort={handleSort}
    />,
    <SortableHeader
      label="Destination"
      sortColumn={sortColumn}
      sortOrder={sortOrder}
      handleSort={handleSort}
    />,
    <SortableHeader
      label="No of Days"
      sortColumn={sortColumn}
      sortOrder={sortOrder}
      handleSort={handleSort}
    />,
    <SortableHeader
      label="Car Type"
      sortColumn={sortColumn}
      sortOrder={sortOrder}
      handleSort={handleSort}
    />,
    <SortableHeader
      label="Trip Type"
      sortColumn={sortColumn}
      sortOrder={sortOrder}
      handleSort={handleSort}
    />,
    <SortableHeader
      label="Sub Trip Type"
      sortColumn={sortColumn}
      sortOrder={sortOrder}
      handleSort={handleSort}
    />,
    <SortableHeader
      label="Status"
      sortColumn={sortColumn}
      sortOrder={sortOrder}
      handleSort={handleSort}
    />,
    "Km Included",
    "Hrs Included",
    "Base Fare",
    "Night Charge",
    "Actual Km",
    "Actual Hrs",
    "Per Km Charge",
    "Per Hrs Charge",
    "Extra Km",
    "Extra Hrs",
    "Extra Km Charge",
    "Extra Hrs Charge",
    <>
      <span>Extra Night Charge</span>
      <br />
      <span style={{ fontSize: "10px" }}>(If Any)</span>
    </>,
    <>
      <span>Parking Charge</span>
      <br />
      <span style={{ fontSize: "10px" }}>(If Any)</span>
    </>,
    <>
      <span>Toll Charge</span>
      <br />
      <span style={{ fontSize: "10px" }}>(If Any)</span>
    </>,
    <>
      <span>State Tax</span>
      <br />
      <span style={{ fontSize: "10px" }}>(If Any)</span>
    </>,
    "Total Earning",
    <>
      <span>Less TDS </span>
      <br />
      <span style={{ fontSize: "10px" }}>(2%)</span>
    </>,
    <>
      <span>Less Cash Payment</span>
      <br />
      <span style={{ fontSize: "10px" }}>Received (if any)</span>
    </>,
    <>
      <span>Less Penalty</span>
      <br />
      <span style={{ fontSize: "10px" }}>Received (if any)</span>
    </>,
    <>
      <span>Net Balance</span>
      <br />
      <span style={{ fontSize: "10px" }}>Amount To Pay (if any)</span>
    </>,
    <>
      <span>Excess Amount </span>
      <br />
      <span style={{ fontSize: "10px" }}>(if any)</span>
    </>,
    "Customer Rating",
    "View More Details",
  ];

  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [locationOptions, setLocationOptions] = useState([]);
  const [carCategoryOptions, setCarCategoryOptions] = useState([]);
  const [tripTypeOptions, setTripTypeOptions] = useState([]);
  const [subTripTypeOptions, setSubTripTypeOptions] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState("");
  const [selectedCarType, setSelectedCarType] = useState("");
  const [selectedTripType, setSelectedTripType] = useState("");
  const [selectedSubTripType, setSelectedSubTripType] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [searchBookingId, setSearchBookingId] = useState("");

  const getStarRating = (rating) => {
    if (rating === null || rating === undefined) return "No Rating";
    const stars = "⭐".repeat(Math.round(rating)); // Repeat stars based on rating
    return stars || "No Rating"; // If rating is 0, show "No Rating"
  };
  const fetchEarningData = async () => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}/v1/admin/drivers/${driverId}/earning-data`
      );
      console.log("DRIVER DATA>>>>>>>", response);

      if (!response.data || !response.data.data) {
        throw new Error("Invalid response structure");
      }

      const { fares, rating, bookings, totalEarnings } = response.data.data;
      const ratingMap = {
        5: 5,
        4: 4,
        3: 3,
        2: 2,
        1: 1,
        0: "No Rating",
        null: "No Rating",
        undefined: "No Rating",
      };

      const starRating = ratingMap[Math.round(rating)] || 0;

      if (!bookings || !Array.isArray(bookings)) {
        throw new Error("Bookings data is not in expected format");
      }

      // Define color mapping for statuses
      const statusColors = {
        "Cab Assigned": "#00BCBC",
        Completed: "#38B000", // Green
        Cancelled: "#FC0F0F", // Red
        "In Route": "#AD00FF", // Purple
        Upcoming: "#1B07FA", // Blue
      };

      const tempData = bookings.map((item, index) => {
        let baseFare = "N/A";

        if (item.tripType === "Airport") {
          baseFare = fares?.airport?.baseFare
            ? `Rs. ${fares.airport.baseFare}`
            : "N/A";
        } else if (item.tripType === "Local") {
          baseFare =
            fares?.local?.[item.subTripType] !== undefined
              ? `Rs. ${fares.local[item.subTripType]}`
              : "N/A";
        } else if (item.tripType === "Outstation") {
          baseFare = fares?.outstation?.minKmPerDay
            ? `Rs. ${fares.outstation.minKmPerDay * fares.outstation.perKmFare}`
            : "N/A";
        }

        const tripStatus = item.status || "";
        const isCancelled = tripStatus.toLowerCase() === "cancelled";
        const isUpcoming = tripStatus.toLowerCase() === "upcoming";
        const isInRoute = tripStatus.toLowerCase() === "in route";
        const statusColor = statusColors[tripStatus] || "#000000"; // Default to white if status is unknown

        return {
          "Sr No.": index + 1,
          "Booking ID": item.bookingId,
          "From Date": `\u00A0\u00A0${item.fromDate}\u00A0\u00A0`,
          "To Date":
            ["Local", "Airport"].includes(item.tripType) ||
            (item.tripType === "Outstation" && item.subTripType === "One Way")
              ? `\u00A0\u00A0${item.fromDate}\u00A0\u00A0`
              : `\u00A0\u00A0${item.toDate}\u00A0\u00A0`,

          "Booking Type": item.bookingType || "",
          "Pickup City": item.pickupCity || "",
          Destination:
            item.tripType === "Local"
              ? item.pickupCity
              : item.destination?.join(", ") || "",

          "No of Days": (() => {
            if (
              ["Local", "Airport"].includes(item.tripType) ||
              (item.tripType === "Outstation" && item.subTripType === "One Way")
            ) {
              return 1;
            }

            const parseDate = (dateStr) => {
              if (!dateStr || dateStr.toLowerCase() === "undefined")
                return null; // Handle undefined cases
              const [day, month, year] = dateStr.split("-").map(Number);
              return new Date(year, month - 1, day); // JavaScript months are 0-based (Jan = 0)
            };

            let fromDate = parseDate(item.fromDate);
            let toDate = parseDate(item.toDate);

            if (!fromDate || !toDate || isNaN(fromDate) || isNaN(toDate)) {
              console.log(
                "Invalid Date for booking:",
                item.bookingId,
                item.fromDate,
                item.toDate
              );
              return ""; // Prevent NaN
            }

            return Math.ceil((toDate - fromDate) / (1000 * 60 * 60 * 24)) + 1;
          })(),

          "Car Type": item.carType || "",
          "Trip Type": item.tripType || "",
          "Sub Trip Type": item.subTripType || "",
          Status: (
            <span style={{ color: statusColor, fontWeight: "bold" }}>
              {tripStatus}
            </span>
          ),
          "Km Included":
            isCancelled || isUpcoming ? (
              item.status
            ) : item.kmIncluded ? (
              <span>
                {item.kmIncluded}{" "}
                <span style={{ fontWeight: "normal" }}>KM</span>
              </span>
            ) : (
              ""
            ),

          "Hrs Included":
            item.tripType === "Outstation" ? (
              "N/A"
            ) : isCancelled || isUpcoming ? (
              item.status
            ) : item.hrsIncluded ? (
              <span>
                {String(item.hrsIncluded).padStart(2, "0")}{" "}
                <span style={{ fontWeight: "normal" }}>Hrs</span>
              </span>
            ) : (
              ""
            ),
          "Base Fare":
            item.tripType === "Outstation" ? (
              "N/A"
            ) : item.baseFare ? (
              <span>Rs. {Number(item.baseFare).toLocaleString("en-IN")}</span>
            ) : (
              "Rs. 0"
            ),

          "Night Charge": isCancelled ? (
            "Cancelled"
          ) : isUpcoming ? (
            "Upcoming"
          ) : item.nightCharge ? (
            <span>Rs. {Number(item.nightCharge).toLocaleString("en-IN")}</span>
          ) : (
            "Rs. 0"
          ),
          "Actual Km": isCancelled ? (
            "Cancelled"
          ) : isUpcoming ? (
            "Upcoming"
          ) : isInRoute ? (
            "In Route"
          ) : item.actualKm ? (
            <span>
              {item.actualKm} <span>KM</span>
            </span>
          ) : (
            "0 KM"
          ),

          "Actual Hrs":
            item.tripType === "Outstation"
              ? "N/A"
              : isCancelled
              ? "Cancelled"
              : isUpcoming
              ? "Upcoming"
              : isInRoute
              ? "In Route"
              : item.actualHrs
              ? `${String(item.actualHrs).padStart(2, "0")} Hrs`
              : "N/A",

          "Per Km Charge": isCancelled
            ? "Cancelled"
            : `Rs. ${item.perKmCharge}`,
          "Per Hrs Charge":
            item.tripType === "Outstation"
              ? "N/A"
              : isCancelled
              ? "Cancelled"
              : `Rs. ${item.perHrsCharge}`,
          "Extra Km": isCancelled
            ? "Cancelled"
            : isUpcoming
            ? "Upcoming"
            : isInRoute
            ? "In Route"
            : item.extraKm
            ? `${item.extraKm} KM`
            : "0 KM",

          "Extra Hrs":
            item.tripType === "Outstation"
              ? "N/A"
              : isCancelled
              ? "Cancelled"
              : isUpcoming
              ? "Upcoming"
              : isInRoute
              ? "In Route"
              : item.extraHrs
              ? `${String(item.extraHrs).padStart(2, "0")} Hrs`
              : "0 Hrs",

          "Extra Km Charge": isCancelled
            ? "Cancelled"
            : isUpcoming
            ? "Upcoming"
            : isInRoute
            ? "In Route"
            : item.extraKmCharge
            ? `Rs. ${Number(item.extraKmCharge).toLocaleString("en-IN")}`
            : "Rs. 0",

          "Extra Hrs Charge":
            item.tripType === "Outstation"
              ? "N/A"
              : isCancelled
              ? "Cancelled"
              : isUpcoming
              ? "Upcoming"
              : isInRoute
              ? "In Route"
              : item.extraHrsCharge
              ? `Rs. ${Number(item.extraHrsCharge).toLocaleString("en-IN")}`
              : "Rs. 0",

          "Extra Night Charge": isCancelled
            ? "Cancelled"
            : isUpcoming
            ? "Upcoming"
            : isInRoute
            ? "In Route"
            : item.extraNightCharge
            ? `Rs. ${item.extraNightCharge}`
            : "N/A",
          "Parking Charge": isCancelled
            ? "Cancelled"
            : isUpcoming
            ? "Upcoming"
            : isInRoute
            ? "In Route"
            : item.parkingCharge
            ? `Rs. ${item.parkingCharge}`
            : "N/A",
          "Toll Charge": isCancelled
            ? "Cancelled"
            : isUpcoming
            ? "Upcoming"
            : isInRoute
            ? "In Route"
            : item.tollCharge
            ? `Rs. ${item.tollCharge}`
            : "N/A",
          "State Tax": isCancelled
            ? "Cancelled"
            : isUpcoming
            ? "Upcoming"
            : isInRoute
            ? "In Route"
            : item.stateTax
            ? `Rs. ${item.stateTax}`
            : "N/A",
          "Total Earning": isCancelled
            ? "Cancelled"
            : isUpcoming
            ? "Upcoming"
            : isInRoute
            ? "In Route"
            : totalEarnings
            ? `Rs. ${Number(totalEarnings).toLocaleString("en-IN")}`
            : "",

          "Less TDS (2%)": isCancelled
            ? "Cancelled"
            : isUpcoming
            ? "Upcoming"
            : isInRoute
            ? "In Route"
            : item.lessTDS
            ? `Rs. ${item.lessTDS}`
            : "",
          "Less Cash Payment (Received off driver)": isCancelled
            ? "Cancelled"
            : item.lessCashPayment
            ? `Rs. ${Number(item.lessCashPayment).toLocaleString("en-IN")}`
            : "N/A",

          "Less Penalty": isCancelled
            ? "Cancelled"
            : isUpcoming
            ? "Upcoming"
            : isInRoute
            ? "In Route"
            : item.lessPenalty
            ? `Rs. ${item.lessPenalty}`
            : "N/A",
          "Net Balance (Amount to Pay by/ to driver)": isCancelled
            ? "Cancelled"
            : isUpcoming
            ? "Upcoming"
            : isInRoute
            ? "In Route"
            : item.netBalance
            ? `Rs. ${item.netBalance}`
            : "N/A",
          "Excess Amount (if any)": isCancelled
            ? "Cancelled"
            : isUpcoming
            ? "Upcoming"
            : isInRoute
            ? "In Route"
            : item.excessAmount
            ? `Rs. ${item.excessAmount}`
            : "N/A",
          "Customer Rating": item?.customerRating ? (
            <StarRating rating={item.customerRating} />
          ) : (
            NoRating()
          ),
        };
      });

      // Ensure at least 20 rows are displayed
      while (tempData.length < 20) {
        tempData.push({
          "Sr No.": tempData.length + 1,
          "Booking ID": "—",
          "From Date": "—",
          "To Date": "—",
          "Booking Type": "—",
          "Pickup City": "—",
          Destination: "—",
          "No of Days": "—",
          "Car Type": "—",
          "Trip Type": "—",
          "Sub Trip Type": "—",
          Status: "—",
          "Km Included": "—",
          "Hrs Included": "—",
          "Base Fare": "—",
          "Night Charge": "—",
          "Actual Km": "—",
          "Actual Hrs": "—",
          "Per Km Charge": "—",
          "Per Hrs Charge": "—",
          "Extra Km": "—",
          "Extra Hrs": "—",
          "Extra Km Charge": "—",
          "Extra Hrs Charge": "—",
          "Extra Night Charge": "—",
          "Parking Charge": "—",
          "Toll Charge": "—",
          "State Tax": "—",
          "Total Earning": "—",
          "Less TDS (2%)": "—",
          "Less Cash Payment": "—",
          "Less Penalty": "—",
          "Net Balance": "—",
          "Excess Amount": "—",
          "Customer Rating": "—",
        });
      }

      setData(tempData);
      setFilteredData(tempData);
    } catch (error) {
      console.error("Error fetching earnings data:", error);
      toast.error(`Failed to get Earning Report! ${error.message}`);
    }
  };

  const exportToPDF = () => {
    const { jsPDF } = require("jspdf");
    require("jspdf-autotable");

    const doc = new jsPDF();

    filteredData.forEach((item, index) => {
      doc.text(`Booking ${index + 1}`, 14, 10 + index * 10);

      const tableData = Object.entries(item).map(([key, value]) => [
        key,
        value || "",
      ]);

      doc.autoTable({
        startY: 15 + index * 60, // Adjust space between bookings
        head: [["Field", "Value"]],
        body: tableData,
        styles: { fontSize: 10 },
        headStyles: { fillColor: [22, 160, 133] },
      });
    });

    doc.save("EarningsReport_Transposed.pdf");
  };

  const exportToExcel = () => {
    const XLSX = require("xlsx");

    // Extract headers and table data
    const tableHeaders = headers.map((header) =>
      typeof header === "object" ? header.props.children[0] : header
    );

    const tableData = filteredData.map((item) =>
      headers.map(
        (header) =>
          item[
            typeof header === "object" ? header.props.children[0] : header
          ] || ""
      )
    );

    // Create worksheet and workbook
    const worksheet = XLSX.utils.aoa_to_sheet([tableHeaders, ...tableData]);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Earnings Data");

    // Save as Excel file
    XLSX.writeFile(workbook, "EarningsReport.xlsx");
  };

  const fetchFilterOptions = async () => {
    try {
      const [locationsRes, carCategoriesRes, tripTypesRes] = await Promise.all([
        axios.get(`${API_BASE_URL}/v1/locations`),
        axios.get(`${API_BASE_URL}/v1/car-categories`),
        axios.get(`${API_BASE_URL}/v1/trip-types`),
      ]);

      setLocationOptions(
        locationsRes.data.data.map((item) => ({
          label: item.location,
          value: item.location,
        }))
      );
      setCarCategoryOptions(
        carCategoriesRes.data.data.map((item) => ({
          label: item.category,
          value: item.category,
        }))
      );
      setTripTypeOptions(
        tripTypesRes.data.data.map((item) => ({
          label: item.tripType,
          value: item.tripType,
        }))
      );
    } catch (error) {
      console.error("Error fetching filter options:", error);
    }
  };

  const fetchSubTripTypes = async (tripType) => {
    try {
      const response = await axios.get(
       `${API_BASE_URL}/v1/sub-trip-types/${tripType}`
      );
      setSubTripTypeOptions(
        response.data.data[0].subTripTypes.map((item) => ({
          label: item,
          value: item,
        }))
      );
    } catch (error) {
      console.error("Error fetching sub-trip types:", error);
    }
  };

  const handleFilterChange = () => {
    const filtered = data.filter((item) => {
      return (
        (!selectedLocation || item["Pickup City"] === selectedLocation) &&
        (!selectedCarType || item["Car Type"] === selectedCarType) &&
        (!selectedTripType || item["Trip Type"] === selectedTripType) &&
        (!selectedSubTripType ||
          item["Sub Trip Type"] === selectedSubTripType) &&
        (!selectedDate || item["From Date"].startsWith(selectedDate)) &&
        (!searchBookingId ||
          item["Booking ID"]
            ?.toString()
            .toLowerCase()
            .includes(searchBookingId.toLowerCase()))
      );
    });
    setFilteredData(filtered);
  };

  useEffect(() => {
    fetchEarningData();
    fetchFilterOptions();
  }, [driverId]);

  useEffect(() => {
    handleFilterChange();
  }, [
    selectedLocation,
    selectedCarType,
    selectedTripType,
    selectedSubTripType,
    selectedDate,
    searchBookingId,
  ]);

  useEffect(() => {
    if (selectedTripType) {
      fetchSubTripTypes(selectedTripType);
    } else {
      setSubTripTypeOptions([]);
    }
  }, [selectedTripType]);

  return (
    <>
      <Toaster />
      <div
        className="bookingfilter-container"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <div className="filter-header-third">
          <span>Filters</span>
          <CiFilter size={18} color="#38b000" />
        </div>
        <div className="bookingfilters">
          <select
            className="filter-dropdown"
            value={selectedLocation}
            onChange={(e) => setSelectedLocation(e.target.value)}
          >
            <option value="">Select Location</option>
            {locationOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
          <select
            className="filter-dropdown"
            value={selectedCarType}
            onChange={(e) => setSelectedCarType(e.target.value)}
          >
            <option value="">Select Car Type</option>
            {carCategoryOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
          <select
            className="filter-dropdown"
            value={selectedTripType}
            onChange={(e) => setSelectedTripType(e.target.value)}
          >
            <option value="">Select Trip Type</option>
            {tripTypeOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
          {/* filter */}
          <select
            className="filter-dropdown"
            value={selectedSubTripType}
            onChange={(e) => setSelectedSubTripType(e.target.value)}
          >
            <option value="">Select Sub Trip Type</option>
            {subTripTypeOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
          <input
            type="date"
            className="filter-calender"
            value={selectedDate}
            onChange={(e) => setSelectedDate(e.target.value)}
          />
          <input
            type="text"
            placeholder="Search by Booking ID"
            value={searchBookingId}
            onChange={(e) => setSearchBookingId(e.target.value)}
            className="filter-search-thirdtable"
          />
        </div>
        <div className="Export-buttons">
          <span>Export as</span>

          <button
            onClick={exportToExcel}
            className="icon-button"
            title="Export as Excel"
          >
            <FaFileExcel size={24} color="green" />
          </button>
          <button
            onClick={exportToPDF}
            className="icon-button"
            title="Export as PDF"
          >
            <FaFilePdf size={24} color="red" />
          </button>
        </div>
      </div>
      <div className="table-wrapper" style={{ overflowX: "auto" }}>
        <Table headers={headers} data={filteredData} driverId={driverId} />
      </div>
    </>
  );
};

export default ThirdTable;
