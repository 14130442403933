import React, { useEffect, useState } from "react";
import { FaLongArrowAltRight } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import {
  Link,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import DropdownColumn from "../Booking/Dropdown";
import Filter from "../Booking/Filter";
import ActiveCity from "./activecity/ActiveCity";
import AddNewCity from "./AddNewCity";
import "./Geography.css";
import {
  fetchactivecity,
  fetchinactivecity,
  fetchviewallcityAsync,
  resetCompState,
  selectAllActiveCity,
  selectInActiveCity,
  selectViewAllCity,
} from "./geographySlice";
import InactiveCity from "./InactiveCity";
import ViewAllCityPage from "./ViewAllCityPage";

const Geography = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedCity, setSelectedCity] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [selectedAirport, setSelectedAirport] = useState("");
  const [filteredCities, setFilteredCities] = useState([]);
  const [filteredActiveCities, setFilteredActiveCities] = useState([]);
  const [filteredInactiveCities, setFilteredInactiveCities] = useState([]);

  const allcity = useSelector(selectViewAllCity);
  const activecities = useSelector(selectAllActiveCity);
  const inactivecities = useSelector(selectInActiveCity);

  const { currentComp } = useSelector((state) => state.geography);

  // Function to get unique options
  const extractOptions = (dataArray, message) => {
    if (Array.isArray(dataArray)) {
      return [...new Set(dataArray.map((item) => item[message]))].map(
        (item) => ({
          label: item,
          value: item,
        })
      );
    }
    return [];
  };
  const [filteredCityOptions, setFilteredCityOptions] = useState([]);
  // Extract unique options for states and airports
  const stateOptions = extractOptions(allcity, "state");
  const airportOptions = extractOptions(allcity, "airportName");

  useEffect(() => {
    console.log("Selected State:", selectedState);

    if (selectedState) {
      const filteredOptions = extractOptions(
        allcity.filter((city) => city.state === selectedState),
        "city"
      );
      setFilteredCityOptions(filteredOptions);
    } else {
      setFilteredCityOptions([]);
    }

    console.log("Filtered City Options:", filteredCityOptions);
  }, [selectedState, allcity]);

  useEffect(() => {
    dispatch(fetchviewallcityAsync());
    dispatch(fetchactivecity());
    dispatch(fetchinactivecity());
    if (currentComp !== null) {
      navigate(`/geography/${currentComp.toLowerCase().replace(/\s+/g, "-")}`);
      dispatch(resetCompState(null));
    } else if (
      location.pathname === "/geography" ||
      location.pathname === "/geography/"
    ) {
      navigate("/geography/view-all-city");
    }
  }, [dispatch, currentComp, navigate, location.pathname]);

  const handleGeographyButtonClick = (buttonType) => {
    navigate(`/geography/${buttonType.toLowerCase().replace(/\s+/g, "-")}`);
  };

  useEffect(() => {
    const path = location.pathname.split("/").pop().replace(/-/g, " ");
    if (path) {
      setSelectedCity("");
      setSelectedState("");
      setSelectedAirport("");
      setFilteredCities([]);
      setFilteredActiveCities([]);
      setFilteredInactiveCities([]);
    }
  }, [location.pathname]);

  useEffect(() => {
    if (allcity.length > 0) {
      const cities = allcity.filter((object) => {
        return (
          (!selectedCity || object.city === selectedCity) &&
          (!selectedState || object.state === selectedState) &&
          (!selectedAirport || object.airportName === selectedAirport)
        );
      });
      setFilteredCities(cities);
    }

    if (activecities.length > 0) {
      const cities = activecities.filter((object) => {
        return (
          (!selectedCity || object.city === selectedCity) &&
          (!selectedState || object.state === selectedState) &&
          (!selectedAirport || object.airportName === selectedAirport)
        );
      });
      setFilteredActiveCities(cities);
    }

    if (inactivecities.length > 0) {
      const cities = inactivecities.filter((object) => {
        return (
          (!selectedCity || object.city === selectedCity) &&
          (!selectedState || object.state === selectedState) &&
          (!selectedAirport || object.airportName === selectedAirport)
        );
      });
      setFilteredInactiveCities(cities);
    }
  }, [
    selectedCity,
    selectedState,
    selectedAirport,
    allcity,
    activecities,
    inactivecities,
  ]);

  const capitalizeWords = (str) => {
    return str.replace(/\b\w/g, (char) => char.toUpperCase());
  };

  return (
    <div className="carmangement-header" style={{ width: "100%" }}>
      <div className="booking">
        <div className="header">
          <div className="header-text">
            <Link to="/dashboard" className="h3-booking">
              Geography
            </Link>{" "}
            <FaLongArrowAltRight />{" "}
            <Link to="/geography" className="h2-booking">
              {capitalizeWords(
                location.pathname.split("/").pop().replace(/-/g, " ")
              )}
            </Link>
          </div>
          <div className="btns">
            <button
              onClick={() => handleGeographyButtonClick("View All City")}
              className={
                location.pathname.endsWith("/view-all-city")
                  ? "createButton-cars"
                  : "createButton"
              }
            >
              View All City
            </button>
            <button
              onClick={() => handleGeographyButtonClick("Active City")}
              className={
                location.pathname.endsWith("/active-city")
                  ? "createButton-cars"
                  : "createButton"
              }
            >
              Active City
            </button>
            <button
              onClick={() => handleGeographyButtonClick("Inactive City")}
              className={
                location.pathname.endsWith("/inactive-city")
                  ? "createButton-cars"
                  : "createButton"
              }
            >
              Inactive City
            </button>
            <button
              onClick={() => handleGeographyButtonClick("Add New City")}
              className={
                location.pathname.endsWith("/add-new-city")
                  ? "createButton-cars"
                  : "createButton"
              }
            >
              Add New City
            </button>
          </div>
        </div>
      </div>
      {location.pathname !== "/geography/add-new-city" && (
        <div className="dropdown-container">
          <Filter />

          <DropdownColumn
            placeholder="Search State"
            options={stateOptions}
            selectedValue={selectedState}
            handleChange={(e) => setSelectedState(e.target.value)}
            className="dropdown dropdown-custom"
          />

          <DropdownColumn
            placeholder="Search City"
            options={filteredCityOptions}
            selectedValue={selectedCity}
            handleChange={(e) => setSelectedCity(e.target.value)}
            className="dropdown state-drop"
          />
        </div>
      )}

      <div>
        <Routes>
          <Route
            path="view-all-city"
            element={<ViewAllCityPage viewallcitydata={filteredCities} />}
          />
          <Route
            path="active-city"
            element={<ActiveCity data={filteredActiveCities} />}
          />
          <Route
            path="inactive-city"
            element={<InactiveCity data={filteredInactiveCities} />}
          />
          <Route path="add-new-city" element={<AddNewCity />} />
        </Routes>
      </div>
    </div>
  );
};

export default Geography;
