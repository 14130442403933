import React from "react";
import "./Fourthtable.css";
import { useDispatch, useSelector } from "react-redux";
import { selectbookingDetails } from "../DetailsSlice";

const FourthExtertable = ({ data }) => {
  console.log("data is:", data);
  const bookingDetails = useSelector(selectbookingDetails);

  // Ensure data is not null or undefined
  const pickupDateString = data?.data?.pickupDate || "0-0-0";
  const returnDateString = data?.data?.returnDate || "0-0-0";

  // Parse date strings into Date objects
  const pickupDateParts = pickupDateString.split("-").reverse();
  const returnDateParts = returnDateString.split("-").reverse();

  // Create Date objects with fallbacks
  const pickupDate = new Date(
    pickupDateParts[0],
    pickupDateParts[1] - 1,
    pickupDateParts[2] || 0
  );
  const returnDate = new Date(
    returnDateParts[0],
    returnDateParts[1] - 1,
    returnDateParts[2] || 0
  );

  // Calculate difference in days
  const differenceInMs = returnDate - pickupDate;
  const differenceInDays = differenceInMs / (1000 * 60 * 60 * 24);

  // Ensure all other fields have default values if missing
  const totaldistancetravelled = data?.extraCoverage?.totalDistanceCovered ?? "0";
  const extraKm =data?.extraCoverage?.extraKm??0;
  const noofdays = data?.extraCoverage?.actualNoOfDays||"N/A";
  const actualHrs = data?.extraCoverage?.totalHoursCovered ?? "0";
  const nightDriverAllowanceRate = data?.extraFare?.nightDriverAllowanceRate;
  const extraNightAllowance=data?.extraFare?.extraNightAllowance

  const extraHrs = data?.extraCoverage?.extraHrs ?? "0";
  // const extraDays =
  //   data.data?.extraHrs >= 24 ? Math.floor(data.data?.extraHrs / 24) : "0";

  const extraDays = (data?.extraCoverage?.extraDays);

  if (bookingDetails?.tariff?.tripType === "Outstation") {
        if(bookingDetails?.tariff?.subTripType==="One Way") {
          var coverageDetail = [
            {
              lable: "Total Distance Covered",
              value:
                totaldistancetravelled !== "0" ? `${totaldistancetravelled} Km` : "0",
            },
            { lable: "Extra km", value: extraKm !== "0" ? `${extraKm} Km` : "0" },
           
          ];
        } else{

        
        var coverageDetail = [
      {
        lable: "Total Distance Covered",
        value:
          totaldistancetravelled !== "0" ? `${totaldistancetravelled} Km` : "0",
      },
      { lable: "Extra km", value: extraKm !== "0" ? `${extraKm} Km` : "0" },
      {
        lable: "Actual No of Days",
        value: bookingDetails?.tariff?.subTripType === "One Way"?  "N/A":`${noofdays} Days` ,
      },
      {
        lable: "Extra Days",
        value:bookingDetails?.tariff?.subTripType === "One Way"?  "N/A":(extraDays !== "0" ? `${extraDays} Days`:"0") 
      },
    ];}
  } else if (bookingDetails?.tariff?.tripType === "Local") {
    var coverageDetail = [
      {
        lable: "Total Distance Covered",
        value:
          totaldistancetravelled !== "0" ? `${totaldistancetravelled} Km` : "0",
      },
      {
        lable: "Total Hours Covered",
        value: actualHrs !== "0" ? `${actualHrs} Hrs` : "0",
      },
      { lable: "Extra km", value: extraKm !== "0" ? `${extraKm} Km` : "0" },

      {
        lable: "Extra Hours",
        value: extraHrs !== "0" ? `${extraHrs} Hrs` : "0",
      },
      // {
      //   lable: "Night Allowance",
      //   value:
      //     extraNightAllowance != 0
      //       ? `Rs. ${nightDriverAllowanceRate}`
      //       : "N/A",
      // },
    ];
  } else {
    var coverageDetail = [
      {
        lable: "Total Distance Covered",
        value:
          totaldistancetravelled !== "0" ? `${totaldistancetravelled} Km` : "0",
      },
      {
        lable: "Total Hours Covered",
        value: actualHrs !== "0" ? `${actualHrs} Hrs` : "0",
      },
      { lable: "Extra km", value: extraKm !== "0" ? `${extraKm} Km` : "0" },

      {
        lable: "Extra Hours",
        value: extraHrs !== "0" ? `${extraHrs} Hrs` : "0",
      },
      // {
      //   lable: "Night Allowance",
      //   value:
      //   extraNightAllowance !== 0
      //   ? `Rs. ${nightDriverAllowanceRate}`
      //   : "N/A",
      // },
    ];
  }
  return (
    <div className="billing_fourth_containers">
      <div className="billing_fourth_scroll">
        <table className="billing-table-fourth">
          <thead>
            <tr>
              <th  style={{
              textAlign:"center"
            }} colSpan="2">Extra Coverage</th>
            </tr>
          </thead>
          <tbody className="first_billing_table_data">
            {coverageDetail.map((row, index) => (
              <React.Fragment key={index}>
                <tr>
                  <td className="fourth-td">{row.lable}</td>
                  <td>{row.value}</td>
                </tr>
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default FourthExtertable;
