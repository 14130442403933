import { useEffect, useState } from "react";
import { Routes, Route, useNavigate, useLocation, Link } from "react-router-dom";
import AddNewCar from "./AddNewCar";
import ViewAllDrivers from "./ViewAllDrivers";
import BookingHistory from "./BookingHistory";
import InactiveDriver from "./InactiveDriver";
import "./CarManagement.css";
import ApprovalPending from "./ApprovalPending";
import DriverInfo from "./DriverInfo";
import { FaLongArrowAltRight } from "react-icons/fa";
import { useSelector, useDispatch } from "react-redux";
import { resetCompState } from "./DriverDetailsSlice";
import { selectBooleanValue, setBooleanValue } from "./DriverSlice";
import Earning from "./earning/Earning";

const CarManagement = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [activeComponent, setActiveComponent] = useState("view-all-drivers");

  const { currentComp } = useSelector((state) => state.driverInfo);
  const earning_report = useSelector(selectBooleanValue);

  const formatTitle = (str) => {
    return str
      .replace(/-/g, " ")
      .replace(/\b\w/g, (char) => char.toUpperCase());
  };

  useEffect(() => {
    if (location.pathname === "/drivermanagement") {
      navigate("/drivermanagement/view-all-drivers");
    } else {
      const path = location.pathname.split("/").pop();
      setActiveComponent(path);
    }
  }, [navigate, location.pathname]);

  const handleActiveComponent = (type) => {
    setActiveComponent(type);
    if (type === "add-new-car") {
      dispatch(setBooleanValue(true));
    } else {
      dispatch(setBooleanValue(false));
    }
    navigate(`/drivermanagement/${type}`);
  };

  useEffect(() => {
    if (currentComp !== null) {
      setActiveComponent(currentComp);
      dispatch(resetCompState(null));
    }
  }, [currentComp, dispatch]);

  return (
    <div style={{ width: "100%" }}>
      <div className="booking">
        <div className="header">
          <div className="header-text">
            <Link to="/dashboard" className="h3-booking">
              Driver Management
            </Link>
            <FaLongArrowAltRight />
            <Link to="/drivermanagement" className="h2-booking">
              {formatTitle(activeComponent)}
            </Link>
          </div>
          <div>
            <button
              className={
                activeComponent === "view-all-drivers"
                  ? "createButton-cars"
                  : "createButton"
              }
              onClick={() => handleActiveComponent("view-all-drivers")}
            >
              View All Drivers
            </button>
            <button
              className={
                activeComponent === "inactive-driver"
                  ? "createButton-cars"
                  : "createButton"
              }
              onClick={() => handleActiveComponent("inactive-driver")}
            >
              Inactive Driver
            </button>
            <button
              className={
                activeComponent === "approval-pending"
                  ? "createButton-cars"
                  : "createButton"
              }
              onClick={() => handleActiveComponent("approval-pending")}
            >
              Approval Pending
            </button>
            <button
              className={
                activeComponent === "add-new-car"
                  ? "createButton-cars"
                  : "createButton"
              }
              onClick={() => handleActiveComponent("add-new-car")}
            >
              Add New Driver
            </button>
          </div>
        </div>
      </div>

      <div>
        <Routes>
          <Route path="view-all-drivers" element={<ViewAllDrivers />} />
          <Route path="inactive-driver" element={<InactiveDriver />} />
          <Route path="approval-pending" element={<ApprovalPending />} />
          <Route path="add-new-car" element={earning_report ? <AddNewCar /> : <Earning />} />
          <Route path="booking-history" element={<BookingHistory />} />
          <Route path="driver-info" element={<DriverInfo />} />
        </Routes>
      </div>
    </div>
  );
};

export default CarManagement;
