import React, { useEffect, useState } from 'react';
import './thirdtable.css';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import toast, { Toaster } from "react-hot-toast";

const TableItem = ({ item, index }) => {
    return (
        <tr className="third_Earning_table_data">
            <td>{item["Sr No."]}</td>
            <td>{item["Booking ID"]}</td>
            <td>{item["From Date"]}</td>
            <td>{item["To Date"]}</td>
            <td>{item["Pickup City"]}</td>
            <td>{item["Destination"]}</td>
            <td>{item["No of Days"]}</td>
            <td>{item["Car Type"]}</td>
            <td>{item["Trip Type"]}</td>
            <td>{item["Sub Trip Type"]}</td>
            <td>{item["Status"]}</td>
            <td>{item["Est Fare"]}</td>
            <td>{item["Billed Amount"]}</td>
            <td>{item["Cash Collected"]}</td>
            <td>{item["Penalty if any"]}</td>
            <td>{item["Earning Amount"]}</td>
            <td>{item["Customer Rating"]}</td>
        </tr>
    );
};

const Table = ({ headers, data }) => {
    return (
        <div className="third_table_containers">
            <div className="third_table_scroll">
                <table className="third_table">
                    <thead>
                        <tr>
                            {headers.map((header, index) => (
                                <th style={{borderRadius:"0"}} key={index}>{header}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((item, index) => (
                            <TableItem key={index} item={item} index={index} />
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

const ThirdTable = () => {
    const { driverId } = useParams();
    const headers = [
        "Sr No.",
        "Booking ID",
        "From Date",
        "To Date",
        "Pickup City",
        "Destination",
        "No of Days",
        "Car Type",
        "Trip Type",
        "Sub Trip Type",
        "Status",
        "Est Fare",
        "Billed Amount",
        "Cash Collected",
        "Penalty if any",
        "Earning Amount",
        "Customer Rating"
    ];

    const [data, setData] = useState([]); 

    function getStarRating(rating) {
      const fullStars = Math.floor(rating);
      return '⭐'.repeat(fullStars);
    }

    const fetchEarningData = async () => {
        try {
            const response = await axios.get(
                `https://api.yatricabs.com/v1/admin/drivers/${driverId}/earning-data`
            );

            const tempData = response.data.data.bookings;
            const transformedData = [];
            for (let i = 0; i < tempData.length; i++) {
                transformedData.push({
                    "Sr No.": i + 1,
                    "Booking ID": tempData[i].bookingId,
                    "From Date": tempData[i].pickupDate,
                    "To Date": tempData[i].returnDate,
                    "Pickup City": tempData[i].pickupAddress,
                    "Destination": tempData[i].location.city + ', ' + tempData[i].location.state,
                    "No of Days": tempData[i].noOfDays,
                    "Car Type": tempData[i].carCategory.category,
                    "Trip Type": tempData[i].tariff.tripType,
                    "Sub Trip Type": tempData[i].tariff.subTripType,
                    "Status": tempData[i].status,
                    "Est Fare": `Rs. ${tempData[i].estimatedFare}`,
                    "Billed Amount": tempData[i].totalAmount !== undefined ? `Rs. ${tempData[i].totalAmount}` : '',
                    "Cash Collected": tempData[i].cashCollected !== undefined ? `Rs. ${tempData[i].cashCollected}` : '',
                    "Penalty if any": tempData[i].penalty !== undefined ? `Rs. ${tempData[i].penalty}` : '',
                    "Earning Amount": tempData[i].earningAmount !== undefined ? `Rs. ${tempData[i].earningAmount}` : '',
                    "Customer Rating": tempData[i].customerRating !== undefined ? getStarRating(tempData[i].customerRating) : ''
                });
            }
            setData(transformedData); 
        } catch (error) {
            toast.error("Failed to get Earning Report!");
            console.error(error);
        }
    };

    useEffect(() => {
        fetchEarningData();
    }, [driverId]);

    return (
        <>
            <Toaster />
            <Table headers={headers} data={data} />
        </>
    );
};

export default ThirdTable;
