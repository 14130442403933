import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  fetchAllDriver,
  fetchApprovalData,
  fetchInactivelData,
} from "./DriverAPI";
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const AllinitialState = {
  drivers: [], 
  status: null,
  isLoading: false,
  isError: false,
  booleanValue: false,
};

const ApprovalinitialState = {
  Approvals: [],
  status: null,
  isLoading: false,
  isError: false,
};

const InactivelinitialState = {
  Inactives: [],
  status: null,
  isLoading: false,
  isError: false,
};

const createDriver = {
  RegisterDrivers: [],
  status: null,
  isLoading: false,
  isError: false,
};

export const setBooleanValue = (booleanValue) => ({
  type: "driver/setBooleanValue",
  payload: booleanValue,
});

export const fetchDriver = createAsyncThunk("fetchDriver", async () => {
  try {
    const response = await fetchAllDriver();
    // console.log(response);
    return response;
  } catch (e) {
    console.log("Error in fetching Data", e);
    throw e;
  }
});

export const fetchApproval = createAsyncThunk("fetchApproval", async () => {
  try {
    const response = await fetchApprovalData();
    // console.log(response);
    return response;
  } catch (e) {
    console.log("Error in fetching Data", e);
    throw e;
  }
});

export const fetchInactive = createAsyncThunk("fetchInactive", async () => {
  try {
    const response = await fetchInactivelData();
    // console.log(response);
    return response;
  } catch (e) {
    console.log("Error in fetching Data", e);
    throw e;
  }
});

// export const createDriverData = createAsyncThunk(
//   "createDriver",
//   async (data) => {
//     try {
//       const response = await createDriver(data);
//       // console.log(response);
//       return response;
//     } catch (e) {
//       console.log("Error in fetching Data", e);
//       throw e;
//     }
//   }
// );

export const createDriverData = createAsyncThunk(
  "createDriver",
  async (data, { rejectWithValue }) => {
    try {
      const response = await fetch(
        `${API_BASE_URL}/v1/admin/drivers`,
        {
          method: "POST",
          headers: {
            "Content-Type": "multipart/form-data",
          },
          body: JSON.stringify(data),
        }
      );
      const result = await response.json();
      return result;
    } catch (e) {
      console.log("Error in fetching Data", e);
      return rejectWithValue(e.response);
    }
  }
);

const DriverSlice = createSlice({
  name: "driver",
  initialState: AllinitialState,

  reducers: {
    setBooleanValue: (state, action) => {
      state.booleanValue = action.payload;
    },
    toggleDriverStatus: (state, action) => { 
      console.log(state,action)
      const { driverId, newStatus } = action.payload;
      console.log( driverId)
      console.log(newStatus )
      const driverIndex = state.drivers.findIndex(driver => driver._id === driverId);
      if (driverIndex !== -1) {
        state.drivers[driverIndex].status = newStatus;
      }
    },
  },

  extraReducers: (builder) => {
    builder.addCase(fetchDriver.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchDriver.fulfilled, (state, action) => {
      state.isLoading = false;
      state.status = "idle";
      state.drivers = action.payload;
    });
    builder.addCase(fetchDriver.rejected, (state, action) => {
      state.isLoading = false;
      console.log("Error", action.payload);
      state.isError = true;
    });
  },
});

const ApprovalSlice = createSlice({
  name: "Approval",
  initialState: ApprovalinitialState,
  extraReducers: (builder) => {
    builder.addCase(fetchApproval.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchApproval.fulfilled, (state, action) => {
      state.isLoading = false;
      state.status = "idle";
      state.Approvals = action.payload;
    });
    builder.addCase(fetchApproval.rejected, (state, action) => {
      state.isLoading = false;
      console.log("Error", action.payload);
      state.isError = true;
    });
  },
});

const InactiveSlice = createSlice({
  name: "Inactive",
  initialState: InactivelinitialState,

  extraReducers: (builder) => {
    builder.addCase(fetchInactive.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchInactive.fulfilled, (state, action) => {
      state.isLoading = false;
      state.status = "idle";
      state.Inactives = action.payload;
    });
    builder.addCase(fetchInactive.rejected, (state, action) => {
      state.isLoading = false;
      console.log("Error", action.payload);
      state.isError = true;
    });
  },
});

const createDriverSlice = createSlice({
  name: "createDriver",
  initialState: createDriver,
  extraReducers: (builder) => {
    builder.addCase(createDriverData.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(createDriverData.fulfilled, (state, action) => {
      state.isLoading = false;
      state.status = "idle";
      state.RegisterDrivers.push(action.payload);
    });
    builder.addCase(createDriverData.rejected, (state, action) => {
      state.isLoading = false;
      console.log("Error", action.payload);
      state.isError = true;
    });
  },
});

export const selectAllDriver = (state) => state.driver.drivers;
export const selectLoading = (state) => state.driver.isLoading;
export const selectApprovalDriver = (state) => state.Approval.Approvals;
export const selectInactiveDriver = (state) => state.Inactive.Inactives;
export const selectCreateDriver = (state) => state.createDriver.RegisterDrivers;
//export const selectApprovalLoading = (state) => state.driver.isLoading;
export const driverSclice = DriverSlice.reducer;
export const approvalSclice = ApprovalSlice.reducer;
export const inactiveSclice = InactiveSlice.reducer;
export const createDriverSclice = createDriverSlice.reducer;
export const selectBooleanValue = (state) => state.driver.booleanValue;
export const { toggleDriverStatus } = DriverSlice.actions;
