// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.billing_table_containers {
    flex-grow: 2;
    margin: 4px 23px 0px 23px;
    display: flex
}

.billing_table_containers table {
    border-collapse: collapse;
    width: 100%;
    /* padding: 10px; */
    table-layout: auto;
}

.firstdata_billing_table_data td span {
    display: block;
    text-align: center;
    color: #000;

    font-family: Lato;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
}

.first_billing_table_data td:first-child {
    text-align: center;
}

.billing_table_scroll {
    width: 104%;
}

.firstdata_billing_table_data td {
    border: 1px solid black;

    white-space: nowrap;

    color: #000;
    padding: 7px 2px;
    text-align: center;
    font-family: Lato;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    overflow: hidden;
   
    text-overflow: ellipsis;
}`, "",{"version":3,"sources":["webpack://./src/components/Details/tablebilling/TableFirst.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,yBAAyB;IACzB;AACJ;;AAEA;IACI,yBAAyB;IACzB,WAAW;IACX,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,cAAc;IACd,kBAAkB;IAClB,WAAW;;IAEX,iBAAiB;IACjB,eAAe;IACf,kBAAkB;IAClB,gBAAgB;AACpB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,uBAAuB;;IAEvB,mBAAmB;;IAEnB,WAAW;IACX,gBAAgB;IAChB,kBAAkB;IAClB,iBAAiB;IACjB,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,gBAAgB;;IAEhB,uBAAuB;AAC3B","sourcesContent":[".billing_table_containers {\n    flex-grow: 2;\n    margin: 4px 23px 0px 23px;\n    display: flex\n}\n\n.billing_table_containers table {\n    border-collapse: collapse;\n    width: 100%;\n    /* padding: 10px; */\n    table-layout: auto;\n}\n\n.firstdata_billing_table_data td span {\n    display: block;\n    text-align: center;\n    color: #000;\n\n    font-family: Lato;\n    font-size: 13px;\n    font-style: normal;\n    font-weight: 600;\n}\n\n.first_billing_table_data td:first-child {\n    text-align: center;\n}\n\n.billing_table_scroll {\n    width: 104%;\n}\n\n.firstdata_billing_table_data td {\n    border: 1px solid black;\n\n    white-space: nowrap;\n\n    color: #000;\n    padding: 7px 2px;\n    text-align: center;\n    font-family: Lato;\n    font-size: 13px;\n    font-style: normal;\n    font-weight: 600;\n    overflow: hidden;\n   \n    text-overflow: ellipsis;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
