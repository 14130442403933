import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const initialState = {
  driverData: null,
  allDrivers: {
    data: null,
    loading: "idle",
    error: null,
  },
  bookingid: null,
};

export const fetchDriverData1 = createAsyncThunk(
  "assignDriverData/fetchDriverData",
  async ({ id, bookinglocation }) => {
    try {
      console.log("CRED00>>>>>>>>>>>>>>>>>>>>>>", id, bookinglocation); 
      const response = await axios.get(
        `${API_BASE_URL}/v1/admin/bookings/drivers/location?bookingId=${id}&location=${encodeURIComponent(
          bookinglocation
        )}`
      );
      console.log("API Driver Response>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>:", response.data);
      return response.data;
    } catch (error) {
      console.error("Error fetching driver data:", error);
      throw error;
    }
  }
);




const fetchDriverDataSlice = createSlice({
  name: "assignDriverData",
  initialState,
  reducers: {
    assignDriver: (state, action) => {
      state.driverData = action.payload;
    },
    savebookingid: (state, action) => {
      state.bookingid = action.payload;
    },
    updateDriverStatus: (state, action) => {
      const { driverId, status } = action.payload;
      // Safely use optional chaining
      const driver = state.allDrivers?.data?.data?.find(d => d._id === driverId);
      if (driver) {
        driver.available = status;
      }
    }
    
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDriverData1.pending, (state) => {
        state.allDrivers.loading = "loading";
      })
      .addCase(fetchDriverData1.fulfilled, (state, action) => {
        state.allDrivers.loading = "succeeded";
        state.allDrivers.data = action.payload;
      })
      .addCase(fetchDriverData1.rejected, (state, action) => {
        state.allDrivers.data = null;
        state.allDrivers.loading = "failed";
        state.allDrivers.error = action.error.message;
      });
  },
});

export const { assignDriver, savebookingid, updateDriverStatus } = fetchDriverDataSlice.actions;
export default fetchDriverDataSlice.reducer;
