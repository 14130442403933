import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "./popuptable.css";
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const TableItem = ({ item, index, onRowClick }) => {
  const getStatusStyle = (status) => {
    switch (status?.toLowerCase()) {
      case "pending":
        return { color: "#FBAB0E" };
      case "in route":
        return { color: "#F507FA" };
      case "completed":
        return { color: "#38B000" };
      case "confirmed":
        return { color: "blue" };
      case "cancelled":
        return { color: "#FC0F0F" };
      case "cab assigned":
        return { color: "#00BCBC" };
      default:
        return { color: "black" };
    }
  };

  const NoRating = () => <span>No rating available</span>;

// Star Rating Component
const StarRating = ({ rating }) => {
  const starSvgs = {
    1: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100"
        height="16"
        viewBox="0 0 100 16"
        fill="none"
      >
        <path
          d="M10 0.21875L12.2451 5.59306H19.5106L13.6327 8.91457L15.8779 14.2889L10 10.9674L4.12215 14.2889L6.36729 8.91457L0.489435 5.59306H7.75486L10 0.21875Z"
          fill="#FF0000"
        />
        <path
          d="M30 0.21875L32.2451 5.59306H39.5106L33.6327 8.91457L35.8779 14.2889L30 10.9674L24.1221 14.2889L26.3673 8.91457L20.4894 5.59306H27.7549L30 0.21875Z"
          fill="#8B8B8B"
        />
        <path
          d="M50 0.21875L52.2451 5.59306H59.5106L53.6327 8.91457L55.8779 14.2889L50 10.9674L44.1221 14.2889L46.3673 8.91457L40.4894 5.59306H47.7549L50 0.21875Z"
          fill="#8B8B8B"
        />
        <path
          d="M70 0.21875L72.2451 5.59306H79.5106L73.6327 8.91457L75.8779 14.2889L70 10.9674L64.1221 14.2889L66.3673 8.91457L60.4894 5.59306H67.7549L70 0.21875Z"
          fill="#8B8B8B"
        />
        <path
          d="M90 0.21875L92.2451 5.59306H99.5106L93.6327 8.91457L95.8779 14.2889L90 10.9674L84.1221 14.2889L86.3673 8.91457L80.4894 5.59306H87.7549L90 0.21875Z"
          fill="#8B8B8B"
        />
      </svg>
    ),
    2: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100"
        height="17"
        viewBox="0 0 100 17"
        fill="none"
      >
        <path
          d="M10 0.664062L12.2451 6.03837H19.5106L13.6327 9.35988L15.8779 14.7342L10 11.4127L4.12215 14.7342L6.36729 9.35988L0.489435 6.03837H7.75486L10 0.664062Z"
          fill="#FF8C00"
        />
        <path
          d="M30 0.664062L32.2451 6.03837H39.5106L33.6327 9.35988L35.8779 14.7342L30 11.4127L24.1221 14.7342L26.3673 9.35988L20.4894 6.03837H27.7549L30 0.664062Z"
          fill="#FF8C00"
        />
        <path
          d="M50 0.664062L52.2451 6.03837H59.5106L53.6327 9.35988L55.8779 14.7342L50 11.4127L44.1221 14.7342L46.3673 9.35988L40.4894 6.03837H47.7549L50 0.664062Z"
          fill="#8B8B8B"
        />
        <path
          d="M70 0.664062L72.2451 6.03837H79.5106L73.6327 9.35988L75.8779 14.7342L70 11.4127L64.1221 14.7342L66.3673 9.35988L60.4894 6.03837H67.7549L70 0.664062Z"
          fill="#8B8B8B"
        />
        <path
          d="M90 0.664062L92.2451 6.03837H99.5106L93.6327 9.35988L95.8779 14.7342L90 11.4127L84.1221 14.7342L86.3673 9.35988L80.4894 6.03837H87.7549L90 0.664062Z"
          fill="#8B8B8B"
        />
      </svg>
    ),
    3: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100"
        height="16"
        viewBox="0 0 100 16"
        fill="none"
      >
        <path
          d="M10 0.109375L12.2451 5.48369H19.5106L13.6327 8.80519L15.8779 14.1795L10 10.858L4.12215 14.1795L6.36729 8.80519L0.489435 5.48369H7.75486L10 0.109375Z"
          fill="#E3D96F"
        />
        <path
          d="M30 0.109375L32.2451 5.48369H39.5106L33.6327 8.80519L35.8779 14.1795L30 10.858L24.1221 14.1795L26.3673 8.80519L20.4894 5.48369H27.7549L30 0.109375Z"
          fill="#E3D96F"
        />
        <path
          d="M50 0.109375L52.2451 5.48369H59.5106L53.6327 8.80519L55.8779 14.1795L50 10.858L44.1221 14.1795L46.3673 8.80519L40.4894 5.48369H47.7549L50 0.109375Z"
          fill="#E3D96F"
        />
        <path
          d="M70 0.109375L72.2451 5.48369H79.5106L73.6327 8.80519L75.8779 14.1795L70 10.858L64.1221 14.1795L66.3673 8.80519L60.4894 5.48369H67.7549L70 0.109375Z"
          fill="#8B8B8B"
        />
        <path
          d="M90 0.109375L92.2451 5.48369H99.5106L93.6327 8.80519L95.8779 14.1795L90 10.858L84.1221 14.1795L86.3673 8.80519L80.4894 5.48369H87.7549L90 0.109375Z"
          fill="#8B8B8B"
        />
      </svg>
    ),
    4: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100"
        height="17"
        viewBox="0 0 100 17"
        fill="none"
      >
        <path
          d="M10 0.554688L12.2451 5.929H19.5106L13.6327 9.25051L15.8779 14.6248L10 11.3033L4.12215 14.6248L6.36729 9.25051L0.489435 5.929H7.75486L10 0.554688Z"
          fill="#CFD72A"
        />
        <path
          d="M30 0.554688L32.2451 5.929H39.5106L33.6327 9.25051L35.8779 14.6248L30 11.3033L24.1221 14.6248L26.3673 9.25051L20.4894 5.929H27.7549L30 0.554688Z"
          fill="#CFD72A"
        />
        <path
          d="M50 0.554688L52.2451 5.929H59.5106L53.6327 9.25051L55.8779 14.6248L50 11.3033L44.1221 14.6248L46.3673 9.25051L40.4894 5.929H47.7549L50 0.554688Z"
          fill="#CFD72A"
        />
        <path
          d="M70 0.554688L72.2451 5.929H79.5106L73.6327 9.25051L75.8779 14.6248L70 11.3033L64.1221 14.6248L66.3673 9.25051L60.4894 5.929H67.7549L70 0.554688Z"
          fill="#CFD72A"
        />
        <path
          d="M90 0.554688L92.2451 5.929H99.5106L93.6327 9.25051L95.8779 14.6248L90 11.3033L84.1221 14.6248L86.3673 9.25051L80.4894 5.929H87.7549L90 0.554688Z"
          fill="#8B8B8B"
        />
      </svg>
    ),
    5: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100"
        height="16"
        viewBox="0 0 100 16"
        fill="none"
      >
        <path
          d="M10 0L12.2451 5.37431H19.5106L13.6327 8.69582L15.8779 14.0701L10 10.7486L4.12215 14.0701L6.36729 8.69582L0.489435 5.37431H7.75486L10 0Z"
          fill="#38B000"
        />
        <path
          d="M30 0L32.2451 5.37431H39.5106L33.6327 8.69582L35.8779 14.0701L30 10.7486L24.1221 14.0701L26.3673 8.69582L20.4894 5.37431H27.7549L30 0Z"
          fill="#38B000"
        />
        <path
          d="M50 0L52.2451 5.37431H59.5106L53.6327 8.69582L55.8779 14.0701L50 10.7486L44.1221 14.0701L46.3673 8.69582L40.4894 5.37431H47.7549L50 0Z"
          fill="#38B000"
        />
        <path
          d="M70 0L72.2451 5.37431H79.5106L73.6327 8.69582L75.8779 14.0701L70 10.7486L64.1221 14.0701L66.3673 8.69582L60.4894 5.37431H67.7549L70 0Z"
          fill="#38B000"
        />
        <path
          d="M90 0L92.2451 5.37431H99.5106L93.6327 8.69582L95.8779 14.0701L90 10.7486L84.1221 14.0701L86.3673 8.69582L80.4894 5.37431H87.7549L90 0Z"
          fill="#38B000"
        />
      </svg>
    ),
  };

  return rating ? starSvgs[rating] || <NoRating /> : <NoRating />;
};
  if (!item) return null;

  return (
    <tr
      className="pop_up_table_data"
      onClick={() => onRowClick(item.bookingId)}
    >
      <td>{index + 1}</td>
      <td>{item.bookingId}</td>
      <td>{item.pickupDate}</td>
      <td>{item.bookingType}</td>
      <td>
        {item.city}, {item.state}
      </td>
      <td>{item.tripType}</td>
      <td>{item.subTripType}</td>
      <td>{item.totalAmount}</td>
      <td style={getStatusStyle(item.status)}>{item.status}</td>
      <td><StarRating rating={item.customerRating} /></td>
    </tr>
  );
};
const Table = ({ headers, data, onRowClick }) => {
  if (!data?.length) {
    return (
      <div className="not-available-data">
        <table className="pop_up_table_empty">
          <thead>
            <tr>
              {headers.map((header, index) => (
                <th key={index}>{header}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td colSpan={headers.length}>
                No rides available in drivers account
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }

  return (
    <div className="pop_up_table_containers">
      <div className="pop_up_table_scroll">
        <table className="pop_up_table">
          <thead>
            <tr>
              {headers.map((header, index) => (
                <th key={index}>{header}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <TableItem
                key={`${item?.bookingId}-${index}`}
                item={item}
                index={index}
                onRowClick={onRowClick}
              />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const PopUptable = ({ driverId }) => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      if (!driverId) {
        setError("Driver ID is required");
        setIsLoading(false);
        return;
      }

      setIsLoading(true);
      setError(null);

      try {
        const response = await axios.get(
          `${API_BASE_URL}/v1/admin/drivers/${driverId}/earning-data`
        );

        console.log("API Response:", response.data);
        const bookings = response.data?.data?.bookings || [];

        const allBookings = bookings.map((booking) => ({
          bookingId: booking.bookingId || "N/A",
          pickupDate: booking.fromDate || "N/A",
          bookingType: booking.bookingType || "N/A",
          city: booking.pickupCity || "N/A",
          tripType: booking.tripType || "N/A",
          subTripType: booking.subTripType || "N/A",
          totalAmount: booking.baseFare + booking.extraKmCharge * booking.extraKm + booking.extraHrsCharge * booking.extraHrs + booking.nightCharge || 0,
          status: booking.status || "N/A",
          customerRating: booking.customerRating || "No rating",
        }));


        // Sort by pickupDate (dd-mm-yyyy)
        allBookings.sort((a, b) => {
          const dateA = a.pickupDate.split("-").reverse().join("-"); // Convert to yyyy-mm-dd
          const dateB = b.pickupDate.split("-").reverse().join("-");
          return new Date(dateB) - new Date(dateA);
        });

        setData(allBookings);
      } catch (error) {
        console.error("Error fetching data:", error);
        setError(error.message || "Failed to fetch booking data");
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [driverId]);
  const headers = [
    "Sr No's.",
    "Booking ID's",
    "Trip Date",
    "Booking Type",
    "Pickup City",
    "Trip Type",
    "Sub Trip Type",
    "Amount",
    "Status",
    "Customer Rating",
  ];

  const handleRowClick = (bookingId) => {
    if (bookingId) {
      navigate(`/booking/${bookingId}`);
    }
  };

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "2rem",
          color: "#666",
        }}
      >
        Loading...
      </div>
    );
  }

  if (error) {
    return (
      <div
        style={{
          margin: "1rem",
          padding: "1rem",
          backgroundColor: "#fee2e2",
          border: "1px solid #ef4444",
          borderRadius: "0.375rem",
          color: "#b91c1c",
        }}
      >
        {error}
      </div>
    );
  }

  return <Table headers={headers} data={data} onRowClick={handleRowClick} />;
};

export default PopUptable;
