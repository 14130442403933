import React, { useEffect, useState } from "react";
import "./CustomBilling.css";
import cross from "../../../assets/cross.svg";
import down_box from "../../../assets/down-arrow-box.svg";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { fetchbillingDetails } from "../../Details/DetailsAPI.js";
import axios from "axios";
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const CustomBilling = ({ bookingDetails, open, close, type }) => {
  console.log(
    "Booking Details in Custom Billing ",
    bookingDetails?.tariff?.subTripType
  );
  const [startDate, setStartDate] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endDate, setEndDate] = useState("");
  const [endTime, setEndTime] = useState("");
  const [totalHours, setTotalHours] = useState(null);
  const [triptype, settriptype] = useState(type);
  const [inputDistance, setInputDistance] = useState("");
  const [fuelCash, setFuelCash] = useState("");
  const [totalDistance, setTotalDistance] = useState(0);
  const [extraKm, setExtraKm] = useState(0);
  const [extraTime, setExtraTime] = useState(0);
  const [totalDays, setTotalDays] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [totalAmountOutStation, setTotalAmountOutStation] = useState(0);
  const [isApplicable, setIsApplicable] = useState(false);

  const [errors, setErrors] = useState({});
  const handleSubmit = async () => {
    console.log("Total OutStation", totalAmountOutStation);
    console.log("Total Amount", totalAmount);
    console.log("Booking Details", bookingDetails);
    console.log("bookingId", bookingDetails.bookingId);
    console.log("endDate and Time", endDate, endTime);
    console.log("totalDistance", totalDistance);
    console.log("FuelCash", fuelCash);

    let newErrors = {};
    if (!endTime) newErrors.endTime = "End Time is required";
    if (!totalDistance) newErrors.totalDistance = "Total Distance is required";
    if (!fuelCash) newErrors.fuelCash = "Fuel Cash is required";
    if (type !== "Local" && type !== "Airport" && !endDate)
      newErrors.endDate = "Return Date is required";

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      // toast.error("Please fill all required fields", {
      //   position: "top-center",
      // });
      return;
    }

    let dataObj = {
      bookingId: bookingDetails.bookingId,
      returnTime: endTime,
      actualDistance: totalDistance,
      cashForFuel: fuelCash,
      finalAmount: totalAmount,
      ...(type !== "Local" && type !== "Airport" && { returnDate: endDate }),
    };

    console.log("dataObj", dataObj);

    try {
      const response = await axios.post(
        `${API_BASE_URL}/v1/admin/bookings/manualbill`,
        dataObj
      );
      console.log("response", response.data.message);

      toast.success(response.data.message, {
        position: "top-center",
      });

      close();
    } catch (e) {
      console.error("Error from manual billing API", e);

      toast.error(e?.response?.data?.message || "Something went wrong", {
        position: "top-center",
      });
      setErrors({});
    }
  };
  const handleDateChange = (e) => {
    const inputDate = e.target.value;
    const [year, month, day] = inputDate.split("-");
    setEndDate(`${day}-${month}-${year}`);
  };

  useEffect(() => {
    const distance = parseFloat(inputDistance) || 0;
    setTotalDistance(distance);
    const extraDistance = distance - bookingDetails.kmIncluded;
    setExtraKm(extraDistance > 0 ? extraDistance : 0);
  }, [inputDistance]);

  const handleInputChange = (e) => {
    setInputDistance(e.target.value);
  };

  const handleFuelCashChange = (e) => {
    const value = e.target.value;
    setFuelCash(value === "" ? "" : Number(value));
  };

  useEffect(() => {
    setStartDate(bookingDetails.pickupDate);
    setStartTime(bookingDetails.pickupTime);
  }, [bookingDetails]);

  useEffect(() => {
    calculateTotalHours();
    calculateTotalDays();
  }, [startDate, startTime, endDate, endTime]);

  const convertToISOFormat = (dateString) => {
    const [day, month, year] = dateString.split("-");
    return `${year}-${month}-${day}`;
  };

  const calculateTotalDays = () => {
    // if (startDate && endDate) {
    //   const formattedStartDate = convertToISOFormat(startDate);
    //   const startDateTime = new Date(formattedStartDate);
    //   const endDateTime = new Date(endDate);
    //   const diffInMs = endDateTime - startDateTime;
    //   const extraDays = Math.floor(diffInMs / (1000 * 60 * 60 * 24));
    //   setTotalDays(extraDays >= 0 ? extraDays : 0);

    // }

    if (startDate && endDate) {
      // Convert startDate and endDate to "YYYY-MM-DD"

      const formattedStartDate = convertToISOFormat(startDate);
      const formattedEndDate = convertToISOFormat(endDate);
      console.log(
        "Start Date and end Date",
        formattedEndDate,
        formattedStartDate
      );
      // Ensure proper Date object creation
      const startDateTime = new Date(formattedStartDate);
      const endDateTime = new Date(formattedEndDate);

      // Calculate difference in milliseconds
      const diffInMs = endDateTime - startDateTime;
      const extraDays = Math.floor(diffInMs / (1000 * 60 * 60 * 24));

      // Update total days state (Ensure non-negative value)
      setTotalDays(extraDays + 1 >= 0 ? extraDays + 1 : 0);
    }
  };

  const calculateTotalHours = () => {
    if (!startTime || !endTime) {
      console.log("Missing time values");
      return 0;
    }

    // Parse times
    let [startHours, startMinutes] = startTime.split(":").map(Number);
    let [endHours, endMinutes] = endTime.split(":").map(Number);

    // Calculate total minutes considering midnight crossing
    let totalMinutes;
    if (endHours < startHours) {
      // Add 24 hours if end time is next day
      totalMinutes =
        (24 + endHours) * 60 + endMinutes - (startHours * 60 + startMinutes);
    } else {
      totalMinutes =
        endHours * 60 + endMinutes - (startHours * 60 + startMinutes);
    }

    // Calculate hours and remaining minutes
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;

    // Round up if minutes > 30
    const roundedHours = minutes > 30 ? hours + 1 : hours;

    console.log("Time Calculation:", {
      startTime,
      endTime,
      totalMinutes,
      roundedHours,
    });
    return Math.max(0, roundedHours);
  };

  useEffect(() => {
    // console.log('here tripe is ...', type)
    settriptype(type);
  });

  const [openLocal, setopenLocal] = useState(false);
  const [openLocalPayTpye, setopenLocalPayTpye] = useState("online");
  const paytypeLocal = (type) => {
    setopenLocalPayTpye(type);
    setopenLocal(false);
  };

  const [openOutstaion, setopenOutstaion] = useState(false);
  const [openOutstaionPayTpye, setopenOutstaionPayTpye] = useState("online");
  const paytypeOutstation = (type) => {
    setopenOutstaionPayTpye(type);
    setopenOutstaion(false);
  };

  const parseDate = (dateStr) => {
    const [day, month, year] = dateStr.split("-").map(Number);
    return new Date(year, month - 1, day);
  };

  const start = parseDate(startDate);

  const handleEndDateChange = (e) => {
    const endDateValue = e.target.value;
    const endDate = new Date(endDateValue);

    start.setHours(0, 0, 0, 0);
    endDate.setHours(0, 0, 0, 0);

    const oneDayInMs = 24 * 60 * 60 * 1000;

    if (
      endDate.getTime() === start.getTime() ||
      endDate.getTime() === start.getTime() + oneDayInMs
    ) {
      console.log("End date is valid");
      setEndDate(endDateValue);
    } else {
      alert("End date should be the same or the day after the start date");
    }
  };

  const handleEndTimeChange = (e) => {
    const endTimeValue = e.target.value;
    setEndTime(endTimeValue);
    console.log("End Time:", endTimeValue);
    // const endTimeDate = new Date(`1970-01-01T${endTimeValue}:00Z`);

    // const start = new Date(startDate.split("-").reverse().join("-"));
    // const end = new Date(endDate);

    // if (start.getDate() === end.getDate() && start.getMonth() === end.getMonth() && start.getFullYear() === end.getFullYear()) {
    //     console.log("End time is valid");
    // } else if (endTimeDate.getUTCHours() < 5 || (endTimeDate.getUTCHours() === 5 && endTimeDate.getUTCMinutes() === 0)) {
    //     console.log("End time is valid");
    //     setEndTime(endTimeValue)
    // } else {
    //     alert("End time should be no later than 5:00 AM");
    // }
  };
  useEffect(() => {
    if (bookingDetails?.nightAllowance === 0 && endTime) {
      const endHour = parseInt(endTime.split(":")[0], 10);
      if (endHour > 21 || endHour < 5) {
        setIsApplicable(true);
        console.log("Night Charging is Applicable");
      } else {
        setIsApplicable(false);
      }
    }
  }, [bookingDetails?.nightAllowance, endTime]);

  const calculateTotal = () => {
    const extraKmCharge =
      (bookingDetails?.tariff?.fareDetails?.extraKmCharge || 0) *
      (extraKm || 0);
    const extraHrsCharge =
      (bookingDetails?.tariff?.fareDetails?.extraHrsCharge || 0) *
      Math.max(0, calculateTotalHours() - (bookingDetails?.hrsIncluded || 0));
    const nightAllowance =
      bookingDetails?.tariff?.fareDetails?.nightDriverAllowance || 0;

    var total = extraKmCharge + extraHrsCharge;
    const endHour = parseInt(endTime.split(":")[0], 10);
    console.log("Night Allowance", bookingDetails?.nightAllowance);

    if (bookingDetails?.nightAllowance === 0 && endHour > 21) {
      total += bookingDetails?.tariff?.fareDetails?.nightDriverAllowance;

      console.log("Night Charging is Applicable");
    }

    const extraDays = totalDays ? totalDays - bookingDetails.noOfDays : 0;
    if (bookingDetails?.tariff?.subTripType === "Round Trip" && extraDays > 0) {
      total +=
        bookingDetails?.tariff?.fareDetails?.dayDriverAllowance * extraDays;
    }
    return total;
  };

  useEffect(() => {
    setTotalAmount(
      calculateTotal() +
        calculateTotal() * 0.05 +
        (bookingDetails?.amountPayable || 0) -
        (fuelCash || 0)
    );

    setTotalAmountOutStation(
      (bookingDetails?.amountPayable || 0) +
        (bookingDetails?.tariff?.fareDetails?.nightDriverAllowance || 0) *
          (totalDays ? totalDays - bookingDetails.noOfDays : 0) +
        (bookingDetails?.tariff?.fareDetails?.extraKmCharge || 0) *
          (extraKm || 0) +
        0.05 *
          ((bookingDetails?.tariff?.fareDetails?.extraKmCharge || 0) *
            (extraKm || 0) -
            (fuelCash || 0))
    );
  }, [totalDistance, endDate, endTime, fuelCash]);

  // +(bookingDetails?.tariff?.fareDetails
  //     ?.nightDriverAllowance || 0) *
  //     (totalDays
  //       ? totalDays - bookingDetails.noOfDays
  //       : 0)

  if (!open) return;
  return (
    <>
      <div id="big-bg-dull">
        <div className="manual-billing-container">
          {triptype === "Local" || triptype === "Airport" ? (
            <>
              <div className="cross-holder" onClick={close}>
                <img src={cross} alt="" />
              </div>
              <h1>Manual Billing</h1>

              <div className="trip-detail-container">
                <div className="row-flexer2">
                  <h2>Trip Type</h2>
                  <p>
                    Local - {bookingDetails.hrsIncluded} HRS |{" "}
                    {bookingDetails.kmIncluded} KM
                  </p>
                </div>

                <div className="row-flexer2">
                  <h2>Trip Start Date & Time</h2>
                  <p>
                    {bookingDetails.pickupDate} &ensp; &ensp;{" "}
                    {bookingDetails.pickupTime}
                  </p>
                </div>

                <div className="row-flexer2">
                  <h2>
                    Trip End Date & Time{" "}
                    <span
                      style={{
                        fontSize: "15px",
                        color: "red",
                      }}
                    >
                      *
                    </span>
                  </h2>
                  <div
                    className="sub-datetime-row"
                    style={{
                      display: "flex",
                      // flexDirection: "colum",
                    }}
                  >
                    <input
                      type="date"
                      value={endDate}
                      onChange={(e) => setEndDate(e.target.value)}
                      // onChange={handleEndDateChange}
                    />
                    <input
                      type="time"
                      style={{
                        width: "100%",
                        border: errors.endTime
                          ? "1px solid red"
                          : "1px solid black",
                      }}
                      //   value={endTime}
                      // onChange={(e) => setEndTime(e.target.value)}
                      onChange={handleEndTimeChange}
                    />
                    {errors.endTime && (
                      <p style={{ color: "red", fontSize: "12px" }}>
                        {errors.endTime}
                      </p>
                    )}
                  </div>
                </div>
              </div>

              <div className="mannual-booking-customize-container">
                <h2>Fare Breakup</h2>

                <div className="flexer">
                  <div className="l clr-white green">
                    Estimated Fare{" "}
                    <p className="p-small">
                      {bookingDetails.kmIncluded} Km,{" "}
                      {bookingDetails.hrsIncluded} Hours{" "}
                    </p>
                  </div>
                  <div className="r">Rs. {bookingDetails.estimatedFare}</div>
                </div>

                <div className="flexer">
                  <div className="l ">
                    Advance Payment {bookingDetails.partialPaymentPercentage}%
                  </div>
                  <div className="r">
                    Rs. {bookingDetails.advancePaymentReceived}
                  </div>
                </div>

                <div className="flexer down-line">
                  <div className="l clr-white green">
                    Balance Payment to Pay
                  </div>
                  <div className="r clr-green">
                    Rs. {bookingDetails.amountPayable}
                  </div>
                </div>

                <h2>Extra Coverage</h2>

                <div className="flexer">
                  <div className="l green clr-white flx-row">
                    Total Distance Covered <span> *</span>
                  </div>
                  <div
                    className="r light-green last"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <div>
                      <input
                        type="text"
                        className="input-box"
                        placeholder="Enter Total Distance Covered"
                        value={inputDistance}
                        onChange={handleInputChange}
                        //   style={{
                        //     width: "100%",
                        //     border: errors.totalDistance ? "1px solid red" : "1px solid black",

                        //   }}
                      />{" "}
                      &ensp; km
                    </div>
                    {errors.totalDistance && (
                      <p style={{ color: "red", fontSize: "12px" }}>
                        {errors.totalDistance}
                      </p>
                    )}
                  </div>
                </div>

                <div className="flexer">
                  <div className="l green clr-white ">Total Hours Covered </div>
                  <div className="r light-green last">
                    {calculateTotalHours()} Hrs
                  </div>
                </div>

                <div className="flexer">
                  <div className="l green clr-white">Extra km</div>
                  <div className="r light-green last"> {extraKm} Km</div>
                </div>

                <div className="flexer">
                  <div className="l green clr-white">Extra Hours</div>
                  <div className="r light-green last">
                    {" "}
                    {Math.max(
                      0,
                      calculateTotalHours() - (bookingDetails?.hrsIncluded || 0)
                    )}{" "}
                    Hrs
                  </div>
                </div>

                <div className="flexer down-line">
                  {/* <div className="l green clr-white">Night Allowance</div>
                  <div className="r light-green start">
                    Rs {bookingDetails.tariff.fareDetails.nightDriverAllowance}
                  </div> */}
                </div>

                <h2>Extra Fare</h2>

                <div className="flexer">
                  <div className="l green clr-white">
                    Extra km charge @ Rs.{" "}
                    {bookingDetails.tariff.fareDetails.extraKmCharge}/Km
                  </div>
                  <div className="r last">
                    Rs.{" "}
                    {bookingDetails.tariff.fareDetails.extraKmCharge * extraKm}
                  </div>
                </div>

                <div className="flexer">
                  <div className="l green clr-white">
                    Extra hrs charge @ Rs.{" "}
                    {bookingDetails.tariff.fareDetails.extraHrsCharge}/hrs x{" "}
                    {Math.max(
                      0,
                      calculateTotalHours() - (bookingDetails?.hrsIncluded || 0)
                    )}
                  </div>
                  <div className="r last">
                    Rs.{" "}
                    {(bookingDetails?.tariff?.fareDetails?.extraHrsCharge ||
                      0) *
                      Math.max(
                        0,
                        calculateTotalHours() -
                          (bookingDetails?.hrsIncluded || 0)
                      )}
                  </div>
                </div>

                <div className="flexer">
                  <div className="l green clr-white">
                    Night Charge <br></br>@ Rs.
                    {bookingDetails.tariff.fareDetails.nightDriverAllowance}/day
                  </div>
                  <div
                    style={{
                      display: "flex",

                      gap: "120px",
                    }}
                    className="r last"
                  >
                    {isApplicable && (
                      <span
                        style={{
                          color: "#8B8B8B",
                        }}
                      >
                        Applicable
                      </span>
                    )}
                    {isApplicable
                      ? `  Rs. ${bookingDetails.tariff.fareDetails.nightDriverAllowance}`
                      : "Not Applicable"}
                  </div>
                </div>

                <div className="flexer">
                  <div className="l green clr-white">
                    5 % GST on Rs. {calculateTotal()}
                  </div>
                  <div className="r last">
                    Rs. {(calculateTotal() * 0.05).toFixed(2)}
                  </div>
                </div>

                <div className="flexer">
                  <div className="l clr-green fnt-14px">Total Extra Amount</div>
                  <div className="r clr-green last fnt-14px">
                    Rs.{" "}
                    {(calculateTotal() + calculateTotal() * 0.05).toFixed(2)}{" "}
                  </div>
                </div>

                <div className="flexer">
                  <div className="l green clr-white">Plus Balance Amount</div>
                  <div className="r green last clr-white">
                    Rs. {(bookingDetails?.amountPayable || 0).toFixed(2)}
                  </div>
                  {/* <div className="r green last clr-white">Rs. {((calculateTotal() + (calculateTotal() * 0.05)) + (bookingDetails?.amountPayable || 0)).toFixed(2)}</div> */}
                </div>

                <div className="flexer">
                  <div className="l clr-green ">
                    Less cash for fuel received{" "}
                    <p>
                      {" "}
                      by driver in advance{" "}
                      <span
                        style={{
                          fontSize: "15px",
                        }}
                      >
                        *
                      </span>
                    </p>
                  </div>
                  <div
                    className="r light-green start"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      style={{
                        marginTop: "8px",
                      }}
                    >
                      Rs. &ensp;
                      <input
                        type="text"
                        placeholder="Enter advance fuel cash"
                        value={fuelCash}
                        onChange={handleFuelCashChange}
                      />
                    </div>
                    {errors.fuelCash && (
                      <p style={{ color: "red", fontSize: "12px" }}>
                        {errors.fuelCash}
                      </p>
                    )}
                  </div>
                </div>

                <div className="flexer">
                  <div className="l green clr-white flx-row gp-4">
                    Total Paid Cash
                    {/* Total Paid - {openLocalPayTpye}{" "}
                    <span onClick={() => setopenLocal(!openLocal)}>
                      <img src={down_box} alt="" />
                    </span> */}
                  </div>
                  <div className="r green  clr-white last">
                    Rs. {totalAmount.toFixed(2)}
                    {/* {(
                      calculateTotal() +
                      calculateTotal() * 0.05 +
                      (bookingDetails?.amountPayable || 0) -
                      (fuelCash || 0)
                    ).toFixed(2)} */}
                  </div>

                  {/* {openLocal ? (
                    <div className="paytype">
                      <div
                        className="sub-paytype"
                        onClick={() => paytypeLocal("online")}
                      >
                        Online
                      </div>
                      <div
                        className="sub-paytype"
                        onClick={() => paytypeLocal("cash")}
                      >
                        Cash
                      </div>
                    </div>
                  ) : (
                    ""
                  )} */}
                </div>
              </div>

              <div className="generate-bill-btn">
                <button onClick={() => handleSubmit()}>Generate Bill</button>
              </div>
            </>
          ) : (
            <>
              <div className="cross-holder" onClick={close}>
                <img src={cross} alt="" />
              </div>
              <h1>Manual Billing</h1>

              <div className="trip-detail-container">
                <div className="row-flexer2">
                  <h2>Trip Type</h2>
                  <p>
                    {bookingDetails.tariff.tripType} -{" "}
                    {bookingDetails.tariff.subTripType}{" "}
                  </p>
                </div>

                <div className="row-flexer2">
                  <h2>Trip Start Date & Time</h2>
                  <p>
                    {bookingDetails.pickupDate} &ensp; &ensp;{" "}
                    {bookingDetails.pickupTime}
                  </p>
                </div>

                <div className="row-flexer2">
                  <h2>
                    Trip End Date & Time
                    <span
                      style={{
                        color: "red",
                        // fontSize:"15px"
                      }}
                    >
                      *
                    </span>
                  </h2>
                  <div className="sub-datetime-row">
                    <div>
                      <input
                        type="date"
                        value={endDate.split("-").reverse().join("-")}
                        onChange={(e) => handleDateChange(e)}
                        style={{
                          width: "100%",
                          border: errors.endDate ? "1px solid red" : "",
                        }}
                      />{" "}
                      {errors.endDate && (
                        <p style={{ color: "red", fontSize: "10px" }}>
                          {errors.endDate}
                        </p>
                      )}
                    </div>

                    <div>
                      <input
                        type="time"
                        value={endTime}
                        onChange={(e) => setEndTime(e.target.value)}
                        style={{
                          width: "100%",
                          border: errors.endTime ? "1px solid red" : "",
                        }}
                      />
                      {errors.endTime && (
                        <p style={{ color: "red", fontSize: "10px" }}>
                          {errors.endTime}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="mannual-booking-customize-container">
                <h2>Fare Breakup</h2>

                <div className="flexer">
                  <div className="l clr-white green">
                    Estimated Fare{" "}
                    <p className="p-small">
                      {bookingDetails.kmIncluded} Km, {bookingDetails.noOfDays}{" "}
                      days{" "}
                    </p>
                  </div>
                  <div className="r">Rs. {bookingDetails.estimatedFare}</div>
                </div>

                <div className="flexer">
                  <div className="l ">
                    Advance Payment ({bookingDetails.partialPaymentPercentage})%
                  </div>
                  <div className="r">
                    Rs. {bookingDetails.advancePaymentReceived}
                  </div>
                </div>

                <div className="flexer down-line">
                  <div className="l clr-white green">
                    Balance Payment to Pay
                  </div>
                  <div className="r clr-green">
                    Rs. {bookingDetails.amountPayable}
                  </div>
                </div>

                <h2>Extra Coverage</h2>

                <div
                  className="flexer"
                  // style={{
                  //     width: "100%",
                  //     border: errors.endTime
                  //       ? "1px solid red"
                  //       : "1px solid black",
                  //   }}
                >
                  <div className="l green clr-white flx-row">
                    Total Distance Covered<span> *</span>
                  </div>
                  <div
                    className="r light-green last"
                    style={{
                      display: "flex",
                      flexDirection: "column",

                      border: errors.endTime ? "1px solid red" : "",
                    }}
                  >
                    <div>
                      <input
                        type="text"
                        placeholder="Enter Total Distance Covered"
                        value={inputDistance}
                        onChange={handleInputChange}
                      />{" "}
                      &ensp; km
                    </div>
                    {errors.totalDistance && (
                      <p style={{ color: "red", fontSize: "10px" }}>
                        {errors.totalDistance}
                      </p>
                    )}
                  </div>
                </div>

                <div className="flexer">
                  <div className="l green clr-white">Extra km</div>
                  <div className="r light-green last">{extraKm} Km</div>
                </div>

                {bookingDetails?.tariff?.subTripType === "Round Trip" && (
                  <div>
                    <div className="flexer">
                      <div className="l green clr-white">Actual No of Days</div>
                      <div className="r light-green last">{totalDays}</div>
                    </div>

                    <div className="flexer down-line">
                      <div className="l green clr-white">Extra Days</div>
                      <div className="r light-green last">
                        {" "}
                        {totalDays ? totalDays - bookingDetails.noOfDays : 0}
                      </div>
                    </div>
                  </div>
                )}

                <h2>Extra Fare</h2>

                <div className="flexer">
                  <div className="l green clr-white">
                    Extra km charge @ Rs.{" "}
                    {bookingDetails.tariff.fareDetails.extraKmCharge}/Km
                  </div>
                  <div className="r last">
                    Rs.{" "}
                    {bookingDetails.tariff.fareDetails.extraKmCharge * extraKm}
                  </div>
                </div>

                {bookingDetails?.tariff?.subTripType === "One Way" ? (
                  <div className="flexer">
                    <div className="l green clr-white">
                      Night Charge @ Rs.
                      {bookingDetails.tariff.fareDetails.nightDriverAllowance}
                      /day
                    </div>

                    <div
                      style={{
                        display: "flex",

                        gap: "120px",
                      }}
                      className="r last"
                    >
                      {isApplicable && (
                        <span
                          style={{
                            color: "#8B8B8B",
                          }}
                        >
                          Applicable
                        </span>
                      )}
                      {isApplicable
                        ? `  Rs. ${bookingDetails.tariff.fareDetails.nightDriverAllowance}`
                        : "Not Applicable"}
                    </div>
                  </div>
                ) : (
                  <div className="flexer">
                    <div className="l green clr-white">
                      Extra Driver Allowance @ Rs.
                      {bookingDetails.tariff.fareDetails.dayDriverAllowance}/Day X {totalDays?( totalDays - bookingDetails.noOfDays):0} Day
                    </div>

                    <div
                      style={{
                        display: "flex",

                        gap: "120px",
                      }}
                      className="r last"
                    >
                      {(totalDays - bookingDetails.noOfDays)>0 ? (
                        <div style={{
                          display:"flex",
                          gap:"100px"
                        }}>
                          
                            <span
                              style={{
                                color: "#8B8B8B",
                              }}
                            >
                              Applicable
                            </span>
                          
                          <span> Rs. {
                            bookingDetails.tariff.fareDetails
                              .dayDriverAllowance *
                            (totalDays?totalDays - bookingDetails.noOfDays:0)
                          }</span>
                        </div>
                      ) : (
                        <span
                          style={{
                            // color: "#8B8B8B",
                          }}
                        >
                          Not Applicable
                        </span>
                      )}
                    </div>
                  </div>
                )}

                <div className="flexer">
                  <div className="l green clr-white">
                    5 % GST on Rs. {calculateTotal().toFixed(2)}{" "}
                    {/* {bookingDetails.tariff.fareDetails.extraKmCharge * extraKm 
                    // +
                    //   bookingDetails.tariff.fareDetails.nightDriverAllowance *
                    //     (totalDays ? totalDays - bookingDetails.noOfDays : 0)
                        } */}
                  </div>
                  <div className="r last">
                    Rs. {(calculateTotal() * 0.05).toFixed(2)}{" "}
                    {/* {(
                      0.05 *
                      (bookingDetails.tariff.fareDetails.extraKmCharge *
                        extraKm 
                        // +
                        // bookingDetails.tariff.fareDetails.nightDriverAllowance *
                        //   (totalDays ? totalDays - bookingDetails.noOfDays : 0)
                        )
                    ).toFixed(2)} */}
                  </div>
                </div>

                <div className="flexer">
                  <div className="l clr-green fnt-14px">Total Extra Amount</div>
                  <div className="r clr-green  fnt-14px last">
                    Rs.{" "}
                    {(calculateTotal() + calculateTotal() * 0.05).toFixed(2)}{" "}
                    {/* {bookingDetails.tariff.fareDetails.nightDriverAllowance *
                      (totalDays ? totalDays - bookingDetails.noOfDays : 0) +
                      bookingDetails.tariff.fareDetails.extraKmCharge *
                        extraKm +
                      0.05 *
                        (bookingDetails.tariff.fareDetails.extraKmCharge *
                          extraKm +
                          bookingDetails.tariff.fareDetails
                            .nightDriverAllowance *0
                            // (totalDays
                            //   ? totalDays - bookingDetails.noOfDays
                            //   : 0)
                              )} */}
                  </div>
                </div>

                <div className="flexer">
                  <div className="l green clr-white">Plus Balance Amount</div>
                  <div className="r green last clr-white">
                    Rs. {(bookingDetails?.amountPayable || 0).toFixed(2)}
                  </div>
                  {/* <div className="r green  clr-white last">Rs. {bookingDetails.amountPayable + bookingDetails.tariff.fareDetails.nightDriverAllowance * (totalDays ? totalDays - bookingDetails.noOfDays : 0) + bookingDetails.tariff.fareDetails.extraKmCharge * extraKm + 0.05 * (bookingDetails.tariff.fareDetails.extraKmCharge * extraKm + bookingDetails.tariff.fareDetails.nightDriverAllowance * (totalDays ? totalDays - bookingDetails.noOfDays : 0))}</div> */}
                </div>

                <div className="flexer">
                  <div className="l clr-green ">
                    Less cash for fuel received{" "}
                    <p>
                      {" "}
                      by driver in advance <span> *</span>
                    </p>
                  </div>
                  <div
                    className="r light-green start"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <div
                      style={{
                        marginTop: "8px",
                      }}
                    >
                      Rs. &ensp;
                      <input
                        type="text"
                        placeholder={"Advance amount received if any"}
                        value={fuelCash}
                        onChange={handleFuelCashChange}
                      />
                    </div>
                    {errors.fuelCash && (
                      <p style={{ color: "red", fontSize: "10px" }}>
                        {errors.fuelCash}
                      </p>
                    )}
                  </div>
                </div>

                <div className="flexer">
                  <div className="l green clr-white flx-row gp-4">
                    Total Paid Cash
                    {/* Total Paid - {openOutstaionPayTpye}{" "}
                    <span onClick={() => setopenOutstaion(!openOutstaion)}>
                      <img src={down_box} alt="" />
                    </span> */}
                  </div>
                  <div className="r green  clr-white last">
                    Rs. {totalAmount}
                    {/* {(bookingDetails?.amountPayable || 0) +
                      (bookingDetails?.tariff?.fareDetails
                        ?.nightDriverAllowance || 0) *
                        (totalDays ? totalDays - bookingDetails.noOfDays : 0) +
                      (bookingDetails?.tariff?.fareDetails?.extraKmCharge ||
                        0) *
                        (extraKm || 0) +
                      0.05 *
                        ((bookingDetails?.tariff?.fareDetails?.extraKmCharge ||
                          0) *
                          (extraKm || 0) 
                          // +
                          // (bookingDetails?.tariff?.fareDetails
                          //   ?.nightDriverAllowance || 0) *
                          //   (totalDays
                          //     ? totalDays - bookingDetails.noOfDays
                          //     : 0)
                            )
                               -
                      (fuelCash || 0)} */}
                  </div>

                  {/* {openOutstaion ? (
                    <div className="paytype">
                      <div
                        className="sub-paytype"
                        onClick={() => paytypeOutstation("online")}
                      >
                        Online
                      </div>
                      <div
                        className="sub-paytype"
                        onClick={() => paytypeOutstation("cash")}
                      >
                        Cash
                      </div>
                    </div>
                  ) : (
                    ""
                  )} */}
                </div>
              </div>

              <div className="generate-bill-btn">
                <button onClick={() => handleSubmit()}>Generate Bill</button>
              </div>
            </>
          )}
        </div>
      </div>
      {/* <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      /> */}
    </>
  );
};

export default CustomBilling;
