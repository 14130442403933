import React from "react";
import { useSelector } from "react-redux";
import { selectbookingDetails } from "../DetailsSlice";
import "./tablesecond.css";

const TableFirstItem = ({ item }) => {
  const bookingDetails = useSelector(selectbookingDetails);

  console.log(item);

  return (
    <tr className="firstsecond_billing_table_data">
      {/* add lable as the title of mode of payment online or offline */}
      <td>
        Paid Online
        <br></br>
        <br></br>
        {item?.data?.finalOnline >= 0 ? (
          <p>Rs. {item?.data?.finalOnline}</p>
        ) : (
          <p>No payment details found</p>
        )}
      </td>

      <td>
        Paid Cash
        <br></br>
        <br></br>
        {item?.data?.finalCash >= 0 ? (
          <p>Rs. {item?.data?.finalCash}</p>
        ) : (
          <p>No payment details found</p>
        )}
      </td>
    </tr>
  );
};

const Table = ({ data }) => {
  console.log(data);
  return (
    <div className="billing_tablesecond_containers">
      <div className="billing_table_scroll">
        <table className="billing_table">
          <thead>
            <th style={{ borderRadius: "0" }} colSpan={2}>
              Mode Of Payment
            </th>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <TableFirstItem key={index} item={item} />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const TableFirst = ({ data }) => {
  console.log(data);

  return <Table data={[data]} />;
};

export default TableFirst;
