import axios from "axios";
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import toast, { Toaster } from "react-hot-toast";
import useSuggestionsLocation from "../../hooks/useSuggestionCities";
import { generateTimeOptions } from "../../utils/time/time";
import "./CreateNewBooking.css";
import DropdownColumn from "./Dropdown";

const LocalBookingForm = (props) => {
  const {
    pickUpDate,
    setPickUpDate,
    pickUpTime,
    setPickUpTime,
    hourlyPackage,
    setHourlyPackage,
    cabType,
    setCabType,
    selectedLocation,
    setSelectedLocation,
    selectedHourlyPackage,
    setselectedHourlyPackage,
    selectedCabType,
    setSelectedCabType,
    setSelectTemp,
    setSelectedTariffId,
    setCarCategoriesDetails,
  } = props;

  const [citySuggestions] = useSuggestionsLocation();
  const [showDropdown, setShowDropdown] = useState(false);
  const suggestionhide = () => {
    setShowDropdown(false);
    console.log("mouseleave", showDropdown);
  };

  const suggtionshows = () => {
    setShowDropdown(true);

    console.log("mouseover", showDropdown);
  };

  const extractCarCategoriesAsOptions = (dataArray) => {
    if (Array.isArray(dataArray)) {
      return dataArray.map((item) => ({
        label: item.carCategory.category,
        value: item.carCategory.category,
      }));
    }
    return [];
  };

  const extractSubTripAsOptions = (dataArray) => {
    if (Array.isArray(dataArray.data)) {
      return dataArray.data.map((item) => ({
        label: item.subTripType,
        value: item.subTripType,
      }));
    }
    return [];
  };

  const handleChange = async (e, isFromSuggestion) => {
    const location = e.target.value;
    setSelectedLocation(location);

    if (isFromSuggestion) {
      try {
        const response = await axios.get(
          `https://api.yatricabs.com/v1/users/select-cars/local?source=${location}`
        );
        console.log(response.data);
        // console.log(response.data.message);
        const subTripOptions = extractSubTripAsOptions(response.data);
        setHourlyPackage(subTripOptions);
        const temp = response.data.tempDataId;
        console.log("Temp Id", temp);
        setSelectTemp(temp);
        // console.log(hourlyPackage);
        // console.log(response.data.data[0].carCategory);
      } catch (error) {
        console.error("Error fetching cars:", error);
        setHourlyPackage([]);
        setCabType([]);
      }
    }
  };
  const handleselectedlocation = async (e) => {
    setSelectedLocation(e.target.value);
  };


  useEffect(() => {
    if (selectedLocation && pickUpTime) {
      const callYourAPI = async () => {
        try {
          const response = await axios.get(
            `https://api.yatricabs.com/v1/users/select-cars/local?source=${selectedLocation}&time=${pickUpTime}`
          );
          console.log(response.data);
          const subTripOptions = extractSubTripAsOptions(response.data);
          setHourlyPackage(subTripOptions);
          const temp = response.data.tempDataId;
          console.log("Temp Id", temp);
          setSelectTemp(temp);
        } catch (error) {
          console.error("Error fetching cars:", error);
          setHourlyPackage([]);
          setCabType([]);
        }
      };

      // Call the API
      callYourAPI();
    }
  }, [pickUpTime, selectedLocation]); // Add selectedLocation to dependencies if it's required for the API call

  const handleChangeTripType = async (e) => {
    const tripType = e.target.value;
    setselectedHourlyPackage(tripType);

    try {
      const response = await axios.get(
        `https://api.yatricabs.com/v1/users/select-cars/local?source=${selectedLocation}&time=${pickUpTime}`
      );
      // console.log(response.data.data);?

      // i am working on it
      const carCategories = response.data.data.filter(
        (item) => item.subTripType === tripType
      );

      console.log(carCategories);
      setCarCategoriesDetails(carCategories);

      carCategories.forEach((item) => {
        setSelectedTariffId(item._id);
      });
      // console.log(carCategories);
      const carCategoryOptions = extractCarCategoriesAsOptions(carCategories);
      setCabType(carCategoryOptions);

      console.log(carCategoryOptions);
    } catch (error) {
      toast.error(error.response.data.message);
      console.error("Error fetching cars:", error);
      setCabType([]);
    }
  };

  // console.log(suggestions);

  return (
    <>
      <div onMouseLeave={suggestionhide}>
        <Toaster />
        <div>
          <div className="row lo-ex-pic-loca-row">
            <div className="user-booking select" onClick={suggtionshows}>
              <label className="title-name local-booking">
                Pick Up Location
              </label>
              <input
                type="text"
                id="from"
                className="custom-input"
                autoComplete="off"
                placeholder="Enter pick up location"
                value={selectedLocation}
                onChange={(e) => handleselectedlocation(e)}
                name="location"
              />
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="7"
                height="9"
                viewBox="0 0 7 9"
                fill="none"
              >
                <path
                  d="M3.93203 8.79777C4.86719 7.66632 7 4.92407 7 3.38376C7 1.51564 5.43229 0 3.5 0C1.56771 0 0 1.51564 0 3.38376C0 4.92407 2.13281 7.66632 3.06797 8.79777C3.29219 9.06741 3.70781 9.06741 3.93203 8.79777ZM3.5 2.25584C3.80942 2.25584 4.10617 2.37467 4.32496 2.5862C4.54375 2.79772 4.66667 3.08461 4.66667 3.38376C4.66667 3.6829 4.54375 3.96979 4.32496 4.18132C4.10617 4.39284 3.80942 4.51168 3.5 4.51168C3.19058 4.51168 2.89383 4.39284 2.67504 4.18132C2.45625 3.96979 2.33333 3.6829 2.33333 3.38376C2.33333 3.08461 2.45625 2.79772 2.67504 2.5862C2.89383 2.37467 3.19058 2.25584 3.5 2.25584Z"
                  fill="#7C7A7A"
                />
              </svg>

              {showDropdown && (
                <div className="dropdown-option lo-ex-pic-loca-dropd-option">
                  {citySuggestions
                    .filter((suggestion) =>
                      suggestion
                        .toLowerCase()
                        .includes(selectedLocation.toLowerCase())
                    )
                    .slice(0, 6)
                    .map((suggestion, index) => {
                      const isMatch =
                        suggestion
                          .toLowerCase()
                          .indexOf(selectedLocation.toLowerCase()) > -1;
                      return (
                        <div key={index}>
                          {isMatch &&
                            selectedLocation &&
                            !citySuggestions.includes(selectedLocation) && (
                              <div
                                className="suggestion"
                                onClick={() => {
                                  setSelectedLocation(suggestion);
                                  handleselectedlocation({
                                    target: { value: suggestion },
                                  });
                                }}
                              >
                                {suggestion}
                              </div>
                            )}
                        </div>
                      );
                    })}
                </div>
              )}

              {/* <DropdownColumn
            placeholder="Enter pick up location"
            options={pickUpLocation}
            selectedValue={selectedLocation}
            handleChange={handleChange}
            className="dropdown"
            useInlineStyle={true}
          /> */}
            </div>
            <div className="user-booking">
              <label className="title-name">Pick Up Date</label>
              <DatePicker
                selected={pickUpDate}
                dateFormat="dd-MM-yyyy"
                autoComplete="off"
                onChange={(date) => {
                  setPickUpDate(date);
                }}
                className="custom-input"
                // isClearable
                minDate={new Date()}
                closeOnScroll={true}
                placeholderText="Select Pick up date"
              />
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="9"
                height="9"
                viewBox="0 0 9 9"
                fill="none"
              >
                <path
                  d="M2.57143 0C2.92701 0 3.21429 0.251367 3.21429 0.5625V1.125H5.78571V0.5625C5.78571 0.251367 6.07299 0 6.42857 0C6.78415 0 7.07143 0.251367 7.07143 0.5625V1.125H8.03571C8.56808 1.125 9 1.50293 9 1.96875V2.8125H0V1.96875C0 1.50293 0.43192 1.125 0.964286 1.125H1.92857V0.5625C1.92857 0.251367 2.21585 0 2.57143 0ZM0 3.375H9V8.15625C9 8.62207 8.56808 9 8.03571 9H0.964286C0.43192 9 0 8.62207 0 8.15625V3.375ZM1.28571 4.78125V5.34375C1.28571 5.49844 1.43036 5.625 1.60714 5.625H2.25C2.42679 5.625 2.57143 5.49844 2.57143 5.34375V4.78125C2.57143 4.62656 2.42679 4.5 2.25 4.5H1.60714C1.43036 4.5 1.28571 4.62656 1.28571 4.78125ZM3.85714 4.78125V5.34375C3.85714 5.49844 4.00179 5.625 4.17857 5.625H4.82143C4.99821 5.625 5.14286 5.49844 5.14286 5.34375V4.78125C5.14286 4.62656 4.99821 4.5 4.82143 4.5H4.17857C4.00179 4.5 3.85714 4.62656 3.85714 4.78125ZM6.75 4.5C6.57321 4.5 6.42857 4.62656 6.42857 4.78125V5.34375C6.42857 5.49844 6.57321 5.625 6.75 5.625H7.39286C7.56964 5.625 7.71429 5.49844 7.71429 5.34375V4.78125C7.71429 4.62656 7.56964 4.5 7.39286 4.5H6.75ZM1.28571 7.03125V7.59375C1.28571 7.74844 1.43036 7.875 1.60714 7.875H2.25C2.42679 7.875 2.57143 7.74844 2.57143 7.59375V7.03125C2.57143 6.87656 2.42679 6.75 2.25 6.75H1.60714C1.43036 6.75 1.28571 6.87656 1.28571 7.03125ZM4.17857 6.75C4.00179 6.75 3.85714 6.87656 3.85714 7.03125V7.59375C3.85714 7.74844 4.00179 7.875 4.17857 7.875H4.82143C4.99821 7.875 5.14286 7.74844 5.14286 7.59375V7.03125C5.14286 6.87656 4.99821 6.75 4.82143 6.75H4.17857ZM6.42857 7.03125V7.59375C6.42857 7.74844 6.57321 7.875 6.75 7.875H7.39286C7.56964 7.875 7.71429 7.74844 7.71429 7.59375V7.03125C7.71429 6.87656 7.56964 6.75 7.39286 6.75H6.75C6.57321 6.75 6.42857 6.87656 6.42857 7.03125Z"
                  fill="#7C7A7A"
                />
              </svg>
            </div>

            <div className="user-booking">
              <label className="title-name">Pick Up Time</label>
              <div className=" local-booking-time-dropdown">
                <DropdownColumn
                  placeholder="Select pick up time"
                  options={generateTimeOptions(pickUpDate).map((option) => ({
                    label: option,
                    value: option,
                  }))}
                  value={pickUpTime}
                  handleChange={(e) => handleChange(e)}
                  className="dropdown"
                  useInlineStyle={true}
                  autoComplete="off"
                  name="pickUpTime"
                  required
                />
              </div>
              <div className="local-booking-pick-time">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="17"
                  height="17"
                  viewBox="0 0 17 17"
                  fill="none"
                >
                  <g filter="url(#filter0_d_633_1454)">
                    <path
                      d="M6.57143 0C6.92701 0 7.21429 0.251367 7.21429 0.5625V1.125H9.78571V0.5625C9.78571 0.251367 10.073 0 10.4286 0C10.7842 0 11.0714 0.251367 11.0714 0.5625V1.125H12.0357C12.5681 1.125 13 1.50293 13 1.96875V2.8125H4V1.96875C4 1.50293 4.43192 1.125 4.96429 1.125H5.92857V0.5625C5.92857 0.251367 6.21585 0 6.57143 0ZM4 3.375H13V8.15625C13 8.62207 12.5681 9 12.0357 9H4.96429C4.43192 9 4 8.62207 4 8.15625V3.375ZM5.28571 4.78125V5.34375C5.28571 5.49844 5.43036 5.625 5.60714 5.625H6.25C6.42679 5.625 6.57143 5.49844 6.57143 5.34375V4.78125C6.57143 4.62656 6.42679 4.5 6.25 4.5H5.60714C5.43036 4.5 5.28571 4.62656 5.28571 4.78125ZM7.85714 4.78125V5.34375C7.85714 5.49844 8.00179 5.625 8.17857 5.625H8.82143C8.99821 5.625 9.14286 5.49844 9.14286 5.34375V4.78125C9.14286 4.62656 8.99821 4.5 8.82143 4.5H8.17857C8.00179 4.5 7.85714 4.62656 7.85714 4.78125ZM10.75 4.5C10.5732 4.5 10.4286 4.62656 10.4286 4.78125V5.34375C10.4286 5.49844 10.5732 5.625 10.75 5.625H11.3929C11.5696 5.625 11.7143 5.49844 11.7143 5.34375V4.78125C11.7143 4.62656 11.5696 4.5 11.3929 4.5H10.75ZM5.28571 7.03125V7.59375C5.28571 7.74844 5.43036 7.875 5.60714 7.875H6.25C6.42679 7.875 6.57143 7.74844 6.57143 7.59375V7.03125C6.57143 6.87656 6.42679 6.75 6.25 6.75H5.60714C5.43036 6.75 5.28571 6.87656 5.28571 7.03125ZM8.17857 6.75C8.00179 6.75 7.85714 6.87656 7.85714 7.03125V7.59375C7.85714 7.74844 8.00179 7.875 8.17857 7.875H8.82143C8.99821 7.875 9.14286 7.74844 9.14286 7.59375V7.03125C9.14286 6.87656 8.99821 6.75 8.82143 6.75H8.17857ZM10.4286 7.03125V7.59375C10.4286 7.74844 10.5732 7.875 10.75 7.875H11.3929C11.5696 7.875 11.7143 7.74844 11.7143 7.59375V7.03125C11.7143 6.87656 11.5696 6.75 11.3929 6.75H10.75C10.5732 6.75 10.4286 6.87656 10.4286 7.03125Z"
                      fill="#7C7A7A"
                    />
                  </g>
                  <defs>
                    <filter
                      id="filter0_d_633_1454"
                      x="0"
                      y="0"
                      width="17"
                      height="17"
                      filterUnits="userSpaceOnUse"
                      color-interpolation-filters="sRGB"
                    >
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                      />
                      <feOffset dy="4" />
                      <feGaussianBlur stdDeviation="2" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                      />
                      <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_633_1454"
                      />
                      <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_633_1454"
                        result="shape"
                      />
                    </filter>
                  </defs>
                </svg>
              </div>
            </div>
          </div>
        </div>

        <div className="row ">
          <div className="user-booking">
            <label className="title-name">Hourly Package</label>
            <div className="local-booking-hourly-dropdown">
              <DropdownColumn
                placeholder="Select Package" 
                options={hourlyPackage}
                selectedValue={selectedHourlyPackage}
                handleChange={handleChangeTripType}
                className="dropdown"
                useInlineStyle={true}
              />
            </div>
          </div>
          <div className="user-booking">
            <label className="title-cab-type">Cab Type</label>
            <div className="cab-type-dropdown">
              <DropdownColumn
                placeholder="Select cab type"
                options={cabType}
                selectedValue={selectedCabType}
                handleChange={(e) => setSelectedCabType(e.target.value)}
                className="dropdown"
                useInlineStyle={true}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LocalBookingForm;
