import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";

import { fetchAllCustomer } from "./CustomerAPI";

const AllinitialState = {
  customers: [],
  status: null,
  isLoading: false,
  isError: false,
  booleanValue: false,
  customerbookingdetailpath: null, // Add a new field for the new value
};

export const fetchCustomer = createAsyncThunk("fetchCustomer", async () => {
  try {
    const response = await fetchAllCustomer();
    // console.log(response);
    return response;
  } catch (e) {
    console.log("Error in fetching Data", e);
    throw e;
  }
});

// Action to set a new value
export const setNewValue = createAction("customer/setNewValue", (value) => {
  return {
    payload: value,
  };
});

const CustomerSlice = createSlice({
  name: "customer",
  initialState: AllinitialState,

  reducers: {
    toggleCustomerStatus: (state, action) => {
      console.log(state,action)
      const { customerId, newStatus } = action.payload;
      console.log( customerId)
      console.log(newStatus )
      const cuustomerIndex = state.customers.findIndex(customer => customer._id === customerId);
      if (cuustomerIndex !== -1) {
        state.customers[cuustomerIndex].status = newStatus;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCustomer.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchCustomer.fulfilled, (state, action) => {
      state.isLoading = false;
      state.status = "idle";
      state.customers = action.payload;
    });
    builder.addCase(fetchCustomer.rejected, (state, action) => {
      state.isLoading = false;
      console.log("Error", action.payload);
      state.isError = true;
    });

    // Reducer for setting a new value
    builder.addCase(setNewValue, (state, action) => {
      state.customerbookingdetailpath = action.payload;
    });
  },
  
});





export const selectAllCustomer = (state) => state.customer.customers;
export const selectLoading = (state) => state.customer.isLoading;
export const selectNewValue = (state) => state.customer.customerbookingdetailpath; // Selector for the new value
export const customerSlice = CustomerSlice.reducer;
export const { toggleCustomerStatus } = CustomerSlice.actions;
