import React from "react";
import map from "./../../assets/map.png";
import "./RouteLog.css";
import  { useEffect, useRef } from "react";
import { selectbillingDetails, selectbookingDetails } from "./DetailsSlice";
import { useSelector } from "react-redux";
import TableFirstNotCompleted from "./tablebilling/TableFirstNotCompleted"
import TableFirst from "./tablebilling/TableFirst";

// import { IoArrowBackCircleSharp } from "react-icons/io5";
// import { IoArrowForwardCircle } from "react-icons/io5";

const TableItem = ({ item, index }) => {
  return (
    <tr >
      <td className={`trip-head ${index === 0 ? "first-row" : ""}`}>
        {item["Car Category"]}
      </td>
      <td>{item["Trip Type"]}</td>
      <td>{item["Route"]}</td>
      <td>{item["Trip Start Date & Time"]}</td>
      <td>{item["Trip End Date & Time"]}</td>
      <td>{item["No of Days"]}</td>
      <td>{item["Distance Travelled"]}</td>
    </tr>
  );
};

const Table = ({ headers, data }) => {
  return (
    <table className="route-log-table">
      <thead>
        <tr>
          {headers.map((header, index) => (
            <th key={index}>{header}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((item, index) => (
          <TableItem key={index} item={item} index={index} />
        ))}
      </tbody>
    </table>
  );
};

const RouteLog = ({ handleButtonClick }) => {
  const bookingDetails = useSelector(selectbookingDetails);
  const billingDetails = useSelector(selectbillingDetails);
  console.log("billing details",billingDetails)
  console.log("")

  const headers = [
    "Car Category",
    "Trip Type",
    "Route",
    "Trip Start Date & Time",
    "Trip End Date & Time",
    "No of Days",
    "Distance Travelled",
  ];

  const ViewAllCityData = [
    {
      "Car Category":"Sedan",
      "Trip Type": "Outstation - Round Trip",
      "Route": "Lucknow - Gorakhpur - Varanasi",
      "Trip Start Date & Time": "13-12-2023 12:00",
      "Trip End Date & Time": "17-12-2023 22:30",
      "No of Days": "5 Days",
      "Distance Travelled": "650 KM",
    },
  ];

  const locations = [
    { lat: 28.7041, lng: 77.1025 }, 
    { lat: 28.7051, lng: 77.1035 },
    { lat: 28.7061, lng: 77.1045 },
    { lat: 28.7071, lng: 77.1055 },
    { lat: 28.7081, lng: 77.1065 },
    { lat: 28.7091, lng: 77.1075 },
    { lat: 28.7101, lng: 77.1085 },
    { lat: 28.7111, lng: 77.1095 },
    { lat: 28.7121, lng: 77.1105 },
    { lat: 28.7131, lng: 77.1115 },
  ];
  const travelledData=[
    {
      "day":"Day 1",
      "date":"13/12/2023",
      "distance":"40KM"
    },
    {
      "day":"Day 2",
      "date":"14/12/2023",
      "distance":"50KM"
    },
    {
      "day":"Day 3",
      "date":"15/12/2023",
      "distance":"60KM"
    },
    {
      "day":"Day 4",
      "date":"16/12/2023",
      "distance":"70KM"
    },
    {
      "day":"Day 5",
      "date":"18/12/2023",
      "distance":"80KM"
    },
  ]
  
  return (
    <div>
      <div className="main-box-route">
        {/* <div style={{ display: "flex", justifyContent: "space-between" }}>
          <IoArrowBackCircleSharp
            onClick={() => handleButtonClick("Interaction")}
            className="back"
          />
          <IoArrowForwardCircle
            onClick={() => handleButtonClick("BillingDetails")}
            className="back"
          />
        </div> */}
        <div className="top-extra-space"></div>
        {/* <Table headers={headers} data={ViewAllCityData} /> */}
        {bookingDetails?.status === "Completed" ? (
          <>
            <TableFirst data={billingDetails?.data} />
          </>
        ) : (
          <>
          {/* trip details table */}
            <TableFirstNotCompleted bookingDetailsTablefirst={bookingDetails} />
          </>
        )}
        {/* Used the table Component that was used in Billing  */}
        {/* <TableFirstNotCompleted bookingDetailsTablefirst={bookingDetails} /> */}
        <div className="top-extra-space"></div>

        {/* distancce travelled div */}
     { (  bookingDetails?.tariff?.tripType === "Outstation")&&    <div style={{display:"flex",justifyContent:"center",gap:"30px",fontSize:"12px",fontWeight:700,marginBottom:"30px",marginTop:"30px"}}>
              {
                   travelledData.map((data,index)=>(
                    <div style={{display:"flex",flexDirection:"column",alignItems:"center",}} >
                      <div>{data.day}</div>
                       <span>-</span>
                      <div>{data.date}</div>
                      <span>-</span>
                      <div>{data.distance}</div>
                    </div>
                   ))
              }
        </div>}
        <div className="map-box">

          <Map locations={locations} zoom={14} />
        </div>
      </div>
    </div>
  );
};


const Map = ({ locations, zoom = 14 }) => {
  const mapRef = useRef(null);
  const GOOGLE_MAPS_API_KEY="AIzaSyB1LsPrgq_8-ygapY5zgmzGhXuPcn4VtQQ"
  console.log("env",process.env)

  useEffect(() => {
    const loadGoogleMaps = () => {
      const script = document.createElement("script");
      script.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&callback=initMap`;
      script.async = true;
      script.defer = true;
      document.body.appendChild(script);
    };

    window.initMap = () => {
      if (mapRef.current) {
        const map = new window.google.maps.Map(mapRef.current, {
          center: locations[0],
          zoom,
        });

        locations.forEach(({ lat, lng }) => {
          new window.google.maps.Marker({
            position: { lat, lng },
            map,
            icon: {
              url: "http://maps.google.com/mapfiles/ms/icons/red-dot.png",
            },
          });
        });
      }
    };

    if (!window.google) {
      loadGoogleMaps();
    } else {
      window.initMap();
    }
  }, [locations, zoom]);

  return <div ref={mapRef} className="map-container"></div>;
};

export default RouteLog;
