import React, { useState, useRef, useEffect } from "react";
import CountryDropdown from "../countrydropdown/CountryDropdown";
import "./AddCustomer.css";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import { useParams, Link, useNavigate } from "react-router-dom";
import { fetchCustomerDetails, resetCompState } from "./CustomerDetailsSclice";
import { FaLongArrowAltRight } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../Loader";
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const EditCustomerDetails = () => {
  const [firstName, setFirstName] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [alternateMobile, setAlternateMobile] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumberVerified, setPhoneNumberVerified] = useState(false);
  const [otpInput, setOtpInput] = useState(["", "", "", "", "", ""]);
  const [verified, setVerified] = useState(false);
  const [OtpId, setOtpId] = useState("");
  const [verificationToken, setVerificationToken] = useState("");
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isOtpModalOpen, setIsOtpModalOpen] = useState(false);
  const otpInputs = useRef([]);

  const singleCustomerDetail = useSelector(
    (state) => state.customerInfo?.customerDetails
  );

  const handleOTPChange = (index, value) => {
    const updatedOTP = [...otpInput];
    updatedOTP[index] = value;
    setOtpInput(updatedOTP);

    if (value !== "" && index < otpInputs.current.length - 1) {
      otpInputs.current[index + 1].focus();
    }
  };

  if (selectedCountry && phoneNumber) {
    var completePhoneNumber = `${selectedCountry}-${phoneNumber}`;
  }

  if (selectedCountry && alternateMobile) {
    var completeAlterNumber = `${selectedCountry}-${alternateMobile}`;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const headers = {
      "Content-Type": "application/json",
    };
    const custumerObj = new FormData();
    custumerObj.append("firstName", firstName);
    custumerObj.append("lastName", lastName);
    // custumerObj.append("email", email);
    custumerObj.append("phone", completePhoneNumber);
    // custumerObj.append("otpToken", verificationToken);
    // custumerObj.append("alternatePhone", completeAlterNumber);

    console.log("Data Entered");
    for (const pair of custumerObj.entries()) {
      console.log(pair[0] + ": " + pair[1]);
    }

    try {
      await axios.put(
        `${API_BASE_URL}/v1/admin/users/${id}`,
        custumerObj,
        { headers }
      );
      toast.success("Details Updated successfully!");

      setTimeout(() => {
        window.location.reload();
      }, 3000);

      e.target.reset();
    } catch (error) {
      toast.error(error.response.data.message);
      console.error(error.response.data.message);
    }
  };

  const handleVerifyPhoneNumber = () => {
    console.log("Phone Number:", phoneNumber);
    fetch(`${API_BASE_URL}/v1/send-otp`, {
      method: "POST",
      body: JSON.stringify({ phone: completePhoneNumber }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.ok) {
          console.log("OTP sent successfully");
          console.log(response);
          return response.json();
        } else {
          throw new Error("Failed to send OTP");
        }
      })
      .then((data) => {
        if (data && data.data && data.data.otpId) {
          setOtpId(data.data.otpId);
          setPhoneNumberVerified(true);
          setIsOtpModalOpen(true);
          console.log("OTP ID:", data.data.otpId);
        } else {
          throw new Error("Invalid response format from OTP sending API");
        }
      })
      .catch((error) => {
        console.error("Error sending OTP:", error.message);
      });
  };

  const handleVerifyOTP = () => {
    const otpNumber = parseInt(otpInput.join(""), 10);
    console.log("OTP:", otpNumber);
    console.log("OTP ID:", OtpId);
    fetch(`${API_BASE_URL}/v1/verify-otp`, {
      method: "POST",
      body: JSON.stringify({
        phone: completePhoneNumber,
        otp: otpNumber,
        otpId: OtpId,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.ok) {
          console.log("OTP verified successfully");
          console.log(response);
          setVerified(true);
          setIsOtpModalOpen(false);
          return response.json();
        } else {
          throw new Error("Failed to verify OTP");
        }
      })
      .then((data) => {
        setVerificationToken(data.otpToken);
        console.log("Message:", data.message);
        console.log("Token:", data.otpToken);
      })
      .catch((error) => {
        console.error("Error verifying OTP:", error.message);
      });

    console.log("Phone Number:", phoneNumber);
  };

  const handlePhoneCode = (phoneNumber, value) => {
    let ind = 0;
    let code = "+";
    while (phoneNumber[ind] !== "-") {
      if (phoneNumber[ind] !== "+") {
        code = code + phoneNumber[ind];
      }
      ind = ind + 1;
    }
    const checkNumber = phoneNumber.slice(ind + 1);
    if (value === "phone") {
      setPhoneNumber(checkNumber);
    }

    if (value === "alternatePhone") {
      setAlternateMobile(checkNumber);
    }
    setSelectedCountry(code);
    console.log("checking code", code);
    console.log("checking Number", checkNumber);
  };

  useEffect(() => {
    if (singleCustomerDetail) {
      console.log("checking customer details", singleCustomerDetail);
      setFirstName(singleCustomerDetail.firstName);
      setLastName(singleCustomerDetail.lastName);
      // setPhoneNumber(singleCustomerDetail.phone);
      // setAlternateMobile(singleCustomerDetail.alternatePhone);
      setEmail(singleCustomerDetail.email);
      handlePhoneCode(singleCustomerDetail.phone, "phone");
      handlePhoneCode(singleCustomerDetail.alternatePhone, "alternatePhone");
    }
  }, [singleCustomerDetail]);

  useEffect(() => {
    if (id) {
      dispatch(fetchCustomerDetails(id));
    }
  }, []);
  return (
    <>
      <Toaster />
      <form onSubmit={handleSubmit}>
        <div className="nav-content" style={{ marginTop: "25px" }}>
          <div className="header-text">
            <Link className="h3-booking">Customers</Link>
            <FaLongArrowAltRight />
            {""}
            <Link className="h3-booking">View All Customers</Link>
            <FaLongArrowAltRight />
            <Link className="h2-booking">Edit Customer Details</Link>
          </div>

          <button
            className="createButton"
            onClick={() => navigate("/customer")}
          >
            View All Customers
          </button>
        </div>
        {!singleCustomerDetail ? (
          <Loader />
        ) : (
          <>
            <div className="add-new-customer-container">
              <div className="customer">
                <div className="crete-new-sub-head">Edit Customer Details</div>
                <div className="customer-details">
                  {/* Row 1: First Name and Last Name */}
                  <div style={{ marginBottom: "40px" }} className="row1">
                    <div className="col">
                      <div className="col-item">
                        <div className="dropdown-column">
                          <div
                            style={{
                              marginRight: "60px",
                              marginBottom: "10px",
                            }}
                            className="title"
                          >
                            First Name
                          </div>
                          <input
                            type="text"
                            onChange={(e) => setFirstName(e.target.value)}
                            placeholder="Type First Name"
                            className="input"
                            style={{ marginLeft: "25px" }}
                            value={firstName}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col">
                      <div className="col-item">
                        <div
                          className="dropdown-column"
                          style={{ marginLeft: "110px" }}
                        >
                          <div
                            className="title"
                            style={{ marginBottom: "10px",marginLeft:"-15px" }}
                          >
                            Last Name
                          </div>
                          <input
                            type="text"
                            onChange={(e) => setLastName(e.target.value)}
                            placeholder="Type Last Name"
                            className="input"
                            style={{ marginLeft: "65px", }}
                            value={lastName}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Row 2: Primary and Alternate Mobile Numbers */}
                  <div style={{ marginBottom: "40px" }} className="row2">
                    {/* Primary Mobile No. */}
                    <div className="col">
                      <div className="col-item-phone">
                        <div
                          className={`dropdown-phone ${
                            phoneNumberVerified && !verified
                              ? "column-layout"
                              : "row-layout"
                          }`}
                        >
                          <label
                            className="title"
                            style={{
                              minWidth: "150px",
                              textAlign: "right",
                              marginBottom: "10px",
                              display: "flex",
                              flexDirection: "column",
                              lineHeight: "1.2",
                            }}
                          >
                            <span style={{ marginLeft: "-75px" }}>
                              {" "}
                              Primary{" "}
                            </span>
                            <span style={{ marginLeft: "-50px" }}>
                            Mobile No.
                            </span>
                          </label>
                          {!verified ? (
                            <React.Fragment>
                              <CountryDropdown
                                selectedCountry={selectedCountry}
                                setSelectedCountry={setSelectedCountry}
                              />
                              <input
                                type="text"
                                onChange={(e) => setPhoneNumber(e.target.value)}
                                maxLength="10"
                                placeholder="Type Mobile Number"
                                style={{ width:"180px" }}
                                className="input-mobile"
                                value={phoneNumber}
                                disabled={phoneNumberVerified}
                              />
                              {!phoneNumberVerified &&
                                phoneNumber.length === 10 && (
                                  <div
                                    className="verifynow-btn"
                                    onClick={() => {
                                      handleVerifyPhoneNumber();
                                      setIsOtpModalOpen(true); // Open OTP modal
                                    }}
                                  >
                                    Verify Now
                                  </div>
                                )}
                            </React.Fragment>
                          ) : (
                            <React.Fragment>
                              <div className="phone-number">
                                <CountryDropdown
                                  selectedCountry={selectedCountry}
                                  setSelectedCountry={setSelectedCountry}
                                />
                                <input
                                  type="tel"
                                  onChange={(e) =>
                                    setPhoneNumber(e.target.value)
                                  }
                                  maxLength="10"
                                  value={phoneNumber}
                                  placeholder="Type Mobile Number"
                                  className="input-mobile"
                                />
                                <div className="verified">Verified</div>
                              </div>
                            </React.Fragment>
                          )}
                        </div>
                      </div>
                    </div>

                    {/* OTP Modal */}
                    {isOtpModalOpen && (
                      <div className="otp-modal">
                        <div className="otp-modal-content">
                          <h3>Enter OTP</h3>
                          <div className="otp-inputs">
                            {otpInput.map((value, index) => (
                              <input
                                key={index}
                                ref={(input) =>
                                  (otpInputs.current[index] = input)
                                }
                                type="text"
                                maxLength="1"
                                className="otp-input"
                                value={value}
                                onChange={(e) =>
                                  handleOTPChange(index, e.target.value)
                                }
                              />
                            ))}
                          </div>
                          <button
                            type="button"
                            className="verify"
                            onClick={handleVerifyOTP}
                          >
                            Verify OTP
                          </button>
                          <button
                            type="button"
                            className="close-btn"
                            onClick={() => setIsOtpModalOpen(false)}
                          >
                            Close
                          </button>
                        </div>
                      </div>
                    )}

                    {/* Alternate Mobile No. */}
                    <div className="col">
                      <div className="col-item">
                        <div
                          className="dropdown-column"
                          style={{ marginLeft: "20px",marginTop:"20px" }}
                        >
                          {/* <div
                            style={{ marginRight: "45px" }}
                            className="title"
                          >
                            
                          </div> */}
                          <label
                            className="title"
                            style={{
                              minWidth: "150px",
                              textAlign: "right",
                              marginBottom: "10px",
                              display: "flex",
                              flexDirection: "column",
                              lineHeight: "1.2",
                            }}
                          >
                            <span style={{ marginLeft: "-50px" }}>
                              {" "}
                              Alternate .{" "}
                            </span>
                            <span style={{ marginLeft: "-50px" }}>
                              Mobile No
                            </span>
                          </label>
                          <CountryDropdown
                            selectedCountry={selectedCountry}
                            setSelectedCountry={setSelectedCountry}
                          />
                          <input
                            type="mobile"
                            style={{ marginLeft: "20px"}}
                            onChange={(e) => setAlternateMobile(e.target.value)}
                            placeholder="Alternate Mobile Number"
                            className="input-mobile"
                            value={alternateMobile}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Row 3: Email Address */}
                  <div className="row3">
                    <div className="col">
                      <div className="col-item">
                        <div className="dropdown-column">
                          <div
                            style={{ marginRight: "35px",marginBottom:"10px" }}
                            className="title"
                          >
                            Email Address
                          </div>
                          <input
                            type="text"
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="Type Email Address"
                            className="input"
                            style={{ marginLeft: "45px" }}
                            value={email}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="add">
                <button type="submit" className="add-btn">
                  Update
                </button>
              </div>
            </div>
          </>
        )}
      </form>
    </>
  );
};

export default EditCustomerDetails;
