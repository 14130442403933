import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchsinglecardata } from "./carsAPI";

const initialState = {
  singleCarData: [],
  currentComp: null,
  status: null,
  isLoading: false,
  isError: false,
};

export const fetchSingleCarDetailsAsync = createAsyncThunk(
  "carDetails/fetchSingleCarDetails",
  async (carId) => {
    return await fetchsinglecardata(carId);
  }
);

const CarDetailsSlice = createSlice({
  name: "carDetails",
  initialState,
  reducers: {
    resetCarDetails: (state) => {
      state.singleCarData = [];
      state.status = "idle";
      state.isLoading = false;
      state.isError = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSingleCarDetailsAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchSingleCarDetailsAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.status = "idle";
        state.singleCarData = action.payload;
      })
      .addCase(fetchSingleCarDetailsAsync.rejected, (state) => {
        state.isLoading = false;
        state.isError = true;
      });
  },
});

export const { resetCarDetails , currentCarId} = CarDetailsSlice.actions;
export const carDetailsReducer = CarDetailsSlice.reducer;
