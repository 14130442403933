import React, { useEffect, useRef } from "react";
import "./searchLocation.css";

const apiUrl = "AIzaSyB1LsPrgq_8-ygapY5zgmzGhXuPcn4VtQQ";

// const apiUrl = process.env.GOOGLE_MAPS_KEY;
// console.log({ apiUrl });
let autoComplete;

const loadScript = (url, callback) => {
  let script = document.createElement("script");
  script.type = "text/javascript";

  if (script.readyState) {
    script.onreadystatechange = function () { 
      if (script.readyState === "loaded" || script.readyState === "complete") {
        script.onreadystatechange = null;
        callback();
      }
    };
  } else {
    script.onload = () => callback();
  }

  script.src = url;
  document.getElementsByTagName("head")[0].appendChild(script);
};

const SearchLocationInput = ({ setlatlong, query, setQuery }) => {
  const autoCompleteRef = useRef(null);

  const handleScriptLoad = (updateQuery, autoCompleteRef) => {
    autoComplete = new window.google.maps.places.Autocomplete(
      autoCompleteRef.current,
      {
        // types: ["(cities)"],
        componentRestrictions: { country: "IN" },
      }
    );

    autoComplete.addListener("place_changed", () => {
      handlePlaceSelect(updateQuery);
    });
  };

  const handlePlaceSelect = async (updateQuery) => {
    const addressObject = await autoComplete.getPlace();

    const query = addressObject.formatted_address;
    updateQuery(query);
    console.log({ query });

    const latLng = {
      lat: addressObject?.geometry?.location?.lat(),
      lng: addressObject?.geometry?.location?.lng(),
    };

    console.log({ latLng });
    setlatlong(latLng);
  };

  useEffect(() => {
    loadScript(
      `https://maps.googleapis.com/maps/api/js?key=${apiUrl}&libraries=places`,
      () => handleScriptLoad(setQuery, autoCompleteRef)
    );
  }, []);

  return (
 
    <>
       <div className="pickup-address">
      <input
        ref={autoCompleteRef}
        className="custom-input"
        onChange={(event) => setQuery(event.target.value)}
        value={query}
        placeholder="Pickup Address"
      />
      <div className="to-the-airport-picadd">
      <svg xmlns="http://www.w3.org/2000/svg" width="15" height="17" viewBox="0 0 15 17" fill="none">
  <g filter="url(#filter0_d_633_1453)">
    <path d="M7.93203 8.79777C8.86719 7.66632 11 4.92407 11 3.38376C11 1.51564 9.43229 0 7.5 0C5.56771 0 4 1.51564 4 3.38376C4 4.92407 6.13281 7.66632 7.06797 8.79777C7.29219 9.06741 7.70781 9.06741 7.93203 8.79777ZM7.5 2.25584C7.80942 2.25584 8.10617 2.37467 8.32496 2.5862C8.54375 2.79772 8.66667 3.08461 8.66667 3.38376C8.66667 3.6829 8.54375 3.96979 8.32496 4.18132C8.10617 4.39284 7.80942 4.51168 7.5 4.51168C7.19058 4.51168 6.89383 4.39284 6.67504 4.18132C6.45625 3.96979 6.33333 3.6829 6.33333 3.38376C6.33333 3.08461 6.45625 2.79772 6.67504 2.5862C6.89383 2.37467 7.19058 2.25584 7.5 2.25584Z" fill="#7C7A7A"/>
  </g>
  <defs>
    <filter id="filter0_d_633_1453" x="0" y="0" width="15" height="17" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset dy="4"/>
      <feGaussianBlur stdDeviation="2"/>
      <feComposite in2="hardAlpha" operator="out"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_633_1453"/>
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_633_1453" result="shape"/>
    </filter>
  </defs>
</svg>
      </div>
      </div>
    </>
  );
};

export default SearchLocationInput;
