import React from 'react';
import './secondtable.css';

const TableItem = ({ item, index }) => {
  return (
    <tr className="second_Earning_table_data">
      <td>{item[" "]}</td>
      <td>{item["Amount in Rs ."]}</td>
    </tr>
  );
};

const Table = ({ headers, data }) => {
  return (
    <div className="second_table_containers">
      <div className="second_table_scroll">
        <table 
          className="second_table"
          style={{ 
            tableLayout: 'fixed',  // Ensures columns respect the widths set in <colgroup>
            borderCollapse: 'collapse'
          }}
        >
          <colgroup>
            {/* First column: 30% of total width */}
            <col style={{ width: '51%',borderRadius:"0" }} />
            {/* Second column: 70% of total width */}
            <col style={{ width: '49.5%',borderRadius:"0"  }} />
          </colgroup>
          <thead>
            <tr>
              {headers.map((header, index) => (
                <th style={{borderRadius:"0" }} key={index}>{header}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <tr key={index} className="second_Earning_table_data">
                <td>{item[" "]}</td>
                <td>{item["Amount in Rs ."]}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};


const SecondTable = ({earningData}) => {
  // Get the current date and format it as "01-MM-YYYY"
  const getCurrentDate = () => {
    const now = new Date();
    const month = String(now.getMonth() + 1).padStart(2, '0'); // Add 1 since months are 0-indexed
    const year = now.getFullYear();
    return `01-${month}-${year}`;
  };

  const headers = [
    " ",
    "Amount in Rs .",
  ];

  const data = [
    {
      " ": `Opening Balance as on ${getCurrentDate()}`,
      "Amount in Rs .": "Rs 10",
    },
    {
      " ": "Total Business",
      "Amount in Rs .": "Rs 10",
    },
    {
      " ": "Total Driver Earning",
      "Amount in Rs .": "Rs 10",
    },
    {
      " ": "Total Amount Received in Cash",
      "Amount in Rs .": "Rs 10",
    },
    {
      " ": "Payment Pending",
      "Amount in Rs .": "Rs 10",
    },
  ];

  return <Table headers={headers} data={data} />;
};

export default SecondTable;
