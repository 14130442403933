import React, { useState } from "react";
import { IoIosArrowDown } from "react-icons/io";
import { Link } from "react-router-dom";
import "./App.css";
import { FaPen, FaTimes } from "react-icons/fa";
import { RxCross2 } from "react-icons/rx";

const App = () => {
  const [banner1, setBanner1] = useState("");
  const [banner2, setBanner2] = useState("");
  const [banner3, setBanner3] = useState("");
  const [banner4, setBanner4] = useState("");

  const banners = [
    {
      state: banner1,
      setState: setBanner1,
    },
    {
      state: banner2,
      setState: setBanner2,
    },
    {
      state: banner3,
      setState: setBanner3,
    },
    {
      state: banner4,
      setState: setBanner4,
    },
  ];

  const handleFileChange = (e, setFile) => {
    const selectedFile = e.target.files[0];
    console.log("event", e);
    if (selectedFile) {
      setFile(selectedFile);
    }
  };

  return (
    <div style={{ minWidth: "81dvw", paddingRight: "10px", marginTop: "20px",position:"" }}>
      <div className="app-managemen">
        <div className="heade">
          <div style={{
            display:"flex",
            alignItems:"center",
            gap:"5px"
          }} className="header-tex">
            <span>
              {/* <Link to="/dashboard" className=""> */}
              App Management
              {/* </Link> */}
            </span>
            <IoIosArrowDown
              style={{ transform: "rotate(-90deg)" }}
              color="#f56a00"
            />
          </div>
        </div>
      </div>

      <div style={{
        marginTop:"30px"
      }}>
        <div className="">
          <div className="user-banner-div">User App Banner</div>

          <div
            style={{
              display: "flex",
              gap: "20px",
              justifyContent: "space-between",
            }}
          >
            {banners.map(({ state, setState }, index) => (
              <div>
                {" "}
                <div
                  style={{
                    marginTop: "30px",
                    position: "relative",
                  }}
                >
                  <div className="image-div">
                    {state ? (
                      <img
                      className="banner-img"
                        src={URL.createObjectURL(state)}
                        alt="Selected"
                       
                      />
                    ) : null}

                    {state && (
                      <span
                        className="cross-icon"
                        onClick={() => setState(null)}
                      >
                        ×
                      </span>
                    )}
                  </div>

                  <div className="choose-file-div">
                    <label className="choose-file-label">
                      <input
                        type="file"
                        style={{ display: "none" }}
                        onChange={(e) => handleFileChange(e, setState)}
                      />
                      Choose File
                    </label>

                    {/* Selected File Info */}
                    <p
                      style={{
                        fontSize: "11px",
                        color: "black",
                        fontWeight: "700",
                      }}
                    >
                      {state ? `${state.name}` : "No file chosen"}
                    </p>
                  </div>
                </div>
                <div
                  style={{
                    textAlign: "center",
                    fontSize:"20px",
                    fontWeight:"700"
                  }}
                >
                  Banner {index + 1}
                </div>
              </div>
            ))}
          </div>
        </div>

        <div style={{
            marginTop:"80px"
        }}>
          <div style={{
            display:"flex",
            alignItems:"center",
            
          }} className="user-banner-div">24<span><RxCross2 /></span>7 Customer Support Number</div>
          <div style={{
            marginTop:"50px",
            display:"flex",
            gap:"70px",
            marginLeft:"50px"
          }}>
            <span style={{
                color:"#6D6D6D",
                fontSize:"20px",
                fontWeight:700
            }}>Customer Support Number:</span>
           <div>
           <span style={{
                   color:"black",
                   fontSize:"20px",
                   fontWeight:700
            }}>+918960170877</span>
            <span style={{
                color:"#0C41FF",
                marginLeft:"20px",
             
                fontSize:"20px"
            }}><FaPen/></span>
           </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default App;
