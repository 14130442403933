import React from "react";
import { useSelector } from "react-redux";
import { selectbookingDetails } from "../DetailsSlice";
import "./Fourthtable.css";

const FourthFare = ({ data }) => {
  const bookingDetails = useSelector(selectbookingDetails);
  // if (!data || typeof data !== "object") {
  //   return null;
  // }

  console.log("data from fourthFare",data?.extraFare);

  const extrakmchargeRate = data?.extraFare?.extraKmChargeRate || 0;
  const extraHrsChargeRate = data?.extraFare?.extraHrsChargeRate ?? 0;
  // const finalExtrakmcharge =
  //   extrakmchargeRate *
  //   (data?.data?.actualDistance - data?.data?.approxDistance);
  const finalExtrakmcharge=data?.extraFare?.extraKmCharge||0
  const finalExtraHrsCharge = data?.extraFare?.extraHrsCharge ?? 0;
  const nightDriverAllowanceRate = data?.extraFare?.nightDriverAllowanceRate || "N/A";

  const extraNightAllowanceDay=(data?.extraFare?.extraNightAllowanceDays)

  const finalNightDriverAllowance = data?.extraFare?.extraNightAllowance ;
  const gstRate = data?.data?.gstAmount || 0;
  const finalGstRate = data?.data?.gstAmount || 0;
  // const finalGstAmount =
  //   data?.data?.paymentDetails && data?.data?.paymentDetails.length > 0
  //     ? data?.data?.paymentDetails[data?.data?.paymentDetails.length - 1]?.totalAmount
  //     : 0;

  const extraCharges = data?.extraFare?.extraCharges ?? 0;
  const finalGstAmount = data?.extraFare?.gstOnExtraFare ?? 0;
  const totalextraamount = data?.extraFare?.totalExtraAmount
 
  const balanceAmount = data?.extraFare?.plusBalanceAmount || 0;
  
  const cashPaid = data?.extraFare?.lessCashForFuelReceivedByDriverInAdvance  ?? 0;
  // const finalcash = (totalAmtPaidToDriver - cashPaid) + (totalextraamount) ;


  const totalAmtPaidToDriverPaymentMode=data?.extraFare?.totalPaidCash>0?"Cash":"Online"
  

  const totalAmtPaidToDriver = data?.extraFare?.totalPaidOnline || data?.extraFare?.totalPaidCash;
 

  


  if(bookingDetails?.tariff?.tripType==="Outstation" && bookingDetails?.tariff?.subTripType==="Round Trip"){

    
  var farebreakupdata = [
    {
      lable: `Extra km charge @Rs.${extrakmchargeRate}/Km `,
      value: finalExtrakmcharge.toFixed(2),
    },
    {
      lable: `Extra Driver Allowance  @Rs.${data?.extraFare?.extraDriverAllowanceRate}/day X ${data?.extraCoverage?.extraDays} Day`,
      value: data?.extraFare?.extraDriverAllowance!=0?data?.extraFare?.extraDriverAllowance?.toFixed(2):"N/A",
    },
    {
      lable: `5 % GST on Rs. ${extraCharges.toFixed(2)}`,
      value: finalGstAmount.toFixed(2),
    },
    { lable: "Total Extra Amount", value: totalextraamount.toFixed(2) },
    { lable: "Plus Balance Amount", value: balanceAmount.toFixed(2)},
    { lable: "Less cash for fuel received by driver in advance", value: cashPaid.toFixed(2) },
    { lable: `Total Paid to ${totalAmtPaidToDriverPaymentMode}` , value: totalAmtPaidToDriver.toFixed(2) },
  ];


  }else if(bookingDetails?.tariff?.tripType==="Outstation" && bookingDetails?.tariff?.subTripType==="One Way"){
    var farebreakupdata = [
      {
        lable: `Extra km charge @Rs.${extrakmchargeRate}/Km `,
        value: finalExtrakmcharge.toFixed(2),
      },
      {
        lable: `Night Charge  @Rs.${nightDriverAllowanceRate}/day`,
        value: finalNightDriverAllowance!=0?finalNightDriverAllowance?.toFixed(2):"N/A",
      },
      {
        lable: `5 % GST on Rs. ${extraCharges.toFixed(2)}`,
        value: finalGstAmount.toFixed(2),
      },
      { lable: "Total Extra Amount", value: totalextraamount.toFixed(2) },
      { lable: "Plus Balance Amount", value: balanceAmount.toFixed(2) },
      { lable: "Less cash for fuel received by driver in advance", value: cashPaid.toFixed(2) },
      { lable: `Total Paid to ${totalAmtPaidToDriverPaymentMode}` , value: totalAmtPaidToDriver.toFixed(2) },
    ];
  

  }
  
  else if(bookingDetails?.tariff?.tripType==="Local"){

    
  var farebreakupdata = [
    {
      lable: `Extra Km charge  @Rs.${extrakmchargeRate}/Km `,
      value: `${finalExtrakmcharge?.toFixed(2)}`,
    },
    {
      lable: `Extra Hrs charge @Rs.${extraHrsChargeRate}/Hr `,
      value: `${finalExtraHrsCharge?.toFixed(2)}`,
    },
    {
      lable: `Night Charge  @Rs.${nightDriverAllowanceRate}/day`,
      value:finalNightDriverAllowance!=0?finalNightDriverAllowance?.toFixed(2):"N/A",
    },
    {
      lable: `5 % GST on Rs. ${extraCharges.toFixed(2)}`,
      value: finalGstAmount?.toFixed(2),
    },
    { lable: "Total Extra Amount", value: totalextraamount?.toFixed(2) },
    { lable: "Plus Balance Amount", value: balanceAmount.toFixed(2) },
    { lable: "Less cash for fuel received by driver in advance", value: cashPaid },
    { lable: `Total Paid to ${totalAmtPaidToDriverPaymentMode}`, value: totalAmtPaidToDriver?.toFixed(2) },
  ];


  }else{
    
    
  var farebreakupdata = [
    {
      lable: `Extra Km charge @Rs.${extrakmchargeRate}/Km `,
      value: finalExtrakmcharge.toFixed(2),
    },
    {
      lable: `Extra Hrs charge @Rs.${extraHrsChargeRate}/Hr `,
      value: finalExtraHrsCharge.toFixed(2),
    },
    {
      lable: `Extra Night Allowance @Rs.${nightDriverAllowanceRate}/day X 1 Day`,
      value:finalNightDriverAllowance!=0?finalNightDriverAllowance?.toFixed(2):"N/A",
    },
    {
      lable: `5 % GST on Rs. ${extraCharges.toFixed(2)}`,
      value: finalGstAmount.toFixed(2),
    },
    { lable: "Total Extra Amount", value: totalextraamount.toFixed(2) },
    { lable: "Plus Balance Amount", value: balanceAmount.toFixed(2) },
    { lable: "Less cash for fuel received by driver in advance", value: cashPaid.toFixed(2) },
    { lable: "Total Paid - Online", value: totalAmtPaidToDriver.toFixed(2) },
  ];
  }

  return (
    <div className="billing_fourth_containers">
      <div className="billing_fourth_scroll">
        <table className="billing-table-fourth">
          <thead>
            <tr>
              <th  style={{
              textAlign:"center"
            }} colSpan="2">Extra Fare</th>
            </tr>
          </thead>
          <tbody className="firstFare_billing_table_data">
            {farebreakupdata.map((row, index) => (
              <React.Fragment key={index}>
                <tr>
                  <td
                    className="fourth-td"
                    style={
                      row.lable === "Total Extra Amount" ||
                      row.lable ===
                        "Less cash for fuel received by driver in advance"
                        ? {
                            backgroundColor: "#fff",
                            color: "#38B000",
                            fontWeight: "700",
                          }
                        : {}
                    }
                  >
                    {row.lable}
                  </td>
                  <td
                    style={
                      row.lable === "Total Extra Amount" ||
                      row.lable ===
                        "Less cash for fuel received by driver in advance" ||
                      row.lable === "Total Paid to Online"
                        ? {
                            backgroundColor: "#fff",
                            color: "#38B000",
                            fontWeight: "500",
                            fontWeight: "700",
                          }
                        : {}
                    }
                  >
                    {row.value < 1 ? "Rs. 0" : `Rs. ${row.value}`}
                  </td>
                </tr>
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default FourthFare;
