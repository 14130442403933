import React, { useState, useEffect } from "react";
import "./LocalTripPage.css";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FaPen } from "react-icons/fa";
import DropdownColumn from "../Booking/Dropdown";

import {
  LoadLocalTariffList,
  setTariffInactive,
  setTariffActive,
} from "./Action";
import {
  currentTariffId,
  fetchTariffDetails,
} from "./SingleTariffDetalisSlice";
import Filter from "../Booking/Filter";
import { Toggle } from "./Toggle";
import { CiFilter } from "react-icons/ci";
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const TableItem = ({ item, index }) => {
  const rowClass = index % 2 === 0 ? "table-row-even" : "table-row-odd";

  let dispatch = useDispatch();

  const [togglestate, setToggleState] = useState(item.status);



  const createdAt = item?.createdAt;

  const dateObj = new Date(createdAt);

  // Format Date
  const formattedDate = new Intl.DateTimeFormat("en-GB", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  }).format(dateObj).replace(/\//g, "-");
  
  // Format Time
  const formattedTime = new Intl.DateTimeFormat("en-GB", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  }).format(dateObj);

  const handleEditClick = (id) => {
    console.log("tariff id send", id);
    dispatch(currentTariffId(id));
    dispatch(fetchTariffDetails(id));
  };

  const handleInactiveTariff = (id) => {
    console.log(togglestate);
    console.log("tariff id ", id);
    dispatch(setTariffInactive(id));
  };

  const handleActiveTariff = (id) => {
    console.log(togglestate);
    console.log("tariff id ", id);
    dispatch(setTariffActive(id));
  };

  const value = `/UpdateTariff/${item._id}`;
  index = index + 1;

  const logState = (state) => {
    console.log("Toggled:", state);
    setToggleState(state);
    state === false
      ? handleActiveTariff(item._id)
      : handleInactiveTariff(item._id);
  };

  return (
    <tr className={rowClass} style={{ border: "2px solid black" }}>
  <td style={{ border: "2px solid black", padding: "8px" }}>{index}</td>
  <td style={{ border: "2px solid black", padding: "8px" }}>
    {item?.location?.state}, {item?.location?.city}
  </td>
  <td style={{ border: "2px solid black", padding: "8px" }}>{item?.days}</td>
  <td style={{ border: "2px solid black", padding: "8px" }}>{item?.carCategory?.category}</td>
  <td style={{ border: "2px solid black", padding: "8px" }}>{item?.tripType}</td>
  <td style={{ border: "2px solid black", padding: "8px" }}>{item?.subTripType}</td>
  <td style={{ border: "2px solid black", padding: "8px" }}>{formattedDate}</td>
  <td style={{ border: "2px solid black", padding: "8px" }}>Rs. {item?.fareDetails?.baseFare}</td>
  <td style={{ border: "2px solid black", padding: "8px" }}>{item?.fareDetails?.minKm} Km</td>
  <td style={{ border: "2px solid black", padding: "8px" }}>{item?.fareDetails?.minHrs} Hrs</td>
  <td style={{ border: "2px solid black", padding: "8px" }}>Rs.{item?.fareDetails?.extraKmCharge}</td>
  <td style={{ border: "2px solid black", padding: "8px" }}>Rs. {item?.fareDetails?.extraHrsCharge}</td>
  <td style={{ border: "2px solid black", padding: "8px" }}>Rs. {item?.fareDetails?.nightDriverAllowance}</td>
  <td style={{ border: "2px solid black", padding: "8px" }}>Rs. {item?.fareDetails?.morningDriverAllowance}</td>
  <td style={{ border: "2px solid black", padding: "8px" }}>{item?.distance}</td>
  <td style={{ border: "2px solid black", padding: "8px" }}>{item?.fareDetails?.gstRate}%</td>
  {/* <td style={{ border: "2px solid black", padding: "8px" }}>{item?.status}</td> */}

  <td className="box-action" >
    <Toggle toggled={item?.status === "Active" ? false : true} onClick={logState} />
    <Link to={value}>
      <FaPen className="icon-edit" onClick={() => handleEditClick(item._id)} />
    </Link>
  </td>
</tr>

  );
};

const Table = ({ headers, localtariffData }) => {
  return (
    <div className="table-containers">
      <div className="table-scroll">
      <table className="approvalpending-table"style={{
        // border: "2px solid black",
        // borderCollapse: "collapse",
        // width: "100%",
      }}>
  <thead>
    <tr>
      {headers.map((header, index) => (
        <th key={index} style={{ border: "2px solid black", padding: "8px" }}>{header}</th>
      ))}
    </tr>
  </thead>
  <tbody>
    {localtariffData.map((item, index) => (
      <TableItem
        key={index}
        item={item}
        index={index}
        props={localtariffData}
        style={{ border: "2px solid black", padding: "8px" }}
      />
    ))}
  </tbody>
</table>

      </div>
    </div>
  );
};

const LocalTripPage = () => {
  let dispatch = useDispatch();
  const [selectedLocation, setSelectedLocation] = useState("");
  const [selectedCarCategory, setselectedCarCategory] = useState("");
  const [selectedSubTripType, setSelectedSubTripType] = useState("");
  const [selectedGarageType, setSelectedGarageType] = useState("");
  const [selectedDaysType, setSelectedDaysType] = useState("");
  const [locationOptions, setLocationOptions] = useState([]);
  const [carCategoriesOptions, setCarCategoriesOptions] = useState([]);
  const [subTripOptions, setSubTripOptions] = useState([]);
  const [GarageOption, setGarageOption] = useState([]);
  const [DaysOption, setDaysOption] = useState([]);

  useEffect(() => {
    dispatch(LoadLocalTariffList());
  }, []);

  const localtariffData = useSelector(
    (state) => state.localtariff.localtariffData
  );

  console.log(localtariffData);

  useEffect(() => {
    const extractCategoriesAsOptions = (dataArray) => {
      if (Array.isArray(dataArray.data)) {
        return dataArray.data.map((item) => ({
          label: item.category,
          value: item.category,
        }));
      }
      return [];
    };

    const extractLocationsAsOptions = (dataArray) => {
      if (Array.isArray(dataArray.data)) {
        return dataArray.data.map((item) => ({
          label: item.location,
          value: item.location,
        }));
      }
      return [];
    };

    const extractSubTripAsOptions = (dataArray) => {
      if (Array.isArray(dataArray.data)) {
        return dataArray.data.map((item) => ({
          label: item.subTripTypes,
          value: item.subTripTypes,
        }));
      }
      return [];
    };

    const extractGarageAsOptions = (dataArray) => {
      if (Array.isArray(dataArray.data)) {
        return dataArray.data.map((item) => ({
          label: item.distance,
          value: item.distance,
        }));
      }
      return [];
    };

    const extractdaysAsOptions = (dataArray) => {
      if (Array.isArray(dataArray.data)) {
        return dataArray.data.map((item) => ({
          label: item.day,
          value: item.day,
        }));
      }
      return [];
    };

    const fetchOptions = async () => {
      try {
        const locationResponse = await fetch(
          `${API_BASE_URL}/v1/locations`
        );
        const locationData = await locationResponse.json();
        const locationOptions = extractLocationsAsOptions(locationData);
        // console.log(locationOptions);
        setLocationOptions(locationOptions);
        const carResponse = await fetch(
          `${API_BASE_URL}/v1/car-categories`
        );
        const carData = await carResponse.json();
        const carCategoriesOptions = extractCategoriesAsOptions(carData);
        // console.log(carCategoriesOptions);
        setCarCategoriesOptions(carCategoriesOptions);
        const subTripResponse = await fetch(
          `${API_BASE_URL}/v1/sub-trip-types/Local`
        );
        const subTripData = await subTripResponse.json();
        const subTripOptions = extractSubTripAsOptions(subTripData);
        console.log(subTripOptions);
        setSubTripOptions(subTripOptions);

        const garageResponse = await fetch(
          `${API_BASE_URL}/v1/distances`
        );
        const garageData = await garageResponse.json();
        const garageOptions = extractGarageAsOptions(garageData);
        console.log(garageOptions);
        setGarageOption(garageOptions);

        const daysResponse = await fetch(`${API_BASE_URL}/v1/days`);
        const daysData = await daysResponse.json();
        const daysOptions = extractdaysAsOptions(daysData);
        console.log(daysOptions);
        setDaysOption(daysOptions);
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };
    fetchOptions();
  }, []);

  const headers = [
    "Sr No",
    "Location",
    "Days",
    "Car Type",
    "Trip Type",
    "Sub Trip Type",
    "Created On",
    "Base Fare",
    "Min km Included",
    "Min hrs Included",
    "Extra km",
    "Extra hrs",
    "Night Charges",
    "Morning Charges",
    "Garage Options",
    "GST",
    // "Status",
    "Action",
  ];

  return (
    <div>
      <div className="dropdown-container">
        <div className="main-1">
          <div>Filters</div>
          <div className="icon-1">
            <CiFilter color="#38b000" size={18} />
          </div>
        </div>

        <div style={{ position: "relative", display: "inline-block" }}>
          <select
            style={{
              display: "flex",
              width: "121px",
              height: "29px", // Increased height for better alignment
              padding: "8px 16px",
              alignItems: "center",
              gap: "4px",
              flexShrink: "0",
              borderRadius: "2px 0px 0px 2px",
              border: "1px solid #D9D9D9",
              background: "#FFF",
              appearance: "none", // Removes default dropdown arrow
              WebkitAppearance: "none",
              MozAppearance: "none",
              paddingRight: "30px", // Space for the SVG arrow
            }}
            value={selectedLocation}
            onChange={(e) => setSelectedLocation(e.target.value)}
          >
            <option value="">Location</option>
            {locationOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>

          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="10"
            height="6"
            viewBox="0 0 10 6"
            fill="none"
            style={{
              position: "absolute",
              right: "10px",
              top: "50%",
              transform: "translateY(-50%)",
              pointerEvents: "none", // Prevent interaction with SVG
            }}
          >
            <path
              d="M9.35355 1.35355C9.54882 1.15829 9.54882 0.841709 9.35355 0.646447C9.15829 0.451184 8.84171 0.451184 8.64645 0.646447L9.35355 1.35355ZM5 5L4.64645 5.35355L5 5.70711L5.35355 5.35355L5 5ZM1.35355 0.646447L1 0.292893L0.292892 1L0.646446 1.35355L1.35355 0.646447ZM8.64645 0.646447L4.64645 4.64645L5.35355 5.35355L9.35355 1.35355L8.64645 0.646447ZM5.35355 4.64645L1.35355 0.646447L0.646446 1.35355L4.64645 5.35355L5.35355 4.64645Z"
              fill="#FA7C07"
            />
          </svg>
        </div>

        <div style={{ position: "relative", display: "inline-block" }}>
          <select
            style={{
              display: "flex",
              width: "121px",
              height: "29px", // Increased height for better alignment
              padding: "8px 16px",
              alignItems: "center",
              gap: "4px",
              flexShrink: "0",
              borderRadius: "2px 0px 0px 2px",
              border: "1px solid #D9D9D9",
              background: "#FFF",
              appearance: "none", // Removes default dropdown arrow
              WebkitAppearance: "none",
              MozAppearance: "none",
              paddingRight: "30px", // Space for the SVG arrow
            }}
            value={selectedSubTripType}
            onChange={(e) => setSelectedSubTripType(e.target.value)}
          >
            <option value="">Sub Trip Type</option>
            <option value="2hrs 20km">2hrs 20km</option>
            <option value="4hrs 40km">4hrs 40km</option>
            <option value="6hrs 60km">6hrs 60km</option>
            <option value="8hrs 80km">8hrs 80km</option>
            <option value="10hrs 100km">10hrs 100km	</option>
            <option value="12hrs 120km">12hrs 120km</option>
          </select>

          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="10"
            height="6"
            viewBox="0 0 10 6"
            fill="none"
            style={{
              position: "absolute",
              right: "10px",
              top: "50%",
              transform: "translateY(-50%)",
              pointerEvents: "none", // Prevent interaction with SVG
            }}
          >
            <path
              d="M9.35355 1.35355C9.54882 1.15829 9.54882 0.841709 9.35355 0.646447C9.15829 0.451184 8.84171 0.451184 8.64645 0.646447L9.35355 1.35355ZM5 5L4.64645 5.35355L5 5.70711L5.35355 5.35355L5 5ZM1.35355 0.646447L1 0.292893L0.292892 1L0.646446 1.35355L1.35355 0.646447ZM8.64645 0.646447L4.64645 4.64645L5.35355 5.35355L9.35355 1.35355L8.64645 0.646447ZM5.35355 4.64645L1.35355 0.646447L0.646446 1.35355L4.64645 5.35355L5.35355 4.64645Z"
              fill="#FA7C07"
            />
          </svg>
        </div>

        <div style={{ position: "relative", display: "inline-block" }}>
          <select
            style={{
              display: "flex",
              width: "121px",
              height: "29px", // Increased height for better alignment
              padding: "8px 16px",
              alignItems: "center",
              gap: "4px",
              flexShrink: "0",
              borderRadius: "2px 0px 0px 2px",
              border: "1px solid #D9D9D9",
              background: "#FFF",
              appearance: "none", // Removes default dropdown arrow
              WebkitAppearance: "none",
              MozAppearance: "none",
              paddingRight: "30px", // Space for the SVG arrow
            }}
            value={selectedGarageType}
            onChange={(e) => setSelectedGarageType(e.target.value)}
          >
            <option value="">Garage Options</option>
            {GarageOption.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>

          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="10"
            height="6"
            viewBox="0 0 10 6"
            fill="none"
            style={{
              position: "absolute",
              right: "10px",
              top: "50%",
              transform: "translateY(-50%)",
              pointerEvents: "none", // Prevent interaction with SVG
            }}
          >
            <path
              d="M9.35355 1.35355C9.54882 1.15829 9.54882 0.841709 9.35355 0.646447C9.15829 0.451184 8.84171 0.451184 8.64645 0.646447L9.35355 1.35355ZM5 5L4.64645 5.35355L5 5.70711L5.35355 5.35355L5 5ZM1.35355 0.646447L1 0.292893L0.292892 1L0.646446 1.35355L1.35355 0.646447ZM8.64645 0.646447L4.64645 4.64645L5.35355 5.35355L9.35355 1.35355L8.64645 0.646447ZM5.35355 4.64645L1.35355 0.646447L0.646446 1.35355L4.64645 5.35355L5.35355 4.64645Z"
              fill="#FA7C07"
            />
          </svg>
        </div>

        <div style={{ position: "relative", display: "inline-block" }}>
          <select
            style={{
              display: "flex",
              width: "121px",
              height: "29px", // Increased height for better alignment
              padding: "8px 16px",
              alignItems: "center",
              gap: "4px",
              flexShrink: "0",
              borderRadius: "2px 0px 0px 2px",
              border: "1px solid #D9D9D9",
              background: "#FFF",
              appearance: "none", // Removes default dropdown arrow
              WebkitAppearance: "none",
              MozAppearance: "none",
              paddingRight: "30px", // Space for the SVG arrow
            }}
            value={selectedDaysType}
            onChange={(e) => setSelectedDaysType(e.target.value)}
          >
            <option value="">Days</option>
            {DaysOption.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>

          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="10"
            height="6"
            viewBox="0 0 10 6"
            fill="none"
            style={{
              position: "absolute",
              right: "10px",
              top: "50%",
              transform: "translateY(-50%)",
              pointerEvents: "none", // Prevent interaction with SVG
            }}
          >
            <path
              d="M9.35355 1.35355C9.54882 1.15829 9.54882 0.841709 9.35355 0.646447C9.15829 0.451184 8.84171 0.451184 8.64645 0.646447L9.35355 1.35355ZM5 5L4.64645 5.35355L5 5.70711L5.35355 5.35355L5 5ZM1.35355 0.646447L1 0.292893L0.292892 1L0.646446 1.35355L1.35355 0.646447ZM8.64645 0.646447L4.64645 4.64645L5.35355 5.35355L9.35355 1.35355L8.64645 0.646447ZM5.35355 4.64645L1.35355 0.646447L0.646446 1.35355L4.64645 5.35355L5.35355 4.64645Z"
              fill="#FA7C07"
            />
          </svg>
        </div>

        <div style={{ position: "relative", display: "inline-block" }}>
          <select
            style={{
              display: "flex",
              width: "121px",
              height: "29px", // Increased height for better alignment
              padding: "8px 16px",
              alignItems: "center",
              gap: "4px",
              flexShrink: "0",
              borderRadius: "2px 0px 0px 2px",
              border: "1px solid #D9D9D9",
              background: "#FFF",
              appearance: "none", // Removes default dropdown arrow
              WebkitAppearance: "none",
              MozAppearance: "none",
              paddingRight: "30px", // Space for the SVG arrow
            }}
            value={selectedCarCategory}
            onChange={(e) => setselectedCarCategory(e.target.value)}
          >
            <option value="">Vehicle Type</option>
            {carCategoriesOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>

          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="10"
            height="6"
            viewBox="0 0 10 6"
            fill="none"
            style={{
              position: "absolute",
              right: "10px",
              top: "50%",
              transform: "translateY(-50%)",
              pointerEvents: "none", // Prevent interaction with SVG
            }}
          >
            <path
              d="M9.35355 1.35355C9.54882 1.15829 9.54882 0.841709 9.35355 0.646447C9.15829 0.451184 8.84171 0.451184 8.64645 0.646447L9.35355 1.35355ZM5 5L4.64645 5.35355L5 5.70711L5.35355 5.35355L5 5ZM1.35355 0.646447L1 0.292893L0.292892 1L0.646446 1.35355L1.35355 0.646447ZM8.64645 0.646447L4.64645 4.64645L5.35355 5.35355L9.35355 1.35355L8.64645 0.646447ZM5.35355 4.64645L1.35355 0.646447L0.646446 1.35355L4.64645 5.35355L5.35355 4.64645Z"
              fill="#FA7C07"
            />
          </svg>
        </div>
      </div>
      <Table
        headers={headers}
        localtariffData={localtariffData.filter((item) => {
          const combinedLocation = `${item?.location?.city}, ${item?.location.state}`;
          return (
            (selectedLocation ? combinedLocation === selectedLocation : true) &&
            (selectedSubTripType
              ? item?.subTripType === selectedSubTripType
              : true) &&
            (selectedGarageType
              ? item?.distance === selectedGarageType
              : true) &&
            (selectedDaysType ? item?.days === selectedDaysType : true) &&
            (selectedCarCategory
              ? item?.carCategory?.category === selectedCarCategory
              : true)
          );
        })}
      />
    </div>
  );
};

export default LocalTripPage;
