import React, { useEffect, useState } from "react";
//import { Document, Page } from "react-pdf";
import "./AddNewCar.css";
import axios from "axios";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Link, useNavigate, useParams } from "react-router-dom";


//import Dropdown from 'react-dropdown-select';
import toast, { Toaster } from "react-hot-toast";
const DropdownColumn = ({
  title,
  options,
  selectedValue,
  handleChange,
  selectedFile,
  removeFile,
}) => {
  return (
    <div className="View-add-new-car-dropdown-column">
      <h3>{title}</h3>
      <select value={selectedValue} onChange={handleChange}>
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
};

const AddNewCar = () => {
  const navigate = useNavigate();
  const [cityoption, setCityOption] = useState([]);
  const [driveroption, setDriverOption] = useState([]);
  const [randomCategory, setRandomCategory] = useState([]);
  const [selectcarmanufacturerArray, setSelectcarmanufacturerArray] = useState(
    []
  );
  const [selectCarModelArray, setSelectCarModelArray] = useState([]);

  const [selectcity, setSelectCity] = useState("");
  const [selectcarmanufacturer, setSelectCarManufacturer] = useState("");
  const [selectCategory, setSelectCategory] = useState("");
  const [selectcarmodel, setSelectCarModel] = useState("");
  const [selectedRadioButton, setselectedRadioButton] = useState("");
  const [selectcarCategory, setSelectCarCategory] = useState("");
  const [selectfuelcategory, setSelectFuelCategory] = useState("");
  const [carFrontPhoto, setcarFrontPhoto] = useState(null);
  const [permitDetails, setpermitDetails] = useState(null);
  const [carPhotoSide, setcarPhotoSide] = useState(null);
  const [PUCTest, setPUCTest] = useState(null);
  const [registrationCertificate, setregistrationCertificate] = useState(null);
  const [insurancePolicy, setinsurancePolicy] = useState(null);
  const [registrationCertificateBack, setregistrationCertificateBack] =
    useState(null);
  const [showCloseIconCar, setShowCloseIconCar] = useState(false);
  const [showCloseIconPermit, setShowCloseIconPermit] = useState(false);
  const [showCloseIconFront, setShowCloseIconFront] = useState(false);
  const [showCloseIconPUC, setShowCloseIconPUC] = useState(false);
  const [showCloseIconCertificate, setShowCloseIconCertificate] =
    useState(false);
  const [showCloseIconCertificateBack, setShowCloseIconCertificateBack] =
    useState(false);
  const [showCloseIconPolicy, setShowCloseIconPolicy] = useState(false);
  const [carColor, setCarColor] = useState("");
  const [rcNumber, setrcNumber] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const [insuranceExpiryDate, setInsuranceExpiryDate] = useState("");
  const [pucExpiryDate, setPucExpiryDate] = useState("");
  const [carRegYear, setCarRegYear] = useState("");


  const [vehiclePart1, setVehiclePart1] = useState("");
  const [vehiclePart2, setVehiclePart2] = useState("");
  const [vehiclePart3, setVehiclePart3] = useState("");
  const [vehiclePart4, setVehiclePart4] = useState("");

  // // Get today's date and set the time to the start of the day
  // const today = new Date();
  // today.setHours(0, 0, 0, 0);

  const handleRadioChange = (e) => {
    const selectedValue = e.target.value;
    setselectedRadioButton(selectedValue);

    if (selectedValue === "Company Owned") {
      setSelectCategory("");
    }
  };

  const handleFileChange = (e, setFile, setShowCloseIcon) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
      setShowCloseIcon(true);
    }
  };

  const handleRemoveFile = (setFile, setShowCloseIcon) => {
    setFile(null);
    setShowCloseIcon(false);
  };

  const FuelCategoryOption = [
    { label: "Select Fuel Category", value: "city0" },
    { label: "Petrol", value: "Petrol" },
    { label: "Diesel", value: "Diesel" },
    { label: "CNG", value: "CNG" },
    { label: "Electric", value: "Electric" },
  ];
  const handleSubmit = async () => {
    console.log(selectCategory);
    console.log(
      selectcity,
      selectcarCategory,
      selectcarmanufacturer,
      selectcarmodel,
      selectfuelcategory,
      selectcarmodel,
      carFrontPhoto,
      permitDetails,
      carPhotoSide,
      PUCTest,
      registrationCertificate,
      registrationCertificateBack
    );

    const formData = new FormData();

    formData.append("location", selectcity);
    formData.append("driver", selectCategory);
    formData.append("carCategory", selectcarCategory);
    formData.append("carMake", selectcarmanufacturer);
    formData.append("carModel", selectcarmodel);
    formData.append("fuelType", selectfuelcategory);
    formData.append("carColor", carColor);
    formData.append("carRegistrationYear", carRegYear);

    // Combine vehicle number parts
    const rcNumber = `${vehiclePart1}${vehiclePart2}${vehiclePart3}${vehiclePart4}`;

    formData.append("rcNumber", rcNumber);

    formData.append("permitExpiryDate", expiryDate);
    formData.append("insuranceExpiryDate", insuranceExpiryDate);
    formData.append("pucExpiryDate", pucExpiryDate);
    formData.append(
      "rcFrontPhoto",
      registrationCertificate,
      registrationCertificate.name
    );
    formData.append(
      "rcBackPhoto",
      registrationCertificateBack,
      registrationCertificateBack.name
    );
    formData.append("carFrontPhoto", carFrontPhoto, carFrontPhoto.name);
    formData.append("carSidePhoto", carPhotoSide, carPhotoSide.name);
    formData.append("permitPhoto", permitDetails, permitDetails.name);
    formData.append("pucPhoto", PUCTest, PUCTest.name);
    formData.append("insurancePhoto", insurancePolicy, insurancePolicy.name);

    for (const pair of formData.entries()) {
      console.log(pair[0] + ", " + pair[1]);
    }
    try {
      console.log("adding car");
      const req = await axios.post(
        "https://api.yatricabs.com/v1/admin/cars",
        formData
      );
      console.log("car added");
      toast.success("car added");

      setTimeout(() => {
        navigate('/carmanagement');
      }, 3000);
    } catch (error) {
      console.log(error?.response?.data?.message);
      toast.error(error?.response?.data?.message);
    }
  };
  const extractLocationsAsOptions = (dataArray) => {
    if (Array.isArray(dataArray.data)) {
      return dataArray.data.map((item) => ({
        label: item?.location,
        value: item?._id,
      }));
    }
    return [];
  };
  const extractDriversAsOptions = (dataArray) => {
    if (Array.isArray(dataArray.data)) {
      return dataArray.data.map((item) => ({
        label: item?.firstName,
        value: item?._id,
      }));
    }
    return [];
  };

  const extractCategoriesAsOptions = (dataArray) => {
    if (Array.isArray(dataArray.data)) {
      return dataArray.data.map((item) => ({
        label: item?.category,
        value: item?._id,
      }));
    }
    return [];
  };
  const extractCarManufacturesAsOptions = (dataArray) => {
    if (Array.isArray(dataArray.data)) {
      return dataArray.data.map((item) => ({
        label: item?.make,
        value: item?.make,
      }));
    }
    return [];
  };
  const extractCarModelsAsOptions = (dataArray) => {
    if (Array.isArray(dataArray.models)) {
      return dataArray.models.map((item) => ({
        label: item,
        value: item,
      }));
    }
    return [];
  };

  useEffect(() => {
    const locations = async () => {
      try {
        const locationResponse = await fetch(
          "https://api.yatricabs.com/v1/locations"
        );
        const locationData = await locationResponse.json();

        const locationOptions = extractLocationsAsOptions(locationData);
        locationOptions.unshift({ label: "Select the location" });
        setCityOption(locationOptions);
        console.log(locationOptions);
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };
    locations();
    const driver = async () => {
      try {
        const driverData = await axios.get(
          "https://api.yatricabs.com/v1/admin/drivers"
        );
        // const driverData = await drivers.json();
        console.log(driverData?.data);
        const din = extractDriversAsOptions(driverData?.data);
        din.unshift({ label: "Select the driver" });
        console.log(din);
        setDriverOption(din);
        console.log(driveroption);
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };
    driver();
    const carCategories = async () => {
      try {
        const categories = await fetch(
          "https://api.yatricabs.com/v1/car-categories"
        );
        const categoriesData = await categories.json();
        const categoriesOptions = extractCategoriesAsOptions(categoriesData);
        categoriesOptions.unshift({ label: "Select the car category" });
        console.log(categoriesOptions);
        setRandomCategory(categoriesOptions);
        console.log(randomCategory);
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };

    carCategories();

    const carmanufacturing = async () => {
      try {
        const categories = await fetch("https://api.yatricabs.com/v1/car-makes");
        const categoriesData = await categories.json();
        console.log(categoriesData);
        const categoriesOptions =
          extractCarManufacturesAsOptions(categoriesData);
        categoriesOptions.unshift({ label: "Select the car category" });
        console.log(categoriesOptions);
        setSelectcarmanufacturerArray(categoriesOptions);
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };
    carmanufacturing();

    const carmodels = async () => {
      try {
        if (selectcarmanufacturer) {
          const categories = await fetch(
            `https://api.yatricabs.com/v1//car-models/${selectcarmanufacturer}`
          );
          const categoriesData = await categories.json();
          console.log(categoriesData.data[0]);
          const categoriesOptions = extractCarModelsAsOptions(
            categoriesData.data[0]
          );
          categoriesOptions.unshift({ label: "Select the car model" });
          console.log(categoriesOptions);
          setSelectCarModelArray(categoriesOptions);
        }
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };
    carmodels();
  }, [selectcarmanufacturer]);

  const handleDateChange = (e, setDate) => {
    const selectedDate = e.target.value;
    setDate(selectedDate);
  };

  return (
    <>
      <Toaster />
      <div className="add-new-car-vehicle-scrolbar" style={{ height: "95vh" }}>
        <div className="add-new-car-vehicle">Vehicle Information</div>
        <div className="user-details">
          <div className="fleet-user-details-section">
            {/* Location */}
            <div className="fleet-user-detail">
              <div className="user-heading">Location</div>
              <DropdownColumn
                options={cityoption}
                selectedValue={selectcity}
                handleChange={(e) => setSelectCity(e.target.value)}
              />
            </div>
            {/* Sourcing */}
            <div className="fleet-user-detail">
              <div className="user-heading">Sourcing</div>

              <div style={{ display: "flex" }}>
                <input
                  type="radio"
                  id="Company Owned"
                  name="radioCategory"
                  value="Company Owned"
                  checked={selectedRadioButton === "Company Owned"}
                  onChange={handleRadioChange}
                />
                <label htmlFor="Company Owned">Company Owned</label>

                <input
                  type="radio"
                  id="Outsourced"
                  name="radioCategory"
                  value="Outsourced"
                  checked={selectedRadioButton === "Outsourced"}
                  onChange={handleRadioChange}
                />
                <label htmlFor="Outsourced">Outsourced</label>
              </div>
            </div>
            {/* DropDown for Driver if Outsourced */}
            {selectedRadioButton === "Outsourced" && (
              <div className="fleet-user-detail">
                <div className="user-heading">Vehicle Owner / Driver</div>

                <DropdownColumn
                  options={driveroption}
                  selectedValue={selectCategory}
                  handleChange={(e) => setSelectCategory(e.target.value)}
                />
              </div>
            )}
            {/* Car Category */}
            <div className="fleet-user-detail">
              <div className="user-heading">Car Category</div>
              <DropdownColumn
                options={randomCategory}
                selectedValue={selectcarCategory}
                handleChange={(e) => setSelectCarCategory(e.target.value)}
              />
            </div>
            {/* Car Make */}
            <div className="fleet-user-detail">
              <div className="user-heading">Car Make</div>
              <DropdownColumn
                options={selectcarmanufacturerArray}
                selectedValue={selectcarmanufacturer}
                handleChange={(e) => setSelectCarManufacturer(e.target.value)}
              />
            </div>
            {/* Model */}
            <div className="fleet-user-detail">
              <div className="user-heading">Model</div>
              <DropdownColumn
                options={selectCarModelArray}
                selectedValue={selectcarmodel}
                handleChange={(e) => setSelectCarModel(e.target.value)}
              />
            </div>
            {/* Fuel Type */}
            <div className="fleet-user-detail">
              <div className="user-heading">Fuel Type</div>
              <DropdownColumn
                options={FuelCategoryOption}
                selectedValue={selectfuelcategory}
                handleChange={(e) => setSelectFuelCategory(e.target.value)}
              />
            </div>
            {/* Body Colour */}
            <div className="fleet-user-detail">
              <div className="user-heading">Body Colour</div>
              <input
                type="text"
                className="custom-input"
                placeholder="Type Color"
                onChange={(e) => {
                  setCarColor(e.target.value);
                }}
              />
            </div>
            {/* Year */}
            <div className="fleet-user-detail">
              <div className="user-heading">Year</div>
              <input
                type="text"
                className="custom-input"
                placeholder="Y-Y-Y-Y"
                onChange={(e) => setCarRegYear(e.target.value)}
              />
            </div>
            {/* Vehicle Number */}
            <div className="fleet-user-detail vn-11">
              <div className="user-heading">Vehicle Number</div>
              <input
                type="text"
                placeholder="KA"
                className="custom-input-3"
                onChange={(e) => setVehiclePart1(e.target.value)}
              />
              <input
                type="text"
                className="custom-input-3"
                placeholder="01"
                onChange={(e) => setVehiclePart2(e.target.value)}
              />
              <input
                type="text"
                className="custom-input-3"
                placeholder="AB"
                onChange={(e) => setVehiclePart3(e.target.value)}
              />
              <input
                type="text"
                className="custom-input-3"
                placeholder="0000"
                onChange={(e) => setVehiclePart4(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="add-new-car-vehicle">Upload Documents</div>
        <div className="user-details">
          <div className="user-details-section">
            <div className="user-detail">
              <div className="user-headin">Car Photo Front</div>
              <div className="dropdown">
                <div className="pic">
                  {carFrontPhoto && (
                    <>
                      <img
                        className="pic-selected"
                        src={URL.createObjectURL(carFrontPhoto)}
                        alt="Selected"
                      />
                    </>
                  )}
                </div>
                <div className="dropdown-file">
                  <div className="file-container">
                    <label className="upload">
                      <input
                        type="file"
                        onChange={(e) =>
                          handleFileChange(
                            e,
                            setcarFrontPhoto,
                            setShowCloseIconCar
                          )
                        }
                      />
                      <span>Choose File</span>
                    </label>

                    {showCloseIconCar && (
                      <span
                        className="close-sign"
                        onClick={() =>
                          handleRemoveFile(
                            setcarFrontPhoto,
                            setShowCloseIconCar
                          )
                        }
                      >
                        &times;
                      </span>
                    )}
                  </div>
                  
                   {carFrontPhoto ? (
                    <p className="selected">Selected File: {carFrontPhoto.name}</p>
                  ) : (
                    <p className="not-selected">No file selected</p>
                  )}
                </div>
              </div>
            </div>
            <div className="user-detail">
              <div className="user-headin">Permit Details</div>
              <div className="dropdown">
                <div className="dropdown-user-detail">
                  <div className="pic">
                    {permitDetails && (
                      <>
                        <img
                          className="pic-selected"
                          src={URL.createObjectURL(permitDetails)}
                          alt="Selected"
                        />
                      </>
                    )}
                  </div>
                  <div className="dropdown-pic">
                    {/* <input
                      type="date"
                      id="myDate"
                      value={expiryDate}
                      onChange={(e) => setExpiryDate(e.target.value)}
                    /> */}
                    <input
                      type="date"
                      id="myDate"
                      min={new Date().toISOString().split("T")[0]}
                      value={expiryDate}
                      onChange={(e) => handleDateChange(e, setExpiryDate)}
                    />

                    {/* <DatePicker
                      
                      selected={expiryDate}
                      onChange={(e) => setExpiryDate(e.target.value)}
                      placeholderText="dd/mm/yy"
                      dateFormat="dd-mm-yyyy"
                      autoComplete="off"
                      minDate={new Date()}
                      closeOnScroll={true}
                      required
                      value={expiryDate}
                    /> */}
                    <br></br>expiry date
                  </div>
                </div>
                <div className="dropdown-file">
                  <div className="file-container">
                    <label className="upload">
                      <input
                        type="file"
                        onChange={(e) =>
                          handleFileChange(
                            e,
                            setpermitDetails,
                            setShowCloseIconPermit
                          )
                        }
                      />
                      <span>Choose File</span>
                    </label>

                    {showCloseIconPermit && (
                      <span
                        className="close-sign"
                        onClick={() =>
                          handleRemoveFile(
                            setpermitDetails,
                            setShowCloseIconPermit
                          )
                        }
                      >
                        &times;
                      </span>
                    )}
                  </div>
                
                  {permitDetails ? (
                    <p className="selected">Selected File: {permitDetails.name}</p>
                  ) : (
                    <p className="not-selected">No file selected</p>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="user-details-section">
            <div className="user-detail">
              <div className="user-headin">Car Photo Side</div>
              <div className="dropdown">
                <div className="pic">
                  {carPhotoSide && (
                    <>
                      <img
                        className="pic-selected"
                        src={URL.createObjectURL(carPhotoSide)}
                        alt="Selected"
                      />
                      {/* {showCloseIcon && (
                      <span
                        className="close-sign"
                        onClick={() =>
                          handleRemoveFile(setcarPhotoSide, setShowCloseIcon)
                        }
                      >
                        &times;
                      </span>
                    )} */}
                    </>
                  )}
                </div>
                <div className="dropdown-file">
                  <div className="file-container">
                    <label className="upload">
                      <input
                        type="file"
                        onChange={(e) =>
                          handleFileChange(
                            e,
                            setcarPhotoSide,
                            setShowCloseIconFront
                          )
                        }
                      />
                      <span>Choose File</span>
                    </label>

                    {showCloseIconFront && (
                      <span
                        className="close-sign"
                        onClick={() =>
                          handleRemoveFile(
                            setcarPhotoSide,
                            setShowCloseIconFront
                          )
                        }
                      >
                        &times;
                      </span>
                    )}
                  </div>
                  {carPhotoSide ? (
                    <p className="selected">Selected File: {carPhotoSide.name}</p>
                  ) : (
                    <p className="not-selected">No file selected</p>
                  )}
                </div>
              </div>
            </div>
            <div className="user-detail">
              <div className="user-headin">PUC Test</div>
              <div className="dropdown">
                <div className="dropdown-user-detail">
                  <div className="pic">
                    {PUCTest && (
                      <>
                        <img
                          className="pic-selected"
                          src={URL.createObjectURL(PUCTest)}
                          alt="Selected"
                        />
                        {/* {showCloseIcon && (
                        <span
                          className="close-sign"
                          onClick={() =>
                            handleRemoveFile(setPUCTest, setShowCloseIcon)
                          }
                        >
                          &times;
                        </span>
                      )} */}
                      </>
                    )}
                  </div>
                  <div className="dropdown-pic">
                    {/* <input
                      type="date"
                      id="myDate"
                      value={pucExpiryDate}
                      onChange={(e) => setPucExpiryDate(e.target.value)}
                    /> */}
                    <input
                      type="date"
                      id="myDate"
                      min={new Date().toISOString().split("T")[0]}
                      value={pucExpiryDate}
                      onChange={(e) => handleDateChange(e, setPucExpiryDate)}
                    />
                    <br></br>expiry date
                  </div>
                </div>
                <div className="dropdown-file">
                  <div className="file-container">
                    <label className="upload">
                      <input
                        type="file"
                        onChange={(e) =>
                          handleFileChange(e, setPUCTest, setShowCloseIconPUC)
                        }
                      />
                      <span>Choose File</span>
                    </label>
                    {showCloseIconPUC && (
                      <span
                        className="close-sign"
                        onClick={() =>
                          handleRemoveFile(setPUCTest, setShowCloseIconPUC)
                        }
                      >
                        &times;
                      </span>
                    )}
                  </div>

                  {PUCTest ? (
                    <p className="selected">Selected File: {PUCTest.name}</p>
                  ) : (
                    <p className="not-selected">No file selected</p>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="user-details-section">
            <div className="user-detail">
              <div className="user-headin">Registration Certificate Front</div>
              <div className="dropdown">
                <div className="pic">
                  {registrationCertificate && (
                    <>
                      <img
                        className="pic-selected"
                        src={URL.createObjectURL(registrationCertificate)}
                        alt="Selected"
                      />
                      {/* {showCloseIcon && (
                      <span
                        className="close-sign"
                        onClick={() =>
                          handleRemoveFile(
                            setregistrationCertificate,
                            setShowCloseIcon
                          )
                        }
                      >
                        &times;
                      </span>
                    )} */}
                    </>
                  )}
                </div>
                <div className="dropdown-file">
                  <div className="file-container">
                    <label className="upload">
                      <input
                        type="file"
                        onChange={(e) =>
                          handleFileChange(
                            e,
                            setregistrationCertificate,
                            setShowCloseIconCertificate
                          )
                        }
                      />
                      <span>Choose File</span>
                    </label>
                    {showCloseIconCertificate && (
                      <span
                        className="close-sign"
                        onClick={() =>
                          handleRemoveFile(
                            setregistrationCertificate,
                            setShowCloseIconCertificate
                          )
                        }
                      >
                        &times;
                      </span>
                    )}
                  </div>
                 
                  {registrationCertificate ? (
                    <p className="selected">Selected File: {registrationCertificate.name}</p>
                  ) : (
                    <p className="not-selected">No file selected</p>
                  )}
                </div>
              </div>
            </div>
            <div className="user-detail">
              <div className="user-headin">Insurance Policy</div>
              <div className="dropdown">
                <div className="dropdown-user-detail">
                  <div className="pic">
                    {" "}
                    {insurancePolicy && (
                      <>
                        <img
                          className="pic-selected"
                          src={URL.createObjectURL(insurancePolicy)}
                          alt="Selected"
                        />
                      </>
                    )}
                  </div>
                  <div className="dropdown-pic">
                    {/* <input
                      type="date"
                      id="myDate"
                      value={insuranceExpiryDate}
                      onChange={(e) => setInsuranceExpiryDate(e.target.value)}
                    /> */}
                    <input
                      type="date"
                      id="myDate"
                      min={new Date().toISOString().split("T")[0]}
                      value={insuranceExpiryDate}
                      onChange={(e) => handleDateChange(e, setInsuranceExpiryDate)}
                    />
                    <br></br>expiry date
                  </div>
                </div>
                <div className="dropdown-file">
                  <div className="file-container">
                    <label className="upload">
                      <input
                        type="file"
                        onChange={(e) =>
                          handleFileChange(
                            e,
                            setinsurancePolicy,
                            setShowCloseIconPolicy
                          )
                        }
                      />
                      <span>Choose File</span>
                    </label>
                    {showCloseIconPolicy && (
                      <span
                        className="close-sign"
                        onClick={() =>
                          handleRemoveFile(
                            setinsurancePolicy,
                            setShowCloseIconPolicy
                          )
                        }
                      >
                        &times;
                      </span>
                    )}
                  </div>
                  
                  {insurancePolicy ? (
                    <p className="selected">Selected File: {insurancePolicy.name}</p>
                  ) : (
                    <p className="not-selected">No file selected</p>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="user-details-section">
            <div className="user-detail">
              <div className="user-headin">Registration Certificate Back</div>
              <div className="dropdown">
                <div className="pic">
                  {" "}
                  {registrationCertificateBack && (
                    <>
                      <img
                        className="pic-selected"
                        src={URL.createObjectURL(registrationCertificateBack)}
                        alt="Selected"
                      />
                      {/* {showCloseIcon && (
                      <span
                        className="close-sign"
                        onClick={() =>
                          handleRemoveFile(
                            setregistrationCertificateBack,
                            setShowCloseIcon
                          )
                        }
                      >
                        &times;
                      </span>
                    )} */}
                    </>
                  )}
                </div>
                <div className="dropdown-file">
                  <div className="file-container">
                    <label className="upload">
                      <input
                        type="file"
                        onChange={(e) =>
                          handleFileChange(
                            e,
                            setregistrationCertificateBack,
                            setShowCloseIconCertificateBack
                          )
                        }
                      />
                      <span>Choose File</span>
                    </label>
                    {showCloseIconCertificateBack && (
                      <span
                        className="close-sign"
                        onClick={() =>
                          handleRemoveFile(
                            setregistrationCertificateBack,
                            setShowCloseIconCertificateBack
                          )
                        }
                      >
                        &times;
                      </span>
                    )}
                  </div>
                  
                   {registrationCertificateBack ? (
                    <p className="selected">Selected File: {registrationCertificateBack.name}</p>
                  ) : (
                    <p className="not-selected">No file selected</p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div style={{ display: "flex", justifyContent: "center" }}>
          <button
            style={{
              borderRadius: "5px",
              margin: "10px",
              border: "0.5px solid #38B000",
              background: "#38B000",
              width: "150px",
              padding: "6px",
              color: "#FFF",
              fontSize: "15px",
              cursor: "pointer"
            }}
            onClick={handleSubmit}
          >
            Submit
          </button>
        </div>
      </div>
    </>
  );
};

export default AddNewCar;
