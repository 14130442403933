import React, { useState, useEffect, useRef } from "react";
import "./AddNewCar.css";
import axios from "axios";
import DropdownColumn from "../Booking/Dropdown";
import { useDispatch } from "react-redux";
import { setBooleanValue } from "./DriverSlice";
import toast, { Toaster } from "react-hot-toast";
import CountryDropdown from "../countrydropdown/CountryDropdown";
import { Link, useNavigate, useParams } from "react-router-dom";

const tableHeaderStyle = {
  backgroundColor: "#38B000",
  color: "white",
  padding: "10px",
  fontFamily: "Inter",
  fontSize: "15px",
  fontWeight: 600,
};

const AddNewCar = () => {
  const navigate  =  useNavigate();
  const [selectedLocation, setSelectedLocation] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("+91");
  const [showOtherInput, setShowOtherInput] = useState(false);
  const [otherLanguage, setOtherLanguage] = useState("");
  const [locationOptions, setLocationOptions] = useState([]);
  const [profilePhoto, setProfilePhoto] = useState(null);
  const [panCard, setPanCard] = useState(null);
  const [aadharCardFront, setAadharCardFront] = useState(null);
  const [aadharCardBack, setAadharCardBack] = useState(null);
  const [drivingLicenseFront, setDrivingLicenseFront] = useState(null);
  const [drivingLicenseBack, setDrivingLicenseBack] = useState(null);
  const [policeClearanceCertificate, setPoliceClearanceCertificate] =
    useState(null);
  const [inputValue, setInputValue] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    alternatePhoneNumber: "",
    password: "",
    confirmPassword: "",
    dob: "",
    source: "",
    panNo: "",
    adharNo: "",
    permanentAddress: "",
    currentAddress: "",
    location: "",
    language: "",
    dlNo: "",
    expiryDate: "",
    beneficiaryName: "",
    accountNo: "",
    ifscCode: "",
    ratePer20km: "",
    ratePer40km: "",
    ratePer60km: "",
    ratePer80km: "",
    ratePer100km: "",
    ratePer120km: "",
    extraKm: "",
    extraHours: "",
    ratePerKm: "",
    minKmPerDay: "",
    nightCharge: "",
    ratePerTrip: "",
    profilePhoto: "",
    panCard: "",
    aadharCardFront: "",
    aadharCardBack: "",
    drivingLicenseFront: "",
    drivingLicenseBack: "",
    policeClearanceCertificate: "",
    otp: ["", "", "", "", "", ""],
    isPhoneNumberVerified: false,
    isOTPVerified: false,
    otpId: "",
    phoneNumberToken: "",
  });

  const dispatch = useDispatch();

  const handleEarningReport = (e) => {
    e.preventDefault();
    dispatch(setBooleanValue(false));
  };

  const handleChange = (e) => {
    if(e.target.name==='dlNo'){
      if(e.target.value.length<=16){
        e.target.value = e.target.value.toUpperCase();
        if (e.target.value.length === 2 && inputValue.dlNo.length === 3) {
          e.target.value = e.target.value.slice(0, -1); // Remove the hyphen
        }
  
        // Add hyphen after the second character
        if (e.target.value.length === 2 && !e.target.value.includes('-')) {
          e.target.value += '-';
        }
        console.log(e.target.value)
        setInputValue({
          ...inputValue,
          [e.target.name]: e.target.value,
        });
      }
    }else if(e.target.name==='panNo'){
      e.target.value = e.target.value.toUpperCase();
      const regex = /^[A-Z]{0,5}$|^[A-Z]{5}[0-9]{0,4}$|^[A-Z]{5}[0-9]{4}[A-Z]{0,1}$/;
      if(regex.test(e.target.value)){
        setInputValue({
          ...inputValue,
          [e.target.name]: e.target.value,
        });
      }
    }
    else{
      setInputValue({
        ...inputValue,
        [e.target.name]: e.target.value,
      });
    }
  };

  const SourcehandleCheckbox = (e) => {
    const { name, value, checked } = e.target;
    console.log(value);
    setInputValue({
      ...inputValue,
      [name]: checked ? value : "",
    });
  };

  const handleCheckboxChange = (e) => {
    const { name, value, checked } = e.target;
    setInputValue((prevInputValue) => {
      if (checked) {
        // Add the language to the array if checked
        const updatedLanguages = [...prevInputValue[name], value];
        console.log(updatedLanguages); // Log the updated array
        return { ...prevInputValue, [name]: updatedLanguages };
      } else {
        // Remove the language from the array if unchecked
        const updatedLanguages = prevInputValue[name].filter(
          (lang) => lang !== value
        );
        console.log(updatedLanguages); // Log the updated array
        return { ...prevInputValue, [name]: updatedLanguages };
      }
    });
    if (value === "Other") {
      setShowOtherInput(checked);
      if (!checked) {
        setOtherLanguage("");
      }
    }
  };

  const otpInputs = useRef([]);

  const handleOTPChange = (index, value) => {
    const updatedOTP = [...inputValue.otp];
    updatedOTP[index] = value;
    setInputValue({
      ...inputValue,
      otp: updatedOTP,
    });

    if (value !== "" && index < otpInputs.current.length - 1) {
      otpInputs.current[index + 1].focus();
    }
  };

  const handleFileChange = (event, setFile) => {
    const file = event.target.files[0];

    if (file) {
      setFile(file);
    }
  };

  if (selectedCountry && inputValue.phoneNumber) {
    var completePhoneNumber = `${selectedCountry}-${inputValue.phoneNumber}`;
  }

  if (selectedCountry && inputValue.alternatePhoneNumber) {
    var alternateNumber = `${selectedCountry}-${inputValue.alternatePhoneNumber}`;
  }

  if (inputValue.language) {
    var formattedlanguage = inputValue.language.join(",");
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const headers = {
      // 'Content-Type': 'application/json',
      "Content-Type": "multipart/form-data",
    };

    const data = {
      firstName: inputValue.firstName,
      lastName: inputValue.lastName,
      phone: completePhoneNumber,
      alternatePhone: alternateNumber,
      password: inputValue.password,
      otpToken: inputValue.phoneNumberToken,
      dateOfBirth: inputValue.dob,
      languages: formattedlanguage,
      sourcing: inputValue.source,
      location: selectedLocation,
      dlNumber: inputValue.dlNo,
      dlExpiryDate: inputValue.expiryDate,
      panNumber: inputValue.panNo,
      permanentAddress: inputValue.permanentAddress,
      currentAddress: inputValue.currentAddress,
      aadhaarNumber: inputValue.adharNo,
      bankAccountNumber: inputValue.accountNo,
      bankAccountBeneficiaryName: inputValue.beneficiaryName,
      bankIFSCCode: inputValue.ifscCode,
      outstationPerKmFare: inputValue.ratePerKm,
      outstationMinKmPerDay: inputValue.minKmPerDay,
      local2hr20kmFare: inputValue.ratePer20km,
      local4hr40kmFare: inputValue.ratePer40km,
      local6hr60kmFare: inputValue.ratePer60km,
      local8hr80kmFare: inputValue.ratePer80km,
      local10hr100kmFare: inputValue.ratePer100km,
      local12hr120kmFare: inputValue.ratePer120km,
      localPerKmFareExtra: inputValue.extraKm,
      localPerHourFareExtra: inputValue.extraHours,
      airportBaseFare: inputValue.ratePerTrip,
      nightCharge: inputValue.nightCharge,
      profilePhoto: profilePhoto,
      aadhaarFrontPhoto: aadharCardFront,
      aadhaarBackPhoto: aadharCardBack,
      panCardPhoto: panCard,
      drivingLicenseFrontPhoto: drivingLicenseFront,
      drivingLicenseBackPhoto: drivingLicenseBack,
      policeClearanceCertificatePhoto: policeClearanceCertificate,
    };
    try {
      // Add a new car category
      await axios.post("https://api.yatricabs.com/v1/admin/drivers", data, {
        headers,
      });
      toast.success("New Driver added!");

      setTimeout(() => {
        navigate('/drivermanagement');
      }, 3000);

      e.target.reset();
      setSelectedLocation("");
      setProfilePhoto(null);
      setPanCard(null);
      setAadharCardFront(null);
      setAadharCardBack(null);
      setDrivingLicenseFront(null);
      setDrivingLicenseBack(null);
      setPoliceClearanceCertificate(null);
    } catch (error) {
      toast.error(error.response.data.message);
      console.error("error ", error.response.data.message);
    }

    console.log("Form Values:", data);
  };

  const handleVerifyPhoneNumber = () => {
    console.log("Phone Number:", completePhoneNumber);
    fetch("https://api.yatricabs.com/v1/send-otp", {
      method: "POST",
      body: JSON.stringify({ phone: completePhoneNumber }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.ok) {
          console.log("OTP sent successfully");
          console.log(response);
          return response.json();
        } else {
          throw new Error("Failed to send OTP");
        }
      })
      .then((data) => {
        if (data && data.data && data.data.otpId) {
          const otpId = data.data.otpId;
          console.log("OTP ID:", otpId);
          setInputValue({
            ...inputValue,
            otpId: otpId,
            isPhoneNumberVerified: true,
          });
        } else {
          throw new Error("Invalid response format from OTP sending API");
        }
      })
      .catch((error) => {
        console.error("Error sending OTP:", error.message);
      });
  };

  const handleVerifyOTP = () => {
    const otpInteger = parseInt(inputValue.otp.join(""), 10);

    fetch("https://api.yatricabs.com/v1/verify-otp", {
      method: "POST",
      body: JSON.stringify({
        phone: completePhoneNumber,
        otp: otpInteger,
        otpId: inputValue.otpId,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.ok) {
          console.log("OTP verified successfully");
          console.log(response);
          return response.json();
        } else {
          throw new Error("Failed to verify OTP");
        }
      })
      .then((data) => {
        const phoneNumberToken = data.otpToken;
        setInputValue({
          ...inputValue,
          isOTPVerified: true,
          phoneNumberToken: phoneNumberToken,
        });
        console.log("Message:", data.message);
        console.log("Token:", data.otpToken);
      })
      .catch((error) => {
        console.error("Error verifying OTP:", error.message);
      });
    console.log("OTP:", otpInteger);
    console.log("OTP ID:", inputValue.otpId);
    console.log("Phone Number:", completePhoneNumber);
  };

  useEffect(() => {
    const extractLocationsAsOptions = (dataArray) => {
      if (Array.isArray(dataArray.data)) {
        return dataArray.data.map((item) => ({
          label: item.location,
          value: item._id,
        }));
      }
      return [];
    };

    const fetchLocation = async () => {
      try {
        const locationResponse = await fetch(
          "https://api.yatricabs.com/v1/locations"
        );
        const locationData = await locationResponse.json();
        const locationOptions = extractLocationsAsOptions(locationData);
        // console.log(locationOptions);
        setLocationOptions(locationOptions);
      } catch (error) {
        console.error("Error fetching location options:", error);
      }
    };
    fetchLocation();
  }, []);

  const handlePhoneNumberChange = () => {
    setInputValue({
      ...inputValue,
      isPhoneNumberVerified: false,
      isOTPVerified: false,
      phoneNumber: "",
      otp: ["", "", "", "", "", ""],
    });
  };

  const handleOtherLanguageChange = (e) => {
    setOtherLanguage(e.target.value);
  };

  const maxDate = new Date();
  maxDate.setFullYear(maxDate.getFullYear() - 19);
  const maxDateString = maxDate.toISOString().split("T")[0];

  return (
    <>
      <Toaster />
      <form onSubmit={handleSubmit}>
        <div className="add-new-car-scrolbar">
          <div
            style={{
              backgroundColor: "#38B000",
              borderRadius: "2px",
              boxShadow: "0px 4px 22px 0px rgba(0, 0, 0, 0.17)",
              color: "#FFF",
              padding: "10px",
              fontFamily: "Lato",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: "700",
              lineHeight: "normal",
            }}
          >
            Driver Information
          </div>
          <div className="add-new-car-driver-info-user-details">
            <div className="add-new-car-driver-info-user-details-section">
              {/* Location */}
              <div className="add-new-car-driver-info-user-detail">
                <div className="user-heading">Location</div>
                <div className="user-info">
                  <DropdownColumn
                    placeholder="Location"
                    options={locationOptions}
                    selectedValue={selectedLocation}
                    useInlineStyle={true}
                    handleChange={(e) => setSelectedLocation(e.target.value)}
                    className="dropdown"
                  />
                </div>
              </div>
              {/* First Name */}
              <div className="add-new-car-driver-info-user-detail">
                <div className="user-heading">First Name</div>
                <div>
                  <input
                    placeholder="Enter Name"
                    className="custom-input"
                    name="firstName"
                    autoComplete="off"
                    value={inputValue.firstName}
                    onChange={handleChange}
                  />
                </div>
              </div>
              {/* Last Name */}
              <div className="add-new-car-driver-info-user-detail">
                <div className="user-heading">Last Name</div>
                <div>
                  <input
                    placeholder="Enter surname"
                    className="custom-input"
                    name="lastName"
                    autoComplete="off"
                    value={inputValue.lastName}
                    onChange={handleChange}
                  />
                </div>
              </div>
              {/* Phone no */}
              <div
                className={`add-new-car-driver-info-user-detail ${inputValue.isPhoneNumberVerified && !inputValue.isOTPVerified
                    ? "column-layout"
                    : "row-layout"
                  }`}
              >
                <div className="user-heading">Phone Number</div>
                {!inputValue.isOTPVerified ? (
                  <React.Fragment>
                    <CountryDropdown
                      selectedCountry={selectedCountry}
                      setSelectedCountry={setSelectedCountry}
                    />
                    <input
                      placeholder="Enter Phone Number"
                      className="custom-input-phone"
                      name="phoneNumber"
                      autoComplete="off"
                      keyboardType="numeric"
                      value={inputValue.phoneNumber}
                      onChange={handleChange}
                      disabled={inputValue.isPhoneNumberVerified}
                    />
                    {!inputValue.isPhoneNumberVerified && (
                      <div className="verify" onClick={handleVerifyPhoneNumber}>
                        Verify
                      </div>
                    )}
                    <div className="otp-inputs">
                      {inputValue.isPhoneNumberVerified &&
                        inputValue.otp.map((value, index) => (
                          <input
                            key={index}
                            ref={(input) => (otpInputs.current[index] = input)}
                            type="text"
                            maxLength="1"
                            className="otp-input"
                            value={value}
                            onChange={(e) =>
                              handleOTPChange(index, e.target.value)
                            }
                          />
                        ))}
                    </div>
                    {inputValue.isPhoneNumberVerified &&
                      !inputValue.isOTPVerified && (
                        <div className="verify" onClick={handleVerifyOTP}>
                          Submit OTP
                        </div>
                      )}
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <div className="phone-number-verified">
                      <div className="phone-number">
                        <input
                          placeholder="Enter Phone Number"
                          className="custom-input-phone"
                          name="phoneNumber"
                          autoComplete="off"
                          keyboardType="numeric"
                          value={inputValue.phoneNumber}
                          onChange={handleChange}
                          disabled
                        />
                        <div className="verified">Verified</div>
                      </div>
                      <div
                        className="change-number"
                        onClick={handlePhoneNumberChange}
                      >
                        Change Number ?
                      </div>
                    </div>
                  </React.Fragment>
                )}
              </div>
              {/* Alternate Phone no */}
              <div className="add-new-car-driver-info-user-detail">
                <div className="user-heading">Alternate Phone No.</div>
                <div>
                  <input
                    placeholder="Alternate Phone Number"
                    className="custom-input"
                    autoComplete="off"
                    name="alternatePhoneNumber"
                    value={inputValue.alternatePhoneNumber}
                    onChange={handleChange}
                  />
                </div>
              </div>
              {/* DOB */}
              <div className="add-new-car-driver-info-user-detail">
                <div className="user-heading">DOB</div>
                <div>
                  <input
                    type="date"
                    placeholder="Enter DOB"
                    className="custom-input"
                    name="dob"
                    autoComplete="off"
                    value={inputValue.dob}
                    onChange={handleChange}
                    max={maxDateString}
                  />
                </div>
              </div>
              {/* Language Speak */}
              <div className="add-new-car-driver-info-user-detail" style={{ width: showOtherInput ? '600px' : 'auto' }}>
                <div className="user-heading">Language Speak</div>
                <input
                  type="checkbox"
                  id="hindiCheckbox"
                  name="language"
                  value="Hindi"
                  checked={inputValue.language.includes("Hindi")}
                  onChange={handleCheckboxChange}
                />
                <label htmlFor="hindiCheckbox">Hindi</label>
                <input
                  type="checkbox"
                  id="englishCheckbox"
                  name="language"
                  value="English"
                  checked={inputValue.language.includes("English")}
                  onChange={handleCheckboxChange}
                />
                <label htmlFor="englishCheckbox">English</label>
                <input
                  type="checkbox"
                  id="otherCheckbox"
                  name="language"
                  value="Other"
                  checked={inputValue.language.includes("Other")}
                  onChange={handleCheckboxChange}
                />
                <label htmlFor="otherCheckbox">Other</label>
                {showOtherInput && (
                  <div style={{ display: "flex" }}>
                    <input
                      type="text"
                      className="custom-input"
                      value={otherLanguage}
                      onChange={handleOtherLanguageChange}
                      placeholder="Enter Other Language"
                    />
                  </div>
                )}
              </div>
              {/* Create Password */}
              <div className="add-new-car-driver-info-user-detail" >
                <div className="user-heading">Create Password</div>
                <div>
                  <input
                    type="password"
                    placeholder="Enter Password"
                    className="custom-input"
                    autoComplete="off"
                    name="password"
                    value={inputValue.password}
                    onChange={handleChange}
                  />
                </div>
              </div>
              {/* Confirm Password */}
              <div className="add-new-car-driver-info-user-detail" >
                <div className="user-heading">Confirm Password</div>
                <div>
                  <input
                    type="password"
                    placeholder="Enter Password"
                    className="custom-input"
                    name="confirmPassword"
                    autoComplete="off"
                    value={inputValue.confirmPassword}
                    onChange={handleChange}
                  />
                </div>
              </div>
              {/* DL No. */}
              <div className="add-new-car-driver-info-user-detail">
                <div className="user-heading">DL No.</div>
                <div style={{marginTop:"6px"}}>
                  <input
                    placeholder="Enter Driving License number"
                    className="custom-input"
                    name="dlNo"
                    autoComplete="off"
                    value={inputValue.dlNo}
                    onChange={handleChange}
                  />
                <p style={{ color: 'grey', fontSize: '10px',textAlign: 'left', marginLeft: '3px', marginTop:"3px" }}>Format: DL-1420110012345</p>
                </div>
              </div>

              {/* Expiry Date */}
              <div className="add-new-car-driver-info-user-detail">
                <div className="user-heading">Expiry Date</div>
                <div className="user-info">
                  <input
                    type="date" 
                    placeholder="Enter Password"
                    className="custom-input"
                    name="expiryDate"
                    autoComplete="off"
                    value={inputValue.expiryDate}
                    onChange={handleChange}
                    min={new Date().toISOString().split('T')[0]} 
                  />
                </div>
              </div>
              {/* PAN No. */}
              <div className="add-new-car-driver-info-user-detail">
                <div className="user-heading">PAN No.</div>
                <div style={{marginTop:"6px"}}>
                  <input
                    type="text"
                    placeholder="Enter PAN Number"
                    className="custom-input"
                    name="panNo"
                    autoComplete="off"
                    value={inputValue.panNo}
                    onChange={handleChange}
                  />
                <p style={{ color: 'grey', fontSize: '10px',textAlign: 'left', marginLeft: '3px', marginTop:"3px" }}>Format: ABCDE1234C</p>
                </div>
              </div>
              {/* Sourcing */}
              <div className="add-new-car-driver-info-user-detail">
                <div className="user-heading">Sourcing</div>
                <input
                  type="checkbox"
                  id="CompanyOwnedCheckbox"
                  name="source"
                  value="Company Owned"
                  checked={inputValue.source === "Company Owned"}
                  onChange={SourcehandleCheckbox}
                />
                <label
                  htmlFor="CompanyOwnedCheckbox"
                  style={{
                    margintop: " 6px",
                    marginLeft: "1px",
                    width: "140px",
                  }}
                >
                  Company Owned
                </label>
                <input
                  type="checkbox"
                  id="OutsourcedCheckbox"
                  name="source"
                  value="Outsourced"
                  checked={inputValue.source === "Outsourced"}
                  onChange={SourcehandleCheckbox}
                />
                <label
                  htmlFor="OutsourcedCheckbox"
                  style={{
                    margintop: " 6px",
                    marginLeft: "1px",
                    width: "100px",
                  }}
                >
                  Outsourced
                </label>
              </div>
              {/* Current Address */}
              <div className="add-new-car-driver-info-user-detail">
                <div className="user-heading">Current Address</div>
                <div>
                  <input
                    type="text"
                    placeholder="Current Address"
                    className="custom-input"
                    name="currentAddress"
                    autoComplete="off"
                    value={inputValue.currentAddress}
                    onChange={handleChange}
                  />
                </div>
              </div>
              {/* Aadhar No. */}
              <div className="add-new-car-driver-info-user-detail">
                <div className="user-heading">Aadhar No.</div>
                <div>
                  <input
                    placeholder="Enter Aadhar Number"
                    className="custom-input"
                    name="adharNo"
                    autoComplete="off"
                    value={inputValue.adharNo}
                    onChange={handleChange}
                  />
                </div>
              </div>
              {/* Permanent address*/}
              <div className="add-new-car-driver-info-user-detail">
                <div className="user-heading">Permanent Address</div>
                <div>
                  <input
                    placeholder="Permanent Address"
                    className="custom-input"
                    name="permanentAddress"
                    autoComplete="off"
                    value={inputValue.permanentAddress}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
            {/* <div>
              <button className="earning-button" onClick={handleEarningReport}>
                Earning Report
              </button>
            </div> */}
          </div>

          <div
            style={{
              backgroundColor: "#38B000",
              borderRadius: "2px",
              boxShadow: "0px 4px 22px 0px rgba(0, 0, 0, 0.17)",
              color: "#FFF",
              padding: "10px",
              fontFamily: "Lato",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: "700",
              lineHeight: "normal",
            }}
          >
            Bank Details
          </div>
          <div className="user-details">
            <div className="user-details-section">
              <div className="user-detail">
                <div className="user-heading">Beneficiary name</div>
                <div>
                  <input
                    placeholder="Enter Beneficiary name"
                    className="custom-input"
                    name="beneficiaryName"
                    autoComplete="off"
                    value={inputValue.beneficiaryName}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
            <div className="user-details-section">
              <div className="user-detail">
                <div className="user-heading">Account No.</div>
                <div>
                  <input
                    placeholder="Enter Bank Account number"
                    className="custom-input"
                    name="accountNo"
                    autoComplete="off"
                    value={inputValue.accountNo}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
            <div className="user-details-section">
              <div className="user-detail">
                <div className="user-heading">IFSCCODE</div>
                <div>
                  <input
                    placeholder="Enter code"
                    className="custom-input"
                    name="ifscCode"
                    autoComplete="off"
                    value={inputValue.ifscCode}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
          </div>

          <div
            style={{
              backgroundColor: "#38B000",
              borderRadius: "2px",
              boxShadow: "0px 4px 22px 0px rgba(0, 0, 0, 0.17)",
              color: "#FFF",
              padding: "10px",
              fontFamily: "Lato",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: "700",
              lineHeight: "normal",
            }}
          >
            Price Quote
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            {/* Outstation Table */}
            <table className="pricing-table">
              <thead>
                <tr>
                  <th colSpan="2" style={tableHeaderStyle}>
                    Outstation
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Rate/km</td>
                  <td>
                    Rs.{" "}
                    <input
                      className="custom-input-2"
                      name="ratePerKm"
                      autoComplete="off"
                      value={inputValue.ratePerKm}
                      onChange={handleChange}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Min km/Day</td>
                  <td>
                    Km.{" "}
                    <input
                      className="custom-input-2"
                      name="minKmPerDay"
                      autoComplete="off"
                      value={inputValue.minKmPerDay}
                      onChange={handleChange}
                    />
                  </td>
                </tr>
              </tbody>
            </table>

            {/* Local Table */}
            <table className="pricing-table">
              <thead>
                <tr>
                  <th colSpan="2" style={tableHeaderStyle}>
                    Local
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>2hrs | 20km</td>
                  <td>
                    Rs.{" "}
                    <input
                      className="custom-input-2"
                      autoComplete="off"
                      name="ratePer20km"
                      value={inputValue.ratePer20km}
                      onChange={handleChange}
                    />
                  </td>
                </tr>
                <tr>
                  <td>4hrs | 40km</td>
                  <td>
                    Rs.{" "}
                    <input
                      className="custom-input-2"
                      autoComplete="off"
                      name="ratePer40km"
                      value={inputValue.ratePer40km}
                      onChange={handleChange}
                    />
                  </td>
                </tr>
                <tr>
                  <td>6hrs | 60km</td>
                  <td>
                    Rs.{" "}
                    <input
                      className="custom-input-2"
                      autoComplete="off"
                      name="ratePer60km"
                      value={inputValue.ratePer60km}
                      onChange={handleChange}
                    />
                  </td>
                </tr>
                <tr>
                  <td>8hrs | 80km</td>
                  <td>
                    Rs.{" "}
                    <input
                      className="custom-input-2"
                      autoComplete="off"
                      name="ratePer80km"
                      value={inputValue.ratePer80km}
                      onChange={handleChange}
                    />
                  </td>
                </tr>
                <tr>
                  <td>10hrs | 100km</td>
                  <td>
                    Rs.{" "}
                    <input
                      className="custom-input-2"
                      autoComplete="off"
                      name="ratePer100km"
                      value={inputValue.ratePer100km}
                      onChange={handleChange}
                    />
                  </td>
                </tr>
                <tr>
                  <td>12hrs | 120km</td>
                  <td>
                    Rs.{" "}
                    <input
                      className="custom-input-2"
                      autoComplete="off"
                      name="ratePer120km"
                      value={inputValue.ratePer120km}
                      onChange={handleChange}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Extra Km Charge</td>
                  <td>
                    Rs.{" "}
                    <input
                      className="custom-input-2"
                      autoComplete="off"
                      name="extraKm"
                      value={inputValue.extraKm}
                      onChange={handleChange}
                    />
                  </td>
                </tr>

                <tr>
                  <td>Extra Hrs Charge</td>
                  <td>
                    Rs.{" "}
                    <input
                      className="custom-input-2"
                      autoComplete="off"
                      name="extraHours"
                      value={inputValue.extraHours}
                      onChange={handleChange}
                    />
                  </td>
                </tr>
              </tbody>
            </table>

            {/* Airport Table */}
            <table className="pricing-table">
              <thead>
                <tr>
                  <th colSpan="2" style={tableHeaderStyle}>
                    Airport
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Rate Per Trip</td>
                  <td>
                    Rs.{" "}
                    <input
                      className="custom-input-2"
                      autoComplete="off"
                      name="ratePerTrip"
                      value={inputValue.ratePerTrip}
                      onChange={handleChange}
                    />
                  </td>
                </tr>
              </tbody>
            </table>

            {/* Night Charge Table */}
            <table className="pricing-table">
              <thead>
                <tr>
                  <th colSpan="2" style={tableHeaderStyle}>
                    Night Charge
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    Rs.{" "}
                    <input
                      className="custom-input-2"
                      autoComplete="off"
                      name="nightCharge"
                      value={inputValue.nightCharge}
                      onChange={handleChange}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div
            style={{
              backgroundColor: "#38B000",
              borderRadius: "2px",
              boxShadow: "0px 4px 22px 0px rgba(0, 0, 0, 0.17)",
              color: "#FFF",
              padding: "10px",
              fontFamily: "Lato",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: "700",
              lineHeight: "normal",
            }}
          >
            Upload Documents
          </div>
          <div className="user-details">
            <div className="user-details-section">
              <div className="user-detail">
                <div className="user-headin">Profile Photo</div>
                <div className="dropdown">
                  <div className="pic">
                    {profilePhoto && (
                      <img
                        className="pic-selected"
                        src={URL.createObjectURL(profilePhoto)}
                        alt="Selected"
                      />
                    )}
                  </div>

                  <label className="upload">
                    <input
                      type="file"
                      onChange={(e) => handleFileChange(e, setProfilePhoto)}
                      value={inputValue.profilePhoto}
                    />
                    <span>Choose File</span>
                  </label>
                  {profilePhoto && (
                    <p className="selected">
                      Selected File: {profilePhoto.name}
                    </p>
                  )}
                </div>
              </div>
              <div className="user-detail">
                <div className="user-headin">Pan Card</div>
                <div className="dropdown">
                  <div className="pic">
                    {panCard && (
                      <img
                        className="pic-selected"
                        src={URL.createObjectURL(panCard)}
                        alt="Selected"
                      />
                    )}
                  </div>
                  <label className="upload">
                    <input
                      type="file"
                      onChange={(e) => handleFileChange(e, setPanCard)}
                      value={inputValue.panCard}
                    />
                    <span>Choose File</span>
                  </label>
                  {panCard && (
                    <p className="selected">Selected File: {panCard.name}</p>
                  )}
                </div>
              </div>
            </div>
            <div className="user-details-section">
              <div className="user-detail">
                <div className="user-headin">Aadhar Card Front</div>
                <div className="dropdown">
                  <div className="pic">
                    {aadharCardFront && (
                      <img
                        className="pic-selected"
                        src={URL.createObjectURL(aadharCardFront)}
                        alt="Selected"
                      />
                    )}
                  </div>
                  <label className="upload">
                    <input
                      type="file"
                      onChange={(e) => handleFileChange(e, setAadharCardFront)}
                      value={inputValue.aadharCardFront}
                    />
                    <span>Choose File</span>
                  </label>
                  {aadharCardFront && (
                    <p className="selected">
                      Selected File: {aadharCardFront.name}
                    </p>
                  )}
                </div>
              </div>
              <div className="user-detail">
                <div className="user-headin">Aadhar Card Back</div>
                <div className="dropdown">
                  <div className="pic">
                    {aadharCardBack && (
                      <img
                        className="pic-selected"
                        src={URL.createObjectURL(aadharCardBack)}
                        alt="Selected"
                      />
                    )}
                  </div>
                  <label className="upload">
                    <input
                      type="file"
                      onChange={(e) => handleFileChange(e, setAadharCardBack)}
                      value={inputValue.aadharCardBack}
                    />
                    <span>Choose File</span>
                  </label>
                  {aadharCardBack && (
                    <p className="selected">
                      Selected File: {aadharCardBack.name}
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div className="user-details-section">
              <div className="user-detail">
                <div className="user-headin">Driving License Front</div>
                <div className="dropdown">
                  <div className="pic">
                    {drivingLicenseFront && (
                      <img
                        className="pic-selected"
                        src={URL.createObjectURL(drivingLicenseFront)}
                        alt="Selected"
                      />
                    )}
                  </div>
                  <label className="upload">
                    <input
                      type="file"
                      onChange={(e) =>
                        handleFileChange(e, setDrivingLicenseFront)
                      }
                      value={inputValue.drivingLicenseFront}
                    />
                    <span>Choose File</span>
                  </label>
                  {drivingLicenseFront && (
                    <p className="selected">
                      Selected File: {drivingLicenseFront.name}
                    </p>
                  )}
                </div>
              </div>
              <div className="user-detail">
                <div className="user-headin">Driving License Back</div>
                <div className="dropdown">
                  <div className="pic">
                    {drivingLicenseBack && (
                      <img
                        className="pic-selected"
                        src={URL.createObjectURL(drivingLicenseBack)}
                        alt="Selected"
                      />
                    )}
                  </div>
                  <label className="upload">
                    <input
                      type="file"
                      onChange={(e) =>
                        handleFileChange(e, setDrivingLicenseBack)
                      }
                      value={inputValue.drivingLicenseBack}
                    />
                    <span>Choose File</span>
                  </label>
                  {drivingLicenseBack && (
                    <p className="selected">
                      Selected File: {drivingLicenseBack.name}
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div className="user-details-section">
              <div className="user-detail">
                <div className="user-headin">Police clearance certificate</div>
                <div className="dropdown">
                  <div className="pic">
                    {policeClearanceCertificate && (
                      <img
                        className="pic-selected"
                        src={URL.createObjectURL(policeClearanceCertificate)}
                        alt="Selected"
                      />
                    )}
                  </div>
                  <label className="upload">
                    <input
                      type="file"
                      onChange={(e) =>
                        handleFileChange(e, setPoliceClearanceCertificate)
                      }
                      value={inputValue.policeClearanceCertificate}
                    />
                    <span>Choose File</span>
                  </label>
                  {policeClearanceCertificate && (
                    <p className="selected">
                      Selected File: {policeClearanceCertificate.name}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <button
              style={{
                borderRadius: "5px",
                margin: "10px",
                border: "0.5px solid #38B000",
                background: "#38B000",
                width: "150px",
                cursor: "pointer",
                padding: "6px",
                color: "#FFF",
                fontSize: "15px",
              }}
            >
              Submit
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default AddNewCar;
