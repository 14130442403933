import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';
import authSlice from "../components/login/authSlice";
import couponSlice from "../components/Coupons/couponSlice";
import DetailsSlice from "../components/Details/DetailsSlice";
import {
  bookingSclice,
  pendingBookingSclice,
} from "../components/Booking/BookingSlice";
import assigncabanddriver from "../components/AssignCab&Driver/assignCab&DriverSlice";
import assignCabDataSlice from "../components/AssignCab&Driver/assignCabSlice";
import assignDriverDataSlice from "../components/AssignCab&Driver/assignDriverSlice";
import driverDetailsSlice from "../components/DriverManagement/DriverDetailsSlice";
import customerDetailsSlice from "../components/Customer/CustomerDetailsSclice";
import geographySlice from "../components/Geography/geographySlice";
import { outstationReducer } from "../components/TariffManagement/Reducer";
import { localReducer } from "../components/TariffManagement/Reducer";
import rootReducer from "../components/TariffManagement/root-reducer";
import carSlice from "../components/CarManagement/carSlice";
import { carDetailsReducer } from "../components/CarManagement/CarDetailsSlice";
import { customerSlice } from "../components/Customer/CustomerSlice";
import tarrifDetailsSlice from "../components/VendorTarrifManagement/VendorTarrifSlice";
import corporateBookingReducer from "../components/CorporateBooking/CorporateBookingSlice"
import {
  driverSclice,
  approvalSclice,
  inactiveSclice,
  createDriverSclice,
} from "../components/DriverManagement/DriverSlice";
import singletariffDetailSlice from "../components/TariffManagement/SingleTariffDetalisSlice";
import InteractionSlice from "../components/Details/InteractionSlice";
import DeleteBookingSlice from "../components/Details/DeleteBookingSlice";

const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(
  persistConfig,
  combineReducers({
    auth: authSlice,
    coupon: couponSlice,
    details: DetailsSlice,
    booking: bookingSclice,
    pendingBooking: pendingBookingSclice,
    assignCabs: assignCabDataSlice, 
    assigncabanddriver: assigncabanddriver,
    assignDrivers: assignDriverDataSlice,
    geography: geographySlice,
    localtariff: localReducer,
    rootReducer: rootReducer,
    oustationtariff: outstationReducer,
    car: carSlice,
    driver: driverSclice,
    driverInfo: driverDetailsSlice,
    Approval: approvalSclice,
    Inactive: inactiveSclice,
    singleCar: carDetailsReducer, 
    singleTariff: singletariffDetailSlice,
    createDriver: createDriverSclice,
    customer: customerSlice,
    customerInfo: customerDetailsSlice,
    interaction: InteractionSlice,
    deleteBooking: DeleteBookingSlice,
    tarrifDetails:tarrifDetailsSlice,
    corporateBookings: corporateBookingReducer,
  })
);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        ignoredPaths: ['register', 'rehydrate']
      },
    }),
});

const persistor = persistStore(store);

export { store,persistor};