import { useState, useEffect } from "react";

const useSuggestionsCities = () => {
  const [suggestions, setSuggestions] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("https://api.yatricabs.com/v1/locations");
        const data = await response.json();
        const locationCities = data.data.map((item) => item.location);

        // console.log(locationCities);

        setSuggestions(locationCities);
      } catch (error) {
        console.error("Error fetching suggestions:", error);
      }
    };

    fetchData();
  }, []);

  return [suggestions];
};

export default useSuggestionsCities;
