import { useEffect, useState } from "react";
import { FaLongArrowAltRight } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import "./ViewCustomerDetails.css";
import { useSelector } from "react-redux";
import { selectNewValue } from "./CustomerSlice";

const NavContent = () => {
  const [previousLocation, setPreviousLocation] = useState();
  const [bookingdetailsOpened, setbookingdetailsOpened] = useState(false);
  const navigate = useNavigate();

  const newValue = useSelector(selectNewValue);


  useEffect(() => {
    checkParentLocation(window.location.pathname);
    console.log("checking parent location", window.location.pathname);
  }, []);

  const checkParentLocation = (path) => {
    if (path.includes("InactiveCars")) {
      setPreviousLocation("Inactive Cars");
    } else if (path.includes("ApprovalPendingCars")) {
      setPreviousLocation("Approval Pending");
    } else {
      setPreviousLocation("View All Cars");
    }
    if (path.includes("booking-details")) {

      setbookingdetailsOpened(true);
    }
  };

  const handleToggleComponent = (value) => {

    if (value === "Customer Details") {
     
      navigate(newValue)

    } else {
      navigate("/customer");

    }
  };
  return (
    <div className="nav-content" style={{ marginBottom: "10px" }}>
      <div className="header-text">
        <Link className="h3-booking">Customer</Link>
        <FaLongArrowAltRight />
        {""}
        <Link className="h3-booking">{previousLocation}</Link>
        <FaLongArrowAltRight />
        <Link className={bookingdetailsOpened ? " h3-booking" : "h2-booking"}>Customer Details</Link>
        {bookingdetailsOpened && (
          <>
            <FaLongArrowAltRight />
            <Link className="h2-booking">Booking Details</Link>
          </>

        )


        }


      </div>

      {!bookingdetailsOpened && previousLocation === "View All Cars" && (
        <button
          className="createButton"
          onClick={() => handleToggleComponent("View All Cars")}
        >
          View All Cars
        </button>
      )}

      {previousLocation === "Inactive Cars" && (
        <button
          className="createButton"
          onClick={() => {
            handleToggleComponent("Inactive Cars");
          }}
        >
          Inactive Cars
        </button>
      )}

      {previousLocation === "Approval Pending" && (
        <button
          className="createButton"
          onClick={() => handleToggleComponent("Approval Pending")}
        >
          Approval Pending
        </button>
      )}

      {bookingdetailsOpened && (
        <button
          className="createButton"
          onClick={() => handleToggleComponent("Customer Details")}
        >
          Customer Details
        </button>
      )}

    </div>
  )
}

export default NavContent
