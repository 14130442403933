import React from "react";
import countries from "./countrycode.json";

const CountryDropdown = (props) => {
  const { selectedCountry, setSelectedCountry } = props;

  const handleCountryChange = (event) => {
    const selectedValue = event.target.value;
    const digitPart = selectedValue.replace(/\D/g, "");
    setSelectedCountry(digitPart);
  };

  return (
    <div className="dropdown-column" style={{ width: "20%" }}>
      <select
        className="drop"
        onChange={handleCountryChange}
        value={selectedCountry}
        style={{
          fontSize: "11px",
          width: "90px",
          fontFamily: "sans-serif",
          height: "35px",
          color: "black",
          fontWeight: "600",
        }}
      >
        {countries.map((country) => (
          <option key={country.code} value={country.dial_code}>
            {`${country.name} (${country.dial_code})`}
          </option>
        ))}
      </select>
    </div>
  );
};

export default CountryDropdown;
