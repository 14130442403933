import React, { useState } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import Header from "../../components/header/Header";
import Navbar from "../../components/navbar/Navbar";
import { useDispatch } from "react-redux";
import App from "./App";

const AppManagement = () => {

  const [sidebar, setSidebar] = useState(false);

  const navigates = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const handleItemClick = (itemName) => {
    console.log(`Clicked on item: ${itemName}`);
  };

  const navigate = (to) => {
    console.log(`Navigating to: ${to}`);
    navigates(to);
  };
  return (
    <div>
    <Header setSidebar={setSidebar} sidebar={sidebar} />
    <div className="navbar-adminInfo">
      <Navbar
        sidebar={sidebar}
        handleItemClick={handleItemClick}
        navigate={navigate}
      />
      <App/>
    </div>
  </div>
  );
};



export default AppManagement;
