// import React, { useState } from "react";
// import { useSelector } from "react-redux";
// import { selectbookingDetails } from "../Details/DetailsSlice";
// import "./Clip.css";

// const SvgButton = ({ text, isActive, onClick }) => (
//   // <div className="main-btn">
//   <div className={`main-btn ${isActive ? "active" : ""}`} onClick={onClick}>
//     <svg
//       xmlns="http://www.w3.org/2000/svg"
//       viewBox="0 0 153 32"
//       fill="none"
//     >
//       <path
//         d="M6.13669 1H7.93525M7.93525 1H134.734L152 17.2121L134.734 31H3.25899L18.9065 17.0606L2 1H7.93525Z"
//         // stroke="#ADF192"
//         fill={isActive ? "#38B000" : "none"}
//         stroke={isActive ? "#000" : "#ADF192"}

//       />
//       <text
//         x="50%"
//         y="50%"
//         dominantBaseline="middle"
//         textAnchor="middle"
//         // fill="#000"
//         fill={isActive ? "#FFF" : "#000"}
//       >
//         {text}
//       </text>
//     </svg>
//   </div>
// );

// const Clip = () => {
//   const bookingDetails = useSelector(selectbookingDetails);

//   const [activeButton, setActiveButton] = useState(null);

//   const buttonLabels = [
//     "New Booking",
//     "Assigned",
//     "On Trip",
//     "Trip Completed",
//     "Billing Done",
//     "Feedback Received",
//   ];

//   return (
//     <div className="btn-3">
//       {buttonLabels.map((label, index) => (
//         <SvgButton
//           key={index}
//           text={label}
//           isActive={activeButton === index}
//           onClick={() => setActiveButton(index)}
//         />
//       ))}
//     </div>
//   );
// };

// export default Clip;


///////////////////////////////////////////////////////////////////////////////////////



import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { selectbookingDetails } from "../Details/DetailsSlice";
// import { selectBillingDetails } from "../Billing/BillingSlice"; // Assuming you have a similar selector for billing details
import "./Clip.css";

const SvgButton = ({ text, isActive }) => (
  <div className={`main-btn ${isActive ? "active" : ""}`}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 153 32"
      fill="none"
    >
      <path
        d="M6.13669 1H7.93525M7.93525 1H134.734L152 17.2121L134.734 31H3.25899L18.9065 17.0606L2 1H7.93525Z"
        fill={isActive ? "#38B000" : "none"}
        stroke={isActive ? "#000" : "#ADF192"}
      />
      <text
        x="50%"
        y="50%"
        dominantBaseline="middle"
        textAnchor="middle"
        fill={isActive ? "#FFF" : "#000"}
      >
        {text}
      </text>
    </svg>
  </div>
);

const Clip = () => {
  const bookingDetails = useSelector(selectbookingDetails);
  // const billingDetails = useSelector(selectBillingDetails); // Assuming this selector is available

  const [activeButton, setActiveButton] = useState(-1);

  const buttonLabels = [
    "New Booking",
    "Assigned",
    "On Trip",
    "Trip Completed",
    "Billing Done",
    "Feedback Received",
  ];

  useEffect(() => {
    if (bookingDetails) {
      const { status, driverCarAssigned } = bookingDetails;
      let newActiveButton = -1;

      // Set the active button index based on conditions
      if (status === "Confirmed") {
        newActiveButton = 0; // New Booking
      }
      if (driverCarAssigned) {
        newActiveButton = Math.max(newActiveButton, 1); // Assigned
      }
      if (status === "In Route") {
        newActiveButton = Math.max(newActiveButton, 2); // On Trip
      }
      if (status === "Completed") {
        newActiveButton = Math.max(newActiveButton, 3); // Trip Completed
      }

      // Additional conditions for Billing and Feedback
      // if (billingDetails) {
      //   newActiveButton = Math.max(newActiveButton, 4); // Billing Done
      // }
      
      // Assuming you have a way to get feedback details, adjust accordingly
      if (bookingDetails.feedback && bookingDetails.feedback.length > 0) {
        newActiveButton = Math.max(newActiveButton, 5); // Feedback Received
      }

      setActiveButton(newActiveButton);
    }
  }, [bookingDetails]);

  return (
    <div className="btn-3">
      {buttonLabels.map((label, index) => (
        <SvgButton
          key={index}
          text={label}
          isActive={index <= activeButton}
        />
      ))}
    </div>
  );
};

export default Clip;

