import React, { useState, useEffect, useRef } from "react";
import "../AddNewCar.css";
import axios from "axios";
import DropdownColumn from "../../Booking/Dropdown";
import { useDispatch, useSelector } from "react-redux";
import { setBooleanValue } from "../DriverSlice";
import toast, { Toaster } from "react-hot-toast";
import { useParams, Link, useNavigate } from "react-router-dom";
import { fetchDriversDetails, resetCompState } from '../DriverDetailsSlice'
import { FaLongArrowAltRight } from "react-icons/fa";
import Loader from "../../Loader";

const tableHeaderStyle = {
    backgroundColor: "#38B000",
    color: "white",
    padding: "10px",
    fontFamily: "Inter",
    fontSize: "15px",
    fontWeight: 600,
};

const ModifyDriverData = () => {
    const { driverDetails, currentComp } = useSelector((state) => state.driverInfo)
    const [previousLocation, setPreviousLocation] = useState();
    const { driverId } = useParams()
    const navigate = useNavigate()
    const [selectedLocation, setSelectedLocation] = useState("");
    const [locationOptions, setLocationOptions] = useState([]);
    const [showOtherInput, setShowOtherInput] = useState(false);
    const [otherLanguage, setOtherLanguage] = useState();
    const [profilePhoto, setProfilePhoto] = useState(null);
    const [panCard, setPanCard] = useState(null);
    const [aadharCardFront, setAadharCardFront] = useState(null);
    const [aadharCardBack, setAadharCardBack] = useState(null);
    const [drivingLicenseFront, setDrivingLicenseFront] = useState(null);
    const [drivingLicenseBack, setDrivingLicenseBack] = useState(null);
    const [policeClearanceCertificate, setPoliceClearanceCertificate] =
        useState(null);

    const [updatedData, setUpdatedData] = useState();
    const [callServer, setCallServer] = useState(false);

    const [updatedLanguages, setUpdatedLanguages] = useState();

    const [inputValue, setInputValue] = useState({
        firstName: "",
        lastName: "",
        phoneNumber: "",
        alternatePhoneNumber: "",
        password: "",
        confirmPassword: "",
        dob: "",
        panNo: "",
        adharNo: "",
        permanentAddress: "",
        currentAddress: "",
        location: "",
        language: "",
        dlNo: "",
        expiryDate: "", 
        beneficiaryName: "",
        accountNo: "",
        ifscCode: "",
        ratePer20km: "",
        ratePer40km: "",
        ratePer60km: "",
        ratePer80km: "",
        ratePer100km: "",
        ratePer120km: "",
        extraKm: "",
        extraHours: "",
        ratePerKm: "",
        minKmPerDay: "",
        nightCharge: "",
        ratePerTrip: "",
        sourcing: "",
        otp: ["", "", "", "", "", ""],
        isPhoneNumberVerified: false,
        isOTPVerified: false,
        otpId: "",
        phoneNumberToken: "",
        profilePhoto: "",
        aadhaarFrontPhoto: "",
        aadhaarBackPhoto: "",
        panCardPhoto: "",
        drivingLicenseFrontPhoto: "",
        drivingLicenseBackPhoto: "",
        policeClearanceCertificatePhoto: "",
    });

    console.log("Driver details", driverDetails);
    const dispatch = useDispatch();

    const handleEarningReport = (e) => {
        e.preventDefault();
        dispatch(setBooleanValue(false));
    };

    const SourcehandleCheckbox = (e) => {
        const { name, value, checked } = e.target;
        console.log(value);
        setInputValue({
            ...inputValue,
            [name]: checked ? value : "",
        });
    }

    const handleOtherLanguageChange = (e) => {
        setOtherLanguage(e.target.value)
    }

    useEffect(() => {
        if (otherLanguage) {
            if (inputValue.language) {
                setUpdatedLanguages(inputValue.language.join(', ') + ', ' + otherLanguage)
            } else {
                setUpdatedLanguages(otherLanguage)
            }

        }
    }, [otherLanguage])

    const handleToggleComponent = (value) => {
        dispatch(resetCompState(value));
        navigate('/drivermanagement');
    }

    const checkParentLocation = (path) => {
        if (path.includes('InactiveDriver')) {
            setPreviousLocation('Inactive Drivers');
        } else if (path.includes('ApprovalPendingDriver')) {
            setPreviousLocation('Approval Pending');
        } else {
            setPreviousLocation('View All Drivers');
        }
    }

    const handleChange = (e) => {
        setInputValue({
            ...inputValue,
            [e.target.name]: e.target.value,
        });
    }


    useEffect(() => {
        if (inputValue.language) {
            const currentLanguage = inputValue.language.join(', ')
            if (!otherLanguage) {
                console.log("checking languagesss", currentLanguage);
                setUpdatedLanguages(currentLanguage);
            }
            else {
                setUpdatedLanguages(currentLanguage + ', ' + otherLanguage);
            }

        }
    }, [inputValue.language])

    const handleCheckboxChange = (e) => {
        const { name, value, checked } = e.target;
        if (value !== 'Other') {

            setInputValue((prevInputValue) => {
                if (checked) {
                    // Add the language to the array if checked
                    const updatedLanguages = [...prevInputValue[name], value];
                    console.log(updatedLanguages); // Log the updated array
                    return { ...prevInputValue, [name]: updatedLanguages };
                } else {
                    // Remove the language from the array if unchecked
                    const updatedLanguages = prevInputValue[name].filter(
                        (lang) => lang !== value
                    );
                    console.log(updatedLanguages); // Log the updated array
                    return { ...prevInputValue, [name]: updatedLanguages };
                }
            });
        }


        if (value === "Other") {
            setShowOtherInput(checked);
            if (!checked) {
                setOtherLanguage();
            }
        }

    };

    const otpInputs = useRef([]);

    const handleOTPChange = (index, value) => {
        const updatedOTP = [...inputValue.otp];
        updatedOTP[index] = value;
        setInputValue({
            ...inputValue,
            otp: updatedOTP,
        });

        if (value !== "" && index < otpInputs.current.length - 1) {
            otpInputs.current[index + 1].focus();
        }
    };

    const handleFileChange = (event, setFile) => {
        const file = event.target.files[0];
        setInputValue({
            ...inputValue,
            [event.target.name]: URL.createObjectURL(file)
        })

        setUpdatedData({
            ...updatedData,
            [event.target.name]: file
        })

        if (file) {
            setFile(file);
        }
    };


    const handleSubmit = (e) => {

        e.preventDefault();

        setUpdatedData({
            ...updatedData,
            firstName: inputValue.firstName,
            lastName: inputValue.lastName,
            phone: inputValue.phoneNumber,
            dateOfBirth: inputValue.dob,
            languages: updatedLanguages,
            location: inputValue.location,
            dlNumber: inputValue.dlNo,
            dlExpiryDate: inputValue.expiryDate,
            panNumber: inputValue.panNo,
            permanentAddress: inputValue.permanentAddress,
            currentAddress: inputValue.currentAddress,
            aadhaarNumber: inputValue.adharNo,
            bankAccountNumber: inputValue.accountNo,
            bankAccountBeneficiaryName: inputValue.beneficiaryName,
            bankIFSCCode: inputValue.ifscCode,
            outstationPerKmFare: inputValue.ratePerKm,
            outstationMinKmPerDay: inputValue.minKmPerDay,
            local2hr20kmFare: inputValue.ratePer20km,
            local4hr40kmFare: inputValue.ratePer40km,
            local6hr60kmFare: inputValue.ratePer60km,
            local8hr80kmFare: inputValue.ratePer80km,
            local10hr100kmFare: inputValue.ratePer100km,
            local12hr120kmFare: inputValue.ratePer120km,
            localPerKmFareExtra: inputValue.extraKm,
            localPerHourFareExtra: inputValue.extraHours,
            airportBaseFare: inputValue.ratePerTrip,
            nightCharge: inputValue.nightCharge,
            sourcing: inputValue.sourcing,
        })
        setCallServer(true)

        console.log("Form Values:", updatedData);
    };



    const handleVerifyPhoneNumber = () => {
        fetch("https://api.yatricabs.com/v1/send-otp", {
            method: "POST",
            body: JSON.stringify({ phone: inputValue.phoneNumber }),
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((response) => {
                if (response.ok) {
                    console.log("OTP sent successfully");
                    console.log(response);
                    return response.json();
                } else {
                    throw new Error("Failed to send OTP");
                }
            })
            .then((data) => {
                if (data && data.data && data.data.otpId) {
                    const otpId = data.data.otpId;
                    console.log("OTP ID:", otpId);
                    setInputValue({
                        ...inputValue,
                        otpId: otpId,
                        isPhoneNumberVerified: true,
                    });
                } else {
                    throw new Error("Invalid response format from OTP sending API");
                }
            })
            .catch((error) => {
                // Handle errors here
                console.error("Error sending OTP:", error.message);
            });
    };

    const handleVerifyOTP = () => {
        const otpInteger = parseInt(inputValue.otp.join(""), 10);

        fetch("https://api.yatricabs.com/v1/verify-otp", {
            method: "POST",
            body: JSON.stringify({
                phone: inputValue.phoneNumber,
                otp: otpInteger,
                otpId: inputValue.otpId,
            }),
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((response) => {
                if (response.ok) {
                    console.log("OTP verified successfully");
                    console.log(response);
                    return response.json();
                } else {
                    throw new Error("Failed to verify OTP");
                }
            })
            .then((data) => {
                const phoneNumberToken = data.otpToken;
                setInputValue({
                    ...inputValue,
                    isOTPVerified: true,
                    phoneNumberToken: phoneNumberToken,
                });
                console.log("Message:", data.message);
                console.log("Token:", data.otpToken);
            })
            .catch((error) => {
                console.error("Error verifying OTP:", error.message);
            });
        console.log("OTP:", otpInteger);
        console.log("OTP ID:", inputValue.otpId);
        console.log("Phone Number:", inputValue.phoneNumber);
    };

    useEffect(() => {
        const extractLocationsAsOptions = (dataArray) => {
            if (Array.isArray(dataArray.data)) {
                return dataArray.data.map((item) => ({
                    label: item.location,
                    value: item._id,
                }));
            }
            return [];
        };

        const fetchLocation = async () => {
            try {
                const locationResponse = await fetch(
                    "https://api.yatricabs.com/v1/locations"
                );
                const locationData = await locationResponse.json();
                const locationOptions = extractLocationsAsOptions(locationData);
                // console.log(locationOptions);
                setLocationOptions(locationOptions);
            } catch (error) {
                console.error("Error fetching location options:", error);
            }
        };
        fetchLocation();
        dispatch(fetchDriversDetails(driverId));
        checkParentLocation(window.location.pathname);

    }, []);

    const [driverLocation, setDriverLocation] = useState()

    useEffect(() => {
        if (driverDetails) {
            console.log("checking driver details in update section", driverDetails);
            setDriverLocation(driverDetails.location.city + ', ' + driverDetails.location.state)
            setProfilePhoto(driverDetails.driverPhoto)
            setPanCard(driverDetails.pan.photo)
            setAadharCardFront(driverDetails.aadhaar.frontPhoto)
            setAadharCardBack(driverDetails.aadhaar.backPhoto)
            setDrivingLicenseFront(driverDetails.license.frontPhoto)
            setDrivingLicenseBack(driverDetails.license.backPhoto)
            setPoliceClearanceCertificate(driverDetails.policeClearanceCertificatePhoto)
            setInputValue({
                firstName: driverDetails.firstName,
                lastName: driverDetails.lastName,
                phoneNumber: driverDetails.phone,
                alternatePhoneNumber: driverDetails.alternatePhone,
                dob: driverDetails.dateOfBirth,
                panNo: driverDetails.pan.number,
                adharNo: driverDetails.aadhaar.number,
                permanentAddress: driverDetails.address.permanent,
                currentAddress: driverDetails.address.current,
                location: driverDetails.location._id,
                language: driverDetails.languages,
                dlNo: driverDetails.license.number,
                expiryDate: driverDetails.license.expiryDate,
                beneficiaryName: driverDetails.bankDetails.beneficiaryName,
                accountNo: driverDetails.bankDetails.accountNumber,
                ifscCode: driverDetails.bankDetails.IFSC,
                ratePer20km: driverDetails.fares.local.fare2hr20km,
                ratePer40km: driverDetails.fares.local.fare4hr40km,
                ratePer60km: driverDetails.fares.local.fare6hr60km,
                ratePer80km: driverDetails.fares.local.fare8hr80km,
                ratePer100km: driverDetails.fares.local.fare10hr100km,
                ratePer120km: driverDetails.fares.local.fare12hr120km,
                extraKm: driverDetails.fares.local.perKmFareExtra,
                extraHours: driverDetails.fares.local.perHourFareExtra,
                ratePerKm: driverDetails.fares.outstation.perKmFare,
                minKmPerDay: driverDetails.fares.outstation.minKmPerDay,
                nightCharge: driverDetails.fares.nightCharge,
                ratePerTrip: driverDetails.fares.airport.baseFare,
                sourcing: driverDetails.sourcing,
                otp: ["", "", "", "", "", ""],
                isPhoneNumberVerified: false,
                isOTPVerified: false,
                otpId: "",
                phoneNumberToken: "",
                profilePhoto: driverDetails.driverPhoto,
                aadhaarFrontPhoto: driverDetails.aadhaar.frontPhoto,
                aadhaarBackPhoto: driverDetails.aadhaar.backPhoto,
                panCardPhoto: driverDetails.pan.photo,
                drivingLicenseFrontPhoto: driverDetails.license.frontPhoto,
                drivingLicenseBackPhoto: driverDetails.license.backPhoto,
                policeClearanceCertificatePhoto: driverDetails.policeClearanceCertificatePhoto,
            })
        }
    }, [driverDetails])

    useEffect(() => {
        if (selectedLocation) {
            setInputValue({
                ...inputValue,
                location: selectedLocation,
            });
        }
    }, [selectedLocation])

    useEffect(() => {

        const dataUpdate = async () => {

            const headers = {
                "Content-Type": "multipart/form-data",
            };

            try {
                // Add a new car category
                await axios.put(`https://api.yatricabs.com/v1/admin/drivers/${driverId}`, updatedData, {
                    headers,
                });
                toast.success("Driver Data Updated!");
                setUpdatedData()

                setTimeout(() => {
                    window.location.reload();
                }, 3000);

                // e.target.reset();
                setSelectedLocation("");
                setProfilePhoto(null);
                setPanCard(null);
                setAadharCardFront(null);
                setAadharCardBack(null);
                setDrivingLicenseFront(null);
                setDrivingLicenseBack(null);
                setPoliceClearanceCertificate(null);
            } catch (error) {
                toast.error(error.response.data.message);
                console.error("error ", error.response.data.message);
            }

            setCallServer(false);
        }

        if (callServer === true) {

            dataUpdate();
        }

    }, [updatedData])


    return (
        <>
            <Toaster />


            <form onSubmit={handleSubmit}>

                <div className="nav-content" style={{ marginTop: '25px' }}>

                    <div className="header-text">
                        <Link className="h3-booking">
                            Driver Management
                        </Link>
                        <FaLongArrowAltRight />
                        {""}
                        <Link className="h3-booking">
                            {previousLocation}
                        </Link>
                        <FaLongArrowAltRight />
                        <Link className="h2-booking">
                            Edit Driver Details
                        </Link>
                    </div>
                    {(previousLocation === 'View All Drivers') && (
                        <button
                            className="createButton"
                            onClick={() => handleToggleComponent('View All Drivers')}
                        >
                            View All Drivers
                        </button>
                    )
                    }

                    {(previousLocation === 'Inactive Drivers') && (
                        <button
                            className="createButton"
                            onClick={() => {
                                handleToggleComponent('Inactive Driver')
                            }}
                        >
                            Inactive Drivers
                        </button>
                    )
                    }

                    {(previousLocation === 'Approval Pending') && (
                        <button
                            className="createButton"
                            onClick={() => handleToggleComponent('Approval Pending')}
                        >
                            Approval Pending
                        </button>
                    )
                    }

                </div>

                <div
                    style={{
                        backgroundColor: "#38B000",
                        borderRadius: "2px",
                        boxShadow: "0px 4px 22px 0px rgba(0, 0, 0, 0.17)",
                        color: "#FFF",
                        padding: "10px",
                        fontFamily: "Lato",
                        fontSize: "16px",
                        fontStyle: "normal",
                        fontWeight: "700",
                        lineHeight: "normal",
                        width: '1300px'
                    }}
                >
                    Driver Information
                </div>

                {
                    driverDetails ?
                        <>
                            <div className="user-details">
                                <div className="user-details-section">
                                    <div className="user-detail">
                                        <div className="user-heading">Location</div>
                                        <div className="user-info">
                                            <DropdownColumn
                                                placeholder={driverLocation}
                                                options={locationOptions}
                                                selectedValue={selectedLocation}
                                                handleChange={(e) => setSelectedLocation(e.target.value)}
                                                className="dropdown"
                                            />
                                        </div>
                                    </div>
                                    <div
                                        className={`user-detail ${inputValue.isPhoneNumberVerified && !inputValue.isOTPVerified
                                            ? "column-layout"
                                            : "row-layout"
                                            }`}
                                    >
                                        <div className="user-heading">Phone Number</div>
                                        {!inputValue.isOTPVerified ? (
                                            <React.Fragment>
                                                <input
                                                    placeholder="Enter Phone Number"
                                                    className="custom-input-phone"
                                                    name="phoneNumber"
                                                    autoComplete="off"
                                                    keyboardType="numeric"
                                                    value={inputValue.phoneNumber}
                                                    onChange={handleChange}
                                                    disabled={inputValue.isPhoneNumberVerified}
                                                />
                                                {!inputValue.isPhoneNumberVerified && (
                                                    <div className="verify" onClick={handleVerifyPhoneNumber}>
                                                        Verify
                                                    </div>
                                                )}
                                                <div className="otp-inputs">
                                                    {inputValue.isPhoneNumberVerified &&
                                                        inputValue.otp.map((value, index) => (
                                                            <input
                                                                key={index}
                                                                ref={(input) => (otpInputs.current[index] = input)}
                                                                type="text"
                                                                maxLength="1"
                                                                className="otp-input"
                                                                value={value}
                                                                onChange={(e) =>
                                                                    handleOTPChange(index, e.target.value)
                                                                }
                                                            />
                                                        ))}
                                                </div>
                                                {inputValue.isPhoneNumberVerified &&
                                                    !inputValue.isOTPVerified && (
                                                        <div className="verify" onClick={handleVerifyOTP}>
                                                            Submit OTP
                                                        </div>
                                                    )}
                                            </React.Fragment>
                                        ) : (
                                            <React.Fragment>
                                                <input
                                                    placeholder="Enter Phone Number"
                                                    className="custom-input-phone"
                                                    name="phoneNumber"
                                                    autoComplete="off"
                                                    keyboardType="numeric"
                                                    value={inputValue.phoneNumber}
                                                    onChange={handleChange}
                                                    disabled
                                                />
                                                <div className="verified">Verified</div>
                                            </React.Fragment>
                                        )}
                                    </div>

                                    <div className="user-detail">
                                        <div className="user-heading">Language Speak</div>
                        
                                            {
                                                driverDetails.languages.map((lang) => {
                                                    return <>
                                                        <input
                                                            type="checkbox"
                                                            id="hindiCheckbox"
                                                            name="language"
                                                            value={lang}
                                                            checked={inputValue.language.includes(lang)}
                                                            onChange={handleCheckboxChange}
                                                        />
                                                        <label htmlFor="hindiCheckbox">{lang}</label>
                                                    </>
                                                })
                                            }

                                            <>
                                                <input
                                                    type="checkbox"
                                                    id="otherCheckbox"
                                                    name="language"
                                                    value="Other"
                                                    checked={showOtherInput}
                                                    onChange={handleCheckboxChange}
                                                />
                                                <label htmlFor="otherCheckbox">Other</label>
                                            </>


                                        </div>

                                        <div className="lang-input">
                                            {
                                                showOtherInput &&
                                                (
                                                    <input
                                                        type="text"
                                                        className="custom-input"
                                                        onChange={handleOtherLanguageChange}
                                                        placeholder="Enter Other Language"
                                                    />
                                                )
                                            }
                                        </div>

                                


                                    <div className="user-detail">
                                        <div className="user-heading">DL No.</div>
                                        <div>
                                            <input
                                                type="text"
                                                placeholder="Enter Driving License number"
                                                className="custom-input"
                                                name="dlNo"
                                                autoComplete="off"
                                                value={inputValue.dlNo}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>

                                    <div className="user-detail">
                                        <div className="user-heading">Sourcing</div>
                                        <input
                                            type="checkbox"
                                            id="CompanyOwnedCheckbox"
                                            name="sourcing"
                                            value="Company Owned"
                                            checked={inputValue.sourcing === "Company Owned"}
                                            onChange={SourcehandleCheckbox}
                                        />
                                        <label
                                            htmlFor="CompanyOwnedCheckbox"
                                            style={{
                                                margintop: " 6px",
                                                marginLeft: "10px",
                                                width: "140px",
                                            }}
                                        >
                                            Company Owned
                                        </label>
                                        <input
                                            type="checkbox"
                                            id="OutsourcedCheckbox"
                                            name="sourcing"
                                            value="Outsourced"
                                            checked={inputValue.sourcing === "Outsourced"}
                                            onClick={SourcehandleCheckbox}
                                        />
                                        <label
                                            htmlFor="OutsourcedCheckbox"
                                            style={{
                                                margintop: " 6px",
                                                marginLeft: "10px",
                                                width: "100px",
                                            }}
                                        >
                                            Outsourced
                                        </label>
                                    </div>
                                    <div className="user-detail">
                                        <div className="user-heading">Permanent Address</div>
                                        <div>
                                            <input
                                                placeholder="Permanent Address"
                                                className="custom-input"
                                                name="permanentAddress"
                                                autoComplete="off"
                                                value={inputValue.permanentAddress}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="user-details-section">
                                    <div className="user-detail">
                                        <div className="user-heading">First Name</div>
                                        <div>
                                            <input
                                                placeholder="Enter Name"
                                                className="custom-input"
                                                name="firstName"
                                                autoComplete="off"
                                                value={inputValue.firstName}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="user-detail">
                                        <div className="user-heading">Alternate Phone No.</div>
                                        <div>
                                            <input
                                                placeholder="Alternate Phone Number"
                                                className="custom-input"
                                                autoComplete="off"
                                                name="alternatePhoneNumber"
                                                value={inputValue.alternatePhoneNumber}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>

                                    <div className="user-detail">
                                        <div className="user-heading">Expiry Date</div>
                                        <div className="user-info">
                                            <input
                                                type="text"
                                                placeholder="Enter Expiry Date"
                                                className="custom-input"
                                                name="expiryDate"
                                                autoComplete="off"
                                                value={inputValue.expiryDate}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="user-detail">
                                        <div className="user-heading">Current Address</div>
                                        <div>
                                            <input
                                                type="text"
                                                placeholder="Current Address"
                                                className="custom-input"
                                                name="currentAddress"
                                                autoComplete="off"
                                                value={inputValue.currentAddress}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="user-details-section">
                                    <div className="user-detail">
                                        <div className="user-heading">Last Name</div>
                                        <div>
                                            <input
                                                placeholder="Enter surname"
                                                className="custom-input"
                                                name="lastName"
                                                autoComplete="off"
                                                value={inputValue.lastName}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="user-detail">
                                        <div className="user-heading">DOB</div>
                                        <div>
                                            <input
                                                type="text"
                                                placeholder="Enter DOB"
                                                className="custom-input"
                                                name="dob"
                                                autoComplete="off"
                                                value={inputValue.dob}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="user-detail">
                                        <div className="user-heading">Pan No.</div>
                                        <div>
                                            <input
                                                type="text"
                                                placeholder="Enter PAN Number"
                                                className="custom-input"
                                                name="panNo"
                                                autoComplete="off"
                                                value={inputValue.panNo}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="user-detail">
                                        <div className="user-heading">Aadhaar No.</div>
                                        <div>
                                            <input
                                                placeholder="Enter Aadhar Number"
                                                className="custom-input"
                                                name="adharNo"
                                                autoComplete="off"
                                                value={inputValue.adharNo}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <button className="earning-button" onClick={handleEarningReport}>
                                        Earning Report
                                    </button>
                                </div>
                            </div>

                        </> :
                        <Loader />
                }
                <div
                    style={{
                        backgroundColor: "#38B000",
                        borderRadius: "2px",
                        boxShadow: "0px 4px 22px 0px rgba(0, 0, 0, 0.17)",
                        color: "#FFF",
                        padding: "10px",
                        fontFamily: "Lato",
                        fontSize: "16px",
                        fontStyle: "normal",
                        fontWeight: "700",
                        lineHeight: "normal",
                    }}
                >
                    Bank Details
                </div>
                {
                    driverDetails ?
                        <>
                            <div className="user-details">
                                <div className="user-details-section">
                                    <div className="user-detail">
                                        <div className="user-heading">Beneficiary name</div>
                                        <div>
                                            <input
                                                placeholder="Enter Beneficiary name"
                                                className="custom-input"
                                                name="beneficiaryName"
                                                autoComplete="off"
                                                value={inputValue.beneficiaryName}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="user-details-section">
                                    <div className="user-detail">
                                        <div className="user-heading">Account No.</div>
                                        <div>
                                            <input
                                                placeholder="Enter Bank Account number"
                                                className="custom-input"
                                                name="accountNo"
                                                autoComplete="off"
                                                value={inputValue.accountNo}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="user-details-section">
                                    <div className="user-detail">
                                        <div className="user-heading">IFSCCODE</div>
                                        <div>
                                            <input
                                                placeholder="Enter code"
                                                className="custom-input"
                                                name="ifscCode"
                                                autoComplete="off"
                                                value={inputValue.ifscCode}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </> :
                        <Loader />
                }


                <div
                    style={{
                        backgroundColor: "#38B000",
                        borderRadius: "2px",
                        boxShadow: "0px 4px 22px 0px rgba(0, 0, 0, 0.17)",
                        color: "#FFF",
                        padding: "10px",
                        fontFamily: "Lato",
                        fontSize: "16px",
                        fontStyle: "normal",
                        fontWeight: "700",
                        lineHeight: "normal",
                    }}
                >
                    Price Quote
                </div>
                {
                    driverDetails ?
                        <>
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-around",
                                    alignItems: "center",
                                    flexWrap: "wrap",
                                }}
                            >
                                {/* Outstation Table */}
                                <table className="pricing-table">
                                    <thead>
                                        <tr>
                                            <th colSpan="2" style={tableHeaderStyle}>
                                                Outstation
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Rate/km</td>
                                            <td>
                                                Rs.{" "}
                                                <input
                                                    className="custom-input-2"
                                                    name="ratePerKm"
                                                    autoComplete="off"
                                                    value={inputValue.ratePerKm}
                                                    onChange={handleChange}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Min km/Day</td>
                                            <td>
                                                Rs.{" "}
                                                <input
                                                    className="custom-input-2"
                                                    name="minKmPerDay"
                                                    autoComplete="off"
                                                    value={inputValue.minKmPerDay}
                                                    onChange={handleChange}
                                                />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>

                                {/* Local Table */}
                                <table className="pricing-table">
                                    <thead>
                                        <tr>
                                            <th colSpan="2" style={tableHeaderStyle}>
                                                Local
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>2hrs | 20km</td>
                                            <td>
                                                Rs.{" "}
                                                <input
                                                    className="custom-input-2"
                                                    autoComplete="off"
                                                    name="ratePer20km"
                                                    value={inputValue.ratePer20km}
                                                    onChange={handleChange}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>4hrs | 40km</td>
                                            <td>
                                                Rs.{" "}
                                                <input
                                                    className="custom-input-2"
                                                    autoComplete="off"
                                                    name="ratePer40km"
                                                    value={inputValue.ratePer40km}
                                                    onChange={handleChange}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>6hrs | 60km</td>
                                            <td>
                                                Rs.{" "}
                                                <input
                                                    className="custom-input-2"
                                                    autoComplete="off"
                                                    name="ratePer60km"
                                                    value={inputValue.ratePer60km}
                                                    onChange={handleChange}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>8hrs | 80km</td>
                                            <td>
                                                Rs.{" "}
                                                <input
                                                    className="custom-input-2"
                                                    autoComplete="off"
                                                    name="ratePer80km"
                                                    value={inputValue.ratePer80km}
                                                    onChange={handleChange}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>10hrs | 100km</td>
                                            <td>
                                                Rs.{" "}
                                                <input
                                                    className="custom-input-2"
                                                    autoComplete="off"
                                                    name="ratePer100km"
                                                    value={inputValue.ratePer100km}
                                                    onChange={handleChange}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>12hrs | 120km</td>
                                            <td>
                                                Rs.{" "}
                                                <input
                                                    className="custom-input-2"
                                                    autoComplete="off"
                                                    name="ratePer120km"
                                                    value={inputValue.ratePer120km}
                                                    onChange={handleChange}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Extra Km Charge</td>
                                            <td>
                                                Rs.{" "}
                                                <input
                                                    className="custom-input-2"
                                                    autoComplete="off"
                                                    name="extraKm"
                                                    value={inputValue.extraKm}
                                                    onChange={handleChange}
                                                />
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>Extra Hrs Charge</td>
                                            <td>
                                                Rs.{" "}
                                                <input
                                                    className="custom-input-2"
                                                    autoComplete="off"
                                                    name="extraHours"
                                                    value={inputValue.extraHours}
                                                    onChange={handleChange}
                                                />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>

                                {/* Airport Table */}
                                <table className="pricing-table">
                                    <thead>
                                        <tr>
                                            <th colSpan="2" style={tableHeaderStyle}>
                                                Airport
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Rate Per Trip</td>
                                            <td>
                                                Rs.{" "}
                                                <input
                                                    className="custom-input-2"
                                                    autoComplete="off"
                                                    name="ratePerTrip"
                                                    value={inputValue.ratePerTrip}
                                                    onChange={handleChange}
                                                />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>

                                {/* Night Charge Table */}
                                <table className="pricing-table">
                                    <thead>
                                        <tr>
                                            <th colSpan="2" style={tableHeaderStyle}>
                                                Night Charge
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                Rs.{" "}
                                                <input
                                                    className="custom-input-2"
                                                    autoComplete="off"
                                                    name="nightCharge"
                                                    value={inputValue.nightCharge}
                                                    onChange={handleChange}
                                                />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </> :
                        <Loader />
                }


                <div
                    style={{
                        backgroundColor: "#38B000",
                        borderRadius: "2px",
                        boxShadow: "0px 4px 22px 0px rgba(0, 0, 0, 0.17)",
                        color: "#FFF",
                        padding: "10px",
                        fontFamily: "Lato",
                        fontSize: "16px",
                        fontStyle: "normal",
                        fontWeight: "700",
                        lineHeight: "normal",
                    }}
                >
                    Upload Documents
                </div>
                {
                    driverDetails ?
                        <>
                            <div className="user-details">
                                <div className="user-details-section">
                                    <div className="user-detail">
                                        <div className="user-headin">Profile Photo</div>
                                        <div className="dropdown">
                                            <div className="pic">

                                                <img
                                                    className="pic-selected"
                                                    src={inputValue?.profilePhoto}
                                                    alt="profilePhoto"
                                                />

                                            </div>

                                            <label className="upload">
                                                <input
                                                    type="file"
                                                    name="profilePhoto"
                                                    onChange={(e) => handleFileChange(e, setProfilePhoto)}
                                                    value={""}
                                                />
                                                <span>Choose File</span>
                                            </label>

                                        </div>
                                    </div>
                                    <div className="user-detail">
                                        <div className="user-headin">Pan Card</div>
                                        <div className="dropdown">
                                            <div className="pic">

                                                <img
                                                    className="pic-selected"
                                                    src={inputValue?.panCardPhoto}
                                                    alt="panCard"
                                                />

                                            </div>
                                            <label className="upload">
                                                <input
                                                    type="file"
                                                    name="panCardPhoto"
                                                    onChange={(e) => handleFileChange(e, setPanCard)}
                                                    value={""}
                                                />
                                                <span>Choose File</span>
                                            </label>

                                        </div>
                                    </div>
                                </div>
                                <div className="user-details-section">
                                    <div className="user-detail">
                                        <div className="user-headin">Aadhaar Card Front</div>
                                        <div className="dropdown">
                                            <div className="pic">

                                                <img
                                                    className="pic-selected"
                                                    src={inputValue?.aadhaarFrontPhoto}
                                                    alt="aadharCard"
                                                />

                                            </div>
                                            <label className="upload">
                                                <input
                                                    type="file"
                                                    name="aadhaarFrontPhoto"
                                                    onChange={(e) => handleFileChange(e, setAadharCardFront)}
                                                    value={""}
                                                />
                                                <span>Choose File</span>
                                            </label>

                                        </div>
                                    </div>
                                    <div className="user-detail">
                                        <div className="user-headin">Aadhaar Card Back</div>
                                        <div className="dropdown">
                                            <div className="pic">

                                                <img 
                                                    className="pic-selected"
                                                    src={inputValue?.aadhaarBackPhoto}
                                                    alt="aadharCard"
                                                />

                                            </div>
                                            <label className="upload">
                                                <input
                                                    type="file"
                                                    name="aadhaarBackPhoto"
                                                    onChange={(e) => handleFileChange(e, setAadharCardBack)}
                                                    value={""}
                                                />
                                                <span>Choose File</span>
                                            </label>

                                        </div>
                                    </div>
                                </div>
                                <div className="user-details-section">
                                    <div className="user-detail">
                                        <div className="user-headin">Driving License Front</div>
                                        <div className="dropdown">
                                            <div className="pic">

                                                <img
                                                    className="pic-selected"
                                                    src={inputValue?.drivingLicenseFrontPhoto}
                                                    alt="drivingLicense"
                                                />

                                            </div>
                                            <label className="upload">
                                                <input
                                                    type="file"
                                                    name="drivingLicenseFrontPhoto"
                                                    onChange={(e) =>
                                                        handleFileChange(e, setDrivingLicenseFront)
                                                    }
                                                    value={""}
                                                />
                                                <span>Choose File</span>
                                            </label>

                                        </div>
                                    </div>
                                    <div className="user-detail">
                                        <div className="user-headin">Driving License Back</div>
                                        <div className="dropdown">
                                            <div className="pic">

                                                <img
                                                    className="pic-selected"
                                                    src={inputValue?.drivingLicenseBackPhoto}
                                                    alt="drivingLicense"
                                                />

                                            </div>
                                            <label className="upload">
                                                <input
                                                    type="file"
                                                    name="drivingLicenseBackPhoto"
                                                    onChange={(e) => handleFileChange(e, setDrivingLicenseBack)}
                                                    value={""}
                                                />
                                                <span>Choose File</span>
                                            </label>

                                        </div>
                                    </div>
                                </div>
                                <div className="user-details-section">
                                    <div className="user-detail">
                                        <div className="user-headin">Police clearance certificate</div>
                                        <div className="dropdown">
                                            <div className="pic">

                                                <img
                                                    className="pic-selected"
                                                    src={inputValue?.policeClearanceCertificatePhoto}
                                                    alt="policeClearanceCertificate"
                                                />

                                            </div>
                                            <label className="upload">
                                                <input
                                                    type="file"
                                                    name="policeClearanceCertificatePhoto"
                                                    onChange={(e) =>
                                                        handleFileChange(e, setPoliceClearanceCertificate)
                                                    }
                                                    value={""}
                                                />
                                                <span>Choose File</span>
                                            </label>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </> :
                        <Loader />
                }
                <div style={{ display: "flex", justifyContent: "center" }}>
                    <button
                        style={{
                            borderRadius: "5px",
                            margin: "10px",
                            border: "0.5px solid #38B000",
                            background: "#38B000",
                            width: "150px",
                            cursor: "pointer",
                            padding: "6px",
                            color: "#FFF",
                            fontSize: "15px",
                        }}
                    >
                        Update
                    </button>
                </div>
            </form>
        </>
    );
};

export default ModifyDriverData;
