import React from "react";
import "./Fourthtable.css";
import { useDispatch, useSelector } from "react-redux";
import { selectbookingDetails } from "../DetailsSlice";

const FourthFare = ({ bookingDetailsTableFourthFare }) => {
  const bookingDetails = useSelector(selectbookingDetails);
  // if (!bookingDetailsTableFourthFare || typeof bookingDetailsTableFourthFare !== "object") {
  //   return null;
  // }




  console.log("FourthFareNotCompleted",bookingDetailsTableFourthFare);

  const extrakmchargeRate = "N/A";
  const finalExtrakmcharge = "N/A";
  const finalExtraHrscharge = "N/A";
  const nightDriverAllowanceRate = "N/A";
  const finalNightDriverAllowance = "N/A";
  const gstRate = "N/A";
  const finalGstRate = "N/A";
  const finalAmountwithGstRate = "N/A";
  const totalextraamount = "N/A";
  const balanceAmount = bookingDetailsTableFourthFare?.estimatedFare - bookingDetailsTableFourthFare?.advancePaymentReceived ?? 0;
  const totalAmtPaidToDriver = "N/A";
  const cashAmount = bookingDetailsTableFourthFare?.cashFuel ?? "N/A";
  const extraCharges = bookingDetailsTableFourthFare?.extraCharges ?? 0;

  if (bookingDetailsTableFourthFare?.tariff?.tripType === "Outstation") {


  if(bookingDetailsTableFourthFare?.tariff?.subTripType==="Round Trip"){  
    var farebreakupdata = [
      {
        lable: `Extra km charge @Rs.${bookingDetailsTableFourthFare?.tariff?.fareDetails?.extraKmCharge}/Km `,
        value:`${ finalExtrakmcharge}`,
      },
      {
        lable: `Extra Driver Allowance @Rs.${bookingDetailsTableFourthFare.tariff.fareDetails.nightDriverAllowance}/day`,
        value: `${finalNightDriverAllowance}`,
      },
      {
        lable: `5 % GST on Rs. ${extraCharges.toFixed(2)}`,
        value: `${finalAmountwithGstRate}`,
      },
      { lable: "Total Extra Amount", value: `${totalextraamount}` },
      { lable: "Plus Balance Amount", value: `Rs. ${balanceAmount.toFixed(2)}` },
      { lable: "Less cash for fuel received by driver in advance", value: `${cashAmount }`},
      { lable: "Total Paid to Online", value: `Rs. ${totalAmtPaidToDriver}` },
    ];
  }else{
    var farebreakupdata = [
      {
        lable: `Extra km charge @Rs.${bookingDetailsTableFourthFare?.tariff?.fareDetails?.extraKmCharge}/Km `,
        value:`${ finalExtrakmcharge}`,
      },
      {
        lable: `Night Charge @Rs.${bookingDetailsTableFourthFare.tariff.fareDetails.nightDriverAllowance}/day`,
        value: `${finalNightDriverAllowance}`,
      },
      {
        lable: `5 % GST on Rs. ${extraCharges.toFixed(2)}`,
        value: `${finalAmountwithGstRate}`,
      },
      { lable: "Total Extra Amount", value: `${totalextraamount}` },
      { lable: "Plus Balance Amount", value: `Rs. ${balanceAmount.toFixed(2)}` },
      { lable: "Less cash for fuel received by driver in advance", value: `${cashAmount }`},
      { lable: "Total Paid to Online", value: `Rs. ${totalAmtPaidToDriver}` },
    ];
  }
 }else if (bookingDetailsTableFourthFare?.tariff?.tripType === "Local") {

    var farebreakupdata = [
      {
        lable: `Extra km charge @Rs.${bookingDetailsTableFourthFare?.tariff?.fareDetails?.extraKmCharge}/Km `,
        value:`${ finalExtrakmcharge}`,
      },
      {
        lable: `Extra Hrs charge @Rs.${bookingDetailsTableFourthFare?.tariff?.fareDetails?.extraHrsCharge}/Hr `,
        value: `${finalExtraHrscharge}`,
      },
      {
        lable: `Extra Night Allowance @Rs.${bookingDetailsTableFourthFare?.tariff?.fareDetails?.nightDriverAllowance}/day X 1 Day`,
        value: ` ${finalNightDriverAllowance}`,
      },
      {
        lable: `5 % GST on Rs. ${extraCharges.toFixed(2)}`,
        value: ` ${finalAmountwithGstRate}`,
      },
      { lable: "Total Extra Amount", value: `${totalextraamount}` },
      { lable: "Plus Balance Amount", value: `Rs. ${balanceAmount.toFixed(2)}` },
      { lable: "Less cash for fuel received by driver in advance", value: ` ${cashAmount}` },
      { lable: "Total Paid to Online", value: totalAmtPaidToDriver },
    ];


  } else {

    var farebreakupdata = [
      {
        lable: `Extra km charge @Rs.${bookingDetailsTableFourthFare?.tariff?.fareDetails?.extraKmCharge}/Km `,
        value: finalExtrakmcharge,
      },
      {
        lable: `Extra Hrs charge @Rs.${bookingDetailsTableFourthFare?.tariff?.fareDetails?.extraHrsCharge}/Hr `,
        value: finalExtraHrscharge,
      },
      {
        lable: `Extra Night Allowance @Rs.${bookingDetailsTableFourthFare?.tariff?.fareDetails?.nightDriverAllowance}/day X 1 Day`,
        value: finalNightDriverAllowance,
      },
      {
        lable: `5 % GST on Rs. ${extraCharges.toFixed(2)}`,
        value: finalAmountwithGstRate,
      },
      { lable: "Total Extra Amount", value: totalextraamount },
      { lable: "Plus Balance Amount", value: balanceAmount.toFixed(2) },
      { lable: "Less cash for fuel received by driver in advance", value: cashAmount },
      { lable: "Total Paid to Online", value: totalAmtPaidToDriver },
    ];

  }
  return (
    <div className="billing_fourth_containers">
      <div className="billing_fourth_scroll">
        <table className="billing-table-fourth">
          <thead>
            <tr>
              <th  style={{
                textAlign:"center"
              }}  colSpan="2">Extra Fare</th>
            </tr>
          </thead>
          <tbody className="firstFare_billing_table_data">
            {farebreakupdata.map((row, index) => (
              <React.Fragment key={index}>
                <tr>
                  <td
                    className="fourth-td"
                    style={
                      row.lable === "Total Extra Amount" ||
                        row.lable === "Less cash for fuel received by driver in advance"
                        ? { backgroundColor: "#fff", color: "#38B000", fontWeight: "700" }
                        : {}
                    }
                  >
                    {row.lable}
                  </td>
                  <td
                    style={
                      row.lable === "Total Extra Amount" ||
                        row.lable === "Less cash for fuel received by driver in advance" ||
                        row.lable === "Total Paid to Online"
                        ? { backgroundColor: "#fff", color: "#38B000", fontWeight: "500", fontWeight: "700", }
                        : {}
                    }
                  >
                    {row.value < 1 ? "0" : `${row.value}`}
                  </td>
                </tr>
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default FourthFare;
