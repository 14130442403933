import React, { useEffect, useState } from "react";
import { BiSolidCar } from "react-icons/bi";
import { FiUserCheck } from "react-icons/fi";
import {
  MdFlight,
  MdOutlineEdit,
  MdOutlinePermContactCalendar,
} from "react-icons/md";
import { PiCarProfileLight } from "react-icons/pi";
import { SlCalender, SlLocationPin } from "react-icons/sl";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../Loader";
import Interaction from "./addInteraction";
import "./Details.css";
import {
  fetchbillingDetailsAsync,
  fetchbookingDetailsAsync,
  selectbookingDetails,
} from "./DetailsSlice";
import Airport_Trip_Type_Popup from "./popups/Airport_Trip_Type_Popup";
import Local_Trip_Type_Popup from "./popups/Local_Trip_Type_Popup";
import Oustation_One_Way from "./popups/Outstation_One_Way";
import Outstation_Round_Trip from "./popups/Outstation_Round_Trip";
import Schedule from "../../assets/Schedule.svg";
import TripType from "../../assets/TripType.svg";
import PreferredVehicle from "../../assets/PreferredVehicle.svg";
import FareDetails from "../../assets/FareDetails.svg";
import Assignto from "../../assets/Assignto.svg";
import CreatedBy from "../../assets/CreatedBy.svg";
import LocationDetails from "../../assets/LocationDetails.svg";
import FlightDetails from "../../assets/FlightDetails.svg";


const TaskDetails = ({ item }) => {
  const navigate = useNavigate();

  const [popup, setPop] = useState(false);
  const handleClickopen = () => {
    setPop(!popup);
  };
  const closepopup = () => {
    setPop(false);
  };

  const dispatch = useDispatch();
  const { id } = useParams();
  console.log(id);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const bookingDetails = useSelector(selectbookingDetails); 
  const { driverData } = useSelector((state) => state.assignDrivers);
  const { cabData } = useSelector((state) => state.assignCabs);
  console.log("Booking details : ", bookingDetails);
  console.log("Booking details user : ", bookingDetails?.user);
  console.log("ADVANCE PAYMENT",bookingDetails?.paymentDetails?.[0]?.amountPaid);
  useEffect(() => {
    try {
      dispatch(fetchbookingDetailsAsync(id));   
      dispatch(fetchbillingDetailsAsync(id));
    } catch (error) {
      setError("Error fetching details. Please try again.");
    } finally {
      setLoading(false);
    }
  }, []);


  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleConfirmDetach = () => {
    handleDetachCabAndDriver(bookingDetails?.bookingId);
    handleCloseModal();
  };

  const handleDetachCabAndDriver = async (bookingId) => {
    try {
      // Retrieve the accessToken from the cookies
      // const accessToken = Cookies.get('accessToken');

      // if (!accessToken) {
      //   throw new Error('Access token not found in cookies');
      // }

      // console.log('Access Token found:', accessToken);
      const response = await fetch(
        `https://api.yatricabs.com/v1/admin/bookings/deassign-driver-car`,
        {
          method: "POST",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
            // 'Authorization': `Bearer ${accessToken}`, // Include the access token in the Authorization header
          },
          body: JSON.stringify({ bookingId }), // Pass the id in the request body
        }
      );

      if (!response.ok) {
        throw new Error(`Error: ${response.status} - ${response.statusText}`);
      }

      const data = await response.json();
      console.log("Response data:", data);
      // navigate(`/booking/${bookingId}/taskdetails`);
      alert(data.message);
      window.location.reload();
    } catch (error) {
      console.error("Error detaching cab and driver:", error);
      // Handle the error, e.g., display a notification or alert
    }
  };

  return (
    <div>
      {loading ? (
        <div className="loader1">
          <Loader />
        </div>
      ) : error ? (
        <p>Error: {error}</p>
      ) : (
        <div className="main-header-box">
          <div className="main-header">
            <div className="task">
              <div className="icon-booking">
                <img src={CreatedBy} alt="CreatedBy" className="icon_1" />
                Created By:
                <h7 style={{ marginLeft: "3rem", marginTop: "-0rem" }}>
                  {bookingDetails?.source}{" "}
                </h7>
              </div>
            </div>
            <div className="Line-booking"></div>
            <div className="form">
              <form>
                <div className="one">
                  <label>
                    Customer Name:
                    <h7>
                      {bookingDetails?.user?.firstName}{" "}
                      {bookingDetails?.user?.lastName}
                    </h7>
                  </label>
                  <label>
                    Mobile No.:
                    <h7>{bookingDetails?.user?.phone}</h7>
                  </label>
                  <label>
                    Atl Mobile No. :<h7>{bookingDetails?.user?.phone}</h7>
                  </label>
                  <br></br>
                  <br></br>
                  <label>
                    Pickup address:
                    <h7 title={bookingDetails?.pickupAddress}>
                      {bookingDetails?.pickupAddress.length > 95
                        ? `${bookingDetails?.pickupAddress.substring(0, 95)}...`
                        : bookingDetails?.pickupAddress}
                    </h7>
                  </label>
                </div>
                <div className="tow">
                  <label style={{ marginLeft: "1rem" }}>
                    Email address:
                    <h7>{bookingDetails?.user?.email}</h7>
                  </label>
                  <br />
                  <br />
                  <label style={{ textAlign: "center", marginLeft: "1rem" }}>
                    <img src={FlightDetails} alt="FlightDetails" className="icon_1" style={{ marginRight: "8px" }} />
                    Flight Details:
                    <h7>{bookingDetails?.flightDetails}</h7>
                  </label>
                </div>
              </form>
            </div>
          </div>

          {bookingDetails?.tariff?.tripType === "Local" ||
            bookingDetails?.tariff?.tripType === "Airport" ? (
            <div className="location">
              <div className="task" style={{ display: "flex", gap: "23vw", marginBottom: "-3px " }}>
                <div className="icon-booking">
                  <img src={LocationDetails} alt="LocationDetails" className="icon_1" />
                  Location Details:
                </div>
                <div className="icon-booking">
                  <img src={Schedule} alt="Schedule" className="icon_1" />
                  Schedule:
                </div>
              </div>

              <div className="Line-booking"></div>
              <div className="form" >
                <form >
                  <div className="box-booking-main-div" style={{ display: "flex", gap: "15.5vw" }}>
                    <label className="box-booking">
                      Pickup City:
                      <h7>
                        {bookingDetails?.location?.city},
                        {bookingDetails?.location?.state}
                      </h7>
                    </label>
                    <label className="box-booking">
                      Pickup Date:
                      <h7>{bookingDetails?.pickupDate}  </h7>
                    </label>
                  </div>
                </form>
              </div>
            </div>

            // OUTSTATION ONE WAY START

          ) : bookingDetails?.tariff?.tripType === "Outstation" &&
            bookingDetails?.tariff?.subTripType === "One Way" ? (
            <div
              className="location"
              style={{ display: "flex", flexDirection: "column" }}
            >
              <div className="icon-booking">
                <img src={LocationDetails} alt="LocationDetails" className="icon_1" />
                Location Details:
              </div>

              <div className="Line-booking"></div>

              <div className="form">
                <form>
                  <div className="box-booking-main-div">
                    <label className="box-booking">
                      Pickup City:
                      <h7>
                        {bookingDetails?.location?.city},{" "}
                        {bookingDetails?.location?.state}
                      </h7>
                    </label>
                    <label className="box-booking">
                      Destination Cities:
                      {bookingDetails?.destinationCities.map((cities) => (
                        <h7>{cities}</h7>
                      ))}
                    </label>
                  </div>
                </form>
              </div>
            </div>
          ) : (
            <div className="location">
              <div className="task" style={{ display: "flex", gap: "10vw" }}>
                <div className="icon-booking">
                  <img src={LocationDetails} alt="LocationDetails" className="icon_1" />
                  Location Details:
                </div>
              </div>
              <div className="Line-booking"></div>
              <div className="form">
                <form>
                  <div className="box-booking-main-div">
                    <label className="box-booking">
                      Pickup City:
                      <h7>
                        {bookingDetails?.location?.city},{" "}
                        {bookingDetails?.location?.state}
                      </h7>
                    </label>
                    <label className="box-booking">
                      Destination Cities:
                      {bookingDetails?.destinationCities.map((cities) => (
                        <h7>{cities}</h7>
                      ))}
                    </label>
                  </div>
                </form>
              </div>
            </div>
          )}

          {bookingDetails?.tariff?.tripType === "Local" ||
            bookingDetails?.tariff?.tripType === "Airport" ? (
            <></>
          ) : bookingDetails?.tariff?.tripType === "Outstation" &&
            bookingDetails?.tariff?.subTripType === "One Way" ? (
            <div className="Schedule">
              <div className="task">
                <div className="icon-booking">
                  <img src={Schedule} alt="Schedule" className="icon_1" />
                  Schedule:
                </div>
              </div>
              <div className="Line-booking"></div>
              <div className="form">
                <form>
                  <div className="box-booking-main-div">
                    <label className="box-line-booking">
                      Start Date:
                      <h7>{bookingDetails?.pickupDate} </h7>
                    </label>

                    <label className="box-line-booking">
                      <div className="end-date">End Date:</div>
                      <div className="end-date">
                        <h7>{bookingDetails?.returnDate === "undefined" ? bookingDetails?.pickupDate : bookingDetails?.returnDate} </h7>
                      </div>
                    </label>

                    <label className="box-line-booking">
                      <div className="no-of-days">No.of Days:</div>
                      <div className="no-of-days">
                        <h7>{bookingDetails?.noOfDays} </h7>
                      </div>
                    </label>
                  </div>
                </form>
              </div>
            </div>
          ) : (
            <div className="Schedule">
              <div className="task">
                <div className="icon-booking">
                  <img src={Schedule} alt="Schedule" className="icon_1" />
                  Schedule:
                </div>
              </div>
              <div className="Line-booking"></div>
              <div className="form">
                <form>
                  <div className="box-booking-main-div">
                    <label className="box-line-booking">
                      Start Date:
                      <h7>{bookingDetails?.pickupDate} </h7>
                    </label>

                    <label className="box-line-booking">
                      <div className="end-date">End Date:</div>
                      <div className="end-date">
                        <h7>{bookingDetails?.returnDate === "undefined" ? bookingDetails?.pickupDate : bookingDetails?.returnDate} </h7>
                      </div>
                    </label>

                    <label className="box-line-booking">
                      <div className="no-of-days"> No.of Days:</div>
                      <div className="no-of-days">
                        <h7>{bookingDetails?.noOfDays} </h7>
                      </div>
                    </label>
                  </div>
                </form>
              </div>
            </div>
          )}

          <div className="Trip-Type">
            <div className="task">
              <div
                style={{ display: "flex", flexDirection: "row", marginTop: "3px " }}
              >
                <div className="icon-booking">
                  <img src={TripType} alt="Trip-Type" className="icon_1" />
                  Trip Type:
                </div>
                <div className="icon-booking" style={{ marginLeft: "15rem" }}>
                  <img src={PreferredVehicle} alt="PreferredVehicle" className="icon_1" />
                  Preferred Vehicle:
                </div>

                <div className="icon-booking" style={{ marginLeft: "15rem" }}>GSTIN</div>

              </div>
            </div>
            <div className="Line-booking"></div>
            <div className="form">
              <form style={{ display: "flex", gap: "6rem" }}>
                <label>
                  {bookingDetails?.tariff?.tripType}:
                  <h7 style={{ marginLeft: "4rem" }}>
                    {bookingDetails?.tariff?.subTripType}
                  </h7>
                </label>

                <label>
                  <h7>{bookingDetails?.carCategory?.category}</h7>
                </label>

                <label>
                  <h7 style={{ paddingLeft: "6rem" }}>{bookingDetails?.gstNumber}</h7>
                </label>

              </form>
            </div>
          </div>

          {/* OUTSTATION ONE WAY End */}

          {/* OUTSTATION Round Trip Start */}

          {bookingDetails?.tariff?.tripType === "Outstation" ? (
            bookingDetails?.tariff?.subTripType === "Round Trip" ? (
              <div className="fare">

                <div className="task">
                  <div className="icon-booking">
                    <img src={FareDetails} alt="FareDetails" className="icon_2" />
                    Fare Details:

                    <div className="estimated-1">
                      <h7>
                        Estimated Fare:
                        <span style={{ fontWeight: "600" }}>
                          Rs. {bookingDetails?.estimatedFare}
                        </span>
                      </h7>
                    </div>

                    {/* popup start */}
                    <div className="breakup-1">
                      <h8 onClick={handleClickopen}>Fare Breakup</h8>{" "}
                      <div>
                        <div>
                          {popup ? (
                            <Outstation_Round_Trip
                              bookingDetails={bookingDetails}
                              closepopup={closepopup}
                            />
                          ) : (
                            ""
                          )}
                        </div>
                        {/* popup close */}
                        <div className="ad-pay-container-1">Advance Payment
                          <span className="ad-pay-1">
                            ({bookingDetails?.paymentDetails?.[0]?.partialPaymentPercentage ??
                              0}%) : Rs.
                            { bookingDetails?.paymentDetails?.[0]?.amountPaid ??
                              0}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="Line-booking"></div>
                <div className="form" style={{ padding: "0px" }}>

                  <form style={{ display: "flex", flexDirection: "row" }}>
                    <label style={{
                      color: "rgba(56, 175, 0, 1)", display: "flex",
                      flexDdirection: "row",
                      gap: "16px ", marginTop: "16px"
                    }}>
                      Inclusion:

                      <div >
                        <h7>
                          KM Covered: {bookingDetails?.approxDistance} km
                        </h7>
                      </div>


                      <div >
                        <h7>
                          DA: Rs.
                          {
                            bookingDetails?.tariff?.fareDetails
                              ?.nightDriverAllowance
                          }{" "}
                        </h7>
                      </div>

                      <div >
                        <h7>
                          GST@{bookingDetails?.tariff?.fareDetails?.gstRate}%:Rs. {" "}
                          {bookingDetails?.gstOnEstimatedFare}
                        </h7>
                      </div>
                    </label>

                    <label
                      style={{
                        color: "rgba(56, 175, 0, 1)",
                        display: "flex",
                        marginTop: "5px",
                      }}
                    >

                      <div className="line"></div>
                      <div className="exclu-1">Exclusion:</div>
                      <div
                        style={{
                          display: "flex",
                          gap: "0.7rem",
                          paddingLeft: "1rem",
                        }}
                      >
                        <div
                          style={{ display: "flex", flexDirection: "row" }}
                        >
                          <div className="extRs-1">
                            <h7 >
                              Extra Km@ Rs.
                              {bookingDetails?.tariff?.fareDetails?.extraKmCharge}
                              /Km
                            </h7>
                          </div>
                        </div>


                        <div
                          style={{ display: "flex", flexDirection: "row" }}
                        >

                          <div className="nightChar-1">
                            <h7 style={{ margin: "5px" }}>
                              Night Charge: Rs.
                              {
                                bookingDetails?.tariff?.fareDetails
                                  .nightDriverAllowance
                              }
                              /day
                            </h7>
                          </div>

                          <div className="gstOnEx-1">
                            <h7 style={{ margin: "5px" }}>
                              GST on Extras@
                              {bookingDetails?.tariff?.fareDetails?.gstRate}%
                            </h7>
                          </div>

                        </div>
                      </div>
                    </label>
                  </form>
                </div>
              </div>
            ) : (
              <>
                <div className="fare">
                  <div className="task">
                    <div className="icon-booking" style={{ marginBottom: "-18px" }}>
                      <div className="icon-booking">
                        <img src={FareDetails} alt="FareDetails" className="icon_2" />
                        Fare Details:
                      </div>

                      <div className="estimated-2">
                        <h7 >
                          <span style={{ fontWeight: "600", color: "#6D6D6D" }}> Estimated Fare: Rs.
                            {bookingDetails?.estimatedFare}{" "}
                          </span>
                        </h7>
                      </div>

                      {/* popup start */}
                      <h8 onClick={handleClickopen}>Fare Breakup</h8>
                      <div>
                        {popup ? (
                          <Oustation_One_Way
                            bookingDetails={bookingDetails}
                            closepopup={closepopup}
                          />
                        ) : (
                          ""
                        )}
                      </div>
                      {/* popup close */} <div className="ad-pay-container-2">
                        Advance Payment Received
                        <span className="ad-pay">
                          {" "}
                          : Rs.
                          {bookingDetails?.paymentDetails?.[0]?.amountPaid ?? 0}{" "}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="Line-booking"></div>
                  <div className="form" style={{ padding: "0px" }}>

                    <form style={{ display: "flex", flexDirection: "row" }}>
                      <label style={{
                        color: "rgba(56, 175, 0, 1)", display: "flex",
                        flexDdirection: "row",
                        gap: "16px "
                      }}>
                        Inclusion:

                        <div >
                          <h7>
                            KM Covered: {bookingDetails?.approxDistance}km
                          </h7>
                        </div>

                        <div >
                          <h7>
                            DA:Rs.
                            {
                              bookingDetails?.tariff?.fareDetails
                                .nightDriverAllowance
                            }
                          </h7>
                        </div>

                        <div >
                          <h7>
                            GST@{bookingDetails?.tariff?.fareDetails?.gstRate}
                            %:Rs. {bookingDetails?.gstOnEstimatedFare}
                          </h7>
                        </div>
                      </label>

                      <label
                        style={{
                          color: "rgba(56, 175, 0, 1)",
                          display: "flex",
                          marginTop: "5px",
                        }}
                      >

                        <div className="line"></div>
                        <div className="exclu-1">  Exclusion: </div>
                        <div
                          style={{
                            display: "flex",
                            gap: "0.7rem",
                            paddingLeft: "1rem",
                          }}
                        >
                          <div
                            style={{ display: "flex", flexDirection: "row" }}
                          >

                            <div className="extRs-1">
                              <h7>
                                Extra Km@ Rs.{" "}
                                {
                                  bookingDetails?.tariff?.fareDetails
                                    ?.extraKmCharge
                                }
                                /Km
                              </h7>
                            </div>
                          </div>

                          <div
                            style={{ display: "flex", flexDirection: "row" }}
                          >

                            <div className="nightChar-1">
                              <h7 style={{ margin: "5px" }}>

                                Night Charge: Rs.
                                {
                                  bookingDetails?.tariff?.fareDetails
                                    .nightDriverAllowance
                                }
                                /day
                              </h7>
                            </div>

                            <div className="gstOnEx-1">
                              <h7 style={{ margin: "5px" }}>
                                GST on Extras@
                                {bookingDetails?.tariff?.fareDetails?.gstRate}%
                              </h7>
                            </div>
                          </div>
                        </div>
                      </label>
                    </form>
                  </div>
                </div>
              </>
            )
          ) : (
            ""
          )}

          {/* OUTSTATION Round Trip  End */}

          {/* Local START */}

          {bookingDetails?.tariff?.tripType === "Local" ? (
            <div className="fare" style={{ height: "110px" }}>
              <div className="task">
                <div className="icon-booking" style={{ marginBottom: "-18px" }}>
                  <img src={FareDetails} alt="FareDetails" className="icon_2" />
                  Fare Details:

                  <div className="estimated-3">
                    <h7>
                      <span style={{ fontWeight: "600", color: "#6D6D6D" }}> Estimated Fare: Rs. {bookingDetails?.estimatedFare}
                      </span>
                    </h7>
                  </div>

                  <div>
                    <h8 onClick={handleClickopen}>Fare Breakup</h8>
                    {/* popup starts */}
                  </div>

                  <div>
                    {popup ? (
                      <Local_Trip_Type_Popup
                        bookingDetails={bookingDetails}
                        closepopup={closepopup}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                  {/* popup close */}
                  Advance Payment Received
                  <span className="ad-pay">
                     : Rs.
                    {bookingDetails?.paymentDetails?.[0]?.amountPaid ?? 0}{" "}
                  </span>
                </div>
              </div>

              <div className="Line-booking"></div>
              <div className="form" style={{ padding: "0px" }}>
                <form style={{ display: "flex", flexDirection: "row" }}>
                  <label style={{
                    color: "rgba(56, 175, 0, 1)", display: "flex",
                    flexDdirection: "row",
                    gap: "16px "
                  }}>
                    Inclusion:

                    <div >
                      <h7>
                        KM Include: {bookingDetails?.tariff?.fareDetails?.minKm}km
                      </h7>
                    </div>

                    <div >
                      <h7>
                        Hrs Include : { bookingDetails?.tariff?.fareDetails?.minHrs} hrs{" "}
                      </h7>
                    </div>

                    <div >
                      <h7>
                        GST @ {bookingDetails?.tariff?.fareDetails?.gstRate}%
                      </h7>
                    </div>

                  </label>

                  <label
                    style={{
                      color: "rgba(56, 175, 0, 1)",
                      display: "flex",
                      marginTop: "5px",
                    }}
                  >

                    <div className="line-1"></div>
                    <div className="exclu-3"> Exclusion:</div>
                    <div
                      style={{
                        display: "flex",
                        gap: "2.7rem",
                        paddingLeft: "1rem",
                      }}
                    >
                      <div style={{ display: "flex", flexDirection: "column" }}>



                        <div className="extRs-2">
                          <h7 >
                            Extra Km@ Rs.{" "}
                            {bookingDetails?.tariff?.fareDetails?.extraKmCharge}
                            /Km
                          </h7>
                        </div>

                        <div className="extRs-2">
                          <h7 >
                            GST on Extras @
                            {bookingDetails?.tariff?.fareDetails?.gstRate}%
                          </h7>
                        </div>

                      </div>
                      <div style={{ display: "flex", flexDirection: "column" }}>

                        <div className="extRs-3">
                          <h7 >
                            Extra hrs@ Rs.{" "}
                            {bookingDetails?.tariff?.fareDetails?.extraHrsCharge}
                            /Km
                          </h7>
                        </div>

                        <div className="extRs-3">
                          <h7>

                            Night Charge: Rs.
                            {
                              bookingDetails?.tariff?.fareDetails
                                .nightDriverAllowance
                            }
                            /day
                          </h7>
                        </div>




                      </div>
                    </div>
                  </label>
                </form>
              </div>
            </div>
          ) : (
            <></>
          )}

          {/* Local End */}

          {/* Airport Start  */}

          {bookingDetails?.tariff?.tripType === "Airport" ? (
            <div className="fare" style={{ height: "110px" }}>
              <div className="task">
                <div className="icon-booking" style={{ marginBottom: "-18px" }}>
                  <img src={FareDetails} alt="FareDetails" className="icon_2" />
                  Fare Details:

                  <div className="estimated-3">
                    <h7>
                      <span style={{ fontWeight: "600", color: "#6D6D6D" }}>  Estimated Fare: Rs.
                        {bookingDetails?.estimatedFare}
                      </span>
                    </h7>
                  </div>


                  <div>
                    <h8 onClick={handleClickopen}>Fare Breakup</h8>
                    {/* popup starts */}
                  </div>

                  <div>
                    {popup ? (
                      <Airport_Trip_Type_Popup
                        bookingDetails={bookingDetails}
                        closepopup={closepopup}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                  {/* popup close */}
                  Advance Payment : Rs.
                  <span className="ad-pay">
                    {bookingDetails?.paymentDetails?.[0]?.amountPaid ?? 0}
                  </span>
                </div>
              </div>
              <div className="Line-booking"></div>
              <div className="form" style={{ padding: "0px" }}>
                <form style={{ display: "flex", flexDirection: "row" }}>
                  <label style={{
                    color: "rgba(56, 175, 0, 1)", display: "flex",
                    flexDdirection: "row",
                    gap: "16px "
                  }}>
                    Inclusion:

                    <div >
                      <h7>
                        KM Include: {bookingDetails?.tariff?.fareDetails?.minKm}km
                      </h7>
                    </div>

                    <div >
                      <h7>
                        {" "}
                        Hrs Include :{" "}
                        {bookingDetails?.tariff?.fareDetails?.minHrs} hrs{" "}
                      </h7>
                    </div>


                    <div >
                      <h7>
                        GST @ {bookingDetails?.tariff?.fareDetails?.gstRate}%
                      </h7>
                    </div>

                  </label>

                  <label
                    style={{
                      color: "rgba(56, 175, 0, 1)",
                      display: "flex",
                      marginTop: "5px",
                    }}
                  >


                    <div className="line-1"></div>
                    <div className="exclu-3"> Exclusion:</div>
                    <div
                      style={{
                        display: "flex",
                        gap: "2.7rem",
                        paddingLeft: "1rem",
                      }}
                    >
                      <div style={{ display: "flex", flexDirection: "column" }}>


                        <div className="extRs-2">
                          <h7>
                            Extra Km@ Rs.{" "}
                            {bookingDetails?.tariff?.fareDetails?.extraKmCharge}
                            /Km
                          </h7>
                        </div>

                        <div className="extRs-2">
                          <h7>
                            GST on Extras@
                            {bookingDetails?.tariff?.fareDetails?.gstRate}%
                          </h7>
                        </div>


                      </div>
                      <div style={{ display: "flex", flexDirection: "column" }}>

                        <div className="extRs-3">
                          <h7>
                            Extra hrs@ Rs.{" "}
                            {bookingDetails?.tariff?.fareDetails?.extraHrsCharge}
                            /Km
                          </h7>
                        </div>

                        <div className="extRs-3">
                          <h7>
                            Night Charge:Rs.
                            {
                              bookingDetails?.tariff?.fareDetails
                                .nightDriverAllowance
                            }
                            /day
                          </h7>
                        </div>

                      </div>
                    </div>
                  </label>
                </form>
              </div>
            </div>
          ) : (
            <></>
          )}

          {/* Airport End */}

          <div className="Assign">
            <div className="task">
              <div className="icon-booking">
                <img src={Assignto} alt="Assignto" className="icon_3" />
                Assign to: <span>Details</span>
                {bookingDetails?.driver?.firstName && (
                  <button
                    className="detach-cab-driver"
                    onClick={handleOpenModal}
                  >
                    Detach Driver & Cab
                  </button>
                )}
              </div>

              {isModalOpen && (
                <div className="modal-overlay">
                  <div className="modal">
                    <p>Are you sure you want to detach the driver and cab?</p>
                    <button className="confirm-button" onClick={handleConfirmDetach}>
                      Yes
                    </button>
                    <button className="cancel-button" onClick={handleCloseModal}>
                      Cancel
                    </button>
                  </div>
                </div>
              )}
            </div>
            <div className="Line-booking"></div>
            <div className="form">
              <form style={{ transform: "translateX(-27px)" }}>
                <label style={{fontWeight:"600"}}>
                  Drive Name:{" "}
                  <h7>
                    {bookingDetails?.driver?.firstName}{" "}
                    {bookingDetails?.driver?.lastName || "Not Assigned"}
                  </h7>
                </label>
                <label>
                  Mobile no.{" "}
                  <h7>{bookingDetails?.driver?.phone || "Not Assigned"}</h7>
                </label>
                <label>
                  Cab Model:
                  <h7>{bookingDetails?.car?.carModel || "Not Assigned"}</h7>
                  {/* {cabData ? cabData["Cab Model"] : "Not Assigned"} */}
                </label>
                <label>
                  Cab colour:
                  <h7>{bookingDetails?.car?.carColor || "Not Assigned"}</h7>
                </label>
                <label>
                  Cab No:
                  <h7>
                    {bookingDetails?.car?.registrationCertificate.number ||
                      "Not Assigned"}
                  </h7>
                </label>
              </form>
            </div>
          </div>
          <div className="comments">
            <div
              className="task"
              style={{ color: "rgba(56, 175, 0, 1)", marginLeft: "2.50rem" }}
            >
              Comments From Customer:
            </div>
            <div className="Line-booking"></div>
            <div className="form">
              <form style={{ transform: "translateX(-29px)" }}>
                <label>
                  <h7>{bookingDetails?.comment || "No comments"}</h7>
                </label>
              </form>
            </div>
          </div>

          <Interaction />
        </div>
      )}
    </div>
  );
};

export default TaskDetails;
