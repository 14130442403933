import React, { useState } from "react";
import { IoMdClose } from "react-icons/io";
import PopUptable from "./popuptable/PopUptable";
import "./bookinghistorypopup.css";
import logo from "../../../assets/logo.png";
// write a code for addition
const BookingHistoryPopUp = ({
  popUpBookingHistory,
  setPopUpBookingHistory,
  driverId,
}) => {
  const handleShowButton = () => {
    setPopUpBookingHistory(false);
  };

  return (
    <div
      className={
        popUpBookingHistory ? "pop_up_main_container" : "noshowbookinghistory"
      }
    >
      <div className="pop_up_sub_container">
        <div className="background_white">
          <img src={logo} alt="" />
          <div className="pop_up_content">
            <p>Booking History</p>
            <div className="pop_up_table">
              <PopUptable driverId={driverId} />
            </div>
          </div>
        </div>

        <IoMdClose onClick={handleShowButton} />
      </div>
    </div>
  );
};

export default BookingHistoryPopUp;
