import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ImEye } from "react-icons/im";
import "../DriverManagement/ViewAllCar.css";
import { FaPen } from "react-icons/fa";
import Filter from "../Booking/Filter";
import DatePicker from "react-datepicker";
import InputColumn from "../Booking/Input";

import {
  fetchCustomer,
  selectAllCustomer,
  selectLoading,
  setNewValue,
} from "./CustomerSlice";
import { Toggle } from "../TariffManagement/Toggle";
import axios from "axios";
import { toggleCustomerStatus } from "./CustomerSlice";
import { resetCustomerDetails } from "./CustomerDetailsSclice";
import { useDispatch, useSelector } from "react-redux";
import { format } from "date-fns";
import { CiFilter } from "react-icons/ci";
import { IoSearch } from "react-icons/io5";
import { FaFilePdf } from "react-icons/fa6";
import { FaFileExcel } from "react-icons/fa";
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const TableItem = ({ item, index }) => {
  const rowClass = index % 2 === 0 ? "table-row-even" : "table-row-odd";

  return (
    <tr className={rowClass}>
      <td>{item["Sr No"]}</td>
      <td>{item["Customer Name"]}</td>
      <td>{item["Contact Number"]}</td>
      <td>{item["Alternate Number"]}</td>
      <td>{item["Email"]}</td>
      <td>{item["Registered On"]}</td>
      <td>{item["Status"]}</td>
      <td>{item["Booking History"]}</td>
      <td>{item["Action"]}</td>
    </tr>
  );
};

const Table = ({ headers, data, setShowCustomerDetails }) => {
  return (
    <div className="table-containers">
      <div className="table-scroll">
        <table className="table" style={{ padding: "10px" }}>
          <thead>
            <tr>
              {headers.map((header, index) => (
                <th
                  key={index}
                  className={
                    index === headers.length
                      ? "no-left-border"
                      : "with-left-border"
                  }
                >
                  {header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <TableItem
                key={index}
                index={index}
                item={item}
                setShowCustomerDetails={setShowCustomerDetails}
              />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const ViewAllCustomer = () => {
  const [searchPhoneNumber, setsearchPhoneNumber] = useState("");
  const [searchName, setsearchName] = useState("");
  const [searchEmail, setsearchEmail] = useState("");
  const [showCustomerDetails, setShowCustomerDetails] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const currentSection = "View-All-Customer";
  const [showDetails, setShowDetails] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const customerData = useSelector(selectAllCustomer);

  const loader = useSelector(selectLoading);

  console.log("checking loader", loader);

  const calenderKhol = (e) => {
    if (e?.target?.showPicker) {
      e.target.showPicker();
    }
  };

  useEffect(() => {
    dispatch(fetchCustomer());
    dispatch(resetCustomerDetails());
  }, [dispatch]);

  const handleImEyeClick = (id) => {
    dispatch(setNewValue(`/customer/${currentSection}/details/${id}`));
    navigate(`/customer/${currentSection}/details/${id}`);
  };

  const handleEditButton = (customerId) => {
    navigate(`/customer/${currentSection}/edit-details/${customerId}`);
  };

  const handleCustomerStatus = async (customerId, newStatus, dispatch) => {
    try {
      const response = await axios.put(
       `${API_BASE_URL}/v1/admin/users/${customerId}/status`,
        {
          status: newStatus,
        }
      );
      console.log("Response:", response.data);
      dispatch(toggleCustomerStatus({ customerId, newStatus }));
      dispatch(fetchCustomer());
    } catch (error) {
      console.error("Error toggling customer status:", error);
    }
  };

  const logState = (state, customerId, dispatch) => {
    const newStatus = state === true ? "Inactive" : "Active";
    handleCustomerStatus(customerId, newStatus, dispatch);
  };
  const exportToPDF = () => {
    const { jsPDF } = require("jspdf");
    require("jspdf-autotable");

    const doc = new jsPDF();

    // Include only columns till "Status"
    const selectedHeaders = headers.slice(0, headers.indexOf("Status") + 1); // Headers up to "Status"
    const tableHeaders = selectedHeaders.map((header) => header.trim());

    const tableRows = CostumerList.map((item) =>
      selectedHeaders.map((header) => item[header] || "")
    );

    doc.text("Customer Data", 14, 10); // Title
    doc.autoTable({
      head: [tableHeaders],
      body: tableRows,
      startY: 20,
    });

    doc.save("CustomerData.pdf");
  };

  const exportToExcel = () => {
    const XLSX = require("xlsx");
  
    // Include only columns till "Status"
    const selectedHeaders = headers.slice(0, headers.indexOf("Status") + 1); // Headers up to "Status"
  
    // Create a workbook and worksheet
    const workbook = XLSX.utils.book_new();
    const worksheetData = [
      selectedHeaders, // Add filtered headers as the first row
      ...CostumerList.map((item) =>
        selectedHeaders.map((header) => item[header] || "")
      ),
    ];
    const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
  
    // Add worksheet to workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, "Customer Data");
  
    // Export to Excel file
    XLSX.writeFile(workbook, "CustomerData.xlsx");
  };
  

  const headers = [
    "Sr No",
    "Customer Name",
    "Contact Number",
    "Alternate Number ",
    "Email",
    "Registered On",
    "Status",
    "Booking History",
    "Action",
  ];

  const handleDateChange = (date) => {
    setSelectedDate(date);
    console.log(date);
  };

  const formatDateDDMMYYYY = (date) => format(date, "dd-MM-yyyy");

  const filteredCustomer = customerData.filter((customer) => {
    const selectedDateFormatted = selectedDate
      ? formatDateDDMMYYYY(selectedDate)
      : null;
    return (
      (!searchName ||
        customer.firstName.toLowerCase().includes(searchName) ||
        customer.lastName.toLowerCase().includes(searchName)) &&
      (!searchPhoneNumber || customer.phone.includes(searchPhoneNumber)) &&
      (!searchEmail ||
        customer.email.toLowerCase().includes(searchEmail.toLowerCase())) &&
      (!selectedDateFormatted || customer.createdAt === selectedDateFormatted)
    );
  });

  const data = [];

  for (let i = 0; i < filteredCustomer.length; i++) {
    const customer = filteredCustomer[i];

    data.push({
      "Sr No": i + 1,
      "Customer Name": `${customer.firstName} ${customer.lastName}`,
      "Contact Number": customer.phone,
      "Alternate Number": customer.phone,
      Email: customer.email,
      "Registered On": customer.createdAt,
      Status: customer.status,
      "Booking History": (
        <button
          onClick={() => handleImEyeClick(customer._id)}
          className="button"
        >
          Show
        </button>
      ),
      Action: (
        <div className="box-action">
          <Toggle
            toggled={customer.status === "Active" ? false : true}
            onClick={(state) => logState(state, customer._id, dispatch)}
          />
          <span className="icon-margin"></span>
          <ImEye
            className="icon icon-large"
            onClick={() => handleImEyeClick(customer._id)}
          />
          {/* <span className="icon-margin"></span> */}
          <FaPen
            className="icon-edit "
            onClick={() => handleEditButton(customer._id)}
          />
        </div>
      ),
    });
  }

  const CostumerList = data;

  return (
    <div>
      <>
        <div className="dropdown-container-customer">
          {/* <Filter /> */}
          {/* <div className="main-1">
            <div>Filters</div>
            <div className="icon-1">
              <CiFilter color="#38b000" size={18} />
            </div>
          </div> */}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "177px",
              height: "28px",
              flexShrink: "0",
              backgroundColor: "#FFF",
              borderRadius: "6px",
              boxShadow: "0px 4px 22px 0px rgba(0, 0, 0, 0.17)",
              marginLeft: "5px",
            }}
          >
            <IoSearch
              style={{
                marginRight: "10px",
                fontSize: "20px",
                color: "#ABABAB",
              }}
            />
            <input
              style={{
                border: "none",
                outline: "none",
                flex: "1",
                fontSize: "10px",
                fontFamily: "Inter, sans-serif",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "normal",
                backgroundColor: "transparent",
                color: "#000", // Darker color for normal text
              }}
              placeholder="Search with id ,Number..."
              value={searchName}
              onChange={(e) => setsearchName(e.target.value)}
            />
            <style>
              {`
      input::placeholder {
        color: #ABABAB;
        font-family: 'Inter', sans-serif;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    `}
            </style>
          </div>
          <div className="Export-buttons">
            <span>Export as</span>

            <button
              onClick={exportToExcel}
              className="icon-button"
              title="Export as Excel"
            >
              <FaFileExcel size={24} color="green" />
            </button>
            <button
              onClick={exportToPDF}
              className="icon-button"
              title="Export as PDF"
            >
              <FaFilePdf size={24} color="red" />
            </button>
          </div>
        </div>
        <Table
          headers={headers}
          data={CostumerList.map((item) => ({
            ...item,
          }))}
          setShowCustomerDetails={setShowCustomerDetails}
        />
      </>
    </div>
  );
};

export default ViewAllCustomer;
