import React, { useEffect, useState } from "react";
import axios from "axios";
import Loader from "../Loader";
import "./AssignDriverData.css";
import { useParams, useNavigate } from "react-router-dom";

import { FaFilter } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import DropdownFilter from "../dropdown/DropdownFilter";
import {
  fetchDriverData,
  assignDriver,
  updateDriverStatus,
} from "./assignDriverSlice";
import { current } from "@reduxjs/toolkit";

function AssignDriverData({ handleDriverState }) {
  const navigate = useNavigate();
  const [apiData, setApiData] = useState([]);
  const [searchName, setSearchName] = useState("");
  const [searchContact, setSearchContact] = useState("");
  const [filterOptionsState, setFilterOptionsState] = useState([]);
  const [selectedState, setSelectedState] = useState("");
  const [cities, setCities] = useState([]);
  const [filterValues, setFilterValues] = useState({});
  const [cabNumber, setCabNumber] = useState("");
  const [componentKey, setComponentKey] = useState(0)
  const driversDetail = [];
  const filterLocations = [];
  const [labels, setLabels] = useState([]);

  const [initialDriverData, setInitialDriverData] = useState([]);
  const dispatch = useDispatch();
  const { allDrivers } = useSelector((state) => state.assignDrivers);
  const { id } = useParams();

  const headerColumns = [
    "Driver Name",
    "Contact No.",
    "Location",
    "Sourcing Type",
    "Language Spoken",
    "Availability Status",
    "Driver License No.",
    "",
  ];

  let filterOptions = [
    {
      label: "Pick Up State",
      values: null,
    },
    {
      label: "Pick Up City",
      values: null,
    },
    {
      label: "Language Spoken",
      values: ["Hindi", "English", "English, Hindi"],
    },
    {
      label: "Sourcing Type",
      values: ["Outsourced", "Company Owned"],
    },
  ];

  let filterAvailability = {
    label: "Availability",
    values: ["Available", "Not Available"],
  };

  const handleSelectedData = (driverId) => {
    navigate(`/booking/${id}/assign/driver`);
    const currentAssignDriver = initialDriverData.find(
      (driver) => driver.id === driverId
    );
    handleDriverState(id, driverId);
    dispatch(assignDriver(currentAssignDriver));
    dispatch(updateDriverStatus({ driverId, status: false }));
  };

  const TableHeaders = ({ headers }) => {
    return (
      <thead>
        <tr className="table-header-row">
          {headers.map((header, index) => (
            <th key={index}>
              <div className="table-header"> {header}</div>
            </th>
          ))}
        </tr>
      </thead>
    );
  };
console.log("all drivers in assign driver", allDrivers)


  const TableBody = ({ entries, headers }) => {
    return (
      <tbody>
        {entries.map((driver, index) => (
          <tr className="table-data-row" key={index}>
            {headers.map((header) => (
              <td className="table-data" key={header}>
                {header !== "" ? (
                  driver[header]
                ) : (
                  <button
                    className={`select-button ${
                      driver["Availability Status"] !== "Available"
                        ? "disabled-select-button"
                        : ""
                    }`}
                    onClick={() => handleSelectedData(driver["id"])}
                    disabled={driver["Availability Status"] !== "Available"}
                  >
                    Select
                  </button>
                )}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    );
  };
  
  
  useEffect(() => {
    if (allDrivers?.data !== null) {
      const drivers = allDrivers?.data?.data.map((driver) => ({
        id: driver?._id,
        "Driver Name": driver?.firstName + " " + driver?.lastName,
        "Contact No.": driver?.phone,
        "Language Spoken":
          driver?.languages.length !== 1
            ? driver?.languages[0] + ", " + driver?.languages[1]
            : driver?.languages[0],
        "Sourcing Type": driver?.sourcing,
        "Availability Status":
          driver?.availability === "Available" ? "Available" : "Not Available",
        "Driver License No.": driver?.license?.number,
        Location: driver?.location
          ? driver?.location?.city + ", " + driver?.location?.state
          : driver?.location,
      }));

      setApiData(drivers);
      setInitialDriverData(drivers);
    }
  }, [allDrivers]);

  

  useEffect(() => {
    fetchLocations();
  }, []);

 

  
  useEffect(() => {
    applyFilters();
  }, [searchName, searchContact, filterValues]);

 
  const applyFilters = () => {
    const filteredData = initialDriverData.filter((driver) => {
      // Check search by name
      const matchesSearchName = driver["Driver Name"]
        .toLowerCase()
        .startsWith(searchName.toLowerCase());
  
      // Check search by contact number
      const contactNumber = driver["Contact No."];
      const normalizedContact = contactNumber.startsWith("+91-")
        ? contactNumber.slice(4) // Ignore the "+91-" part
        : contactNumber;
      const matchesSearchContact = normalizedContact
        .toLowerCase()
        .startsWith(searchContact.toLowerCase());
  
      // Check all filter values
      const matchesFilterValues = Object.keys(filterValues).every((key) => {
        if (key === "Pick Up City") {
          // Match city part of 'Location'
          const [driverCity] = driver["Location"]?.split(",").map(part => part.trim()) || [];
          return driverCity?.toLowerCase() === filterValues[key].toLowerCase();
        } else if (key === "Pick Up State") {
          // Match state part of 'Location'
          const [, driverState] = driver["Location"]?.split(",").map(part => part.trim()) || [];
          return driverState?.toLowerCase() === filterValues[key].toLowerCase();
        } else if (key === "Availability") {
          // Match availability status, case-insensitive
          return driver["Availability Status"]?.toLowerCase() === filterValues[key].toLowerCase();
        } else if (key === "Language Spoken") {
          // Match language spoken
          return driver["Language Spoken"]?.includes(filterValues[key]);
        } else {
          // Generic case for other filters
          return driver[key] === filterValues[key];
        }
      });
  
      // Combine search filters with other filter conditions
      return matchesSearchName && matchesSearchContact && matchesFilterValues;
    });
  
    setApiData(filteredData);
  };
  
  
  console.log("initial driver data",initialDriverData)
console.log("filter values",filterValues)


  const fetchLocations = async () => {
    try {
      const response = await axios.get("https://api.yatricabs.com/v1/locations");
      const locations = response.data.data.map((value) => {
        // Split the location string by comma
        const [city, state] = value.location
          .split(",")
          .map((item) => item.trim());
        return { city, state }; // Return an object with city and state
      });

      // Extract unique state names for the filter options
      const stateNames = [
        ...new Set(locations.map((location) => location.state)),
      ];

      const updatedFilterOptions = filterOptions.map((temp) => {
        if (temp.label === "Pick Up State") {
          temp.values = stateNames; // Use unique state names for filter options
        }
         else if (temp.label === "Pick Up City") {
          temp.values = []; // Initialize as empty or default message
        }
        return temp;
      });

      setFilterOptionsState(updatedFilterOptions);
    } catch (error) {
      console.error("Failed to fetch locations.");
    }
  };

  useEffect(() => {
  if (filterOptionsState) {
const newData=initialDriverData.filter((driver) => {
  const result = labels.every((key) => {
    if (driver[key] !== null && filterValues[key] !== undefined) {
      if (key === "Pick Up State" || key === "Pick Up City") {
        const state = filterValues["Pick Up State"];
        const city = filterValues["Pick Up City"];

        // Driver's location is stored in 'Location' field in the format: 'City, State'
        const driverLocation = driver["Location"];
        const [driverCity, driverState] = driverLocation
          ? driverLocation.split(",").map((part) => part.trim()) // Split into city and state and remove extra spaces
          : [];

        // Compare the city and state individually
        if (key === "Pick Up State") {
          return driverState && driverState.toLowerCase() === state.toLowerCase();
        } else if (key === "Pick Up City") {
          return driverCity && driverCity.toLowerCase() === city.toLowerCase();
        }
        
      }
      else if(key==="Availability"){
        return driver["Availability Status"]?.toLowerCase() === filterValues[key].toLowerCase();

      }
      else {
        // Regular filtering for other fields (like 'Cab Type')
        return driver[key]?.includes(filterValues[key]);
      }
    }
      return true;
    
  })
  return result;

})
setApiData([...newData]);

  }

},[ labels, filterValues, initialDriverData])
  

 
  const ResetFilters = () => {
    // Perform the necessary cleanup actions before reload (if needed)
    setFilterValues({});
    setSearchName("");
    setSearchContact("");
    
    // Full page reload
    window.location.reload();
  };
  


 
  const globalSelectedOption = async (value, label) => {
    let temp = { ...filterValues, [label]: value };
  
    if (value === "") {
      delete temp[label]; // Remove empty filters
    }
  
    setFilterValues(temp);
  
    if (!labels.includes(label)) {
      setLabels((prevLabels) => [...prevLabels, label]); // Track active filters
    }
  
    // Debugging: Log the label and value for each selection
    console.log("Selected label:", label);
    console.log("Selected value:", value);
    console.log("Updated filterValues:", temp);
  
    if (label === "Pick Up State") {
      await fetchCitiesByState(value); // Fetch cities when a state is selected
    } else if (label === "Pick Up City") {
      const pickUpState = filterValues["Pick Up State"]; // Get the selected state
      if (pickUpState) {
        const location = `${value}, ${pickUpState}`; // Concatenate city and state
        await fetchDriversByLocation(location); // Fetch drivers for the location
      }
    } else {
      applyFilters(); // Apply filters for other dropdowns
    }
  };
  
 
  const fetchCitiesByState = async (state) => {
    console.log("state is ", state);
    
    try {
      const response = await axios.post(
        'https://api.yatricabs.com/v1/admin/bookings/get-cities-state',
        { state : state }  // Pass state as a body
      );
      // console.log("response by api", response)
      const cities = response.data.data.map((cityData) => cityData.city);
      console.log("cities for selected state is", cities);
      
      const updatedFilterOptions = filterOptionsState.map((temp) => {
        if (temp.label === "Pick Up City") {
          temp.values = cities; // Update cities for the selected state
        }
        return temp;
      });
  
      setFilterOptionsState(updatedFilterOptions);
    } catch (error) {
      console.error("Failed to fetch cities for the selected state.", error);
    }
  };

  const fetchDriversByLocation = async (location) => {
    // console.log("id is",id)
    console.log("location is ", location)
    try {
      const response = await axios.get(
        `https://api.yatricabs.com/v1/admin/bookings/drivers/location?bookingId=${id}&location=${location}`
      );

      if (response.data.data.length === 0) {
        alert("No available drivers found for the selected city.");
        setApiData([]);
        setInitialDriverData([]);
      } else {
        const drivers = response.data.data.map((driver) => ({
          id: driver._id,
          "Driver Name": driver?.firstName + " " + driver?.lastName,
          "Contact No.": driver?.phone,
          "Language Spoken":
            driver.languages.length !== 1
              ? driver.languages[0] + ", " + driver.languages[1]
              : driver.languages[0],
          "Sourcing Type": driver.sourcing,
          "Availability Status":
            driver.availability === "Available" ? "Available" : "Not Available",
          "Driver License No.": driver?.license?.number,
          Location: driver?.location
            ? driver?.location?.city + ", " + driver?.location?.state
            : driver?.location,
        }));

        setApiData(drivers); // Set new data directly
        setInitialDriverData(drivers); // Update initial data
      }
    } catch (error) {
      alert("No Drivers Found!");
      setApiData([]);
      setInitialDriverData([]);
    }
  };

  return (
    <div className="data-container">
      <div className="filter-section">
        <p className="filter-tag" style={{width:"12%"}}>
          Filter
          <FaFilter />
        </p>

        <div
          style={{
            display: "flex",
            width: "52%",
            // gap: "11.5%",
             justifyContent: "space-between",
            marginRight: "1.5%",
          }}
        >
          {filterOptionsState &&
            filterOptionsState.map((filter, index) => {
              const defaultValue =
                 filter.label === "Pick Up City"
                  ? initialDriverData[0]?.Location?.split(",")[0]?.trim()
                   :undefined;
              return (
                <DropdownFilter
                  key={index}
                  filterOptions={filter}
                  onSelect={globalSelectedOption}
                  defaultValue={defaultValue}
                />
              );
            })}
        </div>
        <div className="rightfilterDriver">
          <input
            className="input-search"
            value={searchName}
            type="text"
            placeholder="Search By Name"
            onChange={(e) => setSearchName(e.target.value)}
          />
          <input
            className="input-search gap"
            value={searchContact}
            type="text"
            placeholder="Search By Contact No."
            onChange={(e) => setSearchContact(e.target.value)}
          />
          <div style={{ marginLeft: "10%" }}>
            <DropdownFilter
              key={0}
              filterOptions={filterAvailability}
              onSelect={globalSelectedOption}
              defaultValue={undefined}
            />
          </div>
          <div>
        {/* {filterValue.length !== 0 && ( */}
          <button className="filter-button" onClick={() => ResetFilters()}>
            Reset 
          </button>
        {/* )} */}
      </div>
        </div>
      </div>

      <div className="table-container">
        <table className="driver-table">
          <TableHeaders headers={headerColumns} />
          {apiData.length > 0 ? (
            <TableBody entries={apiData} headers={headerColumns} />
          ) : (
            <tbody>
              <tr>
                <td colSpan={headerColumns.length} className="no-data">
                  <span>{"No driver available for this Booking!"}</span>
                </td>
              </tr>
            </tbody>
          )}
        </table>
      </div>
    </div>
  );
}

export default AssignDriverData;
