import React from "react";
import "./header.css";
import axios from "axios";
import logo from "./../../assets/logo.png";
import { HiOutlineMenuAlt2 } from "react-icons/hi";
import { IoSearchSharp } from "react-icons/io5";
import { BsFillBellFill } from "react-icons/bs";
import { FaPeopleGroup } from "react-icons/fa6";
import { FiLogOut } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../login/authSlice";

const Header = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  console.log(user);
  const handleLogout = async () => {
    try {
  
      dispatch(logout());
      navigate("/");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <div className="navbar">
        <div className="left-side">
          <img src={logo} className="logo" loading="lazy" alt="logo" />
          <HiOutlineMenuAlt2
            onClick={() => {
              props.setSidebar(!props.sidebar);
            }}
            className="menu-icon"
          />

          <p className="naming">
            Hi, <span>{user.firstName}</span>
          </p>
        </div>

        <div className="right-side">
          <form className="search">
            <IoSearchSharp className="search-icon" />
            <input
              type="text"
              className="header-input"
              placeholder="Search with ID,Number"
            />
          </form>
          <BsFillBellFill fontSize={"15px"} className="bell-fill" />
          <FaPeopleGroup fontSize={"25px"} className="people-group" />
          <FiLogOut
            fontSize={"20px"}
            color="red"
            className="logout-icon"
            onClick={handleLogout}
          />
        </div>
      </div>
    </div>
  );
};

export default Header;
