// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  text-decoration: none;
}

ul {
  list-style: none;
}

.sub-nav {
  width: 100%;
  height: 30px;
  flex-shrink: 0;
  border-radius: 10px;
  background: #fff;
  text-wrap: nowrap;
}

a {
  margin: 5px 5px -4px 5px;
  color: black;
  text-decoration: none;
  outline-style: none;
}

.sub-navbar {
  display: flex;
  gap: 16px;
  width: 45%;
  height: 30px;
  font-family: 'inter';
  align-items: center;
  padding-left: 10px;
}

.sub-navbar li {
  cursor: pointer;
  padding: 7px;
  font-family: 'inter';
  font-size: clamp(10px, 0.5vw, 100px);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: center;
}

.sub-navbar li:hover {
  color: #ffffff;
  border-radius: 5px;
  flex-shrink: 0;
  border-radius: 10px;
  background: #38b000;
}

.sub-navbar li Link {
  font-family: "Inter", sans-serif;
}
`, "",{"version":3,"sources":["webpack://./src/components/Nav2/Nav.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;AACvB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,cAAc;EACd,mBAAmB;EACnB,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;EACE,wBAAwB;EACxB,YAAY;EACZ,qBAAqB;EACrB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,SAAS;EACT,UAAU;EACV,YAAY;EACZ,oBAAoB;EACpB,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,YAAY;EACZ,oBAAoB;EACpB,oCAAoC;EACpC,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,cAAc;EACd,kBAAkB;EAClB,cAAc;EACd,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,gCAAgC;AAClC","sourcesContent":["body {\n  text-decoration: none;\n}\n\nul {\n  list-style: none;\n}\n\n.sub-nav {\n  width: 100%;\n  height: 30px;\n  flex-shrink: 0;\n  border-radius: 10px;\n  background: #fff;\n  text-wrap: nowrap;\n}\n\na {\n  margin: 5px 5px -4px 5px;\n  color: black;\n  text-decoration: none;\n  outline-style: none;\n}\n\n.sub-navbar {\n  display: flex;\n  gap: 16px;\n  width: 45%;\n  height: 30px;\n  font-family: 'inter';\n  align-items: center;\n  padding-left: 10px;\n}\n\n.sub-navbar li {\n  cursor: pointer;\n  padding: 7px;\n  font-family: 'inter';\n  font-size: clamp(10px, 0.5vw, 100px);\n  font-style: normal;\n  font-weight: 600;\n  line-height: normal;\n  text-align: center;\n}\n\n.sub-navbar li:hover {\n  color: #ffffff;\n  border-radius: 5px;\n  flex-shrink: 0;\n  border-radius: 10px;\n  background: #38b000;\n}\n\n.sub-navbar li Link {\n  font-family: \"Inter\", sans-serif;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
