import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchingActiveLocations, fetchingInActiveLocations,fetchingLocations } from "./GeograpyApi";


const initialState = {
  viewallcity: [],
  inactivecity:[],
  addnewcity:[],
  activecity: [],
  editcity:  [],
  status: null,
  isLoading: false,
  isError: false,
  addednewcity: false,
  currentComp:null
};


export const fetchviewallcityAsync = createAsyncThunk("fetchlocation", async () => {
  try {
    const response = await fetchingLocations();
    return response;
  } catch (e) {
    console.log("Error in fetching Data", e);
    throw e;
  }
});
export const fetchinactivecity = createAsyncThunk("fetchinactivelocation", async () => {
  try {
    const response = await fetchingInActiveLocations();
    return response;
  } catch (e) {
    console.log("Error in fetching Data", e);
    throw e;
  }
});

export const fetchactivecity = createAsyncThunk("fetchactivelocation", async () => {
  try {
    const response = await fetchingActiveLocations();
    return response;
  } catch (e) {
    console.log("Error in fetching Data", e);
    throw e;
  }
});

const geographySlice = createSlice({
  name: "geography",
  initialState,
  reducers:{
    editCurrentCity :(state,action)=>{
      state.editcity = action.payload
    },
    resetCompState: (state, action) => {
      state.currentComp = action.payload;
    },
    resetInactiveCityState: (state) => {
      state.inactivecity = [];
      state.isLoading = false;
      state.isError = false;
    },
    toggleCityStatus: (state, action) => {
      try {
        const { cityId, newStatus } = action.payload;
        console.log("Toggling city status:", cityId, newStatus);

        const cityIndex = state.viewallcity.findIndex(city => city._id === cityId);
        if (cityIndex !== -1) {
          state.viewallcity[cityIndex].status = newStatus;
          console.log("City status updated successfully.");
        } else {
          console.error("City not found in state:", cityId);
        }
      } catch (error) {
        console.error("Error toggling city status:", error);
      }
    },
    toggleCityStatusForActive: (state, action) => {
      try {
        const { cityId, newStatus } = action.payload;
        console.log("Toggling city status:", cityId, newStatus);

        const cityIndex = state.activecity.findIndex(city => city._id === cityId);
        if (cityIndex !== -1) {
          state.activecity[cityIndex].status = newStatus;
          console.log("City status updated successfully.");
        } else {
          console.error("City not found in state:", cityId);
        }
      } catch (error) {
        console.error("Error toggling city status:", error);
      }
    }
  },
  extraReducers: (builder) => {
    builder
      // For fetchviewallcity
      .addCase(fetchviewallcityAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(fetchviewallcityAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.status = "idle";
        state.viewallcity = action.payload;
      })
      .addCase(fetchviewallcityAsync.rejected, (state, action) => {
        state.isLoading = false;
        console.log("Error", action.payload);
        state.isError = true;
      })
      // For fetchinactivecity
      .addCase(fetchinactivecity.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(fetchinactivecity.fulfilled, (state, action) => {
        state.isLoading = false;
        state.status = "idle";
        // state.inactivecity = action.payload;
if (action.payload && action.payload.length > 0) {
          state.inactivecity = action.payload;
        } else {
          // No inactive locations found, set inactivecity to an empty array
          state.inactivecity = null;
        }
      })
      .addCase(fetchinactivecity.rejected, (state, action) => {
        state.isLoading = false;
        console.log("Error", action.payload);
        state.isError = true;
      })
      // For fetchactivecity
      .addCase(fetchactivecity.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(fetchactivecity.fulfilled, (state, action) => {
        state.isLoading = false;
        state.status = "idle";
        state.activecity = action.payload;
      })
      .addCase(fetchactivecity.rejected, (state, action) => {
        state.isLoading = false;
        console.log("Error", action.payload);
        state.isError = true;
      });
  },
});


export const {editCurrentCity,resetCompState, toggleCityStatus,toggleCityStatusForActive,resetInactiveCityState} = geographySlice.actions;
export const selectViewAllCity = (state) => state.geography.viewallcity;
export const selectAllActiveCity = (state)=>state.geography.activecity;
export const selectInActiveCity = (state)=>state.geography.inactivecity;
export const selectLoading = (state) => state.geography.isLoading;
export default geographySlice.reducer;


