import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import imgbilling from "./../../assets/speedometer.png";
import rsbilling from "./../../assets/Vectorb.png";
import "./billing.css";
import { fetchbillingDetailsAsync, selectbillingDetails } from "./DetailsSlice";
import FourthExtertable from "./tablebilling/FourthExtertable";
import FourthFare from "./tablebilling/FourthFare";
import Fourthtable from "./tablebilling/Fourthtable";
import TableFirst from "./tablebilling/TableFirst";
import TableFirstNotCompleted from "./tablebilling/TableFirstNotCompleted";

import TableSecond from "./tablebilling/TableSecond";
import TableSecondNotCompleted from "./tablebilling/TableSecondNotCompleted";

import TableThird from "./tablebilling/TableThird";
import TableThirdNotCompleted from "./tablebilling/TableThirdNotCompleted";

import FourthtableNotCompleted from "./tablebilling/FourthtableNotCompleted";

import FourthExtertableNotCompleted from "./tablebilling/FourthExtertableNotCompleted";

import FourthFareNotCompleted from "./tablebilling/FourthFareNotCompleted";

import { selectbookingDetails } from "../Details/DetailsSlice";

const BillingDetails = ({ handleButtonClick }) => {
  const bookingDetails = useSelector(selectbookingDetails);

  console.log("Booking in billng page", bookingDetails);

  const { id } = useParams();
  console.log(id);

  const [loading, setLoading] = useState(true);
  const [errors, setError] = useState(null);

  const dispatch = useDispatch();
  const billingDetails = useSelector(selectbillingDetails);

  console.log("Billing details", billingDetails);
  const totalAmount =
    bookingDetails?.status === "Completed"
      ? billingDetails?.data?.totalPaidToDriver
      : "Bill Not Generated";

  // const totalAmount = Array.isArray(billingDetails?.paymentDetails) && billingDetails.paymentDetails.length > 0
  // ? billingDetails.paymentDetails[billingDetails.paymentDetails.length - 1]?.totalAmount
  // : "Bill Not Generated";

  console.log(totalAmount);

  console.log("billing details are", billingDetails);

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        dispatch(fetchbillingDetailsAsync(id));
      } catch (error) {
        setError("Error fetching details. Please try again.");
      } finally {
        setLoading(false);
      }
    };

    fetchDetails();
  }, [dispatch, id]);

  console.log(billingDetails);
  console.log(errors);

  return (
    <div className="billing-details-header">
      {/* <div style={{ display: "flex", justifyContent: "space-between" }}>
        <IoArrowBackCircleSharp
          onClick={() => handleButtonClick("RouteLog")}
          className="back"
        />
        <IoArrowForwardCircle className="back" />
      </div> */}

      <div className="billing-details">
        {bookingDetails?.status === "Completed" ? (
          <>
            <TableFirst data={billingDetails} />
          </>
        ) : (
          <>
            <TableFirstNotCompleted bookingDetailsTablefirst={bookingDetails} />
          </>
        )}
      </div>
      <div style={{ display: "flex" }}>
        <div className="billing-details-box1">
          <div className="billing-details-icon-details">
            <div className="billing-details-icon-text">
              <img src={imgbilling} alt=""></img>
            </div>
            <div className="billing-details-text">Total Bill</div>

            <div className="billing-details-ru">
              <img src={rsbilling} alt=""></img>
            </div>

            {bookingDetails.status === "Completed" ? (
              <div className="billing-details-rup">{totalAmount}</div>
            ) : (
              <div className="not-generated">{totalAmount}</div>
            )}
          </div>

          <div className="table-second-gap">
            {bookingDetails.status === "Completed" ? (
              <TableSecond data={billingDetails} />
            ) : (
              <TableSecondNotCompleted
                bookingDetailsTableSecond={bookingDetails}
              />
            )}
          </div>
          <div>
            {bookingDetails.status === "Completed" ? (
              <TableThird data={billingDetails} />
            ) : (
              <TableThirdNotCompleted
                bookingDetailsTableThird={bookingDetails}
              />
            )}
          </div>
        </div>
        <div className="billing-details-box2">
          <div>
            {bookingDetails.status === "Completed" ? (
              <>
                <Fourthtable data={billingDetails} />
                <FourthExtertable data={billingDetails} />
                <FourthFare data={billingDetails} />
              </>
            ) : (
              <>
                <FourthtableNotCompleted
                  bookingDetailsTableFourth={bookingDetails}
                />
                <FourthExtertableNotCompleted
                  bookingDetailsTableFourthExter={bookingDetails}
                />
                <FourthFareNotCompleted
                  bookingDetailsTableFourthFare={bookingDetails}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BillingDetails;
