import { useState, useRef } from "react";
import CountryDropdown from "../countrydropdown/CountryDropdown";
import "./AddCustomer.css";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const AddCustomer = () => {
	const [firstName, setFirstName] = useState("");
	const [selectedCountry, setSelectedCountry] = useState("+91");
	const [lastName, setLastName] = useState("");
	const [email, setEmail] = useState("");
	const [alternateMobile, setAlternateMobile] = useState("");
	const [phoneNumber, setPhoneNumber] = useState("");
	const [phoneNumberVerified, setPhoneNumberVerified] = useState(false);
	const [otpInput, setOtpInput] = useState(["", "", "", "", "", ""]);
	const [verified, setVerified] = useState(false);
	const [OtpId, setOtpId] = useState("");
	const [verificationToken, setVerificationToken] = useState("");
	const [password, setPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");
	const otpInputs = useRef([]);
	const [isOtpModalOpen, setIsOtpModalOpen] = useState(false);

	const handleOTPChange = (index, value) => {
		const updatedOTP = [...otpInput];
		updatedOTP[index] = value;
		setOtpInput(updatedOTP);

		if (value !== "" && index < otpInputs.current.length - 1) {
			otpInputs.current[index + 1].focus();
		}
	};

	if (selectedCountry && phoneNumber) {
		var completePhoneNumber = `${selectedCountry}-${phoneNumber}`;
	}

	if (selectedCountry && alternateMobile) {
		var completeAlterNumber = `${selectedCountry}-${alternateMobile}`;
	}

	const handleSubmit = async (e) => {
		e.preventDefault();
		const headers = {
			"Content-Type": "application/json",
		};
		const custumerObj = new FormData();
		custumerObj.append("firstName", firstName);
		custumerObj.append("lastName", lastName);
		custumerObj.append("email", email);
		custumerObj.append("phone", completePhoneNumber);
		custumerObj.append("otpToken", verificationToken);
		custumerObj.append("alternatePhone", completeAlterNumber);
		custumerObj.append("password", password);
		custumerObj.append("confirmPassword", confirmPassword);

		console.log("Data Entered");
		for (const pair of custumerObj.entries()) {
			console.log(pair[0] + ": " + pair[1]);
		}

		try {
			await axios.post(
				`${API_BASE_URL}/v1/users/register-guest`,
				custumerObj,
				{ headers },
			);
			toast.success("Customer added successfully!");

			setTimeout(() => {
				window.location.reload();
			}, 3000);

			e.target.reset();
		} catch (error) {
			toast.error(error.response.data.message);
			console.error(error.response.data.message);
		}
	};

	const handleVerifyPhoneNumber = () => {
		console.log("Phone Number:", completePhoneNumber);
		fetch(`${API_BASE_URL}/v1/send-otp`, {
			method: "POST",
			body: JSON.stringify({ phone: completePhoneNumber }),
			headers: {
				"Content-Type": "application/json",
			},
		})
			.then((response) => {
				if (response.ok) {
					console.log("OTP sent successfully");
					console.log(response);
					return response.json();
				} else {
					throw new Error("Failed to send OTP");
				}
			})
			.then((data) => {
				if (data && data.data && data.data.otpId) {
					setOtpId(data.data.otpId);
					setPhoneNumberVerified(true);
					setIsOtpModalOpen(true);
					console.log("OTP ID:", data.data.otpId);
				} else {
					throw new Error("Invalid response format from OTP sending API");
				}
			})
			.catch((error) => {
				console.error("Error sending OTP:", error.message);
			});
	};

	const handleVerifyOTP = () => {
		const otpNumber = parseInt(otpInput.join(""), 10);
		console.log("OTP:", otpNumber);
		console.log("OTP ID:", OtpId);
		fetch(`${API_BASE_URL}/v1/verify-otp`, {
			method: "POST",
			body: JSON.stringify({
				phone: completePhoneNumber,
				otp: otpNumber,
				otpId: OtpId,
			}),
			headers: {
				"Content-Type": "application/json",
			},
		})
			.then((response) => {
				if (response.ok) {
					console.log("OTP verified successfully");
					console.log(response);
					setVerified(true);
					setIsOtpModalOpen(false);
					return response.json();
				} else {
					throw new Error("Failed to verify OTP");
				}
			})
			.then((data) => {
				setVerificationToken(data.otpToken);
				console.log("Message:", data.message);
				console.log("Token:", data.otpToken);
			})
			.catch((error) => {
				console.error("Error verifying OTP:", error.message);
			});

		console.log("Phone Number:", completePhoneNumber);
	};

	return (
		<>
			<Toaster />
			<form onSubmit={handleSubmit}>
				<div className="add-new-customer-container">
					<div className="customer">
						<div className="crete-new-sub-head">Add Customer</div>
						<div className="customer-details">
							{/* Row 1: First Name and Last Name */}
							<div style={{ marginBottom: "0px" }} className="row row1">
								{/* First Name */}
								<div
									className="input-group"
									style={{
										display: "flex",
										alignItems: "center",
										marginBottom: "10px",
									}}
								>
									<label
										style={{
											marginRight: "10px",
											minWidth: "100px",
											textAlign: "right",
											marginBottom: "10px",
										}}
										className="title"
									>
										First Name:
									</label>
									<input
										type="text"
										onChange={(e) => setFirstName(e.target.value)}
										placeholder="Type First Name"
										className="input"
										value={firstName}
										style={{ flex: "1", padding: "8px", marginLeft: "70px" }}
									/>
								</div>

								{/* Last Name */}
								<div
									className="input-group"
									style={{
										display: "flex",
										alignItems: "center",
										marginBottom: "10px",
									}}
								>
									<label
										style={{
											marginRight: "10px",
											minWidth: "100px",
											textAlign: "right",
											marginBottom: "10px",
										}}
										className="title"
									>
										Last Name:
									</label>
									<input
										type="text"
										onChange={(e) => setLastName(e.target.value)}
										placeholder="Type Last Name"
										className="input"
										value={lastName}
										style={{ flex: "1", padding: "8px", marginLeft: "50px" }}
									/>
								</div>
							</div>

							{/* Row 2: Primary Mobile No., Verify OTP, Alternate Mobile No. */}
							<div className="row2">
								{/* Primary Mobile No. */}
								<div
									className="input-group"
									style={{ display: "flex", alignItems: "center", gap: "10px" }}
								>
									<label
										className="title"
										style={{
											minWidth: "150px",
											textAlign: "right",
											marginBottom: "10px",
											display: "flex",
											flexDirection: "column",
											lineHeight: "1.2",
										}}
									>
										<span style={{ marginLeft: "-85px" }}>Primary</span>
										<span style={{ marginLeft: "-60px" }}>Mobile No.</span>
									</label>

									<CountryDropdown
										selectedCountry={selectedCountry}
										setSelectedCountry={setSelectedCountry}
									/>
									<input
										type="tel"
										onChange={(e) => setPhoneNumber(e.target.value)}
										maxLength="10"
										placeholder="Type Mobile Number"
										className="input-mobile"
										disabled={phoneNumberVerified}
										value={phoneNumber}
										style={{ flex: "1", padding: "8px", width: "190px" }}
									/>
									{!phoneNumberVerified && phoneNumber.length === 10 && (
										<button
											type="button"
											className="verify-btn"
											onClick={handleVerifyPhoneNumber}
										>
											Verify Now
										</button>
									)}
									{verified && <span className="verified">Verified</span>}
								</div>

								{/* OTP Modal */}
								{isOtpModalOpen && (
									<div className="otp-modal">
										<div className="otp-modal-content">
											<h3>Enter OTP</h3>
											<div className="otp-inputs">
												{otpInput.map((value, index) => (
													<input
														key={index}
														ref={(input) => (otpInputs.current[index] = input)}
														type="text"
														maxLength="1"
														className="otp-input"
														value={value}
														onChange={(e) =>
															handleOTPChange(index, e.target.value)
														}
													/>
												))}
											</div>
											<button
												type="button"
												className="verify"
												onClick={handleVerifyOTP}
											>
												Verify OTP
											</button>
											<button
												type="button"
												className="close-btn"
												onClick={() => setIsOtpModalOpen(false)}
											>
												Close
											</button>
										</div>
									</div>
								)}

								{/* Alternate Mobile No. */}
								<div
									className="input-group"
									style={{ display: "flex", alignItems: "center", gap: "10px" }}
								>
									<label
										className="title"
										style={{
											minWidth: "150px",
											textAlign: "right",
											marginBottom: "10px",
											display: "flex",
											flexDirection: "column",
											lineHeight: "1.2",
										}}
									>
										<span style={{ marginLeft: "-50px" }}>Alternate</span>
										<span style={{ marginLeft: "-40px" }}>Mobile No.</span>
									</label>
									<input
										type="text"
										onChange={(e) => setAlternateMobile(e.target.value)}
										placeholder="Type Alternate Number"
										className="input"
										value={alternateMobile}
										style={{ flex: "1", padding: "8px", marginLeft: 0 }}
									/>
								</div>
							</div>

							{/* Row 3: Email Address and Create Password */}
							<div className="row3">
								{/* Email Address */}
								<div
									className="input-group"
									style={{
										display: "flex",
										alignItems: "center",
										gap: "10px",
										marginBottom: "10px",
									}}
								>
									<label
										className="title"
										style={{
											minWidth: "150px",
											textAlign: "right",
											display: "flex",
											alignItems: "center",
											height: "40px",
											marginBottom: "10px",
										}}
									>
										Email Address
									</label>
									<input
										type="text"
										onChange={(e) => setEmail(e.target.value)}
										placeholder="Type Email Address"
										className="input"
										value={email}
										style={{ flex: "1", padding: "8px", height: "40px" }}
									/>
								</div>

								{/* Create Password */}
								<div
									className="input-group"
									style={{
										display: "flex",
										alignItems: "center",
										gap: "10px",
										marginBottom: "10px",
									}}
								>
									<label
										className="title"
										style={{
											minWidth: "150px",
											textAlign: "right",
											marginBottom: "10px",
											display: "flex",
											flexDirection: "column",
											lineHeight: "1.2",
										}}
									>
										<span style={{ marginLeft: "-100px" }}> Create </span>
										<span style={{ marginLeft: "-70px" }}>Password</span>
									</label>
									<input
										type="password"
										onChange={(e) => setPassword(e.target.value)}
										placeholder="Type Create Password"
										className="input"
										value={password}
										style={{
											flex: "1",
											padding: "8px",
											height: "40px",
											marginLeft: "-10px",
										}}
									/>
								</div>
							</div>

							{/* Row 4: Confirm Password */}
							<div className="row4">
								<div
									className="input-group"
									style={{ display: "flex", alignItems: "center", gap: "10px" }}
								>
									<label
										className="title"
										style={{
											minWidth: "150px",
											textAlign: "right",
											marginBottom: "10px",
											display: "flex",
											flexDirection: "column",
											lineHeight: "1.2",
										}}
									>
										<span style={{ marginLeft: "-85px" }}> Confirm </span>
										<span style={{ marginLeft: "-70px" }}>Password</span>
									</label>
									<input
										type="password"
										onChange={(e) => setConfirmPassword(e.target.value)}
										placeholder="Confirm Password"
										className="input"
										value={confirmPassword}
										style={{ flex: "1", padding: "8px" }}
									/>
								</div>
							</div>

							{/* Submit Button */}
							<div className="add">
								<button type="submit" className="add-btn">
									Add
								</button>
							</div>
						</div>
					</div>
				</div>
			</form>
		</>
	);
};

export default AddCustomer;
