import React from 'react'
import './firsttable.css'

const TableItem = ({ item, index }) => {
    
    return (
      <tr className="first_Earning_table_data">
        <td>{item[" "]}</td>
        <td>{item["All"]}</td>
        <td>{item["Retail"]}</td>
        <td>{item["Corporate"]}</td>
        
      </tr>
    );
  };

const Table = ({ headers, data }) => {
    return (
      <div className="first_table_containers">
        <div className="first_table_scroll">
          <table className="first_table">
            <thead>
              <tr>
                {headers.map((header, index) => (
                  <th style={{borderRadius:"0"}}key={index}>{header}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => (
                <TableItem key={index} item={item} index={index} />
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  };

const FirstTable = () => {
    const headers =[
        " ",
        "All",
        "Retail",
        "Corporate"

    ];
    const data=[
        {
            " ":"Total  Booking",
            "All":"10",
            "Retail":"9",
            "Corporate":"1"
        },
        {
            " ":"Total  Booking",
            "All":"10",
            "Retail":"9",
            "Corporate":"1"
        },
        {
            " ":"Total  Booking",
            "All":"10",
            "Retail":"9",
            "Corporate":"1"
        },
    ];

  return (
    <Table headers={headers} data={data}/>
  )
}

export default FirstTable
