import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { selectSelectedTariffId, selectTripType } from "./VendorTarrifSlice";
import axios from "axios"; 
import "./UpdateVendorTarrif.css";
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const UpdateVendorTarrif = () => {
  const selectedTariffId = useSelector(selectSelectedTariffId); // Get the selected tariff ID
  const tripType = useSelector(selectTripType); 
  console.log("Here is the ID of the row to be updated:", selectedTariffId);

  const [formValues, setFormValues] = useState({
    locationId: "",
    carCategoryId: "",
    tripType: "",
    subTripType: "",
    distance: "",
    fuelType: "",
    days: "",
    baseFare: "",
    minKm: "",
    minHrs: "",
    extraKmCharge: "",
    extraHrsCharge: "",
    tdsRate: "",
    morningDriverAllowance: "",
    nightDriverAllowance: "",
    dayDriverAllowance: "",
    minKmPerDay: "",
    perKmCharge: "",
    extraKmChargeOutstation: "",
    tdsOutstation: "",
  });

  const [locationOptions, setLocationOptions] = useState([]);
  const [carCategoryOptions, setCarCategoryOptions] = useState([]);
  const [tripTypeOptions, setTripTypeOptions] = useState([]);
  const [subTripTypeOptions, setSubTripTypeOptions] = useState([]);
  const [garageDistanceOptions] = useState([
    { label: "G2G Fixed", value: "G2G Fixed" },
    { label: "P2P", value: "P2P" },
    { label: "G2G Actual", value: "G2G Actual" },
  ]);
  const [fuelTypeOptions] = useState([
    { label: "Petrol", value: "Petrol" },
    { label: "Diesel", value: "Diesel" },
    { label: "CNG", value: "CNG" },
    { label: "Electric", value: "Electric" },
  ]);
  const [daysOptions, setDaysOptions] = useState([]);
  const [showAdditionalFields, setShowAdditionalFields] = useState(false);
  const [isOutstation, setIsOutstation] = useState(false);
  const handleChange = (e) => {
    const { name, value } = e.target;
  
    setFormValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  
    if (name === "tripType") {
      setShowAdditionalFields(!!value); // Show fields only if tripType has a value
      setIsOutstation(value === "Outstation"); // Set outstation flag
    }
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();

    let payload;
  
    // Form payload based on tripType
    if (formValues.tripType === "Local" || formValues.tripType === "Airport") {
      payload = {
        locationId: formValues.locationId,
        carCategoryId: formValues.carCategoryId,
        tripType: formValues.tripType,
        subTripType: formValues.subTripType,
        distance: formValues.distance,
        fuelType: formValues.fuelType,
        days: formValues.days,
        baseFare: formValues.baseFare,
        minKm: formValues.minKm,
        minHrs: formValues.minHrs,
        extraKmCharge: formValues.extraKmCharge,
        extraHrsCharge: formValues.extraHrsCharge,
        tds: formValues.tdsRate,
        morningDriverAllowance: formValues.morningDriverAllowance,
        nightDriverAllowance: formValues.nightDriverAllowance,
        dayDriverAllowance: formValues.dayDriverAllowance,
      };
    } else if (formValues.tripType === "Outstation") {
      payload = {
        locationId: formValues.locationId,
        carCategoryId: formValues.carCategoryId,
        tripType: formValues.tripType,
        subTripType: formValues.subTripType,
        distance: formValues.distance,
        fuelType: formValues.fuelType,
        days: formValues.days,
        minKmPerDay: formValues.minKmPerDay,
        perKmCharge: formValues.perKmCharge,
        extraKmCharge: formValues.extraKmChargeOutstation,
        tds: formValues.tdsOutstation,
        morningDriverAllowance: formValues.morningDriverAllowance,
        nightDriverAllowance: formValues.nightDriverAllowance,
        dayDriverAllowance: formValues.dayDriverAllowance,
      };
    } else {
      alert("Please select a valid Trip Type!");
      return;
    }
  
    console.log("Payload prepared:", payload);

    const apiUrl =
      tripType === "Local" || tripType === "Airport"
        ?`${API_BASE_URL}/v1/admin/vendor-tariffs/local-airport/${selectedTariffId}`
        : `${API_BASE_URL}/v1/admin/vendor-tariffs/outstation/${selectedTariffId}`;

    try {
      const response = await axios.put(apiUrl, payload, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      console.log("Update Successful:", response.data);
      alert("Tariff updated successfully!");
    } catch (error) {
      if (error.response) {
        console.error("Error updating tariff:", error.response.data);
        alert(`Error: ${error.response.data.message || "Update failed"}`);
      } else {
        console.error("Network error:", error.message);
        alert("Failed to update the tariff. Please try again.");
      }
    }
  };

  useEffect(() => {
    if (formValues.tripType) {
      setShowAdditionalFields(true);
      setIsOutstation(formValues.tripType === "Outstation");
    }
  }, [formValues.tripType]);
  

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const locationResponse = await fetch(`${API_BASE_URL}/v1/locations`);
        const locationData = await locationResponse.json();
        setLocationOptions(
          locationData.data.map((item) => ({
            label: item.location,
            value: item._id,
          }))
        );

        const carCategoryResponse = await fetch(`${API_BASE_URL}/v1/car-categories`);
        const carCategoryData = await carCategoryResponse.json();
        setCarCategoryOptions(
          carCategoryData.data.map((item) => ({
            label: item.category,
            value: item._id,
          }))
        );

        const tripTypeResponse = await fetch(`${API_BASE_URL}/v1/trip-types`);
        const tripTypeData = await tripTypeResponse.json();
        setTripTypeOptions(
          tripTypeData.data.map((item) => ({
            label: item.tripType,
            value: item.tripType,
          }))
        );

        const daysResponse = await fetch(`${API_BASE_URL}/v1/days`);
        const daysData = await daysResponse.json();
        setDaysOptions(
          daysData.data.map((item) => ({
            label: item.day,
            value: item.day,
          }))
        );
      } catch (error) {
        console.error("Error fetching options:", error);
      }
    };

    const fetchTariffDetails = async () => {
      if (selectedTariffId) {
        try {
          const endpoint = `${API_BASE_URL}/v1/admin/vendor-tariffs/${selectedTariffId}`;

          const response = await axios.get(endpoint);
          console.log("Fetched tariff details:", response.data);

          setFormValues({
            locationId: response.data.data.location?._id || "",
            carCategoryId: response.data.data.carCategory?._id || "",
            tripType: response.data.data.tripType || "",
            subTripType: response.data.data.subTripType || "",
            distance: response.data.data.distance || "",
            fuelType: response.data.data.fuelType || "",
            days: response.data.data.days || "",
            baseFare: response.data.data.fareDetails?.baseFare || "",
            minKm: response.data.data.fareDetails?.minKm || "",
            minHrs: response.data.data.fareDetails?.minHrs || "",
            extraKmCharge: response.data.data.fareDetails?.extraKmCharge || "",
            extraHrsCharge: response.data.data.fareDetails?.extraHrsCharge || "",
            tdsRate: response.data.data.fareDetails?.tds|| "",
            morningDriverAllowance: response.data.data.fareDetails?.morningDriverAllowance || "",
            nightDriverAllowance: response.data.data.fareDetails?.nightDriverAllowance || "",
            dayDriverAllowance: response.data.data.fareDetails?.dayDriverAllowance || "",
            minKmPerDay:response.data.data.fareDetails?.minKmPerDay || "",
            perKmCharge:response.data.data.fareDetails?.perKmCharge || "",
            extraKmChargeOutstation:response.data.data.fareDetails?.extraKmCharge || "",
            tdsOutstation:response.data.data.fareDetails?.tds|| "",
          });
        } catch (error) {
          if (error.response) {
            console.error("Error fetching tariff details:", error.response.data);
          } else {
            console.error("Network error:", error.message);
          }
        }
      }
    };

    fetchOptions();
    fetchTariffDetails();
  }, [selectedTariffId]);

  useEffect(() => {
    const fetchSubTripTypes = async () => {
      try {
        if (formValues.tripType) {
          const response = await fetch(`${API_BASE_URL}/v1/sub-trip-types/${formValues.tripType}`);
          const data = await response.json();
          setSubTripTypeOptions(
            data.data[0].subTripTypes.map((subTripType) => ({
              label: subTripType,
              value: subTripType,
            }))
          );
        }
      } catch (error) {
        console.error("Error fetching sub-trip types:", error);
      }
    };

    fetchSubTripTypes();
  }, [formValues.tripType]);

  return (
    <form className="create-vendor-tariff" onSubmit={handleSubmit}>
      <div className="section">
        <div className="section-title">
          <div className="title">Add Price Details</div>
        </div>
        <div className="form-row">
          <div>
            <label htmlFor="locationId">Location</label>
            <select
              id="locationId"
              name="locationId"
              value={formValues.locationId}
              onChange={handleChange}
              disabled
            >
              <option value="">Select City</option>
              {locationOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>

          <div>
            <label htmlFor="carCategoryId">Car Category</label>
            <select
              id="carCategoryId"
              name="carCategoryId"
              value={formValues.carCategoryId}
              onChange={handleChange}
              disabled
            >
              <option value="">Select Category</option>
              {carCategoryOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>

          <div>
            <label htmlFor="tripType">Trip Type</label>
            <select
              id="tripType"
              name="tripType"
              value={formValues.tripType}
              onChange={handleChange}
              disabled
            >
              <option value="">Select Trip Type</option>
              {tripTypeOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>

          <div>
            <label htmlFor="subTripType">Sub Trip Type</label>
            <select
              id="subTripType"
              name="subTripType"
              value={formValues.subTripType}
              onChange={handleChange}
              disabled
            >
              <option value="">Select Sub Trip Type</option>
              {subTripTypeOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>

          <div>
            <label htmlFor="distance">Garage Distance Option</label>
            <select
              id="distance"
              name="distance"
              value={formValues.distance}
              onChange={handleChange}
              disabled
            >
              <option value="">Select Option</option>
              {garageDistanceOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>

          <div>
            <label htmlFor="fuelType">Fuel Type</label>
            <select
              id="fuelType"
              name="fuelType"
              value={formValues.fuelType}
              onChange={handleChange}
              disabled
            >
              <option value="">Select Option</option>
              {fuelTypeOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>

          <div>
            <label htmlFor="days">Days</label>
            <select
              id="days"
              name="days"
              value={formValues.days}
              onChange={handleChange}
              disabled
            >
              <option value="">Select Day Type</option>
              {daysOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>

      {showAdditionalFields && (
        <>
          {isOutstation ? (
            <div className="section">
              <div className="section-title">
                <div className="title">Outstation Pricing</div>
              </div>
              <div className="form-row">
                <div>
                  <label htmlFor="minKmPerDay">Min Km/Day</label>
                  <input
                    type="text"
                    id="minKmPerDay"
                    name="minKmPerDay"
                    placeholder="Min Km/Day"
                    value={formValues.minKmPerDay}
                    onChange={handleChange}
                  />
                </div>
                <div>
                  <label htmlFor="perKmCharge">Per Km Charge</label>
                  <input
                    type="text"
                    id="perKmCharge"
                    name="perKmCharge"
                    placeholder="Per Km Charge"
                    value={formValues.perKmCharge}
                    onChange={handleChange}
                  />
                </div>
                <div>
                  <label htmlFor="extraKmChargeOutstation">Extra Km Charge</label>
                  <input
                    type="text"
                    id="extraKmChargeOutstation"
                    name="extraKmChargeOutstation"
                    placeholder="Extra Km Charge"
                    value={formValues.extraKmChargeOutstation}
                    onChange={handleChange}
                  />
                </div>
                <div>
                  <label htmlFor="tdsOutstation">TDS</label>
                  <input
                    type="text"
                    id="tdsOutstation"
                    name="tdsOutstation"
                    placeholder="TDS (%)"
                    value={formValues.tdsOutstation}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
          ) : (
            <div className="section">
              <div className="section-title">
                <div className="title">Local/Airport Pricing</div>
              </div>
              <div className="form-row">
                <div>
                  <label htmlFor="baseFare">Base Fare</label>
                  <input
                    type="text"
                    id="baseFare"
                    name="baseFare"
                    placeholder="Base Fare"
                    value={formValues.baseFare}
                    onChange={handleChange}
                  />
                </div>
                <div>
                  <label htmlFor="minKm">Min Km</label>
                  <input
                    type="text"
                    id="minKm"
                    name="minKm"
                    placeholder="Min Km"
                    value={formValues.minKm}
                    onChange={handleChange}
                  />
                </div>
                <div>
                  <label htmlFor="minHrs">Min Hours</label>
                  <input
                    type="text"
                    id="minHrs"
                    name="minHrs"
                    placeholder="Min Hours"
                    value={formValues.minHrs}
                    onChange={handleChange}
                  />
                </div>
                <div>
                  <label htmlFor="extraKmCharge">Extra Km Charge</label>
                  <input
                    type="text"
                    id="extraKmCharge"
                    name="extraKmCharge"
                    placeholder="Extra Km Charge"
                    value={formValues.extraKmCharge}
                    onChange={handleChange}
                  />
                </div>
                <div>
                  <label htmlFor="extraHrsCharge">Extra Hours Charge</label>
                  <input
                    type="text"
                    id="extraHrsCharge"
                    name="extraHrsCharge"
                    placeholder="Extra Hours Charge"
                    value={formValues.extraHrsCharge}
                    onChange={handleChange}
                  />
                </div>
                <div>
                  <label htmlFor="tdsRate">TDS</label>
                  <input
                    type="text"
                    id="tdsRate"
                    name="tdsRate"
                    placeholder="TDS (%)"
                    value={formValues.tdsRate}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
          )}

          <div className="section">
            <div className="section-title">
              <div className="title">Driver Allowance</div>
            </div>
            <div className="form-row">
              <div>
                <label htmlFor="morningDriverAllowance">Morning Bata</label>
                <input
                  type="text"
                  id="morningDriverAllowance"
                  name="morningDriverAllowance"
                  placeholder="Morning Bata
                (Rs./Day)"
                  value={formValues.morningDriverAllowance}
                  onChange={handleChange}
                />
              </div>

              <div>
                <label htmlFor="nightDriverAllowance">Night Bata</label>
                <input
                  type="text"
                  id="nightDriverAllowance"
                  name="nightDriverAllowance"
                  placeholder="Night Bata (Rs./Day)"
                  value={formValues.nightDriverAllowance}
                  onChange={handleChange}
                />
              </div>

              <div>
                <label htmlFor="dayDriverAllowance">Day Bata</label>
                <input
                  type="text"
                  id="dayDriverAllowance"
                  name="dayDriverAllowance"
                  placeholder="Day Bata (Rs./Day)"
                  value={formValues.dayDriverAllowance}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
        </>
      )}

      <div className="form-submit">
        <button type="submit" className="submit-button">
          Update
        </button>
      </div>
    </form>
  );
};

export default UpdateVendorTarrif;
