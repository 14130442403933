import React, { useState } from 'react'
import Navbar from '../../navbar/Navbar'
import Header from '../../header/Header'
import Local from './Local/Local'
import Outstation from './outsatation/Outstation'
const Layout = () => {
    const [sidebar, setSidebar] = useState(false);


    return (
        <>

            {/* <Header setSidebar={true} sidebar={true} />
            <div className="navbar-adminInfo nav007-flexer">
                <Navbar/>


                <Local/>
            </div> */}

            <div className="App">
                <Header setSidebar={setSidebar} sidebar={sidebar} name="Raj" />
                <div className="navbar-adminInfo">
                    <Navbar
                        sidebar={sidebar}
                       
                    />
                    
                    <Local />
                    
                    {/* <Outstation/> */}
                    
                </div>
            </div>


        </>
    )
}

export default Layout