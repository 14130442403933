import React, { useEffect, useState } from "react";
import axios from "axios";
import "./DriverInfo.css";
import { useSelector, useDispatch } from "react-redux";
import { IoIosCloseCircleOutline } from "react-icons/io";
import redArrow from "./../../assets/redArrow.svg";
import { Link, useNavigate, useParams } from "react-router-dom";

import {
  currentDriverId,
  resetDriverDetails,
  fetchDriversDetails,
  resetCompState,
} from "./DriverDetailsSlice";
import { IoIosArrowDown } from "react-icons/io";
import Loader from "../Loader";
const tableHeaderStyle = {
  backgroundColor: "#38B000",
  color: "white",
  padding: "10px",
  fontFamily: "Inter",
  fontSize: "15px",
  fontWeight: 600,
  borderRadius: "unset",
};
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const DriverInfo = (props) => {
  const { driverDetails, currentComp } = useSelector(
    (state) => state.driverInfo
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showPopup, setShowPopup] = useState(false);
  const [popupText, setPopupText] = useState("");
  const [reason, setReason] = useState("");
  const { currentSection, driverId } = useParams();
  const [isDriverInfoOpen, setIsDriverInfoOpen] = useState(true);
  const [isBankDetailsOpen, setIsBankDetailsOpen] = useState(true);
  const [isUploadDocsOpen, setIsUploadDocsOpen] = useState(true);
  const toggleDriverInfo = () => setIsDriverInfoOpen(!isDriverInfoOpen);
  const toggleBankDetails = () => setIsBankDetailsOpen(!isBankDetailsOpen);
  const toggleUploadDocs = () => setIsUploadDocsOpen(!isUploadDocsOpen);

  const handleCheckboxChange = (e) => {
    setReason(e.target.value);
  };

  const handleReSubmitClick = () => {
    setShowPopup(true);
  };

  const handlePopupClose = () => {
    setShowPopup(false);
    setPopupText(""); // Clear the text field value when closing the popup
  };
  const handlePopupSubmit = async (driverId) => {
    try {
      // Construct the API URL with the driverId
      const apiUrl = `${API_BASE_URL}/v1/admin/drivers/${driverId}/approve/resubmit`;

      // Prepare the request body
      const requestBody = {
        reason: reason,
        remark: popupText,
      };

      // Make the API request
      const response = await axios.post(apiUrl, requestBody);

      // Handle the response
      console.log("API response:", response.data);

      // Close the popup after submission
      setShowPopup(false);
      setPopupText("");
      setReason("");
    } catch (error) {
      // Handle any errors
      console.error("Error submitting data:", error);
    }
  };

  const [popUpImage, setPopUpImage] = useState(false);
  const [displayImage, setDisplayImage] = useState();
  const hanldeImagePopup = (imageURL) => {
    setPopUpImage(true);
    setDisplayImage(imageURL);
  };
  const [previousLocation, setPreviousLocation] = useState();
  const [previousUrl, setPreviousUrl] = useState();
  const checkParentLocation = (path) => {
    if (path.includes("InactiveDriver")) {
      setPreviousLocation("Inactive Drivers");
      setPreviousUrl("/drivermanagement/inactive-driver");
    } else if (path.includes("ApprovalPendingDriver")) {
      setPreviousLocation("Approval Pending");
      setPreviousUrl("/drivermanagement/approval-pending");
    } else {
      setPreviousLocation("View All Drivers");
      setPreviousUrl("/drivermanagement/view-all-drivers");
    }
  };

  const handleModifyButton = () => {
    navigate(`/drivermanagement/${currentSection}/modify-details/${driverId}`);
  };

  const handleApprovalClick = async (driverId) => {
    try {
      const response = await axios.put(
        `${API_BASE_URL}/v1/admin/drivers/${driverId}/approve`
      );

      const approvedDriver = response.data;

      // After successful approval, navigate to '/drivermanagement' page
      navigate("/drivermanagement");

      console.log("Driver Approved:", approvedDriver);
    } catch (error) {
      console.error("Error approving driver:", error);
    }
  };

  // const handleChange = (e) => {
  //   setInputValue({
  //     ...inputValue,
  //     [e.target.name]: e.target.value,
  //   });
  // };

  const handleEarning = () => {
    navigate(
      `/drivermanagement/${currentSection}/DriverDetails/${driverId}/Reports`
    );
  };

  useEffect(() => {
    const checkingLocation = window.location.pathname;
    console.log("checking driver details in booking", driverDetails);
    console.log(
      "checking locattion",
      checkingLocation.includes("ViewAllDriver")
    );
    checkParentLocation(window.location.pathname);
  }, []);

  const handleToggleComponent = (value) => {
    dispatch(resetCompState(value));
    navigate("/drivermanagement");
  };
  console.log("i am here ", previousLocation);
  return (
    <>
      {
        <div
          className={popUpImage ? "blurbg" : "driver-info-container"}
          style={popUpImage ? {} : { marginTop: "1px" }}
        >
          <div className="nav-content">
            <div className="header-text">
              <Link className="h3-booking" to="/dashboard">
                Driver Management
              </Link>
              <img src={redArrow} alt="redArrow" />
              {""}
              <Link className="h3-booking" to={previousUrl}>
                {previousLocation}
              </Link>
              <img src={redArrow} alt="redArrow" />
              <Link className="h2-booking">Driver Details</Link>
            </div>
            {previousLocation === "View All Drivers" && (
              <button
                className="createButton"
                onClick={() => handleToggleComponent("View All Drivers")}
              >
                View All Drivers
              </button>
            )}

            {previousLocation === "Inactive Drivers" && (
              <button
                className="createButton"
                onClick={() => {
                  handleToggleComponent("Inactive Driver");
                }}
              >
                Inactive Drivers
              </button>
            )}

            {previousLocation === "Approval Pending" && (
              <button
                className="createButton"
                onClick={() => handleToggleComponent("Approval Pending")}
              >
                Approval Pending
              </button>
            )}
          </div>
          <div
            className="add-new-car-vehicle-scrolbar"
            style={{ height: "100vh" }}
          >
            <div
              onClick={toggleDriverInfo}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                cursor: "pointer",
                backgroundColor: "#38B000",
                borderRadius: "2px",
                boxShadow: "0px 4px 22px 0px rgba(0, 0, 0, 0.17)",
                color: "#FFF",
                padding: "10px",
                fontFamily: "Lato",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: "700",
                lineHeight: "normal",
              }}
            >
              Driver Information
              <IoIosArrowDown
                style={{
                  color: "#000", 
                  fontSize: "20px", 
                  transform: isDriverInfoOpen
                    ? "rotate(180deg)"
                    : "rotate(0deg)",
                  transition: "transform 0.3s ease",
                }}
              />
            </div>

            <div
              style={{
                maxHeight: isDriverInfoOpen ? "500px" : "0",
                overflow: "hidden",
                transition: "max-height 0.5s ease-in-out",
              }}
            >
              {driverDetails ? (
                <>
                  <div className="user-details">
                    <div className="user-details-section">
                      <div className="user-detail">
                        <div className="user-heading">Location</div>
                        <div className="user-info">
                          {driverDetails.location.city},
                          {driverDetails.location.state}
                        </div>
                      </div>
                      <div className="user-detail">
                        <div className="user-heading">Phone Number</div>
                        <div className="user-info">{driverDetails.phone}</div>
                      </div>
                      <div className="user-detail">
                        <div className="user-heading">Language Speak</div>
                        {driverDetails.languages.map((language, index) => {
                          return (
                            <div className="user-languages" key={index}>
                              <input
                                type="checkbox"
                                id="hindiCheckbox"
                                name="language"
                                value="Hindi"
                                checked
                              />
                              <label
                                htmlFor="hindiCheckbox"
                                style={{ marginTop: "0px" }}
                              >
                                {language}
                              </label>
                            </div>
                          );
                        })}
                      </div>

                      <div className="user-detail">
                        <div className="user-heading">DL Number</div>
                        <div className="user-info">
                          {driverDetails.license.number}
                        </div>
                      </div>
                      <div className="user-detail">
                        <div className="user-heading">Permanent Address</div>
                        <div className="user-info">
                          {driverDetails.address.permanent}
                        </div>
                      </div>
                    </div>
                    <div className="user-details-section">
                      <div className="user-detail">
                        <div className="user-heading">First Name</div>
                        <div className="user-info">
                          {driverDetails.firstName}
                        </div>
                      </div>
                      <div className="user-detail">
                        <div className="user-heading">
                          Alternate Phone Number
                        </div>
                        <div className="user-info">
                          {driverDetails.alternatePhone}
                        </div>
                      </div>
                      <div className="user-detail">
                        <div className="user-heading">Expiry Date</div>
                        <div className="user-info">
                          {driverDetails.license.expiryDate}
                        </div>
                      </div>
                      <div className="user-detail">
                        <div className="user-heading">Current Address</div>
                        <div className="user-info">
                          {driverDetails.address.current}
                        </div>
                      </div>
                      <div className="user-detail">
                        <div className="user-heading">Sourcing</div>
                        <div className="user-info">
                          {driverDetails.sourcing}
                        </div>
                      </div>
                    </div>
                    <div className="user-details-section">
                      <div className="user-detail">
                        <div className="user-heading">Last Name</div>
                        <div className="user-info">
                          {driverDetails.lastName}
                        </div>
                      </div>
                      <div className="user-detail">
                        <div className="user-heading">Date Of Birth</div>
                        <div className="user-info">
                          {driverDetails.dateOfBirth}
                        </div>
                      </div>
                      <div className="user-detail">
                        <div className="user-heading">PAN Number</div>
                        <div className="user-info">
                          {driverDetails.pan.number}
                        </div>
                      </div>
                      <div className="user-detail">
                        <div className="user-heading">Aadhar Number</div>
                        <div className="user-info">
                          {driverDetails.aadhaar.number}
                        </div>
                      </div>
                    </div>
                    {/* {previousLocation !== "Approval Pending" ? ( */}
                    <div>
                      <button
                        className="createButton"
                        onClick={handleEarning}
                        style={{ marginTop: "10px", marginRight: "10px" }}
                      >
                        Earning Report
                      </button>
                    </div>
                    {/* ) : null} */}
                  </div>
                </>
              ) : (
                <div className="loading-circle">
                  <Loader />
                </div>
              )}
            </div>

            <div
              onClick={toggleBankDetails}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                cursor: "pointer",
                backgroundColor: "#38B000",
                borderRadius: "2px",
                boxShadow: "0px 4px 22px 0px rgba(0, 0, 0, 0.17)",
                color: "#FFF",
                padding: "10px",
                fontFamily: "Lato",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: "700",
                lineHeight: "normal",
              }}
            >
              Bank Details
              <IoIosArrowDown
                style={{
                  color: "#000", 
                  fontSize: "20px", 
                  transform: isBankDetailsOpen
                    ? "rotate(180deg)"
                    : "rotate(0deg)",
                  transition: "transform 0.3s ease",
                }}
              />
            </div>
            <div
              style={{
                maxHeight: isBankDetailsOpen ? "500px" : "0",
                overflow: "hidden",
                transition: "max-height 0.5s ease-in-out",
              }}
            >
              {/* Bank Details Content */}
            </div>

            <div
              style={{
                maxHeight: isBankDetailsOpen ? "500px" : "0",
                overflow: "hidden",
                transition: "max-height 0.5s ease-in-out",
              }}
            >
              {driverDetails ? (
                <div className="user-details">
                  <div className="user-details-section">
                    <div className="user-detail">
                      <div className="user-heading">Beneficiary Name</div>
                      <div className="user-info">
                        {driverDetails.bankDetails.beneficiaryName}
                      </div>
                    </div>
                  </div>
                  <div className="user-details-section">
                    <div className="user-detail">
                      <div className="user-heading">Account No.</div>
                      <div className="user-info">
                        {driverDetails.bankDetails.accountNumber}
                      </div>
                    </div>
                  </div>
                  <div className="user-details-section">
                    <div className="user-detail">
                      <div className="user-heading">IFSCCODE</div>
                      <div className="user-info">
                        {driverDetails.bankDetails.IFSC}
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="loading-circle">
                  <Loader />
                </div>
              )}
            </div>

            <div
              onClick={toggleUploadDocs}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                cursor: "pointer",
                backgroundColor: "#38B000",
                borderRadius: "2px",
                boxShadow: "0px 4px 22px 0px rgba(0, 0, 0, 0.17)",
                color: "#FFF",
                padding: "10px",
                fontFamily: "Lato",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: "700",
                lineHeight: "normal",
              }}
            >
              Upload Documents
              <IoIosArrowDown
                style={{
                  color: "#000", 
                  fontSize: "20px", 
                  transform: isUploadDocsOpen
                    ? "rotate(180deg)"
                    : "rotate(0deg)",
                  transition: "transform 0.3s ease",
                }}
              />
            </div>
            <div
              style={{
                maxHeight: isUploadDocsOpen ? "500px" : "0",
                overflow: "hidden",
                transition: "max-height 0.5s ease-in-out",
              }}
            >
              {/* Upload Documents Content */}
            </div>

            <div
              style={{
                maxHeight: isUploadDocsOpen ? "500px" : "0",
                overflow: "hidden",
                transition: "max-height 0.5s ease-in-out",
              }}
            >
              {driverDetails ? (
                <>
                  <div className="user-details">
                    <div
                      className="file-upload-grid"
                      style={{ padding: "20px", width: "100%" }}
                    >
                      <div
                        className="grid-container"
                        style={{
                          display: "grid",
                          gridTemplateColumns: "repeat(4, 1fr)", // First row: 4 items
                          gridAutoRows: "1fr",
                          rowGap: "20px", // Space between rows
                        }}
                      >
                        {[
                          {
                            label: "Profile Photo",
                            src: driverDetails.driverPhoto,
                          },
                          { label: "Pan Card", src: driverDetails.pan.photo },
                          {
                            label: "Aadhar Card Front",
                            src: driverDetails.aadhaar.frontPhoto,
                          },
                          {
                            label: "Aadhar Card Back",
                            src: driverDetails.aadhaar.backPhoto,
                          },
                          {
                            label: "Driving License Front",
                            src: driverDetails.license.frontPhoto,
                          },
                          {
                            label: "Driving License Back",
                            src: driverDetails.license.backPhoto,
                          },
                          {
                            label: "Police Clearance Certificate",
                            src: driverDetails.policeClearanceCertificatePhoto,
                          },
                        ].map(({ label, src }, index) => (
                          <div
                            className="file-upload-item"
                            key={index}
                            style={{
                              textAlign: "center",
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",
                              marginLeft: index >= 4 ? "100%" : "0", // Adds margin-left for second row
                            }}
                          >
                            <div
                              style={{
                                color: "#000",
                                fontFamily: "Lato",
                                fontSize: "15px",
                                fontWeight: 600,
                                marginBottom: "10px",
                                whiteSpace: "nowrap", // Prevent text wrapping
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                textAlign: "center",
                              }}
                            >
                              {label}
                            </div>

                            <div
                              className="file-upload-container"
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                                width: "150px",
                                height: "150px",
                                border: "6px dashed #CBD0DC",
                                borderRadius: "40px",
                                background: "#FFF",
                                position: "relative",
                              }}
                            >
                              {src ? (
                                <img
                                  className="uploaded-preview"
                                  src={src}
                                  alt={label}
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    objectFit: "cover",
                                    borderRadius: "40px",
                                    position: "absolute",
                                    top: 0,
                                    left: 0,
                                  }}
                                  onClick={() => hanldeImagePopup(src)}
                                />
                              ) : (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="51"
                                  height="50"
                                  viewBox="0 0 51 50"
                                  fill="none"
                                  style={{ marginBottom: "10px" }}
                                >
                                  <path
                                    d="M16.043 40.2909H12.0429C5.91786 39.8534 3.16797 35.1451 3.16797 30.9576C3.16797 26.7701 5.91789 22.0409 11.9387 21.6242C12.7929 21.5409 13.5429 22.2076 13.6054 23.0826C13.6679 23.9367 13.0221 24.6867 12.1471 24.7492C8.10546 25.0409 6.29297 28.0825 6.29297 30.9784C6.29297 33.8742 8.10546 36.9159 12.1471 37.2076H16.043C16.8971 37.2076 17.6055 37.9159 17.6055 38.7701C17.6055 39.6242 16.8971 40.2909 16.043 40.2909Z"
                                    fill="#292D32"
                                  />
                                </svg>
                              )}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>

                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <div className="add-car-driver">
                      <button
                        style={{
                          borderRadius: "5px",
                          margin: "10px",
                          border: "0.5px solid #38B000",
                          background: "#38B000",
                          width: "150px",
                          padding: "6px",
                          color: "#FFF",
                          fontSize: "15px",
                          hover: "cursor",
                        }}
                        onClick={handleModifyButton}
                      >
                        Modify
                      </button>

                      {previousLocation === "Approval Pending" ? (
                        <>
                          <button
                            onClick={() => handleApprovalClick(driverId)}
                            style={{
                              borderRadius: "5px",
                              margin: "10px",
                              border: "0.5px solid #38B000",
                              background: "#38B000",
                              width: "150px",
                              padding: "6px",
                              color: "#FFF",
                              fontSize: "15px",
                            }}
                          >
                            Approve
                          </button>
                          <button
                            onClick={handleReSubmitClick}
                            style={{
                              borderRadius: "5px",
                              margin: "10px",
                              border: "0.5px solid #38B000",
                              background: "#38B000",
                              width: "150px",
                              padding: "6px",
                              color: "#FFF",
                              fontSize: "15px",
                            }}
                          >
                            ReSubmit
                          </button>
                        </>
                      ) : null}
                    </div>
                  </div>
                </>
              ) : (
                <div className="loading-circle">
                  <Loader />
                </div>
              )}
            </div>

            {/* Popup */}
            {showPopup && (
              <div className="popup">
                <div className="popup-content">
                  <span className="close" onClick={handlePopupClose}>
                    &times;
                  </span>
                  <div className="popup-content-checkbox">
                    <div className="popup-checkbox">
                      <input
                        type="checkbox"
                        id="checkbox1"
                        name="checkbox1"
                        value="Document Pending"
                        // checked={reason === "Document Pending"}
                        onChange={handleCheckboxChange}
                      />
                      <label htmlFor="checkbox1">Document Pending</label>
                    </div>
                    <div className="popup-checkbox">
                      <input
                        type="checkbox"
                        id="checkbox1"
                        name="checkbox1"
                        value="Missing Profile Information"
                        // checked={reason === "Missing Profile Information"}
                        onChange={handleCheckboxChange}
                      />
                      <label htmlFor="checkbox1">
                        Missing Profile Information
                      </label>
                    </div>
                  </div>
                  <textarea
                    type="text"
                    value={popupText}
                    placeholder="Enter remark here"
                    onChange={(e) => setPopupText(e.target.value)}
                  />
                  <button onClick={() => handlePopupSubmit(driverId)}>
                    Resubmit
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      }
      {popUpImage && (
        <>
          <div className="imagePopUp">
            <div style={{ marginLeft: "100%", cursor: "pointer" }}>
              <IoIosCloseCircleOutline
                style={{ width: "30px", height: "30px" }}
                onClick={() => setPopUpImage(false)}
              />
            </div>
            <img
              src={displayImage}
              style={{ width: "100%", height: "100%" }}
              alt="Document image"
            />
          </div>
        </>
      )}
    </>
  );
};

export default DriverInfo;
