import axios from "axios";
import React, { useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { BsArrowRight } from "react-icons/bs";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import "./addInteraction.css";
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const Interaction = () => {
  const [selectcontactType, setSelectcontactType] = useState("");
  const [selectqueryType, setSelectqueryType] = useState("");
  const [selectsubQueryType, setSelectsubQueryType] = useState("");
  const [comment, setComment] = useState("");
  const user = useSelector((state) => state.auth.user);
  const { id } = useParams();
  const [showValidation, setShowValidation] = useState(false);

  const contactType = [
    { label: "Inbound", value: "Inbound" },
    { label: "Outbound", value: "Outbound" },
  ];
  const queryType = [
    { label: "Complaint", value: "Complaint" },
    { label: "Feedback", value: "Feedback" },
    {
      label: "Request for Modification",
      value: "Modification",
    },
  ];
  const subQueryType = {
    Complaint: [
      { label: "Driver Complaint", value: "Driver Complaint" },
      { label: "Cab Complaint", value: "Cab Complaint" },
      {
        label: "Billing Issue",
        value: "Billing Issue",
      },
    ],
    Feedback: [
      { label: "Suggestion", value: "Suggestion" },
      { label: "Service Feedback", value: "Feedback" },
    ],
    Modification: [
      { label: "Ride Cancellation ", value: "Cancellation" },
      { label: "Ride Reschedule", value: "Reschedule" },
      { label: "Change in Trip Type ", value: "tripType" },
      { label: "Change in Vehicle Category", value: "vehicleCategory" },
    ],
  };
  console.log("selectcontact Type", selectcontactType);
  const handleSubmit = async (e) => {
    e.preventDefault();
    setShowValidation(true); // show validation errors if any field is empty

    if (
      !selectcontactType ||
      !selectqueryType ||
      !selectsubQueryType ||
      comment.trim() === ""
    ) {
      return;
    }

    const headers = { "Content-Type": "application/json" };
    const data = new FormData();
    data.append("contactType", selectcontactType);
    data.append("queryType", selectqueryType);
    data.append("subQueryType", selectsubQueryType);
    data.append("remarks", comment);

    try {
      await axios.post(
        `${API_BASE_URL}/v1/admin/bookings/interactions/${id}`,
        data,
        { headers }
      );
      toast.success("Interaction added successfully!");
      setTimeout(() => window.location.reload(), 3000);
      e.target.reset();
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  const [open, setOpen] = useState(false);
  console.log("open value", open);
  return (
    <>
      <Toaster />
      <form onSubmit={handleSubmit}>
        <div className="btn">
          <div className="btn-1" style={{ marginLeft: "-15px" }}>
            <button className="main-button">Interaction</button>
            <span className="Dropdown_contact_type">
              <select
                className="contact-type-select"
                placeholder="Contact Type"
                onChange={(e) => setSelectcontactType(e.target.value)}
              >
                <option value="">Contact Type</option>
                {contactType.map((item) => (
                  <option key={item.value} value={item.value}>
                    {item.label}
                  </option>
                ))}
              </select>
              {showValidation && !selectcontactType && (
                <p
                  style={{
                    color: "red",
                    fontSize: "12px",
                    marginBottom: "-10px",
                  }}
                >
                  This field is required
                </p>
              )}
            </span>

            <BsArrowRight className="arrow_icon" />

            <span className="Dropdown_query_type">
              <select
                className="contact-type-select"
                placeholder="Query Type"
                onChange={(e) => setSelectqueryType(e.target.value)}
              >
                <option value="">Query Type</option>
                {queryType.map((item) => (
                  <option key={item.value} value={item.value}>
                    {item.label}
                  </option>
                ))}
              </select>
              {showValidation && !selectqueryType && (
                <p
                  style={{
                    color: "red",
                    fontSize: "12px",
                    marginBottom: "-10px",
                  }}
                >
                  This field is required
                </p>
              )}
            </span>
            <BsArrowRight className="Query_arrow_icon " />
            <span className="Dropdown_contact_type Dropdown_subQuery">
              <select
                className="contact-type-select"
                placeholder="sub Query Type"
                onChange={(e) => setSelectsubQueryType(e.target.value)}
              >
                <option value="">Sub Query Type</option>
                {subQueryType[selectqueryType]?.map((item) => (
                  <option key={item.value} value={item.value}>
                    {item.label}
                  </option>
                ))}
              </select>
              {showValidation && !selectsubQueryType && (
                <p
                  style={{
                    color: "red",
                    fontSize: "12px",
                    marginBottom: "-10px",
                  }}
                >
                  This field is required
                </p>
              )}
            </span>
          </div>
        </div>
        <div className="costm-booking">
          <div className="name-TD">
            {user.firstName} {user.lastName}
          </div>
        </div>
        <div className="border-OTD">
          {/* Input with onChange and value binding */}
          <input
            type="text"
            placeholder="Type your remark of this interaction."
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            style={{
              display: "flex",
              marginTop: "5px",
              width: "100%",
              height: "45px",
              padding: "9px 10px",
              alignItems: "center",
              gap: "10px",
              borderRadius: "10px",
              border: "1px solid #000",
              background: "#FFF",
              boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset",
              outline: "none",
              fontSize: "14px",
            }}
          />
          {showValidation && comment.trim() === "" && (
            <p
              style={{ color: "red", fontSize: "12px", marginBottom: "-10px" }}
            >
              This field is required
            </p>
          )}

          {/* Submit Button disabled conditionally */}
          <div className="submit-OTD">
            <button className="submit-button-OTD" type="submit">
              Submit
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default Interaction;
