import axios from "axios";
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export async function fetchAllCoupons() {
  try {
    const response = await axios.get(`${API_BASE_URL}/v1/admin/coupon-codes`);
    console.log(response.data.data);
    return response.data.data; // Return the data directly
  } catch (error) {
    console.error("Error fetching coupons:", error);
    throw error; // Rethrow the error to be handled by the caller
  }
}

