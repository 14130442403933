import React from "react";
import "./tablesecond.css";
import { useDispatch, useSelector } from "react-redux";
import { selectbookingDetails } from "../DetailsSlice";

const TableFirstItem = ({ item }) => {
  const bookingDetails = useSelector(selectbookingDetails);

  console.log(item);

  return (
    <tr className="firstsecond_billing_table_data">
      <td>
        Paid Online
        <br></br>
        <br></br>
        {item?.advancePaymentReceived >= 0 ? (
          <p>Rs. {item?.advancePaymentReceived}</p>
        ) : (
          <p>No payment details found</p>
        )}

        {/* Previous Code */}
        {/* {item?.finalOnline >=0 ? (
          <p>Rs. {item?.finalOnline}</p>
        ) : (
          <p>No payment details found</p>
        )} */}
      </td>

      <td>
        Paid Cash
        <br></br>
        <br></br>
        {item?.finalCash >= 0 ? (
          <p>Rs. {item?.finalCash}</p>
        ) : (
          <p>No payment details found</p>
        )}
      </td>
    </tr>
  );
};

const Table = ({ bookingDetailsTableSecond }) => {
  console.log(bookingDetailsTableSecond);
  return (
    <div className="billing_tablesecond_containers">
      <div className="billing_table_scroll">
        <table className="billing_table">
          <thead>
            <th style={{ borderRadius: "0" ,textAlign:"center"}} colSpan={2}>
              Mode Of Payment
            </th>
          </thead>
          <tbody>
            {bookingDetailsTableSecond.map((item, index) => (
              <TableFirstItem key={index} item={item} />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const TableFirst = ({ bookingDetailsTableSecond }) => {
  console.log(bookingDetailsTableSecond);

  return <Table bookingDetailsTableSecond={[bookingDetailsTableSecond]} />;
};

export default TableFirst;
