import axios from "axios";
import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { CiFilter, CiSearch } from "react-icons/ci";
import { FaPen } from "react-icons/fa";
import { ImEye } from "react-icons/im";
import { IoIosArrowDown } from "react-icons/io";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Toggle } from "../TariffManagement/Toggle";
import { resetCarDetails } from "./CarDetailsSlice";
import { fetchviewallcarFun, toggleCarStatus } from "./carSlice";
import "./SharedDropdownAndInput.css";
import "./ViewAllCar.css";

const TableItem = ({ item, index, isRowSelected }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [editDetails, setEditDetails] = useState(false);
  const rowClass = index % 2 === 0 ? "table-row-even" : "table-row-odd";
  const selectedRowClass = isRowSelected ? "table-row-selected" : "";
  const currentComponent = "viewAllCar";

  index = index + 1;

  const handleClick = () => {
    navigate(`/carManagement/${currentComponent}/car-details/${item._id}`);
  };

  const handleEditIcon = () => {
    // dispatch(currentDriverId(id));
    // dispatch(currentCarId(item._id));
    dispatch(resetCarDetails());
    navigate(
      `/carManagement/${currentComponent}/modify-car-details/${item._id}`
    );
  };

  const handleCarStatus = async (carId, newStatus, dispatch) => {
    try {
      const response = await axios.put(
        `https://api.yatricabs.com/v1/admin/cars/${carId}/status`,
        {
          status: newStatus,
          reason: "Something Expired",
        }
      );
      console.log("Response:", response.data);
      dispatch(toggleCarStatus({ carId, newStatus }));
      dispatch(fetchviewallcarFun());
    } catch (error) {
      console.error("Error toggling car status:", error);
    }
  };

  const logState = (state, carId, dispatch) => {
    const newStatus = state === true ? "Inactive" : "Active";
    handleCarStatus(carId, newStatus, dispatch);
  };

  return (
    <tr className={`${rowClass} ${selectedRowClass}`}>
      <td>{index}</td>
      <td>
        {item?.location?.city}, {item?.location?.state}
      </td>
      <td>{item?.driver ? "Outsourced" : "Company Owned"}</td>
      <td>{item?.carCategory?.category}</td>
      <td>
        {item?.carMake} - {item?.carModel}
      </td>
      <td>{item?.registrationCertificate?.number}</td>
      <td>
        {item.driver
          ? `${item?.driver?.firstName} ${item?.driver?.lastName}`
          : "Yatri Cabs"}
      </td>
      <td>{item?.driver ? `${item?.driver?.phone} ` : "+91-0000000000"}</td>
      <td>
        <img
          className="car-image"
          src={item?.carPhoto?.sidePhoto}
          alt="car"
          width="58"
          height="78"
        />
      </td>
      <td>{item?.createdAt || "19-01-2024"}</td>
      <td>{item?.status?.state}</td>
      <td>{item.status.state === "Active" ? "---" : "Something expired"}</td>
      <td>
        <div className="box-action">
          <Toggle
            toggled={item?.status?.state === "Inactive" ? true : false}
            onClick={(state) => logState(state, item._id, dispatch)}
          />
          {/* <ImEye className="icon icon-large" onClick={handleClick} /> */}
          <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-large" onClick={handleClick} width="19" height="15" viewBox="0 0 19 15" fill="none">
            <path d="M9.37337 0C6.74339 0 4.63746 1.23214 3.10439 2.69866C1.58108 4.15179 0.562288 5.89286 0.0805594 7.08817C-0.0268531 7.35268 -0.0268531 7.64732 0.0805594 7.91183C0.562288 9.10714 1.58108 10.8482 3.10439 12.3013C4.63746 13.7679 6.74339 15 9.37337 15C12.0034 15 14.1093 13.7679 15.6424 12.3013C17.1657 10.8449 18.1845 9.10714 18.6694 7.91183C18.7769 7.64732 18.7769 7.35268 18.6694 7.08817C18.1845 5.89286 17.1657 4.15179 15.6424 2.69866C14.1093 1.23214 12.0034 0 9.37337 0ZM4.68628 7.5C4.68628 6.22128 5.1801 4.99493 6.0591 4.09073C6.9381 3.18654 8.13028 2.67857 9.37337 2.67857C10.6165 2.67857 11.8086 3.18654 12.6876 4.09073C13.5666 4.99493 14.0605 6.22128 14.0605 7.5C14.0605 8.77872 13.5666 10.0051 12.6876 10.9093C11.8086 11.8135 10.6165 12.3214 9.37337 12.3214C8.13028 12.3214 6.9381 11.8135 6.0591 10.9093C5.1801 10.0051 4.68628 8.77872 4.68628 7.5ZM9.37337 5.35714C9.37337 6.53906 8.43921 7.5 7.29022 7.5C7.05912 7.5 6.83779 7.45982 6.62947 7.38951C6.45045 7.32924 6.24213 7.44308 6.24864 7.63728C6.25841 7.8683 6.29096 8.09933 6.3528 8.33036C6.79873 10.0446 8.51407 11.0625 10.1806 10.6038C11.8471 10.1451 12.8366 8.38058 12.3907 6.66629C12.0294 5.27679 10.8348 4.34263 9.50683 4.28571C9.31804 4.27902 9.20737 4.48995 9.26596 4.67745C9.33431 4.89174 9.37337 5.11942 9.37337 5.35714Z" fill="#3EC100" />
          </svg>

          {/* <FaPen className="icon-edit " onClick={handleEditIcon} /> */}
          <svg xmlns="http://www.w3.org/2000/svg" onClick={handleEditIcon} width="18" height="15" viewBox="0 0 18 15" fill="none">
            <path d="M12.717 0.549872L11.1201 1.96927L15.4094 5.78172L17.0063 4.36232C17.8312 3.62915 17.8312 2.44143 17.0063 1.70827L15.7063 0.549872C14.8815 -0.183291 13.5452 -0.183291 12.7203 0.549872H12.717ZM10.3744 2.63205L2.68327 9.47099C2.34012 9.77599 2.08936 10.1543 1.95078 10.5678L0.782766 14.0958C0.700279 14.3451 0.776167 14.6119 0.980735 14.7937C1.1853 14.9756 1.48556 15.043 1.76271 14.9726L5.732 13.9345C6.19723 13.8113 6.62286 13.5884 6.96601 13.2834L14.6637 6.4445L10.3744 2.63205Z" fill="#0C41FF" />
          </svg>
        </div>
      </td>
    </tr>
  );
};

const Table = ({ headers, data }) => {
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);

  const handleRowClick = (index) => {
    setSelectedRowIndex(index);
  };
  return (
    <div className="table-containers">
      <div className="table-scroll">
        <table className="approvalpending-table">
          <thead>
            <tr>
              {headers.map((header, index) => (
                <th key={index}>{header}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data &&
              data.map((item, index) => (
                <TableItem
                  key={index}
                  item={item}
                  index={index}
                  isRowSelected={selectedRowIndex === index}
                  handleRowClick={handleRowClick}
                />
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const ViewAllCar = () => {
  const [selectedLocation, setSelectedLocation] = useState("");
  const [selectedSourceType, setselectedSourceType] = useState("");
  const [selectedMake, setselectedMake] = useState("");
  const [selectedCarCategory, setselectedCarCategory] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [searchById, setSearchById] = useState("");
  const [searchPhoneNumber, setsearchPhoneNumber] = useState("");
  const [searchByName, setSearchByName] = useState("");
  const [selectedModel, setSelectedModel] = useState("");
  const [locationOptions, setLocationOptions] = useState([]);
  const [carCategoriesOptions, setCarCategoriesOptions] = useState([]);
  const [makeOptions, setMakesOptions] = useState([]);
  const [modelOptions, setModelOptions] = useState([]);
  const headers = [
    "Sr no.",
    "Location",
    "Source Type",
    "Car Category",
    "Models & Make",
    "Vehicle No.",
    "Owner Name",
    "Owner No.",
    "Image",
    "Created On",
    "Status",
    "Reason",
    "Action",
  ];

  const dispatch = useDispatch();
  const car = useSelector((state) => state.car.cardata);

  useEffect(() => {
    dispatch(fetchviewallcarFun());
  }, []);

  useEffect(() => {
    const extractCategoriesAsOptions = (dataArray) => {
      if (Array.isArray(dataArray.data)) {
        return dataArray.data.map((item) => ({
          label: item.category,
          value: item.category,
        }));
      }
      return [];
    };

    const extractLocationsAsOptions = (dataArray) => {
      if (Array.isArray(dataArray.data)) {
        return dataArray.data.map((item) => ({
          label: item.location,
          value: item.location,
        }));
      }
      return [];
    };

    const extractMakeAsOptions = (dataArray) => {
      if (Array.isArray(dataArray.data)) {
        return dataArray.data.map((item) => ({
          label: item.make,
          value: item.make,
        }));
      }
      return [];
    };

    const fetchOptions = async () => {
      try {
        const locationResponse = await fetch(
          "https://api.yatricabs.com/v1/locations"
        );
        const locationData = await locationResponse.json();
        const locationOptions = extractLocationsAsOptions(locationData);
        // console.log(locationOptions);
        setLocationOptions(locationOptions);
        const carResponse = await fetch(
          "https://api.yatricabs.com/v1/car-categories"
        );
        const carData = await carResponse.json();
        const carCategoriesOptions = extractCategoriesAsOptions(carData);
        // console.log(carCategoriesOptions);
        setCarCategoriesOptions(carCategoriesOptions);
        const makeResponse = await fetch(
          "https://api.yatricabs.com/v1/car-makes"
        );
        const makeData = await makeResponse.json();
        const makeOptions = extractMakeAsOptions(makeData);
        // console.log(makeOptions);
        setMakesOptions(makeOptions);
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };
    fetchOptions();
  }, []);

  useEffect(() => {
    const extractModelAsOptions = (dataArray) => {
      if (Array.isArray(dataArray.data)) {
        return dataArray.data.map((item) => ({
          label: item.models,
          value: item.models,
        }));
      }
      return [];
    };
    const fetchCarModels = async () => {
      try {
        if (selectedMake) {
          const modelMakeResponse = await fetch(
            `https://api.yatricabs.com/v1/car-models/${selectedMake}`
          );
          const modelData = await modelMakeResponse.json();
          const modelOptions = extractModelAsOptions(modelData);
          console.log("car model", modelOptions);
          setModelOptions(modelOptions);
        } else {
          setModelOptions([]);
        }
      } catch (error) {
        console.error("Error fetching sub-trip types: ", error);
      }
    };
    fetchCarModels();
  }, [selectedMake]);
  const statusOptions = [
    { label: "Active", value: "Active" },
    { label: "Inactive", value: "Inactive" },
  ];

  console.log("Car all:-", car);

  return (
    <div>
      <div className="dropdown-container">
        <div className="main-1">
          <div>Filters</div>
          <div className="icon-1">
            <CiFilter color="#38b000" size={18} />
          </div>
        </div>
        <select
          className="dropdown-select-1"
          placeholder="Select Location"
          onChange={(e) => setSelectedLocation(e.target.value)}
        >
          <option value="">Select Location</option>

          {locationOptions.map((item) => (
            <option key={item.value} value={item.value}>
              {item.label}
            </option>
          ))}
        </select>
        <IoIosArrowDown className="dropdown-icon" />
        {/* <DropdownColumn
          placeholder="Select Source Type"
          options={[
            { label: "Company Owned", value: "Company Owned" },
            { label: "Outsourced", value: "Outsourced" },
          ]}
          selectedValue={selectedSourceType}
          handleChange={(e) => setselectedSourceType(e.target.value)}
          className="dropdown"
        /> */}

        <select
          className="dropdown-select-1 "
          id="selectCarCategory"
          placeholder="Select Car Category"
          onChange={(e) => setselectedCarCategory(e.target.value)}
        >
          <option value="">Select Car Category</option>

          {carCategoriesOptions.map((item) => (
            <option key={item.value} value={item.value}>
              {item.label}
            </option>
          ))}
        </select>
        <IoIosArrowDown className="dropdown-icon" />
        <select
          className="dropdown-select-1"
          placeholder="vehicle Make"
          onChange={(e) => setselectedMake(e.target.value)}
        >
          <option value="">vehicle Make</option>

          {makeOptions.map((item) => (
            <option key={item.value} value={item.value}>
              {item.label}
            </option>
          ))}
        </select>
        <IoIosArrowDown className="dropdown-icon" />
        <select
          className="dropdown-select-1"
          placeholder="vehicle Model"
          onChange={(e) => setSelectedModel(e.target.value)}
        >
          <option value="">vehicle Model</option>

          {modelOptions.flatMap((item, index) =>
            item.label.map((model, modelIndex) => (
              <option
                key={item.value[modelIndex]}
                value={item.value[modelIndex]}
              >
                {model}
              </option>
            ))
          )}
        </select>
        <IoIosArrowDown className="dropdown-icon" />

        <select
          className="dropdown-select-1"
          placeholder="Select Status"
          onChange={(e) => setSelectedStatus(e.target.value)}
        >
          <option value=""> Select Status</option>

          {statusOptions.map((item) => (
            <option key={item.value} value={item.value}>
              {item.label}
            </option>
          ))}
        </select>
        <IoIosArrowDown className="dropdown-icon" />
        <input
          type="text"
          placeholder="Search by Driver Name"
          className="inputText dropdown-select-1"
          selectedValue={searchByName}
          onChange={(e) => setSearchByName(e.target.value)}
        />
        <CiSearch className="dropdown-icon search007" />

        <input
          type="text"
          placeholder="Search by Mobile No."
          className="inputText dropdown-select-1"
          value={searchPhoneNumber}
          onChange={(e) => setsearchPhoneNumber(e.target.value)}
        />
        <CiSearch className="dropdown-icon search007" />

        <input
          type="text"
          className="dropdown-select-1 inputText "
          placeholder="Search by Vehicle No."
          value={searchById}
          onChange={(e) => setSearchById(e.target.value)}
        />
        <CiSearch className="dropdown-icon search007" />
      </div>

      <Table
        headers={headers}
        data={car.filter((item) => {
          return (
            (selectedLocation
              ? `${item?.location?.city}, ${item?.location?.state}` ===
              selectedLocation
              : true) &&
            (selectedSourceType
              ? selectedSourceType === "Outsourced"
                ? item?.driver
                : !item?.driver
              : true) &&
            (selectedMake
              ? item?.carMake === selectedMake &&
              (selectedModel ? item?.carModel === selectedModel : true)
              : true) &&
            (selectedCarCategory
              ? item?.carCategory?.category === selectedCarCategory
              : true) &&
            (searchById
              ? item?.registrationCertificate?.number
                .toLowerCase()
                .includes(searchById.toLowerCase())
              : true) &&
            (searchByName
              ? `${item?.driver?.firstName} ${item?.driver?.lastName}`
                .toLowerCase()
                .includes(searchByName.toLowerCase())
              : true) &&
            (searchPhoneNumber
              ? item?.driver?.phone
                .toLowerCase()
                .includes(searchPhoneNumber.toLowerCase())
              : true) &&
            (selectedStatus ? item?.status === selectedStatus : true)
          );
        })}
      />
    </div>
  );
};

export default ViewAllCar;
