import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CiFilter } from "react-icons/ci";
import "./CorporateUser.css";

import {
  fetchCorporateUsers, // instead of fetchCorporateBookings
  selectAllCorporateUsers, // instead of selectAllCorporateBookings
} from "./CorporateBookingSlice"; // or from the correct file

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export default function CorporateUser() {
  const dispatch = useDispatch();

  // Grab the corporate users from your Redux store
  const corporateUsers = useSelector(selectAllCorporateUsers) || [];

  // Local state for filters
  const [selectedStatus, setSelectedStatus] = useState("");

  const [selectedlocation, setSelectedlocation] = useState(""); // e.g., city/state
  const [searchCompanyName, setSearchCompanyName] = useState("");

  // location (or city) dropdown options - you can fetch them from your API if needed
  const [locationOptions, setlocationOptions] = useState([]);

  useEffect(() => {
    // Fetch the corporate users on component mount
    dispatch(fetchCorporateUsers());
  }, [dispatch]);

  useEffect(() => {
    // Example fetch for location/city options (if your API has a locations endpoint)
    const fetchlocations = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/v1/locations`);
        const data = await response.json();
        if (Array.isArray(data?.data)) {
          const mapped = data.data.map((loc) => ({
            label: loc.location,
            value: loc.location,
          }));
          setlocationOptions(mapped);
        }
      } catch (error) {
        console.error("Error fetching location options:", error);
      }
    };
    fetchlocations();
  }, []);

  // Filtered list based on location and company name
  const filteredUsers = corporateUsers.filter((user) => {
    const matcheslocation = selectedlocation
      ? user.corporateOffice === selectedlocation
      : true;
    const matchesCompany = searchCompanyName
      ? user.corporateName
          ?.toLowerCase()
          .includes(searchCompanyName.toLowerCase())
      : true;
    const matchesStatus = selectedStatus
      ? user.status === selectedStatus
      : true;

    return matcheslocation && matchesCompany && matchesStatus;
  });

  return (
    <div>
      {/* Filter Bar */}
      <div className="corporate-dropdown-main">
        <div className="corporate-filter-header">
          <div className="filter-name">Filters</div>
          <div className="corporate-filter-icon">
            <CiFilter color="#38b000" size={18} />
          </div>
        </div>

        <div className="corporate-ele">
          {/* location (or City) dropdown */}
          <select
            className="corporate-dropdown-select"
            value={selectedlocation}
            onChange={(e) => setSelectedlocation(e.target.value)}
          >
            <option value="">Location</option>
            {locationOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
          <select
            className="corporate-dropdown-select"
            value={selectedStatus}
            onChange={(e) => setSelectedStatus(e.target.value)}
          >
            <option value="">Status</option>
            <option value="Confirmed">Confirmed</option>
            <option value="Cab Assigned">Cab Assigned</option>
            <option value="In Route">In Route</option>
            <option value="Completed">Completed</option>
            <option value="Cancelled">Cancelled</option>
          </select>

          {/* Search by Company Name */}
          <div className="search-container">
            <input
              type="text"
              placeholder="Company Name"
              value={searchCompanyName}
              onChange={(e) => setSearchCompanyName(e.target.value)}
            />
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
            >
              <path
                d="M11.3756 5.68667C11.3756 6.94156 10.9681 8.10077 10.2818 9.04125L13.7436 12.5052C14.0855 12.8469 14.0855 13.4019 13.7436 13.7437C13.4018 14.0854 12.8467 14.0854 12.5049 13.7437L9.04302 10.2797C8.10235 10.9687 6.94292 11.3733 5.68778 11.3733C2.54583 11.3733 0 8.828 0 5.68667C0 2.54533 2.54583 0 5.68778 0C8.82973 0 11.3756 2.54533 11.3756 5.68667ZM5.68778 9.62359C6.20488 9.62359 6.71692 9.52176 7.19467 9.32391C7.67241 9.12606 8.1065 8.83607 8.47215 8.47049C8.8378 8.10491 9.12784 7.67091 9.32573 7.19326C9.52362 6.71561 9.62547 6.20367 9.62547 5.68667C9.62547 5.16966 9.52362 4.65772 9.32573 4.18007C9.12784 3.70242 8.8378 3.26842 8.47215 2.90284C8.1065 2.53726 7.67241 2.24727 7.19467 2.04942C6.71692 1.85158 6.20488 1.74974 5.68778 1.74974C5.17067 1.74974 4.65863 1.85158 4.18089 2.04942C3.70315 2.24727 3.26906 2.53726 2.90341 2.90284C2.53776 3.26842 2.24771 3.70242 2.04982 4.18007C1.85194 4.65772 1.75009 5.16966 1.75009 5.68667C1.75009 6.20367 1.85194 6.71561 2.04982 7.19326C2.24771 7.67091 2.53776 8.10491 2.90341 8.47049C3.26906 8.83607 3.70315 9.12606 4.18089 9.32391C4.65863 9.52176 5.17067 9.62359 5.68778 9.62359Z"
                fill="#8B8B8B"
              />
            </svg>
          </div>
        </div>
      </div>

      {/* Table */}
      <div className="corporate-table-container">
        <table className="corporate-table">
          <thead className="corporate-head">
            <tr>
              <th className="corporate-th">Sr No.</th>
              <th className="corporate-th">Corporate Name</th>
              <th className="corporate-th">Manager</th>
              <th className="corporate-th">Contact Details</th>
              <th className="corporate-th">Email address</th>
              <th className="corporate-th">Corporate office</th>
              <th className="corporate-th">Created on</th>
              <th className="corporate-th">Status</th>
              <th className="corporate-th">Billing Type</th>
              <th className="corporate-th">Actions</th>
            </tr>
          </thead>
          <tbody className="corporate-body">
            {filteredUsers.map((user, index) => (
              <tr key={user.id}>
                <td>{index + 1}</td>
                <td>{user.corporateName}</td>
                <td>{user.manager}</td>
                <td>{user.contactDetails}</td>
                <td>{user.emailAddress}</td>
                <td>{user.corporateOffice}</td>
                <td>{user.createdOn}</td>
                <td>{user.status}</td>
                <td>{user.billingType}</td>
                <td>
                  {/* Example toggle or action buttons */}
                  {/* You can replace with icons, toggles, etc. */}
                  <button>Edit</button>
                  <button>Toggle</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
