// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.second_table{
    padding-left: 4rem;
}.earning_container .second_table_containers table{
    border-collapse: collapse;
    width: 60%;
    margin-bottom: -50px;
}

.earning_container .second_table_containers  th{
    padding: 10px 8px;
    color: white;
    background-color: #38b000;
    box-shadow: 0px 4px 18px 0px #38b00040;
    font-size: 14px;
    width: 8rem;
    border:1px solid black;
}

.second_Earning_table_data td{
    border: 1px solid black;
    text-align: center;
    font-size: 0.8rem;
}
.second_Earning_table_data td:first-child{
    text-align: left;
}`, "",{"version":3,"sources":["webpack://./src/components/DriverManagement/earning/earningtable/secondtable.css"],"names":[],"mappings":";AACA;IACI,kBAAkB;AACtB,CAAC;IACG,yBAAyB;IACzB,UAAU;IACV,oBAAoB;AACxB;;AAEA;IACI,iBAAiB;IACjB,YAAY;IACZ,yBAAyB;IACzB,sCAAsC;IACtC,eAAe;IACf,WAAW;IACX,sBAAsB;AAC1B;;AAEA;IACI,uBAAuB;IACvB,kBAAkB;IAClB,iBAAiB;AACrB;AACA;IACI,gBAAgB;AACpB","sourcesContent":["\n.second_table{\n    padding-left: 4rem;\n}.earning_container .second_table_containers table{\n    border-collapse: collapse;\n    width: 60%;\n    margin-bottom: -50px;\n}\n\n.earning_container .second_table_containers  th{\n    padding: 10px 8px;\n    color: white;\n    background-color: #38b000;\n    box-shadow: 0px 4px 18px 0px #38b00040;\n    font-size: 14px;\n    width: 8rem;\n    border:1px solid black;\n}\n\n.second_Earning_table_data td{\n    border: 1px solid black;\n    text-align: center;\n    font-size: 0.8rem;\n}\n.second_Earning_table_data td:first-child{\n    text-align: left;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
