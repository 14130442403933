import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchLocalTariff } from "./VendorTarrifSlice"; // Updated to call specific thunk
import "./LocalTarrif.css";
import { FaPen } from "react-icons/fa";
import { Toggle } from "./Toggle";
import { CiFilter } from "react-icons/ci";

import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { setSelectedTariff } from "./VendorTarrifSlice";
import axios from "axios";
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const TableItem = ({ item, index }) => {
	const [toggleState, setToggleState] = useState(false); // Local state for toggle

	const updateTariffStatus = async (id, status) => {
		try {
			const response = await axios.put(
				`${API_BASE_URL}/v1/admin/vendor-tariffs/${id}/status`,
				{ status },
			);

			if (response.status === 200) {
				console.log(`Tariff ${id} updated successfully to ${status}`);
			} else {
				console.error("Failed to update tariff status:", response.statusText);
			}
		} catch (error) {
			console.error("Error updating tariff status:", error);
		}
	};

	const logState = async (state) => {
		console.log("Toggled:", state);
		setToggleState(state);

		const newStatus = state ? "Inactive" : "Active";
		console.log("NEW STATUS_>>>>>>>>>>>>>>", newStatus);
		await updateTariffStatus(item._id, newStatus); // Call the API with the appropriate status
		alert("Status Change Successful");
	};

	const dispatch = useDispatch();
	const navigate = useNavigate();

	const handleEditClick = () => {
		dispatch(setSelectedTariff({ id: item._id, tripType: item.tripType })); // Store the ID and tripType in Redux
		console.log("Row clicked. ID and Trip Type stored in Redux:", {
			id: item._id,
			tripType: item.tripType,
		});
		navigate("/vendortarrifmanagement/update-vendor-tariff"); // Navigate to update page
	};

	const formatDate = (dateString) => {
		if (!dateString) return "NA";
		const date = new Date(dateString);
		return `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
	};

	return (
		<tr>
			<td>{index + 1}</td>
			<td>{`${item.location?.city || "NA"}, ${
				item.location?.state || "NA"
			}`}</td>
			<td>{item.carCategory?.category || "NA"}</td>
			<td>{item.tripType || "NA"}</td>
			<td>{item.subTripType || "NA"}</td>
			<td>{formatDate(item.createdAt)}</td>
			<td>{item.fareDetails?.baseFare || "NA"}</td>
			<td>{`${item.fareDetails?.minKm || "NA"} Km`}</td>
			<td>{`${item.fareDetails?.minHrs || "NA"} hrs`}</td>
			<td>{`Rs. ${item.fareDetails?.extraKmCharge || "NA"}`}</td>
			<td>{`Rs. ${item.fareDetails?.extraHrsCharge || "NA"}`}</td>
			<td>{`Rs. ${item.fareDetails?.nightDriverAllowance || "NA"}`}</td>
			<td>
				{item.fareDetails?.tds != null
					? `${
							item.fareDetails.tds < 10
								? "0" + item.fareDetails.tds
								: item.fareDetails.tds
					  } %`
					: "NA %"}
			</td>

			<td>
				<div className="box-action">
					<Toggle toggled={toggleState} onClick={(state) => logState(state)} />
					<FaPen
						style={{ marginLeft: "20px" }}
						className="icon-edit"
						onClick={handleEditClick} // Handle pencil button click
					/>
				</div>
			</td>
		</tr>
	);
};

const Table = ({ headers, data }) => {
	return (
		<div className="view-coupon-table-container">
			<table className="view-coupon-table">
				<thead>
					<tr>
						{headers.map((header, index) => (
							<th key={index}>{header}</th>
						))}
					</tr>
				</thead>
				<tbody>
					{data.map((item, index) => (
						<TableItem key={index} item={item} index={index} />
					))}
				</tbody>
			</table>
		</div>
	);
};

const LocalTarrif = () => {
	const [locationOptions, setLocationOptions] = useState([]);
	const [carCategoryOptions, setCarCategoryOptions] = useState([]);
	const [tripTypeOptions, setTripTypeOptions] = useState([]);
	const [subTripTypeOptions, setSubTripTypeOptions] = useState([]);

	const [selectedLocation, setSelectedLocation] = useState("");
	const [selectedCarType, setSelectedCarType] = useState("");
	const selectedTripType = "Local";
	const [selectedSubTripType, setSelectedSubTripType] = useState("");
	const [selectedDate, setSelectedDate] = useState("");

	const dispatch = useDispatch();
	const tariffs = useSelector(
		(state) => state.tarrifDetails.tarrifDetails || [],
	);
	const loading = useSelector((state) => state.tarrifDetails.loading);

	useEffect(() => {
		// Fetch filter options from APIs
		const fetchOptions = async () => {
			try {
				const locationResponse = await fetch(
					`${API_BASE_URL}/v1/locations`,
				);
				const carCategoryResponse = await fetch(
					`${API_BASE_URL}/v1/car-categories`,
				);
				const tripTypeResponse = await fetch(
					`${API_BASE_URL}/v1/trip-types`,
				);

				const [locations, carCategories, tripTypes] = await Promise.all([
					locationResponse.json(),
					carCategoryResponse.json(),
					tripTypeResponse.json(),
				]);

				setLocationOptions(
					locations.data.map((item) => ({
						label: item.location, // A string representation
						value: item.location, // Unique identifier for the option
					})),
				);
				setCarCategoryOptions(
					carCategories.data.map((item) => ({
						label: item.category,
						value: item.category,
					})),
				);
				setTripTypeOptions(
					tripTypes.data.map((item) => ({
						label: item.tripType,
						value: item.tripType,
					})),
				);
			} catch (error) {
				console.error("Error fetching filter options:", error);
			}
		};

		fetchOptions();
	}, []);

	useEffect(() => {
		if (selectedTripType) {
			const fetchSubTripTypes = async () => {
				try {
					const response = await fetch(
						`${API_BASE_URL}/v1/sub-trip-types/${selectedTripType}`,
					);
					const data = await response.json();
					setSubTripTypeOptions(
						data.data[0].subTripTypes.map((subTripType) => ({
							label: subTripType,
							value: subTripType,
						})),
					);
				} catch (error) {
					console.error("Error fetching sub-trip types:", error);
				}
			};

			fetchSubTripTypes();
		} else {
			setSubTripTypeOptions([]);
		}
	}, [selectedTripType]);

	useEffect(() => {
		dispatch(fetchLocalTariff());
	}, [dispatch]);

	const headers = [
		"Sr. No.",
		"Location",
		"Car Type",
		"Trip Type",
		"Sub Trip Type",
		"Created On",
		"Base Fare",
		"Min Km Included",
		"Min Hrs Included",
		"Extra Km",
		"Extra Hrs",
		"Night Charges",
		"TDS",
		"",
	];

	const filteredTariffs = tariffs.filter((tariff) => {
		const matchesLocation =
			!selectedLocation ||
			`${tariff.location?.city || ""}, ${tariff.location?.state || ""}` ===
				selectedLocation;

		const matchesCarType =
			!selectedCarType || tariff.carCategory?.category === selectedCarType;

		const matchesTripType =
			!selectedTripType || tariff.tripType === selectedTripType;
		const matchesSubTripType =
			!selectedSubTripType || tariff.subTripType === selectedSubTripType;
		const matchesDate =
			!selectedDate || tariff.createdOn.startsWith(selectedDate);

		return (
			matchesLocation &&
			matchesCarType &&
			matchesTripType &&
			matchesSubTripType &&
			matchesDate
		);
	});

	return (
		<div>
			<div className="filter-container">
				<div className="filter-header-local">
					<span>Filters</span>
					<CiFilter size={18} color="#38b000" />
				</div>
				<div className="filters">
					<select
						className="filter-dropdown"
						value={selectedLocation}
						onChange={(e) => setSelectedLocation(e.target.value)}
					>
						<option value="">Select Location</option>
						{locationOptions.map((option) => (
							<option key={option.value} value={option.value}>
								{option.label}
							</option>
						))}
					</select>
					<select
						className="filter-dropdown"
						value={selectedCarType}
						onChange={(e) => setSelectedCarType(e.target.value)}
					>
						<option value="">Select Car Category</option>
						{carCategoryOptions.map((option) => (
							<option key={option.value} value={option.value}>
								{option.label}
							</option>
						))}
					</select>
					{/* <select
            className="filter-dropdown"
            value={selectedTripType}
            onChange={(e) => setSelectedTripType(e.target.value)}
          >
            <option value="">Select Trip Type</option>
            {tripTypeOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select> */}
					<select
						className="filter-dropdown"
						value={selectedSubTripType}
						onChange={(e) => setSelectedSubTripType(e.target.value)}
					>
						<option value="">Select Sub Trip Type</option>
						{subTripTypeOptions.map((option) => (
							<option key={option.value} value={option.value}>
								{option.label}
							</option>
						))}
					</select>
					<input
						type="date"
						className="filter-calender"
						value={selectedDate}
						onChange={(e) => setSelectedDate(e.target.value)}
					/>
				</div>
			</div>
			<div className="Table-Wrapper-Local ">
				{loading === "Loading" ? (
					<p>Loading...</p>
				) : (
					<Table headers={headers} data={filteredTariffs} />
				)}
			</div>
		</div>
	);
};

export default LocalTarrif;
