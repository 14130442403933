
// import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// // import { fetchingActiveLocations, fetchingInActiveLocations,fetchingLocations } from "./GeograpyApi";
// import { fetchviewallcar, fetchpendingcardata, fetchinactivecardata } from "./carsAPI";

// const initialState = {
//   cardata: [],
//   pendingcardata: [],
//   inactivecardata: [],
//   status: null,
//   currentComp: null,
//   isLoading: false,
//   isError: false,
//   addednewcity: false,
// };


// export const fetchviewallcarFun = createAsyncThunk("fetchviewallcar", async () => {
//   try {
//     const response = await fetchviewallcar();
//     return response;
//   } catch (e) {
//     console.log("Error in fetching Data", e);
//     throw e;
//   }
// });

// export const fetchpendingcardataFun = createAsyncThunk("fetchpendingcardata", async () => {
//   try {
//     const response = await fetchpendingcardata();
//     return response;
//   } catch (e) {
//     console.log("Error in fetching Data", e);
//     throw e;
//   }
// });

// export const fetchinactivecardataFun = createAsyncThunk("fetchinactivecardata", async () => {
//   try {
//     const response = await fetchinactivecardata();
//     return response;
//   } catch (e) {
//     console.log("Error in fetching Data", e);
//     throw e;
//   }
// });




// const carSlice = createSlice({
//   name: "car",
//   initialState,
//   reducers: {
//     resetCompState: (state, action) => {
//       state.currentComp = action.payload;
//     },
//     toggleCarStatus: (state, action) => {
//       console.log(state,action)
//       const { carId, newStatus } = action.payload;
//       console.log( carId)
//       console.log(newStatus )
//       const carIndex = state.cardata.findIndex(car => car._id === carId);
//       if (carIndex !== -1) {
//         state.cardata[carIndex].status = newStatus;
//       }
//     }
//   },
//   extraReducers: (builder) => {
//     builder
//       // For fetching all car data
//       .addCase(fetchviewallcarFun.pending, (state, action) => {
//         state.isLoading = true;
//       })
//       .addCase(fetchviewallcarFun.fulfilled, (state, action) => {
//         state.isLoading = false;
//         state.status = "idle";
//         state.cardata = action.payload;
//       })
//       .addCase(fetchviewallcarFun.rejected, (state, action) => {
//         state.isLoading = false;
//         console.log("Error", action.payload);
//         state.isError = true;
//       })
//       // For fetching pending car data
//       .addCase(fetchpendingcardataFun.pending, (state, action) => {
//         state.isLoading = true;
//       })
//       .addCase(fetchpendingcardataFun.fulfilled, (state, action) => {
//         state.isLoading = false;
//         state.status = "idle";
//         state.pendingcardata = action.payload;
//       })
//       .addCase(fetchpendingcardataFun.rejected, (state, action) => {
//         state.isLoading = false;
//         console.log("Error", action.payload);
//         state.isError = true;
//       })
//       // For fetching inactive car data
//       .addCase(fetchinactivecardataFun.pending, (state, action) => {
//         state.isLoading = true;
//       })
//       .addCase(fetchinactivecardataFun.fulfilled, (state, action) => {
//         state.isLoading = false;
//         state.status = "idle";
//         state.inactivecardata = action.payload;
//       })
//       .addCase(fetchinactivecardataFun.rejected, (state, action) => {
//         state.isLoading = false;
//         console.log("Error", action.payload);
//         state.isError = true;
//       })

//     // .addCase(fetchsinglecardataFun.pending, (state, action) => {
//     //   state.isLoading = true;
//     // })
//     // .addCase(fetchsinglecardataFun.fulfilled, (state, action) => {
//     //   state.isLoading = false;
//     //   state.status = "idle";
//     //   state.singlecardata = action.payload;
//     // })
//     // .addCase(fetchsinglecardataFun.rejected, (state, action) => {
//     //   state.isLoading = false;
//     //   console.log("Error", action.payload);
//     //   state.isError = true;
//     // });
//   },
// });


// export const {resetCompState} = carSlice.actions;
// export const getAllViewCar = (state) => state.car.cardata;
// export const getPendingCar = (state) => state.car.pendingcardata;
// export const getInactiveCar = (state) => state.car.inactivecardata;
// export const { toggleCarStatus } = carSlice.actions;
// console.log(getAllViewCar)

// export default carSlice.reducer;
// // export const singleCarSlice=  singlecarSlice.reducer;





import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchviewallcar, fetchpendingcardata, fetchinactivecardata, fetchviewcarscategory } from "./carsAPI";

const initialState = {
  cardata: [],
  carscategorydata: [],
  pendingcardata: [],
  inactivecardata: [],
  status: null,
  isLoading: false,
  isError: false,
};

export const fetchviewallcarFun = createAsyncThunk("car/fetchviewallcar", async () => {
  try {
    const response = await fetchviewallcar();
    return response;
  } catch (error) {
    console.log("Error in fetching all cars", error);
    throw error;
  }
});
export const fetchviewcarscategoryFun = createAsyncThunk(
  "car/fetchviewcarscategory",
  async () => {
    try {
      const response = await fetchviewcarscategory();
      return response;
    } catch (error) {
      console.log("Error in fetching cars category data", error);
      throw error;
    }
  }
);

export const fetchpendingcardataFun = createAsyncThunk("car/fetchpendingcardata", async () => {
  try {
    const response = await fetchpendingcardata();
    return response;
  } catch (error) {
    console.log("Error in fetching pending cars", error);
    throw error;
  }
});

export const fetchinactivecardataFun = createAsyncThunk("car/fetchinactivecardata", async () => {
  try {
    const response = await fetchinactivecardata();
    return response;
  } catch (error) {
    console.log("Error in fetching inactive cars", error);
    throw error;
  }
});

const carSlice = createSlice({
  name: "car",
  initialState,
  reducers: {
    toggleCarStatus: (state, action) => {
      const { carId, newStatus } = action.payload;
      const carIndex = state.cardata.findIndex((car) => car._id === carId);
      if (carIndex !== -1) {
        state.cardata[carIndex].status = newStatus;
      }
    },
    resetCompState: (state, action) => {
      state.currentComp = action.payload;
    },
    addNewCity: (state, action) => {
      state.addednewcity = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchviewallcarFun.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchviewallcarFun.fulfilled, (state, action) => {
        state.isLoading = false;
        state.status = "idle";
        state.cardata = action.payload;
      })
      .addCase(fetchviewallcarFun.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        console.log("Error fetching all cars:", action.error.message);
      })
      .addCase(fetchpendingcardataFun.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchpendingcardataFun.fulfilled, (state, action) => {
        state.isLoading = false;
        state.status = "idle";
        state.pendingcardata = action.payload;
      })
      .addCase(fetchpendingcardataFun.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        console.log("Error fetching pending cars:", action.error.message);
      })
      .addCase(fetchinactivecardataFun.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchinactivecardataFun.fulfilled, (state, action) => {
        state.isLoading = false;
        state.status = "idle";
        state.inactivecardata = action.payload;
      })
      .addCase(fetchinactivecardataFun.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        console.log("Error fetching inactive cars:", action.error.message);
      })
      .addCase(fetchviewcarscategoryFun.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchviewcarscategoryFun.fulfilled, (state, action) => {
        state.isLoading = false;
        state.status = "idle";
        state.carscategorydata = action.payload;
      })
      .addCase(fetchviewcarscategoryFun.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        console.log("Error fetching cars category data:", action.error.message);
      });
  },
});

export const { toggleCarStatus, resetCompState, addNewCity } = carSlice.actions;
export const getAllViewCar = (state) => state.car.cardata;
export const getPendingCar = (state) => state.car.pendingcardata;
export const getInactiveCar = (state) => state.car.inactivecardata;
export const getCarsCategory = (state) => state.car.carscategorydata;

export default carSlice.reducer;
