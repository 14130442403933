import axios from "axios";

export async function fetchAllCoupons() {
  try {
    const response = await axios.get("https://api.yatricabs.com/v1/admin/coupon-codes");
    console.log(response.data.data);
    return response.data.data; // Return the data directly
  } catch (error) {
    console.error("Error fetching coupons:", error);
    throw error; // Rethrow the error to be handled by the caller
  }
}

//  export function deleteUserAsync(){
//     try {
//      const response = await axios.delete(`https://api.yatricabs.com/admin/couponCode/${id}`);
//     } catch (error) {
//         console.error("Error deleting coupon:", error);
//     }
//  }

// export function addCoupons() {
//     return new Promise(async (resolve) => {
//         try {
//             const { data } = await axios.post('https://api.yatricabs.com/admin/couponCode', {
//                 promoCode: selectedPromoCode,
//                 description: "discount applicable on last ride", // Adjust this as per your description
//                 tripType: tripType,
//                 subTripType: subTripType,
//                 discountType: selectedDiscountOption,
//                 discountValue: selectedDiscountValue,
//                 minBookingAmount: selectedMinimumAmount,
//                 maxDiscountAmount: selectedUptoDiscount,
//                 expiryDate: selectedExpiryDate,
//                 status: selectedStatusOption
//             }, {
//                 headers: {
//                     'Content-Type': 'application/json'  // Adjusted the content type here
//                 }
//             });

//         } catch (error) {
//             if (error.response) {
//                 // The request was made and the server responded with a status outside the range of 2xx
//                 console.error("Server responded with a status:", error.response.status);
//                 console.error("Server response data:", error.response.data);
//             } else if (error.request) {
//                 // The request was made but no response was received
//                 console.error("No response received:", error.request);
//             } else {
// Something happened in setting up the request
//                 console.error("Error:", error.message);
//             }
//         }
//     });
//  }
