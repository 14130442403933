import axios from "axios";
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export function fetchAllDriver() {
  return new Promise(async (resolve) => {
    try {
      const response = await axios.get(
       `${API_BASE_URL}/v1/admin/drivers`
      );
      resolve(response.data.data);
    } catch (e) {
      console.log("Error in fetching Data", e);
    }
  });
}

export function fetchApprovalData() {
  return new Promise(async (resolve) => {
    try {
      const response = await axios.get(
       `${API_BASE_URL}/v1/admin/drivers/approval-pending`
      );
      resolve(response.data.data);
    } catch (e) {
      console.log("Error in fetching Data", e);
    }
  });
}

export function fetchInactivelData() {
  return new Promise(async (resolve) => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}/v1/admin/drivers/inactive`
      );
      resolve(response.data.data);
    } catch (e) {
      console.log("Error in fetching Data", e);
    }
  });
}

export function createDriver(data) {
  return new Promise(async (resolve) => {
    try {
      const response = await axios.post(
        `${API_BASE_URL}/v1/admin/drivers`,
        JSON.stringify(data)
      );

      resolve(response.data);
    } catch (e) {
      console.log("Error in fetching Data", e);
    }
  });
}
