import React, { useEffect, useState } from "react";
import { ImEye } from "react-icons/im";
import "./ViewCustomerDetails.css";

import { useParams, Link, useNavigate } from "react-router-dom";
import Filter from "../Booking/Filter";
import { useDispatch, useSelector } from "react-redux";
import { FaLongArrowAltRight } from "react-icons/fa";
import DatePicker from "react-datepicker";
import InputColumn from "../Booking/Input";
import {
  fetchCustomerDetails,
  resetCustomerDetails,
} from "./CustomerDetailsSclice";
import CustomerInvoice from "./CustomerInvoice";
import Loader from "../Loader";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";

const TableItem = ({
  item,
  onInvoiceClick,
  setShowAddNewCar,
  carid,
  showinvoice,
  setShowInvoice,
}) => {
  const navigate = useNavigate();

  const handleShowAddNewCar = () => {
    // Call the setShowAddNewCar function to indicate that AddNewCar should be displayed
    setShowAddNewCar(true);
  };

  const navigateToCustomerBookingDetails = () => {
    navigate(
      `/customer/view-All-Customer/details/${carid}/booking-details/${item["Booking ID"]}`
    );
  };

  return (
    <tr
      onClick={() => {
        showinvoice ? onInvoiceClick(item) : navigateToCustomerBookingDetails();
      }}
    >
      <td>{item["Sr No"]}</td>
      <td>{item["Booking ID"]}</td>
      <td>{item["Pickup Date"]}</td>
      <td>{item["Pickup City"]}</td>
      <td>{item["Destination"]}</td>
      <td>{item["No of Days"]}</td>
      <td>{item["Car Type "]}</td>
      <td>{item["Trip Type"]}</td>
      <td>{item["Sub Trip Type"]}</td>
      <td>{item["Status"]}</td>
      <td>{item["Booking Type"]}</td>
      <td>{item["Created At"]}</td>
      <td>{item["Est  Fare"]}</td>
      <td>{item["Billed Amount"]}</td>
      <td>{item["Extra Km Charge"]}</td>
      <td>{item["Extra hrs Charge"]}</td>
      <td>{item["Night Charge"]}</td>
      <td>{item["Gst on Extra"]}</td>
      <td
        className="view-customer-buttons"
        onFocus={() => setShowInvoice(true)}
      >
        <button
          className="view-customer-table-button"

          //  onClick={() => onInvoiceClick(item)}
        >
          <ImEye className="icon icon-large" />
          View
        </button>
      </td>
    </tr>
  );
};

const Table = ({
  headers,
  data,
  onInvoiceClick,
  carid,
  showinvoice,
  setShowInvoice,
}) => {
  return (
    <table className="view-customer-table">
      <thead>
        <tr>
          {headers.map((header, index) => (
            <th key={index}>{header}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((item, index) => (
          <TableItem
            key={index}
            item={item}
            index={index}
            onInvoiceClick={onInvoiceClick}
            carid={carid}
            showinvoice={showinvoice}
            setShowInvoice={setShowInvoice}
          />
        ))}
      </tbody>
    </table>
  );
};

const CustomerDetails = (setShowAddNewCar) => {
  const id = useParams();
  const carid = id.id;
  console.log("id", carid);

  const handleShowAddNewCar = () => {
    setShowAddNewCar(true);
  };
  const [previousLocation, setPreviousLocation] = useState();
  const [showInvoicePopup, setShowInvoicePopup] = useState(false);
  const [selectedInvoiceItem, setSelectedInvoiceItem] = useState(null);
  const [showinvoice, setShowInvoice] = useState(false);
  const [userDetails, setUserDetails] = useState();
  const singleCustomerDetail = useSelector(
    (state) => state.customerInfo?.customerDetails
  );

  const loading = useSelector((state) => state.loading);
  const errors = useSelector((state) => state.error);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleViewInvoice = (item) => {
    console.log("Checking Invoice Items", item);
    setSelectedInvoiceItem(item);
    setShowInvoice(false);
    setShowInvoicePopup(true);
  };

  useEffect(() => {
    dispatch(fetchCustomerDetails(carid));
    checkParentLocation(window.location.pathname);
    console.log("checking parent location", window.location.pathname);
  }, []);

  console.log("checking singleCustomerDetail", singleCustomerDetail);

  useEffect(() => {
    if (singleCustomerDetail !== null) {
      setUserDetails({
        firstName: singleCustomerDetail.firstName,
        lastName: singleCustomerDetail.lastName,
        phone: singleCustomerDetail.phone,
        mail: singleCustomerDetail.email,
      });
    }
  }, [singleCustomerDetail]);

  // const singleCustomerdatavalue = singleCustomerDetail
  //   ? Object.values(singleCustomerDetail)
  //   : [];
  // console.log("checking singleCustomerdatavalue", singleCustomerdatavalue);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (errors) {
    return <div>Error: {errors}</div>;
  }

  const checkParentLocation = (path) => {
    if (path.includes("View-All-Customer")) {
      setPreviousLocation("View All Customer");
    } else {
      setPreviousLocation("View All Customer");
    }
  };

  const handleToggleComponent = (value) => {
    navigate("/customer");
  };

  const handleResetPassword = async () => {
    try {
      await axios.put(
        `https://api.yatricabs.com/v1/admin/users/${carid}/password`
      );
      toast.success("Password reset successfully");
    } catch (error) {
      toast.error(error.response.data.message);
      console.error(error.response.data.message);
    }
  };

  // Cleanup function to reset body overflow when component unmounts or showInvoicePopup changes

  const headers = [
    "Sr No",
    "Booking ID",
    "Pickup Date",
    "Pickup City",
    "Destination",
    "No of Days",
    "Car Type ",
    "Trip Type",
    "Sub Trip Type",
    "Status",
    "Booking Type",
    "Created At",
    "Est  Fare",
    "Billed Amount",
    "Extra Km Charge",
    "Extra hrs Charge",
    "Night Charge",
    "Gst on Extra",
    "Invoice",
  ];

  const data = [];

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
    }).format(amount);
  };

  let serialNumber = 1;

  if (
    singleCustomerDetail &&
    singleCustomerDetail.completedBookings &&
    singleCustomerDetail.cancelledBookings &&
    singleCustomerDetail.upcomingBookings
  ) {
    console.log(
      "checking lengths of completedBookings,upcomingBookings,cancelledBookings,currentBooking ",
      singleCustomerDetail.completedBookings.length,
      singleCustomerDetail.upcomingBookings.length,
      singleCustomerDetail.cancelledBookings.length,
      singleCustomerDetail.currentBooking
    );

    for (let i = 0; i < singleCustomerDetail?.completedBookings.length; i++) {
      const customer = singleCustomerDetail?.completedBookings[i];

      const createdAtDate = new Date(customer?.createdAt);
      const month = createdAtDate.getMonth() + 1;
      const day = createdAtDate.getDate();
      const year = createdAtDate.getFullYear();

      const hours = createdAtDate.getHours();
      const minutes = createdAtDate.getMinutes();
      const ampm = hours >= 12 ? "PM" : "AM";
      const formattedTime =
        (hours % 12) + ":" + (minutes < 10 ? "0" : "") + minutes + " " + ampm;

      const formattedCreatedAt = `${month}-${day}-${year}, ${formattedTime}`;

      data.push({
        "Sr No": serialNumber++,
        "Booking ID": customer.bookingId,
        "Pickup Date": customer.pickupDate,
        "Pickup City": customer?.destinationCities[0] || "Not Available",
        Destination: customer?.dropAddress || "Not Available",
        "No of Days": customer?.noOfDays,
        "Car Type ": customer?.carCategory.category,
        "Trip Type": customer?.tariff.tripType,
        "Sub Trip Type": customer?.tariff.subTripType,
        Status: customer?.status,
        "Booking Type": "Completed",
        "Created At": formattedCreatedAt,
        "Est  Fare": formatCurrency(customer?.estimatedFare),
        "Billed Amount": formatCurrency(customer?.totalAmount),
        "Extra Km Charge": formatCurrency(
          customer?.tariff.fareDetails.extraKmCharge
        ),
        "Extra hrs Charge": formatCurrency(
          customer?.tariff.fareDetails.extraHrsCharge
        ),
        "Night Charge": formatCurrency(
          customer?.tariff.fareDetails.nightDriverAllowance
        ),
        "Gst on Extra": formatCurrency(customer?.tariff.fareDetails.gstRate),
      });
    }

    for (let i = 0; i < singleCustomerDetail?.upcomingBookings.length; i++) {
      const customer = singleCustomerDetail?.upcomingBookings[i];

      const createdAtDate = new Date(customer?.createdAt);
      const month = createdAtDate.getMonth() + 1;
      const day = createdAtDate.getDate();
      const year = createdAtDate.getFullYear();

      const hours = createdAtDate.getHours();
      const minutes = createdAtDate.getMinutes();
      const ampm = hours >= 12 ? "PM" : "AM";
      const formattedTime =
        (hours % 12) + ":" + (minutes < 10 ? "0" : "") + minutes + " " + ampm;

      const formattedCreatedAt = `${month}-${day}-${year}, ${formattedTime}`;

      data.push({
        "Sr No": serialNumber++,
        "Booking ID": customer?.bookingId,
        "Pickup Date": customer?.pickupDate,
        "Pickup City": customer?.destinationCities[0] || "Not Available",
        Destination: customer?.dropAddress || "Not Available",
        "No of Days": customer?.noOfDays,
        "Car Type ": customer?.carCategory.category,
        "Trip Type": customer?.tariff.tripType,
        "Sub Trip Type": customer?.tariff.subTripType,
        Status: customer?.status,
        "Booking Type": "Upcoming",
        "Created At": formattedCreatedAt,
        "Est  Fare": formatCurrency(customer?.estimatedFare),
        "Billed Amount": formatCurrency(customer?.totalAmount),
        "Extra Km Charge": formatCurrency(
          customer?.tariff.fareDetails.extraKmCharge
        ),
        "Extra hrs Charge": formatCurrency(
          customer?.tariff.fareDetails.extraHrsCharge
        ),
        "Night Charge": formatCurrency(
          customer?.tariff.fareDetails.nightDriverAllowance
        ),
        "Gst on Extra": formatCurrency(customer?.tariff.fareDetails.gstRate),
      });
    }
    for (let i = 0; i < singleCustomerDetail?.cancelledBookings.length; i++) {
      const customer = singleCustomerDetail?.cancelledBookings[i];

      const createdAtDate = new Date(customer?.createdAt);
      const month = createdAtDate.getMonth() + 1;
      const day = createdAtDate.getDate();
      const year = createdAtDate.getFullYear();

      const hours = createdAtDate.getHours();
      const minutes = createdAtDate.getMinutes();
      const ampm = hours >= 12 ? "PM" : "AM";
      const formattedTime =
        (hours % 12) + ":" + (minutes < 10 ? "0" : "") + minutes + " " + ampm;

      const formattedCreatedAt = `${month}-${day}-${year}, ${formattedTime}`;

      data.push({
        "Sr No": serialNumber++,
        "Booking ID": customer?.bookingId,
        "Pickup Date": customer?.pickupDate,
        "Pickup City": customer?.destinationCities[0],
        Destination: customer?.dropAddress,
        "No of Days": customer?.noOfDays,
        "Car Type ": customer?.carCategory.category,
        "Trip Type": customer?.tariff.tripType,
        "Sub Trip Type": customer?.tariff.subTripType,
        Status: customer?.status,
        "Booking Type": "Cancelled",
        "Created At": formattedCreatedAt,
        "Est  Fare": formatCurrency(customer?.estimatedFare),
        "Billed Amount": formatCurrency(customer?.totalAmount),
        "Extra Km Charge": formatCurrency(
          customer?.tariff.fareDetails.extraKmCharge
        ),
        "Extra hrs Charge": formatCurrency(
          customer?.tariff.fareDetails.extraHrsCharge
        ),
        "Night Charge": formatCurrency(
          customer?.tariff.fareDetails.nightDriverAllowance
        ),
        "Gst on Extra": formatCurrency(customer.tariff.fareDetails.gstRate),
      });
    }
    const customer = singleCustomerDetail?.currentBooking;
    const createdAtDate = new Date(customer?.createdAt);
    const month = createdAtDate.getMonth() + 1;
    const day = createdAtDate.getDate();
    const year = createdAtDate.getFullYear();

    const hours = createdAtDate.getHours();
    const minutes = createdAtDate.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    const formattedTime =
      (hours % 12) + ":" + (minutes < 10 ? "0" : "") + minutes + " " + ampm;

    const formattedCreatedAt = `${month}-${day}-${year}, ${formattedTime}`;

    data.push({
      "Sr No": serialNumber++,
      "Booking ID": customer?.bookingId,
      "Pickup Date": customer?.pickupDate,
      "Pickup City": customer?.destinationCities[0],
      Destination: customer?.dropAddress,
      "No of Days": customer?.noOfDays,
      "Car Type ": customer?.carCategory.category,
      "Trip Type": customer?.tariff.tripType,
      "Sub Trip Type": customer?.tariff.subTripType,
      Status: customer?.status,
      "Booking Type": "Current Booking",
      "Created At": formattedCreatedAt,
      "Est  Fare": formatCurrency(customer?.estimatedFare),
      "Billed Amount": formatCurrency(customer?.totalAmount),
      "Extra Km Charge": formatCurrency(
        customer?.tariff.fareDetails.extraKmCharge
      ),
      "Extra hrs Charge": formatCurrency(
        customer?.tariff.fareDetails.extraHrsCharge
      ),
      "Night Charge": formatCurrency(
        customer?.tariff.fareDetails.nightDriverAllowance
      ),
      "Gst on Extra": formatCurrency(customer?.tariff.fareDetails.gstRate),
    });
  } else {
    return (
      <div
        className="loading-circle"
        style={{ display: "flex", alignItems: "center" }}
      >
        <Loader />
      </div>
    );
  }

  const CustomerData = data;

  return (
    <>
      <Toaster />
      <div
        className="driver-info-container"
        style={{
          width: "100%",
          overflowX: "auto",
        }}
      >
        <div className="nav-content" style={{ marginBottom: "10px" }}>
          <div className="header-text">
            <Link className="h3-booking">Customer</Link>
            <FaLongArrowAltRight />
            {""}
            <Link className="h3-booking">{previousLocation}</Link>
            <FaLongArrowAltRight />
            <Link className="h2-booking">Customer Details</Link>
          </div>

          {previousLocation === "View All Customer" && (
            <button
              className="createButton"
              onClick={() => handleToggleComponent("View All Customer")}
            >
              View All Customer
            </button>
          )}
        </div>

        <div className="customer-detailss">
          <div className="customer-profile">
            <div className="crete-new-sub-head">
              Customer Profile Information
            </div>
            {singleCustomerDetail ? (
              <div className="row">
                <div className="col">
                  <div className="col-item">
                    <p className="col-item-title"> First Name</p>
                    <p className="col-item-input">
                      {singleCustomerDetail?.firstName}
                    </p>
                  </div>
                  <div className="col-item">
                    <p className="col-item-title"> Primary Mobile No.</p>
                    <p className="col-item-input">
                      {singleCustomerDetail?.phone}
                    </p>
                  </div>
                </div>

                <div className="col">
                  <div className="col-item">
                    <p className="col-item-title"> Last Name</p>
                    <p className="col-item-input">
                      {singleCustomerDetail?.lastName}
                    </p>
                  </div>
                  <div className="col-item">
                    <p className="col-item-title">Alternate Mobile No.</p>
                    <p className="col-item-input">
                      {singleCustomerDetail?.alternatePhone || "Not Available"}
                    </p>
                  </div>
                </div>
                <div className="col">
                  <div className="col-item">
                    <p className="col-item-title">Email Address</p>
                    <p className="col-item-input">
                      {singleCustomerDetail.email}
                    </p>
                  </div>

                  <div className="col-item">
                    <button
                      className="createButton"
                      onClick={handleResetPassword}
                    >
                      Reset Password
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <div className="loading-circle">
                <Loader />
              </div>
            )}
          </div>

          <div className="crete-new-sub-head">Booking History</div>
          {CustomerData.length > 0 ? (
            <div>
              <div
                className="dropdown-container"
                style={{
                  marginBottom: "12px",
                  marginTop: "12px",
                }}
              >
                <Filter />
                <InputColumn
                  title="Search by Name"
                  // selectedValue={searchName}
                  // handleChange={(e) => setsearchName(e.target.value)}
                />
                <InputColumn
                  title="Search by Mobile No."
                  // selectedValue={searchPhoneNumber}
                  // handleChange={(e) => setsearchPhoneNumber(e.target.value)}
                />
                <InputColumn
                  title="Search by Email"
                  // selectedValue={searchEmail}
                  // handleChange={(e) => setsearchEmail(e.target.value)}
                />
                <DatePicker
                  // selected={selectedDate}
                  // onChange={handleDateChange}
                  placeholderText="Select a date"
                  dateFormat="dd-MM-yyyy"
                  className="custom-datepicker"
                />
              </div>

              {CustomerData.length > 0 ? (
                <div className="table">
                  <Table
                    headers={headers}
                    data={CustomerData.map((item) => ({
                      ...item,
                    }))}
                    onInvoiceClick={handleViewInvoice}
                    carid={carid}
                    showinvoice={showinvoice}
                    setShowInvoice={setShowInvoice}
                  />
                </div>
              ) : (
                <div
                  className="loading-circle"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <Loader />
                </div>
              )}

              <div className="invoice">
                {showInvoicePopup && (
                  <CustomerInvoice
                    setShowInvoicePopup={setShowInvoicePopup}
                    selectedInvoiceItem={selectedInvoiceItem}
                    userDetails={userDetails}
                  />
                )}
              </div>
            </div>
          ) : (
            <div className="heading-error">No Booking History Available</div>
          )}
        </div>
      </div>
    </>
  );
};

export default CustomerDetails;
