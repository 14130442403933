import React from "react";
import "./TableFirst.css";
import { useDispatch, useSelector } from "react-redux";
import { selectbookingDetails } from "../DetailsSlice";

const TableFirstItem = ({ item }) => {
  const bookingDetails = useSelector(selectbookingDetails);
  console.log("Booking details in tablefirst now",bookingDetails);


  console.log("Item in tablefirst:", item);
  

  return (

    item?.tariff?.tripType === "Outstation" ? (<tr className="firstdata_billing_table_data">
      <td>
        {bookingDetails?.carCategory?.category}
      </td>

      <td>
        <span>{item?.tariff?.tripType}</span>
        <span>-</span>
        <span>{item?.tariff?.subTripType}</span>
      </td>

      <td>
      {item?.location?.city},{item?.location?.state}-{item?.destinationCities[0]}
      </td>
      <td>
        {item?.pickupDate} {item?.pickupTime}
      </td>
      <td>
        {bookingDetails?.status}
      </td>

      <td>{bookingDetails?.status}</td>
      <td>{bookingDetails.status}</td>


    </tr>) : (<tr className="firstdata_billing_table_data">

      {/* added car category field here */}
      <td>
        {bookingDetails?.carCategory?.category}
      </td>

      <td>
        <span>{item?.tariff?.tripType} - {item?.tariff?.subTripType}</span>
        
      </td>

      <td>{item?.location?.city},{item?.location?.state}</td>
      <td>
        {item?.pickupDate} {item?.pickupTime}
      </td>

      <td>
      {bookingDetails?.status}
      </td>

      <td>
        {bookingDetails?.status==="Completed"? (`${item?.actualHrs} HRS`) : (   `${bookingDetails?.status}`)} 
      </td>

      <td>
      {bookingDetails?.status==="Completed"? (`${item?.actualDistance} KM`) : ( `${bookingDetails?.status}`)} 
      </td>

    </tr>)

  );
};

const Table = ({ headers, bookingDetailsTablefirst }) => {
  return (
    <div className="billing_table_containers">
      <div className="billing_table_scroll">
        <table className="billing_table">
          <thead>
            <tr>
              {headers.map((header, index) => (
                <th style={{ borderRadius: "0",padding:"8px",fontSize:"12px",textAlign:"center" }} key={index}>
                  {header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {bookingDetailsTablefirst.map((item, index) => (
              <TableFirstItem key={index} item={item} index={index} />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const TableFirst = ({ bookingDetailsTablefirst }) => {
  const bookingDetails = useSelector(selectbookingDetails);



  if (bookingDetails?.tariff?.tripType === "Outstation") {
    var headers = [
      "Car Category",
      "Trip Type",
      "Route",
      "Trip Start Date & Time",
      "Trip End Date & Time",
      "No of Day",
      "Distance Travelled",

    ];

  } else {
    var headers = [
      // Earlier Car Category was not added 
      "Car Category",
      "Trip Type",
      "Route",
      "Trip Start Date & Time",
      "Trip End Date & Time",
      "Total Hours Covered",
      "Total Km Covered",

    ];
  }


  return <Table headers={headers} bookingDetailsTablefirst={[bookingDetailsTablefirst]} />;
};

export default TableFirst;
