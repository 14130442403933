// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.box-action {
    display: flex;
    margin-right: 10px;
    align-items: center;
    justify-content: center;
    height:10px;
    gap: 10px;
    }
    
    .box-action svg{
    cursor: pointer;
    }
    .toggle {
      display: flex;
      align-items: center; 
    }
    .box-action .icon.icon-large{
    font-size: 20px;
    margin-left: 5px;
    }`, "",{"version":3,"sources":["webpack://./src/components/VendorTarrifManagement/Toggle.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,kBAAkB;IAClB,mBAAmB;IACnB,uBAAuB;IACvB,WAAW;IACX,SAAS;IACT;;IAEA;IACA,eAAe;IACf;IACA;MACE,aAAa;MACb,mBAAmB;IACrB;IACA;IACA,eAAe;IACf,gBAAgB;IAChB","sourcesContent":[".box-action {\n    display: flex;\n    margin-right: 10px;\n    align-items: center;\n    justify-content: center;\n    height:10px;\n    gap: 10px;\n    }\n    \n    .box-action svg{\n    cursor: pointer;\n    }\n    .toggle {\n      display: flex;\n      align-items: center; \n    }\n    .box-action .icon.icon-large{\n    font-size: 20px;\n    margin-left: 5px;\n    }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
