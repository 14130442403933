import React, { useState, useEffect } from 'react';
import './DropdownFilter.css';
import { IoIosArrowDown } from 'react-icons/io';

const DropdownFilter = ({ filterOptions, onSelect, defaultValue, disabled  }) => {
  const [selectedStatus, setSelectedStatus] = useState(defaultValue || '');

  const handleSelect = (value, label) => {
    setSelectedStatus(value);
    // onSelect(value, label);
    if (value === "All") {
    // Clear filters or show all drivers
    onSelect(value, label);
  } else {
    onSelect(value, label);
  }
  };

  const DropdownColumn = ({ options, selectedValue, handleChange, placeholder }) => {
    return (
      <div className="dropdown">
        <select
          value={selectedValue}
          onChange={handleChange}
          className="dropdown-select"
          placeholder={placeholder || 'Pick a City'}
          disabled={disabled}
        >
          <option value="" disabled hidden>{placeholder || 'Select an option'}</option>
          {options.values &&
            options.values.map((data, index) => (
              <option key={index} value={data}>
                {data}
              </option>
            ))}
        </select>
        <IoIosArrowDown className="drop-icon" />
      </div>
    );
  };
  
  

  

  useEffect(() => {
    setSelectedStatus(defaultValue || ''); // Update selected value when defaultValue changes
  }, [defaultValue]);

  return (
    <div>
      <DropdownColumn
      
        placeholder={filterOptions.label}
        options={filterOptions}
        selectedValue={selectedStatus}
        handleChange={(e) => handleSelect(e.target.value, filterOptions.label)}
      />
    </div>
  );
};

export default DropdownFilter;


