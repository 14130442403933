import React from 'react'
import './Rightside.css'
import { IoIosPhonePortrait } from "react-icons/io"
<style>
    @import url('https://fonts.googleapis.com/css2?family=AR+One+Sans:wght@500&family=Dosis:wght@500&family=Kanit:ital,wght@1,300&family=Martian+Mono&family=Poppins&family=Roboto:ital,wght@0,100;0,300;0,400;0,900;1,400&display=swap');
</style>
const Rightside = () => {
    return (
        <div className='right'>
            <div className='right-box'>
                <img src='https://cdn.britannica.com/17/155017-050-9AC96FC8/Example-QR-code.jpg' alt=''></img>
                <h7>Booking ID YC2324-01</h7>
            </div>
            <div className='right-box-action'>

                <h4>Recent Action</h4>
                <div className='Right-btn'>
                    <button>Cancel</button>

                    <button>Broadcast Booking</button>

                    <button>Assign Driver & Cab</button>
                </div>
            </div>
            <div className='right-box-2'>
                <h3>16-08-2023 16:58</h3>
                <div className='Line'></div>
                <div className='right-text'>
                    <IoIosPhonePortrait style={{ marginLeft: '10px', marginRight: '-10px' }}></IoIosPhonePortrait>
                    <h7>Booking Created by
                    </h7>  <br></br>
                    <span >Peeyush Vats
                        +91-9876543212</span>

                </div>
            </div>
            <div className='right-box-3'>
                <h3>16-08-2023 16:58</h3>
                <div className='Line'></div>
                <div className='right-text'>
                    <IoIosPhonePortrait style={{ marginLeft: '10px', marginRight: '-10px' }}></IoIosPhonePortrait>
                    <h7>Booking Created by
                    </h7>  <br></br>
                    <span >Peeyush Vats
                        +91-9876543212</span>
                  

                       

                </div>
            </div>
            <div className='right-box-1'>
                <h3>16-08-2023 16:58</h3>
                <div className='Line'></div>
                <div className='right-text'>
                    <IoIosPhonePortrait style={{ marginLeft: '10px', marginRight: '-10px' }}></IoIosPhonePortrait>
                    <h7>Pickup Initialed</h7>
                </div>
            </div>
            <div className='right-box-1'>
                <h3>16-08-2023 16:58</h3>
                <div className='Line'></div>
                <div className='right-text'>
                    <IoIosPhonePortrait style={{ marginLeft: '10px', marginRight: '-10px' }}></IoIosPhonePortrait>
                    <h7>Reached Pickup Point</h7>
                </div>
            </div><div className='right-box-1'>
                <h3>16-08-2023 16:58</h3>
                <div className='Line'></div>
                <div className='right-text'>
                    <IoIosPhonePortrait style={{ marginLeft: '10px', marginRight: '-10px' }}></IoIosPhonePortrait>
                    <h7>Ride Started</h7>
                </div>
            </div>
            <div className='right-box-1'>
                <h3>16-08-2023 16:58</h3>
                <div className='Line'></div>
                <div className='right-text'>
                    <IoIosPhonePortrait style={{ marginLeft: '10px', marginRight: '-10px' }}></IoIosPhonePortrait>
                    <h7>Ride Ended</h7>
                </div>
            </div>

        </div>


    )
}

export default Rightside
