import React, { useEffect, useState } from "react";
import axios from "axios";
import Loader from "../Loader";
import "./AssignDriverData.css";
import { useParams } from "react-router-dom";

import { FaFilter } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import DropdownFilter from "../dropdown/DropdownFilter";
import {
  fetchDriverData,
  assignDriver,
  updateDriverStatus,
} from "./assignDriverSlice";

function DefaultFile({ handleDriverState }) {
  const [apiData, setApiData] = useState([]);
  const [searchName, setSearchName] = useState("");
  const [searchContact, setSearchContact] = useState("");
  const [filterOptionsState, setfilterOptionsState] = useState([]);
  const [filterValues, setFilterValues] = useState([]);
  const driversDetail = [];
  const filterLocations = [];
  const [intialDriverData, setIntialDriverData] = useState([]);
  const dispatch = useDispatch();
  const { allDrivers } = useSelector((state) => state.assignDrivers);
  const { id } = useParams();

  const headerColumns = [
    "Driver Name",
    "Contact No.",
    "Location",
    "Sourcing Type",
    "Language Spoken",
    "Availability Status",
    "Driver License No.",
    "",
  ];

  let filterOptions = [
    {
      label: "Pick Up City ",
      values: null,
    },
    {
      label: "Language Spoken",
      // values: ["Language Spoken", "Hindi", "English", "English, Hindi"],
      values: ["Hindi", "English", "English, Hindi"],
    },
    {
      label: "Sourcing Type",
      // values: ["Sourcing Type", "Hindi", "English", "English, Hindi"],
      values: ["Outsourced", "Company Owned"],
    },
    // {
    //   label: "Status",
    //   // values: ["Status", "Available", "Not Available"],
    //   values: [ "Available", "Not Available"],
    // },
  ];

  let filterAvailability = 
    {
      label: "Availability",
      // values: ["Status", "Available", "Not Available"],
      values: ["Available", "Not Available"],
    } 
  
let filterLocation={
  label: "Pick Up City ",
      values: null,
}

let LanguageSpoken={
  label: "Language Spoken",
  //     // values: ["Language Spoken", "Hindi", "English", "English, Hindi"],
      values: ["Hindi", "English", "English, Hindi"],
}
let SourcingType={
  label: "Sourcing Type",
  //     // values: ["Sourcing Type", "Hindi", "English", "English, Hindi"],
      values: ["Outsourced", "Company Owned"],
}
  const handleSelectedData = (driverId) => {
    const currentAssignDriver = intialDriverData.filter((driver) => {
      return driver["id"] === driverId;
    });
    handleDriverState(id, driverId);
    console.log("driver data current", currentAssignDriver[0]);
    dispatch(assignDriver(currentAssignDriver[0]));
    dispatch(updateDriverStatus({ driverId, status: false }));
  };

  const TableHeaders = ({ headers }) => {
    return (
      <thead>
        <tr className="table-header-row">
          {headers.map((header, index) => (
            <th key={index}>
              <div className="table-header"> {header}</div>
            </th>
          ))}
        </tr>
      </thead>
    );
  };

  const TableBody = ({ enteries, headers }) => {
    return (
      <tbody>
        {enteries.map((driver, index) => {
          return (
            <tr className="table-data-row" key={index}>
              {headers.map((header) => {
                return header !== "" ? (
                  <td className="table-data" key={header}>
                    {driver[header]}
                  </td>
                ) : (
                  <td className="table-data" key={header}>
                    <button
                      className="select-button"
                      onClick={() => handleSelectedData(driver["id"])}
                    >
                      Select
                    </button>
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    );
  };

  useEffect(() => {
    if (allDrivers.data !== null) {
      allDrivers.data.data.forEach((driver) => {
        driversDetail.push({
          id: driver._id,
          "Driver Name": driver?.firstName + " " + driver?.lastName,
          "Contact No.": driver?.phone,
          "Language Spoken":
            driver.languages.length !== 1
              ? driver.languages[0] + ", " + driver.languages[1]
              : driver.languages[0],
          "Sourcing Type": driver.sourcing,
          "Availability Status":
            driver?.availability === "Available"
              ? "Available"
              : "Not Available",
          "Driver License No.": driver?.license.number,
          Location: driver.location
            ? driver?.location?.city + ", " + driver?.location?.state
            : driver?.location,
        });
      });

      setApiData(driversDetail);
      setIntialDriverData(driversDetail);
      fetchLocations();
    }
  }, [allDrivers]);

  const fetchLocations = async () => {
    const response = await axios.get("https://api.yatricabs.com/v1/locations");
    // filterLocations.push("Location");
    response.data.data.forEach((value) => filterLocations.push(value.location));
    let tempFilter = filterOptions.map((temp) => {
      if (temp.label === "Location") {
        temp.values = filterLocations;
      }
      return temp;
    });

    setfilterOptionsState([...tempFilter]);
  };

  useEffect(() => {
    globalSelectedOption(searchName, "Driver Name");
  }, [searchName]);

  useEffect(() => {
    globalSelectedOption(searchContact, "Contact No.");
  }, [searchContact]);

  const [labels, setLabels] = useState([]);
  const [currentFilterValue, setCurrentFilterValue] = useState();

  useEffect(() => {
    if (intialDriverData) {
      const newData = intialDriverData.filter((driver) => {
        return labels.every((key) => {
          if (driver[key] !== null) {
            const checkFilter = driver[key];
            if (key !== "Language Spoken") {
              return checkFilter.includes(filterValues[key]);
            } else {
              return driver[key] === filterValues[key];
            }
          }
          return false;
        });
      });
      setApiData(newData);
    }
  }, [currentFilterValue]);

  const globalSelectedOption = async (value, label) => {
    if (value === "") {
      filterValues["Driver Name"] && delete filterValues["Driver Name"];
      filterValues["Contact No."] && delete filterValues["Contact No."];
    }

    let temp = filterValues;
    temp[label] = value;

    if (labels && !labels.includes(label)) {
      setLabels((prevValues) => [...prevValues, label]);
    } else if (filterValues[label] === value) {
      setLabels((prevValues) => prevValues.filter((key) => key !== value));
    }

    setFilterValues(temp);
    setCurrentFilterValue(value);

    if (label === "Location") {
      fetchDriversByLocation(value);
    }
  };

  const fetchDriversByLocation = async (location) => {
    try {
      const response = await axios.get(
        `https://api.yatricabs.com/v1/admin/bookings/drivers/location?bookingId=${id}&location=${location}`
      );

      if (response.data.data.length === 0) {
        alert("No available drivers found when fetching from API.");
        setApiData([]);
        setIntialDriverData([]);
        return;
      }

      const driversDetail = response.data.data.map((driver) => ({
        id: driver._id,
        "Driver Name": driver?.firstName + " " + driver?.lastName,
        "Contact No.": driver?.phone,
        "Language Spoken":
          driver.languages.length !== 1
            ? driver.languages[0] + ", " + driver.languages[1]
            : driver.languages[0],
        "Sourcing Type": driver.sourcing,
        "Availability Status":
          driver.availability === "Available" ? "Available" : "Not Available",
        "Driver License No.": driver?.license?.number,
        Location: driver?.location
          ? driver?.location?.city + ", " + driver?.location?.state
          : driver?.location,
      }));

      setApiData(driversDetail);
      setIntialDriverData(driversDetail);
    } catch (error) {
      alert("No available drivers found.");
      setApiData([]);
      setIntialDriverData([]);
    }
  };
  return (
    <div className="data-container">
      <div className="filter-section">
        <p className="filter-tag">
          Filter
          <FaFilter />
        </p>

        <div
          style={{
            display: "flex",
            width: "32%",
            justifyContent: "space-between",
            marginRight: "1.5%",
          }}
        >
          {/* {filterOptionsState &&
           
           filterOptionsState.map((filter, index) => {
            const defaultValue =
              filter.label === "Location"
                ? intialDriverData[0]?.Location
                : undefined;
              return (
                <DropdownFilter
                  key={index}
                  filterOptions={filter}
                  onSelect={globalSelectedOption}
                  defaultValue={defaultValue}
                />
              );
            })} */}
             <DropdownFilter
                  // key={index}
                  filterOptions={filterLocation}
                  onSelect={globalSelectedOption}
                  defaultValue={undefined}
                />
                 <DropdownFilter
                  // key={index}
                  filterOptions={LanguageSpoken}
                  onSelect={globalSelectedOption}
                  defaultValue={undefined}
                />
                 <DropdownFilter
                  // key={index}
                  filterOptions={SourcingType}
                  onSelect={globalSelectedOption}
                  defaultValue={undefined}
                />
        </div>
        <div className="rightfilter">
          <input
            className="input-search"
            value={searchName}
            type="text"
            placeholder="Search By Name"
            onChange={(e) => setSearchName(e.target.value)}
          />
          <input
            className="input-search"
            value={searchContact}
            type="text"
            placeholder="Search By Contact No."
            onChange={(e) => setSearchContact(e.target.value)}
          />
          {/* <input 
        className="input-search"
        // value={kk}
placeholder="Availability"

        /> */}
        <div style={{marginLeft:"10%"}}> 
          <DropdownFilter
                            key={0}

            filterOptions={filterAvailability}
            onSelect={globalSelectedOption}
            defaultValue={undefined}
          />
          </div>
        </div>
      </div>

      <div className="table-container">
        <table className="driver-table">
          <TableHeaders headers={headerColumns} />
          {intialDriverData && (
            <TableBody enteries={apiData} headers={headerColumns} />
          )}
        </table>
      </div>
      <div>{!intialDriverData && <Loader />}</div>
    </div>
  );
}

export default DefaultFile;
