import "./App.css";
import { Route, Routes } from "react-router-dom";
import Dashboard from "./function/Dashboard";
import Geography from "./function/Geography";
import Booking from "./function/Booking";
import Tariff from "./function/Tariff";
import CarDetails from "./function/CarDetails";
import CarManagement from "./function/CarManagement";
import Login from "./components/login/Login";
import BookingOne from "./function/BookingOne";
import DriverManagemnt from "./function/DriverManagement";
import AssignSection from "./components/AssignCab&Driver/AssignSection";
import Coupon from "./function/Coupons";
import Customers from "./function/Customer";
import UpdateTariff from "../src/components/TariffManagement/UpdateTariff";
import DriverInformation from "./function/DriverInformation";
import ProtectiveRoute from "./utils/ProtectiveRoute";
import { useSelector } from "react-redux";
import BookingHistroy from "../src/components/DriverManagement/BookingHistory";
import UpdateDriverDetails from "./function/UpdateDriverDetails";
import UpdateCarDetails from "./function/UpdateCarDetails";
import axios from "axios";
import CustomerDetail from "./function/CustomerDetails";
import ModifyCustomerDetails from "./function/ModifyCustomerDetails";
import UpdateGeography from "./function/UpdateGeography";
import CustomerBookingDetails1 from "./function/CustomerBookingDetails1";
import CreateNewBooking1 from "./function/CreateNewBooking1";
import Assign from "./function/Assign";
import Page404 from "./components/Page404";
import Earning from "./function/Earning";
import UpdateCarCategory from "./function/UpdateCarCategory";

import Layout from "./components/DriverManagement/earning-report/Layout";

axios.defaults.withCredentials = true;

function App() {
  const { isLogIn } = useSelector((state) => state.auth);

  return (
    <div>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route element={<ProtectiveRoute id={isLogIn} />}>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/geography/*" element={<Geography />} />
          <Route path="/bookings/*" element={<Booking />} />
          <Route path="/assign-page" element={<AssignSection />} />
          <Route path="/customer/*" element={<Customers />} />
          <Route path="/carmanagement/*" element={<CarManagement />} />

          <Route path="/drivermanagement/*" element={<DriverManagemnt />} />
          <Route
            path="/carManagement/:carDataComponent/car-details/:id"
            element={<CarDetails />}
          />
          <Route
            path="/carManagement/:carDataComponent/modify-car-details/:id"
            element={<UpdateCarDetails />}
          />
          <Route
            path="/carManagement/:carDataComponent/modify-car-category/:id/:previewBool"
            element={<UpdateCarCategory />}
          />
          <Route path="/tariff-management/*" element={<Tariff />} />
          <Route path="/UpdateTariff/:id" element={<UpdateTariff />} />
          {/* <Route path="/CarDetails/:id" element={<CarDetails/>} /> */}
          <Route path="/coupon" element={<Coupon />} />
          <Route path="/booking/:id" element={<BookingOne />} />
          <Route path="/booking/:id/assign/*" element={<Assign />} />
          <Route
            path="/booking/create-new-booking"
            element={<CreateNewBooking1 />}
          />
          <Route path="/driver-all-data" element={<BookingHistroy />} />

          <Route
            path="/drivermanagement/:currentSection/DriverDetails/:driverId"
            element={<DriverInformation />}
          />
          <Route
            path="/drivermanagement/:currentSection/DriverDetails/:driverId/Reports"
            element={<Earning />}
          />
          <Route
            path="/drivermanagement/:currentSection/modify-details/:driverId"
            element={<UpdateDriverDetails />}
          />

          <Route
            path="/customer/:components/details/:id"
            element={<CustomerDetail />}
          />

          <Route
            path="/customer/view-All-Customer/details/:carid/booking-details/:id"
            element={<CustomerBookingDetails1 />}
          />

          <Route
            path="/customer/:components/edit-details/:id"
            element={<ModifyCustomerDetails />}
          />
          <Route
            path="/geography/:component/edit-location/:id"
            element={<UpdateGeography />}
          />

          <Route path="/booking/:id/*" element={<BookingOne />} />
        </Route>
        <Route path="/*" element={<Page404 />} />

        <Route path="/earning-report" element={<Layout />}></Route>

      </Routes>
    </div>
  );
}

export default App;
