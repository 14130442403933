import React, { useState } from "react";
import "./UpdateTariff.css";
import Header from "../header/Header";

import Navbar from "../navbar/Navbar";
import {
  updateOutstationTariff,
  getSingleOutstationTariff,
  updateLocalAirportTariffFun,
} from "./Action";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

const DropdownColumn = ({ title, option }) => {
  return (
    <div className="">
      <div className="title">{title}</div>
      <select
        style={{
          width: "200px",
          fontSize: "11px",
          fontFamily: "Inter",
        }}
        value={option}
        className="dro"
      >
        <option value={option}>{option}</option>
      </select>
    </div>
  );
};

// const InputColumn = ({
//   title,
//   selectedValue,
//   handleChange,
//   getData,
//   isChanged,
// }) => {
//   return (
//     <div className="">
//       <span className="title">{title}</span>

//       <input
//         type="text"
//         value={isChanged ? selectedValue : getData}
//         onChange={handleChange}
//         className="input-field"

//       />
//     </div>
//   );
// };

const InputColumn = ({
  title,
  selectedValue,
  handleChange,
  position,
  text,
  getData,
  isChanged,
}) => {
  return (
    <div className="dropdown-column">
      <span className="title">{title}</span>

      <div
        style={{
          display: "flex",
          flexDirection: position === "left" ? "row-reverse" : "row",
          justifyContent: "space-between",
          alignItems: "center",
          width: "200px",
          borderRadius: "5px",
          border: "none",
          boxShadow: " 0px 4px 22px 0px rgba(0, 0, 0, 0.17)",
        }}
      >
        <input
          style={{
            border: "2px",
            width: "150px",
            boxShadow: "none",
          }}
          type="text"
          value={isChanged ? selectedValue : getData}
          onChange={handleChange}
          className="input-field"
        />
        <div
          style={{
            fontSize: "11px",
            fontWeight: "500",
            padding: "0 6px",
          }}
        >
          {text}
        </div>
      </div>
    </div>
  );
};

const UpdateTariff = () => {
  const [isMinkmperdayChanged, setisMinkmperdayChanged] = useState(false);
  const [isPerkmChanged, setisPerkmChanged] = useState(false);
  const [isExtrakmChanged, setisExtrakmChanged] = useState(false);
  const [isGstValueChanged, setisGstValueChanged] = useState(false);
  const [isMorningBataChanged, setisMorningBataChanged] = useState(false);
  const [isNightBataChanged, setisNightBataChanged] = useState(false);
  const [isDayDriverAllowance, setisDayDriverAllowance] = useState(false);
  //for airport and local
  const [isExtraHoursChanged, setisExtraHoursChanged] = useState(false);
  const [isBaseFairChanged, setisBaseFairChanged] = useState(false);
  const [isMinKmChanged, setisMinKmChanged] = useState(false);
  const [isMinHoursChanged, setisMinHoursChanged] = useState(false);

  const [selectedBaseFare, setSelectedBaseFare] = useState("");
  const [selectedMinKm, setSelectedMinKm] = useState("");
  const [selectedMinHours, setSelectedMinHours] = useState("");
  const [selectedExtraHours, setSelectedExtraHours] = useState("");
  const [selectedGst, setSelectedGst] = useState("");
  const [selectedExtraMorBata, setSelectedExtraMorBata] = useState(0);
  const [selectedPerKmCharge, setSelectedPerKmCharge] = useState("");
  const [selectedExtraKmCharge, setSelectedExtraKmCharge] = useState("");
  const [selectedNightCharge, setSelectedNightCharge] = useState(0);
  const [selectedDayDriverAllowance, setSelectedDayDriverAllowance] =
    useState(0);

  const [sidebar, setSidebar] = useState(false);

  const dispatch = useDispatch();
  const navigates = useNavigate();
  const id = useParams();
  const tariffId = id.id;
  console.log(tariffId);

  const traiffDetails = useSelector(
    (state) => state.singleTariff.tariffDetails
  );
  console.log("tariff", traiffDetails);

  // Function to handle input change
  const handleMinKmChanged = (e) => {
    setSelectedMinKm(e.target.value);
    setisMinkmperdayChanged(true);
  };

  const handlePerKmChanged = (e) => {
    setSelectedPerKmCharge(e.target.value);
    setisPerkmChanged(true);
  };

  const handleExtraKmChanged = (e) => {
    setSelectedExtraKmCharge(e.target.value);
    setisExtrakmChanged(true);
  };

  const handleGstChanged = (e) => {
    setSelectedGst(e.target.value);
    setisGstValueChanged(true);
  };

  const handleBaseFairChanged = (e) => {
    setSelectedBaseFare(e.target.value);
    setisBaseFairChanged(true);
  };

  const handleExtraHours = (e) => {
    setSelectedExtraHours(e.target.value);
    setisExtraHoursChanged(true);
  };

  const handleMinKm = (e) => {
    setSelectedMinKm(e.target.value);
    setisMinKmChanged(true);
  };

  const handleMinHours = (e) => {
    setSelectedMinHours(e.target.value);
    setisMinHoursChanged(true);
  };

  const handleMorningBataChanged = (e) => {
    const inputValue = e.target.value;
    if (!isNaN(inputValue)) {
      setSelectedExtraMorBata(inputValue);
      console.log(selectedExtraMorBata);
      console.log(selectedExtraMorBata);
    } else {
      setSelectedExtraMorBata(parseInt(inputValue));
    }
    setisMorningBataChanged(true);
  };

  const handleNightBataChanged = (e) => {
    const inputValue = e.target.value;
    if (!isNaN(inputValue)) {
      console.log(selectedNightCharge);
      setSelectedNightCharge(inputValue);
      console.log(selectedNightCharge);
    } else {
      setSelectedNightCharge(parseInt(inputValue));
    }
    setisNightBataChanged(true);
  };

  const handleDayDriverAllowanceChanged = (e) => {
    const inputValue = e.target.value;
    if (inputValue === "" || isNaN(inputValue)) {
      setSelectedDayDriverAllowance("");
    } else {
      setSelectedDayDriverAllowance(parseInt(inputValue));
    }
    setisDayDriverAllowance(true);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Check which values have been changed and use accordingly
    const updatedMinKmPerDay = isMinkmperdayChanged
      ? selectedMinKm
      : traiffDetails.fareDetails.minKmPerDay;
    const updatedPerKmCharge = isPerkmChanged
      ? selectedPerKmCharge
      : traiffDetails.fareDetails.perKmCharge;
    const updatedExtraKmCharge = isExtrakmChanged
      ? selectedExtraKmCharge
      : traiffDetails.fareDetails.extraKmCharge;
    const updatedGstValue = isGstValueChanged
      ? selectedGst
      : traiffDetails.fareDetails.gstRate;
    const updatedMorningBata = isMorningBataChanged
      ? selectedExtraMorBata
      : traiffDetails.fareDetails.morningDriverAllowance;
    const updatedNightBata = isNightBataChanged
      ? selectedNightCharge
      : traiffDetails.fareDetails.nightDriverAllowance;
    const updatedDayDriverAllowance = isDayDriverAllowance
      ? selectedDayDriverAllowance
      : traiffDetails.fareDetails.dayDriverAllowance;

    //for airport and local
    const updatedBaseFair = isBaseFairChanged
      ? selectedBaseFare
      : traiffDetails.fareDetails.baseFare;
    const updatedExtraHours = isExtraHoursChanged
      ? selectedExtraHours
      : traiffDetails.fareDetails.extraHrsCharge;
    const updatedMinKm = isMinKmChanged
      ? selectedMinKm
      : traiffDetails.fareDetails.minKm;
    const updatedMinHours = isMinHoursChanged
      ? selectedMinHours
      : traiffDetails.fareDetails.minHrs;

    if (traiffDetails.tripType === "Airport") {
      const airportObj = {
        locationId: traiffDetails.location._id,
        carCategoryId: traiffDetails.carCategory._id,
        tripType: traiffDetails.tripType,
        subTripType: traiffDetails.subTripType,
        distance: traiffDetails.distance,
        fuelType: traiffDetails.fuelType,
        days: traiffDetails.days,
        morningDriverAllowance: updatedMorningBata,
        nightDriverAllowance: updatedNightBata,
        dayDriverAllowance: updatedDayDriverAllowance,
        extraKmCharge: updatedExtraKmCharge,
        gstRate: updatedGstValue,
        baseFare: updatedBaseFair,
        minKm: updatedMinKm,
        minHrs: updatedMinHours,
        extraHrsCharge: updatedExtraHours,
        // minKmPerDay:selectedMinKm,perKmCharge:"10"
      };
      console.log(airportObj);
      dispatch(updateLocalAirportTariffFun(airportObj, tariffId));
      setTimeout(() => {
        navigate("/tariff-management");
      }, 3000);
    } else if (traiffDetails.tripType === "Local") {
      const localtariffObj = {
        locationId: traiffDetails.location._id,
        carCategoryId: traiffDetails.carCategory._id,
        tripType: traiffDetails.tripType,
        subTripType: traiffDetails.subTripType,
        distance: traiffDetails.distance,
        fuelType: traiffDetails.fuelType,
        days: traiffDetails.days,
        morningDriverAllowance: updatedMorningBata,
        nightDriverAllowance: updatedNightBata,
        dayDriverAllowance: updatedDayDriverAllowance,
        extraKmCharge: updatedExtraKmCharge,
        gstRate: updatedGstValue,
        baseFare: updatedBaseFair,
        minKm: updatedMinKm,
        minHrs: updatedMinHours,
        extraHrsCharge: updatedExtraHours,
        // minKmPerDay:selectedMinKm,perKmCharge:"10"
      };
      console.log(localtariffObj);
      dispatch(updateLocalAirportTariffFun(localtariffObj, tariffId));
      setTimeout(() => {
        navigate("/tariff-management");
      }, 3000);
    } else {
      const outstationtariffObj = {
        locationId: traiffDetails.location._id,
        carCategoryId: traiffDetails.carCategory._id,
        tripType: traiffDetails.tripType,
        subTripType: traiffDetails.subTripType,
        distance: traiffDetails.distance,
        fuelType: traiffDetails.fuelType,
        days: traiffDetails.days,
        morningDriverAllowance: updatedMorningBata,
        nightDriverAllowance: updatedNightBata,
        dayDriverAllowance: updatedDayDriverAllowance,
        extraKmCharge: updatedExtraKmCharge,
        gstRate: updatedGstValue,
        minKmPerDay: updatedMinKmPerDay,
        perKmCharge: updatedPerKmCharge,
      };
      console.log(updatedMorningBata, updatedNightBata);
      console.log(outstationtariffObj);
      dispatch(updateOutstationTariff(outstationtariffObj, tariffId));

      // Reset the changed states
      setisMinkmperdayChanged(false);
      setisPerkmChanged(false);
      setisExtrakmChanged(false);
      setisGstValueChanged(false);
      setisMorningBataChanged(false);
      setisNightBataChanged(false);
      setisDayDriverAllowance(false);
      console.log(outstationtariffObj);
      setTimeout(() => {
        console.log(outstationtariffObj);
        navigates("/tariff-management");
      }, 3000);
    }
  };

  console.log(getSingleOutstationTariff());

  // Function to handle item click
  const handleItemClick = (itemName) => {
    // Perform actions based on the clicked item
    console.log(`Clicked on item: ${itemName}`);
    // Add further logic here, such as updating state or performing other operations
  };

  // Function to handle navigation
  const navigate = (to) => {
    // Perform navigation logic, e.g., using react-router-dom
    console.log(`Navigating to: ${to}`);
    navigates(to);
    // Implement navigation logic here, e.g., using history.push() or Link components
  };

  return (
    <div className="create-new-tariff-container">
      <Header setSidebar={setSidebar} sidebar={sidebar} />
      <div className="navbar-adminInfo">
        <Navbar
          sidebar={sidebar}
          handleItemClick={handleItemClick}
          navigate={navigate}
        />
        <div className="form-container">
          <form onSubmit={handleSubmit}>
            <div
              style={{
                padding: "10px 0",
              }}
              className="price-details"
            >
              {/* {singleTariffData.map((item) =>(
              <> */}
              <div className="crete-new-sub-head">Add Price Details</div>
              <div className="row">
                <DropdownColumn
                  title="Location"
                  value={[
                    traiffDetails.location.state,
                    traiffDetails.location.city,
                  ]}
                  // option={[singlelocation[0], singlelocation[1]]}
                  option={[
                    traiffDetails.location.state,
                    traiffDetails.location.city,
                  ]}
                />

                <DropdownColumn
                  title="Car Category"
                  disable="disabled"
                  value={traiffDetails.carCategory.category}
                  option={traiffDetails.carCategory.category}
                />

                <DropdownColumn
                  title="Trip Type"
                  disable="disabled"
                  value={traiffDetails.tripType}
                  option={traiffDetails.tripType}
                />

                <DropdownColumn
                  title="Sub Trip Type"
                  disable="disabled"
                  value={traiffDetails.subTripType}
                  option={traiffDetails.subTripType}
                />
              </div>

              <div
                style={{
                  marginTop: "20px",
                }}
                className="row"
              >
                <DropdownColumn
                  title="Distance"
                  disable="disabled"
                  value={traiffDetails.distance}
                  option={traiffDetails.distance}
                />

                <DropdownColumn
                  title="Fuel"
                  disable="disabled"
                  value={traiffDetails.fuelType}
                  option={traiffDetails.fuelType}
                />

                <DropdownColumn
                  title="Days"
                  disabled
                  value={traiffDetails.days}
                  option={traiffDetails.days}
                />
              </div>
              {/* </>
            ))} */}
            </div>

            {(traiffDetails.tripType === "Local" ||
              traiffDetails.tripType === "Airport") && (
              <div className="price-slab">
                <div className="crete-new-sub-head">Pricing Slab</div>
                <div className="row">
                  <InputColumn
                    title="Base Fare"
                    text="Rs."
                    position="left"
                    selectedValue={selectedBaseFare}
                    handleChange={handleBaseFairChanged}
                    isChanged={isBaseFairChanged}
                    getData={traiffDetails.fareDetails.baseFare}
                  />

                  <InputColumn
                    title="Min Km"
                    text="Km"
                    position="right"
                    selectedValue={selectedMinKm}
                    handleChange={handleMinKm}
                    isChanged={isMinKmChanged}
                    getData={traiffDetails.fareDetails.minKm}
                  />

                  <InputColumn
                    title="Min Hours"
                    text="Hrs"
                    position="right"
                    selectedValue={selectedMinHours}
                    handleChange={handleMinHours}
                    isChanged={isMinHoursChanged}
                    getData={traiffDetails.fareDetails.minHrs}
                  />
                </div>

                <div style={{
                  margin:"50px 0px"
                }} className="row">
                  <InputColumn
                    title="Extra KM"
                    text="Rs./Km"
                    position="right"
                    selectedValue={selectedExtraKmCharge}
                    handleChange={handleExtraKmChanged}
                    isChanged={isExtrakmChanged}
                    getData={traiffDetails.fareDetails.extraKmCharge}
                  />

                  <InputColumn
                    title="Extra Hours"
                    text="Rs./Hrs"
                    position="right"
                    selectedValue={selectedExtraHours}
                    handleChange={handleExtraHours}
                    isChanged={isExtraHoursChanged}
                    getData={traiffDetails.fareDetails.extraHrsCharge}
                  />

                  <InputColumn
                    title="GST"
                    text="%"
                    position="right"
                    selectedValue={selectedGst}
                    handleChange={handleGstChanged}
                    isChanged={isGstValueChanged}
                    getData={traiffDetails.fareDetails.gstRate}
                  />
                </div>
              </div>
            )}

            {(traiffDetails.tripType === "Local" ||
              traiffDetails.tripType === "Airport") && (
              <div className="driver-allowance">
                <div className="crete-new-sub-head">Driver Allowance</div>

                <div className="row">
                  <InputColumn
                    title="Morning Charge"
                    text="Rs./Day"
                    position="right"
                    selectedValue={selectedExtraMorBata}
                    handleChange={handleMorningBataChanged}
                    isChanged={isMorningBataChanged}
                    getData={traiffDetails.fareDetails.morningDriverAllowance}
                  />

                  <InputColumn
                    title="Night Charge"
                    text="Rs./Day"
                    position="right"
                    selectedValue={selectedNightCharge}
                    handleChange={handleNightBataChanged}
                    isChanged={isNightBataChanged}
                    getData={traiffDetails.fareDetails.nightDriverAllowance}
                  />

                  {/* <InputColumn
                    title="Day Bata"
                    text="Rs./Day"
                    position="right"
                    selectedValue={selectedDayDriverAllowance}
                    isChanged={isDayDriverAllowance}
                    handleChange={handleDayDriverAllowanceChanged}
                    getData={traiffDetails.fareDetails.dayDriverAllowance}
                  /> */}
                </div>
              </div>
            )}

            {traiffDetails.tripType === "Outstation" && (
              <div>
                <div className="price-slab">
                  <div className="crete-new-sub-head">Pricing Slab</div>
                  <div style={{
                    marginBottom:"60px"
                  }} className="row">
                    <InputColumn
                      text="Km"
                       position="right"
                      title="Min Km/day"
                      selectedValue={selectedMinKm}
                      handleChange={handleMinKmChanged}
                      isChanged={isMinkmperdayChanged}
                      getData={traiffDetails.fareDetails.minKmPerDay}
                    />

                    <InputColumn
                      text="Rs./Km"
                    position="right"
                      title="Per Km Charge"
                      selectedValue={selectedPerKmCharge}
                      handleChange={handlePerKmChanged}
                      isChanged={isPerkmChanged}
                      getData={traiffDetails.fareDetails.perKmCharge}
                    />

                    <InputColumn
                      text="Rs./Km"
                    position="right"
                      title="Extra KM Charge"
                      selectedValue={selectedExtraKmCharge}
                      handleChange={handleExtraKmChanged}
                      isChanged={isExtrakmChanged}
                      getData={traiffDetails.fareDetails.extraKmCharge}
                    />

                    <InputColumn
                      title="GST"
                        text="%"
                       position="right"
                      selectedValue={selectedGst}
                      handleChange={handleGstChanged}
                      isChanged={isGstValueChanged}
                      getData={traiffDetails.fareDetails.gstRate}
                    />
                  </div>
                </div>
                <div className="driver-allowance">
                  <div className="crete-new-sub-head">Driver Allowance</div>
                  <div className="row">
                    <InputColumn
                      text="Rs./Day"
                    position="right"
                      title={traiffDetails?.subTripType==="Round Trip"?"Morning Allowance":"Morning Charge"}
                      selectedValue={selectedExtraMorBata}
                      handleChange={handleMorningBataChanged}
                      isChanged={isMorningBataChanged}
                      getData={traiffDetails.fareDetails.morningDriverAllowance}
                    />

                   {traiffDetails?.subTripType === "One Way" &&  <InputColumn
                      text="Rs./Day"
                    position="right"
                      title="Night Charge"
                      selectedValue={selectedNightCharge}
                      handleChange={handleNightBataChanged}
                      isChanged={isNightBataChanged}
                      getData={traiffDetails.fareDetails.nightDriverAllowance}
                    />
}
                   { traiffDetails?.subTripType === "Round Trip" &&<InputColumn
                      text="Rs./Day"
                    position="right"
                      title="Driver Allowance"
                      selectedValue={selectedDayDriverAllowance}
                      isChanged={isDayDriverAllowance}
                      handleChange={handleDayDriverAllowanceChanged}
                      getData={traiffDetails.fareDetails.dayDriverAllowance}
                    />}
                  </div>
                </div>
              </div>
            )}

            <div style={{

            }} className="add">
              <button className="add-btn" type="submit">
                Update
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default UpdateTariff;
