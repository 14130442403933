import React from "react";
import { useState, useEffect } from "react";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./ViewAllCar.css";
import AddNewDriver from "./BookingHistory";
//import BookingHistory from "./components/DriverManagement/BookingHistory";
//import BookingHistory from "./BookingHistory";
import AddNewCar from "./AddNewCar";
import { ImEye } from "react-icons/im";
import { FaPen } from "react-icons/fa6";
import { IoSearch } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchInactive,
  selectInactiveDriver,
  selectLoading,
} from "./DriverSlice";
import {
  currentDriverId,
  resetDriverDetails,
  fetchDriversDetails,
  resetCompState,
} from "./DriverDetailsSlice";
import { useNavigate } from "react-router-dom";
import InputColumn from "../Booking/Input";
import DropdownColumn from "../Booking/Dropdown";
import Filter from "../Booking/Filter";
import { Toggle } from "../TariffManagement/Toggle";
import { toggleDriverStatus } from "./DriverSlice";
import axios from "axios";
import { IoIosArrowDown } from "react-icons/io";

const CustomDropdown = ({
  options,
  placeholder,
  selectedValue,
  handleChange,
}) => {
  const placeholderOption = {
    label: placeholder || "Select an option",
    value: "",
  };

  return (
    <div className="div1">
      <select
        value={selectedValue}
        onChange={handleChange}
        className="dropdown-select-1 " style={{borderRadius:"5px", height:"28px", width:"126px", border:"1px solid white", boxShadow: "0px 1px 8px 0px #0000002B"}}
   
      >
        {[placeholderOption, ...options].map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      <IoIosArrowDown className="dropdown-icon" style={{marginTop:"0px", paddingTop:"5px"}}  />

    </div>
  );
};

// const InputColumn = ({ title, selectedValue, handleChange }) => {
//   return (
//     <div class="input-container">
//       <input
//         type="text"
//         value={selectedValue}
//         onChange={handleChange}
//         className="input-text"
//         placeholder={title}
//       />
//       {/* <IoSearch className="search-icon" /> */}
//     </div>
//   );
// };

const TableItem = ({ item, index }) => {
  const rowClass = index % 2 === 0 ? "table-row-even" : "table-row-odd";
  return (
    <tr className={rowClass}>
      <td>{item["Sr no."]}</td>
      <td>{item["Location"]}</td>
      <td>{item["Driver Name"]}</td>
      <td>{item["Driver Mobile No."]}</td>
      <td>{item["No. of Ride"]}</td>
      <td>{item["Rating"]}</td>
      <td>{item["Registered On"]}</td>
      <td>{item["Verified On"]}</td>
      <td>{item["Status"]}</td>
      <td>{item["Reason"]}</td>
      <td>{item["Action"]}</td>
    </tr>
  );
};

const Table = ({ headers, data }) => {
  return (
    <div className="table-containers">
      <div className="table-scroll">
    <table className="table">
      <thead>
        <tr>
          {headers.map((header, index) => (
            <th key={index}>{header}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((item, index) => (
          <TableItem key={index} item={item} index={index} />
        ))}
      </tbody>
    </table>
    </div>
    </div>
  );
};

const ViewAllCar = () => {
  const [locationOptions, setLocationOptions] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState("");
  const [searchPhoneNumber, setsearchPhoneNumber] = useState("");
  const [searchName, setsearchName] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedApprovel, setselectedApprovel] = useState("");
  const [placeholder, setPlaceholder] = useState("Select a location");
  const [showDetails, setShowDetails] = useState(false);
  const [editDetails, setEditDetails] = useState(false);

  const Inactives = useSelector(selectInactiveDriver);
  const loader = useSelector(selectLoading);
  const dispatch = useDispatch();
  const { driversId, currentComp } = useSelector((state) => state.driverInfo);
  const navigate = useNavigate();
  const currentSection = "InactiveDriver";

  const handleImEyeClick = (id) => {
    console.log("checking inactive driver id", id);
    dispatch(currentDriverId(id));
    setShowDetails(true);
  };

  const handleEditIcon = (id) => {
    dispatch(currentDriverId(id));
    setEditDetails(true);
  };

  console.log(loader);
  useEffect(() => {
    dispatch(fetchInactive());
  }, [dispatch]);

  console.log(Inactives);

  // const handleInactiveDriver = (driverId) => {
  //   console.log("Driver id ", driverId);
  //   try {
  //     const updatedStatus = "Inactive";
  //     const response = axios.put(
  //       `https://api.yatricabs.com/v1/admin/drivers/${driverId}/status`,
  //       { status: "Inactive" }
  //     );
  //     console.log(response);
  //     dispatch(toggleDriverStatus(driverId, updatedStatus));
  //     dispatch(fetchInactive());
  //   } catch (error) {
  //     console.error("Error toggling driver status: ", error);
  //   }
  // };

  const handleActiveDriver = (driverId) => {
    console.log("Driver id ", driverId);
    try {
      const updatedStatus = "Active";
      const response = axios.put(
        `https://api.yatricabs.com/v1/admin/drivers/${driverId}/status`,
        { status: "Active" }
      );
      console.log(response);
      alert("Driver status set to active");
      dispatch(toggleDriverStatus(driverId, updatedStatus));
      dispatch(fetchInactive());
    } catch (error) {
      console.error("Error toggling driver status: ", error);
    }
  };

  const logState = async (state, driverId) => {
    console.log("Toggled:", state);
    // setToggleState(state);
    console.log(driverId);
    state === false && handleActiveDriver(driverId);
    // ? handleActiveDriver(driverId)
    // : handleInactiveDriver(driverId);
  };

  const headers = [
    "Sr no.",
    "Location",
    "Driver Name",
    "Driver Mobile No.",
    "No. of Ride",
    "Rating",
    "Registered On",
    "Verified On",
    "Status",
    "Reason",
    "Action",
  ];
  const filteredDrivers = Inactives.filter((Inactive) => {
    const selectedRating = parseInt(selectedApprovel, 10);
    return (
      (!selectedStatus || Inactive.status === selectedStatus) &&
      (!selectedLocation || Inactive.location === selectedLocation) &&
      (!searchName ||
        Inactive.name.toLowerCase().includes(searchName.toLowerCase())) &&
      (!searchPhoneNumber || Inactive.phone.includes(searchPhoneNumber)) &&
      (!selectedRating || Inactive.rating === selectedRating)
    );
  });
  const data = [];

  for (let i = 0; i < filteredDrivers.length; i++) {
    const Inactive = filteredDrivers[i];

    data.push({
      "Sr no.": i + 1,
      Location: `${Inactive.location.state} ${Inactive.location.city}`,
      "Driver Name": `${Inactive.firstName} ${Inactive.lastName}`,
      "Driver Mobile No.": Inactive.phone.startsWith("+91-")
        ? Inactive.phone
        : `+91- ${Inactive.phone}`,
      "No. of Ride": Inactive.noOfRides,

      "Registered On": Inactive.createdAt,
      // "Verified On": driver.verifiedOn,
      "Verified On": "22-10-2023", // "verifiedOn" is not present in the data
      "Approval Status": Inactive.approval.status,
      Status: Inactive.status.state,
      Reason: Inactive.status.reason,
      Action: (
        <div className="box-action">
          <Toggle
            // toggled={Inactives.status === "Active" ? false : true}
            // onClick={(state) => {
            //   console.log("Driver id passed : ",Inactives._id)
            //   logState(state, Inactive._id)}
            // }
            toggled={true}
            onClick={(state) => {
              console.log("Driver id passed : ", Inactives._id);
              logState(state, Inactive._id);
            }}
          />

          <ImEye
            className="icon icon-large"
            onClick={() => handleImEyeClick(Inactive._id)}
          />
          <FaPen
            className="icon-edit "
            onClick={() => handleEditIcon(Inactive._id)}
          />
        </div>
      ),
    });
  }
  const DriverList = data;

  useEffect(() => {
    const extractlocationAsOptions = (dataArray) => {
      if (Array.isArray(dataArray.data)) {
        const allLocationsOption = { label: "Select a location", value: "" };
        const locationOptions = dataArray.data.map((item) => ({
          label: item.location,
          value: item._id,
        }));
        return [allLocationsOption, ...locationOptions];
      }
      return [];
    };

    const fetchOptions = async () => {
      try {
        const locationRespons = await fetch(
          "https://api.yatricabs.com/v1/locations"
        );
        if (!locationRespons.ok) {
          throw new Error(`HTTP error! Status: ${locationRespons.status}`);
        }
        const locationData = await locationRespons.json();
        const locationOptions = extractlocationAsOptions(locationData);
        console.log(locationOptions);
        setLocationOptions(locationOptions);
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };
    fetchOptions();
  }, []);

  useEffect(() => {
    if (driversId !== null && showDetails) {
      dispatch(resetDriverDetails());
      dispatch(fetchDriversDetails(driversId));
      navigate(
        `/drivermanagement/${currentSection}/driverDetails/${driversId}`
      );
    }

    setShowDetails(false);

    console.log("checking Inactive drivers id in effect", driversId);
  }, [showDetails]);

  useEffect(() => {
    if (driversId !== null && editDetails) {
      dispatch(resetDriverDetails());
      navigate(
        `/drivermanagement/${currentSection}/modify-details/${driversId}`
      );
    }
    setShowDetails(false);
    console.log("checking drivres id in effect", driversId);
  }, [editDetails]);

  return (
    <div>
      <>
        <div className="row">
          <div className="dropdown-container" >
            <Filter />
            <CustomDropdown
              placeholder={placeholder}
              options={locationOptions}
              selectedValue={selectedLocation}
              // handleChange={(e) => setSelectedLocation(e.target.value)}
              handleChange={(e) => {
                const selectedOption = locationOptions.find(
                  (option) => option.value === e.target.value
                );

                if (selectedOption) {
                  const selectedCity = selectedOption.label;
                  console.log("Selected City:", selectedCity);
                  // setSelectedLocation(selectedCity);
                  // setPlaceholder(selectedCity);
                  if (selectedCity === "Select a location") {
                    // If "Select a location" is selected, set an empty string to show all locations
                    setSelectedLocation("");
                    setPlaceholder("Select a location");
                  } else {
                    setSelectedLocation(selectedCity);
                    setPlaceholder(selectedCity);
                  }
                } else {
                  setPlaceholder("Select a location");
                }
              }}
              className="dropdown"
            />
            <InputColumn
              title="Search by Name"
              selectedValue={searchName}
              handleChange={(e) => setsearchName(e.target.value)}
            />
            <InputColumn
              title="Search by Mobile No."
              selectedValue={searchPhoneNumber}
              handleChange={(e) => setsearchPhoneNumber(e.target.value)}
            />
            <CustomDropdown
              placeholder="Status"
              options={[
                { label: "Active", value: "Active" },
                { label: "Inactive", value: "Inactive" },
              ]}
              selectedValue={selectedStatus}
              handleChange={(e) => setSelectedStatus(e.target.value)}
              className="dropdown"
            />
            <CustomDropdown
              placeholder="Rating"
              options={[
                { label: "1 Star", value: "1" },
                { label: "2 Stars", value: "2" },
                { label: "3 Stars", value: "3" },
                { label: "4 Stars", value: "4" },
                { label: "5 Stars", value: "5" },
              ]}
              selectedValue={selectedApprovel}
              handleChange={(e) => setselectedApprovel(e.target.value)}
              className="dropdown"
            />
          </div>
        </div>
        <Table
          headers={headers}
          data={DriverList.map((item) => ({
            ...item,
          }))}
        />
      </>
    </div>
  );
};

export default ViewAllCar;
