import React, { useEffect } from "react";
// import { IoArrowBackCircleSharp, IoArrowForwardCircle } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Loader from "../Loader";
import "./Interaction.css";
import { fetchInteraction, selectInteraction } from "./InteractionSlice";

const InteractionItem = ({
  assistedBy,
  dateTime,
  contactType,
  queryType,
  subqureyType,
  remark,
}) => {
  return (
    <>
      <div className="secondary">
        <div className="main-box-body-inteaction">
          <div className="main-box-intro-inteaction">
            <div className="main-box-text-inteaction">
              <h5 style={{ marginLeft: "0.60rem" }}>Assisted By :</h5>
              <h6>{assistedBy}</h6>
            </div>
          </div>
          <div className="main-box-intro-inteaction">
            <div className="main-box-text-inteaction">
              <h5>Date & Time :</h5>
              <h6>{dateTime}</h6>
            </div>
          </div>
          <div className="main-box-intro-inteaction">
            <div className="main-box-text-inteaction">
              <h5>Contact Type :</h5>
              <h6>{contactType}</h6>
            </div>
          </div>
          <div className="main-box-intro-inteaction">
            <div className="main-box-text-inteaction">
              <h5>Query Type :</h5>
              <h6>{queryType}</h6>
            </div>
          </div>
          <div className="main-box-intro-inteaction">
            <div className="main-box-text-inteaction">
              <h5>Sub Query Type :</h5>
              <h6>{subqureyType}</h6>
            </div>
          </div>
        </div>
        <div className="main-box-line-inteaction">
          <div className="main-box-Remark">
            <h5>Remark :</h5>
            <h6>{remark ? remark : "N/A"}</h6>
          </div>
        </div>
      </div>
    </>
  );
};

const Interation = ({ handleButtonClick }) => {
  const dispatch = useDispatch();
  const interaction = useSelector(selectInteraction);
  const { id } = useParams();
  useEffect(() => {
    dispatch(fetchInteraction(id));
  }, []);

  console.log("interaction", interaction);

  return (
    <div className="main-box-inteaction">
      {/* <div style={{ display: "flex", justifyContent: "space-between" }}>
        <IoArrowBackCircleSharp
          onClick={() => handleButtonClick("TaskDetails")}
          className="back"
        />
        <IoArrowForwardCircle
          onClick={() => handleButtonClick("RouteLog")}
          className="back"
        />
      </div> */}

      <div className="top-extra-space"></div>

      {interaction ? (
        <>
          {interaction.length === 0 ? (
            <div className="no-interaction-message">
              No interaction available for this Booking
            </div>
          ) : (
            <>
              {interaction.map((item, index) => (
                <InteractionItem
                  key={index}
                  assistedBy={
                    item.assistedBy?.firstName + " " + item.assistedBy?.lastName
                  }
                  dateTime={item.dateTime ? item.dateTime : "Not Available"}
                  contactType={item?.contactType}
                  queryType={item?.queryType}
                  subqureyType={item?.subQueryType}
                  remark={item.remarks ? item?.remarks : "Not Available"}
                />
              ))}
            </>
          )}
        </>
      ) : (
        <div className="loading-circle">
          <Loader />
        </div>
      )}
    </div>
  );
};

export default Interation;

/**<div>
      <div className="main-box-inteaction">
        <div className="main-box-body-inteaction">
          <div className="main-box-intro-inteaction">
            <div className=" main-box-text-inteaction">
              <h5 style={{ marginLeft: "0.60rem" }}>Assisted By :</h5>
              <h6>Sankalp Jha</h6>
            </div>
          </div>
          <div className="main-box-intro-inteaction">
            <div className=" main-box-text-inteaction">
              <h5>Date & Time :</h5>
              <h6>12/10/2023 05:15PM</h6>
            </div>
          </div>
          <div className="main-box-intro-inteaction">
            <div className=" main-box-text-inteaction">
              <h5>Contact Type :</h5>
              <h6>Inbound Call</h6>
            </div>
          </div>
          <div className="main-box-intro-inteaction">
            <div className=" main-box-text-inteaction">
              <h5>Query Type :</h5>
              <h6>New Booking</h6>
            </div>
          </div>
        </div>
        <div className="main-box-line-inteaction"></div>
        <div className="main-box-Remark">
          <h5>Remark :</h5>
          <h6>
            As per customer request, New booking created for Outstation-Round
            trip journey, Customer has specially requested for Innova Crysta
            with luggage carrier{" "}
          </h6>
        </div>
      </div>
    </div>  */
