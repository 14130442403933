import React, { useEffect, useState } from "react";
//import { Document, Page } from "react-pdf";
import "./ModifyCarData.css";
import axios from "axios";
import DropdownColumnCar from "../../Booking/Dropdown";
import { useParams, Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchSingleCarDetailsAsync } from "../CarDetailsSlice";
import { resetCompState } from "../carSlice";
//import Dropdown from 'react-dropdown-select';
import toast, { Toaster } from "react-hot-toast";
import { FaLongArrowAltRight } from "react-icons/fa";
import Loader from "../../Loader";
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const DropdownColumn = ({
  title,
  options,
  selectedValue, 
  handleChange,
  selectedFile,
  removeFile,
}) => {
  return (
    <div className="View-add-new-car-dropdown-column">
      <h3>{title}</h3>
      <select value={selectedValue} onChange={handleChange}>
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );

  
};

const ModifyCarData = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [loading,setLoading]=useState(false);
  const [cityoption, setCityOption] = useState([]);
  const [driveroption, setDriverOption] = useState([]);
  const [randomCategory, setRandomCategory] = useState([]);
  const [selectcarmanufacturerArray, setSelectcarmanufacturerArray] = useState(
    []
  );
  const [selectCarModelArray, setSelectCarModelArray] = useState([]);

  const [selectcity, setSelectCity] = useState("");
  const [selectcarmanufacturer, setSelectCarManufacturer] = useState("");
  const [selectCategory, setSelectCategory] = useState("");
  const [selectcarmodel, setSelectCarModel] = useState("");
  const [selectcarCategory, setSelectCarCategory] = useState("");
  const [selectfuelcategory, setSelectFuelCategory] = useState("");


  const [carFrontPhoto, setcarFrontPhoto] = useState(null);
  const [permitDetails, setpermitDetails] = useState(null);
  const [carPhotoSide, setcarPhotoSide] = useState(null);
  const [PUCTest, setPUCTest] = useState(null);
  const [registrationCertificate, setregistrationCertificate] = useState(null);
  const [insurancePolicy, setinsurancePolicy] = useState(null);
  const [carRegistrationYear, setCarRegistrationYear] = useState("");
  const [registrationCertificateBack, setregistrationCertificateBack] =
    useState(null);

    
  const [showCloseIconCar, setShowCloseIconCar] = useState(false);
  const [showCloseIconPermit, setShowCloseIconPermit] = useState(false);
  const [showCloseIconFront, setShowCloseIconFront] = useState(false);
  const [showCloseIconPUC, setShowCloseIconPUC] = useState(false);
  const [showCloseIconCertificate, setShowCloseIconCertificate] =
    useState(false);
  const [showCloseIconCertificateBack, setShowCloseIconCertificateBack] =
    useState(false);
  const [showCloseIconPolicy, setShowCloseIconPolicy] = useState(false);
  const [carColor, setCarColor] = useState("");
  const [rcNumber, setrcNumber] = useState("");
  const [expiryDate, setExpiryDate] = useState("");

  const [insuranceExpiryDate, setInsuranceExpiryDate] = useState(null);
  const [pucExpiryDate, setPucExpiryDate] = useState(null);
  const [permitDetailsExpiryDate, setPermitDetailsExpiryDate] = useState(null);

  const [updatedData, setUpdatedData] = useState();
  const [carLocation, setCarLocation] = useState("");

  const id = useParams();
  const carid = id.id;

  const singleCarDetail = useSelector((state) => state.singleCar.singleCarData);

  console.log("singlecardetail",singleCarDetail);
  useEffect(() => {
    // Assuming carid is the ID of the car you want to fetch
    dispatch(fetchSingleCarDetailsAsync(carid));
    checkParentLocation(window.location.pathname);
  }, []);

  const [inputValue, setInputValue] = useState({
    driver: "",
    carCategory: "",
    carMake: "",
    fuelType: "",
    carColor: "",
    rcNumber: "",
    permitExpiryDate: "",
    insuranceExpiryDate: "",
    pucExpiryDate: "",
    carRegistrationYear: "",

    carFrontPhoto: "",

    location: "",
  });


  const [carCategory, setCarCategory] = useState("");
  const [driver, setDriver] = useState("");
  const [callServer, setCallServer] = useState(false);

  useEffect(() => {
    if (singleCarDetail) {
      setCarLocation(
        singleCarDetail.location?.city + ", " + singleCarDetail.location?.state
      );
      // setDriver(
      //   singleCarDetail.driver?.firstName +
      //     " " +
      //     singleCarDetail.driver?.lastName
      // );

      setDriver(singleCarDetail.driver?._id)

      setCarCategory(singleCarDetail.carCategory?.category);
      setcarFrontPhoto(singleCarDetail.carPhoto?.frontPhoto);
      setcarPhotoSide(singleCarDetail.carPhoto?.sidePhoto);
      setregistrationCertificate(
        singleCarDetail.registrationCertificate?.frontPhoto
      );
      setregistrationCertificateBack(
        singleCarDetail.registrationCertificate?.backPhoto
      );
      setinsurancePolicy(singleCarDetail.insurance?.photo);
      setPUCTest(singleCarDetail.puc?.photo);
      setpermitDetails(singleCarDetail.permit?.photo);
      setInsuranceExpiryDate(singleCarDetail.insurance?.expiryDate);
      setPucExpiryDate(singleCarDetail.puc?.expiryDate);
      setPermitDetailsExpiryDate(singleCarDetail.permit?.expiryDate);
      setCarColor(singleCarDetail.carColor);
      setrcNumber(singleCarDetail.registrationCertificate?.number);
      setCarRegistrationYear(singleCarDetail.registrationCertificate?.year);
      setSelectCarCategory(singleCarDetail.carCategory?._id);
      setSelectCity(singleCarDetail.location?._id);
      setSelectCarManufacturer(singleCarDetail?.carMake);
      setSelectCarModel(singleCarDetail?.carModel);
      setSelectFuelCategory(singleCarDetail.fuelType);

      setInputValue({
        location: singleCarDetail.location?._id,
        driver: singleCarDetail.driver?._id,
        carCategory: singleCarDetail.carCategory?._id,
        carModel: singleCarDetail.carModel,
        carMake: singleCarDetail.carMake,
        fuelType: singleCarDetail.fuelType,
        carColor: singleCarDetail.carColor,
        carRegistrationYear: singleCarDetail.registrationCertificate?.year,
        rcNumber: singleCarDetail.registrationCertificate?.number,
        permitExpiryDate: singleCarDetail.permit?.expiryDate,
        insuranceExpiryDate: singleCarDetail.insurance?.expiryDate,
        pucExpiryDate: singleCarDetail.puc?.expiryDate,

        carFrontPhoto: singleCarDetail.carPhoto?.frontPhoto,
      });
    }
  }, [singleCarDetail]);

 

  console.log("selectCategory",selectCategory);
  console.log("Driver",driver)
  
  const fileFields = [
    {
      label: "Car Photo Front",
      state: carFrontPhoto,
      setState: setcarFrontPhoto,
    },
    { label: "Car Photo Side", 
      state: carPhotoSide, 
      setState: setcarPhotoSide 
    },
    {
      label: "Registration Certificate Front",
      state: registrationCertificate,
      setState: setregistrationCertificate,
    },
    {
      label: "Registration Certificate Back",
      state: registrationCertificateBack,
      setState: setregistrationCertificateBack,
    },
    {
      label: "Permit Details",
      state: permitDetails,
      setState: setpermitDetails,
      showDate: true,
      dateState: permitDetailsExpiryDate,
      setDateState: setPermitDetailsExpiryDate,
    },
    {
      label: "PUC Test",
      state: PUCTest,
      setState: setPUCTest,
      showDate: true,
      dateState: pucExpiryDate,
      setDateState: setPucExpiryDate,
    },
    {
      label: "Insurance Policy",
      state: insurancePolicy,
      setState: setinsurancePolicy,
      showDate: true,
      dateState: insuranceExpiryDate,
      setDateState: setInsuranceExpiryDate,
    },
  ];

  const handleFileChange = (e, setFile, setShowCloseIcon) => {
   
    const file = e.target.files[0];
    console.log("file",file)

    setInputValue({
      ...inputValue,
      [e.target.name]: URL.createObjectURL(file),
    });

    setUpdatedData({
      ...updatedData,
      [e.target.name]: file,
    });
    if (file) {
      setFile(file);
      setShowCloseIcon(true);
    }
  };

  const handleRemoveFile = (setFile, setShowCloseIcon) => {
    setFile(null);
    setShowCloseIcon(false);
  };

  const FuelCategoryOption = [
    { label: "Select Fuel Category", value: "city0" },
    { label: "Petrol", value: "Petrol" },
    { label: "Diesel", value: "Diesel" },
    { label: "CNG", value: "CNG" },
    { label: "Electric", value: "Electric" },
  ];
  const handleSubmit = async () => {
  
    // setUpdatedData({
    //   ...updatedData,
    //   location: selectcity,
    //   driver: selectCategory,
    //   carCategory: selectcarCategory,
    //   carModel: selectcarmodel,
    //   carMake: selectcarmanufacturer,
    //   fuelType: selectfuelcategory,
    //   carColor: carColor,
    //   carRegistrationYear: carRegistrationYear,
    //   rcNumber: rcNumber,
    //   permitExpiryDate: permitDetailsExpiryDate,
    //   insuranceExpiryDate: insuranceExpiryDate,
    //   pucExpiryDate: pucExpiryDate,
    // });
    
    const formData = new FormData();

    formData.append("location", selectcity);
    formData.append("driver", driver);
    formData.append("carCategory", selectcarCategory);
    formData.append("carMake", selectcarmanufacturer);
    formData.append("carModel", selectcarmodel);
    formData.append("fuelType", selectfuelcategory);
    formData.append("carColor", carColor);
    formData.append("carRegistrationYear", carRegistrationYear);
    formData.append("rcNumber", rcNumber);
    formData.append("permitExpiryDate", permitDetailsExpiryDate);
    formData.append("insuranceExpiryDate", insuranceExpiryDate);
    formData.append("pucExpiryDate", pucExpiryDate);


    // Appending files only if they are valid File/Blob objects
  const appendFile = (key, file) => {
  if (file instanceof File || file instanceof Blob) {
    formData.append(key, file, file.name);
  } else {
    console.warn(`Skipping ${key}: Not a valid file`);
  }
};

appendFile("rcFrontPhoto", registrationCertificate);
appendFile("rcBackPhoto", registrationCertificateBack);
appendFile("carFrontPhoto", carFrontPhoto);
appendFile("carSidePhoto", carPhotoSide);
appendFile("permitPhoto", permitDetails);
appendFile("pucPhoto", PUCTest);
appendFile("insurancePhoto", insurancePolicy);

    
    console.log("Updated FormData:", [...formData.entries()]);

    try {
    
      await axios.put(`${API_BASE_URL}/v1/admin/cars/${carid}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    
      toast.success("Car Data Updated!");

      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } catch (error) {
      toast.error(error.response?.data?.message || "Error updating data");
      console.error("Error:", error);
    }
   
  };

  const extractLocationsAsOptions = (dataArray) => {
    if (Array.isArray(dataArray.data)) {
      return dataArray.data.map((item) => ({
        label: item.location,
        value: item._id,
      }));
    }
    return [];
  };
  const extractDriversAsOptions = (dataArray) => {
    if (Array.isArray(dataArray.data)) {
      return dataArray.data.map((item) => ({
        label: item.firstName +" " + item.lastName,
        value: item._id,
      }));
    }
    return [];
  };

  const extractCategoriesAsOptions = (dataArray) => {
    if (Array.isArray(dataArray.data)) {
      return dataArray.data.map((item) => ({
        label: item.category,
        value: item._id,
      }));
    }
    return [];
  };
  const extractCarManufacturesAsOptions = (dataArray) => {
    if (Array.isArray(dataArray.data)) {
      return dataArray.data.map((item) => ({
        label: item.make,
        value: item.make,
      }));
    }
    return [];
  };
  const extractCarModelsAsOptions = (dataArray) => {
    if (Array.isArray(dataArray.models)) {
      return dataArray.models.map((item) => ({
        label: item,
        value: item,
      }));
    }
    return [];
  };

  useEffect(() => {
    const locations = async () => {
      try {
        const locationResponse = await fetch(
          `${API_BASE_URL}/v1/locations`
        );
        const locationData = await locationResponse.json();

        const locationOptions = extractLocationsAsOptions(locationData);
        locationOptions.unshift({ label: "Select the location" });
        setCityOption(locationOptions);
        console.log("location options", locationOptions);
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };
    locations();
    const driver = async () => {
      try {
        const driverData = await axios.get(
          `${API_BASE_URL}/v1/admin/drivers`
        );
        // const driverData = await drivers.json();
        console.log("driver data",driverData.data);
        const din = extractDriversAsOptions(driverData.data);
        din.unshift({ label: "Select the driver" });
        console.log("drivers options", din);
        setDriverOption(din);
        console.log(driveroption);
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };
    driver();
    const carCategories = async () => {
      try {
        const categories = await fetch(
          `${API_BASE_URL}/v1/car-categories`
        );
        const categoriesData = await categories.json();
        const categoriesOptions = extractCategoriesAsOptions(categoriesData);
        categoriesOptions.unshift({ label: "Select the car category" });
        console.log("categoriesOptions", categoriesOptions);
        setRandomCategory(categoriesOptions);
        console.log(randomCategory);
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };

    carCategories();

    const carmanufacturing = async () => {
      try {
        const categories = await fetch(`${API_BASE_URL}/v1/car-makes`);
        const categoriesData = await categories.json();
        console.log(categoriesData);
        const categoriesOptions =
          extractCarManufacturesAsOptions(categoriesData);
        categoriesOptions.unshift({ label: "Select the car category" });
        console.log(" manufacturescategoriesOptions", categoriesOptions);
        setSelectcarmanufacturerArray(categoriesOptions);
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };
    carmanufacturing();

    const carmodels = async () => {
      try {
        if (selectcarmanufacturer) {
          const categories = await fetch(
            `${API_BASE_URL}/v1//car-models/${selectcarmanufacturer}`
          );
          const categoriesData = await categories.json();
          console.log("categories data",categoriesData.data[0]);
          const categoriesOptions = extractCarModelsAsOptions(
            categoriesData.data[0]
          );
          categoriesOptions.unshift({ label: "Select the car model" });
          console.log("categoires as option",categoriesOptions);
          // setSelectCarModel("");
          setSelectCarModelArray(categoriesOptions);
        }
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };
    carmodels();
  }, [selectcarmanufacturer]);

  useEffect(() => {
    const dataUpdate = async () => {
      const headers = {
        "Content-Type": "multipart/form-data",
      };

      try {
        // Add a new car category
        console.log("updated data",updatedData);
        await axios.put(
          `${API_BASE_URL}/v1/admin/cars/${carid}`,
          updatedData,
          {
            headers,
          }
        );
        toast.success("Car Data Updated!");
        setUpdatedData();

        setTimeout(() => {
          window.location.reload();
        }, 3000);

        // e.target.reset();
        setDriver("");
        setCarCategory("");
        setcarFrontPhoto("");
        setcarPhotoSide("");
        setregistrationCertificate("");
        setregistrationCertificateBack("");
        setinsurancePolicy("");
        setPUCTest("");
        setpermitDetails("");
        setInsuranceExpiryDate("");
        setPucExpiryDate("");
        setPermitDetailsExpiryDate("");
      } catch (error) {
        toast.error(error.response.data.message);
        console.error("error ", error.response.data.message);
      }

      setCallServer(false);
    };

    if (callServer === true) {
      dataUpdate();
    }
  }, [updatedData]);

  const [previousLocation, setPreviousLocation] = useState();

  const handleToggleComponent = (value) => {
    dispatch(resetCompState(value));
    navigate(`/carmanagement/${value}`);
  };

  const checkParentLocation = (path) => {
    if (path.includes("InactiveCar")) {
      setPreviousLocation("Inactive Cars");
    } else if (path.includes("ApprovalPendingCar")) {
      setPreviousLocation("Approval Pending");
    } else {
      setPreviousLocation("View All Cars");
    }
  };

  console.log("select city", selectcity);
  console.log("Placeholder location", carLocation);
  console.log("selectcarmanufacturer", selectcarmanufacturer);

  return (
    <>
      <Toaster />

      <div className="add-new-car-vehicle-scrolbar" style={{ height: "100vh" }}>
        <div className="nav-content" style={{ marginTop: "25px" }}>
          <div  className="header-text">
            <Link className="h3-booking">Fleet Management</Link>
            <FaLongArrowAltRight />
            {""}
            <Link className="h3-booking">{previousLocation}</Link>
            <FaLongArrowAltRight />
            <Link className="h2-booking">Edit Car Details</Link>
          </div> 
          {previousLocation === "View All Cars" && (
            <button
              className="createButton"
              onClick={() => handleToggleComponent("view-all-cars")}
            >
              View All Cars
            </button>
          )}

          {previousLocation === "Inactive Cars" && (
            <button
              className="createButton"
              onClick={() => {
                handleToggleComponent("inactive-cars");
              }}
            >
              Inactive Cars
            </button>
          )}

          {previousLocation === "Approval Pending" && (
            <button
              className="createButton"
              onClick={() => handleToggleComponent("approval-pending")}
            >
              Approval Pending
            </button>
          )}
        </div>
        <div className="add-new-car-vehicle">Vehicle Information</div>
        <div className="user-details">
          <div style={{
            height:"250px",
            display:"flex",
            flexDirection:"column",
            justifyContent:"space-between"
          }} className="user-details-section">

            <div style={{
              display:"flex",
              // alignItems:"center",
              alignContent:"center",
          
             
              marginTop:"5px"
              

            }} className="user-detail">
              <div className="user-heading">Location</div>
             <div>
             <DropdownColumnCar
                // placeholder={carLocation}
                options={cityoption}
                useInlineStyle={true}
                selectedValue={selectcity}
                handleChange={(e) => setSelectCity(e.target.value)}
              />
             </div>
            </div>

            <div style={{
              marginTop:"20px"
            }} className="user-detail">
              <div className="user-heading">Car Make</div>
              <DropdownColumnCar
                // placeholder={inputValue.carMake}
                options={selectcarmanufacturerArray}
                useInlineStyle={true}
                selectedValue={selectcarmanufacturer}
                handleChange={(e) =>{ 
                  setSelectCarModel("");
                  setSelectCarManufacturer(e.target.value)}}
              />
            </div>
            <div className="user-detail">
              <div className="user-heading">Body Colour</div>
              <input
              style={{
                width:"200px",
                height:"27px",
                fontSize:"11px"
              }}
                type="text"
                className="custom-input"
                placeholder="Car Color"
                value={carColor}
                onChange={(e) => {
                  setCarColor(e.target.value);
                }}
              />
            </div>
          </div>


          <div
          style={{
            height:"250px",
            display:"flex",
            flexDirection:"column",
            justifyContent:"space-between"
          }}
           className="user-details-section">
            <div className="user-detail">
              <div className="user-heading">Vehicle Owner / Driver</div>
              <DropdownColumnCar
                placeholder={driver}
                useInlineStyle={true}
                options={driveroption}
                selectedValue={driver}
                handleChange={(e) => setDriver(e.target.value)}
              />
            </div>
            <div className="user-detail">
              <div className="user-heading">Model</div>
            
              <DropdownColumnCar
                // placeholder={inputValue.carModel}
                useInlineStyle={true}
                options={selectCarModelArray}
                selectedValue={selectcarmodel}
                handleChange={(e) => setSelectCarModel(e.target.value)}
              />
            </div>
            <div className="user-detail">
              <div className="user-heading">Year</div>
              <input
              style={{
                width:"200px",
                height:"27px",
                fontSize:"11px"
              }}
                type="text"
                className="custom-input"
                placeholder="y-y-y-y"
                value={carRegistrationYear}
                onChange={(e) => setCarRegistrationYear(e.target.value)}
              />
            </div>
          </div>


          <div
            style={{
              height:"250px",
              display:"flex",
              flexDirection:"column",
              justifyContent:"space-between",
              
            }}
           className="user-details-section">
            <div style={{
               marginTop:"8px"
            }} className="user-detail">
              <div className="user-heading">Car Category</div>
              <DropdownColumnCar
                // placeholder={carCategory}
                useInlineStyle={true}
                options={randomCategory}
                selectedValue={selectcarCategory}
                handleChange={(e) => setSelectCarCategory(e.target.value)}
              />
            </div>
            <div className="user-detail">
              <div className="user-heading">Fuel Type</div>
              <DropdownColumnCar
                // placeholder={inputValue.fuelType}
                useInlineStyle={true}
                options={FuelCategoryOption}
                selectedValue={selectfuelcategory}
                handleChange={(e) => setSelectFuelCategory(e.target.value)}
              />
            </div>
            <div className="user-detail">
              <div className="user-heading">Vehicle Number</div>
              {/* <input type="text" placeholder="KA" className="custom-input-3" />
            <input type="text" className="custom-input-3" placeholder="01" />
            <input type="text" className="custom-input-3" placeholder="AB" />
            <input type="text" className="custom-input-3" placeholder="0000" /> */}
              <input
              style={{
                width:"200px",
                height:"27px",
                borderRadius:"5px",
       
                fontSize:"11px",
                border:"none",
                padding:"5px 10px",
                color:"gray",
               
                boxShadow:" 0px 4px 22px 0px rgba(0, 0, 0, 0.17)",
              }}
                type="text"
                className="custom-input-"
                // style={{ width: "calc(45% - 10px)" }}
                // placeholder="Enter Vehicle Number"
                value={rcNumber}
                onChange={(e) => setrcNumber(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="add-new-car-vehicle">Upload Documents</div>



        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(auto-fit, minmax(350px, 1fr))",
            gap: "30px",
            padding: "30px 25px",
          }}
        >
          {fileFields.map(
            (
              { label, state, setState, showDate, dateState, setDateState },
              index
            ) => (
              <div
                key={index}
                style={{
                  display: "grid",
                  gridTemplateColumns: "100px 1fr",
                  gap: "px",
                }}
              >
                {/* Label Column */}
                <div
                  style={{
                    fontSize: "11px",
                    fontWeight: 700,
                    display: "flex",
                    flexWrap: true,
                  }}
                >
                  {label}
                </div>

                {/* File & Button Column */}
                <div
                  style={{
                    display: "grid",
                    gridTemplateRows: "auto auto",
                    gap: "5px",
                  }}
                >
                  {/* File Preview + Expiry Date */}
                  <div
                    style={{
                      display: "flex",
                      alignItems: "start",
                      gap: "10px",
                    }}
                  >
                    {/* File Preview Box */}
                    <div
                      style={{
                        width: "83px",
                        height: "89px",
                        backgroundColor: "#ddd",
                        borderRadius: "5px",
                        position: "relative",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {state  ? (
                        <img
                          // src={state}
                          // src={URL.createObjectURL(state)}
                          src={typeof state === "string" ? state : URL.createObjectURL(state)} 
                          alt="Selected"
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                            borderRadius: "5px",
                          }}
                        />
                      ) : null}

                      {state && (
                        <span
                          onClick={() =>
                            handleRemoveFile(
                              setState,
                              setShowCloseIconCar,
                              setDateState
                            )
                          }
                          style={{
                            position: "absolute",
                            top: "5px",
                            right: "5px",
                            color: "red",
                            fontSize: "18px",
                            fontWeight: "bold",
                            cursor: "pointer",
                          }}
                        >
                          ×
                        </span>
                      )}
                    </div>

                    {/* Expiry Date Field (if applicable) */}
                    {showDate && (
                     <div style={{
                      display:"flex",
                      flexDirection:"column",
                      gap:"1px",
                      marginLeft:0,
                      
                     }}>
                       <input
                        type="date"
                        name={dateState}
                        value={dateState}
                        onChange={(e) => setDateState(e.target.value)}
                        style={{
                          padding: "5px",
                          border: "1px solid #ccc",
                          color:"#606060",
                          // border:"none",
                          borderRadius: "5px",
                          fontSize: "14px",
                        }}
                      />
                       <label style={{
                        color:"#606060",

                       }}>expiry date</label>
                     
                      </div>
                    )}
                  </div>

                  {/* File Upload Button & File Name */}
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "8px",
                    }}
                  >
                    <label
                      style={{
                        backgroundColor: "#0A85DE",
                        color: "#fff",
                        padding: "6px 10px",
                        borderRadius: "4px",
                        cursor: "pointer",
                        fontSize: "8px",
                        marginLeft: 0,
                      }}
                    >
                      <input
                        type="file"
                        name={state}
                        style={{ display: "none" }}
                        onChange={(e) =>
                          handleFileChange(e, setState, setShowCloseIconCar)
                        }
                      />
                      Choose File
                    </label>

                    {/* Selected File Info */}
                    <p style={{ fontSize: "11px", color: "#666" }}>
                      {state ? `${state.name}` : "No file chosen"}
                    </p>
                  </div>
                </div>
              </div>
            )
          )}
        </div>



    





        <div style={{ display: "flex", justifyContent: "center" }}>
          <button
            style={{
              borderRadius: "5px",
              margin: "10px",
              marginBottom:"20px",
              border: "0.5px solid #38B000",
              background: "#38B000",
              cursor:"pointer",
              width: "150px",
              padding: "6px",
              color: "#FFF",
              fontSize: "15px",
            }}
            onClick={handleSubmit}
          >
           Update
          </button>
        </div>
      </div>
    </>
  );
};

export default ModifyCarData;
