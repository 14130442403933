import React from "react";
import { AiOutlineRight } from "react-icons/ai";
import { Link } from "react-router-dom";
import "./Navbar.css";
import { sideBar } from "../../utils/SideBar";

const Navbar = ({ sidebar, handleItemClick, navigate }) => {
 {/*if (!sidebar) {
    return (
      <div className="relative-part">
        <div className="nav-part">
          {sideBar.map((s, index) => {
            return (
              <Link
                to={s.to}
                key={index}
                onClick={() => handleItemClick(s.name)}
                className="link-style"
              >
                <div className="part" key={index}>
                  <div className="sub-part">
                    <p className="image-size">{s.src}</p>
                    <p className="para-font">{s.name}</p>
                  </div>
                  <AiOutlineRight className="outline-right" />
                </div>
              </Link>
            );
          })}
        </div>
      </div>
    );
   } else {
    return (
     <div className="false-part">
        {sideBar.map((s, index) => {
          return (
            <Link to={s.to} onClick={() => handleItemClick(s.name)}>
              <div className="without-name" key={index}>
                <div className="false-subpart">{s.src}</div>
              </div>
            </Link>
          );
        })}
        
      </div>
   
    )
    }*/}
    return (
      <div className="relative-part">
        <div className="nav-part">
          {sideBar.map((s, index) => (
            <Link
              to={s.to}
              key={index}
              onClick={() => handleItemClick(s.name)}
              className="link-style"
            >
              <div className="part" key={index}>
                <div className="sub-part">
                  <p className="image-size">{s.src}</p>
                  <p className="para-font">{s.name}</p>
                </div>
                <AiOutlineRight className="outline-right" />
              </div>
            </Link>
          ))}
        </div>
      </div>
          );
};

export default Navbar;
