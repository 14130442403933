import React from "react";
import "./Fourthtable.css";
import { useDispatch, useSelector } from "react-redux";
import { selectbookingDetails } from "../DetailsSlice";

const Fourthtable = ({ data }) => {

  const bookingDetails = useSelector(selectbookingDetails);

  const pickupDateString = data.data?.pickupDate || "0-0-0";
  const returnDateString = data.data?.returnDate || "0-0-0";

  const pickupDateParts = pickupDateString.split("-").reverse();
  const returnDateParts = returnDateString.split("-").reverse();

  const pickupDate = new Date(
    pickupDateParts[0],
    pickupDateParts[1] - 1,
    pickupDateParts[2] || 0
  );
  const returnDate = new Date(
    returnDateParts[0],
    returnDateParts[1] - 1,
    returnDateParts[2] || 0
  );

  const differenceInMs = returnDate - pickupDate;
  const differenceInDays = isNaN(differenceInMs)
    ? "N/A"
    : differenceInMs / (1000 * 60 * 60 * 24);

  const estimatedfare = data?.data?.estimatedFare ?? 0;
  const advancePayment = data?.data?.advancepayment ?? 0;
  const balancePayment = data?.data?.balancePayment ?? 0;
  const distanceTravelled = data?.data?.approxDistance ?? 0;
  const partialPaymentPercentage = data?.data?.partialPaymentPercentage ?? 0;
  const noofdays = data?.data?.noOfDays ?? 0;
  const hrsIncluded = data?.data?.hrsIncluded ?? 0;

  if(bookingDetails?.tariff?.tripType==="Outstation"){
    
  var faredetaildata = [
    {
      lable: `Estimated Fare <br/> ${distanceTravelled} Km, ${noofdays} day`,
      value: estimatedfare.toFixed(2),
    },
    { lable: `Advance Payment (${partialPaymentPercentage}%)`, value: advancePayment.toFixed(2) },
    { lable: "Balance Payment to Pay", value: balancePayment.toFixed(2) },
  ];

  }else if(bookingDetails?.tariff?.tripType==="Local"){
    
  var faredetaildata = [
    {
      lable: `Estimated Fare <br/> ${distanceTravelled} Km, ${hrsIncluded} Hours`,
      value: estimatedfare.toFixed(2),
    },
    { lable:  `Advance Payment (${partialPaymentPercentage}%)`, value: advancePayment.toFixed(2) },
    { lable: "Balance Payment to Pay", value: balancePayment.toFixed(2) },
  ];

  }else{
    
  var faredetaildata = [
    {
      lable: `Estimated Fare <br/> ${distanceTravelled} Km, ${hrsIncluded} Hours`,
      value: estimatedfare.toFixed(2),
    },
    { lable:  `Advance Payment (${partialPaymentPercentage}%)`, value: advancePayment.toFixed(2) },
    { lable: "Balance Payment to Pay", value: balancePayment.toFixed(2) },
  ];
  }

  return (
    <div className="billing_fourth_containers">
      <div className="billing_fourth_scroll">
        <table className="billing-table-fourth">
          <thead>
            <tr>
              <th colSpan="2">Fare Breakup</th>
            </tr>
          </thead>
          <tbody className="first_billing_table_data">
            {faredetaildata.map((row, index) => (
              <React.Fragment key={index}>
                <tr>
                  <td
                    style={
                      row.lable ===  `Advance Payment (${partialPaymentPercentage}%)`
                        ? { backgroundColor: "white", color: "black",  fontWeight: "700",  }
                        : { backgroundColor: "#38b000", color: "white" }
                    }
                    dangerouslySetInnerHTML={{ __html: row.lable }}
                  />
                  <td
                    style={
                      row.lable === "Balance Payment to Pay"
                      ? { color: "#38b000", fontWeight: "800",backgroundColor: "#fff", }

                      : row.lable === `Advance Payment (${partialPaymentPercentage}%)`
                        ? { backgroundColor: "white", color: "black",  fontWeight: "700",  }
                        : { backgroundColor: "#38b000", color: "white" }
                    }
                  >
                    {row.value === "" ? "" : `Rs. ${row.value}`}
                  </td>
                </tr>
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Fourthtable;
