import React from "react";
import map from "./../../assets/map.png";
import "./RouteLog.css";
// import { IoArrowBackCircleSharp } from "react-icons/io5";
// import { IoArrowForwardCircle } from "react-icons/io5";

const TableItem = ({ item, index}) => {
  return (
    <tr>
      <td className={`trip-head ${index === 0 ? "first-row" : ""}`}>
        {item["Trip Type"]}
      </td>
      <td>{item["Route"]}</td>
      <td>{item["Trip Start Date & Time"]}</td>
      <td>{item["Trip End Date & Time"]}</td>
      <td>{item["No of Days"]}</td>
      <td>{item["Distance Travelled"]}</td>
    </tr>
  );
};

const Table = ({ headers, data }) => {
  return (
    <table className="route-log-table">
      <thead>
        <tr>
          {headers.map((header, index) => (
            <th key={index}>{header}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((item, index) => (
          <TableItem key={index} item={item} index={index} />
        ))}
      </tbody>
    </table>
  );
};

const RouteLog = ({handleButtonClick }) => {
  const headers = [
    "Trip Type",
    "Route",
    "Trip Start Date & Time",
    "Trip End Date & Time",
    "No of Days",
    "Distance Travelled",
  ];

  const ViewAllCityData = [
    {
      "Trip Type": "Outstation - Round Trip",
      Route: "Lucknow - Gorakhpur - Varanasi - Lucknow",
      "Trip Start Date & Time": "13-12-2023 12:00",
      "Trip End Date & Time": "17-12-2023 22:30",
      "No of Days": "5 Days",
      "Distance Travelled": "650 KM",
    },
  ];
  return (
    <div>
      <div className="main-box-route">
        {/* <div style={{ display: "flex", justifyContent: "space-between" }}>
          <IoArrowBackCircleSharp
            onClick={() => handleButtonClick("Interaction")}
            className="back"
          />
          <IoArrowForwardCircle
            onClick={() => handleButtonClick("BillingDetails")}
            className="back"
          />
        </div> */}
        <div className="top-extra-space"></div>
        <Table headers={headers} data={ViewAllCityData} />
        <div className="top-extra-space"></div>
        <div className="map-box">
          <div>
            <img
              src={map}
              alt="map"
              className="map"
              height="514px"
              width="990px"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RouteLog;
