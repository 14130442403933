import React, { useEffect, useState } from "react";
import axios from "axios";
import Loader from "../Loader";
import "./AssignDriverData.css";
import { useNavigate, useParams } from "react-router-dom";
import { FaFilter } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import DropdownFilter from "../dropdown/DropdownFilter";
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

function AssignDriverOtherCities() {
  const navigate = useNavigate();
  const [apiData, setApiData] = useState([]);
  const [searchName, setSearchName] = useState("");
  const [searchContact, setSearchContact] = useState("");
  const [filterOptionsState, setFilterOptionsState] = useState([]);
  const [filterValues, setFilterValues] = useState({});
  const [initialDriverData, setInitialDriverData] = useState([]);
  const [cabNumber, setCabNumber] = useState("");

  const dispatch = useDispatch();
  const { allDrivers } = useSelector((state) => state.assignDrivers);
  const { id } = useParams();

console.log("all drivers", allDrivers)
  const headerColumns = [
    "Driver Name",
    "Contact No.",
    "Location",
    "Sourcing Type",
    "Language Spoken",
    // "Availability Status",
    // "Driver License No.",
    "Date & Time of Availability",
    "Vehicle Type",
    "Vehicle Number",
    "Vehicle Color",
    "",
  ];

  const filterOptions = [
    {
      label: "Pick Up State",
      values: null, // Values will be fetched from the API
    },
    {
      label: "Pick Up City",
      values: null, // Values will be fetched from the API
    },
    {
      label: "Availability",
      values: ["Available", "Not Available"],
    },
    {
      label: "Sourcing Type",
      values: ["Outsourced", "Company Owned"],
    },
    {
      label: "Language Spoken",
      values: ["Hindi", "English", "English, Hindi"],
    },
  ];

  // let filterAvailability =

  // Handler to assign a driver and car to a booking
  const handleSelectedData = async (driverId, carId) => {
    try {
      const response = await axios.post(
        `${API_BASE_URL}/v1/admin/bookings/assign-driver-car`,
        {
          driverId: driverId,
          carId: carId,
          bookingId: id,
        }
      );

      if (response.status === 200) {
        alert("Cab and driver successfully assigned");
        navigate(`/booking/${id}`);
      } else {
        alert("Failed to assign cab and driver");
      }
    } catch (error) {
      console.error("Error assigning cab and driver:", error);
      alert("An error occurred while assigning cab and driver");
    }
  };

  // Table header component
  const TableHeaders = ({ headers }) => (
    <thead>
      <tr className="table-header-row">
        {headers.map((header, index) => (
          <th key={index}>
            <div
              className="table-header"
              style={{
                // width: "max-content",
                paddingRight: "5px",
                // margin: "3%",
              }}
            >
              {" "}
              {header}
            </div>
          </th>
        ))}
      </tr>
    </thead>
  );

  // Table body component
  const TableBody = ({ entries, headers }) => (
    <tbody>
      {entries.map((driver, index) => (
        <tr className="table-data-row" key={index}>
          {headers.map((header) => (
            <td
              className="table-data"
              key={header}
              style={{ padding: "4px 2px", paddingLeft: "6px"
              }}
            >
              {header !== "" ? (
                driver[header]
              ) : (
                <button
                  className="select-button"
                  onClick={() =>
                    handleSelectedData(driver.driverId, driver.carId)
                  }
                >
                  Select
                </button>
              )}
            </td>
          ))}
        </tr>
      ))}
    </tbody>
  );

  useEffect(() => {
    if (allDrivers?.data !== null) {
      console.log("allDrivers are", allDrivers)
      const drivers = allDrivers?.data?.data.map((driver) => ({
        id: driver?._id,
        "Driver Name": driver?.firstName + " " + driver?.lastName,
        "Contact No.": driver?.phone,
        "Language Spoken":
          driver?.languages.length !== 1
            ? driver?.languages[0] + ", " + driver?.languages[1]
            : driver?.languages[0],
        "Sourcing Type": driver?.sourcing,
        "Availability Status":
          driver?.availability === "Available" ? "Available" : "Not Available",
        "Driver License No.": driver?.license?.number,
        "Location": driver?.location
          ? driver?.location?.city + ", " + driver?.location?.state
          : driver?.location,
      }));

      setApiData(drivers);
      setInitialDriverData(drivers);
    }
  }, [allDrivers]);

  const ResetFilters = () => {
    setFilterValues([]);
    setCabNumber("");
  };
  // Fetch drivers related to the booking when component mounts or booking ID changes
  useEffect(() => {
    const fetchRideRequestedDrivers = async () => {
      console.log("id is", id)
      try {
        const response = await axios.get(
          `${API_BASE_URL}/v1/admin/bookings/ride-requested-drivers?bookingId=${id}`
        );
        console.log("information in other cities", response)
        const drivers = response.data.data;
        const formattedDrivers = drivers.map((entry) => ({
          driverId: entry.driver._id,
          carId: entry.driver.currentCar._id,
          "Driver Name": `${entry.driver.firstName} ${entry.driver.lastName}`,
          "Contact No.": entry.driver.phone,
          "Language Spoken": entry.driver.languages.join(", "),
          "Sourcing Type": entry.driver.sourcing,
          // "Availability Status": "Available",
          // "Driver License No.": entry.driver.license.number,
          "Date & Time of Availability": `${entry.dateForNextRide} ${entry.timeForNextRide
          }`,
          // "Date & Time of Availability": entry.driver.currentCar.registrationCertificate.number,
          "Vehicle Type": entry.carCategory.category,
          "Vehicle Number":
            entry.driver.currentCar.registrationCertificate.number,
          "Vehicle Color": entry.driver.currentCar.carColor,
          Location: `${entry.currentCity.city}, ${entry.currentCity.state}`,
        }));
        setApiData(formattedDrivers);
        setInitialDriverData(formattedDrivers);
      } catch (error) {
        if (error.response && error.response.status === 404) {
          alert(
            error.response.data.message || "No drivers found for the location"
          );
        } else {
          alert("An unexpected error occurred. Please try again later.");
        }
        console.error("Error fetching drivers:", error);
      }
    };

    fetchRideRequestedDrivers();
  }, [id]);

  // Fetch available locations for the Location filter
  useEffect(() => {
    fetchLocations();
  }, []);

  const fetchLocations = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/v1/locations`);
      const locations = response.data.data.map((value) => {
        // Split the location string by comma
        const [city, state] = value.location
          .split(",")
          .map((item) => item.trim());
        return { city, state }; // Return an object with city and state
      });

      // Extract unique state names for the filter options
      const stateNames = [
        ...new Set(locations.map((location) => location.state)),
      ];

      const updatedFilterOptions = filterOptions.map((temp) => {
        if (temp.label === "Pick Up State") {
          temp.values = stateNames; // Use unique state names for filter options
        }
         else if (temp.label === "Pick Up City") {
          temp.values = []; // Initialize as empty or default message
        }
        return temp;
      });

      setFilterOptionsState(updatedFilterOptions);
    } catch (error) {
      console.error("Failed to fetch locations.");
    }
  };

  // Apply filters whenever search terms or filter values change
  useEffect(() => {
    applyFilters();
  }, [searchName, searchContact, filterValues]);

  const applyFilters = () => {
    const filteredData = initialDriverData.filter((driver) => {
      const matchesSearchName = driver["Driver Name"]
        .toLowerCase()
        .includes(searchName.toLowerCase());
      const matchesSearchContact = driver["Contact No."]
        .toLowerCase()
        .includes(searchContact.toLowerCase());
      const matchesFilterValues = Object.keys(filterValues).every((key) => {
        if (key === "Pick Up City") {
          return driver["Location"]?.includes(filterValues[key]);
        } else if (key === "Availability") {
          return driver["Availability Status"] === filterValues[key];
        }else if(key==="Language Spoken"){
          return driver["Language Spoken"]?.includes(filterValues[key])
        } else {
          return driver[key] === filterValues[key];
        }
      });

      return matchesSearchName && matchesSearchContact && matchesFilterValues;
    });

    setApiData(filteredData);
  };

  // Handler for filter selection

  const globalSelectedOption = async (value, label) => {
    let temp = { ...filterValues, [label]: value };
  
    if (value === "") {
      delete temp[label];
    }
  
    setFilterValues(temp);
  
    if (label === "Pick Up State") {
      await fetchCitiesByState(value); // Fetch cities when a state is selected
    } else if (label === "Pick Up City") {
      // Get the selected state and city from filterValues
      const state = temp["Pick Up State"];
      const city = value;
  
      // Concatenate state and city with a comma
      const location = `${city}, ${state}`;
  console.log("state and location is",location)
      await fetchDriversByLocation(location); // Fetch drivers with the concatenated state and city
    } else {
      applyFilters(); // Apply other filters
    }
  };
  const fetchDriversByLocation = async (location) => {
    try {
      const response = await axios.get(
       `${API_BASE_URL}/v1/admin/bookings/drivers/location?bookingId=${id}&location=${location}`
      );
console.log("response data on fetching driver by location",response)
      if (response.data.data.length === 0) {
        alert("No available drivers found for the selected city.");
 //   setTimeout(() => {
      //     window.location.reload();
      // }, 1000); 
        setApiData([]);
        setInitialDriverData([]);
     
      } else {

        const drivers = response.data.data.map((driver) => ({
          id: driver._id,
          "Driver Name": driver?.firstName + " " + driver?.lastName,
          "Contact No.": driver?.phone,
          "Language Spoken":
            driver.languages.length !== 1
              ? driver.languages[0] + ", " + driver.languages[1]
              : driver.languages[0],
          "Sourcing Type": driver.sourcing,
          //  "Date & Time of Availability":driver.pickupTime,

          // "Availability Status":
          //   driver.availability === "Available" ? "Available" : "Not Available",
          // "Driver License No.": driver?.license?.number,
          "Location": driver?.location
            ? driver?.location?.city + ", " + driver?.location?.state
            : driver?.location,

        }));

        setApiData(drivers); // Set new data directly
        setInitialDriverData(drivers); // Update initial data
      }
    } catch (error) {
      alert("No Drivers Found!");
      setApiData([]);
      setInitialDriverData([]);
    }
  };
  const fetchCitiesByState = async (state) => {
    console.log("state is ", state);
    
    try {
      const response = await axios.post(
        `${API_BASE_URL}/v1/admin/bookings/get-cities-state`,
        { state : state }  // Pass state as a body
      );
      // console.log("response by api", response)
      const cities = response.data.data.map((cityData) => cityData.city);
      console.log("cities for selected state is", cities);
      
      const updatedFilterOptions = filterOptionsState.map((temp) => {
        if (temp.label === "Pick Up City") {
          temp.values = cities; // Update cities for the selected state
        }
        return temp;
      });
  
      setFilterOptionsState(updatedFilterOptions);
    } catch (error) {
      console.error("Failed to fetch cities for the selected state.", error);
    }
  };
  return (
    <div className="data-container">
      <div className="filter-section">
        <p className="filter-tag">
          Filter <FaFilter />
        </p>

        <div
          style={{
            display: "flex",
            width: "70%",
            justifyContent: "space-between",
            marginRight: "1.5%",
          }}
        >
          {filterOptionsState &&
            filterOptionsState.map((filter, index) => {
              const defaultValue =
                filter.label === "Pick Up City"
                  ? initialDriverData[0]?.Location
                  : undefined;
              return (
                <DropdownFilter
                  key={index}
                  filterOptions={filter}
                  onSelect={globalSelectedOption}
                  defaultValue={defaultValue}
                />
              );
            })}
        </div>
        <div className="rightfilterother">
          <input
            className="input-search"
            value={searchName}
            type="text"
            placeholder="Search By Name"
            onChange={(e) => setSearchName(e.target.value)}
          />
          <input
            className="input-search gap"
            value={searchContact}
            type="text"
            placeholder="Search By Mobile No."
            onChange={(e) => setSearchContact(e.target.value)}
          />
<div>
        {/* {filterValue.length !== 0 && ( */}
          <button className="filter-button" onClick={() => ResetFilters()}>
            Reset 
          </button>
        {/* )} */}
      </div>
          {/* <div style={{ marginLeft: "10%" }}>
            <DropdownFilter
              key={0}
              filterOptions={filterAvailability}
              onSelect={globalSelectedOption}
              defaultValue={undefined}
            />
          </div> */}
        </div>
      </div>

      {/* <div className="table-container">
        <table className="driver-table" style={{ width: "1047px" }}>
          <TableHeaders headers={headerColumns} />
          <TableBody entries={apiData} headers={headerColumns} />
        </table>
      </div> */}
       <div className="table-container">
        <table className="driver-table" style={{ width: "1047px" }}>
          <TableHeaders headers={headerColumns} />
          {apiData.length > 0 ? (
            <TableBody entries={apiData} headers={headerColumns} />
          ) : (
            <tbody>
              <tr>
                <td colSpan={headerColumns.length} className="no-data">
                  <span>{"No driver available for this Booking!"}</span>

                </td>
              </tr>
            </tbody>
          )}
        </table>
      </div>
    </div>
  );
}

export default AssignDriverOtherCities;
