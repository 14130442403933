import React, { useEffect, useState } from "react";
import "./CreateNewBooking.css";
// import DropdownColumn from "./Dropdown";
import axios from "axios";
import { format } from "date-fns";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import useSuggestionsCustomer from "../../hooks/useSuggestionCustomer";
import SearchLocationInput from "../../utils/searchLocation/searchLocation";
import AirportDetails from "./AirportDetails";
import Fromtheairport from "./from-the-airport";
import LocalBookingForm from "./local-booking-form";
import Localgst from "./local-Gst";
import LocalDetails from "./LocalDetails";
import OutstationOneWayForm from "./outstation-one-way";
import OutstationDetails from "./OutstationDetails";
import Roundtripoutstation from "./roundtrip-outstation";
import Totheairport from "./to-the-airport";

const CreateNewBooking = () => {
  const navigate = useNavigate();
  const [tripType, setTripType] = useState("Local");
  const [outstationSubTripType, setOutstationSubTripType] = useState("oneway");
  const [airportSubTripType, setAirportSubTripType] =
    useState("fromtheairport");
  const [pickUpLocation, setPickUpLocation] = useState("");
  const [pickUpDate, setPickUpDate] = useState("");
  const [dropDate, setDropDate] = useState("");
  const [pickUpTime, setPickUpTime] = useState("");
  const [PickupAddress, setPickupAddress] = useState("");
  const [AlternateContactNumber, setAlternateContactNumber] = useState("");
  const [selectCategory, setSelectCategory] = useState("");
  const [longitude, setLongitude] = useState("");
  const [cabType, setCabType] = useState([]);
  const [hourlyPackage, setHourlyPackage] = useState([]);
  const [selectCustomer, setSelectCustomer] = useState("");
  const [selectedHourlyPackage, setselectedHourlyPackage] = useState("");
  const [selectedCabType, setSelectedCabType] = useState("");
  const [selectedLocation, setSelectedLocation] = useState("");
  const [gstValue, setGstValue] = useState("");
  const [flightValue, setFlightValue] = useState("");
  const [comments, setComments] = useState("");
  const [selectedAdvanceAmount, setSelectedAdvanceAmount] = useState("");
  const [selectTemp, setSelectTemp] = useState("");
  const [selectedTariffId, setSelectedTariffId] = useState("");
  const [selectAirport, setSelectAirport] = useState("");
  const [selectDropAddress, setSelectDropAddress] = useState("");
  const [carCategoriesDetails, setCarCategoriesDetails] = useState([]);
  const [estimatedFare, setEstimatedFare] = useState(0);

  // New state variables for handling new customer form
  const [isNewCustomer, setIsNewCustomer] = useState(false);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  const [otpId, setOtpId] = useState("");
  const [mobileNo, setMobileNo] = useState("");

  // const [otpError, setOtpError] = useState("");
  const [email, setEmail] = useState("");
  // const [phone, setPhone] = useState("");

  // const [responseData, setResponseData] = useState(null);
  // const [userId, setUserId] = useState("");
  const [isOtpModalOpen, setIsOtpModalOpen] = useState(false);
  // const [otpToken, setOtpToken] = useState("");
  // const [guestRegistered, setGuestRegistered] = useState(false);

  // const [bookingId, setBookingId] = useState(null);
  // const [requiredToPay, setRequiredToPay] = useState(true);

  const { suggestions, loading, error, refetch } = useSuggestionsCustomer();

  useEffect(() => {
    setPickUpLocation("");
    setPickUpDate("");
    setPickUpTime("");
    setSelectCategory("");
    setSelectedCabType("");
    setSelectedLocation("");
    setHourlyPackage([]);
    setCabType([]);
    setComments("");
    setSelectedAdvanceAmount("");
    setSelectAirport("");
    setSelectDropAddress("");
    setEstimatedFare(0);
  }, [tripType, airportSubTripType, outstationSubTripType]);

  const InputColumn = ({
    title,
    type,
    name,
    id,
    value,
    handleChange,
    checked,
    placeholder,
    style,
    className,
  }) => {
    const handleRadioChange = (e) => {
      handleChange(e.target.value);
    };
    return (
      <div>
        <div className="title">{title}</div>
        <input
          type={type}
          value={value}
          onChange={handleRadioChange}
          checked={checked}
          className={className}
          style={style}
          name={name}
          id={id}
          placeholder={placeholder}
        />
      </div>
    );
  };

  const handleOtpChange = (index, value) => {
    const newOtp = [...otp];
    newOtp[index] = value;

    // If all OTP digits are filled, open the modal
    if (newOtp.every((digit) => digit !== "")) {
      setIsOtpModalOpen(true);
    }

    setOtp(newOtp);

    // Move focus to the next input field
    if (value !== "" && index < otp.length - 1) {
      document.getElementById(`otp-input-${index + 1}`).focus();
    }
  };

  const handleKeyDown = (index, e) => {
    // Move focus to the previous input field when backspace is pressed and the current input is empty
    if (e.keyCode === 8 && otp[index] === "" && index > 0) {
      document.getElementById(`otp-input-${index - 1}`).focus();
    }
  };

  const handleAdvanceAmountChange = (value) => {
    setSelectedAdvanceAmount(value);
    console.log("Selected Advance Amount:", value);
  };

  const formatDate = (pickUpDate) => {
    if (!(pickUpDate instanceof Date) || isNaN(pickUpDate)) {
      return "";
    }
    return format(pickUpDate, "dd-MM-yyyy");
  };

  const handleEstimatedFareChange = (newFare) => {
    console.log("New Fare", newFare);
    setEstimatedFare(newFare);
  };

  const calculatePercentOfActualValue = (actualValue, percent) => {
    if (actualValue) {
      return (actualValue * (percent / 100)).toFixed(2);
    } else {
      return "0.00";
    }
  };

  const handleCreateBooking = async () => {
    const headers = {
      "Content-Type": "application/json",
    };

    const LocalObj = new FormData();
    if (tripType === "Local") {
      LocalObj.append("userId", selectCategory);
      LocalObj.append("comment", comments);
      LocalObj.append("gstNumber", gstValue);
      LocalObj.append("flightDetails", flightValue);
      LocalObj.append(
        "partialPaymentPercentage",
        parseInt(selectedAdvanceAmount)
      );
      LocalObj.append("tempDataId", selectTemp);
      LocalObj.append("selectedTariffId", selectedTariffId);
      LocalObj.append("tripType", tripType);
      LocalObj.append("subTripType", selectedHourlyPackage);
      LocalObj.append("pickupAddress", PickupAddress);
      LocalObj.append("pickupLat", longitude.lat);
      LocalObj.append("pickupLong", longitude.lng);
      LocalObj.append("pickupDate", formatDate(pickUpDate));
      LocalObj.append("pickupTime", pickUpTime);
      // LocalObj.append("alternatePhone", `+91-${AlternateContactNumber}`);
     
    } else if (
      tripType === "Outstation" &&
      outstationSubTripType === "roundtrip"
    ) {
      LocalObj.append("userId", selectCategory);
      LocalObj.append("tripType", tripType);
      LocalObj.append("selectedTariffId", selectedTariffId);
      LocalObj.append("pickupAddress", PickupAddress);
      LocalObj.append("pickupLat", longitude.lat);
      LocalObj.append("pickupLong", longitude.lng);
      LocalObj.append("pickupDate", formatDate(pickUpDate));
      LocalObj.append("pickupTime", pickUpTime);
      LocalObj.append("endDate", formatDate(dropDate));
      LocalObj.append("subTripType", "Round Trip");
      LocalObj.append("tempDataId", selectTemp);
      // LocalObj.append("alternatePhone", `+91-${AlternateContactNumber}`);
      LocalObj.append("partialPaymentPercentage", selectedAdvanceAmount);
    } else if (
      tripType === "Outstation" &&
      outstationSubTripType === "oneway"
    ) {
      LocalObj.append("userId", selectCategory);
      LocalObj.append("tripType", tripType);
      LocalObj.append("selectedTariffId", selectedTariffId);
      LocalObj.append("pickupAddress", PickupAddress);
      LocalObj.append("pickupLat", longitude.lat);
      LocalObj.append("pickupLong", longitude.lng);
      LocalObj.append("pickupDate", formatDate(pickUpDate));
      LocalObj.append("pickupTime", pickUpTime);
      LocalObj.append("subTripType", "One Way");
      LocalObj.append("tempDataId", selectTemp);
      // LocalObj.append("alternatePhone", `+91-${AlternateContactNumber}`);
      LocalObj.append("partialPaymentPercentage", selectedAdvanceAmount);
      
      // LocalObj.append("dropAddress", selectDropAddress);
    } else if (
      tripType === "Airport" &&
      airportSubTripType === "fromtheairport"
    ) {
      LocalObj.append("tripType", tripType);
      LocalObj.append("userId", selectCategory);
      LocalObj.append("selectedTariffId", selectedTariffId);
      LocalObj.append("pickupDate", formatDate(pickUpDate));
      LocalObj.append("pickupTime", pickUpTime);
      LocalObj.append("subTripType", "From The Airport");
      LocalObj.append("tempDataId", selectTemp);
      LocalObj.append("pickupAddress", PickupAddress);
      LocalObj.append("pickupLat", longitude.lat);
      LocalObj.append("pickupLong", longitude.lng);
      // LocalObj.append("alternatePhone", `+91-${AlternateContactNumber}`);
      LocalObj.append("partialPaymentPercentage", selectedAdvanceAmount);
      LocalObj.append("dropAddress", selectDropAddress);
    } else if (
      tripType === "Airport" &&
      airportSubTripType === "totheairport"
    ) {
      LocalObj.append("tripType", tripType);
      LocalObj.append("userId", selectCategory);
      LocalObj.append("selectedTariffId", selectedTariffId);
      LocalObj.append("pickupDate", formatDate(pickUpDate));
      LocalObj.append("pickupTime", pickUpTime);
      LocalObj.append("subTripType", "To The Airport");
      LocalObj.append("tempDataId", selectTemp);
      LocalObj.append("pickupAddress", PickupAddress);
      LocalObj.append("pickupLat", longitude.lat);
      LocalObj.append("pickupLong", longitude.lng);
      // LocalObj.append("alternatePhone", `+91-${AlternateContactNumber}`);
      LocalObj.append("partialPaymentPercentage", selectedAdvanceAmount);
    }

    console.log("Data Entered");
    for (const pair of LocalObj.entries()) {
      console.log(pair[0] + ": " + pair[1]);
    }

    try {
      await axios.post("https://api.yatricabs.com/v1/admin/bookings", LocalObj, {
        headers,
      });
      toast.success("Booking Created successfully!");

      setTimeout(() => {
        navigate("/bookings/all-bookings");
      }, 3000);

      // e.target.reset();
    } catch (error) {
      toast.error(error.response.data.message);
      console.error(error.response.data.message);
    }
    // toast.error("Booking not created! Please try again.");
  };

  const handleOtpSendAndVerification = async () => {
    try {
      const phoneNumberWithCountryCode = "+91-" + mobileNo;

      const otpApiUrl = `https://api.yatricabs.com/v1/send-otp`;
      const otpResponse = await fetch(otpApiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ phone: phoneNumberWithCountryCode }),
      });

      const result = await otpResponse.json();
      console.log("OTP DATA RESPONSE ID", result.data);

      if (otpResponse.ok) {
        setOtpId(result.data.otpId);
        setIsOtpModalOpen(true);
        toast.success(result.message);
      } else {
        console.error(
          "Error generating OTP:",
          otpResponse.status,
          otpResponse.statusText
        );
        // toast.error(result.message);
      }

      // Open OTP verification modal
    } catch (error) {
      console.error("Error during  OTP send :", error);
    }
  };

  const handleVerifyOtp = async () => {
    try {
      const phoneNumberWithCountryCode = "+91-" + mobileNo;

      const combinedOtp = otp.join("");

      const requestBodyOtp = {
        phone: phoneNumberWithCountryCode,
        otp: Number(combinedOtp),
        otpId: otpId,
      };

      const otpVerifyApiUrl = `https://api.yatricabs.com/v1/verify-otp`;
      const otpVerifyResponse = await fetch(otpVerifyApiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBodyOtp),
      });

      if (otpVerifyResponse.ok) {
        const result = await otpVerifyResponse.json();
        console.log("OTP Verification Result:", result);
        console.log("Otp Token :", result.otpToken);
        toast.success(result.message);

        // Close OTP verification modal
        setIsOtpModalOpen(false);
        // Update the otpToken state before proceeding
        // await handleOtpToken(result);
        // otpToken = await result.otpToken;
        if (result.otpToken) {
          handleRegisterAndProceed(result.otpToken);
        } else {
          console.error("Error: OTP token is empty.");
          // Handle the case where OTP token is empty
        }

        // Proceed with further logic after successful OTP verification
      } else {
        const result = await otpVerifyResponse.json();
        console.error("Error verifying OTP:", result);
        // toast.error('OTP verification failed. Please try again.');
        // setOtpError("Invalid OTP. Please try again.");
      }
    } catch (error) {
      console.error("Error during OTP verification:", error);
      // setOtpError("Something went wrong. Please try again.");
    }
  };

  const handleCancelOtp = () => {
    // Clear OTP input and close the modal
    setOtp(["", "", "", "", "", ""]);
    setIsOtpModalOpen(false);
  };

  const handleRegisterAndProceed = async (otpToken) => {
    try {
      const phoneNumberWithCountryCode = "+91-" + mobileNo;
      const alternatePhoneNumberWithCountryCode =
        "+91-" + AlternateContactNumber;

      let requestBody;

      console.log("AlternateContactNumber :", AlternateContactNumber);

      if (!AlternateContactNumber) {
        requestBody = {
          // Include other data from the form
          otpToken: otpToken,
          firstName: firstName,
          lastName: lastName,
          email: email,
          phone: phoneNumberWithCountryCode,
          // ... Add other form fields as needed
        };
      } else {
        requestBody = {
          // Include other data from the form
          otpToken: otpToken,
          firstName: firstName,
          lastName: lastName,
          email: email,
          phone: phoneNumberWithCountryCode,
          alternatePhone: alternatePhoneNumberWithCountryCode,
          // ... Add other form fields as needed
        };
      }

      console.log("Guest Register requestbody :", requestBody);

      const registerApiUrl = `https://api.yatricabs.com/v1/users/register-guest`;
      const registerResponse = await fetch(registerApiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-device-type": "Web",
        },
        body: JSON.stringify(requestBody),
      });
      console.log("Register Response :", registerResponse);

      // setGuestRegistered(true);
      await refetch();
      setIsNewCustomer(false);
      // navigate('/bookings/create-new-booking')

      if (registerResponse.ok) {
        const resultRegister = await registerResponse.json();
        toast.success(
          "Now select it from select custmer:",
          resultRegister.message
        );
        // handleCreateBooking();
      } else {
        // const resultAgainRegister = await registerResponse.json();
        console.error(
          "Error registering user:",
          registerResponse.status,
          registerResponse.statusText
        );
        // toast.error(resultAgainRegister.message);
      }
    } catch (error) {
      console.error("Error during registration and order creation :", error);
      // toast.error(error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isNewCustomer) {
      handleCreateBooking();
    } else {
      handleOtpSendAndVerification();
    }
  };

  useEffect(() => {
    if (isNewCustomer) {
      setFirstName("");
      setLastName("");
      setMobileNo("");
      setEmail("");
    }
  }, [isNewCustomer]);

  // console.log("selected", selectCategory);

  // All Booking Starting

  return (
    <>
      <Toaster />
      <div className="bookingg ">
        <div className="booking-section">
          <div className="booking-box">
            <div className="booking-container">
              <div
                className="booking-heading"
                style={{
                  marginTop:
                    tripType === "Outstation" || tripType === "Airport"
                      ? "0"
                      : "0",
                }}
              >
                Trip Type:
              </div>

              <div
                className="booking-head-main"
                style={{
                  marginRight:
                    tripType === "Outstation" || tripType === "Airport"
                      ? "0"
                      : "0",
                }}
              >
                <div className="trip-type-btns">
                  <div className="btns">
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        setTripType("Local");
                      }}
                      className={
                        tripType === "Local"
                          ? "active-button-booking"
                          : "inactive-button-booking"
                      }
                    >
                      Local
                    </button>

                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        setTripType("Outstation");
                      }}
                      className={
                        tripType === "Outstation"
                          ? "active-button-booking"
                          : "inactive-button-booking"
                      }
                    >
                      Outstation
                    </button>

                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        setTripType("Airport");
                      }}
                      className={
                        tripType === "Airport"
                          ? "active-button-booking"
                          : "inactive-button-booking"
                      }
                    >
                      Airport
                    </button>
                  </div>

                  {tripType === "Outstation" && (
                    <div className="sub-btns out-roundt">
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          setOutstationSubTripType("oneway");
                        }}
                        className={
                          outstationSubTripType === "oneway"
                            ? "active-button-booking"
                            : "inactive-button-booking"
                        }
                      >
                        Oneway
                      </button>

                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          setOutstationSubTripType("roundtrip");
                        }}
                        className={
                          outstationSubTripType === "roundtrip"
                            ? "active-button-booking"
                            : "inactive-button-booking"
                        }
                      >
                        Round Trip
                      </button>
                    </div>
                  )}

                  {tripType === "Airport" && (
                    <div className="sub-btns">
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          setAirportSubTripType("fromtheairport");
                        }}
                        className={
                          airportSubTripType === "fromtheairport"
                            ? "active-button-booking"
                            : "inactive-button-booking"
                        }
                      >
                        From the airport
                      </button>
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          setAirportSubTripType("totheairport");
                        }}
                        className={
                          airportSubTripType === "totheairport"
                            ? "active-button-booking"
                            : "inactive-button-booking"
                        }
                      >
                        To the airport
                      </button>
                    </div>
                  )}
                </div>
              </div>

              <div className="booking-form">
                <div
                  className="customer-constainer"
                  style={{ position: "relative", width: "100%" }}
                >
                  <div className="user-info-1">
                    <span>Customer Type</span>
                    <div className="typeNew">
                      <input
                        type="checkbox"
                        checked={isNewCustomer}
                        onChange={() => setIsNewCustomer(true)}
                      />
                      <p>New Customer</p>
                    </div>
                    <div className="typeExiting">
                      <input
                        type="checkbox"
                        checked={!isNewCustomer}
                        onChange={() => setIsNewCustomer(false)}
                      />
                      <h6>Existing Customer</h6>
                    </div>
                  </div>

                  {!isNewCustomer && (
                    <div className="container_Search ">
                      <input
                        type="text"
                        id="from"
                        className="custom-input"
                        autoComplete="off"
                        placeholder="Search Customer by Name of Mobile number"
                        value={selectCustomer}
                        onChange={(e) => {
                          setSelectCustomer(e.target.value);
                          setShowSuggestions(true);
                        }}
                      />
                      <div className="searchBar">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="15"
                          height="15"
                          viewBox="0 0 15 15"
                          fill="none"
                        >
                          <path
                            d="M12.0093 6.00369C12.0093 7.32855 11.5792 8.55238 10.8546 9.5453L14.5094 13.2024C14.8702 13.5632 14.8702 14.1491 14.5094 14.5099C14.1485 14.8707 13.5625 14.8707 13.2016 14.5099L9.54685 10.8528C8.55377 11.5802 7.32974 12.0074 6.00467 12.0074C2.68767 12.0074 0 9.32016 0 6.00369C0 2.68723 2.68767 0 6.00467 0C9.32167 0 12.0093 2.68723 12.0093 6.00369ZM6.00467 10.1601C6.55058 10.1601 7.09115 10.0526 7.59551 9.84371C8.09987 9.63483 8.55815 9.32867 8.94417 8.94271C9.33019 8.55676 9.6364 8.09856 9.84531 7.59428C10.0542 7.09 10.1617 6.54952 10.1617 6.00369C10.1617 5.45787 10.0542 4.91739 9.84531 4.41311C9.6364 3.90883 9.33019 3.45063 8.94417 3.06467C8.55815 2.67871 8.09987 2.37256 7.59551 2.16368C7.09115 1.9548 6.55058 1.84729 6.00467 1.84729C5.45875 1.84729 4.91818 1.9548 4.41382 2.16368C3.90946 2.37256 3.45119 2.67871 3.06517 3.06467C2.67915 3.45063 2.37294 3.90883 2.16403 4.41311C1.95512 4.91739 1.84759 5.45787 1.84759 6.00369C1.84759 6.54952 1.95512 7.09 2.16403 7.59428C2.37294 8.09856 2.67915 8.55676 3.06517 8.94271C3.45119 9.32867 3.90946 9.63483 4.41382 9.84371C4.91818 10.0526 5.45875 10.1601 6.00467 10.1601Z"
                            fill="#8B8B8B"
                          />
                        </svg>
                      </div>

                      {selectCustomer && showSuggestions && (
                        <div className="dropdown-option customer">
                          {suggestions
                            .filter((suggestion) => {
                              const lowerCaseInput =
                                selectCustomer.toLowerCase();
                              const lowerCaseName =
                                suggestion.label.toLowerCase();
                              const phone = suggestion.phone;

                              return (
                                lowerCaseName.includes(lowerCaseInput) ||
                                phone.includes(selectCustomer)
                              );
                            })
                            .slice(0, 6)
                            .map((suggestion, index) => (
                              <div key={index} className="suggestion">
                                <div
                                  onClick={() => {
                                    setSelectCustomer(suggestion.label);
                                    setSelectCategory(suggestion.value);
                                    setFirstName(suggestion.firstname);
                                    setLastName(suggestion.lastname);
                                    setMobileNo(suggestion.phone.split("-")[1]);
                                    setEmail(suggestion.email);
                                    setShowSuggestions(false);
                                  }}
                                >
                                  {suggestion.label} - {suggestion.phone}
                                </div>
                              </div>
                            ))}
                        </div>
                      )}
                    </div>
                  )}
                </div>

                {tripType === "Local" && (
                  <LocalBookingForm
                    pickUpLocation={pickUpLocation}
                    setPickUpLocation={setPickUpLocation}
                    pickUpDate={pickUpDate}
                    setPickUpDate={setPickUpDate}
                    pickUpTime={pickUpTime}
                    setPickUpTime={setPickUpTime}
                    hourlyPackage={hourlyPackage}
                    setHourlyPackage={setHourlyPackage}
                    cabType={cabType}
                    setCabType={setCabType}
                    selectedCabType={selectedCabType}
                    setSelectedCabType={setSelectedCabType}
                    selectedLocation={selectedLocation}
                    setSelectedLocation={setSelectedLocation}
                    selectedHourlyPackage={selectedHourlyPackage}
                    setselectedHourlyPackage={setselectedHourlyPackage}
                    setSelectTemp={setSelectTemp}
                    setSelectedTariffId={setSelectedTariffId}
                    setCarCategoriesDetails={setCarCategoriesDetails}
                  />
                )}

                {tripType === "Outstation" &&
                  outstationSubTripType === "oneway" && (
                    <OutstationOneWayForm
                      pickUpLocation={pickUpLocation}
                      setPickUpLocation={setPickUpLocation}
                      selectedLocation={selectedLocation}
                      setSelectedLocation={setSelectedLocation}
                      selectDropAddress={selectDropAddress}
                      setSelectDropAddress={setSelectDropAddress}
                      pickUpDate={pickUpDate}
                      setPickUpDate={setPickUpDate}
                      dropDate={dropDate}
                      setDropDate={setDropDate}
                      pickUpTime={pickUpTime}
                      setPickUpTime={setPickUpTime}
                      cabType={cabType}
                      setCabType={setCabType}
                      selectTemp={selectTemp}
                      setSelectTemp={setSelectTemp}
                      selectedCabType={selectedCabType}
                      setSelectedCabType={setSelectedCabType}
                      setSelectedTariffId={setSelectedTariffId}
                      setCarCategoriesDetails={setCarCategoriesDetails}
                    />
                  )}

                {tripType === "Outstation" &&
                  outstationSubTripType === "roundtrip" && (
                    <Roundtripoutstation
                      pickUpLocation={pickUpLocation}
                      setPickUpLocation={setPickUpLocation}
                      selectedLocation={selectedLocation}
                      setSelectedLocation={setSelectedLocation}
                      selectDropAddress={selectDropAddress}
                      setSelectDropAddress={setSelectDropAddress}
                      pickUpDate={pickUpDate}
                      setPickUpDate={setPickUpDate}
                      dropDate={dropDate}
                      setDropDate={setDropDate}
                      pickUpTime={pickUpTime}
                      setPickUpTime={setPickUpTime}
                      cabType={cabType}
                      setCabType={setCabType}
                      selectTemp={selectTemp}
                      setSelectTemp={setSelectTemp}
                      selectedCabType={selectedCabType}
                      setSelectedCabType={setSelectedCabType}
                      setSelectedTariffId={setSelectedTariffId}
                      setCarCategoriesDetails={setCarCategoriesDetails}
                    />
                  )}

                {tripType === "Airport" &&
                  airportSubTripType === "fromtheairport" && (
                    <Fromtheairport
                      pickUpLocation={pickUpLocation}
                      setPickUpLocation={setPickUpLocation}
                      selectAirport={selectAirport}
                      selectDropAddress={selectDropAddress}
                      setSelectAirport={setSelectAirport}
                      setSelectDropAddress={setSelectDropAddress}
                      selectedLocation={selectedLocation}
                      setSelectedLocation={setSelectedLocation}
                      pickUpDate={pickUpDate}
                      setPickUpDate={setPickUpDate}
                      pickUpTime={pickUpTime}
                      setPickUpTime={setPickUpTime}
                      cabType={cabType}
                      setCabType={setCabType}
                      selectTemp={selectTemp}
                      setSelectTemp={setSelectTemp}
                      selectedCabType={selectedCabType}
                      setSelectedCabType={setSelectedCabType}
                      setSelectedTariffId={setSelectedTariffId}
                      setCarCategoriesDetails={setCarCategoriesDetails}
                    />
                  )}

                {tripType === "Airport" &&
                  airportSubTripType === "totheairport" && (
                    <Totheairport
                      pickUpLocation={pickUpLocation}
                      setPickUpLocation={setPickUpLocation}
                      selectAirport={selectAirport}
                      setSelectAirport={setSelectAirport}
                      selectedLocation={selectedLocation}
                      setSelectedLocation={setSelectedLocation}
                      pickUpDate={pickUpDate}
                      setPickUpDate={setPickUpDate}
                      pickUpTime={pickUpTime}
                      setPickUpTime={setPickUpTime}
                      cabType={cabType}
                      setCabType={setCabType}
                      selectTemp={selectTemp}
                      setSelectTemp={setSelectTemp}
                      selectedCabType={selectedCabType}
                      setSelectedCabType={setSelectedCabType}
                      setSelectedTariffId={setSelectedTariffId}
                      setCarCategoriesDetails={setCarCategoriesDetails}
                    />
                  )}

                <div className="passenger-title-row">
                  <div className="row">
                    <div className="title-name">Passenger Details</div>
                  </div>
                </div>

                <div className="row">
                  <div className="user-info-1">
                    {isNewCustomer ? (
                      <>
                        <div className="new-passenger-fname">
                          <div className="row">
                            <input
                              type="text"
                              className="custom-input"
                              placeholder="First name"
                              value={firstName}
                              onChange={(e) => setFirstName(e.target.value)}
                              required
                            />
                          </div>
                        </div>

                        <div className="new-passenger-lname">
                          <div className="row">
                            <input
                              type="text"
                              className="custom-input"
                              placeholder="Last name"
                              value={lastName}
                              onChange={(e) => setLastName(e.target.value)}
                              required
                            />
                          </div>
                        </div>

                        <div className="new-passenger-email">
                          <div className="row">
                            <input
                              type="text"
                              className="custom-input"
                              placeholder="Passenger E-mail"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                              required
                            />
                          </div>
                        </div>

                        {/* local new customer end */}
                        <div className="passenger-contact">
                          <div className="row">
                            <input
                              type="text"
                              className="custom-input"
                              placeholder="Passenger contact number"
                              value={mobileNo}
                              onChange={(e) => setMobileNo(e.target.value)}
                              required
                            />
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="existing-passe">
                          <div className="passenger-fname ex-fname">
                            <div className="row">
                              <input
                                type="text"
                                className="custom-input"
                                placeholder="First name"
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                                required
                                readOnly
                              />
                            </div>
                          </div>
                        </div>

                        <div className="existing-passe">
                          <div className="passenger-lname ex-lname">
                            <div className="row">
                              <input
                                type="text"
                                className="custom-input"
                                placeholder="Last name"
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                                required
                                readOnly
                              />
                            </div>
                          </div>
                        </div>

                        <div className="existing-passe">
                          <div className="passenger-email ex-email">
                            <div className="row">
                              <input
                                type="text"
                                className="custom-input"
                                placeholder="Passenger E-mail"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                                readOnly
                              />
                            </div>
                          </div>
                        </div>

                        <div className="existing-passe">
                          <div className="row passenger-mobile-no  ex-mobile">
                            <input
                              type="text"
                              className="custom-input"
                              placeholder="Passenger contact number"
                              value={mobileNo}
                              onChange={(e) => setMobileNo(e.target.value)}
                              required
                              readOnly
                            />
                          </div>
                        </div>
                      </>
                    )}

                    <SearchLocationInput
                      setlatlong={setLongitude}
                      query={PickupAddress}
                      setQuery={setPickupAddress}
                    />
                    <div className="passenger-Alternte-contact">
                      <input
                        className="custom-input"
                        autoComplete="off"
                        type="text"
                        onChange={(e) =>
                          setAlternateContactNumber(e.target.value)
                        }
                        placeholder="Alternate contact number"
                      />
                    </div>
                  </div>
                </div>
                {tripType === "Local" && (
                  <Localgst
                    gstValue={gstValue}
                    setGstValue={setGstValue}
                    flightValue={flightValue}
                    setFlightValue={setFlightValue}
                    comments={comments}
                    setComments={setComments}
                  />
                )}
              </div>
            </div>
          </div>

          {isOtpModalOpen && (
            <div className="modal-container">
              <div className="modal-content">
                <p>
                  Please enter the OTP sent on user mobile no.{" "}
                  {"+91-" + mobileNo}
                </p>
                <div className="otp-input-container">
                  {otp.map((digit, index) => (
                    <input
                      key={index}
                      id={`otp-input-${index}`}
                      type="text"
                      maxLength="1"
                      value={digit}
                      onChange={(e) => handleOtpChange(index, e.target.value)}
                      onKeyDown={(e) => handleKeyDown(index, e)}
                      autoComplete="off"
                    />
                  ))}
                </div>
                <div className="button-container">
                  <button className="verify-button" onClick={handleVerifyOtp}>
                    Verify Now
                  </button>
                  <button className="cancel-button" onClick={handleCancelOtp}>
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          )}

          {/* {tripType === "Local" && ( */}
          <div className="bootom-pay-amount">
            <div className="head-grn">Select Advance Payable Amount</div>
            <div className="sub-head">(incl. of all taxes)</div>

            <div className="black-box">
              <div className="black-box-content">
                <div className="one">
                  <InputColumn
                    type="radio"
                    name="advanceAmount"
                    value="0"
                    label="Rs. 0.00"
                    checked={selectedAdvanceAmount === "0"}
                    id="advanceAmount0"
                    className="check-box"
                    handleChange={handleAdvanceAmountChange}
                  />

                  <label htmlFor="advanceAmount0" className="label-check">
                    Rs.{" "}
                    {calculatePercentOfActualValue(estimatedFare, 0)
                      ? calculatePercentOfActualValue(estimatedFare, 0)
                      : "00.00"}
                  </label>
                </div>
                <div className="two">0%</div>
              </div>
              <div className="black-box-content">
                <div className="one">
                  <InputColumn
                    type="radio"
                    name="advanceAmount"
                    value="25"
                    label="Rs. 866.25"
                    id="advanceAmount1"
                    checked={selectedAdvanceAmount === "25"}
                    className="check-box"
                    handleChange={handleAdvanceAmountChange}
                  />

                  <label htmlFor="advanceAmount1" className="label-check">
                    Rs.{" "}
                    {calculatePercentOfActualValue(estimatedFare, 25)
                      ? calculatePercentOfActualValue(estimatedFare, 25)
                      : "00.00"}
                  </label>
                </div>
                <div className="two">25%</div>
              </div>

              <div className="black-box-content">
                <div className="one">
                  <InputColumn
                    type="radio"
                    name="advanceAmount"
                    id="advanceAmount2"
                    value="50"
                    label="Rs. 1732.5"
                    checked={selectedAdvanceAmount === "50"}
                    className="check-box"
                    handleChange={handleAdvanceAmountChange}
                  />

                  <label htmlFor="advanceAmount2" className="label-check">
                    Rs.{" "}
                    {calculatePercentOfActualValue(estimatedFare, 50)
                      ? calculatePercentOfActualValue(estimatedFare, 50)
                      : "00.00"}
                  </label>
                </div>
                <div className="two">50%</div>
              </div>

              <div className="black-box-content">
                <div className="one">
                  <InputColumn
                    type="radio"
                    name="advanceAmount"
                    id="advanceAmount3"
                    value="100"
                    label="Rs. 3465"
                    checked={selectedAdvanceAmount === "100"}
                    className="check-box"
                    handleChange={handleAdvanceAmountChange}
                  />

                  <label htmlFor="advanceAmount3" className="label-check">
                    Rs.{" "}
                    {calculatePercentOfActualValue(estimatedFare, 100)
                      ? calculatePercentOfActualValue(estimatedFare, 100)
                      : "00.00"}
                  </label>
                </div>
                <div className="two">100%</div>
              </div>
            </div>
          </div>
          {/* )} */}

          <div className="add">
          <button
        onClick={handleCreateBooking}
        type="submit"
        className="add-btn"
        style={{ marginTop: "10px" }}
      >
        Create Booking
      </button>
          </div>
        </div>

        {tripType === "Local" && (
          <LocalDetails
            carCategoriesDetails={carCategoriesDetails}
            selectedCabType={selectedCabType}
            onEstimatedFareChange={handleEstimatedFareChange}
          />
        )}

        {/* outstation */}
        {tripType === "Outstation" && (
          <OutstationDetails
            carCategoriesDetails={carCategoriesDetails}
            selectedCabType={selectedCabType}
            onEstimatedFareChange={handleEstimatedFareChange}
          />
        )}

        {/* airport */}

        {tripType === "Airport" && (
          <AirportDetails
            carCategoriesDetails={carCategoriesDetails}
            selectedCabType={selectedCabType}
            onEstimatedFareChange={handleEstimatedFareChange}
          />
        )}
      </div>
    </>
  );
};

export default CreateNewBooking;
