import axios from "axios";
import React, { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import useSuggestionsCity from "../../hooks/useSuggestionsIndianCities";
import "./AddNewCity.css";
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const AddNewCity = () => {
	const [citySuggestionsHook] = useSuggestionsCity();
	const [data, setData] = useState([]);
	const [states, setStates] = useState([]);
	const [selectedState, setSelectedState] = useState("");
	const [cities, setCities] = useState([]);
	const [selectedCity, setSelectedCity] = useState("");
	const [payToDriver, setPayToDriver] = useState("Enable");

	useEffect(() => {
		setData(citySuggestionsHook);

		// Extract unique states
		const uniqueStates = Array.from(
			new Set(
				citySuggestionsHook
					.map((city) => {
						const parts = city.split(", ");
						return parts.length === 2 ? parts[1] : null; // Ensure we only return the state if it's properly formatted
					})
					.filter((state) => state), // Remove any null or undefined values
			),
		);

		setStates(uniqueStates);
	}, [citySuggestionsHook]);

	useEffect(() => {
		if (selectedState) {
			const filteredCities = data
				.filter((city) => {
					const parts = city.split(", ");
					return parts.length === 2 && parts[1] === selectedState; // Check if the state matches
				})
				.map((city) => city.split(", ")[0]); // Extract the city part
			setCities(filteredCities);
		} else {
			setCities([]);
		}
	}, [selectedState, data]);

	const handleSubmit = async (e) => {
		e.preventDefault();
		console.log("get called without clikced ");
		const headers = {
			"Content-Type": "application/json",
		};

		const dataObj = new FormData();
		dataObj.append("state", selectedState);
		dataObj.append("city", selectedCity);
		dataObj.append(
			"zeroPaymentBooking",
			payToDriver === "Enable" ? "true" : "false",
		);

		if (selectedCity !== "" && payToDriver !== "") {
			try {
				await axios.post(
					`${API_BASE_URL}/v1/admin/locations`,
					dataObj,
					{
						headers: headers,
					},
				);
				toast.success("New City added successfully!");
				setTimeout(() => {
					window.location.reload();
				}, 3000);

				e.target.reset();
			} catch (error) {
				toast.error(error.response.data.message);
			}
		}
	};
	// State suggestions
	const [stateInput, setStateInput] = useState("");
	const [stateSuggestions, setStateSuggestions] = useState([]);
	const handleStateChange = (event) => {
		const value = event.target.value;
		setStateInput(value);
		setSelectedState(value);
		setStateSuggestions(getSuggestions(value, states));
	};

	// City suggestions
	const [cityInput, setCityInput] = useState("");
	const [citySuggestions, setCitySuggestions] = useState([]);
	const handleCityChange = (event) => {
		const value = event.target.value;
		setCityInput(value);
		setSelectedCity(value);
		setCitySuggestions(getSuggestions(value, cities));
	};

	// Method to get suggestions based on user input
	const getSuggestions = (value, list) => {
		const inputValue = value.trim().toLowerCase();
		return inputValue.length === 0
			? []
			: list
					.filter((item) => item && item.toLowerCase().startsWith(inputValue)) // Match from the start of the string
					.slice(0, 6); // Limit results to first 6 matches
	};

	useEffect(() => {
		async function fetchCities() {
			try {
				const response = await axios.get(
					"https://media.yatricab.com/indian-cities.json",
				);
				const validData = response.data.filter((city) => city.includes(", ")); // Ensure all entries have the ", " format
				setData(validData);
			} catch (error) {
				console.error("Error fetching suggestions:", error);
				setData([]); // Clear data if there's an error
			}
		}
		fetchCities();
	}, []);

	const [stateSuggestionsIndex, setStateSuggestionsIndex] = useState(-1);
	const [citySuggestionsIndex, setCitySuggestionsIndex] = useState(-1);

	const HandleStateSuggestions = (e) => {
		const { key } = e;
		if (key === "ArrowDown") {
			setStateSuggestionsIndex((prevIndex) =>
				prevIndex < stateSuggestions.length - 1 ? prevIndex + 1 : 0,
			);
		}
		if (key === "ArrowUp") {
			setStateSuggestionsIndex((prevIndex) =>
				prevIndex > 0 ? prevIndex - 1 : stateSuggestions.length - 1,
			);
		}
		if (key === "Enter" && stateSuggestionsIndex >= 0) {
			setSelectedState(stateSuggestions[stateSuggestionsIndex]);
			setStateInput(stateSuggestions[stateSuggestionsIndex]);
			setStateSuggestions([]);
		}
	};
	const HandleCitySuggestions = (e) => {
		const { key } = e;
		if (key === "ArrowDown") {
			setCitySuggestionsIndex((prevIndex) =>
				prevIndex < citySuggestions.length - 1 ? prevIndex + 1 : 0,
			);
		}
		if (key === "ArrowUp") {
			setCitySuggestionsIndex((prevIndex) =>
				prevIndex > 0 ? prevIndex - 1 : citySuggestions.length - 1,
			);
		}
		if (key === "Enter" && citySuggestionsIndex >= 0) {
			setSelectedCity(citySuggestions[citySuggestionsIndex]);
			setCityInput(citySuggestions[citySuggestionsIndex]);
			setCitySuggestions([]);
		}
	};
	console.log("FocusIndex" + stateSuggestionsIndex);
	return (
		<>
			<Toaster />
			<form onSubmit={handleSubmit}>
				<div className="add-new-city-container">
					<div className="price-details">
						<div className="create-new-sub-head">Add City</div>
						<div className="customer-details">
							<div className="row1">
								<div onKeyDown={HandleStateSuggestions} className="col">
									<div className="col-item">
										<div className="dropdown-column">
											<div className="title">State</div>
											<input
												tabIndex={1}
												type="text"
												placeholder="Type State Name Here"
												value={stateInput}
												onChange={handleStateChange}
												className="input"
											/>
											{stateSuggestions.length > 0 && (
												<div className="suggestions">
													{stateSuggestions.map((suggestion, index) => (
														<div
															key={index}
															className={`suggestionss ${
																index === stateSuggestionsIndex ? "focused" : ""
															}`}
															onMouseOver={() => {
																setStateSuggestionsIndex(index);
															}}
															onClick={() => {
																setSelectedState(suggestion);
																setStateInput(suggestion);
																setStateSuggestions([]);
															}}
														>
															{suggestion}
														</div>
													))}
												</div>
											)}
										</div>
									</div>
									<div
										className="dropdown-column-pay"
										style={{
											marginTop: "20px",
											alignItems: "center",
										}}
									>
										<div
											style={{
												fontSize: "11px",
												fontWeight: "bold",
												marginLeft: "20px",
											}}
										>
											Pay To Driver
										</div>
										<div
											role="radiogroup"
											aria-labelledby="payToDriverLabel"
											onKeyDown={(e) => {
												if (e.key === "ArrowRight" || e.key === "ArrowLeft") {
													const radios =
														document.getElementsByName("payToDriver");
													let newIndex;
													radios.forEach((radio, index) => {
														if (radio.checked) {
															newIndex =
																e.key === "ArrowRight"
																	? (index + 1) % radios.length
																	: (index - 1 + radios.length) % radios.length;
														}
													});
													radios[newIndex].checked = true;
													setPayToDriver(radios[newIndex].value);
												}
											}}
											style={{
												display: "flex",
												width: "250px",
												alignItems: "center",
												marginLeft: "20px",
											}}
										>
											<label
												style={{
													display: "flex",
													gap: "10px",
													alignItems: "center",
													fontSize: "14px",
													marginTop: 0,
													marginBottom: 0,
												}}
											>
												<input
													type="radio"
													tabIndex={3}
													id="enableRadio"
													name="payToDriver"
													value="Enable"
													checked={payToDriver === "Enable"}
													onChange={(e) => setPayToDriver(e.target.value)}
													style={{
														height: "12px",
														width: "12px",
													}}
												/>
												Enable
											</label>

											<label
												style={{
													display: "flex",
													gap: "10px",
													alignItems: "center",
													fontSize: "14px",
													marginTop: 0,
													marginBottom: 0,
												}}
											>
												<input
													tabIndex={4}
													type="radio"
													id="disableRadio"
													name="payToDriver"
													value="Disable"
													checked={payToDriver === "Disable"}
													onChange={(e) => setPayToDriver(e.target.value)}
													style={{
														height: "12px",
														width: "12px",
													}}
												/>
												Disable
											</label>
										</div>
									</div>
								</div>
								<div onKeyDown={HandleCitySuggestions} className="col">
									<div className="col-item">
										<div className="dropdown-column">
											<div className="title">City</div>

											<input
												tabIndex={2}
												type="text"
												placeholder="Type City Name Here"
												value={cityInput}
												onChange={handleCityChange}
												className="input"
												disabled={!selectedState}
											/>
											{citySuggestions.length > 0 && (
												<div className="suggestions">
													{citySuggestions.map((suggestion, index) => (
														<div
															key={index}
															className={`suggestionss ${
																index === citySuggestionsIndex ? "focused" : ""
															}`}
															onMouseOver={() => {
																setCitySuggestionsIndex(index);
															}}
															onClick={() => {
																setSelectedCity(suggestion);
																setCityInput(suggestion);
																setCitySuggestions([]);
															}}
														>
															{suggestion}
														</div>
													))}
												</div>
											)}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className="add">
						<button className="add-btn" tabIndex={5}>
							Submit
						</button>
					</div>
				</div>
			</form>
		</>
	);
};

export default AddNewCity;
