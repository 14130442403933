import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Loader from "../Loader";
import "./Interaction.css";
import { fetchInteraction, selectInteraction } from "./InteractionSlice";

const Interation = () => {
  const dispatch = useDispatch();
  const interaction = useSelector(selectInteraction);
  const { id } = useParams();

  useEffect(() => {
    dispatch(fetchInteraction(id));
  }, []);

  return (
    <div className="secondary">
      <div className="main-box-body-inteaction">
        <div className="top-extra-space"></div>

        <div className="interaction-table">
          <table>
            <thead>
              <tr className="headWrapper">
                <th className="WTbox">

                </th>
                <th className="GRbox">Date & Time</th>
                <th className="GRbox">Assisted By</th>
                <th className="GRbox">Contact Type</th>
                <th className="GRbox">Query Type</th>
                <th className="GRbox">Sub Query Type</th>
                <th className="GRbox">Sub Query Type</th>
                <th className="GRbox"></th>
              </tr>
            </thead>

            <tbody>
              {interaction ? (
                interaction.length === 0 ? (
                  <tr>
                    <td colSpan="7" className="no-interaction-message">
                      No interaction available for this Booking
                    </td>
                  </tr>
                ) : (
                  interaction.map((item, index) => (
                    <React.Fragment key={index}>
                      <tr className="headWrapper">
                        <td className="WTbox">
                          <span className="greenHL">
                            Interaction {index + 1}
                          </span>
                        </td>
                        <td className="WTdata">
                        <span className="WTtext">
                          {item.createdAt
                            ? new Date(item.createdAt).toLocaleString("en-IN", {
                                day: "2-digit",
                                month: "2-digit",
                                year: "numeric",
                                hour: "2-digit",
                                minute: "2-digit",
                              })
                            : "Not Available"}
                            </span>
                        </td>
                        <td className="WTdata">
                        <span className="WTtext">
                          {item.assistedBy?.firstName +
                            " " +
                            item.assistedBy?.lastName}
                            </span>
                        </td>
                        <td className="WTdata">
                        <span className="WTtext">
                          {item.contactType || "Not Available"}
                          </span>
                        </td>
                        <td className="WTdata">
                        <span className="WTtext">
                          {item.queryType || "Not Available"}
                          </span>
                        </td>
                        <td className="WTdata">
                        <span className="WTtext">
                          {item.subQueryType || "Not Available"}
                          </span>
                        </td>
                        <td className="WTdata">
                        <span className="WTtext">
                          {item.subQueryType || "Not Available"}
                          </span>
                        </td>
                        <td className="WTdata">
                        <span className="WTtext"></span>
                        </td>
                      </tr>
                      <tr className="headWrapper">
                        <td className="WTbox">
                          <span  className="greenHL">Remarks</span>
                        </td>
                        <td colSpan="6" className="GBG"><span className="GBtext"> {item.remarks || "N/A"} </span></td>
                      </tr>
                    </React.Fragment>
                  ))
                )
              ) : (
                <tr>
                  <td colSpan="7" className="loading-circle">
                    <Loader />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Interation;
