import { useEffect, useState } from "react";
import { FaLongArrowAltRight } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import {
	Link,
	Route,
	Routes,
	useLocation,
	useNavigate,
} from "react-router-dom";
import AddCarCategory from "./AddCarCategory";
import AddNewCar from "./AddNewCar";
import ApprovalPending from "./ApprovalPending";
import "./CarManagement.css";
import { resetCompState } from "./carSlice";
import InactiveCars from "./InactiveCars";
import "./SharedDropdownAndInput.css";
import ViewAllCars from "./ViewAllCar";
import ViewAllCategory from "./ViweAllCategory";
import { IoIosArrowDown } from "react-icons/io";

const CarManagement = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();
	const [activeComponent, setActiveComponent] = useState("view-all-cars");

	const { currentComp } = useSelector((state) => state.car);

	const formatTitle = (str) => {
		return str
			.replace(/-/g, " ")
			.replace(/\b\w/g, (char) => char.toUpperCase());
	};

	useEffect(() => {
		if (location.pathname === "/carmanagement") {
			navigate("/carmanagement/view-all-cars");
		} else {
			const path = location.pathname.split("/").pop();
			setActiveComponent(path);
		}
	}, [navigate, location.pathname]);

	const handleActiveComponent = (type) => {
		setActiveComponent(type);
		navigate(`/carmanagement/${type}`);
	};

	useEffect(() => {
		if (currentComp !== null) {
			setActiveComponent(currentComp);
			dispatch(resetCompState(null));
		}
	}, [currentComp, dispatch]);

	return (
		<div
			className="carmangement-header"
			style={{ minWidth: "81dvw", paddingRight: "10px" }}
		>
			<div
				style={{
					display: "flex",
					flexDirection: "row",
					justifyContent: "space-between",
					marginTop: "10px",
				}}
			>
				<div
					style={{
						display: "flex",
						alignItems: "center",
					}}
				>
					<Link
						to="/dashboard"
						style={{
							fontSize: "11px",
							width: "100px",
							margin: 0,
						}}
					>
						Fleet Management
					</Link>
					<IoIosArrowDown
						style={{ transform: "rotate(-90deg)" }}
						color="#f56a00"
					/>
					<Link
						to="/carmanagement"
						style={{
							width: "160px",
							margin: 0,
						}}
					>
						{formatTitle(activeComponent)}
					</Link>
				</div>

				<div
					style={{
						display: "flex",
						alignItems: "center",
					}}
				>
					<button
						className={
							activeComponent === "view-all-cars"
								? "createButton-cars"
								: "createButton"
						}
						onClick={() => handleActiveComponent("view-all-cars")}
						style={{}}
					>
						View All Cars
					</button>
					<button
						className={
							activeComponent === "inactive-cars"
								? "createButton-cars"
								: "createButton"
						}
						onClick={() => handleActiveComponent("inactive-cars")}
					>
						Inactive Cars
					</button>
					<button
						className={
							activeComponent === "approval-pending"
								? "createButton-cars"
								: "createButton"
						}
						onClick={() => handleActiveComponent("approval-pending")}
					>
						Approval Pending
					</button>
					<button
						className={
							activeComponent === "add-new-car"
								? "createButton-cars"
								: "createButton"
						}
						onClick={() => handleActiveComponent("add-new-car")}
					>
						Add New Car
					</button>
					<button
						className={
							activeComponent === "add-car-category"
								? "createButton-cars"
								: "createButton"
						}
						onClick={() => handleActiveComponent("add-car-category")}
					>
						Add Car Category
					</button>
					<button
						className={
							activeComponent === "view-cars-category"
								? "createButton-cars"
								: "createButton"
						}
						onClick={() => handleActiveComponent("view-cars-category")}
					>
						View Cars Category
					</button>
				</div>
			</div>

			<div>
				<Routes>
					<Route path="view-all-cars" element={<ViewAllCars />} />
					<Route path="inactive-cars" element={<InactiveCars />} />
					<Route path="approval-pending" element={<ApprovalPending />} />
					<Route path="add-new-car" element={<AddNewCar />} />
					<Route path="add-car-category" element={<AddCarCategory />} />
					<Route path="view-cars-category" element={<ViewAllCategory />} />
				</Routes>
			</div>
		</div>
	);
};

export default CarManagement;
