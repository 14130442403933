// import React, { useState } from "react";
// import "./Table.css";
// import "./Dropdown.css";
// import { useNavigate } from "react-router-dom";
// import { useDispatch } from "react-redux";
// import { createnewbookingpath } from "./BookingSlice";
// import { setToggleForTaskDetails } from "../AssignCab&Driver/assignCab&DriverSlice";

// const TableItem = ({ item, index, isRowSelected }) => {
//   const navigate = useNavigate();
//   const dispatch = useDispatch();
//   const rowClass = index % 2 === 0 ? "table-row-even" : "table-row-odd";
//   const selectedRowClass = isRowSelected ? "table-row-selected" : "";

//   function getStatusColor(status) {
//     switch (status.toLowerCase()) {
//       case "pending":
//         return "#FBAB0E";
//       case "in route":
//         return "#F507FA";
//       case "completed":
//         return "#38B000";
//       case "confirmed":
//         return "blue";
//       case "cancelled":
//         return "#FC0F0F";
//       case "cab assigned":
//         return "#00BCBC";
//       default:
//         return "black";
//     }
//   }

//   const handleClick = () => {
//     dispatch(createnewbookingpath(`/booking/${item["bookingId"]}`));
//     dispatch(setToggleForTaskDetails(false));
//     navigate(`/booking/${item["bookingId"]}`);
//   };

//   return (
//     <tr className={`${rowClass} ${selectedRowClass}`} onClick={handleClick}>
//       <td>{index + 1}</td>
//       <td>{item["bookingId"]}</td>
//       <td>{item["source"]}</td>
//       <td>{item["name"]}</td>
//       <td>
//         {item.location &&
//         typeof item.location === "string" &&
//         item.location.includes(",")
//           ? item.location
//               .split(",")
//               .map((part) => part.trim())
//               .join(", ")
//           : "Invalid Location"}
//       </td>
//       {/* <td>{item["bookingDate"]}</td> */}
//       <td>{item["fromDate"]}</td>
//       <td>{item["toDate"]===null?(item["fromDate"]):(item["toDate"])}</td>
//       <td>{item["tripType"]}</td>
//       {/* <td>{subTripType}</td> */}
//       <td
//         style={{
//           color: getStatusColor(item["status"]),
//           fontWeight: "bold",
//           fontFamily: "Inter",
//         }}
//       >
//         {item["status"]}
//       </td>
//       <td>{item["vehicleType"]}</td>
//       <td>{`₹ ${item["fare"].toFixed(2)}`}</td>
//     </tr>
//   );
// };

// const Table = ({ headers, data }) => {
//   const [selectedRowIndex, setSelectedRowIndex] = useState(null);
//   const [sortConfig, setSortConfig] = useState({ key: null, direction: null });

//   const handleRowClick = (index) => {
//     setSelectedRowIndex(index);
//   };

//   const handleSort = (key) => {
//     let direction = "ascending";

//     if (sortConfig.key === key && sortConfig.direction === "ascending") {
//       direction = "descending";
//     }

//     setSortConfig({ key, direction });
//   };

//   return (
//     <div className="table-containers">
//       <div className="table-scroll">
//         <table className="table">
//           <thead>
//             <tr>
//               {headers.map((header, index) => (
//                 <th key={index} onClick={() => handleSort(header)}>
//                 <div className="span-th">
//                   {header}
//                   </div>
//                 </th>
//               ))}
//             </tr>
//           </thead>
//           <tbody>
//             {data &&
//               data.map((item, index) => (
//                 <TableItem
//                   key={index}
//                   item={item}
//                   index={index}
//                   isRowSelected={selectedRowIndex === index}
//                   handleRowClick={handleRowClick}
//                 />
//               ))}
//           </tbody>
//         </table>
//       </div>
//     </div>
//   );
// };

// export default Table;


//////////////////////////  dont remove above commented code///////////////////////////////////////////////////////////////////////


import React, { useState } from "react";
import "./Table.css";
import "./Dropdown.css";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { createnewbookingpath } from "./BookingSlice";
import { setToggleForTaskDetails } from "../AssignCab&Driver/assignCab&DriverSlice";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";

const TableItem = ({ booking, index, isRowSelected }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const rowClass = index % 2 === 0 ? "table-row-even" : "table-row-odd";
  const selectedRowClass = isRowSelected ? "table-row-selected" : "";


  function getStatusColor(status) {
    switch (status.toLowerCase()) {
      case "pending":
        return "#FBAB0E";
      case "in route":
        return "#F507FA";
      case "completed":
        return "#38B000";
      case "confirmed":
        return "blue";
      case "cancelled":
        return "#FC0F0F";
      case "cab assigned":
        return "#00BCBC";
      default:
        return "black";
    }
  }

  const handleClick = () => {
    dispatch(createnewbookingpath(`/booking/${booking?.bookingId}`));
    dispatch(setToggleForTaskDetails(false));
    navigate(`/booking/${booking?.bookingId}`);
  };
  return (
    <tr className={`${rowClass} ${selectedRowClass}`} onClick={handleClick}>
      <td>{index + 1}</td>
      <td>{booking?.bookingId}</td>
      <td>{booking?.source}</td>
      <td>{booking?.name}</td>
      <td>{booking?.location}</td>
      <td>{booking?.fromDate}</td>
      <td>{booking?.toDate === null ? (booking?.fromDate) : (booking?.toDate)}</td>
      <td>{booking?.tripType}</td>
      <td style={{
        color: getStatusColor(booking?.status),
        fontWeight: "bold",
        fontFamily: "Inter",
      }}
      >{booking?.status}</td>
      <td>{booking?.vehicleType}</td>
      <td>{`₹ ${booking?.fare.toFixed(2)}`}</td>

    </tr>
  );
};

const Table = ({ headers, bookings, onSort, sortBy, sortOrder }) => {
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [columnSortOrder, setColumnSortOrder] = useState({});
  const handleRowClick = (index) => {
    setSelectedRowIndex(index);
  };

  const handleSort = (criteria) => {
    const currentOrder = columnSortOrder[criteria] || "ascending";
    const newOrder = currentOrder === "ascending" ? "descending" : "ascending";

    // Update the local state and trigger the sort function
    setColumnSortOrder({ ...columnSortOrder, [criteria]: newOrder });
    onSort(criteria, newOrder);
  };

  return (
    <div className="table-containers">
      <div className="table-scroll">
        <table className="table">
          <thead>
            <tr>
              {headers.map((header, index) => (
                <th key={index} className={header.sortable ? "sortable-header" : ""}>
                  <div 
                  className="header-content"
                  onClick={() => handleSort(header.criteria)}
                  style={{ cursor: "pointer"  }}

                  >
                    <span>{header.label}</span>
                    {header.sortable && (
                      <div  className="sort-icons" >
                        <IoIosArrowUp
                          style={{
                            color:
                              sortBy === header.criteria && sortOrder === "descending"
                                ? "orange"
                                : "white",
                          }}
                        />
                        <IoIosArrowDown
                          style={{
                            color:
                              sortBy === header.criteria && sortOrder === "ascending"
                                ? "orange"
                                : "white",
                          }}
                        />
                      </div>
                    )}
                  </div>
                  {index !== headers.length - 1 && <div className='th-line-arrow'></div>}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {bookings.map((booking, index) => (
              <TableItem
                key={booking?.bookingId}
                booking={booking}
                index={index}
                isRowSelected={selectedRowIndex === index}
                handleRowClick={handleRowClick}
              />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Table;
