import React, { useState, useEffect } from "react";
import "./LocalTripPage.css";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FaPen } from "react-icons/fa";
import DropdownColumn from "../Booking/Dropdown";
import {
  LoadLocalTariffList,
  setTariffInactive,
  setTariffActive,
} from "./Action";
import {
  currentTariffId,
  fetchTariffDetails,
} from "./SingleTariffDetalisSlice";
import Filter from "../Booking/Filter";
import { Toggle } from "./Toggle";

const TableItem = ({ item, index }) => {
  let dispatch = useDispatch();

  const [togglestate, setToggleState] = useState(item.status);

  const handleEditClick = (id) => {
    console.log("tariff id send", id);
    dispatch(currentTariffId(id));
    dispatch(fetchTariffDetails(id));
  };

  const handleInactiveTariff = (id) => {
    console.log(togglestate);
    console.log("tariff id ", id);
    dispatch(setTariffInactive(id));
  };

  const handleActiveTariff = (id) => {
    console.log(togglestate);
    console.log("tariff id ", id);
    dispatch(setTariffActive(id));
  };

  const value = `/UpdateTariff/${item._id}`;
  index = index + 1;

  const logState = (state) => {
    console.log("Toggled:", state);
    setToggleState(state);
    state === false
      ? handleActiveTariff(item._id)
      : handleInactiveTariff(item._id);
  };

  return (
    <tr>
      <td>{index}</td>
      <td>
        {item?.location?.state}, {item?.location?.city}
      </td>
      <td>{item?.days}</td>
      <td>{item?.carCategory?.category}</td>
      <td>{item?.tripType}</td>
      <td>{item?.subTripType}</td>
      <td>{item?.fareDetails?.baseFare}</td>
      <td>{item?.fareDetails?.minKm}</td>
      <td>{item?.fareDetails?.minHrs}</td>
      <td>{item?.fareDetails?.extraKmCharge}</td>
      <td>{item?.fareDetails?.extraHrsCharge}</td>
      <td>{item?.fareDetails?.nightDriverAllowance}</td>
      <td>{item?.fareDetails?.dayDriverAllowance}</td>
      <td>{item?.distance}</td>
      <td>{item?.fareDetails?.gstRate}</td>
      <td>{item?.status}</td>

      <td>
        <button className="local-trip-table-button-toggle">
          <Toggle
            toggled={item?.status === "Active" ? false : true}
            onClick={logState}
          />
        </button>
        <Link to={value}>
          <FaPen
            className="icon-edit "
            onClick={() => handleEditClick(item._id)}
          />
        </Link>
      </td>
    </tr>
  );
};

const Table = ({ headers, localtariffData }) => {
  return (
    <div className="table-containers">
      <div className="table-scroll">
        <table className="local-table">
          <thead>
            <tr>
              {headers.map((header, index) => (
                <th key={index}>{header}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {localtariffData.map((item, index) => (
              <TableItem
                key={index}
                item={item}
                index={index}
                props={localtariffData}
              />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const LocalTripPage = () => {
  let dispatch = useDispatch();
  const [selectedLocation, setSelectedLocation] = useState("");
  const [selectedCarCategory, setselectedCarCategory] = useState("");
  const [selectedSubTripType, setSelectedSubTripType] = useState("");
  const [selectedGarageType, setSelectedGarageType] = useState("");
  const [selectedDaysType, setSelectedDaysType] = useState("");
  const [locationOptions, setLocationOptions] = useState([]);
  const [carCategoriesOptions, setCarCategoriesOptions] = useState([]);
  const [subTripOptions, setSubTripOptions] = useState([]);
  const [GarageOption, setGarageOption] = useState([]);
  const [DaysOption, setDaysOption] = useState([]);

  useEffect(() => {
    dispatch(LoadLocalTariffList());
  }, []);

  const localtariffData = useSelector(
    (state) => state.localtariff.localtariffData
  );

  console.log(localtariffData);

  useEffect(() => {
    const extractCategoriesAsOptions = (dataArray) => {
      if (Array.isArray(dataArray.data)) {
        return dataArray.data.map((item) => ({
          label: item.category,
          value: item.category,
        }));
      }
      return [];
    };

    const extractLocationsAsOptions = (dataArray) => {
      if (Array.isArray(dataArray.data)) {
        return dataArray.data.map((item) => ({
          label: item.location,
          value: item.location,
        }));
      }
      return [];
    };

    const extractSubTripAsOptions = (dataArray) => {
      if (Array.isArray(dataArray.data)) {
        return dataArray.data.map((item) => ({
          label: item.subTripTypes,
          value: item.subTripTypes,
        }));
      }
      return [];
    };

    const extractGarageAsOptions = (dataArray) => {
      if (Array.isArray(dataArray.data)) {
        return dataArray.data.map((item) => ({
          label: item.distance,
          value: item.distance,
        }));
      }
      return [];
    };

    const extractdaysAsOptions = (dataArray) => {
      if (Array.isArray(dataArray.data)) {
        return dataArray.data.map((item) => ({
          label: item.day,
          value: item.day,
        }));
      }
      return [];
    };

    const fetchOptions = async () => {
      try {
        const locationResponse = await fetch(
          "https://api.yatricabs.com/v1/locations"
        );
        const locationData = await locationResponse.json();
        const locationOptions = extractLocationsAsOptions(locationData);
        // console.log(locationOptions);
        setLocationOptions(locationOptions);
        const carResponse = await fetch(
          "https://api.yatricabs.com/v1/car-categories"
        );
        const carData = await carResponse.json();
        const carCategoriesOptions = extractCategoriesAsOptions(carData);
        // console.log(carCategoriesOptions);
        setCarCategoriesOptions(carCategoriesOptions);
        const subTripResponse = await fetch(
          "https://api.yatricabs.com/v1/sub-trip-types/Local"
        );
        const subTripData = await subTripResponse.json();
        const subTripOptions = extractSubTripAsOptions(subTripData);
        console.log(subTripOptions);
        setSubTripOptions(subTripOptions);

        const garageResponse = await fetch(
          "https://api.yatricabs.com/v1/distances"
        );
        const garageData = await garageResponse.json();
        const garageOptions = extractGarageAsOptions(garageData);
        console.log(garageOptions);
        setGarageOption(garageOptions);

        const daysResponse = await fetch("https://api.yatricabs.com/v1/days");
        const daysData = await daysResponse.json();
        const daysOptions = extractdaysAsOptions(daysData);
        console.log(daysOptions);
        setDaysOption(daysOptions);
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };
    fetchOptions();
  }, []);

  const headers = [
    "Sr No",
    "Location",
    "Days",
    "Vehicle Type",
    "Trip Type",
    "Sub Trip Type",
    "Base Fare",
    "Min km/Day",
    "Min hrs/Day",
    "Extra km",
    "Extra hrs",
    "Night Charges",
    "Day Driver Allowance",
    "Garage Options",
    "GST",
    "Status",
    "",
  ];

  return (
    <div>
      <div className="dropdown-container">
        <Filter />
        <DropdownColumn
          placeholder="Location"
          options={locationOptions}
          selectedValue={selectedLocation}
          handleChange={(e) => setSelectedLocation(e.target.value)}
          className="dropdown"
        />
        <DropdownColumn
          placeholder="Sub Trip Type"
          options={subTripOptions}
          selectedValue={selectedSubTripType}
          handleChange={(e) => setSelectedSubTripType(e.target.value)}
          className="dropdown"
        />
        <DropdownColumn
          placeholder="Garage Options"
          options={GarageOption}
          selectedValue={selectedGarageType}
          handleChange={(e) => setSelectedGarageType(e.target.value)}
          className="dropdown"
        />
        <DropdownColumn
          placeholder="Days"
          options={DaysOption}
          selectedValue={selectedDaysType}
          handleChange={(e) => setSelectedDaysType(e.target.value)}
          className="dropdown"
        />
        <DropdownColumn
          placeholder="vehicle Type"
          options={carCategoriesOptions}
          selectedValue={selectedCarCategory}
          handleChange={(e) => setselectedCarCategory(e.target.value)}
          className="dropdown"
        />
      </div>
      <Table
        headers={headers}
        localtariffData={localtariffData.filter((item) => {
          const combinedLocation = `${item?.location?.city}, ${item?.location.state}`;
          return (
            (selectedLocation ? combinedLocation === selectedLocation : true) &&
            (selectedSubTripType
              ? item?.subTripType === selectedSubTripType
              : true) &&
            (selectedGarageType
              ? item?.distance === selectedGarageType
              : true) &&
            (selectedDaysType ? item?.days === selectedDaysType : true) &&
            (selectedCarCategory
              ? item?.carCategory?.category === selectedCarCategory
              : true)
          );
        })}
      />
    </div>
  );
};

export default LocalTripPage;
