import React, { useEffect, useState } from "react";
//import { Document, Page } from "react-pdf";
import "./AddNewCar.css";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Link, useNavigate, useParams } from "react-router-dom";

//import Dropdown from 'react-dropdown-select';
import toast, { Toaster } from "react-hot-toast";
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const DropdownColumn = ({
  title,
  options,
  selectedValue,
  handleChange,
  selectedFile,
  removeFile,
}) => {
  return (
    <div className="View-add-new-car-dropdown-column">
      <h3>{title}</h3>
      <select value={selectedValue} onChange={handleChange}>
        {options.map((option) => (
          <option style={{}} key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
};

const AddNewCar = () => {
  const navigate = useNavigate();
  const [cityoption, setCityOption] = useState([]);
  const [driveroption, setDriverOption] = useState([]);
  const [randomCategory, setRandomCategory] = useState([]);
  const [selectcarmanufacturerArray, setSelectcarmanufacturerArray] = useState(
    []
  );
  const [selectCarModelArray, setSelectCarModelArray] = useState([]);

  const [selectcity, setSelectCity] = useState("");
  const [selectcarmanufacturer, setSelectCarManufacturer] = useState("");
  const [selectCategory, setSelectCategory] = useState("");
  const [selectcarmodel, setSelectCarModel] = useState("");
  const [selectedRadioButton, setselectedRadioButton] = useState("");
  const [selectcarCategory, setSelectCarCategory] = useState("");
  const [selectfuelcategory, setSelectFuelCategory] = useState("");
  const [carFrontPhoto, setcarFrontPhoto] = useState(null);
  const [permitDetails, setpermitDetails] = useState(null);
  const [carPhotoSide, setcarPhotoSide] = useState(null);
  const [PUCTest, setPUCTest] = useState(null);
  const [registrationCertificate, setregistrationCertificate] = useState(null);
  const [insurancePolicy, setinsurancePolicy] = useState(null);
  const [registrationCertificateBack, setregistrationCertificateBack] =
    useState(null);
  const [showCloseIconCar, setShowCloseIconCar] = useState(false);
  const [showCloseIconPermit, setShowCloseIconPermit] = useState(false);
  const [showCloseIconFront, setShowCloseIconFront] = useState(false);
  const [showCloseIconPUC, setShowCloseIconPUC] = useState(false);
  const [showCloseIconCertificate, setShowCloseIconCertificate] =
    useState(false);
  const [showCloseIconCertificateBack, setShowCloseIconCertificateBack] =
    useState(false);
  const [showCloseIconPolicy, setShowCloseIconPolicy] = useState(false);
  const [carColor, setCarColor] = useState("");
  const [rcNumber, setrcNumber] = useState("");

  const [expiryDate, setExpiryDate] = useState("");
  const [insuranceExpiryDate, setInsuranceExpiryDate] = useState("");
  const [pucExpiryDate, setPucExpiryDate] = useState("");

  const [carRegYear, setCarRegYear] = useState("");

  const [vehiclePart1, setVehiclePart1] = useState("");
  const [vehiclePart2, setVehiclePart2] = useState("");
  const [vehiclePart3, setVehiclePart3] = useState("");
  const [vehiclePart4, setVehiclePart4] = useState("");

  // // Get today's date and set the time to the start of the day
  // const today = new Date();
  // today.setHours(0, 0, 0, 0);

  const fileFields = [
    {
      label: "Car Photo Front",
      state: carFrontPhoto,
      setState: setcarFrontPhoto,
    },
    { label: "Car Photo Side", state: carPhotoSide, setState: setcarPhotoSide },
    {
      label: "Registration Certificate Front",
      state: registrationCertificate,
      setState: setregistrationCertificate,
    },
    {
      label: "Registration Certificate Back",
      state: registrationCertificateBack,
      setState: setregistrationCertificateBack,
    },
    {
      label: "Permit Details",
      state: permitDetails,
      setState: setpermitDetails,
      showDate: true,
      dateState: expiryDate,
      setDateState: setExpiryDate,
    },
    {
      label: "PUC Test",
      state: PUCTest,
      setState: setPUCTest,
      showDate: true,
      dateState: pucExpiryDate,
      setDateState: setPucExpiryDate,
    },
    {
      label: "Insurance Policy",
      state: insurancePolicy,
      setState: setinsurancePolicy,
      showDate: true,
      dateState: insuranceExpiryDate,
      setDateState: setInsuranceExpiryDate,
    },
  ];

  const handleRadioChange = (e) => {
    const selectedValue = e.target.value;
    setselectedRadioButton(selectedValue);

    if (selectedValue === "Company Owned") {
      setSelectCategory("");
    }
  };

  const handleFileChange = (e, setFile, setShowCloseIcon) => {
    const selectedFile = e.target.files[0];
    console.log("event",e)
    if (selectedFile) {
      setFile(selectedFile);
      setShowCloseIcon(true);
    }
  };

  const handleRemoveFile = (setFile, setShowCloseIcon, setDateState) => {
    setFile(null);
    if (setDateState) setDateState("");
    setShowCloseIcon(false);
  };

  const FuelCategoryOption = [
    { label: "Select Fuel Category", value: "city0" },
    { label: "Petrol", value: "Petrol" },
    { label: "Diesel", value: "Diesel" },
    { label: "CNG", value: "CNG" },
    { label: "Electric", value: "Electric" },
  ];
  const handleSubmit = async () => {
    console.log(selectCategory);
    console.log(
      selectcity,
      selectcarCategory,
      selectcarmanufacturer,
      selectcarmodel,
      selectfuelcategory,
      selectcarmodel,
      carFrontPhoto,
      permitDetails,
      carPhotoSide,
      PUCTest,
      registrationCertificate,
      registrationCertificateBack
    );

    const formData = new FormData();

    formData.append("location", selectcity);
    formData.append("driver", selectCategory);
    formData.append("carCategory", selectcarCategory);
    formData.append("carMake", selectcarmanufacturer);
    formData.append("carModel", selectcarmodel);
    formData.append("fuelType", selectfuelcategory);
    formData.append("carColor", carColor);
    formData.append("carRegistrationYear", carRegYear);

    // Combine vehicle number parts
    const rcNumber = `${vehiclePart1}${vehiclePart2}${vehiclePart3}${vehiclePart4}`;

    formData.append("rcNumber", rcNumber);

    formData.append("permitExpiryDate", expiryDate);
    formData.append("insuranceExpiryDate", insuranceExpiryDate);
    formData.append("pucExpiryDate", pucExpiryDate);
    formData.append(
      "rcFrontPhoto",
      registrationCertificate,
      registrationCertificate.name
    );
    formData.append(
      "rcBackPhoto",
      registrationCertificateBack,
      registrationCertificateBack.name
    );
    formData.append("carFrontPhoto", carFrontPhoto, carFrontPhoto.name);
    formData.append("carSidePhoto", carPhotoSide, carPhotoSide.name);
    formData.append("permitPhoto", permitDetails, permitDetails.name);
    formData.append("pucPhoto", PUCTest, PUCTest.name);
    formData.append("insurancePhoto", insurancePolicy, insurancePolicy.name);

    for (const pair of formData.entries()) {
      console.log("form Data", formData);
      console.log(pair[0] + ", " + pair[1]);
    }
    try {
      console.log("adding car");
      const req = await axios.post(
        `${API_BASE_URL}/v1/admin/cars`,
        formData
      );
      console.log("car added");
      toast.success("car added");

      setTimeout(() => {
        navigate("/carmanagement");
      }, 3000);
    } catch (error) {
      console.log(error?.response?.data?.message);
      toast.error(error?.response?.data?.message);
    }
  };
  const extractLocationsAsOptions = (dataArray) => {
    if (Array.isArray(dataArray.data)) {
      return dataArray.data.map((item) => ({
        label: item?.location,
        value: item?._id,
      }));
    }
    return [];
  };
  const extractDriversAsOptions = (dataArray) => {
    if (Array.isArray(dataArray.data)) {
      return dataArray.data.map((item) => ({
        label: item?.firstName,
        value: item?._id,
      }));
    }
    return [];
  };

  const extractCategoriesAsOptions = (dataArray) => {
    if (Array.isArray(dataArray.data)) {
      return dataArray.data.map((item) => ({
        label: item?.category,
        value: item?._id,
      }));
    }
    return [];
  };
  const extractCarManufacturesAsOptions = (dataArray) => {
    if (Array.isArray(dataArray.data)) {
      return dataArray.data.map((item) => ({
        label: item?.make,
        value: item?.make,
      }));
    }
    return [];
  };
  const extractCarModelsAsOptions = (dataArray) => {
    if (Array.isArray(dataArray.models)) {
      return dataArray.models.map((item) => ({
        label: item,
        value: item,
      }));
    }
    return [];
  };

  useEffect(() => {
    const locations = async () => {
      try {
        const locationResponse = await fetch(
          `${API_BASE_URL}/v1/locations`
        );
        const locationData = await locationResponse.json();

        const locationOptions = extractLocationsAsOptions(locationData);
        locationOptions.unshift({ label: "Select the location" });
        setCityOption(locationOptions);
        console.log(locationOptions);
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };
    locations();
    const driver = async () => {
      try {
        const driverData = await axios.get(
          `${API_BASE_URL}/v1/admin/drivers`
        );
        // const driverData = await drivers.json();
        console.log(driverData?.data);
        const din = extractDriversAsOptions(driverData?.data);
        din.unshift({ label: "Select the driver" });
        console.log(din);
        setDriverOption(din);
        console.log(driveroption);
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };
    driver();
    const carCategories = async () => {
      try {
        const categories = await fetch(
          `${API_BASE_URL}/v1/car-categories`
        );
        const categoriesData = await categories.json();
        const categoriesOptions = extractCategoriesAsOptions(categoriesData);
        categoriesOptions.unshift({ label: "Select the car category" });
        console.log(categoriesOptions);
        setRandomCategory(categoriesOptions);
        console.log(randomCategory);
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };

    carCategories();

    const carmanufacturing = async () => {
      try {
        const categories = await fetch(`${API_BASE_URL}/v1/car-makes`);
        const categoriesData = await categories.json();
        console.log(categoriesData);
        const categoriesOptions =
          extractCarManufacturesAsOptions(categoriesData);
        categoriesOptions.unshift({ label: "Select the car category" });
        console.log(categoriesOptions);
        setSelectcarmanufacturerArray(categoriesOptions);
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };
    carmanufacturing();

    const carmodels = async () => {
      try {
        if (selectcarmanufacturer) {
          const categories = await fetch(
            `${API_BASE_URL}/v1//car-models/${selectcarmanufacturer}`
          );
          const categoriesData = await categories.json();
          console.log(categoriesData.data[0]);
          const categoriesOptions = extractCarModelsAsOptions(
            categoriesData.data[0]
          );
          categoriesOptions.unshift({ label: "Select the car model" });
          console.log(categoriesOptions);
          setSelectCarModelArray(categoriesOptions);
        }
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };
    carmodels();
  }, [selectcarmanufacturer]);

  const handleDateChange = (e, setDate) => {
    const selectedDate = e.target.value;
    setDate(selectedDate);
  };

  return (
    <>
      <Toaster />
      <div className="add-new-car-vehicle-scrolbar" style={{ height: "95vh" }}>
        <div className="add-new-car-vehicle">Vehicle Information</div>
        <div className="user-details">
          <div className="fleet-user-details-section">
            {/* Location */}
            <div
              className="fleet-user-detail"
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <div className="user-heading">Location</div>
              <DropdownColumn
                options={cityoption}
                selectedValue={selectcity}
                handleChange={(e) => setSelectCity(e.target.value)}
              />
            </div>
            {/* Sourcing */}
            <div className="fleet-user-detail">
              <div className="user-heading">Sourcing</div>

              <div style={{ display: "flex" }}>
                <input
                  type="radio"
                  id="Company Owned"
                  name="radioCategory"
                  value="Company Owned"
                  checked={selectedRadioButton === "Company Owned"}
                  onChange={handleRadioChange}
                />
                <label htmlFor="Company Owned">Company Owned</label>

                <input
                  type="radio"
                  id="Outsourced"
                  name="radioCategory"
                  value="Outsourced"
                  checked={selectedRadioButton === "Outsourced"}
                  onChange={handleRadioChange}
                />
                <label htmlFor="Outsourced">Outsourced</label>
              </div>
            </div>
            {/* DropDown for Driver if Outsourced */}
            {selectedRadioButton === "Outsourced" && (
              <div className="fleet-user-detail">
                <div className="user-heading">Vehicle Owner / Driver</div>

                <DropdownColumn
                  options={driveroption}
                  selectedValue={selectCategory}
                  handleChange={(e) => setSelectCategory(e.target.value)}
                />
              </div>
            )}
            {/* Car Category */}
            <div className="fleet-user-detail">
              <div className="user-heading">Car Category</div>
              <DropdownColumn
                options={randomCategory}
                selectedValue={selectcarCategory}
                handleChange={(e) => setSelectCarCategory(e.target.value)}
              />
            </div>
            {/* Car Make */}
            <div className="fleet-user-detail">
              <div className="user-heading">Car Make</div>
              <DropdownColumn
                options={selectcarmanufacturerArray}
                selectedValue={selectcarmanufacturer}
                handleChange={(e) => setSelectCarManufacturer(e.target.value)}
              />
            </div>
            {/* Model */}
            <div className="fleet-user-detail">
              <div className="user-heading">Model</div>
              <DropdownColumn
                options={selectCarModelArray}
                selectedValue={selectcarmodel}
                handleChange={(e) => setSelectCarModel(e.target.value)}
              />
            </div>
            {/* Fuel Type */}
            <div className="fleet-user-detail">
              <div className="user-heading">Fuel Type</div>
              <DropdownColumn
                options={FuelCategoryOption}
                selectedValue={selectfuelcategory}
                handleChange={(e) => setSelectFuelCategory(e.target.value)}
              />
            </div>
            {/* Body Colour */}
            <div
              className="fleet-user-detail"
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <div className="user-heading">Body Colour</div>

              <input
                style={{
                  marginTop: "8px",
                  fontSize: "11px",
                  fontWeight: 400,
                  lineHeight: "px",
                  width: "200px",
                  display: "flex",
                  alignItems: "center",
                }}
                type="text"
                className="custom-input"
                placeholder="Type Color"
                onChange={(e) => {
                  setCarColor(e.target.value);
                }}
              />
            </div>
            {/* Year */}
            <div className="fleet-user-detail">
              <div className="user-heading">Year</div>
              <input
                style={{ width: "200px", marginTop: "8px" }}
                type="text"
                className="custom-input"
                placeholder="Y-Y-Y-Y"
                onChange={(e) => setCarRegYear(e.target.value)}
              />
            </div>
            {/* Vehicle Number */}
            <div
            style={{
              display:"flex",
             
              gap:"58px"
            }}
             className="fleet-user-detail vn-11">
              <div className="user-heading">Vehicle Number</div>
              <div style={{
                display:"flex",
              
              }}>
                <input
                  type="text"
                  placeholder="KA"
                  className="custom-input-3"
                  onChange={(e) => setVehiclePart1(e.target.value)}
                />
                <input
                  type="text"
                  className="custom-input-3"
                  placeholder="01"
                  onChange={(e) => setVehiclePart2(e.target.value)}
                />
                <input
                  type="text"
                  className="custom-input-3"
                  placeholder="AB"
                  onChange={(e) => setVehiclePart3(e.target.value)}
                />
                <input
                  type="text"
                  className="custom-input-3"
                  placeholder="0000"
                  onChange={(e) => setVehiclePart4(e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>

        {/* Upload documents Part */}
        <div className="add-new-car-vehicle">Upload Documents</div>

        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(auto-fit, minmax(350px, 1fr))",
            gap: "30px",
            padding: "30px 55px",
          }}
        >
          {fileFields.map(
            (
              { label, state, setState, showDate, dateState, setDateState },
              index
            ) => (
              <div
                key={index}
                style={{
                  display: "grid",
                  gridTemplateColumns: "100px 1fr",
                  // alignItems: "center",
                  gap: "px",
                }}
              >
                {/* Label Column */}
                <div
                  style={{
                    fontSize: "11px",
                    fontWeight: 700,
                    display: "flex",
                    flexWrap: true,
                  }}
                >
                  {label}
                </div>

                {/* File & Button Column */}
                <div
                  style={{
                    display: "grid",
                    gridTemplateRows: "auto auto",
                    gap: "5px",
                  }}
                >
                  {/* File Preview + Expiry Date */}
                  <div
                    style={{
                      display: "flex",
                      alignItems: "start",
                      gap: "10px",
                    }}
                  >
                    {/* File Preview Box */}
                    <div
                      style={{
                        width: "83px",
                        height: "89px",
                        backgroundColor: "#ddd",
                        borderRadius: "5px",
                        position: "relative",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {state ? (
                        <img
                          src={URL.createObjectURL(state)}
                          alt="Selected"
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                            borderRadius: "5px",
                          }}
                        />
                      ) : null}

                      {state && (
                        <span
                          onClick={() =>
                            handleRemoveFile(
                              setState,
                              setShowCloseIconCar,
                              setDateState
                            )
                          }
                          style={{
                            position: "absolute",
                            top: "5px",
                            right: "5px",
                            color: "red",
                            fontSize: "18px",
                            fontWeight: "bold",
                            cursor: "pointer",
                          }}
                        >
                          ×
                        </span>
                      )}
                    </div>

                    {/* Expiry Date Field (if applicable) */}
                    {showDate && (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "1px",
                          marginLeft: 0,
                        }}
                      >
                        <input
                          type="date"
                          value={dateState}
                          onChange={(e) => setDateState(e.target.value)}
                          style={{
                            padding: "5px",
                            border: "1px solid #ccc",
                            color: "#606060",
                            // border:"none",
                            borderRadius: "5px",
                            fontSize: "14px",
                          }}
                        />
                        <label
                          style={{
                            color: "#606060",
                          }}
                        >
                          expiry date
                        </label>
                      </div>
                    )}
                  </div>

                  {/* File Upload Button & File Name */}
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "8px",
                    }}
                  >
                    <label
                      style={{
                        backgroundColor: "#0A85DE",
                        color: "#fff",
                        padding: "6px 10px",
                        borderRadius: "4px",
                        cursor: "pointer",
                        fontSize: "8px",
                        marginLeft: 0,
                      }}
                    >
                      <input
                        type="file"
                        style={{ display: "none" }}
                        onChange={(e) =>
                          handleFileChange(e, setState, setShowCloseIconCar)
                        }
                      />
                      Choose File
                    </label>

                    {/* Selected File Info */}
                    <p style={{ fontSize: "11px", color: "#666" }}>
                      {state ? `${state.name}` : "No file chosen"}
                    </p>
                  </div>
                </div>
              </div>
            )
          )}
        </div>

        <div style={{ display: "flex", justifyContent: "center" }}>
          <button
            style={{
              borderRadius: "5px",
              margin: "10px ",
              marginBottom:"30px",
              marginTop: "30px",
              border: "0.5px solid #38B000",
              background: "#38B000",
              width: "150px",
              padding: "6px",
              color: "#FFF",
              fontSize: "15px",
              cursor: "pointer",
            }}
            onClick={handleSubmit}
          >
            Submit
          </button>
        </div>
      </div>
    </>
  );
};

export default AddNewCar;
