// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.view-coupon-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 79px;
}

.view-coupon-table th,
.view-coupon-table td {
  border: 1px solid #000;
  padding: 10px;
  text-align: left;
  border-radius: none;
  text-align: center;
}

.view-coupon-table th {
  background: #38b000;
  color: #fff;
  border: 1px solid #000;
  padding: 10px;
  text-align: left;
  border-radius: 0;
  text-align: center;
}

.view-coupon-buttons button {
  border-radius: 10px;
  background: #38b000;
  width: 47px;
  height: 15px;
  flex-shrink: 0;
  border: none;
  cursor: pointer;
  color: #fff;
  font-family: Lato;
  font-size: 8px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.view-coupon-buttons button svg {
  margin-right: 5px;
  vertical-align: middle;
}
`, "",{"version":3,"sources":["webpack://./src/components/Coupons/ViewCouponCode.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,yBAAyB;EACzB,gBAAgB;AAClB;;AAEA;;EAEE,sBAAsB;EACtB,aAAa;EACb,gBAAgB;EAChB,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,mBAAmB;EACnB,WAAW;EACX,sBAAsB;EACtB,aAAa;EACb,gBAAgB;EAChB,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,mBAAmB;EACnB,mBAAmB;EACnB,WAAW;EACX,YAAY;EACZ,cAAc;EACd,YAAY;EACZ,eAAe;EACf,WAAW;EACX,iBAAiB;EACjB,cAAc;EACd,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;EACjB,sBAAsB;AACxB","sourcesContent":[".view-coupon-table {\n  width: 100%;\n  border-collapse: collapse;\n  margin-top: 79px;\n}\n\n.view-coupon-table th,\n.view-coupon-table td {\n  border: 1px solid #000;\n  padding: 10px;\n  text-align: left;\n  border-radius: none;\n  text-align: center;\n}\n\n.view-coupon-table th {\n  background: #38b000;\n  color: #fff;\n  border: 1px solid #000;\n  padding: 10px;\n  text-align: left;\n  border-radius: 0;\n  text-align: center;\n}\n\n.view-coupon-buttons button {\n  border-radius: 10px;\n  background: #38b000;\n  width: 47px;\n  height: 15px;\n  flex-shrink: 0;\n  border: none;\n  cursor: pointer;\n  color: #fff;\n  font-family: Lato;\n  font-size: 8px;\n  font-style: normal;\n  font-weight: 700;\n  line-height: normal;\n}\n\n.view-coupon-buttons button svg {\n  margin-right: 5px;\n  vertical-align: middle;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
