import React from 'react'
import './VenderPayout.css'
const VenderPayout = () => {
  return (
    <>

      <div className="vp-main-container">
        <h1>Vendor Payout</h1>

        <div className="vp-flexer-1">
          <div>Description</div>
          <div>No. of Units <p>(KM/Days/Hours)</p></div>
          <div>Rate Per Unit</div>
          <div>Amount</div>
        </div>

        <div className="vp-flexer-1">
          <div>Estimated KM <p>Min Km Charged : 250 Kms/day</p></div>
          <div>634 Km</div>
          <div>Rs. 10.00 / Km</div>
          <div>Rs. 6,340.00</div>
        </div>

        <div className="vp-flexer-1">
          <div>Extra Km </div>
          <div>66 Km</div>
          <div>Rs. 10.00 / Km</div>
          <div>Rs. 660.00</div>
        </div>

        <div className="vp-flexer-1">
          <div>Night Allowance</div>
          <div>05 Days</div>
          <div>Rs. 300 / day</div>
          <div>Rs. 1,500.00</div>
        </div>

        <div className="vp-flexer-2">
          <div>Total Amount</div>
          <div>Rs. 8,500.00</div>
        </div>

        <div className="vp-flexer-2">
          <div>Total Amount</div>
          <div>Rs. 8,500.00</div>
        </div>

        <div className="vp-flexer-2">
          <div>Total Amount</div>
          <div>Rs. 8,500.00</div>
        </div>

        <div className="vp-flexer-2">
          <div>Balance Payment to be paid</div>
          <div>Rs. 8,500.00</div>
        </div>

        <div className="vp-flexer-2">
          <div>Excess Payment if any</div>
          <div>Rs. 0.00</div>
        </div>

      </div>

    </>
  )
}

export default VenderPayout