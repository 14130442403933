import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchAllCoupons } from "./couponAPI";

const initialState = {
  coupons: [],
};

export const fetchAllCouponsAsync = createAsyncThunk(
  "products/fetchAllCoupons",
  async () => {
    try {
      const response = await fetchAllCoupons();
      console.log(response)
      return response;
    } catch (error) {
      console.error("Error fetching products:", error);
      throw error;
    }
  }
);

export const couponSlice = createSlice({
  name: "coupon",
  initialState,

  reducers: {
    clearSelectedProduct: (state) => {
      state.selectedProduct = null;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchAllCouponsAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllCouponsAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.coupons = action.payload;
      });
  },
});

export const { clearSelectedProduct } = couponSlice.actions;

export const selectAllcoupons = (state) => state.coupon.coupons;

export default couponSlice.reducer;
