import React, { useState } from "react";
import "./AddNewCorporate.css";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const AddNewCorporate = () => {
  const [step, setStep] = useState(1);

  // -------------------------------------
  // Initial State (includes both steps)
  // -------------------------------------
  const [inputValue, setInputValue] = useState({
    // Step 1: Company Info + Payment & Billing
    companyName: "",
    travelDeskManagerName: "",
    workEmailAddress: "",
    mobileNo: "",
    altMobileNo: "",
    companyPinNo: "",
    companyGSTIN: "",
    companyPanNo: "", // optional
    password: "",
    confirmPassword: "",
    modeOfPayment: "",
    creditPeriod: "",
    noOfDays: "",
    billingAddress: "",
    billingCityName: "",
    billingState: "",

    // Step 2: Tariff Rates
    // Add Price Details
    location: "",
    carCategory: "",
    tripType: "",
    subTripType: "",
    garageDistanceOption: "",
    fuelType: "",
    days: "",

    // Pricing Slab
    baseKm: "",
    minHours: "",
    extraKm: "",
    extraHours: "",
    gst: "",
    gscFixed: "",

    // Driver Allowance
    morningBata: "",
    nightBata: "",
    dayBata: "",
  });

  const [errors, setErrors] = useState({});

  // -------------------------------------
  // Validation for Step 1
  // (Company Info + Payment & Billing)
  // -------------------------------------
  const validateStep1 = () => {
    let newErrors = {};

    // Company Info
    if (!inputValue.companyName.trim()) {
      newErrors.companyName = "Company Name is required.";
    }
    if (!inputValue.travelDeskManagerName.trim()) {
      newErrors.travelDeskManagerName = "Travel Desk Manager Name is required.";
    }
    if (!inputValue.workEmailAddress.trim()) {
      newErrors.workEmailAddress = "Work Email Address is required.";
    }
    if (!inputValue.mobileNo.trim()) {
      newErrors.mobileNo = "Mobile No. is required.";
    }
    if (!inputValue.companyPinNo.trim()) {
      newErrors.companyPinNo = "Company Pin No. is required.";
    }
    if (!inputValue.companyGSTIN.trim()) {
      newErrors.companyGSTIN = "Company GSTIN is required.";
    }

    // Payment & Billing (also on Step 1)
    if (!inputValue.modeOfPayment.trim()) {
      newErrors.modeOfPayment = "Mode of Payment is required.";
    }
    if (!inputValue.creditPeriod.trim()) {
      newErrors.creditPeriod = "Credit Period is required.";
    }
    if (!inputValue.noOfDays.trim()) {
      newErrors.noOfDays = "No. of Days is required.";
    }
    if (!inputValue.billingAddress.trim()) {
      newErrors.billingAddress = "Billing Address is required.";
    }
    if (!inputValue.billingCityName.trim()) {
      newErrors.billingCityName = "Billing City Name is required.";
    }
    if (!inputValue.billingState.trim()) {
      newErrors.billingState = "Billing State is required.";
    }

    // Password
    if (!inputValue.password.trim()) {
      newErrors.password = "Password is required.";
    }
    if (!inputValue.confirmPassword.trim()) {
      newErrors.confirmPassword = "Confirm Password is required.";
    } else if (
      inputValue.password &&
      inputValue.confirmPassword &&
      inputValue.password !== inputValue.confirmPassword
    ) {
      newErrors.confirmPassword = "Passwords do not match.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // -------------------------------------
  // Validation for Step 2
  // (Tariff Rates: Price Details, etc.)
  // -------------------------------------
  const validateStep2 = () => {
    let newErrors = {};

    // If you want to require some fields on Step 2, add them here
    // Example:
    // if (!inputValue.location.trim()) {
    //   newErrors.location = "Location is required.";
    // }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // -----------------------
  // Step Handlers
  // -----------------------
  const handleNext = () => {
    if (step === 1) {
      if (validateStep1()) {
        setStep(2);
      } else {
        toast.error("Please fill all required fields in Step 1.");
      }
    }
  };

  const handlePrevious = () => {
    if (step === 2) {
      setStep(1);
    }
  };

  const handleAdd = async (e) => {
    e.preventDefault();
    if (validateStep2()) {
      try {
        // Build final data from both steps
        const data = {
          // Step 1 fields
          companyName: inputValue.companyName,
          travelDeskManagerName: inputValue.travelDeskManagerName,
          workEmailAddress: inputValue.workEmailAddress,
          mobileNo: inputValue.mobileNo,
          altMobileNo: inputValue.altMobileNo,
          companyPinNo: inputValue.companyPinNo,
          companyGSTIN: inputValue.companyGSTIN,
          companyPanNo: inputValue.companyPanNo,
          password: inputValue.password,
          confirmPassword: inputValue.confirmPassword,
          modeOfPayment: inputValue.modeOfPayment,
          creditPeriod: inputValue.creditPeriod,
          noOfDays: inputValue.noOfDays,
          billingAddress: inputValue.billingAddress,
          billingCityName: inputValue.billingCityName,
          billingState: inputValue.billingState,

          // Step 2 fields
          location: inputValue.location,
          carCategory: inputValue.carCategory,
          tripType: inputValue.tripType,
          subTripType: inputValue.subTripType,
          garageDistanceOption: inputValue.garageDistanceOption,
          fuelType: inputValue.fuelType,
          days: inputValue.days,

          baseKm: inputValue.baseKm,
          minHours: inputValue.minHours,
          extraKm: inputValue.extraKm,
          extraHours: inputValue.extraHours,
          gst: inputValue.gst,
          gscFixed: inputValue.gscFixed,

          morningBata: inputValue.morningBata,
          nightBata: inputValue.nightBata,
          dayBata: inputValue.dayBata,
        };

        await axios.post(`${API_BASE_URL}/v1/corporate`, data);
        toast.success("New Corporate added successfully!");
        // Optionally reset or navigate away
        // setInputValue({ ...initialState }); // If you define an initialState
      } catch (error) {
        console.error("Error adding corporate:", error);
        toast.error("Failed to add corporate. Please try again.");
      }
    } else {
      toast.error("Please fill all required fields in Step 2.");
    }
  };

  // -----------------------
  // Change Handler
  // -----------------------
  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputValue({ ...inputValue, [name]: value });
  };

  // ---------------------------------
  // STEP 1 (UNTOUCHED as you requested)
  // ---------------------------------
  const renderStep1 = () => (
    <div className="add-new-corporate-step1">
      {/* Left Column: Company Information */}
      <div className="corporate-info-section">
        {/* Company Name */}
        <div className="corporate-detail">
          <label>Company Name</label>
          <input
            type="text"
            name="companyName"
            placeholder="Enter Company Name"
            value={inputValue.companyName}
            onChange={handleChange}
          />
          {errors.companyName && (
            <p className="error-text">{errors.companyName}</p>
          )}
        </div>

        {/* Travel Desk Manager Name */}
        <div className="corporate-detail">
          <label>Travel Desk Manager Name</label>
          <input
            type="text"
            name="travelDeskManagerName"
            placeholder="Enter Travel Desk Manager Name"
            value={inputValue.travelDeskManagerName}
            onChange={handleChange}
          />
          {errors.travelDeskManagerName && (
            <p className="error-text">{errors.travelDeskManagerName}</p>
          )}
        </div>

        {/* Work Email Address */}
        <div className="corporate-detail">
          <label>Work Email Address</label>
          <input
            type="email"
            name="workEmailAddress"
            placeholder="Enter Work Email"
            value={inputValue.workEmailAddress}
            onChange={handleChange}
          />
          {errors.workEmailAddress && (
            <p className="error-text">{errors.workEmailAddress}</p>
          )}
        </div>

        {/* Mobile No. */}
        <div className="corporate-detail">
          <label>Mobile No.</label>
          <input
            type="text"
            name="mobileNo"
            placeholder="Enter Mobile Number"
            value={inputValue.mobileNo}
            onChange={handleChange}
          />
          {errors.mobileNo && <p className="error-text">{errors.mobileNo}</p>}
        </div>

        {/* Alt Mobile No. */}
        <div className="corporate-detail">
          <label>Alt Mobile No.</label>
          <input
            type="text"
            name="altMobileNo"
            placeholder="Enter Alternate Mobile"
            value={inputValue.altMobileNo}
            onChange={handleChange}
          />
        </div>

        {/* Company Pin No. */}
        <div className="corporate-detail">
          <label>Company Pin No.</label>
          <input
            type="text"
            name="companyPinNo"
            placeholder="Enter Company Pin No."
            value={inputValue.companyPinNo}
            onChange={handleChange}
          />
          {errors.companyPinNo && (
            <p className="error-text">{errors.companyPinNo}</p>
          )}
        </div>

        {/* Company GSTIN */}
        <div className="corporate-detail">
          <label>Company GSTIN</label>
          <input
            type="text"
            name="companyGSTIN"
            placeholder="Enter GSTIN"
            value={inputValue.companyGSTIN}
            onChange={handleChange}
          />
          {errors.companyGSTIN && (
            <p className="error-text">{errors.companyGSTIN}</p>
          )}
        </div>

        {/* (Optional) Company PAN No. */}
        <div className="corporate-detail">
          <label>Company PAN No.</label>
          <input
            type="text"
            name="companyPanNo"
            placeholder="Enter Company PAN No."
            value={inputValue.companyPanNo}
            onChange={handleChange}
          />
          {errors.companyPanNo && (
            <p className="error-text">{errors.companyPanNo}</p>
          )}
        </div>

        {/* Create Password */}
        <div className="corporate-detail">
          <label>Create Password</label>
          <input
            type="password"
            name="password"
            placeholder="Enter Password"
            value={inputValue.password}
            onChange={handleChange}
          />
          {errors.password && <p className="error-text">{errors.password}</p>}
        </div>

        {/* Confirm Password */}
        <div className="corporate-detail">
          <label>Confirm Password</label>
          <input
            type="password"
            name="confirmPassword"
            placeholder="Re-enter Password"
            value={inputValue.confirmPassword}
            onChange={handleChange}
          />
          {errors.confirmPassword && (
            <p className="error-text">{errors.confirmPassword}</p>
          )}
        </div>
      </div>

      {/* Right Column: Payment & Billing */}
      <div className="corporate-info-section-right">
        {/* Mode of Payment */}
        <div className="corporate-detail">
          <label>Mode of Payment</label>
          <select
            name="modeOfPayment"
            value={inputValue.modeOfPayment}
            onChange={handleChange}
          >
            <option value="">Select Option</option>
            <option value="Credit">Credit</option>
            <option value="Cash">Cash</option>
            <option value="Wallet">Wallet</option>
          </select>
          {errors.modeOfPayment && (
            <p className="error-text">{errors.modeOfPayment}</p>
          )}
        </div>

        {/* Credit Period */}
        <div className="corporate-detail">
          <label>Credit Period</label>
          <input
            type="text"
            name="creditPeriod"
            placeholder="No. of Days"
            value={inputValue.creditPeriod}
            onChange={handleChange}
          />
          {errors.creditPeriod && (
            <p className="error-text">{errors.creditPeriod}</p>
          )}
        </div>

        {/* No. of Days */}
        <div className="corporate-detail">
          <label>No. of Days</label>
          <input
            type="text"
            name="noOfDays"
            placeholder="No. of Days"
            value={inputValue.noOfDays}
            onChange={handleChange}
          />
          {errors.noOfDays && <p className="error-text">{errors.noOfDays}</p>}
        </div>

        {/* Billing Address */}
        <div className="corporate-detail">
          <label>Billing Address</label>
          <input
            type="text"
            name="billingAddress"
            placeholder="Type Billing Address"
            value={inputValue.billingAddress}
            onChange={handleChange}
          />
          {errors.billingAddress && (
            <p className="error-text">{errors.billingAddress}</p>
          )}
        </div>

        {/* Billing City Name */}
        <div className="corporate-detail">
          <label>Billing City Name</label>
          <input
            type="text"
            name="billingCityName"
            placeholder="Type Billing City Name"
            value={inputValue.billingCityName}
            onChange={handleChange}
          />
          {errors.billingCityName && (
            <p className="error-text">{errors.billingCityName}</p>
          )}
        </div>

        {/* Billing State */}
        <div className="corporate-detail">
          <label>Billing State</label>
          <input
            type="text"
            name="billingState"
            placeholder="Type State Name"
            value={inputValue.billingState}
            onChange={handleChange}
          />
          {errors.billingState && (
            <p className="error-text">{errors.billingState}</p>
          )}
        </div>
        {/* Next Button */}
        <div className="corporate-button-container">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="128"
            height="36"
            viewBox="0 0 128 36"
            fill="none"
            onClick={handleNext}
            style={{ cursor: "pointer" }}
          >
            <path
              d="M0 9C0 4.02944 4.02944 0 9 0H106.435C108.412 0 110.335 0.651323 111.905 1.85331L123.663 10.8533C128.369 14.4556 128.369 21.5444 123.663 25.1467L111.905 34.1467C110.335 35.3487 108.412 36 106.435 36H9C4.02944 36 0 31.9706 0 27V9Z"
              fill="#38B000"
            />
            <text
              x="40%"
              y="50%"
              dominantBaseline="middle"
              textAnchor="middle"
              fill="#FFF"
              fontFamily="Inter, Arial, sans-serif"
              fontSize="23"
              fontWeight="700"
            >
              Next
            </text>
          </svg>
        </div>
      </div>
    </div>
  );

  // ---------------------------------
  // STEP 2: Tariff Rates ONLY
  // ---------------------------------
  const renderStep2 = () => (
    <div className="add-new-corporate-step2">
      {/* Add Price Details */}
      <div className="corporate-price-details">
        <h3>Add Price Details</h3>
        <div className="price-detail-row">
          <label>Location</label>
          <input
            type="text"
            name="location"
            placeholder="Select City"
            value={inputValue.location}
            onChange={handleChange}
          />
        </div>
        <div className="price-detail-row">
          <label>Car Category</label>
          <input
            type="text"
            name="carCategory"
            placeholder="Select Category"
            value={inputValue.carCategory}
            onChange={handleChange}
          />
        </div>
        <div className="price-detail-row">
          <label>Trip Type</label>
          <input
            type="text"
            name="tripType"
            placeholder="Select Trip Type"
            value={inputValue.tripType}
            onChange={handleChange}
          />
        </div>
        <div className="price-detail-row">
          <label>Sub Trip Type</label>
          <input
            type="text"
            name="subTripType"
            placeholder="Select Sub Trip Type"
            value={inputValue.subTripType}
            onChange={handleChange}
          />
        </div>
        <div className="price-detail-row">
          <label>Garage Distance Option</label>
          <input
            type="text"
            name="garageDistanceOption"
            placeholder="Select Option"
            value={inputValue.garageDistanceOption}
            onChange={handleChange}
          />
        </div>
        <div className="price-detail-row">
          <label>Fuel Type</label>
          <input
            type="text"
            name="fuelType"
            placeholder="Select Option"
            value={inputValue.fuelType}
            onChange={handleChange}
          />
        </div>
        <div className="price-detail-row">
          <label>Days</label>
          <input
            type="text"
            name="days"
            placeholder="No. of days"
            value={inputValue.days}
            onChange={handleChange}
          />
        </div>
      </div>

      {/* Pricing Slab */}
      <div className="corporate-pricing-slab">
        <h3>Pricing Slab</h3>
        <div className="price-detail-row">
          <label>Base KM</label>
          <input
            type="text"
            name="baseKm"
            value={inputValue.baseKm}
            onChange={handleChange}
          />
        </div>
        <div className="price-detail-row">
          <label>Min Hours</label>
          <input
            type="text"
            name="minHours"
            value={inputValue.minHours}
            onChange={handleChange}
          />
        </div>
        <div className="price-detail-row">
          <label>Extra KM</label>
          <input
            type="text"
            name="extraKm"
            value={inputValue.extraKm}
            onChange={handleChange}
          />
        </div>
        <div className="price-detail-row">
          <label>Extra Hours</label>
          <input
            type="text"
            name="extraHours"
            value={inputValue.extraHours}
            onChange={handleChange}
          />
        </div>
        <div className="price-detail-row">
          <label>GST</label>
          <input
            type="text"
            name="gst"
            value={inputValue.gst}
            onChange={handleChange}
          />
        </div>
        <div className="price-detail-row">
          <label>GSC Fixed</label>
          <input
            type="text"
            name="gscFixed"
            value={inputValue.gscFixed}
            onChange={handleChange}
          />
        </div>
      </div>

      {/* Driver Allowance */}
      <div className="corporate-driver-allowance">
        <h3>Driver Allowance</h3>
        <div className="price-detail-row">
          <label>Morning Bata</label>
          <input
            type="text"
            name="morningBata"
            value={inputValue.morningBata}
            onChange={handleChange}
          />
        </div>
        <div className="price-detail-row">
          <label>Night Bata</label>
          <input
            type="text"
            name="nightBata"
            value={inputValue.nightBata}
            onChange={handleChange}
          />
        </div>
        <div className="price-detail-row">
          <label>Day Bata</label>
          <input
            type="text"
            name="dayBata"
            value={inputValue.dayBata}
            onChange={handleChange}
          />
        </div>
      </div>

      {/* Buttons */}
      <div className="corporate-button-container">
        {/* Previous */}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="128"
          height="36"
          viewBox="0 0 128 36"
          fill="none"
          onClick={handlePrevious}
          style={{ cursor: "pointer" }}
        >
          <path
            d="M0 9C0 4.02944 4.02944 0 9 0H106.435C108.412 0 110.335 0.651323 111.905 1.85331L123.663 10.8533C128.369 14.4556 128.369 21.5444 123.663 25.1467L111.905 34.1467C110.335 35.3487 108.412 36 106.435 36H9C4.02944 36 0 31.9706 0 27V9Z"
            fill="#38B000"
            transform="rotate(180,64,18)"
          />
          <text
            x="55%"
            y="50%"
            dominantBaseline="middle"
            textAnchor="middle"
            fill="#FFF"
            fontFamily="Inter, Arial, sans-serif"
            fontSize="23"
            fontWeight="700"
          >
            Previous
          </text>
        </svg>

        {/* Add (Submit) */}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="128"
          height="36"
          viewBox="0 0 128 36"
          fill="none"
          onClick={handleAdd}
          style={{ cursor: "pointer" }}
        >
          <path
            d="M0 9C0 4.02944 4.02944 0 9 0H106.435C108.412 0 110.335 0.651323 111.905 1.85331L123.663 10.8533C128.369 14.4556 128.369 21.5444 123.663 25.1467L111.905 34.1467C110.335 35.3487 108.412 36 106.435 36H9C4.02944 36 0 31.9706 0 27V9Z"
            fill="#38B000"
          />
          <text
            x="40%"
            y="50%"
            dominantBaseline="middle"
            textAnchor="middle"
            fill="#FFF"
            fontFamily="Inter, Arial, sans-serif"
            fontSize="23"
            fontWeight="700"
          >
            Add
          </text>
        </svg>
      </div>
    </div>
  );

  // Main Render
  return (
    <div className="add-new-corporate">
      <Toaster />
      {/* Step Navigation */}
      <div className="step-navigation">
        <div className={`step-item ${step === 1 ? "active-step" : ""}`}>
          Company Information {step > 1 && "✓"}
        </div>
        <div className={`step-item ${step === 2 ? "active-step" : ""}`}>
          Tariff Rates
        </div>
      </div>

      <form>{step === 1 ? renderStep1() : renderStep2()}</form>
    </div>
  );
};

export default AddNewCorporate;
