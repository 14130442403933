import React, { useState, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { ImEye } from "react-icons/im";
import { FaStar } from "react-icons/fa6";
import "./ViewAllCar.css";
import { FaPen } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { fetchDriver, selectAllDriver, selectLoading } from "./DriverSlice";

import {
  currentDriverId,
  resetDriverDetails,
  fetchDriversDetails,
} from "./DriverDetailsSlice";
import { useNavigate } from "react-router-dom";
import BookingHistoryPopUp from "./bookinghistory/BookingHistoryPopUp";
import InputColumn from "../Booking/Input";
import DropdownColumn from "../Booking/Dropdown";
import Filter from "../Booking/Filter";
import { Toggle } from "../TariffManagement/Toggle";
import { toggleDriverStatus } from "./DriverSlice";
import axios from "axios";
import { CiFilter } from "react-icons/ci";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const TableItem = ({ item, index }) => {
  const rowClass = index % 2 === 0 ? "table-row-even" : "table-row-odd";

  return (
    <tr className={rowClass} style={{ height: "10px" }}>
      <td>{item["Sr no."]}</td>
      <td>{item["Location"]}</td>
      <td>{item["Driver Name"]}</td>
      <td>{item["Driver Mobile No."]}</td>
      <td>{item["No. of Ride"]}</td>
      <td>{item["Rating"]}</td>
      <td>{item["Registered On"]}</td>
      <td>{item["Verified On"]}</td>
      <td>{item["Status"]}</td>
      <td>{item["Booking History"]}</td>
      <td>{item["Action"]}</td>
    </tr>
  );
};

const Table = ({ headers, data }) => {
  return (
    <div className="table-containers">
      <div className="table-scroll">
        <table className="table">
          <thead>
            <tr>
              {headers.map((header, index) => (
                <th key={index}>{header}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <TableItem key={index} item={item} index={index} />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const ViewAllCar = () => {
  const [locationOptions, setLocationOptions] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState("");
  const [searchPhoneNumber, setsearchPhoneNumber] = useState("");
  const [searchName, setsearchName] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedApprovel, setselectedApprovel] = useState("");
  const [placeholder, setPlaceholder] = useState("Select a location");

  const dispatch = useDispatch();
  const drivers = useSelector(selectAllDriver);
  const loader = useSelector(selectLoading);
  const { driversId } = useSelector((state) => state.driverInfo);
  const navigate = useNavigate();

  const currentSection = "ViewAllDriver";
  const [showDetails, setShowDetails] = useState(false);
  const [editDetails, setEditDetails] = useState(false);
  const [popUpBookingHistory, setPopUpBookingHistory] = useState(false);
  const [driverIdHistory, setDriverIdHistory] = useState("");

  console.log(loader);
  useEffect(() => {
    dispatch(fetchDriver());
  }, [dispatch]);

  const handleImEyeClick = (id) => {
    console.log("checking driver id", id);
    dispatch(currentDriverId(id));
    setShowDetails(true);
  };

  const handleEditIcon = (id) => {
    dispatch(currentDriverId(id));
    setEditDetails(true);
  };

  const handleShowBookingHistory = async (driverId) => {
    await setDriverIdHistory(driverId);
    setPopUpBookingHistory(true);
  };

  const handleDriverStatus = async (driverId, newStatus, dispatch) => {
    try {
      const response = await axios.put(
        `${API_BASE_URL}/v1/admin/drivers/${driverId}/status`,
        {
          status: newStatus,
        }
      );
      console.log("Response:", response.data);
      dispatch(toggleDriverStatus({ driverId, newStatus }));
      dispatch(fetchDriver());
    } catch (error) {
      console.error("Error toggling driver status:", error);
    }
  };

  const logState = (state, driverId, dispatch) => {
    const newStatus = state === true ? "Inactive" : "Active";
    handleDriverStatus(driverId, newStatus, dispatch);
  };

  console.log("Drivers:", drivers);

  const headers = [
    "Sr no.",
    "Location",
    "Driver Name",
    "Driver Mobile No.",
    "No. of Ride",
    "Rating",
    "Registered On",
    "Verified On",
    "Status",
    "Booking History",
    "Action",
  ];
  // let filteredDrivers = drivers;

  // // Filter based on selectedLocation
  // if (selectedStatus) {
  //   filteredDrivers = drivers.filter(
  //     (driver) => driver.status === selectedStatus
  //   );
  // }

  // // Filter based on selectedLocation
  // if (selectedLocation) {
  //   filteredDrivers = drivers.filter(
  //     (driver) => driver.location === selectedLocation
  //   );
  // }

  // // Filter based on searchName
  // if (searchName) {
  //   filteredDrivers = filteredDrivers.filter((driver) =>
  //     driver.name.toLowerCase().includes(searchName.toLowerCase())
  //   );
  // }

  // // Filter based on searchPhoneNumber
  // if (searchPhoneNumber) {
  //   filteredDrivers = filteredDrivers.filter((driver) =>
  //     driver.phone.includes(searchPhoneNumber)
  //   );
  // }

  const filteredDrivers = drivers.filter((driver) => {
    const selectedRating = parseInt(selectedApprovel, 10);
    return (
      (!selectedStatus || driver.status === selectedStatus) &&
      (!selectedLocation || driver.location === selectedLocation) &&
      (!searchName ||
        driver.name.toLowerCase().includes(searchName.toLowerCase())) &&
      (!searchPhoneNumber || driver.phone.includes(searchPhoneNumber)) &&
      (!selectedRating || driver.rating === selectedRating)
    );
  });

  const data = [];

  for (let i = 0; i < filteredDrivers.length; i++) {
    const driver = filteredDrivers[i];
    const NoRating = () => <span>No rating available</span>;

    const StarRating = ({ rating }) => {
      const starSvgs = {
        1: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100"
            height="16"
            viewBox="0 0 100 16"
            fill="none"
          >
            <path
              d="M10 0.21875L12.2451 5.59306H19.5106L13.6327 8.91457L15.8779 14.2889L10 10.9674L4.12215 14.2889L6.36729 8.91457L0.489435 5.59306H7.75486L10 0.21875Z"
              fill="#FF0000"
            />
            <path
              d="M30 0.21875L32.2451 5.59306H39.5106L33.6327 8.91457L35.8779 14.2889L30 10.9674L24.1221 14.2889L26.3673 8.91457L20.4894 5.59306H27.7549L30 0.21875Z"
              fill="#8B8B8B"
            />
            <path
              d="M50 0.21875L52.2451 5.59306H59.5106L53.6327 8.91457L55.8779 14.2889L50 10.9674L44.1221 14.2889L46.3673 8.91457L40.4894 5.59306H47.7549L50 0.21875Z"
              fill="#8B8B8B"
            />
            <path
              d="M70 0.21875L72.2451 5.59306H79.5106L73.6327 8.91457L75.8779 14.2889L70 10.9674L64.1221 14.2889L66.3673 8.91457L60.4894 5.59306H67.7549L70 0.21875Z"
              fill="#8B8B8B"
            />
            <path
              d="M90 0.21875L92.2451 5.59306H99.5106L93.6327 8.91457L95.8779 14.2889L90 10.9674L84.1221 14.2889L86.3673 8.91457L80.4894 5.59306H87.7549L90 0.21875Z"
              fill="#8B8B8B"
            />
          </svg>
        ),
        2: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100"
            height="17"
            viewBox="0 0 100 17"
            fill="none"
          >
            <path
              d="M10 0.664062L12.2451 6.03837H19.5106L13.6327 9.35988L15.8779 14.7342L10 11.4127L4.12215 14.7342L6.36729 9.35988L0.489435 6.03837H7.75486L10 0.664062Z"
              fill="#FF8C00"
            />
            <path
              d="M30 0.664062L32.2451 6.03837H39.5106L33.6327 9.35988L35.8779 14.7342L30 11.4127L24.1221 14.7342L26.3673 9.35988L20.4894 6.03837H27.7549L30 0.664062Z"
              fill="#FF8C00"
            />
            <path
              d="M50 0.664062L52.2451 6.03837H59.5106L53.6327 9.35988L55.8779 14.7342L50 11.4127L44.1221 14.7342L46.3673 9.35988L40.4894 6.03837H47.7549L50 0.664062Z"
              fill="#8B8B8B"
            />
            <path
              d="M70 0.664062L72.2451 6.03837H79.5106L73.6327 9.35988L75.8779 14.7342L70 11.4127L64.1221 14.7342L66.3673 9.35988L60.4894 6.03837H67.7549L70 0.664062Z"
              fill="#8B8B8B"
            />
            <path
              d="M90 0.664062L92.2451 6.03837H99.5106L93.6327 9.35988L95.8779 14.7342L90 11.4127L84.1221 14.7342L86.3673 9.35988L80.4894 6.03837H87.7549L90 0.664062Z"
              fill="#8B8B8B"
            />
          </svg>
        ),
        3: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100"
            height="16"
            viewBox="0 0 100 16"
            fill="none"
          >
            <path
              d="M10 0.109375L12.2451 5.48369H19.5106L13.6327 8.80519L15.8779 14.1795L10 10.858L4.12215 14.1795L6.36729 8.80519L0.489435 5.48369H7.75486L10 0.109375Z"
              fill="#E3D96F"
            />
            <path
              d="M30 0.109375L32.2451 5.48369H39.5106L33.6327 8.80519L35.8779 14.1795L30 10.858L24.1221 14.1795L26.3673 8.80519L20.4894 5.48369H27.7549L30 0.109375Z"
              fill="#E3D96F"
            />
            <path
              d="M50 0.109375L52.2451 5.48369H59.5106L53.6327 8.80519L55.8779 14.1795L50 10.858L44.1221 14.1795L46.3673 8.80519L40.4894 5.48369H47.7549L50 0.109375Z"
              fill="#E3D96F"
            />
            <path
              d="M70 0.109375L72.2451 5.48369H79.5106L73.6327 8.80519L75.8779 14.1795L70 10.858L64.1221 14.1795L66.3673 8.80519L60.4894 5.48369H67.7549L70 0.109375Z"
              fill="#8B8B8B"
            />
            <path
              d="M90 0.109375L92.2451 5.48369H99.5106L93.6327 8.80519L95.8779 14.1795L90 10.858L84.1221 14.1795L86.3673 8.80519L80.4894 5.48369H87.7549L90 0.109375Z"
              fill="#8B8B8B"
            />
          </svg>
        ),
        4: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100"
            height="17"
            viewBox="0 0 100 17"
            fill="none"
          >
            <path
              d="M10 0.554688L12.2451 5.929H19.5106L13.6327 9.25051L15.8779 14.6248L10 11.3033L4.12215 14.6248L6.36729 9.25051L0.489435 5.929H7.75486L10 0.554688Z"
              fill="#CFD72A"
            />
            <path
              d="M30 0.554688L32.2451 5.929H39.5106L33.6327 9.25051L35.8779 14.6248L30 11.3033L24.1221 14.6248L26.3673 9.25051L20.4894 5.929H27.7549L30 0.554688Z"
              fill="#CFD72A"
            />
            <path
              d="M50 0.554688L52.2451 5.929H59.5106L53.6327 9.25051L55.8779 14.6248L50 11.3033L44.1221 14.6248L46.3673 9.25051L40.4894 5.929H47.7549L50 0.554688Z"
              fill="#CFD72A"
            />
            <path
              d="M70 0.554688L72.2451 5.929H79.5106L73.6327 9.25051L75.8779 14.6248L70 11.3033L64.1221 14.6248L66.3673 9.25051L60.4894 5.929H67.7549L70 0.554688Z"
              fill="#CFD72A"
            />
            <path
              d="M90 0.554688L92.2451 5.929H99.5106L93.6327 9.25051L95.8779 14.6248L90 11.3033L84.1221 14.6248L86.3673 9.25051L80.4894 5.929H87.7549L90 0.554688Z"
              fill="#8B8B8B"
            />
          </svg>
        ),
        5: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100"
            height="16"
            viewBox="0 0 100 16"
            fill="none"
          >
            <path
              d="M10 0L12.2451 5.37431H19.5106L13.6327 8.69582L15.8779 14.0701L10 10.7486L4.12215 14.0701L6.36729 8.69582L0.489435 5.37431H7.75486L10 0Z"
              fill="#38B000"
            />
            <path
              d="M30 0L32.2451 5.37431H39.5106L33.6327 8.69582L35.8779 14.0701L30 10.7486L24.1221 14.0701L26.3673 8.69582L20.4894 5.37431H27.7549L30 0Z"
              fill="#38B000"
            />
            <path
              d="M50 0L52.2451 5.37431H59.5106L53.6327 8.69582L55.8779 14.0701L50 10.7486L44.1221 14.0701L46.3673 8.69582L40.4894 5.37431H47.7549L50 0Z"
              fill="#38B000"
            />
            <path
              d="M70 0L72.2451 5.37431H79.5106L73.6327 8.69582L75.8779 14.0701L70 10.7486L64.1221 14.0701L66.3673 8.69582L60.4894 5.37431H67.7549L70 0Z"
              fill="#38B000"
            />
            <path
              d="M90 0L92.2451 5.37431H99.5106L93.6327 8.69582L95.8779 14.0701L90 10.7486L84.1221 14.0701L86.3673 8.69582L80.4894 5.37431H87.7549L90 0Z"
              fill="#38B000"
            />
          </svg>
        ),
      };

      return rating ? starSvgs[rating] || NoRating() : NoRating();
    };

    data.push({
      "Sr no.": i + 1,
      Location: driver.location,
      "Driver Name": driver.name,
      // "Driver Mobile No.": driver.phone.startsWith("+91-")
      //   ? driver.phone
      //   : `+91- ${driver.phone}`,
      "Driver Mobile No.": driver.phone,
      "No. of Ride": driver.noOfRides,
      Rating: <StarRating rating={driver?.rating} />,
      "Registered On": driver?.createdAt,
      "Verified On": driver?.approval?.verifiedOn || "Not Verified",
      // "Verified On": "22-10-2023", // "verifiedOn" is not present in the data
      Status: driver.status,
      "Booking History": (
        <button
          className="button-show"
          onClick={() => handleShowBookingHistory(driver._id)}
        >
          Show
        </button>
      ),
      Action: (
        <div className="box-action-all-drivers">
          <Toggle
            toggled={driver.status === "Active" ? false : true}
            onClick={(state) => logState(state, driver._id, dispatch)}
          />
          <ImEye
            style={{
              marginLeft: "-2px",
              marginRight: "-5px",
              marginTop: "-10px",
            }}
            className="icon icon-large"
            onClick={() => handleImEyeClick(driver._id)}
          />
          <FaPen
            style={{ marginTop: "-10px" }}
            className="icon-edit "
            onClick={() => handleEditIcon(driver._id)}
          />
        </div>
      ),
    });
  }

  const DriverList = data;

  useEffect(() => {
    const extractlocationAsOptions = (dataArray) => {
      if (Array.isArray(dataArray.data)) {
        const allLocationsOption = { label: "Select a location", value: "" };
        const locationOptions = dataArray.data.map((item) => ({
          label: item.location,
          value: item._id,
        }));
        return [allLocationsOption, ...locationOptions];
      }
      return [];
    };

    const fetchOptions = async () => {
      try {
        const locationRespons = await fetch(
          `${API_BASE_URL}/v1/locations`
        );
        if (!locationRespons.ok) {
          throw new Error(`HTTP error! Status: ${locationRespons.status}`);
        }
        const locationData = await locationRespons.json();
        const locationOptions = extractlocationAsOptions(locationData);
        console.log(locationOptions);
        setLocationOptions(locationOptions);
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };
    fetchOptions();
  }, []);

  useEffect(() => {
    if (driversId !== null && showDetails) {
      dispatch(resetDriverDetails());
      dispatch(fetchDriversDetails(driversId));
      navigate(
        `/drivermanagement/${currentSection}/DriverDetails/${driversId}`
      );
    }
    setShowDetails(false);
    console.log("checking drivres id in effect", driversId);
  }, [showDetails]);

  useEffect(() => {
    if (driversId !== null && editDetails) {
      dispatch(resetDriverDetails());
      navigate(
        `/drivermanagement/${currentSection}/modify-details/${driversId}`
      );
    }
    setShowDetails(false);
    console.log("checking drivres id in effect", driversId);
  }, [editDetails]);

  return (
    <div>
      <BookingHistoryPopUp
        popUpBookingHistory={popUpBookingHistory}
        setPopUpBookingHistory={setPopUpBookingHistory}
        driverId={driverIdHistory}
      />
      <>
        <div className="dropdown-container">
          <div className="main-1">
            <div>Filters</div>
            <div className="icon-1">
              <CiFilter color="#38b000" size={18} />
            </div>
          </div>
          <select
            value={selectedLocation}
            onChange={(e) => {
              const selectedCity = e.target.value;
              setSelectedLocation(
                selectedCity === "Select a location" ? "" : selectedCity
              );
              setPlaceholder(selectedCity);
            }}
            className="dropdown-view"
          >
            {locationOptions.map((option) => (
              <option key={option.value} value={option.label}>
                {option.label}
              </option>
            ))}
          </select>

          <InputColumn
            title="Search by Name"
            selectedValue={searchName}
            handleChange={(e) => setsearchName(e.target.value)}
          />
          <InputColumn
            title="Search by Mobile No."
            selectedValue={searchPhoneNumber}
            handleChange={(e) => setsearchPhoneNumber(e.target.value)}
          />
          <select
            value={selectedStatus}
            onChange={(e) => setSelectedStatus(e.target.value)}
            className="dropdown-view"
          >
            <option value="">Status</option>
            <option value="Active">Active</option>
            <option value="Inactive">Inactive</option>
          </select>

          <select
            value={selectedApprovel}
            onChange={(e) => setselectedApprovel(e.target.value)}
            className="dropdown-view"
          >
            <option value="">Rating</option>
            <option value="1">1 Star</option>
            <option value="2">2 Stars</option>
            <option value="3">3 Stars</option>
            <option value="4">4 Stars</option>
            <option value="5">5 Stars</option>
          </select>
        </div>

        <Table
          headers={headers}
          data={DriverList.map((item) => ({
            ...item,
          }))}
        />
      </>
      {/*})*/}
    </div>
  );
};

export default ViewAllCar;
