import axios from "axios";
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;


export function fetchbookingDetails(id) {
  return new Promise(async (resolve) => {
    try {
      console.log(id);
      const response = await axios.get(
       `${API_BASE_URL}/v1/admin/bookings/${id}`
      );

      resolve(response.data.data);
    } catch (error) {
      console.log("error in fetching api", error);
      
    }
  });
}

export function fetchbillingDetails(id) {
  console.log(id);
  return new Promise(async (resolve,reject) => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}/v1/admin/bookings/bookingbill/${id}`
      );
      resolve(response.data.data);
    } catch (error) {
      console.log("Error in fetching API:", error);
      reject(error);
    }
  });
}

export function fetchInteractionDetails(id) {
  return new Promise(async (resolve) => {
    try {
      console.log(id);
      const response = await axios.get(
        `${API_BASE_URL}/v1/admin/bookings/interactions/${id}`
      );
      // console.log(response.data.data);
      resolve(response.data.data);
    } catch (error) {
      console.log("error in fetching api", error);
    }
  });
}

export function deleteBooking(bookingId) {
  return new Promise(async (resolve) => {
    try {
      console.log(bookingId);
      const response = await axios.delete(
        `${API_BASE_URL}/v1/admin/bookings/${bookingId}`
      );
      // console.log(response.data.data);
      resolve(response.data.data);
    } catch (error) {
      console.log("error in fetching api", error);
    }
  });
}
