import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ca } from "date-fns/locale";
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const initialState = {
  customerId: null,
  customerDetails: null,
  currentComp: null,
  loading: "idle",
  errorMessage: null,
};

export const fetchCustomerDetails = createAsyncThunk(
  "customer/fetchCustomerDetails",
  async (customerId) => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}/v1/admin/users/${customerId}`
      );
      // console.log("checking customer details", response.data.data);
      return response.data.data;
    } catch (error) {
      if (error.response) {
        console.log(
          "Server responded with status code:",
          error.response.status
        );
        console.log("Response data:", error.response.data);
      } else {
        console.log("Error creating request:", error.message);
      }
    }
  }
);

const customerDetailsSlice = createSlice({
  name: "customerDetails",
  initialState,

  reducers: {
    currentCustomerId: (state, action) => {
      state.customerId = action.payload;
    },
    resetCustomerDetails: (state) => {
      state.customerDetails = null;
    },
    resetCompState: (state, action) => {
      state.currentComp = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCustomerDetails.pending, (state) => {
        state.loading = "Loading";
      })
      .addCase(fetchCustomerDetails.fulfilled, (state, action) => {
        state.loading = "Idle";
        state.customerDetails = action.payload;
      })
      .addCase(fetchCustomerDetails.rejected, (state, action) => {
        state.loading = "Rejected";
        state.errorMessage = action.error.message;
      });
  },
});

export const { currentCustomerId, resetCustomerDetails, resetCompState } =
  customerDetailsSlice.actions;

export default customerDetailsSlice.reducer;
