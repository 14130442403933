import React from "react";
import "./AdminInfo.css";
import { Link } from "react-router-dom";
import { AiOutlineRight } from "react-icons/ai";
import bell from "./../../assets/Alarm.png";
import { useSelector } from "react-redux";
import { selectAllBooking } from "./../Booking/BookingSlice";
import { selectAllDriver } from "./../DriverManagement/DriverSlice";
import { selectViewAllCity } from "./../Geography/geographySlice";
import { selectAllCustomer } from "../Customer/CustomerSlice";

export const Card = ({ number, data, to }) => {
  return (
    <Link to={to} className="card">
      <p className="number"> {number} </p>
      <p className="data"> {data} </p>
      <button>
        {" "}
        See Now <AiOutlineRight fontSize={"8px"} />{" "}
      </button>
    </Link>
  );
};

const AdminInfo = () => {
  const date = new Date();
  const bookingId = 123456;
  const addLeadingZero = (number) => (number < 10 ? `0${number}` : number);
  const lastLoginTimestamp = Date.now();
  const lastLoginDate = new Date(lastLoginTimestamp);

  const bookings = useSelector(selectAllBooking) || [];
  const drivers = useSelector(selectAllDriver) || [];
  const city = useSelector(selectViewAllCity) || [];
  const car = useSelector((state) => state.car.cardata) || [];
  const customer = useSelector(selectAllCustomer) || [];
  const user = useSelector((state) => state.auth.user) || {};

  console.log("bookings", bookings);
  return (
    <div className="main-container">
      <div className="main-grid">
        <div className="main">
          <Card
            number={bookings.length > 0 ? bookings.length : 0}
            data="Total Booking"
            to="/bookings"
          />
          <Card
            number={customer.length > 0 ? customer.length : 0}
            data="Total Customer"
            to="/customer"
          />
          <Card number="369" data="Total Vehicle" to="/carmanagement" />
          <Card
            number={city.length > 0 ? city.length : 0}
            data="Total Cities"
            to="/geography"
          />
          <Card
            number={drivers.length > 0 ? drivers.length : 0}
            data="Total Driver"
            to="/drivermanagement"
          />
          <Card
            number={car.length > 0 ? car.length : 0}
            data="Total Cars"
            to="/carmanagement"
          />
          <Card number="369" data="Total Booking" to="/bookings" />
          <Card number="369" data="Payments" to="/dashboard" />
          <Card number="369" data="Vehicle Details" to="/dashboard" />
          <Card number="369" data="New Pick Request" to="/dashboard" />
          <Card number="369" data="Total Driver" to="/dashboard" />
          <Card number="369" data="Driver's Details" to="/dashboard" />
        </div>
      </div>
      <div className="client-info">
        <div className="row-info">
          <p className="info">
            Organisation : <span> Ride Evee </span>
          </p>
          <p className="info">
            Code : <span>10001</span>
          </p>
        </div>

        <div className="row-info">
          <p className="info">
            {" "}
            User Name :{" "}
            <span>
              {" "}
              {user.firstName} {user.lastName}
            </span>{" "}
          </p>
          <p className="info">
            {" "}
            User Type : <span>{user.role}</span>{" "}
          </p>
        </div>

        <div className="row-info">
          <p className="info">
            Designation : <span> Developer </span>
          </p>
          <p className="info">
            Mobile No. : <span> {user.phone}</span>
          </p>
        </div>

        <div className="row-info">
          <p className="info">
            {" "}
            E-Mail ID : <span> {user.email} </span>{" "}
          </p>
          <p className="info">
            {" "}
            Last Log in : <span> {user.createdAt.toLocaleString()}</span>{" "}
          </p>
        </div>
      </div>

      <div className="bell-activity">
        <img src={bell} />
        <p className="bell-activities"> Activities </p>
      </div>
      <div className="line"></div>

      <div className="admin-split-view">
        <div
          className="left-column"
          style={{ overflowX: "auto", height: "350px" }}
        >
          {bookings.slice(0, 10).map((booking, index) => (
            <div
              key={booking.bookingId}
              className="box-with-details"
              style={{ display: "inline-block", margin: "0 5px" }}
            >
              <div
                className="bell-activity-details"
                style={{ display: "flex" }}
              >
                <div className="bell-activity-dark">
                  <img src={bell} alt="Bell Icon" />
                </div>
                <div className="bell-booking-details">
                  <p>
                    Booking ID: {booking.bookingId}
                    {"  "}
                  </p>
                  <p>
                    {" "}
                    Created from: {booking.source} on {booking.bookingDate}{" "}
                  </p>
                  <p>
                    {" "}
                    Booking Type: {booking.tripType} {booking.location}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="right-column">
          <div className="empty-box"></div>
        </div>
      </div>
    </div>
  );
};

export default AdminInfo;
