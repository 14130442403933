// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pickup-address input {
    color: #868686;
    font-size: 10px;
    font-style: normal;
    line-height: normal;
    font-weight: 500;
    width: 195px;
    height: 31px;
    flex-shrink: 0;
    border-radius: 6px;
    background: #fff;
    box-shadow: 2px 2px 7px 0 rgb(0, 0, 0, 0.6);
    border: none;
    cursor: pointer;
    transform: translateY(-68px);
    position: relative;
    right: 8px;
}

.to-the-airport-picadd{
    position: relative;
    right: -165px;
    transform: translateY(-87px);
}`, "",{"version":3,"sources":["webpack://./src/utils/searchLocation/searchLocation.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,eAAe;IACf,kBAAkB;IAClB,mBAAmB;IACnB,gBAAgB;IAChB,YAAY;IACZ,YAAY;IACZ,cAAc;IACd,kBAAkB;IAClB,gBAAgB;IAChB,2CAA2C;IAC3C,YAAY;IACZ,eAAe;IACf,4BAA4B;IAC5B,kBAAkB;IAClB,UAAU;AACd;;AAEA;IACI,kBAAkB;IAClB,aAAa;IACb,4BAA4B;AAChC","sourcesContent":[".pickup-address input {\n    color: #868686;\n    font-size: 10px;\n    font-style: normal;\n    line-height: normal;\n    font-weight: 500;\n    width: 195px;\n    height: 31px;\n    flex-shrink: 0;\n    border-radius: 6px;\n    background: #fff;\n    box-shadow: 2px 2px 7px 0 rgb(0, 0, 0, 0.6);\n    border: none;\n    cursor: pointer;\n    transform: translateY(-68px);\n    position: relative;\n    right: 8px;\n}\n\n.to-the-airport-picadd{\n    position: relative;\n    right: -165px;\n    transform: translateY(-87px);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
