// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-banner-div{
    background-color: #38B000;
    padding: 5px 22px;
    border-radius: 5px;
    color: white;
    font-size: 20px;
    font-weight: 700;

}
.image-div{
width: 291px;
height: 168px;
background-color:#ddd ;
border: 1px solid black;
display: flex;
position: relative;
align-items: center;
justify-content: center;
}

.cross-icon{
 position: absolute;
 top: 5px;
 right: 5px;
 color: red;
 font-size: 25px;
 font-weight: bold;
 cursor: pointer;
}
.choose-file-div{
    background-color: white;
    display: flex;
    align-items: center;
    gap: 6px;
    position: absolute;
    bottom: 5px;
    transform: translateX(8px);
    border: 1px solid black;
    width: 275px;
    height: 35px;
    justify-content: center;
}

.choose-file-label{
background-color:   #D9D9D9;
color: black;
padding: 6px 10px;
cursor: pointer;
font-size: 11px;
border: 1px solid black;
margin-bottom: 12px;
font-weight: 700;
}

.banner-img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
`, "",{"version":3,"sources":["webpack://./src/components/AppManagement/App.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,iBAAiB;IACjB,kBAAkB;IAClB,YAAY;IACZ,eAAe;IACf,gBAAgB;;AAEpB;AACA;AACA,YAAY;AACZ,aAAa;AACb,sBAAsB;AACtB,uBAAuB;AACvB,aAAa;AACb,kBAAkB;AAClB,mBAAmB;AACnB,uBAAuB;AACvB;;AAEA;CACC,kBAAkB;CAClB,QAAQ;CACR,UAAU;CACV,UAAU;CACV,eAAe;CACf,iBAAiB;CACjB,eAAe;AAChB;AACA;IACI,uBAAuB;IACvB,aAAa;IACb,mBAAmB;IACnB,QAAQ;IACR,kBAAkB;IAClB,WAAW;IACX,0BAA0B;IAC1B,uBAAuB;IACvB,YAAY;IACZ,YAAY;IACZ,uBAAuB;AAC3B;;AAEA;AACA,2BAA2B;AAC3B,YAAY;AACZ,iBAAiB;AACjB,eAAe;AACf,eAAe;AACf,uBAAuB;AACvB,mBAAmB;AACnB,gBAAgB;AAChB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,iBAAiB;AACrB","sourcesContent":[".user-banner-div{\n    background-color: #38B000;\n    padding: 5px 22px;\n    border-radius: 5px;\n    color: white;\n    font-size: 20px;\n    font-weight: 700;\n\n}\n.image-div{\nwidth: 291px;\nheight: 168px;\nbackground-color:#ddd ;\nborder: 1px solid black;\ndisplay: flex;\nposition: relative;\nalign-items: center;\njustify-content: center;\n}\n\n.cross-icon{\n position: absolute;\n top: 5px;\n right: 5px;\n color: red;\n font-size: 25px;\n font-weight: bold;\n cursor: pointer;\n}\n.choose-file-div{\n    background-color: white;\n    display: flex;\n    align-items: center;\n    gap: 6px;\n    position: absolute;\n    bottom: 5px;\n    transform: translateX(8px);\n    border: 1px solid black;\n    width: 275px;\n    height: 35px;\n    justify-content: center;\n}\n\n.choose-file-label{\nbackground-color:   #D9D9D9;\ncolor: black;\npadding: 6px 10px;\ncursor: pointer;\nfont-size: 11px;\nborder: 1px solid black;\nmargin-bottom: 12px;\nfont-weight: 700;\n}\n\n.banner-img{\n    width: 100%;\n    height: 100%;\n    object-fit: cover;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
