// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.route-log-table {
    width: 95%;
    border-collapse: collapse;
}

.route-log-table td {
    border: 1px solid #000;
    /* padding: 10px; */
    text-align: left;
    border-radius: none;
    text-align: center;
    white-space: nowrap;
    padding-bottom: 30px;
}

.route-log-table th {
    background: #38b000;
    color: #fff;
    border: 1px solid #000;
    /* padding: 10px; */
    text-align: left;
    border-radius: 0;
    text-align: center;

}

.route-log-table tbody tr {
    height: 60px;
}


.map-box {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.map {
    width: 430px;
    height: 514px;
    flex-shrink: 0;
}

.main-box-route {
    height: 780px;
    flex-shrink: 0;
    border-radius: 10px;
    background: #fff;
    margin-top: 10px;
    padding: 10px;
}

.top-extra-space {
    height: 4vh;
    background: #fff;
}`, "",{"version":3,"sources":["webpack://./src/components/Details/RouteLog.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,yBAAyB;AAC7B;;AAEA;IACI,sBAAsB;IACtB,mBAAmB;IACnB,gBAAgB;IAChB,mBAAmB;IACnB,kBAAkB;IAClB,mBAAmB;IACnB,oBAAoB;AACxB;;AAEA;IACI,mBAAmB;IACnB,WAAW;IACX,sBAAsB;IACtB,mBAAmB;IACnB,gBAAgB;IAChB,gBAAgB;IAChB,kBAAkB;;AAEtB;;AAEA;IACI,YAAY;AAChB;;;AAGA;IACI,WAAW;IACX,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,cAAc;IACd,mBAAmB;IACnB,gBAAgB;IAChB,gBAAgB;IAChB,aAAa;AACjB;;AAEA;IACI,WAAW;IACX,gBAAgB;AACpB","sourcesContent":[".route-log-table {\n    width: 95%;\n    border-collapse: collapse;\n}\n\n.route-log-table td {\n    border: 1px solid #000;\n    /* padding: 10px; */\n    text-align: left;\n    border-radius: none;\n    text-align: center;\n    white-space: nowrap;\n    padding-bottom: 30px;\n}\n\n.route-log-table th {\n    background: #38b000;\n    color: #fff;\n    border: 1px solid #000;\n    /* padding: 10px; */\n    text-align: left;\n    border-radius: 0;\n    text-align: center;\n\n}\n\n.route-log-table tbody tr {\n    height: 60px;\n}\n\n\n.map-box {\n    width: 100%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n\n.map {\n    width: 430px;\n    height: 514px;\n    flex-shrink: 0;\n}\n\n.main-box-route {\n    height: 780px;\n    flex-shrink: 0;\n    border-radius: 10px;\n    background: #fff;\n    margin-top: 10px;\n    padding: 10px;\n}\n\n.top-extra-space {\n    height: 4vh;\n    background: #fff;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
