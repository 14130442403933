import axios from "axios";
import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import toast, { Toaster } from "react-hot-toast";
import useSuggestionsCity from "../../hooks/useSuggestionsIndianCities";
import { generateTimeOptions } from "../../utils/time/time";
import DropdownColumn from "./Dropdown";
// import AddIcon from "../../../src/assets/delete.png";
import "./List.css";
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const Roundtripoutstation = (props) => {
  const {
    pickUpLocation,
    setPickUpLocation,
    selectedLocation,
    setSelectedLocation,
    selectDropAddress,
    setSelectDropAddress,
    pickUpDate,
    setPickUpDate,
    dropDate,
    setDropDate,
    pickUpTime,
    setPickUpTime,
    cabType,
    setCabType,
    selectedCabType,
    setSelectedCabType,
    setSelectTemp,
    setSelectedTariffId,
    setCarCategoriesDetails,
  } = props;

  const [citySuggestions] = useSuggestionsCity();
  const [additionalCities, setAdditionalCities] = useState([""]);
  // const [showAddDestinationInput, setShowAddDestinationInput] = useState(false);
  const [search, setSearch] = useState("");
  const [listVisible, setListVisible] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [showDate, setShowDate] = useState(false);

  const handleDateClick = () => {
    setShowDate(true);
    console.log("data get clicked ", showDropdown);
  };

  const outsideClickOfDate = () => {
    setShowDate(false);
    console.log("oustsidegetclicked", showDropdown);
  };

  useEffect(() => {
    console.log("showDate changed to:", showDate);
  }, [showDate]);
  const suggestionhide = () => {
    setShowDropdown(false);
    console.log("mouseleave dropdwon", showDropdown);
  };

  const suggtionshows = () => {
    setShowDropdown(true);

    console.log("mouseover dropdown ", showDropdown);
  };

  const handleAddDestination = () => {
    if (additionalCities.length < 5) {
      setAdditionalCities([...additionalCities, ""]);
      // setShowAddDestinationInput(true);
    }
  };

  const handleCityChange = (index, value) => {
    const updatedCities = [...additionalCities];
    updatedCities[index] = value;
    setAdditionalCities(updatedCities);
  };

  const handleRemoveCity = (index) => {
    const updatedCities = [...additionalCities];
    updatedCities.splice(index, 1);
    setAdditionalCities(updatedCities);
  };

  useEffect(() => {
    const allCities = additionalCities.join(", ");
    setSelectDropAddress(allCities);
  }, [additionalCities, setSelectDropAddress]);

  useEffect(() => {
    const extractLocationsAsOptions = (dataArray) => {
      if (Array.isArray(dataArray.data)) {
        return dataArray.data.map((item) => ({
          label: item.location,
          value: item.location,
        }));
      }
      return [];
    };

    const fetchData = async () => {
      try {
        const locationResponse = await fetch(
          `${API_BASE_URL}/v1/locations`
        );
        const locationData = await locationResponse.json();
        const pickUpLocation = extractLocationsAsOptions(locationData);
        setPickUpLocation(pickUpLocation);
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };
    fetchData();
  }, [setPickUpLocation]);

  const formatDate = (pickUpDate) => {
    if (!(pickUpDate instanceof Date) || isNaN(pickUpDate)) {
      return "";
    }
    return format(pickUpDate, "dd-MM-yyyy");
  };

  const extractCarCategoriesAsOptions = (dataArray) => {
    if (Array.isArray(dataArray.data)) {
      return dataArray.data.map((item) => ({
        label: item.carCategory.category,
        value: item.carCategory.category,
      }));
    }
    return [];
  };

  const handleChange = async (e) => {
    const time = e.target.value;
    setPickUpTime(time);
    const formatedPickup = formatDate(pickUpDate);
    const formatedDrop = formatDate(dropDate);
    const subTripType = "Round Trip";
    console.log(
      selectedLocation,
      selectDropAddress,
      formatedPickup,
      formatedDrop,
      time,
      subTripType
    );

    try {
      const response = await axios.get(
       `${API_BASE_URL}/v1/users/select-cars/outstation?source=${selectedLocation}&destination=${selectDropAddress}&time=${time}&startDate=${formatedPickup}&endDate=${formatedDrop}&subTripType=${subTripType}`
      );
      // console.log(response.data.data);
      const carCategoryOptions = extractCarCategoriesAsOptions(response.data);
      setCabType(carCategoryOptions);

      // console.log(carCategoryOptions);
      const temp = response.data.tempDataId;
      // console.log("Temp Id", temp);
      setSelectTemp(temp);
    } catch (error) {
      toast.error(error.response.data.message);
      console.error("Error fetching cars:", error);
      setCabType([]);
    }
  };

  const handleChangeCabType = async (e) => {
    const cabType = e.target.value;
    setSelectedCabType(cabType);
    const formatedPickup = formatDate(pickUpDate);
    const formatedDrop = formatDate(dropDate);
    const subTripType = "Round Trip";
    try {
      const response = await axios.get(
        `${API_BASE_URL}/v1/users/select-cars/outstation?source=${selectedLocation}&destination=${selectDropAddress}&time=${pickUpTime}&startDate=${formatedPickup}&endDate=${formatedDrop}&subTripType=${subTripType}`
      );
      // console.log(response.data.data);

      const carCategories = response.data.data.filter(
        (item) => item.carCategory.category === cabType
      );

      console.log(carCategories);
      setCarCategoriesDetails(carCategories);
      carCategories.forEach((item) => {
        setSelectedTariffId(item._id);
      });
    } catch (error) {
      console.error("Error fetching cars:", error);
    }
  };

  const filteredLocations =
    search.length === 0
      ? pickUpLocation
      : pickUpLocation.filter((city) =>
          city.label.toLowerCase().includes(search.toLowerCase())
        );

  const handleCityClick = (city) => {
    setSelectedLocation(city.label);
    setSearch(city.label);
    setListVisible(false);
  };
  const clearalldata = (e) => {
    if (e.target.value === "") {
      setSelectedLocation("");
      setPickUpTime("");
      setPickUpDate("");
      setAdditionalCities("");
    }
  };

  return (
    <>
      <div onMouseLeave={suggestionhide}>
        <Toaster />
        <div className="row lo-ex-pic-loca-row">
          <div className="user-booking" onClick={suggtionshows}>
            <label className="title-name">Pick Up Location</label>
            <div className="out-roundt-loc-dropdown">
              <input
                placeholder="Enter pick up location"
                type="text"
                className="custom-input"
                name="pickUpLocation"
                autoComplete="off"
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                  setListVisible(true);
                  clearalldata(e);
                }}
              />
            </div>
            <div className="out-roundt-location">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="15"
                height="17"
                viewBox="0 0 15 17"
                fill="none"
              >
                <g filter="url(#filter0_d_633_1237)">
                  <path
                    d="M7.93203 8.79777C8.86719 7.66632 11 4.92407 11 3.38376C11 1.51564 9.43229 0 7.5 0C5.56771 0 4 1.51564 4 3.38376C4 4.92407 6.13281 7.66632 7.06797 8.79777C7.29219 9.06741 7.70781 9.06741 7.93203 8.79777ZM7.5 2.25584C7.80942 2.25584 8.10617 2.37467 8.32496 2.5862C8.54375 2.79772 8.66667 3.08461 8.66667 3.38376C8.66667 3.6829 8.54375 3.96979 8.32496 4.18132C8.10617 4.39284 7.80942 4.51168 7.5 4.51168C7.19058 4.51168 6.89383 4.39284 6.67504 4.18132C6.45625 3.96979 6.33333 3.6829 6.33333 3.38376C6.33333 3.08461 6.45625 2.79772 6.67504 2.5862C6.89383 2.37467 7.19058 2.25584 7.5 2.25584Z"
                    fill="#7C7A7A"
                  />
                </g>
                <defs>
                  <filter
                    id="filter0_d_633_1237"
                    x="0"
                    y="0"
                    width="15"
                    height="17"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                  >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                      in="SourceAlpha"
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                      result="hardAlpha"
                    />
                    <feOffset dy="4" />
                    <feGaussianBlur stdDeviation="2" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                    />
                    <feBlend
                      mode="normal"
                      in2="BackgroundImageFix"
                      result="effect1_dropShadow_633_1237"
                    />
                    <feBlend
                      mode="normal"
                      in="SourceGraphic"
                      in2="effect1_dropShadow_633_1237"
                      result="shape"
                    />
                  </filter>
                </defs>
              </svg>
            </div>
            {showDropdown && (
              <div className="dropdown-div  new-out-oneway-drpd-row">
                {listVisible && showDropdown && (
                  <div className="list-container">
                    <ul>
                      {filteredLocations && filteredLocations.length > 0 ? (
                        filteredLocations.map((city) => (
                          <li
                            key={city.value}
                            style={{ cursor: "pointer" }}
                            onClick={() => handleCityClick(city)}
                          >
                            {city.label}
                          </li>
                        ))
                      ) : (
                        <li>No cities found</li>
                      )}
                    </ul>
                  </div>
                )}
              </div>
            )}
          </div>

          {/* <div className="destinationcities">
          <label className="title-name">Destination City</label>
          

          <div className="add-city-icon">
            <div className="out-rount-destination-add">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="19"
                height="19"
                viewBox="0 0 19 19"
                fill="none"
              >
                <g filter="url(#filter0_d_5320_2335)">
                  <path d="M9.5 0V11" stroke="#7C7A7A" stroke-width="3" />
                  <path d="M4 5.5H15" stroke="#7C7A7A" stroke-width="3" />
                </g>
                <defs>
                  <filter
                    id="filter0_d_5320_2335"
                    x="0"
                    y="0"
                    width="19"
                    height="19"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                  >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                      in="SourceAlpha"
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                      result="hardAlpha"
                    />
                    <feOffset dy="4" />
                    <feGaussianBlur stdDeviation="2" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                    />
                    <feBlend
                      mode="normal"
                      in2="BackgroundImageFix"
                      result="effect1_dropShadow_5320_2335"
                    />
                    <feBlend
                      mode="normal"
                      in="SourceGraphic"
                      in2="effect1_dropShadow_5320_2335"
                      result="shape"
                    />
                  </filter>
                </defs>
              </svg>
            </div>
            <p className="add-city-text" onClick={handleAddDestination}>
              Add Cities
            </p>
          </div>

        </div> */}

          {additionalCities.map((city, index) => (
            <div className="destinationcities" onClick={suggtionshows}>
              <div className="out-roundt-desination-title">
                <label className="title-name">
                  Destination City {index + 1}
                </label>
              </div>
              <div key={index} className="inputCity   out-round-desi-drop">
                <input
                  type="text"
                  placeholder="Enter city name"
                  value={city}
                  className="custom-input2"
                  id="addcityinput"
                  onChange={(e) => handleCityChange(index, e.target.value)}
                  disabled={!search}
                />
                <div className="out-roundt-des-city">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15"
                    height="17"
                    viewBox="0 0 15 17"
                    fill="none"
                  >
                    <g filter="url(#filter0_d_14218_5235)">
                      <path
                        d="M7.93203 8.79777C8.86719 7.66632 11 4.92407 11 3.38376C11 1.51564 9.43229 0 7.5 0C5.56771 0 4 1.51564 4 3.38376C4 4.92407 6.13281 7.66632 7.06797 8.79777C7.29219 9.06741 7.70781 9.06741 7.93203 8.79777ZM7.5 2.25584C7.80942 2.25584 8.10617 2.37467 8.32496 2.5862C8.54375 2.79772 8.66667 3.08461 8.66667 3.38376C8.66667 3.6829 8.54375 3.96979 8.32496 4.18132C8.10617 4.39284 7.80942 4.51168 7.5 4.51168C7.19058 4.51168 6.89383 4.39284 6.67504 4.18132C6.45625 3.96979 6.33333 3.6829 6.33333 3.38376C6.33333 3.08461 6.45625 2.79772 6.67504 2.5862C6.89383 2.37467 7.19058 2.25584 7.5 2.25584Z"
                        fill="#7C7A7A"
                      />
                    </g>
                    <defs>
                      <filter
                        id="filter0_d_14218_5235"
                        x="0"
                        y="0"
                        width="15"
                        height="17"
                        filterUnits="userSpaceOnUse"
                        color-interpolation-filters="sRGB"
                      >
                        <feFlood
                          flood-opacity="0"
                          result="BackgroundImageFix"
                        />
                        <feColorMatrix
                          in="SourceAlpha"
                          type="matrix"
                          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                          result="hardAlpha"
                        />
                        <feOffset dy="4" />
                        <feGaussianBlur stdDeviation="2" />
                        <feComposite in2="hardAlpha" operator="out" />
                        <feColorMatrix
                          type="matrix"
                          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                        />
                        <feBlend
                          mode="normal"
                          in2="BackgroundImageFix"
                          result="effect1_dropShadow_14218_5235"
                        />
                        <feBlend
                          mode="normal"
                          in="SourceGraphic"
                          in2="effect1_dropShadow_14218_5235"
                          result="shape"
                        />
                      </filter>
                    </defs>
                  </svg>
                </div>
                {showDropdown && (
                  <div>
                    {index === additionalCities.length - 1 &&
                      additionalCities.length < 5 && (
                        <p
                          className="add-city-text"
                          onClick={handleAddDestination}
                        >
                          Add Cities
                          <div className="out-rount-destination-add">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="19"
                              height="19"
                              viewBox="0 0 19 19"
                              fill="none"
                            >
                              <g filter="url(#filter0_d_5320_2335)">
                                <path
                                  d="M9.5 0V11"
                                  stroke="#7C7A7A"
                                  stroke-width="3"
                                />
                                <path
                                  d="M4 5.5H15"
                                  stroke="#7C7A7A"
                                  stroke-width="3"
                                />
                              </g>
                              <defs>
                                <filter
                                  id="filter0_d_5320_2335"
                                  x="0"
                                  y="0"
                                  width="19"
                                  height="19"
                                  filterUnits="userSpaceOnUse"
                                  color-interpolation-filters="sRGB"
                                >
                                  <feFlood
                                    flood-opacity="0"
                                    result="BackgroundImageFix"
                                  />
                                  <feColorMatrix
                                    in="SourceAlpha"
                                    type="matrix"
                                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                    result="hardAlpha"
                                  />
                                  <feOffset dy="4" />
                                  <feGaussianBlur stdDeviation="2" />
                                  <feComposite in2="hardAlpha" operator="out" />
                                  <feColorMatrix
                                    type="matrix"
                                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                                  />
                                  <feBlend
                                    mode="normal"
                                    in2="BackgroundImageFix"
                                    result="effect1_dropShadow_5320_2335"
                                  />
                                  <feBlend
                                    mode="normal"
                                    in="SourceGraphic"
                                    in2="effect1_dropShadow_5320_2335"
                                    result="shape"
                                  />
                                </filter>
                              </defs>
                            </svg>
                          </div>
                        </p>
                      )}

                    <div className="div_7_outstation   out-round-desi-drop-potion">
                      {citySuggestions
                        .filter((suggestion) =>
                          suggestion.toLowerCase().includes(city.toLowerCase())
                        )
                        .slice(0, 6)
                        .map((suggestion, suggestionIndex) => {
                          const isMatch =
                            suggestion
                              .toLowerCase()
                              .indexOf(city.toLowerCase()) > -1;
                          return (
                            <div key={suggestionIndex}>
                              {isMatch &&
                                city &&
                                !citySuggestions.includes(city) && (
                                  <div
                                    className="suggestion"
                                    onClick={() =>
                                      handleCityChange(index, suggestion)
                                    }
                                  >
                                    {suggestion}
                                  </div>
                                )}
                            </div>
                          );
                        })}
                    </div>
                  </div>
                )}

                {index > 0 && (
                  <button
                    onClick={() => handleRemoveCity(index)}
                    className="remove-city-button"
                    style={{
                      border: "none",
                      background: "none",
                      margin: "0.5rem",
                    }}
                  >
                    {/* <img
                    style={{ marginLeft: "0.5rem" }}
                    // src={AddIcon}
                    alt="deleteIcon"
                  /> */}
                    <div className="out-round-minus-button">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="11"
                        height="4"
                        viewBox="0 0 11 4"
                        fill="none"
                      >
                        <path d="M0 2H11" stroke="#7C7A7A" stroke-width="3" />
                      </svg>
                    </div>
                  </button>
                )}
              </div>
            </div>
          ))}
          <div onMouseLeave={outsideClickOfDate}>
            <div className={`user-booking ${showDate ? "lo-ex" : "inactive"}`}>
              <label className="title-name">From Date</label>

              <DatePicker
                selected={pickUpDate}
                dateFormat="dd-MM-yyyy"
                autoComplete="off"
                onChange={(date) => {
                  setPickUpDate(date);
                }}
                disabled={!search}
                onClick={handleDateClick}
                className="custom-input"
                // isClearable
                minDate={new Date()}
                closeOnScroll={true}
                placeholderText="Select Pick Up Date"
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="user-booking">
            <label className="title-name">To Date</label>
            <DatePicker
              selected={dropDate}
              dateFormat="dd-MM-yyyy"
              autoComplete="off"
              onChange={(date) => {
                setDropDate(date);
              }}
              disabled={!search}
              className="custom-input"
              // isClearable
              minDate={new Date()}
              closeOnScroll={true}
              placeholderText="Select Return Date"
            />
          </div>
          <div className="user-booking">
            <label className="title-name">Pick Up Time</label>
            <div className="out-roundt-pickup-dropdown">
              <DropdownColumn
                placeholder="Select Time"
                options={generateTimeOptions(pickUpDate).map((option) => ({
                  label: option,
                  value: option,
                }))}
                disabled={!search} 
                selectedValue={pickUpTime}
                handleChange={handleChange}
                className="dropdown"
                useInlineStyle={true}
                autoComplete="off"
                required
              />
            </div>
          </div>

          <div className="user-booking out-roundt-cab-type">
            <label className="title-name ">Cab Type</label>
            <div className="out-roundt-cab-dropdown">
              <DropdownColumn
                placeholder="Vehicle Type"
                options={cabType}
                selectedValue={selectedCabType}
                handleChange={handleChangeCabType}
                className="dropdown"
                useInlineStyle={true}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Roundtripoutstation;
