import axios from "axios";
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export  function fetchingLocations() {
  return new Promise(async (resolve,rejection) => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}/v1/admin/locations`
      );
      resolve(response.data.data);
    } catch (e) {
      console.log("Error in fetching Data", e);
      rejection(e);
    }
  });
}
export  function fetchingActiveLocations() {
  return new Promise(async (resolve,rejection) => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}/v1/admin/locations/active`
      );
      resolve(response.data.data);
    } catch (e) {
      console.log("Error in fetching Data", e);
      rejection(e);
    }
  });
}
export  function fetchingInActiveLocations() {
  return new Promise(async (resolve,rejection) => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}/v1/admin/locations/inactive`
      );
      resolve(response.data.data);
      console.log(response.data.data);
    } catch (e) {
      console.log("Error in fetching Data", e);
      rejection(e);
    }
  });
}


