import { useEffect, useState } from "react";
import {
	Routes,
	Route,
	useNavigate,
	useLocation,
	Link,
} from "react-router-dom";
import "./VendorTarrif.css";
import { useSelector, useDispatch } from "react-redux";
import { setBooleanValue, selectBooleanValue } from "./VendorTarrifSlice";
import AirportTariff from "./AirportTarrif";
import OutstationTarrif from "./OutstationTarrif";
import LocalTarrif from "./LocalTarrif";
import CreateVendorTarrif from "./CreateVendorTarrif";
import UpdateVendorTarrif from "./UpdateVendorTarrif";
import { IoIosArrowDown } from "react-icons/io";
const VendorTarrif = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();
	const [activeComponent, setActiveComponent] = useState("airport-tariff");

	const create_tariff_mode = useSelector(selectBooleanValue) || false;

	const formatTitle = (str) => {
		return str
			.replace(/-/g, " ")
			.replace(/\b\w/g, (char) => char.toUpperCase());
	};

	useEffect(() => {
		if (location.pathname === "/vendortarrifmanagement") {
			navigate("/vendortarrifmanagement/airport-tariff");
			setActiveComponent("airport-tariff");
		} else {
			const path = location.pathname.split("/").pop();
			setActiveComponent(path);
		}
	}, [navigate, location.pathname]);

	const handleActiveComponent = (type) => {
		setActiveComponent(type);
		if (type === "create-vendor-tariff") {
			dispatch(setBooleanValue(true));
		} else {
			dispatch(setBooleanValue(false));
		}
		navigate(`/vendortarrifmanagement/${type}`);
	};

	return (
		<div style={{ minWidth: "81dvw", paddingRight: "10px", marginTop: "20px" }}>
			<div className="tariff-management">
				<div className="header">
					<div
						className="header-text"
						style={{ display: "flex", alignItems: "center" }}
					>
						<Link
							to="/dashboard"
							className="h3-tariff"
							style={{ marginTop: 0, paddingTop: 0 }}
						>
							Vendor Tariff Management
						</Link>
						<IoIosArrowDown
							style={{ transform: "rotate(-90deg)" }}
							color="#f56a00"
						/>
						<Link
							to="/vendortarrifmanagement"
							className="h2-tariff"
							style={{ marginTop: 0 }}
						>
							{formatTitle(activeComponent)}
						</Link>
					</div>
					<div className="btn-container">
						<button
							className={
								activeComponent === "airport-tariff"
									? "createButton-tariff-active"
									: "createButton-tariff"
							}
							onClick={() => handleActiveComponent("airport-tariff")}
						>
							Airport Tariff
						</button>
						<button
							className={
								activeComponent === "local-tariff"
									? "createButton-tariff-active"
									: "createButton-tariff"
							}
							onClick={() => handleActiveComponent("local-tariff")}
						>
							Local Tariff
						</button>
						<button
							className={
								activeComponent === "outstation-tariff"
									? "createButton-tariff-active"
									: "createButton-tariff"
							}
							onClick={() => handleActiveComponent("outstation-tariff")}
						>
							Outstation Tariff
						</button>
						<button
							style={{ width: "125px" }}
							className={
								activeComponent === "create-vendor-tariff"
									? "createButton-tariff-active"
									: "createButton-tariff"
							}
							onClick={() => handleActiveComponent("create-vendor-tariff")}
						>
							Create Vendor Tariff
						</button>
					</div>
				</div>
			</div>

			<div>
				<Routes>
					<Route path="airport-tariff" element={<AirportTariff />} />
					<Route path="local-tariff" element={<LocalTarrif />} />
					<Route path="outstation-tariff" element={<OutstationTarrif />} />
					<Route path="create-vendor-tariff" element={<CreateVendorTarrif />} />
					<Route path="update-vendor-tariff" element={<UpdateVendorTarrif />} />
				</Routes>
			</div>
		</div>
	);
};

export default VendorTarrif;
