import axios from "axios";
import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import toast, { Toaster } from "react-hot-toast";
import AddIcon from "../../../src/assets/delete.png";
import useSuggestionsCity from "../../hooks/useSuggestionsIndianCities";
import { generateTimeOptions } from "../../utils/time/time";
import DropdownColumn from "./Dropdown";
import "./List.css";
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const OutstationOneWayForm = (props) => {
  const {
    pickUpLocation,
    setPickUpLocation,
    selectedLocation,
    setSelectedLocation,
    selectDropAddress,
    setSelectDropAddress,
    pickUpDate,
    setPickUpDate,
    dropDate,
    pickUpTime,
    setPickUpTime,
    cabType,
    setCabType,
    selectedCabType,
    setSelectedCabType,
    setSelectTemp,
    setSelectedTariffId,
    setCarCategoriesDetails,
  } = props;

  const [citySuggestions] = useSuggestionsCity();
  const [search, setSearch] = useState("");
  // const [citySelected, setCitySelected] = useState(false);
  const [listVisible, setListVisible] = useState(false);
  const [additionalCities, setAdditionalCities] = useState([""]);
  const [showAddDestinationInput, setShowAddDestinationInput] = useState(false);
  const [formatedPickup, setFormatedPickup] = useState("");
  const [formatedDrop, setFormatedDrop] = useState("");

  const handleAddDestination = () => {
    setAdditionalCities([...additionalCities, ""]);
    setShowAddDestinationInput(true);
  };

  const handleCityChange = (index, value) => {
    const updatedCities = [...additionalCities];
    updatedCities[index] = value;
    setAdditionalCities(updatedCities);
  };

  const handleRemoveCity = (index) => {
    const updatedCities = [...additionalCities];
    updatedCities.splice(index, 1);
    setAdditionalCities(updatedCities);
  };

  useEffect(() => {
    const allCities = additionalCities.join(", ");
    setSelectDropAddress(allCities);
  }, [additionalCities]);

  useEffect(() => {
    const extractLocationsAsOptions = (dataArray) => {
      if (Array.isArray(dataArray.data)) {
        return dataArray.data.map((item) => ({
          label: item.location,
          value: item.location,
        }));
      }
      return [];
    };

    const fetchData = async () => {
      try {
        const locationResponse = await fetch(
          `${API_BASE_URL}/v1/locations`
        );
        const locationData = await locationResponse.json();
        const pickUpLocation = extractLocationsAsOptions(locationData);
        setPickUpLocation(pickUpLocation);
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };
    fetchData();
  }, []);

  const formatDate = (pickUpDate) => {
    if (!(pickUpDate instanceof Date) || isNaN(pickUpDate)) {
      return "";
    }
    return format(pickUpDate, "dd-MM-yyyy");
  };

  const extractCarCategoriesAsOptions = (dataArray) => {
    if (Array.isArray(dataArray.data)) {
      return dataArray.data.map((item) => ({
        label: item.carCategory.category,
        value: item.carCategory.category,
      }));
    }
    return [];
  };

  const filteredLocations =
    search.length === 0
      ? pickUpLocation
      : pickUpLocation.filter((city) =>
          city.label.toLowerCase().includes(search.toLowerCase())
        );
  // console.log(filteredLocations);
  const handleChange = (e) => {
    const time = e.target.value;
    setPickUpTime(time);
    setFormatedPickup(formatDate(pickUpDate));
    setFormatedDrop(formatDate(dropDate));
  };

  useEffect(() => {
    const subTripType = "One Way";
    if (selectedLocation && selectDropAddress && pickUpTime && formatedPickup) {
      const fetchCars = async () => {
        try {
          const response = await axios.get(
            `${API_BASE_URL}/v1/users/select-cars/outstation?source=${selectedLocation}&destination=${selectDropAddress}&time=${pickUpTime}&startDate=${formatedPickup}&subTripType=${subTripType}`
          );

          const carCategoryOptions = extractCarCategoriesAsOptions(
            response.data
          );
          setCabType(carCategoryOptions);

          const temp = response.data.tempDataId;
          setSelectTemp(temp);

          console.log(carCategoryOptions);
        } catch (error) {
          toast.error(error.response.data.message);
          console.error(
            "Error fetching cars so please check in backend or you provided proper values:",
            error
          );
          setCabType([]);
        }
      };

      fetchCars();
    }
  }, [pickUpTime, selectedLocation, selectDropAddress, formatedPickup]);

  const handleChangeCabType = async (e) => {
    const cabType = e.target.value;
    setSelectedCabType(cabType);
    const formatedPickup = formatDate(pickUpDate);
    const subTripType = "One Way";
    try {
      const response = await axios.get(
        `${API_BASE_URL}/v1/users/select-cars/outstation?source=${selectedLocation}&destination=${selectDropAddress}&time=${pickUpTime}&startDate=${formatedPickup}&subTripType=${subTripType}`
      );

      const carCategories = response.data.data.filter(
        (item) => item.carCategory.category === cabType
      );

      console.log(carCategories);
      setCarCategoriesDetails(carCategories);
      carCategories.map((item) => {
        setSelectedTariffId(item._id);
      });
    } catch (error) {
      console.error("Error fetching cars:", error);
    }
  };

  const handleCityClick = (city) => {
    setSelectedLocation(city.label);
    setSearch(city.label);
    setListVisible(false);
  };

  // console.log(selectDropAddress);

  return (
    <>
      <Toaster />
      <div className="row">
        <div className="user-booking select">
          <label className="title-name local-existing-title-name">
            Pick Up Location
          </label>
          <input
            placeholder="Enter pick up location"
            type="text"
            className="custom-input"
            name="pickUpLocation"
            autoComplete="off"
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
              setListVisible(true);
            }}
          />
          <div className="out-oneway-location-svg">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="15"
              height="17"
              viewBox="0 0 15 17"
              fill="none"
            >
              <g filter="url(#filter0_d_633_1237)">
                <path
                  d="M7.93203 8.79777C8.86719 7.66632 11 4.92407 11 3.38376C11 1.51564 9.43229 0 7.5 0C5.56771 0 4 1.51564 4 3.38376C4 4.92407 6.13281 7.66632 7.06797 8.79777C7.29219 9.06741 7.70781 9.06741 7.93203 8.79777ZM7.5 2.25584C7.80942 2.25584 8.10617 2.37467 8.32496 2.5862C8.54375 2.79772 8.66667 3.08461 8.66667 3.38376C8.66667 3.6829 8.54375 3.96979 8.32496 4.18132C8.10617 4.39284 7.80942 4.51168 7.5 4.51168C7.19058 4.51168 6.89383 4.39284 6.67504 4.18132C6.45625 3.96979 6.33333 3.6829 6.33333 3.38376C6.33333 3.08461 6.45625 2.79772 6.67504 2.5862C6.89383 2.37467 7.19058 2.25584 7.5 2.25584Z"
                  fill="#7C7A7A"
                />
              </g>
              <defs>
                <filter
                  id="filter0_d_633_1237"
                  x="0"
                  y="0"
                  width="15"
                  height="17"
                  filterUnits="userSpaceOnUse"
                  color-interpolation-filters="sRGB"
                >
                  <feFlood flood-opacity="0" result="BackgroundImageFix" />
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  />
                  <feOffset dy="4" />
                  <feGaussianBlur stdDeviation="2" />
                  <feComposite in2="hardAlpha" operator="out" />
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                  />
                  <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_633_1237"
                  />
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_633_1237"
                    result="shape"
                  />
                </filter>
              </defs>
            </svg>
          </div>

          <div className="dropdown-div  new-out-oneway-drpd-row">
            {listVisible && (
              <div className="list-container">
                <ul>
                  {filteredLocations && filteredLocations.length > 0 ? (
                    filteredLocations.map((city) => (
                      <li
                        key={city.value}
                        style={{ cursor: "pointer" }}
                        onClick={() => handleCityClick(city)}
                      >
                        {city.label}
                      </li>
                    ))
                  ) : (
                    <li>No cities found</li>
                  )}
                </ul>
              </div>
            )}
          </div>
        </div>
        <div className="destinationcities">
          <div className="out-oneway-desination-title">
            <label className="title-name">Destination City</label>
          </div>

          <div className="additional-cities  out-oneway-div-drop-option">
            {additionalCities.map((city, index) => (
              <div key={index} className="inputCity">
                <input
                  type="text"
                  placeholder="Enter city name"
                  value={city}
                  className="custom-input2"
                  onChange={(e) => handleCityChange(index, e.target.value)}
                />
                <div className="out-oneway-destin">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15"
                    height="17"
                    viewBox="0 0 15 17"
                    fill="none"
                  >
                    <g filter="url(#filter0_d_945_1131)">
                      <path
                        d="M7.93203 8.79777C8.86719 7.66632 11 4.92407 11 3.38376C11 1.51564 9.43229 0 7.5 0C5.56771 0 4 1.51564 4 3.38376C4 4.92407 6.13281 7.66632 7.06797 8.79777C7.29219 9.06741 7.70781 9.06741 7.93203 8.79777ZM7.5 2.25584C7.80942 2.25584 8.10617 2.37467 8.32496 2.5862C8.54375 2.79772 8.66667 3.08461 8.66667 3.38376C8.66667 3.6829 8.54375 3.96979 8.32496 4.18132C8.10617 4.39284 7.80942 4.51168 7.5 4.51168C7.19058 4.51168 6.89383 4.39284 6.67504 4.18132C6.45625 3.96979 6.33333 3.6829 6.33333 3.38376C6.33333 3.08461 6.45625 2.79772 6.67504 2.5862C6.89383 2.37467 7.19058 2.25584 7.5 2.25584Z"
                        fill="#7C7A7A"
                      />
                    </g>
                    <defs>
                      <filter
                        id="filter0_d_945_1131"
                        x="0"
                        y="0"
                        width="15"
                        height="17"
                        filterUnits="userSpaceOnUse"
                        color-interpolation-filters="sRGB"
                      >
                        <feFlood
                          flood-opacity="0"
                          result="BackgroundImageFix"
                        />
                        <feColorMatrix
                          in="SourceAlpha"
                          type="matrix"
                          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                          result="hardAlpha"
                        />
                        <feOffset dy="4" />
                        <feGaussianBlur stdDeviation="2" />
                        <feComposite in2="hardAlpha" operator="out" />
                        <feColorMatrix
                          type="matrix"
                          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                        />
                        <feBlend
                          mode="normal"
                          in2="BackgroundImageFix"
                          result="effect1_dropShadow_945_1131"
                        />
                        <feBlend
                          mode="normal"
                          in="SourceGraphic"
                          in2="effect1_dropShadow_945_1131"
                          result="shape"
                        />
                      </filter>
                    </defs>
                  </svg>
                </div>

                <div className="out-onway-drop-container">
                  <div className="div_7_outstation   out-oneway-drop-option">
                    {citySuggestions
                      .filter((suggestion) =>
                        suggestion.toLowerCase().includes(city.toLowerCase())
                      )
                      .slice(0, 6)
                      .map((suggestion, suggestionIndex) => {
                        const isMatch =
                          suggestion.toLowerCase().indexOf(city.toLowerCase()) >
                          -1;
                        return (
                          <div key={suggestionIndex}>
                            {isMatch &&
                              city &&
                              !citySuggestions.includes(city) && (
                                <div
                                  className="suggestion"
                                  onClick={() =>
                                    handleCityChange(index, suggestion)
                                  }
                                >
                                  {suggestion}
                                </div>
                              )}
                          </div>
                        );
                      })}
                  </div>
                </div>

                {index > 0 && (
                  <button
                    onClick={() => handleRemoveCity(index)}
                    className="remove-city-button"
                    style={{
                      border: "none",
                      background: "none",
                      margin: "0.5rem",
                    }}
                  >
                    <img
                      style={{ marginLeft: "0.5rem" }}
                      src={AddIcon}
                      alt="deleteIcon"
                    />
                  </button>
                )}
              </div>
            ))}
          </div>

          <div className="add-city-icon"></div>
        </div>
        <div className="user-booking">
          <label className="title-name">Pick Up Date</label>

          <DatePicker
            selected={pickUpDate}
            dateFormat="dd-MM-yyyy"
            autoComplete="off"
            onChange={(date) => {
              setPickUpDate(date);
            }}
            className="custom-input"
            // isClearable
            minDate={new Date()}
            closeOnScroll={true}
            placeholderText="Select date"
          />
          <div className="out-oneway-pdate">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="17"
              height="17"
              viewBox="0 0 17 17"
              fill="none"
            >
              <g filter="url(#filter0_d_633_1454)">
                <path
                  d="M6.57143 0C6.92701 0 7.21429 0.251367 7.21429 0.5625V1.125H9.78571V0.5625C9.78571 0.251367 10.073 0 10.4286 0C10.7842 0 11.0714 0.251367 11.0714 0.5625V1.125H12.0357C12.5681 1.125 13 1.50293 13 1.96875V2.8125H4V1.96875C4 1.50293 4.43192 1.125 4.96429 1.125H5.92857V0.5625C5.92857 0.251367 6.21585 0 6.57143 0ZM4 3.375H13V8.15625C13 8.62207 12.5681 9 12.0357 9H4.96429C4.43192 9 4 8.62207 4 8.15625V3.375ZM5.28571 4.78125V5.34375C5.28571 5.49844 5.43036 5.625 5.60714 5.625H6.25C6.42679 5.625 6.57143 5.49844 6.57143 5.34375V4.78125C6.57143 4.62656 6.42679 4.5 6.25 4.5H5.60714C5.43036 4.5 5.28571 4.62656 5.28571 4.78125ZM7.85714 4.78125V5.34375C7.85714 5.49844 8.00179 5.625 8.17857 5.625H8.82143C8.99821 5.625 9.14286 5.49844 9.14286 5.34375V4.78125C9.14286 4.62656 8.99821 4.5 8.82143 4.5H8.17857C8.00179 4.5 7.85714 4.62656 7.85714 4.78125ZM10.75 4.5C10.5732 4.5 10.4286 4.62656 10.4286 4.78125V5.34375C10.4286 5.49844 10.5732 5.625 10.75 5.625H11.3929C11.5696 5.625 11.7143 5.49844 11.7143 5.34375V4.78125C11.7143 4.62656 11.5696 4.5 11.3929 4.5H10.75ZM5.28571 7.03125V7.59375C5.28571 7.74844 5.43036 7.875 5.60714 7.875H6.25C6.42679 7.875 6.57143 7.74844 6.57143 7.59375V7.03125C6.57143 6.87656 6.42679 6.75 6.25 6.75H5.60714C5.43036 6.75 5.28571 6.87656 5.28571 7.03125ZM8.17857 6.75C8.00179 6.75 7.85714 6.87656 7.85714 7.03125V7.59375C7.85714 7.74844 8.00179 7.875 8.17857 7.875H8.82143C8.99821 7.875 9.14286 7.74844 9.14286 7.59375V7.03125C9.14286 6.87656 8.99821 6.75 8.82143 6.75H8.17857ZM10.4286 7.03125V7.59375C10.4286 7.74844 10.5732 7.875 10.75 7.875H11.3929C11.5696 7.875 11.7143 7.74844 11.7143 7.59375V7.03125C11.7143 6.87656 11.5696 6.75 11.3929 6.75H10.75C10.5732 6.75 10.4286 6.87656 10.4286 7.03125Z"
                  fill="#7C7A7A"
                />
              </g>
              <defs>
                <filter
                  id="filter0_d_633_1454"
                  x="0"
                  y="0"
                  width="17"
                  height="17"
                  filterUnits="userSpaceOnUse"
                  color-interpolation-filters="sRGB"
                >
                  <feFlood flood-opacity="0" result="BackgroundImageFix" />
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  />
                  <feOffset dy="4" />
                  <feGaussianBlur stdDeviation="2" />
                  <feComposite in2="hardAlpha" operator="out" />
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                  />
                  <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_633_1454"
                  />
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_633_1454"
                    result="shape"
                  />
                </filter>
              </defs>
            </svg>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="user-booking">
          <label className="title-name">Pick Up Time</label>
          <div className="out-one-picup-dropdown">
            <DropdownColumn
              placeholder="Select Time"
              options={generateTimeOptions(pickUpDate).map((option) => ({
                label: option,
                value: option,
              }))}
              selectedValue={pickUpTime}
              handleChange={handleChange}
              className="dropdown"
              useInlineStyle={true}
              autoComplete="off"
              required
            />
          </div>
          <div className="out-oneway-ptime">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="17"
              height="17"
              viewBox="0 0 17 17"
              fill="none"
            >
              <g filter="url(#filter0_d_633_1454)">
                <path
                  d="M6.57143 0C6.92701 0 7.21429 0.251367 7.21429 0.5625V1.125H9.78571V0.5625C9.78571 0.251367 10.073 0 10.4286 0C10.7842 0 11.0714 0.251367 11.0714 0.5625V1.125H12.0357C12.5681 1.125 13 1.50293 13 1.96875V2.8125H4V1.96875C4 1.50293 4.43192 1.125 4.96429 1.125H5.92857V0.5625C5.92857 0.251367 6.21585 0 6.57143 0ZM4 3.375H13V8.15625C13 8.62207 12.5681 9 12.0357 9H4.96429C4.43192 9 4 8.62207 4 8.15625V3.375ZM5.28571 4.78125V5.34375C5.28571 5.49844 5.43036 5.625 5.60714 5.625H6.25C6.42679 5.625 6.57143 5.49844 6.57143 5.34375V4.78125C6.57143 4.62656 6.42679 4.5 6.25 4.5H5.60714C5.43036 4.5 5.28571 4.62656 5.28571 4.78125ZM7.85714 4.78125V5.34375C7.85714 5.49844 8.00179 5.625 8.17857 5.625H8.82143C8.99821 5.625 9.14286 5.49844 9.14286 5.34375V4.78125C9.14286 4.62656 8.99821 4.5 8.82143 4.5H8.17857C8.00179 4.5 7.85714 4.62656 7.85714 4.78125ZM10.75 4.5C10.5732 4.5 10.4286 4.62656 10.4286 4.78125V5.34375C10.4286 5.49844 10.5732 5.625 10.75 5.625H11.3929C11.5696 5.625 11.7143 5.49844 11.7143 5.34375V4.78125C11.7143 4.62656 11.5696 4.5 11.3929 4.5H10.75ZM5.28571 7.03125V7.59375C5.28571 7.74844 5.43036 7.875 5.60714 7.875H6.25C6.42679 7.875 6.57143 7.74844 6.57143 7.59375V7.03125C6.57143 6.87656 6.42679 6.75 6.25 6.75H5.60714C5.43036 6.75 5.28571 6.87656 5.28571 7.03125ZM8.17857 6.75C8.00179 6.75 7.85714 6.87656 7.85714 7.03125V7.59375C7.85714 7.74844 8.00179 7.875 8.17857 7.875H8.82143C8.99821 7.875 9.14286 7.74844 9.14286 7.59375V7.03125C9.14286 6.87656 8.99821 6.75 8.82143 6.75H8.17857ZM10.4286 7.03125V7.59375C10.4286 7.74844 10.5732 7.875 10.75 7.875H11.3929C11.5696 7.875 11.7143 7.74844 11.7143 7.59375V7.03125C11.7143 6.87656 11.5696 6.75 11.3929 6.75H10.75C10.5732 6.75 10.4286 6.87656 10.4286 7.03125Z"
                  fill="#7C7A7A"
                />
              </g>
              <defs>
                <filter
                  id="filter0_d_633_1454"
                  x="0"
                  y="0"
                  width="17"
                  height="17"
                  filterUnits="userSpaceOnUse"
                  color-interpolation-filters="sRGB"
                >
                  <feFlood flood-opacity="0" result="BackgroundImageFix" />
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  />
                  <feOffset dy="4" />
                  <feGaussianBlur stdDeviation="2" />
                  <feComposite in2="hardAlpha" operator="out" />
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                  />
                  <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_633_1454"
                  />
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_633_1454"
                    result="shape"
                  />
                </filter>
              </defs>
            </svg>
          </div>
        </div>

        <div className="user-booking  out-onway-user-booking">
          <label className="title-name   out-oneway-cab-title">Cab Type</label>
          <div className="out-one-cab-dropdown">
            <DropdownColumn
              placeholder="Vehicle Type"
              options={cabType}
              selectedValue={selectedCabType}
              handleChange={handleChangeCabType}
              className="dropdown"
              useInlineStyle={true}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default OutstationOneWayForm;
