// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.first_table{
    padding-left: 4rem;

}
.earning_container .first_table_containers table{
    border-collapse: collapse;
    width: 60%;
}

.earning_container .first_table_containers  th{
    padding: 10px 8px;
    color: white;
    background-color: #38b000;
    box-shadow: 0px 4px 18px 0px #38b00040;
    font-size: 14px;
    width: 8rem;
    border:1px solid black;
}
.earning_container .first_table tr :first-child{
    width: 25rem;
}
.first_Earning_table_data td{
    border: 1px solid black;
    text-align: center;
    font-size: 0.8rem;
}
.first_Earning_table_data td:first-child{
    text-align: left;
}

`, "",{"version":3,"sources":["webpack://./src/components/DriverManagement/earning/table/firsttable.css"],"names":[],"mappings":";AACA;IACI,kBAAkB;;AAEtB;AACA;IACI,yBAAyB;IACzB,UAAU;AACd;;AAEA;IACI,iBAAiB;IACjB,YAAY;IACZ,yBAAyB;IACzB,sCAAsC;IACtC,eAAe;IACf,WAAW;IACX,sBAAsB;AAC1B;AACA;IACI,YAAY;AAChB;AACA;IACI,uBAAuB;IACvB,kBAAkB;IAClB,iBAAiB;AACrB;AACA;IACI,gBAAgB;AACpB","sourcesContent":["\n.first_table{\n    padding-left: 4rem;\n\n}\n.earning_container .first_table_containers table{\n    border-collapse: collapse;\n    width: 60%;\n}\n\n.earning_container .first_table_containers  th{\n    padding: 10px 8px;\n    color: white;\n    background-color: #38b000;\n    box-shadow: 0px 4px 18px 0px #38b00040;\n    font-size: 14px;\n    width: 8rem;\n    border:1px solid black;\n}\n.earning_container .first_table tr :first-child{\n    width: 25rem;\n}\n.first_Earning_table_data td{\n    border: 1px solid black;\n    text-align: center;\n    font-size: 0.8rem;\n}\n.first_Earning_table_data td:first-child{\n    text-align: left;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
