// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pop_up_main_container .pop_up_table_containers table {
  border-collapse: collapse;
}

.pop_up_main_container .pop_up_table_containers th {
  padding: 10px 8px;
  font-family: Lato;
  color: white;
  background-color: #38b000;
  box-shadow: 0px 4px 18px 0px #38b00040;
  font-size: 14px;
  width: 8rem;
}

.pop_up_table_data td {
  font-family: Lato;
  text-align: center;
  font-size: 0.8rem;
  white-space: nowrap;
  cursor: pointer;
}
.pop_up_table_data td:first-child {
  text-align: left;
}
.pop_up_table tr :first-child {
  border-top-left-radius: 6px;
  text-align: center;
}
.pop_up_table tr:nth-child(even) {
  border-radius: 8px;
  background-color: #f2f2f2;
}
.pop_up_table tr:nth-child(odd) {
  border-radius: 8px;
  box-shadow: 0px 4px 15px 0px #6c6c6c40;
  background-color: white;
}
`, "",{"version":3,"sources":["webpack://./src/components/DriverManagement/bookinghistory/popuptable/popuptable.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;AAC3B;;AAEA;EACE,iBAAiB;EACjB,iBAAiB;EACjB,YAAY;EACZ,yBAAyB;EACzB,sCAAsC;EACtC,eAAe;EACf,WAAW;AACb;;AAEA;EACE,iBAAiB;EACjB,kBAAkB;EAClB,iBAAiB;EACjB,mBAAmB;EACnB,eAAe;AACjB;AACA;EACE,gBAAgB;AAClB;AACA;EACE,2BAA2B;EAC3B,kBAAkB;AACpB;AACA;EACE,kBAAkB;EAClB,yBAAyB;AAC3B;AACA;EACE,kBAAkB;EAClB,sCAAsC;EACtC,uBAAuB;AACzB","sourcesContent":[".pop_up_main_container .pop_up_table_containers table {\n  border-collapse: collapse;\n}\n\n.pop_up_main_container .pop_up_table_containers th {\n  padding: 10px 8px;\n  font-family: Lato;\n  color: white;\n  background-color: #38b000;\n  box-shadow: 0px 4px 18px 0px #38b00040;\n  font-size: 14px;\n  width: 8rem;\n}\n\n.pop_up_table_data td {\n  font-family: Lato;\n  text-align: center;\n  font-size: 0.8rem;\n  white-space: nowrap;\n  cursor: pointer;\n}\n.pop_up_table_data td:first-child {\n  text-align: left;\n}\n.pop_up_table tr :first-child {\n  border-top-left-radius: 6px;\n  text-align: center;\n}\n.pop_up_table tr:nth-child(even) {\n  border-radius: 8px;\n  background-color: #f2f2f2;\n}\n.pop_up_table tr:nth-child(odd) {\n  border-radius: 8px;\n  box-shadow: 0px 4px 15px 0px #6c6c6c40;\n  background-color: white;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
