import axios from "axios";
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export function fetchAllCustomer() {
  return new Promise(async (resolve) => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}/v1/admin/users`
      );
      resolve(response.data.data);
    } catch (e) {
      console.log("Error in fetching Data", e.message);
    }
  });
}
