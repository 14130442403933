// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.assign-container {
  background: #e6f5e0;
  height: 500px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px;
  gap: 20px;
  /* overflow-y: scroll; */
}



.assign-buttons {
  display: flex;
  gap: 20px;
}

.assign-buttons > .individual-button {
  background: white;
  border: 1px solid #38b000;
  border-radius: 10px;
  width: 330px;
  height: 33px;
  font-size: 20px;
  font-weight: 700;
  color: #38b000;
  cursor: pointer;
}
.assign-buttons > .selected-button {
  background: #38b000;
  border: 1px solid #38b000;
  border-radius: 10px;
  width: 330px;

  height: 33px;
  font-size: 20px;
  font-weight: 700;
  color: white;
  cursor: pointer;
}
.no_available_driver{
  display: flex;
  /* justify-content: center; */
  /* align-items: center; */
  flex-direction: column;
  height: 100vh;
  width: 100%;
  
  line-height: normal;
  color: #6d6d6d;
}
.no_available_driver span{
  text-align: center;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  padding-top: 5%;
}
.greenButton{
  background-color: #38b000;
}
.whiteButton{
  background-color: white;
}`, "",{"version":3,"sources":["webpack://./src/components/AssignCab&Driver/AssignSection.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,aAAa;EACb,mBAAmB;EACnB,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,YAAY;EACZ,SAAS;EACT,wBAAwB;AAC1B;;;;AAIA;EACE,aAAa;EACb,SAAS;AACX;;AAEA;EACE,iBAAiB;EACjB,yBAAyB;EACzB,mBAAmB;EACnB,YAAY;EACZ,YAAY;EACZ,eAAe;EACf,gBAAgB;EAChB,cAAc;EACd,eAAe;AACjB;AACA;EACE,mBAAmB;EACnB,yBAAyB;EACzB,mBAAmB;EACnB,YAAY;;EAEZ,YAAY;EACZ,eAAe;EACf,gBAAgB;EAChB,YAAY;EACZ,eAAe;AACjB;AACA;EACE,aAAa;EACb,6BAA6B;EAC7B,yBAAyB;EACzB,sBAAsB;EACtB,aAAa;EACb,WAAW;;EAEX,mBAAmB;EACnB,cAAc;AAChB;AACA;EACE,kBAAkB;EAClB,kBAAkB;EAClB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;AACjB;AACA;EACE,yBAAyB;AAC3B;AACA;EACE,uBAAuB;AACzB","sourcesContent":[".assign-container {\n  background: #e6f5e0;\n  height: 500px;\n  border-radius: 10px;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  padding: 8px;\n  gap: 20px;\n  /* overflow-y: scroll; */\n}\n\n\n\n.assign-buttons {\n  display: flex;\n  gap: 20px;\n}\n\n.assign-buttons > .individual-button {\n  background: white;\n  border: 1px solid #38b000;\n  border-radius: 10px;\n  width: 330px;\n  height: 33px;\n  font-size: 20px;\n  font-weight: 700;\n  color: #38b000;\n  cursor: pointer;\n}\n.assign-buttons > .selected-button {\n  background: #38b000;\n  border: 1px solid #38b000;\n  border-radius: 10px;\n  width: 330px;\n\n  height: 33px;\n  font-size: 20px;\n  font-weight: 700;\n  color: white;\n  cursor: pointer;\n}\n.no_available_driver{\n  display: flex;\n  /* justify-content: center; */\n  /* align-items: center; */\n  flex-direction: column;\n  height: 100vh;\n  width: 100%;\n  \n  line-height: normal;\n  color: #6d6d6d;\n}\n.no_available_driver span{\n  text-align: center;\n  font-family: Inter;\n  font-size: 20px;\n  font-style: normal;\n  font-weight: 600;\n  padding-top: 5%;\n}\n.greenButton{\n  background-color: #38b000;\n}\n.whiteButton{\n  background-color: white;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
