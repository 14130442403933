import { IoCalendarOutline } from 'react-icons/io5';
import React, { useState, useEffect, useRef } from "react";
import "./Table.css";
import "./Dropdown.css";
import { AiOutlineSearch } from "react-icons/ai";
import InputColumn from "./Input";
import DropdownColumn from "./Dropdown";
import DatePicker from "react-datepicker";
import { useDispatch, useSelector } from "react-redux";
import { fetchPendingBookings, selectPendingBooking } from "./BookingSlice";
import Filter from "./Filter";
import { IoIosArrowDown } from "react-icons/io";
import "./BookingPending.css"
const TableItem = ({ item, index, isRowSelected }) => {
  const rowClass = index % 2 === 0 ? "table-row-even table-row" : "table-row-odd table-row";
  const selectedRowClass = isRowSelected ? "table-row-selected" : "";

  function getStatusColor(status) {
    switch (status.toLowerCase()) {
      case "pending":
        return "#FBAB0E";
      case "in route":
        return "#F507FA";
      case "completed":
        return "#38B000";
      case "confirmed":
        return "blue";
      case "cancelled":
        return "#FC0F0F";
      case "cab assigned":
        return "#00BCBC";
      default:
        return "black";
    }
  }

  return (
    <tr className={`${rowClass} ${selectedRowClass}`}>
      <td>{index + 1}</td>
      <td>{`${item?.user?.firstName} ${item?.user?.lastName}`}</td>
      <td>{`${item?.location?.city}, ${item?.location?.state}`}</td>
      <td>{item["createdAt"]}</td>
      <td>{`${item?.tariff?.tripType} - ${item?.tariff?.subTripType}`}</td>
      <td
        style={{
          color: getStatusColor(item["status"]),
          fontWeight: "bold",
          fontFamily: "Inter",
        }}
      >
        {item["status"]}
      </td>
      <td>{item?.carCategory?.category}</td>
      <td>
        {item?.estimatedFare ? `₹ ${item?.estimatedFare.toFixed(2)}` : "N/A"}
      </td>
    </tr>
  );
};

const Table = ({ headers, data }) => {
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: null });

  const handleRowClick = (index) => {
    setSelectedRowIndex(index);
  };

  const handleSort = (key) => {
    let direction = "ascending";

    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }

    setSortConfig({ key, direction });
  };

  return (
    <div className="table-containers">
      <div className="table-scroll">
        <table className="table">
          <thead>
            <tr>
              {headers.map((header, index) => (
                <th key={index} onClick={() => handleSort(header)}>
                  <div className='th-span'>
                    {header}
                  </div>
                </th>
              ))}
            </tr>
          </thead>

          <tbody>
            {data &&
              data.map((item, index) => (
                <TableItem
                  key={index}
                  item={item}
                  index={index}
                  isRowSelected={selectedRowIndex === index}
                  handleRowClick={handleRowClick}
                />
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const BookingPending = () => {
  const dispatch = useDispatch();
  const pendingBookings = useSelector(selectPendingBooking);
  const [selectedLocation, setSelectedLocation] = useState("");
  const [selectedTripType, setselectedTripType] = useState("");
  const [selectedCarCategory, setselectedCarCategory] = useState("");
  const [selectedSubTripType, setSelectedSubTripType] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);
  const [searchByName, setSearchByName] = useState("");
  const [locationOptions, setLocationOptions] = useState([]);
  const [carCategoriesOptions, setCarCategoriesOptions] = useState([]);
  const [tripOptions, setTripOptions] = useState([]);
  const [subTripOptions, setSubTripOptions] = useState([]);

  const handleDateChange = (date) => {
    setSelectedDate(date);
    console.log(date);
  };

  useEffect(() => {
    dispatch(fetchPendingBookings());
  }, [dispatch]);

  useEffect(() => {
    const extractCategoriesAsOptions = (dataArray) => {
      if (Array.isArray(dataArray.data)) {
        return dataArray.data.map((item) => ({
          label: item.category,
          value: item.category,
        }));
      }
      return [];
    };

    const extractLocationsAsOptions = (dataArray) => {
      if (Array.isArray(dataArray.data)) {
        return dataArray.data.map((item) => ({
          label: item.location,
          value: item.location,
        }));
      }
      return [];
    };

    const extractTripAsOptions = (dataArray) => {
      if (Array.isArray(dataArray.data)) {
        return dataArray.data.map((item) => ({
          label: item.tripType,
          value: item.tripType,
        }));
      }
      return [];
    };

    const fetchOptions = async () => {
      try {
        const locationResponse = await fetch(
          "https://api.yatricabs.com/v1/locations"
        );
        const locationData = await locationResponse.json();
        const locationOptions = extractLocationsAsOptions(locationData);
        setLocationOptions(locationOptions);

        const carResponse = await fetch(
          "https://api.yatricabs.com/v1/car-categories"
        );
        const carData = await carResponse.json();
        const carCategoriesOptions = extractCategoriesAsOptions(carData);
        setCarCategoriesOptions(carCategoriesOptions);

        const tripResponse = await fetch(
          "https://api.yatricabs.com/v1/trip-types"
        );
        const tripData = await tripResponse.json();
        const tripOptions = extractTripAsOptions(tripData);
        setTripOptions(tripOptions);
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };
    fetchOptions();
  }, []);

  useEffect(() => {
    const extractSubTripAsOptions = (dataArray) => {
      if (Array.isArray(dataArray.data) && dataArray.data.length > 0) {
        const subTripTypes = dataArray.data[0].subTripTypes; // Access the subTripTypes array
        return subTripTypes.map((subTrip) => ({
          label: subTrip,
          value: subTrip,
        }));
      }
      return [];
    };

    const fetchSubTripTypes = async () => {
      try {
        if (selectedTripType) {
          const subTripResponse = await fetch(`https://api.yatricabs.com/v1/sub-trip-types/${selectedTripType}`);
          const subTripData = await subTripResponse.json();
          const subTripOptions = extractSubTripAsOptions(subTripData);
          console.log("subTripOptions:", subTripOptions);
          setSubTripOptions(subTripOptions);
        } else {
          setSubTripOptions([]);
        }
      } catch (error) {
        console.error("Error fetching sub-trip types: ", error);
      }
    };
    fetchSubTripTypes();
  }, [selectedTripType]);

  const headers = [
    "Sr no.",
    "Customers Name",
    "Location",
    "Booking Date",
    "Trip Type",
    "Status",
    "Vehicle Type",
    "Fare",
  ];
  const [pickUpDate, setPickUpDate] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const inputRef = useRef(null);

  const handleCalendarClick = () => {
    setIsOpen((prev) => !prev);
  };

  const handlePickUpDateChange = (date) => {
    setPickUpDate(date);
    setIsOpen(false);
  };
  // Filter the pending bookings based on selected filters
  const filteredBookings = pendingBookings.filter((item) => {
    const combinedLocation = `${item?.location?.city}, ${item?.location?.state}`;
    const combinedName = `${item?.user?.firstName} ${item?.user?.lastName}`;
    return (
      (selectedLocation ? combinedLocation === selectedLocation : true) &&
      (selectedTripType ? item?.tariff?.tripType === selectedTripType : true) &&
      (selectedSubTripType
        ? item?.tariff?.subTripType === selectedSubTripType
        : true) &&
      (selectedCarCategory
        ? item?.carCategory?.category === selectedCarCategory
        : true) &&
      (selectedDate
        ? new Date(item["createdAt"]).toDateString() ===
        selectedDate.toDateString()
        : true) &&
      (searchByName
        ? combinedName.toLowerCase().includes(searchByName.toLowerCase())
        : true)
    );
  }).reverse();;

  // Updated UI with integrated filter logic
  return (
    <div>
      <div className="dropdown-main-box">
        <div className="dropdown-main-1">
          <select
            className="dropdown-select-main"
            value={selectedTripType}
            onChange={(e) => setselectedTripType(e.target.value)}
          >
            <option value="" >
              Trip Type
            </option>
            {tripOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
          <IoIosArrowDown className="drop-icon-1" color="#FA7C07" size={15} />
        </div>

        <div className="dropdown-main-1">
          <select
            className="dropdown-select-main"
            value={selectedSubTripType}
            onChange={(e) => setSelectedSubTripType(e.target.value)}
          >
            <option value="" >
              Sub Trip Type
            </option>
            {subTripOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
          <IoIosArrowDown className="drop-icon-1" color="#FA7C07" size={15} />
        </div>

        <div className="dropdown-main-1">
          <select
            className="dropdown-select-main"
            value={selectedLocation}
            onChange={(e) => setSelectedLocation(e.target.value)}
          >
            <option value="" >
              Pick Up City
            </option>
            {locationOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
          <IoIosArrowDown className="drop-icon-1" color="#FA7C07" size={15} />
        </div>

        <div className="dropdown-main-1">
          <DatePicker
            placeholderText="Pick Up Date"
            selected={pickUpDate}
            onChange={handlePickUpDateChange}
            open={isOpen}
            onClickOutside={() => setIsOpen(false)}
            ref={inputRef}
            className="date-input-1"
          />
          <IoCalendarOutline
            color="#FA7C07"
            className="calendar-icon-1"
            size={13}
            onClick={handleCalendarClick}
          />
        </div>

        <div className="dropdown-main-1">
          <select
            className="dropdown-select-main"
            value={selectedCarCategory}
            onChange={(e) => setselectedCarCategory(e.target.value)}
          >
            <option value="" >
              Vehicle Type
            </option>
            {carCategoriesOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
          <IoIosArrowDown className="drop-icon-1" color="#FA7C07" size={15} />
        </div>

        <div className="dropdown-main-1">
          <div className="search-input-container-1">
            <input
              className="search-input-1"
              type="text"
              placeholder="Search Name"
              value={searchByName}
              onChange={(e) => setSearchByName(e.target.value)}
            />
            <AiOutlineSearch size={15} className="search-iconn-1" />
          </div>
        </div>
      </div>

      <Table headers={headers} data={filteredBookings} />
    </div>
  );
};

export default BookingPending;


