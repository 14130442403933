import axios from "axios";

export function fetchAllDriver() {
  return new Promise(async (resolve) => {
    try {
      const response = await axios.get(
        "https://api.yatricabs.com/v1/admin/drivers"
      );
      resolve(response.data.data);
    } catch (e) {
      console.log("Error in fetching Data", e);
    }
  });
}

export function fetchApprovalData() {
  return new Promise(async (resolve) => {
    try {
      const response = await axios.get(
        "https://api.yatricabs.com/v1/admin/drivers/approval-pending"
      );
      resolve(response.data.data);
    } catch (e) {
      console.log("Error in fetching Data", e);
    }
  });
}

export function fetchInactivelData() {
  return new Promise(async (resolve) => {
    try {
      const response = await axios.get(
        "https://api.yatricabs.com/v1/admin/drivers/inactive"
      );
      resolve(response.data.data);
    } catch (e) {
      console.log("Error in fetching Data", e);
    }
  });
}

export function createDriver(data) {
  return new Promise(async (resolve) => {
    try {
      const response = await axios.post(
        "https://api.yatricabs.com/v1/admin/drivers",
        JSON.stringify(data)
      );

      resolve(response.data);
    } catch (e) {
      console.log("Error in fetching Data", e);
    }
  });
}
