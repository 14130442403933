import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import axios from 'axios'

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const initialState = {
    cabData:null,
    allCabs:{
        data:null,
        loading:'idle',
        error:null  
    },
}
export const fetchCabData = createAsyncThunk('assignCabData/fetchCabData',async({bookingId , driverId})=>{
    const response = await axios.get(`${API_BASE_URL}/v1/admin/bookings/cars?bookingId=${bookingId}&driverId=${driverId}`);
    console.log('api cab response',response.data);
    return response.data;
})

const assignCabDataSlice = createSlice({
    name:'assignCabData',
    initialState,
    reducers:{
        assignCab:(state,action)=>{
            state.cabData = action.payload
        }
    },
    extraReducers: (builder)=>{
        builder.addCase(fetchCabData.pending,(state)=>{
            state.allCabs.loading ='loading'
        })
        .addCase(fetchCabData.fulfilled,(state,action)=>{
            state.allCabs.loading = 'succeeded';
            state.allCabs.data = action.payload
        })
        .addCase(fetchCabData.rejected,(state,action)=>{
            state.allCabs.loading = 'failed';
            state.allCabs.error = action.error.message;
            state.allCabs.data=null;
        })
    }
})

export const {assignCab}  = assignCabDataSlice.actions;
export default assignCabDataSlice.reducer;