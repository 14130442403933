// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.createButton-tariff {
  border-radius: 5px;
  background: #38b000;
  color: #fff; 
  width: 89px;
  height: 28px;
  flex-shrink: 0;
  border: none;
  box-shadow: none;
  font-size: 10px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.btn-container{
  display: flex;
  flex-direction: row;
  gap: 22px;
  margin-right: 20px;
}
.createButton-tariff-active {
  border-radius: 5px;
  background: #fff;
  color: #38b000; /* White text color for active button */
  border: 1px solid #38b000;
  box-shadow: 0px 4px 22px 0px rgba(0, 0, 0, 0.17);
  width: 89px;
  height: 28px;
  flex-shrink: 0;
  font-size: 10px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.h3-tariff{
  color: #000;

font-family: Inter;
font-size: 11px;
font-style: normal;
font-weight: 400;
line-height: normal;
}

.h2-tariff{
  color: #000;

font-family: Inter;
font-size: 15px;
font-style: normal;
font-weight: 500;
line-height: normal;
}`, "",{"version":3,"sources":["webpack://./src/components/VendorTarrifManagement/VendorTarrif.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,mBAAmB;EACnB,WAAW;EACX,WAAW;EACX,YAAY;EACZ,cAAc;EACd,YAAY;EACZ,gBAAgB;EAChB,eAAe;EACf,eAAe;EACf,gCAAgC;AAClC;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,SAAS;EACT,kBAAkB;AACpB;AACA;EACE,kBAAkB;EAClB,gBAAgB;EAChB,cAAc,EAAE,uCAAuC;EACvD,yBAAyB;EACzB,gDAAgD;EAChD,WAAW;EACX,YAAY;EACZ,cAAc;EACd,eAAe;EACf,eAAe;EACf,gCAAgC;AAClC;AACA;EACE,WAAW;;AAEb,kBAAkB;AAClB,eAAe;AACf,kBAAkB;AAClB,gBAAgB;AAChB,mBAAmB;AACnB;;AAEA;EACE,WAAW;;AAEb,kBAAkB;AAClB,eAAe;AACf,kBAAkB;AAClB,gBAAgB;AAChB,mBAAmB;AACnB","sourcesContent":[".createButton-tariff {\n  border-radius: 5px;\n  background: #38b000;\n  color: #fff; \n  width: 89px;\n  height: 28px;\n  flex-shrink: 0;\n  border: none;\n  box-shadow: none;\n  font-size: 10px;\n  cursor: pointer;\n  transition: all 0.3s ease-in-out;\n}\n\n.btn-container{\n  display: flex;\n  flex-direction: row;\n  gap: 22px;\n  margin-right: 20px;\n}\n.createButton-tariff-active {\n  border-radius: 5px;\n  background: #fff;\n  color: #38b000; /* White text color for active button */\n  border: 1px solid #38b000;\n  box-shadow: 0px 4px 22px 0px rgba(0, 0, 0, 0.17);\n  width: 89px;\n  height: 28px;\n  flex-shrink: 0;\n  font-size: 10px;\n  cursor: pointer;\n  transition: all 0.3s ease-in-out;\n}\n.h3-tariff{\n  color: #000;\n\nfont-family: Inter;\nfont-size: 11px;\nfont-style: normal;\nfont-weight: 400;\nline-height: normal;\n}\n\n.h2-tariff{\n  color: #000;\n\nfont-family: Inter;\nfont-size: 15px;\nfont-style: normal;\nfont-weight: 500;\nline-height: normal;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
