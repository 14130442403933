import React, { useState, useEffect } from "react";
import "./activecity.css";
import { useSelector, useDispatch} from "react-redux";
import { FaPen } from "react-icons/fa";
import Notification from "../../Notification";
import { selectAllActiveCity, editCurrentCity, toggleCityStatusForActive} from "../geographySlice";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import { Toggle } from "../../TariffManagement/Toggle";
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const TableItem = ({ item, index, deleteItem, inactiveItem, editItem }) => {
  const rowClass = index % 2 === 0 ? "table-row-even" : "table-row-odd";


  const logState = (state, item, dispatch) => {
    const newStatus = state ? "Inactive" : "Active";
    inactiveItem(item, newStatus, dispatch);
};
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentComponent = 'active-city'

  // Handle edit button click
  const handleEditbutton = () => {
    dispatch(editCurrentCity([item.city,item.state,item.zeroPaymentBooking === true ? "Enabled" : "Disabled"]))
    console.log("checking current location")
    navigate(`/geography/${currentComponent}/edit-location/${item._id}`)
  };

  return (
    <tr className={rowClass}>
      <td>{index + 1}</td>
      <td>{item?.city}</td>
      <td>{item?.state}</td>
      <td>{item?.zeroPaymentBooking === true ? "Enabled" : "Disabled"}</td>
      <td>{item?.status}</td>
      <td>
      <div className="icons" style={{height:"10px"}}>
        <FaPen className="icon-edit " onClick={handleEditbutton} style={{marginTop :"-5px"}} />
        <Toggle
            toggled={item.status === "Active" ? false : true}
            onClick={(state) => logState(state, item._id, dispatch)}
          />
        </div>
      </td>
    </tr>
  );
};

const Table = ({ headers, data }) => {
  const [rawdata, setRawData] = useState(data);
  const [showNotification, setShowNotification] = useState(null);
  const [showMessage, setShowMessage] = useState(null);
  const updatedActivecity = useSelector(selectAllActiveCity);

  const deleteItem = async (item, type, message) => {
    try {
      // Perform API call to delete data
      await axios.delete(
        `${API_BASE_URL}/v1/admin/locations/${item?._id}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      updatedActivecity = updatedActivecity.filter(
        (data) => item._id !== data._id
      );
      setRawData(updatedActivecity);
      setShowNotification(type);
      setShowMessage(message);
    } catch (error) {
      // Handle error and show appropriate message
      console.error("Error deleting data:", error);
      setShowNotification("error");
      setShowMessage("Failed to delete data");
    }
  };

  const inactiveItem = async (item,newStatus, dispatch, type, message) => {
    console.log(item)
    try {
      // Perform API call to update status to "inactive"
      const response = await axios.put(
        `${API_BASE_URL}/v1/admin/locations/${item}/status`,
        { status: "Inactive" },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log(response)
      if (response.status === 200) {
        //setRawData(updatedActivecity);
        dispatch(toggleCityStatusForActive(item, newStatus));
        toast.success(response.data.message);
        setTimeout(() => {
          window.location.reload();
        }, 1000);

      } 
    } catch (error) {
      // Handle error and show appropriate message
      console.error("Error updating status:", error);
      toast.error("Failed to update status");
    }
  };

  const editItem = (type, message) => {
    setShowNotification(type);
    setShowMessage(message);
  };

  const handleNotificationClose = () => {
    setShowNotification(false);
  };

  return (
    <>
    <Toaster />
    <div className="table-containers">
      <div className="table-scroll">
        <table className="table">
          <thead>
            <tr>
              {headers?.map((header, index) => (
                <th key={index}>
                  {index !== headers.length - 1 && <div className='th-line'></div>}
                  {header}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {updatedActivecity?.map((item, index) => (
              <TableItem
                key={index}
                item={item}
                index={index}
                deleteItem={deleteItem}
                inactiveItem={inactiveItem}
                editItem={editItem}
              />
            ))}
          </tbody>
        </table>
        {showNotification && (
          <Notification
            type={`${showNotification}`}
            message={`${showMessage}`}
            onClose={handleNotificationClose}
          />
        )}
      </div>
    </div>
    </>
  );
};

const ActiveCity = ({ data }) => {
  const headers = [
    "Sr No",
    "City Name",
    "State Name",
    "Pay To Driver",
    "Status",
    "Action",
  ];
  const activedata = data;
  const [tableKey, setTableKey] = useState(0);

  useEffect(() => {
    setTableKey((prevKey) => prevKey + 1);
  }, [data]);

  return (
    <div>
      <Table key={tableKey} headers={headers} data={activedata} />
    </div>
  );
};

export default ActiveCity;
