import React, { useEffect, useState } from "react";
//import { Document, Page } from "react-pdf";
import "./ModifyCarData.css";
import axios from "axios";
import DropdownColumnCar from "../../Booking/Dropdown";
import { useParams, Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchSingleCarDetailsAsync } from "../CarDetailsSlice";
import { resetCompState } from "../carSlice";
//import Dropdown from 'react-dropdown-select';
import toast, { Toaster } from "react-hot-toast";
import { FaLongArrowAltRight } from "react-icons/fa";
import Loader from "../../Loader";
const DropdownColumn = ({
  title,
  options,
  selectedValue, 
  handleChange,
  selectedFile,
  removeFile,
}) => {
  return (
    <div className="View-add-new-car-dropdown-column">
      <h3>{title}</h3>
      <select value={selectedValue} onChange={handleChange}>
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
};

const ModifyCarData = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [cityoption, setCityOption] = useState([]);
  const [driveroption, setDriverOption] = useState([]);
  const [randomCategory, setRandomCategory] = useState([]);
  const [selectcarmanufacturerArray, setSelectcarmanufacturerArray] = useState(
    []
  );
  const [selectCarModelArray, setSelectCarModelArray] = useState([]);

  const [selectcity, setSelectCity] = useState("");
  const [selectcarmanufacturer, setSelectCarManufacturer] = useState("");
  const [selectCategory, setSelectCategory] = useState("");
  const [selectcarmodel, setSelectCarModel] = useState("");
  const [selectcarCategory, setSelectCarCategory] = useState("");
  const [selectfuelcategory, setSelectFuelCategory] = useState("");
  const [carFrontPhoto, setcarFrontPhoto] = useState(null);
  const [permitDetails, setpermitDetails] = useState(null);
  const [carPhotoSide, setcarPhotoSide] = useState(null);
  const [PUCTest, setPUCTest] = useState(null);
  const [registrationCertificate, setregistrationCertificate] = useState(null);
  const [insurancePolicy, setinsurancePolicy] = useState(null);
  const [carRegistrationYear, setCarRegistrationYear] = useState("");
  const [registrationCertificateBack, setregistrationCertificateBack] =
    useState(null);
  const [showCloseIconCar, setShowCloseIconCar] = useState(false);
  const [showCloseIconPermit, setShowCloseIconPermit] = useState(false);
  const [showCloseIconFront, setShowCloseIconFront] = useState(false);
  const [showCloseIconPUC, setShowCloseIconPUC] = useState(false);
  const [showCloseIconCertificate, setShowCloseIconCertificate] =
    useState(false);
  const [showCloseIconCertificateBack, setShowCloseIconCertificateBack] =
    useState(false);
  const [showCloseIconPolicy, setShowCloseIconPolicy] = useState(false);
  const [carColor, setCarColor] = useState("");
  const [rcNumber, setrcNumber] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const [insuranceExpiryDate, setInsuranceExpiryDate] = useState("");
  const [pucExpiryDate, setPucExpiryDate] = useState("");
  const [permitDetailsExpiryDate, setPermitDetailsExpiryDate] = useState(null);
  const [updatedData, setUpdatedData] = useState();
  const [carLocation, setCarLocation] = useState("");

  const id = useParams();
  const carid = id.id;

  const singleCarDetail = useSelector((state) => state.singleCar.singleCarData);
  useEffect(() => {
    // Assuming carid is the ID of the car you want to fetch
    dispatch(fetchSingleCarDetailsAsync(carid));
    checkParentLocation(window.location.pathname);
  }, []);

  const [inputValue, setInputValue] = useState({
    driver: "",
    carCategory: "",
    carMake: "",
    fuelType: "",
    carColor: "",
    rcNumber: "",
    permitExpiryDate: "",
    insuranceExpiryDate: "",
    pucExpiryDate: "",
    carRegistrationYear: "",

    carFrontPhoto: "",

    location: "",
  });

  const [carCategory, setCarCategory] = useState("");
  const [driver, setDriver] = useState("");
  const [callServer, setCallServer] = useState(false);

  useEffect(() => {
    if (singleCarDetail) {
      setCarLocation(
        singleCarDetail.location?.city + ", " + singleCarDetail.location?.state
      );
      setDriver(
        singleCarDetail.driver?.firstName +
          " " +
          singleCarDetail.driver?.lastName
      );
      setCarCategory(singleCarDetail.carCategory?.category);
      setcarFrontPhoto(singleCarDetail.carPhoto?.frontPhoto);
      setcarPhotoSide(singleCarDetail.carPhoto?.sidePhoto);
      setregistrationCertificate(
        singleCarDetail.registrationCertificate?.frontPhoto
      );
      setregistrationCertificateBack(
        singleCarDetail.registrationCertificate?.backPhoto
      );
      setinsurancePolicy(singleCarDetail.insurance?.photo);
      setPUCTest(singleCarDetail.puc?.photo);
      setpermitDetails(singleCarDetail.permit?.photo);
      setInsuranceExpiryDate(singleCarDetail.insurance?.expiryDate);
      setPucExpiryDate(singleCarDetail.puc?.expiryDate);
      setPermitDetailsExpiryDate(singleCarDetail.permit?.expiryDate);
      setCarColor(singleCarDetail.carColor);
      setrcNumber(singleCarDetail.registrationCertificate?.number);
      setCarRegistrationYear(singleCarDetail.registrationCertificate?.year);
      setSelectCarCategory(singleCarDetail.carCategory?._id);
      setSelectCity(singleCarDetail.location?._id);
      setSelectCarManufacturer(singleCarDetail.carMake);
      setSelectCarModel(singleCarDetail.carModel);
      setSelectFuelCategory(singleCarDetail.fuelType);

      setInputValue({
        location: singleCarDetail.location?._id,
        driver: singleCarDetail.driver?._id,
        carCategory: singleCarDetail.carCategory?._id,
        carModel: singleCarDetail.carModel,
        carMake: singleCarDetail.carMake,
        fuelType: singleCarDetail.fuelType,
        carColor: singleCarDetail.carColor,
        carRegistrationYear: singleCarDetail.registrationCertificate?.year,
        rcNumber: singleCarDetail.registrationCertificate?.number,
        permitExpiryDate: singleCarDetail.permit?.expiryDate,
        insuranceExpiryDate: singleCarDetail.insurance?.expiryDate,
        pucExpiryDate: singleCarDetail.puc?.expiryDate,

        carFrontPhoto: singleCarDetail.carPhoto?.frontPhoto,
      });
    }
  }, [singleCarDetail]);

  console.log("Input values", inputValue);
  // rcFrontPhoto
  // rcBackPhoto
  // carSidePhoto
  // permitPhoto
  // pucPhoto
  // insurancePhoto

  const handleFileChange = (event, setFile, setShowCloseIcon) => {
    const file = event.target.files[0];
    setInputValue({
      ...inputValue,
      [event.target.name]: URL.createObjectURL(file),
    });

    setUpdatedData({
      ...updatedData,
      [event.target.name]: file,
    });
    if (file) {
      setFile(file);
      setShowCloseIcon(true);
    }
  };

  const handleRemoveFile = (setFile, setShowCloseIcon) => {
    setFile(null);
    setShowCloseIcon(false);
  };

  const FuelCategoryOption = [
    { label: "Select Fuel Category", value: "city0" },
    { label: "Petrol", value: "Petrol" },
    { label: "Diesel", value: "Diesel" },
    { label: "CNG", value: "CNG" },
    { label: "Electric", value: "Electric" },
  ];
  const handleSubmit = async () => {
    console.log(selectCategory);
    console.log(
      selectcity,
      selectcarCategory,
      selectcarmanufacturer,
      selectcarmodel,
      selectfuelcategory,
      selectcarmodel,
      carFrontPhoto,
      permitDetails,
      carPhotoSide,
      PUCTest,
      registrationCertificate,
      registrationCertificateBack
    );

    setUpdatedData({
      ...updatedData,
      location: selectcity,
      driver: selectCategory,
      carCategory: selectcarCategory,
      carModel: selectcarmodel,
      carMake: selectcarmanufacturer,
      fuelType: selectfuelcategory,
      carColor: carColor,
      carRegistrationYear: carRegistrationYear,
      rcNumber: rcNumber,
      permitExpiryDate: permitDetailsExpiryDate,
      insuranceExpiryDate: insuranceExpiryDate,
      pucExpiryDate: pucExpiryDate,
    });
    // const formData = new FormData();

    // formData.append("location", selectcity);
    // formData.append("driver", selectCategory);
    // formData.append("carCategory", selectcarCategory);
    // formData.append("carMake", selectcarmanufacturer);
    // formData.append("carModel", selectcarmodel);
    // formData.append("fuelType", selectfuelcategory);
    // formData.append("carColor", carColor);
    // formData.append("carManufacturingYear", "2024");
    // formData.append("rcNumber", rcNumber);
    // formData.append("permitExpiryDate", expiryDate);
    // formData.append("insuranceExpiryDate", insuranceExpiryDate);
    // formData.append("pucExpiryDate", pucExpiryDate);
    // formData.append("rcFrontPhoto", registrationCertificate, registrationCertificate.name);
    // formData.append("rcBackPhoto", registrationCertificateBack, registrationCertificateBack.name);
    // formData.append("carFrontPhoto", carFrontPhoto, carFrontPhoto.name);
    // formData.append("carSidePhoto", carPhotoSide, carPhotoSide.name);
    // formData.append("permitPhoto", permitDetails, permitDetails.name);
    // formData.append("pucPhoto", PUCTest, PUCTest.name);
    // formData.append("insurancePhoto", insurancePolicy, insurancePolicy.name);

    // for (const pair of formData.entries()) {
    //   console.log(pair[0] + ', ' + pair[1]);
    // }
    setCallServer(true);

    console.log("Form Values:", updatedData);
  };
  const extractLocationsAsOptions = (dataArray) => {
    if (Array.isArray(dataArray.data)) {
      return dataArray.data.map((item) => ({
        label: item.location,
        value: item._id,
      }));
    }
    return [];
  };
  const extractDriversAsOptions = (dataArray) => {
    if (Array.isArray(dataArray.data)) {
      return dataArray.data.map((item) => ({
        label: item.firstName,
        value: item._id,
      }));
    }
    return [];
  };

  const extractCategoriesAsOptions = (dataArray) => {
    if (Array.isArray(dataArray.data)) {
      return dataArray.data.map((item) => ({
        label: item.category,
        value: item._id,
      }));
    }
    return [];
  };
  const extractCarManufacturesAsOptions = (dataArray) => {
    if (Array.isArray(dataArray.data)) {
      return dataArray.data.map((item) => ({
        label: item.make,
        value: item.make,
      }));
    }
    return [];
  };
  const extractCarModelsAsOptions = (dataArray) => {
    if (Array.isArray(dataArray.models)) {
      return dataArray.models.map((item) => ({
        label: item,
        value: item,
      }));
    }
    return [];
  };

  useEffect(() => {
    const locations = async () => {
      try {
        const locationResponse = await fetch(
          "https://api.yatricabs.com/v1/locations"
        );
        const locationData = await locationResponse.json();

        const locationOptions = extractLocationsAsOptions(locationData);
        locationOptions.unshift({ label: "Select the location" });
        setCityOption(locationOptions);
        console.log("location options", locationOptions);
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };
    locations();
    const driver = async () => {
      try {
        const driverData = await axios.get(
          "https://api.yatricabs.com/v1/admin/drivers"
        );
        // const driverData = await drivers.json();
        console.log(driverData.data);
        const din = extractDriversAsOptions(driverData.data);
        din.unshift({ label: "Select the driver" });
        console.log("drivers options", din);
        setDriverOption(din);
        console.log(driveroption);
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };
    driver();
    const carCategories = async () => {
      try {
        const categories = await fetch(
          "https://api.yatricabs.com/v1/car-categories"
        );
        const categoriesData = await categories.json();
        const categoriesOptions = extractCategoriesAsOptions(categoriesData);
        categoriesOptions.unshift({ label: "Select the car category" });
        console.log("categoriesOptions", categoriesOptions);
        setRandomCategory(categoriesOptions);
        console.log(randomCategory);
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };

    carCategories();

    const carmanufacturing = async () => {
      try {
        const categories = await fetch("https://api.yatricabs.com/v1/car-makes");
        const categoriesData = await categories.json();
        console.log(categoriesData);
        const categoriesOptions =
          extractCarManufacturesAsOptions(categoriesData);
        categoriesOptions.unshift({ label: "Select the car category" });
        console.log(" manufacturescategoriesOptions", categoriesOptions);
        setSelectcarmanufacturerArray(categoriesOptions);
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };
    carmanufacturing();

    const carmodels = async () => {
      try {
        if (selectcarmanufacturer) {
          const categories = await fetch(
            `https://api.yatricabs.com/v1//car-models/${selectcarmanufacturer}`
          );
          const categoriesData = await categories.json();
          console.log(categoriesData.data[0]);
          const categoriesOptions = extractCarModelsAsOptions(
            categoriesData.data[0]
          );
          categoriesOptions.unshift({ label: "Select the car model" });
          console.log(categoriesOptions);
          setSelectCarModelArray(categoriesOptions);
        }
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };
    carmodels();
  }, [selectcarmanufacturer]);

  useEffect(() => {
    const dataUpdate = async () => {
      const headers = {
        "Content-Type": "multipart/form-data",
      };

      try {
        // Add a new car category
        await axios.put(
          `https://api.yatricabs.com/v1/admin/cars/${carid}`,
          updatedData,
          {
            headers,
          }
        );
        toast.success("Car Data Updated!");
        setUpdatedData();

        setTimeout(() => {
          window.location.reload();
        }, 3000);

        // e.target.reset();
        setDriver("");
        setCarCategory("");
        setcarFrontPhoto("");
        setcarPhotoSide("");
        setregistrationCertificate("");
        setregistrationCertificateBack("");
        setinsurancePolicy("");
        setPUCTest("");
        setpermitDetails("");
        setInsuranceExpiryDate("");
        setPucExpiryDate("");
        setPermitDetailsExpiryDate("");
      } catch (error) {
        toast.error(error.response.data.message);
        console.error("error ", error.response.data.message);
      }

      setCallServer(false);
    };

    if (callServer === true) {
      dataUpdate();
    }
  }, [updatedData]);

  const [previousLocation, setPreviousLocation] = useState();

  const handleToggleComponent = (value) => {
    dispatch(resetCompState(value));
    navigate(`/carmanagement/${value}`);
  };

  const checkParentLocation = (path) => {
    if (path.includes("InactiveCar")) {
      setPreviousLocation("Inactive Cars");
    } else if (path.includes("ApprovalPendingCar")) {
      setPreviousLocation("Approval Pending");
    } else {
      setPreviousLocation("View All Cars");
    }
  };

  console.log("select city", selectcity);
  console.log("Placeholder location", carLocation);
  console.log("selectcarmanufacturer", selectcarmanufacturer);

  return (
    <>
      <Toaster />

      <div className="add-new-car-vehicle-scrolbar" style={{ height: "100vh" }}>
        <div className="nav-content" style={{ marginTop: "25px" }}>
          <div className="header-text">
            <Link className="h3-booking">Fleet Management</Link>
            <FaLongArrowAltRight />
            {""}
            <Link className="h3-booking">{previousLocation}</Link>
            <FaLongArrowAltRight />
            <Link className="h2-booking">Edit Car Details</Link>
          </div> 
          {previousLocation === "View All Cars" && (
            <button
              className="createButton"
              onClick={() => handleToggleComponent("view-all-cars")}
            >
              View All Cars
            </button>
          )}

          {previousLocation === "Inactive Cars" && (
            <button
              className="createButton"
              onClick={() => {
                handleToggleComponent("inactive-cars");
              }}
            >
              Inactive Cars
            </button>
          )}

          {previousLocation === "Approval Pending" && (
            <button
              className="createButton"
              onClick={() => handleToggleComponent("approval-pending")}
            >
              Approval Pending
            </button>
          )}
        </div>
        <div className="add-new-car-vehicle">Vehicle Information</div>
        <div className="user-details">
          <div className="user-details-section">
            <div className="user-detail">
              <div className="user-heading">Location</div>
              <DropdownColumnCar
                placeholder={carLocation}
                options={cityoption}
                useInlineStyle={true}
                selectedValue={selectcity}
                handleChange={(e) => setSelectCity(e.target.value)}
              />
            </div>
            <div className="user-detail">
              <div className="user-heading">Car Make</div>
              <DropdownColumnCar
                placeholder={inputValue.carMake}
                options={selectcarmanufacturerArray}
                useInlineStyle={true}
                selectedValue={selectcarmanufacturer}
                handleChange={(e) => setSelectCarManufacturer(e.target.value)}
              />
            </div>
            <div className="user-detail">
              <div className="user-heading">Body Colour</div>
              <input
                type="text"
                className="custom-input"
                placeholder="Car Color"
                value={carColor}
                onChange={(e) => {
                  setCarColor(e.target.value);
                }}
              />
            </div>
          </div>
          <div className="user-details-section">
            <div className="user-detail">
              <div className="user-heading">Vehicle Owner / Driver</div>
              <DropdownColumnCar
                placeholder={driver}
                useInlineStyle={true}
                options={driveroption}
                selectedValue={selectCategory}
                handleChange={(e) => setSelectCategory(e.target.value)}
              />
            </div>
            <div className="user-detail">
              <div className="user-heading">Model</div>
              <DropdownColumnCar
                placeholder={inputValue.carModel}
                useInlineStyle={true}
                options={selectCarModelArray}
                selectedValue={selectcarmodel}
                handleChange={(e) => setSelectCarModel(e.target.value)}
              />
            </div>
            <div className="user-detail">
              <div className="user-heading">Year</div>
              <input
                type="text"
                className="custom-input"
                placeholder="y-y-y-y"
                value={carRegistrationYear}
                onChange={(e) => setCarRegistrationYear(e.target.value)}
              />
            </div>
          </div>
          <div className="user-details-section">
            <div className="user-detail">
              <div className="user-heading">Car Category</div>
              <DropdownColumnCar
                placeholder={carCategory}
                useInlineStyle={true}
                options={randomCategory}
                selectedValue={selectcarCategory}
                handleChange={(e) => setSelectCarCategory(e.target.value)}
              />
            </div>
            <div className="user-detail">
              <div className="user-heading">Fuel Type</div>
              <DropdownColumnCar
                placeholder={inputValue.fuelType}
                useInlineStyle={true}
                options={FuelCategoryOption}
                selectedValue={selectfuelcategory}
                handleChange={(e) => setSelectFuelCategory(e.target.value)}
              />
            </div>
            <div className="user-detail">
              <div className="user-heading">Vehicle Number</div>
              {/* <input type="text" placeholder="KA" className="custom-input-3" />
            <input type="text" className="custom-input-3" placeholder="01" />
            <input type="text" className="custom-input-3" placeholder="AB" />
            <input type="text" className="custom-input-3" placeholder="0000" /> */}
              <input
                type="text"
                className="custom-input-3"
                style={{ width: "calc(45% - 10px)" }}
                placeholder="Enter Vehicle Number"
                value={rcNumber}
                onChange={(e) => setrcNumber(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="add-new-car-vehicle">Upload Documents</div>
        <div className="user-details">
          <div className="user-details-section">
            <div className="user-detail">
              <div className="user-headin">Car Photo Front</div>
              <div className="dropdown">
                <div className="pic">
                  {carFrontPhoto && (
                    <>
                      <img
                        className="pic-selected"
                        src={carFrontPhoto}
                        alt="Selected"
                      />
                    </>
                  )}
                </div>
                <div className="dropdown-file">
                  <div className="file-container">
                    <label className="upload">
                      <input
                        name="carFrontPhoto"
                        type="file"
                        onChange={(e) =>
                          handleFileChange(
                            e,
                            setcarFrontPhoto,
                            setShowCloseIconCar
                          )
                        }
                      />
                      <span>Choose File</span>
                    </label>

                    {showCloseIconCar && (
                      <span
                        className="close-sign"
                        onClick={() =>
                          handleRemoveFile(
                            setcarFrontPhoto,
                            setShowCloseIconCar
                          )
                        }
                      >
                        &times;
                      </span>
                    )}
                  </div>
                  {carFrontPhoto && (
                    <p className="selected">
                      Selected File: {carFrontPhoto.name}
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div className="user-detail">
              <div className="user-headin">Permit Details</div>
              <div className="dropdown">
                <div className="dropdown-user-detail">
                  <div className="pic">
                    {permitDetails && (
                      <>
                        <img
                          className="pic-selected"
                          src={permitDetails}
                          alt="Selected"
                        />
                      </>
                    )}
                  </div>
                  <div className="dropdown-pic">
                    <input
                      type="date"
                      id="myDate"
                      name="permitDetailsExpiryDate"
                      onChange={(e) =>
                        setPermitDetailsExpiryDate(e.target.value)
                      }
                      value={permitDetailsExpiryDate}
                    ></input>
                    <br></br>expiry date
                  </div>
                </div>
                <div className="dropdown-file">
                  <div className="file-container">
                    <label className="upload">
                      <input
                        name="permitDetails"
                        type="file"
                        onChange={(e) =>
                          handleFileChange(
                            e,
                            setpermitDetails,
                            setShowCloseIconPermit
                          )
                        }
                      />
                      <span>Choose File</span>
                    </label>

                    {showCloseIconPermit && (
                      <span
                        className="close-sign"
                        onClick={() =>
                          handleRemoveFile(
                            setpermitDetails,
                            setShowCloseIconPermit
                          )
                        }
                      >
                        &times;
                      </span>
                    )}
                  </div>
                  {permitDetails && (
                    <p className="selected">
                      Selected File: {permitDetails.name}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="user-details-section">
            <div className="user-detail">
              <div className="user-headin">Car Photo Side</div>
              <div className="dropdown">
                <div className="pic">
                  {carPhotoSide && (
                    <>
                      <img
                        className="pic-selected"
                        src={carPhotoSide}
                        alt="Selected"
                      />
                      {/* {showCloseIcon && (
                      <span
                        className="close-sign"
                        onClick={() =>
                          handleRemoveFile(setcarPhotoSide, setShowCloseIcon)
                        }
                      >
                        &times;
                      </span>
                    )} */}
                    </>
                  )}
                </div>
                <div className="dropdown-file">
                  <div className="file-container">
                    <label className="upload">
                      <input
                        name="carPhotoSide"
                        type="file"
                        onChange={(e) =>
                          handleFileChange(
                            e,
                            setcarPhotoSide,
                            setShowCloseIconFront
                          )
                        }
                      />
                      <span>Choose File</span>
                    </label>

                    {showCloseIconFront && (
                      <span
                        className="close-sign"
                        onClick={() =>
                          handleRemoveFile(
                            setcarPhotoSide,
                            setShowCloseIconFront
                          )
                        }
                      >
                        &times;
                      </span>
                    )}
                  </div>
                  {carPhotoSide && (
                    <p className="selected">
                      Selected File: {carPhotoSide.name}
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div className="user-detail">
              <div className="user-headin">PUC Test</div>
              <div className="dropdown">
                <div className="dropdown-user-detail">
                  <div className="pic">
                    {PUCTest && (
                      <>
                        <img
                          className="pic-selected"
                          src={PUCTest}
                          alt="Selected"
                        />
                        {/* {showCloseIcon && (
                        <span
                          className="close-sign"
                          onClick={() =>
                            handleRemoveFile(setPUCTest, setShowCloseIcon)
                          }
                        >
                          &times;
                        </span>
                      )} */}
                      </>
                    )}
                  </div>
                  <div className="dropdown-pic">
                    <input
                      type="date"
                      id="myDate"
                      value={pucExpiryDate}
                      name="pucExpiryDate"
                      onChange={(e) => setPucExpiryDate(e.target.value)}
                    />
                    <br></br>expiry date
                  </div>
                </div>
                <div className="dropdown-file">
                  <div className="file-container">
                    <label className="upload">
                      <input
                        name="PUCTest"
                        type="file"
                        onChange={(e) =>
                          handleFileChange(e, setPUCTest, setShowCloseIconPUC)
                        }
                      />
                      <span>Choose File</span>
                    </label>
                    {showCloseIconPUC && (
                      <span
                        className="close-sign"
                        onClick={() =>
                          handleRemoveFile(setPUCTest, setShowCloseIconPUC)
                        }
                      >
                        &times;
                      </span>
                    )}
                  </div>
                  {PUCTest && (
                    <p className="selected">Selected File: {PUCTest.name}</p>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="user-details-section">
            <div className="user-detail">
              <div className="user-headin">Registration Certificate Front</div>
              <div className="dropdown">
                <div className="pic">
                  {registrationCertificate && (
                    <>
                      <img
                        className="pic-selected"
                        src={registrationCertificate}
                        alt="Selected"
                      />
                      {/* {showCloseIcon && (
                      <span
                        className="close-sign"
                        onClick={() =>
                          handleRemoveFile(
                            setregistrationCertificate,
                            setShowCloseIcon
                          )
                        }
                      >
                        &times;
                      </span>
                    )} */}
                    </>
                  )}
                </div>
                <div className="dropdown-file">
                  <div className="file-container">
                    <label className="upload">
                      <input
                        name="registrationCertificate"
                        type="file"
                        onChange={(e) =>
                          handleFileChange(
                            e,
                            setregistrationCertificate,
                            setShowCloseIconCertificate
                          )
                        }
                      />
                      <span>Choose File</span>
                    </label>
                    {showCloseIconCertificate && (
                      <span
                        className="close-sign"
                        onClick={() =>
                          handleRemoveFile(
                            setregistrationCertificate,
                            setShowCloseIconCertificate
                          )
                        }
                      >
                        &times;
                      </span>
                    )}
                  </div>
                  {registrationCertificate && (
                    <p className="selected">
                      Selected File: {registrationCertificate.name}
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div className="user-detail">
              <div className="user-headin">Insurance Policy</div>
              <div className="dropdown">
                <div className="dropdown-user-detail">
                  <div className="pic">
                    {" "}
                    {insurancePolicy && (
                      <>
                        <img
                          className="pic-selected"
                          src={insurancePolicy}
                          alt="Selected"
                        />
                      </>
                    )}
                  </div>
                  <div className="dropdown-pic">
                    <input
                      type="date"
                      id="myDate"
                      value={insuranceExpiryDate}
                      name="insuranceExpiryDate"
                      onChange={(e) => setInsuranceExpiryDate(e.target.value)}
                    />
                    <br></br>expiry date
                  </div>
                </div>
                <div className="dropdown-file">
                  <div className="file-container">
                    <label className="upload">
                      <input
                        name="insurancePolicy"
                        type="file"
                        onChange={(e) =>
                          handleFileChange(
                            e,
                            setinsurancePolicy,
                            setShowCloseIconPolicy
                          )
                        }
                      />
                      <span>Choose File</span>
                    </label>
                    {showCloseIconPolicy && (
                      <span
                        className="close-sign"
                        onClick={() =>
                          handleRemoveFile(
                            setinsurancePolicy,
                            setShowCloseIconPolicy
                          )
                        }
                      >
                        &times;
                      </span>
                    )}
                  </div>
                  {insurancePolicy && (
                    <p className="selected">
                      Selected File: {insurancePolicy.name}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="user-details-section">
            <div className="user-detail">
              <div className="user-headin">Registration Certificate Back</div>
              <div className="dropdown">
                <div className="pic">
                  {" "}
                  {registrationCertificateBack && (
                    <>
                      <img
                        className="pic-selected"
                        src={registrationCertificateBack}
                        alt="Selected"
                      />
                      {/* {showCloseIcon && (
                      <span
                        className="close-sign"
                        onClick={() =>
                          handleRemoveFile(
                            setregistrationCertificateBack,
                            setShowCloseIcon
                          )
                        }
                      >
                        &times;
                      </span>
                    )} */}
                    </>
                  )}
                </div>
                <div className="dropdown-file">
                  <div className="file-container">
                    <label className="upload">
                      <input
                        name="registrationCertificateBack"
                        type="file"
                        onChange={(e) =>
                          handleFileChange(
                            e,
                            setregistrationCertificateBack,
                            setShowCloseIconCertificateBack
                          )
                        }
                      />
                      <span>Choose File</span>
                    </label>
                    {showCloseIconCertificateBack && (
                      <span
                        className="close-sign"
                        onClick={() =>
                          handleRemoveFile(
                            setregistrationCertificateBack,
                            setShowCloseIconCertificateBack
                          )
                        }
                      >
                        &times;
                      </span>
                    )}
                  </div>
                  {registrationCertificateBack && (
                    <p className="selected">
                      Selected File: {registrationCertificateBack.name}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div style={{ display: "flex", justifyContent: "center" }}>
          <button
            style={{
              borderRadius: "5px",
              margin: "10px",
              border: "0.5px solid #38B000",
              background: "#38B000",
              width: "150px",
              padding: "6px",
              color: "#FFF",
              fontSize: "15px",
            }}
            onClick={handleSubmit}
          >
            Modify
          </button>
        </div>
      </div>
    </>
  );
};

export default ModifyCarData;
