import { useLocation } from "react-router-dom";
import "./Page404.css";

const Page404 = () => {
  const location = useLocation();

  return (
    <div className="not-found-box">
      <div className="not-found-container">
        <h1>404 - Page Not Found</h1>
        <p>
          Oops! The Route{" "}
          <code className="route-text">{location.pathname}</code> you are
          Searching for does not exist.
        </p>
      </div>
    </div>
  );
};

export default Page404;
