import React from "react";
import "./AdvancePay.css";
const AdvancePay = ({
	handleSubmit,
	tripType,
	estimatedFare,
	setSelectedAdvanceAmount,
	selectedAdvanceAmount,
	zeroPaymentBooking,
}) => {
	if (!estimatedFare) {
		return <div>First Select The tariff.....</div>; // Loading state
	}

	const handleSelection = (percentage) => {
		const advanceAmount = percentage;
		setSelectedAdvanceAmount(advanceAmount);
	};

	const handleBookingClick = (e) => {
		handleSubmit(e); // Pass the selected advance amount to booking creation
	};

	return (
		<section className="AdvancePay-main-container">
			<p>Advance Payable for {tripType}</p>
			<p>Amount: Rs. {estimatedFare?.toFixed(2)} (incl. of all taxes)</p>

			<div className="select-multi-pay-container">
				{/* 0% Selection */}
				{zeroPaymentBooking && (
					<div className="sub-amount-wrapper">
						<div className="radiobutton-wrapper">
							<input
								type="radio"
								id="radio001"
								name="payment"
								onChange={() => handleSelection(0)}
							/>
							<label htmlFor="radio001"></label>
							<p>Rs. 0.00</p>
						</div>
						<div className="percentage-div">
							<p>0%</p>
						</div>
					</div>
				)}

				{/* 25% Selection */}
				<div className="sub-amount-wrapper">
					<div className="radiobutton-wrapper">
						<input
							type="radio"
							id="radio002"
							name="payment"
							onChange={() => handleSelection(25)}
						/>
						<label htmlFor="radio002"></label>
						<p>Rs. {(estimatedFare * 0.25).toFixed(2)}</p>
					</div>
					<div className="percentage-div">
						<p>25%</p>
					</div>
				</div>

				{/* 50% Selection */}
				<div className="sub-amount-wrapper">
					<div className="radiobutton-wrapper">
						<input
							type="radio"
							id="radio003"
							name="payment"
							onChange={() => handleSelection(50)}
						/>
						<label htmlFor="radio003"></label>
						<p>Rs. {(estimatedFare * 0.5).toFixed(2)}</p>
					</div>
					<div className="percentage-div">
						<p>50%</p>
					</div>
				</div>

				{/* 100% Selection */}
				<div className="sub-amount-wrapper">
					<div className="radiobutton-wrapper">
						<input
							type="radio"
							id="radio004"
							name="payment"
							onChange={() => handleSelection(100)}
						/>
						<label htmlFor="radio004"></label>
						<p>Rs. {(estimatedFare * 1).toFixed(2)}</p>
					</div>
					<div className="percentage-div">
						<p>100%</p>
					</div>
				</div>
			</div>

			<div className="Advance-pay-btn-holder">
				<button className="filled-btn-admin" onClick={handleBookingClick}>
					Create Booking
				</button>
			</div>
		</section>
	);
};

export default AdvancePay;
