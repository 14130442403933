import React from 'react'
import './FareDetails.css'

const FareDetails_Local = ({fareDetails}) => {
    if (!fareDetails) {
        return <>
            <div className="fd-details-container">
                <div className="head-title"><p>Fare Details</p></div>

                <div className="subheader-strong-container">
                    <p>Ride Estimate</p>
                    <div>
                        <b>Rs. 0.00</b>
                        <p>(incl. of all taxes)</p>
                    </div>
                </div>


                <div className="row-flexer">
                    <p>Included Hrs</p>
                    <p>0 Hrs</p>
                </div>

                <div className="row-flexer">
                    <p>Included KM</p>
                    <p>0 KM</p>
                </div>

                <div className="row-flexer">
                    <p>Base Fare</p>
                    <p>Rs. 0.00</p>
                </div>

                <div className="row-flexer">
                    <p>GST @ 5%</p>
                    <p>Rs. 0.00</p>
                </div>


            </div>



          
        </>
    }

    console.log("fareDetails.fareDetails.estimatedFare:",fareDetails?.fareDetails?.estimatedFare);
    return (
        <>
            <div className="fd-details-container">
                <div className="head-title"><p>Fare Details</p></div>

                <div className="subheader-strong-container">
                    <p>Ride Estimate</p>
                    <div>
                        <b>Rs. {fareDetails?.fareDetails?.estimatedFare.toFixed(2)}</b>
                        <p>(incl. of all taxes)</p>
                    </div>
                </div>


                <div className="row-flexer">
                    <p>Included Hrs</p>
                    <p>{fareDetails?.fareDetails?.minHrs} Hrs</p>
                </div>

                <div className="row-flexer">
                    <p>Included KM</p>
                    <p>{fareDetails?.fareDetails?.minKm} KM</p>
                </div>

                <div className="row-flexer">
                    <p>Base Fare</p>
                    <p>Rs. {fareDetails?.fareDetails?.baseFare.toFixed(2)}</p>
                </div>

                <div className="row-flexer">
                    <p>GST @ {fareDetails?.fareDetails?.gstRate}%</p>
                    <p>Rs. {fareDetails?.fareDetails?.gstAmount.toFixed(2)}</p>
                </div>


            </div>



            <div className="info-container">
                <div className="infohead">Inclusions</div>
                <div className="info-wrapper">
                    <p>Base Fare (Include {fareDetails?.fareDetails?.minKm} Km {fareDetails?.fareDetails?.minHrs} Hrs) </p>
                    <p>GST ({fareDetails?.fareDetails?.gstRate}%)</p>
                </div>
            </div>

            <div className="info-container">
                <div className="infohead">Exclusion</div>
                <div className="info-wrapper">
                    <p>Pay ₹{fareDetails?.fareDetails?.extraKmCharge}/km after {fareDetails?.fareDetails?.minKm} km </p>
                    <p>Pay ₹{fareDetails?.fareDetails?.extraHrsCharge}/hrs after {fareDetails?.fareDetails?.minHrs}hrs</p>
                    <p>Toll / State tax / Parking</p>
                </div>
            </div>

        </>
    )
}

export default FareDetails_Local