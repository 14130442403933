import React, { Suspense, useEffect, useState } from "react";
import AssignDriverData from "./AssignDriverData";
import AssignCar from "./AssignCarData";
import AssignDriverOtherCities from "./AssignDriverOtherCities";
import { useDispatch, useSelector } from "react-redux";
import {
  assignDriver,
  fetchDriverData1,
} from "./assignDriverSlice";
import { fetchbookingDetailsAsync, selectbookingDetails } from "../Details/DetailsSlice";
import { assignCab, fetchCabData } from "./assignCabSlice";
import "./AssignSection.css";
import { useParams, useNavigate } from "react-router-dom";
import { IoArrowBackCircleSharp } from "react-icons/io5";
import { setToggleForTaskDetails } from "./assignCab&DriverSlice";
import DefaultFile from "./DefaultFile";

function AssignSection() {
  const [driverState, setDriverState] = useState(false);
  const [otherCitiesState, setOtherCitiesState] = useState(false);
  const bookingDetails = useSelector(selectbookingDetails);
  const { driverData, allDrivers } = useSelector((state) => state.assignDrivers);
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleAssignDriver = (id, driverId) => {
    setDriverState(true);
    setOtherCitiesState(false); // Ensure otherCitiesState is false

    console.log("driverId and bookingId :---", driverId, id);

    dispatch(fetchCabData({ id, driverId }));
  };
  console.log("Booking details in assign section:-", bookingDetails);

  useEffect(() => {
    dispatch(fetchDriverData1(id));
    dispatch(assignDriver(null));
    dispatch(assignCab(null));
  }, [dispatch, id]);

  return (
    <div>
      <div className="assign-container">
        <div className="assign-buttons">
          <button
            onClick={() => {
              setDriverState(false);
              setOtherCitiesState(false);
            }}
            className={!driverState && !otherCitiesState ? "selected-button" : "individual-button"}
          >
            Assign Driver
          </button>
          <button
            className={
              driverState && driverData && !otherCitiesState
                ? "selected-button"
                : "individual-button"
            }
            style={{ cursor: "default" }}
          >
            Assign Cab
          </button>
          {(bookingDetails?.tariff?.subTripType === "One Way") ? (
            <button
              onClick={() => {
                setDriverState(false);
                setOtherCitiesState(true);
              }}
              className={
                otherCitiesState
                  ? "selected-button"
                  : "individual-button"
              }
              style={{ cursor: "default" }}
            >
              Available driver from other cities
            </button>) : ("")}
        </div>

        <Suspense fallback="Loading Data...">
          {!driverState ? (
            otherCitiesState ? (
              <AssignDriverOtherCities />
            ) : (
              <AssignDriverData
                handleDriverState={handleAssignDriver}
                driverData={allDrivers?.data || []} // Pass empty array if no data
              />
            )
          ) : (
            <AssignCar bookingId={id} driverData={driverData} setDriverState={setDriverState} />
          )}
        </Suspense>

        {/* {!allDrivers?.data && (
          <div className="no_available_driver">
            <DefaultFile />
            <span>{"No driver available for this Booking!"}</span>
          </div>
        )} */}
      </div>
    </div>
  );
}

export default AssignSection;
