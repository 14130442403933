import React, { useState, useEffect, useRef } from "react";
import "./Table.css";
import "./Dropdown.css";
import { AiOutlineSearch } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { fetchPendingBookings, selectPendingBooking } from "./BookingSlice";
import { IoIosArrowDown } from "react-icons/io";
import "./BookingPending.css";
import { CiFilter } from "react-icons/ci";
import { FaCalendarDays } from "react-icons/fa6";
import useOutsideClick from "./useOutsideClick.jsx";
import CustomDatePicker from "../CustomDatePicker/CustomDatePicker.jsx";
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const TableItem = ({ item, index, isRowSelected }) => {
  const rowClass = "Custombody-BP";
  const selectedRowClass = isRowSelected ? "table-row-selected" : "";

  function getStatusColor(status) {
    switch (status.toLowerCase()) {
      case "pending":
        return "#FBAB0E";
      case "in route":
        return "#F507FA";
      case "completed":
        return "#38B000";
      case "confirmed":
        return "blue";
      case "cancelled":
        return "#FC0F0F";
      case "cab assigned":
        return "#00BCBC";
      default:
        return "black";
    }
  }

  return (
    <tr className={`${rowClass} ${selectedRowClass}`}>
      <td>{index + 1}</td>
      <td>{item?.source || "N/A"}</td>
      <td>
        {item?.user ? `${item.user.firstName} ${item.user.lastName}` : "N/A"}
      </td>
      <td>
        {item?.location
          ? `${item.location.city}, ${item.location.state}`
          : "N/A"}
      </td>
      <td>{item?.pickupDate || "N/A"}</td>
      <td>
        {(() => {
          const pickup = item?.pickupDate;
          const ret = item?.returnDate;
          const trip = item?.tariff?.tripType?.toLowerCase();
          const subTrip = item?.tariff?.subTripType?.toLowerCase();

          const sameAsPickup =
            trip === "local" ||
            trip === "airport" ||
            (trip === "outstation" && subTrip === "one way");

          if (sameAsPickup) return pickup || "N/A";
          if (ret && ret !== "undefined") return ret;
          return "N/A";
        })()}
      </td>

      <td>
        {item?.tariff
          ? `${item.tariff.tripType} - ${item.tariff.subTripType}`
          : "N/A"}
      </td>
      <td>{item?.carCategory?.category || "N/A"}</td>
      <td
        style={{
          color: getStatusColor(item?.status),
          fontWeight: "bold",
          display: "flex",
          alignItems: "center",
          gap: "5px",
        }}
      >
        {item?.status || "N/A"}
        {item?.status?.toLowerCase() === "pending" && (
          <button className="refresh-button">Refresh</button>
        )}
      </td>
      <td>{`Rs. ${item.advancePaymentReceived?.toFixed(2)}`}</td>
    </tr>
  );
};

const Table = ({ headers, data }) => {
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "ascending",
  });
  const headerKeyMap = {
    "Sr. No.": "index",
    "Booking Source": "source",
    "Customers Name": "user",
    Location: "location",
    "From Date": "pickupDate",
    "To Date": "returnDate",
    "Trip Type": "tariff.tripType",
    "Vehicle Type": "carCategory.category",
    Status: "status",
    "Advanced Payment": "advancePaymentReceived",
  };
  const getValueByKey = (item, key) => {
    if (!item) return "";

    // Handle nested keys
    const keys = key.split(".");
    let value = item;
    for (let k of keys) {
      value = value?.[k];
      if (value === undefined) return ""; // Return empty if any level is missing
    }

    // Handle special cases for sorting
    if (key === "user") {
      return `${item.user?.firstName || ""} ${item.user?.lastName || ""}`
        .trim()
        .toLowerCase();
    }

    if (key === "location") {
      return `${item.location?.city || ""}, ${item.location?.state || ""}`
        .trim()
        .toLowerCase();
    }

    if (key === "advancePaymentReceived") {
      return parseFloat(value) || 0; // Convert to number for correct sorting
    }

    // Handle date format (DD-MM-YYYY)
    if (key.includes("Date") && typeof value === "string") {
      const [day, month, year] = value.split("-");
      return new Date(`${year}-${month}-${day}`); // Convert to sortable date format
    }

    // Convert text values to lowercase for consistent sorting
    if (typeof value === "string") {
      return value.trim().toLowerCase();
    }

    return value;
  };

  const handleSort = (header) => {
    const key = headerKeyMap[header];
    if (!key) return;

    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  const sortedData = [...data].sort((a, b) => {
    if (!sortConfig.key) return 0;

    let valueA = getValueByKey(a, sortConfig.key);
    let valueB = getValueByKey(b, sortConfig.key);

    if (valueA instanceof Date && valueB instanceof Date) {
      return sortConfig.direction === "ascending"
        ? valueA - valueB
        : valueB - valueA;
    }

    if (typeof valueA === "string" && typeof valueB === "string") {
      return sortConfig.direction === "ascending"
        ? valueA.localeCompare(valueB, "en", { sensitivity: "base" })
        : valueB.localeCompare(valueA, "en", { sensitivity: "base" });
    }

    if (typeof valueA === "number" && typeof valueB === "number") {
      return sortConfig.direction === "ascending"
        ? valueA - valueB
        : valueB - valueA;
    }

    return 0;
  });

  return (
    <div>
      <div className="table-containers-BP">
        <div className="table-scroll-BP">
          <table className="table-BP">
            <thead style={{ position: "sticky" }}>
              <tr>
                {headers.map((header, index) => (
                  <th
                    key={index}
                    style={{
                      height: "31px",
                      textAlign: "left", // Ensure text is aligned left
                      padding: "8px 16px",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {/* Flex container for header text and sorting icons */}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between", // Keeps text left and icons right
                        alignItems: "center",
                      }}
                    >
                      {/* Column Title */}
                      <span>{header}</span>

                      {/* Sort Icons (Skip sorting for first 2 columns) */}
                      {index !== 0 && index !== 1 && (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            marginLeft: "8px",
                          }}
                        >
                          <button
                            className="sort-button-BP"
                            onClick={() => handleSort(header)}
                            style={{
                              background: "none",
                              border: "none",
                              cursor: "pointer",
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              padding: "0",
                            }}
                          >
                            {/* Up Arrow (Ascending) */}
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="10px"
                              height="7px"
                              viewBox="0 0 10 7"
                              fill="none"
                              stroke={
                                sortConfig.key === header &&
                                sortConfig.direction === "ascending"
                                  ? "red"
                                  : "#FA7C07"
                              }
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            >
                              <path d="M1 6L5 2L9 6" />
                            </svg>

                            {/* Down Arrow (Descending) */}
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="10px"
                              height="7px"
                              viewBox="0 0 10 7"
                              style={{marginTop:"5px"}}
                              fill="none"
                              stroke={
                                sortConfig.key === header &&
                                sortConfig.direction === "descending"
                                  ? "red"
                                  : "#FA7C07"
                              }
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            >
                              <path d="M1 1L5 5L9 1" />
                            </svg>
                          </button>
                        </div>
                      )}
                    </div>
                  </th>
                ))}
              </tr>
            </thead>

            <tbody>
              {sortedData.map((item, index) => (
                <TableItem
                  key={index}
                  item={item}
                  index={index}
                  isRowSelected={selectedRowIndex === index}
                />
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

const BookingPending = () => {
  const dispatch = useDispatch();
  const pendingBookings = useSelector(selectPendingBooking);
  const [selectedLocation, setSelectedLocation] = useState("");
  const [selectedTripType, setselectedTripType] = useState("");
  const [selectedCarCategory, setselectedCarCategory] = useState("");
  const [selectedSubTripType, setSelectedSubTripType] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedBookingSource, setSelectedBookingSource] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");

  const [locationOptions, setLocationOptions] = useState([]);
  const [carCategoriesOptions, setCarCategoriesOptions] = useState([]);
  const [tripOptions, setTripOptions] = useState([]);
  const [subTripOptions, setSubTripOptions] = useState([]);

  const [dateRange, setDateRange] = useState([
    { startDate: null, endDate: null, key: "selection" },
  ]);
  const [showCalendar, setShowCalendar] = useState(false);

  const calendarRef = useRef(null);

  useOutsideClick(calendarRef, () => setShowCalendar(false));

  const handleApplyDateRange = ({ from, to }) => {
    console.log("Applying date range:", from, to);

    setDateRange([{ startDate: from, endDate: to }]);
    setShowCalendar(false);
  };

  const formatDate = (date) => {
    if (!date) {
      return "No Date Selected";
    }

    const options = { day: "2-digit", month: "short", year: "2-digit" };
    return new Intl.DateTimeFormat("en-GB", options).format(date);
  };

  useEffect(() => {
    dispatch(fetchPendingBookings());
  }, [dispatch]);

  useEffect(() => {
    const extractCategoriesAsOptions = (dataArray) => {
      if (Array.isArray(dataArray.data)) {
        return dataArray.data.map((item) => ({
          label: item.category,
          value: item.category,
        }));
      }
      return [];
    };

    const extractLocationsAsOptions = (dataArray) => {
      if (Array.isArray(dataArray.data)) {
        return dataArray.data.map((item) => ({
          label: item.location,
          value: item.location,
        }));
      }
      return [];
    };

    const extractTripAsOptions = (dataArray) => {
      if (Array.isArray(dataArray.data)) {
        return dataArray.data.map((item) => ({
          label: item.tripType,
          value: item.tripType,
        }));
      }
      return [];
    };

    const fetchOptions = async () => {
      try {
        const locationResponse = await fetch(`${API_BASE_URL}/v1/locations`);
        const locationData = await locationResponse.json();
        const locationOptions = extractLocationsAsOptions(locationData);
        setLocationOptions(locationOptions);

        const carResponse = await fetch(`${API_BASE_URL}/v1/car-categories`);
        const carData = await carResponse.json();
        const carCategoriesOptions = extractCategoriesAsOptions(carData);
        setCarCategoriesOptions(carCategoriesOptions);

        const tripResponse = await fetch(`${API_BASE_URL}/v1/trip-types`);
        const tripData = await tripResponse.json();
        const tripOptions = extractTripAsOptions(tripData);
        setTripOptions(tripOptions);
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };
    fetchOptions();
  }, []);

  useEffect(() => {
    const extractSubTripAsOptions = (dataArray) => {
      if (Array.isArray(dataArray.data) && dataArray.data.length > 0) {
        const subTripTypes = dataArray.data[0].subTripTypes;
        return subTripTypes.map((subTrip) => ({
          label: subTrip,
          value: subTrip,
        }));
      }
      return [];
    };

    const fetchSubTripTypes = async () => {
      try {
        if (selectedTripType) {
          const subTripResponse = await fetch(
            `${API_BASE_URL}/v1/sub-trip-types/${selectedTripType}`
          );
          const subTripData = await subTripResponse.json();
          const subTripOptions = extractSubTripAsOptions(subTripData);
          console.log("subTripOptions:", subTripOptions);
          setSubTripOptions(subTripOptions);
        } else {
          setSubTripOptions([]);
        }
      } catch (error) {
        console.error("Error fetching sub-trip types: ", error);
      }
    };
    fetchSubTripTypes();
  }, [selectedTripType]);

  const headers = [
    "Sr. No.",
    "Booking Source",
    "Customers Name",
    "Location",
    "From Date",
    "To Date",
    "Trip Type",
    "Vehicle Type",
    "Status",
    "Advanced Payment",
  ];


  const filteredBookings = pendingBookings
  .filter((item) => {
    const combinedLocation = `${item?.location?.city}, ${item?.location?.state}`;
    const combinedName = `${item?.user?.firstName || ""} ${item?.user?.lastName || ""}`.trim().toLowerCase();
    const mobileNumber = item?.user?.phone?.toString() || "";
    const bookingID = item?.bookingId?.toLowerCase().trim() || ""; 
    const status = item?.status || "";
    const query = searchQuery.trim().toLowerCase();

    return (
      (selectedLocation ? combinedLocation === selectedLocation : true) &&
      (selectedTripType ? item?.tariff?.tripType === selectedTripType : true) &&
      (selectedSubTripType
        ? item?.tariff?.subTripType === selectedSubTripType
        : true) &&
      (selectedCarCategory
        ? item?.carCategory?.category === selectedCarCategory
        : true) &&
      (selectedBookingSource ? item?.source === selectedBookingSource : true) &&
      (selectedStatus
        ? status.toLowerCase() === selectedStatus.toLowerCase()
        : true) &&
      (selectedDate
        ? new Date(item["createdAt"]).toDateString() ===
          selectedDate.toDateString()
        : true) &&
      (searchQuery
        ? combinedName.includes(query) ||
          mobileNumber.includes(query) ||
          bookingID.includes(query) // ✅ Ensures bookingId search works
        : true)
    );
  })
  .reverse();


  return (
    <div>
      <div
        className="dropdown-main-box-BP"
        style={{ gap: "0px", justifyContent: "start", marginBottom: "20px" }}
      >
        <div className="filter-icon-BP">
          <div>Filters</div>
          <div className="icon-1">
            <CiFilter color="#38b000" size={18} />
          </div>
        </div>
        <div className="filter-container-BP">
          <select
            className="dropdown-BP"
            value={selectedBookingSource}
            onChange={(e) => setSelectedBookingSource(e.target.value)}
            style={{ width: "120px" }}
          >
            <option value="">Booking Source</option>
            <option value="Mobile">Mobile</option>
            <option value="Web">Web</option>
            <option value="Admin">Admin</option>
          </select>
          <IoIosArrowDown className="drop-icon-1" color="#FA7C07" size={15} />
        </div>
        <div className="filter-container-BP">
          <select
            className="dropdown-BP"
            value={selectedLocation}
            onChange={(e) => setSelectedLocation(e.target.value)}
            style={{ width: "160px" }}
          >
            <option value="">Pick Up City</option>
            {locationOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
          <IoIosArrowDown className="drop-icon-1" color="#FA7C07" size={15} />
        </div>

        <div className="filter-container-BP">
          <select
            className="dropdown-BP"
            value={selectedCarCategory}
            onChange={(e) => setselectedCarCategory(e.target.value)}
            style={{ width: "150px" }}
          >
            <option value="">Select Car Category</option>
            {carCategoriesOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
          <IoIosArrowDown className="drop-icon-1" color="#FA7C07" size={15} />
        </div>

        <div className="filter-container-BP">
          <select
            className="dropdown-BP"
            value={selectedTripType}
            onChange={(e) => setselectedTripType(e.target.value)}
            style={{ width: "80px" }}
          >
            <option value="">Trip Type</option>
            {tripOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
          <IoIosArrowDown className="drop-icon-1" color="#FA7C07" size={15} />
        </div>

        <div className="filter-container-BP">
          <select
            className="dropdown-BP"
            value={selectedSubTripType}
            onChange={(e) => setSelectedSubTripType(e.target.value)}
            style={{ width: "100px" }}
          >
            <option value="">Sub Trip Type</option>
            {subTripOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
          <IoIosArrowDown className="drop-icon-1" color="#FA7C07" size={15} />
        </div>

        <div className="filter-container-BP" ref={calendarRef}>
          <button
            className="dropdown-date_BP"
            onClick={() => setShowCalendar(!showCalendar)}
            style={{
              display: "flex",
              height: "28px",
              alignItems: "center",
              justifyContent: "center",
              gap: "10px",
            }}
          >
            {dateRange[0].startDate
              ? dateRange[0].endDate
                ? `${formatDate(dateRange[0].startDate)} - ${formatDate(
                    dateRange[0].endDate
                  )}`
                : formatDate(dateRange[0].startDate)
              : "Select Date Range"}
          </button>
          <FaCalendarDays className="drop-icon" color="#FA7C07" size={15} />
          {showCalendar && (
            <div
              style={{
                position: "relative",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  zIndex: 100,
                  left: "-500px",
                }}
              >
                <CustomDatePicker
                  closeCalendar={() => setShowCalendar(false)}
                  setSelectedDate={({ from, to }) => {
                    handleApplyDateRange({ from, to });
                  }}
                  initialRange={dateRange[0]}
                />
              </div>
            </div>
          )}
        </div>
        <div className="filter-container-BP">
          <select
            className="dropdown-BP"
            value={selectedStatus}
            onChange={(e) => setSelectedStatus(e.target.value)}
            style={{
              width: "100px",
            }}
          >
            <option value="">Status</option>
            <option value="Pending">Pending</option>
            <option value="In Route">In Route</option>
            <option value="Completed">Completed</option>
            <option value="Confirmed">Confirmed</option>
            <option value="Cancelled">Cancelled</option>
            <option value="Cab Assigned">Cab Assigned</option>
          </select>
          <IoIosArrowDown className="drop-icon-1" color="#FA7C07" size={15} />
        </div>

        <div className="filter-container-BP">
          <div className="Custom-Search-BP">
            <input
              className="searchbox-BP"
              type="text"
              placeholder="Search by Customer Name, Mobile No., Booking ID"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              style={{
                width: "280px",
                height: "27px",
                borderRadius: "0px",
              }}
            />
            <AiOutlineSearch size={15} className="search-iconn-1" />
          </div>
        </div>
      </div>

      <Table headers={headers} data={filteredBookings} />
    </div>
  );
};

export default BookingPending;
