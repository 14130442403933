import React from 'react';
import './table1.css';

const Table1 = ({ data }) => {
  console.log(data);

  const tripType = data?.data?.tariff?.tripType || 'N/A';
  const subTripType = data?.data?.tariff?.subTripType || '';
  
  let startDateTime = `${data?.data?.pickupDate || 'N/A'} ${data?.data?.pickupTime || ''}`;
  let endDateTime = data?.endDateTime || startDateTime;
  let numberOfDays = data?.numberOfDays || '1';

  if (
    tripType === "local" ||
    tripType === "airport" ||
    (tripType === "outstation" && subTripType === "one way")
  ) {
    endDateTime = startDateTime;
    numberOfDays = 1;
  }

  // Calculate distance travelled
  const distanceTravelled = data?.data?.totalDistance;

  return (
    <table className="table1">
      <thead>
        <tr>
          <th>Booking ID</th>
          <th>Car Category</th>
          <th>Trip Type</th>
          <th>Route</th>
          <th>Start Date & Time</th>
          <th>End Date & Time</th>
          <th>Number of Days</th>
          <th>Distance Travelled</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{data?.data?.bookingId || 'N/A'}</td>
          <td>{data?.data?.carCategory?.category || 'N/A'}</td>
          <td>{tripType}</td>
          <td>{data?.data?.pickupAddress || 'N/A'}</td>
          <td>{startDateTime}</td>
          <td>{endDateTime}</td>
          <td>{numberOfDays}</td>
          <td>{distanceTravelled} km</td>
        </tr>
      </tbody>
    </table>
  );
};

export default Table1;
