import React from "react";
import { useState, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import "./ViewAllCar.css";
import InputColumn from "../Booking/Input";
import CustomDropdown from "../Booking/Dropdown";
import { format } from "date-fns";
// import DatePicker from "react-datepicker";
import { IoCalendar, IoSearch } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import {
  currentDriverId, 
  resetDriverDetails,
  fetchDriversDetails,
} from "./DriverDetailsSlice";
import { useNavigate } from "react-router-dom";
import {
  fetchApproval,
  selectApprovalDriver,
  selectLoading,
} from "./DriverSlice";
import { IoToggleSharp } from "react-icons/io5";
import { ImEye } from "react-icons/im";
import { FaPen } from "react-icons/fa";
import Filter from "../Booking/Filter";

const TableItem = ({ item, index }) => {
  const rowClass = index % 2 === 0 ? "table-row-even" : "table-row-odd";
  return (
    <tr className={rowClass}>
      <td>{item["Sr no."]}</td>
      <td>{item["Location"]}</td>
      <td>{item["Driver Name"]}</td>
      <td>{item["Driver Mobile No."]}</td>
      <td>{item["Registered On"]}</td>
      <td>{item["Approval Status"]}</td>
      <td>{item["Reason"]}</td>
      <td>{item["Remark"]}</td>
      <td>{item["Action"]}</td>
    </tr>
  );
};

const Table = ({ headers, data }) => {
  return (
    <div className="table-containers">
      <div className="table-scroll">
    <table className="table">
      <thead>
        <tr>
          {headers.map((header, index) => (
            <th key={index}>{header}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((item, index) => (
          <TableItem key={index} item={item} index={index} />
        ))}
      </tbody>
    </table>
    </div>
    </div>
  );
};

const ViewAllCar = () => {
  const [locationOptions, setLocationOptions] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState("");
  const [searchPhoneNumber, setsearchPhoneNumber] = useState("");
  const [searchName, setsearchName] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedApprovel, setselectedApprovel] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);
  const [placeholder, setPlaceholder] = useState("Select a location");
  const dispatch = useDispatch();
  const Approvals = useSelector(selectApprovalDriver);
  const loader = useSelector(selectLoading);

  const { driversId } = useSelector((state) => state.driverInfo);
  const navigate = useNavigate();

  const currentSection = "ApprovalPendingDriver";
  const [showDetails, setShowDetails] = useState(false);
  const [editDetails, setEditDetails] = useState(false);

  const handleDateChange = (date) => {
    setSelectedDate(date);
    console.log("the new date is "+date);
  };
  // const handleDateChange = (dateString) => {
  //   const newDate = new Date(dateString); // Parse the date string
  //   if (!isNaN(newDate.getTime())) { // Check if it's a valid date
  //     setSelectedDate(newDate);
  //     console.log("the new date is",newDate);
  //   } else {
  //     console.error("Invalid date selected");
  //   }
  // };

  console.log(loader);

  useEffect(() => {
    dispatch(fetchApproval());
  }, [dispatch]);

  const handleImEyeClick = (id) => {
    console.log("checking driver id", id);
    dispatch(currentDriverId(id));
    setShowDetails(true);
  };

  const handleEditIcon = (id) => {
    dispatch(currentDriverId(id));
    setEditDetails(true);
  };

  const calenderKhol = (e) => {
    if (e?.target?.showPicker) {
      e.target.showPicker();  
    }
  };

  const headers = [
    "Sr no.",
    "Location",
    "Driver Name",
    "Driver Mobile No.",
    "Registered On",
    "Approval Status",
    "Reason",
    "Remark",
    "Action",
  ];

  const formatDateDDMMYYYY = (date) => format(date, "dd-MM-yyyy");


  const filteredDrivers = Approvals.filter((Approval) => {

    const selectedRating = parseInt(selectedApprovel, 10);
    const selectedDateFormatted = selectedDate
      ? formatDateDDMMYYYY(selectedDate)
      : null;
    const bookingDateFormatted = Approval?.createdAt;
    return (
      (!selectedStatus || Approval?.status === selectedStatus) &&
      (!selectedLocation ||
        `${Approval?.location?.city}, ${Approval?.location?.state}` ===
          selectedLocation) &&
      (!searchName ||
        `${Approval?.firstName} ${Approval?.lastName}`
          .toLowerCase()
          .includes(searchName.toLowerCase())) &&
      (!searchPhoneNumber || Approval?.phone.includes(searchPhoneNumber)) &&
      (!selectedRating || Approval?.rating === selectedRating) &&
      (selectedDateFormatted
        ? bookingDateFormatted === selectedDateFormatted
        : true)
    );
  });

  const data = [];

  for (let i = 0; i < filteredDrivers.length; i++) {
    const Approval = filteredDrivers[i];

    data.push({
      "Sr no.": i + 1,
      Location: `${Approval?.location?.city}, ${Approval?.location?.state} `,
      "Driver Name": `${Approval?.firstName} ${Approval?.lastName}`,
      "Driver Mobile No.": Approval?.phone.startsWith("+91-")
        ? Approval?.phone
        : `+91- ${Approval?.phone}`,
      "No. of Ride": Approval?.noOfRides,

      "Registered On": Approval?.createdAt,
      // "Verified On": driver.verifiedOn,
      "Verified On": "22-10-2023",
      "Approval Status": Approval?.approval?.status,
      Remark: Approval?.approval?.remark,
      Reason: Approval?.approval?.reason,
      Action: (
        <>
          <div className="box-action">   
            <ImEye
              className="icon icon-large"
              onClick={() => handleImEyeClick(Approval?._id)}
            />
            <span className="icon-margin"></span>
            <FaPen
              className="icon-edit "
              onClick={() => handleEditIcon(Approval?._id)}
            />
          </div>
        </>
      ),
    });
  }
  const DriverList = data;

  useEffect(() => {
    const extractlocationAsOptions = (dataArray) => {
      if (Array.isArray(dataArray.data)) {
        const allLocationsOption = { label: "Select a location", value: "" };
        const locationOptions = dataArray.data.map((item) => ({
          label: item.location,
          value: item._id,
        }));
        return [allLocationsOption, ...locationOptions];
      }
      return [];
    };

    const fetchOptions = async () => {
      try {
        const locationRespons = await fetch(
          "https://api.yatricabs.com/v1/locations"
        );
        if (!locationRespons.ok) {
          throw new Error(`HTTP error! Status: ${locationRespons.status}`);
        }
        const locationData = await locationRespons.json();
        const locationOptions = extractlocationAsOptions(locationData);
        console.log(locationOptions);
        setLocationOptions(locationOptions);
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };
    fetchOptions();
  }, []);

  useEffect(() => {
    if (driversId !== null && showDetails) {
      dispatch(resetDriverDetails());
      dispatch(fetchDriversDetails(driversId));
      navigate(
        `/drivermanagement/${currentSection}/DriverDetails/${driversId}`
      );
    }
    setShowDetails(false);
    console.log("checking drivres id in effect", driversId);
  }, [showDetails]);

  useEffect(() => {
    if (driversId !== null && editDetails) {
      dispatch(resetDriverDetails());
      navigate(
        `/drivermanagement/${currentSection}/modify-details/${driversId}`
      );
    }
    setShowDetails(false);
    console.log("checking drivres id in effect", driversId);
  }, [editDetails]);

  return (
    <div>
      <>
        <div className="row"> 
          <div className="dropdown-container">
            <Filter />
            <CustomDropdown
              placeholder={placeholder}
              options={locationOptions}
              selectedValue={selectedLocation}
              // handleChange={(e) => setSelectedLocation(e.target.value)}
              handleChange={(e) => {
                const selectedOption = locationOptions.find(
                  (option) => option.value === e.target.value
                );

                if (selectedOption) {
                  const selectedCity = selectedOption.label;
                  console.log("Selected City:", selectedCity);
                  // setSelectedLocation(selectedCity);
                  // setPlaceholder(selectedCity);
                  if (selectedCity === "Select a location") {
                    // If "Select a location" is selected, set an empty string to show all locations
                    setSelectedLocation("");
                    setPlaceholder("Select a location");
                  } else {
                    setSelectedLocation(selectedCity);
                    setPlaceholder(selectedCity);
                  }
                } else {
                  setPlaceholder("Select a location");
                }
              }}
              className="dropdown"
            />
            <InputColumn
              title="Search by Name"
              selectedValue={searchName}
              handleChange={(e) => setsearchName(e.target.value)}
            />
            <InputColumn
              title="Search by Mobile No."
              selectedValue={searchPhoneNumber}
              handleChange={(e) => setsearchPhoneNumber(e.target.value)}
            />
           
       <div className="single-txtfield-wrapper">
      <input
        type="date"
        value={selectedDate ? format(selectedDate, "yyyy-MM-dd") : ""}
        onChange={(e) => handleDateChange(e.target.value)}
        onClick={calenderKhol}
        // min={new Date().toISOString().split("T")[0]} // Setting today's date as the minimum
        required
        placeholder={selectedDate ? format(selectedDate, "dd-MM-yyyy") : "dd-MM-yyyy"}
        style={{
          borderRadius: "5px",
          marginLeft: "25px",
          fontSize: "10px",
          fontWeight: "400",
          paddingLeft: "11px",
          height: "28px",
          width: "120px",
          border: "1px solid white",
          boxShadow: "0px 1px 8px 0px #0000002B"
        }}
      />
    </div>

            <CustomDropdown
              placeholder="Status"
              options={[
                { label: "Active", value: "Active" },
                { label: "Inactive", value: "Inactive" },
              ]}
              selectedValue={selectedStatus}
              handleChange={(e) => setSelectedStatus(e.target.value)}
              className="dropdown"
            />
          </div>
        </div>
        <Table
          headers={headers}
          data={DriverList.map((item) => ({
            ...item,
          }))}
        />
      </>
    </div>
  );
};

export default ViewAllCar;
