import { useEffect, useState } from "react";
import { FaLongArrowAltRight } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import {
  Link,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import AddCarCategory from "./AddCarCategory";
import AddNewCar from "./AddNewCar";
import ApprovalPending from "./ApprovalPending";
import "./CarManagement.css";
import { resetCompState } from "./carSlice";
import InactiveCars from "./InactiveCars";
import "./SharedDropdownAndInput.css";
import ViewAllCars from "./ViewAllCar";
import ViewAllCategory from "./ViweAllCategory";

const CarManagement = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [activeComponent, setActiveComponent] = useState("view-all-cars");

  const { currentComp } = useSelector((state) => state.car);

  const formatTitle = (str) => {
    return str
      .replace(/-/g, " ")
      .replace(/\b\w/g, (char) => char.toUpperCase());
  };

  useEffect(() => {
    if (location.pathname === "/carmanagement") {
      navigate("/carmanagement/view-all-cars");
    } else {
      const path = location.pathname.split("/").pop();
      setActiveComponent(path);
    }
  }, [navigate, location.pathname]);

  const handleActiveComponent = (type) => {
    setActiveComponent(type);
    navigate(`/carmanagement/${type}`);
  };

  useEffect(() => {
    if (currentComp !== null) {
      setActiveComponent(currentComp);
      dispatch(resetCompState(null));
    }
  }, [currentComp, dispatch]);

  return (
    <div className="carmangement-header">
      <div className="booking">
        <div className="header">
          <div className="header-text">
            <Link to="/dashboard" className="h3-booking">
              Fleet Management
            </Link>
            <FaLongArrowAltRight />
            <Link to="/carmanagement" className="h2-booking">
              {() => formatTitle(activeComponent)}
            </Link>
          </div>
          <div className="btns">
            <button
              className={
                activeComponent === "view-all-cars"
                  ? "createButton-cars"
                  : "createButton"
              }
              onClick={() => handleActiveComponent("view-all-cars")}
            >
              View All Cars
            </button>
            <button
              className={
                activeComponent === "inactive-cars"
                  ? "createButton-cars"
                  : "createButton"
              }
              onClick={() => handleActiveComponent("inactive-cars")}
            >
              Inactive Cars
            </button>
            <button
              className={
                activeComponent === "approval-pending"
                  ? "createButton-cars"
                  : "createButton"
              }
              onClick={() => handleActiveComponent("approval-pending")}
            >
              Approval Pending
            </button>
            <button
              className={
                activeComponent === "add-new-car"
                  ? "createButton-cars"
                  : "createButton"
              }
              onClick={() => handleActiveComponent("add-new-car")}
            >
              Add New Car
            </button>
            <button
              className={
                activeComponent === "add-car-category"
                  ? "createButton-cars"
                  : "createButton"
              }
              onClick={() => handleActiveComponent("add-car-category")}
            >
              Add Car Category
            </button>
            <button
              className={
                activeComponent === "view-cars-category"
                  ? "createButton-cars"
                  : "createButton"
              }
              onClick={() => handleActiveComponent("view-cars-category")}
            >
              View Cars Category
            </button>
          </div>
        </div>
      </div>

      <div>
        <Routes>
          <Route path="view-all-cars" element={<ViewAllCars />} />
          <Route path="inactive-cars" element={<InactiveCars />} />
          <Route path="approval-pending" element={<ApprovalPending />} />
          <Route path="add-new-car" element={<AddNewCar />} />
          <Route path="add-car-category" element={<AddCarCategory />} />
          <Route path="view-cars-category" element={<ViewAllCategory />} />
        </Routes>
      </div>
    </div>
  );
};

export default CarManagement;
