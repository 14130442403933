import React from "react";
import ReactDOM from "react-dom/client";
import "./global.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./store/store";
import LogoutTimer from "./components/LogoutTimerWrapper";
import { ToastContainer, toast } from "react-toastify";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <LogoutTimer />
        <ToastContainer />
        <App />
      </PersistGate>
    </Provider>
  </BrowserRouter>
); 
    
// document.addEventListener("contextmenu", function (event) {
//   event.preventDefault();
//   toast.error("Right-click is disabled on this site.", {
//     position: "top-center",
//     autoClose: 2000,
//     hideProgressBar: false,
//     closeOnClick: true,
//     pauseOnHover: true,
//     draggable: true,
//     progress: undefined,
//   });
// });

// // Disabling certain keyboard shortcuts and show toast message
// document.onkeydown = function (event) {
//   if (
//     (event.ctrlKey && (event.keyCode === 73 || event.keyCode === 74)) ||
//     event.keyCode === 123 ||
//     (event.ctrlKey && (event.keyCode === 83 || event.keyCode === 85))
//   ) {
//     event.preventDefault();
//     toast.error("This shortcut is disabled.", {
//       position: "top-center",
//       autoClose: 2000,
//       hideProgressBar: false,
//       closeOnClick: true,
//       pauseOnHover: true,
//       draggable: true,
//       progress: undefined,
//     });
//   }
// };
