import React from "react";
import "./table3.css";

const Table3 = ({ data }) => {
  console.log(data);

  // Extracting values with default fallback to "0.00"
  const customerPaidOnline = data?.data?.customerPaidOnline || "0.00";
  const cashCollectedByDriver = data?.data?.lessCashReceived || "0.00";

  return (
    <table className="table3">
      <tbody>
        <tr>
          <td className="green-cell3">Customer Paid Online</td>
          <td className="right-cell3">Rs. {customerPaidOnline}</td>
        </tr>
        <tr>
          <td className="green-cell3">Cash Collected by Driver</td>
          <td className="right-cell3">Rs. {cashCollectedByDriver}</td>
        </tr>
      </tbody>
    </table>
  );
};

export default Table3;
