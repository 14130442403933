import React, { useState, useEffect } from "react";
import { Routes, Route, useNavigate, useParams, useLocation } from "react-router-dom";
import "./Nav.css";
import Interation from "../Details/Interation";
import TaskDetails from "../Details/TaskDetails";
import RouteLog from "../Details/RouteLog";
import BillingDetails from "../Details/BillingDetails";
import Feedback from "../Details/Feedback";

const Nav = () => {
  const [activeButton, setActiveButton] = useState("taskdetails");
  const navigate = useNavigate();
  const { id } = useParams();
  const location = useLocation();

  useEffect(() => {
    // Highlight TaskDetails component initially if path is root
    if (location.pathname === `/booking/${id}`) {
      navigate(`/booking/${id}/taskdetails`, { replace: true });
    }
  }, [id, location.pathname, navigate]);

  useEffect(() => {
    // Update active button based on the current path
    const currentPath = location.pathname.split("/").pop();
    setActiveButton(currentPath);
  }, [location]);

  const handleButtonClick = (component) => {
    setActiveButton(component.toLowerCase());
    navigate(`/booking/${id}/${component.toLowerCase()}`);
  };

  const activeStyle = {
    width: "77px",
    height: "24px",
    borderRadius: "10px",
    background: "#38B000",
    color: "white",
    marginTop:"-2px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    border: "1px solid #000"
  };

  return (
    <>
    
    <div className="sub-nav">
      <ul className="sub-navbar">
        <li
          onClick={() => handleButtonClick("taskdetails")}
          style={{ border: "none", ...(activeButton === "taskdetails" && activeStyle) }}
        >
          Task Details
        </li>
        <li
          onClick={() => handleButtonClick("interaction")}
          style={{ ...(activeButton === "interaction" && activeStyle) }}
        >
          Interaction
        </li>
        <li
          onClick={() => handleButtonClick("routelog")}
          style={{ ...(activeButton === "routelog" && activeStyle) }}
        >
          Route Log
        </li>
        <li
          onClick={() => handleButtonClick("billingdetails")}
          style={{
            whiteSpace: "nowrap",
            ...(activeButton === "billingdetails" && activeStyle),
          }}
        >
          Billing Details
        </li>
        <li
        onClick={()=>handleButtonClick("feedback")}
        style={{
          whiteSpace: "nowrap",
          ...(activeButton === "feedback" && activeStyle),
        }}
        >Feedback</li>
      </ul>


    </div>

    <Routes>
        <Route path="taskdetails" element={<TaskDetails />} />
        <Route path="interaction" element={<Interation handleButtonClick={handleButtonClick} />} />
        <Route path="routelog" element={<RouteLog handleButtonClick={handleButtonClick} />} />
        <Route path="billingdetails" element={<BillingDetails handleButtonClick={handleButtonClick} />} />
        <Route path="feedback" element={<Feedback/>}/>
      </Routes>
    </>
  );
};

export default Nav;

