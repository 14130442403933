import React from "react";
import "./table5.css";

const Table5 = ({ data }) => {
  if (!data || !data.data) return <p>Loading...</p>;

  const tripType = data.data.tariff?.tripType || "N/A";
  const fareDetails = data.data.tariff?.fareDetails || {};
  const vendorFareDetails = data.data.vendorTariff?.fareDetails || {};

  const estimatedKm = data.data.estimatedKm || 0;
  const minKmCharged = fareDetails.minKm || 0;
  const ratePerKm = vendorFareDetails.extraKmCharge || 0;
  const extraKm = data.data.extraKm || 0;
  const extraHrs = data.data.extraHrs || 0;
  const extraHrsCharge = vendorFareDetails.extraHrsCharge || 0;
  const nightAllowance = data.data.extraNightAllowance ? 1 : 0;
  const nightAllowanceCharge = vendorFareDetails.nightDriverAllowance || 0;
  const baseFare = vendorFareDetails.baseFare || 0;
  const tdsRate = vendorFareDetails.tds || 2;
  const cashCollected = data.data.lessCashReceived || 0;

  const totalAmount = baseFare + (extraKm * ratePerKm) + (extraHrs * extraHrsCharge) + (nightAllowance * nightAllowanceCharge);
  const tds = (totalAmount * tdsRate) / 100;
  const netBalance = totalAmount - tds - cashCollected;
  const excessPayment = netBalance < 0 ? Math.abs(netBalance) : 0;

  return (
    <div className="table5-container">
      <h2 className="table-title5">Vendor Payout</h2>
      <table className="styled-table5">
        <thead>
          <tr>
            <th className="greenhead-cell5">Description</th>
            <th className="greenhead-cell5">
              No. of Units <div style={{ fontSize: "12px" }}>(KM/Days/Hours)</div>
            </th>
            <th className="greenhead-cell5">Rate Per Unit</th>
            <th className="greenhead-cell5">Amount</th>
          </tr>
        </thead>
        <tbody>
          {tripType === "Outstation" ? (
            <>
              <tr>
                <td className="green-cell5">
                  Estimated KM
                  <div style={{ fontSize: "8px" }}>Min KM Charged: {minKmCharged} KMs/day</div>
                </td>
                <td className="right-cell5">{estimatedKm} Km</td>
                <td className="right-cell5">Rs. {ratePerKm} / Km</td>
                <td className="right-cell5">Rs. {(estimatedKm * ratePerKm).toFixed(2)}</td>
              </tr>
              <tr>
                <td className="green-cell5">Extra Km</td>
                <td className="right-cell5">{extraKm} Km</td>
                <td className="right-cell5">Rs. {ratePerKm} / Km</td>
                <td className="right-cell5">Rs. {(extraKm * ratePerKm).toFixed(2)}</td>
              </tr>
              <tr>
                <td className="green-cell5">Night Allowance</td>
                <td className="right-cell5">{nightAllowance} Days</td>
                <td className="right-cell5">Rs. {nightAllowanceCharge} / day</td>
                <td className="right-cell5">Rs. {(nightAllowance * nightAllowanceCharge).toFixed(2)}</td>
              </tr>
            </>
          ) : (
            <>
              <tr>
                <td className="green-cell5">Base Fare</td>
                <td className="right-cell5">{minKmCharged} Km</td>
                <td className="right-cell5"></td>
                <td className="right-cell5">Rs. {baseFare}</td>
              </tr>
              <tr>
                <td className="green-cell5">Extra Km</td>
                <td className="right-cell5">{extraKm} Km</td>
                <td className="right-cell5">Rs. {ratePerKm} / Km</td>
                <td className="right-cell5">Rs. {(extraKm * ratePerKm).toFixed(2)}</td>
              </tr>
              <tr>
                <td className="green-cell5">Extra Hrs</td>
                <td className="right-cell5">{extraHrs} Hrs</td>
                <td className="right-cell5">Rs. {extraHrsCharge} / Hr</td>
                <td className="right-cell5">Rs. {(extraHrs * extraHrsCharge).toFixed(2)}</td>
              </tr>
              <tr>
                <td className="green-cell5">Night Allowance</td>
                <td className="right-cell5">{nightAllowance} Days</td>
                <td className="right-cell5">Rs. {nightAllowanceCharge} / day</td>
                <td className="right-cell5">Rs. {(nightAllowance * nightAllowanceCharge).toFixed(2)}</td>
              </tr>
            </>
          )}
          <tr>
            <td className="void-cell5"></td>
            <td colSpan="2" className="green-cell50" style={{ fontWeight: "700", fontSize: "18px" }}>
              Total Amount
            </td>
            <td className="highlight-green5" style={{ fontWeight: "700" }}>
              Rs. {totalAmount.toFixed(2)}
            </td>
          </tr>
          <tr>
            <td className="void-cell5"></td>
            <td colSpan="2" className="green-cell50">Less TDS @ {tdsRate}%</td>
            <td className="highlight-green5" style={{ fontWeight: "700", fontSize: "14px" }}>
              Rs. {tds.toFixed(2)}
            </td>
          </tr>
          <tr>
            <td className="void-cell5"></td>
            <td colSpan="2" className="green-cell50">Less Cash Collected by Driver</td>
            <td className="highlight-green5" style={{ fontWeight: "700", fontSize: "14px" }}>
              Rs. {cashCollected.toFixed(2)}
            </td>
          </tr>
          <tr>
            <td className="void-cell5"></td>
            <td colSpan="2" className="green-cell50" style={{ fontWeight: "700", fontSize: "18px" }}>
              {netBalance >= 0 ? "Balance Payment To Be Paid" : "Net Balance"}
            </td>
            <td className="highlight-green5" style={{ fontWeight: "700" }}>
              Rs. {Math.abs(netBalance).toFixed(2)}
            </td>
          </tr>
          <tr>
            <td className="void-cell5"></td>
            <td colSpan="2" className="green-cell50">Excess Payment If Any</td>
            <td className="highlight-green5" style={{ fontWeight: "700", fontSize: "14px" }}>
              Rs. {excessPayment.toFixed(2)}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default Table5;
