import { useEffect, useState } from "react";

const useSuggestionsAirport = () => {
  const [suggestions, setSuggestions] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://d28cfqpbd12ftu.cloudfront.net/indian-airports.json"
        );
        const data = await response.json();
        const airportCities = data.airports;
        setSuggestions(airportCities);
      } catch (error) {
        console.error("Error fetching suggestions:", error);
      }
    };

    fetchData();
  }, []);

  return [suggestions];
};

export default useSuggestionsAirport;
