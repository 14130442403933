import React, { useEffect, useState } from "react";
import "./AssignCarData.css";
import { FaFilter } from "react-icons/fa";
import DropdownFilter from "../dropdown/DropdownFilter";
import Loader from "../Loader";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { assignCab, fetchCabData } from "./assignCabSlice";
import { setToggleForTaskDetails } from "./assignCab&DriverSlice";
import { useNavigate, useParams } from "react-router-dom";
import { BiRightArrow } from "react-icons/bi";

function AssignCar({ bookingId, driverData, setDriverState }) {
  const [apiData, setApiData] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams(); 
  const [filterValue, setFilterValue] = useState([]);
  const [filterValues, setFilterValues] = useState({});
  const [componentKey, setComponentKey] = useState(0)
  const [searchName, setSearchName] = useState("");
  const [filterOptionsStates, setFilterOptionsStates] = useState([]);
  const [initialDriverData, setInitialDriverData] = useState([]);
const [filterCabMakes, setfilterMakeOption]=useState([])
const [currentFilterValue, setCurrentFilterValue] = useState();
const [intialCabData, setInitialCabData] = useState([]);

  const [cabNumber, setCabNumber] = useState("");
  const [filterOptionsState, setfilterOptionsState] = useState();
  const { allCabs, cabData } = useSelector((state) => state.assignCabs);
  const headerColumns = [
    "Cab No.",
    "Cab Type",
    "Location",
    "Cab Make & Model",
    "Cab Color",
    "Status",
    "Insurance Validity",
    "Permit Validity",
    "",
  ];
  console.log("cab data is ", cabData);

  console.log("Driver data in car", driverData);
  console.log("Booking id in car assign code", bookingId);
  console.log("Booking id in car assign code", id);

  useEffect(() => {
    if (driverData && driverData.id) {
      const driverId =  driverData?.id.toString(); 
      dispatch(fetchCabData({ bookingId, driverId }));
    }
  }, [dispatch, bookingId, driverData]);

  const currentPath = window.location.pathname;
  useEffect(()=>{
  if (currentPath.includes('/assign/cab')){
    setDriverState(false);
  }
  },[currentPath])

  const filterOptions = [
    {
      label: "Pick Up State",
      values: null,
    },
    {
      label: "Pick Up City",
      values: null,
    },
    {
      label: "Cab Type",
      values: null,
    },
    {
        label: "Cab Make",
        values: null,
    },
    {
      label: "Cab Model",
      values: null,
    },
  ];
 
  
  let filterAvailability = {
    label: "Availability",
    values: ["Available", "Not Available"],
  };
  let filterLocations = [];
  let filterCabType = [];
  let filterCabMake=[]
 
  const fetchLocations = async () => {
    try {
      const response = await axios.get("https://api.yatricabs.com/v1/locations");
      const locations = response.data.data.map((value) => {
        // Split the location string by comma
        const [city, state] = value.location
          .split(",")
          .map((item) => item.trim());
        return { city, state }; // Return an object with city and state
      });

      // Extract unique state names for the filter options
      const stateNames = [
        ...new Set(locations.map((location) => location.state)),
      ];

      const updatedFilterOptions = filterOptions.map((temp) => {
        if (temp.label === "Pick Up State") {
          temp.values = stateNames; // Use unique state names for filter options
        }
         else if (temp.label === "Pick Up City") {
          temp.values = []; // Initialize as empty or default message
        }
        return temp;
      });

      setFilterOptionsStates(updatedFilterOptions);
    } catch (error) {
      console.error("Failed to fetch locations.");
    }
  };
  const fetchCabTypes = async () => {
    const response = await axios.get(
      "https://api.yatricabs.com/v1/car-categories"
    );
    // filterCabType.push('Cab Type');
    response.data.data.map((value) => filterCabType.push(value.category));
    let tempFilter = filterOptions.map((temp, index) => {
      if (temp.label === "Cab Type") {
        temp.values = filterCabType;
      }

      return temp;
    });

console.log("frtch cab type filterCabType.....",filterCabType)

    setfilterOptionsState([...tempFilter]);
  };

    const fetchCabMake = async () => {
      const response = await axios.get( "https://api.yatricabs.com/v1/car-makes");
      
      filterCabMake.push(...response.data.data.map((value) => value.make));

      let tempFilter = filterOptions.map((temp, index) => {
        if (temp.label === "Cab Make") {
          temp.values = filterCabMake;
        
        }
        return temp;
      }
    )

      setfilterMakeOption([...tempFilter]);
     
  };
 
 
  console.log("filter Cab Make make api.....",filterCabMake)



const applyFilters = () => {
  const filteredData = intialCabData?.filter((driver) => {
    // Matches search by cab number
    const matchesSearchName = driver["Cab No."]
      ?.toLowerCase()
      .startsWith(searchName.toLowerCase());

    // Matches filter values for other fields
    const matchesFilterValues = Object.keys(filterValues).every((key) => {
      if (key === "Pick Up State") {
        const driverLocation = driver["Location"];
        const locationParts = driverLocation ? driverLocation.split(",") : [];
        const driverState = locationParts[1]?.trim();
        return driverState?.toLowerCase() === filterValues["Pick Up State"].toLowerCase();
      } else if (key === "Pick Up City") {
        const driverLocation = driver["Location"];
        const locationParts = driverLocation ? driverLocation.split(",") : [];
        const driverCity = locationParts[0]?.trim();
        return driverCity?.toLowerCase() === filterValues["Pick Up City"].toLowerCase();
      } else if (key === "Cab Make" || key === "Cab Model") {
        const cabMake = filterValues["Cab Make"];
        const cabModel = filterValues["Cab Model"];
        
        if (cabMake && cabModel) {
          const cabMakeModel = `${cabMake} ${cabModel}`.trim().toLowerCase();
          const driverMakeModel = driver["Cab Make & Model"]?.trim().toLowerCase();
          return driverMakeModel === cabMakeModel;
        } else if (cabMake) {
          const driverCabMakeModel = driver["Cab Make & Model"];
          const [driverMake] = driverCabMakeModel ? driverCabMakeModel.split(" ") : [];
          return driverMake && driverMake.toLowerCase().startsWith(cabMake.toLowerCase());
        } else if (cabModel) {
          return driver["Cab Make & Model"]?.toLowerCase().includes(cabModel.toLowerCase());
        }
        return true;
      } else if (key === "Availability") {
          // Normalize the comparison for availability
          return driver["Status"]?.toLowerCase() === filterValues[key].toLowerCase();

      } else {
        return driver[key]?.includes(filterValues[key]);
      }
    });

    // Both search and filter values must match
    return matchesSearchName && matchesFilterValues;
  });

  setApiData(filteredData);
};

console.log("filter values",filterValues)


useEffect(() => {
  applyFilters();
}, [searchName, filterValues,currentFilterValue]);
  

useEffect(() => {
  if (cabNumber) {
    globalSelectedOption(cabNumber, "Cab No.");
  }
}, [cabNumber]);

  const handleSelectedData = (cabNumber) => {
    const currentCab = apiData.filter((cab) => {
      return cab["id"] === cabNumber;
    });
    dispatch(assignCab(currentCab[0]));
  };
  const cabDetails = [];
  let cabModels = [];
  const [filterCabModels, setFilterCabModels] = useState();
  useEffect(() => {
    if (allCabs.data !== null) {
      const cabDetails = [];
      const cabModels = [{ "Cab Make & Model": "Cab Model" }]; // Initial model
  
      allCabs.data.data.forEach((cab) => {
        cabDetails.push({
          id: cab._id,
          "Cab No.": cab.registrationCertificate.number,
          "Cab Type": cab.carCategory.category,
          "Location": cab.location ? `${cab.location.city}, ${cab.location.state}` : cab.location,
          "Cab Make & Model": `${cab.carMake} ${cab.carModel}`,
          "Cab Color": cab.carColor,
          "Status": cab?.availability === "Available" ? "Available" : "Not Available",
          "Insurance Validity": cab.insurance.expiryDate,
          "Permit Validity": cab.permit.expiryDate,
        });
  
        cabModels.push({
          "Cab Make & Model": `${cab.carMake} ${cab.carModel}`,
          "Cab Type": cab.carCategory.category,
        });
      });
  
      setApiData(cabDetails);
      setInitialCabData(cabDetails);
      setFilterCabModels(cabModels);
    }
  }, [allCabs.data]);

  useEffect(() => {
    const initializeFilters = async () => {
      await fetchLocations();
      await fetchCabTypes();
      await fetchCabMake();

    };
    initializeFilters();
  }, []);

  const [labels, setLabels] = useState([]);
  // const [filterValuess, setFilterValuess] = useState([]);

 
  
  console.log("initial cab data",intialCabData)


  const globalSelectedOption = async (value, label) => {
    let temp = { ...filterValues , [label]: value }; // Copy the current filter values
  
    if (value === "") {
      delete temp[label]; // Remove the value if it's empty
    } else {
      temp[label] = value; // Set the new filter value
    }
  
    setFilterValues(temp); // Update filter values
  
    if (!labels.includes(label)) {
      setLabels((prevLabels) => [...prevLabels, label]); // Track active filters
    }
  
    // Handle the specific cases for each filter
    if (label === "Pick Up State") {
      setCurrentFilterValue(value);
      await fetchCitiesByState(value); // Fetch cities for selected state
    } else if (label === "Pick Up City") {
      const state = temp["Pick Up State"];
      const city = value;
      if (state && city) {
        const location = `${city}, ${state}`; // Concatenate city and state
        setCurrentFilterValue(location);
      }
    } else if (label === "Cab Make") {
      await fetchCabModel(value); // Fetch models for selected cab make
    } else if (label === "Cab Model") {
      const cabMake = filterValues["Cab Make"];
      const cabModel = value;
      if (cabMake && cabModel) {
        const cabMakeModel = `${cabMake} ${cabModel}`; // Concatenate make and model
        setCurrentFilterValue(cabMakeModel);
      }
    }  else {
      applyFilters(); // Apply other filters
    }
  };
  

  useEffect(() => {
    if (currentFilterValue) {
      const newData = intialCabData.filter((driver) => {
        const result = labels.every((key) => {
          if (driver[key] !== null && filterValues[key] !== undefined) {
            if (key === "Pick Up State" || key === "Pick Up City") {
              const state = filterValues["Pick Up State"];
              const city = filterValues["Pick Up City"];
              const driverLocation = driver["Location"];
              const [driverCity, driverState] = driverLocation
                ? driverLocation.split(",").map((part) => part.trim())
                : [];
              if (key === "Pick Up State") {
                return driverState && driverState.toLowerCase() === state.toLowerCase();
              } else if (key === "Pick Up City") {
                return driverCity && driverCity.toLowerCase() === city.toLowerCase();
              }
            } else if (key === "Cab Make" || key === "Cab Model") {
              const cabMake = filterValues["Cab Make"];
              const cabModel = filterValues["Cab Model"];
              if (key === "Cab Make") {
                const driverCabMakeModel = driver["Cab Make & Model"];
                const [driverMake] = driverCabMakeModel
                  ? driverCabMakeModel.split(" ")
                  : [];
                return driverMake && driverMake.toLowerCase().startsWith(cabMake.toLowerCase());
              } else if (key === "Cab Model" && cabMake) {
                const CabMakeModel = `${cabMake} ${cabModel}`;
                const check = driver["Cab Make & Model"];
                return check?.includes(CabMakeModel);
              }
            } else if (key === "Availability") {
              return driver["Status"]?.toLowerCase() === filterValues[key].toLowerCase();
            } else {
              return driver[key]?.includes(filterValues[key]);
            }
          }
          return true;
        });
  
        return result;
      });
  
      setApiData([...newData]);
    }
  }, [currentFilterValue, labels, filterValues, intialCabData]);
  



  // useEffect(() => {
  //   // Check if currentFilterValue exists, otherwise reset to the initial data
  //   if (!currentFilterValue) {
  //     setApiData([...intialCabData]); // Reset to initial data if no filter is applied
  //     return;
  //   }
  
  //   // Ensure intialCabData is an array before proceeding with filtering
  //   if (Array.isArray(intialCabData)) {
  //     const newData = intialCabData.filter((driver) => {
  //       const result = labels.every((key) => {
  //         if (driver[key] !== null && filterValues[key] !== undefined) {
  //           if (key === "Pick Up State" || key === "Pick Up City") {
  //             const state = filterValues["Pick Up State"];
  //             const city = filterValues["Pick Up City"];
  //             const driverLocation = driver["Location"];
  //             const [driverCity, driverState] = driverLocation
  //               ? driverLocation.split(",").map((part) => part.trim())
  //               : [];
  //             if (key === "Pick Up State") {
  //               return driverState && driverState.toLowerCase() === state.toLowerCase();
  //             } else if (key === "Pick Up City") {
  //               return driverCity && driverCity.toLowerCase() === city.toLowerCase();
  //             }
  //           } else if (key === "Cab Make" || key === "Cab Model") {
  //             const cabMake = filterValues["Cab Make"];
  //             const cabModel = filterValues["Cab Model"];
  //             if (key === "Cab Make") {
  //               const driverCabMakeModel = driver["Cab Make & Model"];
  //               const [driverMake] = driverCabMakeModel
  //                 ? driverCabMakeModel.split(" ")
  //                 : [];
  //               return driverMake && driverMake.toLowerCase().startsWith(cabMake.toLowerCase());
  //             } else if (key === "Cab Model" && cabMake) {
  //               const CabMakeModel = `${cabMake} ${cabModel}`;
  //               const check = driver["Cab Make & Model"];
  //               return check?.includes(CabMakeModel);
  //             }
  //           } else if (key === "Availability") {
  //             return driver["Status"]?.toLowerCase() === filterValues[key].toLowerCase();
  //           } else {
  //             return driver[key]?.includes(filterValues[key]);
  //           }
  //         }
  //         return true;
  //       });
  
  //       return result;
  //     });
  
  //     // Safely set the new filtered data
  //     setApiData(Array.isArray(newData) ? [...newData] : []);
  //   } else {
  //     // If intialCabData is not an array, set an empty array
  //     setApiData([]);
  //   }
  // }, [currentFilterValue, labels, filterValues, intialCabData]);
  

  const fetchCitiesByState = async (state) => {
    console.log("state is ", state);

    try {
      const response = await axios.post(
        "https://api.yatricabs.com/v1/admin/bookings/get-cities-state",
        { state: state } // Pass state as a body
      );
      console.log("response by api", response)
      const cities = response.data.data.map((cityData) => cityData.city);
      console.log("cities for selected state is", cities);

      const updatedFilterOptions = filterOptionsStates.map((temp) => {
        if (temp.label === "Pick Up City") {
          temp.values = cities; // Update cities for the selected state
        }
        return temp;
      });

      setFilterOptionsStates(updatedFilterOptions);
     
    }
    
    catch (error) {
      console.error("Failed to fetch cities for the selected state.", error);
    }
  };
const fetchCabModel = async (selectedMake) => {
  try {
    const response = await axios.get(
      `https://api.yatricabs.com/v1/car-models/${selectedMake}`
    );
    const models = response.data.data[0].models; // Get models from response
    
    const updatedFilterOptions = filterOptionsStates.map((temp) => {
      if (temp.label === "Cab Model") {
        temp.values = models; // Populate "Cab Model" dropdown with models
      }
      return temp;
    });
    
    setFilterOptionsStates(updatedFilterOptions);
  } catch (error) {
    console.error("Failed to fetch models for the selected cab make", error);
  }
};

  useEffect(() => {
    console.log("preview Data", driverData);
    console.log("cabData", cabData);
  }, [handleSelectedData]);

  const TableHeaders = ({ headers }) => {
    return (
      <thead>
        <tr className="table-header-row">
          {headers.map((header, index) => (
            <th key={index}>
              <div className="table-header"> {header}</div>
            </th>
          ))}
        </tr>
      </thead>
    );
  };

  const TableBody = ({ enteries, headers }) => {
    return (
      <tbody>
        {enteries.map((driver, index) => {
          return (
            <tr className="table-data-row" key={index}>
              {headers.map((header) => {
                return header !== "" ? (
                  <td className="table-data" key={header}>
                    {" "}
                    {driver[header]}{" "}
                  </td>
                ) : (
                  <td className="table-data" key={header}>
                    <button
                      className="select-button"
                      onClick={() => handleSelectedData(driver["id"])}
                    >
                      Select
                    </button>
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    );
  };


  const ResetFilters = () => {
    // Perform the necessary cleanup actions before reload (if needed)
    setFilterValues({});
    setSearchName("");
    // setSearchContact("");
    
    // Full page reload
    window.location.reload();
  };
  

  useEffect(() => {
    if (cabNumber.length !== 0) {
      console.log("name", apiData);
      const filterData = intialCabData.filter((driver) => {
        return cabNumber.includes(driver["Cab No."]);
      });

      if (filterData.length !== 0) {
        setApiData([...filterData]);
      }
    }
  }, [cabNumber]);

  useEffect(() => {
    if (filterValue.length !== 0) {
      const temp = filterValue.filter((values) => {
        return values !== undefined || values;
      });

      const check = temp.map((value, index) => {
        return value[1];
      });

      const filterData = intialCabData.filter((driver) =>
        check.every((key, index) => temp[index].includes(driver[key]))
      );

      setApiData([...filterData]);
    }
  }, [filterValue]);

  const handleAssignCabAndDriver = async () => {
    try {
      const response = await axios.post(
        "https://api.yatricabs.com/v1/admin/bookings/assign-driver-car",
        {
          driverId: driverData?.id,
          carId: cabData?.id,
          bookingId: bookingId,
        }
      );

      if (response.status === 200) {
        alert("Cab and driver successfully assigned");
        navigate(`/booking/${bookingId}`);
      } else {
        alert("Failed to assign cab and driver");
      }
    } catch (error) {
      // console.error("Error assigning cab and driver:", error);
      alert("Driver is already assigned");
    }
  };

  return (
    <div className="data-container">
      <div className="filter-sectionCab">
        <p className="filter-tag" >
          {" "}
          Filter
          <FaFilter />
        </p>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            // gap: "8.5%",
            width: "65%",
          }}
        >
          {filterOptionsStates &&
            filterOptionsStates?.map((filter, index) => {
              const defaultValue =
                // filter.label === "Pick Up City"
                //   ? intialCabData[0]?.Location
                   driverData.id;
              return (
                <DropdownFilter
                  key={index}
                  filterOptions={filter}
                  onSelect={globalSelectedOption}
                  defaultValue={defaultValue}
                />
              );
            })}
        </div>
        <div className="second-Container">
          <div>
            <input
              className="input-search " style={{marginRight: "49px"}}
              value={searchName}
              type="text"
              placeholder="Search By Cab no."
              onChange={(e) => setSearchName(e.target.value)}
            />
          </div>
          <div>
            <DropdownFilter
              key={0}
              filterOptions={filterAvailability}
              onSelect={globalSelectedOption}
              defaultValue={undefined}
            />
          </div>
          <div>
        {/* {filterValue.length !== 0 && ( */}
          <button className="filter-button" style={{marginLeft:"13%"}} onClick={() => ResetFilters()}>
            Reset 
          </button>
        {/* )} */}
      </div>
        </div>
      </div>
     
      <div className="table-container">
        <table className="driver-table">
          <TableHeaders headers={headerColumns} />
          {intialCabData.length>0 ? (
            <TableBody enteries={apiData} headers={headerColumns} />
          ) : (
            <tbody>
            <tr>
              <td colSpan={headerColumns.length} className="no-data">
                <span>{"Driver has no available cars"}</span>
              </td>
            </tr>
          </tbody>
          )}
        </table>
      </div>

      {/* <div>{intialCabData.length==0 && "Driver has no available cars"}</div> */}

      <div className="preview-data">
        <div className="driver-data-preview">
          <p style={{ fontWeight: "700"}}>Selected Driver</p>
          <p>Driver Name: {driverData["Driver Name"]}</p>
          <p>Driver Mob. No.: {driverData["Contact No."]} </p>
          
        </div>
        <div className="driver-data-preview">
          <p style={{ fontWeight: "700" }}>Selected Cab</p>
          {cabData ? (
            <>
              <p>Cab Type: {cabData["Cab Type"]} </p>
              <p>Cab Model: {cabData["Cab Model"]}</p>
              <p>Cab Color: {cabData["Cab Color"]}</p>
              <p>Cab No.: {cabData["Cab No."]}</p>
            </>
          ) : (
            <p>Not Assigned yet</p>
          )}
        </div>
      </div>
      <div>
        <button
          className="assign-cabdriver-button"
          onClick={handleAssignCabAndDriver}
        >
          Assign Cab & Driver
        </button>
      </div>
    </div>
  );
}

export default AssignCar;
