import React, { useEffect, useState } from "react";
import "./CarDetails.css";
import { useParams, Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// import { fetchSingleCarDetails } from "./action";
import { fetchSingleCarDetailsAsync, resetCarDetails } from "./CarDetailsSlice";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { FaLongArrowAltRight } from "react-icons/fa";
import { resetCompState } from "./carSlice";
import axios from "axios";
import Loader from "../Loader";
import { singleCarData } from "./CarDetailsSlice";
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const CarDetails = () => {
  const id = useParams();
  const carid = id.id;
  console.log(carid);

  const [popUpImage, setPopUpImage] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [popupText, setPopupText] = useState("");
  const [reason, setReason] = useState("");
  const [displayImage, setDisplayImage] = useState();
  const [previousLocation, setPreviousLocation] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const singleCarDetail = useSelector(
  //   (state) => state.singleCar?.singlecardata
  // );
  // const loadings = useSelector((state) => state.loading);
  // const errors = useSelector((state) => state.error);

  const singleCarDetail = useSelector((state) => state.singleCar.singleCarData);
  console.log(singleCarDetail)
  const loadings = useSelector((state) => state.singleCar.isLoading);
  // const errors = useSelector((state) => state.singleCar.isError);

  const checkParentLocation = (path) => {
    if (path.includes("InactiveCars")) {
      setPreviousLocation("Inactive Cars");
    } else if (path.includes("ApprovalPendingCars")) {
      setPreviousLocation("Approval Pending");
    } else {
      setPreviousLocation("View All Cars");
    }
  };

  const currentComponent = "carDetails";

  useEffect(() => {
    // Assuming carid is the ID of the car you want to fetch
    dispatch(fetchSingleCarDetailsAsync(carid));
    checkParentLocation(window.location.pathname);
  }, []);

  console.log(singleCarDetail);
  // const singlecardatavalue = Object.values(singleCarDetail)
  const singlecardatavalue = singleCarDetail
    ? Object.values(singleCarDetail)
    : [];
  console.log(singlecardatavalue);

  if (loadings) {
    return <div>Loading...</div>;
  }

  // if (errors) {
  //   return <div>Error in fetching Data: {errors}</div>;
  // }



  const handleCheckboxChange = (e) => {
    setReason(e.target.value);
  };


  const handleReSubmitClick = () => {
    setShowPopup(true);
  };

  const handlePopupClose = () => {
    setShowPopup(false);
    setPopupText(""); // Clear the text field value when closing the popup
    setReason("");
  };
  const handlePopupSubmit = async (carid) => {
    try {
      // Construct the API URL with the driverId
      const apiUrl = `${API_BASE_URL}/v1/admin/cars/${carid}/approve/resubmit`;

      
      const requestBody = {
        reason: reason,
        remark: popupText,
      };

     
      const response = await axios.post(apiUrl, requestBody);

      // Handle the response
      console.log("API response:", response.data);

      // Close the popup after submission
      setShowPopup(false);
      setPopupText("");
      setReason("");
    } catch (error) {
      // Handle any errors
      console.error("Error submitting data:", error);
    }
  };

  const hanldeImagePopup = (imageURL) => {
    setPopUpImage(true);
    setDisplayImage(imageURL);
  };

  const handleModify = () => {
    // dispatch(currentDriverId(id));
    // dispatch(currentCarId(item._id));
    dispatch(resetCarDetails());
    navigate(`/carManagement/${currentComponent}/modify-car-details/${carid}`);
  };

  const handleToggleComponent = (value) => {
    dispatch(resetCompState(value));
    navigate("/carmanagement");
  };

  const handleModified = () => {
    // dispatch(currentDriverId(id));
    // dispatch(currentCarId(item._id));
    // dispatch(resetCarDetails());
    navigate(`/carManagement/${previousLocation}/modify-car-details/${carid}`);
  };
  // useEffect(() => {
  //     checkParentLocation(window.location.pathname);
  // }, [])

  const handleApprovalClick = async (carid) => {
    try {
      const response = await axios.put(
        `${API_BASE_URL}/v1/admin/cars/${carid}/approve`
      );

      const approvedCar = response.data;

      // After successful approval, navigate to '/drivermanagement' page
      navigate("/carmanagement");

      console.log("Car Approved:", approvedCar);
    } catch (error) {
      console.error("Error approving car:", error);
    }
  };

  // const [showPopup, setShowPopup] = useState(false);

  // const handleReSubmitClick = () => {
  //   setShowPopup(true);
  // };

  return (
    <>
      <div
        className={popUpImage ? "blurbg" : "driver-info-container"}
        style={popUpImage ? {} : { marginTop: "1px" }}
      >
        <div className="nav-content">
          <div className="header-text">
            <Link className="h3-booking">Fleet Management</Link>
            <FaLongArrowAltRight />
            {""}
            <Link className="h3-booking">{previousLocation}</Link>
            <FaLongArrowAltRight />
            <Link className="h3-booking">Car Details</Link>
          </div>
          {previousLocation === "View All Cars" && (
            <button
              className="createButton"
              onClick={() => handleToggleComponent("View All Cars")}
            >
              View All Cars
            </button>
          )}

          {previousLocation === "Inactive Cars" && (
            <button
              className="createButton"
              onClick={() => {
                handleToggleComponent("Inactive Cars");
              }}
            >
              Inactive Cars
            </button>
          )}

          {previousLocation === "Approval Pending" && (
            <button
              className="createButton"
              onClick={() => handleToggleComponent("Approval Pending")}
            >
              Approval Pending
            </button>
          )}
        </div>
        <div className="">
          <div
            className="add-new-car-vehicle-scrolbar"
            style={{ height: "95vh" }}
          >
            <div
              style={{
                backgroundColor: "#38B000",
                borderRadius: "2px",
                boxShadow: "0px 4px 22px 0px rgba(0, 0, 0, 0.17)",
                color: "#FFF",
                padding: "10px",
                fontFamily: "Lato",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: "700",
                lineHeight: "normal",
              }}
            >
              Vehicle Information
            </div>
            {singleCarDetail && (
              <div className="columns-4">
                <div className="vehicle-div">
                  <div className="inner-div">
                    <div className="dropdown-heading-car">Location</div>
                    <div className="fieldData">
                      {singleCarDetail.location?.city},{" "}
                      {singleCarDetail.location?.state}
                    </div>
                  </div>
                  <div className="inner-div">
                    <div className="dropdown-heading-car">Car Make</div>
                    <div className="fieldData" style={{}}>{singleCarDetail.carMake}</div>
                  </div>


                  <div className="inner-div">
                    <div className="dropdown-heading-car">Body Colour</div>
                    <div className="fieldData">{singleCarDetail.carColor}</div>
                  </div>


                  <div className="inner-div">
                    <div className="dropdown-heading-car">Source Type</div>
                    <div className="fieldData">{singleCarDetail.driver ? "Outsourced" : "Company Owned"}</div>
                  </div>
                </div>
                <div className="vehicle-div">
                  <div className="inner-div">
                    <div className="dropdown-heading-car">
                      Vehicle Owner / Driver
                    </div>
                    <div className="fieldData">
                      {singleCarDetail.driver?.firstName}{" "}
                      {singleCarDetail.driver?.lastName}
                    </div>
                  </div>
                  <div className="inner-div">
                    <div className="dropdown-heading-car">Model</div>
                    <div className="fieldData"> {singleCarDetail?.carModel}</div>
                  </div>
                  <div className="inner-div">
                    <div className="dropdown-heading-car">Year</div>
                    <div className="fieldData">
                      {singleCarDetail?.registrationCertificate?.year}
                    </div>
                  </div>
                </div>
                <div className="vehicle-div">
                  <div className="inner-div">
                    <div className="dropdown-heading-car">Car Category</div>
                    <div className="fieldData">
                      {singleCarDetail.carCategory?.category}
                    </div>
                  </div>
                  <div className="inner-div">
                    <div className="dropdown-heading-car">Fuel Type</div>
                    <div className="fieldData">{singleCarDetail.fuelType}</div>
                  </div>
                  <div className="inner-div">
                    <div className="dropdown-heading-car">Vehicle Number</div>
                    <div className="fieldData">
                      {singleCarDetail.registrationCertificate?.number}
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div
              /*style={{
              backgroundColor: "#38B000",
              borderRadius: "2px",
              boxShadow: "0px 4px 22px 0px rgba(0, 0, 0, 0.17)",
              color: "#FFF",
              padding: "10px",
              fontFamily: "Lato",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: "700",
              lineHeight: "normal",
            }}*/
              className="add-new-car-vehicle"
            >
              Upload Documents
            </div>
            {singleCarDetail && (
              <div className="user-details">
                <div className="user-details-section">
                  <div className="user-detail" style={{marginBottom:"100px"}}>
                    <div className="user-headin">Car Photo Front</div>
                    <div className="dropdown">
                      <div className="pic">
                        <img
                          className="pic-selected"
                          src={singleCarDetail.carPhoto?.frontPhoto}
                          alt=""
                          onClick={() =>
                            hanldeImagePopup(
                              singleCarDetail.carPhoto?.frontPhoto
                            )
                          }
                        />
                      </div>

                      {/*<label className="upload">
                <input
                  type="file"
                  onChange={(e) => handleFileChange(e, setProfilePhoto)}
                />
                {/*<span>Choose File</span>
              </label>
              {profilePhoto && (
                <p className="selected">Selected File: {profilePhoto.name}</p>
              )}*/}
                    </div>
                  </div>
                  <div className="user-detail">
                    <div className="user-headin">Permit Details</div>
                    <div className="dropdown">
                      <div className="pic">
                        <img
                          className="pic-selected"
                          src={singleCarDetail.permit?.photo}
                          alt=""
                          onClick={() =>
                            hanldeImagePopup(singleCarDetail.permit?.photo)
                          }
                        />
                        <div>[{singleCarDetail.permit?.expiryDate}]</div>
                      </div>
                      {/*<label className="upload">
                <input
                  type="file"
                  onChange={(e) => handleFileChange(e, setPanCard)}
                />
                <span>Choose File</span>
              </label>
              {panCard && (
                <p className="selected">Selected File: {panCard.name}</p>
              )}*/}
                    </div>
                  </div>
                </div>
                <div className="user-details-section">
                <div className="user-detail" style={{marginBottom:"100px"}}>
                    <div className="user-headin">Car Photo Side</div>
                    <div className="dropdown">
                      <div className="pic">
                        <img
                          className="pic-selected"
                          src={singleCarDetail.carPhoto?.sidePhoto}
                          alt=""
                          onClick={() =>
                            hanldeImagePopup(
                              singleCarDetail.carPhoto?.sidePhoto
                            )
                          }
                        />
                      </div>
                      {/*<label className="upload">
                <input
                  type="file"
                  onChange={(e) => handleFileChange(e, setAadharCardFront)}
                />
                <span>Choose File</span>
              </label>
              {aadharCardFront && (
                <p className="selected">
                  Selected File: {aadharCardFront.name}
                </p>
              )}*/}
                    </div>
                  </div>
                  <div className="user-detail">
                    <div className="user-headin">PUC Test</div>
                    <div className="dropdown">
                      <div className="pic">
                        <img
                          className="pic-selected"
                          src={singleCarDetail.puc?.photo}
                          alt=""
                          onClick={() =>
                            hanldeImagePopup(singleCarDetail.puc?.photo)
                          }
                        />
                        <div>[{singleCarDetail.puc?.expiryDate}]</div>
                      </div>
                      {/*<label className="upload">
                <input
                  type="file"
                  onChange={(e) => handleFileChange(e, setAadharCardBack)}
                />
                <span>Choose File</span>
              </label>
              {aadharCardBack && (
                <p className="selected">Selected File: {aadharCardBack.name}</p>
              )}*/}
                    </div>
                  </div>
                </div>
                <div className="user-details-section">
                <div className="user-detail" style={{marginBottom:"80px"}}>
                    <div className="user-headin">
                      Registration Certificate Front
                    </div>
                    <div className="dropdown">
                      <div className="pic">
                        <img
                          className="pic-selected"
                          src={
                            singleCarDetail.registrationCertificate?.frontPhoto
                          }
                          alt=""
                          onClick={() =>
                            hanldeImagePopup(
                              singleCarDetail.registrationCertificate
                                ?.frontPhoto
                            )
                          }
                        />
                      </div>
                      {/*<label className="upload">
                <input
                  type="file"
                  onChange={(e) => handleFileChange(e, setDrivingLicenseFront)}
                />
                <span>Choose File</span>
              </label>
              {drivingLicenseFront && (
                <p className="selected">
                  Selected File: {drivingLicenseFront.name}
                </p>
              )}*/}
                    </div>
                  </div>
                  <div className="user-detail">
                    <div className="user-headin">
                      Registration Certificate Back
                    </div>
                    <div className="dropdown">
                      <div className="pic">
                        <img
                          className="pic-selected"
                          src={
                            singleCarDetail.registrationCertificate?.backPhoto
                          }
                          alt=""
                          onClick={() =>
                            hanldeImagePopup(
                              singleCarDetail.registrationCertificate?.backPhoto
                            )
                          }
                        />
                      </div>
                      {/*<label className="upload">
                <input
                  type="file"
                  onChange={(e) => handleFileChange(e, setDrivingLicenseBack)}
                />
                <span>Choose File</span>
              </label>
              {drivingLicenseBack && (
                <p className="selected">
                  Selected File: {drivingLicenseBack.name}
                </p>
              )}*/}
                    </div>
                  </div>
                </div>
                <div className="user-details-section">
                  <div className="user-detail">
                    <div className="user-headin">
                      Insurance
                    </div>
                    <div className="dropdown">
                      <div className="pic">
                        <img
                          className="pic-selected"
                          src={singleCarDetail.insurance?.photo}
                          alt=""
                          onClick={() =>
                            hanldeImagePopup(singleCarDetail.insurance?.photo)
                          }
                        />
                        <div>[{singleCarDetail.insurance?.expiryDate}]</div>
                      </div>
                      {/*<label className="upload">
                <input
                  type="file"
                  onChange={(e) =>
                    handleFileChange(e, setPoliceClearanceCertificate)
                  }
                />
                <span>Choose File</span>
              </label>
              {policeClearanceCertificate && (
                <p className="selected">
                  Selected File: {policeClearanceCertificate.name}
                </p>
              )}*/}
                    </div>
                  </div>
                </div>
              </div>
            )}


            <div style={{
              marginTop:"50px ",
              fontSize:"15px",
              fontWeight:700,
              marginBottom:"10px",
              marginLeft:"20px"
            }}>Remark</div>
            <div className="remark-remark">
              <input className="remak" placeholder="Type Here" />
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <button
                style={{
                  borderRadius: "5px",
                  margin: "10px",
                  border: "0.5px solid #38B000",
                  background: "#38B000",
                  width: "150px",
                  padding: "6px",
                  color: "#FFF",
                  fontSize: "15px",
                  cursor: "pointer",
                }}
                onClick={() => handleModified()}
              >
                Modify
              </button>
              {previousLocation === "Approval Pending" ? (
                <>
                  <button
                    onClick={() => handleApprovalClick(carid)}
                    style={{
                      borderRadius: "5px",
                      margin: "10px",
                      border: "0.5px solid #38B000",
                      background: "#38B000",
                      width: "150px",
                      padding: "6px",
                      color: "#FFF",
                      fontSize: "15px",
                      cursor: "pointer"
                    }}
                  >
                    Approve
                  </button>
                  <button
                    onClick={handleReSubmitClick}
                    style={{
                      borderRadius: "5px",
                      margin: "10px",
                      border: "0.5px solid #38B000",
                      background: "#38B000",
                      width: "150px",
                      padding: "6px",
                      color: "#FFF",
                      fontSize: "15px",
                      cursor: "pointer"
                    }}
                  >
                    ReSubmit
                  </button>
                </>
              ) : null}
              {showPopup && (
                <div className="popup">
                  <div className="popup-content">
                    <span className="close" onClick={handlePopupClose}>
                      &times;
                    </span>
                    <div className="popup-content-checkbox">
                      <div className="popup-checkbox">
                        <input
                          type="checkbox"
                          id="checkbox1"
                          name="checkbox1"
                          value="Document Pending"
                          // checked={reason === "Document Pending"}
                          onChange={handleCheckboxChange}
                        />
                        <label htmlFor="checkbox1">Document Pending</label>
                      </div>
                      <div className="popup-checkbox">
                        <input
                          type="checkbox"
                          id="checkbox1"
                          name="checkbox1"
                          value="Missing Profile Information"
                          // checked={reason === "Missing Profile Information"}
                          onChange={handleCheckboxChange}
                        />
                        <label htmlFor="checkbox1">
                          Missing Profile Information
                        </label>
                      </div>
                    </div>
                    <textarea
                      type="text"
                      value={popupText}
                      placeholder="Enter remark here"
                      onChange={(e) => setPopupText(e.target.value)}
                    />
                    <button onClick={()=>handlePopupSubmit(carid)}>Resubmit</button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {popUpImage && (
        <>
          <div className="imagepopUp">
            <div style={{ marginLeft: "100%", cursor: "pointer" }}>
              <IoIosCloseCircleOutline
                style={{ width: "30px", height: "30px" }}
                onClick={() => setPopUpImage(false)}
              />
            </div>
            <img
              src={displayImage}
              style={{ width: "100%", height: "100%" }}
              alt="Document image"
            />
          </div>
        </>
      )}
    </>
  );
};

export default CarDetails;
