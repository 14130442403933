import axios from "axios";

export function fetchAllCustomer() {
  return new Promise(async (resolve) => {
    try {
      const response = await axios.get(
        "https://api.yatricabs.com/v1/admin/users"
      );
      resolve(response.data.data);
    } catch (e) {
      console.log("Error in fetching Data", e.message);
    }
  });
}
