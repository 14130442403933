// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-input3 {
  padding: 10px;
  border-radius: 6px;
  background: #fff;
  box-shadow: 0px 4px 22px 0px rgba(0, 0, 0, 0.17);
  border: none;
  outline: none;
  font-family: Lato;
  font-size: 10px;
  font-weight: 500;
  line-height: 12px;
  text-align: left;  
  color: #333;
  margin-right: 4px;
  width: 195px;
  margin-bottom: 10px;
}

.user-heading3 {
  margin-top: 8px;
  text-align: left;
  width: -moz-fit-content;
  width: fit-content;
  font-weight: bold;
  margin-right: 10px;
  font-family: Lato;
font-size: 11px;
font-weight: 500;
line-height: 13.2px;
text-align: left;
color:#000000;
}

.user-detail-section {
  width: 30%;
  margin: 10px;
  padding: 15px;
  border-radius: 8px;
}


.user-detail textarea{
  margin:0;
  padding: 8px;
  height: 65px;
}

.user-detail textarea::-webkit-scrollbar {
  display: none;
}
`, "",{"version":3,"sources":["webpack://./src/components/CarManagement/AddCarCategory.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,kBAAkB;EAClB,gBAAgB;EAChB,gDAAgD;EAChD,YAAY;EACZ,aAAa;EACb,iBAAiB;EACjB,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,gBAAgB;EAChB,WAAW;EACX,iBAAiB;EACjB,YAAY;EACZ,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,uBAAkB;EAAlB,kBAAkB;EAClB,iBAAiB;EACjB,kBAAkB;EAClB,iBAAiB;AACnB,eAAe;AACf,gBAAgB;AAChB,mBAAmB;AACnB,gBAAgB;AAChB,aAAa;AACb;;AAEA;EACE,UAAU;EACV,YAAY;EACZ,aAAa;EACb,kBAAkB;AACpB;;;AAGA;EACE,QAAQ;EACR,YAAY;EACZ,YAAY;AACd;;AAEA;EACE,aAAa;AACf","sourcesContent":[".custom-input3 {\n  padding: 10px;\n  border-radius: 6px;\n  background: #fff;\n  box-shadow: 0px 4px 22px 0px rgba(0, 0, 0, 0.17);\n  border: none;\n  outline: none;\n  font-family: Lato;\n  font-size: 10px;\n  font-weight: 500;\n  line-height: 12px;\n  text-align: left;  \n  color: #333;\n  margin-right: 4px;\n  width: 195px;\n  margin-bottom: 10px;\n}\n\n.user-heading3 {\n  margin-top: 8px;\n  text-align: left;\n  width: fit-content;\n  font-weight: bold;\n  margin-right: 10px;\n  font-family: Lato;\nfont-size: 11px;\nfont-weight: 500;\nline-height: 13.2px;\ntext-align: left;\ncolor:#000000;\n}\n\n.user-detail-section {\n  width: 30%;\n  margin: 10px;\n  padding: 15px;\n  border-radius: 8px;\n}\n\n\n.user-detail textarea{\n  margin:0;\n  padding: 8px;\n  height: 65px;\n}\n\n.user-detail textarea::-webkit-scrollbar {\n  display: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
