import React from "react";
import { RxCross2 } from "react-icons/rx";
import "./TripTypePopups.css";

const Oustation_One_Way = ({ bookingDetails, closepopup }) => {
  return (
    <div className="airport-popup-main-container">
      <div className="airport-popup-main-booking">
        <div className="type-airport-popup-OO">
          <div className="summary">Fare Summary</div>
          <div className="type-airport-popup-header">
            <div className="local-f2">Fare Breakup :</div>
            <h5
              onClick={closepopup}
              style={{
                fontSize: "1.8rem",
                cursor: "pointer",
                height: "0px",
                width: "19px",
                marginTop: "-31px",
              }}
            >
              <RxCross2 />
            </h5>
          </div>
          <div className="airport-popup-text">
            <div className="airport-fare-amount">
              <div className="airport-each-amount">
                <span className="left-span">Approx Oneway Distance : </span>
                <span className="middle-span">
                  {bookingDetails.approxDistance} Kms
                </span>
                <span className="right-span"></span>
                {/* remained empty for proper alignment of span*/}
              </div>
              <div className="airport-each-amount">
                {/* <span className="left-span">Min Km Charged : </span> */}
                {/* <span className="middle-span">
                  {bookingDetails.tariff.fareDetails.minKmPerDay} Kms/day
                </span> */}
                <span className="right-span"></span>
                {/* remained empty for proper alignment of span*/}
              </div>
              <br />

              <div className="airport-each-amount">
                <span className="left-span  approx-oneway">
                  Approx Oneway Charges :
                </span>
                <span className="middle-span approx-oneway">
                  {bookingDetails.approxDistance} Km x Rs.
                  {bookingDetails.tariff.fareDetails.perKmCharge}
                  /km
                </span>
                <span className="right-span approx-oneway">
                  = Rs.
                  {bookingDetails.approxDistance *
                    bookingDetails.tariff.fareDetails.perKmCharge}
                  /-
                </span>
              </div>

              {/* Conditionally render Morning Charge */}
              {bookingDetails?.morningAllowance> 0 && (
                <div className="airport-each-amount">
                  <span className="left-span">Morning Charge :</span>
                  <span className="right-span">
                    = Rs.{" "}
                    {bookingDetails?.morningAllowance}/-
                  </span>
                </div>
              )}

              {/* Conditionally render Night Charge */}
              {bookingDetails?.nightAllowance > 0 && (
                <div className="airport-each-amount">
                  <span className="left-span  l-s-gst">Night Charge :</span>
                  <span className="middle-span l-s-gst">
                  {" "}
                </span>
                  <span className="right-span  l-s-gst">
                    = Rs.{" "}
                    {bookingDetails?.nightAllowance}/-
                  </span>
                </div>
              )}

              <div className="airport-each-amount">
                <span className="left-span l-s-gst">GST :</span>
                <span className="middle-span l-s-gst">
                  {bookingDetails.tariff.fareDetails.gstRate} % GST on Rs.
                  {bookingDetails?.nightAllowance *
                    bookingDetails.noOfDays +
                    bookingDetails.approxDistance *
                      bookingDetails.tariff.fareDetails.perKmCharge}
                </span>
                <span className="right-span l-s-gst">
                  = Rs.
                  {bookingDetails.gstOnEstimatedFare}
                  /-
                </span>
              </div>
              <br />

              <div className="airport-each-amount">
                <span className="left-span green-total">Total :</span>
                <span className="middle-span"></span>
                {/* remained empty for proper alignment of span*/}
                <span className="right-span" style={{ marginTop: "-4px" }}>
                  <b>
                    Rs.
                    {bookingDetails.estimatedFare}
                  </b>
                </span>
              </div>
              <br />
            </div>
            <div className="airport-total-text">
              Extra Charges: If you will use car/cab more than
              {bookingDetails.approxDistance} Kms for oneway, extra charges as
              follows:
            </div>

            <div className="airport-extra-charges">
              <div className="airport-each-extra-charge">
                <span className="left-span">
                  Extra Km after {bookingDetails.approxDistance} Kms
                </span>
                <span className="right-span" style={{}}>
                  = Rs.
                  {bookingDetails.tariff.fareDetails.extraKmCharge}
                  /km
                </span>
              </div>
            </div>
            <div className="airport-instruction">
              <div className="airport-Tc air-2">
                **T&c apply:-
                <div className="airport-all-instructions">
                  1) One day means one calendar day (12 midnight to 12
                  midnight).
                  <br></br>
                  2) Kilometres and Hours will be calculated from garage to
                  garage.
                  <br></br>
                  3) Toll, Parking, State Border Charges etc. not include in the
                  above cost, you have to pay extra as applicable.
                  <br></br>
                  4) AC will be switched off in hilly areas. <br></br>
                  5) Driver would take care of his food and stay.
                  <br></br>
                  6) For driving between 09:45 PM to 06:00 AM on any of the
                  nights, an Driver Night Allowance will be applicable and is to
                  be paid to the driver.
                  <br></br>
                  7) Your trip includes one pick up in Pick-up city and one drop
                  to destination city. It does not include within city travel.
                  <br></br>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Oustation_One_Way;
