import React from "react";
import "./Fourthtable.css";
import { useDispatch, useSelector } from "react-redux";
import { selectbookingDetails } from "../DetailsSlice";

const FourthExtertable = ({ bookingDetailsTableFourthExter }) => {
  console.log("data is here:", bookingDetailsTableFourthExter);
  const bookingDetails = useSelector(selectbookingDetails);


  // Ensure data is not null or undefined
  const pickupDateString = bookingDetailsTableFourthExter?.pickupDate || "0-0-0";
  const returnDateString = bookingDetailsTableFourthExter?.returnDate || "0-0-0";

  // Parse date strings into Date objects
  const pickupDateParts = pickupDateString.split("-").reverse();
  const returnDateParts = returnDateString.split("-").reverse();

  // Create Date objects with fallbacks
  const pickupDate = new Date(
    pickupDateParts[0],
    pickupDateParts[1] - 1,
    pickupDateParts[2] || 0
  );
  const returnDate = new Date(
    returnDateParts[0],
    returnDateParts[1] - 1,
    returnDateParts[2] || 0
  );

  // Calculate difference in days
  const differenceInMs = returnDate - pickupDate;
  const differenceInDays = differenceInMs / (1000 * 60 * 60 * 24);

  // Ensure all other fields have default values if missing
  const totaldistancetravelled = "N/A";
  const extraKm = "N/A";
  const noofdays = "N/A";
  // const extraDays =
  //   data.data?.extraHrs >= 24 ? Math.floor(data.data?.extraHrs / 24) : "0";

  const extraDays = "N/A";

  if (bookingDetails?.tariff?.tripType === "Outstation") {
    var coverageDetail = [
      {
        lable: "Total Distance Covered",
        value: "N/A",
      },
      { lable: "Extra km", value: "N/A" },
      {
        lable: "Actual No of Days",
        value: "N/A",
      },
      {
        lable: "Extra Days",
        value: "N/A",
      },
    ];

  } else if (bookingDetails?.tariff?.tripType === "Local") {

    var coverageDetail = [
      {
        lable: "Total Distance Covered",
        value: "N/A",
      },
      {
        lable: "Total Hours Covered",
        value: "N/A",
      },
      { lable: "Extra Km", value: "N/A" },

      {
        lable: "Extra Hours",
        value: "N/A",
      },
      {
        lable: "Night Allowance",
        value: "N/A",
      },
    ];

  } else {

    var coverageDetail = [
      {
        lable: "Total Distance Covered",
        value: "N/A",
      },
      {
        lable: "Total Hours Covered",
        value: "N/A",
      },
      { lable: "Extra km", value: "N/A" },

      {
        lable: "Extra Hours",
        value: "N/A",
      },
      {
        lable: "Night Allowance",
        value: "N/A",
      },
    ];
  }

  return (
    <div className="billing_fourth_containers">
      <div className="billing_fourth_scroll">
        <table className="billing-table-fourth">
          <thead>
            <tr>
              <th colSpan="2">Extra Coverage</th>
            </tr>
          </thead>
          <tbody className="first_billing_table_data">
            {coverageDetail.map((row, index) => (
              <React.Fragment key={index}>
                <tr>
                  <td className="fourth-td">{row.lable}</td>
                  <td>{row.value}</td>
                </tr>
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default FourthExtertable;
