import React from "react";
import "./Feedback.css";
import TableFirstNotCompleted from "./tablebilling/TableFirstNotCompleted";
import { selectbillingDetails, selectbookingDetails } from "./DetailsSlice";
import { useSelector } from "react-redux";
import { IoStar } from "react-icons/io5";
import TableFirst from "./tablebilling/TableFirst";

const Feedback = () => {
  const bookingDetails = useSelector(selectbookingDetails);
  const billingDetails = useSelector(selectbillingDetails);

  // console.log(bookingDetails.rideReview);

  const numberOfRating = bookingDetails?.rideReview?.rating;
  // console.log(numberOfRating);

  const ratingDetail = {
    1: {
      color: "#FF0000",
      comment: "Very Bad",
    },
    2: {
      color: "#FF8C00",
      comment: "Bad",
    },
    3: {
      color: "#E3D96F",
      comment: "Average",
    },
    4: {
      color: "#CFD72A",
      comment: "Good",
    },

    5: {
      color: "#38B000",
      comment: "Excellent",
    },
  };

  return (
    <div
      className="feedback-container"
      style={{
        fontSize: "13px",
        fontWeight: 700,
      }}
    >
      <div>
        {bookingDetails?.status === "Completed" ? (
          <>
            <TableFirst data={billingDetails?.data} />
          </>
        ) : (
          <>
            <TableFirstNotCompleted bookingDetailsTablefirst={bookingDetails} />
          </>
        )}
     
      </div>

      <div
        style={{
          display: "flex",
          gap: "80px",
          margin: "20px 40px",

          placeItems: "center",
        }}
      >
        <div>Rating</div>

        <div>
          {[1, 2, 3, 4, 5].map((start, index) => (
            <span
              style={{
                color:
                  index < numberOfRating
                    ? ratingDetail[numberOfRating].color
                    : "#8B8B8B",
                fontSize: "15px",
              }}
              key={index}
            >
              <IoStar />
            </span>
          ))}
        </div>

        <span
          style={{
            color:ratingDetail[numberOfRating]?.color,
          }}
        >
          {!numberOfRating?(<p>No rating available</p>):<p>{ratingDetail[numberOfRating]?.comment}</p>}
        </span>
      </div>

      <div
        style={{
          display: "flex",
          margin: "30px 40px",
          gap: "40px",
        }}
      >
        <div>Feedback</div>
        <div
          style={{
            backgroundColor: " #E6F5E0",
            minWidth: "650px",
            borderRadius: "15px",
            border: "2px solid #38B000",
            padding: "10px",
            paddingBottom: "50px",
          }}
        >
          {!bookingDetails?.rideReview?.comment ? (
            <p>No comment available.</p>
          ) : (
            <p>{bookingDetails?.rideReview?.comment}</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Feedback;
