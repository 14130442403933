import React from "react";
import "./Filter.css";

export default function Filter() {
  return (
    <div className="filter-container">
      <div className="text">Filters</div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="my-svg"
        viewBox="0 0 14 14"
        fill="none"
      >
        <path
          d="M5.17176 6.32244V12.792C5.17176 12.8792 5.19334 12.965 5.23444 13.0412C5.27554 13.1174 5.3348 13.1815 5.40659 13.2273C5.47838 13.2732 5.56031 13.2993 5.64459 13.3031C5.72888 13.307 5.81272 13.2885 5.88814 13.2494L8.8566 11.7144C8.93867 11.6719 9.00768 11.6066 9.05591 11.5258C9.10414 11.445 9.12969 11.3519 9.12969 11.257V6.32244L13.4706 0.836331C13.5117 0.784323 13.5426 0.724434 13.5614 0.660083C13.5803 0.595731 13.5866 0.528178 13.5802 0.46128C13.5738 0.394382 13.5547 0.329449 13.524 0.270189C13.4933 0.210929 13.4516 0.158502 13.4013 0.115901C13.351 0.0733005 13.2931 0.0413609 13.2309 0.0219055C13.1687 0.0024502 13.1033 -0.00413972 13.0387 0.00251208C12.974 0.00916387 12.9112 0.0289272 12.8539 0.0606734C12.7966 0.0924196 12.7459 0.135527 12.7047 0.187535L8.25202 5.81589C8.17963 5.90741 8.14012 6.02204 8.14021 6.14029V10.9408L6.16124 11.9641V6.14029C6.16133 6.02204 6.12182 5.90741 6.04943 5.81589L2.25773 1.0236H10.1192C10.2504 1.0236 10.3762 0.969694 10.469 0.873737C10.5618 0.777781 10.6139 0.647636 10.6139 0.511933C10.6139 0.37623 10.5618 0.246085 10.469 0.150128C10.3762 0.0541719 10.2504 0.000264019 10.1192 0.000264019H1.21382C1.1201 0.000199353 1.02828 0.0276694 0.949069 0.0794759C0.869854 0.131282 0.806497 0.205294 0.766375 0.292894C0.726253 0.380494 0.711016 0.47808 0.722438 0.574288C0.73386 0.670497 0.771471 0.761371 0.830892 0.836331L5.17176 6.32244Z"
          fill="#38B000"
        />
      </svg>
    </div>
  );
}
