import { useState, useEffect, useRef } from "react";
import useOutsideClick from "./useOutsideClick";
import "./Table.css";
import "./Dropdown.css";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import { fetchBooking, selectAllBooking } from "./BookingSlice";
import { IoIosArrowDown } from "react-icons/io";
import Table from "./Table";
import { CiFilter } from "react-icons/ci";
import "./DropDownMain.css";
import "./DatePickerStyle.css";
import "./viewAllBooking.css";
import CustomDatePicker from "../CustomDatePicker/CustomDatePicker.jsx";
import { FaCalendarDays } from "react-icons/fa6";
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export default function ViewAllBooking() {
	const dispatch = useDispatch();
	const bookings = useSelector(selectAllBooking) || [];

	const [selectedLocation, setSelectedLocation] = useState("");
	const [selectedTripType, setselectedTripType] = useState("");
	const [selectedCarCategory, setselectedCarCategory] = useState("");
	const [selectedSubTripType, setSelectedSubTripType] = useState("");
	const [selectedSource, setSelectedSource] = useState("");
	const [searchById, setSearchById] = useState("");
	const [searchByName, setSearchByName] = useState("");
	const [locationOptions, setLocationOptions] = useState([]);
	const [carCategoriesOptions, setCarCategoriesOptions] = useState([]);
	const [tripOptions, setTripOptions] = useState([]);
	const [subTripOptions, setSubTripOptions] = useState([]);
	const [selectedStatus, setSelectedStatus] = useState("");
	const [searchQuery, setSearchQuery] = useState("");
	const [sortOrder, setSortOrder] = useState("ascending");
	const [sortBy, setSortBy] = useState(""); // Default to no sorting

	const calendarRef = useRef(null);

	useOutsideClick(calendarRef, () => setShowCalendar(false));

	useEffect(() => {
		dispatch(fetchBooking());
	}, [dispatch]);

	useEffect(() => {
		const extractCategoriesAsOptions = (dataArray) => {
			if (Array.isArray(dataArray.data)) {
				return dataArray.data.map((item) => ({
					label: item.category,
					value: item.category,
				}));
			}
			return [];
		};

		const extractLocationsAsOptions = (dataArray) => {
			if (Array.isArray(dataArray.data)) {
				return dataArray.data.map((item) => ({
					label: item.location,
					value: item.location,
				}));
			}
			return [];
		};

		const extractTripAsOptions = (dataArray) => {
			if (Array.isArray(dataArray.data)) {
				return dataArray.data.map((item) => ({
					label: item.tripType,
					value: item.tripType,
				}));
			}
			return [];
		};

		const fetchOptions = async () => {
			try {
				const locationResponse = await fetch(
					`${API_BASE_URL}/v1/locations`,
				);
				const locationData = await locationResponse.json();
				const locationOptions = extractLocationsAsOptions(locationData);
				setLocationOptions(locationOptions);

				const carResponse = await fetch(
					`${API_BASE_URL}/v1/car-categories`,
				);
				const carData = await carResponse.json();
				const carCategoriesOptions = extractCategoriesAsOptions(carData);
				setCarCategoriesOptions(carCategoriesOptions);

				const tripResponse = await fetch(
					`${API_BASE_URL}/v1/trip-types`,
				);
				const tripData = await tripResponse.json();
				const tripOptions = extractTripAsOptions(tripData);
				setTripOptions(tripOptions);
			} catch (error) {
				console.error("Error fetching data: ", error);
			}
		};
		fetchOptions();
	}, []);

	const handleSearchChange = (e) => {
		setSearchQuery(e.target.value.toLowerCase());
	};

	useEffect(() => {
		const extractSubTripAsOptions = (dataArray) => {
			if (Array.isArray(dataArray.data) && dataArray.data.length > 0) {
				const subTripTypes = dataArray.data[0].subTripTypes; // Access the subTripTypes array
				return subTripTypes.map((subTrip) => ({
					label: subTrip,
					value: subTrip,
				}));
			}
			return [];
		};

		const fetchSubTripTypes = async () => {
			try {
				if (selectedTripType) {
					const subTripResponse = await fetch(
						`${API_BASE_URL}/v1/sub-trip-types/${selectedTripType}`,
					);
					const subTripData = await subTripResponse.json();
					const subTripOptions = extractSubTripAsOptions(subTripData);
					console.log("subTripOptions:", subTripOptions);
					setSubTripOptions(subTripOptions);
				} else {
					setSubTripOptions([]);
				}
			} catch (error) {
				console.error("Error fetching sub-trip types: ", error);
			}
		};

		fetchSubTripTypes();
	}, [selectedTripType]);

	const extractTripAndSubTripTypes = (fullTripType) => {
		const [tripType, subTripType] = fullTripType.split(" - ");
		return { tripType, subTripType };
	};

	// Function to parse date from "dd-MM-yyyy" format
	// const parseDate = (dateString) => {
	//   return parse(dateString, 'dd-MM-yyyy', new Date());
	// };

	const parseDate = (dateString) => {
		// Split the dateString into components
		const [day, month, year] = dateString.split("-").map(Number);

		// Create a Date object (Note: months are 0-based in JavaScript)
		return new Date(year, month - 1, day);
	};

	const [dateRange, setDateRange] = useState([
		{ startDate: null, endDate: null, key: "selection" },
	]);
	const [showCalendar, setShowCalendar] = useState(false);

	const handleApplyDateRange = ({ from, to }) => {
		console.log("Applying date range:", from, to);

		setDateRange([{ startDate: from, endDate: to }]); // Apply the temporary date range
		setShowCalendar(false); // Close the calendar
	};

	const headers = [
		{ label: "Sr no.", sortable: false },
		{ label: "ID no.", sortable: true, criteria: "id" },
		{ label: "Booking Source", sortable: true, criteria: "source" },
		{ label: "Customer's Name", sortable: true, criteria: "name" },
		{ label: "Location", sortable: true, criteria: "location" },
		{ label: "From Date", sortable: true, criteria: "fromDate" },
		{ label: "To Date", sortable: true, criteria: "toDate" },
		{ label: "Trip Type", sortable: true, criteria: "tripType" },
		{ label: "Status", sortable: true, criteria: "status" },
		{ label: "Vehicle Type", sortable: true, criteria: "vehicleType" },
		{ label: "Fare", sortable: true, criteria: "fare" },
	];

	// Extract numeric part from booking ID
	const extractNumericPart = (bookingId) => {
		const match = bookingId.match(/(\d+)-(\d+)/);
		if (match) {
			return parseInt(match[1] + match[2], 10); // Combine both parts into a single number
		}
		return NaN;
	};

	const sortedBookings = [...bookings].sort((a, b) => {
		if (sortBy === "id") {
			const numA = extractNumericPart(a.bookingId);
			const numB = extractNumericPart(b.bookingId);
			return sortOrder === "ascending" ? numA - numB : numB - numA;
		} else if (sortBy === "name") {
			const nameA = a?.name.toLowerCase();
			const nameB = b?.name.toLowerCase();
			if (nameA < nameB) return sortOrder === "ascending" ? -1 : 1;
			if (nameA > nameB) return sortOrder === "ascending" ? 1 : -1;
			return 0;
		} else if (sortBy === "fromDate") {
			const dateA = parseDate(a?.fromDate);
			const dateB = parseDate(b?.fromDate);
			return sortOrder === "ascending" ? dateA - dateB : dateB - dateA;
		} else if (sortBy === "toDate") {
			const dateA = parseDate(a?.fromDate);
			const dateB = parseDate(b?.fromDate);
			return sortOrder === "ascending" ? dateA - dateB : dateB - dateA;
		} else if (sortBy === "fare") {
			const numA = a?.fare.toFixed(2);
			const numB = b?.fare.toFixed(2);
			return sortOrder === "ascending" ? numA - numB : numB - numA;
		} else if (sortBy === "location") {
			const nameA = a?.location.toLowerCase();
			const nameB = b?.location.toLowerCase();
			if (nameA < nameB) return sortOrder === "ascending" ? -1 : 1;
			if (nameA > nameB) return sortOrder === "ascending" ? 1 : -1;
			return 0;
		} else if (sortBy === "tripType") {
			const nameA = a?.tripType.toLowerCase();
			const nameB = b?.tripType.toLowerCase();
			if (nameA < nameB) return sortOrder === "ascending" ? -1 : 1;
			if (nameA > nameB) return sortOrder === "ascending" ? 1 : -1;
			return 0;
		} else if (sortBy === "status") {
			const nameA = a?.status.toLowerCase();
			const nameB = b?.status.toLowerCase();
			if (nameA < nameB) return sortOrder === "ascending" ? -1 : 1;
			if (nameA > nameB) return sortOrder === "ascending" ? 1 : -1;
			return 0;
		} else if (sortBy === "source") {
			const nameA = a?.source.toLowerCase();
			const nameB = b?.source.toLowerCase();
			if (nameA < nameB) return sortOrder === "ascending" ? -1 : 1;
			if (nameA > nameB) return sortOrder === "ascending" ? 1 : -1;
			return 0;
		} else if (sortBy === "vehicleType") {
			const nameA = a?.vehicleType.toLowerCase();
			const nameB = b?.vehicleType.toLowerCase();
			if (nameA < nameB) return sortOrder === "ascending" ? -1 : 1;
			if (nameA > nameB) return sortOrder === "ascending" ? 1 : -1;
			return 0;
		}

		return 0;
	});

	// Ensure all bookings are displayed if no date range is selected
	const filteredBookings = sortedBookings.filter((item) => {
		const { tripType, subTripType } = extractTripAndSubTripTypes(item.tripType);

		// Normalize and parse fromDate
		const bookingFromDate = item?.fromDate ? parseDate(item.fromDate) : null;

		// Define a flag for default date condition
		const isDefaultDate =
			!bookingFromDate ||
			bookingFromDate.getTime() === new Date("1970-01-01").getTime();

		// Debugging logs
		console.log("Booking From Date:", bookingFromDate);
		console.log("Date Range Start:", dateRange[0]?.startDate);
		console.log("Date Range End:", dateRange[0]?.endDate);

		// Date range filtering logic
		const [range] = dateRange;
		const areDatesNull = !range.startDate && !range.endDate;
		const isWithinDateRange =
			areDatesNull ||
			isDefaultDate ||
			(bookingFromDate >= range.startDate && bookingFromDate <= range.endDate);

		console.log("Are Dates Null:", areDatesNull);
		console.log("Is Within Date Range:", isWithinDateRange);

		return (
			(selectedLocation ? item?.location === selectedLocation : true) &&
			(selectedTripType ? tripType === selectedTripType : true) &&
			(selectedSubTripType ? subTripType === selectedSubTripType : true) &&
			(selectedCarCategory
				? item?.vehicleType === selectedCarCategory
				: true) &&
			(selectedSource ? item?.source === selectedSource : true) &&
			(selectedStatus ? item?.status === selectedStatus : true) &&
			isWithinDateRange &&
			(searchById
				? item?.bookingId?.toLowerCase().includes(searchById.toLowerCase())
				: true) &&
			(searchByName
				? item?.name?.toLowerCase().includes(searchByName.toLowerCase())
				: true) &&
			(item?.phone?.toLowerCase().includes(searchQuery) ||
				item?.bookingId?.toLowerCase().includes(searchQuery) ||
				item?.name?.toLowerCase().includes(searchQuery))
		);
	});

	const handleSort = (criteria, direction) => {
		if (sortBy === criteria) {
			// If already sorted by the same criteria and the same direction, do nothing
			if (sortOrder === direction) return;

			// If sorted by the same criteria but different direction, update the sortOrder
			setSortOrder(direction);
		} else {
			// If sorting by a new criteria, set the new criteria and direction
			setSortBy(criteria);
			setSortOrder(direction);
		}
	};

	const formatDate = (date) => {
		if (!date) {
			return "No Date Selected"; // Placeholder text
		}

		const options = { day: "2-digit", month: "short", year: "2-digit" };
		return new Intl.DateTimeFormat("en-GB", options).format(date);
	};

	return (
		<div>
			<div className="dropdown-main">
				<div className="main-1">
					<div>Filters</div>
					<div className="icon-1">
						<CiFilter color="#38b000" size={18} />
					</div>
				</div>

				<div className="main-2">
					<select
						className="dropdown-select-1"
						value={selectedSource}
						onChange={(e) => setSelectedSource(e.target.value)}
					>
						<option value="">Booking Source</option>
						<option value="Admin">Admin</option>
						<option value="Mobile">Mobile</option>
						<option value="Web">Web</option>
					</select>
					<IoIosArrowDown className="drop-icon" color="#FA7C07" size={15} />
				</div>

				<div className="main-2">
					<select
						className="dropdown-select-1"
						value={selectedLocation}
						onChange={(e) => setSelectedLocation(e.target.value)}
						style={{ width: "150px" }}
					>
						<option value="">Select Pickup City</option>
						{locationOptions.map((option) => (
							<option key={option.value} value={option.value}>
								{option.label}
							</option>
						))}
					</select>
					<IoIosArrowDown
						className="drop-icon"
						color="#FA7C07"
						size={15}
						style={{ right: "1px" }}
					/>
				</div>
				<div className="main-2">
					<select
						className="dropdown-select-1"
						value={selectedCarCategory}
						onChange={(e) => setselectedCarCategory(e.target.value)}
						style={{ width: "140px" }}
					>
						<option value="">Select Car Category</option>
						{carCategoriesOptions.map((option) => (
							<option key={option.value} value={option.value}>
								{option.label}
							</option>
						))}
					</select>
					<IoIosArrowDown
						className="drop-icon"
						color="#FA7C07"
						size={15}
						style={{ right: "1px" }}
					/>
				</div>
				<div className="main-2">
					<select
						className="dropdown-select-2"
						value={selectedTripType}
						onChange={(e) => setselectedTripType(e.target.value)}
					>
						<option value="">Trip Type</option>
						{tripOptions.map((option) => (
							<option key={option.value} value={option.value}>
								{option.label}
							</option>
						))}
					</select>
					<IoIosArrowDown className="drop-icon" color="#FA7C07" size={15} />
				</div>
				<div className="main-2">
					<select
						className="dropdown-select-2"
						value={selectedSubTripType}
						onChange={(e) => setSelectedSubTripType(e.target.value)}
						style={{ width: "100px" }}
					>
						<option value="">Sub Trip Type</option>
						{subTripOptions.map((option) => (
							<option key={option.value} value={option.value}>
								{option.label}
							</option>
						))}
					</select>
					<IoIosArrowDown className="drop-icon" color="#FA7C07" size={15} />
				</div>
				<div className="main-2">
					<select
						className="dropdown-select-1"
						value={selectedStatus}
						onChange={(e) => setSelectedStatus(e.target.value)}
						style={{ width: "100px" }}
					>
						<option value="">Status</option>
						<option value="Confirmed">Confirmed</option>
						<option value="Cab Assigned">Cab Assigned</option>
						<option value="In Route">In Route</option>
						<option value="Completed">Completed</option>
						<option value="Cancelled">Cancelled</option>
					</select>
					<IoIosArrowDown className="drop-icon" color="#FA7C07" size={15} />
				</div>

				<div className="main-2" ref={calendarRef}>
					<button
						className="dropdown-select-daterange"
						onClick={() => setShowCalendar(!showCalendar)}
						style={{
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
						}}
					>
						{dateRange[0].startDate
							? dateRange[0].endDate
								? `${formatDate(dateRange[0].startDate)} - ${formatDate(
										dateRange[0].endDate,
								  )}`
								: formatDate(dateRange[0].startDate)
							: "Select Date Range"}
					</button>
					<FaCalendarDays className="drop-icon" color="#FA7C07" size={15} />
					{showCalendar && (
						<div
							style={{
								position: "relative",
							}}
						>
							<div
								style={{
									position: "absolute",
									zIndex: 100,
									left: "-500px",
								}}
							>
								<CustomDatePicker
									closeCalendar={() => setShowCalendar(false)}
									setSelectedDate={({ from, to }) => {
										handleApplyDateRange({ from, to });
									}}
									initialRange={dateRange[0]}
								/>
							</div>
						</div>
					)}
				</div>

				<div className="main-2">
					<input
						type="text"
						placeholder="Search by ID, Name, or Mobile No"
						value={searchQuery}
						onChange={handleSearchChange}
						className="search-input"
						style={{ padding: 0 }}
					/>
				</div>
			</div>

			<Table
				headers={headers}
				bookings={filteredBookings}
				onSort={handleSort}
				sortBy={sortBy}
				sortOrder={sortOrder}
			/>
		</div>
	);
}
