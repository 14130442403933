import React from "react";

const AirportDetails = (props) => {
  const { carCategoriesDetails, selectedCabType, onEstimatedFareChange } =
    props;

  const cabType = carCategoriesDetails?.find(
    (item) => item?.carCategory?.category === selectedCabType
  );

  console.log("Selected cabType", cabType);

  const fareDetails = cabType?.fareDetails;
  onEstimatedFareChange(fareDetails?.estimatedFare);

  return (
    <>
      <div className="booking-section">
        <div className="fare-details  from-the-airport-fare-details">
          <div className="fare-head">
            <div className="fare-head-content">Fare Details</div>
          </div>
          <div className="rows">
            <div className="row-head">
              <div className="col col-head">Ride Estimate</div>
              <div className="col col-head">
                <div className="col-item">
                  Rs.{" "}
                  {fareDetails && fareDetails?.estimatedFare
                    ? fareDetails?.estimatedFare.toFixed(2)
                    : "00.00"}
                </div>
                <div className="col-sub-item">(incl. of all taxes)</div>
              </div>
            </div>

            <hr />

            <div className="row">
              <div className="col1">Included Hrs</div>
              <div className="col">
                {fareDetails && fareDetails?.minHrs ? fareDetails?.minHrs : "0"} Hrs
              </div>
            </div>

            <div className="row">
              <div className="col1">Included KM</div>
              <div className="col">
                {" "}
                {fareDetails && fareDetails?.minKm
                  ? fareDetails?.minKm
                  : "0"} KM
              </div>
            </div>

            <div className="row">
              <div className="col1">Base Fare</div>
              <div className="col">
                Rs.{" "}
                {fareDetails && fareDetails?.baseFare
                  ? fareDetails?.baseFare.toFixed(2)
                  : "00.00"}
              </div>
            </div>

            <div className="row">
              <div className="col1">GST @ 5%</div>
              <div className="col">
                Rs.{" "}
                {fareDetails && fareDetails?.gstAmount
                  ? fareDetails?.gstAmount.toFixed(2)
                  : "00.00"}
              </div>
            </div>

          </div>

          <div className="fare-bottom">
            <button className="grn-btn">Inclusions</button>
            <div className="green-box1">
              <div className="grn-con">Base Fare</div>
              <div className="grn-con">
              {" "}GST (
                {fareDetails && fareDetails ? fareDetails?.gstRate : "5"}% )
              </div>
            </div>


            <button className="grn-btn">Exclusions</button>
            <div className="green-box2">
              <div className="grn-con">
                Pay ₹   
                {fareDetails && fareDetails?.extraKmCharge
                  ? fareDetails?.extraKmCharge.toFixed(2)
                  : "11"}{" "}
                  /km after{" "}
                {fareDetails && fareDetails?.minKm
                  ? fareDetails?.minKm.toFixed(2)
                  : "80"}{" "}
                km
              </div>


              <div className="grn-con">
                Pay ₹{""}
                {fareDetails && fareDetails?.extraHrsCharge
                  ? fareDetails?.extraHrsCharge.toFixed(2)
                  : "00.00"}{" "}
                {/* /hrs after */}
                120/hrs after{" "}
                {fareDetails && fareDetails?.minHrs
                  ? fareDetails?.minHrs.toFixed(2)
                  : ""}{" "}
                {/* hrs */}
                8hrs
              </div>
              <div className="grn-con">Night allowance</div> 

              <div className="grn-con">Toll / State tax / Parking</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AirportDetails;
