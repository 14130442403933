import React, { useEffect, useState } from "react";
import { ImEye } from "react-icons/im";
import "./ViewCustomerDetails.css";

import { useParams, Link, useNavigate } from "react-router-dom";
import Filter from "../Booking/Filter";
import { useDispatch, useSelector } from "react-redux";
import { FaLongArrowAltRight } from "react-icons/fa";
import DatePicker from "react-datepicker";
import InputColumn from "../Booking/Input";
import {
  fetchCustomerDetails,
  resetCustomerDetails,
} from "./CustomerDetailsSclice";
import CustomerInvoice from "./CustomerInvoice";
import DropdownFilter from "./../dropdown/DropdownFilter";
import Loader from "../Loader";
import axios from "axios";
import { IoSearch } from "react-icons/io5";
import toast, { Toaster } from "react-hot-toast";
import { setNewValue } from "./CustomerSlice";
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const TableItem = ({
  item,
  onInvoiceClick,
  setShowAddNewCar,
  carid,
  showinvoice,
  setShowInvoice,
}) => {
  const navigate = useNavigate();

  const handleShowAddNewCar = () => {
    // Call the setShowAddNewCar function to indicate that AddNewCar should be displayed
    setShowAddNewCar(true);
  };

  const navigateToCustomerBookingDetails = () => {
    navigate(
      `/customer/view-All-Customer/details/${carid}/booking-details/${item["Booking ID"]}`
    );
  };

  return (
    <tr
      onClick={() => {
        showinvoice ? onInvoiceClick(item) : navigateToCustomerBookingDetails();
      }}
    >
      <td>{item["Sr No"]}</td>
      <td>{item["Booking ID"]}</td>
      <td>{item["Pickup Date"]}</td>
      <td>{item["Pickup City"]}</td>
      <td>{item["Destination"]}</td>
      <td>{item["No of Days"]}</td>
      <td>{item["Car Type "]}</td>
      <td>{item["Trip Type"]}</td>
      <td>{item["Sub Trip Type"]}</td>
      <td>{item["Status"]}</td>
      {/* <td>{item["Booking Type"]}</td> */}
      <td>{item["Created At"]}</td>
      <td>{item["Est  Fare"]}</td>
      <td>{item["Billed Amount"]}</td>
      <td>{item["Extra Km Charge"]}</td>
      <td>{item["Extra hrs Charge"]}</td>
      <td>{item["Night Charge"]}</td>
      <td>{item["Gst on Extra"]}</td>
      <td
        className="view-customer-buttons"
        onFocus={() => setShowInvoice(true)}
      >
        {item["Status"] === "Completed" ? (
          <button
            className="view-customer-table-button"

            //  onClick={() => onInvoiceClick(item)}
          >
            <ImEye className="icon icon-view" />
            View
          </button>
        ) : (
          <div className="invoice-not-generated">
            <span>Invoice not</span>
            <span> generated</span>
          </div>
        )}
      </td>
    </tr>
  );
};

const Table = ({
  headers,
  data,
  onInvoiceClick,
  carid,
  showinvoice,
  setShowInvoice,
}) => {
  return (
    <div
      style={{
        maxHeight: "550px",
        overflowY: "auto",
        overflowX: "auto",
        border: "1px solid #ddd",
      }}
    >
      <table className="view-customer-table" style={{ width: "100%" }}>
        <thead
          style={{
            position: "sticky",
            top: "-2px",
            backgroundColor: "#f8f9fa",
            zIndex: 10,
          }}
        >
          <tr>
            {headers.map((header, index) => (
              <th key={index}>{header}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data
            .filter((item) => item["Booking ID"]) // Only include rows with a valid Booking ID
            .map((item, index) => (
              <TableItem
                key={index}
                item={item}
                index={index}
                onInvoiceClick={onInvoiceClick}
                carid={carid}
                showinvoice={showinvoice}
                setShowInvoice={setShowInvoice}
              />
            ))}
        </tbody>
      </table>
    </div>
  );
};



const tripTypeOptions = ["Local", "Outstation", "Airport"];
const subTripTypeOptions = {
  Local: [
    "02hrs 20km",
    "04hrs 40km",
    "06hrs 60km",
    "08hrs 80km",
    "10hrs 100km",
    "12hrs 120km",
  ],
  Outstation: ["Oneway", "Round Trip"],
  Airport: ["To the Airport", "From the Airport"],
};
const CustomerDetails = (setShowAddNewCar) => {
  const id = useParams();
  const carid = id.id;
  const currentSection = "View-All-Customer";
  console.log("id", carid);
  const [filters, setFilters] = useState({
    bookingId: "",
    tripType: "",
    subTripType: "",
    carType: "",
    status: "",
  });

  const handleShowAddNewCar = () => {
    setShowAddNewCar(true);
  };
  const [previousLocation, setPreviousLocation] = useState();
  const [showInvoicePopup, setShowInvoicePopup] = useState(false);
  const [selectedInvoiceItem, setSelectedInvoiceItem] = useState(null);
  const [showinvoice, setShowInvoice] = useState(false);
  const [userDetails, setUserDetails] = useState();
  const singleCustomerDetail = useSelector(
    (state) => state.customerInfo?.customerDetails
  );

  const loading = useSelector((state) => state.loading);
  const errors = useSelector((state) => state.error);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleViewInvoice = (item) => {
    console.log("Checking Invoice Items", item);
    setSelectedInvoiceItem(item);
    setShowInvoice(false);
    setShowInvoicePopup(true);
  };

  useEffect(() => {
    dispatch(fetchCustomerDetails(carid));
    checkParentLocation(window.location.pathname);
    console.log("checking parent location", window.location.pathname);
  }, []);

  //console.log("checking singleCustomerDetail", singleCustomerDetail);

  useEffect(() => {
    if (singleCustomerDetail !== null) {
      setUserDetails({
        firstName: singleCustomerDetail.firstName,
        lastName: singleCustomerDetail.lastName,
        phone: singleCustomerDetail.phone,
        mail: singleCustomerDetail.email,
      });
    }
  }, [singleCustomerDetail]);

  // const singleCustomerdatavalue = singleCustomerDetail
  //   ? Object.values(singleCustomerDetail)
  //   : [];
  // console.log("checking singleCustomerdatavalue", singleCustomerdatavalue);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (errors) {
    return <div>Error: {errors}</div>;
  }

  const checkParentLocation = (path) => {
    if (path.includes("View-All-Customer")) {
      setPreviousLocation("View All Customer");
    } else {
      setPreviousLocation("View All Customer");
    }
  };

  const handleToggleComponent = (value) => {
    navigate("/customer");
  };

  const handleResetPassword = async () => {
    try {
      await axios.put(
        `${API_BASE_URL}/v1/admin/users/${carid}/password`
      );
      toast.success("Password reset successfully");
    } catch (error) {
      toast.error(error.response.data.message);
      console.error(error.response.data.message);
    }
  };

  // Cleanup function to reset body overflow when component unmounts or showInvoicePopup changes

  const headers = [
    "Sr No",
    "Booking ID",
    "Pickup Date",
    "Pickup City",
    "Destination",
    "No of Days",
    "Car Type ",
    "Trip Type",
    "Sub Trip Type",
    "Status",
    // "Booking Type",
    "Created At",
    "Est  Fare",
    "Billed Amount",
    "Extra Km Charge",
    "Extra hrs Charge",
    "Night Charge",
    "Gst on Extra",
    "Invoice",
  ];

  const data = [];

  const formatCurrency = (amount) => {
    // Check if the amount is valid; if not, return "0"
    if (!amount || isNaN(amount)) {
      amount = 0;
    }
    return new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
    }).format(amount);
  };

  let serialNumber = 1;

  if (
    singleCustomerDetail &&
    singleCustomerDetail.completedBookings &&
    singleCustomerDetail.cancelledBookings &&
    singleCustomerDetail.upcomingBookings
  ) {
    console.log(
      "checking lengths of completedBookings,upcomingBookings,cancelledBookings,currentBooking ",
      singleCustomerDetail.completedBookings.length,
      singleCustomerDetail.upcomingBookings.length,
      singleCustomerDetail.cancelledBookings.length,
      singleCustomerDetail.currentBooking
    );

    for (let i = 0; i < singleCustomerDetail?.completedBookings.length; i++) {
      const customer = singleCustomerDetail?.completedBookings[i];
      const createdAtDate = new Date(customer?.createdAt);
      const month = createdAtDate.getMonth() + 1;
      const day = createdAtDate.getDate();
      const year = createdAtDate.getFullYear();
      const hours = createdAtDate.getHours();
      const minutes = createdAtDate.getMinutes();
      const ampm = hours >= 12 ? "PM" : "AM";
      const formattedTime =
        (hours % 12) + ":" + (minutes < 10 ? "0" : "") + minutes + " " + ampm;

      const formattedCreatedAt = `${month}-${day}-${year}, ${formattedTime}`;

      const destination =
        customer?.tariff.tripType === "Local"
          ? customer?.location.city || "Not Available"
          : customer?.destinationCities[0] || "Not Available";

      const noOfDays =
        customer.tariff.tripType === "Local" ? "1" : customer.noOfDays;

      data.push({
        "Sr No": serialNumber++,
        "Booking ID": customer.bookingId,
        "Pickup Date": customer.pickupDate,
        "Pickup City": customer?.location.city || "Not Available",
        Destination: destination,
        "No of Days": noOfDays,
        "Car Type ": customer?.carCategory.category,
        "Trip Type": customer?.tariff.tripType,
        "Sub Trip Type": customer?.tariff.subTripType,
        Status: customer?.status,
        "Booking Type": "Completed",
        "Created At": formattedCreatedAt,
        "Est  Fare": formatCurrency(customer?.estimatedFare),
        "Billed Amount": formatCurrency(customer?.totalAmount),
        "Extra Km Charge": formatCurrency(
          customer?.tariff.fareDetails.extraKmCharge
        ),
        "Extra hrs Charge": formatCurrency(
          customer?.tariff.fareDetails.extraHrsCharge
        ),
        "Night Charge": formatCurrency(
          customer?.tariff.fareDetails.nightDriverAllowance
        ),
        "Gst on Extra": formatCurrency(customer?.tariff.fareDetails.gstRate),
      });
    }

    for (let i = 0; i < singleCustomerDetail?.upcomingBookings.length; i++) {
      const customer = singleCustomerDetail?.upcomingBookings[i];

      const createdAtDate = new Date(customer?.createdAt);
      const month = createdAtDate.getMonth() + 1;
      const day = createdAtDate.getDate();
      const year = createdAtDate.getFullYear();

      const hours = createdAtDate.getHours();
      const minutes = createdAtDate.getMinutes();
      const ampm = hours >= 12 ? "PM" : "AM";
      const formattedTime =
        (hours % 12) + ":" + (minutes < 10 ? "0" : "") + minutes + " " + ampm;

      const formattedCreatedAt = `${month}-${day}-${year}, ${formattedTime}`;
      const destination =
        customer?.tariff.tripType === "Local"
          ? customer?.location.city || "Not Available"
          : customer?.destinationCities[0] || "Not Available";

      const noOfDays =
        customer.tariff.tripType === "Local" ? "1" : customer.noOfDays;

      data.push({
        "Sr No": serialNumber++,
        "Booking ID": customer?.bookingId,
        "Pickup Date": customer?.pickupDate,
        "Pickup City": customer?.location.city || "Not Available",
        Destination: destination,
        "No of Days": noOfDays,
        "Car Type ": customer?.carCategory.category,
        "Trip Type": customer?.tariff.tripType,
        "Sub Trip Type": customer?.tariff.subTripType,
        Status: customer?.status,
        "Booking Type": "Upcoming",
        "Created At": formattedCreatedAt,
        "Est  Fare": formatCurrency(customer?.estimatedFare),
        "Billed Amount": formatCurrency(customer?.totalAmount),
        "Extra Km Charge": formatCurrency(
          customer?.tariff.fareDetails.extraKmCharge
        ),
        "Extra hrs Charge": formatCurrency(
          customer?.tariff.fareDetails.extraHrsCharge
        ),
        "Night Charge": formatCurrency(
          customer?.tariff.fareDetails.nightDriverAllowance
        ),
        "Gst on Extra": formatCurrency(customer?.tariff.fareDetails.gstRate),
      });
    }
    for (let i = 0; i < singleCustomerDetail?.cancelledBookings.length; i++) {
      const customer = singleCustomerDetail?.cancelledBookings[i];

      const createdAtDate = new Date(customer?.createdAt);
      const month = createdAtDate.getMonth() + 1;
      const day = createdAtDate.getDate();
      const year = createdAtDate.getFullYear();

      const hours = createdAtDate.getHours();
      const minutes = createdAtDate.getMinutes();
      const ampm = hours >= 12 ? "PM" : "AM";
      const formattedTime =
        (hours % 12) + ":" + (minutes < 10 ? "0" : "") + minutes + " " + ampm;

      const formattedCreatedAt = `${month}-${day}-${year}, ${formattedTime}`;

      const destination =
        customer?.tariff.tripType === "Local"
          ? customer?.location.city || "Not Available"
          : customer?.destinationCities[0] || "Not Available";

      const noOfDays =
        customer.tariff.tripType === "Local" ? "1" : customer.noOfDays;

      data.push({
        "Sr No": serialNumber++,
        "Booking ID": customer?.bookingId,
        "Pickup Date": customer?.pickupDate,
        "Pickup City": customer?.location.city,
        Destination: destination,
        "No of Days": noOfDays,
        "Car Type ": customer?.carCategory.category,
        "Trip Type": customer?.tariff.tripType,
        "Sub Trip Type": customer?.tariff.subTripType,
        Status: customer?.status,
        "Booking Type": "Cancelled",
        "Created At": formattedCreatedAt,
        "Est  Fare": formatCurrency(customer?.estimatedFare),
        "Billed Amount": formatCurrency(customer?.totalAmount),
        "Extra Km Charge": formatCurrency(
          customer?.tariff.fareDetails.extraKmCharge
        ),
        "Extra hrs Charge": formatCurrency(
          customer?.tariff.fareDetails.extraHrsCharge
        ),
        "Night Charge": formatCurrency(
          customer?.tariff.fareDetails.nightDriverAllowance
        ),
        "Gst on Extra": formatCurrency(customer.tariff.fareDetails.gstRate),
      });
    }
    const customer = singleCustomerDetail?.currentBooking;
    const createdAtDate = new Date(customer?.createdAt);
    const month = createdAtDate.getMonth() + 1;
    const day = createdAtDate.getDate();
    const year = createdAtDate.getFullYear();

    const hours = createdAtDate.getHours();
    const minutes = createdAtDate.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    const formattedTime =
      (hours % 12) + ":" + (minutes < 10 ? "0" : "") + minutes + " " + ampm;

    const formattedCreatedAt = `${month}-${day}-${year}, ${formattedTime}`;

    data.push({
      "Sr No": serialNumber++,
      "Booking ID": customer?.bookingId,
      "Pickup Date": customer?.pickupDate,
      "Pickup City": customer?.location.city,
      Destination: customer?.destinationCities[0],
      "No of Days": customer?.noOfDays,
      "Car Type ": customer?.carCategory.category,
      "Trip Type": customer?.tariff.tripType,
      "Sub Trip Type": customer?.tariff.subTripType,
      Status: customer?.status,
      "Booking Type": "Current Booking",
      "Created At": formattedCreatedAt,
      "Est  Fare": formatCurrency(customer?.estimatedFare),
      "Billed Amount": formatCurrency(customer?.totalAmount),
      "Extra Km Charge": formatCurrency(
        customer?.tariff.fareDetails.extraKmCharge
      ),
      "Extra hrs Charge": formatCurrency(
        customer?.tariff.fareDetails.extraHrsCharge
      ),
      "Night Charge": formatCurrency(
        customer?.tariff.fareDetails.nightDriverAllowance
      ),
      "Gst on Extra": formatCurrency(customer?.tariff.fareDetails.gstRate),
    });
  } else {
    return (
      <div
        className="loading-circle"
        style={{ display: "flex", alignItems: "center" }}
      >
        <Loader />
      </div>
    );
  }
  const handleImEyeClick = (id) => {
    console.log("Edit button clicked for ID:", id); // Debugging line
    const editPath = `/customer/${currentSection}/edit-details/${id}`;
    dispatch(setNewValue(editPath)); // Update Redux state
    navigate(editPath); // Navigate to the edit page
  };

  const CustomerData = data;
  const filteredData = data.filter((item) => {
    
    return (
      (filters.bookingId === "" ||
        item["Booking ID"]
          ?.toString()
          .toLowerCase()
          .includes(filters.bookingId.toLowerCase())) &&
      (filters.tripType === "" || item["Trip Type"] === filters.tripType) &&
      (filters.subTripType === "" ||
        item["Sub Trip Type"] === filters.subTripType) &&
      (filters.carType === "" || item["Car Type "] === filters.carType) &&
      (filters.status === "" || item["Status"] === filters.status)
    );
  });

 const minRows = 21;

 const paddedData = Array.from({ length: Math.max(filteredData.length, minRows) }, (_, i) => {
   return filteredData[i] || {
     "Sr No": i , 
     "Booking ID": "-",
     "Pickup Date": "-",
     "Pickup City": "-",
     Destination: "-",
     "No of Days": "-",
     "Car Type ": "-",
     "Trip Type": "-",
     "Sub Trip Type": "-",
     Status: "-",
     "Created At": "-",
     "Est  Fare": "-",
     "Billed Amount": "-",
     "Extra Km Charge": "-",
     "Extra hrs Charge": "-",
     "Night Charge": "-",
     "Gst on Extra": "-",
   };
 });

  const handleFilterChange = (key, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [key]: value === "All" ? "" : value,
    }));

    // Clear the Sub Trip Type filter if Trip Type is changed
    if (key === "tripType") {
      setFilters((prevFilters) => ({
        ...prevFilters,
        subTripType: "",
      }));
    }
  };
  const getFilteredSubTripOptions = () => {
    return subTripTypeOptions[filters.tripType] || [];
  };
  return (
    <>
      <Toaster />
      <div
        className="driver-info-container"
        style={{
          width: "100%",
          overflowX: "auto",
        }}
      >
        <div className="nav-content" style={{ marginBottom: "10px" }}>
          <div className="header-text">
            <Link className="h3-booking">Customer</Link>
            <FaLongArrowAltRight />
            {""}
            <Link className="h3-booking">{previousLocation}</Link>
            <FaLongArrowAltRight />
            <Link className="h2-booking">Customer Details</Link>
          </div>

          {previousLocation === "View All Customer" && (
            <button
              className="createButton"
              onClick={() => handleToggleComponent("View All Customer")}
            >
              View All Customer
            </button>
          )}
        </div>

        <div className="customer-detailss">
          <div className="customer-profile">
            <div className="crete-new-sub-head">
              Customer Profile Information
            </div>
            {singleCustomerDetail ? (
              <div className="row">
                <div className="col">
                  <div className="col-item">
                    <p className="col-item-title"> First Name</p>
                    <p className="col-item-input">
                      {singleCustomerDetail?.firstName}
                    </p>
                  </div>
                  <div className="col-item">
                    <p className="col-item-title"> Primary Mobile No.</p>
                    <p className="col-item-input">
                      {singleCustomerDetail?.phone}
                    </p>
                  </div>
                </div>

                <div className="col">
                  <div className="col-item">
                    <p className="col-item-title"> Last Name</p>
                    <p className="col-item-input">
                      {singleCustomerDetail?.lastName}
                    </p>
                  </div>
                  <div className="col-item">
                    <p className="col-item-title">Alternate Mobile No.</p>
                    <p className="col-item-input">
                      {singleCustomerDetail?.alternatePhone || "Not Available"}
                    </p>
                  </div>
                </div>
                <div className="col">
                  <div className="col-item">
                    <p className="col-item-title">Email Address</p>
                    <p className="col-item-input">
                      {singleCustomerDetail.email}
                    </p>
                  </div>

                  <div className="col-item">
                    <button
                      className="createButton"
                      onClick={handleResetPassword}
                    >
                      Reset Password
                    </button>
                    <button
                      className="createButton"
                      onClick={() => handleImEyeClick(carid)}
                    >
                      Edit Profile
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <div className="loading-circle">
                <Loader />
              </div>
            )}
          </div>

          <div className="crete-new-sub-head">Booking History</div>
          {CustomerData.length > 0 ? (
            <div>
              <div
                className="dropdown-container"
                style={{
                  marginBottom: "12px",
                  marginTop: "12px",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                {/* Search by Booking ID */}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  <div
                    style={{
                      color: "#000",
                      fontFamily: "Inter, sans-serif",
                      fontSize: "11px",
                      fontStyle: "normal",
                      fontWeight: "500",
                      lineHeight: "normal",
                      marginBottom: "4px",
                      marginLeft: "10px",
                    }}
                  >
                    Booking ID
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between", // Adjusted to separate items
                      width: "177px",
                      height: "28px",
                      flexShrink: "0",
                      backgroundColor: "#FFF",
                      borderRadius: "6px",
                      boxShadow: "0px 4px 22px 0px rgba(0, 0, 0, 0.17)",
                      marginLeft: "10px",
                      padding: "0 5px", // Added padding for spacing
                      marginTop: "8px",
                    }}
                  >
                    <input
                      style={{
                        border: "none",
                        outline: "none",
                        flex: "1",
                        fontSize: "10px",
                        fontFamily: "Inter, sans-serif",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "normal",
                        backgroundColor: "transparent",
                        color: "#000", // Darker color for normal text
                      }}
                      placeholder="Search by Booking ID"
                      title="Search by Booking ID"
                      onChange={(e) =>
                        handleFilterChange("bookingId", e.target.value)
                      }
                    />
                    <IoSearch
                      style={{
                        fontSize: "20px",
                        color: "#ABABAB",
                      }}
                    />
                    <style>
                      {`
                      input::placeholder {
                        color: #ABABAB;
                        font-family: 'Inter', sans-serif;
                        font-size: 10px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                      }
                    `}
                    </style>
                  </div>
                </div>

                {/* Trip Type */}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  <div
                    style={{
                      color: "#000",
                      fontFamily: "Inter",
                      fontSize: "11px",
                      fontStyle: "normal",
                      fontWeight: 500,
                      lineHeight: "normal",
                      marginBottom:"10px"
                    }}
                  >
                    Trip Type
                  </div>
                  <div
                    style={{
                      position: "relative",
                      width: "180px",
                      background: "#FFFFFF",
                      borderRadius: "6px",
                      boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                    }}
                  >
                    <select
                      style={{
                        width: "100%",
                        padding: "8px 10px",
                        border: "1px solid #E0E0E0",
                        borderRadius: "6px",
                        color: "#7D7D7D",
                        fontSize: "10px",
                        fontFamily: "Inter",
                        fontWeight: "400",
                        appearance: "none",
                        backgroundColor: "transparent",
                        cursor: "pointer",
                        outline: "none",
                      }}
                      onChange={(e) =>
                        handleFilterChange("tripType", e.target.value)
                      }
                    >
                      <option value="">Select Trip Type</option>
                      {tripTypeOptions.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>
                    <div
                      style={{
                        position: "absolute",
                        top: "50%",
                        right: "10px",
                        transform: "translateY(-50%)",
                        pointerEvents: "none",
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="10"
                        height="6"
                        viewBox="0 0 10 6"
                        fill="none"
                      >
                        <path
                          d="M1 1L5 5L9 1"
                          stroke="#FA7C07"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                  </div>
                </div>

                {/* Sub Trip Type */}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  <div
                    style={{
                      color: "#000",
                      fontFamily: "Inter",
                      fontSize: "11px",
                      fontStyle: "normal",
                      fontWeight: 500,
                      lineHeight: "normal",
                       marginBottom:"10px"
                    }}
                  >
                    Sub Trip Type
                  </div>
                  <div
                    style={{
                      position: "relative",
                      width: "180px",
                      background: "#FFFFFF",
                      borderRadius: "6px",
                      boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                    }}
                  >
                    <select
                      style={{
                        width: "100%",
                        padding: "8px 10px",
                        border: "1px solid #E0E0E0",
                        borderRadius: "6px",
                        color: "#7D7D7D",
                        fontSize: "10px",
                        fontFamily: "Inter",
                        fontWeight: "400",
                        appearance: "none",
                        backgroundColor: "transparent",
                        cursor: "pointer",
                        outline: "none",
                      }}
                      onChange={(e) =>
                        handleFilterChange("subTripType", e.target.value)
                      }
                    >
                      <option value="">Select Sub Trip Type</option>
                      {getFilteredSubTripOptions().map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>
                    <div
                      style={{
                        position: "absolute",
                        top: "50%",
                        right: "10px",
                        transform: "translateY(-50%)",
                        pointerEvents: "none",
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="10"
                        height="6"
                        viewBox="0 0 10 6"
                        fill="none"
                      >
                        <path
                          d="M1 1L5 5L9 1"
                          stroke="#FA7C07"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                  </div>
                </div>

                {/* Car Type */}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  <div
                    style={{
                      color: "#000",
                      fontFamily: "Inter",
                      fontSize: "11px",
                      fontStyle: "normal",
                      fontWeight: 500,
                      lineHeight: "normal",
                       marginBottom:"10px"
                    }}
                  >
                    Car Type
                  </div>
                  <div
                    style={{
                      position: "relative",
                      width: "180px",
                      background: "#FFFFFF",
                      borderRadius: "6px",
                      boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                    }}
                  >
                    <select
                      style={{
                        width: "100%",
                        padding: "8px 10px",
                        border: "1px solid #E0E0E0",
                        borderRadius: "6px",
                        color: "#7D7D7D",
                        fontSize: "10px",
                        fontFamily: "Inter",
                        fontWeight: "400",
                        appearance: "none",
                        backgroundColor: "transparent",
                        cursor: "pointer",
                        outline: "none",
                      }}
                      onChange={(e) =>
                        handleFilterChange("carType", e.target.value)
                      }
                    >
                      <option value="">Select Car Type</option>
                      {[
                        ...new Set(
                          data.map((item) => item["Car Type "]).filter(Boolean)
                        ),
                      ].map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>
                    <div
                      style={{
                        position: "absolute",
                        top: "50%",
                        right: "10px",
                        transform: "translateY(-50%)",
                        pointerEvents: "none",
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="10"
                        height="6"
                        viewBox="0 0 10 6"
                        fill="none"
                      >
                        <path
                          d="M1 1L5 5L9 1"
                          stroke="#FA7C07"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                  </div>
                </div>

                {/* Status */}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  <div
                    style={{
                      color: "#000",
                      fontFamily: "Inter",
                      fontSize: "11px",
                      fontStyle: "normal",
                      fontWeight: 500,
                      lineHeight: "normal",
                      marginBottom:"10px"
                    }}
                  >
                    Status
                  </div>
                  <div
                    style={{
                      position: "relative",
                      width: "180px",
                      background: "#FFFFFF",
                      borderRadius: "6px",
                      boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                    }}
                  >
                    <select
                      style={{
                        width: "100%",
                        padding: "8px 10px",
                        border: "1px solid #E0E0E0",
                        borderRadius: "6px",
                        color: "#7D7D7D",
                        fontSize: "10px",
                        fontFamily: "Inter",
                        fontWeight: "400",
                        appearance: "none",
                        backgroundColor: "transparent",
                        cursor: "pointer",
                        outline: "none",
                      }}
                      onChange={(e) =>
                        handleFilterChange("status", e.target.value)
                      }
                    >
                      <option value="">Select Status</option>
                      {[
                        ...new Set(
                          data.map((item) => item["Status"]).filter(Boolean)
                        ),
                      ].map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>
                    <div
                      style={{
                        position: "absolute",
                        top: "50%",
                        right: "10px",
                        transform: "translateY(-50%)",
                        pointerEvents: "none",
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="10"
                        height="6"
                        viewBox="0 0 10 6"
                        fill="none"
                      >
                        <path
                          d="M1 1L5 5L9 1"
                          stroke="#FA7C07"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
              </div>

              {CustomerData.length > 0 ? (
                <div className="table">
                  <Table
                    headers={headers}
                    data={paddedData}
                    onInvoiceClick={handleViewInvoice}
                    carid={carid}
                    showinvoice={showinvoice}
                    setShowInvoice={setShowInvoice}
                  />
                </div>
              ) : (
                <div
                  className="loading-circle"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <Loader />
                </div>
              )}

              <div className="invoice">
                {showInvoicePopup && (
                  <CustomerInvoice
                    setShowInvoicePopup={setShowInvoicePopup}
                    selectedInvoiceItem={selectedInvoiceItem}
                    userDetails={userDetails}
                  />
                )}
              </div>
            </div>
          ) : (
            <div className="heading-error">No Booking History Available</div>
          )}
        </div>
      </div>
    </>
  );
};

export default CustomerDetails;
