
import React from 'react'
import './FareDetails.css'
const FareDetails = ({ fareDetails }) => {
    if (!fareDetails) {
        return <>
            <div className="fd-details-container">
                <div className="head-title"><p>Fare  Details</p></div>

                <div className="subheader-strong-container">
                    <p>Ride Estimate</p>
                    <div>
                        <b>Rs. 00.00</b>
                        <p>(incl. of all taxes)</p>
                    </div>
                </div>


                <div className="row-flexer">
                    <p>Included Km </p>
                    <p>0 Km</p>
                </div>

                <div className="row-flexer">
                    <p>Base Fare </p>
                    <p>Rs. 00.00</p>
                </div>

                <div className="row-flexer">
                    <p>Night charges </p>
                    <p>Rs. 00.00</p>
                </div>

                <div className="row-flexer">
                    <p>GST @ 5%</p>
                    <p>Rs. 00.00</p>
                </div>


            </div>




        </>
    }

    return (
        <div className="fd-details-container">
            <div className="head-title"><p>Fare Details</p></div>
            <div className="subheader-strong-container">
                <p>Ride Estimate</p>
                <div>
                    <b>Rs. {fareDetails?.fareDetails?.estimatedFare.toFixed(2)}</b>
                    <p>(incl. of all taxes)</p>
                </div>
            </div>

            <div className="row-flexer">
                <p>Included Km @ Min {fareDetails?.fareDetails?.minKmPerDay}km/day</p>
                <p>{fareDetails?.rideDistance} Km</p>
            </div>

            <div className="row-flexer">
                <p>Base Fare @ Rs.{fareDetails?.fareDetails?.perKmCharge}/km</p>
                <p>Rs. {fareDetails?.fareDetails?.baseFare.toFixed(2)}</p>
            </div>

            <div className="row-flexer">
                <p>Night charges @ Rs.{fareDetails?.fareDetails?.nightDriverAllowance}</p>
                <p>Rs. {fareDetails?.nightAllowance ? (fareDetails?.nightAllowance.toFixed(2)):(0)}</p>
            </div>

            <div className="row-flexer">
                <p>GST @ {fareDetails?.fareDetails?.gstRate}%</p>
                <p>Rs. {fareDetails?.fareDetails?.gstAmount.toFixed(2)}</p>
            </div>

            {/* Additional fare details... */}

            <div className="info-container">
                <div className="infohead">Inclusions</div>
                <div className="info-wrapper">
                    <p>{fareDetails?.rideDistance}km @Rs.{fareDetails?.fareDetails?.perKmCharge}/km </p>
                    <p>Night Charge  Rs.{fareDetails?.fareDetails?.nightDriverAllowance}/day X  {fareDetails?.noOfDays} Day</p>
                    <p>GST (5%)</p>
                </div>
            </div>

            <div className="info-container">
                <div className="infohead">Exclusion</div>
                <div className="info-wrapper">
                    <p>Pay ₹{fareDetails?.fareDetails?.extraKmCharge}/km after {fareDetails?.rideDistance} km </p>
                    <p>Night Charge Rs.{fareDetails?.fareDetails?.nightDriverAllowance}/day X {fareDetails?.noOfDays} Day</p>
                    <p>Toll / State tax / Parking</p>
                </div>
            </div>

        </div>
    );
};

export default FareDetails;
