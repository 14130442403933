import React, { useEffect, useState } from "react";
import axios from "axios";
import Loader from "../Loader";
import "./AssignDriverData.css";
import { useParams, useNavigate } from "react-router-dom";
import { IoIosArrowDown } from "react-icons/io";
import { FaFilter } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import DropdownFilter from "../dropdown/DropdownFilter";
import {
  fetchDriverData,
  assignDriver,
  updateDriverStatus,
  savebookinglocation,
} from "./assignDriverSlice";
import { current } from "@reduxjs/toolkit";

function AssignDriverData({ handleDriverState }) {
  const navigate = useNavigate();
  const [apiData, setApiData] = useState([]);
  const [searchName, setSearchName] = useState("");
  const [searchContact, setSearchContact] = useState("");
  const [filterOptionsState, setFilterOptionsState] = useState([]);
  const [selectedState, setSelectedState] = useState("");
  const [cities, setCities] = useState([]);
  const [filterValues, setFilterValues] = useState({});
  const [cabNumber, setCabNumber] = useState("");
  const [componentKey, setComponentKey] = useState(0);
  const driversDetail = [];
  const filterLocations = [];
  const [labels, setLabels] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [suggestions, setSuggestions] = useState([]); // For dynamic suggestions
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

  const [initialDriverData, setInitialDriverData] = useState([]);
  const dispatch = useDispatch();
  const { allDrivers } = useSelector((state) => {
    console.log("Redux State:", state);
    return state.assignDrivers;
  });
  const { id } = useParams();

  const fetchDriverInitial = async ({ id, savebookinglocation }) => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}/v1/admin/bookings/drivers/location?bookingId=${id}&location=${savebookinglocation}`
      );
      console.log("api Filter driver response", response.data);
      return response.data;
    } catch (error) {
      console.error("Error fetching driver initial data:", error);
      throw error;
    }
  };

  // useEffect(()=>{
  //   fetchDriverInitial(id,savebookinglocation);
  // },[]);

  const headerColumns = [
    "Driver Name",
    "Contact No.",
    "Location",
    "Sourcing Type",
    "Language Spoken",
    "Availability Status",
    "Driver License No.",
    "",
  ];

  let filterOptions = [
    {
      label: "Pick Up State",
      values: null,
    },
    {
      label: "Pick Up City",
      values: null,
    },
    {
      label: "Language Spoken",
      values: ["Hindi", "English", "Hindi, English"],
    },
    {
      label: "Sourcing Type",
      values: ["Outsourced", "Company Owned"],
    },
  ];

  let filterAvailability = {
    label: "Availability",
    values: ["Available", "Not Available"],
  };

  const handleSelectedData = (driverId) => {
    navigate(`/booking/${id}/assign/driver`);
    const currentAssignDriver = initialDriverData.find(
      (driver) => driver.id === driverId
    );
    handleDriverState(id, driverId);
    dispatch(assignDriver(currentAssignDriver));
    dispatch(updateDriverStatus({ driverId, status: false }));
  };

  const TableHeaders = ({ headers }) => {
    return (
      <thead>
        <tr className="table-header-row">
          {headers.map((header, index) => (
            <th key={index}>
              <div className="table-header"> {header}</div>
            </th>
          ))}
        </tr>
      </thead>
    );
  };
  console.log("all drivers in assign driver", allDrivers);

  const TableBody = ({ entries, headers }) => {
    return (
      <tbody>
        {entries.map((driver, index) => (
          <tr className="table-data-row" key={index}>
            {headers.map((header) => (
              <td className="table-data" key={header}>
                {header !== "" ? (
                  driver[header]
                ) : (
                  <button
                    className={`select-button ${
                      driver["Availability Status"] !== "Available"
                        ? "disabled-select-button"
                        : ""
                    }`}
                    onClick={() => handleSelectedData(driver["id"])}
                    disabled={driver["Availability Status"] !== "Available"}
                  >
                    Select
                  </button>
                )}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    );
  };
  useEffect(() => {
    if (allDrivers?.data !== null) {
      const drivers = allDrivers?.data?.data.map((driver) => ({
        id: driver?._id,
        "Driver Name": driver?.firstName + " " + driver?.lastName,
        "Contact No.": driver?.phone,
        "Language Spoken":
          driver?.languages.length !== 1
            ? driver?.languages[0] + ", " + driver?.languages[1]
            : driver?.languages[0],
        "Sourcing Type": driver?.sourcing,
        "Availability Status": driver.available ? "Available" : "Not Available",
        "Driver License No.": driver?.license?.number,
        Location: driver?.location
          ? driver?.location?.city + ", " + driver?.location?.state
          : driver?.location,
      }));

      setApiData(drivers);
      setInitialDriverData(drivers);
    }
  }, [allDrivers]);

  useEffect(() => {
    fetchLocations();
  }, []);

  useEffect(() => {
    applyFilters();
  }, [searchName, searchContact, filterValues]);

  const applyFilters = () => {
    const filteredData = initialDriverData.filter((driver) => {
      const searchTerm = searchQuery.toLowerCase();

      const matchesName = driver["Driver Name"]
        .toLowerCase()
        .includes(searchTerm);

      const contactNumber = driver["Contact No."];
      const normalizedContact = contactNumber.startsWith("+91-")
        ? contactNumber.slice(4)
        : contactNumber;

      const matchesContact = normalizedContact.includes(searchTerm);

      const matchesSearchQuery = matchesName || matchesContact;

      // Check all filter values
      const matchesFilterValues = Object.keys(filterValues).every((key) => {
        if (key === "Pick Up City") {
          const [driverCity] =
            driver["Location"]?.split(",").map((part) => part.trim()) || [];
          return driverCity?.toLowerCase() === filterValues[key].toLowerCase();
        } else if (key === "Pick Up State") {
          const [, driverState] =
            driver["Location"]?.split(",").map((part) => part.trim()) || [];
          return driverState?.toLowerCase() === filterValues[key].toLowerCase();
        } else if (key === "Availability") {
          // Match availability status, case-insensitive
          return (
            driver["Availability Status"]?.toLowerCase() ===
            filterValues[key].toLowerCase()
          );
        } else if (key === "Language Spoken") {
          // Match language spoken
          return driver["Language Spoken"]?.includes(filterValues[key]);
        } else {
          // Generic case for other filters
          return driver[key] === filterValues[key];
        }
      });

      return matchesSearchQuery && matchesFilterValues;
    });

    setApiData(filteredData);
  };

  console.log("initial driver data", initialDriverData);
  console.log("filter values", filterValues);

  const fetchLocations = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/v1/locations`);
      const locations = response.data.data.map((value) => {
        const [city, state] = value.location
          .split(",")
          .map((item) => item.trim());
        return { city, state };
      });

      const stateNames = [
        ...new Set(locations.map((location) => location.state)),
      ];

      const updatedFilterOptions = filterOptions.map((temp) => {
        if (temp.label === "Pick Up State") {
          temp.values = ["All Drivers", ...stateNames]; // Add "All Drivers" option
        } else if (temp.label === "Pick Up City") {
          temp.values = [];
        }
        return temp;
      });

      setFilterOptionsState(updatedFilterOptions);
    } catch (error) {
      console.error("Failed to fetch locations.");
    }
  };

  useEffect(() => {
    if (filterOptionsState) {
      const newData = initialDriverData.filter((driver) => {
        const result = labels.every((key) => {
          if (driver[key] !== null && filterValues[key] !== undefined) {
            if (key === "Pick Up State" || key === "Pick Up City") {
              const state = filterValues["Pick Up State"];
              const city = filterValues["Pick Up City"];

              // Driver's location is stored in 'Location' field in the format: 'City, State'
              const driverLocation = driver["Location"];
              const [driverCity, driverState] = driverLocation
                ? driverLocation.split(",").map((part) => part.trim()) // Split into city and state and remove extra spaces
                : [];

              // Compare the city and state individually
              if (key === "Pick Up State") {
                return (
                  driverState &&
                  driverState.toLowerCase() === state.toLowerCase()
                );
              } else if (key === "Pick Up City") {
                return (
                  driverCity && driverCity.toLowerCase() === city.toLowerCase()
                );
              }
            } else if (key === "Availability") {
              return (
                driver["Availability Status"]?.toLowerCase() ===
                filterValues[key].toLowerCase()
              );
            } else {
              // Regular filtering for other fields (like 'Cab Type')
              return driver[key]?.includes(filterValues[key]);
            }
          }
          return true;
        });
        return result;
      });
      setApiData([...newData]);
    }
  }, [labels, filterValues, initialDriverData]);

  const ResetFilters = () => {
    // Perform the necessary cleanup actions before reload (if needed)
    setFilterValues({});
    setSearchName("");
    setSearchContact("");

    // Full page reload
    window.location.reload();
  };

  const fetchDriversForAllCities = async (state, cities) => {
    let allDrivers = [];
    try {
      for (const city of cities) {
        const location = `${city}, ${state}`;
        const response = await axios.get(
          `${API_BASE_URL}/v1/admin/bookings/drivers/location?bookingId=${id}&location=${location}`
        );

        if (response.data.data.length > 0) {
          const drivers = response.data.data.map((driver) => ({
            id: driver._id,
            "Driver Name": driver?.firstName + " " + driver?.lastName,
            "Contact No.": driver?.phone,
            "Language Spoken":
              driver.languages.length !== 1
                ? driver.languages[0] + ", " + driver.languages[1]
                : driver.languages[0],
            "Sourcing Type": driver.sourcing,
            "Availability Status": driver.available
              ? "Available"
              : "Not Available",
            "Driver License No.": driver?.license?.number,
            Location: driver?.location
              ? driver?.location?.city + ", " + driver?.location?.state
              : driver?.location,
          }));
          allDrivers = [...allDrivers, ...drivers]; // Aggregate drivers
        }
      }
    } catch (error) {
      console.error("Error fetching drivers for state:", error);
    }
    return allDrivers;
  };

  const globalSelectedOption = async (value, label) => {
    let temp = { ...filterValues, [label]: value };

    if (value === "") delete temp[label];
    setFilterValues(temp);
    // Ensure labels are updated only for specific filter types
    if (label !== "Pick Up State" || value !== "All Drivers") {
      if (!labels.includes(label)) {
        setLabels((prevLabels) => [...prevLabels, label]); // Track active filters
      }
    }

    if (label === "Pick Up State" && value === "All Drivers") {
      try {
        let allDrivers = [];
        const responseStates = await axios.get(
          `${API_BASE_URL}/v1/locations`
        );

        const allStates = [
          ...new Set(
            responseStates.data.data.map((location) =>
              location.location.split(",")[1]?.trim()
            )
          ),
        ];

        const fetchDriversPromises = allStates.map(async (state) => {
          const cities = await fetchCitiesByState(state);
          const driversPromises = cities.map((city) =>
            fetchDriversByLocation(`${city}, ${state}`)
          );

          const driversResults = await Promise.all(driversPromises);
          return driversResults.flat();
        });

        const allDriversResults = await Promise.all(fetchDriversPromises);
        allDrivers = allDriversResults.flat();

        setApiData(allDrivers);
        setInitialDriverData(allDrivers);
        console.log("All Drivers Fetched:", allDrivers);
      } catch (error) {
        console.error("Error fetching all drivers:", error.message);
        alert("Failed to fetch all drivers. Please try again.");
      }
    } else if (label === "Pick Up State") {
      // State-specific logic
      const stateCities = await fetchCitiesByState(value);
      const updatedFilterOptions = filterOptionsState.map((filterOption) => {
        if (filterOption.label === "Pick Up City") {
          filterOption.values = stateCities;
        }
        return filterOption;
      });
      setFilterOptionsState(updatedFilterOptions);

      const aggregatedDrivers = await fetchDriversForAllCities(
        value,
        stateCities
      );
      setApiData(aggregatedDrivers);
      setInitialDriverData(aggregatedDrivers);
    } else if (label === "Pick Up City") {
      // City-specific logic
      const state = filterValues["Pick Up State"];
      if (state) {
        const location = `${value}, ${state}`;
        const drivers = await fetchDriversByLocation(location);
        setApiData(drivers);
        setInitialDriverData(drivers);
      }
    } else {
      // Apply filters for other options
      applyFilters();
    }
  };

  const fetchCitiesByState = async (state) => {
    try {
      const response = await axios.post(
        `${API_BASE_URL}/v1/admin/bookings/get-cities-state`,
        { state }
      );
      const cities = response.data.data.map((cityData) => cityData.city);
      return cities;
    } catch (error) {
      console.error("Failed to fetch cities for the selected state.", error);
      return [];
    }
  };

  const handleSearchQuery = (query) => {
    setSearchQuery(query);

    if (query === "") {
      setSuggestions([]); // Clear suggestions
      setApiData(initialDriverData); // Reset table data
      return;
    }

    // Filter suggestions based on Driver Name or Contact Number
    const filteredSuggestions = initialDriverData.filter((driver) => {
      const nameMatch = driver["Driver Name"]
        .toLowerCase()
        .includes(query.toLowerCase());

      const contactNumber = driver["Contact No."];
      const normalizedContact = contactNumber.startsWith("+91-")
        ? contactNumber.slice(4) // Ignore "+91-"
        : contactNumber;

      const contactMatch = normalizedContact
        .toLowerCase()
        .includes(query.toLowerCase());

      return nameMatch || contactMatch;
    });

    setSuggestions(filteredSuggestions); // Update suggestions
    setApiData(filteredSuggestions); // Update the table dynamically
  };

  const fetchDriversByLocation = async (location) => {
    try {
      console.log(`Fetching drivers for location: ${location}`);
      const response = await axios.get(
        `${API_BASE_URL}/v1/admin/bookings/drivers/location?bookingId=${id}&location=${encodeURIComponent(
          location
        )}`
      );
      if (response.data.data?.length > 0) {
        return response.data.data.map((driver) => ({
          id: driver._id,
          "Driver Name": `${driver.firstName} ${driver.lastName}`,
          "Contact No.": driver.phone,
          "Language Spoken": driver.languages.join(", "),
          "Sourcing Type": driver.sourcing,
          "Availability Status": driver.available
            ? "Available"
            : "Not Available",
          "Driver License No.": driver.license?.number,
          Location: `${driver.location?.city}, ${driver.location?.state}`,
        }));
      }
      return []; // Return empty array if no drivers are found
    } catch (error) {
      console.warn(`No drivers found for location: ${location}`);
      return []; // Return empty array on error
    }
  };

  return (
    <div className="data-container">
      <div className="filter-section">
        <p className="filter-tag" style={{ width: "12%" }}>
          Filter
          <FaFilter />
        </p>
        <div
          style={{
            display: "flex",
            width: "600px",
            justifyContent: "space-between",
            marginBottom: "10px",
            height: "28px",
          }}
        >
          {/* Pick Up State */}
          <div
            style={{
              position: "relative",
              display: "inline-block",
              width: "auto",
              height: "28px",
              background: "#e6f5e0",
              borderRadius: "4px",
              color: "#000",
              fontFamily: "Inter",
              fontSize: "9px",
              fontWeight: "400",
              border: "none",
              cursor: "pointer",
              marginTop: "5px",
            }}
          >
            <select
              value={filterValues["Pick Up State"] || ""}
              onChange={(e) =>
                globalSelectedOption(e.target.value, "Pick Up State")
              }
              style={{
                width: "100%",
                height: "100%",
                background: "transparent",
                border: "none",
                paddingRight: "25px",
                paddingLeft: "10px",
                fontFamily: "inherit",
                fontSize: "inherit",
                fontWeight: "inherit",
                color: "inherit",
                cursor: "pointer",
                appearance: "none",
              }}
            >
              <option value="" disabled hidden>
                Pick Up State
              </option>
              {filterOptionsState[0]?.values?.map((state, index) => (
                <option key={index} value={state}>
                  {state}
                </option>
              ))}
            </select>
            <IoIosArrowDown
              style={{
                position: "absolute",
                right: "10px",
                top: "50%",
                transform: "translateY(-50%)",
                fontSize: "15px",
                color: "#fa7c07",
                pointerEvents: "none",
              }}
            />
          </div>

          {/* Pick Up City */}
          <div
            style={{
              position: "relative",
              display: "inline-block",
              width: "auto",
              height: "28px",
              background: "#e6f5e0",
              borderRadius: "4px",
              color: "#000",
              fontFamily: "Inter",
              fontSize: "9px",
              fontWeight: "400",
              border: "none",
              cursor: "pointer",
              marginTop: "5px",
            }}
          >
            <select
              value={filterValues["Pick Up City"] || ""}
              onChange={(e) =>
                globalSelectedOption(e.target.value, "Pick Up City")
              }
              style={{
                width: "100%",
                height: "100%",
                background: "transparent",
                border: "none",
                paddingRight: "25px",
                paddingLeft: "10px",
                fontFamily: "inherit",
                fontSize: "inherit",
                fontWeight: "inherit",
                color: "inherit",
                cursor: "pointer",
                appearance: "none",
              }}
            >
              <option value="" disabled hidden>
                Pick a City
              </option>
              {filterOptionsState[1]?.values?.map((city, index) => (
                <option key={index} value={city}>
                  {city}
                </option>
              ))}
            </select>
            <IoIosArrowDown
              style={{
                position: "absolute",
                right: "10px",
                top: "50%",
                transform: "translateY(-50%)",
                fontSize: "15px",
                color: "#fa7c07",
                pointerEvents: "none",
              }}
            />
          </div>

          {/* Language Spoken */}
          <div
            style={{
              position: "relative",
              display: "inline-block",
              width: "auto",
              height: "28px",
              background: "#e6f5e0",
              borderRadius: "4px",
              color: "#000",
              fontFamily: "Inter",
              fontSize: "9px",
              fontWeight: "400",
              border: "none",
              cursor: "pointer",
              marginTop: "5px",
            }}
          >
            <select
              value={filterValues["Language Spoken"] || ""}
              onChange={(e) =>
                globalSelectedOption(e.target.value, "Language Spoken")
              }
              style={{
                width: "100%",
                height: "100%",
                background: "transparent",
                border: "none",
                paddingRight: "25px",
                paddingLeft: "10px",
                fontFamily: "inherit",
                fontSize: "inherit",
                fontWeight: "inherit",
                color: "inherit",
                cursor: "pointer",
                appearance: "none",
              }}
            >
              <option value="" disabled hidden>
                Language Spoken
              </option>
              {filterOptionsState[2]?.values?.map((language, index) => (
                <option key={index} value={language}>
                  {language}
                </option>
              ))}
            </select>
            <IoIosArrowDown
              style={{
                position: "absolute",
                right: "10px",
                top: "50%",
                transform: "translateY(-50%)",
                fontSize: "15px",
                color: "#fa7c07",
                pointerEvents: "none",
              }}
            />
          </div>

          {/* Sourcing Type */}
          <div
            style={{
              position: "relative",
              display: "inline-block",
              width: "auto",
              height: "28px",
              background: "#e6f5e0",
              borderRadius: "4px",
              color: "#000",
              fontFamily: "Inter",
              fontSize: "9px",
              fontWeight: "400",
              border: "none",
              cursor: "pointer",
              marginTop: "5px",
            }}
          >
            <select
              value={filterValues["Sourcing Type"] || ""}
              onChange={(e) =>
                globalSelectedOption(e.target.value, "Sourcing Type")
              }
              style={{
                width: "100%",
                height: "100%",
                background: "transparent",
                border: "none",
                paddingRight: "25px",
                paddingLeft: "10px",
                fontFamily: "inherit",
                fontSize: "inherit",
                fontWeight: "inherit",
                color: "inherit",
                cursor: "pointer",
                appearance: "none",
              }}
            >
              <option value="" disabled hidden>
                Sourcing Type
              </option>
              {filterOptionsState[3]?.values?.map((type, index) => (
                <option key={index} value={type}>
                  {type}
                </option>
              ))}
            </select>
            <IoIosArrowDown
              style={{
                position: "absolute",
                right: "10px",
                top: "50%",
                transform: "translateY(-50%)",
                fontSize: "15px",
                color: "#fa7c07",
                pointerEvents: "none",
              }}
            />
          </div>
        </div>
        <div
          style={{
            position: "relative",
            display: "inline-block",
            width: "120px",
            height: "28px",
            background: "#e6f5e0",
            borderRadius: "4px",
            color: "#000",
            fontFamily: "Inter",
            fontSize: "9px",
            fontWeight: "400",
            border: "none",
            cursor: "pointer",
            marginBottom: "0",
          }}
        >
          <select
            value={filterValues["Availability"] || ""}
            onChange={(e) =>
              globalSelectedOption(e.target.value, "Availability")
            }
            style={{
              width: "100%",
              height: "100%",
              background: "transparent",
              border: "none",
              paddingRight: "25px",
              paddingLeft: "10px",
              fontFamily: "inherit",
              fontSize: "inherit",
              fontWeight: "inherit",
              color: "inherit",
              cursor: "pointer",
              appearance: "none",
            }}
          >
            <option value="" disabled hidden>
              Availability
            </option>
            {filterAvailability?.values?.map((status, index) => (
              <option key={index} value={status}>
                {status}
              </option>
            ))}
          </select>
          <IoIosArrowDown
            style={{
              position: "absolute",
              right: "10px",
              top: "50%",
              transform: "translateY(-50%)",
              fontSize: "15px",
              color: "#fa7c07",
              pointerEvents: "none",
            }}
          />
        </div>
        <div className="rightfilterDriver">
          <input
            className="input-search"
            value={searchQuery}
            type="text"
            placeholder="Search By Name,Mobile Number"
            onChange={(e) => handleSearchQuery(e.target.value)}
          />

          {/* Suggestions Dropdown */}
          {suggestions.length > 0 && (
            <div className="suggestions-dropdown">
              {suggestions.map((driver, index) => (
                <div
                  key={index}
                  className="suggestion-item"
                  onClick={() => {
                    setSearchQuery(driver["Driver Name"]); // Set input value
                    setSuggestions([]); // Hide suggestions
                    setApiData([driver]); // Filter table to selected driver
                  }}
                >
                  {driver["Driver Name"]} ({driver["Contact No."]})
                </div>
              ))}
            </div>
          )}

          <div>
            <button className="filter-button" onClick={() => ResetFilters()}>
              Reset
            </button>
          </div>
        </div>
      </div>

      <div className="table-container">
        <table className="driver-table">
          <TableHeaders headers={headerColumns} />
          {apiData.length > 0 ? (
            <TableBody entries={apiData} headers={headerColumns} />
          ) : (
            <tbody>
              <tr>
                <td colSpan={headerColumns.length} className="no-data">
                  <span>{"No driver available for this Booking!"}</span>
                </td>
              </tr>
            </tbody>
          )}
        </table>
      </div>
    </div>
  );
}

export default AssignDriverData;
