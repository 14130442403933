import React, { useState, useEffect } from "react";
import ViewCouponCode from "./ViewCouponCode";
import AddCouponCode from "./AddCouponCode";
import "./Coupons.css";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllCouponsAsync, selectAllcoupons } from "./couponSlice";
import { Link } from "react-router-dom";
import { FaLongArrowAltRight } from "react-icons/fa";
import Filter from "../Booking/Filter";
import InputColumn from "../Booking/Input";
import DropdownColumn from "../Booking/Dropdown";
import { IoIosArrowDown } from "react-icons/io";

const SearchBar = ({ title, searchQuery, handleSearch }) => {
  return (
    <div className="coupon-search-column">
      <div className="title">
        {title}
        <span className="search-icon"></span>
      </div>
      <input
        type="text"
        value={searchQuery}
        onChange={(e) => handleSearch(e.target.value)}
        className="input"
        placeholder={`Search by ${title} code`}
      />
    </div>
  );
};

const Coupons = () => {
  const [couponButtontype, setCouponsButtonType] = useState("View Coupon Code");
  const [selectedTripType, setSelectedTripType] = useState("");
  const [selectedSubTripType, setSelectedSubTripType] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const dispatch = useDispatch();

  const handleCouponsButtonClick = (buttonType) => {
    setCouponsButtonType(buttonType);
  };

  const tripOptions = [
    { label: "All", value: "" },
    { label: "Local", value: "local" },
    { label: "Outstation", value: "outstation" },
    { label: "Airport", value: "airport" },
  ];

  const subTripOptions = {
    local: [
      { label: "2hrs 20km", value: "2hrs 20km" },
      { label: "4hrs 40km", value: "4hrs 40km" },
      { label: "6hrs 60km", value: "6hrs 60km" },
      { label: "8hrs 80km", value: "8hrs 80km" },
      { label: "10hrs 100km", value: "10hrs 100km" },
      { label: "12hrs 120km", value: "12hrs 120km" },
    ],
    outstation: [
      { label: "One Way", value: "One Way" },
      { label: "Round Trip", value: "Round Trip" },
    ],
    airport: [
      { label: "select", value: "" },
      { label: "From The Airport", value: "From The Airport" },
      { label: "To The Airport", value: "To The Airport" },
    ],
  };

  const coupons = useSelector(selectAllcoupons);

  const filteredCoupons = coupons.filter((coupon) => {
    return (
      coupon.promoCode.toLowerCase().includes(searchQuery.toLowerCase()) &&
      (selectedTripType === "" ||
        coupon.tripType.toLowerCase() === selectedTripType.toLowerCase()) &&
      (selectedSubTripType === "" ||
        coupon.subTripType.toLowerCase() === selectedSubTripType.toLowerCase())
    );
  });

  useEffect(() => {
    dispatch(fetchAllCouponsAsync());
  }, []);

  return (
    <div className="coupon-container">
      <div className="coupon-head-main">
        <div className="coupon-head-left">
          <div className="coupon-heading">Coupons</div>
          <IoIosArrowDown
            style={{ transform: "rotate(-90deg)" }}
            color="#f56a00"
          />
          <div className="selected-trip-type">{couponButtontype}</div>
        </div>
        {couponButtontype !== "Add Coupon Code" && (
          <div className="coupon-type-row">
            <div
              className="dropdown-container"
              style={{ alignItems: "center" }}
            >
              <Filter style={{marginTop:"0px",marginRight:"0"}} />
              <InputColumn
                title="Coupon Code"
                selectedValue={searchQuery}
                handleChange={(e) => setSearchQuery(e.target.value)}
              />

              <div style={{ position: "relative", display: "inline-block" }}>
                <select
                  style={{
                    display: "flex",
                    width: "121px",
                    height: "29px", // Increased height for better alignment
                    padding: "8px 16px",
                    alignItems: "center",
                    gap: "4px",
                    flexShrink: "0",
                    borderRadius: "2px 0px 0px 2px",
                    border: "1px solid #D9D9D9",
                    background: "#FFF",
                    appearance: "none", // Removes default dropdown arrow
                    WebkitAppearance: "none",
                    MozAppearance: "none",
                    paddingRight: "30px", // Space for the SVG arrow
                  }}
                  value={selectedTripType} // Fixed value
                  onChange={(e) => {
                    setSelectedTripType(e.target.value);
                    setSelectedSubTripType(""); // Reset sub trip type
                  }}
                >
                  <option value="">Trip Type</option>
                  {tripOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="10"
                  height="6"
                  viewBox="0 0 10 6"
                  fill="none"
                  style={{
                    position: "absolute",
                    right: "10px",
                    top: "50%",
                    transform: "translateY(-50%)",
                    pointerEvents: "none", // Prevent interaction with SVG
                  }}
                >
                  <path
                    d="M9.35355 1.35355C9.54882 1.15829 9.54882 0.841709 9.35355 0.646447C9.15829 0.451184 8.84171 0.451184 8.64645 0.646447L9.35355 1.35355ZM5 5L4.64645 5.35355L5 5.70711L5.35355 5.35355L5 5ZM1.35355 0.646447L1 0.292893L0.292892 1L0.646446 1.35355L1.35355 0.646447ZM8.64645 0.646447L4.64645 4.64645L5.35355 5.35355L9.35355 1.35355L8.64645 0.646447ZM5.35355 4.64645L1.35355 0.646447L0.646446 1.35355L4.64645 5.35355L5.35355 4.64645Z"
                    fill="#FA7C07"
                  />
                </svg>
              </div>

              <div style={{ position: "relative", display: "inline-block" }}>
                <select
                  style={{
                    display: "flex",
                    width: "121px",
                    height: "29px", // Increased height for better alignment
                    padding: "8px 16px",
                    alignItems: "center",
                    gap: "4px",
                    flexShrink: "0",
                    borderRadius: "2px 0px 0px 2px",
                    border: "1px solid #D9D9D9",
                    background: "#FFF",
                    appearance: "none", // Removes default dropdown arrow
                    WebkitAppearance: "none",
                    MozAppearance: "none",
                    paddingRight: "30px", // Space for the SVG arrow
                  }}
                  value={selectedSubTripType}
                  onChange={(e) => setSelectedSubTripType(e.target.value)}
                >
                  <option value="">Sub Trip Type</option>
                  {subTripOptions[selectedTripType]?.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="10"
                  height="6"
                  viewBox="0 0 10 6"
                  fill="none"
                  style={{
                    position: "absolute",
                    right: "10px",
                    top: "50%",
                    transform: "translateY(-50%)",
                    pointerEvents: "none", // Prevent interaction with SVG
                  }}
                >
                  <path
                    d="M9.35355 1.35355C9.54882 1.15829 9.54882 0.841709 9.35355 0.646447C9.15829 0.451184 8.84171 0.451184 8.64645 0.646447L9.35355 1.35355ZM5 5L4.64645 5.35355L5 5.70711L5.35355 5.35355L5 5ZM1.35355 0.646447L1 0.292893L0.292892 1L0.646446 1.35355L1.35355 0.646447ZM8.64645 0.646447L4.64645 4.64645L5.35355 5.35355L9.35355 1.35355L8.64645 0.646447ZM5.35355 4.64645L1.35355 0.646447L0.646446 1.35355L4.64645 5.35355L5.35355 4.64645Z"
                    fill="#FA7C07"
                  />
                </svg>
              </div>
            </div>
          </div>
        )}

        <div className="btns">
          <button
            onClick={() => handleCouponsButtonClick("View Coupon Code")}
            className={
              couponButtontype === "View Coupon Code"
                ? "active-button-coupon"
                : "inactive-button-coupon"
            }
          >
            View Coupon Code
          </button>
          <button
            onClick={() => handleCouponsButtonClick("Add Coupon Code")}
            className={
              couponButtontype === "Add Coupon Code"
                ? "active-button-coupon"
                : "inactive-button-coupon"
            }
          >
            Add Coupon Code
          </button>
        </div>
      </div>

      <div className="table">
        {couponButtontype === "View Coupon Code" && (
          <ViewCouponCode filteredCoupons={filteredCoupons} />
        )}
        {couponButtontype === "Add Coupon Code" && <AddCouponCode />}
      </div>
    </div>
  );
};

export default Coupons;
