import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchCorporateBookings,
  selectAllCorporateBookings,
} from "./CorporateBookingSlice";
import { CiFilter } from "react-icons/ci";
import "./CorporateBookings.css";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export default function CorporateBookings() {
  const dispatch = useDispatch();
  const corporateBookings = useSelector(selectAllCorporateBookings) || [];

  // State variables for filters
  const [selectedPickupCity, setSelectedPickupCity] = useState("");
  const [selectedBookingType, setSelectedBookingType] = useState("");
  const [selectedCarType, setSelectedCarType] = useState("");
  const [selectedTripType, setSelectedTripType] = useState("");
  const [selectedSubTripType, setSelectedSubTripType] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [searchByBookingId, setSearchByBookingId] = useState("");
  const [searchByCorporateName, setSearchByCorporateName] = useState("");
  const [searchQuery, setSearchQuery] = useState("");

  // NEW: Separate From/To Date States
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  // Dropdown options
  const [pickupCityOptions, setPickupCityOptions] = useState([]);
  const [carTypeOptions, setCarTypeOptions] = useState([]);
  const [tripTypeOptions, setTripTypeOptions] = useState([]);
  const [subTripOptions, setSubTripOptions] = useState([]);

  useEffect(() => {
    dispatch(fetchCorporateBookings());
  }, [dispatch]);

  useEffect(() => {
    const extractCarTypeOptions = (dataArray) => {
      if (Array.isArray(dataArray.data)) {
        return dataArray.data.map((item) => ({
          label: item.category,
          value: item.category,
        }));
      }
      return [];
    };

    const extractPickupCityOptions = (dataArray) => {
      if (Array.isArray(dataArray.data)) {
        return dataArray.data.map((item) => ({
          label: item.location,
          value: item.location,
        }));
      }
      return [];
    };

    const extractTripTypeOptions = (dataArray) => {
      if (Array.isArray(dataArray.data)) {
        return dataArray.data.map((item) => ({
          label: item.tripType,
          value: item.tripType,
        }));
      }
      return [];
    };

    const fetchOptions = async () => {
      try {
        const cityResponse = await fetch(`${API_BASE_URL}/v1/locations`);
        const cityData = await cityResponse.json();
        setPickupCityOptions(extractPickupCityOptions(cityData));

        const carResponse = await fetch(`${API_BASE_URL}/v1/car-categories`);
        const carData = await carResponse.json();
        setCarTypeOptions(extractCarTypeOptions(carData));

        const tripResponse = await fetch(`${API_BASE_URL}/v1/trip-types`);
        const tripData = await tripResponse.json();
        setTripTypeOptions(extractTripTypeOptions(tripData));
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };
    fetchOptions();
  }, []);

  useEffect(() => {
    const extractSubTripOptions = (dataArray) => {
      if (Array.isArray(dataArray.data) && dataArray.data.length > 0) {
        const subTripTypes = dataArray.data[0].subTripTypes || [];
        return subTripTypes.map((subTrip) => ({
          label: subTrip,
          value: subTrip,
        }));
      }
      return [];
    };

    const fetchSubTripTypes = async () => {
      try {
        if (selectedTripType) {
          const subTripResponse = await fetch(
            `${API_BASE_URL}/v1/sub-trip-types/${selectedTripType}`
          );
          const subTripData = await subTripResponse.json();
          setSubTripOptions(extractSubTripOptions(subTripData));
        } else {
          setSubTripOptions([]);
        }
      } catch (error) {
        console.error("Error fetching sub-trip types: ", error);
      }
    };

    fetchSubTripTypes();
  }, [selectedTripType]);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value.toLowerCase());
  };

  // Utility to parse date from "dd-MM-yyyy"
  const parseDate = (dateString) => {
    if (!dateString) return null;
    const [day, month, year] = dateString.split("-").map(Number);
    return new Date(year, month - 1, day);
  };

  const filteredBookings = corporateBookings.filter((item) => {
    const bookingFromDate = parseDate(item.fromDate);
    const bookingToDate = parseDate(item.toDate);
    const userFromDate = fromDate ? new Date(fromDate) : null;
    const userToDate = toDate ? new Date(toDate) : null;

    const passesFromDate = userFromDate
      ? bookingFromDate >= userFromDate
      : true;
    const passesToDate = userToDate ? bookingToDate <= userToDate : true;

    return (
      passesFromDate &&
      passesToDate &&
      (selectedPickupCity ? item.pickupCity === selectedPickupCity : true) &&
      (selectedBookingType ? item.bookingType === selectedBookingType : true) &&
      (selectedCarType ? item.carType === selectedCarType : true) &&
      (selectedTripType ? item.tripType === selectedTripType : true) &&
      (selectedSubTripType ? item.subTripType === selectedSubTripType : true) &&
      (selectedStatus ? item.status === selectedStatus : true) &&
      (searchByBookingId
        ? item.bookingId.toLowerCase().includes(searchByBookingId.toLowerCase())
        : true) &&
      (searchByCorporateName
        ? item.corporateName
            ?.toLowerCase()
            .includes(searchByCorporateName.toLowerCase())
        : true) &&
      (searchQuery
        ? item.bookingId?.toLowerCase().includes(searchQuery) ||
          item.corporateName?.toLowerCase().includes(searchQuery)
        : true)
    );
  });

  return (
    <div>
      {/* Filters */}
      <div className="corporate-dropdown-main">
        <div className="corporate-filter-header">
          <div className="filter-name">Filters</div>
          <div className="corporate-filter-icon">
            <CiFilter color="#38b000" size={18} />
          </div>
        </div>

        <div className="corporate-ele">
          <select
            className="corporate-dropdown-select"
            value={selectedBookingType}
            onChange={(e) => setSelectedBookingType(e.target.value)}
          >
            <option value="">Booking Type</option>
            <option value="Corporate">Corporate</option>
            <option value="Retail">Retail</option>
          </select>

          <select
            className="corporate-dropdown-select"
            value={selectedPickupCity}
            onChange={(e) => setSelectedPickupCity(e.target.value)}
          >
            <option value="">Pickup City</option>
            {pickupCityOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>

          <select
            className="corporate-dropdown-select"
            value={selectedCarType}
            onChange={(e) => setSelectedCarType(e.target.value)}
          >
            <option value="">Car Type</option>
            {carTypeOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>

          <select
            className="corporate-dropdown-select"
            value={selectedTripType}
            onChange={(e) => setSelectedTripType(e.target.value)}
          >
            <option value="">Trip Type</option>
            {tripTypeOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>

          <select
            className="corporate-dropdown-select"
            value={selectedSubTripType}
            onChange={(e) => setSelectedSubTripType(e.target.value)}
          >
            <option value="">Sub Trip Type</option>
            {subTripOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>

          <select
            className="corporate-dropdown-select"
            value={selectedStatus}
            onChange={(e) => setSelectedStatus(e.target.value)}
          >
            <option value="">Status</option>
            <option value="Confirmed">Confirmed</option>
            <option value="Cab Assigned">Cab Assigned</option>
            <option value="In Route">In Route</option>
            <option value="Completed">Completed</option>
            <option value="Cancelled">Cancelled</option>
          </select>

          {/* 
            REPLACE the default date inputs with custom date selects
            that include the calendar SVG and hide the native icon.
          */}
          <input
            type="date"
            className="placeholder-corp"
            placeholder="From Date"
            value={fromDate}
            onChange={(e) => setFromDate(e.target.value)}
          />
          <input
            type="date"
            className="placeholder-corp"
            placeholder="To Date"
            value={toDate}
            onChange={(e) => setToDate(e.target.value)}
          />

          <div className="search-container">
            <input
              type="text"
              placeholder="Booking ID"
              value={searchQuery}
              onChange={handleSearchChange}
            />
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
            >
              <path
                d="M11.3756 5.68667C11.3756 6.94156 10.9681 8.10077 10.2818 9.04125L13.7436 12.5052C14.0855 12.8469 14.0855 13.4019 13.7436 13.7437C13.4018 14.0854 12.8467 14.0854 12.5049 13.7437L9.04302 10.2797C8.10235 10.9687 6.94292 11.3733 5.68778 11.3733C2.54583 11.3733 0 8.828 0 5.68667C0 2.54533 2.54583 0 5.68778 0C8.82973 0 11.3756 2.54533 11.3756 5.68667ZM5.68778 9.62359C6.20488 9.62359 6.71692 9.52176 7.19467 9.32391C7.67241 9.12606 8.1065 8.83607 8.47215 8.47049C8.8378 8.10491 9.12784 7.67091 9.32573 7.19326C9.52362 6.71561 9.62547 6.20367 9.62547 5.68667C9.62547 5.16966 9.52362 4.65772 9.32573 4.18007C9.12784 3.70242 8.8378 3.26842 8.47215 2.90284C8.1065 2.53726 7.67241 2.24727 7.19467 2.04942C6.71692 1.85158 6.20488 1.74974 5.68778 1.74974C5.17067 1.74974 4.65863 1.85158 4.18089 2.04942C3.70315 2.24727 3.26906 2.53726 2.90341 2.90284C2.53776 3.26842 2.24771 3.70242 2.04982 4.18007C1.85194 4.65772 1.75009 5.16966 1.75009 5.68667C1.75009 6.20367 1.85194 6.71561 2.04982 7.19326C2.24771 7.67091 2.53776 8.10491 2.90341 8.47049C3.26906 8.83607 3.70315 9.12606 4.18089 9.32391C4.65863 9.52176 5.17067 9.62359 5.68778 9.62359Z"
                fill="#8B8B8B"
              />
            </svg>
          </div>
        </div>
      </div>

      {/* Inline Table */}
      <div className="corporate-table-container">
        <table className="corporate-table">
          <thead className="corporate-head">
            <tr>
              <th  className="corporate-th">Sr No.</th>
              <th className="corporate-th">Corporate Name</th>
              <th className="corporate-th">Booking ID</th>
              <th className="corporate-th">From Date</th>
              <th className="corporate-th">To Date</th>
              <th className="corporate-th">Booking Type</th>
              <th className="corporate-th">Pickup City</th>
              <th className="corporate-th">No of Days</th>
              <th className="corporate-th">Car Type</th>
              <th className="corporate-th">Trip Type</th>
              <th className="corporate-th">Sub Trip Type</th>
              <th className="corporate-th">Status</th>
            </tr>
          </thead>
          <tbody className="corporate-body">
            {filteredBookings.map((booking, index) => (
              <tr key={booking.bookingId}>
                <td>{index + 1}</td>
                <td>{booking.corporateName}</td>
                <td>{booking.bookingId}</td>
                <td>{booking.fromDate}</td>
                <td>{booking.toDate || ""}</td>
                <td>{booking.bookingType}</td>
                <td>{booking.pickupCity}</td>
                <td>{booking.noOfDays}</td>
                <td>{booking.carType}</td>
                <td>{booking.tripType}</td>
                <td>{booking.subTripType}</td>
                <td>{booking.status}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
