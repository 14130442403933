// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#BtoC-main-container {
    width: 100%;
    height: -moz-fit-content;
    height: fit-content;
    display: flex;
    gap: 20px;
    padding: 0px 5px 10px 5px;
}

#BtoC-main-container #trip-booking-form-main-container {
    min-width: 550px;
    max-width: 64%;
    width: 100%;
    height: -moz-fit-content ;
    height: fit-content ;
}

#BtoC-main-container #trip-booking-form-main-container #form-container-layout{
    min-width: 550px;
    width: 100%;
    height: -moz-fit-content ;
    height: fit-content ;
    background: #BAE7A4;
    box-shadow: 0px 4px 22px 0px rgba(0, 0, 0, 0.17);
    border-radius: 21px;
    padding: 15px 20px ;
}

#BtoC-main-container #fair-details-main-container {
    min-width:300px;
    width: 36%;
    padding: 15px 32px 1px 14px;
    height: -moz-fit-content;
    height: fit-content;
}`, "",{"version":3,"sources":["webpack://./src/components/BookingB2C/CreateNewBooking/Layout.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,wBAAmB;IAAnB,mBAAmB;IACnB,aAAa;IACb,SAAS;IACT,yBAAyB;AAC7B;;AAEA;IACI,gBAAgB;IAChB,cAAc;IACd,WAAW;IACX,yBAAoB;IAApB,oBAAoB;AACxB;;AAEA;IACI,gBAAgB;IAChB,WAAW;IACX,yBAAoB;IAApB,oBAAoB;IACpB,mBAAmB;IACnB,gDAAgD;IAChD,mBAAmB;IACnB,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,UAAU;IACV,2BAA2B;IAC3B,wBAAmB;IAAnB,mBAAmB;AACvB","sourcesContent":["#BtoC-main-container {\n    width: 100%;\n    height: fit-content;\n    display: flex;\n    gap: 20px;\n    padding: 0px 5px 10px 5px;\n}\n\n#BtoC-main-container #trip-booking-form-main-container {\n    min-width: 550px;\n    max-width: 64%;\n    width: 100%;\n    height: fit-content ;\n}\n\n#BtoC-main-container #trip-booking-form-main-container #form-container-layout{\n    min-width: 550px;\n    width: 100%;\n    height: fit-content ;\n    background: #BAE7A4;\n    box-shadow: 0px 4px 22px 0px rgba(0, 0, 0, 0.17);\n    border-radius: 21px;\n    padding: 15px 20px ;\n}\n\n#BtoC-main-container #fair-details-main-container {\n    min-width:300px;\n    width: 36%;\n    padding: 15px 32px 1px 14px;\n    height: fit-content;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
