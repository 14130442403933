import React, { useEffect, useState } from "react";
import { Routes, Route, useNavigate, useLocation, Link } from "react-router-dom";
import LocalTripPage from "./LocalTripPage";
import OutstationTripPage from "./OutstationTripPage";
import AirportTripPage from "./AirportTripPage";
import CreateNewTariffPage from "./CreateNewTariffPage";
import { FaLongArrowAltRight } from "react-icons/fa";
import "./TariffManagement.css";

const TariffManagement = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [tripType, setTripType] = useState("Airport");
  const [isNewTariffActive, setIsNewTariffActive] = useState(false);

  useEffect(() => {
    if (location.pathname === "/tariff-management") {
      navigate("/tariff-management/airport");
    }
  }, [navigate, location.pathname]);

  useEffect(() => {
    const path = location.pathname.split("/").pop().replace(/-/g, " ");
    if (path === "create new tariff") {
      setIsNewTariffActive(true);
      setTripType("");
    } else {
      setIsNewTariffActive(false);
      setTripType(path.replace(/\b\w/g, (char) => char.toUpperCase()));
    }
  }, [location.pathname]);

  const handleTripType = (type) => {
    navigate(`/tariff-management/${type.replace(/ /g, "-").toLowerCase()}`);
  };

  const handleNewTariffClick = () => {
    navigate(`/tariff-management/create-new-tariff`);
  };

  return (
    <div className="carmangement-header" style={{ overflowX: "auto" }}>
      <div className="booking">
        <div className="header">
          <div className="header-text">
            <Link to="/dashboard" className="h3-booking">
              Tariff management
            </Link>{" "}
            <FaLongArrowAltRight />{" "}
            <Link to="/tariff-management" className="h2-booking">
              {isNewTariffActive ? "Create New Tariff" : tripType}
            </Link>
          </div>

          <div className="btns">
            <button
              onClick={() => handleTripType("Airport")}
              className={
                tripType === "Airport" ? "createButton-cars" : "createButton"
              }
            >
              Airport Tariff
            </button>
            <button
              onClick={() => handleTripType("Local")}
              className={
                tripType === "Local" ? "createButton-cars" : "createButton"
              }
            >
              Local Tariff
            </button>
            <button
              onClick={() => handleTripType("Outstation")}
              className={
                tripType === "Outstation" ? "createButton-cars" : "createButton"
              }
            >
              Outstation Tariff
            </button>
            <button
              onClick={handleNewTariffClick}
              className={
                isNewTariffActive ? "createButton-cars" : "createButton"
              }
            >
              Create New Tariff
            </button>
          </div>
        </div>
      </div>

      <div className="table">
        <Routes>
          <Route path="airport" element={<AirportTripPage />} />
          <Route path="local" element={<LocalTripPage />} />
          <Route path="outstation" element={<OutstationTripPage />} />
          <Route path="create-new-tariff" element={<CreateNewTariffPage />} />
        </Routes>
      </div>
    </div>
  );
};

export default TariffManagement;
