import { useLocation } from "react-router-dom";
import LogoutTimer from "./Logger";

const LogoutTimerWrapper = () => {
  const location = useLocation();
  const isLoginRoute = location.pathname === "/";

  return isLoginRoute ? null : <LogoutTimer />;
};

export default LogoutTimerWrapper;
