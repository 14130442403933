import React, { useEffect, useRef, useState } from "react";
import { BiSolidCar } from "react-icons/bi";
import { FiUserCheck } from "react-icons/fi";
import {
  MdFlight,
  MdOutlineEdit,
  MdOutlinePermContactCalendar,
} from "react-icons/md";
import { PiCarProfileLight } from "react-icons/pi";
import { SlCalender, SlLocationPin } from "react-icons/sl";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../Loader";
import Interaction from "./addInteraction";
import "./TaskDetails.css";

import {
  fetchbillingDetailsAsync,
  fetchbookingDetailsAsync,
  selectbookingDetails,
} from "./DetailsSlice";
import Airport_Trip_Type_Popup from "./popups/Airport_Trip_Type_Popup";
import Local_Trip_Type_Popup from "./popups/Local_Trip_Type_Popup";
import Oustation_One_Way from "./popups/Outstation_One_Way";
import Outstation_Round_Trip from "./popups/Outstation_Round_Trip";
import Schedule from "../../assets/Schedule.svg";
import TripType from "../../assets/TripType.svg";
import PreferredVehicle from "../../assets/PreferredVehicle.svg";
import FareDetails from "../../assets/FareDetails.svg";
import Assignto from "../../assets/Assignto.svg";
import CreatedBy from "../../assets/CreatedBy.svg";
import LocationDetails from "../../assets/LocationDetails.svg";
import FlightDetails from "../../assets/FlightDetails.svg";
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const TaskDetails = ({ item }) => {
  const navigate = useNavigate();

  const [popup, setPop] = useState(false);
  const handleClickopen = () => {
    setPop(!popup);
  };
  const closepopup = () => {
    setPop(false);
  };

  const dispatch = useDispatch();
  const { id } = useParams();
  console.log(id);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const bookingDetails = useSelector(selectbookingDetails);
  const { driverData } = useSelector((state) => state.assignDrivers);
  const { cabData } = useSelector((state) => state.assignCabs);
  console.log("Booking details : ", bookingDetails);
  console.log("Booking details user : ", bookingDetails?.user);
  console.log(
    "ADVANCE PAYMENT",
    bookingDetails?.paymentDetails?.[0]?.amountPaid
  );
  const [selectedReasons, setSelectedReasons] = useState([]);

  const [showPopup, setShowPopup] = useState(false);
  const popupRef = useRef(null);
  const [activePopupId, setActivePopupId] = useState(null); // Changed name to activePopupId
  const popupContainerRef = useRef(null); // Changed name to popupContainerRef

  // Handle clicking outside the popup
  const handleOutsidePopupClick = (event) => {
    // Changed function name to handleOutsidePopupClick
    if (
      popupContainerRef.current &&
      !popupContainerRef.current.contains(event.target)
    ) {
      setActivePopupId(null); // Changed state setter to setActivePopupId
    }
  };

  const handleOutsideClick = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      setShowPopup(false);
    }
  };

  useEffect(() => {
    if (showPopup) {
      document.addEventListener("mousedown", handleOutsideClick);
    } else {
      document.removeEventListener("mousedown", handleOutsideClick);
    }
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [showPopup, activePopupId]);

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return `${text.substring(0, maxLength)}...`;
    }
    return text;
  };

  // Render the cities with a Show More option if there are more than 2 cities
  const renderDestinationCities = () => {
    // Changed name to renderDestinationCities
    const cities = bookingDetails?.destinationCities || [];
    if (cities.length <= 2) {
      return cities.map((city, index) => (
        <div key={index} className="weakfont">
          {city}
        </div>
      ));
    } else {
      return (
        <>
          <div className="weakfont">{cities[0]}</div>
          <div className="weakfont">{cities[1]}</div>
          <div
            style={{
              cursor: "pointer",
              color: "grey",
              fontSize: "12px",
            }}
            onClick={() => setActivePopupId(bookingDetails.bookingId)} // Changed state setter to setActivePopupId
          >
            ... Show more
          </div>
        </>
      );
    }
  };
  const isMaxCheckboxSelected = selectedReasons.length >= 2;

  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;
    setSelectedReasons((prev) =>
      checked ? [...prev, value] : prev.filter((reason) => reason !== value)
    );
  };
  useEffect(() => {
    try {
      dispatch(fetchbookingDetailsAsync(id));
      dispatch(fetchbillingDetailsAsync(id));
    } catch (error) {
      setError("Error fetching details. Please try again.");
    } finally {
      setLoading(false);
    }
  }, []);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleConfirmDetach = () => {
    const validReasons = [
      "Ride was Assigned Incorrectly",
      "Driver Denied the Ride",
      "Driver is Unavailable due to Emergency",
      "Driver is no Longer Employed by the Company",
      "Driver is Unavailable due to Illness",
      "Cab is Unavailable due to Maintenance",
      "Cab is Unavailable due to Accident",
      "Other Operational Issues",
      "Customer Requested a Different Driver and Cab",
    ];

    const filteredReasons = selectedReasons.filter((reason) =>
      validReasons.includes(reason)
    );

    if (filteredReasons.length === 0) {
      alert("Please select valid reasons to detach the driver and cab.");
      return;
    }

    handleDetachCabAndDriver(bookingDetails?.bookingId, filteredReasons); // Pass reasons as deassignReasons
    handleCloseModal();
  };

  const handleDetachCabAndDriver = async (bookingId, deassignReasons) => {
    try {
      const response = await fetch(
        `${API_BASE_URL}/v1/admin/bookings/deassign-driver-car`,
        {
          method: "POST",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ bookingId, deassignReasons }), // Include reasons with the correct key
        }
      );

      if (!response.ok) {
        throw new Error(`Error: ${response.status} - ${response.statusText}`);
      }

      const data = await response.json();
      console.log("Response data:", data);
      alert(data.message);
      window.location.reload();
    } catch (error) {
      console.error("Error detaching cab and driver:", error);
      alert("An error occurred. Please try again.");
    }
  };

  return (
    <div>
      {loading ? (
        <div className="loader1">
          <Loader />
        </div>
      ) : error ? (
        <p>Error: {error}</p>
      ) : (
        <div className="main-header-box">
          <div className="main-header-TD">
            <div className="task">
              <div className="icon-booking">
                <img src={CreatedBy} alt="CreatedBy" className="icon_1" />
                Created By:
                <h7 style={{ marginLeft: "3rem", marginTop: "-0rem" }}>
                  {bookingDetails?.source}{" "}
                </h7>
              </div>
            </div>
            <div className="Line-booking"></div>
            <div className="form">
              <form>
                <div className="one" style={{ top: "0" }}>
                  <div className="createdby-contents" style={{}}>
                    <label>
                      Customer Name:
                      <h7>
                        {bookingDetails?.user?.firstName}{" "}
                        {bookingDetails?.user?.lastName}
                      </h7>
                    </label>
                    <label>
                      Mobile No.:
                      <h7>{bookingDetails?.user?.phone}</h7>
                    </label>
                    <label>
                      Atl Mobile No. :<h7>{bookingDetails?.user?.phone}</h7>
                    </label>
                  </div>
                  {/* <br></br> */}
                  <div>
                    <label>
                      Pickup address:
                      <h7 title={bookingDetails?.pickupAddress}>
                        {truncateText(bookingDetails?.pickupAddress, 50)}
                        {bookingDetails?.pickupAddress.length > 50 && (
                          <button
                            type="button" // Prevents form submission
                            onClick={() => setShowPopup(true)}
                            style={{
                              fontSize: "12px",
                              color: "grey",
                              background: "none",
                              border: "none",
                              cursor: "pointer",
                            }}
                          >
                            View More
                          </button>
                        )}
                      </h7>
                    </label>

                    {showPopup && (
                      <div
                        ref={popupRef}
                        style={{
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                          background: "white",
                          border: "1px solid #ccc",
                          padding: "20px",
                          boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                          zIndex: 1000,
                        }}
                      >
                        <p>{bookingDetails?.pickupAddress}</p>
                        <button
                          type="button" // Prevents form submission
                          onClick={() => setShowPopup(false)}
                          style={{
                            marginTop: "10px",
                            color: "white",
                            backgroundColor: "red",
                            border: "none",
                            padding: "5px 10px",
                            cursor: "pointer",
                          }}
                        >
                          Close
                        </button>
                      </div>
                    )}

                    {showPopup && (
                      <div
                        style={{
                          position: "fixed",
                          top: 0,
                          left: 0,
                          width: "100%",
                          height: "100%",
                          background: "rgba(0, 0, 0, 0.5)",
                          zIndex: 999,
                        }}
                      />
                    )}
                  </div>
                </div>
                <div className="tow">
                  <label style={{ marginLeft: "1rem" }}>
                    Email address:
                    <h7>{bookingDetails?.user?.email}</h7>
                  </label>
                  {/* <br /> */}
                  {/* <br /> */}
                  <div
                    style={{
                      fontWeight: "600",
                      color: "#333333",
                      marginTop: "13px",
                    }}
                    className="flight-details-div"
                  >
                    <img
                      src={FlightDetails}
                      alt="FlightDetails"
                      className="icon_1"
                      style={{ marginRight: "8px", fontWeight: "" }}
                    />
                    Flight Details:
                    <h7>{bookingDetails?.flightDetails}</h7>
                  </div>
                </div>
              </form>
            </div>
          </div>

          {bookingDetails?.tariff?.tripType === "Local" ||
          bookingDetails?.tariff?.tripType === "Airport" ? (
            <div className="location-TD">
              <div className="task-TD" >
                <div className="icon-booking">
                  <img
                    src={LocationDetails}
                    alt="LocationDetails"
                    className="icon_1"
                  />
                  Location Details:
                </div>

                <form className="location-TD-value">
                  <div className="box-booking-main-div" style={{width:"290px"}}>
                    <div>
                      {" "}
                      <label className="box-booking-destination">
                        Pickup City:{" "}
                      </label>
                      <div className="weakfont-TD">
                        {bookingDetails?.location?.city},{" "}
                        {bookingDetails?.location?.state}
                      </div>
                    </div>
                  </div>
                  <div className="icon-booking" >
                    <img src={Schedule} alt="Schedule" className="icon_1" />
                    Schedule:
                  </div>
                </form>
                <form className="location-TD-value">
                  <div className="box-booking-main-div">
                    <div>
                      <label className="box-booking-destination">
                        Pickup Date & Time:{" "}
                      </label>
                      <div className="weakfont-TD">
                        {bookingDetails.pickupDate} {bookingDetails?.pickupTime}
                      </div>

                      {activePopupId === bookingDetails.bookingId && ( // Changed comparison to activePopupId
                        <div
                          style={{
                            position: "fixed",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",
                            background: "rgba(0, 0, 0, 0.5)",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            zIndex: 1000,
                          }}
                        >
                          <div
                            ref={popupContainerRef} // Changed ref to popupContainerRef
                            style={{
                              background: "white",
                              padding: "20px",
                              borderRadius: "5px",
                              maxWidth: "400px",
                              width: "90%",
                              position: "relative",
                            }}
                          >
                            <button
                              onClick={() => setActivePopupId(null)} // Changed state setter to setActivePopupId
                              style={{
                                position: "absolute",
                                top: "10px",
                                right: "10px",
                                background: "red",
                                color: "white",
                                border: "none",
                                padding: "5px 10px",
                                cursor: "pointer",
                              }}
                            >
                              Close
                            </button>
                            <div>
                              {bookingDetails?.destinationCities.map(
                                (city, index) => (
                                  <div
                                    key={index}
                                    style={{
                                      fontSize: "14px",
                                      margin: "5px 0",
                                    }}
                                  >
                                    {city}
                                  </div>
                                )
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          ) : // OUTSTATION ONE WAY START

          bookingDetails?.tariff?.tripType === "Outstation" &&
            bookingDetails?.tariff?.subTripType === "One Way" ? (
            <div className="location-TD">
              <div className="task-TD">
                <div className="icon-booking">
                  <img
                    src={LocationDetails}
                    alt="LocationDetails"
                    className="icon_1"
                  />
                  Location Details:
                </div>

                <form className="location-TD-value">
                  <div className="box-booking-main-div">
                    <label
                      className="location-wraper-TDO"
                      style={{ marginLeft: "100px" }}
                    >
                      Pickup City:
                      <div className="weakfont-TDO">
                        {bookingDetails?.location?.city},{" "}
                        {bookingDetails?.location?.state}
                      </div>
                    </label>
                    <label
                      className="location-wraper-TDO"
                      style={{ marginLeft: "100px" }}
                    >
                      Destination Cities:
                      <div className="weakfont-TDO">
                        {renderDestinationCities()}
                      </div>
                    </label>
                    <div>
                      {activePopupId === bookingDetails.bookingId && ( // Changed comparison to activePopupId
                        <div
                          style={{
                            position: "fixed",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",
                            background: "rgba(0, 0, 0, 0.5)",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            zIndex: 1000,
                          }}
                        >
                          <div
                            ref={popupContainerRef} // Changed ref to popupContainerRef
                            style={{
                              background: "white",
                              padding: "20px",
                              borderRadius: "5px",
                              maxWidth: "400px",
                              width: "90%",
                              position: "relative",
                            }}
                          >
                            <button
                              onClick={() => setActivePopupId(null)} // Changed state setter to setActivePopupId
                              style={{
                                position: "absolute",
                                top: "10px",
                                right: "10px",
                                background: "red",
                                color: "white",
                                border: "none",
                                padding: "5px 10px",
                                cursor: "pointer",
                              }}
                            >
                              Close
                            </button>
                            <div>
                              {bookingDetails?.destinationCities.map(
                                (city, index) => (
                                  <div
                                    key={index}
                                    style={{
                                      fontSize: "14px",
                                      margin: "5px 0",
                                    }}
                                  >
                                    {city}
                                  </div>
                                )
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          ) : (
            <div className="location-TD">
              <div className="task-TD">
                <div className="icon-booking">
                  <img
                    src={LocationDetails}
                    alt="LocationDetails"
                    className="icon_1"
                  />
                  Location Details:
                </div>

                <form className="location-TD-value">
                  <div className="box-booking-main-div">
                    <label
                      className="location-wraper-TDO"
                      style={{ marginLeft: "100px" }}
                    >
                      Pickup City:
                      <div className="weakfont-TDO">
                        {bookingDetails?.location?.city},{" "}
                        {bookingDetails?.location?.state}
                      </div>
                    </label>
                    <label
                      className="location-wraper-TDO"
                      style={{ marginLeft: "100px" }}
                    >
                      Destination Cities:
                      <div className="weakfont-TDO">
                        {renderDestinationCities()}
                      </div>
                    </label>
                    <div>
                      {activePopupId === bookingDetails.bookingId && ( // Changed comparison to activePopupId
                        <div
                          style={{
                            position: "fixed",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",
                            background: "rgba(0, 0, 0, 0.5)",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            zIndex: 1000,
                          }}
                        >
                          <div
                            ref={popupContainerRef} // Changed ref to popupContainerRef
                            style={{
                              background: "white",
                              padding: "20px",
                              borderRadius: "5px",
                              maxWidth: "400px",
                              width: "90%",
                              position: "relative",
                            }}
                          >
                            <button
                              onClick={() => setActivePopupId(null)} // Changed state setter to setActivePopupId
                              style={{
                                position: "absolute",
                                top: "10px",
                                right: "10px",
                                background: "red",
                                color: "white",
                                border: "none",
                                padding: "5px 10px",
                                cursor: "pointer",
                              }}
                            >
                              Close
                            </button>
                            <div>
                              {bookingDetails?.destinationCities.map(
                                (city, index) => (
                                  <div
                                    key={index}
                                    style={{
                                      fontSize: "14px",
                                      margin: "5px 0",
                                    }}
                                  >
                                    {city}
                                  </div>
                                )
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          )}

          {bookingDetails?.tariff?.tripType === "Local" ||
          bookingDetails?.tariff?.tripType === "Airport" ? (
            <></>
          ) : bookingDetails?.tariff?.tripType === "Outstation" &&
            bookingDetails?.tariff?.subTripType === "One Way" ? (
            <div className="Schedule-OTD">
              <div className="task">
                <div className="icon-booking">
                  <img src={Schedule} alt="Schedule" className="icon_1" />
                  Schedule:
                </div>
              </div>

              <div
                className="box-booking-main-div"
                style={{ marginTop: "10px" }}
              >
                <label className="box-line-booking">
                  Start Date & Time:
                  <h7>
                    {bookingDetails?.pickupDate} {bookingDetails?.pickupTime}{" "}
                  </h7>
                </label>
              </div>
              <div
                className="box-booking-main-div"
                style={{ marginTop: "10px" }}
              >
                <label className="box-line-booking">
                  <div className="end-date">End Date:</div>
                  <div className="end-date">
                    <h7>
                      {bookingDetails?.returnDate === "undefined"
                        ? bookingDetails?.pickupDate
                        : bookingDetails?.returnDate}{" "}
                    </h7>
                  </div>
                </label>
              </div>
              <div
                className="box-booking-main-div"
                style={{ marginTop: "10px" }}
              >
                <label className="box-line-booking">
                  <div className="no-of-days">No.of Days:</div>
                  <div className="no-of-days">
                    <h7>{bookingDetails?.noOfDays} </h7>
                  </div>
                </label>
              </div>
            </div>
          ) : (
            <div className="Schedule-OTD">
              <div className="task">
                <div className="icon-booking">
                  <img src={Schedule} alt="Schedule" className="icon_1" />
                  Schedule:
                </div>
              </div>

              <div
                className="box-booking-main-div"
                style={{ marginTop: "10px" }}
              >
                <label className="box-line-booking">
                  Start Date & Time:
                  <h7>
                    {bookingDetails?.pickupDate} {bookingDetails?.pickupTime}{" "}
                  </h7>
                </label>
              </div>
              <div
                className="box-booking-main-div"
                style={{ marginTop: "10px" }}
              >
                <label className="box-line-booking">
                  <div className="end-date">End Date:</div>
                  <div className="end-date">
                    <h7>
                      {bookingDetails?.returnDate === "undefined"
                        ? bookingDetails?.pickupDate
                        : bookingDetails?.returnDate}{" "}
                    </h7>
                  </div>
                </label>
              </div>
              <div
                className="box-booking-main-div"
                style={{ marginTop: "10px" }}
              >
                <label className="box-line-booking">
                  <div className="no-of-days">No.of Days:</div>
                  <div className="no-of-days">
                    <h7>{bookingDetails?.noOfDays} </h7>
                  </div>
                </label>
              </div>
            </div>
          )}

          <div className="Trip-Type-TD">
            <div className="task-TD">
              <div className="icon-booking">
                <img src={TripType} alt="Trip-Type" className="icon_1" />
                Trip Type:
              </div>

              <div className="box-booking-main-div">
                <div>
                  {" "}
                  <label className="box-booking-destination">
                    {bookingDetails?.tariff?.tripType}:{" "}
                  </label>
                  <div
                    className="weakfont-TD"
                    style={{ width: "100px", marginTop: "-5px" }}
                  >
                    {bookingDetails?.tariff?.subTripType}
                  </div>
                </div>
              </div>
              <div className="icon-booking" style={{ marginLeft: "200px" }}>
                <img
                  src={PreferredVehicle}
                  alt="PreferredVehicle"
                  className="icon_1"
                />
                Preferred Vehicle:
              </div>
              <div className="Trip-TD-value">
                <label style={{ width: "100px", marginTop: "10px" }}>
                  <h7>{bookingDetails?.carCategory?.category}</h7>
                </label>
              </div>

              <div
                className="box-booking-main-div"
                style={{ marginLeft: "50px" }}
              >
                <div className="GSTD">
                  <div className="icon-booking" style={{ height: "15px" }}>
                    GSTIN
                  </div>
                  <label>
                    <h7 style={{ marginLeft: "-10px" }}>
                      {bookingDetails?.gstNumber}
                    </h7>
                  </label>
                </div>
              </div>
            </div>
          </div>

          {/* OUTSTATION ONE WAY End */}

          {/* OUTSTATION Round Trip Start */}

          {bookingDetails?.tariff?.tripType === "Outstation" ? (
            bookingDetails?.tariff?.subTripType === "Round Trip" ? (
              <div className="fare-TD">
                <div className="task">
                  <div
                    className="icon-booking"
                    style={{
                      display: "flex",
                    }}
                  >
                    <img
                      src={FareDetails}
                      alt="FareDetails"
                      className="icon_2"
                    />
                    <div
                      className="fare1"
                      style={{ transform: "translateX(-10px)" }}
                    >
                      Fare Details:
                    </div>
                    <div
                      className="estimated-1"
                      style={{ transform: "translateX(-50px)" }}
                    >
                      <h7 style={{ color: "#6D6D6D" }}>
                        Estimated Fare:
                        <span style={{ fontWeight: "600" }}>
                          Rs. {bookingDetails?.estimatedFare}
                        </span>
                      </h7>
                    </div>

                    {/* popup start */}
                    <div className="breakup-1">
                      <h8 onClick={handleClickopen}>Fare Breakup</h8>{" "}
                      <div>
                        <div>
                          {popup ? (
                            <Outstation_Round_Trip
                              bookingDetails={bookingDetails}
                              closepopup={closepopup}
                            />
                          ) : (
                            ""
                          )}
                        </div>
                        {/* popup close */}
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="Line-bookin"
                  style={{
                    width: "full",
                    height: "1px",
                    backgroundColor: "#E6F5E0",
                    margin: "0px 6px",
                    border: "1px solid #E6F5E0",
                  }}
                ></div>

                <div
                  style={{
                    width: "full",
                    height: "30px",
                    fontSize: "12px",
                    display: "flex",

                    placeItems: "center",
                    padding: "0px 15px",
                    marginTop: "5px",
                    color: "#6D6D6D",
                    fontSize: "12px",
                    fontWeight: "600",
                  }}
                >
                  <div style={{ display: "flex", gap: "8px" }}>
                    <div style={{ color: "#38AF00" }}>Inclusion:</div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "20px",
                      }}
                    >
                      <div className="cont1-TD">
                        <div>
                          KM Covered : {bookingDetails?.approxDistance} km
                        </div>
                        <div>
                          Driver Charge : Rs.{" "}
                          {
                            bookingDetails?.tariff?.fareDetails?.nightDriverAllowance
                          }{" "}
                          X {bookingDetails?.noOfDays} Days
                        </div>
                      </div>

                      <div className="cont1-TD" style={{ marginTop: "10px" }}>
                        {bookingDetails?.morningAllowance> 0 && (
                          <div className="cont1-TD">
                            <div>
                              Morning Charge : Rs.{" "}
                              {
                                bookingDetails?.morningAllowance
                              }
                            </div>
                          </div>
                        )}
                        <div>
                          GST @{bookingDetails?.tariff?.fareDetails?.gstRate}% :
                          Rs. {bookingDetails?.gstOnEstimatedFare}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="line"
                    style={{ marginLeft: "10px", marginRight: "10px" }}
                  ></div>
                  <div
                    style={{
                      display: "flex",
                      gap: "8px",
                      marginRight: "-00px",
                    }}
                  >
                    <div style={{ color: "#38AF00" }}>Exclusion:</div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px",
                      }}
                    >
                      <div className="cont1-TD">
                        <div>
                          Extra Km @ Rs.
                          {bookingDetails?.tariff?.fareDetails?.extraKmCharge}
                          /Km
                        </div>
                        {bookingDetails?.extraDriverAllowance > 0 && (
                          <div>
                            Extra Driver Charge : Rs.{" "}
                            {bookingDetails?.extraDriverAllowance}/day
                          </div>
                        )}
                      </div>
                      <div className="cont1-TD" style={{ marginTop: "20px" }}>
                        <div>
                          GST on Extras @
                          {bookingDetails?.tariff?.fareDetails?.gstRate}%
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <>
                <div className="fare-TD">
                  <div className="task">
                    <div
                      className="icon-booking"
                      style={{
                        display: "flex",
                      }}
                    >
                      <img
                        src={FareDetails}
                        alt="FareDetails"
                        className="icon_2"
                      />
                      <div
                        className="fare1"
                        style={{ transform: "translateX(-10px)" }}
                      >
                        Fare Details:
                      </div>
                      <div
                        className="estimated-1"
                        style={{ transform: "translateX(-50px)" }}
                      >
                        <h7 style={{ color: "#6D6D6D" }}>
                          Estimated Fare:
                          <span style={{ fontWeight: "600" }}>
                            Rs. {bookingDetails?.estimatedFare}
                          </span>
                        </h7>
                      </div>

                      {/* popup start */}
                      <div className="breakup-1">
                        <h8 onClick={handleClickopen}>Fare Breakup</h8>{" "}
                        <div>
                          <div>
                            {popup ? (
                              <Oustation_One_Way
                                bookingDetails={bookingDetails}
                                closepopup={closepopup}
                              />
                            ) : (
                              ""
                            )}
                          </div>
                          {/* popup close */}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="Line-bookin"
                    style={{
                      width: "full",
                      height: "1px",
                      backgroundColor: "#E6F5E0",
                      margin: "0px 6px",
                      border: "1px solid #E6F5E0",
                    }}
                  ></div>

                  <div
                    style={{
                      width: "full",
                      height: "30px",
                      fontSize: "12px",
                      display: "flex",

                      placeItems: "center",
                      padding: "0px 15px",
                      marginTop: "5px",
                      color: "#6D6D6D",
                      fontSize: "12px",
                      fontWeight: "600",
                    }}
                  >
                    <div style={{ display: "flex", gap: "8px" }}>
                      <div style={{ color: "#38AF00" }}>Inclusion:</div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "20px",
                        }}
                      >
                        <div className="cont1-TD">
                          <div>
                            KM Covered : {bookingDetails?.approxDistance} km
                          </div>
                          <div>
                            Driver Charge : Rs.{" "}
                            {
                              bookingDetails?.tariff?.fareDetails?.nightDriverAllowance
                            }{" "}
                            X {bookingDetails?.noOfDays} Days
                          </div>
                        </div>

                        <div className="cont1-TD" style={{ marginTop: "15px" }}>
                          {bookingDetails?.morningAllowance> 0 && (
                            <div className="cont1-TD">
                              <div>
                                Morning Charge : Rs.{" "}
                                {
                                  bookingDetails?.morningAllowance
                                }
                              </div>
                            </div>
                          )}
                          <div>
                            GST @{bookingDetails?.tariff?.fareDetails?.gstRate}%
                            : Rs. {bookingDetails?.gstOnEstimatedFare}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      className="line"
                      style={{ marginLeft: "10px", marginRight: "10px" }}
                    ></div>
                    <div
                      style={{
                        display: "flex",
                        gap: "8px",
                        marginRight: "-00px",
                      }}
                    >
                      <div style={{ color: "#38AF00" }}>Exclusion:</div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "10px",
                        }}
                      >
                        <div className="cont1-TD">
                          <div>
                            Extra Km @ Rs.
                            {bookingDetails?.tariff?.fareDetails?.extraKmCharge}
                            /Km
                          </div>
                          {bookingDetails?.extraDriverAllowance > 0 && (
                            <div>
                              Extra Driver Charge : Rs.{" "}
                              {bookingDetails?.extraDriverAllowance}/day
                            </div>
                          )}
                        </div>
                        <div className="cont1-TD" style={{ marginTop: "20px" }}>
                          <div>
                            GST on Extras @
                            {bookingDetails?.tariff?.fareDetails?.gstRate}%
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )
          ) : (
            ""
          )}

          {/* OUTSTATION Round Trip  End */}

          {/* Local START */}

          {bookingDetails?.tariff?.tripType === "Local" ? (
            <div className="fare-TD">
              <div className="task">
                <div
                  className="icon-booking"
                  style={{
                    display: "flex",
                  }}
                >
                  <img src={FareDetails} alt="FareDetails" className="icon_2" />
                  <div
                    className="fare1"
                    style={{ transform: "translateX(-10px)" }}
                  >
                    Fare Details:
                  </div>
                  <div
                    className="estimated-1"
                    style={{ transform: "translateX(-50px)" }}
                  >
                    <h7 style={{ color: "#6D6D6D" }}>
                      Estimated Fare:
                      <span style={{ fontWeight: "600" }}>
                        Rs. {bookingDetails?.estimatedFare}
                      </span>
                    </h7>
                  </div>

                  {/* popup start */}
                  <div className="breakup-1">
                    <h8 onClick={handleClickopen}>Fare Breakup</h8>{" "}
                    <div>
                      <div>
                        {popup ? (
                          <Local_Trip_Type_Popup
                            bookingDetails={bookingDetails}
                            closepopup={closepopup}
                          />
                        ) : (
                          ""
                        )}
                      </div>
                      {/* popup close */}
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="Line-bookin"
                style={{
                  width: "full",
                  height: "1px",
                  backgroundColor: "#E6F5E0",
                  margin: "0px 6px",
                  border: "1px solid #E6F5E0",
                }}
              ></div>

              <div
                style={{
                  width: "full",
                  height: "30px",
                  fontSize: "12px",
                  display: "flex",

                  placeItems: "center",
                  padding: "0px 15px",
                  marginTop: "5px",
                  color: "#6D6D6D",
                  fontSize: "12px",
                  fontWeight: "600",
                }}
              >
                <div style={{ display: "flex", gap: "8px" }}>
                  <div style={{ color: "#38AF00" }}>Inclusion:</div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "20px",
                    }}
                  >
                    <div className="cont1-TD">
                      {bookingDetails?.tariff?.fareDetails?.minKm > 0 && (
                        <div>
                          KM Include :{" "}
                          {bookingDetails?.tariff?.fareDetails?.minKm} km
                        </div>
                      )}
                      {bookingDetails?.tariff?.fareDetails?.minHrs > 0 && (
                        <div>
                          Hrs Include :{" "}
                          {bookingDetails?.tariff?.fareDetails?.minHrs} hrs
                        </div>
                      )}
                      <div>
                        GST @ {bookingDetails?.tariff?.fareDetails?.gstRate}% 
                      </div>
                    </div>

                    <div className="cont1-TD" style={{ marginTop: "15px" }}>
                      {bookingDetails?.morningAllowance> 0 && (
                        <div>
                          Morning Charge : Rs.{" "}
                          {
                            bookingDetails?.morningAllowance
                          }
                        </div>
                      )}
                      {bookingDetails?.nightAllowance > 0 && (
                        <div>
                          Night Charge : Rs.{" "}
                          {
                            bookingDetails?.nightAllowance
                          }
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div
                  className="line"
                  style={{ marginLeft: "10px", marginRight: "10px" }}
                ></div>
                <div
                  style={{ display: "flex", gap: "8px", marginRight: "-00px" }}
                >
                  <div style={{ color: "#38AF00" }}>Exclusion:</div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                    }}
                  >
                    <div className="cont1-TD">
                      <div>
                        Extra Km @ Rs.
                        {bookingDetails?.tariff?.fareDetails?.extraKmCharge}
                        /Km
                      </div>
                      <div>
                        Extra Hrs @ Rs.
                        {bookingDetails?.tariff?.fareDetails?.extraHrsCharge}
                        /Km
                      </div>
                      {bookingDetails?.extraDriverAllowance > 0 && (
                        <div>
                          Extra Driver Charge : Rs.{" "}
                          {bookingDetails?.extraDriverAllowance}/day
                        </div>
                      )}
                    </div>
                    <div className="cont1-TD" style={{ marginTop: "20px" }}>
                      <div>
                        GST on Extras @
                        {bookingDetails?.tariff?.fareDetails?.gstRate}%
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}

          {/* Local End */}

          {/* Airport Start  */}

          {bookingDetails?.tariff?.tripType === "Airport" ? (
            <div className="fare-TD">
              <div className="task">
                <div
                  className="icon-booking"
                  style={{
                    display: "flex",
                  }}
                >
                  <img src={FareDetails} alt="FareDetails" className="icon_2" />
                  <div
                    className="fare1"
                    style={{ transform: "translateX(-10px)" }}
                  >
                    Fare Details:
                  </div>
                  <div
                    className="estimated-1"
                    style={{ transform: "translateX(-50px)" }}
                  >
                    <h7 style={{ color: "#6D6D6D" }}>
                      Estimated Fare:
                      <span style={{ fontWeight: "600" }}>
                        Rs. {bookingDetails?.estimatedFare}
                      </span>
                    </h7>
                  </div>

                  {/* popup start */}
                  <div className="breakup-1">
                    <h8 onClick={handleClickopen}>Fare Breakup</h8>{" "}
                    <div>
                      <div>
                        {popup ? (
                          <Airport_Trip_Type_Popup
                            bookingDetails={bookingDetails}
                            closepopup={closepopup}
                          />
                        ) : (
                          ""
                        )}
                      </div>
                      {/* popup close */}
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="Line-bookin"
                style={{
                  width: "full",
                  height: "1px",
                  backgroundColor: "#E6F5E0",
                  margin: "0px 6px",
                  border: "1px solid #E6F5E0",
                }}
              ></div>

              <div
                style={{
                  width: "full",
                  height: "30px",
                  fontSize: "12px",
                  display: "flex",

                  placeItems: "center",
                  padding: "0px 15px",
                  marginTop: "5px",
                  color: "#6D6D6D",
                  fontSize: "12px",
                  fontWeight: "600",
                }}
              >
                <div style={{ display: "flex", gap: "8px" }}>
                  <div style={{ color: "#38AF00" }}>Inclusion:</div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "20px",
                    }}
                  >
                    <div className="cont1-TD">
                      {bookingDetails?.tariff?.fareDetails?.minKm > 0 && (
                        <div>
                          KM Include :{" "}
                          {bookingDetails?.tariff?.fareDetails?.minKm} km
                        </div>
                      )}
                      {bookingDetails?.tariff?.fareDetails?.minHrs > 0 && (
                        <div>
                          Hrs Include :{" "}
                          {bookingDetails?.tariff?.fareDetails?.minHrs} hrs
                        </div>
                      )}
                      <div>
                        GST @ {bookingDetails?.tariff?.fareDetails?.gstRate}% 
                      </div>
                    </div>

                    <div className="cont1-TD" style={{ marginTop: "15px" }}>
                      {bookingDetails?.morningAllowance > 0 && (
                        <div>
                          Morning Charge : Rs.{" "}
                          {
                            bookingDetails?.morningAllowance
                          }
                        </div>
                      )}
                      {bookingDetails?.nightAllowance > 0 && (
                        <div>
                          Night Charge : Rs.{" "}
                          {
                            bookingDetails?.nightAllowance
                          }
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div
                  className="line"
                  style={{ marginLeft: "10px", marginRight: "10px" }}
                ></div>
                <div
                  style={{ display: "flex", gap: "8px", marginRight: "-00px" }}
                >
                  <div style={{ color: "#38AF00" }}>Exclusion:</div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                    }}
                  >
                    <div className="cont1-TD">
                      <div>
                        Extra Km @ Rs.
                        {bookingDetails?.tariff?.fareDetails?.extraKmCharge}
                        /Km
                      </div>
                      <div>
                        Extra Hrs @ Rs.
                        {bookingDetails?.tariff?.fareDetails?.extraHrsCharge}
                        /Km
                      </div>
                    </div>
                    <div className="cont1-TD" style={{ marginTop: "20px" }}>
                      <div>
                        GST on Extras @
                        {bookingDetails?.tariff?.fareDetails?.gstRate}%
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}

          {/* Airport End */}

          <div className="Assign-TD">
            <div className="task">
              <div className="icon-booking">
                <img src={Assignto} alt="Assignto" className="icon_3" />
                Assign to:<span>Details</span>
                {bookingDetails?.driver?.firstName && (
                  <button
                    className="detach-cab-driver"
                    onClick={handleOpenModal}
                  >
                    Detach Driver & Cab
                  </button>
                )}
              </div>

              {isModalOpen && (
                <div className="modal-overlay">
                  <div className="modal">
                    <div className="modal-title">
                      Why do you want to detach the cab and driver from this
                      ride?
                    </div>
                    <form className="checkbox-form">
                      <div className="checkbox-container">
                        <div className="checkbox-column">
                          <div className="checkbox-item">
                            <input
                              type="checkbox"
                              id="reason1"
                              name="reason"
                              className="checkbox-text"
                              value="Ride was Assigned Incorrectly"
                              onChange={handleCheckboxChange}
                              disabled={
                                isMaxCheckboxSelected &&
                                !selectedReasons.includes(
                                  "Ride was Assigned Incorrectly"
                                )
                              }
                            />
                            <label
                              htmlFor="reason1"
                              className="checkbox-modal-text"
                            >
                              <div className="checkbox-modal-text">
                                Ride was Assigned Incorrectly
                              </div>
                            </label>
                          </div>
                          <div className="checkbox-item">
                            <input
                              type="checkbox"
                              id="reason2"
                              name="reason"
                              value="Driver Denied the Ride"
                              onChange={handleCheckboxChange}
                              disabled={
                                isMaxCheckboxSelected &&
                                !selectedReasons.includes(
                                  "Driver Denied the Ride"
                                )
                              }
                            />
                            <label htmlFor="reason2">
                              <div className="checkbox-modal-text">
                                Driver Denied the Ride
                              </div>
                            </label>
                          </div>
                          <div className="checkbox-item">
                            <input
                              type="checkbox"
                              id="reason3"
                              name="reason"
                              value="Driver is Unavailable due to Emergency"
                              onChange={handleCheckboxChange}
                              disabled={
                                isMaxCheckboxSelected &&
                                !selectedReasons.includes(
                                  "Driver is Unavailable due to Emergency"
                                )
                              }
                            />
                            <label htmlFor="reason3">
                              <div className="checkbox-modal-text">
                                Driver is Unavailable due to Emergency
                              </div>
                            </label>
                          </div>
                          <div className="checkbox-item">
                            <input
                              type="checkbox"
                              id="reason4"
                              name="reason"
                              value="Driver is no Longer Employed by the Company"
                              onChange={handleCheckboxChange}
                              disabled={
                                isMaxCheckboxSelected &&
                                !selectedReasons.includes(
                                  "Driver is no Longer Employed by the Company"
                                )
                              }
                            />
                            <label htmlFor="reason4">
                              <div className="checkbox-modal-text">
                                Driver is no Longer Employed by the Company
                              </div>
                            </label>
                          </div>
                          <div className="checkbox-item">
                            <input
                              type="checkbox"
                              id="reason5"
                              name="reason"
                              value="Driver is Unavailable due to Illness"
                              onChange={handleCheckboxChange}
                              disabled={
                                isMaxCheckboxSelected &&
                                !selectedReasons.includes(
                                  "Driver is Unavailable due to Illness"
                                )
                              }
                            />
                            <label htmlFor="reason5">
                              <div className="checkbox-modal-text">
                                Driver is Unavailable due to Illness
                              </div>
                            </label>
                          </div>
                        </div>
                        <div className="checkbox-column">
                          <div className="checkbox-item">
                            <input
                              type="checkbox"
                              id="reason6"
                              name="reason"
                              value="Cab is Unavailable due to Accident"
                              onChange={handleCheckboxChange}
                              disabled={
                                isMaxCheckboxSelected &&
                                !selectedReasons.includes(
                                  "Cab is Unavailable due to Accident"
                                )
                              }
                            />
                            <label htmlFor="reason6">
                              <div className="checkbox-modal-text">
                                Cab is Unavailable due to Accident
                              </div>
                            </label>
                          </div>
                          <div className="checkbox-item">
                            <input
                              type="checkbox"
                              id="reason7"
                              name="reason"
                              value="Cab is Unavailable due to Maintenance"
                              onChange={handleCheckboxChange}
                              disabled={
                                isMaxCheckboxSelected &&
                                !selectedReasons.includes(
                                  "Cab is Unavailable due to Maintenance"
                                )
                              }
                            />
                            <label htmlFor="reason7">
                              <div className="checkbox-modal-text">
                                Cab is Unavailable due to Maintenance
                              </div>
                            </label>
                          </div>
                          <div className="checkbox-item">
                            <input
                              type="checkbox"
                              id="reason8"
                              name="reason"
                              value="Customer Requested a Different Driver and Cab"
                              onChange={handleCheckboxChange}
                              disabled={
                                isMaxCheckboxSelected &&
                                !selectedReasons.includes(
                                  "Customer Requested a Different Driver and Cab"
                                )
                              }
                            />
                            <label htmlFor="reason8">
                              <div className="checkbox-modal-text">
                                Customer Requested a Different Driver and Cab
                              </div>
                            </label>
                          </div>
                          <div className="checkbox-item">
                            <input
                              type="checkbox"
                              id="reason9"
                              name="reason"
                              value="Other Operational Issues"
                              onChange={handleCheckboxChange}
                              disabled={
                                isMaxCheckboxSelected &&
                                !selectedReasons.includes(
                                  "Other Operational Issues"
                                )
                              }
                            />
                            <label htmlFor="reason9">
                              <div className="checkbox-modal-text">
                                Other Operational Issues
                              </div>
                            </label>
                          </div>
                        </div>
                      </div>
                    </form>
                    <div className="modal-buttons">
                      <button
                        className="confirm-button"
                        onClick={handleConfirmDetach}
                        disabled={selectedReasons.length === 0}
                      >
                        Detach
                      </button>
                      <button
                        className="cancel-button-modal"
                        onClick={handleCloseModal}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="Line-booking" style={{marginBottom:"5px"}}></div>
            <div className="formb2" style={{ maxWidth: "1035px" }}>
              <div
                style={{
                  // transform: "translateX(-27px)",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems:"center",
                  alignContent:"center",
                  width: "full",
                  padding: "0px 20px",
                  paddingLeft: "10px",
                  color: "#6D6D6D",
                  fontSize: "12px",
                  fontWeight: "600",
                }}
              >
                <div>
                  Drive Name :{" "}
                  <h7 style={{ fontWeight: "400" }}>
                    {bookingDetails?.driver?.firstName}{" "}
                    {bookingDetails?.driver?.lastName || "Not Assigned"}
                  </h7>
                </div>
                <div>
                  Mobile no :{" "}
                  <h7 style={{ fontWeight: "400" }}>
                    {bookingDetails?.driver?.phone || "Not Assigned"}
                  </h7>
                </div>
                <div>
                  Cab Model :{" "}
                  <h7 style={{ fontWeight: "400" }}>
                    {bookingDetails?.car?.carModel || "Not Assigned"}
                  </h7>
                  {/* {cabData ? cabData["Cab Model"] : "Not Assigned"} */}
                </div>
                <div>
                  Cab colour :{" "}
                  <h7 style={{ fontWeight: "400" }}>
                    {bookingDetails?.car?.carColor || "Not Assigned"}
                  </h7>
                </div>
                <div>
                  Cab No :{" "}
                  <h7 style={{ fontWeight: "400" }}>
                    {bookingDetails?.car?.registrationCertificate.number ||
                      "Not Assigned"}
                  </h7>
                </div>
              </div>
            </div>
          </div>

          <div className="comments-TD">
            <div style={{ padding: "10px" }}>
              <div
                style={{
                  fontSize: "8px",
                  fontWeight: "300",
                  paddingLeft: "9px",
                }}
              >
                <h1
                  style={{
                    color: "#38AF00",
                    fontSize: "10px",
                    fontWeight: "600",
                  }}
                >
                  Comments From Customer
                </h1>
              </div>
              <div
                style={{
                  backgroundColor: "#E6F5E0",
                  height: "2px",
                  marginTop: "5px",
                }}
              ></div>
              <div style={{ paddingLeft: "9px" }}>
                <h7
                  style={{
                    color: "#6D6D6D",
                    fontSize: "10px",
                    fontWeight: "600",
                  }}
                >
                  {bookingDetails?.comment || "Need Sedan With Luggage Carrier"}
                </h7>
              </div>
            </div>
          </div>
          <Interaction />
        </div>
      )}
    </div>
  );
};

export default TaskDetails;
