import React, { useState, useEffect } from "react";
import ViewCouponCode from "./ViewCouponCode";
import AddCouponCode from "./AddCouponCode";
import "./Coupons.css";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllCouponsAsync, selectAllcoupons } from "./couponSlice";
import { Link } from "react-router-dom";
import { FaLongArrowAltRight } from "react-icons/fa";
import Filter from "../Booking/Filter";
import InputColumn from "../Booking/Input";
import DropdownColumn from "../Booking/Dropdown";

const SearchBar = ({ title, searchQuery, handleSearch }) => {
  return (
    <div className="coupon-search-column">
      <div className="title">
        {title}
        <span className="search-icon"></span>
      </div>
      <input
        type="text"
        value={searchQuery}
        onChange={(e) => handleSearch(e.target.value)}
        className="input"
        placeholder={`Search by ${title} code`}
      />
    </div>
  );
};

const Coupons = () => {
  const [couponButtontype, setCouponsButtonType] = useState("View Coupon Code");
  const [selectedTripType, setSelectedTripType] = useState("");
  const [selectedSubTripType, setSelectedSubTripType] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const dispatch = useDispatch();

  const handleCouponsButtonClick = (buttonType) => {
    setCouponsButtonType(buttonType);
  };

  const tripOptions = [
    { label: "All", value: "" },
    { label: "Local", value: "local" },
    { label: "Outstation", value: "outstation" },
    { label: "Airport", value: "airport" },
  ];

  const subTripOptions = {
    local: [
      { label: "3hr", value: "3hr" },
      { label: "4hr", value: "4hr" },
    ],
    outstation: [
      { label: "One Way", value: "oneWay" },
      { label: "Round Trip", value: "roundTrip" },
    ],
    airport: [
      { label: "select", value: "" },
      { label: "From the Airport", value: "From the Airport" },
      { label: "To the Airport", value: "To the Airport" },
    ],
  };

  const coupons = useSelector(selectAllcoupons);

  const filteredCoupons = coupons.filter((coupon) => {
    return (
      coupon.promoCode.toLowerCase().includes(searchQuery.toLowerCase()) &&
      (selectedTripType === "" ||
        coupon.tripType.toLowerCase() === selectedTripType.toLowerCase()) &&
      (selectedSubTripType === "" ||
        coupon.subTripType.toLowerCase() === selectedSubTripType.toLowerCase())
    );
  });

  useEffect(() => {
    dispatch(fetchAllCouponsAsync());
  }, []);

  return (
    <div className="coupon-container">
      <div className="coupon-head-main">
        <div className="coupon-head-left">
          <div className="coupon-heading">Coupons</div>
          <FaLongArrowAltRight />
          <div className="selected-trip-type">{couponButtontype}</div>
        </div>
        {couponButtontype !== "Add Coupon Code" && (
          <div className="coupon-type-row">
            <div className="dropdown-container">
              <Filter />
              <InputColumn
                title="Coupon Code"
                selectedValue={searchQuery}
                handleChange={(e) => setSearchQuery(e.target.value)}
              />
              <DropdownColumn
                title="Trip Type"
                options={tripOptions}
                selectedValue={selectedTripType}
                handleChange={(e) => setSelectedTripType(e.target.value)}
              />
              <DropdownColumn
                title="Sub Trip Type"
                options={subTripOptions[selectedTripType] || []}
                selectedValue={selectedSubTripType}
                handleChange={(e) => setSelectedSubTripType(e.target.value)}
              />
            </div>
          </div>
        )}

        <div className="btns">
          <button
            onClick={() => handleCouponsButtonClick("View Coupon Code")}
            className={
              couponButtontype === "View Coupon Code"
                ? "active-button-coupon"
                : "inactive-button-coupon"
            }
          >
            View Coupon Code
          </button>
          <button
            onClick={() => handleCouponsButtonClick("Add Coupon Code")}
            className={
              couponButtontype === "Add Coupon Code"
                ? "active-button-coupon"
                : "inactive-button-coupon"
            }
          >
            Add Coupon Code
          </button>
        </div>
      </div>

      <div className="table">
        {couponButtontype === "View Coupon Code" && (
          <ViewCouponCode filteredCoupons={filteredCoupons} />
        )}
        {couponButtontype === "Add Coupon Code" && <AddCouponCode />}
      </div>
    </div>
  );
};

export default Coupons;
