import React from "react";
import { RxCross2 } from "react-icons/rx";
import "./TripTypePopups.css";

const Local_Trip_Type_Popup = ({ bookingDetails, closepopup }) => {
  return (
    <div className="local-popup-main-container">
      <div className="local-popup-main-booking">
        <div className="type-local-popup">
          <div className="summary">Fare Summary</div>

          <div className="type-local-popup-header">
            <div className="local-f">Fare Breakup :</div>
            <h5
              onClick={closepopup}
              style={{
                fontSize: "1.8rem",
                cursor: "pointer",
                height: "0px",
                marginTop: "-30px",
              }}
            >
              <RxCross2 />
            </h5>
          </div>
          <div className="local-popup-text">
            <div className="local-fare-amount">
              <div className="local-each-amount">
                <span className="left-span  left-span-local">Include Km :</span>
                <span className="right-span left-span-local">
                  {bookingDetails.tariff.fareDetails.minKm} Kms**
                </span>
              </div>
              <div className="local-each-amount">
                <span className="left-span">Include Hrs :</span>
                <span className="right-span">
                  {bookingDetails.tariff.fareDetails.minHrs} hrs**
                </span>
              </div>
              <div className="local-each-amount">
                <span className="left-span">Base Fare :</span>
                <span className="right-span">
                  {bookingDetails.tariff.fareDetails.baseFare} INR
                </span>
              </div>

              {/* Conditionally render Morning Charge */}
              {bookingDetails?.morningAllowance > 0 && (
                <div className="local-each-amount">
                  <span className="left-span">Morning Charge:</span>
                  <span className="right-span">
                    Rs. {bookingDetails?.morningAllowance}/-
                  </span>
                </div>
              )}

              {/* Conditionally render Night Charge */}
              {bookingDetails?.nightAllowance> 0 && (
                <div className="local-each-amount">
                  <span className="left-span">Night Charge:</span>
                  <span className="right-span">
                    Rs. {bookingDetails?.nightAllowance}/-
                  </span>
                </div>
              )}

              <div className="local-each-amount">
                <span className="left-span">
                  GST {bookingDetails.tariff.fareDetails.gstRate}{" "}
                </span>
                <span className="right-span">
                  Rs.
                  {bookingDetails.gstOnEstimatedFare}
                  /-
                </span>
              </div>
              <div className="local-each-amount">
                <span className="left-span">
                  <b>Total:</b>
                </span>
                <span className="right-span">
                  <b>
                    Rs.
                    {bookingDetails.estimatedFare}
                  </b>
                </span>
              </div>
            </div>
            <br />

            <div className="local-total-text">
              Extra Charges: If you will use the car/cab for more than{" "}
              {bookingDetails.tariff.fareDetails.minKm} Kms for oneway, extra charges as follows:
            </div>
            <div className="local-extra-charges">
              <div className="local-each-extra-charge">
                <span className="left-span">
                  Extra charges after {bookingDetails.tariff.fareDetails.minKm} Kms:
                </span>
                <span className="right-span">
                  Rs.
                  {bookingDetails.tariff.fareDetails.extraKmCharge}
                  /km
                </span>
              </div>
              <div className="local-each-extra-charge">
                <span className="left-span">
                  Extra charges after {bookingDetails.tariff.fareDetails.minHrs} hrs:
                </span>
                <span className="right-span">
                  Rs.
                  {bookingDetails.tariff.fareDetails.extraHrsCharge}
                  /hr
                </span>
              </div>
              <div className="local-each-extra-charge">
                <span className="left-span">Night Charge:</span>
                <span className="right-span">
                  Rs.
                  {bookingDetails.tariff.fareDetails.nightDriverAllowance}
                  /day
                </span>
              </div>
            </div>

            <div className="local-instruction">
              <div className="local-Tc">
                **T&c apply :-
                <div className="local-a-i">
                  1. Your Trip has a KM limit as well as an Hours limit. If your
                  usage exceeds these limits, you will be charged for the excess
                  KM and/or hours used.<br></br>
                  2. The KM and Hour(s) usage will be calculated starting from
                  your pick-up point and back to the pick-up point.<br></br>
                  3. All road toll fees, parking charges, state taxes, etc., if
                  applicable, will be charged extra and need to be paid to the
                  concerned authorities as per actuals.
                  <br></br>
                  4. The Airport entry charge, if applicable, is not included in
                  the fare and will be charged extra. <br></br>
                  5. For driving between 09:00 PM to 06:00 AM on any of the
                  nights, a Driver Night Allowance will be applicable and is to
                  be paid to the driver.
                  <br></br>
                  <br></br>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Local_Trip_Type_Popup;
