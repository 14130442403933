import React, { useEffect, useState } from "react";
import "./AddNewCity.css";
import toast, { Toaster } from "react-hot-toast";
import axios from "axios";
import Loader from "../Loader";
import { useSelector, useDispatch } from "react-redux";
import { editCurrentCity,resetCompState} from "./geographySlice";
import { useParams, Link, useNavigate } from "react-router-dom";
import { FaLongArrowAltRight } from "react-icons/fa";

const ModifyLocation = () => {
  const [states, setStates] = useState("");
  const [cities, setCities] = useState("");
  const [payToDriver, setPayToDriver] = useState("");
  const {editcity} = useSelector((state)=>state.geography)
  const {id,component} = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const SourcehandleCheckbox = (e) => {
    const { value, checked } = e.target;
    console.log(value);
    setPayToDriver(checked ? value : "");
  };

  const handleToggleComponent = (value) => {
    dispatch(resetCompState(value));
    navigate("/geography");
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    const headers = {
      "Content-Type": "application/json",
    };

    const dataObj = new FormData();
    dataObj.append("state", states);
    dataObj.append("city", cities);

    let payToDriverValue = "";
    if (payToDriver === "Enable") {
      payToDriverValue = "true";
    }else{
        payToDriverValue = 'false'
    }

    dataObj.append("zeroPaymentBooking", payToDriverValue);
    console.log("Data Entered");
    for (const pair of dataObj.entries()) {
      console.log(pair[0] + ": " + pair[1]);
    }

    console.log("Data Entered",dataObj);

    try {
      await axios.put(`https://api.yatricabs.com/v1/admin/locations/${id}`, dataObj, {
        headers: headers,
      });
    
      toast.success("City Updated successfully!");
      dispatch(editCurrentCity([cities,states,payToDriver==='Enable'?'Enabled':'Disabled']))
      setTimeout(() => {
        window.location.reload();
      }, 3000);
      
      e.target.reset();
    } catch (error) {
      toast.error(error.response.data.message);
      console.error(error.response.data.message);
    }
  };
  
  useEffect(()=>{
    if(editcity){
        console.log(editcity);
        setCities(editcity[0]);
        setStates(editcity[1]);
        if(editcity[2]==='Enabled'){
            setPayToDriver('Enable')
        }else{
            setPayToDriver('Disable')
        }
    }
  },[])

  return (
    <>
   
    {
        editcity ?
        <>
           <Toaster />
      <form onSubmit={handleSubmit}>
      <div className="nav-content" style={{ marginTop: '25px' }}>
            <div className="header-text">
              <Link className="h3-booking">Geography</Link>
              <FaLongArrowAltRight />
              {""}
              <Link className="h3-booking">{component==="view-all-city" ? 'View All City': 'Active City'}</Link>
              <FaLongArrowAltRight />
              <Link className="h2-booking">Edit Details</Link>
            </div>
            {component === "view-all-city" && (
              <button
                className="createButton"
                onClick={() => handleToggleComponent("View All City")}
              >
                View All City
              </button>
            )}

            {component === "active-city" && (
              <button
                className="createButton"
                onClick={() => {
                  handleToggleComponent("Active City");
                }}
              >
               Active City
              </button>
            )}
          </div>
        <div className="add-new-city-container">
          <div className="price-details">
            <div className="create-new-sub-head">Update Location</div>
            <div className="customer-details">
              <div className="row1">
                <div className="col">
                  <div className="col-item">
                    <div className="dropdown-column">
                      <div className="title">State</div>
                      <input
                        type="text"
                        onChange={(e) => setStates(e.target.value)}
                        placeholder="Type State Name Here"
                        className="input"
                        value={states}
                      />
                    </div>
                  </div>
                  <div className="dropdown-column">
                    <div className="title">Pay To Driver</div>
                    <input
                      type="checkbox"
                      id="enableCheckbox"
                      name="payToDriver"
                      value="Enable"
                      checked={payToDriver === "Enable"}
                      onChange={SourcehandleCheckbox}
                    />
                    <label htmlFor="hindiCheckbox">Enable</label>
                    <input
                      type="checkbox"
                      id="englishCheckbox"
                      name="payToDriver"
                      value="Disable"
                      checked={payToDriver === "Disable"}
                      onChange={SourcehandleCheckbox}
                    />
                    <label htmlFor="englishCheckbox">Disable</label>
                  </div>
                </div>
                <div className="col">
                  <div className="col-item">
                    <div className="dropdown-column">
                      <div className="title">City</div>
                      <input
                        type="text"
                        onChange={(e) => setCities(e.target.value)}
                        placeholder="Type City Name Here"
                        className="input"
                        value={cities}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="add">
            <button className="add-btn">Update</button>
          </div>
        </div>
      </form>
        </>:
        <Loader/>
    }
     
    </>
  );
};

export default ModifyLocation;
