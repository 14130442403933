import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  driverData: null,
  allDrivers: {
    data: null,
    loading: "idle",
    error: null,
  },
  bookingid: null,
};

export const fetchDriverData1 = createAsyncThunk(
  "assignDriverData/fetchDriverData",
  async (bookingId) => {
    const response = await axios.get(
      `https://api.yatricabs.com/v1/admin/bookings/drivers?bookingId=${bookingId}`
    );
    console.log("api driver response", response.data);
    return response.data;
  }
);

const fetchDriverDataSlice = createSlice({
  name: "assignDriverData",
  initialState,
  reducers: {
    assignDriver: (state, action) => {
      state.driverData = action.payload;
    },
    savebookingid: (state, action) => {
      state.bookingid = action.payload;
    },
    updateDriverStatus: (state, action) => {
      const { driverId, status } = action.payload;
      const driver = state.allDrivers.data.data.find(driver => driver._id === driverId);
      if (driver) {
        driver.available = status;
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDriverData1.pending, (state) => {
        state.allDrivers.loading = "loading";
      })
      .addCase(fetchDriverData1.fulfilled, (state, action) => {
        state.allDrivers.loading = "succeeded";
        state.allDrivers.data = action.payload;
      })
      .addCase(fetchDriverData1.rejected, (state, action) => {
        state.allDrivers.data = null;
        state.allDrivers.loading = "failed";
        state.allDrivers.error = action.error.message;
      });
  },
});

export const { assignDriver, savebookingid, updateDriverStatus } = fetchDriverDataSlice.actions;
export default fetchDriverDataSlice.reducer;
