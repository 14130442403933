import React, { useState, useEffect } from "react";
import "./BookingHistory.css";
import AddNewCar from "./AddNewCar";
import axios from "axios";
//import AddNewCar from "./components/DriverManagement/BookingHistory";
//import AddNewCar from "./AddNewCar";
const AddNewDriver = () => {
  const [profilePhoto, setProfilePhoto] = useState(null);
  const [panCard, setPanCard] = useState(null);
  const [aadharCardFront, setAadharCardFront] = useState(null);
  const [aadharCardBack, setAadharCardBack] = useState(null);
  const [drivingLicenseFront, setDrivingLicenseFront] = useState(null);
  const [drivingLicenseBack, setDrivingLicenseBack] = useState(null);
  const [policeClearanceCertificate, setPoliceClearanceCertificate] =
    useState(null);

  const handleFileChange = (event, setFile) => {
    const file = event.target.files[0];

    if (file) {
      // Update the corresponding state with the selected file
      setFile(file);
    }
  };
  /*const [activeButton, setActiveButton] = useState(null);

  const handleButtonClick = (AddNewCar) => {
    setActiveButton(AddNewCar);
  };*/
  const [showAddCar, setShowAddCar] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [popupText, setPopupText] = useState("");

  const handleButtonClick = () => {
    setShowAddCar(true);
  };

  const handleReSubmitClick = () => {
    setShowPopup(true);
  };

  const handlePopupClose = () => {
    setShowPopup(false);
    setPopupText(""); // Clear the text field value when closing the popup
  };
  const handlePopupSubmit = () => {
    // Handle the submission logic here (e.g., send the text value to the server)
    // You can also add validation logic before submitting
    console.log("Submitted text:", popupText);

    // Close the popup after submission
    setShowPopup(false);
    setPopupText("");
  };

  

  return (

    <>
      {showAddCar ? (
        <AddNewCar />
      ) : (
        <>
          <div
            style={{
              backgroundColor: "#38B000",
              borderRadius: "2px",
              boxShadow: "0px 4px 22px 0px rgba(0, 0, 0, 0.17)",
              color: "#FFF",
              padding: "10px",
              fontFamily: "Lato",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: "700",
              lineHeight: "normal",
            }}
          >
            Driver Information
          </div>
          <div className="user-details">
            <div className="user-details-section">
              <div className="user-detail">
                <div className="user-heading">Location</div>
                <div className="user-info">Lucknow, Uttar Pradesh</div>
              </div>
              <div className="user-detail">
                <div className="user-heading">Phone Number</div>
                <div className="user-info">+91-8005510570</div>
              </div>
              <div className="user-detail">
                <div className="user-heading">Language Speak</div>
                <input
                  type="checkbox"
                  id="hindiCheckbox"
                  name="language"
                  value="Hindi"
                />
                <label htmlFor="hindiCheckbox">Hindi</label>
                <input
                  type="checkbox"
                  id="englishCheckbox"
                  name="language"
                  value="English"
                />
                <label htmlFor="englishCheckbox">English</label>
                <input
                  type="checkbox"
                  id="otherCheckbox"
                  name="language"
                  value="Other"
                />
                <label htmlFor="otherCheckbox">Other</label>
              </div>

              <div className="user-detail">
                <div className="user-heading">DL No.</div>
                <div className="user-info">UP3200AA258969857</div>
              </div>
              <div className="user-detail">
                <div className="user-heading">Permanent Address</div>
                <div className="user-info">
                  Radhika Vihar Rd, Sulabh Puram colony, Lohamandi, Lucknow, Uttar
                  Pradesh
                </div>
              </div>
            </div>
            <div className="user-details-section">
              <div className="user-detail">
                <div className="user-heading">First Name</div>
                <div className="user-info">Sankalp</div>
              </div>
              <div className="user-detail">
                <div className="user-heading">Alternate Phone No.</div>
                <div className="user-info">+91-987686451</div>
              </div>
              <div className="user-detail">
                <div className="user-heading">Create Password</div>
                <div className="user-info">Sankalp@1234</div>
              </div>
              <div className="user-detail">
                <div className="user-heading">Expiry Date</div>
                <div className="user-info">31/06/1987</div>
              </div>
              <div className="user-detail">
                <div className="user-heading">Current Address</div>
                <div className="user-info">
                  Radhika Vihar Rd, Sulabh Puram colony, Lohamandi, Lucknow, Uttar
                  Pradesh
                </div>
              </div>
            </div>
            <div className="user-details-section">
              <div className="user-detail">
                <div className="user-heading">Last Name</div>
                <div className="user-info">Jha</div>
              </div>
              <div className="user-detail">
                <div className="user-heading">DOB</div>
                <div className="user-info">31/06/1987</div>
              </div>
              <div className="user-detail">
                <div className="user-heading">Confirm Password</div>
                <div className="user-info">Sankalp@1234</div>
              </div>
              <div className="user-detail">
                <div className="user-heading">Pan No.</div>
                <div className="user-info">BGSTI7788N</div>
              </div>
              <div className="user-detail">
                <div className="user-heading">Adhar No.</div>
                <div className="user-info">123456789654</div>
              </div>
            </div>
          </div>
          <div
            style={{
              backgroundColor: "#38B000",
              borderRadius: "2px",
              boxShadow: "0px 4px 22px 0px rgba(0, 0, 0, 0.17)",
              color: "#FFF",
              padding: "10px",
              fontFamily: "Lato",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: "700",
              lineHeight: "normal",
            }}
          >
            Bank Details
          </div>
          <div className="user-details">
            <div className="user-details-section">
              <div className="user-detail">
                <div className="user-heading">Beneficiary name</div>
                <div className="user-info">Sankalp jha</div>
              </div>
            </div>
            <div className="user-details-section">
              <div className="user-detail">
                <div className="user-heading">Account No.</div>
                <div className="user-info">000012547896</div>
              </div>
            </div>
            <div className="user-details-section">
              <div className="user-detail">
                <div className="user-heading">IFSCCODE</div>
                <div className="user-info">SBIN0987256</div>
              </div>
            </div>
          </div>

          <div
            style={{
              backgroundColor: "#38B000",
              borderRadius: "2px",
              boxShadow: "0px 4px 22px 0px rgba(0, 0, 0, 0.17)",
              color: "#FFF",
              padding: "10px",
              fontFamily: "Lato",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: "700",
              lineHeight: "normal",
            }}
          >
            Price Quote
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: 270,
                height: 110,
                background: "#E6F5E0",
                borderRadius: 10,
                border: "1px #38B000 solid",
                margin: "25px 15px",
              }}
            >
              <p
                style={{
                  textAlign: "center",
                  padding: 10,
                  fontFamily: "Inter",
                  fontSize: 15,
                  fontWeight: 600,
                }}
              >
                Outstation
              </p>
              <div>
                <div style={{ display: "flex", justifyContent: "space-around" }}>
                  <p className="para-text">Rate/km</p>
                  <p className="para-text">Rate/km</p>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontFamily: "Inter",
                      width: 80,
                      fontSize: 14,
                      height: 31,
                      background: "white",
                      boxShadow: "0px 4px 22px rgba(0, 0, 0, 0.17)",
                      borderRadius: 6,
                    }}
                  >
                    Rs. 1500
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      alignItems: "center",
                      fontFamily: "Inter",
                      fontSize: 14,
                      paddingRight: 5,
                      paddingLeft: 5,
                      height: 31,
                      background: "white",
                      boxShadow: "0px 4px 22px rgba(0, 0, 0, 0.17)",
                      borderRadius: 6,
                    }}
                  >
                    Rs. 1500 km/day
                  </div>
                </div>
              </div>
            </div>

            <div
              style={{
                width: 400,
                height: 110,
                background: "#E6F5E0",
                borderRadius: 10,
                border: "1px #38B000 solid",
                margin: "10px 15px",
              }}
            >
              <p
                style={{
                  textAlign: "center",
                  padding: 10,
                  fontFamily: "Inter",
                  fontSize: 15,
                  fontWeight: 600,
                }}
              >
                Local
              </p>
              <div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                    alignItems: "center",
                  }}
                >
                  <p className="para-text">8hrs/80km</p>
                  <p className="para-text">12hrs/120km</p>
                  <p className="para-text">Extra Km</p>
                  <p className="para-text">Extra Hours</p>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                    alignItems: "center",
                  }}
                >
                  <div className="detail-sec">Rs. 150</div>

                  <div className="detail-sec">Rs. 150</div>

                  <div className="detail-sec">Rs. 150</div>

                  <div className="detail-sec">Rs. 150</div>
                </div>
              </div>
            </div>

            <div
              style={{
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
                flexDirection: "column",
                width: 264,
                height: 110,
                background: "#E6F5E0",
                borderRadius: 10,
                border: "1px #38B000 solid",
                margin: "10px 15px",
                gap: "1rem",
              }}
            >
              <p
                style={{
                  textAlign: "center",
                  padding: 10,
                  fontFamily: "Inter",
                  fontSize: 15,
                  fontWeight: 600,
                }}
              >
                Night Charge
              </p>
              <div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      alignItems: "center",
                      fontFamily: "Inter",
                      width: 100,
                      fontSize: 14,
                      paddingRight: 5,
                      height: 31,
                      background: "white",
                      boxShadow: "0px 4px 22px rgba(0, 0, 0, 0.17)",
                      borderRadius: 6,
                    }}
                  >
                    Rs. 1800 /day
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              backgroundColor: "#38B000",
              borderRadius: "2px",
              boxShadow: "0px 4px 22px 0px rgba(0, 0, 0, 0.17)",
              color: "#FFF",
              padding: "10px",
              fontFamily: "Lato",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: "700",
              lineHeight: "normal",
            }}
          >
            Upload Documents
          </div>
          <div className="user-details">
            <div className="user-details-section">
              <div className="user-detail">
                <div className="user-headin">Profile Photo</div>
                <div className="dropdown">
                  <div className="pic">
                    {profilePhoto && (
                      <img
                        className="pic-selected"
                        src={URL.createObjectURL(profilePhoto)}
                        alt="Selected"
                      />
                    )}
                  </div>

                  {/*<label className="upload">
                <input
                  type="file"
                  onChange={(e) => handleFileChange(e, setProfilePhoto)}
                />
                {/*<span>Choose File</span>
              </label>
              {profilePhoto && (
                <p className="selected">Selected File: {profilePhoto.name}</p>
              )}*/}
                </div>
              </div>
              <div className="user-detail">
                <div className="user-headin">Pan Card</div>
                <div className="dropdown">
                  <div className="pic">
                    {panCard && (
                      <img
                        className="pic-selected"
                        src={URL.createObjectURL(panCard)}
                        alt="Selected"
                      />
                    )}
                  </div>
                  {/*<label className="upload">
                <input
                  type="file"
                  onChange={(e) => handleFileChange(e, setPanCard)}
                />
                <span>Choose File</span>
              </label>
              {panCard && (
                <p className="selected">Selected File: {panCard.name}</p>
              )}*/}
                </div>
              </div>
            </div>
            <div className="user-details-section">
              <div className="user-detail">
                <div className="user-headin">Aadhar Card Front</div>
                <div className="dropdown">
                  <div className="pic">
                    {aadharCardFront && (
                      <img
                        className="pic-selected"
                        src={URL.createObjectURL(aadharCardFront)}
                        alt="Selected"
                      />
                    )}
                  </div>
                  {/*<label className="upload">
                <input
                  type="file"
                  onChange={(e) => handleFileChange(e, setAadharCardFront)}
                />
                <span>Choose File</span>
              </label>
              {aadharCardFront && (
                <p className="selected">
                  Selected File: {aadharCardFront.name}
                </p>
              )}*/}
                </div>
              </div>
              <div className="user-detail">
                <div className="user-headin">Aadhar Card Back</div>
                <div className="dropdown">
                  <div className="pic">
                    {aadharCardBack && (
                      <img
                        className="pic-selected"
                        src={URL.createObjectURL(aadharCardBack)}
                        alt="Selected"
                      />
                    )}
                  </div>
                  {/*<label className="upload">
                <input
                  type="file"
                  onChange={(e) => handleFileChange(e, setAadharCardBack)}
                />
                <span>Choose File</span>
              </label>
              {aadharCardBack && (
                <p className="selected">Selected File: {aadharCardBack.name}</p>
              )}*/}
                </div>
              </div>
            </div>
            <div className="user-details-section">
              <div className="user-detail">
                <div className="user-headin">Driving License Front</div>
                <div className="dropdown">
                  <div className="pic">
                    {drivingLicenseFront && (
                      <img
                        className="pic-selected"
                        src={URL.createObjectURL(drivingLicenseFront)}
                        alt="Selected"
                      />
                    )}
                  </div>
                  {/*<label className="upload">
                <input
                  type="file"
                  onChange={(e) => handleFileChange(e, setDrivingLicenseFront)}
                />
                <span>Choose File</span>
              </label>
              {drivingLicenseFront && (
                <p className="selected">
                  Selected File: {drivingLicenseFront.name}
                </p>
              )}*/}
                </div>
              </div>
              <div className="user-detail">
                <div className="user-headin">Driving License Back</div>
                <div className="dropdown">
                  <div className="pic">
                    {drivingLicenseBack && (
                      <img
                        className="pic-selected"
                        src={URL.createObjectURL(drivingLicenseBack)}
                        alt="Selected"
                      />
                    )}
                  </div>
                  {/*<label className="upload">
                <input
                  type="file"
                  onChange={(e) => handleFileChange(e, setDrivingLicenseBack)}
                />
                <span>Choose File</span>
              </label>
              {drivingLicenseBack && (
                <p className="selected">
                  Selected File: {drivingLicenseBack.name}
                </p>
              )}*/}
                </div>
              </div>
            </div>
            <div className="user-details-section">
              <div className="user-detail">
                <div className="user-headin">Police clearance certificate</div>
                <div className="dropdown">
                  <div className="pic">
                    {policeClearanceCertificate && (
                      <img
                        className="pic-selected"
                        src={URL.createObjectURL(policeClearanceCertificate)}
                        alt="Selected"
                      />
                    )}
                  </div>
                  {/*<label className="upload">
                <input
                  type="file"
                  onChange={(e) =>
                    handleFileChange(e, setPoliceClearanceCertificate)
                  }
                />
                <span>Choose File</span>
              </label>
              {policeClearanceCertificate && (
                <p className="selected">
                  Selected File: {policeClearanceCertificate.name}
                </p>
              )}*/}
                </div>
              </div>
            </div>
          </div>

          <div style={{ display: "flex", justifyContent: "center" }}>
            <div className="add-car-driver">
              <button
                onClick={() => handleButtonClick("AddNewCar")}
                style={{
                  borderRadius: "5px",
                  margin: "10px",
                  border: "0.5px solid #38B000",
                  background: "#38B000",
                  width: "150px",
                  padding: "6px",
                  color: "#FFF",
                  fontSize: "15px",
                }}
              >
                Modify
              </button>
              <button
                style={{
                  borderRadius: "5px",
                  margin: "10px",
                  border: "0.5px solid #38B000",
                  background: "#38B000",
                  width: "150px",
                  padding: "6px",
                  color: "#FFF",
                  fontSize: "15px",
                }}
              >
                Approve
              </button>
              <button
                onClick={handleReSubmitClick}
                style={{
                  borderRadius: "5px",
                  margin: "10px",
                  border: "0.5px solid #38B000",
                  background: "#38B000",
                  width: "150px",
                  padding: "6px",
                  color: "#FFF",
                  fontSize: "15px",
                }}
              >
                ReSubmit
              </button>
            </div>

          </div>
          {/* Popup */}
          {showPopup && (
            <div className="popup">
              <div className="popup-content">
                <span className="close" onClick={handlePopupClose}>
                  &times;
                </span>
                <div className="popup-content-checkbox">
                  <div className="popup-checkbox">
                    <input
                      type="checkbox"
                      id="checkbox1"
                      name="checkbox1"
                      value="Option 1"
                    />
                    <label htmlFor="checkbox1">Document Pending</label>
                  </div>
                  <div className="popup-checkbox">
                    <input
                      type="checkbox"
                      id="checkbox1"
                      name="checkbox1"
                      value="Option 1"
                    />
                    <label htmlFor="checkbox1">Missing Profile  Information</label>
                  </div>

                </div>
                <textarea

                  type="text"
                  value={popupText}
                  onChange={(e) => setPopupText(e.target.value)}
                />
                <button onClick={handlePopupSubmit}>Resubmit</button>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default AddNewDriver;
