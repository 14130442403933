import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import "./Header.css";
import Clip from "../Buttons/Clip-button";
import Nav from "../Nav2/Nav";
import axios from "axios";
import Scanbar from "../Details/Scanbar";
import AssignSection from "../AssignCab&Driver/AssignSection";
import { useDispatch, useSelector } from "react-redux";
import { selectbookingDetails } from "../Details/DetailsSlice";
import {
  fetchDriverData1,
  savebookingid,
  savebookinglocation,
} from "../AssignCab&Driver/assignDriverSlice";
import { setToggleForTaskDetails } from "../AssignCab&Driver/assignCab&DriverSlice";
import { fetchCabData } from "../AssignCab&Driver/assignCabSlice";
import NavbarContent from "./NavbarContent";
import { deleteBookingAsync } from "../Details/DeleteBookingSlice";
import scanerImg from "../../../src/assets/scanerImg.jpg";
import golFirst from "../../../src/assets/golFirst.svg";
import line from "../../../src/assets/line.svg";
import golSecond from "../../../src/assets/golSecond.svg";
import CustomBilling from "./modal/CustomBilling";

const Header = ({
  bookingDetailsCondition,
  styleFromCustomerBookingDetails,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [offForBookingDetails, setoffForBookingDetails] = useState(
    !bookingDetailsCondition
  );

  const [check, setCheck] = useState(false);
  const { id } = useParams();
  const location = useLocation();

  const bookingDetails = useSelector(selectbookingDetails);



  const bookinglocation=`${bookingDetails?.location?.city}, ${bookingDetails?.location?.state}`


  
  const [triptype, settriptype] = useState(null);

  useEffect(() => {
    if (bookingDetails && bookingDetails.tariff) {
      settriptype(bookingDetails.tariff.tripType);
    }
  }, [bookingDetails]);

  // console.log("Booking Details Is Showing From Header", bookingDetails);
  const toggle = useSelector(
    (state) => state.assigncabanddriver.togglefortaskdetails
  );
  console.log("toggle", toggle);

  const handleAssignAndCabDriver = () => {
    const assign = "cab";
    console.log("INIT LOC BEFORE NAVIGATE", bookinglocation, id); 
    navigate(`/booking/${id}/assign/${assign}`);
    console.log("INIT LOC BEFORE DISPATCH", bookinglocation, id);
    dispatch(fetchDriverData1({ id, bookinglocation })); 
    dispatch(fetchCabData(id));
    dispatch(savebookingid(id));
    dispatch(setToggleForTaskDetails(true));
  };
  

  const handleDelete = async (id) => {
    dispatch(deleteBookingAsync(id));
    navigate("/bookings");
    window.location.reload();
  };

  const [activemodal, setactivemodal] = useState(false);

  const openmodal = () => {
    setactivemodal(true);
  };

  return (
    <header>
      <div className="nevbar-booking" style={styleFromCustomerBookingDetails}>
        <NavbarContent />
        {offForBookingDetails && <Clip />}
        <div className="color">
          <div className="l-1">
            <div>{<Nav />}</div>
          </div>

          <div className="space-container">
            <div className="golFirst-container">
              <img src={golFirst} alt="golFirst" />
            </div>

            <div className="line-container">
              <img src={line} alt="line" />
            </div>

            <div className="golSecond-container">
              <img src={golSecond} alt="golSecond" />
            </div>

            <div>
              {bookingDetails?.driver && (
                <>
                  <div className="line-container" style={{ marginTop: "-5px" ,marginLeft:"11px" }}>
                    <img src={line} alt="line" />
                  </div>

                  <div className="golSecond-container">
                    <img src={golSecond} alt="golSecond" />
                  </div>
                </>
              )}
            </div>

            <div>
              {bookingDetails?.pickupInitiated === true && (
                <>
                  <div className="line-container" style={{ marginTop: "-5px" ,marginLeft:"11px" }}>
                    <img src={line} alt="line" />
                  </div>

                  <div className="golSecond-container">
                    <img src={golSecond} alt="golSecond" />
                  </div>
                </>
              )}
            </div>

            <div>
              {bookingDetails?.reachedPickupPoint === true && (
                <>
                  <div className="line-container" style={{ marginTop: "-5px" ,marginLeft:"11px" }}>
                    <img src={line} alt="line" />
                  </div>

                  <div className="golSecond-container">
                    <img src={golSecond} alt="golSecond" />
                  </div>
                </>
              )}
            </div>

            <div>
              {bookingDetails?.rideStarted === true && (
                <>
                  <div className="line-container" style={{ marginTop: "-5px" ,marginLeft:"11px" }}>
                    <img src={line} alt="line" />
                  </div>

                  <div className="golSecond-container">
                    <img src={golSecond} alt="golSecond" />
                  </div>
                </>
              )}
            </div>


            <div>
              {bookingDetails?.rideEnded === true && (
                <>
                  <div className="line-container" style={{ marginTop: "-5px" ,marginLeft:"11px" }}>
                    <img src={line} alt="line" />
                  </div>

                  <div className="golSecond-container">
                    <img src={golSecond} alt="golSecond" />
                  </div>
                </>
              )}
            </div>




          </div>

          <div className="r-1">
            {offForBookingDetails && (
              <div className="right-action-buttons">
                <div className="right-box">
                  <img src={scanerImg} alt="Scanner"></img>
                  <h7>Booking ID {id}</h7>
                </div>
                <div className="right-box-action">
                  <h4>Recent Action</h4>
                  <div className="Right-btn">
                    <button
                      id="cancel-btn"
                      onClick={() => {
                        handleDelete(id);
                      }}
                      className={
                        location.pathname.endsWith("/billingdetails")
                          ? "whiteButton"
                          : "greenButton"
                      }
                    >
                      Cancel
                    </button>
                    <button
                      id="broadcast-btn"
                      className={
                        location.pathname.endsWith("/billingdetails")
                          ? "greenButton"
                          : "whiteButton"
                      }
                    >
                      Broadcast Ride
                    </button>

                    <button
                      id="assign-btn"
                      onClick={handleAssignAndCabDriver}
                      className={
                        location.pathname.endsWith("/billingdetails")
                          ? "greenButton"
                          : "whiteButton"
                      }
                    >
                      Assign Driver & Cab
                    </button>

                    <button
                      id="assign-btn"
                      onClick={openmodal}
                      className={
                        location.pathname.endsWith("/billingdetails")
                          ? "greenButton"
                          : "whiteButton"
                      }
                    >
                      Manual Billing
                    </button>
                  </div>
                </div>
                <Scanbar />
              </div>
            )}
          </div>
        </div>
      </div>

      {/*=========== modal ==============*/}
      <CustomBilling
        bookingDetails={bookingDetails}
        open={activemodal}
        close={() => setactivemodal(false)}
        type={triptype}
      />
      {/*=========== modal ==============*/}
    </header>
  );
};

export default Header;
