import React from "react";
import { useSelector } from "react-redux";
import { selectbookingDetails } from "../DetailsSlice";
import "./TableFirst.css";

const TableFirstItem = ({ item }) => {
  const bookingDetails = useSelector(selectbookingDetails);


  console.log("Item in tablefirst:", item);
  // Default values
  const pickupDateString = item?.data?.pickupDate || " ";
  const returnDateString = item?.data?.returnDate || "";
  const pickupTime = item?.data?.pickupTime || "";
  const returnTime = item?.data?.returnTime || "";
  const tripType = item?.data?.tripType || "";

  // const dummy_path = ["Lucknow", "Haryana", "Pune", "Lucknow", "Haryana", "Pune"];
  // const final_route = dummy_path.join(" -> "); // logic of route for different trip type is not completed yet
  const route = item?.data?.route?.[0] || "";
  const approxDistance =
    item?.data?.approxDistance >= 0 ? item?.approxDistance : "";

  // Parse date strings into Date objects
  const pickupDateParts = pickupDateString.split("-").reverse();
  const returnDateParts = returnDateString.split("-").reverse();

  // Create Date objects
  const pickupDate = pickupDateString ? new Date(...pickupDateParts) : null;
  const returnDate = returnDateString ? new Date(...returnDateParts) : null;
  const differenceInMs = pickupDate && returnDate ? returnDate - pickupDate : 0;
  const differenceInDays = differenceInMs / (1000 * 60 * 60 * 24);

  return item?.data?.tripType === "Outstation" ? (
    <tr className="firstdata_billing_table_data">
      <td>{bookingDetails?.carCategory?.category}</td>

      <td>
        <span>{item?.data?.tripType}</span>
        <span>-</span>
        <span>{item?.data?.subTripType}</span>
      </td>

      <td>{item?.data?.route[0]}</td>
      <td>
        {item?.data?.pickupDate} {item?.data?.pickupTime}
      </td>
      <td>
        {bookingDetails?.status === "Completed"
          ? `${item?.data?.returnDate} ${item?.data?.returnTime}`
          : bookingDetails?.status}
      </td>

      <td>{item?.data?.noOfDays} Days</td>
      <td>{item?.data?.actualDistance} KM</td>
    </tr>
  ) : (
    <tr className="firstdata_billing_table_data">
      <td>
        <span>
          {item?.data?.tripType} - {item?.data?.subTripType}
        </span>
      </td>

      <td>
        {item?.data?.route?.city},{item?.data?.route?.state}
      </td>
      <td>
        {item?.data?.pickupDate} {item?.data?.pickupTime}
      </td>

      <td>
        {bookingDetails?.status === "Completed"
          ? `${item?.data?.returnDate} ${item?.data?.returnTime}`
          : bookingDetails?.status}
      </td>

      <td>{item?.data?.actualHrs} HRS</td>

      <td>{item?.data?.actualDistance} KM</td>
    </tr>
  );
};

const Table = ({ headers, data }) => {
  return (
    <div className="billing_table_containers">
      <div className="billing_table_scroll">
        <table className="billing_table">
          <thead>
            <tr>
              {headers.map((header, index) => (
                <th style={{ borderRadius: "0" }} key={index}>
                  {header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <TableFirstItem key={index} item={item} index={index} />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const TableFirst = ({ data }) => {
  const bookingDetails = useSelector(selectbookingDetails);

  if (bookingDetails.tariff.tripType === "Outstation") {
    var headers = [
      "Car Category",
      "Trip Type",
      "Route",
      "Trip Start Date & Time",
      "Trip End Date & Time",
      "No of Day",
      "Distance Travelled",
    ];
  } else {
    var headers = [
      "Trip Type",
      "Route",
      "Trip Start Date & Time",
      "Trip End Date & Time",
      "Total Hours Covered",
      "Total Km Covered",
    ];
  }

  return <Table headers={headers} data={[data]} />;
};

export default TableFirst;
