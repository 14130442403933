import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './popuptable.css';

const TableItem = ({ item, index, onRowClick }) => {

  const getStatusStyle = (status)=> {
    switch (status.toLowerCase()) {
      case "pending":
        return { color: "#FBAB0E" };
      case "in route":
        return { color: "#F507FA" };
      case "completed":
        return { color: "#38B000" };
      case "confirmed":
        return { color: "#blue" };
      case "cancelled":
        return { color: "#FC0F0F" };
      case "cab assigned":
        return { color: "#00BCBC" };
      default:
        return { color: "#black" };
    }
  };

  return (
    <tr className="pop_up_table_data" onClick={() => onRowClick(item.bookingId)}>
      <td>{index + 1}</td>
      <td>{item.bookingId}</td>
      <td>{item.pickupDate}</td>
      <td>{item.city}, {item.state}</td>
      <td>{item.tripType}</td>
      <td>{item.subTripType}</td>
      <td>{item.totalAmount}</td>
      <td style={getStatusStyle(item.status)}>{item.status}</td>
      <td>{item.customerRating || 'N/A'}</td>
    </tr>
  );
};

const Table = ({ headers, data, onRowClick }) => {
  return (
    <div className="pop_up_table_containers">
      <div className="pop_up_table_scroll">
        <table className="pop_up_table">
          <thead>
            <tr>
              {headers.map((header, index) => (
                <th key={index}>{header}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <TableItem key={index} item={item} index={index} onRowClick={onRowClick} />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const PopUptable = ({ driverId }) => {
  const [data, setData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`https://api.yatricabs.com/v1/admin/drivers/${driverId}/booking-data`);
        const bookings = response.data.data.bookings.flatMap(category => category.bookings);
        const apiData = bookings.map((item, index) => ({
          bookingId: item.bookingId,
          pickupDate: item.pickupDate,
          city: item.location.city,
          state: item.location.state,
          tripType: item.tariff.tripType,
          subTripType: item.tariff.subTripType,
          totalAmount: item.totalAmount,
          status: item.status,
          customerRating: '⭐⭐⭐⭐' // Assuming static rating for now
        }));
        setData(apiData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [driverId]);

  const headers = [
    "Sr No's.",
    "Booking ID's",
    "Trip Date",
    "Pickup City",
    "Trip Type",
    "Sub Trip Type",
    "Amount",
    "Status",
    "Customer Rating",
  ];

  const handleRowClick = (bookingId) => {
    navigate(`/booking/${bookingId}`);
  };

  return (
    <Table headers={headers} data={data} onRowClick={handleRowClick} />
  );
};

export default PopUptable;
