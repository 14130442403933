// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navbar-adminInfo {
  margin-top: 55px;
  display: grid;
  grid-template-columns: 0fr 2fr;
  /* overflow: auto; */
}

.navbar-adminInf {
  margin-top: 60px;
  display: grid;
  grid-template-columns: auto 2fr;
}

.App-booking {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,aAAa;EACb,8BAA8B;EAC9B,oBAAoB;AACtB;;AAEA;EACE,gBAAgB;EAChB,aAAa;EACb,+BAA+B;AACjC;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,gBAAgB;AAClB","sourcesContent":[".navbar-adminInfo {\n  margin-top: 55px;\n  display: grid;\n  grid-template-columns: 0fr 2fr;\n  /* overflow: auto; */\n}\n\n.navbar-adminInf {\n  margin-top: 60px;\n  display: grid;\n  grid-template-columns: auto 2fr;\n}\n\n.App-booking {\n  display: flex;\n  flex-direction: column;\n  overflow: hidden;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
