import { ADD_OUTSTATION_TARIFF, UPDATE_LOCAL_AIRPORT_TARIFF,DELETE_TARIFF, 
    GET_AIRPORT_TARIFF, GET_AIRPORT_TARIFF_OBJ, ADD_AIRPORT_LOCAL_TARIFF, GET_OUTSTATION_TARIFF, 
    GET_SINGLE_OUTSTATION_TARIFF, UPDATE_OUTSTATION_TARIFF, GET_LOCAL_TARIFF, ADD_LOCAL_TARIFF, GET_SINGLE_LOCAL_TARIFF,
    SET_TARIFF_INACTIVE,SET_TARIFF_ACTIVE} from "./Actiontype";


const airportinitialState = {
    airporttariffData : [],
    airporttariffObj : {},
    loading : true,
}

export const airportReducer = (state = airportinitialState, action) =>{
    switch(action.type){
        case GET_AIRPORT_TARIFF : 
            return{
                ...state,
                airporttariffData : action.payload,
                loading : false,
            }
        case DELETE_TARIFF:
            return{
                ...state,
                loading : false
            }
        case UPDATE_LOCAL_AIRPORT_TARIFF:
            return{
                ...state,
                loading: false
            }
        case GET_AIRPORT_TARIFF_OBJ:
            return{
                ...state,
                loading : false,
                airporttariffObj:action.payload
            }
        case ADD_AIRPORT_LOCAL_TARIFF:
            return{
                ...state,
                loading : false,
            }
        case SET_TARIFF_INACTIVE:
            console.log("Reducer: Setting tariff as inactive with id:", action.payload);
            return {
                ...state,
                airporttariffData: state.airporttariffData.map((tariff) => {
                if (tariff._id === action.payload) {
                    console.log("Reducer: Found matching tariff:", tariff);
                    return { ...tariff, status : "Inactive"};
                }
                return tariff;
                }),
        };
        case SET_TARIFF_ACTIVE:
            console.log("Reducer: Setting tariff as active with id:", action.payload);
            return {
                ...state,
                airporttariffData: state.airporttariffData.map((tariff) => {
                if (tariff._id === action.payload) {
                    console.log("Reducer: Found matching tariff:", tariff);
                    return { ...tariff, status : "Active" };
                }
            return tariff;
            }),
        };
        default : return state;
    }
}

const outstationinitailState = {
    outstationtariffData : [],
    outstationtariffObj : {},
    loading : true,
}

export const outstationReducer = (state = outstationinitailState, action) =>{
    switch(action.type){
        case GET_OUTSTATION_TARIFF :
            return{
                ...state,
                outstationtariffData : action.payload,
                loading : false,
            }
        case ADD_OUTSTATION_TARIFF:
            return{
                ...state,
                loading: false,
            }
        case GET_SINGLE_OUTSTATION_TARIFF:
            return{
                ...state,
                outstationtariffObj : action.payload,
                loading : false
            }
        case UPDATE_OUTSTATION_TARIFF :
            return{
                ...state,
                loading : false
            }
        case SET_TARIFF_INACTIVE:
            console.log("Reducer: Setting tariff as inactive with id:", action.payload);
            return {
                ...state,
                outstationtariffData: state.outstationtariffData.map((tariff) => {
                if (tariff._id === action.payload) {
                    console.log("Reducer: Found matching tariff:", tariff);
                    return { ...tariff, status : "Inactive"};
                }
                return tariff;
                }),
        };
        case SET_TARIFF_ACTIVE:
            console.log("Reducer: Setting tariff as active with id:", action.payload);
            return {
                ...state,
                outstationtariffData: state.outstationtariffData.map((tariff) => {
                if (tariff._id === action.payload) {
                    console.log("Reducer: Found matching tariff:", tariff);
                    return { ...tariff, status : "Active" };
                }
            return tariff;
            }),
        };
        default: return state;
    }
}

const localinitialState = {
    localtariffData : [],
    localtariffObj : {},
    loading : true,
}

export const localReducer = (state = localinitialState, action) =>{
    switch(action.type){
        case GET_LOCAL_TARIFF:
            return{
                ...state,
                localtariffData : action.payload,
                loading : false,
            }
        case ADD_LOCAL_TARIFF:
            return{
                ...state,
                loading: false,
            }
        case GET_SINGLE_LOCAL_TARIFF:
            return{
                ...state,
                localtariffObj : action.payload,
                loading : false
            }
        case UPDATE_LOCAL_AIRPORT_TARIFF :
            return{
                ...state,
                loading : false
            }
        case SET_TARIFF_INACTIVE:
            console.log("Reducer: Setting tariff as inactive with id:", action.payload);
            return {
                ...state,
                localtariffData: state.localtariffData.map((tariff) => {
                if (tariff._id === action.payload) {
                    console.log("Reducer: Found matching tariff:", tariff);
                    return { ...tariff, status : "Inactive"};
                }
                return tariff;
                }),
        };
        case SET_TARIFF_ACTIVE:
            console.log("Reducer: Setting tariff as active with id:", action.payload);
            return {
                ...state,
                localtariffData: state.localtariffData.map((tariff) => {
                if (tariff._id === action.payload) {
                    console.log("Reducer: Found matching tariff:", tariff);
                    return { ...tariff, status : "Active" };
                }
            return tariff;
            }),
        };
        default: return state;
    }
}


  
