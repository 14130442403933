import { useState, useEffect } from "react";

const useSuggestionsIndianCities = () => {
  const [suggestionsIndianCities, setSuggestionsIndianCities] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://media.yatricabs.com/indian-cities.json"
        );
        const data = await response.json();

        const locationIndianCities = data.cities;

        setSuggestionsIndianCities(locationIndianCities);
      } catch (error) {
        console.error("Error fetching suggestions:", error);
      }
    };

    fetchData();
  }, []);

  return [suggestionsIndianCities];
};

export default useSuggestionsIndianCities;
