import React from 'react';
import './firsttable.css';

const TableItem = ({ item }) => {
  return (
    <tr className="first_Earning_table_data">
      <td>{item.label}</td>
      <td>{item.all}</td>
      <td>{item.retail}</td>
      <td>{item.corporate}</td>
    </tr>
  );
};

const Table = ({ headers, data }) => {
  return (
    <div className="first_table_containers">
      <div className="first_table_scroll">
        <table className="first_table">
          <thead>
            <tr style={{ backgroundColor: 'green', color: 'white' }}>
              {headers.map((header, index) => (
                <th style={{ borderRadius: "0" }} key={index}>{header}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => <TableItem key={index} item={item} />)}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const FirstTable = ({ earningData }) => {
  console.log("EARNIN FIRST >>>", earningData);
  const earnings = earningData?.data || {};
  
  const totalBookings = earnings.totalBookings ?? "N/A";
  const totalFullyPaid = earnings.totalFullyPaid ?? "N/A";
  const totalPaymentPending = earnings.totalPaymentPending ?? "N/A";
  

  const retailBookings = earnings.bookings?.filter(booking => booking.bookingType === "retail").length ?? 0;
  const corporateBookings = earnings.bookings?.filter(booking => booking.bookingType === "corporate").length ?? 0;


  const retailFullyPaid = earnings.bookings?.filter(booking => booking.bookingType === "retail" && booking.status === "Completed").length ?? 0;
  const corporateFullyPaid = earnings.bookings?.filter(booking => booking.bookingType === "corporate" && booking.status === "Completed").length ?? 0;
  
  const retailPaymentPending = retailBookings - retailFullyPaid;
  const corporatePaymentPending = corporateBookings - corporateFullyPaid;


  const headers = [" ", "All", "Retail", "Corporate"];


  const data = [
    { label: "Total Booking", all: totalBookings, retail: retailBookings, corporate: corporateBookings },
    { label: "Payment Done", all: totalFullyPaid, retail: retailFullyPaid, corporate: corporateFullyPaid },
    { label: "Payment Pending", all: totalPaymentPending, retail: retailPaymentPending, corporate: corporatePaymentPending },
  ];

  return <Table headers={headers} data={data} />;
};

export default FirstTable;