import React, { useEffect, useState } from "react";
import "./AddCouponCode.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";

const DropdownColumn = ({
  title,
  options,
  selectedValue,
  handleChange,
  placeholder,
}) => {
  const placeholderOption = {
    label: placeholder || "Select an option",
    value: "",
  };
  return (
    <div className="dropdown-column">
      <div className="title">{title}</div>
      <select value={selectedValue} onChange={handleChange} className="drop">
        {[placeholderOption, ...options].map((option, index) => (
          <React.Fragment key={index}>
            {Array.isArray(option.label) ? (
              option.label.map((subOption, subIndex) => (
                <option key={subIndex} value={subOption}>
                  {subOption}
                </option>
              ))
            ) : (
              <option key={index} value={option.value}>
                {option.label}
              </option>
            )}
          </React.Fragment>
        ))}
      </select>
    </div>
  );
};

const InputColumn = ({
  title,
  selectedValue,
  handleChange,
  inputType = "text",
}) => {
  return (
    <div className="dropdown-column">
      <div className="title">{title}</div>
      <input
        type={inputType}
        value={selectedValue}
        onChange={handleChange}
        className="input"
        placeholder={title}
      />
    </div>
  );
};

const TextAreaColumn = ({ title, selectedValue, handleChange }) => {
  return (
    <div className="dropdown-column">
      <div className="title">{title}</div>

      <textarea
        value={selectedValue}
        onChange={handleChange}
        className="textarea"
        placeholder={title}
      />
    </div>
  );
};

const RadioButtonColumn = ({ title, options, selectedValue, handleChange }) => {
  return (
    <div className="dropdown-column radio-column">
      <div className="title">{title}</div>

      {options.map((option) => (
        <label key={option.value} className="radio-label">
          <input
            type="radio"
            value={option.value}
            checked={selectedValue === option.value}
            onChange={handleChange}
          />
          {option.label}
        </label>
      ))}
    </div>
  );
};

const AddCouponCode = () => {
  const [selectedPromoCode, setSelectedPromoCode] = useState("");
  const [selectedDescription, setSelectedDescription] = useState("");
  const [selectedDiscountOption, setSelectedDiscountOption] = useState("");
  const [selectedStatusOption, setSelectedStatusOption] = useState("");
  const [selectedDiscountValue, setSelectedDiscountValue] = useState("");
  const [selectedMinimumAmount, setSelectedMinimumAmount] = useState("");
  const [selectedUptoDiscount, setSelectedUptoDiscount] = useState("");
  const [selectedExpiryDate, setSelectedExpiryDate] = useState("");
  const [subTripOptions, setSubTripOptions] = useState([]);
  const [selectedTripType, setselectedTripType] = useState("");
  const [selectedSubTripType, setSelectedSubTripType] = useState("");
  const [tripOptions, setTripOptions] = useState([]);
  const navigate = useNavigate();

  const discountOptions = [
    { label: "Percentage", value: "Percentage" },
    { label: "Amount", value: "Amount" },
  ];

  const statusOptions = [
    { label: "Inactive", value: "Inactive" },
    { label: "Active", value: "Active" },
  ];

  useEffect(() => {
    const extractTripAsOptions = (dataArray) => {
      if (Array.isArray(dataArray.data)) {
        return dataArray.data.map((item) => ({
          label: item.tripType,
          value: item.tripType,
        }));
      }
      return [];
    };

    const fetchOptions = async () => {
      try {
        const tripResponse = await fetch(
          "https://api.yatricabs.com/v1/trip-types"
        );
        const tripData = await tripResponse.json();
        // console.log(tripData);
        const tripOptions = extractTripAsOptions(tripData);
        // console.log(tripOptions);
        setTripOptions(tripOptions);
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };
    fetchOptions();
  }, []);

  useEffect(() => {
    const extractSubTripAsOptions = (dataArray) => {
      if (Array.isArray(dataArray.data)) {
        return dataArray.data.map((item) => ({
          label: item.subTripTypes,
          value: item.subTripTypes,
        }));
      }
      return [];
    };
    const fetchSubTripTypes = async () => {
      try {
        if (selectedTripType) {
          const subTripResponse = await fetch(
            `https://api.yatricabs.com/v1/sub-trip-types/${selectedTripType}`
          );
          const subTripData = await subTripResponse.json();
          const subTripOptions = extractSubTripAsOptions(subTripData);
          console.log(subTripOptions);
          setSubTripOptions(subTripOptions);
        } else {
          setSubTripOptions([]);
        }
      } catch (error) {
        console.error("Error fetching sub-trip types: ", error);
      }
    };
    fetchSubTripTypes();
  }, [selectedTripType]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const headers = {
      "Content-Type": "application/json",
    };

    const cupponData = new FormData();
    cupponData.append("promoCode", selectedPromoCode);
    cupponData.append("description", selectedDescription);
    cupponData.append("tripType", selectedTripType);
    cupponData.append("subTripType", selectedSubTripType);
    cupponData.append("discountType", selectedDiscountOption);
    cupponData.append("discountValue", selectedDiscountValue);
    cupponData.append("minBookingAmount", selectedMinimumAmount);
    cupponData.append("maxDiscountAmount", selectedUptoDiscount);
    cupponData.append("expiryDate", selectedExpiryDate);
    cupponData.append("status", selectedStatusOption);

    console.log("Data Entered");
    for (const pair of cupponData.entries()) {
      console.log(pair[0] + ": " + pair[1]);
    }
    try {
      await axios.post(
        "https://api.yatricabs.com/v1/admin/coupon-codes",
        cupponData,
        {
          headers,
        }
      );
      toast.success("Coupon Created successfully!");
      setTimeout(() => {
        window.location.reload();
        navigate("/coupon");
      }, 3000);
      e.target.reset();
    } catch (error) {
      toast.error(error.response.data.message);
      console.error(error.response.data.message);
    }
  };

  return (
    <>
      <Toaster />
      <form onSubmit={handleSubmit}>
        <div className="add-coupon-code-container">
          <div className="add-coupon-form">
            <InputColumn
              title="Promo Code"
              selectedValue={selectedPromoCode}
              handleChange={(e) => setSelectedPromoCode(e.target.value)}
            />

            <TextAreaColumn
              title="Description"
              selectedValue={selectedDescription}
              handleChange={(e) => setSelectedDescription(e.target.value)}
            />

            <DropdownColumn
              placeholder="Select Trip Type"
              title="Trip Type"
              options={tripOptions}
              selectedValue={selectedTripType}
              handleChange={(e) => setselectedTripType(e.target.value)}
            />

            <DropdownColumn
              placeholder="Select Sub Trip Type"
              title="Sub Trip Type"
              options={subTripOptions}
              selectedValue={selectedSubTripType}
              handleChange={(e) => setSelectedSubTripType(e.target.value)}
            />

            <div className="radio-btn1">
              <RadioButtonColumn
                title="Discount Type"
                options={discountOptions}
                selectedValue={selectedDiscountOption}
                handleChange={(e) => setSelectedDiscountOption(e.target.value)}
                className="discount-title"
              />
            </div>

            <InputColumn
              title="Discount Value"
              selectedValue={selectedDiscountValue}
              handleChange={(e) => setSelectedDiscountValue(e.target.value)}
            />

            <InputColumn
              title="Minimum Amount"
              selectedValue={selectedMinimumAmount}
              handleChange={(e) => setSelectedMinimumAmount(e.target.value)}
            />

            <InputColumn
              title="Upto Discount"
              selectedValue={selectedUptoDiscount}
              handleChange={(e) => setSelectedUptoDiscount(e.target.value)}
            />
            <div className="radio-btn2">
              <RadioButtonColumn
                title="Status"
                options={statusOptions}
                selectedValue={selectedStatusOption}
                handleChange={(e) => setSelectedStatusOption(e.target.value)}
              />
            </div>

            <InputColumn
              title="Expiry Date"
              selectedValue={selectedExpiryDate}
              handleChange={(e) => setSelectedExpiryDate(e.target.value)}
              inputType="date"
            />
          </div>
          <div className="add">
            <button type="submit" className="add-btn">
              Submit
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default AddCouponCode;
