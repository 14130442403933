// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.billing_tablesecond_containers table {
  border-collapse: collapse;
  width: 100%;
  margin-top: 1.5rem;
}

.billing_table_containers th {
  padding: 2px;
  color: white;
  background-color: #38b000;
  box-shadow: 0px 4px 18px 0px #38b00040;
  border: 1px solid black;
}
.billing_table th {
  box-shadow: none;
  border: 1px solid black;
font-size: 11px;
font-style: normal;
font-weight: 700;
}


.firstsecond_billing_table_data p{
  text-align: center;
  margin: 0;
  color: #000;


font-family: Inter;
font-size: 10px;
font-style: normal;
font-weight: 700;
}


.firstsecond_billing_table_data td {
  border: 1px solid black;
  color: #292D32;
font-size: 12px;
font-style: normal;
font-weight: 500;
}
.firstsecond_billing_table_data td :nth-child(2){
font-weight: 800;
}
.firstthird_billing_table_data td {
  border: 1px solid black;
  font-size: 12px;
  color: #292D32;
font-style: normal;
font-weight: 500;
}
.firstthird_billing_table_data {
  font-size: 12px;
  font-weight: 700;
}`, "",{"version":3,"sources":["webpack://./src/components/Details/tablebilling/tablesecond.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,WAAW;EACX,kBAAkB;AACpB;;AAEA;EACE,YAAY;EACZ,YAAY;EACZ,yBAAyB;EACzB,sCAAsC;EACtC,uBAAuB;AACzB;AACA;EACE,gBAAgB;EAChB,uBAAuB;AACzB,eAAe;AACf,kBAAkB;AAClB,gBAAgB;AAChB;;;AAGA;EACE,kBAAkB;EAClB,SAAS;EACT,WAAW;;;AAGb,kBAAkB;AAClB,eAAe;AACf,kBAAkB;AAClB,gBAAgB;AAChB;;;AAGA;EACE,uBAAuB;EACvB,cAAc;AAChB,eAAe;AACf,kBAAkB;AAClB,gBAAgB;AAChB;AACA;AACA,gBAAgB;AAChB;AACA;EACE,uBAAuB;EACvB,eAAe;EACf,cAAc;AAChB,kBAAkB;AAClB,gBAAgB;AAChB;AACA;EACE,eAAe;EACf,gBAAgB;AAClB","sourcesContent":[".billing_tablesecond_containers table {\n  border-collapse: collapse;\n  width: 100%;\n  margin-top: 1.5rem;\n}\n\n.billing_table_containers th {\n  padding: 2px;\n  color: white;\n  background-color: #38b000;\n  box-shadow: 0px 4px 18px 0px #38b00040;\n  border: 1px solid black;\n}\n.billing_table th {\n  box-shadow: none;\n  border: 1px solid black;\nfont-size: 11px;\nfont-style: normal;\nfont-weight: 700;\n}\n\n\n.firstsecond_billing_table_data p{\n  text-align: center;\n  margin: 0;\n  color: #000;\n\n\nfont-family: Inter;\nfont-size: 10px;\nfont-style: normal;\nfont-weight: 700;\n}\n\n\n.firstsecond_billing_table_data td {\n  border: 1px solid black;\n  color: #292D32;\nfont-size: 12px;\nfont-style: normal;\nfont-weight: 500;\n}\n.firstsecond_billing_table_data td :nth-child(2){\nfont-weight: 800;\n}\n.firstthird_billing_table_data td {\n  border: 1px solid black;\n  font-size: 12px;\n  color: #292D32;\nfont-style: normal;\nfont-weight: 500;\n}\n.firstthird_billing_table_data {\n  font-size: 12px;\n  font-weight: 700;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
