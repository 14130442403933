import axios from "axios";
import React, { useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { BsArrowRight } from "react-icons/bs";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import "./addInteraction.css";

const Interaction = () => {
  const [selectcontactType, setSelectcontactType] = useState("");
  const [selectqueryType, setSelectqueryType] = useState("");
  const [selectsubQueryType, setSelectsubQueryType] = useState("");
  const [comment, setComment] = useState("");
  const user = useSelector((state) => state.auth.user);
  const { id } = useParams();

  const contactType = [
    { label: "Inbound", value: "Inbound" },
    { label: "Outbound", value: "Outbound" },
  ];
  const queryType = [
    { label: "Complaint", value: "Complaint" },
    { label: "Feedback", value: "Feedback" },
    {
      label: "Request for Modification",
      value: "Modification",
    },
  ];
  const subQueryType = {
    Complaint: [
      { label: "Driver Complaint", value: "Driver Complaint" },
      { label: "Cab Complaint", value: "Cab Complaint" },
      {
        label: "Billing Issue",
        value: "Billing Issue",
      },
    ],
    Feedback: [
      { label: "Suggestion", value: "Suggestion" },
      { label: "Service Feedback", value: "Feedback" },
    ],
    Modification: [
      { label: "Ride Cancellation ", value: "Cancellation" },
      { label: "Ride Reschedule", value: "Reschedule" },
      { label: "Change in Trip Type ", value: "tripType" },
      { label: "Change in Vehicle Category", value: "vehicleCategory" },
    ],
  };
  console.log("selectcontact Type", selectcontactType);
  const handleSubmit = async (e) => {
    e.preventDefault();
    const headers = {
      "Content-Type": "application/json",
    };

    const data = new FormData();
    data.append("contactType", selectcontactType);
    data.append("queryType", selectqueryType);
    data.append("subQueryType", selectsubQueryType);
    data.append("remarks", comment);

    for (const pair of data.entries()) {
      console.log(pair[0] + ": " + pair[1]);
    }

    try {
      await axios.post(
        `https://api.yatricabs.com/v1/admin/bookings/interactions/${id}`,
        data,
        {
          headers: headers,
        }
      );
      console.log("Data Entered");
      toast.success("Interaction added successfully!");
      setTimeout(() => {
        window.location.reload();
      }, 3000);

      e.target.reset();
    } catch (error) {
      toast.error(error.response.data.message);
      console.error(error.response.data.message);
    }
  };
  const [open, setOpen] = useState(false);
  console.log("open value", open);
  return (
    <>
      <Toaster />
      <form onSubmit={handleSubmit}>
        <div className="btn">
          <div className="btn-1">
            <button className="main-button">Interaction</button>
            <span className="Dropdown_contact_type">
              <select
                className="contact-type-select"
                placeholder="Contact Type"
                onChange={(e) => setSelectcontactType(e.target.value)}
              >
                <option value="">Contact Type</option>
                {contactType.map((item) => (
                  <option key={item.value} value={item.value}>
                    {item.label}
                  </option>
                ))}
              </select>
            </span>

            <BsArrowRight className="arrow_icon" />

            <span className="Dropdown_query_type">
              <select
                className="contact-type-select"
                placeholder="Query Type"
                onChange={(e) => setSelectqueryType(e.target.value)}
              >
                <option value="">Query Type</option>
                {queryType.map((item) => (
                  <option key={item.value} value={item.value}>
                    {item.label}
                  </option>
                ))}
              </select>
            </span>
            <BsArrowRight className="Query_arrow_icon " />
            <span className="Dropdown_contact_type Dropdown_subQuery">
              <select
                className="contact-type-select"
                placeholder="sub Query Type"
                onChange={(e) => setSelectsubQueryType(e.target.value)}
              >
                <option value="">Sub Query Type</option>
                {subQueryType[selectqueryType]?.map((item) => (
                  <option key={item.value} value={item.value}>
                    {item.label}
                  </option>
                ))}
              </select>
            </span>
          </div>
        </div>
        <div className="costm-booking">
          <h7>
            {user.firstName} {user.lastName}
          </h7>
        </div>
        <div className="border">
          <div className="comment-placeholder">
            <textarea
              className="input_2"
              onChange={(e) => setComment(e.target.value)}
              placeholder="Comment Here For Special Request"
            />
          </div>
          <div className="submit">
            <button className="add-btn">Submit</button>
          </div>
        </div>
      </form>
    </>
  );
};

export default Interaction;
