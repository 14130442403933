import React from "react";
import { IoSearch } from "react-icons/io5";

const InputColumn = ({ title, selectedValue, handleChange }) => {
	return (
		<div
			style={{
				display: "flex",
				alignItems: "center",
			}}
		>
			{" "}
			<input
				type="text"
				value={selectedValue}
				onChange={handleChange}
				className="inputText dropdown-select-1"
				placeholder={title}
				style={{ width: "130px" }}
			/>
			<IoSearch className="dropdown-icon search007" />
		</div>
	);
};

export default InputColumn;
