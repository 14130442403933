export function generateTimeOptions(selectDate) {
	const options = [];
	const currentTime = new Date();

	if (!selectDate) return options;

	let currentHour = currentTime.getHours() + 1;
	let currentMinute = currentTime.getMinutes();
	const selectedDate = new Date(selectDate);

	let min = currentMinute + 30 - (currentMinute % 30);
	if (selectedDate > new Date()) {
		currentHour = currentHour === 24 ? 1 : 0;
		min = 0;
	}
	for (let hour = currentHour; hour < 24; hour++) {
		for (let minute = min; minute < 60 && hour < 24; minute += 15) {
			// Format the hour and minute as "HH:mm"
			const formattedHour = hour.toString().padStart(2, "0");
			const formattedMinute = minute.toString().padStart(2, "0");
			const timeString = `${formattedHour}:${formattedMinute}`;
			options.push(timeString);
			// hour++;
		}
		min = 0;
	}

	return options;
}
