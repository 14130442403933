import React, { useState, useEffect } from "react";
// import "./ViewAllCityPage.css";
import "./InactiveCity.css";
import { useSelector, useDispatch } from "react-redux";
import {
	selectInActiveCity,
	resetInactiveCityState,
	fetchinactivecity,
} from "./geographySlice";
import { fetchingInActiveLocations } from "./GeograpyApi";
import axios from "axios";
import { Toggle } from "../TariffManagement/Toggle";
import toast, { Toaster } from "react-hot-toast";
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

// Component for each row in the table
const TableItem = ({ item, index, sentToActiveItem }) => {
	const rowClass = index % 2 === 0 ? "table-row-even" : "table-row-odd";

	// Handle send to active button click
	const logState = () => {
		sentToActiveItem(item, "error", "Location Has been Sent to Activated !");
	};

	return (
		<tr className={rowClass}>
			<td>{index + 1}</td>
			<td>{item?.city}</td>
			<td>{item?.state}</td>
			<td>{item?.zeroPaymentBooking === true ? "Enabled" : "Disabled"}</td>
			<td>{item?.status}</td>

			<td>
				<div className="inactive_city_three_button" style={{ height: "10px" }}>
					<Toggle
						toggled={item?.status === "Active" ? false : true}
						onClick={logState}
					/>
				</div>
			</td>
		</tr>
	);
};

// Table component
const Table = ({ headers, data }) => {
	const [rawdata, setRawData] = useState(data);
	const updatedInActiveCity = useSelector(selectInActiveCity);
	const dispatch = useDispatch();
	console.log(updatedInActiveCity);

	// Send to active item function
	const sentToActiveItem = async (item, type) => {
		const response = await axios.put(
			`${API_BASE_URL}/v1/admin/locations/${item?._id}/status`,
			{ status: "Active" },
			{ headers: headers },
		);
		console.log(response);
		toast.success(response.data.message);
		setTimeout(() => {
			window.location.reload();
		}, 2000);
		// dispatch(resetInactiveCityState());
		dispatch(fetchinactivecity());
	};

	const getData = fetchingInActiveLocations();
	getData
		.then((response) => {
			console.log(response);
		})
		.catch((err) => {
			console.log(err);
			if (err.response?.status === 404) {
				dispatch(resetInactiveCityState());
			}
		});

	return (
		<>
			<Toaster />
			<div className="table-containers">
				<div className="table-scroll">
					<table className="table">
						<thead>
							<tr>
								{headers?.map((header, index) => (
									<th key={index}>
										{index !== headers.length - 1 && (
											<div className="th-line"></div>
										)}
										{header}
									</th>
								))}
							</tr>
						</thead>
						<tbody>
							{updatedInActiveCity.length >= 1 ? (
								updatedInActiveCity?.map((item, index) => (
									<TableItem
										key={index}
										item={item}
										index={index}
										// deleteItem={deleteItem}
										sentToActiveItem={sentToActiveItem}
									/>
								))
							) : (
								<p>No inactive locations </p>
							)}
						</tbody>
					</table>
				</div>
			</div>
		</>
	);
};

// Main InactiveCity component
const InactiveCity = ({ data }) => {
	const headers = [
		"Sr No",
		"City Name",
		"State Name",
		"Pay To Driver",
		"Status",
		"Action",
	];
	const inactivecity = data;

	const [tableKey, setTableKey] = useState(0);

	useEffect(() => {
		setTableKey((prevKey) => prevKey + 1);
	}, [data]);

	return (
		<div>
			<Table key={tableKey} headers={headers} data={inactivecity} />
		</div>
	);
};

export default InactiveCity;
