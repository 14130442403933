import axios from "axios";
import { logout } from "../login/authSlice";
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export function fetchAllBooking(dispatch) {
  return new Promise(async (resolve) => {
    try {
      const response = await axios.get(
       `${API_BASE_URL}/v1/admin/bookings`
      );
      console.log(response.data.data);
      resolve(response.data.data);
    } catch (e) {
      if (e.message === "Network Error") {
        // Unauthorized, so logout the user
        dispatch(logout());
        // set isLogIn to false
        window.location.href = "/";
      }
      console.log("Error in fetching Data", e);
    }
  });
}

export function fetchPendingBooking() {
  return new Promise(async (resolve) => {
    try {
      const response = await axios.get(
       `${API_BASE_URL}/v1/admin/bookings/pending-bookings`
      );
      resolve(response.data.data);
    } catch (e) {
      console.log("Error in fetching Data", e);
    }
  });
}
