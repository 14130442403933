import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { deleteBooking } from "./DetailsAPI";

const initialState = {
  bookingDetails: null,
  status: "idle",
};

export const deleteBookingAsync = createAsyncThunk(
  "deleteBooking/delete",
  async (id) => {
    try {
      const response = await deleteBooking(id);
      console.log(id);

      return response;
    } catch (error) {
      console.error("Error fetching details:", error);
      throw error;
    }
  }
);

export const deleteSlice = createSlice({
  name: "deleteBooking",
  initialState,

  extraReducers: (builder) => {
    builder
      .addCase(deleteBookingAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteBookingAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.bookingDetails = action.payload;
      })
      .addCase(deleteBookingAsync.rejected, (state) => {
        state.status = "error";
      });
  },
});

export const deleteBookings = (state) => state.details.bookingDetails;

export default deleteSlice.reducer;
