import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ImEye } from "react-icons/im";
import "../DriverManagement/ViewAllCar.css";
import { FaPen } from "react-icons/fa";
import Filter from "../Booking/Filter";
import DatePicker from "react-datepicker";
import InputColumn from "../Booking/Input";
import {
  fetchCustomer,
  selectAllCustomer,
  selectLoading,
  setNewValue,
} from "./CustomerSlice";
import { Toggle } from "../TariffManagement/Toggle";
import axios from "axios";
import { toggleCustomerStatus } from "./CustomerSlice";
import {resetCustomerDetails} from './CustomerDetailsSclice'
import { useDispatch, useSelector } from "react-redux";
import { format } from "date-fns";

const TableItem = ({ item, index }) => {
  const rowClass = index % 2 === 0 ? "table-row-even" : "table-row-odd";

  return (
    <tr className={rowClass}>
      <td>{item["Sr No"]}</td>
      <td>{item["Customer Name"]}</td>
      <td>{item["Contact Number"]}</td>
      <td>{item["Alternate Number"]}</td>
      <td>{item["Email"]}</td>
      <td>{item["Registered On"]}</td>
      <td>{item["Status"]}</td>
      <td>{item["Booking History"]}</td>
      <td>{item["Action"]}</td>
    </tr>
  );
};

const Table = ({ headers, data, setShowCustomerDetails }) => {
  return (
    <div className="table-containers">
      <div className="table-scroll">
        <table className="table" style={{ padding: "10px" }}>
          <thead>
            <tr>
              {headers.map((header, index) => (
                <th key={index}>{header}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <TableItem
                key={index}
                index={index}
                item={item}
                setShowCustomerDetails={setShowCustomerDetails}
              />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const ViewAllCustomer = () => {
  const [searchPhoneNumber, setsearchPhoneNumber] = useState("");
  const [searchName, setsearchName] = useState("");
  const [searchEmail, setsearchEmail] = useState("");
  const [showCustomerDetails, setShowCustomerDetails] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const currentSection = "View-All-Customer";
  const [showDetails, setShowDetails] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const customerData = useSelector(selectAllCustomer);

  const loader = useSelector(selectLoading);

  console.log("checking loader", loader);

  useEffect(() => {
    dispatch(fetchCustomer());
    dispatch(resetCustomerDetails());
  }, [dispatch]);

  const handleImEyeClick = (id) => {
    dispatch(setNewValue(`/customer/${currentSection}/details/${id}`));
    navigate(`/customer/${currentSection}/details/${id}`);
  };

  const handleEditButton = (customerId)=>{
    navigate(`/customer/${currentSection}/edit-details/${customerId}`)
  }

  const handleCustomerStatus = async (customerId, newStatus, dispatch) => {
    try {
      const response = await axios.put(
        `https://api.yatricabs.com/v1/admin/users/${customerId}/status`,
        {
          status: newStatus,
        }
      );
      console.log("Response:", response.data);
      dispatch(toggleCustomerStatus({ customerId, newStatus }));
      dispatch(fetchCustomer());
    } catch (error) {
      console.error("Error toggling customer status:", error);
    }
  };

  const logState = (state, customerId, dispatch) => {
    const newStatus = state === true ? "Inactive" : "Active";
    handleCustomerStatus(customerId, newStatus, dispatch);
  };

  const headers = [
    "Sr No",
    "Customer Name",
    "Contact Number",
    "Alternate Number ",
    "Email",
    "Registered On",
    "Status",
    "Booking History",
    "Action",
  ];

  const handleDateChange = (date) => {
    setSelectedDate(date);
    console.log(date);
  };

  const formatDateDDMMYYYY = (date) => format(date, "dd-MM-yyyy");

  const filteredCustomer = customerData.filter((customer) => {
    const selectedDateFormatted = selectedDate
      ? formatDateDDMMYYYY(selectedDate)
      : null;
    return (
      (!searchName ||
        customer.firstName.toLowerCase().includes(searchName) ||
        customer.lastName.toLowerCase().includes(searchName)) &&
      (!searchPhoneNumber || customer.phone.includes(searchPhoneNumber)) &&
      (!searchEmail ||
        customer.email.toLowerCase().includes(searchEmail.toLowerCase())) &&
      (!selectedDateFormatted || customer.createdAt === selectedDateFormatted)
    );
  });

  const data = [];

  for (let i = 0; i < filteredCustomer.length; i++) {
    const customer = filteredCustomer[i];

    data.push({
      "Sr No": i + 1,
      "Customer Name": `${customer.firstName} ${customer.lastName}`,
      "Contact Number": customer.phone,
      "Alternate Number": customer.phone,
      Email: customer.email,
      "Registered On": customer.createdAt,
      "Status" : customer.status,
      "Booking History": <button className="button">Show</button>,
      Action: (
        <div className="box-action">
          <Toggle
            toggled={customer.status === "Active" ? false : true}
            onClick={(state) => logState(state, customer._id, dispatch)}
          />
          <span className="icon-margin"></span>
          <ImEye
            className="icon icon-large"
            onClick={() => handleImEyeClick(customer._id)}
          />
          {/* <span className="icon-margin"></span> */}
          <FaPen className="icon-edit " onClick={()=>handleEditButton(customer._id)} />
        </div>
      ),
    });
  }

  const CostumerList = data;

  return (
    <div>
      <>
        <div className="dropdown-container">
          <Filter />
          <InputColumn
            title="Search by Name"
            selectedValue={searchName}
            handleChange={(e) => setsearchName(e.target.value)}
          />
          <InputColumn
            title="Search by Mobile No."
            selectedValue={searchPhoneNumber}
            handleChange={(e) => setsearchPhoneNumber(e.target.value)}
          />
          <InputColumn
            title="Search by Email"
            selectedValue={searchEmail}
            handleChange={(e) => setsearchEmail(e.target.value)}
          />
          <DatePicker
            selected={selectedDate}
            onChange={handleDateChange}
            placeholderText="Select a date"
            dateFormat="dd-MM-yyyy"
            className="custom-datepicker"
          />
        </div>
        <Table
          headers={headers}
          data={CostumerList.map((item) => ({
            ...item,
          }))}
          setShowCustomerDetails={setShowCustomerDetails}
        />
      </>
    </div>
  );
};

export default ViewAllCustomer;
