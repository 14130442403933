import React from "react";
import "./tablesecond.css";
import { useDispatch, useSelector } from "react-redux";
import { selectbookingDetails } from "../DetailsSlice";

const TableFirstItem = ({ item }) => {
  const bookingDetails = useSelector(selectbookingDetails);

  const paymentDetails = item?.paymentDetails || [];

  return (
    <>
      {paymentDetails.length === 0 ? (
        <tr className="firstthird_billing_table_data">
          <td colSpan="4">No transaction details found</td>
        </tr>
      ) : (
        paymentDetails.map((payment, index) => (
          <tr key={index} className="firstthird_billing_table_data">
            <td>RS.{payment?.amountPaid ?? "N/A"}</td>
            <td>{payment?.paymentDate ?? "N/A"}</td>
            <td>UPI</td>
            <td>{payment?.transactionId ?? "N/A"}</td>
          </tr>
        ))
      )}
    </>
  );
};


const Table = ({ headers, bookingDetailsTableThird }) => {
  return (
    <div className="billing_tablesecond_containers">
      <div className="billing_table_scroll">
        <table className="billing_table" >
          <thead>

            <th colSpan={4} style={{ borderRadius: "0px" }}>
              Transaction Details
            </th>

            <tr>
              {headers.map((header, index) => (
                <th
                  style={{ backgroundColor: "#E6F5E0", color: "#000" }}
                  key={index}
                >
                  {header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {bookingDetailsTableThird.map((item, index) => (
              <TableFirstItem key={index} item={item} />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const TableFirst = ({ bookingDetailsTableThird }) => {
  const headers = ["Amount", "Payment Date & Time", "Payment Method","Transaction ID"]; // Header labels for the columns

  return <Table headers={headers} bookingDetailsTableThird={[bookingDetailsTableThird]} />;
};

export default TableFirst;
