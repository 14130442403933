import React, { useState, useEffect } from "react";
import "./CreateVendorTarrif.css";
import axios from "axios";
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const CreateVendorTariff = () => {
  const [formValues, setFormValues] = useState({
    locationId: "",
    carCategoryId: "",
    tripType: "",
    subTripType: "",
    distance: "",
    fuelType: "",
    days: "",
    baseFare: "",
    minKm: "",
    minHrs: "",
    extraKmCharge: "",
    extraHrsCharge: "",
    tdsRate: "",
    morningDriverAllowance: "",
    nightDriverAllowance: "",
    dayDriverAllowance: "",
    minKmPerDay: "",
    perKmCharge: "",
    extraKmChargeOutstation: "",
    tdsOutstation: "",
  });

  const [locationOptions, setLocationOptions] = useState([]);
  const [carCategoryOptions, setCarCategoryOptions] = useState([]);
  const [tripTypeOptions, setTripTypeOptions] = useState([]);
  const [subTripTypeOptions, setSubTripTypeOptions] = useState([]);
  const [garageDistanceOptions, setGarageDistanceOptions] = useState([
    { label: "G2G Fixed", value: "G2G Fixed" },
    { label: "P2P", value: "P2P" },
    { label: "G2G Actual", value: "G2G Actual" },
  ]);

  const [fuelTypeOptions, setFuelTypeOptions] = useState([
    { label: "Petrol", value: "Petrol" },
    { label: "Diesel", value: "Diesel" },
    { label: "CNG", value: "CNG" },
    { label: "Electric", value: "Electric" },
  ]);

  const [daysOptions, setDaysOptions] = useState([]);
  const [showAdditionalFields, setShowAdditionalFields] = useState(false);
  const [isOutstation, setIsOutstation] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
      // ...(name === "tripType" && { subTripType: "" }), // Reset subTripType when tripType changes
    });

    if (name === "tripType") {
      setShowAdditionalFields(true);
      setIsOutstation(value === "Outstation");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    let payload;
  
    // Form payload based on tripType
    if (formValues.tripType === "Local" || formValues.tripType === "Airport") {
      payload = {
        locationId: formValues.locationId,
        carCategoryId: formValues.carCategoryId,
        tripType: formValues.tripType,
        subTripType: formValues.subTripType,
        distance: formValues.distance,
        fuelType: formValues.fuelType,
        days: formValues.days,
        baseFare: formValues.baseFare,
        minKm: formValues.minKm,
        minHrs: formValues.minHrs,
        extraKmCharge: formValues.extraKmCharge,
        extraHrsCharge: formValues.extraHrsCharge,
        tds: formValues.tdsRate,
        morningDriverAllowance: formValues.morningDriverAllowance,
        nightDriverAllowance: formValues.nightDriverAllowance,
        dayDriverAllowance: formValues.dayDriverAllowance,
      };
    } else if (formValues.tripType === "Outstation") {
      payload = {
        locationId: formValues.locationId,
        carCategoryId: formValues.carCategoryId,
        tripType: formValues.tripType,
        subTripType: formValues.subTripType,
        distance: formValues.distance,
        fuelType: formValues.fuelType,
        days: formValues.days,
        minKmPerDay: formValues.minKmPerDay,
        perKmCharge: formValues.perKmCharge,
        extraKmCharge: formValues.extraKmChargeOutstation,
        tds: formValues.tdsOutstation,
        morningDriverAllowance: formValues.morningDriverAllowance,
        nightDriverAllowance: formValues.nightDriverAllowance,
        dayDriverAllowance: formValues.dayDriverAllowance,
      };
    } else {
      alert("Please select a valid Trip Type!");
      return;
    }
  
    console.log("Payload prepared:", payload);
  
    // Determine the API endpoint based on tripType
    const apiUrl =
      formValues.tripType === "Local" || formValues.tripType === "Airport"
        ? `${API_BASE_URL}/v1/admin/vendor-tariffs/local-airport`
        : `${API_BASE_URL}/v1/admin/vendor-tariffs/outstation`;
  
    try {
      const response = await axios.post(apiUrl, payload, {
        headers: {
          "Content-Type": "application/json", // Inform the backend of the payload format
        },
      });
  
      console.log("Form Submitted Successfully:", response.data);
  
      // Reset form values
      setFormValues({
        locationId: "",
        carCategoryId: "",
        tripType: "",
        subTripType: "",
        distance: "",
        fuelType: "",
        days: "",
        baseFare: "",
        minKm: "",
        minHrs: "",
        extraKmCharge: "",
        extraHrsCharge: "",
        tdsRate: "",
        morningDriverAllowance: "",
        nightDriverAllowance: "",
        dayDriverAllowance: "",
        minKmPerDay: "",
        perKmCharge: "",
        extraKmChargeOutstation: "",
        tdsOutstation: "",
      });
  
      alert("Form submitted successfully!");
    } catch (error) {
      if (error.response) {
        // Server responded with a status other than 2xx
        console.error("Error submitting form:", error.response.data);
        alert(`Error: ${error.response.data.message || "Submission failed"}`);
      } else if (error.request) {
        // No response received
        console.error("Network error:", error.request);
        alert("Failed to submit the form. Please check your network.");
      } else {
        // Other errors
        console.error("Error:", error.message);
        alert("An unexpected error occurred. Please try again.");
      }
    }
  };
  

  // Fetch dropdown options from APIs
  useEffect(() => {
    const fetchOptions = async () => {
      try {
        // Fetch location options
        const locationResponse = await fetch(
          `${API_BASE_URL}/v1/locations`
        );
        const locationData = await locationResponse.json();
        const locationOptions = locationData.data.map((item) => ({
          label: item.location,
          value: item._id,
        }));
        setLocationOptions(locationOptions);

        // Fetch car category options
        const carCategoryResponse = await fetch(
          `${API_BASE_URL}/v1/car-categories`
        );
        const carCategoryData = await carCategoryResponse.json();
        const carCategoryOptions = carCategoryData.data.map((item) => ({
          label: item.category,
          value: item._id,
        }));
        setCarCategoryOptions(carCategoryOptions);

        // Fetch trip type options
        const tripTypeResponse = await fetch(
          `${API_BASE_URL}/v1/trip-types`
        );
        const tripTypeData = await tripTypeResponse.json();
        const tripTypeOptions = tripTypeData.data.map((item) => ({
          label: item.tripType,
          value: item.tripType,
        }));
        setTripTypeOptions(tripTypeOptions);

        // Fetch garage distance options
        const garageDistanceOptions = [
          { label: "G2G Fixed", value: "G2G Fixed" },
          { label: "P2P", value: "P2P" },
          { label: "G2G Actual", value: "G2G Actual" },
        ];
        setGarageDistanceOptions(garageDistanceOptions);

        // Fetch fuel type options
        const fuelTypeResponse = await fetch(
          `${API_BASE_URL}/v1/fuels`
        );
        const fuelTypeData = await fuelTypeResponse.json();
        const fuelTypeOptions = [
          { label: "Petrol", value: "Petrol" },
          { label: "Diesel", value: "Diesel" },
          { label: "CNG", value: "CNG" },
          { label: "Electric", value: "Electric" },
        ];
        setFuelTypeOptions(fuelTypeOptions);

        // Fetch day type options
        const daysResponse = await fetch(`${API_BASE_URL}/v1/days`);
        const daysData = await daysResponse.json();
        const daysOptions = daysData.data.map((item) => ({
          label: item.day,
          value: item.day,
        }));
        setDaysOptions(daysOptions);
      } catch (error) {
        console.error("Error fetching options:", error);
      }
    };

    fetchOptions();
  }, []);

  useEffect(() => {
    const fetchSubTripTypes = async () => {
      try {
        if (formValues.tripType) {
          const response = await fetch(
            `${API_BASE_URL}/v1/sub-trip-types/${formValues.tripType}`
          );
          const data = await response.json();
          const subTripTypeOptions = data.data[0].subTripTypes.map(
            (subTripType) => ({
              label: subTripType,
              value: subTripType,
            })
          );
          setSubTripTypeOptions(subTripTypeOptions);
        }
      } catch (error) {
        console.error("Error fetching sub-trip types:", error);
      }
    };

    fetchSubTripTypes();
  }, [formValues.tripType]);

  return (
    <form className="create-vendor-tariff" onSubmit={handleSubmit}>
      <div className="section">
        <div className="section-title">
          <div className="title">Add Price Details</div>
        </div>
        <div className="form-row">
          <div>
            <label htmlFor="locationId">Location</label>
            <select
              id="locationId"
              name="locationId"
              value={formValues.locationId}
              onChange={handleChange}
            >
              <option value="">Select City</option>
              {locationOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>

          <div>
            <label htmlFor="carCategoryId">Car Category</label>
            <select
              id="carCategoryId"
              name="carCategoryId"
              value={formValues.carCategoryId}
              onChange={handleChange}
            >
              <option value="">Select Category</option>
              {carCategoryOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>

          <div>
            <label htmlFor="tripType">Trip Type</label>
            <select
              id="tripType"
              name="tripType"
              value={formValues.tripType}
              onChange={handleChange}
            >
              <option value="">Select Trip Type</option>
              {tripTypeOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>

          <div>
            <label htmlFor="subTripType">Sub Trip Type</label>
            <select
              id="subTripType"
              name="subTripType"
              value={formValues.subTripType}
              onChange={handleChange}
            >
              <option value="">Select Sub Trip Type</option>
              {subTripTypeOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>

          <div>
            <label htmlFor="distance">Garage Distance Option</label>
            <select
              id="distance"
              name="distance"
              value={formValues.distance}
              onChange={handleChange}
            >
              <option value="">Select Option</option>
              {garageDistanceOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>

          <div>
            <label htmlFor="fuelType">Fuel Type</label>
            <select
              id="fuelType"
              name="fuelType"
              value={formValues.fuelType}
              onChange={handleChange}
            >
              <option value="">Select Option</option>
              {fuelTypeOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>

          <div>
            <label htmlFor="days">Days</label>
            <select
              id="days"
              name="days"
              value={formValues.days}
              onChange={handleChange}
            >
              <option value="">Select Day Type</option>
              {daysOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>

      {showAdditionalFields && (
        <>
          {isOutstation ? (
            <div className="section">
              <div className="section-title">
                <div className="title">Outstation Pricing</div>
              </div>
              <div className="form-row">
                <div>
                  <label htmlFor="minKmPerDay">Min Km/Day</label>
                  <input
                    type="text"
                    id="minKmPerDay"
                    name="minKmPerDay"
                    placeholder="Min Km/Day"
                    value={formValues.minKmPerDay}
                    onChange={handleChange}
                  />
                </div>
                <div>
                  <label htmlFor="perKmCharge">Per Km Charge</label>
                  <input
                    type="text"
                    id="perKmCharge"
                    name="perKmCharge"
                    placeholder="Per Km Charge"
                    value={formValues.perKmCharge}
                    onChange={handleChange}
                  />
                </div>
                <div>
                  <label htmlFor="extraKmChargeOutstation">Extra Km Charge</label>
                  <input
                    type="text"
                    id="extraKmChargeOutstation"
                    name="extraKmChargeOutstation"
                    placeholder="Extra Km Charge"
                    value={formValues.extraKmChargeOutstation}
                    onChange={handleChange}
                  />
                </div>
                <div>
                  <label htmlFor="tdsOutstation">TDS</label>
                  <input
                    type="text"
                    id="tdsOutstation"
                    name="tdsOutstation"
                    placeholder="TDS (%)"
                    value={formValues.tdsOutstation}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
          ) : (
            <div className="section">
              <div className="section-title">
                <div className="title">Local/Airport Pricing</div>
              </div>
              <div className="form-row">
                <div>
                  <label htmlFor="baseFare">Base Fare</label>
                  <input
                    type="text"
                    id="baseFare"
                    name="baseFare"
                    placeholder="Base Fare"
                    value={formValues.baseFare}
                    onChange={handleChange}
                  />
                </div>
                <div>
                  <label htmlFor="minKm">Min Km</label>
                  <input
                    type="text"
                    id="minKm"
                    name="minKm"
                    placeholder="Min Km"
                    value={formValues.minKm}
                    onChange={handleChange}
                  />
                </div>
                <div>
                  <label htmlFor="minHrs">Min Hours</label>
                  <input
                    type="text"
                    id="minHrs"
                    name="minHrs"
                    placeholder="Min Hours"
                    value={formValues.minHrs}
                    onChange={handleChange}
                  />
                </div>
                <div>
                  <label htmlFor="extraKmCharge">Extra Km Charge</label>
                  <input
                    type="text"
                    id="extraKmCharge"
                    name="extraKmCharge"
                    placeholder="Extra Km Charge"
                    value={formValues.extraKmCharge}
                    onChange={handleChange}
                  />
                </div>
                <div>
                  <label htmlFor="extraHrsCharge">Extra Hours Charge</label>
                  <input
                    type="text"
                    id="extraHrsCharge"
                    name="extraHrsCharge"
                    placeholder="Extra Hours Charge"
                    value={formValues.extraHrsCharge}
                    onChange={handleChange}
                  />
                </div>
                <div>
                  <label htmlFor="tdsRate">TDS</label>
                  <input
                    type="text"
                    id="tdsRate"
                    name="tdsRate"
                    placeholder="TDS (%)"
                    value={formValues.tdsRate}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
          )}

          <div className="section">
            <div className="section-title">
              <div className="title">Driver Allowance</div>
            </div>
            <div className="form-row">
              <div>
                <label htmlFor="morningDriverAllowance">Morning Bata</label>
                <input
                  type="text"
                  id="morningDriverAllowance"
                  name="morningDriverAllowance"
                  placeholder="Morning Bata
                (Rs./Day)"
                  value={formValues.morningDriverAllowance}
                  onChange={handleChange}
                />
              </div>

              <div>
                <label htmlFor="nightDriverAllowance">Night Bata</label>
                <input
                  type="text"
                  id="nightDriverAllowance"
                  name="nightDriverAllowance"
                  placeholder="Night Bata (Rs./Day)"
                  value={formValues.nightDriverAllowance}
                  onChange={handleChange}
                />
              </div>

              <div>
                <label htmlFor="dayDriverAllowance">Day Bata</label>
                <input
                  type="text"
                  id="dayDriverAllowance"
                  name="dayDriverAllowance"
                  placeholder="Day Bata (Rs./Day)"
                  value={formValues.dayDriverAllowance}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
        </>
      )}

      <div className="form-submit">
        <button type="submit" className="submit-button">
          Add
        </button>
      </div>
    </form>
  );
};

export default CreateVendorTariff;
