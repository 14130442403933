// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Styling for the table */
.table2 {
  width: 100%; /* Full width for responsiveness */
  max-width: 400px; /* Optional: Limit the table width */
  border-collapse: collapse; /* Remove gaps between table cells */
  margin: 20px 0; /* Add spacing around the table */
  border: 1px solid #000; /* Black border around the table */
}

/* Styling for the green cells (left side) */
.green-cell2 {
  background-color: #38b000;
  color: white;
  font-weight: bold;
  font-size: 18px !important;
  text-align: left; /* Left-align the text */
  padding: 10px; /* Add padding for better spacing */
  border: 1px solid #000; /* Black border for cells */
}

/* Styling for the right cells */
.right-cell2 {
  text-align: center; /* Center-align the text */
  font-weight: 700;
  font-size: 18px !important;
  padding: 10px; /* Add padding for better spacing */
  border: 1px solid #000; /* Black border for cells */
}
`, "",{"version":3,"sources":["webpack://./src/components/DriverManagement/earning/table/table2.css"],"names":[],"mappings":"AAAA,0BAA0B;AAC1B;EACE,WAAW,EAAE,kCAAkC;EAC/C,gBAAgB,EAAE,oCAAoC;EACtD,yBAAyB,EAAE,oCAAoC;EAC/D,cAAc,EAAE,iCAAiC;EACjD,sBAAsB,EAAE,kCAAkC;AAC5D;;AAEA,4CAA4C;AAC5C;EACE,yBAAyB;EACzB,YAAY;EACZ,iBAAiB;EACjB,0BAA0B;EAC1B,gBAAgB,EAAE,wBAAwB;EAC1C,aAAa,EAAE,mCAAmC;EAClD,sBAAsB,EAAE,2BAA2B;AACrD;;AAEA,gCAAgC;AAChC;EACE,kBAAkB,EAAE,0BAA0B;EAC9C,gBAAgB;EAChB,0BAA0B;EAC1B,aAAa,EAAE,mCAAmC;EAClD,sBAAsB,EAAE,2BAA2B;AACrD","sourcesContent":["/* Styling for the table */\n.table2 {\n  width: 100%; /* Full width for responsiveness */\n  max-width: 400px; /* Optional: Limit the table width */\n  border-collapse: collapse; /* Remove gaps between table cells */\n  margin: 20px 0; /* Add spacing around the table */\n  border: 1px solid #000; /* Black border around the table */\n}\n\n/* Styling for the green cells (left side) */\n.green-cell2 {\n  background-color: #38b000;\n  color: white;\n  font-weight: bold;\n  font-size: 18px !important;\n  text-align: left; /* Left-align the text */\n  padding: 10px; /* Add padding for better spacing */\n  border: 1px solid #000; /* Black border for cells */\n}\n\n/* Styling for the right cells */\n.right-cell2 {\n  text-align: center; /* Center-align the text */\n  font-weight: 700;\n  font-size: 18px !important;\n  padding: 10px; /* Add padding for better spacing */\n  border: 1px solid #000; /* Black border for cells */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
