// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.add-new-corporate-step1 {
    display: flex;
    flex-wrap: wrap;
  }
  
  .corporate-info-section,
  .corporate-info-section-right {
    flex: 1 1 50%;
    padding: 0 1rem;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/CorporateBooking/AddNewCorporate.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,eAAe;EACjB;;EAEA;;IAEE,aAAa;IACb,eAAe;EACjB","sourcesContent":[".add-new-corporate-step1 {\n    display: flex;\n    flex-wrap: wrap;\n  }\n  \n  .corporate-info-section,\n  .corporate-info-section-right {\n    flex: 1 1 50%;\n    padding: 0 1rem;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
