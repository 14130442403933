// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.billing_fourth_containers{
  width: 360px;
  }

.billing_fourth_containers table {
  border-collapse: collapse;
  width: 100%;
}

.billing_fourth_containers th {
  color: black;
  background-color: white;
  box-shadow: 0px 4px 18px 0px #38b00040;
  font-size: 15px;
  font-weight: 700;
  border: 1px solid black;
  padding: 2px 6px;
}

.temp {
  margin-left: 3rem;
}
.display-grid-billing {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 4rem;
}
.first_billing_table_data td {
  border: 1px solid black;
  text-align: center;
  font-size: 10px;
  font-weight: 700;
}
.first_billing_table_data td:first-child {
  text-align: center;
  font-size: 12px;
  font-weight: 550;
  color: white;
  width: 48%;
  padding: 2px;
  background: #38b000;
}

.fourth-td {
  background: #38b000;
  color: white;
  font-size: 11px;
}
.firstFare_billing_table_data td {
  border: 1px solid black;
  text-align: center;
  font-size: 10px;
  font-weight: 700;
}
.firstFare_billing_table_data td:first-child {
  text-align: start;
  font-size: 12px;
  font-weight: 550;
  width: 49%;
  padding: 2px;
  text-wrap: wrap;
  padding-left: 4%;
}`, "",{"version":3,"sources":["webpack://./src/components/Details/tablebilling/Fourthtable.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ;;AAEF;EACE,yBAAyB;EACzB,WAAW;AACb;;AAEA;EACE,YAAY;EACZ,uBAAuB;EACvB,sCAAsC;EACtC,eAAe;EACf,gBAAgB;EAChB,uBAAuB;EACvB,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;AACnB;AACA;EACE,aAAa;EACb,8BAA8B;EAC9B,SAAS;AACX;AACA;EACE,uBAAuB;EACvB,kBAAkB;EAClB,eAAe;EACf,gBAAgB;AAClB;AACA;EACE,kBAAkB;EAClB,eAAe;EACf,gBAAgB;EAChB,YAAY;EACZ,UAAU;EACV,YAAY;EACZ,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;EACnB,YAAY;EACZ,eAAe;AACjB;AACA;EACE,uBAAuB;EACvB,kBAAkB;EAClB,eAAe;EACf,gBAAgB;AAClB;AACA;EACE,iBAAiB;EACjB,eAAe;EACf,gBAAgB;EAChB,UAAU;EACV,YAAY;EACZ,eAAe;EACf,gBAAgB;AAClB","sourcesContent":[".billing_fourth_containers{\n  width: 360px;\n  }\n\n.billing_fourth_containers table {\n  border-collapse: collapse;\n  width: 100%;\n}\n\n.billing_fourth_containers th {\n  color: black;\n  background-color: white;\n  box-shadow: 0px 4px 18px 0px #38b00040;\n  font-size: 15px;\n  font-weight: 700;\n  border: 1px solid black;\n  padding: 2px 6px;\n}\n\n.temp {\n  margin-left: 3rem;\n}\n.display-grid-billing {\n  display: grid;\n  grid-template-columns: 1fr 1fr;\n  gap: 4rem;\n}\n.first_billing_table_data td {\n  border: 1px solid black;\n  text-align: center;\n  font-size: 10px;\n  font-weight: 700;\n}\n.first_billing_table_data td:first-child {\n  text-align: center;\n  font-size: 12px;\n  font-weight: 550;\n  color: white;\n  width: 48%;\n  padding: 2px;\n  background: #38b000;\n}\n\n.fourth-td {\n  background: #38b000;\n  color: white;\n  font-size: 11px;\n}\n.firstFare_billing_table_data td {\n  border: 1px solid black;\n  text-align: center;\n  font-size: 10px;\n  font-weight: 700;\n}\n.firstFare_billing_table_data td:first-child {\n  text-align: start;\n  font-size: 12px;\n  font-weight: 550;\n  width: 49%;\n  padding: 2px;\n  text-wrap: wrap;\n  padding-left: 4%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
