import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const initialState = {
  tarrifId: null, // Selected tariff ID for updates
  tripType: null, // Selected trip type
  tarrifDetails: null, // Tariff details data
  currentComp: null, // Current component state
  loading: "idle", // Loading status
  errorMessage: null, // Error message
  booleanValue: false, // Custom boolean value
};

// Fetch Airport Tariff
export const fetchAirportTariff = createAsyncThunk(
  "tarrifDetails/fetchAirportTariff",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}/v1/admin/vendor-tariffs/airport`
      );
      console.log("SLICE RESPONCE>>>>>>>",response);
      return response.data.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(
          error.response.data.message || "Error fetching airport tariff"
        );
      }
      return rejectWithValue(error.message);
    }
  }
);

// Fetch Local Tariff
export const fetchLocalTariff = createAsyncThunk(
  "tarrifDetails/fetchLocalTariff",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}/v1/admin/vendor-tariffs/local`
      );
      console.log("response for the table>>",response);
      return response.data.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(
          error.response.data.message || "Error fetching local tariff"
        );
      }
      return rejectWithValue(error.message);
    }
  }
);

// Fetch Outstation Tariff
export const fetchOutstationTariff = createAsyncThunk(
  "tarrifDetails/fetchOutstationTariff",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}/v1/admin/vendor-tariffs/outstation`
      );
      console.log("response for the table>>",response);
      return response.data.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(
          error.response.data.message || "Error fetching outstation tariff"
        );
      }
      return rejectWithValue(error.message);
    }
  }
);

const tarrifDetailsSlice = createSlice({
  name: "tarrifDetails",
  initialState,
  reducers: {
    setSelectedTariff: (state, action) => {
      // Set the ID and tripType of the tariff to update
      const { id, tripType } = action.payload;
      state.tarrifId = id;
      state.tripType = tripType;
      console.log("Selected Tariff ID and Trip Type set in slice:", {
        id,
        tripType,
      });
    },
    resetTarrifDetails: (state) => {
      // Reset tariff details
      state.tarrifDetails = null;
    },
    resetCompState: (state, action) => {
      // Reset the current component state
      state.currentComp = action.payload;
    },
    setBooleanValue: (state, action) => {
      // Set a custom boolean value
      state.booleanValue = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // Fetch Airport Tariff
      .addCase(fetchAirportTariff.pending, (state) => {
        state.loading = "Loading";
        state.errorMessage = null;
      })
      .addCase(fetchAirportTariff.fulfilled, (state, action) => {
        state.loading = "Idle";
        state.tarrifDetails = action.payload;
        state.errorMessage = null;
      })
      .addCase(fetchAirportTariff.rejected, (state, action) => {
        state.loading = "Rejected";
        state.errorMessage = action.payload || "Error fetching airport tariff";
      })

      // Fetch Local Tariff
      .addCase(fetchLocalTariff.pending, (state) => {
        state.loading = "Loading";
        state.errorMessage = null;
      })
      .addCase(fetchLocalTariff.fulfilled, (state, action) => {
        state.loading = "Idle";
        state.tarrifDetails = action.payload;
        state.errorMessage = null;
      })
      .addCase(fetchLocalTariff.rejected, (state, action) => {
        state.loading = "Rejected";
        state.errorMessage = action.payload || "Error fetching local tariff";
      })

      // Fetch Outstation Tariff
      .addCase(fetchOutstationTariff.pending, (state) => {
        state.loading = "Loading";
        state.errorMessage = null;
      })
      .addCase(fetchOutstationTariff.fulfilled, (state, action) => {
        state.loading = "Idle";
        state.tarrifDetails = action.payload;
        state.errorMessage = null;
      })
      .addCase(fetchOutstationTariff.rejected, (state, action) => {
        state.loading = "Rejected";
        state.errorMessage =
          action.payload || "Error fetching outstation tariff";
      });
  },
});

// Export actions
export const {
  setSelectedTariff,
  resetTarrifDetails,
  resetCompState,
  setBooleanValue,
} = tarrifDetailsSlice.actions;

// Export selectors
export const selectTariffDetails = (state) => state.tarrifDetails.tarrifDetails;
export const selectSelectedTariffId = (state) => state.tarrifDetails.tarrifId;
export const selectTripType = (state) => state.tarrifDetails.tripType;
export const selectBooleanValue = (state) => state.tarrifDetails.booleanValue;

// Export reducer
export default tarrifDetailsSlice.reducer;
