import React, { useEffect, useState } from "react";
import "./Layout.css";

import ButtonSwitcher from "./buttons/ButtonSwitcher";
import axios from "axios";

import Local from "./SubComponents/Local";
import { Oneway, Roundtrip } from "./SubComponents/Outstation";
import { FromAirport, ToAirport } from "./SubComponents/Airport";
import AdvancePay from "./AdvancePayable_component/AdvancePay";
import FareDetails_Outstation from "./FareDetails/FareDetails_Outstation";
import FareDetails_Outstation_Oneway from "./FareDetails/FareDetails_Outstation_Oneway";
import useSuggestionsCustomer from "../../../hooks/useSuggestionCustomer";

import FareDetails_Airport from "./FareDetails/FareDetails_Airport";
import FareDetails_Local from "./FareDetails/FareDetails_Local";
import "../CreateNewBooking/buttons/ButtonSwitcher.css";
import { useRef } from "react";
import "react-datepicker/dist/react-datepicker.css";
import useSuggestionsLocation from "../../../hooks/useSuggestionCities";

import useSuggestionsCity from "../../../hooks/useSuggestionsIndianCities";
import { useDispatch, useSelector } from "react-redux";

import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { format } from "date-fns";
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const Layout = () => {
	const [comp, setComp] = useState("Local");
	const [isOutstationOpen, setIsOutstationOpen] = useState(false);
	const [isAirportOpen, setIsAirportOpen] = useState(false);

	// I am trying round trip component here
	const navigate = useNavigate();

	const [tripType, setTripType] = useState("Local");
	const [subTripType, setSubTripType] = useState("");
	const [pickUpLocation, setPickUpLocation] = useState([]);
	const [selectedLocation, setSelectedLocation] = useState("");
	const [selectDropAddress, setSelectDropAddress] = useState("");
	const [selectPickUpAddress, setSelectPickUpAddress] = useState("");
	const [pickUpDate, setPickUpDate] = useState("");
	const [dropDate, setDropDate] = useState("");
	const [pickUpTime, setPickUpTime] = useState("");
	const [timeOptions, setTimeOptions] = useState([]);
	const [isDropdownVisible, setIsDropdownVisible] = useState(false);
	const [cabType, setCabType] = useState([]);
	const [allCabsData, setAllCabsData] = useState([]);
	const [selectedCabType, setSelectedCabType] = useState("");
	const [selectTemp, setSelectTemp] = useState("");
	const [selectedTariffId, setSelectedTariffId] = useState("");
	const [carCategoriesDetails, setCarCategoriesDetails] = useState([]);
	const [additionalCities, setAdditionalCities] = useState([""]);
	const [search, setSearch] = useState("");
	const [listVisible, setListVisible] = useState(false);
	const [showDropdown, setShowDropdown] = useState(false);
	const [showDropdownDestination, setShowDropdownDestination] = useState(false);
	const [activeInputIndex, setActiveInputIndex] = useState(null);
	const [errorMessages, setErrorMessages] = useState([""]);
	const [showDate, setShowDate] = useState(false);
	const [startDate, setStartDate] = useState("");
	const [endDate, setEndDate] = useState("");
	const [isNewCustomer, setIsNewCustomer] = useState(true);
	const [selectCustomer, setSelectCustomer] = useState("");
	const [selectCustomerId, setSelectCustomerId] = useState("");
	const [showSuggestions, setShowSuggestions] = useState(false);
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [mobileNo, setMobileNo] = useState("");
	const [email, setEmail] = useState("");
	const [AlternateContactNumber, setAlternateContactNumber] = useState("");
	const [pickupAddress, setPickupAddress] = useState("");
	const [longitude, setLongitude] = useState("");
	const [gstValue, setGstValue] = useState("");
	const [flightValue, setFlightValue] = useState("");
	const [isGstChecked, setIsGstChecked] = useState(!!gstValue);
	const [isFlightChecked, setIsFlightChecked] = useState(!!flightValue);
	const [comment, setComment] = useState("");
	const [selectedAdvanceAmount, setSelectedAdvanceAmount] = useState(0);
	const [otp, setOtp] = useState(["", "", "", "", "", ""]);
	const [otpId, setOtpId] = useState("");
	const [isOtpModalOpen, setIsOtpModalOpen] = useState(false);
	const [fareDetailsData, setFareDetailsData] = useState(null);
	const [comments, setComments] = useState("");
	const [errors, setErrors] = useState({});

	//   const tripType = "Local"; // This should be dynamically set based on your application's state

	// Use the generateTimeOptions function to generate time options based on the date or any other parameter

	// State to store fare details from API
	const [fareDetails, setFareDetails] = useState(null);
	const [zeroPayment, setZeroPayment] = useState("");

	useEffect(() => {
		setFareDetails(null);
		setIsNewCustomer(true);
		setSelectCustomer("");
		setSelectCustomerId("");
		setFirstName("");
		setLastName("");
		setMobileNo("");
		setEmail("");
		setAlternateContactNumber("");
	}, [tripType, subTripType]);

	// for airport added
	const [selectAirport, setSelectAirport] = useState("");

	// for one way added destination state single
	const [destination, setDestination] = useState("");

	// for local booking aded
	const [selectedHourlyPackage, setselectedHourlyPackage] = useState("");

	console.log("Faredetails selected:", fareDetails);

	const handleOtpSendAndVerification = async () => {
		try {
			const phoneNumberWithCountryCode = "+91-" + mobileNo;

			const otpApiUrl = `${API_BASE_URL}/v1/send-otp`;
			const otpResponse = await fetch(otpApiUrl, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({ phone: phoneNumberWithCountryCode }),
			});

			const result = await otpResponse.json();

			if (otpResponse.ok) {
				setOtpId(result.data.otpId);
				setIsOtpModalOpen(true);
				toast.success(result.message);
			} else {
				console.error(
					"Error generating OTP:",
					otpResponse.status,
					otpResponse.statusText,
				);
				// toast.error(result.message);
			}

			// Open OTP verification modal
		} catch (error) {
			console.error("Error during  OTP send :", error);
		}
	};

	console.log("tripType:", tripType);
	console.log("subTripType:", subTripType);
	console.log("selectedHourlyPackage:", selectedHourlyPackage);
	console.log("pickupAddress:", selectedLocation);
	console.log("pickupLat:", longitude.lat);
	console.log("pickupLong:", longitude.lng);
	console.log("startDate:", startDate);
	console.log("pickUpTime:", pickUpTime);
	console.log("tempDataId:", selectTemp);
	console.log("partialPaymentPercentage:", selectedAdvanceAmount);

	const handleCreateBooking = async () => {
		const headers = {
			"Content-Type": "application/json",
		};
		console.log("userId", selectCustomerId);
		console.log("comment", comment);
		// console.log("gstNumber", gstNumber);
		// console.log("flightDetails", flightDetails);
		console.log("partialPaymentPercentage", selectedAdvanceAmount);
		console.log("tempDataId", selectTemp);
		console.log("selectedTariffId", selectedTariffId);
		console.log("tripType", comp);
		console.log("subTripType", selectedHourlyPackage);
		console.log("pickupAddress", pickupAddress);
		console.log("pickupLat", longitude.lat);
		console.log("pickupLong", longitude.lng);
		console.log("pickupDate", formatDate(startDate));
		console.log("pickupTime", pickUpTime);
		console.log("alternatePhone", `+91-${AlternateContactNumber}`);

		const LocalObj = new FormData();

		if (tripType === "Outstation" && subTripType === "Round Trip") {
			LocalObj.append("userId", selectCustomerId);
			LocalObj.append("tripType", tripType);
			LocalObj.append("selectedTariffId", selectedTariffId);
			LocalObj.append("pickupAddress", pickupAddress);
			LocalObj.append("pickupLat", longitude.lat);
			LocalObj.append("pickupLong", longitude.lng);
			LocalObj.append("pickupDate", formatDate(new Date(startDate)));
			LocalObj.append("pickupTime", pickUpTime);
			LocalObj.append("endDate", formatDate(new Date(endDate)));
			LocalObj.append("subTripType", "Round Trip");
			LocalObj.append("tempDataId", selectTemp);
			// LocalObj.append("alternatePhone", `+91-${AlternateContactNumber}`);
			LocalObj.append("partialPaymentPercentage", selectedAdvanceAmount);
			LocalObj.append("gstNumber", gstValue);
			LocalObj.append("flightDetails", flightValue);
		} else if (tripType === "Outstation" && subTripType === "One Way") {
			LocalObj.append("userId", selectCustomerId);
			LocalObj.append("tripType", tripType);
			LocalObj.append("selectedTariffId", selectedTariffId);
			LocalObj.append("pickupAddress", pickupAddress);
			LocalObj.append("pickupLat", longitude.lat);
			LocalObj.append("pickupLong", longitude.lng);
			LocalObj.append("pickupDate", formatDate(new Date(startDate)));
			LocalObj.append("pickupTime", pickUpTime);
			LocalObj.append("subTripType", "One Way");
			LocalObj.append("tempDataId", selectTemp);
			// LocalObj.append("alternatePhone", `+91-${AlternateContactNumber}`);
			LocalObj.append("partialPaymentPercentage", selectedAdvanceAmount);
			// LocalObj.append("dropAddress", selectDropAddress);
			LocalObj.append("gstNumber", gstValue);
			LocalObj.append("flightDetails", flightValue);
		} else if (tripType === "Airport" && subTripType === "From The Airport") {
			LocalObj.append("userId", selectCustomerId);
			LocalObj.append("tripType", tripType);
			LocalObj.append("selectedTariffId", selectedTariffId);
			LocalObj.append("pickupAddress", selectAirport);
			LocalObj.append("pickupLat", longitude.lat);
			LocalObj.append("pickupLong", longitude.lng);
			LocalObj.append("pickupDate", formatDate(new Date(startDate)));
			LocalObj.append("pickupTime", pickUpTime);

			LocalObj.append("subTripType", "From The Airport");
			LocalObj.append("tempDataId", selectTemp);
			// LocalObj.append("alternatePhone", `+91-${AlternateContactNumber}`);
			LocalObj.append("partialPaymentPercentage", selectedAdvanceAmount);
			LocalObj.append("dropAddress", selectDropAddress);
			LocalObj.append("gstNumber", gstValue);
			LocalObj.append("flightDetails", flightValue);
		} else if (tripType === "Airport" && subTripType === "To The Airport") {
			LocalObj.append("userId", selectCustomerId);
			LocalObj.append("tripType", tripType);
			LocalObj.append("selectedTariffId", selectedTariffId);
			LocalObj.append("pickupAddress", pickupAddress);
			LocalObj.append("pickupLat", longitude.lat);
			LocalObj.append("pickupLong", longitude.lng);
			LocalObj.append("pickupDate", formatDate(new Date(startDate)));
			LocalObj.append("pickupTime", pickUpTime);

			LocalObj.append("subTripType", "To The Airport");
			LocalObj.append("tempDataId", selectTemp);
			// LocalObj.append("alternatePhone", `+91-${AlternateContactNumber}`);
			LocalObj.append("partialPaymentPercentage", selectedAdvanceAmount);
			LocalObj.append("pickUpAddress", selectPickUpAddress);
			LocalObj.append("gstNumber", gstValue);
			LocalObj.append("flightDetails", flightValue);
		} else if (tripType === "Local") {
			console.log("tripType:", tripType);
			console.log("selectedHourlyPackage:", selectedHourlyPackage);
			console.log("pickupAddress:", selectedLocation);
			console.log("pickupLat:", longitude.lat);
			console.log("pickupLong:", longitude.lng);
			console.log("startDate:", startDate);
			console.log("pickUpTime:", pickUpTime);
			console.log("tempDataId:", selectTemp);
			console.log("partialPaymentPercentage:", selectedAdvanceAmount);
			LocalObj.append("userId", selectCustomerId);
			LocalObj.append("tripType", tripType);
			LocalObj.append("selectedTariffId", selectedTariffId);
			LocalObj.append("pickupAddress", selectedLocation);
			LocalObj.append("pickupLat", longitude.lat);
			LocalObj.append("pickupLong", longitude.lng);
			LocalObj.append("pickupDate", formatDate(new Date(startDate)));
			LocalObj.append("pickupTime", pickUpTime);

			LocalObj.append("subTripType", selectedHourlyPackage);
			LocalObj.append("tempDataId", selectTemp);
			// LocalObj.append("alternatePhone", `+91-${AlternateContactNumber}`);
			LocalObj.append("partialPaymentPercentage", selectedAdvanceAmount);
			LocalObj.append("gstNumber", gstValue);
			LocalObj.append("flightDetails", flightValue);
		}

		for (const pair of LocalObj.entries()) {
			console.log(pair[0] + ": " + pair[1]);
		}

		try {
			await axios.post(`${API_BASE_URL}/v1/admin/bookings`, LocalObj, {
				headers,
			});
			toast.success("Booking Created successfully!");

			setTimeout(() => {
				navigate("/bookings/all-bookings");
			}, 3000);

			// e.target.reset();
		} catch (error) {
			toast.error(error.response.data.message);
			console.error(error.response.data.message);
		}
		// toast.error("Booking not created! Please try again.");
	};

	const validateUserInfo = () => {
		const error = {};
		if (!firstName) {
			error.firstName = "First name is required";
		}
		if (!lastName) {
			error.lastName = "Last name is required";
		}
		if (!mobileNo) {
			error.mobileNo = "Mobile number is required";
		}
		if (!pickupAddress) {
			error.pickupAddress = "Pickup Address is required";
		}
		if (!selectDropAddress) {
			error.selectDropAddress = "Drop Address is required";
		}
		setErrors(error);
		return Object.keys(error).length === 0;
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		if (!validateUserInfo()) return;
		if (!isNewCustomer) {
			handleCreateBooking();
		} else {
			handleOtpSendAndVerification();
		}
	};

	//   const tripType = "Local"; // This should be dynamically set based on your application's state

	// Use the generateTimeOptions function to generate time options based on the date or any other parameter

	// State to store fare details from API
	// const [fareDetails, setFareDetails] = useState(null);
	const formatDate = (date) => {
		if (!(date instanceof Date) || isNaN(date)) {
			return "";
		}
		return format(date, "dd-MM-yyyy");
	};

	useEffect(() => {
		if (comp === "Local" || comp === "FromAirport" || comp === "ToAirport") {
			setIsOutstationOpen(false);
		}

		if (comp === "Local" || comp === "Oneway" || comp === "Roundtrip") {
			setIsAirportOpen(false);
		}
	}, [comp]);

	useEffect(() => {
		setErrors({});
	}, [comp, tripType, subTripType, isNewCustomer]);

	const renderActiveComponent = () => {
		switch (comp) {
			case "Local":
				return (
					<Local
						setFareDetails={setFareDetails}
						fareDetails={fareDetails}
						states={{
							tripType,
							subTripType,
							pickUpLocation,
							selectedLocation,
							selectPickUpAddress,
							pickUpDate,
							dropDate,
							pickUpTime,
							timeOptions,
							isDropdownVisible,
							cabType,
							allCabsData,
							selectedCabType,
							selectTemp,
							selectedTariffId,
							carCategoriesDetails,
							additionalCities,
							search,
							listVisible,
							showDropdown,
							showDate,
							selectAirport,
							startDate,
							endDate,
							isNewCustomer,
							selectCustomer,
							selectCustomerId,
							showSuggestions,
							firstName,
							lastName,
							mobileNo,
							email,
							AlternateContactNumber,
							pickupAddress,
							longitude,
							gstValue,
							flightValue,
							isGstChecked,
							isFlightChecked,
							comments,
							selectedAdvanceAmount,
							otp,
							otpId,
							isOtpModalOpen,
							selectedHourlyPackage,
							errors,
						}}
						setters={{
							setTripType,
							setSubTripType,
							setPickUpLocation,
							setSelectedLocation,
							setSelectPickUpAddress,
							setPickUpDate,
							setDropDate,
							setPickUpTime,
							setTimeOptions,
							setIsDropdownVisible,
							setCabType,
							setAllCabsData,
							setSelectedCabType,
							setSelectTemp,
							setSelectedTariffId,
							setCarCategoriesDetails,
							setAdditionalCities,
							setSearch,
							setListVisible,
							setShowDropdown,
							setShowDate,
							setSelectAirport,
							setStartDate,
							setEndDate,
							setIsNewCustomer,
							setSelectCustomer,
							setSelectCustomerId,
							setShowSuggestions,
							setFirstName,
							setLastName,
							setMobileNo,
							setEmail,
							setAlternateContactNumber,
							setPickupAddress,
							setLongitude,
							setGstValue,
							setFlightValue,
							setIsGstChecked,
							setIsFlightChecked,
							setComments,
							setSelectedAdvanceAmount,
							setOtp,
							setOtpId,
							setIsOtpModalOpen,
							setselectedHourlyPackage,
						}}
					/>
				);
			case "OneWay":
				return (
					<Oneway
						setFareDetails={setFareDetails}
						fareDetails={fareDetails}
						states={{
							tripType,
							subTripType,
							pickUpLocation,
							selectedLocation,
							selectDropAddress,
							pickUpDate,
							dropDate,
							pickUpTime,
							timeOptions,
							isDropdownVisible,
							cabType,
							allCabsData,
							selectedCabType,
							selectTemp,
							selectedTariffId,
							carCategoriesDetails,
							additionalCities,
							search,
							listVisible,
							showDropdown,
							showDropdownDestination,
							activeInputIndex,
							errorMessages,
							showDate,
							startDate,
							endDate,
							isNewCustomer,
							selectCustomer,
							selectCustomerId,
							showSuggestions,
							firstName,
							lastName,
							mobileNo,
							email,
							AlternateContactNumber,
							pickupAddress,
							longitude,
							gstValue,
							flightValue,
							isGstChecked,
							isFlightChecked,
							comments,
							selectedAdvanceAmount,
							otp,
							otpId,
							isOtpModalOpen,
							destination,
							errors,
						}}
						setters={{
							setTripType,
							setSubTripType,
							setPickUpLocation,
							setSelectedLocation,
							setSelectDropAddress,
							setPickUpDate,
							setDropDate,
							setPickUpTime,
							setTimeOptions,
							setIsDropdownVisible,
							setCabType,
							setAllCabsData,
							setSelectedCabType,
							setSelectTemp,
							setSelectedTariffId,
							setCarCategoriesDetails,
							setAdditionalCities,
							setSearch,
							setListVisible,
							setShowDropdown,
							setShowDropdownDestination,
							setActiveInputIndex,
							setErrorMessages,
							setShowDate,
							setStartDate,
							setEndDate,
							setIsNewCustomer,
							setSelectCustomer,
							setSelectCustomerId,
							setShowSuggestions,
							setFirstName,
							setLastName,
							setMobileNo,
							setEmail,
							setAlternateContactNumber,
							setPickupAddress,
							setLongitude,
							setGstValue,
							setFlightValue,
							setIsGstChecked,
							setIsFlightChecked,
							setComments,
							setSelectedAdvanceAmount,
							setOtp,
							setOtpId,
							setIsOtpModalOpen,
							setDestination,
						}}
					/>
				);
			case "Roundtrip":
				return (
					<Roundtrip
						setFareDetails={setFareDetails}
						fareDetails={fareDetails}
						states={{
							tripType,
							subTripType,
							pickUpLocation,
							selectedLocation,
							selectDropAddress,
							pickUpDate,
							dropDate,
							pickUpTime,
							timeOptions,
							isDropdownVisible,
							cabType,
							allCabsData,
							selectedCabType,
							selectTemp,
							selectedTariffId,
							carCategoriesDetails,
							additionalCities,
							search,
							listVisible,
							showDropdown,
							showDropdownDestination,
							activeInputIndex,
							errorMessages,
							showDate,
							startDate,
							endDate,
							isNewCustomer,
							selectCustomer,
							selectCustomerId,
							showSuggestions,
							firstName,
							lastName,
							mobileNo,
							email,
							AlternateContactNumber,
							pickupAddress,
							longitude,
							gstValue,
							flightValue,
							isGstChecked,
							isFlightChecked,
							comments,
							selectedAdvanceAmount,
							otp,
							otpId,
							isOtpModalOpen,
							errors,
						}}
						setters={{
							setTripType,
							setSubTripType,
							setPickUpLocation,
							setSelectedLocation,
							setSelectDropAddress,
							setPickUpDate,
							setDropDate,
							setPickUpTime,
							setTimeOptions,
							setIsDropdownVisible,
							setCabType,
							setAllCabsData,
							setSelectedCabType,
							setSelectTemp,
							setSelectedTariffId,
							setCarCategoriesDetails,
							setAdditionalCities,
							setSearch,
							setListVisible,
							setShowDropdown,
							setShowDropdownDestination,
							setActiveInputIndex,
							setErrorMessages,
							setShowDate,
							setStartDate,
							setEndDate,
							setIsNewCustomer,
							setSelectCustomer,
							setSelectCustomerId,
							setShowSuggestions,
							setFirstName,
							setLastName,
							setMobileNo,
							setEmail,
							setAlternateContactNumber,
							setPickupAddress,
							setLongitude,
							setGstValue,
							setFlightValue,
							setIsGstChecked,
							setIsFlightChecked,
							setComments,
							setSelectedAdvanceAmount,
							setOtp,
							setOtpId,
							setIsOtpModalOpen,
						}}
					/>
				);
			case "FromAirport":
				return (
					<FromAirport
						setFareDetails={setFareDetails}
						fareDetails={fareDetails}
						states={{
							tripType,
							subTripType,
							pickUpLocation,
							selectedLocation,
							selectDropAddress,
							pickUpDate,
							dropDate,
							pickUpTime,
							timeOptions,
							isDropdownVisible,
							cabType,
							allCabsData,
							selectedCabType,
							selectTemp,
							selectedTariffId,
							carCategoriesDetails,
							additionalCities,
							search,
							listVisible,
							showDropdown,
							showDate,
							selectAirport,
							startDate,
							endDate,
							isNewCustomer,
							selectCustomer,
							selectCustomerId,
							showSuggestions,
							firstName,
							lastName,
							mobileNo,
							email,
							AlternateContactNumber,
							pickupAddress,
							longitude,
							gstValue,
							flightValue,
							isGstChecked,
							isFlightChecked,
							comments,
							selectedAdvanceAmount,
							otp,
							otpId,
							isOtpModalOpen,
							errors,
						}}
						setters={{
							setTripType,
							setSubTripType,
							setPickUpLocation,
							setSelectedLocation,
							setSelectDropAddress,
							setPickUpDate,
							setDropDate,
							setPickUpTime,
							setTimeOptions,
							setIsDropdownVisible,
							setCabType,
							setAllCabsData,
							setSelectedCabType,
							setSelectTemp,
							setSelectedTariffId,
							setCarCategoriesDetails,
							setAdditionalCities,
							setSearch,
							setListVisible,
							setShowDropdown,
							setShowDate,
							setSelectAirport,
							setStartDate,
							setEndDate,
							setIsNewCustomer,
							setSelectCustomer,
							setSelectCustomerId,
							setShowSuggestions,
							setFirstName,
							setLastName,
							setMobileNo,
							setEmail,
							setAlternateContactNumber,
							setPickupAddress,
							setLongitude,
							setGstValue,
							setFlightValue,
							setIsGstChecked,
							setIsFlightChecked,
							setComments,
							setSelectedAdvanceAmount,
							setOtp,
							setOtpId,
							setIsOtpModalOpen,
						}}
					/>
				);
			case "ToAirport":
				return (
					<ToAirport
						setFareDetails={setFareDetails}
						fareDetails={fareDetails}
						states={{
							tripType,
							subTripType,
							pickUpLocation,
							selectedLocation,
							selectPickUpAddress,
							pickUpDate,
							dropDate,
							pickUpTime,
							timeOptions,
							isDropdownVisible,
							cabType,
							allCabsData,
							selectedCabType,
							selectTemp,
							selectedTariffId,
							carCategoriesDetails,
							additionalCities,
							search,
							listVisible,
							showDropdown,
							showDate,
							selectAirport,
							startDate,
							endDate,
							isNewCustomer,
							selectCustomer,
							selectCustomerId,
							showSuggestions,
							firstName,
							lastName,
							mobileNo,
							email,
							AlternateContactNumber,
							pickupAddress,
							longitude,
							gstValue,
							flightValue,
							isGstChecked,
							isFlightChecked,
							comments,
							selectedAdvanceAmount,
							otp,
							otpId,
							isOtpModalOpen,
							errors,
						}}
						setters={{
							setTripType,
							setSubTripType,
							setPickUpLocation,
							setSelectedLocation,
							setSelectPickUpAddress,
							setPickUpDate,
							setDropDate,
							setPickUpTime,
							setTimeOptions,
							setIsDropdownVisible,
							setCabType,
							setAllCabsData,
							setSelectedCabType,
							setSelectTemp,
							setSelectedTariffId,
							setCarCategoriesDetails,
							setAdditionalCities,
							setSearch,
							setListVisible,
							setShowDropdown,
							setShowDate,
							setSelectAirport,
							setStartDate,
							setEndDate,
							setIsNewCustomer,
							setSelectCustomer,
							setSelectCustomerId,
							setShowSuggestions,
							setFirstName,
							setLastName,
							setMobileNo,
							setEmail,
							setAlternateContactNumber,
							setPickupAddress,
							setLongitude,
							setGstValue,
							setFlightValue,
							setIsGstChecked,
							setIsFlightChecked,
							setComments,
							setSelectedAdvanceAmount,
							setOtp,
							setOtpId,
							setIsOtpModalOpen,
						}}
					/>
				);
			default:
				return (
					<Oneway
						setFareDetails={setFareDetails}
						fareDetails={fareDetails}
						states={{
							tripType,
							subTripType,
							pickUpLocation,
							selectedLocation,
							selectDropAddress,
							pickUpDate,
							dropDate,
							pickUpTime,
							timeOptions,
							isDropdownVisible,
							cabType,
							allCabsData,
							selectedCabType,
							selectTemp,
							selectedTariffId,
							carCategoriesDetails,
							additionalCities,
							search,
							listVisible,
							showDropdown,
							showDropdownDestination,
							activeInputIndex,
							errorMessages,
							showDate,
							startDate,
							endDate,
							isNewCustomer,
							selectCustomer,
							selectCustomerId,
							showSuggestions,
							firstName,
							lastName,
							mobileNo,
							email,
							AlternateContactNumber,
							pickupAddress,
							longitude,
							gstValue,
							flightValue,
							isGstChecked,
							isFlightChecked,
							comments,
							selectedAdvanceAmount,
							otp,
							otpId,
							isOtpModalOpen,
							destination,
							errors,
						}}
						setters={{
							setTripType,
							setSubTripType,
							setPickUpLocation,
							setSelectedLocation,
							setSelectDropAddress,
							setPickUpDate,
							setDropDate,
							setPickUpTime,
							setTimeOptions,
							setIsDropdownVisible,
							setCabType,
							setAllCabsData,
							setSelectedCabType,
							setSelectTemp,
							setSelectedTariffId,
							setCarCategoriesDetails,
							setAdditionalCities,
							setSearch,
							setListVisible,
							setShowDropdown,
							setShowDropdownDestination,
							setActiveInputIndex,
							setErrorMessages,
							setShowDate,
							setStartDate,
							setEndDate,
							setIsNewCustomer,
							setSelectCustomer,
							setSelectCustomerId,
							setShowSuggestions,
							setFirstName,
							setLastName,
							setMobileNo,
							setEmail,
							setAlternateContactNumber,
							setPickupAddress,
							setLongitude,
							setGstValue,
							setFlightValue,
							setIsGstChecked,
							setIsFlightChecked,
							setComments,
							setSelectedAdvanceAmount,
							setOtp,
							setOtpId,
							setIsOtpModalOpen,
							setDestination,
						}}
					/>
				);
		}
	};

	const OutstationHandle = () => {
		setTripType("Outstation");
		setIsOutstationOpen(true);
		setComp("Oneway");
	};

	const AirportHandle = () => {
		setTripType("Airport");

		setIsAirportOpen(true);
		setComp("FromAirport");
	};

	const faredetailSwitcher = () => {
		switch (comp) {
			case "Local":
				return <FareDetails_Local fareDetails={fareDetails} />;
			case "OneWay":
				return <FareDetails_Outstation_Oneway fareDetails={fareDetails} />;
			case "Roundtrip":
				return <FareDetails_Outstation fareDetails={fareDetails} />;
			case "FromAirport":
				return <FareDetails_Airport fareDetails={fareDetails} />;
			case "ToAirport":
				return <FareDetails_Airport fareDetails={fareDetails} />;
			default:
				return <FareDetails_Outstation_Oneway fareDetails={fareDetails} />;
		}
	};

	return (
		<>
			<div id="BtoC-main-container">
				{/*================= Left Container =====================*/}
				{/*================= Left Container =====================*/}
				<div id="trip-booking-form-main-container">
					<div id="form-container-layout">
						{/* <ButtonSwitcher /> */}

						{/*================== Buttons ================*/}
						{/*Buttons css in /CreateNewBooking/buttons/ButtonSwitcher.css*/}
						<div id="main-buttons-container">
							<p>Trip Type:</p>

							<div className="button-parent-wrapper">
								<div className="big-buttons-wrapper">
									<button
										className={`${comp === "Local" ? "active" : ""}`}
										onClick={() => {
											setComp("Local");
											setTripType("Local");
											setSubTripType(""); // Reset subTripType for 'Local' trips
										}}
									>
										Local
									</button>

									<button
										className={`${
											comp === "Oneway" || comp === "Roundtrip" ? "active" : ""
										}`}
										onClick={() => {
											setComp("Oneway");
											setTripType("Outstation");
											setSubTripType("One Way"); // Default subTripType for Outstation trips
											setIsOutstationOpen(true); // Open the sub-trip buttons for 'Outstation'
											setIsAirportOpen(false); // Close Airport options if needed
										}}
									>
										Outstation
									</button>

									<button
										className={`${
											comp === "FromAirport" || comp === "ToAirport"
												? "active"
												: ""
										}`}
										onClick={() => {
											setComp("FromAirport");
											setTripType("Airport");
											setSubTripType("From The Airport"); // Default subTripType for Airport trips
											setIsAirportOpen(true); // Open the sub-trip buttons for 'Airport'
											setIsOutstationOpen(false); // Close Outstation options if needed
										}}
									>
										Airport
									</button>
								</div>

								{/* Outstation Sub-Buttons */}
								{isOutstationOpen && (
									<div className="small-buttons-wrapper">
										<button
											className={`${comp === "Oneway" ? "active" : ""}`}
											onClick={() => {
												setComp("Oneway");
												setTripType("Outstation");
												setSubTripType("One Way"); // Set subTripType for Oneway
											}}
										>
											Oneway
										</button>

										<button
											className={`${comp === "Roundtrip" ? "active" : ""}`}
											onClick={() => {
												setComp("Roundtrip");
												setTripType("Outstation");
												setSubTripType("Round Trip"); // Set subTripType for Round Trip
											}}
										>
											Round Trip
										</button>
									</div>
								)}

								{/* Airport Sub-Buttons */}
								{isAirportOpen && (
									<div className="small-buttons-wrapper">
										<button
											className={`${comp === "FromAirport" ? "active" : ""}`}
											onClick={() => {
												setComp("FromAirport");
												setTripType("Airport");
												setSubTripType("From The Airport"); // Set subTripType for From Airport
											}}
										>
											From the airport
										</button>

										<button
											className={`${comp === "ToAirport" ? "active" : ""}`}
											onClick={() => {
												setComp("ToAirport");
												setTripType("Airport");
												setSubTripType("To The Airport"); // Set subTripType for To Airport
											}}
										>
											To the airport
										</button>
									</div>
								)}
							</div>
						</div>

						{/*================== Buttons Ends ================*/}

						{/*======== Local, Outstation, Airport =========*/}
						{renderActiveComponent()}
						{/*======== Local, Outstation, Airport =========*/}
					</div>

					<AdvancePay
						handleSubmit={handleSubmit}
						tripType={comp}
						estimatedFare={fareDetails?.fareDetails?.estimatedFare}
						setSelectedAdvanceAmount={setSelectedAdvanceAmount}
						selectedAdvanceAmount={selectedAdvanceAmount}
						zeroPaymentBooking={fareDetails?.location?.zeroPaymentBooking}
					/>
				</div>

				{/*================= Right Container =====================*/}
				{/*================= Right Container =====================*/}

				<div id="fair-details-main-container">{faredetailSwitcher()}</div>
			</div>
		</>
	);
};

export default Layout;
