import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const initialState = {
  driversId: null,
  driverDetails: null,
  currentComp: null,
  loading: "idle",
  errorMessage: null, 
}; 

export const fetchDriversDetails = createAsyncThunk(
  "driverDetails/fetchDriversDetails",
  async (driversId) => {
    const response = await axios.get(
      `${API_BASE_URL}/v1/admin/drivers/${driversId}`
    );
    console.log("checking drivers details", response.data.data);

    return response.data.data;
  }
);

const driverDetailsSlice = createSlice({
  name: "driverDetails",
  initialState,
  reducers: {
    currentDriverId: (state, action) => {
      state.driversId = action.payload;
    },
    resetDriverDetails: (state) => {
      state.driverDetails = null;
    },
    resetCompState: (state, action) => {
      state.currentComp = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDriversDetails.pending, (state) => {
        state.loading = "Loading";
      })
      .addCase(fetchDriversDetails.fulfilled, (state, action) => {
        state.loading = "Idle";
        state.driverDetails = action.payload;
      })
      .addCase(fetchDriversDetails.rejected, (state, action) => {
        state.loading = "Rejected";
        state.errorMessage = action.error.message;
      });
  },
});
export const { currentDriverId, resetDriverDetails, resetCompState } =
  driverDetailsSlice.actions;

export default driverDetailsSlice.reducer;
