import React from "react";



// This Component is used in Billing Details for Completed Ride to Show the Invoce details
const InvoiceDetails = ({billingDetails}) => {

  const invoiceDetails=billingDetails?.data?.invoiceDetails;
  console.log("Invoice Details",invoiceDetails)

  return (
    <div style={{ display: "flex", alignItems: "center", gap: "8px",marginTop:"30px " }}>
      <div style={{ display: "flex",width:"300px", border: "1px solid black", overflow: "hidden" }}>
        <div
          style={{
            width:"150px",
            display:"flex",
            justifyContent:"center",
            alignItems:"center",
            background: "#38B000",
            color: "white",
            padding:"12px 0px",
            fontFamily:"Inter",
            fontWeight: "700",
            fontSize: "15px",
          }}
        >
          Invoice Number
        </div>
        <span
         style={{
            width:"150px",
            display:"flex",
            justifyContent:"center",
            alignItems:"center",
            color:"#38B000",
            border: "none",
            outline: "none",
            padding: "8px 8px",
            fontWeight: "bold",
            fontSize: "15px",
            
         }}>
           {invoiceDetails?.invoiceId}
        </span>
      </div>

      {["Send Email", "Send SMS","View Invoice"].map((text, index) => (
        <button
          key={index}
          style={{
            background: "#38B000",
            color: "white",
            border: "1px solid black",
            padding: "4px 4px",
            fontSize: "8px",
            borderRadius: "15px",
            cursor: "pointer",
          }}
        >
          {text}
        </button>
      ))}
    </div>
  );
};

export default InvoiceDetails;
