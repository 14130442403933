import Dashboard from "../assets/Dashboard.png";
import Geography from "../assets/Geography.png";
import BookingB2C from "../assets/BookingB2C.png";
import CorporateBooking from "../assets/CorporateBooking.png";
import Customer from "../assets/Customer.png";
import FleetManagement from "../assets/FleetManagement.png";
import DriverManagement from "../assets/DriverManagement.png";
import TariffManagement from "../assets/TariffManagement.png";
import Reports from "../assets/Reports.png";
import Billings from "../assets/billing.png";
import Admin from "../assets/admin.png";
import Coupons from "../assets/Coupons.png";
import Enquiry from "../assets/Enquiry.png";
import Manage from "../assets/Manage.png";
import Services from "../assets/Services.png";
import AdminSetting from "../assets/AdminSetting.png";
import Settings from "../assets/Setting.png";
import Management from "../assets/Management.png";
import Attendance from "../assets/Attendance.png";
import CustomerReviews from "../assets/CustomerReview.png";
import RetailUser from "../assets/RetailUser.png";
import Holiday from "../assets/Holiday.png";
import Employee from "../assets/Employee.png";
// import { useNavigate } from "react-router-dom";

export const sideBar = [
  {
    name: "DashBoard",
    src: (
      <img src={Dashboard} alt="Dashboard Icon" height="20px" width="20px" />
    ),
    to: "/dashboard",
  },
  {
    name: "Geography",
    src: (
      <img src={Geography} alt="Dashboard Icon" height="20px" width="20px" />
    ),
    to: "/geography",
  },
  {
    name: "Booking B2C",
    src: (
      <img src={BookingB2C} alt="Dashboard Icon" height="20px" width="20px" />
    ),
    to: "/bookings",
  },
  {
    name: "Corporate Booking",
    src: (
      <img
        src={CorporateBooking}
        alt="Dashboard Icon"
        height="20px"
        width="20px"
      />
    ),
    to: "/corporatebooking",
  },
  {
    name: "Customer",
    src: <img src={Customer} alt="Dashboard Icon" height="20px" width="20px" />,
    to: "/customer",
  },
  {
    name: "Fleet Management",
    src: (
      <img
        src={FleetManagement}
        alt="Dashboard Icon"
        height="20px"
        width="20px"
      />
    ),
    to: "/carmanagement",
  },
  {
    name: "Driver Management",
    src: (
      <img
        src={DriverManagement}
        alt="Dashboard Icon"
        height="20px"
        width="20px"
      />
    ),
    to: "/drivermanagement",
  },
  {
    name: "Tariff Management",
    src: (
      <img
        src={TariffManagement}
        alt="Dashboard Icon"
        height="20px"
        width="20px"
      />
    ),
    to: "/tariff-management",
  },
  {
    name: "Reports",
    src: <img src={Reports} alt="Dashboard Icon" height="20px" width="20px" />,
    to: "/report",
  },
  {
    name: "Billings",
    src: <img src={Billings} alt="Dashboard Icon" height="20px" width="20px" />,
    to: "/billing",
  },
  {
    name: "Admin",
    src: <img src={Admin} alt="Dashboard Icon" height="20px" width="20px" />,
    to: "/dashboard",
  },
  {
    name: "Coupons",
    src: <img src={Coupons} alt="Dashboard Icon" height="20px" width="20px" />,
    to: "/coupon",
  },
  {
    name: "Enquiry",
    src: <img src={Enquiry} alt="Dashboard Icon" height="20px" width="20px" />,
    to: "/enquiry",
  },
  {
    name: "Manage",
    src: <img src={Manage} alt="Dashboard Icon" height="20px" width="20px" />,
    to: "/manage",
  },
  {
    name: "Services",
    src: <img src={Services} alt="Dashboard Icon" height="20px" width="20px" />,
    to: "/service",
  },
  {
    name: "Admin Settings",
    src: (
      <img src={AdminSetting} alt="Dashboard Icon" height="20px" width="20px" />
    ),
    to: "/adminsettings",
  },
  {
    name: "Settings",
    src: <img src={Settings} alt="Dashboard Icon" height="20px" width="20px" />,
    to: "/settings",
  },
  {
    name: "Management",
    src: (
      <img src={Management} alt="Dashboard Icon" height="20px" width="20px" />
    ),
    to: "/carmanagement",
  },
  {
    name: "Attendance",
    src: (
      <img src={Attendance} alt="Dashboard Icon" height="20px" width="20px" />
    ),
    to: "/attendance",
  },
  {
    name: "Customer Reviews",
    src: (
      <img
        src={CustomerReviews}
        alt="Dashboard Icon"
        height="20px"
        width="20px"
      />
    ),
    to: "/customerreview",
  },
  {
    name: "Retail user",
    src: (
      <img src={RetailUser} alt="Dashboard Icon" height="20px" width="20px" />
    ),
    to: "/retailuser",
  },
  {
    name: "Holiday",
    src: <img src={Holiday} alt="Dashboard Icon" height="20px" width="20px" />,
    to: "/holiday",
  },
  {
    name: "Employee",
    src: <img src={Employee} alt="Dashboard Icon" height="20px" width="20px" />,
    to: "/employee",
  },
];
