import LocationDetails from '../../../../assets/LocationDetails.svg'

import React, { useEffect, useState, useRef } from "react";
import airoicon from '../../../../assets/airoicon.svg'
import searchicon from '../../../../assets/search-icon.svg'
import location from '../../../../assets/location.svg'

// ankit did work in it

import axios from "axios";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import useSuggestionsCity from "../../../../hooks/useSuggestionsIndianCities";
import useSuggestionsCitiesAvailable from "../../../../hooks/useSuggestionCities";
import { generateTimeOptions } from "../../../../utils/time/time";
import useSuggestionsCustomer from "../../../../hooks/useSuggestionCustomer";
import useSuggestionsAirport from "../../../../hooks/useSuggestionsAirport";
import './shared.css'
import clock from '../../../../assets/clock.svg'
import "../../../Booking/Dropdown.css";
import '../AdvancePayable_component/AdvancePay.css'




const apiUrl = "AIzaSyB1LsPrgq_8-ygapY5zgmzGhXuPcn4VtQQ";

// const apiUrl = process.env.GOOGLE_MAPS_KEY;
// console.log({ apiUrl });
let autoComplete;

const loadScript = (url, callback) => {
    let script = document.createElement("script");
    script.type = "text/javascript";

    if (script.readyState) {
        script.onreadystatechange = function () {
            if (script.readyState === "loaded" || script.readyState === "complete") {
                script.onreadystatechange = null;
                callback();
            }
        };
    } else {
        script.onload = () => callback();
    }

    script.src = url;
    document.getElementsByTagName("head")[0].appendChild(script);
};


const Local = ({ setFareDetails, fareDetails, states, setters }) => {

    const navigate = useNavigate();
    const {
        tripType, subTripType, pickUpLocation, selectedLocation, selectPickUpAddress,
        pickUpDate, dropDate, pickUpTime, timeOptions, isDropdownVisible,
        cabType, allCabsData, selectedCabType, selectTemp, selectedTariffId, carCategoriesDetails,
        additionalCities, search, listVisible, showDropdown, showDate, selectAirport,
        startDate, endDate, isNewCustomer, selectCustomer, selectCustomerId,
        showSuggestions, firstName, lastName, mobileNo, email, AlternateContactNumber,
        pickupAddress, longitude, gstValue, flightValue, isGstChecked, isFlightChecked, comments,
        selectedAdvanceAmount, otp, otpId, isOtpModalOpen, selectedHourlyPackage
    } = states;

    const {
        setTripType, setSubTripType, setPickUpLocation, setSelectedLocation, setSelectPickUpAddress,
        setPickUpDate, setDropDate, setPickUpTime, setTimeOptions, setIsDropdownVisible,
        setCabType, setAllCabsData, setSelectedCabType, setSelectTemp, setSelectedTariffId, setCarCategoriesDetails,
        setAdditionalCities, setSearch, setListVisible, setShowDropdown, setShowDate, setSelectAirport,
        setStartDate, setEndDate, setIsNewCustomer, setSelectCustomer, setSelectCustomerId,
        setShowSuggestions, setFirstName, setLastName, setMobileNo, setEmail, setAlternateContactNumber,
        setPickupAddress, setLongitude, setGstValue, setFlightValue, setIsGstChecked, setIsFlightChecked, setComments,
        setSelectedAdvanceAmount, setOtp, setOtpId, setIsOtpModalOpen, setselectedHourlyPackage
    } = setters;

    //   const tripType = "Local"; // This should be dynamically set based on your application's state
    const { suggestions, loading, error, refetch } = useSuggestionsCustomer();

    const [citySuggestions] = useSuggestionsCity();
    const [availableCities] = useSuggestionsCitiesAvailable();


    const [hourlyPackage, setHourlyPackage] = useState([]);
    const [highlightedIndex, setHighlightedIndex] = useState(-1);
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const [selectedIndexCustomer, setSelectedIndexCustomer] = useState(-1);
    const inputRef = useRef();
    const [hoveredIndexCustomer, setHoveredIndexCustomer] = useState(-1);

    // Use the generateTimeOptions function to generate time options based on the date or any other parameter

    const [isDropdownOpen, setIsDropdownOpen] = useState(false);


    // fucntion for gst and flight

    const handleGstCheckboxChange = (e) => {
        const checked = e.target.checked;
        setIsGstChecked(checked);
        if (!checked) {
            setGstValue(""); // Clear the value when unchecked
        }
    };

    const handleFlightCheckboxChange = (e) => {
        const checked = e.target.checked;
        setIsFlightChecked(checked);
        if (!checked) {
            setFlightValue(""); // Clear the value when unchecked
        }
    };


    const handleNewCustomerChange = () => {
        setIsNewCustomer(true);
        setSelectCustomer(""); // Clear customer search input when selecting new customer
        // Clear other details when switching to new customer
        setFirstName("");
        setLastName("");
        setMobileNo("");
        setEmail("");
    };

    const handleExistingCustomerChange = () => {
        setIsNewCustomer(false);
    };

    const handleSelectSuggestion = (suggestion) => {
        setSelectCustomer(suggestion.label);
        setSelectCustomerId(suggestion.value)
        setFirstName(suggestion.firstname);
        setLastName(suggestion.lastname);
        setMobileNo(suggestion.phone.split("-")[1]); // Assumes phone format is 'prefix-number'
        setEmail(suggestion.email);
        setShowSuggestions(false);
    };



    // Now here is the search location functions 

    const autoCompleteRef = useRef(null);

    const handleScriptLoad = (updateQuery, autoCompleteRef) => {
        autoComplete = new window.google.maps.places.Autocomplete(
            autoCompleteRef.current,
            {
                // types: ["(cities)"],
                componentRestrictions: { country: "IN" },
            }
        );

        autoComplete.addListener("place_changed", () => {
            handlePlaceSelect(updateQuery);
        });
    };

    const handlePlaceSelect = async (updateQuery) => {
        const addressObject = await autoComplete.getPlace();

        const query = addressObject.formatted_address;
        updateQuery(query);
        console.log({ query });

        const latLng = {
            lat: addressObject?.geometry?.location?.lat(),
            lng: addressObject?.geometry?.location?.lng(),
        };

        console.log({ latLng });
        setLongitude(latLng);
    };

    useEffect(() => {
        loadScript(
            `https://maps.googleapis.com/maps/api/js?key=${apiUrl}&libraries=places`,
            () => handleScriptLoad(setSelectPickUpAddress, autoCompleteRef)
        );
    }, []);





    useEffect(() => {
        if (startDate) {
            const generatedOptions = generateTimeOptions(startDate); // You can customize pickUpDate as needed
            setTimeOptions(generatedOptions);
        }
    }, [startDate]);



    const formatDateNew = (date) => {
        const [year, month, day] = date.split("-");
        return `${day}-${month}-${year}`;
    };

    const dateInputRef = useRef(null);

    const handleStartDateChange = (e) => {
        setStartDate(e.target.value);
    };

    const handleInputClick = () => {
        dateInputRef.current.focus(); // Focus on the input when clicking the wrapper
    };

    

    const outsideClickOfDate = () => {
        setShowDate(false);
        console.log("outside date get selected  ", showDate);
    };



    useEffect(() => {
        console.log("showDate changed to:", showDate);
    }, [showDate]);
    const suggestionhide = () => {
        setShowDropdown(false);
        setShowSuggestions(false);
        console.log("mouseleave dropdown", showDropdown);
    };

    const suggestionshows = () => {
        setShowDropdown(true);
        setShowDropdown(true);
        console.log("mouseover dropdown ", showDropdown);
    };


    const suggestionshowsairport = () => {
        setShowDropdown(true);

        console.log("mouseover dropdown ", showDropdown);
    };






    useEffect(() => {
        const extractLocationsAsOptions = (dataArray) => {
            if (Array.isArray(dataArray.data)) {
                return dataArray.data.map((item) => ({
                    label: item.location,
                    value: item.location,
                }));
            }
            return [];
        };

        const fetchData = async () => {
            try {
                const locationResponse = await fetch(
                    "https://api.yatricabs.com/v1/locations"
                );
                const locationData = await locationResponse.json();
                const pickUpLocation = extractLocationsAsOptions(locationData);
                setPickUpLocation(pickUpLocation);
            } catch (error) {
                console.error("Error fetching data: ", error);
            }
        };
        fetchData();
    }, [setPickUpLocation]);

    // const formatDate = (selectedDates) => {
    //     if (!(selectedDates instanceof Date) || isNaN(selectedDates)) {
    //         return "";
    //     }
    //     return format(selectedDates, "dd-MM-yyyy");
    // };

    const extractCarCategoriesAsOptions = (dataArray) => {
        if (Array.isArray(dataArray.data)) {
            return dataArray.data.map((item) => ({
                label: item.carCategory.category,
                value: item.carCategory.category,
            }));
        }
        return [];
    };


    const extractSubTripAsOptions = (dataArray) => {
        if (Array.isArray(dataArray.data)) {
            return dataArray.data.map((item) => ({
                label: item.subTripType,
                value: item.subTripType,
            }));
        }
        return [];
    };

    // Helper function to format the date
    const formatDate = (date) => {
        if (!(date instanceof Date) || isNaN(date)) {
            return "";
        }
        return format(date, "dd-MM-yyyy");
    };

    const handleselectedlocation = async (e) => {
        setSelectedLocation(e.target.value);
    };

    const handleChange = (e) => {
        setPickUpTime(e.target.value);
    };

    useEffect(() => {
        if (selectedLocation && pickUpTime) {
            const callYourAPI = async () => {
                try {
                    const response = await axios.get(
                        `https://api.yatricabs.com/v1/users/select-cars/local?source=${selectedLocation}&time=${pickUpTime}`
                    );
                    console.log(response.data);
                    const subTripOptions = extractSubTripAsOptions(response.data);
                    setHourlyPackage(subTripOptions);
                    const temp = response.data.tempDataId;
                    console.log("Temp Id", temp);
                    setSelectTemp(temp);
                } catch (error) {
                    console.error("Error fetching cars:", error);
                    setHourlyPackage([]);
                    setCabType([]);
                }
            };

            // Call the API
            callYourAPI();
        }
    }, [pickUpTime, selectedLocation]); // Add selectedLocation to dependencies if it's required for the API call

    const handleChangeTripType = async (e) => {
        const subTripType = e.target.value;  // Selected hourly package (subTripType)
        setselectedHourlyPackage(subTripType);  // Update selected hourly package

        try {
            const response = await axios.get(
                `https://api.yatricabs.com/v1/users/select-cars/local?source=${selectedLocation}&time=${pickUpTime}`
            );

            // Filter the car categories that match the selected subTripType
            const allCabsData = response.data.data.filter(
                (item) => item.subTripType === subTripType
            );

            if (allCabsData.length > 0) {
                console.log('Filtered Car Categories:', allCabsData);

                // Set the filtered car categories into the state
                setCarCategoriesDetails(allCabsData);

                // Extract car categories for dropdown options
                const carCategoryOptions = allCabsData.map((item) => ({
                    value: item.carCategory.category,
                    label: item.carCategory.category,
                    includes: item.carCategory.includes,
                }));

                // Update cabType dropdown with car categories
                setCabType(carCategoryOptions);
                setAllCabsData(allCabsData);
            } else {
                // If no car categories match the selected tripType, reset cabType options
                setCabType([]);
            }
        } catch (error) {
            toast.error(error.response?.data?.message || "Error fetching cars");
            console.error("Error fetching cars:", error);
            setCabType([]);  // Reset the dropdown on error
        }
    };

    // console.log(suggestions);
    useEffect(() => {


        setStartDate("");
        setPickUpTime("");
        setselectedHourlyPackage("");
        setSelectedCabType("");
        setFareDetails(null);
     
    
      
    }, [selectedLocation])

    useEffect(() => {


        setselectedHourlyPackage("");
        setSelectedCabType("")
        setFareDetails(null);
     
    
      
    }, [pickUpTime])

    useEffect(() => {

        setPickUpTime("")
        setselectedHourlyPackage("");
        setSelectedCabType("")
        setFareDetails(null);
     
    
      
    }, [startDate])


    useEffect(() => {

        setSelectedCabType("");
        setFareDetails(null);
     
    
      
    }, [selectedHourlyPackage])


    const handleChangeCabType = (e) => {
        const selectedCab = e.target.value;
        setSelectedCabType(selectedCab);

        // Find the corresponding fare details from the response data
        const selectedFare = allCabsData.find(
            (item) => item.carCategory.category === cabType.find((option) => option.value === selectedCab).label
        );

        // Update fare details state
        if (selectedFare) {
            setSelectedTariffId(selectedFare._id)
            setFareDetails(selectedFare);
        } else {
            setFareDetails(null);
        }
    };
    const filteredLocations =
        search.length === 0
            ? pickUpLocation
            : pickUpLocation.filter((city) =>
                city.label.toLowerCase().includes(search.toLowerCase())
            );















    // code for otp verification

    const handleOtpChange = (index, value) => {
        const newOtp = [...otp];
        newOtp[index] = value;

        // If all OTP digits are filled, open the modal
        if (newOtp.every((digit) => digit !== "")) {
            setIsOtpModalOpen(true);
        }

        setOtp(newOtp);

        // Move focus to the next input field
        if (value !== "" && index < otp.length - 1) {
            document.getElementById(`otp-input-${index + 1}`).focus();
        }
    };

    const handleKeyDown = (index, e) => {
        // Move focus to the previous input field when backspace is pressed and the current input is empty
        if (e.keyCode === 8 && otp[index] === "" && index > 0) {
            document.getElementById(`otp-input-${index - 1}`).focus();
        }
    };




    const handleVerifyOtp = async () => {
        try {
            const phoneNumberWithCountryCode = "+91-" + mobileNo;

            const combinedOtp = otp.join("");

            const requestBodyOtp = {
                phone: phoneNumberWithCountryCode,
                otp: Number(combinedOtp),
                otpId: otpId,
            };

            const otpVerifyApiUrl = `https://api.yatricabs.com/v1/verify-otp`;
            const otpVerifyResponse = await fetch(otpVerifyApiUrl, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(requestBodyOtp),
            });

            if (otpVerifyResponse.ok) {
                const result = await otpVerifyResponse.json();
                console.log("OTP Verification Result:", result);
                console.log("Otp Token :", result.otpToken);
                toast.success(result.message);

                // Close OTP verification modal
                setIsOtpModalOpen(false);
                // Update the otpToken state before proceeding
                // await handleOtpToken(result);
                // otpToken = await result.otpToken;
                if (result.otpToken) {
                    handleRegisterAndProceed(result.otpToken);
                } else {
                    console.error("Error: OTP token is empty.");
                    // Handle the case where OTP token is empty
                }

                // Proceed with further logic after successful OTP verification
            } else {
                const result = await otpVerifyResponse.json();
                console.error("Error verifying OTP:", result);
                // toast.error('OTP verification failed. Please try again.');
                // setOtpError("Invalid OTP. Please try again.");
            }
        } catch (error) {
            console.error("Error during OTP verification:", error);
            // setOtpError("Something went wrong. Please try again.");
        }
    };

    const handleCancelOtp = () => {
        // Clear OTP input and close the modal
        setOtp(["", "", "", "", "", ""]);
        setIsOtpModalOpen(false);
    };

    const handleRegisterAndProceed = async (otpToken) => {
        try {
            const phoneNumberWithCountryCode = "+91-" + mobileNo;
            const alternatePhoneNumberWithCountryCode =
                "+91-" + AlternateContactNumber;

            let requestBody;

            console.log("AlternateContactNumber :", AlternateContactNumber);

            if (!AlternateContactNumber) {
                requestBody = {
                    // Include other data from the form
                    otpToken: otpToken,
                    firstName: firstName,
                    lastName: lastName,
                    email: email,
                    phone: phoneNumberWithCountryCode,
                    deviceType: "Web"
                    // ... Add other form fields as needed
                };
            } else {
                requestBody = {
                    // Include other data from the form
                    otpToken: otpToken,
                    firstName: firstName,
                    lastName: lastName,
                    email: email,
                    phone: phoneNumberWithCountryCode,
                    alternatePhone: alternatePhoneNumberWithCountryCode,
                    deviceType: "Web"

                    // ... Add other form fields as needed
                };
            }

            console.log("Guest Register requestbody :", requestBody);

            const registerApiUrl = `https://api.yatricabs.com/v1/users/register-guest`;
            const registerResponse = await fetch(registerApiUrl, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "x-device-type": "Web",
                },
                body: JSON.stringify(requestBody),
            });
            console.log("Register Response :", registerResponse);

            // setGuestRegistered(true);
            await refetch();
            setIsNewCustomer(false);
            // navigate('/bookings/create-new-booking')

            if (registerResponse.ok) {
                const resultRegister = await registerResponse.json();
                toast.success(
                    "Now select it from select custmer:",
                    resultRegister.message
                );
                // handleCreateBooking();
            } else {
                // const resultAgainRegister = await registerResponse.json();
                console.error(
                    "Error registering user:",
                    registerResponse.status,
                    registerResponse.statusText
                );
                // toast.error(resultAgainRegister.message);
            }
        } catch (error) {
            console.error("Error during registration and order creation :", error);
            // toast.error(error);
        }
    };


    const handleKey = (e) => {
        const filteredSuggestions = availableCities
            .filter((suggestion) =>
                suggestion.toLowerCase().includes(selectedLocation.toLowerCase())
            )
            .slice(0, 6);

        if (e.key === "ArrowDown") {
            setSelectedIndex((prevIndex) =>
                prevIndex < filteredSuggestions.length - 1 ? prevIndex + 1 : 0
            );
        } else if (e.key === "ArrowUp") {
            setSelectedIndex((prevIndex) =>
                prevIndex > 0 ? prevIndex - 1 : filteredSuggestions.length - 1
            );
        } else if (e.key === "Enter") {
            // Prevent form submission when selecting a suggestion
            e.preventDefault();
            if (selectedIndex >= 0 && selectedIndex < filteredSuggestions.length) {
                const suggestion = filteredSuggestions[selectedIndex];
                setSelectedLocation(suggestion);
                handleselectedlocation({
                    target: { value: suggestion },
                });
                inputRef.current.focus(); // Focus back on the input
                setShowDropdown(false); // Close suggestions on selection
            }
        }
    };

    // useEffect(() => {
    //     setSelectedIndex(-1);
    // }, [citySuggestions]);

    const handleKeyCustomer = (e) => {
        const filteredSuggestions = suggestions
            .filter((suggestion) => {
                const lowerCaseInput = selectCustomer.toLowerCase();
                const lowerCaseName = suggestion.label.toLowerCase();
                const phone = suggestion.phone;
                return (
                    lowerCaseName.includes(lowerCaseInput) ||
                    phone.includes(selectCustomer)
                );
            })
            .slice(0, 6);
        if (e.key === "ArrowDown") {
            setSelectedIndexCustomer((prevIndex) =>
                prevIndex < filteredSuggestions.length - 1 ? prevIndex + 1 : 0
            );
        } else if (e.key === "ArrowUp") {
            setSelectedIndexCustomer((prevIndex) =>
                prevIndex > 0 ? prevIndex - 1 : filteredSuggestions.length - 1
            );
        } else if (e.key === "Enter") {
            e.preventDefault();
            if (selectedIndexCustomer >= 0 && selectedIndexCustomer < filteredSuggestions.length) {
                const suggestion = filteredSuggestions[selectedIndexCustomer];
                handleSelectSuggestion(suggestion); // Call the suggestion select handler
                setShowSuggestions(false); // Hide suggestions after selection
            }
        }
    };

    const calenderKhol = (e) => {
        if (e?.target?.showPicker) {
          e.target.showPicker();  
        }
      };

    return (
        <>


            {/* ankit new customer code  */}

            <div style={{ position: "relative", width: "100%" }}>
                <div className="cust-type-checkbox-container">
                    <p>Customer Type</p>
                    <input
                        type="checkbox"
                        checked={isNewCustomer}
                        onChange={handleNewCustomerChange}
                    />
                    <label>New Customer</label>

                    <input
                        type="checkbox"
                        checked={!isNewCustomer}
                        onChange={handleExistingCustomerChange}
                    />
                    <label>Existing Customer</label>
                </div>

                {!isNewCustomer && (
                    <div className="single-txtfield-wrapper big">
                        <input
                            type="text"
                            placeholder="Search Customer by Name or Mobile number"
                            value={selectCustomer}
                            onChange={(e) => {
                                setSelectCustomer(e.target.value);
                                setShowSuggestions(true);
                            }}
                            onKeyDown={handleKeyCustomer}
                            autoComplete="off"
                        />
                        <div className="BtoC-icon-holder">
                            <img src={searchicon} alt="search icon" />
                        </div>

                        {selectCustomer && showSuggestions && (
                            <div 
                            className="cstomerSelectionDropdown"
                            onClick={suggestionshows} 
                            onMouseLeave={() => {
                                suggestionhide();
                                setSelectedIndexCustomer(-1);
                            }}
                        >
                                {suggestions
                                    .filter((suggestion) => {
                                        const lowerCaseInput = selectCustomer.toLowerCase();
                                        const lowerCaseName = suggestion.label.toLowerCase();
                                        const phone = suggestion.phone;
                                        return (
                                            lowerCaseName.includes(lowerCaseInput) ||
                                            phone.includes(selectCustomer)
                                        );
                                    })
                                    .slice(0, 6)
                                    .map((suggestion, index) => (
                                        <div
                                            key={index}
                                            style={{
                                                cursor: "pointer",
                                                backgroundColor:
                                                    selectedIndexCustomer === index
                                                        ? "#38b000" 
                                                        : hoveredIndexCustomer === index
                                                            ? "#38b000" 
                                                            : "transparent",
                                                
                                            }}
                                            className={`suggestion ${selectedIndexCustomer === index ? 'selected' : ''}`}
                                            onClick={() => handleSelectSuggestion(suggestion)}
                                            onMouseEnter={() => setHoveredIndexCustomer(index)} 
                                            onMouseLeave={() => setHoveredIndexCustomer(-1)} 
                                        >
                                            {suggestion.label} - {suggestion.phone}
                                        </div>
                                    ))}
                            </div>
                        )}
                    </div>
                )}


            </div>
            <div className="oneway-row-flexer" >
                <div className="whole-inputlable-wrapper" onClick={suggestionshows}>
                    <label>Select Pickup City</label>
                    <div className="single-txtfield-wrapper">
                        <input
                            type="text"
                            placeholder="Enter pick up location"
                            value={selectedLocation}
                            ref={inputRef}
                            autoComplete="off"
                            onChange={(e) => {
                                handleselectedlocation(e);
                                setListVisible(true);
                                if (e.target.value) {
                                    setShowDropdown(true);
                                }
                            }}
                            onKeyDown={handleKey}
                        />
                        <div className="BtoC-icon-holder">
                            <img src={location} alt="img" />
                        </div>

                        {showDropdown && (
                            <div>
                                {listVisible && showDropdown && (
                                    <div className="list-container" onClick={suggestionshows} onMouseLeave={suggestionhide}>
                                        <ul>
                                            {availableCities && availableCities.length > 0 ? (
                                                availableCities
                                                    .filter((suggestion) =>
                                                        suggestion.toLowerCase().includes(selectedLocation.toLowerCase())
                                                    )
                                                    .slice(0, 6)
                                                    .map((city, index) => (
                                                        <li
                                                            key={index}
                                                            style={{
                                                                cursor: "pointer",
                                                                backgroundColor: selectedIndex === index ? "#bde4ff" : "transparent",
                                                                ...(selectedIndex === index && { backgroundColor: "#38b000" }),
                                                            }}
                                                            onClick={() => {
                                                                setSelectedLocation(city);
                                                                handleselectedlocation({
                                                                    target: { value: city },
                                                                });
                                                                setShowDropdown(false);
                                                            }}
                                                            onMouseEnter={() => setSelectedIndex(index)}
                                                            onMouseLeave={() => setSelectedIndex(-1)}

                                                        >
                                                            {city}
                                                        </li>
                                                    ))
                                            ) : (
                                                <li>No cities found</li>
                                            )}
                                        </ul>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </div>


                {/* <div className="whole-inputlable-wrapper">
                <label>Select Airport</label>
                <div className='single-txtfield-wrapper'>
                    <input type='text' placeholder='Airport Name'></input>
                    <div className="BtoC-icon-holder"> <img src={airoicon} alt='img'></img> </div>
                </div>
            </div> */}


                <div className="whole-inputlable-wrapper"  onClick={handleInputClick}>
                    <label>From Date</label>
                    <div className="single-txtfield-wrapper">
                        <input
                            type="date"
                            value={startDate}
                            onChange={handleStartDateChange}
                            onClick={(e) => calenderKhol(e)}
                            ref={dateInputRef} 
                            min={new Date().toISOString().split("T")[0]} // Setting today's date as the minimum
                            required
                        />
                    </div>
                    
                </div>

                

                <div className="whole-inputlable-wrapper">
                    <label>Pick Up Time</label>
                    <div className="single-txtfield-wrapper">
                        <select
                            value={pickUpTime || ""}  // Ensure pickUpTime is always a string, not an object
                            onChange={handleChange}
                            className="time-select"
                        >
                            <option value="" disabled>Select pick up time</option>
                            {generateTimeOptions(startDate).map((option, index) => (
                                <option key={index} value={option}>
                                    {option}  {/* Rendering string time directly */}
                                </option>
                            ))}
                        </select>
                        <div className="BtoC-icon-holder">
                            <img src={clock} alt='img' />
                        </div>
                    </div>
                </div>

                <div className="whole-inputlable-wrapper">
                    <label>Hourly Package</label>
                    <div className="single-txtfield-wrapper">
                        <select
                            value={selectedHourlyPackage}
                            onChange={handleChangeTripType}
                            className="time-select"
                        >
                            <option value="" disabled>Select hourly package</option>
                            {hourlyPackage.map((option, index) => (
                                <option key={index} value={option.value}>
                                    {option.label}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>

                <div className="whole-inputlable-wrapper">
                    <label>Cab Type</label>
                    <div className="single-txtfield-wrapper">
                        <select
                            value={selectedCabType}  // Assuming this tracks the selected cab type
                            onChange={handleChangeCabType}  // Update the selected cab type
                            className="time-select"
                        >
                            <option value="" disabled>Select cab type</option>
                            {cabType.map((option, index) => (
                                <option key={index} value={option.value}>
                                    {option.label}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>

            </div>


            <div className="oneway-row-flexer">
                <div className="whole-inputlable-wrapper">
                    <label>Passenger Details</label>
                    <div className="single-txtfield-wrapper gray">
                        <input
                            type="text"
                            placeholder="First name"
                            value={isNewCustomer ? firstName : firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                            disabled={!isNewCustomer && selectCustomer !== ""}
                            required
                        />
                    </div>
                </div>

                <div className="whole-inputlable-wrapper">
                    <label>&ensp;</label>
                    <div className="single-txtfield-wrapper gray">
                        <input
                            type="text"
                            placeholder="Last name"
                            value={isNewCustomer ? lastName : lastName}
                            onChange={(e) => setLastName(e.target.value)}
                            disabled={!isNewCustomer && selectCustomer !== ""}
                            required
                        />
                    </div>
                </div>

                <div className="whole-inputlable-wrapper">
                    <label>&ensp;</label>
                    <div className="single-txtfield-wrapper gray">
                        <input
                            type="text"
                            placeholder="Passenger E-mail"
                            value={isNewCustomer ? email : email}
                            onChange={(e) => setEmail(e.target.value)}
                            disabled={!isNewCustomer && selectCustomer !== ""}
                            required
                        />
                    </div>
                </div>

                <div className="whole-inputlable-wrapper">
                    <label>&ensp;</label>
                    <div className="single-txtfield-wrapper gray">
                        <input
                            type="text"
                            placeholder="Passenger contact number"
                            value={isNewCustomer ? mobileNo : mobileNo}
                            onChange={(e) => setMobileNo(e.target.value)}
                            disabled={!isNewCustomer && selectCustomer !== ""}
                            required
                        />
                    </div>
                </div>

                {/* Search Location Component */}

                <div className="whole-inputlable-wrapper">
                    <label>&ensp;</label>
                    <div className="single-txtfield-wrapper">
                        <input
                            type="text"
                            ref={autoCompleteRef}
                            onChange={(event) => setSelectPickUpAddress(event.target.value)}
                            value={selectPickUpAddress}
                            placeholder="Pick Up Address"
                        />
                        <div className="BtoC-icon-holder">
                            <img src={searchicon} alt="search icon" />
                        </div>
                    </div>
                </div>





                <div className="whole-inputlable-wrapper">
                    <label>&ensp;</label>
                    <div className="single-txtfield-wrapper">
                        <input
                            type="text"
                            placeholder="Alternate contact number"
                            value={AlternateContactNumber}
                            onChange={(e) => setAlternateContactNumber(e.target.value)}
                        />
                    </div>
                </div>
            </div>


            <div className="curve-field-container">
                <div className="sub-wrapper">
                    <input
                        id="c001"
                        type="checkbox"
                        checked={isGstChecked}
                        onChange={handleGstCheckboxChange}
                    />
                    <label htmlFor="c001">GST</label>
                    <input
                        type="text"
                        value={gstValue}
                        onChange={(e) => setGstValue(e.target.value)}
                        disabled={!isGstChecked}
                    />
                </div>

                <div className="sub-wrapper">
                    <input
                        id="c002"
                        type="checkbox"
                        checked={isFlightChecked}
                        onChange={handleFlightCheckboxChange}
                    />
                    <label htmlFor="c002">Flight</label>
                    <input
                        type="text"
                        value={flightValue}
                        onChange={(e) => setFlightValue(e.target.value)}
                        disabled={!isFlightChecked}
                    />
                </div>
            </div>

            <textarea
                placeholder="Comment here for any specific request for ride :"
                value={comments}
                onChange={(e) => setComments(e.target.value)}
            />



            {isOtpModalOpen && (
                <div className="modal-container">
                    <div className="modal-content">
                        <p>
                            Please enter the OTP sent on user mobile no.{" "}
                            {"+91-" + mobileNo}
                        </p>
                        <div className="otp-input-container">
                            {otp.map((digit, index) => (
                                <input
                                    key={index}
                                    id={`otp-input-${index}`}
                                    type="text"
                                    maxLength="1"
                                    value={digit}
                                    onChange={(e) => handleOtpChange(index, e.target.value)}
                                    onKeyDown={(e) => handleKeyDown(index, e)}
                                    autoComplete="off"
                                />
                            ))}
                        </div>
                        <div className="button-container">
                            <button className="verify-button" onClick={handleVerifyOtp}>
                                Verify Now
                            </button>
                            <button className="cancel-button" onClick={handleCancelOtp}>
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            )}
            <Toaster />
        </>
    )
}

export default Local