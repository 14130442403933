import React, { useEffect, useState } from "react";
import Styles from "./CustomDatePicker.module.css";
import { FaCalendarDays } from "react-icons/fa6";
import { format, getDay, getDaysInMonth, set } from "date-fns";

function Calendar({ year, month, turn, setTurn, range, setRange }) {
	function handleSelectDate(e) {
		const date = e.target.value;
		if (e.target.classList.contains(Styles.selected1)) {
			setRange({ from: null, to: null });
			setTurn("from");
			return;
		}
		if (e.target.classList.contains(Styles.selected2)) {
			setRange({ ...range, to: null });
			console.log(range);
			if (range.from) setTurn("to");
			else setTurn("from");
			return;
		}

		if (turn === "from") {
			const from = new Date(year, month, date);

			if (range?.to == null) {
				setRange({ from });
				setTurn("to");
				return;
			}

			if (from > range?.to) {
				setRange({ from: range?.to, to: from });
			} else setRange({ ...range, from });
			setTurn("to");
		} else {
			const to = new Date(year, month, date);
			if (to < range?.from) {
				setRange({ from: to, to: range?.from });
			} else setRange({ ...range, to: new Date(year, month, date) });
			setTurn("from");
		}
	}

	return (
		<div className={Styles.calendar}>
			<div className={Styles.days}>
				<div>SUN</div>
				<div>MON</div>
				<div>TUE</div>
				<div>WED</div>
				<div>THU</div>
				<div>FRI</div>
				<div>SAT</div>
			</div>
			<div className={Styles.dates}>
				{Array(getDay(new Date(year, month, 1)))
					.fill(0)
					.map((_, index) => (
						<button
							key={index}
							value={0}
							disabled
							className={Styles.emptySpace}
						></button>
					))}
				{Array(getDaysInMonth(new Date(year, month)))
					.fill(0)
					.map((_, index) => (
						<button
							onClick={handleSelectDate}
							className={
								range?.from?.getMonth() == month &&
								range?.from?.getDate() == index + 1
									? Styles.selected1
									: range?.to?.getMonth() == month &&
									  range.to.getDate() == index + 1
									? Styles.selected2
									: ""
							}
							key={index}
							value={index + 1}
						>
							{index + 1}
						</button>
					))}
			</div>
		</div>
	);
}

export default function DatePicker({
	initialRange,
	setSelectedDate,
	closeCalendar,
	ref,
}) {
	const [range, setRange] = useState();
	const [turn, setTurn] = useState("from");
	const [currentDate, setCurrentDate] = useState(new Date());

	function handlePrevMonth() {
		setCurrentDate(new Date(currentDate.setMonth(currentDate.getMonth() - 1)));
	}

	function handleNextMonth() {
		setCurrentDate(new Date(currentDate.setMonth(currentDate.getMonth() + 1)));
	}

	function handleApply() {
		setSelectedDate({
			from: range?.from,
			to: range?.to,
		});
	}

	useEffect(() => {
		const temp = {};
		if (initialRange?.startDate) {
			temp.from = initialRange.startDate;
		}
		if (initialRange?.endDate) {
			temp.to = initialRange.endDate;
		}
		setRange(temp);
	}, [initialRange]);

	return (
		<div className={Styles.datePicker} ref={ref}>
			<div className={Styles.left}>
				<div>
					<button
						onClick={() => {
							setRange({
								from: new Date(),
								to: new Date(),
							});
							setTurn("to");
						}}
					>
						<span>Today</span>
					</button>

					<button
						onClick={() => {
							setRange({
								from: new Date(new Date().setDate(new Date().getDate() - 1)),
								to: new Date(new Date().setDate(new Date().getDate() - 1)),
							});
							setTurn("to");
						}}
					>
						<span>Yesterday</span>
					</button>

					<button
						onClick={() => {
							setRange({
								from: new Date(new Date().setDate(new Date().getDate() - 6)),
								to: new Date(),
							});
							setTurn("to");
						}}
					>
						<span>Last 7 Days</span>
					</button>

					<button
						onClick={() => {
							setRange({
								from: new Date(new Date().setDate(new Date().getDate() - 14)),
								to: new Date(),
							});
							setTurn("to");
						}}
					>
						<span>Last 15 Days</span>
					</button>

					<button
						onClick={() => {
							setRange({
								from: new Date(new Date().setDate(new Date().getDate() - 29)),
								to: new Date(),
							});
							setTurn("to");
						}}
					>
						<span>Last 30 Days</span>
					</button>

					<button
						onClick={() => {
							setRange({
								from: new Date(new Date().setDate(new Date().getDate() - 1)),
								to: new Date(),
							});
							setTurn("to");
						}}
					>
						<span>Date Range</span>
					</button>
				</div>
				<div>
					<button
						id={Styles.reset}
						onClick={() => {
							setRange({});
							setTurn("from");
						}}
					>
						<span>Reset</span>
					</button>
					<button id={Styles.cancel} onClick={closeCalendar}>
						<span>Cancel</span>
					</button>
				</div>
			</div>

			<div className={Styles.right}>
				<div className={Styles.dateRangeContainer}>
					<div className={Styles.dateRangeMain}>
						<div className={Styles.dateRange}>
							From
							<div>
								<FaCalendarDays color="#FD6A00" />
								{range?.from &&
									format(
										new Date(range?.from).setDate(range?.from.getDate()),
										"dd-MMM-yyyy",
									)}
								{!range?.from && "Select Date"}
							</div>
						</div>
						<div className={Styles.dateRangeApply}>
							<button onClick={handleApply}>Apply</button>
						</div>
						<div className={Styles.dateRange}>
							To
							<div>
								<FaCalendarDays color="#FD6A00" />
								{range?.to &&
									format(
										new Date(range?.to).setDate(range?.to.getDate()),
										"dd-MMM-yyyy",
									)}
								{!range?.to && "Select Date"}
							</div>
						</div>
					</div>
				</div>

				<div className={Styles.calendarContainer}>
					<div>
						<div className={Styles.calendarHeader}>
							<span
								onClick={handlePrevMonth}
								style={{
									color: "black",
									fontWeight: "bold",
									cursor: "pointer",
								}}
							>
								{"<"}
							</span>
							<span>
								{format(currentDate, "LLLL") +
									" " +
									format(currentDate, "yyyy")}
							</span>
						</div>
						<div>
							<Calendar
								range={range}
								setRange={setRange}
								turn={turn}
								setTurn={setTurn}
								month={currentDate.getMonth()}
								year={currentDate.getFullYear()}
							/>
						</div>
					</div>

					<div>
						<div className={Styles.calendarHeader + " " + Styles.rightHeader}>
							<span>
								{format(
									new Date(currentDate).setMonth(currentDate.getMonth() + 1),
									"LLLL",
								) +
									" " +
									format(
										new Date(currentDate).setMonth(currentDate.getMonth() + 1),
										"yyyy",
									)}
							</span>
							<span
								onClick={handleNextMonth}
								style={{
									color: "black",
									fontWeight: "bold",
									cursor: "pointer",
								}}
							>
								{">"}
							</span>
						</div>
						<div>
							<Calendar
								range={range}
								setRange={setRange}
								turn={turn}
								setTurn={setTurn}
								month={new Date(
									new Date(currentDate).setMonth(currentDate.getMonth() + 1),
								).getMonth()}
								year={new Date(
									new Date(currentDate).setMonth(currentDate.getMonth() + 1),
								).getFullYear()}
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
