import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchbookingDetails,fetchbillingDetails } from "./DetailsAPI";

const initialState = {
  bookingDetails: null,
  billingDetails:[],
  status: "idle",
};

export const fetchbookingDetailsAsync = createAsyncThunk(
  "details/bookingDetails",
  async (id) => {
    try {
      const response = await fetchbookingDetails(id);
      console.log(id);
      console.log("Booking details form slice>>>>>>",response);
      return response;
    } catch (error) {
      console.error("Error fetching details:", error);
      throw error;
    }
  }
);

export const fetchbillingDetailsAsync = createAsyncThunk(
  "details/billingDetails",
  async (id) => {
    try {
      const response = await fetchbillingDetails(id);
      return response;
    } catch (error) {
      console.error("Error fetching details:", error);
      throw error;
    }
  }
);


export const detailSlice = createSlice({
  name: "details",
  initialState,

  extraReducers: (builder) => {
    builder
      .addCase(fetchbookingDetailsAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchbookingDetailsAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.bookingDetails = action.payload;
      })
      .addCase(fetchbookingDetailsAsync.rejected, (state) => {
        state.status = "error"; // Handle error state if necessary
      })
      .addCase(fetchbillingDetailsAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchbillingDetailsAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.billingDetails = action.payload;
      })
      .addCase(fetchbillingDetailsAsync.rejected, (state) => {
        state.status = "error"; // Handle error state if necessary
        state.billingDetails = null;
      });
  },
});

export const selectbookingDetails = (state) => state.details.bookingDetails;
export const selectbillingDetails = (state) => state.details.billingDetails;

export default detailSlice.reducer;
