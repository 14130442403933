// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.preview-container{
    position: relative;
    width:100%;
    display:flex;
    height:28rem;
    padding-top: 4rem;
    flex-direction: row;
}
.left-preview-container{
    padding-top: 1rem;
    padding-left: 2rem;
    position: relative;
    flex:50% 1;
    height:10rem;
}
.right-preview-container{
    position: relative;
    flex:50% 1;
    height:10rem;
}
.detail {
    position: relative;
    display: flex;
    margin-top: 1rem;
    margin-bottom:35px;
    flex-direction: row; 
  }
  
  .detail div{
    font-family: Lato;
    font-size: 18px;
    font-weight: 700;
    line-height: 21.6px;
    text-align: left;    
    color: black;
    width:38% ;
  }
  .detail p{
    font-size: 0.8rem;
    color: black;
    /* margin-left: 4rem; */
    text-align: start;
    width: 62%;
  }
  .car-photo div{
    font-size: 1rem;
    font-weight: 700;
    color: black;
    width:9rem;
  }

  .car-photo {
    text-align: center;
  }
  
  .car-photo img {
    max-width: 25rem;
  }
`, "",{"version":3,"sources":["webpack://./src/components/CarManagement/modifyCarCategory/PreviewCarCategory.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,UAAU;IACV,YAAY;IACZ,YAAY;IACZ,iBAAiB;IACjB,mBAAmB;AACvB;AACA;IACI,iBAAiB;IACjB,kBAAkB;IAClB,kBAAkB;IAClB,UAAQ;IACR,YAAY;AAChB;AACA;IACI,kBAAkB;IAClB,UAAQ;IACR,YAAY;AAChB;AACA;IACI,kBAAkB;IAClB,aAAa;IACb,gBAAgB;IAChB,kBAAkB;IAClB,mBAAmB;EACrB;;EAEA;IACE,iBAAiB;IACjB,eAAe;IACf,gBAAgB;IAChB,mBAAmB;IACnB,gBAAgB;IAChB,YAAY;IACZ,UAAU;EACZ;EACA;IACE,iBAAiB;IACjB,YAAY;IACZ,uBAAuB;IACvB,iBAAiB;IACjB,UAAU;EACZ;EACA;IACE,eAAe;IACf,gBAAgB;IAChB,YAAY;IACZ,UAAU;EACZ;;EAEA;IACE,kBAAkB;EACpB;;EAEA;IACE,gBAAgB;EAClB","sourcesContent":[".preview-container{\n    position: relative;\n    width:100%;\n    display:flex;\n    height:28rem;\n    padding-top: 4rem;\n    flex-direction: row;\n}\n.left-preview-container{\n    padding-top: 1rem;\n    padding-left: 2rem;\n    position: relative;\n    flex:50%;\n    height:10rem;\n}\n.right-preview-container{\n    position: relative;\n    flex:50%;\n    height:10rem;\n}\n.detail {\n    position: relative;\n    display: flex;\n    margin-top: 1rem;\n    margin-bottom:35px;\n    flex-direction: row; \n  }\n  \n  .detail div{\n    font-family: Lato;\n    font-size: 18px;\n    font-weight: 700;\n    line-height: 21.6px;\n    text-align: left;    \n    color: black;\n    width:38% ;\n  }\n  .detail p{\n    font-size: 0.8rem;\n    color: black;\n    /* margin-left: 4rem; */\n    text-align: start;\n    width: 62%;\n  }\n  .car-photo div{\n    font-size: 1rem;\n    font-weight: 700;\n    color: black;\n    width:9rem;\n  }\n\n  .car-photo {\n    text-align: center;\n  }\n  \n  .car-photo img {\n    max-width: 25rem;\n  }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
